import type { IDefaultDetailsContent } from '../type';
import Label from '@/components/common/label';
import DetailsItem from '@/pages/account-balance/components/details-item-component';
import React, { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import {
  handleMount,
  handleUtcDate,
  handleSymbolMount,
} from '@/utils/account-balance-untils';
import Skeleton from '@/components/skeleton';
import CopyIcon from '@/components/svg/copy-icon';
import { useTranslation } from 'react-i18next';

let tipsTimeout: any;
let timeout: any;

const DefaultDetailsContent = (props: IDefaultDetailsContent) => {
  const { showType = null, settledAmount = '', record, initLoading } = props;
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyTipsSuccess, setCopyTipsSuccess] = useState(false);
  const { t } = useTranslation("account_balance");

  useEffect(() => {
    if (copySuccess) {
      clearTimeout(tipsTimeout);
      tipsTimeout = setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }
  }, [copySuccess]);

  useEffect(() => {
    if (copyTipsSuccess) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setCopyTipsSuccess(false);
      }, 3000);
    }
  }, [copyTipsSuccess]);

  const {
    id = '',
    amount,
    currency = 'HKD',
    fee_amount: feeAmount = '',
    order_num: orderNum = '',
    oms_transaction_id: omsTransactionId = '',
    order = {},
    acquirer_rrn: acquirerRrn = '',
    created_at: createdAt = '',
    tipsTransaction = {},
    fee_currency: feeCurrency = 'HKD',
  } = record || {};

  const { reference_number: referenceNumber = '' } = order || {};

  const handleCopy = (copyValue: string, isTips = false) => {
    navigator.clipboard.writeText(copyValue).then(() => {
      if (isTips) {
        setCopyTipsSuccess(true);
      } else {
        setCopySuccess(true);
      }
    });
  };

  return (
    <div className="flex w-full space-x-6 mt-6">
      <Transition.Child
        className="flex-1"
        enter="duration-350 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-8 opacity-60"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div
          className={`w-full rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit`}
        >
          <div className="font-semibold text-lg text-color-text-1 flex items-center md:text-xl">
            <div className="font-bold text-xl">
              {t('account_balance:payment_details')}
            </div>
            <div className="w-44">
              {initLoading && (
                <div className={`overflow-hidden inline-block w-60`}>
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-4 rounded-2xl mb-0`}
                  />
                </div>
              )}
              {!initLoading && (
                <div className="text-color-text-2 font-medium pl-2.5 flex items-center">
                  {showType}
                </div>
              )}
            </div>
          </div>
          <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
              <DetailsItem
                title={t('account_balance:transaction_amount')}
                content={`${handleSymbolMount(amount)} ${currency}`}
                loading={initLoading}
              />
              <DetailsItem
                title={t('account_balance:transaction_time')}
                content={handleUtcDate(createdAt)}
                loading={initLoading}
              />
              <DetailsItem
                title={t('account_balance:reference_number')}
                content={referenceNumber}
                loading={initLoading}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
              <DetailsItem
                title={t('account_balance:fees_charged')}
                content={`-${handleMount(feeAmount)} ${feeCurrency}`}
                loading={initLoading}
              />
              <div className="w-full">
                <Label
                  disabled={true}
                  value={t('account_balance:transaction_id')}
                ></Label>
                {initLoading && (
                  <Skeleton
                    row={2}
                    width={['50%', '50%']}
                    skeletonItemClass={`h-4 rounded-2xl mb-4`}
                  />
                )}
                {!initLoading && (
                  <div className="py-2 text-color-text-1 font-medium pr-6 flex items-center space-x-2">
                    <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-color-text-1 text-sm font-medium">
                      {id}
                    </span>
                    <span
                      className="cursor-pointer group"
                      onClick={() => handleCopy(id)}
                    >
                      <CopyIcon className="text-primary-color hover:text-opacity-70" />
                    </span>
                    <span
                      className={`text-primary-color text-xs cursor-pointer ${
                        copySuccess ? 'visible' : 'invisible'
                      }`}
                    >
                      {t('account_balance:copied')}
                    </span>
                  </div>
                )}
              </div>
              <DetailsItem
                title={t('account_balance:invoice_number')}
                content={orderNum}
                loading={initLoading}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6">
              <DetailsItem
                title={t('account_balance:settled_amount')}
                content={settledAmount}
                loading={initLoading}
              />
              <DetailsItem
                title="BRN"
                content={omsTransactionId}
                loading={initLoading}
              />
              <DetailsItem
                title="RRN"
                content={acquirerRrn}
                loading={initLoading}
              />
            </div>
          </>
        </div>
        {tipsTransaction && tipsTransaction.id && (
          <div className="w-full rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 h-fit">
            <div className="text-color-text-1 flex items-center md:text-xl">
              <div className="text-xl font-bold">
                {t('account_balance:payment_details')}
              </div>
              <div className="w-44">
                {initLoading && (
                  <div className={`overflow-hidden inline-block w-60`}>
                    <Skeleton
                      row={1}
                      width={['100%']}
                      skeletonItemClass={`h-4 rounded-2xl mb-0`}
                    />
                  </div>
                )}
                {!initLoading && (
                  <div className="text-color-text-2 font-medium pl-2.5 flex items-center">
                    <span className="px-3 py-1 rounded-3xl text-xs text-[#EE8888] bg-[#EE8888] bg-opacity-10">
                      {t('account_balance:tips').toUpperCase()}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
              <DetailsItem
                title={t('account_balance:transaction_amount')}
                content={`${handleSymbolMount(tipsTransaction.amount)} ${
                  tipsTransaction.currency
                }`}
                loading={initLoading}
              />
              <DetailsItem
                title={t('account_balance:transaction_time')}
                content={handleUtcDate(tipsTransaction.created_at)}
                loading={initLoading}
              />
              <DetailsItem
                className="col-span-2"
                title={t('account_balance:reference_number')}
                content={referenceNumber}
                loading={initLoading}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
              <DetailsItem
                title={t('account_balance:fees_charged')}
                content={`${handleSymbolMount(
                  tipsTransaction.fee_amount,
                  false,
                  '-'
                )} ${tipsTransaction.fee_currency}`}
                loading={initLoading}
              />

              <div className="w-full">
                <Label
                  disabled={true}
                  value={t('account_balance:transaction_id')}
                ></Label>
                {initLoading && (
                  <Skeleton
                    row={2}
                    width={['50%', '50%']}
                    skeletonItemClass={`h-4 rounded-2xl mb-4`}
                  />
                )}
                {!initLoading && (
                  <div className="py-2 text-color-text-1 font-medium pr-6 flex items-center space-x-2">
                    <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-color-text-1 text-sm font-medium">
                      {tipsTransaction.id}
                    </span>
                    <span
                      className="cursor-pointer group"
                      onClick={() => handleCopy(tipsTransaction.id, true)}
                    >
                      <CopyIcon className="text-primary-color hover:text-opacity-70" />
                    </span>
                    <span
                      className={`text-primary-color text-xs cursor-pointer ${
                        copyTipsSuccess ? 'visible' : 'invisible'
                      }`}
                    >
                      {t('account_balance:copied')}
                    </span>
                  </div>
                )}
              </div>
              <DetailsItem
                className="col-span-2"
                title={t('account_balance:invoice_number')}
                content={orderNum}
                loading={initLoading}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6">
              <DetailsItem
                title={t('account_balance:settled_amount')}
                content={`${handleSymbolMount(
                  tipsTransaction.settlement_amount
                )} ${tipsTransaction.settlement_currency}`}
                loading={initLoading}
              />
              <DetailsItem
                title="BRN"
                content={tipsTransaction.oms_transaction_id}
                loading={initLoading}
              />
              <DetailsItem
                className="col-span-2"
                title="RRN"
                content={tipsTransaction.acquirer_rrn}
                loading={initLoading}
              />
            </div>
          </div>
        )}
      </Transition.Child>
    </div>
  );
};

export default React.memo(DefaultDetailsContent);
