import React from 'react';
import type { IIconProps } from '@/types/common-types';

const MastercardIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 36 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18" cy="18" r="18" fill="#F8FAFC" />
        <g clipPath="url(#clip0_8856_139134)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.6115 22.7646C17.4015 23.7846 15.8318 24.4004 14.1166 24.4004C10.2898 24.4004 7.1875 21.335 7.1875 17.5537C7.1875 13.7724 10.2898 10.707 14.1166 10.707C15.8318 10.707 17.4015 11.3228 18.6115 12.3428C19.8215 11.3228 21.3911 10.707 23.1063 10.707C26.9332 10.707 30.0354 13.7724 30.0354 17.5537C30.0354 21.335 26.9332 24.4004 23.1063 24.4004C21.3911 24.4004 19.8215 23.7846 18.6115 22.7646Z"
            fill="#ED0006"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.6104 22.7654C20.1006 21.5096 21.0457 19.6407 21.0457 17.5537C21.0457 15.4667 20.1006 13.5978 18.6104 12.342C19.8202 11.3225 21.3895 10.707 23.1042 10.707C26.931 10.707 30.0333 13.7724 30.0333 17.5537C30.0333 21.335 26.931 24.4004 23.1042 24.4004C21.3895 24.4004 19.8202 23.7849 18.6104 22.7654Z"
            fill="#F9A000"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.612 22.7694C20.1017 21.5136 21.0463 19.645 21.0463 17.5585C21.0463 15.472 20.1017 13.6035 18.612 12.3477C17.1223 13.6035 16.1777 15.472 16.1777 17.5585C16.1777 19.645 17.1223 21.5136 18.612 22.7694Z"
            fill="#FF5E00"
          />
        </g>
        <defs>
          <clipPath id="clip0_8856_139134">
            <rect
              width="22.7857"
              height="22.7857"
              fill="white"
              transform="translate(30.0361 29) rotate(-180)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default MastercardIcon;
