import { useEffect, useState } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { useSelector } from 'react-redux';
import {
  resolvingBusinessStatus,
} from '@/utils/business-utils';
import PersonalSpaceIcon from '../svg/personal-space-icon';
import SignOutIcon from '../svg/sign-out-icon';
import SiderBarIcon from '../svg/sider-bar-icon';
import BusinessIcon from '../svg/business-icon';
import UpIcon from '../svg/up-icon';
import DownIcon from '../svg/down-icon';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { cleanUpCookie, getAvatarsName, getCurrentI18nValue } from '@/utils';
import i18n from '@/i18n';
import { IBreadcrumbItem, IHeaderProps } from '@/types/header-type';
import { IReducersState } from '@/reducers';
import LanguageSelector from '@/i18n/language-selector';


const Header: React.FC<IHeaderProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const state = useSelector((state: IReducersState) => state.businessState);
  const { business, breadcrumbData } = state;
  const { businessID: oldQuery = '', p_business_id = '' } = useParams();
  const lang = i18n.language;
  const { sidebarOpen, setSidebarOpen, user } = props;

  const [showUserMenu] = useState(false);
  const [businessName, setBusinessName] = useState(
    business.business_name || ''
  );

  // const dispatch = useDispatch();
  const businessID = oldQuery || p_business_id;
  let status = 'active';

  if (business && business.id) {
    status = resolvingBusinessStatus(business, true);
  }

  useEffect(() => {
    if (business.id && business.business_name) {
      setBusinessName(getCurrentI18nValue(business, 'business_name', lang));
    }
    // eslint-disable-next-line
  }, [lang, business]);

  const handleGohome = () => {
    if (status === 'active') {
      navigate(`/${lang}/businesses/${businessID}/account-balance/overview`)
    }
  };

  const getBreadcrumb = () => {
    const items = [];
    items.push(
      <span key={'dashboard'} className="text-border-color">
        <span
          className={
            'text-color-text-2 hover:text-primary-color cursor-pointer'
          }
          onClick={handleGohome}
        >
          {t('common:dashboard')}
        </span>
        <span className="mx-2">/</span>
      </span>
    );

    breadcrumbData.forEach((item, index) =>
      items.push(createItem(item, index === breadcrumbData.length - 1))
    );

    return items;
  };

  const createItem = (item: IBreadcrumbItem, isLast: boolean) => {
    const { type, name, url = '', onClick, key } = item;

    if (type === 'link' && !isLast) {
      return (
        <span key={key}>
          <Link to={url} className='no-underline'>
            <span
              className={`text-color-text-2 truncate hover:text-primary-color ${
                status === 'active' ? 'cursor-pointer' : ''
              }`}
            >
              {name}
            </span>
          </Link>
          <span className="text-border-color mx-3">/</span>
        </span>
      );
    } else if (type === 'click' && !isLast) {
      return (
        <span key={key}>
          <span
            onClick={onClick}
            className="text-color-text-2 truncate hover:text-primary-color cursor-pointer"
          >
            {name}
          </span>
          <span className="text-border-color mx-3">/</span>
        </span>
      );
    } else {
      return (
        <span key={key}>
          <span
            className={`truncate ${
              isLast ? 'text-color-text-1' : 'text-color-text-2'
            }`}
          >
            {item.name}
          </span>
          {!isLast && <span className="text-border-color mx-3">/</span>}
        </span>
      );
    }
  };

  const handleSignOut = () => {
    cleanUpCookie();
    navigate('/account/signin');
  };

  const items: MenuProps['items'] = [
    {
      key: 'personal_space',
      label: (
        <Link to="/personal-space/business-overview">
          <div className="flex items-center cursor-pointer hover:bg-disabled-color py-4 px-6">
            <PersonalSpaceIcon className="mr-2 text-color-text-2" />
            <span className="text-color-text-2 text-sm">
              {t('common:personal_space')}
            </span>
          </div>
        </Link>
      ),
    },
    {
      key: 'sign_out',
      label: (
        <div onClick={handleSignOut} className="flex items-center text-error-color cursor-pointer hover:bg-disabled-color py-4 px-6">
          <SignOutIcon className="mr-2" />
          <span className="text-sm">{t('common:sign_out')}</span>
        </div>
      )
    },
  ];

  return (
    <div className="sticky top-0 bg-primary-background-color border-b border-border-color z-30">
      <div className="px-4 md:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          <div className={`flex-1 flex items-center pr-2`}>
            <SiderBarIcon
              className="text-color-text-2 mr-4 cursor-pointer hover:text-primary-color"
              onClick={() => {
                setSidebarOpen(!sidebarOpen);
              }}
            />
            <div
              className={`flex-1 items-center overflow-x-auto text-sm hidden md:visible md:flex hidden_scrollbar`}
            >
              {getBreadcrumb()}
            </div>
          </div>

          {/* Header: Right side */}
          <div className="flex items-center">
            {/* <div className="bg-disabled-color rounded-full flex items-center justify-center p-1.5 cursor-pointer hover:bg-border-color">
              <NotificationsIcon
                className="text-color-text-3"
              />
            </div> */}
            <LanguageSelector />
            <div className="border-l border-border-color h-6 mx-3"></div>
            {businessName && (
              <div className="mr-3 flex justify-center items-center bg-disabled-color rounded-2xl pr-3">
                <BusinessIcon />
                <span className="ml-2 text-color-text-1 text-sm font-medium truncate">
                  {businessName}
                </span>
              </div>
            )}
            <Dropdown
              trigger={['click']}
              menu={{items}}
            >
              <div className="flex items-center cursor-pointer group">
                {user?.avatarUrl && (
                  <img
                    className="rounded-full"
                    src={user?.avatarUrl}
                    width="24px"
                    height="24px"
                    loading="lazy"
                    alt=""
                  />
                )}
                {!user?.avatarUrl && (
                  <div className="truncate flex items-center">
                    <span className="inline-flex items-center justify-center h-7 w-7 bg-primary-color rounded-full">
                      <span className="font-medium text-color-text-6 text-xs leading-normal">
                        {getAvatarsName(user?.name)}
                      </span>
                    </span>
                    <div className="text-color-text-1 font-medium text-sm leading-snug ml-2 hidden lg:block group-hover:text-primary-color">
                      {user?.name}
                    </div>
                  </div>
                )}
                {showUserMenu ? (
                  <UpIcon className="text-icon-color ml-1 hidden lg:block group-hover:text-primary-color" />
                ) : (
                  <DownIcon className="text-icon-color ml-1 hidden lg:block group-hover:text-primary-color" />
                )}
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
