import type { ReactNode } from 'react';
import Label from '@/components/common/label';
import Skeleton from '@/components/skeleton';

export interface IDetailsItemProps {
  title: string;
  content: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  contentClass?: string;
}

const DetailsItem = (props: IDetailsItemProps) => {
  const {
    className = '',
    title = '',
    disabled = true,
    loading = false,
    contentClass = 'py-2 text-color-text-1 font-medium pr-6 text-sm',
  } = props;

  let { content } = props;

  if (content === '' || content === undefined || content === null) {
    content = '--';
  }

  return (
    <div className={`w-full ${className}`}>
      <Label disabled={disabled} value={title}></Label>

      {loading && (
        <div className={`overflow-hidden inline-block w-60`}>
          <Skeleton
            row={1}
            width={['100%']}
            skeletonItemClass={`h-4 rounded-2xl mb-0`}
          />
        </div>
      )}
      {!loading && <div className={`${contentClass}`}>{content}</div>}
    </div>
  );
};

export default DetailsItem;
