import React from 'react';
import type { IIconProps } from '@/types/common-types';

const MoreIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M12.8008 7.77143L2.93411 7.77143M12.8008 7.77143L8.57221 3.54286M12.8008 7.77143L8.57221 12"
          stroke="currentColor"
          strokeWidth="1.336"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default MoreIcon;
