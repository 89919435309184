import dayjs from 'dayjs';

export const getDays = (tag: 'Week' | 'Month' | 'Year') => {
  let arr: any = [];
  const today = dayjs();

  if (tag === 'Week') {
    let startOfWeek = today.startOf('week');
    const todayOfWeek = today.day();
    if (todayOfWeek === 0) {
      startOfWeek = today.subtract(6, 'day');
    } else {
      const dayOfWeek = startOfWeek.day();
      if (dayOfWeek === 0) {
        startOfWeek = startOfWeek.add(1, 'day');
      }
    }
    arr = [startOfWeek, today];
  } else if (tag === 'Month') {
    arr = [dayjs().startOf('month'), today];
  } else if (tag === 'Year') {
    arr = [dayjs().startOf('year'), today];
  }

  return arr;
};
