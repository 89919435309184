export const Constants = {
  TOKEN: '__t',
  MEtABASE_TOKEN: '__mt',
  MEtABASE_TOKEN_2: '__mt2',
  MEtABASE_TOKEN_3: '__mt3',
  ONBOARDING_TOKEN: '__jt',
  USER_REFERENCE: '__ur',
  DEFAULT_CODE: '__dc',
  BUSINESS_ID: '__bi',
  SEARCHKEY: '__sk',
  LANGUAGE: '__l',
  DELIVERY_SCHEDULE: '__ds',
  SIGN_URL: '__su',
};