import type { ISideBarProps, IMenuType } from '../../types/side-bar-type';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import SidebarLinkGroup from './side-bar-group';
import BackIcon from '@/components/svg/back-icon';
import CleanIcon from '@/components/svg/clean-icon';
import { handleRoutePath } from '@/utils/router-utils';
import { useTranslation } from 'react-i18next';
import { deleteCookie, getCookie, setCookie } from '@/utils';
import { Constants } from '@/constants';
import i18n from '@/i18n';

const SideBar: React.FC<ISideBarProps> = (props) => {
  const {
    sidebarOpen = false,
    setSidebarOpen,
    logoSvg = '',
    items = [],
  } = props;
  
  const query = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  // const paths = pathname.split('/');

  const lang = i18n.language;
  const businessID: string =
    (query.businessID as string) || (query.p_business_id as string);

  const trigger: any = useRef(null);
  const sidebar: any = useRef(null);

  let storedSidebarExpanded = null;

  if (typeof window !== 'undefined') {
    storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  }

  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const node = document.querySelector('body');
      localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());

      if (node) {
        if (sidebarExpanded) {
          node.classList.add('sidebar-expanded');
        } else {
          node.classList.remove('sidebar-expanded');
        }
      }
    }
  }, [sidebarExpanded]);

  useEffect(() => {
    const initValue = getCookie(Constants.SEARCHKEY) || '';
    if (initValue) {
      setInputValue(initValue);
    }
  }, []);

  const selectAppModule = (item: IMenuType): boolean => {
    const { moduleKey = '', path = '' } = item;
    let checked = false;

    if (
      (path && pathname.indexOf(`${businessID}/${path}`) > -1) ||
      (moduleKey && pathname.indexOf(`${businessID}/${moduleKey}`) > -1)
    ) {
      checked = true; // 匹配到子项
    }

    return pathname === '/' || checked;
  };

  const appAndModuleTitle = (title: string): string | undefined => {
    let titleRes = undefined;
    if (title.length > 18) {
      titleRes = title;
    }
    return titleRes;
  };

  /**
   * 根据Tree中对象属性key，找到对应的节点/返回新data/带父node
   * @param {Array} list [{}, {}]
   * @param {String} key 对象属性key
   * @param {Any} value 对象属性key的值
   */

  const menuDataSearch = (list: IMenuType[] = [], value: any) => {
    const newarr: IMenuType[] = [];
    list.forEach((element) => {
      if (element['children'] && element['children'].length) {
        const ab = menuDataSearch(element['children'], value);
        const obj = {
          ...element,
          children: ab,
        };
        if (ab && ab.length) {
          newarr.push(obj);
        }
      } else {
        // if (element[key].indexOf(value) > -1)
        if (
          t(`common:${element['localeKey']}`)
            .toLocaleUpperCase()
            .indexOf(value.toLocaleUpperCase()) > -1
        ) {
          newarr.push(element);
        }
      }
    });
    return newarr;
  };

  const handleHightlight = (localeKey: string): string => {
    const title = t(`common:${localeKey}`);
    let returnStr = title;
    if (title && inputValue) {
      const index = title
        .toLocaleLowerCase()
        .indexOf(inputValue.toLocaleLowerCase());
      const hightStr = title.slice(index, index + inputValue.length);
      returnStr = title.replace(
        hightStr,
        `<span style="color: #10B981">${hightStr}</span>`
      );
    }
    return returnStr;
  };

  const handleSetInputValue = (value: string) => {
    setInputValue(value);
    if (value) {
      setCookie(Constants.SEARCHKEY, value);
    } else {
      deleteCookie(Constants.SEARCHKEY);
    }
  };

  const convertItemsToNodes = (list: IMenuType[]) => {
    const newList = menuDataSearch(list, inputValue);
    const node: any[] = [];
    newList.forEach((opt, index) => {
      if (opt && typeof opt === 'object') {
        const {
          key,
          title,
          icon = '',
          children = [],
          path,
          localeKey = '',
        } = opt;
        let checkChildren = false;

        if (children.length > 0) {
          for (let i = 0; i < children.length; i++) {
            const key = children[i].path;
            const moduleKey = children[i].moduleKey || '';
            if (
              (key && pathname.indexOf(`${businessID}/${key}`) > -1) ||
              (moduleKey && pathname.indexOf(`${businessID}/${moduleKey}`) > -1)
            ) {
              checkChildren = true; // 匹配到子项
              break;
            }
          }
        }

        // 展开的app
        const activeApp = (path && pathname.includes(`${businessID}/${key}`)) || checkChildren;

        node.push(
          <SidebarLinkGroup
            key={key}
            activecondition={activeApp}
            inputValue={inputValue}
          >
            {(handleClick, open) => {
              return (
                <React.Fragment>
                  <div
                    className={`block text-color-text-5 hover:text-color-text-6 py-3 truncate transition duration-150 ${
                      activeApp && 'hover:text-white'
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      sidebarExpanded
                        ? handleClick()
                        : setSidebarExpanded(true);
                    }}
                  >
                    <div className="flex items-center justify-between cursor-pointer">
                      <div className="flex items-center">
                        <div
                          className={`h-6 ${
                            activeApp
                              ? 'text-primary-color'
                              : 'text-color-text-5'
                          }`}
                          dangerouslySetInnerHTML={{ __html: icon }}
                        />
                        <span
                          className="text-base font-medium ml-2 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          title={appAndModuleTitle(title)}
                        >
                          {t(`common:${localeKey}`)}
                        </span>
                      </div>
                      {/* Icon */}
                      <div className="flex shrink-0 ml-2">
                        <svg
                          className={`w-2.5 h-2.5 shrink-0 ml-1 text-color-text-4 fill-current ${
                            open && 'transform rotate-180'
                          }`}
                          viewBox="0 0 12 12"
                        >
                          <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  {/* children */}
                  <div className="lg:sidebar-expanded:block 2xl:block">
                    <ul className={`pl-8 my-0 transition-all ease-in overflow-hidden list-none`}
                      style={{maxHeight: open ? `${children.length*40}px` : '0px'}}
                    >
                      {children.map((item, index) => (
                        <li
                          key={`${item.title}_${index}`}
                          className="py-2.5"
                          style={{listStyle: 'none'}}
                        >
                          <Link to={`/${lang}${handleRoutePath(item, businessID)}`} className="no-underline">
                            <div
                              className={`block text-color-text-4 truncate ${selectAppModule(item) ? 'text-primary-color hover:text-primary-color' : 'hover:text-color-text-5'}`}
                            >
                              <span
                                className="text-sm font-medium lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                                title={appAndModuleTitle(item.title)}
                                dangerouslySetInnerHTML={{
                                  __html: handleHightlight(item.localeKey),
                                }}
                              >
                              </span>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </React.Fragment>
              );
            }}
          </SidebarLinkGroup>
        );
      }
    });

    return node;
  };

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-select-secondary-background-color bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar hidden_scrollbar lg:w-60 lg:sidebar-expanded:!w-64 shrink-0 transition-all duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-72'}`}
      >
        <div className="bg-secondary-background-color min-h-screen h-fit">
          {/* Sidebar header */}
          <div className="flex justify-between mb-4 mt-2.5 lg:justify-center">
            {/* Logo */}
            <div dangerouslySetInnerHTML={{ __html: logoSvg }} />
          </div>
          <div
            // className="group flex items-center p-2 mb-4 rounded w-[190px] border-color-text-5 mx-4 border-opacity-20 border-solid border hover:border-opacity-50 bg-color-text-5 bg-opacity-10"
            className="group flex items-center p-2 mb-4 rounded border-color-text-5 w-52 mx-4 border-opacity-20 border hover:border-opacity-50 bg-color-text-5 bg-opacity-10"
          >
            <div className="mr-1.5 flex">
              <svg
                className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.25 12.5C4.355 12.5 2 10.145 2 7.25C2 4.355 4.355 2 7.25 2C10.145 2 12.5 4.355 12.5 7.25C12.5 10.145 10.145 12.5 7.25 12.5ZM7.25003 3.5C5.18228 3.5 3.50003 5.18225 3.50003 7.25C3.50003 9.31775 5.18228 11 7.25003 11C9.31778 11 11 9.31775 11 7.25C11 5.18225 9.31778 3.5 7.25003 3.5ZM11.9858 10.9238L13.7806 12.7186C14.0738 13.0118 14.0738 13.4858 13.7806 13.7791C13.6343 13.9253 13.4423 13.9988 13.2503 13.9988C13.0583 13.9988 12.8663 13.9253 12.7201 13.7791L10.9253 11.9843C11.3221 11.6761 11.6776 11.3206 11.9858 10.9238Z"
                  fill="#94A3B8"
                />
              </svg>
            </div>
            <input
              id="msg-search"
              className="w-full text-xs bg-transparent text-color-text-5 focus:outline-none placeholder:text-color-text-3 p-0 m-0 border-0"
              type="text"
              autoComplete="none"
              value={inputValue}
              placeholder={t('common:search')}
              onChange={(evt) => {
                // setInputValue(evt.target.value);
                handleSetInputValue(evt.target.value);
              }}
            />
            <span
              className={`
                ${inputValue ? 'visible' : 'invisible'}
              `}
            >
              <CleanIcon
                className="text-icon-color hover:text-color-text-5 flex"
                onClick={() => {
                  handleSetInputValue('');
                }}
                size={16}
              />
            </span>
          </div>
          {/* Links */}
          <div>
            {/* Pages group */}
            <div>{convertItemsToNodes(items)}</div>
          </div>
        </div>
        {/* Close button */}
        <div
          ref={trigger}
          className="lg:hidden text-color-text-4 ml-4 mt-10 hover:text-color-text-5"
          onClick={() => {
            setSidebarOpen && setSidebarOpen(!sidebarOpen);
          }}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
        >
          <span className="sr-only">Close sidebar</span>
          <div className="bg-disabled-color shadow-box-drop rounded-full w-10 h-10 flex items-center justify-center cursor-pointer">
            <BackIcon
              size={24}
              className="text-color-text-1 hover:text-primary-color"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SideBar;
