import React from 'react';
import type { IIconProps } from '@/types/common-types';

const FPSPhoneIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 24 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="4.46"
          y="0.96"
          width="14.26"
          height="22.08"
          rx="1.84"
          stroke="black"
          strokeWidth="0.92"
        />
        <path
          d="M14.3455 11.2638L7.84975 17.7594C7.58654 18.0226 7.58654 18.4492 7.84975 18.7124C7.98136 18.8439 8.15368 18.9097 8.32617 18.9097C8.49866 18.9097 8.67116 18.8439 8.80259 18.7124L15.2984 12.2167C15.5616 11.9536 15.5616 11.5269 15.2984 11.2638C15.0353 11.0006 14.6087 11.0006 14.3455 11.2638Z"
          fill="#0094FF"
        />
        <path
          d="M14.3455 6.20898L7.84975 12.7047C7.58654 12.968 7.58654 13.3945 7.84975 13.6578C7.98119 13.7892 8.15368 13.855 8.32617 13.855C8.49849 13.855 8.67098 13.7892 8.80259 13.6578L15.2984 7.16199C15.5616 6.89895 15.5616 6.47219 15.2984 6.20915C15.0353 5.94594 14.6087 5.94594 14.3455 6.20898Z"
          fill="#0094FF"
        />
        <path
          d="M9.51953 0.5H13.6595V1.88C13.6595 2.13405 13.4536 2.34 13.1995 2.34H9.97953C9.72548 2.34 9.51953 2.13405 9.51953 1.88V0.5Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default FPSPhoneIcon;
