import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ZhHKIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className} cursor-pointer`} onClick={onClick}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3794_136787)">
          <path
            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
            fill="#D80027"
          />
          <path
            d="M11.0306 7.56205C10.8034 8.50733 10.3996 8.32705 10.2014 9.15143C9.03412 8.87077 8.31522 7.69694 8.59588 6.52955C8.87643 5.36225 10.0504 4.64334 11.2177 4.92401C10.8213 6.57272 11.234 6.71662 11.0306 7.56205Z"
            fill="#F0F0F0"
          />
          <path
            d="M8.00264 8.26258C8.83143 8.77078 8.53518 9.09918 9.25792 9.54242C8.63026 10.5659 7.29178 10.8868 6.26827 10.2592C5.24479 9.63156 4.92382 8.29301 5.55147 7.26953C6.99702 8.15594 7.26128 7.80801 8.00264 8.26258Z"
            fill="#F0F0F0"
          />
          <path
            d="M7.73305 11.3609C8.47254 10.7297 8.6932 11.1129 9.33809 10.5625C10.1176 11.4757 10.0091 12.8479 9.09602 13.6274C8.18281 14.4068 6.81062 14.2983 6.03125 13.3852C7.32086 12.2843 7.0716 11.9254 7.73305 11.3609Z"
            fill="#F0F0F0"
          />
          <path
            d="M10.5954 12.5718C10.2236 11.6735 10.6562 11.5819 10.332 10.7986C11.4414 10.3395 12.7129 10.8666 13.1721 11.9759C13.6312 13.0853 13.104 14.3568 11.9946 14.8159C11.3463 13.2492 10.928 13.3753 10.5954 12.5718Z"
            fill="#F0F0F0"
          />
          <path
            d="M12.634 10.2274C11.6647 10.3035 11.7113 9.86366 10.8661 9.92998C10.7723 8.73315 11.6666 7.68662 12.8635 7.59268C14.0604 7.49897 15.1068 8.39319 15.2007 9.59014C13.5102 9.72268 13.5008 10.1595 12.634 10.2274Z"
            fill="#F0F0F0"
          />
        </g>
        <defs>
          <clipPath id="clip0_3794_136787">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ZhHKIcon;
