import { combineReducers, configureStore } from '@reduxjs/toolkit';
import reducers from './reducers';
import sagas from './sagas';
import createSagaMiddleware from 'redux-saga';

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: combineReducers(reducers),
    middleware: [sagaMiddleware],
  });

  sagaMiddleware.run(sagas);

  return store;
}

export default createStore;
