import { useMemo, useState } from 'react';
import DownIcon from '@/components/svg/down-icon';
import UpIcon from '@/components/svg/up-icon';
import { IPayee } from '@/types/payouts-type';
import { useTranslation } from 'react-i18next';
import PayeeItem from '../payee-item';
import { getAvatarsName } from '@/utils';
import { getCountryByCurrency, getIdentifierValue, getTransferMethod } from '@/utils/payouts-utils';
import Config from '@/libs/config';
import { useParams } from 'react-router-dom';

interface IPayoutsChoosePayee {
  payee: IPayee;
  className?: string;
  showAvatar?: boolean;
  gridColes?: string;
  nickname?: boolean;
  fold?: boolean;
  bgShow?: boolean;
}

const PayeeDetails: React.FC<IPayoutsChoosePayee> = (props) => {
  const {
    payee,
    className = '',
    showAvatar = true,
    gridColes = 'grid-cols-2',
    nickname = true,
    fold = false,
    bgShow = true,
  } = props;
  const { t } = useTranslation('payouts');
  const { lang = 'en' } = useParams();
  const [show, setShow] = useState(!fold);
  const { payout_accounts, i18n } = payee;
  const {
    transfer_method,
    transfer_methods,
    country_codes,
    business_name,
    business_legal_name,
    currency,
  } = payout_accounts;
  const { alpha_2, country_name } = country_codes;
  const { identifier_1_name, identifier_2_name, identifier_3_name } =
    transfer_methods;

  const prefix = Config.staticPath || '';

  const getTitle = (value: string) => {
    if (i18n && i18n[value]) {
      if (i18n[value][lang]) {
        return i18n[value][lang];
      }
    }
    return value;
  };

  const getType = (type: string) => {
    let color = '';
    let value = '';
    if (type === 'Business') {
      color = 'text-color-text-7 bg-color-text-7';
      value = t('business');
    } else if (type === 'People') {
      color = 'text-primary-color bg-primary-color';
      value = t('people');
    }
    if (!bgShow) {
      return (
        <div className="text-sm py-2.5 text-color-text-1 font-medium pr-6">
          {value}
        </div>
      );
    }
    return (
      <div className="flex py-2">
        <div
          className={`uppercase py-1 text-xs px-3 rounded-3xl ${color} bg-opacity-10 font-medium`}
        >
          {value}
        </div>
      </div>
    );
  };

  const transferMethodDetail = useMemo(() => {
    if (transfer_method === 'Platform Transfer') {
      return (
        <>
          {identifier_1_name && (
            <PayeeItem
              title={getTitle(identifier_1_name)}
              content={getIdentifierValue('identifier_1_type', payee)}
            />
          )}
          <PayeeItem
            title={t('business_legal_name')}
            content={business_legal_name || business_name}
          />
          <PayeeItem
            title={t('business_account_name')}
            content={business_name || business_legal_name}
          />
        </>
      );
    }
    if (transfer_method.indexOf('Alipay') !== -1) {
      return (
        <>
          {identifier_3_name && (
            <PayeeItem
              title={getTitle(identifier_3_name)}
              content={getIdentifierValue('identifier_3_type', payee)}
            />
          )}
        </>
      );
    }

    return (
      <>
        {identifier_1_name && (
          <PayeeItem
            title={getTitle(identifier_1_name)}
            content={getIdentifierValue('identifier_1_type', payee)}
          />
        )}
        {identifier_2_name && (
          <PayeeItem
            title={getTitle(identifier_2_name)}
            content={getIdentifierValue('identifier_2_type', payee)}
          />
        )}
        {identifier_3_name && (
          <PayeeItem
            title={getTitle(identifier_3_name)}
            content={getIdentifierValue('identifier_3_type', payee)}
          />
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <div className={className}>
      {showAvatar && (
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className="truncate">
              <span className="inline-flex items-center justify-center h-14 w-14 bg-primary-color/10 rounded-full">
                <span className="font-bold text-color-text-1 text-xl leading-normal">
                  {getAvatarsName(payee.nickname)}
                </span>
              </span>
            </div>
            <div className="ml-3">
              <div className="font-medium text-color-text-2 text-sm">
                {t('paying_to')}
              </div>
              <div className="font-semibold text-lg text-color-text-1 pt-1">
                {payee.nickname}
              </div>
            </div>
          </div>
          {fold && (
            <div
              className="flex items-center text-primary-color cursor-pointer"
              onClick={() => setShow(!show)}
            >
              <div className="mr-0.5">
                {show ? t('view_less') : t('view_more')}
              </div>
              {show ? <UpIcon size={16} /> : <DownIcon size={16} />}
            </div>
          )}
        </div>
      )}
      <div
        className="transition-all ease-in duration-300 overflow-hidden"
        style={{ maxHeight: show ? '400px' : '0px' }}
      >
        {showAvatar && (
          <div className="border-b border-border-color pt-4"></div>
        )}
        <div className="grid gap-y-4 pt-4">
          <div className={`grid gap-4 ${gridColes}`}>
            <PayeeItem
              title={
                nickname ? t('nickname') : t('payee_nickname')
              }
              content={payee.nickname}
            />
            <PayeeItem
              contentClass="flex"
              title={t('payee_type')}
              content={getType(payee.type)}
            />
            <PayeeItem
              title={t('destination_country')}
              content={
                <div className="flex">
                  <span className="flex items-center relative w-4 mr-1.5">
                    <img
                      width="16px"
                      height="16px"
                      src={`${prefix}/images/country-flag/${
                        alpha_2 ? alpha_2.toLocaleLowerCase() : 'hk'
                      }.svg`}
                      alt=""
                    />
                  </span>
                  <span className="text-color-text-1">{country_name}</span>
                </div>
              }
            />
            <PayeeItem
              title={t('destination_currency')}
              content={
                <div className="flex">
                  <span className="flex items-center relative w-4 mr-1.5">
                    <img
                      width="16px"
                      height="16px"
                      src={`${prefix}/images/country-flag/${getCountryByCurrency(
                        currency
                      )}.svg`}
                      alt=""
                    />
                  </span>
                  <span className="text-color-text-1">{currency}</span>
                </div>
              }
            />
            <PayeeItem
              title={t('transfer_method')}
              content={getTransferMethod(transfer_method)}
              contentClass="text-sm py-2 text-color-text-1 font-medium pr-6"
            />
            {transferMethodDetail}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayeeDetails;
