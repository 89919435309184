import type {  ILedgerAccount } from '@/types/account-balance-type';
import type { IObject } from '@/types/common-types';
import AccountBalanceOverview from './account-balance-page/account-balance-overview/account-balance-overview';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import RefreshIcon from '@/pages/account-balance/account-balances-icons/refresh-icon';
import {
  getBusinessAccount,
  getCurrentlyProcessing,
  getMerchantAccount,
} from '@/data/account-balance';
import { useTranslation } from 'react-i18next';
import { newgetAvailableCurrencies } from '../../data/global-account';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';
import ActionType from '@/actions/action-type';

const AccountBalanceOverviewPage = () => {
  const { t } = useTranslation("account_balance");
  const { lang = 'en', type: activekey = '', p_business_id: businessID = '' } = useParams();
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const [currentDate, setCurrentdate] = useState<dayjs.Dayjs>();
  const [refreshPage, setRefreshPage] = useState<boolean>(false);
  const [processRecords, setProcessRecords] = useState<IObject[] | undefined>(
    undefined
  );
  const [initLoading, setInitLoading] = useState<boolean>(false);
  const [ledgerAccount, setLedgerAccount] = useState<ILedgerAccount | IObject>(
    {}
  );
  const [merchantAccountData, setMerchantAccountData] = useState<IObject>({});

  useEffect(() => {
    getProcessRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activekey, merchantAccountData, refreshPage]);

  useEffect(() => {
    refreshDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  useEffect(() => {
    getInitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage, lang]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('common:company_account'),
          key: '1',
          type: 'label',
        },
        {
          name: t('account_balance:account_balance'),
          key: '2',
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  const { processingRecord } = merchantAccountData || {};

  const getProcessRecords = async () => {
    if (processingRecord && processingRecord.id) {
      const data = await getCurrentlyProcessing({
        pBusinessId: businessID,
        limit: 30,
        offset: 0,
      });

      if (Array.isArray(data)) {
        setProcessRecords(data);
      }
    }
  };

  const getInitData = async () => {
    if (businessID) {
      const allPromise: any[] = [];

      let promiseIndex = -1;
      let ledgerAccountIndex = -1;
      let merchantAccountIndex = -1;

      ledgerAccountIndex = ++promiseIndex;
      allPromise.push(getBusinessAccount(businessID, lang));

      merchantAccountIndex = ++promiseIndex;
      allPromise.push(getMerchantAccount(businessID));

      setInitLoading(true);
      const resultData = await Promise.all(allPromise);
      setInitLoading(false);

      if (resultData && resultData.length > 0) {
        if (ledgerAccountIndex > -1 && resultData[ledgerAccountIndex]) {
          const data = resultData[ledgerAccountIndex] || {};
          const { ledger_accounts = [] } = data;

          if (Array.isArray(ledger_accounts) && ledger_accounts.length < 5) {
            const currentLength = ledger_accounts.length;
            const result = await newgetAvailableCurrencies(businessID, lang);
            const newData: IObject[] = [];
            if (Array.isArray(result)) {
              const existsCode: string[] = ledger_accounts.map(
                (l: any) => l.currency?.currency_code
              );

              let addIndex = 0;
              for (let i = 0; i < result.length; i++) {
                if (addIndex === 5 - currentLength) {
                  break;
                }
                const item = result[i];
                const {
                  currency_code = '',
                  currency_symbol = '',
                  display_name = '',
                  currency_name = '',
                } = item;
                if (!existsCode.includes(currency_code)) {
                  newData.push({
                    ...item,
                    currency: {
                      currency_code,
                      currency_name: display_name || currency_name,
                      currency_symbol,
                    },
                  });
                  addIndex++;
                }
              }
            }
            data.ledger_accounts = ledger_accounts.concat(newData);
          }
          setLedgerAccount(data);
        }

        if (merchantAccountIndex > -1 && resultData[merchantAccountIndex]) {
          const data = resultData[merchantAccountIndex] || {};
          setMerchantAccountData(data);
        }
      }
    }
  };

  const refreshDate = () => {
    const currentDate = dayjs();
    setCurrentdate(currentDate);
  };

  const tabs = [
    t('account_balance:overview'),
    t('account_balance:merchant_account'),
    t('account_balance:current_account'),
  ];

  const handleItemClick = (index: number) => {
    if (index !== 0) {
      let routeStr = '';
      switch (index) {
        case 1:
          routeStr = `/${lang}/businesses/${businessID}/account-balance/merchant-account`;
          break;
        case 2:
          routeStr = `/${lang}/businesses/${businessID}/account-balance/current-account`;
          break;

        default:
          break;
      }

      if (routeStr) {
        navigate(routeStr);
      }
    }
  };

  return (
    <div className="p-4 md:p-6 lg:px-8 lg:py-6">
      <div className="flex mb-4 flex-col mb:mb-6">
        <div className="flex items-center space-x-2 justify-between">
          <div className="text-3xl leading-10 font-bold text-color-text-1 ml-2.5">
            {t('account_balance:account_balance')}
          </div>
          <div className="flex items-center space-x-2">
            <div className="text-sm text-primary-color">
              {t('account_balance:last_updated_at', {
                data: currentDate?.format('HH:mm'),
              })}
            </div>
            <RefreshIcon
              size={20}
              className={`text-primary-color shrink-0 ml-1 fill-current hover:text-primary-color/70 cursor-pointer`}
              onClick={() => setRefreshPage((refreshPage) => !refreshPage)}
            />
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <div
          className={`flex space-x-4 h-fit overflow-x-auto hidden_scrollbar`}
          aria-label="line-tabs"
        >
          {tabs.map((item, index) => (
            <div
              key={index}
              className={`
                  py-2 px-4 rounded-3xl text-sm whitespace-nowrap font-medium
                  ${index === 0
                  ? 'bg-primary-color text-color-text-6'
                  : 'bg-color-text-6 text-color-text-2 cursor-pointer hover:text-primary-color border-border-color border'
                }  
                `}
              onClick={() => handleItemClick(index)}
            >
              {item}
            </div>
          ))}
        </div>
        <AccountBalanceOverview
          storeLedgerAccount={ledgerAccount}
          currentDate={currentDate}
          processInitData={processRecords}
          merchantAccountData={merchantAccountData}
          initLoading={initLoading}
          refreshPage={refreshPage}
          activekey={'overview'} 
          businessID={businessID} 
          ledgerAccount={ledgerAccount} 
          lederEnteryData={Object} 
          upComingData={Object} />
      </div>
    </div>
  );
};

export default AccountBalanceOverviewPage;
