import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';
import WarningIcon from '@/components/svg/warning-side-icon';
import ErrorIcon from '@/components/svg/failed-icon';
import AntdModal from '@/components/common/antd/modal';

interface RateUpdateCountdownModalProps {
  interval: number;
  onCancel: () => void;
  modalType?: string;
}

const RateUpdateCountdownModal: React.FC<RateUpdateCountdownModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const { interval, onCancel, modalType } = props;
  return (
    <AntdModal
      open={true}
      footer={null}
      onCancel={onCancel}
      closeIcon={true}
      className="!w-[456px]"
    >
      {modalType === '1' && (
        <div className="p-10 flex flex-col items-center">
          <div className="bg-warning-color/10 p-[18px] rounded-full">
            <WarningIcon size={36} className="text-warning-color" />
          </div>
          <div className="text-color-text-1 font-bold text-xl mt-6 whitespace-pre-line break-all text-center ">
            {t('global_accounts:rate_update_countdown')}
          </div>
          <div className="text-color-text-2 text-sm font-medium text-center mt-2">
            {t('global_accounts:rate_update_countdown_tips', { interval })}
          </div>
          <Button
            type="primary"
            className="bg-primary-color text-sm w-full mt-6 h-9 font-medium"
            onClick={onCancel}
          >
            {t('common:back')}
          </Button>
        </div>
      )}

      {modalType === '2' && (
        <div className="p-10 flex flex-col items-center">
          {/* <div className="bg-error-color/10 p-[18px] rounded-full"> */}
          <ErrorIcon size={80} className="text-error-color p-[18px]" />
          {/* </div> */}
          <div className="error-color font-bold text-xl mt-6 whitespace-pre-line text-center">
            {t('global_accounts:error_countdown')}
          </div>
          <div className="text-color-text-1 text-sm font-medium text-center mt-2">
            {t('global_accounts:error_tips', { interval })}
          </div>
          <Button
            type="primary"
            className="bg-primary-color text-sm w-full mt-6 h-9 font-medium"
            onClick={onCancel}
          >
            {t('common:back')}
          </Button>
        </div>
      )}
    </AntdModal>
  );
};

export default React.memo(RateUpdateCountdownModal);
