enum ActionType {
  /** Account */
  QUERY_ME = 'QUERY_ME',
  SET_LOGIN_USER = 'SET_LOGIN_USER',

  GOTO_PERSONAL_SPACE = 'GOTO_PERSONAL_SPACE',
  GOTO_HOME_PAGE = 'GOTO_HOME_PAGE',
  LOADING_MAIN_DATA = 'LOADING_MAIN_DATA',
  
  /** Business */
  QUERY_BUSINESS = 'QUERY_BUSINESS',
  SET_BUSINESS = 'SET_BUSINESS',
  SET_BREADCRUMB = 'SET_BREADCRUMB',

  SET_PERMISSIONS = 'SET_PERMISSIONS',
  SET_MENUS = 'SET_MENUS',
}

export default ActionType;
