import type { ICardDetailsProps } from '../type';
import type { IObject } from '@/types/common-types';
import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { getPayoutTranferStatusLabel } from '@/utils/account-balance-untils';
import { getPayOutDetails } from '@/data/account-balance';
import { TransferMethodType } from '@/types/account-balance-type';
import PayoutDefaultCategoryDetails from './payout-details-category/default-category';
import Skeleton from '@/components/skeleton';
import ScrollNum from '@/components/common/scroll-number';
import PayoutPlatformTransferPayeeContent from './payout-detail-payee/payee-platform-transfer';
import PayoutFPSTransferPayeeContent from './payout-detail-payee/payee-fps-transfer';
import PayoutSwiftTransferPayeeContent from './payout-detail-payee/payee-swift-transfer';
import PayoutRtgsTransferPayeeContent from './payout-detail-payee/payee-rtgs-transfer';
import { useTranslation } from 'react-i18next';
import AlipayTransferPayeeContent from './payout-detail-payee/payee-alipay-transfer';
import { useNavigate, useParams } from 'react-router-dom';
import TipsIcon from '@/components/svg/tips-icon';

const PayOutDetailsPage = (props: ICardDetailsProps) => {
  const { t } = useTranslation("account_balance");
  const navigate = useNavigate();
  const { lang = 'en' } = useParams();
  const [record, setRecord] = useState<IObject>({});
  const [initLoading, setInitLoaing] = useState(false);
  const { businessId, recordId } = props;

  const {
    total_amount,
    payee = {},
    currency = 'HKD',
    payout_category_icon_url: iconUrl = '',
    payout_category_icon_file_url: iconFileUrl = '',
    payout_category_color: iconColor = '',
    transfer_method: transferMethod = '',
    transfer_status: transferStatus = '',
    refund_payin_id: refundPayinID = '',
    destination_currency: destinationCurrency = 'HKD',
  } = record || {};


  const { nickname } = payee || {};

  useEffect(() => {
    getReacord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, recordId]);

  const getReacord = async () => {
    setInitLoaing(true);
    const data = await getPayOutDetails({
      pBusinessId: businessId,
      recordId,
    });
    if (data) {
      setRecord(data);
      setInitLoaing(false);
    }
  };

  const handleAmountStyle = () => {
    let style = 'text-color-text-1';
    if (
      ['declined', 'reversed', 'terminated', 'rejected'].includes(
        getPayoutTranferStatusLabel(record).status
      )
    ) {
      style = 'line-through text-color-text-2';
    }
    return style;
  };

  // const handleBusniessIdCopy = () => {
  //   navigator.clipboard.writeText(identifier1Value).then(() => {
  //     setCopyBusinessIdSuccess(true);
  //   });
  // };

  return (
    <Transition.Root show={true} appear={true}>
      <Transition.Child
        className="rounded flex overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 h-fit"
        enter="duration-700 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-10 opacity-0"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="items-center lg:flex">
          <div>
            <div className="flex flex-col md:flex-row whitespace-nowrap">
              <div
                className={`w-12 h-12 flex justify-center items-center rounded-full`}
                style={{ backgroundColor: iconColor }}
              >
                <div
                  style={{
                    backgroundImage: `url(${iconFileUrl || iconUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '26px',
                    height: '26px',
                  }}
                />
              </div>
            </div>
          </div>
          {initLoading && (
            <Skeleton
              row={2}
              width={['50%', '50%']}
              skeletonItemClass={`h-4 rounded-2xl mb-4`}
            />
          )}
          {!initLoading && (
            <div className="flex flex-col ml-4">
              <div className="flex items-center space-x-1 text-3xl font-bold">
                <span className={`${handleAmountStyle()}`}>
                  <ScrollNum
                    value={`-${Math.abs(total_amount)}`}
                    size={36}
                    boxClass={'h-9'}
                  />
                </span>
                <span className="text-color-text-1 font-medium">{`${currency}`}</span>
              </div>
              {/* <div className="flex space-x-1 items-center"> */}
              {/* {fx_quote_id && (
                  <span className="text-color-text-2 text-sm">{`${handleSymbolMount(
                    destinationAmount,
                    false,
                    '-'
                  )} ${destinationCurrency}`}</span>
                )} */}
              <span className="text-color-text-2 text-sm mt-2 font-medium">
                {t('account_balance:billing_amount')}
              </span>
              {/* </div> */}
            </div>
          )}
        </div>
        {nickname && (
          <>
            <div className="h-auto border-r mx-10 border-border-color" />
            <div className="flex flex-col justify-between w-64">
              <span className="font-medium text-color-text-1">{nickname}</span>
              <span className="text-color-text-2 text-sm mt-2 font-medium">
                {t('account_balance:payee')}
              </span>
            </div>
          </>
        )}
        {transferStatus === 'Refunded' && refundPayinID && (
          <>
            <div className="h-auto border-r mx-10 border-border-color" />
            <div className="flex items-center">
              <TipsIcon size={16} className="text-primary-color mr-2" />
              <div className="text-sm text-color-text-1 max-w-[185px] font-medium cursor-default">
                {t('account_balance:payouts_refunded', {
                  currency: destinationCurrency,
                })}
                <br />
                <label
                  className="font-extrabold text-primary-color cursor-pointer"
                  onClick={() => {
                    navigate(
                      `/${lang}/businesses/${businessId}/account-balance/transactions/payin/${refundPayinID}`
                    );
                  }}
                >
                  {t('account_balance:click_here')}
                </label>
                {t('account_balance:to_view')}
              </div>
            </div>
          </>
        )}
      </Transition.Child>
      <PayoutDefaultCategoryDetails record={record} initLoading={initLoading} />
      {TransferMethodType.PlatformTransfer === transferMethod && (
        <PayoutPlatformTransferPayeeContent
          businessId={businessId}
          record={record}
          initLoading={initLoading}
        />
      )}
      {(TransferMethodType.FPSBankAccount === transferMethod ||
        TransferMethodType.FPSEmail === transferMethod ||
        TransferMethodType.FPSMobile === transferMethod ||
        TransferMethodType.FPSId === transferMethod) && (
          <PayoutFPSTransferPayeeContent
            businessId={businessId}
            record={record}
            initLoading={initLoading}
          />
        )}
      {TransferMethodType.SWIFT === transferMethod && (
        <PayoutSwiftTransferPayeeContent
          businessId={businessId}
          record={record}
          initLoading={initLoading}
        />
      )}
      {TransferMethodType.RTGS === transferMethod && (
        <PayoutRtgsTransferPayeeContent
          businessId={businessId}
          record={record}
          initLoading={initLoading}
        />
      )}
      {(TransferMethodType.AlipayEmail === transferMethod ||
        TransferMethodType.AlipayMobile === transferMethod) && (
          <AlipayTransferPayeeContent
            businessId={businessId}
            record={record}
            initLoading={initLoading}
          />
        )}
    </Transition.Root>
  );
};

export default PayOutDetailsPage;
