import React from 'react';
import type { IIconProps } from '@/types/common-types';

const VisaIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="4em"
        height="3em"
        viewBox="0 0 64 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.746 33.7652H11.6388L7.80906 18.8223C7.62729 18.135 7.24132 17.5273 6.67358 17.2409C5.25671 16.5211 3.69542 15.9483 1.99219 15.6594V15.0841H10.2195C11.355 15.0841 12.2066 15.9483 12.3485 16.952L14.3356 27.7308L19.4403 15.0841H24.4056L16.746 33.7652ZM27.2412 33.7652H22.4179L26.3896 15.0841H31.213L27.2412 33.7652ZM37.4502 20.2584C37.5922 19.2523 38.4438 18.677 39.4373 18.677C40.9986 18.5325 42.6994 18.8214 44.1187 19.5387L44.9703 15.5165C43.551 14.9412 41.9897 14.6523 40.5728 14.6523C35.8914 14.6523 32.485 17.24 32.485 20.8312C32.485 23.5633 34.8979 24.9978 36.6011 25.862C38.4438 26.7237 39.1535 27.299 39.0115 28.1607C39.0115 29.4533 37.5922 30.0286 36.1753 30.0286C34.4721 30.0286 32.7688 29.5977 31.21 28.878L30.3584 32.9026C32.0616 33.6198 33.9043 33.9087 35.6075 33.9087C40.8567 34.0507 44.1187 31.4656 44.1187 27.5854C44.1187 22.6991 37.4502 22.4127 37.4502 20.2584ZM61.0003 33.7652L57.1706 15.0841H53.0569C52.2053 15.0841 51.3537 15.6594 51.0698 16.5211L43.978 33.7652H48.9433L49.9343 31.0356H56.0351L56.6028 33.7652H61.0003ZM53.7744 20.1192L55.1913 27.1598H51.2196L53.7744 20.1192Z"
          fill="#0032BE"
        />
      </svg>
    </div>
  );
};

export default VisaIcon;
