import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ReduceIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_80196_9547)">
          <circle
            cx="14"
            cy="14"
            r="13.25"
            stroke="#0094FF"
            strokeWidth="1.5"
          />
          <path
            d="M9 14H19"
            stroke="#0094FF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_80196_9547">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ReduceIcon;
