import type { IAccountBalanceMerchant } from '@/types/account-balance-type';
import type { IObject } from '@/types/common-types';
import ScrollNum from '@/components/common/scroll-number';
import { useEffect, useState } from 'react';
import Table from '@/components/table';
import type { ColumnsType } from 'antd/es/table';
import type { TableRecord } from '@/components/table/interface';
import { Transition } from '@headlessui/react';
import { getTransactionsColumns } from '@/pages/account-balance/account-balance-data';
import { HadleGoPaymentDetails } from '@/utils/account-balance-untils';
import { getCurrentlyProcessing } from '@/data/account-balance';
import Skeleton from '@/components/skeleton';
import { useTranslation } from 'react-i18next';
import type {
  IFilterField,
  IFilterOptions,
} from '@/components/common/filter';
import Filter, {
  FilterOperation,
  IFilterType,
} from '@/components/common/filter';
import { useParams, useNavigate } from 'react-router-dom';


const AccountBalanceCurrentProcess = (props: IAccountBalanceMerchant) => {
  const {
    businessID,
    merchantAccountData,
    processInitData,
    refreshPage,
    activekey,
    initLoading,
  } = props;

  const { t } = useTranslation("account_balance");
  const { lang = 'en' } = useParams();
  const { processingRecord } = merchantAccountData || {};

  const [colums, setColums] = useState<ColumnsType<TableRecord>>([]);
  const [processRecords, setProcessRecords] = useState<IObject[] | undefined>(
    undefined
  );
  const [recordLoading, setRecordLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<IFilterOptions[]>([]);
  const navigate = useNavigate();


  useEffect(() => {
    //构建colunms
    handleColums();
    if (!processInitData && activekey === 'currentAccount') {
      getProcessRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    //构建colunms
    activekey === 'merchantAccount' &&
      processInitData &&
      setProcessRecords(processInitData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processInitData, refreshPage]);

  useEffect(() => {
    if (initLoading) {
      setRecordLoading(initLoading);
      setProcessRecords(undefined);
      setPageSize(1);
      setPageSize(30);
    } else {
      setRecordLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initLoading]);

  // 构建columns
  const handleColums = () => {
    const columns = getTransactionsColumns(t, false, lang);
    setColums(columns);
    return columns;
  };

  const getProcessRecords = async (
    page = 1,
    limit = pageSize,
    search = searchValue,
    filterOptions = options
  ) => {
    let records = [];
    try {
      if (processingRecord && processingRecord.id) {
        setRecordLoading(true);
        const data = await getCurrentlyProcessing({
          pBusinessId: businessID,
          limit: limit + 1,
          offset: (page - 1) * limit,
          searchValue: search,
          options: filterOptions,
        });

        if (Array.isArray(data)) {
          records = data;
        }
      }
    } catch (error) { }
    setPage(page);
    setPageSize(limit);
    setProcessRecords(records);
    setRecordLoading(false);
    setSearchValue(search);
    setOptions(filterOptions);
  };

  const filterFields: IFilterField[] = [
    {
      name: 'payment_types',
      label: t('account_balance:payment_type'),
      type: IFilterType.MULTISELECT,
      operations: [FilterOperation.EQUALS],
      options: [
        {
          name: 'sale',
          label: 'Sale',
        },
        {
          name: 'void',
          label: 'Void',
        },
        {
          name: 'refund',
          label: 'Refund',
        },
        {
          name: 'increment_pre_auth',
          label: 'Increased Pre-auth',
        },
        {
          name: 'pre_auth_complete',
          label: 'Pre-auth Completed',
        },
        {
          name: 'charge_back',
          label: 'Chargeback',
        },
        {
          name: 'dispute',
          label: 'Dispute',
        },
        {
          name: 'dispute_cancel',
          label: 'Cancelled Dispute',
        },
        {
          name: 'hold',
          label: 'On Hold',
        },
        {
          name: 'risk_hold',
          label: 'Risk Hold',
        },
        {
          name: 'pre_dispute',
          label: 'Pre-dispute',
        },
        {
          name: 'hold_reversal',
          label: 'Release',
        },
        {
          name: 'representment',
          label: 'Representment',
        },
      ],
    },
    {
      name: 'amount',
      label: t('account_balance:transaction_amount'),
      type: IFilterType.NUMBER,
      operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
    },
    {
      name: 'payment_methods',
      label: t('common:payment_method'),
      type: IFilterType.MULTISELECT,
      operations: [FilterOperation.EQUALS],
      options: [
        {
          name: 'mastercard',
          label: 'Mastercard',
        },
        {
          name: 'visa',
          label: 'Visa',
        },
        {
          name: 'cup',
          label: 'UnionPay',
        },
        {
          name: 'unionpay_wallet',
          label: 'UnionPay Wallet',
        },
        {
          name: 'alipay',
          label: 'Alipay',
        },
        {
          name: 'wechat',
          label: 'Wechat Pay',
        },
        {
          name: 'fps',
          label: 'FPS',
        },
        {
          name: 'jcb',
          label: 'JCB',
        },
        {
          name: 'discover',
          label: 'Discover',
        },
        {
          name: 'diners',
          label: 'Diners Club',
        },
        {
          name: 'xpay',
          label: 'Xpay',
        },
      ],
    },
  ];

  const isShowAdd = (total: any) => {
    const numericTotal = Number(total);
    return numericTotal >= 0;
  };

  return (
    <Transition.Child
      className="rounded overflow-hidden shadow-card-drop bg-primary-background-color my-4 h-fit p-4 md:p-6 md:my-6"
      enter="duration-600 transform transition ease-in-out"
      enterFrom="-translate-x-1 translate-y-10 opacity-40"
      enterTo="translate-x-0 translate-y-0 opacity-100"
      leave="duration-500 transform transition"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
    >
      <div className="items-center lg:flex lg:justify-between">
        <div>
          <div className="flex flex-col md:flex-row whitespace-nowrap">
            <span className="font-bold text-lg text-color-text-1 flex items-center md:text-xl">
              {t('account_balance:current_batch')}
            </span>
            <span className="text-color-text-1 bg-primary-color bg-opacity-10 px-3 py-0.5 rounded-lg ml-4">
              <div className="flex items-end text-sm">
                <div className="font-medium leading-7 text-color-text-1">
                  {initLoading && (
                    <div className="overflow-hidden inline-block w-40">
                      <Skeleton
                        row={1}
                        width={['100%']}
                        skeletonItemClass={`h-4 rounded-2xl mb-0`}
                      />
                    </div>
                  )}
                  {!initLoading && (
                    <ScrollNum value={processingRecord?.total_amount} showAdd={isShowAdd(processingRecord?.total_amount)} />
                  )}
                </div>
                {!initLoading && (
                  <div className="font-medium leading-7 text-color-text-1 ml-1">
                    {processingRecord?.currency}
                  </div>
                )}
              </div>
            </span>
          </div>
        </div>
        <div className="flex space-x-4 justify-start mt-4 md:justify-end md:mt-0">
          {/* <Button styleType="Secondary">Export</Button> */}
          <Filter
            fields={filterFields}
            onFilter={(search, options) =>
              getProcessRecords(1, pageSize, search, options)
            }
            searchValue={searchValue}
            options={options}
            placeholder={t('account_balance:search_id_ref')}
          />
        </div>
      </div>
      <div className="w-full mt-4 md:mt-6 overflow-auto">
        <Table
          columns={colums}
          className="!max-h-96"
          records={processRecords}
          loadingData={recordLoading || initLoading || !processRecords}
          search={searchValue.length > 0 || options.length > 0}
          pagination={{
            className: 'mt-6',
            pageSize,
            current: page,
            currentSize: processRecords ? processRecords.length : 0,
            pageSizeOptions: [30, 60, 120],
            changePageCallback: getProcessRecords,
          }}
          onRowCell={(record, dataKey) => {
            return {
              onClick: (evt) => {
                evt.stopPropagation();
                HadleGoPaymentDetails(businessID, record.id, navigate, 'merchant');
              },
            };
          }}
        />
      </div>
    </Transition.Child>
  );
};

export default AccountBalanceCurrentProcess;
