import React from 'react';
import { createRoot } from 'react-dom/client';
import ModalBasic from './modal-basic';

import type { IModalBasicProps, IModalFunctions, ModalType } from './type';

// class ModalBox extends React.Component<IModalBasicProps, IModalBasicProps> {

//   constructor(props: IModalBasicProps){
//     super(props);

//     this.setState({
//       ...props,
//     })
//   }

//   confirm(params: IModalBasicProps) {
//     params.visible = true;
//     this.setState({
//       ...params,
//     })
//   }

//   render(): React.ReactNode {

//     return (
//       <ModalBasic
//         {...this.state}
//       />
//     );
//   }
// }

// const div = document.createElement('div');
// document.body.appendChild(div);
// // eslint-disable-next-line react/no-render-return-value
// const Modal = ReactDOM.render(<ModalBox title={""} content={""} />, div);

// export default Modal;

function showModal(params: IModalBasicProps) {
  const options = Object.assign(
    {
      title: '',
      content: '',
    },
    params
  );

  const div = document.createElement('div');
  document.body.appendChild(div);
  const root = createRoot(div);
  root.render(
    <ModalBasic
      {...options}
      onCancel={() => {
        options.onCancel?.();
        root.unmount();
        document.body.removeChild(div);
      }}
      onOk={(data) => {
        options.onOk?.(data);
        root.unmount();
        document.body.removeChild(div);
      }}
    />
  );
}

const ModalFunctions: IModalFunctions = {
  info: (opts: IModalBasicProps) =>
    showModal({
      ...opts,
      type: 'info',
    }),
  warn: (opts: IModalBasicProps) =>
    showModal({
      ...opts,
      type: 'warn',
    }),
  success: (opts: IModalBasicProps) =>
    showModal({
      ...opts,
      type: 'success',
    }),
};

const Modal = ModalBasic as ModalType;

Modal.info = ModalFunctions.info;

Modal.success = ModalFunctions.success;

Modal.warn = ModalFunctions.warn;

export default Modal;
