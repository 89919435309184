import { queryJsonwebtoken } from '@/data/jsonwebtoken';
import { useEffect, useState } from 'react';

const RealData = () => {
  const [embeddingUrls, setEmbeddingUrls] = useState<string[]>([]);

  useEffect(() => {
    getUrls()
    // eslint-disable-next-line
  }, []);

  const getUrls = async () => {
    const METABASE_SITE_URL = 'https://metabase3.bindolabs.com';
    const EXP = Math.round(Date.now() / 1000) + 365 * 24 * 60 * 60; // 365 days expiration

    const resource_dashboard_ids = [
      300, // https://metabase5.bindolabs.com/dashboard/300-off-hkt-easylink-sic-migration
      375, // https://metabase3.bindolabs.com/dashboard/375-gateway-platform-overview?tab=15-overview
      89, // https://metabase5.bindolabs.com/dashboard/89-customer-success-step-by-step-1-pre-approval?date_range=2023-05-01~2023-12-31
      307, // https://metabase5.bindolabs.com/dashboard/307-customer-success-step-by-step-2-post-approval
      293, // https://metabase5.bindolabs.com/dashboard/293-real-time-qa-no-compromise-dashboard-24-7-on-call-no-sleep-no-getting-off-if-anything-fails-here
    ];

    const embeddingUrls: string[] = [];

    for (let i = 0; i < resource_dashboard_ids.length; i++) {
      const resource_dashboard_id = resource_dashboard_ids[i];
      const payload = {
        resource: { dashboard: resource_dashboard_id },
        params: {},
        exp: EXP,
      };
      const token = await queryJsonwebtoken(payload)
      const iframe_url =
        METABASE_SITE_URL +
        '/embed/dashboard/' +
        token +
        '?bordered=true&titled=true#theme=night&refresh=900&fullscreen';

      embeddingUrls.push(iframe_url);
    }

    setEmbeddingUrls(embeddingUrls);
  }

  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 flex">
      {embeddingUrls.map((url, index) => (
        // eslint-disable-next-line react/no-unknown-property
        <iframe key={index} title={`myIframe-${index}`} className="flex-1 border-none" src={url} />
      ))}
    </div>
  );
};

export default RealData;
