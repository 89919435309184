import React from 'react';
import type { IIconProps } from '@/types/common-types';

const CardTransactionIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 32 } = props;
  return (
    <div
      className={`inline-flex items-center justify-center ${className}`}
      onClick={onClick}
    >
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill="#ACD2C1" />
        <path
          d="M9.6001 16.704C9.6001 15.7946 9.97938 14.9226 10.6545 14.2796C11.3296 13.6366 12.2453 13.2754 13.2001 13.2754H34.8001C35.7549 13.2754 36.6705 13.6366 37.3457 14.2796C38.0208 14.9226 38.4001 15.7946 38.4001 16.704V30.4182C38.4001 31.3276 38.0208 32.1996 37.3457 32.8426C36.6705 33.4856 35.7549 33.8468 34.8001 33.8468H13.2001C12.2453 33.8468 11.3296 33.4856 10.6545 32.8426C9.97938 32.1996 9.6001 31.3276 9.6001 30.4182V16.704ZM13.2001 14.9897C12.7227 14.9897 12.2649 15.1703 11.9273 15.4918C11.5897 15.8133 11.4001 16.2493 11.4001 16.704V18.4182H36.6001V16.704C36.6001 16.2493 36.4105 15.8133 36.0729 15.4918C35.7353 15.1703 35.2775 14.9897 34.8001 14.9897H13.2001ZM36.6001 21.8468H11.4001V30.4182C11.4001 30.8729 11.5897 31.3089 11.9273 31.6304C12.2649 31.9519 12.7227 32.1325 13.2001 32.1325H34.8001C35.2775 32.1325 35.7353 31.9519 36.0729 31.6304C36.4105 31.3089 36.6001 30.8729 36.6001 30.4182V21.8468Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default CardTransactionIcon;
