import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import PayoutCreatedIcon from '../payouts-icons/payout-created-icon';
import CloseIcon from '@/components/svg/close-icon';
import { useEffect, useState } from 'react';
import TipsIcon from '@/components/svg/tips-icon';
import ApproximatelyEqualIcon from '../payouts-icons/approximately-equal-icon';
import { Tooltip } from 'antd';
import DownloadIcon from '@/components/svg/download-icon';
import UnsuccessfulPayoutIcon from '../payouts-icons/unsuccessful-payout-icon';
import { getCookie } from '@/utils';
import { Constants } from '@/constants';
import { exportPDF, getBusiness, getPayout } from '@/data/payouts';
import { Alert } from '@/components/common/alert/alert';
import { getPayoutsI18nValue, getState, handleMount, initPayout, showTransferMethod } from '@/utils/payouts-utils';
import Button from '@/components/common/button';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IPayout } from '@/types/payouts-type';

dayjs.extend(utc);

const PayoutResultPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('payouts');
  const { lang = 'en', type: activekey = '', p_business_id: businessID = '', payout_id = '' } = useParams();
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [payout, setPayout] = useState<IPayout>(initPayout);
  const {
    payee,
    schedule_status = '',
    error_code,
    error_message,
    transfer_status = '',
  } = payout;
  const { i18n = {}, type = '' } = payee || {};
  const schedule = schedule_status.toLocaleLowerCase();
  const transfer = transfer_status.toLocaleLowerCase();

  useEffect(() => {
    loadingInitData();
    // eslint-disable-next-line
  }, []);

  const loadingInitData = async() => {
    const token = getCookie(Constants.TOKEN) || '';
    const result = await getPayout(payout_id, businessID, token);
    setPayout(result);
  }

  const handleCancel = () => {
    navigate(`/${lang}/businesses/${businessID}/payouts/${activekey}`);
  };

  const handleExport = async () => {
    setExportLoading(true);
    const token = getCookie(Constants.TOKEN) || '';
    const business = await getBusiness(businessID, token);
    const { url } = await exportPDF(businessID, payout, business);
    let success = true;
    try {
      await fetch(url).then((res) =>
        res.blob().then((blob) => {
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = `Payout_Transfer_Notice_${dayjs().format(
            'YYYY-MM-DD'
          )}_${businessID.slice(-4)}.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
        })
      );
    } catch (error) {
      console.error(error);
      success = false;
    }
    if (success) {
      Alert.success({
        message: t('payout_export_successful'),
        position: 'default',
        wrapClass: 'top-px',
      });
    } else {
      Alert.error({
        message: t('payout_export_failed'),
        position: 'default',
        wrapClass: 'top-px',
      });
    }
    setExportLoading(false);
  };

  const showNotice =
    (schedule === 'approved' && transfer === 'initiated') ||
    (schedule === 'pending verification' && transfer === 'suspended');
  const fail = transfer === 'reversed';

  return (
    <div className="bg-system-background-color min-h-screen flex justify-center overflow-auto py-8 leading-snug">
      <CloseIcon
        size={20}
        onClick={handleCancel}
        className="text-color-text-1 cursor-pointer hover:text-primary-color p-3 bg-white rounded-full absolute right-10 top-8"
      />
      <div className="w-full flex flex-col items-center p-6 max-w-xl justify-center m-auto bg-white rounded shadow-card-drop my-auto">
        {fail && (
          <>
            <UnsuccessfulPayoutIcon className="text-center" />
            <div className="mt-6 text-color-text-1 font-bold text-xl">
              {t('unsuccessful_payout')}
            </div>
          </>
        )}
        {!fail && (
          <>
            <PayoutCreatedIcon className="text-center" />
            <div className="mt-6 text-color-text-1 font-bold text-xl">
              {t('payout_created')}
            </div>
          </>
        )}
        <div className="mt-2">{getState(payout, lang)}</div>
        {error_code && error_message && (
          <div className="text-sm text-error-color mt-2 text-center">
            <div>{error_code}</div>
            <div>{error_message}</div>
          </div>
        )}
        <div className="border border-border-color rounded-2xl px-4 w-full py-3 mt-6 text-sm">
          <div className="flex justify-between items-center font-medium py-3">
            <div className="text-color-text-2">
              {t('transfer_amount')}
            </div>
            <div className="text-color-text-1">
              {handleMount(payout.destination_amount)}{' '}
              {payout.destination_currency}
            </div>
          </div>
          <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
            <div className="text-color-text-2">{t('fees_charged')}</div>
            <div className="text-color-text-1">
              {handleMount(payout.calculated_fee)} {payout.currency}
            </div>
          </div>
          {payout.currency !== payout.destination_currency && (
            <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
              <div className="text-color-text-2">{t('rate')}</div>
              <div className="text-color-text-1">
                <div className="flex items-center">
                  <ApproximatelyEqualIcon className="mr-0.5" />
                  <div>
                    {payout.fx_exchange_rate} {payout.currency}-
                    {payout.destination_currency}
                  </div>
                  <Tooltip
                    className="ml-0.5 leading-3 cursor-pointer"
                    overlayClassName="max-w-none"
                    title={
                      <div className="font-normal">
                        <p className="text-base font-medium">
                          {t('estimated_exchange_rate')}
                        </p>
                        <p className="whitespace-pre-line">
                          {t('estimated_exchange_rate_details')}
                        </p>
                      </div>
                    }
                  >
                    <span>
                      <TipsIcon size={16} className="text-primary-color" />
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          )}
          <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
            <div className="text-color-text-2">
              {t('billing_amount')}
            </div>
            <div className="text-color-text-1 flex items-center">
              {payout.currency !== payout.destination_currency && (
                <ApproximatelyEqualIcon className="mr-0.5" />
              )}
              {handleMount(payout.total_amount)} {payout.currency}
              {payout.currency !== payout.destination_currency && (
                <Tooltip
                  className="ml-0.5 leading-3 cursor-pointer"
                  overlayClassName="max-w-none"
                  trigger={'hover'}
                  placement="bottom"
                  title={
                    <div className="font-normal">
                      <p className="text-base font-medium">
                        {t('estimated_billing_amount')}
                      </p>
                      <p className="whitespace-pre-line">
                        {t('estimated_billing_amount_details')}
                      </p>
                    </div>
                  }
                >
                  <span>
                    <TipsIcon size={16} className="text-primary-color" />
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="flex justify-between font-medium py-3 border-t border-border-color">
            <div className="text-color-text-2">{t('payout_id')}</div>
            <div className="text-color-text-1 max-w-[230px] text-right">
              {payout.id}
            </div>
          </div>
          <div className="flex justify-between font-medium py-3 border-t border-border-color">
            <div className="text-color-text-2">{t('payee')}</div>
            <div className="text-color-text-1">
              {payout.payee && (
                <>
                  <div className="text-right">{payout.payee.nickname}</div>
                  <div className="text-right">
                    {payout.payee.payout_accounts.country_codes.country_name} -{' '}
                    {payout.payee.payout_accounts.currency} -{' '}
                    {showTransferMethod(payout.payee)}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
            <div className="text-color-text-2">{t('payee_type')}</div>
            <div className="text-color-text-1">
              {getPayoutsI18nValue(i18n, type, lang)}
            </div>
          </div>
          <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
            <div className="text-color-text-2">{t('created_at')}</div>
            <div className="text-color-text-1">
              {dayjs(payout.p_created_at).format('MMMM DD, YYYY HH:mm:ss')}
            </div>
          </div>
          <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
            <div className="text-color-text-2">
              {t('transfer_date')}
            </div>
            <div className="text-color-text-1">
              {dayjs(payout.schedule_payout_at || undefined).format(
                'MMMM DD, YYYY'
              )}
            </div>
          </div>
          <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
            <div className="text-color-text-2">
              {t('purpose_of_transfer')}
            </div>
            <div className="text-color-text-1">
              {getPayoutsI18nValue(payout.i18n, payout.transfer_purpose, lang)}
            </div>
          </div>
          <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
            <div className="text-color-text-2">{t('category')}</div>
            <div className="text-color-text-1">
              {getPayoutsI18nValue(
                payout.i18n,
                payout.payout_category_name,
                lang
              )}
            </div>
          </div>
          {payout.reference && (
            <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
              <div className="text-color-text-2">
                {t('internal_reference_number')}
              </div>
              <div className="text-color-text-1">{payout.reference}</div>
            </div>
          )}
          {payout.po_number && (
            <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
              <div className="text-color-text-2">
                {t('purchase_order_number')}
              </div>
              <div className="text-color-text-1">{payout.po_number}</div>
            </div>
          )}
        </div>
        <div className="flex justify-between items-center w-full mt-6">
          {showNotice && (
            <Button
              className="flex-1 mr-6"
              onClick={handleExport}
              styleType="Secondary"
              iconInstance={<DownloadIcon size={20} />}
              loading={exportLoading}
            >
              {t('save_payout_notice')}
            </Button>
          )}
          <Button
            className="flex-1"
            onClick={() =>
              navigate(`/${lang}/businesses/${businessID}/payouts/${activekey}`)
            }
            styleType="Primary"
          >
            {t('done')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PayoutResultPage;
