import React from 'react';
import type { IIconProps } from '@/types/common-types';

const SuccessfullyIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 80 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 80 80"
        fill="none"
      >
        <rect opacity="0.1" width="80" height="80" rx="40" fill="#10B981" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 40C20 29 29 20 40 20C51 20 60 29 60 40C60 51 51 60 40 60C29 60 20 51 20 40ZM37.1504 48.1455C37.3456 48.3407 37.6622 48.3407 37.8575 48.1455L50.6504 35.3526C50.8456 35.1573 50.8456 34.8407 50.6504 34.6455L47.8575 31.8526C47.6622 31.6573 47.3456 31.6573 47.1504 31.8526L37.8575 41.1455C37.6622 41.3407 37.3456 41.3407 37.1504 41.1455L32.8575 36.8526C32.6622 36.6573 32.3456 36.6573 32.1504 36.8526L29.3575 39.6455C29.1622 39.8407 29.1622 40.1573 29.3575 40.3526L37.1504 48.1455Z"
          fill="#10B981"
        />
      </svg>
    </div>
  );
};

export default SuccessfullyIcon;
