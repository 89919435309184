import { Transition } from '@headlessui/react';
import StepWrapper from '../components/step-wrapper';
import type {
  IAddressObject,
  ICartParams,
  ITerminalOrdersCheckPageProps,
} from '../../../types/terminal-type';
import { useTranslation } from 'react-i18next';
import type { IObject } from '@/types/common-types';
import RightIcon from '../terminal-icons/right-icon';
import Button from '@/components/common/button';
import Label from '@/components/common/label';
import { Cascader } from 'antd';
import type { ICascaderOptions } from '../../../utils/terminal-untils';
import { handlePrice } from '../../../utils/terminal-untils';
import CartItem from '../components/card-item';
import PanelTitle from '../components/panel-title';
import Input from '@/components/common/antd/input/input';
import { Input as AntdInput } from 'antd';
import { useState, useEffect } from 'react';
import AddressDetailsModal from './address-details-modal';
import { IAreaCodes } from '@/types/common-types';
import AddressListModal from './choose-address-modal';
import {
  deleteAddressRecord,
  updateAddressRecord,
  getDistrictOption,
} from '../../../data/terminal';
import ErrorIcon from '../terminal-icons/error-icon';
import { Alert } from '@/components/common/alert/alert';
import AntdModal from '@/components/common/antd/modal';
import Skeleton from '@/components/skeleton';
import ScheduleIcon from '@/components/svg/time-icon';
import { createUuid, useUserAction } from '@/utils/common';
import { useParams } from 'react-router-dom';


interface ICheckOrderWebPage extends ITerminalOrdersCheckPageProps {
  cart: ICartParams;
  code: string;
  codePass: boolean;
  currency: string;
  areaCodes: IAreaCodes[];
  token: string;
  customerID: string;
  addressList: IAddressObject[];
  deliverySchedule: string[];
  options: ICascaderOptions[];
  checkoutLoding: boolean;
  loadingData: boolean;
  cartLoading: boolean;
  nodeValue: string;
  selectedAddress?: IAddressObject;
  setDeliverySchedule: (value: string[]) => void;
  loadAddress: () => Promise<IObject | undefined>;
  handleCheckout: () => void;
  handleBack: () => void;
  handleCodeChange: (value: string) => void;
  handleNodeChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleMinus: (record: IObject) => void;
  handlePlus: (record: IObject) => void;
}

const CheckOrderWebPage: React.FC<ICheckOrderWebPage> = (props) => {
  const {
    businessID,
    cart,
    code,
    codePass,
    currency,
    areaCodes,
    token,
    customerID,
    selectedAddress,
    addressList,
    deliverySchedule,
    options,
    cartLoading,
    checkoutLoding,
    loadingData,
    nodeValue,
    inApp,
    setDeliverySchedule,
    loadAddress,
    handleCodeChange,
    handleMinus,
    handlePlus,
    handleCheckout,
    handleBack,
    handleNodeChange,
  } = props;
  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const { lang = 'en' } = useParams();
  const [showAddressModal, setShowAddressModal] = useState<boolean>(false);
  const [showChooseAddressModal, setShowChooseAddressModal] =
    useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<
    IAddressObject | undefined
  >(selectedAddress);
  const [editRecord, setEditRecord] = useState<IAddressObject | undefined>(
    undefined
  );
  const [modalTitle, setModalTitle] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState<boolean>(false);
  const [districtData, setDistrictData] = useState<any>([]);
  const [key, setKey] = useState<any>(createUuid());
  const { TextArea } = AntdInput;
  const canCheckout = cart.listings.length > 0 && selectedAddress;

  useEffect(() => {
    getDistrictOptions();
    // eslint-disable-next-line
  }, []);

  useUserAction(inApp ? true : false);

  const getDistrictOptions = async () => {
    const data = await getDistrictOption(businessID, token, lang);
    const newData: any = [];
    const global = lang === 'en' ? 'en-US' : lang;
    data.map((item: any) => {
      if (item.translations && item.translations[global]) {
        newData.push({
          value: item.name,
          label: item.translations[global],
        });
      }
      return undefined;
    });
    setDistrictData(newData);
  };

  // const handleCancel = () => {
  //   setShowAddressModal(false);
  //   setShowChooseAddressModal(false);
  //   setEditRecord(undefined);
  // };

  const handleAdd = () => {
    setShowChooseAddressModal(false);
    setShowAddressModal(true);
    setKey(createUuid());
    setEditRecord(undefined);
    setModalTitle(t('extended_service:add_new_address'));
  };

  const handleUpdateRecord = async (
    record: IAddressObject | undefined = selectedRecord,
    isUpdateDefault = false
  ) => {
    setShowAddressModal(false);
    setEditRecord(undefined);
    if (record && record.id) {
      const newRecord = {
        ...record,
      };
      if (isUpdateDefault) {
        newRecord.is_default = true;
      }
      const data = await updateAddressRecord(businessID, token, newRecord);

      if (data.id) {
        Alert.success({
          message: t('common:changes_saved'),
          wrapClass: '-top-10',
        });
      } else {
        Alert.error({
          message: t('common:changes_failed'),
          wrapClass: '-top-10',
        });
      }

      await loadAddress();
    }
    setShowChooseAddressModal(!isUpdateDefault);
  };

  const handleEditRecord = (record: IAddressObject) => {
    setEditRecord(record);
    setShowChooseAddressModal(false);
    setShowAddressModal(true);
    setKey(createUuid());
    setModalTitle(t('extended_service:edit_address'));
  };

  const handleDete = async () => {
    setLoading(true);
    if (editRecord && editRecord.id) {
      const result = await deleteAddressRecord(
        businessID,
        token,
        editRecord.id
      );
      if (result) {
        setShowAddressModal(false);
        setDeleteConfirmModal(false);
        setShowChooseAddressModal(true);
        Alert.success({
          message: t('extended_service:removed_successfully'),
          wrapClass: '-top-10',
        });
        await loadAddress();
      }
    }
    setLoading(false);
  };

  const sum = cart.listings.reduce((a, c) => {
    if (c.cartQuantity !== undefined) {
      return a + c.cartQuantity;
    } else {
      return a;
    }
  }, 0);

  const addressInfo = [
    selectedAddress?.address,
    selectedAddress?.street,
    // selectedAddress?.city,
  ].filter((item) => item && item.length > 0);

  return (
    <StepWrapper active={1} businessID={businessID}>
      <div className="p-2 md:p-6 bg-primary-background-color rounded">
        <PanelTitle
          loadingData={loadingData}
          title={t('extended_service:shipping_address')}
        />
        {loadingData && (
          <div className="pb-4">
            {new Array(2).fill('').map((r, i) => (
              <div key={`${r}_${i}`} className="mb-8">
                <Skeleton
                  row={1}
                  width={['50%']}
                  className="mb-3"
                  skeletonItemClass="h-4 rounded-2xl"
                />
                <Skeleton
                  row={1}
                  width={['100%']}
                  skeletonItemClass="h-6 rounded-2xl"
                />
              </div>
            ))}
          </div>
        )}
        {!loadingData && (
          <>
            <div className="border-b border-border-color mb-4 pb-4">
              {selectedAddress && (
                <div
                  className="cursor-pointer flex items-center justify-between"
                  onClick={() => setShowChooseAddressModal(true)}
                >
                  <div className="font-medium text-sm">
                    <div className="font-semibold text-base">
                      {selectedAddress.name || ''}
                    </div>
                    <div>{selectedAddress.phone || ''}</div>
                    <div>
                      {addressInfo.join(', ')} ,{' '}
                      {t('extended_service:hong_kong')}
                    </div>
                  </div>
                  <RightIcon />
                </div>
              )}
              {!selectedAddress && (
                <div>
                  <Button
                    onClick={() => {
                      setKey(createUuid());
                      setShowAddressModal(true);
                    }}
                  >
                    {t('extended_service:add_address')}
                  </Button>
                  <div className="text-sm text-color-text-1 font-medium mt-2">
                    {t('extended_service:add_address_tips')}
                  </div>
                </div>
              )}
            </div>
            <Label value={t('extended_service:delivery_schedule')} required />
            <Cascader
              value={deliverySchedule}
              allowClear={false}
              suffixIcon={<ScheduleIcon size={20} className="text-primary-color" />}
              changeOnSelect
              onChange={(value: any) => {
                if(value[1]){
                setDeliverySchedule(value)
                }
              }}
              className="w-full mb-4"
              options={options}
              getPopupContainer={(triggerNode) => triggerNode}
              popupClassName="w-full h-[20rem]"
            />
          </>
        )}
      </div>
      <Transition.Child
        className="p-2 md:p-6 bg-primary-background-color rounded mt-6"
        enter="duration-600 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-10 opacity-40"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <PanelTitle
          loadingData={loadingData}
          title={t('extended_service:ordered_item_service')}
        />
        {loadingData && (
          <>
            {new Array(2).fill('').map((r, i) => (
              <div key={`${r}_${i}`} className="flex mt-8">
                <Skeleton
                  row={1}
                  className="mr-4 !h-20 !w-20"
                  skeletonItemClass={`h-20 !w-20 rounded-2xl`}
                />
                <div className="ml-4 flex-1 flex flex-col justify-between">
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass="h-6 rounded-2xl"
                  />
                  <Skeleton
                    row={1}
                    width={['50%']}
                    skeletonItemClass="h-6 rounded-2xl"
                  />
                </div>
              </div>
            ))}
          </>
        )}
        {!loadingData && (
          <CartItem
            cart={cart}
            currency={currency}
            handleMinus={handleMinus}
            handlePlus={handlePlus}
          />
        )}
      </Transition.Child>
      <Transition.Child
        className="p-2 md:p-6 bg-primary-background-color rounded mt-6"
        enter="duration-500 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-10 opacity-40"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <PanelTitle
          loadingData={loadingData}
          title={t('extended_service:payment_information')}
        />
        {loadingData && (
          <>
            <Skeleton
              row={1}
              width={['50%']}
              className="mb-3"
              skeletonItemClass="h-4 rounded-2xl"
            />
            <Skeleton
              row={1}
              width={['100%']}
              skeletonItemClass="h-6 rounded-2xl"
            />
          </>
        )}
        {!loadingData && (
          <>
            <label className="text-color-text-1 text-sm font-medium">
              {t('extended_service:promotion_code')}
            </label>
            <Input
              onChange={(value) => handleCodeChange(value)}
              value={code}
              allowClear={!codePass}
              suffix={
                codePass ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10.8701"
                      r="10"
                      fill="#0094FF"
                      fillOpacity="0.2"
                    />
                    <path
                      d="M6.66602 11.6104L9.25861 14.203L13.3327 8.64746"
                      stroke="#0094FF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : undefined
              }
              style={
                code.length > 0
                  ? codePass
                    ? {}
                    : {
                      borderColor: '#F43F5E',
                      // color: '#F43F5E',
                    }
                  : {}
              }
              placeholder={t('extended_service:enter_promotion_code')}
            />
            {code.length > 0 && !codePass && (
              <div className="flex items-center mt-2 text-error-color">
                <ErrorIcon />
                <span className="text-sm ml-2">
                  {t('extended_service:promotion_code_error_tips')}
                </span>
              </div>
            )}
          </>
        )}
        <div className="mt-6 p-4 rounded-2xl border text-sm font-medium text-color-text-1 border-primary-color">
          {loadingData && (
            <>
              {new Array(4).fill('').map((r, i) => (
                <div
                  key={`${r}_${i}`}
                  className="flex items-center justify-between mb-3"
                >
                  <Skeleton
                    row={1}
                    width={[`${i === 3 ? '80%' : '60%'}`]}
                    skeletonItemClass={`${i === 3 ? 'h-6' : 'h-4'} rounded-2xl`}
                  />
                  <Skeleton
                    row={1}
                    width={[`${i === 3 ? '80%' : '60%'}`]}
                    skeletonItemClass={`${i === 3 ? 'h-6' : 'h-4'
                      } self-end rounded-2xl`}
                  />
                </div>
              ))}
            </>
          )}
          {!loadingData && (
            <>
              <div className="flex items-center justify-between mb-3">
                <div className="text-color-text-2">
                  {t('extended_service:total_items')}
                </div>
                <div>
                  {sum} {t('extended_service:items')}
                </div>
              </div>
              <div className="flex items-center justify-between mb-3">
                <div className="text-color-text-2">
                  {t('extended_service:subtotal')}
                </div>
                <div>
                  {handlePrice(cart.origin_total)} {currency}
                </div>
              </div>
              <div className="flex items-center justify-between mb-3">
                <div className="text-color-text-2">
                  {t('extended_service:delivery_fee')}
                </div>
                <div>
                  {handlePrice(cart.delivery_fee)} {currency}
                </div>
              </div>
              {cart.discount_total !== 0 && (
                <div className="flex items-center justify-between mb-3">
                  <div className="text-color-text-2">
                    {t('extended_service:discount')}
                  </div>
                  <div>
                    -{handlePrice(cart.discount_total)} {currency}
                  </div>
                </div>
              )}
              <div className="flex items-center justify-between text-base font-bold">
                <div>{t('extended_service:total_amount')}</div>
                <div>
                  {handlePrice(cart.total)} {currency}
                </div>
              </div>
            </>
          )}
        </div>
      </Transition.Child>
      <Transition.Child
        className="p-2 md:p-6 bg-primary-background-color rounded mt-6"
        enter="duration-400 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-10 opacity-40"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <PanelTitle
          loadingData={loadingData}
          title={t('extended_service:note')}
        />
        {loadingData && (
          <Skeleton
            row={1}
            width={['50%']}
            skeletonItemClass="h-4 rounded-2xl"
          />
        )}
        {!loadingData && (
          <TextArea
            onChange={handleNodeChange}
            placeholder={t('extended_service:enter_note_here')}
            style={{ height: 60, resize: 'none' }}
            maxLength={120}
            value={nodeValue}
          />
        )}
      </Transition.Child>
      <div className="grid grid-cols-2 gap-x-6 mt-6 pb-6">
        <Button onClick={handleBack} styleType="Secondary">
          {t('common:back')}
        </Button>
        <Button
          onClick={handleCheckout}
          disabled={!canCheckout}
          loading={checkoutLoding || cartLoading}
        >
          {t('extended_service:checkout')}
        </Button>
      </div>
      <AddressDetailsModal
        key={key}
        title={modalTitle}
        loading={loading}
        areaCodes={areaCodes}
        businessID={businessID}
        token={token}
        districtData={districtData}
        customerID={customerID}
        visible={showAddressModal}
        onCancel={() => {
          setShowAddressModal(false);
          setShowChooseAddressModal(true);
        }}
        loadAddress={loadAddress}
        record={editRecord}
        handleDete={() => {
          setShowAddressModal(false);
          setDeleteConfirmModal(true);
        }}
        handleUpdateRecord={handleUpdateRecord}
      />
      <AddressListModal
        key={editRecord?.id}
        visible={showChooseAddressModal}
        addressList={addressList}
        selectedAddress={selectedAddress}
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
        onCancel={() => setShowChooseAddressModal(false)}
        handleAdd={handleAdd}
        handleUpdateRecord={handleUpdateRecord}
        handleEditRecord={handleEditRecord}
      />
      <AntdModal
        onOk={handleDete}
        type="warn"
        okText={t('common:remove')}
        cancelText={t('common:back')}
        open={deleteConfirmModal}
        onCancel={() => setDeleteConfirmModal(false)}
      >
        <div className="font-bold text-xl mt-4">
          {t('extended_service:remove_address')}
        </div>
        <div className="font-medium text-sm mt-2">
          {t('extended_service:remove_address_tips')}
        </div>
      </AntdModal>
    </StepWrapper>
  );
};

export default CheckOrderWebPage;
