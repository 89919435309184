import React from 'react';
import type { IIconProps } from '@/types/common-types';

const NoDataIcon: React.FC<IIconProps> = (props) => {
    const { className = '', onClick } = props;
    return (
        <div className={`${className}`} onClick={onClick}>
            <svg width="119" height="95" viewBox="0 0 119 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M43.891 45.3008C35.8228 47.7667 28.2095 52.1241 21.8171 58.4925L32.9738 69.6491C36.3016 66.3213 40.1801 63.7117 44.3699 61.8682L43.891 45.3008Z" fill="black" />
                <path d="M42.9809 14.9883C27.5388 18.1007 12.8388 25.6182 0.89209 37.5649L12.0487 48.7216C21.0267 39.7436 31.944 33.9019 43.4598 31.0768L42.9809 14.9883Z" fill="black" />
                <path d="M76.0194 14.9883L75.5405 31.1008C87.0563 33.9259 97.9735 39.7675 106.952 48.7455L118.108 37.5889C106.185 25.6182 91.4615 18.1007 76.0194 14.9883Z" fill="black" />
                <path d="M74.6067 61.8682C78.7964 63.7117 82.6749 66.3213 86.0267 69.673L97.1834 58.5164C90.815 52.148 83.1777 47.7667 75.1095 45.3008L74.6067 61.8682Z" fill="black" />
                <path d="M59.4996 95.0007C64.7886 95.0007 69.0761 90.7132 69.0761 85.4242C69.0761 80.1352 64.7886 75.8477 59.4996 75.8477C54.2107 75.8477 49.9231 80.1352 49.9231 85.4242C49.9231 90.7132 54.2107 95.0007 59.4996 95.0007Z" fill="#0094FF" />
                <path d="M63.2807 67.9933H55.6913C53.7042 67.9933 52.0762 66.4132 52.0283 64.4261L50.2088 3.78273C50.1369 1.72377 51.8128 0 53.8718 0H65.1242C67.1832 0 68.8591 1.69983 68.7872 3.78273L66.9438 64.4261C66.8959 66.4132 65.2679 67.9933 63.2807 67.9933Z" fill="#0094FF" />
            </svg>

        </div>
    );
};

export default NoDataIcon;
