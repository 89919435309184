
import { useEffect, useState } from 'react';
import Header from '@/components/header';
import SideBar from '@/components/side-bar/side-bar';
import { getPlatformConfig } from '@/App';
import ActionType from '@/actions/action-type';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import { IDispatch } from '@/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IReducersState } from '@/reducers';
import { useUserAction } from '@/utils/common';

const LayoutBasic: React.FC = () => {

  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const { p_business_id = '' } = useParams();
  const { pathname, search } = useLocation();
  const withOutValidate = pathname.indexOf('terminal/orders') > -1 && search.indexOf('isApp=true') > -1;
  const loginUser = useSelector((state: IReducersState) => state.account.loginUser);
  const state = useSelector((state: IReducersState) => state.businessState);
  const { menus, isDisableTimer = false } = state;

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const platformData = getPlatformConfig();
  const { dashboardLogo = '' } =  platformData.platform;

  useUserAction(isDisableTimer || withOutValidate);

  useEffect(() => {
    if (p_business_id) {
      dispatch({
        type: ActionType.LOADING_MAIN_DATA,
        payload: {
          navigate,
          businessId: p_business_id,
          withOutValidate,
        }
      });
    }
    // eslint-disable-next-line
  }, [])

  const handleScroll = () => {
    const dom: any = document.getElementsByClassName('rc-dropdown-open')[0];
    if (dom) {
      dom.classList.remove('rc-dropdown-open');
      dom.click();
    }
  };

  if (withOutValidate) return <Outlet />;

  return (
      <div id={'layout'} className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <SideBar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          logoSvg={dashboardLogo}
          items={menus}
        />
        <div className="relative flex flex-col flex-1 overflow-y-hidden overflow-x-hidden">
          <Header
            user={loginUser}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
          <main
            id={'mainBody'}
            onScroll={handleScroll}
            className={`overflow-y-auto h-screen bg-system-background-color hidden_scrollbar`}
          >
            <Outlet />
          </main>
        </div>
      </div>
  );
};
export default LayoutBasic;
