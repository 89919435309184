import styles from '@/pages/content.module.css';
import { useParams } from 'react-router-dom';
import Config from '@/libs/config';


const PersonalInformationCollectionStatement = () => {
  const { lang = 'en' } = useParams();
  const prefix = Config.staticPath;

  return (
    <>
      <head>
        <link
          rel="preload"
          href={`${prefix}/font/Outfit-Light.woff`}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        ></link>
      </head>
      {lang !== 'zh-CN' && lang !== 'zh-HK' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title} text-center`}>
              Personal Information Collection Statement
            </div>
            <div className={`${styles.content} break-all`}>
              <h3>1. Introduction</h3>
              <p>
                Thank you for choosing to use our ride-hailing platform ("the Platform"). In order to provide and improve our services, we need to collect and use certain personal information about you. This Personal Information Collection Statement ("Statement") aims to explain how we collect, use, store, process, and protect your personal information in accordance with the Personal Data (Privacy) Ordinance ("the Ordinance") of the Hong Kong Special Administrative Region.
              </p>
              <h3>2. Data Collection</h3>
              <p>2.1 Types of Data Collected We may collect the following types of personal information:</p>
              <ul>
                <li>Basic identity information: including but not limited to your name, telephone number, email address, and username.</li>
                <li>Transaction and financial information: such as your payment methods, payment records, bank account information, or credit card information.</li>
                <li>Location data: We may collect your actual location information when you use our services.</li>
                <li>Service usage information: including your ride records, service usage habits, and preferences.</li>
                <li>Device and technical information: the type of device you use, operating system, IP address, browser type, and other technical data.</li>
              </ul>
              <p>2.2 Methods of Collection We may collect your personal information through the following methods:</p>
              <ul>
                <li>Information you provide when you create and set up an account.</li>
                <li>Data automatically collected when you use our services or the platform.</li>
                <li>Data automatically collected through cookies and similar technologies.</li>
              </ul>
              <h3>3. Use of Data</h3>
              <p>Your personal information will be used for the following purposes:</p>
              <ul>
                <li>To provide, maintain, and improve our services.</li>
                <li>To process transactions and send related notifications.</li>
                <li>To respond to your inquiries and provide customer support.</li>
                <li>For market analysis, research, and development.</li>
                <li>To comply with legal and regulatory requirements, including identity verification to prevent fraud.</li>
              </ul>
              <h3>4. Disclosure of Data</h3>
              <p>We may disclose your personal information under the following circumstances:</p>
              <ul>
                <li>To third-party service providers so that they can provide services on our behalf.</li>
                <li>As required by law, court orders, or government requests.</li>
                <li>To protect our legal rights, such as in legal proceedings.</li>
              </ul>
              <h3>5. Data Security</h3>
              <p>We take appropriate technical and organizational measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. This includes the use of encryption technology and providing physical security measures.</p>
              <h3>6. Data Retention Period</h3>
              <p>We will retain your personal information as required by the Ordinance and as needed for operational purposes. When your personal information is no longer needed for the purposes described in this Statement, we will securely delete it.</p>
              <h3>7. Your Rights</h3>
              <p>Under the Ordinance, you have the following rights regarding your personal data:</p>
              <ul>
                <li>Right to inquire and access: You have the right to inquire about and access your personal data.</li>
                <li>Right to correction: You can request us to correct any inaccurate personal data.</li>
                <li>Right to object to processing: You have the right to object to our processing of your personal data.</li>
                <li>Right to deletion: Under certain conditions, you can request us to delete your personal data.</li>
                <li>Right to data portability: You have the right to request that we provide a copy of your personal data for transfer between other service providers.</li>
              </ul>
              <p>If you wish to exercise any of the above rights or have any questions about your personal data, please contact us through our customer service.</p>
              <h3>8. Revisions and Updates</h3>
              <p>We may revise this Statement from time to time to reflect our latest privacy practices or changes in legal requirements. The revised statement will be posted on the platform and clearly indicate the effective date. We encourage users to regularly review this Statement to understand how we protect your personal information.</p>
              <h3>9. Contact Information</h3>
              <p>If you have any questions or concerns about this Statement, please contact our customer service department:</p>
              <p>Email: support@wonder.app</p>
              <p>Phone: 4600 4630 </p>
              <p>Address: Suite 05-181, 5/F The Quayside, 77 Hoi Bun Road, Kwun Tong, Kowloon, Hong Kong</p>
              <p>Thank you for reading our Personal Information Collection Statement. We are committed to protecting your privacy and ensuring the security of your personal information.</p>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-CN' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title} text-center`}>个人信息收集声明</div>
            <div className={`${styles.content} break-all`}>
              <h3>1. 引言</h3>
              <p>
                感谢您选择使用我们的网约车平台（“平台”）。为了提供和改善我们的服务，我们需要收集和使用您的某些个人信息。本个人信息收集声明（“声明”）旨在解释我们如何根据香港特别行政区的个人资料（隐私）条例（“条例”）收集、使用、存储、处理和保护您的个人信息。
              </p>
              <h3>2. 数据收集</h3>
              <p>2.1 收集的数据类型 我们可能收集以下类型的个人信息：</p>
              <ul>
                <li>基本身份信息：包括但不限于您的姓名、电话号码、电子邮件地址和用户名。</li>
                <li>交易和财务信息：如您的支付方式、支付记录、银行账户信息或信用卡信息。</li>
                <li>位置数据：当您使用我们的服务时，我们可能会收集您的实际位置信息。</li>
                <li>服务使用信息：包括您的行程记录、服务使用习惯和偏好。</li>
                <li>设备和技术信息：您使用的设备类型、操作系统、IP地址、浏览器类型以及其他技术数据。</li>
              </ul>
              <p>2.2 收集方法 我们可能通过以下方法收集您的个人信息：</p>
              <ul>
                <li>您在创建和设置账户时提供的信息。</li>
                <li>您使用我们的服务或平台时自动收集的数据。</li>
                <li>通过cookies和类似技术自动收集的数据。</li>
              </ul>
              <h3>3. 数据使用</h3>
              <p>您的个人信息将用于以下目的：</p>
              <ul>
                <li>提供、维护和改善我们的服务。</li>
                <li>处理交易并发送相关通知。</li>
                <li>响应您的查询并提供客户支持。</li>
                <li>进行市场分析、研究和开发。</li>
                <li>遵守法律和监管要求，包括进行身份验证以防止欺诈。</li>
              </ul>
              <h3>4. 数据披露</h3>
              <p>在以下情况下，我们可能会披露您的个人信息：</p>
              <ul>
                <li>向第三方服务提供商，以便他们代表我们提供服务。</li>
                <li>根据法律、法庭命令或政府要求。</li>
                <li>保护我们的法律权利，如在法律诉讼中。</li>
              </ul>
              <h3>5. 数据安全</h3>
              <p>我们采取适当的技术和组织措施保护您的个人信息免受未经授权的访问、更改、披露或销毁。这包括使用加密技术和提供物理安全措施。</p>
              <h3>6. 数据保留期</h3>
              <p>我们将根据条例的要求以及运营需要保留您的个人信息。当您的个人信息不再需要用于本声明中描述的目的时，我们将安全地删除它。</p>
              <h3>7. 您的权利</h3>
              <p>根据条例，您对您的个人数据拥有以下权利：</p>
              <ul>
                <li>查询和访问权：您有权查询和访问您的个人数据。</li>
                <li>更正权：您可以要求我们更正任何不准确的个人数据。</li>
                <li>反对处理权：您有权反对我们处理您的个人数据。</li>
                <li>删除权：在某些条件下，您可以要求我们删除您的个人数据。</li>
                <li>数据可携带权：您有权要求我们提供您的个人数据副本，以便在其他服务提供者之间传输。</li>
              </ul>
              <p>如果您希望行使上述任何权利或对您的个人数据有任何疑问，请通过我们的客户服务联系我们。</p>
              <h3>8. 修订和更新</h3>
              <p>我们可能不时修订此声明以反映我们最新的隐私实践或法律要求的变更。修订后的声明将发布在平台上，并明确标示生效日期。我们鼓励用户定期审查此声明以了解我们如何保护您的个人信息。</p>
              <h3>9. 联系信息</h3>
              <p>如果您对本声明有任何疑问或担忧，请通过以下方式联系我们的客户服务部门：</p>
              <p>电子邮件：support@wonder.app </p>
              <p>电话：4600 4630 </p>
              <p>地址：香港观塘海滨道77号港滨汇5楼05-181室</p>
              <p>感谢您阅读我们的个人信息收集声明。我们致力于保护您的隐私并确保您的个人信息安全。</p>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-HK' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title} text-center`}>個人資訊收集聲明</div>
            <div className={`${styles.content} break-all`}>
              <h3>1. 引言</h3>
              <p>
                感謝您選擇使用我們的叫車平台（「平台」）。 為了提供和改善我們的服務，我們需要收集和使用您的某些個人資訊。 本個人資訊收集聲明（「聲明」）旨在解釋我們如何根據香港特別行政區的個人資料（隱私）條例（「條例」）收集、使用、儲存、處理和保護您的個人資訊。
              </p>
              <h3>2. 資料收集</h3>
              <p>2.1 收集的資料類型 我們可能會收集以下類型的個人資訊：</p>
              <ul>
                <li>基本識別資訊：包括但不限於您的姓名、電話號碼、電子郵件地址和使用者名稱。</li>
                <li>交易和財務資訊：如您的付款方式、付款記錄、銀行帳戶資訊或信用卡資訊。</li>
                <li>位置資料：當您使用我們的服務時，我們可能會收集您的實際位置資訊。</li>
                <li>服務使用資訊：包括您的行程記錄、服務使用習慣和偏好。</li>
                <li>設備和技術資訊：您使用的設備類型、作業系統、IP位址、瀏覽器類型以及其他技術資料。</li>
              </ul>
              <p>2.2 收集方法 我們可能透過以下方法收集您的個人資訊：</p>
              <ul>
                <li>您在建立和設定帳戶時提供的資訊。</li>
                <li>您使用我們的服務或平台時自動收集的資料。</li>
                <li>透過cookies和類似技術自動收集的資料。</li>
              </ul>
              <h3>3. 資料使用</h3>
              <p>您的個人資訊將用於以下目的：</p>
              <ul>
                <li>提供、維護和改善我們的服務。</li>
                <li>處理交易並傳送相關通知。</li>
                <li>回應您的查詢並提供客戶支援。</li>
                <li>進行市場分析、研究和開發。</li>
                <li>遵守法律和監管要求，包括進行身份驗證以防止詐欺。</li>
              </ul>
              <h3>4. 資料揭露</h3>
              <p>在以下情況下，我們可能會揭露您的個人資訊：</p>
              <ul>
                <li>向第三方服務提供者，以便他們代表我們提供服務。</li>
                <li>依法律、法庭命令或政府要求。</li>
                <li>保護我們的法律權利，如在法律訴訟中。</li>
              </ul>
              <h3>5. 資料安全</h3>
              <p>我們採取適當的技術和組織措施保護您的個人資訊免受未經授權的存取、變更、揭露或銷毀。 這包括使用加密技術和提供實體安全措施。</p>
              <h3>6. 資料保留期</h3>
              <p>我們將根據條例的要求以及營運需求保留您的個人資訊。 當您的個人資訊不再需要用於本聲明中所述的目的時，我們將安全地刪除它。</p>
              <h3>7. 您的權利</h3>
              <p>根據條例，您對您的個人資料擁有以下權利：</p>
              <ul>
                <li>查詢和存取權：您有權查詢和存取您的個人資料。</li>
                <li>更正權：您可以要求我們更正任何不準確的個人資料。</li>
                <li>反對處理權：您有權反對我們處理您的個人資料。</li>
                <li>刪除權：在某些條件下，您可以要求我們刪除您的個人資料。</li>
                <li>資料可攜帶權：您有權要求我們提供您的個人資料副本，以便在其他服務提供者之間傳輸。</li>
              </ul>
              <p>如果您希望行使上述任何權利或對您的個人資料有任何疑問，請透過我們的客戶服務與我們聯絡。</p>
              <h3>8. 修訂與更新</h3>
              <p>我們可能不時修訂此聲明以反映我們最新的隱私權實務或法律要求的變更。 修訂後的聲明將發佈在本平台上，並明確標示生效日期。 我們鼓勵用戶定期審查此聲明以了解我們如何保護您的個人資訊。</p>
              <h3>9. 聯絡資訊</h3>
              <p>如果您對本聲明有任何疑問或擔憂，請透過以下方式聯絡我們的客戶服務部門：</p>
              <p>電子郵件：support@wonder.app </p>
              <p>電話：4600 4630 </p>
              <p>地址：香港觀塘海濱道77號港濱匯5樓05-181室</p>
              <p>感謝您閱讀我們的個人資訊收集聲明。 我們致力於保護您的隱私並確保您的個人資訊安全。</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PersonalInformationCollectionStatement;
