import React from 'react';
import type { IIconProps } from '@/types/common-types';

const DeleteCreditCardPCIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M14.5562 3.96094H1.33398M13.2597 5.90538L12.9019 11.272C12.7642 13.3363 12.6958 14.3684 12.023 14.9976C11.3502 15.6276 10.315 15.6276 8.2461 15.6276H7.6441C5.57521 15.6276 4.53998 15.6276 3.86721 14.9976C3.19443 14.3684 3.12521 13.3363 2.98832 11.272L2.63054 5.90538"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M3.66797 3.96126H3.75352C4.06654 3.95327 4.36985 3.85099 4.6238 3.66782C4.87774 3.48464 5.0705 3.2291 5.17686 2.9346L5.2033 2.85449L5.27875 2.62815C5.3433 2.43449 5.37597 2.33804 5.41875 2.2556C5.50291 2.09412 5.6237 1.95459 5.77145 1.84816C5.9192 1.74174 6.08982 1.67138 6.26964 1.64271C6.36064 1.62793 6.46252 1.62793 6.6663 1.62793H9.22519C9.42897 1.62793 9.53086 1.62793 9.62186 1.64271C9.80168 1.67138 9.97229 1.74174 10.12 1.84816C10.2678 1.95459 10.3886 2.09412 10.4727 2.2556C10.5155 2.33804 10.5482 2.43449 10.6127 2.62815L10.6882 2.85449C10.7868 3.18214 10.9906 3.46816 11.2682 3.66824C11.5457 3.86833 11.8815 3.97132 12.2235 3.96126"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </svg>
    </div>
  );
};

export default DeleteCreditCardPCIcon;
