import type { PayeeContentBaseProps } from '../../type';
import type { IObject } from '@/types/common-types';
import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import DetailsItem from '@/pages/account-balance/components/details-item-component';
import DynamicsItem from './dynamics-item';
import FpsDetailsIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/fps-transfer-details-icon';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';


const FpsTransferPayee = (props: PayeeContentBaseProps) => {
  const { businessId, record, initLoading } = props;
  const { t } = useTranslation("account_balance");
  const { lang = 'en' } = useParams();
  const [showArray, setShowArray] = useState<IObject[]>([]);
  const { payee = {}, i18n = {} } = record || {};
  const { nickname = '', payout_accounts = {}, type } = payee || {};
  const {
    identifier_1_value: identifier1Value = '',
    identifier_2_value: identifier2Value = '',
    identifier_3_value: identifier3Value = '',
    transfer_methods: payoutAccountTransferMethod = {},
    banks
  } = payout_accounts || {};

  const {
    identifier_1_name: identifier1Name = '',
    identifier_2_name: identifier2Name = '',
    identifier_3_name: identifier3Name = '',
    identifier_1_type: identifier1Type = '',
    identifier_2_type: identifier2Type = '',
    identifier_3_type: identifier3Type = '',
  } = payoutAccountTransferMethod || {};

  useEffect(() => {
    getDynamicsIdentifier();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const getDynamicsIdentifier = () => {
    const createiIdentifierAarry: IObject[] = [];

    if (identifier1Value && identifier1Name) {
      let labelValue = '';

      if (i18n[identifier1Name]) {
        labelValue = i18n[identifier1Name][lang];
      }

      if (!labelValue) {
        labelValue = identifier1Name;
      }

      createiIdentifierAarry.push({
        label: labelValue,
        value: `${banks.bank_code} ${banks.bank_name}`,
        type: identifier1Type,
      });
    }
    if (identifier2Value && identifier2Name) {
      let labelValue = '';

      if (i18n[identifier2Name]) {
        labelValue = i18n[identifier2Name][lang];
      }

      if (!labelValue) {
        labelValue = identifier2Name;
      }
      createiIdentifierAarry.push({
        label: labelValue,
        value: identifier2Value,
        type: identifier2Type,
      });
    }
    if (identifier3Value && identifier3Name) {
      let labelValue = '';

      if (i18n[identifier3Name]) {
        labelValue = i18n[identifier3Name][lang];
      }

      if (!labelValue) {
        labelValue = identifier3Name;
      }
      createiIdentifierAarry.push({
        label: labelValue,
        value: identifier3Value,
        type: identifier3Type,
      });
    }
    setShowArray(createiIdentifierAarry);
  };

  return (
    <div className="flex w-full space-x-6">
      <Transition.Child
        className="flex-1"
        enter="duration-350 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-8 opacity-60"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div
          className={`w-full rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit`}
        >
          <div className="font-semibold text-lg text-color-text-1 flex items-center md:text-xl">
            {t('account_balance:payee')}
          </div>
          <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
              <DetailsItem
                title={t('account_balance:nickname')}
                content={nickname}
                loading={initLoading}
              />
              <DetailsItem
                title={t('account_balance:payee_type')}
                content={type}
                loading={initLoading}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
              <DetailsItem
                title={t('account_balance:transfer_method')}
                loading={initLoading}
                content={
                  <div className="py-2 text-color-text-1 font-medium pr-6 flex items-center space-x-2">
                    <span className="group">
                      <FpsDetailsIcon className="text-primary-color" />
                    </span>
                    <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-color-text-1 font-medium text-sm">
                      {'FPS'}
                    </span>
                  </div>
                }
                contentClass={'text-color-text-2 font-medium pr-6 text-sm'}
              />
              {showArray.length > 0 &&
                showArray.map((item: IObject) => (
                  <DynamicsItem
                    key={`${item.label}_${item.value}`}
                    businessId={businessId}
                    label={item.label}
                    value={item.value}
                    type={item.type}
                    loading={initLoading}
                  />
                ))}
            </div>
          </>
        </div>
      </Transition.Child>
    </div>
  );
};
export default FpsTransferPayee;
