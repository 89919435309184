import type { ISideBarProps } from '@/types/side-bar-type';
import { ILoginUser } from '@/types/account-type';
import type { IObject } from '@/types/common-types';
import type { ReactElement } from 'react';

export interface IPaymentDetails {
  menuData: ISideBarProps;
  user: ILoginUser;
  businessID: string;
  recordId: string;
  from?: string;
  dayEndId?: string;
  isLinkage?: boolean;
}

export interface IPreAuthDetailsContent {
  record: IObject;
  type: IPaymentType;
  showType: ReactElement;
  initLoading: boolean;
}

export interface IDefaultDetailsContent {
  record: IObject;
  showType: ReactElement;
  settledAmount: string;
  initLoading: boolean;
  viewDetails: (id: string) => void;
}

export interface IVoidDetailsContent {
  record: IObject;
  showType: ReactElement;
  settledAmount: string;
  initLoading: boolean;
}

export interface IAdjustmentFeeDetails {
  menuData: ISideBarProps;
  user: ILoginUser;
  businessID: string;
  recordId: string;
}

export enum IPaymentType {
  SALE = 'sale',
  VIOD = 'void',
  REFUND = 'refund',
  PRE_AUTH = 'pre_auth',
  PRE_AUTH_COMPLETE = 'pre_auth_complete',
}
