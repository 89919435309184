import type { InputHTMLAttributes } from 'react';
import type { IFileObject } from '@/types/common-types';
import type { FieldError } from 'react-hook-form';
import { useEffect, useState } from 'react';
// import NextImage from 'next/image';
import Upload from './upload';
import { getCookie } from '@/utils/index';
import Config from '@/libs/config';
import { Constants } from '@/constants';
import api from '@/libs/api';
import ImagePreview from './image-preview';
import PreviewIcon from '@/components/svg/preview-icon';
import DownloadIcon from '@/components/svg/download-icon';
import DeleteIcon from '@/components/svg/delete-icon';
import NoImageIcon from './icons/no-image-icon';
import { useTranslation } from 'react-i18next';
import './index.css'
export interface IImageInputProps
  extends Omit<
    InputHTMLAttributes<HTMLElement>,
    'onChange' | 'defaultValue' | 'value'
  > {
  name: string;
  proportion: number;
  rowQuantity: number;
  multiValue: boolean;
  maxSize?: number;
  maxCount?: number;
  shape?: 'Rectangular' | 'Circular';
  label?: string;
  disabled?: boolean;
  onChange?: (value: IFileObject[] | IFileObject) => void;
  error?: FieldError;
  defaultValue?: IFileObject[];
  value?: IFileObject[];
}

const aspect: { [key: number]: string } = {
  1: 'aspect-square',
  2: 'aspect-4/3',
  3: 'aspect-3/4',
};

const ImageInput: React.FC<IImageInputProps> = (props) => {
  const {
    name,
    rowQuantity = 4,
    multiValue = 2,
    proportion = 1,
    shape,
    onChange,
    error,
    disabled = false,
    maxSize = 200,
    maxCount = 10,
    // defaultValue,
    label,
    value,
  } = props;

  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState('');
  const [files, setFiles] = useState<IFileObject[]>(value ? value : []);
  const { t } = useTranslation();

  useEffect(() => {
    if (value) {
      setFiles(value ? value : []);
    }
  }, [value]);

  const handlefilesUpdate = (
    fileObject: IFileObject,
    type: 'ADD' | 'DELETE'
  ) => {
    let newFiles: IFileObject[] = [];
    if (type === 'ADD') {
      newFiles = files;
      newFiles.push(fileObject);
    } else if (type === 'DELETE') {
      files.forEach((file) => {
        if (file.id !== fileObject.id) {
          newFiles.push(file);
        }
      });
    }
    setFiles(newFiles);
    if (onChange) {
      onChange(newFiles);
    }
    setLoading(false);
  };

  const onDownloadImage = (file: string, fileName: string) => {
    const downloadUrl = file && file.split('?')[0];
    const a = document.createElement('a');
    a.download = fileName;
    a.style.display = 'none';
    const image = new Image();
    image.src = downloadUrl + '?v=' + Math.random();
    image.crossOrigin = '*';

    image.onload = () => {
      const base64 = getBase64Images(image);
      a.href = base64;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
  };

  const getBase64Images = (image: any) => {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d')!;
    context.drawImage(image, 0, 0, image.width, image.height);
    const url = canvas.toDataURL('image/png', 0);
    return url;
  };

  const handleDownload = (file: IFileObject, fileName: string) => {
    if (file) {
      onDownloadImage(file.fileUrl, fileName);
    } else {
      console.info(1);
    }
  };

  const handleDelete = async (file: IFileObject) => {
    if (file && file.id) {
      const token = getCookie(Constants.TOKEN) || '';
      try {
        await api.remove(
          // `${Config.urls.baseDomain}/api/mb5/files/${file.id}`,
          `${Config.urls.gatewayUrl}/api/directus/treasury-services/files/${file.id}`,
          {
            method: 'DELETE',
            headers: {
              'x-user-access-token': token,
            },
          }
        ).then((data: any) => data.json());
      } catch (e) { }
    }
  };

  return (
    <>
      <div
        className={`block gap-4 sm:grid`}
        style={{
          gridTemplateColumns: `repeat(${rowQuantity}, minmax(0, 1fr))`,
        }}
      >
        {files.map((file, index) => {
          const fileArray = file?.fileUrl.split('/');
          const fileLast = fileArray[fileArray.length - 1].split('.');
          const fileName = fileLast[0];
          const isLoading = loadingImage && currentIndex === index;

          return (
            <div className="rounded-full" key={`${file}_${index}`}>
              <div
                className={`${shape === 'Circular' ? aspect[1] : aspect[proportion]
                  } ${isLoading ? 'loading' : ''} relative`}
              >
                <div>
                  <img
                    className={`rounded absolute w-[100%] h-[100%]  ${shape === 'Circular' ? 'rounded-full' : ''
                      }`}
                    style={{
                      objectFit: 'cover'
                    }}
                    src={file.fileUrl}
                    loading="lazy"
                    alt=""
                    onLoad={() => {
                      setLoadingImage(false);
                      setCurrentIndex(index);
                    }}
                  />
                </div>
                {isLoading && (
                  <div className="flex justify-center items-center text-sm font-medium text-primary-color">
                    {t('common:loading')}
                  </div>
                )}
                {!isLoading && (
                  <div
                    className={`absolute bottom-0 left-0 w-[100%] flex justify-center opacity-0 p-2 gap-x-2 hover:opacity-100 ${shape === 'Circular' ? 'items-center' : 'items-end'
                      }`}
                  >
                    <PreviewIcon
                      className="text-icon-color cursor-pointer"
                      size={40}
                      onClick={() => {
                        setShow(true);
                        setUrl(file.fileUrl);
                      }}
                    />
                    <DownloadIcon
                      className="text-icon-color bg-icon-color/10 rounded-full cursor-pointer p-2.5"
                      size={20}
                      onClick={() => handleDownload(file, fileName)}
                    />
                    {!disabled && (
                      <DeleteIcon
                        className="text-error-color cursor-pointer"
                        size={40}
                        onClick={() => {
                          handlefilesUpdate(file, 'DELETE');
                          handleDelete(file);
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
        {files.length === 0 && disabled && (
          <div className="aspect-square">
            <div className="flex flex-col justify-center items-center">
              <NoImageIcon />
              <div className="mt-4 text-sm text-color-text-2 font-normal">
                {t('common:no_image_yet')}
              </div>
            </div>
          </div>
        )}
        {files.length < maxCount &&
          (multiValue || files.length <= 0) &&
          !disabled && (
            <Upload
              className={aspect[proportion]}
              name={name}
              type="IMAGE"
              loading={loading}
              setLoading={setLoading}
              maxSize={maxSize}
              handlefilesUpdate={handlefilesUpdate}
              shape={shape}
              label={label}
            />
          )}
      </div>
      {error && (
        <div className="mt-1 text-sm text-error-color" id="input-error">
          {error.message}
        </div>
      )}
      <ImagePreview show={show} setShowModal={() => setShow(false)} url={url} />
    </>
  );
};

export default ImageInput;
