import React from 'react';
import type { IIconProps } from '@/types/common-types';

const MasterCardIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="4em"
        height="3em"
        viewBox="0 0 64 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38.6802 11.1586L24.9141 11.207L25.3266 36.5661L39.0927 36.5177L38.6802 11.1586Z"
          fill="#FF5F00"
        />
        <path
          d="M25.8267 23.9448C25.7424 18.787 28.0393 14.204 31.6361 11.2311C28.922 9.08771 25.519 7.7989 21.83 7.81182C13.0903 7.84243 6.13747 15.088 6.28324 24.0132C6.42902 32.9383 13.6177 40.1344 22.3574 40.1038C26.0465 40.0909 29.4069 38.7784 32.0507 36.6162C28.3579 33.7138 25.9109 29.1025 25.8267 23.9448Z"
          fill="#EB001B"
        />
        <path
          d="M57.738 23.7245C57.8837 32.6496 50.9309 39.8952 42.1913 39.9258C38.5022 39.9388 35.0992 38.65 32.3851 36.5065C36.0258 33.5335 38.2788 28.9507 38.1945 23.7929C38.1103 18.6352 35.664 14.0688 31.9705 11.1214C34.6143 8.95924 37.9747 7.64674 41.6638 7.63382C50.4035 7.60322 57.5929 14.8442 57.738 23.7245Z"
          fill="#F79E1B"
        />
      </svg>
    </div>
  );
};

export default MasterCardIcon;
