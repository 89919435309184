import './App.css';
import { RouterProvider } from 'react-router-dom';
// import router from '@/pages/router';
import { Provider } from 'react-redux';
import createStore from './store';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { initPlatformConfig } from './utils/platform-utils';
import routes from './routers'

const platformConfig = initPlatformConfig();
export const getPlatformConfig = () => platformConfig;

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={createStore()}>
        <RouterProvider
          router={routes}
        />
      </Provider>
    </I18nextProvider>
  );
}

export default App;
