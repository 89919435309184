import type { IFileObject } from '@/types/common-types';
import { IObject } from '@/types/common-types';
import Tabs from '@/components/common/tabs';
import StoreSettingsOverview from './store-settings-overview';
import Button from '@/components/common/button';
import { useEffect, useState } from 'react';
import { findNode } from '@/utils';
import { getCookie } from '@/utils/index';
import { Constants } from '@/constants';
import { Alert } from '@/components/common/alert/alert';
import { useForm } from 'react-hook-form';
import StoreSettingAdvancedSettings from './store-settings-advanced-settings';
import FeesSettlement from './fees-settlement';
import api from '@/libs/api';
import Config from '@/libs/config';
import { initBusinessPictureData } from '@/utils/business-utils';
import BackIcon from '@/components/svg/back-icon';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import ModalEmpty from '@/components/common/modal/modal-empty';
import WarningIcon from '@/components/svg/warning-side-icon';
import BusinessSettingsSkeleton from './business-settings-skeleton';
import { useParams } from 'react-router-dom';
import { loadAreaCodesData } from '@/data/area-codes';
import { useDispatch, useSelector } from 'react-redux';
import { IReducersState } from '@/reducers';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';

const TabPane = Tabs.TabPane;

const StoreSettingFormView = () => {
  const { t } = useTranslation(["settings", "business_account_settings", "account_balance"]);
  const permissions = useSelector((state: IReducersState) => state.businessState.permissions);
  const { lang = 'en', p_business_id: businessID = '' } = useParams();
  const dispatch: IDispatch = useDispatch();
  const Form = useForm({
    mode: 'onChange',
  });

  const { getValues, reset } = Form;
  const [isView, setIsView] = useState(true);
  const token = getCookie(Constants.TOKEN) || '';
  const [loading, setLoading] = useState<boolean>(true);
  const [saveDataloading, setSaveDataLoading] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<string>('');
  const [areaCodes, setareaCodes] = useState<any>('');
  // const [permissions, setpermissions] = useState<any>('');
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [cancelDayEndChangeModal, setCancelDayEndChangeModall] =
    useState<boolean>(false);

  // let initData: any;
  const [initBusinessData, setInitData] = useState<IObject>({});

  useEffect(() => {
    getBusinessInitData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('settings:settings'),
          key: '1',
          type: 'label',
        },
        {
          name: t('business_account_settings:business_settings'),
          key: '2',
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  const getBusinessInitData = async (loading: boolean) => {
    const allPromise: any[] = [];
    if (loading) {
      setLoading(true);
    }

    let promiseIndex = -1;
    let businessDataIndex = -1;
    let businessMerchantIndex = -1;
    let autoDisbursementIndex = -1;

    businessDataIndex = ++promiseIndex;

    allPromise.push(
      api.get(
        `${Config.urls.gatewayUrl}/api/registry/businesses/${businessID}`,
        {
          headers: {
            'x-user-access-token': token,
            'x-p-business-id': businessID,
          },
        }
      )
    );

    businessMerchantIndex = ++promiseIndex;
    allPromise.push(
      api.get(
        `${Config.urls.gatewayUrl}/api/registry/bms-settings?microService=wonder-registry&collection=business_merchant_services_settings`,
        {
          headers: {
            'x-user-access-token': token,
            'x-p-business-id': businessID,
          },
        }
      )
    );

    autoDisbursementIndex = ++promiseIndex;
    allPromise.push(
      api.get(
        `${Config.urls.gatewayUrl}/api/treasury-services/api/scheduled_payout_configs`,
        {
          headers: {
            'x-user-access-token': token,
            'x-p-business-id': businessID,
            'X-I18N-LANG': lang === 'en' ? 'en-US' : lang,
          },
        }
      )
    );

    allPromise.push(
      loadAreaCodesData(),
    );

    const resultData = await Promise.all(allPromise);
    let initBusinessData: any;
    if (resultData && resultData.length > 0) {
      setareaCodes(resultData[3] ?? [])
      if (businessDataIndex > -1 && resultData[businessDataIndex]) {
        const { data } = resultData[businessDataIndex] || {};
        const {
          business_pictures = [],
          translations = [],
          phone_country_code: CountryCode,
          business_phone: phone,
        } = data || {};
        const { alpha_2 } = CountryCode || {};
        const dialCode = findNode<any>(resultData[3], 'isoAlpha2Code', alpha_2);
        const { phoneCountryCode } = dialCode || {};
        const businessPhone =
          phoneCountryCode && phone
            ? `+${phoneCountryCode} ${phone}`
            : phoneCountryCode
              ? `+${phoneCountryCode} `
              : `${phone}`;
        data.phone = businessPhone;
        const name: { [key: string]: string } = {
          'en-US': data.business_name || '',
          'zh-HK': '',
          'zh-CN': '',
        };
        const address1: { [key: string]: string } = {
          'en-US': data.address_1 || '',
          'zh-HK': '',
          'zh-CN': '',
        };
        const address2: { [key: string]: string } = {
          'en-US': data.address_2 || '',
          'zh-HK': '',
          'zh-CN': '',
        };
        const city: { [key: string]: string } = {
          'en-US': data.city || '',
          'zh-HK': '',
          'zh-CN': '',
        };
        const businessHighlight: { [key: string]: string } = {
          'en-US': data.business_highlight || '',
          'zh-HK': '',
          'zh-CN': '',
        };
        translations.forEach((item: any) => {
          const languagesCode = item['languages_code'];
          if (['en-US', 'zh-CN', 'zh-HK'].includes(languagesCode)) {
            name[languagesCode] = item['business_name'] || '';
            address1[languagesCode] = item['address_1'] || '';
            address2[languagesCode] = item['address_2'] || '';
            city[languagesCode] = item['city'] || '';
            businessHighlight[languagesCode] = item['business_highlight'] || '';
          }
        });
        data.business_name = name;
        data.address_1 = address1;
        data.address_2 = address2;
        data.city = city;
        data.business_highlight = businessHighlight;
        const businessPictures: IFileObject[] =
          initBusinessPictureData(business_pictures);
        initBusinessData = data;
        initBusinessData.businessPictures = businessPictures;
        initBusinessData.isChainOperatingEntity =
          data.settlement_entity_type === 'Chain Operating Entity';
      }
      if (businessMerchantIndex > -1 && resultData[businessMerchantIndex]) {
        const { data: businessMerchantData } =
          resultData[businessMerchantIndex] || {};
        if (businessMerchantData && businessMerchantData.id) {
          initBusinessData.merchant_services_settings_id =
            businessMerchantData.id;
          initBusinessData.threeds_enabled =
            businessMerchantData.threeds_enabled;
          initBusinessData.threeds_threshold_amount =
            businessMerchantData.threeds_threshold_amount || 0;
          initBusinessData.surcharge_fee = businessMerchantData.surcharge_fee;
          initBusinessData.percentage_surcharge_fee = Number(
            businessMerchantData.percentage_surcharge_fee * 100
          ).toFixed(2);
          initBusinessData.invoice_link_tips_enabled =
            businessMerchantData.invoice_link_tips_enabled;
          initBusinessData.day_end_at = businessMerchantData.day_end_at;
          initBusinessData.octopus_enabled = false;
          initBusinessData.fixed_surcharge_fee_enabled = businessMerchantData.fixed_surcharge_fee_enabled;
        }
      }
      if (autoDisbursementIndex > -1 && resultData[autoDisbursementIndex]) {
        const { data: currentData } = resultData[autoDisbursementIndex] || [];
        if (currentData && currentData.business_id) {
          initBusinessData.auto_disbursement_cycle =
            currentData.cycle || 'Every Day';
          initBusinessData.auto_disbursement_status = currentData.status;
          initBusinessData.payee_id = currentData.payee.id;
          const isChainOperatingEntity =
            initBusinessData.settlement_entity_type === 'Chain Operating Entity';
          if (currentData.payee && currentData.payee.payout_accounts) {
            const payoutAccounts = currentData.payee.payout_accounts;
            const bankData = payoutAccounts.banks;
            if (isChainOperatingEntity) {
              initBusinessData.bankName = payoutAccounts.business_name;
              initBusinessData.bankCode = payoutAccounts.identifier_1_value;
            } else {
              initBusinessData.bankName = bankData.bank_name;
              initBusinessData.bankCode = payoutAccounts.identifier_3_value;
            }
          }
        }
      }
      setInitData(initBusinessData);
      reset({
        ...initBusinessData,
      });
    }
    setLoading(false);
  };

  const handleBack = () => {
    setIsView(true);
    reset({
      ...initBusinessData,
    });
  };

  const handleSaveChages = () => {
    const values = getValues();
    if (values.day_end_at && initBusinessData.day_end_at) {
      const newDayEnd = `${dayjs(new Date()).format('YYYY-MM-DD')} ${values.day_end_at
        }`;
      const oldDayEnd = `${dayjs(new Date()).format('YYYY-MM-DD')} ${initBusinessData.day_end_at
        }`;
      if (dayjs(newDayEnd).format() !== dayjs(oldDayEnd).format()) {
        setCancelDayEndChangeModall(true);
        return;
      } else {
        handleSave();
      }
    } else {
      handleSave();
    }
  };

  const handleSave = async () => {
    const values = getValues();
    const token = getCookie(Constants.TOKEN) || '';
    const picturesRes = handlePictures();
    let phoneCountryCode = '';

    const translationsCreated: any[] = [];
    const translationsUpdate: any[] = [];
    const keys = ['en-US', 'zh-CN', 'zh-HK'];
    if (!values.business_name['en-US']) return;
    if (
      activeIndex === 'advancedSettings' &&
      values.auto_disbursement_status === 'Active' &&
      !values.payee_id &&
      !initBusinessData.payee_id
    )
      return;
    if (
      values.surcharge_fee &&
      (values.percentage_surcharge_fee - 5 > 0 ||
        values.percentage_surcharge_fee === 0)
    )
      return;

    keys.forEach((item) => {
      const record = {
        business_name: values.business_name[item] || '',
        address_1: values.address_1[item] || '',
        address_2: values.address_2[item] || '',
        city: values.city[item] || '',
        business_highlight: values.business_highlight[item] || '',
        languages_code: { code: item },
      };

      if (initBusinessData?.translations.length > 0) {
        const translation = findNode<any>(
          initBusinessData.translations,
          'languages_code',
          item
        );

        if (translation && translation.id) {
          translationsUpdate.push({
            ...record,
            ...{
              id: translation.id,
              business_id: businessID,
            },
          });
        } else {
          translationsCreated.push(record);
        }
      } else {
        translationsCreated.push(record);
      }
    });
    if (values.phone) {
      let dialCode = values.phone.split(' ')[0];
      const phone = values.phone.split(' ')[1];
      values.phone = phone;
      dialCode = dialCode.replace('+', '');
      if (dialCode) {
        const countryCode = findNode<any>(
          areaCodes,
          'phoneCountryCode',
          dialCode
        );
        phoneCountryCode = countryCode?.isoAlpha2Code;
      }
    }
    setSaveDataLoading(true);
    try {
      const businessData = await api.post(
        `${Config.urls.gatewayUrl}/api/registry/businesses`,
        {
          address_1: values.address_1['en-US'],
          address_2: values.address_2['en-US'],
          business_highlight: values.business_highlight['en-US'],
          business_name: values.business_name['en-US'],
          business_pictures_create: picturesRes.create,
          business_pictures_delete: picturesRes.delete,
          city: values.city['en-US'],
          business_email: values.business_email,
          business_phone: values.phone,
          translations_create: translationsCreated,
          translations_update: translationsUpdate,
          translations_delete: [],
          phone_country_code:
            phoneCountryCode ||
            initBusinessData?.phone_country_code?.alpha_2 ||
            '',
          country: values.country?.alpha_2
            ? values.country?.alpha_2
            : initBusinessData.country?.alpha_2,
          // state: values.state,
          // zip_code: values.zip_code,
          // default_currency: values.default_currency.currency_code,
          // timezone: values.timezone,
          p_business_id: businessID,
        },
        {
          headers: {
            'x-user-access-token': token,
            'content-type': 'application/json',
            'x-p-business-id': businessID,
            'X-I18N-LANG': lang === 'en' ? 'en-US' : lang,
          },

        }
      );

      if (values.day_end_at) {
        const params: any = {
          // threeds_enabled: values.threeds_enabled,
          // threeds_threshold_amount: parseFloat(
          //   values.threeds_threshold_amount
          // ),
          invoice_link_tips_enabled: values.invoice_link_tips_enabled,
          day_end_at: values.day_end_at,
          surcharge_fee: values.surcharge_fee,
          p_business_id: businessID,
          percentage_surcharge_fee: Number(
            values.percentage_surcharge_fee / 100
          ).toFixed(4),
        };
        if (!values.surcharge_fee) {
          delete params.percentage_surcharge_fee;
        }
        await api.patch(
          `${Config.urls.gatewayUrl}/api/registry/bms-settings/${initBusinessData.merchant_services_settings_id}`,
          { ...params },
          {
            headers: {
              'x-user-access-token': token,
              'x-p-business-id': businessID,
              'Content-Type': 'application/json; charset=utf-8',
              'X-I18N-LANG': lang === 'en' ? 'en-US' : lang,
            },
          }
        );
      }
      if (
        values.auto_disbursement_status ||
        values.payee_id ||
        values.auto_disbursement_cycle
      ) {
        await api.put(
          `${Config.urls.gatewayUrl}/api/treasury-services/api/scheduled_payout_configs`,
          {
            payee: values.payee_id
              ? values.payee_id
              : initBusinessData.payee_id,
            status: values.auto_disbursement_status,
            cycle: values.auto_disbursement_cycle,
            currency: initBusinessData.default_currency?.currency_code,
          },
          {
            headers: {
              'x-user-access-token': token,
              'x-p-business-id': businessID,
              'Content-Type': 'application/json; charset=utf-8',
            },

          }
        );
      }

      const { data } = businessData;
      if (data && data.id) {
        await getBusinessInitData(false);
        Alert.success({
          message: t('common:changes_saved'),
          position: 'default',
          wrapClass: 'top-px',
        });
        setIsView(true);
      } else {
        Alert.error({
          message: t('common:unable_save_changes'),
          position: 'default',
          wrapClass: 'top-px',
        });
      }
      setSaveDataLoading(false);
    } catch (error) {
      setSaveDataLoading(false);
      console.error(error);
    }
  };

  const handlePictures = () => {
    const initData: IFileObject[] = initBusinessData.businessPictures || [];
    const newData: IFileObject[] = getValues().businessPictures || [];
    const initDataMap: Map<string, IFileObject> = new Map();
    const createArry: string[] = [];
    const deleteArry: string[] = [];
    if (Array.isArray(initData) && initData.length > 0) {
      initData.forEach((item) => {
        initDataMap.set(item.id, item);
      });
    }

    if (Array.isArray(newData) && newData.length > 0) {
      newData.forEach((item) => {
        if (!initDataMap.has(item.id)) {
          // new data
          // const newObj: IObject = {};
          createArry.push(item.id);
        } else {
          // old data
          initDataMap.delete(item.id);
        }
      });

      [...initDataMap.values()].forEach((item) => {
        if (item.id) {
          deleteArry.push(item.id);
        }
      });
    }

    if (newData.length === 0) {
      // delete all
      [...initDataMap.values()].forEach((item) => {
        if (item.id) {
          deleteArry.push(item.id);
        }
      });
    }

    return {
      create: createArry,
      delete: deleteArry,
    };
  };

  const handleCancel = () => {
    setCancelModal(true);
  };

  const handleKeepEdit = () => {
    setCancelModal(false);
  };

  const handleDiscardChanges = () => {
    setCancelModal(false);
    setCancelDayEndChangeModall(false);
    setIsView(true);
    reset({
      ...initBusinessData,
    });
  };



  return (
    <>
      {loading && <BusinessSettingsSkeleton />}
      {!loading && (
        <div className="px-8 py-6 overflow-y-scroll">
          <div className="flex mb-6 justify-between">
            <div className="flex">
              {!isView && (
                <div className="bg-disabled-color shadow-box-drop rounded-full w-10 h-10 flex items-center justify-center cursor-pointer mr-2.5">
                  <BackIcon
                    className="hover:text-primary-color"
                    onClick={handleBack}
                  />
                </div>
              )}
              <div className="text-3xl leading-10 font-bold text-color-text-1">
                {t('business_account_settings:business_settings')}
              </div>
            </div>
            <div className="flex space-x-4">
              <>
                {!isView && (
                  <Button onClick={handleCancel} styleType="Border">
                    {t('common:cancel')}
                  </Button>
                )}
                {!isView && (
                  <Button onClick={handleSaveChages} loading={saveDataloading}>
                    {t('common:save')}
                  </Button>
                )}
                {isView && permissions.includes('store_settings:update') && (
                  <Button onClick={() => setIsView(false)} disabled={loading}>
                    {t('common:edit')}
                  </Button>
                )}
              </>
            </div>
          </div>
          <Tabs
            activeIndex="overview"
            onClickTab={(value: any) => setActiveIndex(value)}
          >
            <TabPane
              key="overview"
              title={t('business_account_settings:business_information')}
            >
              <StoreSettingsOverview
                areaCodes={areaCodes}
                disabled={isView}
                record={initBusinessData}
                useFormReturn={Form}
                loading={loading}
              />
            </TabPane>

            <TabPane
              key="advancedSettings"
              title={t('settings:advanced_settings')}
            >
              <StoreSettingAdvancedSettings
                areaCodes={areaCodes}
                disabled={isView}
                record={initBusinessData}
                useFormReturn={Form}
                businessID={businessID}
              />
            </TabPane>

            <TabPane
              key="feesSettlement"
              title={t('business_account_settings:fees_settlement')}
            >
              <FeesSettlement
                areaCodes={areaCodes}
                disabled={isView}
                record={initBusinessData}
                useFormReturn={Form}
                businessID={businessID}
              />
            </TabPane>
          </Tabs>
        </div>
      )}
      <ModalEmpty
        visible={cancelModal}
        onCancel={() => {
          setCancelModal(false);
        }}
        showCloseIcon={false}
      >
        <div className="text-center">
          <div className="justify-center flex">
            <WarningIcon />
          </div>
          <div className="text-xl font-bold text-color-text-1">
            {t('business_account_settings:unsaved_changes')}
          </div>
          <div className="mt-2 text-sm font-medium text-color-text-1">
            {t('business_account_settings:leave_this_page')}
          </div>
          <Button onClick={handleKeepEdit} className="mt-6 w-full">
            {t('business_account_settings:keep_edit')}
          </Button>
          <Button
            onClick={handleDiscardChanges}
            styleType="Border"
            className="mt-4 w-full text-color-text-1"
          >
            {t('business_account_settings:discard_changes')}
          </Button>
        </div>
      </ModalEmpty>
      <ModalEmpty
        visible={cancelDayEndChangeModal}
        onCancel={() => {
          setCancelDayEndChangeModall(false);
        }}
        showCloseIcon={false}
      >
        <div className="text-center">
          <div className="justify-center flex">
            <WarningIcon />
          </div>
          <div className="text-xl font-bold text-color-text-1">
            {t('business_account_settings:make_change_tips_title')}
          </div>
          <div className="mt-2 text-sm font-medium text-color-text-2">
            {t('business_account_settings:make_change_tips_content')}
          </div>
          <div className="mt-2 text-sm font-medium text-error-color">
            {t('business_account_settings:make_change_tips_sub_title')}
          </div>
          <Button
            onClick={() => {
              setCancelDayEndChangeModall(false);
              handleSave();
            }}
            className="mt-6 w-full"
          >
            {t('common:confirm')}
          </Button>
          <Button
            onClick={handleDiscardChanges}
            styleType="Border"
            className="mt-4 w-full"
          >
            {t('common:cancel')}
          </Button>
        </div>
      </ModalEmpty>
    </>
  );
};

export default StoreSettingFormView;
