import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ContactCustomerIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="36"
        height="32"
        viewBox="0 0 36 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5836 22.4591C12.1736 22.4591 11.8211 22.3134 11.5261 22.0221C11.2311 21.7307 11.0836 21.3777 11.0836 20.9628C11.0836 20.548 11.2311 20.1999 11.5261 19.9184C11.8211 19.6369 12.1786 19.4962 12.5986 19.4962C13.0186 19.4962 13.3711 19.6382 13.6561 19.9221C13.9411 20.206 14.0836 20.5579 14.0836 20.9776C14.0836 21.3826 13.9398 21.7307 13.6524 22.0221C13.3649 22.3134 13.0086 22.4591 12.5836 22.4591ZM23.3836 22.4591C22.9736 22.4591 22.6211 22.3134 22.3261 22.0221C22.0311 21.7307 21.8836 21.3777 21.8836 20.9628C21.8836 20.548 22.0311 20.1999 22.3261 19.9184C22.6211 19.6369 22.9786 19.4962 23.3986 19.4962C23.8186 19.4962 24.1711 19.6382 24.4561 19.9221C24.7411 20.206 24.8836 20.5579 24.8836 20.9776C24.8836 21.3826 24.7398 21.7307 24.4524 22.0221C24.1649 22.3134 23.8086 22.4591 23.3836 22.4591ZM7.22861 19.9999C7.10861 18.2517 7.35611 16.6665 7.97111 15.2443C8.58611 13.8221 9.41111 12.6147 10.4461 11.6221C11.4811 10.6295 12.6736 9.86654 14.0236 9.3332C15.3736 8.79987 16.7236 8.5332 18.0736 8.5332C20.8036 8.5332 23.1211 9.38506 25.0261 11.0888C26.9311 12.7925 28.0936 14.9184 28.5136 17.4665C25.6936 17.4369 23.1886 16.6888 20.9986 15.2221C18.8086 13.7554 17.1286 11.8517 15.9586 9.51098C15.4786 11.911 14.4661 14.0369 12.9211 15.8888C11.3761 17.7406 9.47861 19.111 7.22861 19.9999Z"
          fill="black"
        />
        <path
          d="M16.2 32V29.0371H31.2V15.8222C31.2 14.1037 30.84 12.4642 30.12 10.9037C29.4 9.34322 28.43 7.97039 27.21 6.7852C25.99 5.59999 24.585 4.65677 22.995 3.95556C21.405 3.25431 19.74 2.90369 18 2.90369C16.26 2.90369 14.595 3.25431 13.005 3.95556C11.415 4.65677 10.01 5.59999 8.78999 6.7852C7.56998 7.97039 6.59997 9.34322 5.87997 10.9037C5.15997 12.4642 4.79997 14.1037 4.79997 15.8222V26.6667H3.6C2.61 26.6667 1.7625 26.3185 1.0575 25.6222C0.3525 24.9259 0 24.0889 0 23.1111V19.5556C0 18.8741 0.165 18.2741 0.495 17.7556C0.825 17.237 1.26 16.8148 1.8 16.4889L1.935 14.1333C2.205 11.9704 2.8275 10.0148 3.8025 8.26667C4.7775 6.51852 5.9925 5.03704 7.4475 3.82222C8.9025 2.60741 10.5375 1.66667 12.3525 1C14.1675 0.333333 16.05 0 18 0C19.98 0 21.8775 0.333333 23.6925 1C25.5075 1.66667 27.135 2.61481 28.575 3.84444C30.015 5.07407 31.2225 6.55556 32.1975 8.28889C33.1725 10.0222 33.795 11.9556 34.065 14.0889L34.2 16.4C34.74 16.6667 35.175 17.0593 35.505 17.5778C35.835 18.0963 36 18.6667 36 19.2889V23.3778C36 24.0296 35.835 24.6074 35.505 25.1111C35.175 25.6148 34.74 26 34.2 26.2667V29.0371C34.2 29.8519 33.9063 30.5494 33.3188 31.1296C32.7313 31.7099 32.025 32 31.2 32H16.2Z"
          fill="#FFDA44"
        />
      </svg>
    </div>
  );
};

export default ContactCustomerIcon;
