import { IObject } from "@/types/common-types";

export interface ISplash {
  id: string;
  platformID: string;
  storeID: string;
  title: string;
  describe: string;
  imageUrl: string;
  videoUrl: string;
  sort: string;
  type: 'LOGO' | 'IMAGE' | 'VIDEO';
  i18n: { [key: string]: any };
  subTitle?: string;
}
const prefix = '';

const splashData: ISplash[] = [
  {
    id: '0',
    storeID: '1',
    platformID: '1',
    describe: 'test',
    videoUrl: '',
    sort: '1',
    imageUrl: `${prefix}/images/splash-1.png`,
    title: 'guide_carousel_title0',
    type: 'IMAGE',
    subTitle: 'guide_carousel_text0',
    i18n: {},
  },
  {
    id: '1',
    storeID: '1',
    platformID: '1',
    describe: 'test',
    videoUrl: '',
    sort: '1',
    imageUrl: `${prefix}/images/splash-2.png`,
    title: 'guide_carousel_title1',
    type: 'IMAGE',
    subTitle: 'guide_carousel_text1',
    i18n: {},
  },
  {
    id: '2',
    storeID: '1',
    platformID: '1',
    describe: 'test',
    videoUrl: '',
    sort: '1',
    imageUrl: `${prefix}/images/splash-3.png`,
    title: 'guide_carousel_title2',
    type: 'IMAGE',
    subTitle: 'guide_carousel_text2',
    i18n: {},
  },
  {
    id: '3',
    storeID: '1',
    platformID: '1',
    describe: 'test',
    videoUrl: '',
    sort: '1',
    imageUrl: `${prefix}/images/splash-4.png`,
    title: 'guide_carousel_title3',
    type: 'IMAGE',
    subTitle: 'guide_carousel_text3',
    i18n: {},
  },
  // {
  //   id: '4',
  //   storeID: '1',
  //   platformID: '1',
  //   describe: 'test',
  //   videoUrl: '',
  //   sort: '1',
  //   imageUrl: '/images/splash-5.png',
  //   title: 'Much more are waiting for you to explore',
  //   type: 'ICON',
  //   i18n: {},
  // },
];

const dashboardLogo = `<svg width="216" height="48" viewBox="0 0 216 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.9033 38.6537L14 8.11871H19.8203L27.1609 31.6172H25.2063L32.4166 8.11871H36.8035L44.0138 31.6172H42.0158L49.3998 8.11871H55.2201L45.2734 38.6537H40.8865L33.6328 15.1987H35.5439L28.3337 38.6537H23.9033Z" fill="white"/>
<path d="M65.6351 39.0881C63.5212 39.0881 61.6101 38.6103 59.9016 37.6547C58.2221 36.6702 56.8756 35.3527 55.8622 33.7021C54.8776 32.0226 54.3853 30.1549 54.3853 28.099C54.3853 26.043 54.8776 24.2043 55.8622 22.5827C56.8467 20.9321 58.1932 19.6291 59.9016 18.6735C61.6101 17.689 63.5068 17.1967 65.5917 17.1967C67.7345 17.1967 69.6456 17.689 71.3251 18.6735C73.0336 19.6291 74.3801 20.9321 75.3646 22.5827C76.3491 24.2043 76.8414 26.043 76.8414 28.099C76.8414 30.1549 76.3491 32.0226 75.3646 33.7021C74.3801 35.3527 73.0336 36.6702 71.3251 37.6547C69.6456 38.6103 67.749 39.0881 65.6351 39.0881ZM65.5917 33.789C66.6631 33.789 67.6042 33.5573 68.415 33.094C69.2547 32.6018 69.8918 31.9357 70.3261 31.096C70.7894 30.2273 71.0211 29.2428 71.0211 28.1424C71.0211 27.042 70.7894 26.072 70.3261 25.2322C69.8628 24.3925 69.2257 23.7409 68.415 23.2776C67.6042 22.7854 66.6631 22.5392 65.5917 22.5392C64.5492 22.5392 63.6226 22.7854 62.8118 23.2776C62.001 23.7409 61.364 24.3925 60.9006 25.2322C60.4373 26.072 60.2057 27.042 60.2057 28.1424C60.2057 29.2428 60.4373 30.2273 60.9006 31.096C61.364 31.9357 62.001 32.6018 62.8118 33.094C63.6226 33.5573 64.5492 33.789 65.5917 33.789Z" fill="white"/>
<path d="M94.621 38.6537V26.6222C94.621 25.377 94.2301 24.3635 93.4483 23.5817C92.6665 22.7998 91.653 22.4089 90.4078 22.4089C89.597 22.4089 88.8731 22.5827 88.2361 22.9302C87.599 23.2776 87.0923 23.7699 86.7158 24.407C86.3683 25.044 86.1946 25.7824 86.1946 26.6222L83.9794 25.4928C83.9794 23.8423 84.3269 22.4089 85.0218 21.1927C85.7458 19.9476 86.7303 18.9775 87.9754 18.2826C89.2495 17.5876 90.6829 17.2401 92.2755 17.2401C93.8103 17.2401 95.1857 17.631 96.4019 18.4129C97.6181 19.1658 98.5737 20.1648 99.2686 21.4099C99.9636 22.6261 100.311 23.9436 100.311 25.3625V38.6537H94.621ZM80.5046 38.6537V17.6745H86.1946V38.6537H80.5046Z" fill="white"/>
<path d="M113.755 39.0881C111.815 39.0881 110.078 38.6103 108.543 37.6547C107.008 36.6992 105.792 35.3961 104.895 33.7456C104.026 32.095 103.591 30.2418 103.591 28.1858C103.591 26.1009 104.026 24.2332 104.895 22.5827C105.792 20.9321 106.994 19.6291 108.5 18.6735C110.034 17.7179 111.786 17.2401 113.755 17.2401C115.261 17.2401 116.608 17.5442 117.795 18.1523C119.011 18.7604 119.981 19.6146 120.705 20.715C121.458 21.7864 121.863 23.0025 121.921 24.3635V31.8344C121.863 33.1954 121.472 34.426 120.748 35.5264C120.024 36.6268 119.054 37.4955 117.838 38.1325C116.622 38.7696 115.261 39.0881 113.755 39.0881ZM114.711 33.8324C115.782 33.8324 116.709 33.6008 117.491 33.1375C118.302 32.6452 118.924 31.9792 119.358 31.1394C119.822 30.2707 120.053 29.2717 120.053 28.1424C120.053 27.042 119.822 26.072 119.358 25.2322C118.924 24.3635 118.302 23.6975 117.491 23.2342C116.709 22.7419 115.797 22.4958 114.754 22.4958C113.683 22.4958 112.742 22.7419 111.931 23.2342C111.149 23.7265 110.527 24.3925 110.063 25.2322C109.629 26.072 109.412 27.042 109.412 28.1424C109.412 29.2717 109.629 30.2707 110.063 31.1394C110.527 31.9792 111.164 32.6452 111.974 33.1375C112.785 33.6008 113.697 33.8324 114.711 33.8324ZM125.396 38.6537H119.749V33.0072L120.662 27.8818L119.706 22.8433V7.25H125.396V38.6537Z" fill="white"/>
<path d="M140.463 39.0881C138.263 39.0881 136.308 38.6248 134.6 37.6982C132.891 36.7426 131.53 35.4395 130.517 33.789C129.532 32.1384 129.04 30.2562 129.04 28.1424C129.04 26.0575 129.518 24.2043 130.473 22.5827C131.458 20.9321 132.79 19.6291 134.469 18.6735C136.149 17.689 138.016 17.1967 140.072 17.1967C142.099 17.1967 143.88 17.66 145.415 18.5866C146.979 19.4843 148.195 20.7294 149.063 22.3221C149.961 23.8857 150.41 25.6666 150.41 27.6646C150.41 28.041 150.381 28.432 150.323 28.8374C150.294 29.2138 150.222 29.6482 150.106 30.1404L132.471 30.1839V25.9272L147.456 25.8838L145.154 27.6646C145.096 26.4195 144.865 25.377 144.459 24.5373C144.083 23.6975 143.518 23.0605 142.765 22.6261C142.041 22.1628 141.144 21.9311 140.072 21.9311C138.943 21.9311 137.959 22.1918 137.119 22.713C136.279 23.2052 135.627 23.9147 135.164 24.8413C134.73 25.739 134.513 26.8104 134.513 28.0555C134.513 29.3296 134.744 30.4445 135.208 31.4C135.7 32.3267 136.395 33.0506 137.293 33.5718C138.19 34.0641 139.233 34.3102 140.42 34.3102C141.491 34.3102 142.461 34.1365 143.33 33.789C144.199 33.4125 144.952 32.8624 145.589 32.1384L148.933 35.483C147.92 36.6702 146.675 37.5679 145.198 38.176C143.75 38.7841 142.172 39.0881 140.463 39.0881Z" fill="white"/>
<path d="M153.929 38.6537V17.6745H159.619V38.6537H153.929ZM159.619 27.0565L157.404 25.5797C157.664 23.0025 158.417 20.9756 159.662 19.4988C160.908 17.993 162.703 17.2401 165.048 17.2401C166.062 17.2401 166.974 17.4139 167.785 17.7614C168.596 18.0799 169.334 18.6156 170 19.3685L166.438 23.4514C166.12 23.1039 165.729 22.8433 165.266 22.6695C164.831 22.4958 164.324 22.4089 163.745 22.4089C162.529 22.4089 161.53 22.7998 160.748 23.5817C159.995 24.3346 159.619 25.4928 159.619 27.0565Z" fill="white"/>
</svg>
`;

const dashboardSmallLogo = `<svg width="216" height="48" viewBox="0 0 216 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="5" width="38" height="38" rx="9.5" fill="white"/>
<path d="M10.293 14.75L16.3983 33.5748H19.1296L22.9874 21.0254L26.8684 33.5748H29.573L35.7051 14.75H32.1169L28.1879 27.2535L24.3513 14.75H21.6468L17.7987 27.2908L13.8812 14.75H10.293Z" fill="url(#paint0_linear_27510_336574)"/>
<path d="M63.1832 37.3158L54.667 11.0576H59.6721L65.9845 31.2648H64.3037L70.5041 11.0576H74.2766L80.477 31.2648H78.7588L85.1086 11.0576H90.1137L81.5602 37.3158H77.7877L71.5499 17.1459H73.1934L66.993 37.3158H63.1832Z" fill="white"/>
<path d="M99.07 37.6893C97.2522 37.6893 95.6087 37.2785 94.1395 36.4567C92.6953 35.6101 91.5374 34.4771 90.6658 33.0577C89.8192 31.6135 89.3959 30.0073 89.3959 28.2394C89.3959 26.4714 89.8192 24.8902 90.6658 23.4957C91.5125 22.0763 92.6704 20.9558 94.1395 20.134C95.6087 19.2874 97.2397 18.8641 99.0326 18.8641C100.875 18.8641 102.519 19.2874 103.963 20.134C105.432 20.9558 106.59 22.0763 107.437 23.4957C108.283 24.8902 108.707 26.4714 108.707 28.2394C108.707 30.0073 108.283 31.6135 107.437 33.0577C106.59 34.4771 105.432 35.6101 103.963 36.4567C102.519 37.2785 100.888 37.6893 99.07 37.6893ZM99.0326 33.1324C99.954 33.1324 100.763 32.9332 101.46 32.5348C102.183 32.1115 102.73 31.5388 103.104 30.8166C103.502 30.0696 103.702 29.2229 103.702 28.2767C103.702 27.3305 103.502 26.4963 103.104 25.7741C102.706 25.052 102.158 24.4917 101.46 24.0933C100.763 23.67 99.954 23.4583 99.0326 23.4583C98.1362 23.4583 97.3393 23.67 96.6421 24.0933C95.9449 24.4917 95.397 25.052 94.9986 25.7741C94.6002 26.4963 94.401 27.3305 94.401 28.2767C94.401 29.2229 94.6002 30.0696 94.9986 30.8166C95.397 31.5388 95.9449 32.1115 96.6421 32.5348C97.3393 32.9332 98.1362 33.1324 99.0326 33.1324Z" fill="white"/>
<path d="M123.996 37.3158V26.9694C123.996 25.8986 123.66 25.0271 122.988 24.3548C122.315 23.6824 121.444 23.3463 120.373 23.3463C119.676 23.3463 119.053 23.4957 118.505 23.7945C117.958 24.0933 117.522 24.5166 117.198 25.0645C116.899 25.6123 116.75 26.2473 116.75 26.9694L114.845 25.9983C114.845 24.5789 115.144 23.3463 115.741 22.3004C116.364 21.2297 117.211 20.3955 118.281 19.7979C119.377 19.2002 120.61 18.9014 121.979 18.9014C123.299 18.9014 124.482 19.2376 125.527 19.9099C126.573 20.5574 127.395 21.4164 127.993 22.4872C128.59 23.533 128.889 24.666 128.889 25.8862V37.3158H123.996ZM111.857 37.3158V19.275H116.75V37.3158H111.857Z" fill="white"/>
<path d="M140.45 37.6893C138.782 37.6893 137.288 37.2785 135.968 36.4567C134.648 35.635 133.603 34.5144 132.831 33.0951C132.084 31.6757 131.71 30.082 131.71 28.3141C131.71 26.5212 132.084 24.9151 132.831 23.4957C133.603 22.0763 134.636 20.9558 135.931 20.134C137.251 19.3123 138.757 18.9014 140.45 18.9014C141.745 18.9014 142.903 19.1629 143.924 19.6858C144.97 20.2087 145.804 20.9433 146.427 21.8896C147.074 22.8109 147.423 23.8568 147.472 25.0271V31.4516C147.423 32.622 147.087 33.6802 146.464 34.6265C145.841 35.5727 145.007 36.3198 143.961 36.8676C142.916 37.4154 141.745 37.6893 140.45 37.6893ZM141.272 33.1698C142.193 33.1698 142.99 32.9706 143.663 32.5721C144.36 32.1488 144.895 31.5761 145.269 30.854C145.667 30.1069 145.866 29.2478 145.866 28.2767C145.866 27.3305 145.667 26.4963 145.269 25.7741C144.895 25.0271 144.36 24.4544 143.663 24.056C142.99 23.6326 142.206 23.421 141.309 23.421C140.388 23.421 139.579 23.6326 138.882 24.056C138.209 24.4793 137.674 25.052 137.275 25.7741C136.902 26.4963 136.715 27.3305 136.715 28.2767C136.715 29.2478 136.902 30.1069 137.275 30.854C137.674 31.5761 138.222 32.1488 138.919 32.5721C139.616 32.9706 140.401 33.1698 141.272 33.1698ZM150.461 37.3158H145.605V32.4601L146.389 28.0526L145.568 23.7198V10.3105H150.461V37.3158Z" fill="white"/>
<path d="M163.418 37.6893C161.525 37.6893 159.844 37.2909 158.375 36.4941C156.906 35.6723 155.736 34.5518 154.864 33.1324C154.017 31.7131 153.594 30.0945 153.594 28.2767C153.594 26.4838 154.005 24.8902 154.827 23.4957C155.673 22.0763 156.819 20.9558 158.263 20.134C159.707 19.2874 161.313 18.8641 163.081 18.8641C164.824 18.8641 166.356 19.2625 167.676 20.0593C169.02 20.8313 170.066 21.902 170.813 23.2716C171.585 24.6162 171.971 26.1477 171.971 27.8658C171.971 28.1896 171.946 28.5257 171.896 28.8743C171.871 29.198 171.809 29.5716 171.71 29.9949L156.545 30.0322V26.3718L169.431 26.3344L167.452 27.8658C167.402 26.7951 167.203 25.8986 166.854 25.1765C166.53 24.4544 166.045 23.9066 165.397 23.533C164.775 23.1346 164.003 22.9354 163.081 22.9354C162.11 22.9354 161.264 23.1595 160.541 23.6077C159.819 24.0311 159.259 24.6411 158.861 25.438C158.487 26.2099 158.3 27.1313 158.3 28.202C158.3 29.2977 158.5 30.2563 158.898 31.0781C159.321 31.8749 159.919 32.4974 160.691 32.9457C161.463 33.369 162.359 33.5806 163.38 33.5806C164.302 33.5806 165.136 33.4312 165.883 33.1324C166.63 32.8087 167.277 32.3356 167.825 31.7131L170.701 34.5891C169.83 35.6101 168.759 36.382 167.489 36.9049C166.244 37.4279 164.887 37.6893 163.418 37.6893Z" fill="white"/>
<path d="M174.997 37.3158V19.275H179.89V37.3158H174.997ZM179.89 27.3429L177.985 26.073C178.209 23.8568 178.857 22.1137 179.928 20.8437C180.998 19.5489 182.542 18.9014 184.559 18.9014C185.431 18.9014 186.215 19.0508 186.912 19.3497C187.61 19.6236 188.245 20.0842 188.817 20.7317L185.754 24.2427C185.481 23.9439 185.144 23.7198 184.746 23.5704C184.372 23.421 183.937 23.3463 183.439 23.3463C182.393 23.3463 181.534 23.6824 180.861 24.3548C180.214 25.0022 179.89 25.9983 179.89 27.3429Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_27510_336574" x1="34.2978" y1="14.75" x2="33.6606" y2="33.9931" gradientUnits="userSpaceOnUse">
<stop offset="0.604167" stop-color="#0094FF"/>
<stop offset="1" stop-color="#0075FF"/>
</linearGradient>
</defs>
</svg>
`;

const systemLogo = `<svg width="256" height="72" viewBox="0 0 256 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.4995 59.9903L7.15625 12.6818H16.1738L27.5467 49.0885H24.5184L35.6894 12.6818H42.4862L53.6572 49.0885H50.5616L62.0018 12.6818H71.0193L55.6087 59.9903H48.8119L37.5736 23.6509H40.5346L29.3636 59.9903H22.4995Z" fill="black"/>
<path d="M87.1554 60.6633C83.8804 60.6633 80.9194 59.923 78.2725 58.4425C75.6704 56.9172 73.5842 54.8759 72.014 52.3187C70.4887 49.7166 69.726 46.8229 69.726 43.6376C69.726 40.4523 70.4887 37.6035 72.014 35.0911C73.5394 32.5339 75.6255 30.515 78.2725 29.0346C80.9194 27.5092 83.858 26.7465 87.0881 26.7465C90.408 26.7465 93.369 27.5092 95.9711 29.0346C98.618 30.515 100.704 32.5339 102.23 35.0911C103.755 37.6035 104.518 40.4523 104.518 43.6376C104.518 46.8229 103.755 49.7166 102.23 52.3187C100.704 54.8759 98.618 56.9172 95.9711 58.4425C93.369 59.923 90.4305 60.6633 87.1554 60.6633ZM87.0881 52.4533C88.7481 52.4533 90.2061 52.0943 91.4623 51.3765C92.7633 50.6139 93.7503 49.582 94.4233 48.281C95.1411 46.9351 95.5 45.4097 95.5 43.7049C95.5 42.0001 95.1411 40.4972 94.4233 39.1961C93.7055 37.8951 92.7185 36.8857 91.4623 36.1678C90.2061 35.4052 88.7481 35.0238 87.0881 35.0238C85.473 35.0238 84.0374 35.4052 82.7812 36.1678C81.5251 36.8857 80.5381 37.8951 79.8202 39.1961C79.1024 40.4972 78.7435 42.0001 78.7435 43.7049C78.7435 45.4097 79.1024 46.9351 79.8202 48.281C80.5381 49.582 81.5251 50.6139 82.7812 51.3765C84.0374 52.0943 85.473 52.4533 87.0881 52.4533Z" fill="black"/>
<path d="M132.064 59.9903V41.3496C132.064 39.4204 131.458 37.8502 130.247 36.6389C129.036 35.4276 127.465 34.8219 125.536 34.8219C124.28 34.8219 123.158 35.0911 122.171 35.6295C121.184 36.1678 120.399 36.9305 119.816 37.9175C119.278 38.9045 119.009 40.0485 119.009 41.3496L115.577 39.5999C115.577 37.0427 116.115 34.8219 117.192 32.9377C118.313 31.0085 119.839 29.5056 121.768 28.4289C123.742 27.3522 125.962 26.8138 128.43 26.8138C130.808 26.8138 132.939 27.4195 134.823 28.6308C136.707 29.7972 138.188 31.345 139.264 33.2741C140.341 35.1584 140.88 37.1997 140.88 39.398V59.9903H132.064ZM110.193 59.9903V27.4868H119.009V59.9903H110.193Z" fill="black"/>
<path d="M161.709 60.6633C158.703 60.6633 156.011 59.923 153.634 58.4425C151.256 56.962 149.371 54.9432 147.981 52.386C146.635 49.8287 145.962 46.9575 145.962 43.7722C145.962 40.542 146.635 37.6483 147.981 35.0911C149.371 32.5339 151.233 30.515 153.566 29.0346C155.944 27.5541 158.658 26.8138 161.709 26.8138C164.042 26.8138 166.128 27.2849 167.967 28.227C169.852 29.1691 171.355 30.4926 172.476 32.1974C173.643 33.8574 174.271 35.7416 174.36 37.8502V49.425C174.271 51.5336 173.665 53.4402 172.543 55.1451C171.422 56.8499 169.919 58.1958 168.035 59.1828C166.15 60.1698 164.042 60.6633 161.709 60.6633ZM163.189 52.5205C164.849 52.5205 166.285 52.1616 167.496 51.4438C168.752 50.6812 169.717 49.6493 170.39 48.3483C171.108 47.0024 171.467 45.4546 171.467 43.7049C171.467 42.0001 171.108 40.4972 170.39 39.1961C169.717 37.8502 168.752 36.8184 167.496 36.1005C166.285 35.3379 164.872 34.9565 163.257 34.9565C161.597 34.9565 160.139 35.3379 158.883 36.1005C157.671 36.8632 156.707 37.8951 155.989 39.1961C155.316 40.4972 154.979 42.0001 154.979 43.7049C154.979 45.4546 155.316 47.0024 155.989 48.3483C156.707 49.6493 157.694 50.6812 158.95 51.4438C160.206 52.1616 161.619 52.5205 163.189 52.5205ZM179.744 59.9903H170.996V51.2419L172.409 43.3011L170.928 35.4949V11.3359H179.744V59.9903Z" fill="black"/>
<path d="M203.088 60.6633C199.678 60.6633 196.65 59.9454 194.003 58.5098C191.356 57.0293 189.248 55.0105 187.677 52.4533C186.152 49.896 185.389 46.9799 185.389 43.7049C185.389 40.4747 186.13 37.6035 187.61 35.0911C189.136 32.5339 191.199 30.515 193.801 29.0346C196.403 27.5092 199.297 26.7465 202.482 26.7465C205.623 26.7465 208.382 27.4643 210.76 28.9C213.182 30.2907 215.067 32.2199 216.413 34.6873C217.803 37.11 218.499 39.8691 218.499 42.9646C218.499 43.5479 218.454 44.1535 218.364 44.7816C218.319 45.3648 218.207 46.0378 218.028 46.8005L190.706 46.8678V40.2728L213.923 40.2055L210.356 42.9646C210.266 41.0355 209.907 39.4204 209.279 38.1194C208.696 36.8184 207.821 35.8314 206.655 35.1584C205.533 34.4406 204.142 34.0817 202.482 34.0817C200.733 34.0817 199.207 34.4855 197.906 35.293C196.605 36.0557 195.596 37.1548 194.878 38.5905C194.205 39.9812 193.869 41.6412 193.869 43.5703C193.869 45.5443 194.228 47.2715 194.945 48.752C195.708 50.1877 196.785 51.3092 198.176 52.1168C199.566 52.8795 201.181 53.2608 203.021 53.2608C204.681 53.2608 206.184 52.9916 207.53 52.4533C208.875 51.87 210.042 51.0176 211.029 49.896L216.211 55.0778C214.64 56.9172 212.711 58.3079 210.423 59.2501C208.18 60.1922 205.735 60.6633 203.088 60.6633Z" fill="black"/>
<path d="M223.951 59.9903V27.4868H232.766V59.9903H223.951ZM232.766 42.0225L229.334 39.7345C229.738 35.7416 230.904 32.6012 232.834 30.3132C234.763 27.9803 237.544 26.8138 241.178 26.8138C242.748 26.8138 244.162 27.083 245.418 27.6214C246.674 28.1149 247.818 28.9448 248.85 30.1113L243.332 36.437C242.838 35.8987 242.232 35.4949 241.515 35.2257C240.842 34.9565 240.057 34.8219 239.159 34.8219C237.275 34.8219 235.727 35.4276 234.516 36.6389C233.349 37.8054 232.766 39.5999 232.766 42.0225Z" fill="black"/>
</svg>
`;

export interface IPlatformData {
  platform: IObject;
  splashData: ISplash[];
  theme: IObject;
  languages: any[];
}

export const initPlatformConfig = () => {
  const defaultPlatformData: IPlatformData = {
    platform: {
      dashboardLogo,
      dashboardSmallLogo,
      systemLogo,
      splashEnabled: true,
    },
    splashData,
    theme: {},
    languages: [],
  };

  return defaultPlatformData;
}

export const loadPlatformConfig = async () => {
  const platformData: IPlatformData = {
    platform: {
      dashboardLogo,
      dashboardSmallLogo,
      systemLogo,
      splashEnabled: true,
    },
    splashData,
    theme: {},
    languages: [],
  };

  return platformData;
};
