import React from 'react';
import type { IIconProps } from '@/types/common-types';

const RightIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div
      className={`inline-flex items-center justify-center ${className}`}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M5.71308 13.3327C5.48451 13.3327 5.33212 13.2565 5.17974 13.1041C4.87498 12.7993 4.87498 12.3422 5.17974 12.0374L9.21784 7.99935L5.17974 3.96125C4.87498 3.65649 4.87498 3.19935 5.17974 2.89459C5.48451 2.58983 5.94165 2.58983 6.24641 2.89459L10.8178 7.46602C11.1226 7.77078 11.1226 8.22792 10.8178 8.53268L6.24641 13.1041C6.09403 13.2565 5.94165 13.3327 5.71308 13.3327Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default RightIcon;
