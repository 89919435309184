import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ReleasedReserveIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 32 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="17.5" cy="17.5" r="17.5" fill="#5EBCFF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.284 19.25H7V21H10.5V26.25H23.625V21H27.125V19.25H23.841C22.3345 21.3682 19.8598 22.75 17.0625 22.75C14.2652 22.75 11.7905 21.3682 10.284 19.25Z"
          fill="white"
        />
        <circle
          cx="17.2285"
          cy="14.6035"
          r="6.15371"
          stroke="white"
          strokeWidth="1.14948"
        />
        <path
          d="M16.5635 14.8153L17.7817 14.8153C18.1189 14.8153 18.3932 15.0723 18.3932 15.3883C18.3932 15.7053 18.1193 15.9613 17.7819 15.9613C17.7791 15.9613 17.7764 15.9612 17.7737 15.9612L16.6067 15.9612C16.3196 15.9587 16.0421 15.8118 15.8826 15.5781L15.8765 15.569C15.7332 15.3584 15.4348 15.2965 15.2101 15.4308C14.9854 15.565 14.9193 15.8446 15.0625 16.0552L15.0691 16.0649C15.4056 16.5577 15.9545 16.8657 16.69 16.8657L16.69 17.4081C16.69 17.6579 16.9061 17.8604 17.1726 17.8604C17.4391 17.8604 17.6552 17.6579 17.6552 17.4081L17.6552 16.8657C18.1164 16.8657 18.5157 16.7874 18.8925 16.4369C19.1929 16.1574 19.3584 15.785 19.3584 15.3883C19.3584 14.5736 18.6511 13.9109 17.7817 13.9109L16.5635 13.9109C16.2264 13.9109 15.9521 13.6539 15.9521 13.3379C15.9521 13.0332 16.2097 12.765 16.5716 12.765L17.8449 12.765C18.0957 12.7672 18.3363 12.8772 18.505 13.0671C18.6757 13.259 18.98 13.2849 19.1847 13.1251C19.3895 12.9652 19.4172 12.68 19.2466 12.4881C18.795 11.9801 18.2694 11.8605 17.6552 11.8605L17.6552 11.3181C17.6552 11.0683 17.4392 10.8659 17.1727 10.8659C16.9062 10.8659 16.6901 11.0684 16.6901 11.3181L16.6901 11.8605C15.5661 11.8605 14.9869 12.6025 14.9869 13.3379C14.9869 14.1526 15.6941 14.8153 16.5635 14.8153Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default ReleasedReserveIcon;
