import type { IBaseFilterField, IFilterField, IFilterOptions } from '.';
import { IFilterType } from '.';
import { pathDivisionMark } from '.';
import { FilterOperation } from '.';
import type { IFilterContentProps } from './filter-content';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import { findNode } from '@/utils';
import { useEffect, useState } from 'react';
import { creatMenu } from './filter-utils';
import dayjs from 'dayjs';
import CleanIcon from '@/components/svg/clean-icon';
import DateIcon from '@/components/svg/date-icon';
import FilterItemIcon from './filter-icons/fiter-item-icon';
import {
  ConfigProvider,
  DatePicker,
  Checkbox,
  Dropdown as AntdDropdown,
} from 'antd';
import { useTranslation } from 'react-i18next';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import zhHK from 'antd/lib/locale/zh_HK';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/en';
import 'dayjs/locale/zh-hk';
import { useParams } from 'react-router-dom';
import './filter-style.css'


interface IFilterItemProps extends IFilterContentProps {
  option: IFilterOptions;
  index: number;
}
const FilterItem: React.FC<IFilterItemProps> = (props) => {
  const { option, fields, searchValue, options, onFilter, index } = props;

  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [dateOpen, setDateOpen] = useState([false, false]);
  const [dateLocale, setDateLocale] = useState<any>(zhHK);
  const { t } = useTranslation();
  const { lang = 'en' } = useParams();

  const operatorDispaly: any = {
    [FilterOperation.CONTAINS]: t('common:contains'),
    [FilterOperation.NO_CONTAINS]: t('common:no_contains'),
    [FilterOperation.EQUALS]: t('common:equals'),
    [FilterOperation.NO_EQUALS]: t('common:no_equals'),
    [FilterOperation.IS_NULL]: t('common:is_null'),
    [FilterOperation.IS_NOT_NULL]: t('common:no_null'),
    [FilterOperation.IS_BETWEEN]: t('common:between'),
  };

  useEffect(() => {
    if (lang) {
      let dateLocale = zhHK;
      switch (lang) {
        case 'en':
          dateLocale = enUS;
          break;
        case 'zh_CN':
          dateLocale = zhCN;
          break;
        default:
          break;
      }

      setDateLocale(dateLocale);
      dayjs.locale(lang);
    }
  }, [lang]);

  const handleItemChange = (
    value: any,
    key: keyof IFilterOptions,
    isDateBetween = false,
    isStart = false
  ) => {
    const newOptions = [...options];
    const newOption = { ...option };
    let changeValue = value;
    if (isDateBetween) {
      const oldValue = newOption[key];
      const oldValueArr = oldValue.split('&');
      if (isStart) {
        oldValueArr[0] = value;
      } else {
        oldValueArr[1] = value;
      }
      changeValue = oldValueArr.join('&');
    }
    if (
      [IFilterType.SELECT, IFilterType.MULTISELECT].includes(newOption.type)
    ) {
      let values: string[] = newOption.value ? newOption.value.split(',') : [];
      const index = values.indexOf(value);
      if (index === -1) {
        values.push(value);
        if (newOption.type === IFilterType.SELECT) {
          values = [value];
        }
      } else {
        values.splice(index, 1);
      }
      changeValue = values.join(',');
    }
    newOption[key] = changeValue;

    if (
      [FilterOperation.IS_NULL, FilterOperation.IS_NOT_NULL].includes(
        newOption.operation
      )
    ) {
      newOption.value = '';
    }

    if (key === 'operation') {
      newOption.value = '';
    }

    newOptions.splice(index, 1, newOption);
    onFilter(searchValue, newOptions);
  };

  const handleItemDelete = (e: any) => {
    e.stopPropagation();
    const newOptions = [...options];
    newOptions.splice(index, 1);
    onFilter(searchValue, newOptions);
  };

  const getOverlay = () => {
    let values = Object.values(FilterOperation);
    const { name } = option;
    const paths = name.split(pathDivisionMark);
    const optionName = paths[paths.length - 1];
    const field = findNode<IFilterField>(fields, 'name', optionName);
    if (field && field.operations) {
      values = Object.values(field.operations);
    }
    if (
      field &&
      field.type !== IFilterType.DATE &&
      field.type !== IFilterType.NUMBER &&
      values.indexOf(FilterOperation.IS_BETWEEN) !== -1
    ) {
      values.splice(values.indexOf(FilterOperation.IS_BETWEEN), 1);
    }
    return (
      <Menu className="bg-white">
        {values.map((item) => (
          <MenuItem
            key={item}
            data-menu="open"
            className="text-color-text-2 px-4 py-2 cursor-pointer hover:bg-system-background-color"
            onClick={() => handleItemChange(item, 'operation')}
          >
            {operatorDispaly[item]}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  const getField = () => {
    const value = option.name;
    let showValue = '';
    value.split(pathDivisionMark).forEach((item) => {
      const field = findNode<IFilterField>(fields, 'name', item);
      if (field) {
        showValue = `${
          showValue ? `${showValue} -> ${field.label}` : field.label
        }`;
      }
    });
    return showValue;
  };

  const getFieldOverlay = () => {
    return (
      <Menu
        className="min-w-15 bg-white pt-1.5"
        openKeys={openKeys}
        mode="inline"
      >
        {creatMenu({
          ...props,
          openKeys,
          setOpenKeys,
          index,
        })}
      </Menu>
    );
  };

  const getValueContent = () => {
    const { name, value, operation } = option;
    const paths = name.split(pathDivisionMark);
    const optionName = paths[paths.length - 1];
    const field = findNode<IFilterField>(fields, 'name', optionName);
    if (!field) return;
    const { type = IFilterType.TEXT, options } = field;
    let node = null;
    switch (type) {
      case IFilterType.SELECT:
      case IFilterType.MULTISELECT:
        let values = [value];
        if (value) {
          values = value.split(',');
        }
        let showValue = [value];
        if (options) {
          showValue = [];
          values.forEach((item) => {
            const option = findNode<IBaseFilterField>(options, 'name', item);
            if (option) {
              showValue.push(option.label);
            } else {
              showValue.push(item);
            }
          });
        }
        node = (
          <AntdDropdown
            dropdownRender={() => getSelectOverlay(options, values)}
          >
            <span
              className={`truncate ${
                value ? 'text-primary-color' : 'text-color-text-2'
              }`}
            >
              {showValue.join(', ') || t('common:select')}
            </span>
          </AntdDropdown>
        );
        break;
      case IFilterType.DATE:
        if (operation === FilterOperation.IS_BETWEEN) {
          const start = value.split('&')[0];
          const end = value.split('&')[1] || '';
          const startLeng = start.length;
          const endLeng = end.length;
          node = (
            <>
              <ConfigProvider locale={dateLocale}>
                <DatePicker
                  locale={dateLocale}
                  className={`px-0 group/start cursor-pointer placeholder:text-color-text-2 text-primary-color ${
                    start ? '' : 'search-date'
                  }`}
                  style={{ width: `${startLeng > 1 ? 11 : 5}ch` }}
                  bordered={false}
                  inputReadOnly={true}
                  placeholder=""
                  popupClassName="search"
                  showToday={false}
                  allowClear={false}
                  onOpenChange={(open) => {
                    const newDateOpen = [...dateOpen];
                    newDateOpen[0] = open;
                    setDateOpen(newDateOpen);
                  }}
                  disabledDate={(current) => {
                    return end ? current > dayjs(end).endOf('day') : false;
                  }}
                  value={start ? dayjs(start) : null}
                  suffixIcon={
                    <>
                      {!start && (
                        <div className="text-color-text-2 text-sm font-medium">
                          --
                        </div>
                      )}
                      <DateIcon
                        className={`${
                          dateOpen[0] || start
                            ? 'text-primary-color'
                            : 'text-icon-color'
                        } group-hover/start:text-primary-color cursor-pointer`}
                      />
                    </>
                  }
                  onChange={(value) => {
                    handleItemChange(
                      value ? value.format('YYYY-MM-DD') : '',
                      'value',
                      true,
                      true
                    );
                  }}
                />
              </ConfigProvider>
              <span className="mx-2 text-color-text-1">{t('common:and')}</span>
              <ConfigProvider locale={dateLocale}>
                <DatePicker
                  locale={dateLocale}
                  className={`px-0 group/end cursor-pointer placeholder:text-color-text-2 text-primary-color ${
                    end ? '' : 'search-date'
                  }`}
                  style={{ width: `${endLeng > 1 ? 11 : 5}ch` }}
                  bordered={false}
                  inputReadOnly={true}
                  placeholder=""
                  popupClassName="search"
                  showToday={false}
                  allowClear={false}
                  onOpenChange={(open) => {
                    const newDateOpen = [...dateOpen];
                    newDateOpen[1] = open;
                    setDateOpen(newDateOpen);
                  }}
                  disabledDate={(current) => {
                    return start
                      ? current < dayjs(start).subtract(1, 'D').endOf('day')
                      : false;
                  }}
                  value={end ? dayjs(end) : null}
                  suffixIcon={
                    <>
                      {!end && (
                        <div className="text-color-text-2 text-sm font-medium">
                          --
                        </div>
                      )}
                      <DateIcon
                        className={`${
                          dateOpen[1] || end
                            ? 'text-primary-color'
                            : 'text-icon-color'
                        } group-hover/end:text-primary-color cursor-pointer`}
                      />
                    </>
                  }
                  onChange={(value) => {
                    handleItemChange(
                      value ? value.format('YYYY-MM-DD') : '',
                      'value',
                      true
                    );
                  }}
                />
                <span className="text-color-text-1">
                  {t('common:is_between')}
                </span>
              </ConfigProvider>
            </>
          );
        } else {
          const leng = value.length;
          node = (
            <ConfigProvider locale={dateLocale}>
              <DatePicker
                locale={dateLocale}
                className={`group/start px-0 placeholder:text-color-text-2 text-primary-color ${
                  value ? '' : 'search-date'
                }`}
                style={{ width: `${leng > 1 ? 11 : 5}ch` }}
                bordered={false}
                inputReadOnly={true}
                placeholder=""
                popupClassName="search"
                showToday={false}
                allowClear={false}
                onOpenChange={(open) => {
                  const newDateOpen = [...dateOpen];
                  newDateOpen[0] = open;
                  setDateOpen(newDateOpen);
                }}
                value={value ? dayjs(value) : null}
                suffixIcon={
                  <>
                    {!value && (
                      <div className="text-color-text-2 text-sm font-medium">
                        --
                      </div>
                    )}
                    <DateIcon
                      className={`${
                        dateOpen[0] || value
                          ? 'text-primary-color'
                          : 'text-icon-color'
                      } group-hover/start:text-primary-color cursor-pointer`}
                    />
                  </>
                }
                onChange={(value) => {
                  handleItemChange(
                    value ? value.format('YYYY-MM-DD') : '',
                    'value'
                  );
                }}
              />
            </ConfigProvider>
          );
        }
        break;
      case IFilterType.NUMBER:
        const reg = RegExp(/^-?\d*\.?\d*$/);
        if (operation === FilterOperation.IS_BETWEEN) {
          let values = [value];
          if (value) {
            values = value.split('&');
          }
          const start = values[0];
          const end = values[1] || '';
          const startLeng = start.length;
          const endLeng = end.length;
          node = (
            <>
              <input
                className="border-none text-primary-color outline-none placeholder:text-color-text-2"
                style={{ width: `${startLeng > 1 ? startLeng + 2: 2}ch` }}
                type="text"
                autoFocus={true}
                value={start}
                contentEditable
                placeholder="--"
                maxLength={18}
                onChange={(e) => {
                  const value = e.target.value;
                  if (reg.test(value) || !value) {
                    handleItemChange(value, 'value', true, true);
                  }
                }}
              />
              <span className="mx-2 text-color-text-1">and</span>
              <input
                className="border-none text-primary-color outline-none placeholder:text-color-text-2"
                style={{ width: `${endLeng > 1 ? endLeng + 2 : 2}ch` }}
                type="text"
                autoFocus={true}
                value={end}
                contentEditable
                placeholder="--"
                maxLength={13}
                onChange={(e) => {
                  const value = e.target.value;
                  if (reg.test(value) || !value) {
                    handleItemChange(value, 'value', true);
                  }
                }}
              />
            </>
          );
        } else {
          const leng = value.length;
          node = (
            <input
              className="border-none text-primary-color outline-none placeholder:text-color-text-2"
              style={{ width: `${leng > 1 ? leng + 2 : 2}ch` }}
              type="text"
              autoFocus={true}
              value={value}
              contentEditable
              placeholder="--"
              onChange={(e) => {
                const value = e.target.value;
                if (reg.test(value) || !value) {
                  handleItemChange(value, 'value');
                }
              }}
            />
          );
        }
        break;
      default:
        const leng = value.length;
        node = (
          <input
            className="border-none text-primary-color outline-none"
            style={{ width: `${leng > 1 ? leng + 2 : 2}ch` }}
            type="text"
            autoFocus={true}
            value={value}
            contentEditable
            placeholder="--"
            onChange={(e) => handleItemChange(e.target.value, 'value')}
          />
        );
        break;
    }

    return node;
  };

  const getSelectOverlay = (
    options: IBaseFilterField[] | undefined,
    values: string[]
  ) => {
    return (
      <Menu className="bg-white overflow-y-auto max-h-96" openKeys={openKeys}>
        {options &&
          options.map((option) => (
            <MenuItem
              data-menu="open"
              key={option.name}
              className="text-color-text-1 px-3 py-3.5 cursor-pointer hover:bg-disabled-color flex items-center first:pt-5 last:pb-5"
              onClick={() => handleItemChange(option.name, 'value')}
            >
              <Checkbox
                data-menu="open"
                className="flatpickr h-5"
                checked={values.includes(option.name)}
              ></Checkbox>
              <div data-menu="open" className="text-sm ml-1.5 font-medium">
                {option.label}
              </div>
            </MenuItem>
          ))}
      </Menu>
    );
  };

  const handleVisibleChange = (value: boolean) => {
    if (!value) {
      setOpenKeys([]);
    }
  };

  return (
    <div id={option.name} className="flex items-center mb-3 group">
      <div className="flex pl-2 pr-3 h-8 max-w-[446px] items-center border border-border-color rounded-3xl bg-primary-background-color hover:border-primary-color text-sm font-medium">
        <FilterItemIcon className="text-icon-color" />
        <Dropdown
          trigger={['click']}
          overlay={getFieldOverlay()}
          onVisibleChange={handleVisibleChange}
          animation="slide-up"
        >
          <div className="text-color-text-2 cursor-pointer hover:bg-system-background-color px-1 whitespace-nowrap">
            {getField()}
          </div>
        </Dropdown>
        <Dropdown
          trigger={['click']}
          overlay={getOverlay()}
          animation="slide-up"
          overlayClassName=""
        >
          <div className="px-1 mr-1 text-color-text-1 cursor-pointer rounded hover:bg-system-background-color whitespace-nowrap">
            {operatorDispaly[option.operation]}
          </div>
        </Dropdown>
        {![FilterOperation.IS_NULL, FilterOperation.IS_NOT_NULL].includes(
          option.operation
        ) && getValueContent()}
      </div>
      <CleanIcon
        className="invisible group-hover:visible text-color-text-5 hover:text-icon-color cursor-pointer"
        onClick={handleItemDelete}
      />
    </div>
  );
};

export default FilterItem;
