import type { IFilterProps } from '.';
import Dropdown from 'rc-dropdown';
import Menu from 'rc-menu';
import FilterItem from './filter-item';
import { useState } from 'react';
import { creatMenu } from './filter-utils';
import AddIcon from './filter-icons/add-icon';
import DownIcon from '@/components/svg/down-icon';
import { useTranslation } from 'react-i18next';

export interface IFilterContentProps extends IFilterProps {
  showMenu: boolean;
  filter: boolean;
  search: boolean;
  setShowMenu: (value: boolean) => void;
}

const FilterContent: React.FC<IFilterContentProps> = (props) => {
  const { showMenu, setShowMenu, options, filter, search } = props;
  const { t } = useTranslation();

  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const getOverlay = () => {
    return (
      <Menu
        openKeys={openKeys}
        mode="inline"
        className="bg-white overflow-y-auto max-h-96"
      >
        {creatMenu({
          ...props,
          openKeys,
          setOpenKeys,
        })}
      </Menu>
    );
  };

  const handleVisibleChange = (value: boolean) => {
    if (!value) {
      setOpenKeys([]);
    }
    setShowMenu(value);
  };

  return (
    <div
      className={`bg-disabled-color overflow-hidden px-4 py-3 w-[500px] min-w-full absolute top-full right-0 z-100 border border-t-0 border-border-color shadow-card-drop rounded-bl rounded-br ${
        search
          ? `${
              filter
                ? 'rc-menu-open-slide-up-enter rc-menu-open-slide-up-enter-active'
                : 'hidden rc-menu-open-slide-up-leave rc-menu-open-slide-up-leave-active'
            }`
          : 'hidden'
      }`}
    >
      <div className="w-full">
        {options &&
          options.map((option, index) => (
            <FilterItem
              key={`${option.name}-${index}`}
              option={option}
              index={index}
              {...props}
            />
          ))}
      </div>
      <Dropdown
        trigger={['click']}
        overlay={getOverlay()}
        onVisibleChange={handleVisibleChange}
        animation="slide-up"
      >
        <div className="bg-primary-background-color flex items-center px-2 py-1.5 border-border-color border rounded-3xl cursor-pointer text-color-text-2 group">
          <AddIcon
            className={`${
              showMenu ? 'text-primary-color' : 'text-icon-color'
            } group-hover:text-primary-color`}
          />
          <span className="flex-1 ml-1 text-sm font-medium">
            {t('common:add_filter')}
          </span>
          <DownIcon
            size={14}
            className={`transform duration-150 ${
              showMenu ? '-rotate-180' : ''
            }`}
          />
        </div>
      </Dropdown>
    </div>
  );
};

export default FilterContent;
