import type { IAccountBalanceOverview } from '@/types/account-balance-type';
import type { IObject } from '@/types/common-types';
import { Fragment, useEffect, useState } from 'react';
import Button from '@/components/common/button';
import Table from '@/components/table';
import { getCurrentAccountColumns } from '@/pages/account-balance/account-balance-data';
import { Transition } from '@headlessui/react';
import dayjs from 'dayjs';
import Config from '@/libs/config';
import BigNumber from 'bignumber.js';
import Tooltip from '@/components/common/tips-widget';
import { HadleGoTranscationDetails } from '@/utils/account-balance-untils';
import { getActivities } from '@/data/account-balance';
import AccountBalanceItem from '../../account-balance-item';
import ProjectBalanceitem from './project-balance-item';
import AccountPieCharts from './account-pie-charts';
import UpIcon from '@/components/svg/up-icon';
import DownIcon from '@/components/svg/down-icon';
import EyeIcon from '@/pages/account-balance/account-balances-icons/eye-icon';
import EyeOffIcon from '@/pages/account-balance/account-balances-icons/eye-off-icon';
import NextIcon from '@/components/svg/next-icon';
import RightIcon from '@/pages/account-balance/account-balances-icons/right-icon';
import { findNode } from '@/utils';
import { currencies } from '@/utils/currencies';
import Skeleton from '@/components/skeleton';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import type { TableRecord } from '@/components/table/interface';
import { activeAccount } from '@/data/global-account';
import { handleNumber } from '@/utils/field-utils';
import { useParams, useNavigate } from 'react-router-dom';


export const ProjectbalanceColors: string[] = [
  '#FD9800',
  '#FFC737',
  '#FFF500',
  '#989545',
  '#5AE2D2',
  '#A8E4DD',
  '#00469B',
  '#97D3FF',
  '#9C0E4E',
  '#CDA5B7',
  '#378E1A',
  '#7EEE58',
  '#40F498',
  '#B4FFD8',
  '#4E10FF',
  '#8B63FF',
  '#3759A6',
  '#89A0D6',
  '#EA1C24',
  '#F59599',
  '#E0C500',
  '#FFE737',
  '#2DA771',
  '#ACD2C1',
  '#81736D',
  '#C9C8C8',
  '#97009B',
  '#D42CD9',
  '#0E8B9C',
  '#19E3FE',
  '#7BEFFF',
  '#A68013',
  '#BCBCBC',
];

const AccountBalanceOverview = (props: IAccountBalanceOverview) => {
  const { t } = useTranslation("account_balance");
  const { lang = 'en' } = useParams();
  const navigate = useNavigate();
  const [colums, setColums] = useState<ColumnsType<TableRecord>>([]);
  const [projectBalanceRecord, setProjectBalanceRecord] = useState<IObject[]>(
    []
  );
  const [showCurrenBalance, setShowCurrenBalance] = useState<boolean>(true);
  const [showProjectBalanceMore, setShowProjectBalanceMore] =
    useState<boolean>(false);
  const [activities, setActivities] = useState<IObject[]>([]); // 最近5条
  const [activitiesLoaing, setActivitiesLoaing] = useState<boolean>(false);


  const {
    storeLedgerAccount,
    merchantAccountData,
    initLoading,
    businessID,
    refreshPage,
  } = props;

  const {
    ledger_accounts = [],
    current_total_with_business_default_currency = {},
    default_currency = {},
    payin_request_totals = [],
    projected_balance = '',
  } = storeLedgerAccount || {};
  const {
    available = '',
    incoming = '',
    outgoing = '',
  } = current_total_with_business_default_currency;
  const { currency_code: currency = 'HKD' } = default_currency;
  const prefix = Config.staticPath;


  const { processingRecord = {}, lastSettlementRecord = {} } =
    merchantAccountData || {};

  useEffect(() => {
    handleColums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    handleProjectbalanceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeLedgerAccount, lang]);

  useEffect(() => {
    getActivitiesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  // 构建columns
  const handleColums = () => {
    const columns = getCurrentAccountColumns(t, false, lang);
    setColums(columns);
    return columns;
  };

  const handleProjectbalanceData = () => {
    const newData: any = [
      {
        title: `${t('account_balance:current_balance')} (${currency} ${t(
          'common:equiv'
        )})`,
        amount: available,
        currency: currency,
        color: '#0094FF',
      },
    ];
    if (
      Array.isArray(payin_request_totals) &&
      payin_request_totals.length > 0
    ) {
      for (let i = 0; i < payin_request_totals.length; i++) {
        const { date, amount } = payin_request_totals[i];
        const tempNumber = new BigNumber(amount);
        const tempDate = dayjs(date);
        const tommorDay = dayjs().add(1, 'day');
        const isAfter = dayjs().add(31, 'day');
        let returnString = '';
        // 判断是明天特殊处理
        if (tempDate.isSame(tommorDay, 'day')) {
          returnString = `${t(
            'account_balance:incoming_tomorrow'
          )} (${currency} ${t('common:equiv')})`;
        } else if (tempDate.isSame(isAfter, 'day')) {
          returnString = `${t('account_balance:from_onwards', {
            date: `${tempDate.format('MMM DD')}`,
          })} (${currency} ${t('common:equiv')})`;
        } else {
          returnString = `${tempDate.format('MMM DD')} (${currency} ${t(
            'common:equiv'
          )})`;
        }
        const TDayNumber = tempDate.diff(dayjs().format('YYYY-MM-DD'), 'day');
        newData.push({
          title: returnString,
          amount: tempNumber.toNumber(),
          currency: currency,
          color: ProjectbalanceColors[TDayNumber] || '#A68013',
        });
      }
    }
    setProjectBalanceRecord(newData);
  };

  const getActivitiesList = async () => {
    setActivitiesLoaing(true);
    setActivities([]);
    const activities = await getActivities({
      pBusinessId: businessID,
      limit: 5,
      offset: 0,
    });

    if (Array.isArray(activities)) {
      setActivities(activities);
    }

    setActivitiesLoaing(false);
  };

  const handleItemClick = async (record: IObject) => {
    let ledgerAccountId = '';
    if (record.id) {
      ledgerAccountId = record.id;
    } else {
      const { currency_code } = record;
      ledgerAccountId = await activeAccount(businessID, currency_code);
    }

    if (ledgerAccountId) {
      navigate(
        `/${lang}/businesses/${businessID}/global-accounts/${ledgerAccountId}/current-activities`
      );
    }
  };

  const handleRouter = (str: string) => {
    navigate(`/${lang}/businesses/${businessID}/account-balance/${str}`);
  };

  return (
    <>
      <Transition.Root show={true} as={Fragment} appear={true}>
        <div>
          <div className="flex w-full mt-4 flex-col md:mt-6 lg:flex-row lg:space-x-6">
            <Transition.Child
              className="flex-1 lg:w-1/2"
              enter="duration-350 transform transition ease-in-out"
              enterFrom="-translate-x-1 translate-y-8 opacity-60"
              enterTo="translate-x-0 translate-y-0 opacity-100"
              leave="duration-500 transform transition"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={`flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 mb-4 h-fit md:mb-6`}
              >
                <div className="flex justify-between">
                  <div className="font-bold text-lg text-color-text-1 md:text-xl">
                    {t('account_balance:current_account')} ({currency}{' '}
                    {t('common:equiv')})
                  </div>
                  {showCurrenBalance && (
                    <EyeIcon
                      className="cursor-pointer text-color-text-5 hover:text-icon-color"
                      onClick={() => setShowCurrenBalance(!showCurrenBalance)}
                    />
                  )}
                  {!showCurrenBalance && (
                    <EyeOffIcon
                      className="cursor-pointer text-color-text-5 hover:text-icon-color"
                      onClick={() => setShowCurrenBalance(!showCurrenBalance)}
                    />
                  )}
                </div>
                {/* <div className="mt-1 text-xs text-color-text-4">
                  Estimated in HKD at {currentDate?.format('HH:mm')} Today
                </div> */}
                <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
                <div className={`flex space-x-6`}>
                  <div className="flex flex-col flex-1 space-y-4 md:flex-row md:space-x-6 md:space-y-0">
                    <div className="flex-1">
                      <AccountBalanceItem
                        mount={available}
                        subTitle={t('account_balance:current_balance')}
                        loading={initLoading}
                        show={showCurrenBalance}
                      />
                    </div>

                    <div className="flex-1">
                      <AccountBalanceItem
                        mount={projected_balance}
                        show={showCurrenBalance}
                        subTitle={t('account_balance:projected_balance')}
                        tooltip={
                          <Tooltip
                            className="ml-2 leading-5 h-4"
                            tipWapperclass="-translate-x-1/2 md:-translate-x-3/4"
                          >
                            <div className="text-xs font-normal min-w-[16rem]">
                              {t(
                                'account_balance:projected_balance_description'
                              )}
                            </div>
                          </Tooltip>
                        }
                        loading={initLoading}
                      />
                    </div>
                  </div>

                  <div className="flex justify-end items-center cursor-pointer">
                    {showProjectBalanceMore && (
                      <UpIcon
                        className={`text-icon-color shrink-0 ml-1 fill-current hover:text-primary-color`}
                        onClick={() =>
                          setShowProjectBalanceMore(!showProjectBalanceMore)
                        }
                      />
                    )}
                    {!showProjectBalanceMore && (
                      <DownIcon
                        className={`text-icon-color shrink-0 ml-1 fill-current hover:text-primary-color`}
                        onClick={() =>
                          setShowProjectBalanceMore(!showProjectBalanceMore)
                        }
                      />
                    )}
                  </div>
                </div>
                <div>
                  {
                    <Transition show={showProjectBalanceMore}>
                      <Transition.Child
                        className=""
                        enter="duration-350 transform transition-max-height ease-ease"
                        enterFrom="max-h-0"
                        enterTo="max-h-102"
                        leave="duration-`200 transform transition-max-height ease-ease"
                        leaveFrom="max-h-102"
                        leaveTo="max-h-0 mt-0"
                      >
                        <div className="pt-6 pb-2 flex space-x-6">
                          <div className="flex-1">
                            <AccountPieCharts
                              projectBalanceRecord={projectBalanceRecord}
                              showCurrenBalance={showCurrenBalance}
                            />
                          </div>
                          <div
                            className={`flex-1 flex flex-col space-y-2 justify-center max-h-100 overflow-auto hidden_scrollbar`}
                          >
                            <div
                              className={`h-full hidden_scrollbar`}
                            >
                              {projectBalanceRecord.map((item, index) => (
                                <ProjectBalanceitem
                                  key={`${item.title}_${index}`}
                                  index={index}
                                  title={item.title}
                                  amount={item.amount}
                                  currency={item.currency}
                                  color={item.color}
                                  showCurrenBalance={showCurrenBalance}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </Transition.Child>
                    </Transition>
                  }
                </div>
              </div>
            </Transition.Child>

            <Transition.Child
              className="flex-1 lg:w-1/2"
              enter="duration-450 transform transition ease-in-out"
              enterFrom="-translate-x-1 translate-y-8 opacity-40"
              enterTo="translate-x-0 translate-y-0 opacity-100"
              leave="duration-500 transform transition"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={`flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 mb-4 h-fit md:mb-6`}
              >
                <div className="font-bold text-lg text-color-text-1 md:text-xl">
                  {t('account_balance:upcoming_fund')} ({currency}{' '}
                  {t('common:equiv')})
                </div>
                <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
                <div className="flex space-x-6">
                  <div className="flex flex-col flex-1 space-y-4 md:flex-row md:space-x-6 md:space-y-0">
                    <div className="flex-1">
                      <AccountBalanceItem
                        mount={incoming}
                        subTitle={t('account_balance:incoming_fund')}
                        loading={initLoading}
                        show={showCurrenBalance}
                      />
                    </div>

                    <div className="flex-1 flex flex-col">
                      <AccountBalanceItem
                        mount={outgoing}
                        subTitle={t('account_balance:outgoing_fund')}
                        loading={initLoading}
                        show={showCurrenBalance}
                      />
                    </div>
                  </div>

                  <div className="flex justify-end items-center">
                    <UpIcon
                      className={`text-icon-color shrink-0 ml-1 fill-current rotate-90 hover:text-primary-color cursor-pointer`}
                      onClick={() => handleRouter('current-account')}
                    />
                  </div>
                </div>
                {/* <div>
                  {
                    <Transition show={false}>
                      <Transition.Child
                        className=""
                        enter="duration-350 transform transition-max-height ease-ease"
                        enterFrom="max-h-0"
                        enterTo="max-h-102"
                        leave="duration-`200 transform transition-max-height ease-ease"
                        leaveFrom="max-h-102"
                        leaveTo="max-h-0 mt-0"
                      >
                        <div className="pt-6">
                          <Table
                            columns={upComingColums}
                            records={upComingRecord}
                            hiddenNoData={true}
                            showScrollBar={true}
                            maxHeightClass={`max-h-410`}
                            onRowCell={(record, dataKey) => {
                              return {
                                onClick: (evt) => {
                                  evt.stopPropagation();
                                  HadleGoTranscationDetails(
                                    businessID,
                                    record,
                                    '',
                                    true
                                  );
                                },
                              };
                            }}
                          />
                        </div>
                      </Transition.Child>
                    </Transition>
                  }
                </div> */}
              </div>
            </Transition.Child>
          </div>

          <Transition.Child
            className="flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 mb-4 h-fit md:mb-6"
            enter="duration-700 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-10 opacity-30"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="flex flex-col space-y-4 md:flex-row md:justify-between md:items-center xl:space-y-0">
              <div>
                <div className="font-bold text-lg text-color-text-1 flex items-center md:text-xl">
                  {t('account_balance:merchat_processing_amount')} ({currency}{' '}
                  {t('common:equiv')})
                </div>
                {/* <div className="mt-1 text-xs text-color-text-4">
                  Balance as of Today{' '}
                  {currentDate?.format('MMM D, YYYY, HH:mm')}
                </div> */}
              </div>
              <div className="flex space-x-4 justify-start mt-4 md:justify-end md:mt-0">
                <Button
                  styleType="Secondary"
                  iconPosition="Right"
                  iconInstance={<NextIcon />}
                  onClick={() => handleRouter('merchant-account')}
                >
                  {t('account_balance:view_more')}
                </Button>
              </div>
            </div>
            <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
            <div className="flex flex-col space-y-6 lg:space-x-6 lg:flex-row lg:space-y-0">
              <div className="flex-1 bg-system-background-color p-4 rounded">
                <AccountBalanceItem
                  mount={processingRecord?.total_amount}
                  subTitle={t('account_balance:currently_processing')}
                  loading={initLoading}
                  show={showCurrenBalance}
                />
              </div>

              {/* <div className="flex-1 flex flex-col space-y-6 md:space-x-6 md:flex-row md:space-y-0"> */}
              <div className="flex-1 bg-system-background-color p-4 rounded">
                <AccountBalanceItem
                  mount={
                    lastSettlementRecord?.treasury_services_payin_amount ||
                    lastSettlementRecord?.total_amount
                  }
                  subTitle={t('account_balance:last_settlement')}
                  loading={initLoading}
                  show={showCurrenBalance}
                />
              </div>
            </div>
          </Transition.Child>

          <Transition.Child
            className="flex-1 rounded  shadow-card-drop bg-primary-background-color p-4 md:p-6 mb-4 h-fit md:mb-6"
            enter="duration-700 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-10 opacity-30"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="flex flex-col space-y-4 md:flex-row md:justify-between md:items-center xl:space-y-0">
              <div>
                <div className="font-bold text-lg text-color-text-1 flex items-center md:text-xl">
                  {t('account_balance:global_accounts')}
                </div>
              </div>
              <div className="flex space-x-4 justify-start mt-4 md:justify-end md:mt-0">
                <Button
                  styleType="Secondary"
                  iconPosition="Right"
                  iconInstance={<NextIcon />}
                  onClick={() =>
                    navigate(`/${lang}/businesses/${businessID}/global-accounts`)
                  }
                >
                  {t('account_balance:view_more')}
                </Button>
              </div>
            </div>
            <div className="w-full h-[1px] mt-4 bg-border-color" />
            <div className="mt-6 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
              {ledger_accounts.map((item: any, index: number) => {
                if (index > 4) return '';
                const { currency = {} } = item;
                const {
                  currency_code = '',
                  currency_name = '',
                  currency_symbol,
                } = currency;

                const currencyobj: any = findNode(
                  currencies,
                  'currency_code',
                  currency_code
                );

                if (currencyobj) {
                  return (
                    <div
                      key={item.id}
                      className="cursor-pointer rounded group p-2 hover:bg-disabled-color"
                      onClick={() => handleItemClick(item)}
                    >
                      <div className="flex items-center relative justify-between">
                        <div className="flex">
                          <img
                            // layout="intrinsic"
                            width="33px"
                            height="33px"
                            src={`${prefix}/images/country-flag/${currencyobj.alpha_2.toLocaleLowerCase()}.svg`}
                            alt=""
                          />
                          <div className="ml-2">
                            <div className="text-color-text-1 text-lg font-semibold">
                              {currency_code}
                            </div>
                            <div className="text-color-text-2 font-medium text-sm truncate">
                              {currency_name}
                            </div>
                          </div>
                        </div>
                        <span className="hidden absolute right-1 top-auto group-hover:block">
                          <RightIcon className="text-icon-color" />
                        </span>
                      </div>
                      <div className="flex items-center">
                        <div className="font-bold mt-2 text-color-text-1">
                          {initLoading && (
                            <div
                              className={`overflow-hidden inline-block w-60`}
                            >
                              <Skeleton
                                row={1}
                                width={['100%']}
                                skeletonItemClass={`h-4 rounded-2xl mb-0`}
                              />
                            </div>
                          )}
                          {!initLoading && (
                            <div className="text-xl flex items-center">
                              {currency_code === 'HKD' ? 'HK' : ''}
                              {currency_symbol}
                              {showCurrenBalance
                                ? handleNumber(item.available || 0)
                                : '****'}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return ''
                }
              })}
            </div>
          </Transition.Child>

          <Transition.Child
            className="flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 mb-6 h-fit"
            enter="duration-900 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-10 opacity-0"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="items-center lg:flex lg:justify-between">
              <div>
                <div className="flex flex-col md:flex-row whitespace-nowrap">
                  <span className="font-bold text-lg text-color-text-1 flex items-center md:text-xl">
                    {t('account_balance:current_activities')}
                  </span>
                </div>
                {/* <div className="mt-1 text-xs text-color-text-4">
                  Balance as of Today{' '}
                  {currentDate?.format('MMM D, YYYY, HH:mm')}
                </div> */}
              </div>
              <div className="flex space-x-4 justify-start mt-4 md:justify-end md:mt-0">
                <div>
                  <Button
                    styleType="Secondary"
                    iconPosition="Right"
                    iconInstance={<NextIcon />}
                    onClick={() => handleRouter('current-account')}
                  >
                    {t('account_balance:view_more')}
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div className="w-full mt-6">
                <Table
                  columns={colums}
                  records={activities}
                  rowKey={(record) => {
                    const {
                      payin_request_id,
                      payout_request_id,
                      fx_conversion_id,
                      card_transaction_id,
                    } = record || {};
                    return (
                      payin_request_id ||
                      payout_request_id ||
                      fx_conversion_id ||
                      card_transaction_id
                    );
                  }}
                  loadingData={activitiesLoaing}
                  onRowCell={(record, dataKey) => {
                    return {
                      onClick: (evt) => {
                        evt.stopPropagation();
                        HadleGoTranscationDetails(businessID, record, navigate);
                      },
                    };
                  }}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
    </>
  );
};

export default AccountBalanceOverview;
