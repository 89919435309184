import React from 'react';
import { ISupportingDocuments } from '@/types/payouts-type';
import { useTranslation } from 'react-i18next';
import Label from '@/components/common/label';
import PayeeItem from '../components/payee-item';
import FileInput from '@/components/common/file-input/file-input';
import AntdInput from '@/components/common/antd/input/input';

interface ISupportingDocumentsProps {
  isView: boolean;
  data: ISupportingDocuments;
  setData: (data: ISupportingDocuments) => void;
}

const SupportingDocuments: React.FC<ISupportingDocumentsProps> = (props) => {
  const { data, setData, isView } = props;
  const { reference, poNumber, poFiles, invoiceFiles } = data;
  const { t } = useTranslation('payouts');

  return (
    <div className="rounded shadow-card-drop bg-primary-background-color p-6 mb-4 mx-4">
      <div className="font-bold text-xl text-color-text-1">
        {t('supporting_documents')}
      </div>
      <div className="border-b border-border-color mb-6 mt-4"></div>
      <div className="grid grid-cols-2 gap-y-6 gap-x-4">
        <div>
          <Label value={t('internal_reference_number')} />
          <AntdInput
            disabled={isView}
            value={reference}
            onChange={(value) => setData({ ...data, reference: value })}
          />
        </div>
        <div>
          <Label value={t('purchase_order_number')} />
          <AntdInput
            disabled={isView}
            value={poNumber}
            onChange={(value) => setData({ ...data, poNumber: value })}
          />
        </div>
        <PayeeItem
          title={t('invoice_receipt_attachment')}
          content={
            invoiceFiles.length > 0 || !isView ? (
              <FileInput
                disabled={isView}
                name={'invoice'}
                defaultValue={invoiceFiles}
                rowQuantity={3}
                proportion={1}
                dataBase={'treasury-services'}
                maxCount={
                  isView ? invoiceFiles.length : invoiceFiles.length + 1
                }
                onChange={(value) => setData({ ...data, invoiceFiles: value })}
              />
            ) : (
              ''
            )
          }
        />
        <PayeeItem
          title={t('purchase_order_attachment')}
          content={
            poFiles.length > 0 || !isView ? (
              <FileInput
                disabled={isView}
                name={'po'}
                defaultValue={poFiles}
                rowQuantity={3}
                proportion={1}
                dataBase={'treasury-services'}
                maxCount={isView ? poFiles.length : poFiles.length + 1}
                onChange={(value) => setData({ ...data, poFiles: value })}
              />
            ) : null
          }
        />
      </div>
    </div>
  );
};

export default React.memo(SupportingDocuments);
