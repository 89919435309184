import type { SkeletonProps } from './type';
import { useCallback } from 'react';
import '../common/classes.css'

const Skeleton = <T,>(props: SkeletonProps<T>) => {
  const {
    className,
    loading = true,
    title,
    avatar,
    row = 3,
    width = [],
    avatarClass = '',
    skeletonItemClass = 'h-4 rounded-2xl mb-8',
  } = props;

  const lineHeight = useCallback(
    (i: number) => {
      if (width && width.length) {
        if (typeof width[i] === 'string') {
          return {
            width: width[i],
          };
        }
        if (typeof width[i] === 'number') {
          return {
            width: `${width[i]}px`,
          };
        }
      }
      return {};
    },
    [width]
  );

  return loading ? (
    <div className={`${className} flex w-full h-full`}>
      {avatar && (
        <div
          className={`skeleton_avatar ${avatarClass} bg-gradient-to-r from-border-color/60 to-disabled-color/0`}
        />
      )}
      <div className={`flex flex-col flex-1 h-full`}>
        {title && <div className={`relative verflow-hidden`} />}
        {new Array(row).fill('').map((r, i) => {
          return (
            <div
              key={`${r}_${i}`}
              className={`skeleton_base ${skeletonItemClass} bg-gradient-to-r from-border-color/60 to-disabled-color/0`}
              style={lineHeight(i)}
            ></div>
          );
        })}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Skeleton;
