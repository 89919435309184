import { payInvoice } from '../../../../../data/terminal';
import type { IAlipayMethod } from '../interface';
import PayTypes from '../pay-types';
import { handleBuildOrderPayment } from '../utils';

interface IAlipayParams {
  businessID: string;
  orderNumber: string;
  payData: IAlipayMethod;
  payment: any;
  email: string;
  isPC: boolean;
  lang: string;
}

const UseAlipay = async (params: IAlipayParams) => {
  const { businessID, orderNumber, payData, payment, email, isPC, lang } =
    params;

  const { returnUrl, amount, type, currenToken } = payData;

  let payKey = PayTypes.ALIPAYHK;
  if (type === PayTypes.ALIPAYHK) {
    payKey = PayTypes.ALIPAYHK;
  } else if (type === PayTypes.ALIPAYCN) {
    payKey = PayTypes.ALIPAYCN;
  }

  let newPayment = {};
  if (isPC) {
    newPayment = {
      alipay: [
        {
          amount,
          merchant_presented_qr_code: {
            payment_inst: payKey,
          },
        },
      ],
    };
  } else {
    newPayment = {
      alipay: [
        {
          amount,
          in_web: {
            payment_inst: payKey,
            return_url: returnUrl,
          },
        },
      ],
    };
  }

  const order = handleBuildOrderPayment(newPayment, payment);
  try {
    const resp:any = await payInvoice({
      businessID,
      orderNumber,
      order,
      email,
      lang,
      token: currenToken,
    });
    const { code, data: fulfilOrder } = resp || {};

    if (
      code === 200 &&
      fulfilOrder.sale_transactions &&
      fulfilOrder.sale_transactions.length > 0
    ) {
      return {
        order: fulfilOrder,
      };
    } else {
      return {
        status: 500,
        ...resp,
      };
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export default UseAlipay;
