import React, { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Dropdown, Menu } from 'antd';
import EllipsisIcon from '@/components/svg/ellipsis-icon';
import { TableRecord } from '@/components/table/interface';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { handleMount } from '@/utils/payouts-utils';
import { getCookie } from '@/utils';
import { Constants } from '@/constants';
import api from '@/libs/api';
import Config from '@/libs/config';
import Table from '@/components/table';

interface ILatestActivitiesProps {
  businessID: string;
  viewId: string;
  permissions: string[];
}
const MenuItem = Menu.Item;
const LatestActivities: React.FC<ILatestActivitiesProps> = (props) => {
  const { businessID } = props;

  const [records, setRecords] = useState<TableRecord[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [recordLoading, setRecordLoading] = useState<boolean>(true);
  const { t } = useTranslation('payouts');
  const { t: ct } = useTranslation('common');
  const { lang = 'en' } = useParams();
  const navigate = useNavigate();
  const dateFormat = lang === 'en' ? 'MMM DD, YYYY' : 'MMM DD日, YYYY';
  const columns: ColumnsType<TableRecord> = [
    {
      key: 'display_name',
      title: t('payee_name'),
      dataIndex: 'display_name',
      width: 160,
    },
    {
      key: 'payout_category_name',
      title: t('transfer_category'),
      dataIndex: 'payout_category_name',
      width: 160,
    },
    {
      key: 'transfer_method',
      title: t('transfer_method'),
      dataIndex: 'transfer_method',
      width: 180,
    },
    {
      key: 'status',
      title: t('status'),
      dataIndex: 'status',
      width: 180,
      render: (_key, record) => {
        const { status = '', schedule_status = '', i18n = {} } = record;
        const scheduleStatus = schedule_status || ''
        const schedule = scheduleStatus.toLocaleLowerCase();
        const transfer = status.toLocaleLowerCase();
        if (schedule === 'pending approval' && transfer === 'initiated') {
          return (
            <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-warning-color bg-warning-color/10 uppercase">
              {t('pending_approval')}
            </span>
          );
        }
        if (
          (schedule === 'approved' && transfer === 'initiated') ||
          (schedule === 'pending verification' && transfer === 'suspended')
        ) {
          return (
            <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-warning-color bg-warning-color/10 uppercase">
              {t('pending_for_transfer')}
            </span>
          );
        }
        if (schedule === 'approved' && transfer === 'processing transfer') {
          return (
            <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-primary-color bg-primary-color/10 uppercase">
              {t('processing_transfer')}
            </span>
          );
        }
        if (schedule === 'approved' && transfer === 'settled') {
          return (
            <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#4CD964] bg-[#4CD964]/10 uppercase">
              {t('settled')}
            </span>
          );
        }
        if (schedule === 'rejected' && transfer === 'reversed') {
          return (
            <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#828282] bg-[#828282]/10 uppercase">
              {t('rejected')}
            </span>
          );
        }
        if (schedule === 'cancelled' && transfer === 'reversed') {
          return (
            <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#8E8E93] bg-[#8E8E93]/10 uppercase">
              {t('reversed')}
            </span>
          );
        }
        if (transfer === 'refunded') {
          return (
            <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#F43F5E] bg-[#F43F5E]/10 uppercase">
              {t('refunded')}
            </span>
          );
        }
        return (
          <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#8E8E93] bg-[#8E8E93]/10 uppercase">
            {i18n.status && i18n.status[lang] ? i18n.status[lang] : status}
          </span>
        );
      },
    },
    {
      key: 'local_amount',
      title: t('billing_amount'),
      align: 'right',
      width: 170,
      render: (_key, record) => {
        const { status = '', local_amount, local_currency } = record;
        return (
          <div className="flex items-center justify-end">
            <div
              className={`${status === 'Reversed' ? 'text-color-text-2 line-through' : ''
                }`}
            >
              {handleMount(local_amount, 2)}
            </div>
            <div className="ml-1">{local_currency}</div>
          </div>
        );
      },
    },
    {
      key: 'transaction_amount',
      title: t('transfer_amount'),
      align: 'right',
      width: 170,
      render: (_key, record) => {
        return `${handleMount(record['transaction_amount'], 2, true)} ${record['transaction_currency']
          }`;
      },
    },
    {
      key: 'p_created_at',
      title: t('created_at'),
      dataIndex: 'p_created_at',
      align: 'right',
      width: 126,
      render: (_key, record) => {
        let value = record['p_created_at'];
        if (value && value.indexOf('T') > -1 && value.indexOf('Z') < 0) {
          value = `${value}Z`;
        }
        if (value) {
          return (
            <span className="whitespace-nowrap">
              {dayjs(value).format(dateFormat)}
              <br />
              {dayjs(value).format('HH:mm:ss')}
            </span>
          );
        } else {
          return <span></span>;
        }
      },
    },
    {
      title: t('actions'),
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 88,
      render: (_key, record) => {
        return (
          <Dropdown
            destroyPopupOnHide={true}
            dropdownRender={() => (
              <Menu className="bg-white">
                <MenuItem key="view">
                  <Link
                    to={`/${lang}/businesses/${businessID}/payouts/latest/${record.payout_request_id}`}
                  >
                    <div className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5">
                      <span className="text-color-text-1 text-sm font-medium">
                        {ct('view')}
                      </span>
                    </div>
                  </Link>
                </MenuItem>
              </Menu>
            )}
          >
            <div className="justify-center flex">
              <div className="flex items-center cursor-pointer p-1.5 rounded-full w-max hover:bg-primary-color/10 group">
                <EllipsisIcon className="text-icon-color group-hover:text-primary-color" />
              </div>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    if (businessID) {
      loadRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessID]);

  const loadRecords = async (page = 1, pageSize = 50) => {
    setRecordLoading(true);
    const token = getCookie(Constants.TOKEN) || '';
    try {
      const resp = await api.post(
        `${Config.urls.gatewayUrl}/api/treasury-services/api/activities`,
        {
          limit: pageSize + 1,
          offset: (page - 1) * pageSize,
          actions: ['payout'],
          type: 'all',
        },
        {
          headers: {
            'x-user-access-token': token,
            'x-p-business-id': businessID,
            'Content-Type': 'application/json',
            'x-i18n-lang': lang,
          },
        }
      );
      const { data } = resp || {};
      const { activities } = data || {};
      if (activities && Array.isArray(activities)) {
        setRecords(activities);
      }
    } catch (error) {
      console.error(error);
    }
    setPage(page);
    setPageSize(pageSize);
    setRecordLoading(false);
  };

  const hadleView = (record: any) => {
    navigate(
      `/${lang}/businesses/${businessID}/payouts/latest/${record.payout_request_id}`
    );
  };

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <Table
        columns={columns}
        className="my-6"
        records={records}
        loadingData={recordLoading}
        pagination={
          records.length > 0
            ? {
              current: page,
              currentSize: records.length,
              pageSize: pageSize,
              changePageCallback: loadRecords,
            }
            : undefined
        }
        rowKey={'payout_request_id'}
        onRowCell={(record, dataKey) => {
          return {
            onClick: (evt) => {
              evt.stopPropagation();
              if (dataKey === 'action') return;
              if (record.payout_request_id) {
                hadleView(record);
              }
            },
          };
        }}
      />
    </div>
  );
};

export default LatestActivities;
