
import { IFileObject } from '@/types/common-types';
import { BusinessStatus, IBusiness } from '@/types/business-types';
import { TFunction } from 'i18next';
import { getCurrentI18nValue } from '.';

export const initBusinessPictureData = (pictures: any[]) => {
  const businessPictures: IFileObject[] = [];
  if (Array.isArray(pictures) && pictures.length > 0) {
    pictures.forEach((item) => {
      const filesId = item.directus_files_id.id;
      businessPictures.push({
        id: filesId,
        directusID: item.id,
        fileUrl: item.file_url,
      });
    });
  }
  return businessPictures;
};

export const resolvingBusinessStatus = (
  business: IBusiness,
  isActive = false
) => {
  const {
    business_application_status = '',
    status = '',
    associate_status = '',
  } = business;
  let currentStatus = business_application_status;

  const businessStatus =
    status === 'Inactive' ? business_application_status : status;

  if (status === 'Suspended') {
    currentStatus = 'suspended';
  } else {
    if (
      businessStatus.toLocaleLowerCase() === 'active' &&
      (associate_status === 'active' || isActive)
    ) {
      currentStatus = 'active';
    } else {
      if (associate_status !== 'active') {
        currentStatus = 'pending admission';
      }
    }
  }

  return currentStatus.toLocaleLowerCase() as BusinessStatus;
};

export const translateStatusFuc = (
  data: IBusiness,
  currentStatus: string,
  lang: string,
  t: TFunction<"translation", undefined>
) => {
  let translateStatus = currentStatus;

  switch (translateStatus) {
    case 'active':
      translateStatus = t('personal_space:active');
      break;
    case 'pending admission':
      translateStatus = t('personal_space:pending_admission');
      break;
    case 'suspended':
      translateStatus = t('personal_space:suspended');
      break;

    default:
      translateStatus = getCurrentI18nValue(
        data,
        'business_application_status',
        lang
      );
      break;
  }

  return translateStatus;
};

export const getStatusStyle = (status: string) => {
  const customStyles = {
    color: '#FF9901',
    backgroundColor: 'rgba(255, 153, 1, 0.1)',
  };

  switch (status) {
    case BusinessStatus.ACTIVE:
      customStyles.color = '#10B981';
      customStyles.backgroundColor = 'rgba(16, 185, 129, 0.1)';
      break;
    case BusinessStatus.SUSPENDED:
    case BusinessStatus.REJECTED:
      customStyles.color = '#F43F5E';
      customStyles.backgroundColor = 'rgba(255, 0, 0, 0.1)';
      break;
    case BusinessStatus.UNDER_REVIEW:
      customStyles.color = '#33BBCD';
      customStyles.backgroundColor = 'rgba(51, 187, 205, 0.1)';
      break;
    case BusinessStatus.INACTIVE:
      customStyles.color = '#8134CF';
      customStyles.backgroundColor = 'rgba(129, 52, 207, 0.1)';
      break;
    case BusinessStatus.PENDING_ADMISSION:
      customStyles.color = '#94A3B8';
      customStyles.backgroundColor = 'rgba(148, 163, 184, 0.1)';
      break;
    case BusinessStatus.DORMANT:
      customStyles.color = '#828282';
      customStyles.backgroundColor = 'rgba(130, 130, 130, 0.1)';
      break;
    // case 'terminated':
    //   customStyles.color = '#FFA430';
    //   customStyles.backgroundColor = 'rgba(255, 164, 48, 0.1)';
    //   break;
    case BusinessStatus.REQUIRED_APPLICANT_VERIFICATION:
      customStyles.color = '#EA1CD5';
      customStyles.backgroundColor = 'rgba(255, 43, 133, 0.1)';
      break;
    case BusinessStatus.ABANDONED:
      customStyles.color = '#828282';
      customStyles.backgroundColor = 'rgba(130, 130, 130, 0.1)';
      break;
    case BusinessStatus.INCOMPLETE_APPLICATION:
      customStyles.color = '#FFA217';
      customStyles.backgroundColor = 'rgba(255, 162, 23, 0.1)';
      break;
    case BusinessStatus.LEGACY_POS_ACCOUNT:
    case BusinessStatus.LEGACY_PAYMENT_ACCOUNT:
    case BusinessStatus.MANUAL_UNDERWRITING:
      customStyles.color = '#EAC506';
      customStyles.backgroundColor = 'rgba(234, 197, 6, 0.1)';
      break;
    case BusinessStatus.REQUIRED_ADDITIONAL_CPMPANY_MEMBERS:
      customStyles.color = '#8134CF';
      customStyles.backgroundColor = 'rgba(129, 52, 207, 0.1)';
      break;
    case BusinessStatus.PENDING_PRIMARY_NATURAL_PERSON:
      (customStyles.color = '#EE8888');
      customStyles.backgroundColor = 'rgba(238, 136, 136, 0.1)';
      break;

    default:
      break;
  }

  return customStyles;
};
