import styles from '@/pages/content.module.css';
import Config from '@/libs/config';
import { useParams } from 'react-router-dom';

const PrivacyPolicy = () => {
  const { lang = 'en' } = useParams();
  const prefix = Config.staticPath;

  return (
    <>
      <head>
        <link
          rel="preload"
          href={`${prefix}/font/Outfit-Light.woff`}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="preload"
          href={`${prefix}/font/Outfit-Bold.woff`}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        ></link>
      </head>
      {lang !== 'zh-CN' && lang !== 'zh-HK' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title}`}>Privacy Policy</div>
            <div className={`${styles.content}`}>
              <h3>1. Scope of Policy</h3>
              <p>
                This Policy applies to any user of our Services anywhere in the
                world and any visitor to our website, mobile app, or other
                channel (collectively, “<strong>Sites</strong>”). This Policy
                does not apply to third- party websites, products or services
                even if they are linked or provided in conjunction with our
                Services or Sites. “<strong>Services</strong>” means any
                products, services, technologies or functionalities offered by
                Wonder Ventures Limited.{' '}
              </p>
              <h3>2. Possession of Data</h3>
              <p>
                Within this Privacy Policy, “we”, “us”, “our”, and “Wonder”
                refers to Wonder Ventures Limited. The personal information
                shared with us by our users is stored and maintained by Wonder
                Ventures Limited located at Suite 05-181, 5/F, 77 Hoi Bun road,
                The Quayside, Kwun Tong, Kowloon, Hong Kong.
              </p>
              <h3>3. Legal Basis to Collect and Process Personal Data</h3>
              <p>
                We must have a legal basis (a valid legal reason) for collecting
                and processing your personal data. Our legal basis will be one
                of the following: <br />
              </p>
              <ol>
                <li>Keeping our contracts and agreements with you</li>
                <li>Legal and regulatory restrictions </li>
                <li>
                  Clear consent from our clients to use our products and
                  services{' '}
                </li>
                <li>Provide adequate services for our clients </li>
              </ol>
              <h3>4. Information Collected</h3>
              <p>
                (a) When registering or creating an account with Wonder, we will
                collect information such as your name, phone number, physical
                address, email address and such other information as Wonder
                deems necessary in order to identify you, maintain the security
                of your application, and verify and control access to your
                account. <br />
              </p>
              <p>
                (b) For complete access to Wonder’s services, we may collect
                additional information about you and the business parties you
                have affiliations with including but not limit to:
              </p>
              <ol>
                <li>
                  Name, address, phone number, email address, date of birth,
                  your photos, nationality, information for the verification of
                  identity – including identification document type and
                  identification number – and additional information that may be
                  required for identity/account authentication{' '}
                </li>
                <li>Tax ID number </li>
                <li>
                  Details of your company’s bank account including account
                  number, sort code and IBAN{' '}
                </li>
              </ol>
              <p>
                (c) Wonder will also collect information about you from publicly
                available sources such as official registers and databases as
                well selected social media websites or apps to carry out our due
                diligence checks. In addition, we collect information from media
                stories and websites to ensure your business is legitimate and
                to verify screening results.
              </p>
              <p>
                (d) We may also collect information about you from affiliates
                (by “affiliates” we mean other companies that Wonder has a
                contractual relationship with) who provide us with services such
                as “name screening” and biometric data capturing in order to
                help verify your identity which, in turn, allows us to improve
                the safety of our services. This includes being able to detect
                and respond quickly to any fraud or security risks.
                <br />
              </p>
              <p>
                (e) You acknowledge and agree that, where information relating
                to other parties is disclosed to Wonder by you, you have
                provided a copy of this Privacy Policy to such parties prior to
                your disclosure of their information to Wonder, and you have
                obtained all relevant consents from such parties for the use of
                their information in the manner as described in this Privacy
                Policy. You further represent and warrant that you have complied
                with the requirements of all Applicable Laws with respect to
                such information and its use by us for the purposes set out
                herein.
              </p>
              <h3>5. Purpose of Data Collection</h3>
              <p>
                Wonder will collect your information only for the purpose of,
                and in connection with, providing services to you, including but
                not limited to “Know Your Customer” and customer due diligence
                procedures, account set up and administration, facilitating
                transactions related to your account, credit risk assessments,
                audits, delivering marketing and events communication, internal
                research and development purposes. Wonder may also use the
                information supplied to compile aggregate statistics and data on
                our customers as a whole, for the purpose of managing and
                improving our services to you as well as our services generally.
                The information may also be used as necessary to fulfil our
                legal and Regulatory obligations and meet internal and external
                audit requirements as well as contractual obligations with our
                partner banks and marketplaces. In the event that Wonder wishes
                to use your this information for any other purpose, we will
                notify you of such intent and obtain your consent before doing
                so, unless such new purpose is required by Applicable Laws.
              </p>
              <h3>6. Third Parties</h3>
              <p>
                Wonder may at our discretion partner with third party to either
                display advertising in our Wonder application or on the Wonder
                website, or to manage Wonder’s advertising on other sites. Our
                third-party partners may use technologies such as cookies to
                gather information about your activities while using the
                application or website and other sites in order to provide you
                advertising based on your browsing activities and interests.
                <br />
              </p>
              <p>
                In addition, Wonder automatically gathers and stores in log
                files, certain information, such as Internet Protocol (IP)
                addresses, browser type, internet service provider (ISP),
                referring/exit pages, operating system, date/time stamp, and/or
                clickstream data. Wonder does not link this
                automatically-collected data to other information we collect
                about you. Wonder, as well as our third-party partners, may use
                local storage to store content information and preferences.
              </p>
              <h3>7. Use of Personal Information</h3>
              <p>
                Your information held by us may be used for the following
                purposes:{' '}
              </p>
              <p>
                (a) Wonder may from time to time, use your information to send
                you information on relevant services. If at any time, you do not
                wish to receive that information, you may opt-out by notifying
                us at hello@bindo.com
              </p>
              <p>(b) Process your request for our services; </p>
              <p>
                (c) Management, operation, audit, and maintenance of the
                services, and exercising our and your rights within the Customer
                Agreement
              </p>
              <p>(d) Collecting money due from you</p>
              <p>(e) Assessing your credit risk</p>
              <p>
                (f) Designing new, or improving existing, services provided by
                Wonder
              </p>
              <p>(g) Communication between you and Wonder</p>
              <p>
                (h) Investigation of complaints, suspicious activities, and
                failed transactions
              </p>
              <p>(i) Prevention or detection of crime or suspected crime</p>
              <p>(j) Disclosure as required by Applicable Laws</p>
              <h3>8. Disclosure of Information</h3>
              <p>
                We will safeguard the information collected from you in
                accordance with the laws and regulations set out in the Hong
                Kong Special Administrative Region. Subject to our obligations
                therein, we may, for the purpose(s) set out in Section 3 above,
                transfer or disclose such Personal Information to the following
                parties:
              </p>
              <p>(a) Our partner banks and financial services companies </p>
              <p>(b) Marketplaces, to facilitate your business with them </p>
              <p>
                (c) E-comm platforms with which we have contractual agreement to
                share your information to ensure you can receive disbursement of
                your proceeds{' '}
              </p>
              <p>
                (d) Our agents or contractors under a duty of confidentiality to
                us who provide administrative, telecommunications, computer,
                payment, data processing, professional advisory, audit or other
                services to us in connection with the operation of our business;
              </p>
              <p>
                (e) Our affiliates which owe a duty of confidentiality to us
              </p>
              <p>
                (f) Any law enforcement agencies, judicial authorities,
                regulatory bodies and/or any person or entity to whom we have an
                obligation under Applicable Laws to disclose, but such
                disclosure will only be made under proper authority.
              </p>
              <p>
                (g) Auditors to support our audit, compliance, and corporate
                governance functions, including, but not limited to, compliance
                with Applicable Laws;
              </p>
              <p>
                (h) Internal Risk Team to detect risks, prevent fraud, or assess
                suspicious activities
              </p>
              <p>
                (i) Our contracted affiliations including but not limited to
                cloud-based servers, and third party service providers in
                multiple jurisdictions. The Personal Information that Wonder
                collects from you may be transferred to, and stored at, a
                destination outside of Hong Kong. It may also be processed by
                persons operating outside of Hong Kong on behalf of Wonder, our
                affiliate(s), or one of our service providers, providing
                transaction processing, payment detail verification, as well as
                other Services-related support. Wonder will take commercially
                reasonable steps to ensure that information disclosed is treated
                securely and in accordance with this Privacy Policy within and
                outside of Hong Kong.{' '}
              </p>
              <p>
                By using our services or submitting your confidential
                information, you consent to your information being transferred
                to, stored in, and processed per above in a jurisdiction outside
                of Hong Kong, including jurisdictions that have different data
                protection rules than your home country.
              </p>
              <h3>9. Accuracy</h3>
              <p>
                Wonder will make reasonable efforts to ensure that your
                information is sufficiently accurate, complete and up-to-date to
                minimise the possibility that inappropriate information may be
                used to make a decision about you.{' '}
              </p>
              <p>
                Wonder will not routinely update your Personal Information
                unless such a process is necessary to fulfil the purposes for
                which the information was collected. In accordance with the
                Customer Agreement, you must notify us as soon as possible if
                any of the information you have provided to us has changed.
              </p>
              <h3>10. Contact</h3>
              <p>
                If you have any queries about this Privacy Policy, the Customer
                Agreement or any of our privacy practices, or wish to make a
                complaint, please contact our team by email at
                compliance@wonder.app.
              </p>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-CN' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title}`}>隐私政策</div>
            <div className={`${styles.content}`}>
              <h3>1.政策的范围</h3>
              <p>
                本政策适用于世界任何地方的我们的服务的任何用户以及我们的网站、移动应用程序或其他渠道（统称为
                &quot;<strong>网站</strong>
                &quot;）的任何访问者。本政策不适用于第三方网站、产品或服务，即使它们与我们的服务或网站链接或一起提供。&quot;
                <strong>服务</strong>&quot;是指Wonder Ventures
                Limited提供的任何产品、服务、技术或功能。{' '}
              </p>
              <h3>2.数据的拥有</h3>
              <p>
                在本隐私政策中，&quot;我们&quot;、&quot;我们的 &quot;和
                &quot;Wonder &quot;是指Wonder Ventures
                Limited。我们的用户与我们分享的个人信息由位于
                香港观塘海滨道77号海滨滙5楼05-181的Wonder Ventures
                Limited存储和维护
                <strong data-wg-splitted="">。</strong>
              </p>
              <h3>3.收集和处理个人数据的法律依据</h3>
              <p>
                我们必须有法律依据（有效的法律理由）来收集和处理你的个人数据。我们的法律依据将是以下之一。{' '}
                <br />
              </p>
              <ol>
                <li>保持我们与你的合同和协议</li>
                <li>法律和监管限制 </li>
                <li>我们的客户明确同意使用我们的产品和服务 </li>
                <li>为我们的客户提供充分的服务 </li>
              </ol>
              <h3>4.收集的信息</h3>
              <p>
                (a) 在 &quot;万得
                &quot;注册或创建账户时，我们将收集诸如你的姓名、电话号码、实际地址、电子邮件地址以及
                &quot;万得
                &quot;认为必要的其他信息，以便识别您的身份，维护你的应用程序的安全，并核实和控制对你的账户的访问。{' '}
                <br />
              </p>
              <p>
                (b) 为了完整地使用 &quot;万得
                &quot;的服务，我们可能会收集关于您和与您有关联的企业方的额外信息，包括但不限于：
                1:
              </p>
              <ol>
                <li>
                  姓名、地址、电话号码、电子邮件地址、出生日期、你的照片、国籍、用于核实身份的信息--包括身份证件类型和识别号码--以及身份/账户认证可能需要的其他信息{' '}
                </li>
                <li>税号 </li>
                <li>你的公司的银行账户详情，包括账户号码、分类代码和IBAN。 </li>
              </ol>
              <p>
                (c)
                万得也会从公开渠道收集你的信息，如官方登记册和数据库，以及选定的社交媒体网站或应用程序，以进行我们的尽职调查。此外，我们还从媒体报道和网站收集信息，以确保你的业务的合法性，并核实筛选结果。
              </p>
              <p>
                (d) 我们也可能从关联公司（&quot;关联公司
                &quot;是指与万达有合同关系的其他公司）收集您的信息，这些公司为我们提供
                &quot;姓名筛选
                &quot;和生物识别数据采集等服务，以帮助验证你的，这反过来又使我们能够提高我们服务的安全性。这包括能够发现并迅速应对任何欺诈或安全风险。
                <br />
              </p>
              <p>
                (e)
                您承认并同意，如果您向万得披露与其他方有关的信息，您在你的向万得披露其信息之前，已经向这些方提供了本隐私政策的副本，并且您已经获得这些方的所有相关同意，以本隐私政策所述的方式使用其信息。您进一步声明和保证，您已经遵守了所有适用法律关于这些信息以及我们为本文所述目的使用这些信息的要求。
              </p>
              <h3>5.数据收集的目的</h3>
              <p>
                万得将收集你的，仅用于向您提供服务，包括但不限于
                &quot;了解你的客户
                &quot;和客户尽职调查程序、账户设置和管理、促进与你的账户有关的交易、信用风险评估、审计、提供营销和活动沟通、内部研究和开发目的。万得也可能使用所提供的信息来汇编关于我们客户的总体统计和数据，以便管理和改善我们对您的服务以及我们的一般服务。这些信息也可能被用于履行我们的法律和监管义务，满足内部和外部审计要求，以及与我们的合作伙伴银行和市场的合同义务。如果
                &quot;万得
                &quot;希望将你的该信息用于任何其他目的，我们将通知您该意图，并在这样做之前获得你的同意，除非该新目的是由适用法律要求的。
              </p>
              <h3>6.第三方</h3>
              <p>
                万得可能酌情与第三方合作，在我们的万得应用程序或万得网站上显示广告，或管理万得在其他网站上的广告。我们的第三方合作伙伴可能会使用诸如cookies等技术来收集有关你的，同时使用应用程序或网站和其他网站的活动，以便根据你的浏览活动和兴趣向您提供广告。
                <br />
              </p>
              <p>
                此外，&quot;万得
                &quot;会自动收集并在日志文件中存储某些信息，如互联网协议（IP）地址、浏览器类型、互联网服务提供商（ISP）、参考/退出页面、操作系统、日期/时间戳和/或点击流数据。万得不会将这些自动收集的数据与我们收集的关于您的其他信息联系起来。万得以及我们的第三方合作伙伴，可能会使用本地存储来存储内容信息和偏好。
              </p>
              <h3>7.个人资料的使用</h3>
              <p>你的我们持有的信息可用于以下目的。 </p>
              <p>
                (a) &quot;万得
                &quot;可能会不时地使用你的，向您发送相关服务的信息。如果在任何时候，您不希望收到这些信息，您可以通过通知我们来选择退出，hello@bindo.com
              </p>
              <p>(b) 处理你的要求我们提供的服务。 </p>
              <p>
                (c)
                管理、操作、审计和维护服务，并在客户协议内行使我们和你的的权利
              </p>
              <p>(d) 收取你的欠款</p>
              <p>(e) 评估你的信用风险</p>
              <p>(f) 设计新的、或改进现有的Wonder所提供的服务</p>
              <p>(g) 您和Wonder之间的沟通</p>
              <p>(h) 对投诉、可疑活动和失败交易进行调查</p>
              <p>(i) 预防或侦查犯罪或涉嫌犯罪</p>
              <p>(j) 适用法律要求的披露</p>
              <h3>8.信息披露</h3>
              <p>
                我们将根据香港特别行政区的法律和法规，保护从您那里收集的信息。根据我们的义务，我们可以为上述第3条规定的目的，向以下各方转移或披露这些个人信息。
              </p>
              <p>(a) 我们的伙伴银行和金融服务公司 </p>
              <p>(b) 市场平台，以促进你的与他们的业务。 </p>
              <p>
                (c)
                与我们有合同协议的电子通信平台，以分享你的信息，以确保您能收到你的的收益支付。{' '}
              </p>
              <p>
                (d)
                我们的代理人或对我们有保密义务的承包商，他们向我们提供与我们的业务运作有关的行政、电信、计算机、支付、数据处理、专业咨询、审计或其他服务。
              </p>
              <p>(e) 对我们有保密义务的我们的附属机构</p>
              <p>
                (f)
                任何执法机构、司法机关、监管机构和/或我们根据适用法律有义务披露的任何个人或实体，但这种披露仅在适当授权下进行。
              </p>
              <p>
                (g)
                审计师，以支持我们的审计、合规和公司治理职能，包括但不限于遵守适用法律。
              </p>
              <p>(h) 内部风险小组发现风险，防止欺诈，或评估可疑的活动</p>
              <p>
                (i)
                我们的签约附属机构，包括但不限于基于云的服务器，以及多个司法管辖区的第三方服务提供商。万得从您那里收集的个人信息可能会被转移到香港以外的地方，并储存在那里。它也可能由在香港以外的人员代表万得、我们的关联公司或我们的一个服务提供商进行处理，提供交易处理、付款细节验证以及其他服务相关的支持。沃德将采取商业上合理的步骤，确保所披露的信息在香港和香港以外的地方得到安全处理，并符合本隐私政策。{' '}
              </p>
              <p>
                通过使用我们的服务或提交你的机密信息，您同意你的信息被转移到香港以外的司法管辖区，并在那里存储和按上述方式处理，包括与你的所在国有不同数据保护规则的司法管辖区。
              </p>
              <h3>9.准确度</h3>
              <p>
                万得将做出合理的努力，确保你的信息足够准确、完整和最新，以尽量减少不适当的信息被用于对您做出决定的可能性。{' '}
              </p>
              <p>
                万得不会例行更新你的个人信息，除非这种程序对于实现收集信息的目的是必要的。根据客户协议，如果您提供给我们的任何信息有变化，您必须尽快通知我们。
              </p>
              <h3>10.联系我们</h3>
              <p>
                如果您对本隐私政策、客户协议或我们的任何隐私做法有任何疑问，或希望进行投诉，请通过电子邮件与我们的团队联系，地址是compliance@wonder.app。
              </p>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-HK' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title}`}>隱私策略</div>
            <div className={`${styles.content}`}>
              <h3>1. 政策範圍</h3>
              <p>
                本政策適用於我們服務在世界任何地方的任何使用者以及我們網站，移動應用程式或其他管道（統稱為“
                <strong>網站</strong>
                ”）的任何訪問者。本政策不適用於第三方網站、產品或服務，即使它們與我們的服務或網站連結或提供。「
                <strong>服務</strong>
                」指萬達創投有限公司提供的任何產品、服務、技術或功能。{' '}
              </p>
              <h3>2. 擁有數據</h3>
              <p>
                在本隱私政策中，「我們」、「我們」、「我們的」和「Wonder」均指Wonder
                Ventures Limited。我們的用戶與我們分享的個人資訊由位於
                香港觀塘海濱道77號海濱滙5樓05-181的Wonder Ventures
                Limited存儲和維護。
              </p>
              <h3>3. 收集和處理個人數據的法律依據</h3>
              <p>
                我們必須有法律依據（有效的法律理由）進行收集和處理
                你的個人數據。我們的法律依據是： <br />
              </p>
              <ol>
                <li>遵守我們與您簽訂的合同和協定</li>
                <li>法律和監管限制 </li>
                <li>客戶明確同意使用我們的產品和服務 </li>
                <li>為我們的客戶提供足夠的服務 </li>
              </ol>
              <h3>4. 收集的資訊</h3>
              <p>
                （a） 在Wonder註冊或創建帳戶時，我們將收集以下資訊：你的名字
                電話、實際地址、電子郵件位址以及 Wonder
                認為必要的其他資訊，以便識別您的身份，維護
                你的應用程式，並驗證和控制對 你的帳戶。 <br />
              </p>
              <p>
                （b） 為了完全訪問 Wonder
                的服務，我們可能會收集有關您和您與之有聯繫的業務方的其他資訊，包括但不限於：
              </p>
              <ol>
                <li>
                  姓名、位址、 電話、電子郵件位址、出生日期、
                  你的照片、國籍、身份核實資料（包括身份證明檔類型及身份證明號碼）及身份/帳戶認證可能需要的其他資料{' '}
                </li>
                <li>稅號 </li>
                <li>詳情 你的公司的銀行帳戶，包括帳號，排序代碼和IBAN </li>
              </ol>
              <p>
                （c） Wonder
                還將從官方註冊和資料庫以及選定的社交媒體網站或應用程式等公開來源收集有關您的資訊，以進行我們的盡職調查。此外，我們從媒體報導和網站收集資訊，以確保
                你的業務是合法的，並驗證篩查結果。
              </p>
              <p>
                （d） 我們還可能從附屬公司（“附屬公司”，我們是指 Wonder
                與之有合同關係的其他公司）收集有關您的資訊，這些附屬公司向我們提供“姓名篩選”和生物識別數據捕獲等服務，以幫助驗證
                你的身份，反過來，使我們能夠提高我們服務的安全性。這包括能夠檢測並快速回應任何欺詐或安全風險。
                <br />
              </p>
              <p>
                （e） 您承認並同意，如果您向 Wonder
                披露了與您其他方有關的資訊，則您在之前已向此類方提供了本隱私政策的副本
                你的向 Wonder
                披露其資訊，並且您已獲得此類方的所有相關同意，以便以本隱私政策中所述的方式使用其資訊。您進一步聲明並保證，您已遵守所有適用法律關於此類資訊的要求，以及我們出於此處規定的目的使用此類資訊。
              </p>
              <h3>5. 數據收集的目的</h3>
              <p>
                汪達將收集
                你的僅出於向您提供服務的目的和與之相關的資訊，包括但不限於“知道”
                你的客戶「和客戶盡職調查程式，帳戶設立和管理，促進與交易相關的
                你的帳戶，信用風險評估，審計，提供行銷和活動溝通，內部研究和開發目的。Wonder
                還可能使用所提供的資訊來編製有關我們整個客戶的匯總統計數據和數據，以便管理和改進我們為您提供的服務以及我們的總體服務。這些資訊也可能在必要時使用，以履行我們的法律和監管義務，滿足內部和外部審計要求，以及與我們的合作銀行和市場的合同義務。萬一萬一，汪達希望使用
                你的此資訊用於任何其他目的，我們將通知您此類意圖並獲得
                你的在這樣做之前同意，除非適用法律要求這種新目的。
              </p>
              <h3>6. 第三方</h3>
              <p>
                Wonder 可自行決定與第三方合作，在我們的 Wonder 應用程式或 Wonder
                網站上展示廣告，或管理 Wonder
                在其他網站上的廣告。我們的第三方合作夥伴可能會使用 Cookie
                等技術來收集以下資訊：
                你的在使用應用程式或網站時的活動和其他網站，以便為您提供基於以下方面的廣告
                你的瀏覽活動和興趣。
                <br />
              </p>
              <p>
                此外，Wonder
                會自動收集某些資訊並將其存儲在日誌檔中，例如互聯網協定 （IP）
                位址、瀏覽器類型、互聯網服務提供者
                （ISP）、引用/退出頁面、操作系統、日期/時間戳和/或點擊流數據。Wonder
                不會將這些自動收集的數據與我們收集的有關您的其他資訊相關聯。Wonder
                以及我們的第三方合作夥伴可能會使用本地存儲來存儲內容資訊和偏好。
              </p>
              <h3>7. 個人資訊的使用</h3>
              <p>你的我們持有的資訊可能用於以下目的： </p>
              <p>
                （a） 想知道可能不時使用
                你的向您發送相關服務信息的資訊。如果您在任何時候不希望收到該資訊，您可以通過通知我們來退出退出，hello@bindo.com
              </p>
              <p>（b） 程式 你的請求我們的服務; </p>
              <p>
                （c） 服務的管理、運營、審計和維護，以及行使我們的和
                你的客戶協定中的權利
              </p>
              <p>（d） 向您收取應付款項</p>
              <p>（e） 評估 你的信用風險</p>
              <p>（f） 設計新的或改進萬德提供的現有服務</p>
              <p>（g） 您與汪達之間的溝通</p>
              <p>（h） 調查投訴、可疑活動和未決交易</p>
              <p>（一） 預防或偵查犯罪或涉嫌犯罪</p>
              <p>（j） 根據適用法律的要求披露</p>
              <h3>8. 資訊披露</h3>
              <p>
                我們將根據香港特別行政區的法律法規保護從您那裡收集的資訊。根據我們在其中的義務，我們可以出於上述第3節中規定的目的，將此類個人信息轉讓或披露給以下各方：
              </p>
              <p>（a） 我們的合作銀行和金融服務公司 </p>
              <p>（b） 市場，以便利 你的與他們做生意 </p>
              <p>
                （c） 與我們簽訂合同協議共用的電子通信平臺
                你的資訊，以確保您可以收到 你的收益{' '}
              </p>
              <p>
                （d）
                對我們的代理或承包商負有保密義務，他們向我們提供與業務運營相關的行政、電信、計算機、付款、數據處理、專業諮詢、審計或其他服務;
              </p>
              <p>（e） 對我們負有保密義務的關聯公司</p>
              <p>
                （f）
                根據適用法律，我們有義務向其披露的任何執法機構、司法機關、監管機構和/或任何個人或實體，但此類披露只能在適當授權下進行。
              </p>
              <p>
                （g）
                審計師，以支援我們的審計、合規和公司治理職能，包括但不限於遵守適用法律;
              </p>
              <p>（h） 內部風險團隊，以檢測風險、防止欺詐或評估可疑活動</p>
              <p>
                （i）
                我們的合同附屬公司，包括但不限於基於雲的伺服器，以及多個司法管轄區的第三方服務提供者。Wonder
                從您處收集的個人資訊可能會被傳輸並儲存到香港以外的目的地。該等服務亦可能由在香港境外經營的人士代表Wonder、我們的關聯公司或我們的服務提供者之一進行處理，提供交易處理、付款明細驗證以及其他與服務相關的支援。Wonder
                將採取商業上合理的措施，確保所披露的資訊在香港境內外得到安全處理，並符合本隱私政策。{' '}
              </p>
              <p>
                通過使用我們的服務或提交 你的機密資訊，您同意
                你的在香港以外的司法管轄區（包括數據保護規則與以下司法管轄區不同的司法管轄區）傳輸、存儲和處理的資訊
                你的故鄉。
              </p>
              <h3>9. 準確性</h3>
              <p>
                汪達將盡合理努力確保
                你的信息足夠準確、完整和最新，以盡量減少可能使用不適當的資訊來做出關於您的決定的可能性。{' '}
              </p>
              <p>
                汪達不會定期更新
                你的個人資訊，除非此類過程對於實現收集資訊的目的是必要的。根據客戶協定，如果您提供給我們的任何信息發生更改，您必須儘快通知我們。
              </p>
              <h3>10. 聯繫方式</h3>
              <p>
                如果您對本隱私政策、客戶協定或我們的任何隱私慣例有任何疑問，或希望提出投訴，請通過電子郵件compliance@wonder.app與我們的團隊聯繫。
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivacyPolicy;
