import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import CloseIcon from '@/components/svg/close-icon';
import PayoutsChoosePayee from './payouts-choose-payee';
import PayoutsTransferDetails from './payouts-transfer-details';
import PayoutsPayoutAmount from './payouts-payout-amount';
import PayoutsTransferConfirmation from './payouts-transfer-confirmation';
import NewPayeeStep from './payouts-new-payee';
import WarningIcon from '@/components/svg/warning-icon';
import { IAvailableCountry, IAvailableCurrency, ICategory, IPayee, IPayout, IPayoutsStep, IPurpose, IRetrieveBalance } from '@/types/payouts-type';
import { initPayout, parsePayees } from '@/utils/payouts-utils';
import { IAreaCodes } from '@/types/common-types';
import { getCookie } from '@/utils';
import { Constants } from '@/constants';
import { getAvailableCountries, getAvailableCurrencies, getCategories, getRetrieveBalances, searchRecentPayees } from '@/data/payouts';
import { loadAreaCodesData } from '@/data/area-codes';
import AntdModal from '@/components/common/antd/modal';
import Button from '@/components/common/button';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IReducersState } from '@/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { IDispatch } from '@/actions';
import ActionType from '@/actions/action-type';
import GlobalLoading from '@/components/common/global-loading';
import { useUserAction } from '@/utils/common';

const PayoutsNewPage = () => {
  const { t } = useTranslation('payouts');
  const { lang = 'en', type: activekey = '', p_business_id: businessID = '' } = useParams();
  const user = useSelector((state: IReducersState) => state.account.loginUser);
  const initSteps: IPayoutsStep[] = [
    {
      key: '1',
      title: t('choose_payee'),
    },
    {
      key: '2',
      title: t('transfer_details'),
    },
    {
      key: '3',
      title: t('payout_amount'),
    },
    {
      key: '4',
      title: t('transfer_confirmation'),
    },
  ];
  const [stepCount, setStepCount] = useState<number>(0);
  const [steps, setSteps] = useState<IPayoutsStep[]>(initSteps);
  const [payout, setPayout] = useState<IPayout>(initPayout);
  const [intiPayees, setIntiPayees] = useState<IPayee[]>([]);
  const [retrieveBalances, setRetrieveBalances] = useState<IRetrieveBalance[]>(
    []
  );
  const [availableCurrencies, setAvailableCurrencies] = useState<
    IAvailableCurrency[]
  >([]);
  const [availableCountries, setAvailableCountries] = useState<
    IAvailableCountry[]
  >([]);
  const [areaCodes, setAreaCodes] = useState<IAreaCodes[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [loading, setLoading] = useState(true);
  const [createLoading, setCreateLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [purposes, setPurposes] = useState<IPurpose[]>([]);
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();

  useEffect(() => {
    loadingData();
     // eslint-disable-next-line
  }, [businessID]);

  useEffect(() => {
    if (businessID) {
      dispatch({
        type: ActionType.LOADING_MAIN_DATA,
        payload: {
          navigate,
          businessId: businessID,
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  useUserAction();

  const loadingData = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    const allPromise: any[] = [
      searchRecentPayees('', businessID, token, lang),
      getRetrieveBalances(businessID, token),
      getCategories(token, lang),
      loadAreaCodesData(),
      getAvailableCurrencies(businessID, token, lang),
      getAvailableCountries(token, lang),
    ];

    const resp = await Promise.all(allPromise);
    setRetrieveBalances(resp[1]);
    setCategories(resp[2]);
    setIntiPayees(parsePayees(resp[0]));
    setAreaCodes(resp[3]);
    setAvailableCurrencies(resp[4]);
    setAvailableCountries(resp[5]);
    setLoading(false);
  };

  const handleClose = () => {
    setModalShow(true);
  };

  const handleCancel = () => {
    setModalShow(false);
  };

  return (
    <div className="h-auto min-h-screen bg-system-background-color text-sm font-medium">
      <Transition.Root show={true} appear={true} className="h-full">
        <Transition.Child
          className="flex items-center justify-between p-4 md:px-6 md:py-8 lg:px-12"
          enter="duration-350 transform transition ease-in-out"
          enterFrom="-translate-x-1 translate-y-10 opacity-40"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="duration-500 transform transition"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="font-bold text-xl leading-[30px] md:text-3xl md:leading-10 text-color-text-1">
            {t('new_payout')}
          </div>
          <CloseIcon
            size={20}
            onClick={handleClose}
            className="text-color-text-1 cursor-pointer hover:text-primary-color p-3 bg-white rounded-full"
          />
        </Transition.Child>
        <div className="flex-1 flex flex-col pb-6 md:flex-row lg:mt-6 lg:justify-center w-4/5 lg:w-3/5 mx-auto">
          <Transition.Child
            className="flex flex-[3] items-center flex-col mb-4 md:block"
            enter="duration-450 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-10 opacity-40"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <ul className="flex justify-between w-full md:w-auto px-6 md:block md:px-0">
              {steps.map((step, index) => (
                <div
                  className={`flex md:block ${
                    index === steps.length - 1 ? '' : 'flex-1'
                  }`}
                  key={step.key}
                >
                  <li
                    className={`flex items-center text-color-text-4 hover:text-primary-color`}
                  >
                    <span
                      className={`rounded-full w-6 h-6 flex-none flex items-center justify-center ${
                        stepCount >= index
                          ? 'bg-primary-color text-color-text-6'
                          : 'bg-disabled-color text-color-text-2 border border-border-color'
                      }`}
                    >
                      {step.key}
                    </span>
                    <span
                      className={`ml-3 font-medium whitespace-nowrap hidden md:block ${
                        stepCount < index
                          ? 'text-color-text-2'
                          : 'text-color-text-1'
                      }`}
                    >
                      {step.title}
                    </span>
                  </li>
                  {index !== steps.length - 1 && (
                    <div
                      className={`mt-3 flex-1 h-0.5 md:w-0.5 md:h-[46px] md:ml-3 md:mt-0 ${
                        stepCount >= index
                          ? 'bg-primary-color'
                          : 'bg-border-color opacity-60'
                      }`}
                      aria-hidden="true"
                    ></div>
                  )}
                </div>
              ))}
            </ul>
          </Transition.Child>
          <Transition.Child
            className="flex-[7]"
            enter="duration-500 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-10 opacity-40"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="pl-0 md:pl-6">
              {steps[stepCount].key === '1' && (
                <PayoutsChoosePayee
                  businessID={businessID}
                  intiPayees={intiPayees}
                  payout={payout}
                  loading={loading}
                  setPayout={setPayout}
                  setStepCount={setStepCount}
                  setSteps={setSteps}
                  setPurposes={setPurposes}
                />
              )}
              {steps[stepCount].key === '1A' && (
                <NewPayeeStep
                  businessID={businessID}
                  intiPayees={intiPayees}
                  payout={payout}
                  categories={categories}
                  availableCurrencies={availableCurrencies}
                  availableCountries={availableCountries}
                  areaCodes={areaCodes}
                  setPayout={setPayout}
                  setStepCount={setStepCount}
                  setSteps={setSteps}
                  setPurposes={setPurposes}
                />
              )}
              {steps[stepCount].key === '2' && payout.payee && (
                <PayoutsTransferDetails
                  businessID={businessID}
                  payee={payout.payee}
                  payout={payout}
                  setPayout={setPayout}
                  categories={categories}
                  purposes={purposes}
                  setStepCount={setStepCount}
                />
              )}
              {steps[stepCount].key === '3' && payout.payee && (
                <PayoutsPayoutAmount
                  businessID={businessID}
                  payee={payout.payee}
                  retrieveBalances={retrieveBalances}
                  payout={payout}
                  setPayout={setPayout}
                  setStepCount={setStepCount}
                  setRetrieveBalances={setRetrieveBalances}
                />
              )}
              {steps[stepCount].key === '4' && payout.payee && (
                <PayoutsTransferConfirmation
                  activekey={activekey}
                  businessID={businessID}
                  payee={payout.payee}
                  payout={payout}
                  user={user}
                  setStepCount={setStepCount}
                  setCreateLoading={setCreateLoading}
                />
              )}
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
      <GlobalLoading loading={createLoading} />
      <AntdModal open={modalShow} closable={false} onCancel={handleCancel}>
        <div className="p-10 pt-8">
          <div className="flex flex-col items-center justify-center text-center">
            <div className="bg-primary-color/10 p-5 rounded-full">
              <WarningIcon className="text-primary-color" size={40} />
            </div>
            <div className="pt-4">
              <div className="text-color-text-1 text-lg leading-6 font-semibold">
                {t('cancel_this_payout')}
              </div>
              <div className="text-color-text-2 font-medium text-sm mt-2">
                {t('sure_to_cancel')}
              </div>
            </div>
          </div>
          <Button
            className="w-full mt-6"
            styleType="Border"
            onClick={() => {
              navigate(`/${lang}/businesses/${businessID}/payouts/${activekey}`);
            }}
          >
            {t('cancel_payout')}
          </Button>
          <Button onClick={handleCancel} className="w-full mt-4">
            {t('continue_payout')}
          </Button>
        </div>
      </AntdModal>
    </div>
  );
};

export default PayoutsNewPage;
