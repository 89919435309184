import { useTranslation } from 'react-i18next';
import type { ITerminalOrdersPageProps } from '../../../types/terminal-type';
import Button from '@/components/common/button';
import Table from '@/components/table';
import { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import type { TableRecord } from '@/components/table/interface';
import { IObject } from '@/types/common-types';
import NewIcon from '../terminal-icons/new-icon';
import type {
  // IFilterField,
  IFilterOptions,
} from '@/components/common/filter';
// import Filter from '@/components/common/filter';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getClassByStatus, handlePrice } from '../../../utils/terminal-untils';
import dayjs from 'dayjs';
import { Dropdown } from 'antd';
import Menu, { Item as MenuItem } from 'rc-menu';
import EllipsisIcon from '@/components/svg/ellipsis-icon';
// import Link from 'next/link';
import { downloadInvoicePDF, handleVoidOrder, resetListing } from '../../../data/terminal';
import { Alert } from '@/components/common/alert/alert';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@/components/skeleton';
import NoDataIcon from '../terminal-icons/no-data-icon';
import { IReducersState } from '@/reducers';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';
import { useUserAction } from '@/utils/common';

interface IHistoryWebPageProps extends ITerminalOrdersPageProps {
  records: IObject[];
  loading: boolean;
  filterOptions: IFilterOptions[];
  searchValue: string;
  page: number;
  pageSize: number;
  customerID: string;
  loadData: (
    currentPage?: number,
    limit?: number,
    search?: string,
    options?: IFilterOptions[]
  ) => void;
  handleGotoDetaislPage: (orderNumber: string) => void;
}

const HistoryWebPage: React.FC<IHistoryWebPageProps> = (props) => {
  const {
    businessID,
    records,
    loading,
    // filterOptions,
    // searchValue,
    customerID,
    page,
    pageSize,
    token,
    inApp,
    loadData,
    handleGotoDetaislPage,
  } = props;

  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const { lang = 'en' } = useParams();
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const business = useSelector((state: IReducersState) => state.businessState.business);
  const loginUser = useSelector((state: IReducersState) => state.account.loginUser);

  const [columns, setColumns] = useState<ColumnsType<TableRecord>>([]);

  useEffect(() => {
    getColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useUserAction(inApp ? true : false);

  // const filterFields: IFilterField[] = [];
  const dateFormat = lang === 'en' ? 'DD MMM YYYY' : 'YYYY年MM月DD日';

  const dateInTabale = (date: string, showTiem = true) => {
    const ymdRes = dayjs(date).tz('Asia/Hong_Kong').format(dateFormat);
    const timeRes = dayjs(date).tz('Asia/Hong_Kong').format('HH:mm:ss');
    return (
      <div className="flex flex-col">
        <span className=" whitespace-nowrap">{`${ymdRes}`}</span>
        {showTiem && <span className=" whitespace-nowrap">{`${timeRes}`}</span>}
      </div>
    );
  };

  const handleVoid = async (record: IObject) => {
    const { number } = record;
    const data = await handleVoidOrder({
      number,
      token,
      businessID,
      userID: loginUser.id,
    });

    if (data) {
      Alert.success({
        message: t('extended_service:void_invoice_successfully'),
        wrapClass: '-top-10',
      });
      await loadData();
    } else {
      Alert.error({
        message: t('extended_service:void_invoice_failed'),
        wrapClass: '-top-10',
      });
    }
  };

  const handleDownload = async (record: IObject) => {
    const { file_name = '', url = '' } = await downloadInvoicePDF(
      record,
      business,
      lang
    );
    if (url) {
      try {
        await fetch(url).then((res) =>
          res.blob().then((blob) => {
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${`${file_name}`}`;
            a.click();
            window.URL.revokeObjectURL(url);
          })
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getColumns = () => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('common:extended_service'),
          key: '1',
          type: 'label',
        },
        {
          name: t('common:order_terminal'),
          key: '2',
          type: 'label',
        },
      ]
    });

    const columns: ColumnsType<TableRecord> = [
      {
        key: 'reference_number',
        dataIndex: 'reference_number',
        width: 210,
        align: 'left',
        title: t('extended_service:reference_number'),
      },
      {
        key: 'correspondence_state',
        dataIndex: 'correspondence_state',
        width: 160,
        align: 'left',
        title: t('extended_service:payment_status'),
        render: (_key, record) => {
          const status = record.correspondence_state || '';
          const { className, showStatus } = getClassByStatus(status, t);

          if (!status) return <span>--</span>;
          return (
            <span
              className={`font-medium rounded-3xl text-xs px-3 py-1 bg-opacity-10 ${className}`}
            >
              {showStatus.toUpperCase()}
            </span>
          );
        },
      },
      {
        key: 'delivery_status',
        align: 'left',
        width: 200,
        dataIndex: 'delivery_status',
        title: t('extended_service:delivery_status'),
        render: (_key, record) => {
          const deliveryOrder = record.delivery_order || {};
          const status = deliveryOrder.state || '';
          const { className, showStatus } = getClassByStatus(status, t);

          if (!status) return <span>--</span>;
          return (
            <span
              className={`font-medium rounded-3xl text-xs px-3 py-1 bg-opacity-10 ${className}`}
            >
              {showStatus.toUpperCase()}
            </span>
          );
        },
      },
      {
        key: 'delivery_address',
        dataIndex: 'delivery_address',
        align: 'left',
        width: 210,
        title: t('extended_service:delivery_address'),
        render: (_key, record) => {
          const { oms_delivery_note = {} } = record;
          return (
            <div className="w-[210px] truncate">
              {oms_delivery_note.address1 || '--'}
            </div>
          );
        },
      },
      {
        key: 'initial_total',
        align: 'right',
        width: 160,
        dataIndex: 'initial_total',
        title: t('extended_service:amount'),
        render: (_key, record) => {
          const { initial_total = 0, currency = 'HKD' } = record;
          return `${handlePrice(initial_total)} ${currency}`;
        },
      },
      {
        key: 'created_at',
        align: 'right',
        width: 160,
        dataIndex: 'created_at',
        title: t('extended_service:created_on'),
        render: (key, record) => {
          const { created_at = '' } = record || {};
          if (!created_at) return <span>{`--`}</span>;

          return dateInTabale(created_at);
        },
      },
      {
        key: 'est_delivered_on',
        align: 'right',
        width: 170,
        dataIndex: 'est_delivered_on',
        title: t('extended_service:est_delivered_on'),
        render: (key, record) => {
          const { oms_delivery_note = {} } = record || {};
          const { max_delivery_time, min_delivery_time } = oms_delivery_note;
          if (!min_delivery_time || !min_delivery_time)
            return <span>{`--`}</span>;

          const scheduleAt = `${t('extended_service:estimated')} ${dayjs(
            min_delivery_time
          ).tz('Asia/Hong_Kong').format('MMM D, YYYY')} ${dayjs(min_delivery_time).tz('Asia/Hong_Kong').format(
            'HH:mm'
          )} ~ ${dayjs(max_delivery_time).tz('Asia/Hong_Kong').format('HH:mm')}`;

          return scheduleAt;
        },
      },
      {
        title: t('common:actions'),
        key: 'action',
        fixed: 'right',
        align: 'center',
        width: 88,
        render: (key, record) => {
          const { number, state, correspondence_state } = record;
          return (
            <Dropdown
              destroyPopupOnHide={true}
              dropdownRender={() => (
                <Menu className="bg-white">
                  <MenuItem key="a">
                    <Link
                      to={`/businesses/${businessID}/terminal/orders/${number}`}
                    >
                      <div className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5">
                        <span className="text-color-text-1 text-sm font-medium">
                          {t('common:view')}
                        </span>
                      </div>
                    </Link>
                  </MenuItem>
                  {state === 'invoiced' &&
                    correspondence_state === 'unpaid' && (
                      <MenuItem key="pay">
                        <Link
                          to={`/businesses/${businessID}/terminal/orders/payment?number=${number}`}
                        >
                          <div className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5">
                            <span className="text-color-text-1 text-sm font-medium">
                              {t('extended_service:pay_now')}
                            </span>
                          </div>
                        </Link>
                      </MenuItem>
                    )}
                  {correspondence_state === 'unpaid' && state !== 'voided' && (
                    <MenuItem key="b">
                      <div
                        onClick={() => handleVoid(record)}
                        className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                      >
                        <span className="text-color-text-1 text-sm font-medium">
                          {t('extended_service:void_invoice')}
                        </span>
                      </div>
                    </MenuItem>
                  )}
                  <MenuItem key="c">
                    <div
                      onClick={() => handleDownload(record)}
                      className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                    >
                      <span className="text-color-text-1 text-sm font-medium">
                        {t('extended_service:download_invoice_pdf')}
                      </span>
                    </div>
                  </MenuItem>
                </Menu>
              )}
            >
              <div className="justify-center flex">
                <div className="flex items-center cursor-pointer p-1.5 rounded-full w-max hover:bg-primary-color/10 group">
                  <EllipsisIcon className="text-icon-color group-hover:text-primary-color" />
                </div>
              </div>
            </Dropdown>
          );
        },
      },
    ];
    setColumns(columns);
  };

  // const handleFilter = (
  //   search?: string | undefined,
  //   options?: IFilterOptions[]
  // ) => {
  //   loadData(1, 30, search, options);
  // };

  const handleNewOrder = async () => {
    await resetListing(customerID, businessID, token)
    navigate(`/${lang}/businesses/${businessID}/terminal/line-items`);
  };

  return (
    <div className="p-4 flex flex-col h-full md:p-6 lg:px-8 lg:py-6">
      <div className="flex flex-col md:flex-row md:items-center justify-between">
        <div className="text-3xl leading-10 font-bold text-color-text-1">
          {loading ? (
            <div className="w-20 md:w-60 lg:w-80">
              <Skeleton
                row={1}
                width={['100%']}
                skeletonItemClass="h-10 rounded-2xl"
              />
            </div>
          ) : (
            t('common:order_terminal')
          )}
        </div>
        <div className="flex flex-col-reverse md:flex-row md:items-center">
          {loading && (
            <>
              {/* <Skeleton
                row={1}
                width={['100%']}
                skeletonItemClass="h-10 !w-32 rounded-2xl"
              /> */}
              <Skeleton
                row={1}
                width={['100%']}
                className="mb-2 md:mb-0 md:ml-4"
                skeletonItemClass="h-10 !w-32 rounded-2xl"
              />
            </>
          )}
          {!loading && (
            <>
              {/* <Filter
                fields={filterFields}
                onFilter={handleFilter}
                searchValue={searchValue}
                options={filterOptions}
              /> */}
              <Button
                className="mb-2 md:mb-0 md:ml-4"
                onClick={handleNewOrder}
                iconInstance={<NewIcon />}
              >
                {t('extended_service:new_order')}
              </Button>
            </>
          )}
        </div>
      </div>
      <div
        className={`mt-6 flex flex-col overflow-hidden ${!loading && records.length === 0 ? 'flex-1' : ''
          }`}
      >
        {!loading && records.length === 0 && (
          <div className="bg-primary-background-color flex-1 flex flex-col items-center justify-center">
            <NoDataIcon />
            <div className="text-lg text-color-text-1 mt-6">
              {t('extended_service:no_order')}
            </div>
            <div className="text-sm text-center max-w-[286px] mt-1 text-color-text-2">
              {t('extended_service:no_order_msg')}
            </div>
          </div>
        )}
        {(loading || records.length > 0) && (
          <Table
            columns={columns}
            records={records}
            loadingData={loading}
            onRowCell={(record, dataKey) => {
              return {
                onClick: () => {
                  if (dataKey === 'action') return;
                  handleGotoDetaislPage(record.number);
                },
              };
            }}
            pagination={{
              className: 'mt-6',
              current: page,
              currentSize: records.length,
              pageSize,
              pageSizeOptions: [30, 60, 120],
              changePageCallback: loadData,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default HistoryWebPage;
