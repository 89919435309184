import {
  UseCreditCardPay,
  UseApplePay,
  UseAlipay,
  UseCreditCardPay3DS,
  UseWechatMWebPay,
  UseFPSPay,
  UseWechatOfficialAccountPay,
  UseUnionPayWallet,
} from './pay-action';
import PayTypes from './pay-types';
import type { IPayOrderParam } from './interface';
import { handle3DSMethod } from './utils';

export const usePayment = async (params: IPayOrderParam) => {
  const { payData } = params;
  const { type } = payData;

  /**
   * 调起支付
   * 存在混合支付的情况, 区别构建对待
   */

  const newParams = {
    ...params,
    payment: undefined,
  };

  if (type === PayTypes.CREDIT_CARD) {
    const use3DS = handle3DSMethod(payData);
    // 使用3ds支付
    if (use3DS) {
      const resp = await UseCreditCardPay3DS({
        ...newParams,
        payData,
      });

      return resp;
    }

    const resp = await UseCreditCardPay({
      ...newParams,
      payData: payData,
    });

    return resp;
  }

  if (type === PayTypes.APPLE_PAY) {
    const resp = await UseApplePay({
      ...newParams,
      payData,
    });
    return resp;
  }

  if (type === PayTypes.ALIPAYCN || type === PayTypes.ALIPAYHK) {
    const resp = await UseAlipay({
      ...newParams,
      payData,
    });
    return resp;
  }

  if (type === PayTypes.FPS) {
    const resp = await UseFPSPay({
      ...newParams,
      payData,
    });
    return resp;
  }

  if (type === PayTypes.WECHAT_PAY) {
    const resp = await UseWechatMWebPay({
      ...newParams,
      payData,
    });

    return resp;
  } else if (type === PayTypes.WECHAT_OFFICIAL_ACCOUNT_PAY) {
    const resp = await UseWechatOfficialAccountPay({
      ...newParams,
      payData,
    });

    return resp;
  }

  if (type === PayTypes.UNIONPAY_WALLET) {
    const resp = await UseUnionPayWallet({
      ...newParams,
      payData,
    });

    return resp;
  }
};

export default usePayment;
