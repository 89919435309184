import type { ChangeEvent, TextareaHTMLAttributes } from 'react';
import { useState } from 'react';
import type { FieldError } from 'react-hook-form';
import CleanIcon from '@/components/svg/clean-icon';

export interface TextareaProps
  extends Omit<TextareaHTMLAttributes<HTMLElement>, 'size' | 'onChange'> {
  disabled?: boolean;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  warpClass?: string;
  allowClear?: boolean;
  defaultValue?: string;
  placeholder?: string;
  maxLength?: number;
  minLength?: number;
  error?: FieldError;
  value?: string;
  placeholderStyle?: string;
}

const Textarea: React.FC<TextareaProps> = (props) => {
  const {
    rows = 3,
    disabled = false,
    allowClear = true,
    warpClass = '',
    placeholder = '',
    maxLength,
    minLength,
    onChange,
    onFocus,
    onBlur,
    error,
    value = '',
    placeholderStyle,
    ...restProps
  } = props;
  const [textareaValue, setTextareaValue] = useState<string>(value);

  const changeValue = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(evt.target.value);
    if (onChange) {
      onChange(evt.target.value);
    }
  };

  return (
    <>
      <div
        className={`group flex hover:border-primary-color border px-3 border-color-text-5 rounded bg-transparent ${warpClass} ${
          disabled ? 'border-none pl-0' : 'shadow-box-drop py-2 '
        }`}
      >
        {!disabled && (
          <textarea
            {...restProps}
            style={{
              WebkitAppearance: 'none',
              WebkitTapHighlightColor: 'rgba(0,0,0,0)',
            }}
            className={`${
              placeholderStyle
                ? placeholderStyle
                : 'placeholder:text-color-text-5 font-medium'
            } bg-transparent block w-full  text-sm outline-none rounded focus:outline-none border-none resize-none text-color-text-1 hidden_scrollbar`}
            rows={rows}
            disabled={disabled}
            autoComplete="none"
            onChange={changeValue}
            value={disabled && !textareaValue ? '--' : textareaValue}
            placeholder={placeholder}
            maxLength={maxLength}
            minLength={minLength}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )}
        {disabled && (
          <span className="text-sm text-color-text-1 font-medium">
            {!textareaValue ? '--' : textareaValue}
          </span>
        )}

        <span
          className={`self-center invisible ${
            allowClear && textareaValue && !disabled
              ? 'group-hover:visible'
              : ''
          }`}
        >
          <CleanIcon
            size={20}
            className=" text-color-text-5 hover:text-color-text-3 self-center"
            onClick={() => {
              setTextareaValue('');
              onChange && onChange('');
            }}
          />
        </span>
      </div>
      {error && (
        <p className="mt-1 text-sm text-error-color">{error.message}</p>
      )}
    </>
  );
};

export default Textarea;
