import React from 'react';
import type { IIconProps } from '@/types/common-types';

const WarningIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.75 8C1.75 4.54781 4.54781 1.75 8 1.75C11.4522 1.75 14.25 4.54781 14.25 8C14.25 11.4522 11.4522 14.25 8 14.25C4.54781 14.25 1.75 11.4522 1.75 8Z"
          stroke="#FFA217"
          strokeWidth="1.5"
        />
        <path
          d="M9.61875 10.6469H8.45391V5.86035C8.45391 5.58828 8.23379 5.36816 7.96172 5.36816H7.46953H6.64922C6.37715 5.36816 6.15703 5.58828 6.15703 5.86035C6.15703 6.13242 6.37715 6.35254 6.64922 6.35254H7.46953V10.6469H6.30469C6.03262 10.6469 5.8125 10.867 5.8125 11.1391C5.8125 11.4111 6.03262 11.6312 6.30469 11.6312H7.46953H8.45391H9.61875C9.89082 11.6312 10.1109 11.4111 10.1109 11.1391C10.1109 10.867 9.89082 10.6469 9.61875 10.6469Z"
          fill="#FFA217"
        />
        <path
          d="M7.46875 4.01465C7.46875 4.28648 7.68911 4.50684 7.96094 4.50684C8.23277 4.50684 8.45312 4.28648 8.45312 4.01465C8.45312 3.74282 8.23277 3.52246 7.96094 3.52246C7.68911 3.52246 7.46875 3.74282 7.46875 4.01465H7.46875Z"
          fill="#FFA217"
        />
      </svg>
    </div>
  );
};

export default WarningIcon;
