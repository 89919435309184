import React from 'react';
import type { IIconProps } from '@/types/common-types';

const NoFileIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="46"
        viewBox="0 0 56 46"
        fill="none"
      >
        <circle
          cx="27.0891"
          cy="18.0735"
          r="17.3313"
          fill="#0094FF"
          fillOpacity="0.24"
        />
        <circle
          cx="47.5208"
          cy="37.7767"
          r="7.47983"
          fill="#0094FF"
          fillOpacity="0.24"
        />
        <path
          d="M3.54492 35.4491V42.0365C3.54492 42.5058 3.92206 42.883 4.3914 42.883H30.7997C31.269 42.883 31.6462 42.5058 31.6462 42.0365V15.0499L22.4355 5.84766H4.3914C3.92206 5.84766 3.54492 6.2248 3.54492 6.69413V25.7943"
          stroke="#0094FF"
          strokeWidth="1.18704"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.282 15.0499H31.6378L22.4355 5.84766V14.2034C22.4355 14.6728 22.8127 15.0499 23.282 15.0499Z"
          stroke="#0094FF"
          strokeWidth="1.18704"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.4525 35.4497H1.83809C1.37714 35.4497 1 35.0726 1 34.6117V26.633C1 26.1721 1.37714 25.7949 1.83809 25.7949H23.4525C23.9134 25.7949 24.2906 26.1721 24.2906 26.633V34.6117C24.2906 35.0726 23.9134 35.4497 23.4525 35.4497Z"
          stroke="#0094FF"
          strokeWidth="1.18704"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.5596 27.964C9.65794 27.964 9.73765 28.0437 9.73765 28.142V28.6419C9.73765 28.7403 9.65794 28.82 9.5596 28.82H7.53321V30.176H9.04447C9.14281 30.176 9.22253 30.2557 9.22253 30.3541V30.8388C9.22253 30.9372 9.14281 31.0169 9.04447 31.0169H7.53321V33.0736C7.53321 33.1719 7.45349 33.2516 7.35516 33.2516H6.65071C6.55237 33.2516 6.47266 33.1719 6.47266 33.0736V28.142C6.47266 28.0437 6.55237 27.964 6.65071 27.964H9.5596Z"
          fill="#0094FF"
        />
        <path
          d="M11.0275 28.5549C10.8407 28.5549 10.6841 28.4968 10.5579 28.3806C10.4367 28.2594 10.3761 28.1104 10.3761 27.9337C10.3761 27.7569 10.4367 27.6105 10.5579 27.4943C10.6841 27.3731 10.8407 27.3125 11.0275 27.3125C11.2144 27.3125 11.3684 27.3731 11.4896 27.4943C11.6159 27.6105 11.679 27.7569 11.679 27.9337C11.679 28.1104 11.6159 28.2594 11.4896 28.3806C11.3684 28.4968 11.2144 28.5549 11.0275 28.5549ZM11.3722 29.0548C11.4705 29.0548 11.5502 29.1346 11.5502 29.2329V33.0736C11.5502 33.1719 11.4705 33.2516 11.3722 33.2516H10.6677C10.5694 33.2516 10.4897 33.1719 10.4897 33.0736V29.2329C10.4897 29.1346 10.5694 29.0548 10.6677 29.0548H11.3722Z"
          fill="#0094FF"
        />
        <path
          d="M13.4806 27.6458C13.5789 27.6458 13.6586 27.7255 13.6586 27.8239V33.0736C13.6586 33.1719 13.5789 33.2516 13.4806 33.2516H12.7761C12.6778 33.2516 12.5981 33.1719 12.5981 33.0736V27.8239C12.5981 27.7255 12.6778 27.6458 12.7761 27.6458H13.4806Z"
          fill="#0094FF"
        />
        <path
          d="M18.6078 31.0623C18.6078 31.1544 18.6041 31.2409 18.5966 31.3218C18.5886 31.4085 18.5137 31.4714 18.4267 31.4714H15.5095C15.5347 31.7744 15.6408 32.0118 15.8276 32.1835C16.0145 32.3552 16.2443 32.441 16.517 32.441C16.8713 32.441 17.1337 32.3042 17.3042 32.0304C17.34 31.9728 17.4008 31.9335 17.4686 31.9335H18.2624C18.3817 31.9335 18.4673 32.0488 18.4197 32.1582C18.2873 32.4628 18.0824 32.7212 17.8048 32.9334C17.4614 33.191 17.0397 33.3198 16.5397 33.3198C16.1357 33.3198 15.7721 33.2314 15.4489 33.0547C15.1307 32.8728 14.8807 32.6178 14.6989 32.2895C14.5221 31.9613 14.4338 31.5825 14.4338 31.1532C14.4338 30.7189 14.5221 30.3376 14.6989 30.0093C14.8757 29.6811 15.1231 29.4286 15.4413 29.2518C15.7594 29.075 16.1256 28.9867 16.5397 28.9867C16.9387 28.9867 17.2947 29.0725 17.6078 29.2442C17.926 29.4159 18.171 29.6609 18.3427 29.979C18.5194 30.2922 18.6078 30.6533 18.6078 31.0623ZM17.5094 30.7593C17.5043 30.4866 17.4058 30.2694 17.2139 30.1078C17.022 29.9412 16.7872 29.8578 16.5094 29.8578C16.2468 29.8578 16.0246 29.9386 15.8428 30.1002C15.666 30.2568 15.5574 30.4765 15.517 30.7593H17.5094Z"
          fill="#0094FF"
        />
      </svg>
    </div>
  );
};

export default NoFileIcon;
