import { useEffect } from 'react';

const DownloadWonderApp = () => {
  
  useEffect(() => {
    let destination = 'https://play.google.com/store/apps/details?id=app.wonder.hq';
    const userAgentInfo = window.navigator.userAgent;
    if (userAgentInfo.indexOf('MSIE') >= 0) {
    } else if (userAgentInfo.indexOf('Firefox') >= 0) {
    } else if (userAgentInfo.indexOf('Chrome') >= 0) {
    } else if (userAgentInfo.indexOf('Opera') >= 0) {
    } else if (userAgentInfo.indexOf('Safari') >= 0) {
      destination = 'https://apps.apple.com/cn/app/wonder-business-wallet/id1626393041';
    }
    window.location.href = destination;
  }, []);
  return <div></div>;
};

export default DownloadWonderApp;
