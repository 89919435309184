import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ImageIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 16 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 40 40"
        fill="none"
      >
        <circle
          opacity="0.2"
          cx="20"
          cy="20"
          r="18.8889"
          transform="rotate(-90 20 20)"
          fill="#0094FF"
          stroke="#0094FF"
          strokeWidth="2.22222"
        />
        <path
          d="M19.998 11.1094V28.8872"
          stroke="currentColor"
          strokeWidth="3.33333"
          strokeLinecap="round"
        />
        <path
          d="M11.1113 19.9961L28.8891 19.9961"
          stroke="currentColor"
          strokeWidth="3.33333"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default ImageIcon;
