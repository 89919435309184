import React from 'react';
import type { IIconProps } from '@/types/common-types';

const EditIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <g clipPath="url(#clip0_19679_154413)">
          <path
            d="M0.714286 19.2515H0C0 19.441 0.0752549 19.6227 0.209209 19.7566C0.343164 19.8906 0.524845 19.9658 0.714286 19.9658V19.2515ZM0.714286 14.2515L0.209286 13.7465C0.0753219 13.8805 4.04553e-05 14.0621 0 14.2515H0.714286ZM19.2857 19.9658C19.4752 19.9658 19.6568 19.8906 19.7908 19.7566C19.9247 19.6227 20 19.441 20 19.2515C20 19.0621 19.9247 18.8804 19.7908 18.7465C19.6568 18.6125 19.4752 18.5373 19.2857 18.5373V19.9658ZM5.71429 18.5373H0.714286V19.9658H5.71429V18.5373ZM1.42857 19.2515V14.2515H0V19.2515H1.42857ZM18.7193 1.24655C17.9223 0.449641 16.8414 0.00195313 15.7143 0.00195312C14.5872 0.00195313 13.5063 0.449641 12.7093 1.24655L13.7193 2.25655C14.2484 1.72746 14.966 1.43022 15.7143 1.43022C16.4626 1.43022 17.1802 1.72746 17.7093 2.25655L18.7193 1.24655ZM18.7193 7.25655C19.5162 6.45953 19.9639 5.37862 19.9639 4.25155C19.9639 3.12448 19.5162 2.04356 18.7193 1.24655L17.7093 2.25655C18.2384 2.78566 18.5356 3.50328 18.5356 4.25155C18.5356 4.99981 18.2384 5.71743 17.7093 6.24655L18.7193 7.25655ZM19.2857 18.5373H5.71429V19.9658H19.2857V18.5373ZM1.21929 14.7565L11.5764 4.3994L10.5664 3.3894L0.209286 13.7465L1.21929 14.7565ZM11.5764 4.3994L13.7193 2.25655L12.7093 1.24655L10.5664 3.3894L11.5764 4.3994ZM6.21929 19.7565L16.5764 9.3994L15.5664 8.3894L5.20929 18.7465L6.21929 19.7565ZM16.5764 9.3994L18.7193 7.25655L17.7093 6.24655L15.5664 8.3894L16.5764 9.3994ZM10.5664 4.3994L15.5664 9.3994L16.5764 8.3894L11.5764 3.3894L10.5664 4.3994Z"
            fill="#0094FF"
          />
        </g>
        <defs>
          <clipPath id="clip0_19679_154413">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default EditIcon;
