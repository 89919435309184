import React from 'react';
import type { IIconProps } from '@/types/common-types';

const FileIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 16 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M54.3506 23.4458C54.0992 23.0855 53.7608 22.7928 53.3659 22.5941C52.9711 22.3953 52.5322 22.2967 52.089 22.3073H16.6832C15.9362 22.3386 15.2125 22.5737 14.5929 22.9866C13.9733 23.3994 13.4821 23.9738 13.1738 24.6457C13.1687 24.7174 13.1687 24.7894 13.1738 24.8611L8.61945 40.7683V13.0768H18.7421L22.8129 18.5997C22.9581 18.7957 23.1483 18.9551 23.3679 19.0647C23.5875 19.1743 23.8302 19.2311 24.0763 19.2305H49.1723C49.1723 18.4145 48.8436 17.6319 48.2586 17.0549C47.6736 16.4778 46.8802 16.1537 46.0528 16.1537H24.8718L21.2688 11.2615C20.9784 10.8695 20.5981 10.5509 20.1589 10.3316C19.7198 10.1124 19.2343 9.99875 18.7421 10H8.61945C7.79212 10 6.99868 10.3242 6.41367 10.9012C5.82866 11.4782 5.5 12.2608 5.5 13.0768V47.9835C5.52435 48.5371 5.76878 49.059 6.18039 49.4362C6.59199 49.8134 7.13764 50.0155 7.69921 49.9988H46.5831C46.9312 50.0124 47.2739 49.9108 47.5565 49.7101C47.8392 49.5093 48.0456 49.221 48.1429 48.8911L54.6781 25.6611C54.8 25.2919 54.8339 24.8999 54.7771 24.5156C54.7203 24.1314 54.5743 23.7652 54.3506 23.4458Z"
          fill="currentColor"
        />
        <path
          d="M30.5 31.1641V42.8307"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.668 37H36.3346"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default FileIcon;
