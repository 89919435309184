import type { ButtonHTMLAttributes } from 'react';

interface IButtonProps extends Omit<ButtonHTMLAttributes<HTMLElement>, ''> {
  children: React.ReactNode | string;
  className?: string;
  disabledStyle?: boolean;
  animation?: boolean;
  loading?: boolean;
  // iconType?: IconType;
  iconPosition?: 'Left' | 'Right';
  iconSize?: number;
  styleType?:
  | 'Primary'
  | 'Secondary'
  | 'Border'
  | 'Text'
  | 'Danger'
  | 'Secondary Danger';
  iconInstance?: React.ReactElement;
}

const Button: React.FC<IButtonProps> = (props) => {
  const {
    children,
    styleType = 'primary',
    disabled = false,
    className = '',
    disabledStyle= false,
    animation = true,
    loading = false,
    onClick,
    iconPosition = 'Left',
    // iconType,
    iconSize,
    iconInstance,
    ...restProps
  } = props;

  const getClass = () => {
    const baseCss = `
      after:absolute after:opacity-0 after:duration-500 after:inset-0 after:transition-all after:rounded overflow-hidden
      active:after:shadow-none active:after:transition-none active:after:opacity-40 whitespace-nowrap text-ellipsis 
    `;

    let classes = `bg-primary-color border-none text-color-text-6 shadow-[0_2px_4px_0_rgba(0, 148, 255, 0.08)] hover:bg-primary-color/80 ${baseCss} after:shadow-button active:after:bg-primary-color`;
    switch (styleType) {
      case 'Secondary':
        classes = `bg-primary-color/10 text-primary-color border border-primary-color/0 hover:border hover:border-primary-color shadow-[0_2px_4px_0_rgba(0, 148, 255, 0.08)] ${baseCss} after:shadow-button active:after:bg-primary-color`;
        break;
      case 'Border':
        classes = `bg-primary-background-color text-color-text-1 border border-border-color hover:text-primary-color hover:border-primary-color ${baseCss} after:shadow-button active:after:bg-primary-color/10`;
        break;
      case 'Text':
        classes =
          'text-primary-color border-none online-none hover:text-primary-color/80 px-0 bg-inherit';
        break;
      case 'Danger':
        classes = `text-color-text-6 bg-error-color hover:opacity-80 shadow-[0_2px_4px_0_rgba(244, 63, 94, 0.08)] ${baseCss} after:shadow-button-warn active:after:bg-error-color`;
        break;
      case 'Secondary Danger':
        classes = `text-error-color bg-error-color/10 border border-error-color/0 hover:border hover:border-error-color shadow-[0_2px_4px_0_rgba(244, 63, 94, 0.08)] ${baseCss} after:shadow-button-warn active:after:bg-error-color`;
        break;
      default:
        break;
    }

    if (disabled) {
      classes = `bg-disabled-color border border-border-color text-color-text-4 hover:cursor-not-allowed ${baseCss} `;
      if(disabledStyle){
        classes = `bg-primary-color border-none text-color-text-6 opacity-50 text-color-text-4 hover:cursor-not-allowed ${baseCss} `;
      }
    }

    return classes;
  };

  const getIconClass = (baseClass: string) => {
    let classes = baseClass;

    switch (styleType) {
      case 'Primary':
        classes = `${baseClass} text-color-text-5`;
        break;
      case 'Secondary':
        classes = `${baseClass} text-primary-color`;
        break;
      case 'Border':
        classes = `${baseClass} text-color-text-1 ${disabled ? '' : 'group-hover:text-primary-color'
          }`;
        break;
      case 'Text':
        classes = `${baseClass} text-primary-color`;
        break;
      case 'Danger':
        classes = `${baseClass} text-color-text-6`;
        break;
      case 'Secondary Danger':
        classes = `${baseClass} text-error-color`;
        break;
      default:
        break;
    }

    return classes;
  };

  const hanldeClick = (e: any) => {
    if (!loading && onClick) {
      onClick(e);
    }
  };

  return (
    <button
      {...restProps}
      disabled={disabled}
      onClick={hanldeClick}
      className={`
        rounded h-10 border-0 cursor-pointer flex items-center justify-center font-medium px-4 py-2 ${disabled ? '' : ''
        } relative group
        ${animation && !disabled ? 'duration-200 ease-in' : ''
        } ${getClass()} text-sm ${className} ${loading ? 'opacity-80' : ''}
      `}
    >
      {loading && (
        <svg
          className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
          viewBox="0 0 16 16"
        >
          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
        </svg>
      )}
      {!loading && iconInstance && iconPosition === 'Left' && (
        <div className={`${getIconClass('mr-1.5')}`}>{iconInstance}</div>
      )}
      <span className="font-medium">{children}</span>
      {!loading && iconInstance && iconPosition === 'Right' && (
        <div className={`${getIconClass('ml-1.5')}`}>{iconInstance}</div>
      )}
    </button>
  );
};

export default Button;
