import { useEffect, useState } from 'react';
import Button from '@/components/common/button';
import { getCookie } from '@/utils/index';
import config from '@/libs/config';
import { Constants } from '@/constants';
import api from '@/libs/api';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import type { IPayee } from '@/types/payouts-type';
import type { IPayoutsNewStep } from '@/types/global-accounts-type';
import Skeleton from '@/components/skeleton';
import Noaccount from '@/pages/global-accounts/global-accounts-icons/noaccount';
import CopyIcon from '@/components/svg/copy-icon';
import StepTwoIcon from '@/pages/global-accounts/global-accounts-icons/step-two';
import ErrorTipIcon from '@/pages/global-accounts/global-accounts-icons/error-tip-icon';
import { Alert } from '@/components/common/alert/alert';
import { activeVirtualAccounts } from '@/data/global-account';
import AntdModal from '@/components/common/antd/modal';
import UnsuccessfulIcon from '@/components/svg/failed-icon';
import { getCountryByCurrency } from '@/utils/payouts-utils';

const prefix = config.staticPath;
interface IOtherMethod {
  businessID: string;
  golbalMethods: any;
  setSteps: (steps: IPayoutsNewStep[]) => void;
  setStepCount: (stepCount: number) => void;
}

const OtherMethod = (props: IOtherMethod) => {
  const { businessID, golbalMethods, setStepCount, setSteps } = props;
  const [loadingData, setLoadingData] = useState(true);
  const [show, setShow] = useState(false);
  const [checkItem, setCheckItem] = useState<any>({});
  const { t } = useTranslation(["global_accounts",
    "payouts",
    "account_balance",
    "related_company_accounts",
    "payees"]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryCurrencyCode = searchParams.get('currency_code');
  const [showDetail, setShowDetail] = useState(false);
  const [account, setaccount] = useState<any>([]);
  const [errorCode, setErrorCode] = useState('');
  const [copyed, setCopyed] = useState(false);
  const [currencyCode, setCurrencyCode] = useState<any>('HKD');
  const handleChangePayee = (payee: IPayee) => {
    const steps: IPayoutsNewStep[] = [
      {
        key: '1',
        title: t('global_accounts:choose_method'),
        name: '1',
      },
      {
        key: '1A',
        title: t('common:add_fund'),
        name: '2',
      },
    ];
    setSteps(steps);
    setCheckItem(payee);
    setShowDetail(true);
  };
  useEffect(() => {
    setCurrencyCode(queryCurrencyCode);
    handleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleNext = async () => {
    const resp: any = await activeVirtualAccounts(businessID);
    if (resp && resp.code === 200 && resp.data.status !== 'Failed') {
      setShowDetail(true);
      Alert.success({
        message: t('global_accounts:virtual_account_created_successfully'),
        position: 'default',
        wrapClass: 'top-px',
      });
    } else {
      if (resp.data) {
        setErrorCode(resp.data.vendor_error_code);
      } else {
        setErrorCode(resp.code);
      }
      setShow(true);
    }
  };

  const handleActive = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    const resp = await api.get(
      `${config.urls.gatewayUrl}/api/treasury-services/api/virtual_accounts`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': businessID,
        },
      }
    );
    setaccount(resp.data);
    setLoadingData(false);
  };

  const copy = (item: string) => {
    let value = ``;
    switch (item) {
      case 'all':
        let currency = '';
        checkItem.available_currency.forEach((item: any) => {
          if (currency) {
            currency = currency + ',' + item.currency_code;
          } else {
            currency = item.currency_code;
          }
        });
        value =
      ` Account Details from Bindo Labs Account
        Currency: ${currency}
        Bank Name: ${checkItem.bank_name}
        Bank Code: ${checkItem.bank_code}
        Branch Code: Not Required
        Account Number: ${checkItem.display_account_number}
        SWIFT Code: ${checkItem.swift_code}
        [Powered by Wonder]`;
        setCopyed(true);
        break;
      case 'Account Name':
        value = `${checkItem.account_name}`;
        break;
      case 'Bank Name':
        value = `${checkItem.bank_name}`;
        break;
      case 'Bank Code':
        value = `${checkItem.bank_code}`;
        break;
      case 'Branch Code':
        value = `${checkItem.branch_code}`;
        break;
      case 'Account Number':
        value = `${checkItem.display_account_number}`;
        break;
      case 'SWIFT Code':
        value = `${checkItem.swift_code}`;
        break;
      default:
        break;
    }
    navigator.clipboard.writeText(value).then(() => {
      Alert.success({
        message: t('common:copied'),
        position: 'default',
        wrapClass: 'top-px',
      });
    });
  };

  const handBack = () => {
    const steps: IPayoutsNewStep[] = [
      {
        key: '1',
        title: t('global_accounts:choose_method'),
        name: '1',
      },
      {
        key: '2',
        title: t('common:add_fund'),
        name: '2',
      },
    ];
    setSteps(steps);
    setStepCount(0);
  };

  const handDetailBack = () => {
    const steps: IPayoutsNewStep[] = [
      {
        key: '1',
        title: t('global_accounts:choose_method'),
        name: '1',
      },
      {
        key: '1A',
        title: t('payouts:choose_account'),
        name: '1A',
      },
      {
        key: '2',
        title: t('common:add_fund'),
        name: '2',
      },
    ];
    setSteps(steps);
    setStepCount(1);
    setShowDetail(false)
  }

  return (
    <div>
      {!showDetail && (
        <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
          <div className="font-semibold text-xl text-color-text-1">
            {account.length > 0
              ? t('payouts:choose_account')
              : t('payouts:instruction')}
          </div>
          <div className='bg-[#E2E8F0] h-[1px] w-[100%] mt-4 mb-6'></div>
          {account.length > 0 && !loadingData && (
            <>
              {account.map((item: any) => {
                return (
                  <div
                    key={item.id}
                    onClick={() => handleChangePayee(item)}
                    className="text-color-text-1 font-medium text-sm"
                  >
                    <div
                      className={`flex  py-3 cursor-pointer   rounded h-[68px]  flex-col`}
                    >
                      <div className="flex justify-between  font-medium py-1  border-border-color  ml-[1%] items-center justify-items-center">
                        <div>
                          <div className="">{item.display_account_number}</div>
                          <div className="text-color-text-2">
                            {item.account_name}
                          </div>
                        </div>
                        <div className="flex justify-between w-[60px]">
                          <img
                            width="24px"
                            height="24px"
                            src={`${prefix}/images/country-flag/${getCountryByCurrency(
                              currencyCode
                            )}.svg`}
                            alt=""
                          />
                          <div className="">{currencyCode}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
          {!account.length && !loadingData && (
            <div className="flex flex-col items-center justify-center ">
              <div>
                <Noaccount />
              </div>
              <div className="text-[18px] font-semibold mt-4">
                {t('global_accounts:no_virtual_account_tips')}
              </div>
              <div className="text-[#71829A] mt-4">
                {t('global_accounts:create_virtual_account')}
              </div>
            </div>
          )}
          {loadingData && (
            <div>
              <div className="flex items-center py-4">
                <Skeleton
                  avatar={true}
                  row={3}
                  className="items-center"
                  width={['100%']}
                  avatarClass="h-14 w-14 mr-3.5"
                  skeletonItemClass="h-4 first:h-6 last:h-6 mb-1.5 last:mb-0 rounded-[50px] w-[200px]"
                />
              </div>
              <div className="border-b border-border-color"></div>
              <div className="flex items-center py-4">
                <Skeleton
                  avatar={true}
                  row={3}
                  className="items-center"
                  width={['100%']}
                  avatarClass="h-14 w-14 mr-3.5"
                  skeletonItemClass="h-4 first:h-6 last:h-6 mb-1.5 last:mb-0 rounded-[50px] w-[200px]"
                />
              </div>
              <div className="border-b border-border-color"></div>
              <div className="flex items-center py-4">
                <Skeleton
                  avatar={true}
                  row={3}
                  className="items-center"
                  width={['100%']}
                  avatarClass="h-14 w-14 mr-3.5"
                  skeletonItemClass="h-4 first:h-6 last:h-6 mb-1.5 last:mb-0 rounded-[50px] w-[200px]"
                />
              </div>
              <div className="border-b border-border-color"></div>
              <div className="flex items-center py-4">
                <Skeleton
                  avatar={true}
                  row={3}
                  className="items-center"
                  width={['100%']}
                  avatarClass="h-14 w-14 mr-3.5"
                  skeletonItemClass="h-4 first:h-6 last:h-6 mb-1.5 last:mb-0 rounded-[50px] w-[200px]"
                />
              </div>
            </div>
          )}
        </div>
      )}

      {showDetail && (
        <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
          <div className="flex justify-between">
            <div className="font-semibold text-xl text-color-text-1">
              {t('payouts:add_fund_via')}
              &nbsp;{golbalMethods}
            </div>
            <div
              className="text-[#0094FF] cursor-pointer flex items-center justify-items-center"
              onClick={() => copy('all')}
            >
              <CopyIcon size={24} className="mr-1" />
              {!copyed ? t('payouts:copy_info') : t('payouts:copied')}
            </div>
          </div>
          <div className='bg-[#E2E8F0] h-[1px] w-[100%] mt-4 mb-6'></div>
          <div className="relative pl-12">
            <ul className="flex justify-between w-full md:w-auto px-6 md:block md:px-0 absolute top-0 left-0">
              <div className={`flex md:block flex-1'`}>
                <li
                  className={`flex items-center text-color-text-4 hover:text-primary-color`}
                >
                  <span
                    className={`rounded-full w-6 h-6 flex-none flex items-center justify-center bg-primary-color text-color-text-6`}
                  >
                    1
                  </span>
                </li>
                <div
                  className={`mt-3 flex-1 h-0.5 md:w-0.5 md:h-[466px] md:ml-3 md:mt-0 bg-primary-color`}
                  aria-hidden="true"
                ></div>
              </div>
              <div className={`flex md:block flex-1'`}>
                <li
                  className={`flex items-center text-color-text-4 hover:text-primary-color`}
                >
                  <span
                    className={`rounded-full w-6 h-6 flex-none flex items-center justify-center bg-primary-color text-color-text-6`}
                  >
                    2
                  </span>
                </li>
                <div
                  className={`mt-3 flex-1 h-0.5 md:w-0.5 md:h-[120px] md:ml-3 md:mt-0 bg-primary-color`}
                  aria-hidden="true"
                ></div>
                <li
                  className={`flex items-center text-color-text-4 hover:text-primary-color justify-center`}
                >
                  <span
                    className={`rounded-full w-[11px] h-[11px] flex-none flex bg-primary-color `}
                  ></span>
                </li>
              </div>
            </ul>
            <div className="font-semibold text-xl text-color-text-1 ">
              {t('payouts:copy_detail')}
            </div>
            <div
              className={`flex  py-3 cursor-pointer   rounded h-[68px]  flex-col mt-1 relative`}
            >
              <div className="flex justify-between  font-medium py-1  border-border-color  items-center justify-items-center ">
                <div>
                  <div className="text-[#4C5259]">
                    {t('payouts:account_name')}
                  </div>
                  <div className="mt-2 text-[#1F2F3C]">
                    {checkItem.account_name}
                  </div>
                </div>
                <div className="text-[#0094FF]">
                  <CopyIcon size={24} onClick={() => copy('Account Name')} />
                </div>
              </div>
              <div className='bg-[#E2E8F0] h-[1px] w-[100%] absolute bottom-[-12px] left-0 opacity-50'></div>
            </div>
            <div
              className={`flex  py-3 cursor-pointer   rounded h-[68px]  flex-col mt-1 relative`}
            >
              <div className="flex justify-between  font-medium py-1  border-border-color items-center justify-items-center">
                <div>
                  <div className="text-[#71829A]">{t('payouts:bank_name')}</div>
                  <div className="mt-2 text-[#1F2F3C]">
                    {checkItem.bank_name}
                  </div>
                </div>
                <div className="text-[#0094FF]">
                  <CopyIcon size={24} onClick={() => copy('Bank Name')} />
                </div>
              </div>
              <div className='bg-[#E2E8F0] h-[1px] w-[100%] absolute bottom-[-12px] left-0 opacity-50'></div>
            </div>
            <div
              className={`flex  py-3 cursor-pointer   rounded h-[68px]  flex-col mt-1 relative`}
            >
              <div className="flex justify-between  font-medium py-1  border-border-color items-center justify-items-center">
                <div>
                  <div className="text-[#71829A]">{t('payouts:bank_code')}</div>
                  <div className="mt-2 text-[#1F2F3C]">
                    {checkItem.bank_code}
                  </div>
                </div>
                <div className="text-[#0094FF]">
                  <CopyIcon size={24} onClick={() => copy('Bank Code')} />
                </div>
              </div>
              <div className='bg-[#E2E8F0] h-[1px] w-[100%] absolute bottom-[-12px] left-0 opacity-50'></div>
            </div>
            <div
              className={`flex  py-3 cursor-pointer   rounded h-[68px]  flex-col mt-1 relative`}
            >
              <div className="flex justify-between  font-medium py-1  border-border-color items-center justify-items-center">
                <div>
                  <div className="text-[#71829A]">
                    {t('global_accounts:branch_code')}
                  </div>
                  <div className="mt-2 text-[#F43F5E] flex items-center">
                    <ErrorTipIcon />
                    {t('global_accounts:branch_code_tips')}
                  </div>
                </div>
                {/* <div className="text-[#0094FF]">
                  <CopyIcon size={24} onClick={() => copy('Branch Code')} />
                </div> */}
              </div>
              <div className='bg-[#E2E8F0] h-[1px] w-[100%] absolute bottom-[-12px] left-0 opacity-50'></div>
            </div>
            <div
              className={`flex  py-3 cursor-pointer   rounded h-[68px]  flex-col mt-1 relative`}
            >
              <div className="flex justify-between  font-medium py-1  border-border-color items-center justify-items-center">
                <div>
                  <div className="text-[#71829A]">
                    {t('global_accounts:account_number')}
                  </div>
                  <div className="mt-2 text-[#1F2F3C]">
                    {checkItem.display_account_number}
                  </div>
                </div>
                <div className="text-[#0094FF]">
                  <CopyIcon size={24} onClick={() => copy('Account Number')} />
                </div>
              </div>
              <div className='bg-[#E2E8F0] h-[1px] w-[100%] absolute bottom-[-12px] left-0 opacity-50'></div>
            </div>
            <div
              className={`flex  py-3 cursor-pointer   rounded h-[68px]  flex-col mt-1 relative`}
            >
              <div className="flex justify-between  font-medium py-1  border-border-color items-center justify-items-center">
                <div>
                  <div className="text-[#71829A]">
                    {t('global_accounts:swift_code')}
                  </div>
                  <div className="mt-2 text-[#1F2F3C]">
                    {checkItem.swift_code}
                  </div>
                </div>
                <div className="text-[#0094FF]">
                  <CopyIcon size={24} onClick={() => copy('SWIFT Code')} />
                </div>
              </div>
              <div className='bg-[#E2E8F0] h-[1px] w-[100%] absolute bottom-[-12px] left-0 opacity-50'></div>

            </div>
          </div>
          <div className="font-semibold text-xl text-color-text-1 w-[92%] ml-[8%] mt-6">
            {t('global_accounts:go_other_banks')}
          </div>
          <div className="w-[92%] ml-[8%] mt-6 mb-4">
            {' '}
            <StepTwoIcon />
          </div>
        </div>
      )}

      {showDetail && (
        <div className="flex items-center justify-between">
          <Button
            className="flex-1"
            // onClick={() => setShowDetail(false)}
            onClick={handDetailBack}
            styleType="Secondary"
          >
            {t('common:back')}
          </Button>
          {/* <Button className="flex-1" onClick={handleNext} styleType="Primary">
            {t('common:next')}
          </Button> */}
        </div>
      )}
      {!showDetail && !account.length && (
        <div className="flex items-center justify-between">
          <Button
            className="flex-1 mr-4"
            onClick={handBack}
            styleType="Secondary"
          >
            {t('common:back')}
          </Button>
          <Button className="flex-1" onClick={handleNext} styleType="Primary">
            {t('global_accounts:add_account')}
          </Button>
        </div>
      )}
      {!showDetail && account.length > 0 && (
        <div className="flex items-center justify-between">
          <Button className="flex-1 " onClick={handBack} styleType="Secondary">
            {t('common:back')}
          </Button>
        </div>
      )}
      <AntdModal
        open={show}
        closeIcon={null}
        closable={false}
        onCancel={() => setShow(false)}
        className="!w-[456px]"
      >
        <div className="text-center text-color-text-1 p-8 text-sm font-medium">
          <div className="justify-center flex text-error-color">
            <UnsuccessfulIcon />
          </div>
          <div className="mt-4 font-semibold text-lg leading-6 text-error-color">
            {t('global_accounts:unable_to_create')}
          </div>
          <div className="mt-2 text-error-color">
            {t('global_accounts:error_code')}: {errorCode}
          </div>
          <div className="mt-2 text-[#71829A]">
            {t('global_accounts:error_tips')}
          </div>
          <Button
            className="w-full mt-6"
            styleType="Border"
            onClick={() => setShow(false)}
          >
            {t('common:back')}
          </Button>
        </div>
      </AntdModal>
    </div>
  );
};

export default OtherMethod;
