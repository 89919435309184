import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ResultFailIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="45"
        height="44"
        viewBox="0 0 45 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M43.5 22C43.5 33.598 34.098 43 22.5 43C10.902 43 1.5 33.598 1.5 22C1.5 17.9668 2.63702 14.1991 4.608 11C8.30475 4.99985 14.9353 1 22.5 1C34.098 1 43.5 10.402 43.5 22Z"
          fill="#FC2E01"
        />
        <path
          d="M22.4999 43.7992C22.0999 43.7992 21.6999 43.4992 21.6999 42.9992C21.6999 42.4992 21.9999 42.1992 22.4999 42.1992C33.6999 42.1992 42.6999 33.0992 42.6999 21.9992C42.6999 10.8992 33.6999 1.79922 22.4999 1.79922C15.3999 1.79922 8.99992 5.39922 5.19992 11.3992C4.99992 11.7992 4.49992 11.8992 4.19992 11.5992C3.79992 11.3992 3.8059 10.9132 3.99992 10.5992C7.99992 4.09922 14.8999 0.199219 22.4999 0.199219C34.4999 0.199219 44.2999 9.99922 44.2999 21.9992C44.2999 33.9992 34.4999 43.7992 22.4999 43.7992Z"
          fill="black"
        />
        <path
          d="M29.5999 29.7992C29.3999 29.7992 29.1999 29.6992 29.0999 29.5992L14.8999 15.4992C14.5999 15.1992 14.5999 14.6992 14.8999 14.3992C15.1999 14.0992 15.6999 14.0992 15.9999 14.3992L30.0999 28.4992C30.3999 28.7992 30.3999 29.2992 30.0999 29.5992C29.9999 29.6992 29.7999 29.7992 29.5999 29.7992Z"
          fill="black"
        />
        <path
          d="M15.3999 29.7992C15.1999 29.7992 14.9999 29.6992 14.8999 29.5992C14.5999 29.2992 14.5999 28.7992 14.8999 28.4992L28.9999 14.3992C29.2999 14.0992 29.7999 14.0992 30.0999 14.3992C30.3999 14.6992 30.3999 15.1992 30.0999 15.4992L15.9999 29.5992C15.7999 29.6992 15.5999 29.7992 15.3999 29.7992Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default ResultFailIcon;
