import React from 'react';
import type { IIconProps } from '@/types/common-types';

const PaymentErrorIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="61"
        height="60"
        viewBox="0 0 61 60"
        fill="none"
      >
        <path
          d="M59.136 29.9996C59.136 45.8151 46.3151 58.636 30.4996 58.636C14.6842 58.636 1.86328 45.8151 1.86328 29.9996C1.86328 24.4998 3.41376 19.362 6.10146 14.9996C11.1425 6.81762 20.1841 1.36328 30.4996 1.36328C46.3151 1.36328 59.136 14.1842 59.136 29.9996Z"
          fill="#FC2E01"
        />
        <path
          d="M30.4996 59.726C29.9542 59.726 29.4087 59.3169 29.4087 58.6351C29.4087 57.9533 29.8178 57.5442 30.4996 57.5442C45.7723 57.5442 58.0451 45.1351 58.0451 29.9988C58.0451 14.8624 45.7723 2.4533 30.4996 2.4533C20.8178 2.4533 12.0905 7.36239 6.90871 15.5442C6.63598 16.0897 5.95417 16.226 5.54508 15.8169C4.99962 15.5442 5.00777 14.8814 5.27235 14.4533C10.7269 5.58967 20.136 0.271484 30.4996 0.271484C46.8633 0.271484 60.2269 13.6351 60.2269 29.9988C60.2269 46.3624 46.8633 59.726 30.4996 59.726Z"
          fill="black"
        />
        <path
          d="M40.1814 40.6351C39.9087 40.6351 39.636 40.4988 39.4996 40.3624L20.136 21.1351C19.7269 20.726 19.7269 20.0442 20.136 19.6351C20.5451 19.226 21.2269 19.226 21.636 19.6351L40.8633 38.8624C41.2723 39.2715 41.2723 39.9533 40.8633 40.3624C40.7269 40.4988 40.4542 40.6351 40.1814 40.6351Z"
          fill="black"
        />
        <path
          d="M20.8178 40.6351C20.5451 40.6351 20.2723 40.4988 20.136 40.3624C19.7269 39.9533 19.7269 39.2715 20.136 38.8624L39.3633 19.6351C39.7723 19.226 40.4542 19.226 40.8633 19.6351C41.2723 20.0442 41.2723 20.726 40.8633 21.1351L21.636 40.3624C21.3633 40.4988 21.0905 40.6351 20.8178 40.6351Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default PaymentErrorIcon;
