let env: string = process.env.REACT_APP_SERVER_ENV || 'development';
const isDev = env === 'development';

if (isDev) {
  env = 'staging';
  // env = 'alpha';
  // env = 'production';
}

let baseUrl = 'http://127.0.0.1:8000/api';

const platformId = 124;
let staticPath = 'https://mb5-stg-cdn.bindo.co';
let clientID = '3b95c5102ac5973893fdb3b64bce4206';
let clientSecret = 'ttvp5vzgty6qevi55qmfu7fqsgtfhax';
let xAppSlug = '3Kswi8';
let xAppKey = '02eb3362-1ccb-4063-8f5e-825fde761efb';
let appAuthorization =
  'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfa2V5IjoiMDJlYjMzNjItMWNjYi00MDYzLThmNWUtODI1ZmRlNzYxZWZiIiwiYXBwX2lkIjoiODBhOTg0ZTItNGVjNC00ZDA2LWFiYTktZTQzMDEwOTU2ZTEzIiwiaWF0IjoxNjgxMzkyMzkyLCJleHAiOjE5OTY3NTIzOTJ9.2UF7FOI-d344wJsZt5zVg7dC2r1DzqdmSV_bhSpdt-I';
let publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqM33Bu8iwtTAvskWTzf7
Vsmt2xE+8qT7wpYv/uAGXa+xz2DC2Oah9Bv85rIbp7Rc7zocpxp4XrLsEFPytsE2
nn4oTJjISXXGY6QjrfpmW2G09y5WL45awuucXsywVHIhBqgTSUrsIDVSgwQyb4Vs
+X62uv1hRS4EslejaJI0vm+aOzmHOKPycQz//KPrFKQ/wvx+hiu9cOXtJtYOnG1E
eOt1CbOgcIZJ3rEgKMQRJuv8HgpjNpyqZSZ7uuLMsOlwHtKQ9PpoOIPf50bKzt86
Vcjslkwbz9wCvBNy60oAhg6tdAHtm0WMwq6NVG+9UvF/NCNK5o/H2OOwD+AN/S1Q
lQIDAQAB
-----END PUBLIC KEY-----`;

let baseDomain = 'https://hq-stg.wonder.app';
let payDomain = 'https://pay-stg.wonder.app';
let mainBindo = 'https://main-stg.bindo.co';
let gatewayUrl = 'https://gateway-stg.wonder.app';
let appURI = 'wonder-stg://';

if (env === 'production') {
  staticPath = 'https://mb5-cdn.bindo.co';
  clientID = '0fcefe4bfc5c399c313309c40324fde8';
  clientSecret = 'ttvp5vzgty6qevi55qmfu7fqsgtfhax';
  xAppSlug = '3rTiiv';
  xAppKey = '9a54ed52-7a2c-4d08-aabc-4e1c548fff02';
  appAuthorization =
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfa2V5IjoiOWE1NGVkNTItN2EyYy00ZDA4LWFhYmMtNGUxYzU0OGZmZjAyIiwiYXBwX2lkIjoiMDJjYTAxZmYtNzZkOC00NTQyLWE1Y2YtMmU1YzY1ZTQ0MmI4IiwiaWF0IjoxNjgyMDEwMTg5LCJleHAiOjE5OTczNzAxODl9.tr9s1n6YmqvubeXmsZvRTBN-B4UcaVOrT4gjjFpO6QM';
  publicKey = `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAx4f8aZDHV/YCh9UVfNh2
  wfd/utBb45hfHecj/5Rt7GKLhLbKLof52UfJn6AObjIl9N3mWrlUMf5U+U4cXFuu
  kKc6s06lsl5dcT0FvYcMd2xBUlRzkyx4yOMVtSQrN8GEzoa3eIEkGOjN0yEQqJEf
  BqPJnkTPfe+OedFF6ESHx8fRIrdn43dA17a+uQBivpgSlLobcXcsZu2enppO/sP0
  7UkmVEBeBcfoLMKBuqKtxFbsS9nxi9V5Lbzb5ZXvFQm1YgTeZXMJVSDqt3FBqhx1
  wBK4OG44Cl/bBlhFaq3GCJ9UEOHtd+GJB03OViRhFkHY24stJW1/XElUC6teX3Sy
  JQIDAQAB
  -----END PUBLIC KEY-----`;

  baseDomain = 'https://hq.wonder.app';
  payDomain = 'https://pay.wonder.app';
  mainBindo = 'https://main.bindo.co';
  gatewayUrl = 'https://gateway.wonder.app';
  appURI = 'wonder://';
} else if (env === 'alpha') {
  staticPath = 'https://mb5-alpha-cdn.bindo.co';
  clientID = '9e206e9906b9a824e2b3ea490767eae7';
  clientSecret = 'ttvp5vzgty6qevi55qmfu7fqsgtfhax';
  xAppSlug = 'JgG9C';
  xAppKey = '6bad4911-baa7-4588-997c-09d23d1072df';
  appAuthorization =
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfa2V5IjoiNmJhZDQ5MTEtYmFhNy00NTg4LTk5N2MtMDlkMjNkMTA3MmRmIiwiYXBwX2lkIjoiNDdlNDg4NjQtNTg4Zi00OTk4LTg5MjUtNmE3MWY4ZDUyMTJjIiwiaWF0IjoxNjgxNDY3MTg2LCJleHAiOjE5OTY4MjcxODZ9.QVBAtihGkf0vhfaFgENg3RGqyp0stm-PmRLQATJzrI4';
  publicKey = `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5fXouHTAxAS9uWmq4e5A
  8+2FcGUvsnubmWvneMnyhpU4vjbG9KeHsaEButWxJYOt/wZZTCvV9iUzdtM4E7ct
  CIAnMF94U9LZRHwZ4bRbT3j9pd3u3CDcFi0pYiGB/1M6yboTRBOMmXhAoykVDa6n
  2aQMPnm6dZOgzw9ASm+1PhbtVpL6zP8wl/OwwaUOkVvAEorwPxG1h78JsmkroU9g
  U1xFsnoE0+0BV7SlEd++wRg4f0Hzsn8ShKR0r7f+xC7jco1T/+tSc13+zQggQ6Ik
  jTcChqO0VKf/VckAiFo8I/nbXi7aK6zNgk+XbSVMsbANtC7hZhSaOv5grdcoVZjC
  EwIDAQAB
  -----END PUBLIC KEY-----`;

  baseDomain = 'https://hq-alpha.wonder.app';
  payDomain = 'https://pay-alpha.wonder.app';
  mainBindo = 'https://main-alpha.bindo.co';
  gatewayUrl = 'https://gateway-alpha.wonder.app';
  appURI = 'wonder-alpha://';
}

const Config = {
  isDev,
  env: env,
  isStaging: env === 'staging',
  isAlpha: env === 'alpha',
  isProd: env === 'production',
  baseUrl,
  publicKey,
  clientID,
  clientSecret,
  platformId,
  staticPath,
  appAuthorization,
  xAppSlug,
  xAppKey,
  urls: {
    baseDomain,
    payDomain,
    mainBindo,
    gatewayUrl,
    appURI,
  }
}

export default Config;
