import api from "@/libs/api";
import Config from "@/libs/config";
import { IAreaCodes } from "@/types/common-types";

export const loadAreaCodesData = async () => {
  const areaCodes: IAreaCodes[] = [];
  try {
    const res: any = await api.get(
      // `${config.urls.gatewayUrl}/api/treasury-services/application/global_information/available_countries?limit=500`
      `${Config.urls.gatewayUrl}/api/registry/countries`
    );

    if (res && res.code === 200 && Array.isArray(res.data)) {
      const dataList: any[] = res.data || [];

      dataList.forEach((item) => {
        if (item.alpha_2 && item.calling_code) {
          areaCodes.push({
            isoAlpha2Code: item.alpha_2 || '',
            isoAlpha3Code: item.alpha_3 || '',
            phoneCountryCode: item.calling_code || '',
            name: item.country_name || '',
            __i18n: item.__i18n || {},
            translations: item.translations || [],
          });
        }
      });
    }
  } catch (error) {
    console.error(error);
  }

  // 排序
  areaCodes
    .sort((a, b) => Number(a.phoneCountryCode) - Number(b.phoneCountryCode))
    .sort(
      (a, b) =>
        Number(a.phoneCountryCode.charAt(0)) -
        Number(b.phoneCountryCode.charAt(0))
    );

  // US优先, 处理多值区号
  areaCodes.forEach((item, index) => {
    if (item.isoAlpha2Code === 'US') {
      areaCodes.unshift(areaCodes.splice(index, 1)[0]);
    } else if (item.phoneCountryCode.includes(',')) {
      item.phoneCountryCode = item.phoneCountryCode.slice(
        0,
        item.phoneCountryCode.indexOf(',')
      );
    }
  });

  return areaCodes;
};