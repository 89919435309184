import Button from "@/components/common/button";
import Config from "@/libs/config";
import { useTranslation } from "react-i18next";

const ScanInvalid: React.FC = () => {
  const { t } = useTranslation('signin');
  const prefix = Config.staticPath;

  const handleClick = () => {
    window.location.href = `${Config.urls.appURI}?time=${new Date().getTime()}`;
  };

  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 flex text-color-text-1">
      <div className="flex-1 flex flex-col items-center px-4 md:px-8 py-24 text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="153"
          height="31"
          viewBox="0 0 153 31"
          fill="none"
        >
          <path
            d="M10.3288 30.5157L0.705566 0.844143H6.36131L13.4943 23.6782H11.595L18.6014 0.844143H22.8643L29.8707 23.6782H27.9291L35.1043 0.844143H40.7601L31.0947 30.5157H26.8317L19.7832 7.7239H21.6403L14.6339 30.5157H10.3288Z"
            fill="black"
          />
          <path
            d="M50.8806 30.9378C48.8265 30.9378 46.9694 30.4735 45.3092 29.545C43.6772 28.5883 42.3688 27.308 41.384 25.7041C40.4273 24.0721 39.9489 22.2572 39.9489 20.2594C39.9489 18.2616 40.4273 16.4748 41.384 14.8991C42.3406 13.2952 43.6491 12.029 45.3092 11.1005C46.9694 10.1438 48.8124 9.66542 50.8383 9.66542C52.9206 9.66542 54.7777 10.1438 56.4097 11.1005C58.0698 12.029 59.3782 13.2952 60.3349 14.8991C61.2916 16.4748 61.77 18.2616 61.77 20.2594C61.77 22.2572 61.2916 24.0721 60.3349 25.7041C59.3782 27.308 58.0698 28.5883 56.4097 29.545C54.7777 30.4735 52.9346 30.9378 50.8806 30.9378ZM50.8383 25.7885C51.8794 25.7885 52.7939 25.5634 53.5818 25.1132C54.3978 24.6349 55.0168 23.9877 55.4389 23.1717C55.8891 22.3275 56.1142 21.3709 56.1142 20.3016C56.1142 19.2324 55.8891 18.2897 55.4389 17.4737C54.9887 16.6577 54.3697 16.0246 53.5818 15.5744C52.7939 15.0961 51.8794 14.8569 50.8383 14.8569C49.8254 14.8569 48.9249 15.0961 48.1371 15.5744C47.3492 16.0246 46.7302 16.6577 46.28 17.4737C45.8298 18.2897 45.6047 19.2324 45.6047 20.3016C45.6047 21.3709 45.8298 22.3275 46.28 23.1717C46.7302 23.9877 47.3492 24.6349 48.1371 25.1132C48.9249 25.5634 49.8254 25.7885 50.8383 25.7885Z"
            fill="black"
          />
          <path
            d="M79.0468 30.5157V18.8244C79.0468 17.6144 78.667 16.6296 77.9072 15.8699C77.1475 15.1101 76.1627 14.7303 74.9528 14.7303C74.1649 14.7303 73.4614 14.8991 72.8424 15.2368C72.2234 15.5744 71.7309 16.0528 71.3652 16.6718C71.0275 17.2908 70.8587 18.0084 70.8587 18.8244L68.7061 17.727C68.7061 16.1231 69.0438 14.7303 69.7191 13.5485C70.4225 12.3385 71.3792 11.3959 72.5892 10.7206C73.8272 10.0453 75.2201 9.70763 76.7677 9.70763C78.259 9.70763 79.5955 10.0875 80.7773 10.8472C81.9591 11.5788 82.8877 12.5496 83.563 13.7595C84.2383 14.9413 84.576 16.2216 84.576 17.6004V30.5157H79.0468ZM65.3295 30.5157V10.1297H70.8587V30.5157H65.3295Z"
            fill="black"
          />
          <path
            d="M97.6401 30.9378C95.7548 30.9378 94.0665 30.4735 92.5752 29.545C91.0839 28.6164 89.9021 27.3502 89.0298 25.7463C88.1857 24.1425 87.7636 22.3416 87.7636 20.3438C87.7636 18.3179 88.1857 16.503 89.0298 14.8991C89.9021 13.2952 91.0698 12.029 92.533 11.1005C94.0243 10.1719 95.7267 9.70763 97.6401 9.70763C99.1032 9.70763 100.412 10.0031 101.565 10.594C102.747 11.1849 103.69 12.0149 104.393 13.0842C105.125 14.1253 105.519 15.3071 105.575 16.6296V23.8892C105.519 25.2117 105.139 26.4076 104.435 27.4768C103.732 28.5461 102.789 29.3902 101.608 30.0092C100.426 30.6283 99.1032 30.9378 97.6401 30.9378ZM98.5686 25.8307C99.6097 25.8307 100.51 25.6056 101.27 25.1554C102.058 24.6771 102.663 24.0299 103.085 23.2139C103.535 22.3698 103.76 21.399 103.76 20.3016C103.76 19.2324 103.535 18.2897 103.085 17.4737C102.663 16.6296 102.058 15.9824 101.27 15.5322C100.51 15.0539 99.6238 14.8147 98.6108 14.8147C97.5697 14.8147 96.6552 15.0539 95.8673 15.5322C95.1076 16.0106 94.5026 16.6577 94.0524 17.4737C93.6304 18.2897 93.4193 19.2324 93.4193 20.3016C93.4193 21.399 93.6304 22.3698 94.0524 23.2139C94.5026 24.0299 95.1217 24.6771 95.9096 25.1554C96.6974 25.6056 97.5838 25.8307 98.5686 25.8307ZM108.952 30.5157H103.465V25.0288L104.351 20.0484L103.422 15.1523V0H108.952V30.5157Z"
            fill="black"
          />
          <path
            d="M123.593 30.9378C121.454 30.9378 119.555 30.4876 117.895 29.5872C116.235 28.6586 114.912 27.3924 113.927 25.7885C112.971 24.1847 112.492 22.3557 112.492 20.3016C112.492 18.2757 112.957 16.4748 113.885 14.8991C114.842 13.2952 116.136 12.029 117.768 11.1005C119.4 10.1438 121.215 9.66542 123.213 9.66542C125.183 9.66542 126.913 10.1156 128.404 11.016C129.924 11.8883 131.106 13.0983 131.95 14.6459C132.822 16.1653 133.258 17.8958 133.258 19.8373C133.258 20.2031 133.23 20.583 133.174 20.9769C133.146 21.3427 133.075 21.7648 132.963 22.2431L115.827 22.2853V18.149L130.388 18.1068L128.151 19.8373C128.095 18.6274 127.87 17.6144 127.476 16.7984C127.11 15.9824 126.561 15.3634 125.83 14.9413C125.126 14.4911 124.254 14.266 123.213 14.266C122.116 14.266 121.159 14.5192 120.343 15.0257C119.527 15.5041 118.894 16.1935 118.444 17.0939C118.021 17.9661 117.81 19.0073 117.81 20.2172C117.81 21.4553 118.036 22.5386 118.486 23.4671C118.964 24.3676 119.639 25.071 120.512 25.5775C121.384 26.0558 122.397 26.295 123.551 26.295C124.592 26.295 125.534 26.1262 126.378 25.7885C127.223 25.4227 127.954 24.8881 128.573 24.1847L131.823 27.4346C130.838 28.5883 129.628 29.4605 128.193 30.0514C126.786 30.6423 125.253 30.9378 123.593 30.9378Z"
            fill="black"
          />
          <path
            d="M136.678 30.5157V10.1297H142.207V30.5157H136.678ZM142.207 19.2464L140.054 17.8114C140.307 15.3071 141.039 13.3374 142.249 11.9024C143.459 10.4392 145.203 9.70763 147.483 9.70763C148.467 9.70763 149.354 9.87646 150.142 10.2141C150.93 10.5236 151.647 11.0442 152.294 11.7758L148.833 15.7432C148.524 15.4056 148.144 15.1523 147.694 14.9835C147.272 14.8147 146.779 14.7303 146.216 14.7303C145.035 14.7303 144.064 15.1101 143.304 15.8699C142.573 16.6015 142.207 17.727 142.207 19.2464Z"
            fill="black"
          />
        </svg>
        <div className="my-6 font-semibold text-2xl">
          {t('signin:scan_with_wonder')}
        </div>
        <div
          className="w-full h-full bg-center bg-no-repeat bg-contain"
          style={{ backgroundImage: `url(${prefix}/images/scan-invalid.png)`}}
        />
        <div className="my-6 font-medium">{t('signin:go_to_wonder')}</div>
        <Button onClick={handleClick} className="w-full h-14">
          {t('signin:open_wonder_app')}
        </Button>
      </div>
    </div>
  );
};

export default ScanInvalid;
