import React from 'react';
import type { IIconProps } from '@/types/common-types';

const LockKeyIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9023 22.2851H20.9265L20.9265 16.8129C20.9265 13.6408 22.1866 10.5986 24.4296 8.35556C26.6727 6.11254 29.7149 4.85242 32.887 4.85242C36.0591 4.85242 39.1013 6.11253 41.3443 8.35556C43.5873 10.5986 44.8475 13.6408 44.8475 16.8129C44.8475 18.2124 43.7129 19.3469 42.3135 19.3469C40.914 19.3469 39.7795 18.2124 39.7795 16.8129C39.7795 14.9849 39.0533 13.2318 37.7607 11.9392C36.4681 10.6466 34.715 9.92042 32.887 9.92042C31.059 9.92042 29.3058 10.6466 28.0133 11.9392C26.7207 13.2318 25.9945 14.9849 25.9945 16.8129L25.9945 22.2851L53.8321 22.2851C56.3885 22.2851 58.4609 24.3575 58.4609 26.9139L58.4609 56.2407C58.4609 58.7971 56.3885 60.8695 53.8321 60.8695H53.4867L48.4187 55.8015H53.3929V27.3531L23.5417 27.3531C23.5147 27.354 23.4877 27.3544 23.4605 27.3544C23.4333 27.3544 23.4063 27.354 23.3793 27.3531H19.9703L14.9023 22.2851ZM37.3904 44.7732C39.3697 43.3646 40.6607 41.0518 40.6607 38.4375C40.6607 34.1457 37.1815 30.6665 32.8897 30.6665C30.2754 30.6665 27.9626 31.9575 26.554 33.9368L30.295 37.6778C30.3809 37.3838 30.5155 37.1106 30.6893 36.8674V38.0721L37.3904 44.7732Z"
          fill="#71829A"
        />
      </svg>
    </div>
  );
};

export default LockKeyIcon;
