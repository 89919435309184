import type { ISettlementDetailsRolling } from './type';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import BigNumber from 'bignumber.js';
import { Transition } from '@headlessui/react';
import AdjustmentIcon from '@/pages/account-balance/account-balances-icons/adjustment-icon';
import RollingIcon from '@/pages/account-balance/account-balances-icons/rolling-reserver-icon';
import ProcessFeeIcon from '@/pages/account-balance/account-balances-icons/process-fee-icon';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DownIcon from '@/components/svg/down-icon';
import UpIcon from '@/components/svg/up-icon';


dayjs.extend(utc);
dayjs.extend(timezone);
// dayjs.tz.setDefault('Asia/Hong_Kong');

const SettlementDetailsRolling = (props: ISettlementDetailsRolling) => {
  const { dayEndData, businessID } = props;
  const { t } = useTranslation("account_balance");
  const navigate = useNavigate();
  const { lang = 'en' } = useParams();
  const [showAdjustment, setShowAdjustment] = useState<boolean>(false);

  const {
    rolling_reserve_amount = 0, // rolling resever
    ext_fee_amount = 0, // adjustment
    fee_charged_amount = 0, // processing fee
    currency = 'HKD',
    closed_schedule_at,
    other_adjustment_fees_items = [],
  } = dayEndData || {};

  const handleAmount = (value: string) => {
    let returnValue = value;
    const temp = new BigNumber(returnValue);
    returnValue = `${temp.toFormat(2)}`;

    return <span>{`${returnValue}`}</span>;
  };

  const handleUtcDate = (date: string) => {
    if (!date) return <span>{``}</span>;
    const dayDate = dayjs.utc(date).tz();

    return (
      <span className="whitespace-nowrap">{`${dayDate.format('HH:mm')}`}</span>
    );
  };

  const handleClick = () => {
    if (other_adjustment_fees_items && other_adjustment_fees_items.length > 0) {
      setShowAdjustment(!showAdjustment);
    }
  };

  const handleGoAdjustmentFee = (record: any) => {
    if (record && record.id) {
      navigate(
        `/${lang}/businesses/${businessID}/account-balance/payments/adjustment_fee/${record.id}`
      );
    }
  };

  return (
    <div className="flex w-full space-x-6">
      <Transition.Child
        className="flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 mb-4 h-fit md:mb-6"
        enter="duration-700 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-10 opacity-30"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="flex flex-col space-y-6 lg:space-x-6 lg:flex-row lg:space-y-0">
          <div className="flex-1 rounded">
            <div
              className={`flex p-4 bg-system-background-color items-center space-x-2 ${showAdjustment &&
                'border border-b-0 border-border-color box-border'
                }`}
            >
              <div>
                <AdjustmentIcon size={40} />
              </div>
              <div className="flex flex-col w-full">
                <div className="flex justify-between items-center text-sm font-medium text-color-text-1">
                  <div>{t('account_balance:adjustment')}</div>
                  <div className="flex items-center">
                    <div className="flex flex-col items-end">
                      <span>{handleAmount(ext_fee_amount)}</span>
                      <span className="text-color-text-2">
                        {' '}
                        {`${currency ? currency : 'HKD'}`}
                      </span>
                    </div>
                    {other_adjustment_fees_items &&
                      other_adjustment_fees_items.length > 0 && (
                        <div
                          className="ml-1 cursor-pointer"
                          onClick={handleClick}
                        >
                          {!showAdjustment && (
                            <DownIcon size={20} className="text-icon-color" />
                          )}
                          {showAdjustment && (
                            <UpIcon size={20} className="text-icon-color" />
                          )}
                        </div>
                      )}
                  </div>
                </div>
                {/* <div className="text-end font-medium text-sm text-color-text-2 leading-5">
                  <div>{handleUtcDate(closed_at)}</div>
                  {`${currency ? currency : 'HKD'}`}
                </div> */}
              </div>
            </div>
            <Transition show={showAdjustment}>
              <Transition.Child
                className="primary-background-color"
                enter="duration-350 transform transition-max-height ease-ease"
                enterFrom="max-h-0"
                enterTo="max-h-102"
                leave="duration-`200 transform transition-max-height ease-ease"
                leaveFrom="max-h-102"
                leaveTo="max-h-0 mt-0"
              >
                {other_adjustment_fees_items.map((item: any, index: number) => (
                  <div
                    key={index}
                    onClick={() => handleGoAdjustmentFee(item)}
                    className={`p-4 border border-border-color hover:bg-system-background-color cursor-pointer ${index === 0 ? '' : 'border-t-0'
                      }`}
                  >
                    <div className="flex items-center justify-between text-sm">
                      <div className="flex flex-col text-color-text-1">
                        <span className="font-medium">{item.type || ''}</span>
                        <span className="text-color-text-2">
                          {dayjs(item.transacted_on).format('HH:mm')}
                        </span>
                      </div>
                      <div className="flex flex-col text-color-text-1">
                        <span className="font-medium">
                          {handleAmount(item.fee_amount)}
                        </span>
                        <span className="text-color-text-2">
                          {item.fee_currency}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </Transition.Child>
            </Transition>
          </div>

          <div className="flex-1 rounded">
            <div className="flex bg-system-background-color p-4 items-center space-x-2 text-sm font-medium">
              <div>
                <RollingIcon size={40} />
              </div>
              <div className="flex flex-col w-full">
                <div className="flex justify-between text-color-text-1">
                  <div>{t('account_balance:rolling_reserve')}</div>
                  <div>{handleAmount(rolling_reserve_amount)}</div>
                </div>
                <div className="flex justify-between text-color-text-2 leading-5">
                  <div>{handleUtcDate(closed_schedule_at)}</div>
                  <div>{`${currency ? currency : 'HKD'}`}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1 rounded">
            <div className="flex bg-system-background-color p-4 items-center space-x-2">
              <div>
                <ProcessFeeIcon size={40} />
              </div>
              <div className="flex flex-col w-full text-sm font-medium">
                <div className="flex justify-between text-color-text-1">
                  <div>{t('account_balance:processing_fee')}</div>
                  <div>{handleAmount(fee_charged_amount)}</div>
                </div>
                <div className="flex justify-between text-color-text-2 leading-5">
                  <div>{handleUtcDate(closed_schedule_at)}</div>
                  <div>{`${currency ? currency : 'HKD'}`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition.Child>
    </div>
  );
};

export default SettlementDetailsRolling;
