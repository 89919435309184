import styles from '@/pages/content.module.css';
import React from 'react';

const PaymentTerms = () => {
  return (
    <div className={`${styles.header}`}>
      <div className={`${styles.payment}`}>
        <h3 className={`${styles.heading} text-center`}>Payment Terms</h3>
        <div className={`${styles.title}`}>1. These Terms</div>
        <p>
          1.1 These Payment Terms (these ‘Terms’) govern the provision of the Services by Bindo (‘us’, ‘our’ or ‘we’) to the entity or person (‘you’, ‘your’, or ‘Merchant’) identified in the Merchant Details. Bindo and Merchant are each a ‘Party’ and together the ‘Parties’.
        </p>
        <p>
          1.2 If you receive Services from any additional party, we will
          provide you with details of those additional parties. The entities
          providing Services as referred to above as we make known to you
          will be deemed to be a party to these Terms.
        </p>
        <p>
          1.3 You must not access or use the Services unless you agree to
          abide by all of the terms and conditions in these Terms including
          any Additional Terms. You must agree to the Payout and FX Terms
          prior to us being obliged to provide you with the Services under
          these Terms.
        </p>
        <p>
          1.4 The Parties agree that the Merchant Services Agreement (if applicable), the Schedules to these Terms, any Additional Terms and other terms referenced in these Terms are incorporated into and form part of these Terms, in each case, as may be amended, varied, supplemented, modified or novated from time to time.
        </p>
        <p>
          1.5 Please read the following additional documents which also apply to your use of the Wonder Platform and Services and should be read together with this Agreement:
        </p>
        <p>(a) Privacy Policy;</p>
        <p>
          For the avoidance of doubt, unless the contrary intention is expressed elsewhere, the above documents do not form part of this Agreement.
        </p>
        <p>
          1.6 Capitalised terms that are not otherwise defined in these Terms have the meanings given in Schedule 1 (Definitions).
        </p>
        <p>
          1.7 These Terms shall take effect from the Commencement Date unless and until terminated by either Party in accordance with clause 18.
        </p>
        <p>
          1.8 During the term of these Terms, we will provide a copy of these Terms to you upon request.
        </p>
        <div className={`${styles.title}`}>2. Getting Started</div>
        <p>
          2.1 You may access the Wonder Platform through Bindo’s APIs as well as Wonder App.
          However, in order to use our Services through the Bindo APIs, you must first be approved
          by us. If you are approved by us, you will need to comply with our Product Documentation
          and reasonable directions regarding the integration and use of the Bindo APIs.
        </p>
        <p>
          You warrant and represent that you are not “dealing as a consumer” (for the purposes of,
          amongst other things, the Supply of Services (Implied Terms) Ordinance (Cap. 457), the
          Control of Exemption Clauses Ordinance (Cap. 71) or the Unconscionable Contracts
          Ordinance (Cap. 458) and you hereby acknowledge and agree that you may not continue to
          use the Services if you deal as a consumer at any time.
        </p>
        <p>
          2.2 You may appoint an ‘<strong>Authorised User</strong>’ (for
          example, any of your directors, officers, employees or
          professional advisors) to access information about the Services
          under these Terms. You must set up each Authorised User with a
          User Profile and promptly provide us with the following details of
          any of your proposed Authorised Users: full name, address and
          billing address, phone and email address of, and any other contact
          or identification information of the Authorised User that we may
          reasonably require. You must inform us promptly if any details
          previously provided about an Authorised User changes or is incorrect.
          You will ensure that your Authorised Users comply with these Terms
          and in respect of your obligations and liabilities under these Terms
          and for such purposes any references to ‘<strong>you</strong>’ shall (where the context
          requires) be read as including your Authorised Users.
        </p>
        <p>
          2.3 You may set up your Authorised Users to have different levels of authority by following the relevant prompts on the Wonder Platform.
        </p>
        <p>2.4 You agree that:</p>
        <p>(a)  your Authorised Users have the authority to provide instructions to us for the provision of Services on your behalf;
        </p>
        <p>(b) we may rely on instructions given by the Authorised User, and you will be bound by the actions of your Authorised Users, until you provide us with written notice withdrawing or otherwise varying the authority of an Authorised User;
        </p>
        <p>(c) we may refuse access to your Authorised User(s) if we are concerned about unauthorised or fraudulent access; and
        </p>
        <p>(d) you will promptly report to us any infringements or unauthorised access to the Wonder Platform, including through the Wonder Platform or Bindo APIs.
        </p>
        <div className={`${styles.title}`}>3. Due Diligence Information</div>
        <p>3.1 You shall provide Bindo with the following information (the ‘Due Diligence Information’) in the form specified by Bindo:</p>
        <p>(a) if applicable, copies of your constitutional documents;</p>
        <p>(b) description of your basic business operations;</p>
        <p>(c) your enterprise email address;</p>
        <p>(d) information on your legal and beneficial owners (if applicable);</p>
        <p>(e) business names (both your legal and trading names);</p>
        <p>(f) merchant category code;</p>
        <p>(g) URL of your online sites;</p>
        <p>(h) your shipping address;</p>
        <p>(i) financial statements and information relating to your financial standing; and</p>
        <p>(j) any other information we may reasonably request.</p>
        <p>3.2 You shall provide Bindo with any other relevant information (including but not
          limited to Personal Data of your directors and beneficial owners) upon Bindo’s reasonable
          request from time to time (including, but not limited, to when Bindo carries out annual
          or such other periodic reviews of your business and account with Bindo).
        </p>
        <p>3.3 You shall notify Bindo of any changes to the Due Diligence Information in a timely
          manner as soon as reasonably practicable following such change.
        </p>
        <p>3.4 The Parties agree that failure to comply with clauses 3.1, 3.2 and 3.3 will be a
          material breach of these Terms by Merchant.
        </p>
        <div className={`${styles.title}`}>4. Services</div>
        <p>4.1 Under these Terms, Bindo shall provide you with the following services (each a
          ‘Service’ and together the ‘Services’):
        </p>
        <p>(a) the ‘Payment Processing Service’ which is the processing and transmission by Bindo of
          Authorisation Requests, Capture Requests and Transaction Data between you and Buyer
          and/or between you and a Payment Method Provider or other payment service provider that
          connects us to Networks and the subsequent collection and settlement by Bindo of
          resulting Payments to Merchant; and
        </p>
        <p>(b) any ‘Additional Services’ being the Gateway Services, Fraud Control Service,
          Encryption Service, Customs Declaration Service or any other Additional Services that you
          have requested and that we have agreed to provide to you under these Terms.
        </p>
        <p>4.2 If the Merchant uses a Hosted Checkout Page for the Services, Bindo may allow the
          Merchant to customise the appearance of the Hosted Checkout Page if Bindo so permits. The
          Merchant shall be responsible for any costs of such customisation.
        </p>
        <p>4.3 Subject to clauses 4.4 and 4.5, you may use the Services from the Commencement Date.</p>
        <p>4.4 You hereby acknowledge and agree on an on-going basis that you may not use the
          Services unless and until you have provided all the information we request from you in
          accordance with clause 3.1, You agree that, where we make a request for information in
          accordance with clause 3.2 you will cease to use the Services until you have provided the
          requested information to us and we have confirmed in writing that you may use the
          Services again.
        </p>
        <p>4.5 You hereby acknowledge and agree that you must only use the Services in accordance
          with the Acceptable Use Policy issued by us from time to time applicable to Hong Kong
          (which is available on our website at www.bindolabs.com) and that you may not use the
          Services in respect of Prohibited Transactions.
        </p>
        <p>4.6 You hereby acknowledge and agree that you must immediately cease using the Services
          if we notify that, acting reasonably, we determine that you have breached the Acceptable
          Use Policy. We may terminate these Terms or any Services with immediate effect pursuant
          to clause 18.5 if you have violated the Acceptable User Policy.
        </p>
        <p>4.7 You hereby acknowledge and agree that your Transaction may not be processed if it
          exceeds your Transaction Limit. Unless we otherwise agree in writing with you, you
          acknowledge that we may set your Transaction Limit and change it based on our internal
          policies and procedures and/or Network Rules from time to time.
        </p>
        <p>4.8 Where clause 4.7 applies, we may require you to enter into an Acquiring Agreement
          with us and an Acquirer before you may access the Services again.
        </p>
        <p>4.9 You hereby acknowledge and agree that we are not obliged to provide any Service or
          continue to provide any Service if we reasonably believe this would result in a failure
          to comply with any Applicable Law, Sanctions Law or the Network Rules.
        </p>
        <div className={`${styles.title}`}>5. Service Fees</div>
        <p>5.1 Bindo shall provide you with the Services in accordance with Applicable Law and the
          Network Rules in return for the Service Fees.</p>
        <p>5.2 The Parties agree that the Services Fees are immediately payable by you upon receipt
          of the relevant Service.</p>
        <p>5.3 Unless stated otherwise, all Service Fees, charges and other payments to be made by
          you under these Terms are exclusive of any applicable Taxes under any Applicable Law or
          governmental decree, for which you shall also be liable. Any Tax payable in respect of
          the Services provided or payments made under these Terms (other than Tax payable on our
          net income, profits or gains) will be payable by you.</p>
        <p>5.4 Where we do not settle funds to you net of any amounts owed by you to us (as referred
          to in clause 6.4), while all sums are due immediately, they will be payable in accordance
          with the terms of any invoice issued by us to you.</p>
        <p>5.5 We may from time to time vary the Service Fees and/or introduce new charges in
          addition to the Service Fees, in accordance with clause 22. In addition, if the issuer of
          the Cards, Local Payment Methods and the Networks respectively increase their fees or
          charges (including any interchange fees and/or scheme fees), these fees or charges will
          be added to the Services Fees payable by you to us.</p>
        <div className={`${styles.title}`}>6. Settlement</div>
        <p>6.1 After we have validated a Transaction, subject to clauses 6.4 to 6.10, Bindo will
          instruct payment of the Net Settlement Amount to Merchant on the later of the following:</p>
        <p>(a) without undue delay following receipt of cleared funds from the Payment Method
          Provider;</p>
        <p>(b) at the expiry of the interval/period as reasonably determined by Bindo or as
          otherwise agreed between the parties in writing; and</p>
        <p>(c) the expiry of any period of deferment pursuant to clause 6.5 in respect of the
          relevant Transactions.</p>
        <p>6.2 We shall, at our discretion, make the Settlement Payment to the Merchant by:</p>
        <p>(a) initiating a bank transfer of the Net Settlement Amount to the Merchant Bank Account; or</p>
        <p>(b) by crediting the Net Settlement Amount to a Global Account,
          in the Settlement Currency. The Merchant agrees that a nominated Global Account will be
          used as the default method in which a Settlement Payment will be made, unless otherwise
          agreed in writing by the Parties.</p>
        <p>6.3 We may deduct the Permissible Deductions from the Aggregate Payment Amount. The
          Parties agree that the ‘Net Settlement Amount’ shall therefore be an amount equal to the
          Aggregate Payment Amount after any Permissible Deductions.</p>
        <p>6.4 The Permissible Deductions referred to in clause 6.3 are:</p>
        <p>(a) Service Fees;</p>
        <p>(b) Refunds;</p>
        <p>(c) Refund Fees;</p>
        <p>(d) Chargebacks;</p>
        <p>(e) Chargeback Fees;</p>
        <p>(f) Chargeback Costs;</p>
        <p>(g) Assessments;</p>
        <p>(h) Additional Reserve amounts;</p>
        <p>(i) Claims; and</p>
        <p>(j) any other charges or amounts due and payable from you to us or our Affiliates under
          these Terms or otherwise.</p>
        <p>6.5 In addition to our rights under clauses 6.3 and 6.4, we may defer all or part of your
          Settlement Payment(s):</p>
        <p>(a) if, following any deductions pursuant to clause 6.3, such amount is less than the
          minimum Settlement Payment threshold that we reasonably determine in our sole and
          absolute discretion (of which we will notify you from time to time), until the total Net
          Settlement Amount payable reaches that threshold;</p>
        <p>(b) where we reasonably believe that a Transaction (including activity which would
          otherwise have constituted a Transaction) may be fraudulent or involves other criminal
          activity, until the satisfactory completion of our investigation and/or that of any
          Regulator, Network or Local Payment Provider or any other third party; or</p>
        <p>(c) without limit in amount or time, if we become aware or reasonably believe that you
          are in breach of or likely to be in breach of your obligations under these Terms.</p>
        <p>6.6 Bindo shall make available for download each day by Merchant a list of all the
          recorded Transactions during the immediately preceding day for which Payments have been
          received (the ‘Transaction List’). The Transaction List shall set out our determination
          of the Aggregate Payment Amount and the Net Settlement Amount and in the absence of
          manifest error or valid dispute by the Merchant shall be final and binding on the
          Merchant in relation to such amounts.</p>
        <p>6.7 We may suspend the processing of all or any Transactions, Refunds, Chargebacks or
          Claims where we have the ability or are obligated to do so and where we reasonably
          believe that a Transaction, Refund, Chargeback or Claim (including activity which would
          otherwise have constituted a Transaction, Refund, Chargeback or Claim) may be fraudulent
          or involves any criminal activity, until the satisfactory completion of our investigation
          and/or that of any Regulator, Network or Local Payment Provider, or any other third
          party.</p>
        <p>6.8 In the event that we exercise our rights under this clause 6 we shall notify you of
          any such action and the reasons for it, unless we are prohibited from doing so under
          Applicable Law. Subject to reasonable security measures and Applicable Law, we will
          notify you before any suspension of processing under clause 6.7 if we are able to do so,
          or otherwise immediately after such suspension.</p>
        <p>6.9 Settlement Payments shall be paid in the Settlement Currency unless agreed otherwise
          in writing by you and us from time to time. Where we apply a currency conversion, we
          shall use our prevailing exchange rate of the day determined by us or such other rate as
          we may agree in writing.</p>
        <p>6.10 In addition to the foregoing, we may, in our sole discretion, impose a Reserve on
          all or a portion of your Settlement Payment. If we impose a Reserve, we will provide you
          with a notice specifying the terms of the Reserve. The terms of this notice may require:</p>
        <p>(a) that a certain percentage of your Settlement Payment is held for a certain period of
          time;</p>
        <p>(b) that a fixed amount of your Settlement Payment is withheld and shall not be paid to
          you in accordance with clause 6.2; or</p>
        <p>(c) such other restrictions that we determine are necessary to protect against the risk
          to us associated with our business relationship.
          We may impose a Reserve immediately and provide you with the notice after we have imposed
          the Reserve in circumstances where we reasonably determine such as to mitigate our
          financial exposure. We may change the terms of the Reserve at any time by providing you
          with notice of the new terms.</p>
        <p>6.11 Settlement Payments subject to a Reserve are not immediately available for payment
          to your Merchant Bank Account or Global Account (as applicable) for making Refunds. Other
          restrictions described in clause 6.10(c) above may include:</p>
        <p>(a) limiting Settlement Payments immediately available to you;</p>
        <p>(b) changing the speed or method of making Settlement Payments to you;</p>
        <p>(c) setting off any amounts owed by you against your Settlement Payments and/or requiring
          that you, or a person associated with you, enter into other forms of security
          arrangements with us (for example, by providing a guarantee or requiring you to deposit
          funds with us as security for your obligations to us or third parties).</p>
        <p>6.12 We may hold a Reserve as long as we deem necessary, in our sole and absolute
          discretion, to mitigate any risks related to your Transactions. You agree that you will
          remain liable for all obligations related to your Transactions even after the release of
          any Reserve. In addition, we may require you to keep your Merchant Bank Account or Global
          Account or other accounts with Bindo available for any open settlements, Chargebacks and
          other adjustments.</p>
        <p>6.13 To secure your performance of these Terms, you grant to Bindo a security interest
          (in the form of a charge) to any Settlement Payments held in Reserve.</p>
        <p>6.14 Waiver. Guarantor unconditionally and irrevocably waives:</p>
        <p>(a) Presentment to, demand of performance or cure from and protest to Merchant of any of
          the Payment Obligations, and also waives notice of acceptance of its guarantee, demand,
          protest, presentment, and any requirement of diligence, and notice of any liability to
          which it may apply, and waives notice of nonperformance of any such liability, suit or
          the taking of other action by Lender, and any other notice to any party liable thereon,
          regardless of whether such notices are required by statute, rule of law, or otherwise,
          now or hereafter in effect;</p>
        <p>(b) any defense based upon an election of remedies by Lender, unless the same would
          excuse performance by the Merchant, under the Note;</p>
        <p>(c) any duty of Lender to advise Guarantor of any information known to Lender regarding
          the Merchant or its ability to perform under the Note, except for notices specifically
          provided for herein; and</p>
        <p>(d) any other circumstance whatsoever which might otherwise constitute a legal or
          equitable discharge, release or defense of a guarantor or surety, or which might
          otherwise limit recourse against the Guarantor.</p>
        <p>6.15 <label className="underline decoration-1 underline-offset-4">Third Parties</label>. This Guarantee shall not confer any rights or remedies upon any
          person other than the parties hereto and their successors and assigns.</p>
        <div className={`${styles.title}`}>7. Guarantee</div>
        <p>7.1 Guarantor hereby unconditionally and irrevocably guarantees to Bindo, as primary
          obligor and not merely as a surety, all payments of all liabilities of the Merchant to
          the Bindo under the terms of the Merchant Services Agreement and addendum whether matured
          or unmatured for revenue based financing and whether absolute of contingent including
          chargeback (all of which are herein collectively referred to as the “<strong>Payment
          Obligations</strong>”).</p>
        <p>(a) Guarantor unconditionally and irrevocably guarantees to Lender that if the Merchant
          fails to perform any of the Payment Obligations, when due, Guarantor shall, upon written
          demand by Lender, perform, or cause to be performed, all such Payment Obligations.</p>
        <p>(b) All payments made by Guarantor, pursuant to the obligations incurred by Guarantor
          hereunder, shall be made to Bindo and shall be paid within ten (10) Business Days after
          receipt by Guarantor from Lender of written demand for such payment.</p>
        <p>(c) Guarantor hereby agrees to pay all costs, expenses and fees, including all reasonable
          attorneys’ fees, actually incurred by the Lender in enforcing this Guarantee, whether by
          suit or otherwise.</p>
        <p>(d) Guarantor hereby agrees that its guarantee hereunder constitutes a guarantee of
          payment when due and not of collection.</p>
        <p>7.2 <label className="underline decoration-1 underline-offset-4">Representations and Warranties</label>. Guarantor represents and warrants to Bindo and
          affiliates that this Guarantee constitutes the valid and legally binding obligation of
          Guarantor, enforceable against Guarantor in accordance with its terms and conditions.</p>
        <p>7.3 <label className="underline decoration-1 underline-offset-4">Obligations Unconditional</label>. To the extent permitted by law, the Guarantor waives all
          defenses, counterclaims or offsets that are legally available to the Guarantor with
          respect to the Payment Obligations of the Merchant. The Guarantor guarantees that the
          Merchant will promptly pay the full amount of principal and interest of the Note as and
          when the same will, in any manner, be or become due, either according to the terms and
          conditions provided by the Note or upon acceleration of the payment under the Note by
          reason of an Event of Default.</p>
        <p>7.4 <label className="underline decoration-1 underline-offset-4">Independent Obligations</label>. The obligations of Guarantor hereunder are the direct and
          primary obligations of the Guarantor and are independent of the obligations of the
          Merchant and, in the event of any default hereunder, a separate action or actions may be
          brought and prosecuted against Guarantor whether or not the Merchant is joined therein or
          a separate action is brought against Merchant.</p>
        <div className={`${styles.title}`}>8. Your Obligations</div>
        <p>8.1 You shall at all times comply with:</p>
        <p>(a) the provisions of these Terms;</p>
        <p>(b) the Network Rules;</p>
        <p>(c) Applicable Law; and</p>
        <p>(d) Sanctions Law.</p>
        <p>8.2 You acknowledge and agree that you (and your agents, sub-contractors or any third
          parties used by you) shall not store Buyer data or Card data prohibited by the Network
          Rules and abide by any data security standards of the Payment Card Industry Security
          Standards Council (or any replacement body notified to you by us) and the Networks and
          Local Payment Providers including the PCI SSC Standards.</p>
        <p>8.3 You agree to pay us the Service Fees for administering the system through which you
          report your compliance status with PCI SSC Standards or its equivalent to the Networks or
          Local Payment Providers (‘PCI SSC Service’), and a PCI SSC Standards non-compliance fee
          or its equivalent (specified in the Fee Schedule) for each month in which you are not
          compliant with the PCI SSC Standards or its equivalent issued by the Networks or Local
          Payment Provider.</p>
        <p>8.4 You shall:</p>
        <p>(a) only accept Payments from and/or make Refunds to Buyers in connection with goods
          and/or services which you have sold and supplied as principal to those Buyers, and only
          pay such Refunds to the Buyer using the Payment Method used by the Buyer for the original
          Transaction;</p>
        <p>(b) only accept Payments and/or make Refunds in respect of goods and/or services the sale
          and supply of which commonly falls within your business as identified to us. You
          acknowledge that we may amend the MID assigned to you as required;</p>
        <p>(c) notify us in writing before you make any change to the nature of the goods and/or
          services the sale and supply of which fall within your business as identified to us;</p>
        <p>(d) only accept Payments and submit Transaction Data to us in respect of Transactions
          which a Buyer has authorised in accordance with Applicable Law, the Network Rules and any
          other information or instructions provided or made available by us to you from time to
          time, and shall not knowingly submit any Transaction Data to us in respect of
          Transactions that is illegal or that you should have known was illegal;</p>
        <p>(e) ensure that you prominently and unequivocally inform Buyers of your identity at all
          points of interaction with a Buyer (including prominently displaying your Merchant name
          and any trading name on any website through which you conduct Transactions), so that the
          Buyer can readily identify you as the counter-party to the relevant Transaction;</p>
        <p>(f) only submit Transaction Data to us directly from your own staff or systems, or via a
          third party product which has been approved by us in advance in writing;</p>
        <p>(g) provide Buyers with details of your goods and services, terms and conditions and
          complaints procedure and customer service contact point which shall be accessible by e￾mail and/or telephone in the local language;</p>
        <p>(h) have proper data protection policy and obtain consents or permissions from Buyers or
          other users for the sharing and processing of any relevant data with us, Network, Payment
          Method Provider or Regulator in accordance with Applicable Laws; and</p>
        <p>(i) refrain from doing anything which we reasonably believe to be disreputable or capable
          of damaging the reputation or goodwill of us, our Affiliates, Networks or Local Payment
          Provider.</p>
        <div className={`${styles.title}`}>9. Sanctions Law and Prevention of Bribery</div>
        <p>9.1 You undertake that you are not, and will procure that none of your directors,
          officers, agents, employees or persons acting on behalf of the foregoing is a Sanctioned
          Person and do not act directly or indirectly on behalf of a Sanctioned Person.</p>
        <p>9.2 You warrant and represent that neither you nor any of your directors, officers,
          agents, employees or persons acting on behalf of the foregoing has:</p>
        <p>(a) committed a Prohibited Act;</p>
        <p>(b) to the best of your knowledge, been or is the subject of any investigation, inquiry
          or enforcement proceedings by a governmental, administrative or regulatory body regarding
          any Prohibited Act or alleged Prohibited Act; or</p>
        <p>(c) has been or is listed by any government department or agency as being debarred,
          suspended, proposed for suspension or debarment, or otherwise ineligible for
          participation in government procurement programmes or other government contracts on the
          grounds of a Prohibited Act.</p>
        <p>9.3 If you become aware of any suspected breach of clauses 9.1 or 9.2 or have reason to
          believe that such a breach may occur, you shall, to the extent permitted by Sanctions
          Laws and Applicable Law, notify us immediately.</p>
        <div className={`${styles.title}`}>10. Recurring Transactions</div>
        <p>10.1 You hereby acknowledge and agree that you may only accept Recurring Transactions if
          we have agreed with you in writing that you may do so. You acknowledge that Recurring
          Transaction may only be permitted or possible with respect to certain Payment Methods.</p>
        <p>10.2 Subject to clause 10.1, in respect of Recurring Transactions you shall:</p>
        <p>(a) obtain a Recurring Transaction Authority from the Buyer;</p>
        <p>(b) confirm to the Buyer within two (2) Business Days of the date of the Recurring
          Transaction Authority that the Recurring Transaction Authority has been established;</p>
        <p>(c) notify the Buyer via the agreed method of communication at least seven (d) Business
          Days prior to a Recurring Transaction payment being charged to the Buyer’s Payment Method
          whether: (i) the payment amount or payment date has changed; (ii) the payment date has
          changed; (iii) more than six (6) months have elapsed since the last Recurring Transaction
          payment; or (iv) any trial period, special offer or promotion has expired; or as
          otherwise required in accordance with the applicable Network Rules;</p>
        <p>(e) not effect (or attempt to effect) a Transaction under the Recurring Transaction
          Authority once the Recurring Transaction Authority has expired, or once the Buyer has
          notified you that the it wishes to cancel the Recurring Transaction Authority;</p>
        <p>(f) securely retain the Recurring Transaction Authority for at least a period of eighteen
          (18) months after the date of the final Transaction effected under it; and</p>
        <p>(g) provide us with copies of any Recurring Transaction Authority on demand.</p>
        <div className={`${styles.title}`}>11. Refunds</div>
        <p>11.1 You shall maintain and disclose to Buyers at the time of purchase a policy for the
          return of goods or cancellation of services. You shall not give a cash refund to a Buyer
          for a payment made using a Card or Local Payment Method, unless required by Applicable
          Law, nor accept cash or other compensation for making a refund to a Card or Local Payment
          Method.</p>
        <p>11.2 The form and procedure for making Refunds shall depend on the relevant Payment
          Method. A Refund only arises in circumstances where there is an originating Transaction
          which is being reversed or partially reversed. We will not be able to process any Refund
          request if (i) the amount of the Refund exceeds the amount of the originating
          Transaction. or (ii) you do not have sufficient balance in your Aggregate Payment Amount
          or sufficient funds in your account with Bindo. We shall be authorized to deduct the
          Refund from your Aggregate Payment Amount and the funds in your account with Bindo.</p>
        <p>11.3 A Refund request may be processed only if it is made within one year from or such a
          shorter period as determined by the Network or Payment Method Provider from the date of
          the originating Transaction.</p>
        <p>11.4 We may refuse to execute a Refund if it does not meet the conditions in these Terms
          or is prohibited by Applicable Law or Sanctions Laws. If we do refuse to execute a
          Refund, within the time for processing the Refund we will notify you (i) (unless
          prohibited by Applicable Law or Sanction Laws) of the refusal, (ii) (if possible) the
          reasons for such refusal, and (iii) (where it is possible to provide reasons for the
          refusal and those reasons relate to factual matters) the procedure for correcting any
          factual mistakes that led to it. Any request for a Refund that we refuse will be deemed
          not to have been received for the purposes of execution times and liability for non￾execution or defective execution under Applicable Law.</p>
        <p>11.5 Where we execute a Transaction or Refund in accordance with details provided by you,
          the Transaction or Refund will be deemed to have been correctly executed by us and any
          other payment service provider involved. Where the details provided by you are incorrect,
          we are not liable for the non-execution or defective execution of the Transaction or
          Refund, but we will make reasonable efforts to recover the funds involved in such a
          Transaction or Refund and we may charge you for any such efforts.</p>
        <p>11.6 We will apply a Refund Fee as set forth in the Fee Schedule to any Refunds that are
          requested even where the Refund is not actually processed. You acknowledge that Refunds
          may not be processed by us where you have insufficient funds in the relevant currency.</p>
        <p>11.7 You agree that each Refund Fee represents a debt immediately due and payable by you
          to us.</p>
        <p>11.8 We have the right to initiate Refund or modify and reverse transactions without your
          consent when (i) we suspect that a transaction is fraudulent or is in breach or
          contravention of these terms, our internal policies and procedures or Applicable Laws,
          (ii) we receive guidance, advice or instructions from the Network or governmental or
          regulatory authorities, or (iii) we suspend or terminate any of our services.</p>
        <div className={`${styles.title}`}>12. Chargebacks</div>
        <p>12.1 You agree that each Chargeback and each Assessment represents a debt immediately due
          and payable by you to us.</p>
        <p>12.2 You acknowledge and agree that you are required to reimburse us for Chargebacks in
          circumstances where you have accepted payment in respect of the relevant Transaction,
          even if you are under no legal liability in respect of the supply of the goods or
          services concerned. To the extent permitted by Applicable Law, we shall notify you as
          soon as reasonably practicable of any applicable Chargebacks and Chargeback Costs which
          have occurred or been incurred.</p>
        <p>12.3 Any Chargebacks for which you are required to reimburse us shall correspond to the
          whole or part of the Transaction Value in the currency of the original Transaction.
          Unless you have an account with us in the currency in which the Chargeback is charged,
          the Chargeback amount may be converted to the Settlement Currency from the currency of
          Chargeback at the exchange rate quoted to us or as we otherwise determine.</p>
        <p>12.4 In the event that you wish to dispute a Chargeback, it is your responsibility:</p>
        <p>(a) to prove to our reasonable satisfaction (which shall, subject to clause 12.5 and
          without limitation, be conditional upon the relevant Network or Local Payment Provider,
          as the case may be, confirming it is satisfied) that the debit of the Buyer’s account was
          authorised by such Buyer; and</p>
        <p>(b) (additionally) to provide us with such other evidence as we or any Network or Local
          Payment Provider may require you to provide in support of your claim. The evidence
          required to be provided will depend on, among other things, the nature of the Chargeback,
          and may vary accordingly.</p>
        <p>12.5 Subject to the Network Rules, we shall not be obliged to investigate the validity of
          any Chargeback or Assessment. You acknowledge and agree that any decision or
          determination of the relevant Network or Local Payment Provider as to the validity and
          extent of any Chargeback and/or Assessment shall be final and binding.</p>
        <p>12.6 As Chargebacks may arise a considerable period of time after the date of the
          relevant Transaction, you acknowledge and agree that, notwithstanding any termination of
          these Terms for whatever reason, we shall remain, without prejudice to clause 6, entitled
          to recover Chargebacks and Chargeback Costs from you (and, where relevant, from any
          Person who has provided us with a guarantee or security relating to your obligations
          under these Terms) in respect of all Chargebacks that occur in relation to Transactions
          effected during the term of these Terms.</p>
        <p>12.7 If you wish to dispute a Chargeback or Assessment, you will do so in accordance with
          the applicable procedure set out in the Network Rules. In the case of a disputed
          Chargeback or Assessment, you must provide us within any specified timeframe with the
          evidence required by us, the Network or the Local Payment Provider.</p>
        <p>12.8 We will apply a Chargeback Fee as set forth in the Fee Schedule to any Chargeback.</p>
        <p>12.9 We may suspend all or part of the Services if the ratio of Chargebacks to
          Transactions is excessive or we otherwise consider, in our sole and absolute discretion,
          that the total volume or value of Chargebacks is excessive.</p>
        <div className={`${styles.title}`}>13. Information Request & Periodic Reviews</div>
        <p>13.1 If we request any transaction data or proof of a Transaction, you shall provide the
          original receipts and relevant Transaction records to us via e-mail within two (2)
          Business Days after the receipt of our request.</p>
        <p>13.2 You shall assist us and any Payment Method Provider in handling properly all
          complaints from Buyers on the relevant Payment Method, and shall implement suggestions
          put forward by us or the Payment Method Provider.</p>
        <p>13.3 You shall, at your own expense, cooperate with us, Network, Payment Method Provider
          or Regulator regarding any investigation, enquiry or proceedings and provide any
          information or records as reasonably requested by the relevant party.</p>
        <p>13.4 Bindo may carry out a periodic review of your use of the Services once during each
          calendar year, or at any time if Bindo determines that:</p>
        <p>(a) the Transaction Mix figures differ from the Transaction Mix figures you previously
          provided to Bindo; or</p>
        <p>(b) the Transaction Mix figures that Bindo used to calculate any Service Fee.<br />If the actual or projected Transaction Mix figures materially differ from previous Transaction Mix, then Bindo may revise the Service Fees based on the then current or projected Transaction Mix (as reasonably determined by Bindo) by giving you fourteen (14) days’ written notice ("Fee Change Notice Period").   If you do not accept the revised Service Fees within the Fee Change Notice Period, you must terminate this Agreement by giving thirty (30) days’ written notice to Bindo, otherwise those revised Service Fees will become binding on you under these Terms (unless you and Bindo otherwise agree in writing) upon the expiration of the Fee Change Notice Period.</p>
        <p>13.5 You must promptly provide us with any information reasonably requested by Bindo in
          relation to any periodic review carried out under these Terms.</p>
        <div className={`${styles.title}`}>14. Foreign Currency & Currency Conversions</div>
        <p>14.1 Settlement Currency. As and where supported by the relevant Payment Method
          Providers, Bindo will process Transactions and pay Settlement Payments to you in the
          Settlement Currency. You acknowledge that Bindo is not obliged to process any
          Transaction, or settle a Settlement Payment to you, in a currency that is not a Supported
          Currency.</p>
        <p>14.2 Pricing structure for Card Transactions. You acknowledge that we may agree on a
          'blended' pricing structure or an 'Interchange++' pricing structure with you in respect
          of Card Transactions. For the 'blended' pricing structure, you acknowledge that the
          underlying interchange and scheme fees component of the Service Fees for Card
          Transactions is calculated by Bindo based on the Transaction Mix figures that you report
          to Bindo from time to time, as required under these Terms. Under the ‘Interchange++'
          pricing structure, you acknowledge that Bindo calculates the underlying interchange and
          scheme fees component of the Service Fees for Card Transactions based on the actual
          interchange fee that is attracted by the relevant Transaction, according to interchange
          pricing published (and varied) by the Card Networks from time to time.</p>
        <p>14.3 Foreign Currency Settlement Fee. Subject to clause 13.5, if the Transaction currency
          is in any other currency other than Hong Kong Dollars, and we pay the Settlement Payment
          to you in that other currency, then Bindo will charge you a Foreign Currency Settlement
          Fee on the Transaction Value. For example, if the Transaction currency is USD and the
          Settlement Payment is made in USD, we will charge you a Foreign Currency Settlement Fee.</p>
        <p>14.4 FX Conversion Fee. Subject to clause 13.5, if we pay the Settlement Payment to you
          in a Settlement Currency that is different from the Transaction currency, then Bindo will
          charge you a FX Conversion Fee on the Transaction Value. For example, if the Transaction
          is in USD and we settle HKD to you, we will charge you a FX Conversion Fee.</p>
        <p>14.5 Applicability of Foreign Currency Settlement Fee and FX Conversion Fee. The Parties
          acknowledge and agree that:</p>
        <p>(a) the Foreign Currency Settlement Fee and the FX Conversion Fee will apply in respect
          of all Card Transactions unless an 'Interchange++' pricing structure has been agreed with
          you in writing in respect of Card Transactions, in which case only the FX Conversion Fee
          will be applicable to such transactions; and</p>
        <p>(b) the Foreign Currency Settlement Fee is not applicable to LPM Transactions.</p>
        <p>14.6 Currency conversions by Bindo and Payment Method Providers. You acknowledge and
          agree that:</p>
        <p>(a) a Payment Method Provider may convert the Transaction currency into another currency
          before it is received by Bindo using the prevailing exchange rate(s) determined by the
          Payment Method Provider; and</p>
        <p>(b) Bindo may convert the Transaction currency or, if applicable, the currency received
          from a Payment Method Provider under clause 13.6(a), using the prevailing exchange rate
          determined by Bindo into the Settlement Currency before making a Settlement Payment to
          you.</p>
        <p>In relation to certain Transactions in currencies not directly supported by Bindo, more
          than one currency conversion may be required under this clause 13.6 to convert a
          Transaction currency to the Settlement Currency. For example, if a Transaction is made
          via a Payment Method Provider in an exotic currency that Bindo does not directly support,
          that Payment Method Provider may convert and settle the relevant Transaction currency in
          USD to Bindo and, if your Settlement Currency is HKD, Bindo may convert that USD amount
          to HKD and pay it to you as the Settlement Payment.</p>
        <p>14.7 Supported Currency changes. Without limiting any of our rights under these Terms,
          Bindo may add, restrict or remove any Supported Currency from time to time during the
          Term and, without limiting the foregoing, a Payment Method Provider may add, restrict or
          remove a currency they support from time to time.</p>
        <p>14.8 Buyer warnings. You are solely responsible for giving any notices, warnings or
          disclaimers regarding prices and other amounts displayed to a Buyer when making a
          proposed Transaction (whether the Transaction is to be in Hong Kong Dollars or any other
          currency) including, but not limited to, warning a Buyer that they may be charged foreign
          currency fees by their card issuer and providing any other warnings required by
          Applicable Law in relation to a Transaction involving a foreign currency.</p>
        <p>14.9 You acknowledge and agree that:</p>
        <p>(a) in the event of a Refund, Chargeback or other reversals of the original Transaction
          (a 'Reversal') that was subject to the FX Conversion Fee, you agree that the conversion
          of the relevant currency for the purposes of the Reversal will be subject to the FX
          Conversion Fee and the prevailing relevant exchange rate as determined by Bindo; and</p>
        <p>(b) to the extent that the FX Conversion Fee applies to any Reversal by us under these
          Terms, you acknowledge and agree that the Merchant bears the risk of any movement in
          exchange rate between the time of processing the original Transaction and the time of
          processing the relevant Reversal.</p>
        <div className={`${styles.title}`}>15. Representations and Warranties</div>
        <p>15.1 Each Party makes each of the following representations and warranties to the other
          Party, and acknowledges that such other Party is relying on these representations and
          warranties in entering into these Terms:</p>
        <p>(a) it is an independent corporation duly organized, validly existing and in good
          standing under the laws of jurisdiction of its incorporation;</p>
        <p>(b) it is properly registered to do business in all jurisdictions in which it carries on
          business;</p>
        <p>(c) it has all licences, regulatory approvals, permits and powers legally required to
          conduct its business in each jurisdiction in which it carries on business;</p>
        <p>(d) it has the corporate power, authority and legal right to execute and perform these
          Terms and to carry out the transactions and its obligations contemplated by these Terms;</p>
        <p>(e) these Terms shall constitute valid and binding obligations on the Party, enforceable
          in accordance with its terms. Except as otherwise stated in these Terms, no approval or
          consent of any person or government department or agency is legally or contractually
          required to be obtained by the Party in order to enter into these Terms and perform its
          obligations;</p>
        <p>(f) neither (a) the entry into of these Terms, nor (b) the performance by the Party of
          these Terms will (i) conflict with the certificate of incorporation or by-laws or any
          other corporate or constitutional document of the Party or (ii) breach any material
          obligations of the Party under any contract to which it is a party or (ii) violate any
          Applicable Law to a material extent; and</p>
        <p>(g) there is no litigation, proceeding or investigation of any nature pending or, to the
          Party’s knowledge, threatened against or affecting the Party or any of its Affiliates,
          which would reasonably be expected to have a material adverse effect on its ability to
          perform its obligations under these Terms.</p>
        <p>15.2 You warrant and represent that you have a permanent establishment and/or business
          registration in the Territory and that you will only use the Services from the Territory.</p>
        <p>15.3 You must obtain any necessary authorizations from Data Subjects for the required
          transfers of information within the scope of the Services. You are responsible for the
          legally compliant collection and transmission of information (in particular Personal
          Data) to Bindo.</p>
        <div className={`${styles.title}`}>16. Data Privacy</div>
        <p>16.1 Your use of the Services is subject to our Privacy Policy, which you agree to by
          entering into these Terms, and consent to the collection, use, disclosure, processing,
          management and transfer of Personal Data in accordance therewith.</p>
        <p>16.2 When using the Services, you or any third party authorized by you may submit content
          to Bindo. Any collection, use or processing of information by us shall be restricted to
          the purposes necessary for, or incidental to, the provision of the Services pursuant to
          these Terms unless your prior consent is obtained.</p>
        <p>16.3 If you receive any complaint, notice or communication from the Office of the Privacy
          Commissioner for Personal Data which relates directly to (i) our processing of Personal
          Data; or (ii) a potential failure by us to comply with the Personal Data (Privacy)
          Ordinance in respect of your or our activities under or in connection with these Terms (a
          ‘Data Complaint’) you shall, to the extent permitted by Applicable Law, promptly notify
          us of the Data Complaint and provide us any information we request in relation to such a
          Data Complaint.</p>
        <p>16.4 You acknowledge and agree that we may disclose Transaction Data or Personal Data to
          the Office of the Privacy Commissioner for Personal Data, any law enforcement authority
          or Regulator in accordance with Applicable Law.</p>
        <div className={`${styles.title}`}>17. Confidentiality</div>
        <p>17.1 The Parties agree that they shall:</p>
        <p>(a) treat as confidential all Confidential Information obtained from the other Parties
          under these Terms;</p>
        <p>(b) use the other Parties’ Confidential Information solely for the specific purposes for
          which it was disclosed;</p>
        <p>(c) not publish or otherwise disclose to any person the other Parties’ Confidential
          Information without the owner’s prior written consent; and</p>
        <p>(d) take all action reasonably necessary to secure the other Parties’ Confidential
          Information against theft, loss or unauthorised disclosure.</p>
        <p>17.2 Each Party may disclose Confidential Information only if it can demonstrate that the
          Confidential Information:</p>
        <p>(a) is required to be disclosed by any court of competent jurisdiction, Regulator, by the
          rules of a recognised stock exchange or by Applicable Law or the Network Rules;</p>
        <p>(b) was lawfully in its possession prior to disclosure to it by any other Party without
          an obligation restricting disclosure;</p>
        <p>(c) is already public knowledge or which becomes so at a future date (otherwise than as a
          result of breach of this clause 16);</p>
        <p>(d) is received from a third party who is not under an obligation of confidentiality in
          relation to the information; or</p>
        <p>(e) is developed independently without access to, or use or knowledge of, the
          Confidential Information.</p>
        <div className={`${styles.title}`}>18. Term and Termination</div>
        <p>18.1 These Terms shall come into force on the Commencement Date and, unless otherwise
          terminated earlier in accordance with these Terms, continue thereafter until it is
          terminated in accordance with clause 18.2, 18.3, 18.4 or 18.5.</p>
        <p>18.2 We may terminate these Terms without cause by giving you fourteen (14) days’ written notice.</p>
        <p>18.3 You may terminate these Terms without cause by giving us three (3) months’ written
          notice.</p>
        <p>18.4 Either Party may terminate these Terms or any Service with immediate effect by
          giving written notice if the other Party is:</p>
        <p>(a) in material breach of these Terms and the breach is either not capable of remedy or
          is not remedied to the reasonable satisfaction of the non-breaching party within 30 days
          of service of a notice requiring remedy of the breach in question;</p>
        <p>(b) insolvent or reasonably suspects that the other Party may become Insolvent;</p>
        <p>(c) is the subject of a petition, order, or resolution or any step in connection with
          winding up (whether it is Insolvent or not).</p>
        <p>18.5 We may terminate these Terms or any Service with immediate effect by giving written
          notice to you if:</p>
        <p>(a) you have violated the Acceptable Use Policy;</p>
        <p>(b) you fall below any relevant thresholds as determined by us from time to time;</p>
        <p>(c) you act in a manner, or if anything happens to you or comes to our attention in
          relation to you or arising from or incidental to your business or the conduct of your
          business (including trading practices or any individual’s activity), that we in our
          reasonable discretion consider:</p>
        <p>(c)(i) to be disreputable or capable of damaging the reputation of us or that of any
          Network, or Payment Method Provider; or</p>
        <p>(c)(ii) to be detrimental to our systems, business or that of any Network, or Payment
          Method Provider; or</p>
        <p>(c)(iii) may or does give rise to fraud or any other criminal activity or suspicion of
          fraud or any other criminal activity; or</p>
        <p>(c)(iv) may or does give rise to increased risk of loss or liability to any of us;</p>
        <p>(c)(v) may affect your ability or willingness to comply with all or any of your
          obligations or liabilities under these Terms; or</p>
        <p>(c)(vi) to be or to be for a purpose contrary to Applicable Law and/or any policy of ours
          in relation to Applicable Law;</p>
        <p>(d) you include anything in these Terms, the Merchant Services Agreement (if applicable)
          or application for the Services which is untrue, inaccurate or misleading;</p>
        <p>(e) we are required to do so by any Network, Payment Method Provider or Regulator or
          under the Network Rules or Applicable Law or reasonably believe that a Transaction or
          these Terms or the performance of it may be contrary to Applicable Law or Sanctions Law;</p>
        <p>(f) a Network, Payment Method Provider or any other third party any ceases to provide us
          with any service which is necessary for us to provide a Service to you; and</p>
        <p>(g) the ratio of Chargebacks to Transactions exceeds thresholds set by the Payment Method
          Providers , or we consider in our sole and absolute discretion that the total volume or
          value of Refunds, Chargebacks and/or declined Authorisation Requests is excessive.</p>
        <p>18.6 We may suspend all or part of the Services, including any Transactions or Refunds,
          if we determine that you have breached these Terms or are likely to breach these Terms.</p>
        <div className={`${styles.title}`}>19. Indemnity</div>
        <p>19.1 You will indemnify us, Networks, Acquirers and the Local Payment Providers and hold
          us, Networks, Acquirers and the Local Payment Providers harmless and indemnified from,
          against and in respect of all and any Losses in relation to any Claims brought against us
          by a Buyer, Network, Payment Method Provider, Acquirer or Regulator or any other third
          party, to the extent such Claims arise out of or in consequence of or in connection with:</p>
        <p>(a) a Transaction (including the failure to retain or produce a Recurring Transaction
          Authority), Refund, Assessment, Chargeback and/or Chargeback Cost (including any activity
          which would otherwise constitute a Transaction or Refund);</p>
        <p>(b) any breach of the requirements or failure by you to comply with: (i) the requirements
          of a Network or Local Payment Provider; (ii) the Network Rules; (iii) a Regulator; or
          (iv) Applicable Law, and any reasonable steps taken in the protection of our interests in
          connection with any such breaches;</p>
        <p>(c) any security breach compromise or theft of Data held by you or on your behalf, or any
          other security breach or a security breach relating to Data (whether or not you have
          complied with PCI SSC Standards as defined above), and any reasonable steps taken in the
          protection of our interests in connection with such breach;</p>
        <p>(d) the enforcement or attempted enforcement of these Terms;</p>
        <p>(e) any reasonable steps taken in the protection of our interests in connection with any
          allegation of fraud made in relation to you or your business; and/or</p>
        <p>(f) any breach or alleged breach by you of any provisions of this Agreement (including
          but not limited to the provisions of clause 17 and the applicable Addenda);
          except if and to the extent such Claim is caused by our fraud or any breach of these
          Terms by us.</p>
        <p>19.2 We shall indemnify and hold you indemnified from and against any Losses in relation
          to any Claims brought against you by a third party, to the extent such Claims arise out
          of or in connection with:</p>
        <p>(a) any actual security breach or security breach reported to you by a Network, Acquirer,
          other Acquirer, Card Issuer or us relating to Data which is directly attributable to our
          failure to comply with any PCI SSC Standards or to our gross negligence (but not
          including any claims made by a Regulator), and any reasonable steps taken in the
          protection of your interests in connection with such breach; and/or</p>
        <p>(b) any breach by us of the provisions of clause 17;</p>
        <p>except if and to the extent caused by or contributed to by your negligence or any breach
          of these Terms by you.</p>
        <div className={`${styles.title}`}>20. Limitation of Liability</div>
        <p>20.1 Nothing in these Terms shall exclude or restrict liability for:</p>
        <p>(a) Losses suffered by a Party arising out of the other Party’s fraud, fraudulent
          misrepresentation or wilful default;</p>
        <p>(b) death or personal injury resulting from a Party’s negligence;</p>
        <p>(c) Losses suffered by us in respect of any Chargebacks or Assessments;</p>
        <p>(d) any Service Fees, Permissible Deductions, or other amounts due to us;</p>
        <p>(e) any indemnity provided under these Terms; or</p>
        <p>(f) any other liability to the extent it cannot be lawfully excluded or limited.</p>
        <p>20.2 We shall not be liable for any failure to perform (nor any defective or delayed
          performance of) any of our obligations under these Terms if and to the extent that such
          failure is due to:</p>
        <p>(a) circumstances beyond our reasonable control;</p>
        <p>(b) any cessation or interruption of any part of the Services which are due to any act or
          omission of a third party (including, but not limited to, Payment Method Providers,
          Networks, or Acquirers) and is not caused by our breach of these Terms;</p>
        <p>(c) us taking steps (in our reasonable and honest belief or view) to comply with any
          relevant requirement under the Network Rules or any Applicable Law, Sanctions Law, or the
          requests of any Regulator;</p>
        <p>(d) your failure to provide complete and/or correct Data to us and/or your negligence
          and/or breach of these Terms;</p>
        <p>(e) a suspension of the Services by us in accordance with these Terms;</p>
        <p>(f) your breach of these Terms, negligence, wrongful or bad faith acts or omissions; or</p>
        <p>(g) any deferment/withholding of the Settlement Payment(s) otherwise due to you in
          accordance with the provisions of these Terms.</p>
        <p>20.3 Neither Party shall be liable for:</p>
        <p>(a) loss of profits, revenue or anticipated savings (including those anticipated or
          forecast);</p>
        <p>(b) loss of goodwill (or any other damage to reputation);</p>
        <p>(c) loss connected with or arising from business interruption;</p>
        <p>(d) loss of opportunity, business or contracts;</p>
        <p>(e) loss of bargain;</p>
        <p>(f) lost or corrupted data (or loss associated with the same); and/or</p>
        <p>(g) any special, incidental, punitive, consequential or indirect: loss, damage, cost
          and/or expense whatsoever, in each case whether such losses are direct, indirect or
          consequential, and even if that Party was aware of the possibility that such losses might
          be incurred by another Party.</p>
        <p>20.4 Our aggregate liability to you in relation to all Claims arising out of, or in
          connection with the Services or these Terms during each Contract Year shall be limited
          to:</p>
        <p>(a) in the first Contract Year, a sum equal to the average monthly Service Fees paid
          under these Terms, less any fees incurred by us under the Network Rules in respect of
          Transactions, in each case in the period between the Effective Date and the first event
          giving rise to the first such Claim, multiplied by twelve (12); and</p>
        <p>(b) in each Contract Year thereafter, a sum equal to the Service Fees paid under these
          Terms, less any fees incurred by us under the Network Rules in respect of Transactions,
          in each case in the twelve (12) months immediately preceding the first event giving rise
          to the first such claim in the relevant Contract Year.</p>
        <div className={`${styles.title}`}>21. Notice</div>
        <p>21.1 The Parties agree that any notice to be given under or in connection with these
          Terms to Bindo shall be in writing and shall be served as follows (or otherwise as
          notified by Bindo to you from time to time):</p>
        <p>(a) by email to support@bindo.com</p>
        <p>(b) by mail to Suite 05-181, 5/F The Quayside, 77 Hoi Bun Road, Kwun Tong, Kowloon</p>
        <p>21.2 The Parties agree that any notice to be given under or in connection with these
          Terms to you shall be in writing and shall be served as follows (or otherwise as notified
          by you from time to time through the Wonder Platform):</p>
        <p>(a) by mail to any mailing address we have recorded for you in your Bindo Profile;</p>
        <p>(b) by other electronic communication (such as by sending you an electronic message and
          referring you to a notice available for viewing or to download online or in Wonder App
          using phone or other written records we have recorded for you in your Bindo Profile.</p>
        <p>21.3 Where you provide an email address, we may send notices to and rely on the
          authenticity of communications we receive from that email address as being from and
          binding on you. You must ensure only you and persons with authority to act on your behalf
          have access to your email addresses, that they are kept secure and that you contact us
          immediately if you become aware or suspect any relevant unauthorised use or security
          compromise.</p>
        <p>21.4 Notices sent by email or other electronic communication shall be deemed to be
          received on the day on which the communication is sent, PROVIDED THAT (i) any notice sent
          after 17:00 hours (Hong Kong standard time) on any Business Day or at any time on a day
          which is not a Business Day shall be deemed to have been given at 09:00 (Hong Kong
          standard time) on the next Business Day. Notices sent by mail shall be deemed to be
          received seven (7) days after the letter is posted.</p>
        <div className={`${styles.title}`}>22. Changes to the Services and these Terms</div>
        <p>22.1 From time to time, we may vary the provisions of these Terms, the Schedules to these Terms (including the Fee Schedule) by giving you fourteen (14) days’ written notice. We may also make changes to the Services and our provision thereof which are necessary to comply with any Applicable Law or Network Rules. If such adjustments or changes lead to a change in software, interfaces or operating procedures, we shall notify you as soon as reasonably practicable prior to the implementation of such adjustments or changes.</p>
        <p>22.2 If we make changes to the terms and conditions herein affecting the Services, you
          shall be entitled to terminate these Terms immediately by providing written notice to us,
          PROVIDED THAT such notice is served upon us within two (2) months of you being notified
          of the variation. Otherwise, you will be deemed to have accepted any variation of the
          provisions of these Terms two (2) months from being notified of it.</p>
        <p>22.3 We may issue updates for the Wonder Platform, including the Bindo APIs, from time to time. We may need to do this to facilitate the continued and proper operation of the Platform, make improvements to the Platform or to comply with Applicable Law. Some updates may require you to take steps to implement them. You agree to implement such updates as soon as reasonably practicable after receipt. In some cases (for example, if there are security risks), you will not be able to use the Wonder Platform until you have implemented the update. If you have not implemented an update within 6 months of us releasing it, we may stop providing some of the Services to you or terminate this Agreement, by giving you fourteen (14) days’ prior written notice.</p>
        <div className={`${styles.title}`}>23. General</div>
        <p>23.1 Bindo is licensed as a money service operator by the Customs and Excise Department
          for the provision of “money services” (i.e. “money changing services” and “remittance
          services”) (as such terms are defined under the Anti-Money Laundering and Counter￾Terrorist Financing Ordinance (Cap. 615)).</p>
        <p>23.2 These Terms are concluded in English and all communications (including any notices
          or the information being transmitted) shall be in English. In the event that these Terms
          or any part of it is translated (for any proceedings, for your convenience or otherwise)
          into any other language, the English language text of these Terms shall prevail.</p>
        <div className={`${styles.title}`}>24. Governing Law</div>
        <p>24.1 These Terms shall be governed by Hong Kong law.</p>
        <p>24.2 The Parties submit to the non-exclusive jurisdiction of the courts of Hong Kong to
          settle any dispute arising out of, relating to or having any connection with these Terms,
          and any dispute relating to any non-contractual obligations arising out of or in
          connection with it (for the purpose of this clause, a ‘Dispute’).</p>
        <p>24.3 For the purposes of clause 24.2, each party waives any objection to the Hong Kong
          courts on the grounds that they are an inconvenient or inappropriate forum to settle any
          Dispute.</p>
        <div className={`${styles.title}`}>25. Set-Off</div>
        <p>25.1 If at any time an amount payable by you under these Terms or any other agreement
          with Bindo is due but unpaid, we may withhold payment of any amount that is payable by
          Bindo to you until you have made payment of the amount that you owe us. We may set off
          any amount that you owe us against any amount that we owe you. We may apply any credit
          balance in any account you have with us (including, without limitation, any Reserve) in
          and towards satisfaction of, or payment of, any of your obligations to pay an amount
          which is then due under these Terms or any other agreement you have with Bindo.</p>
        <div className={`${styles.title}`}>26. Further Assurances</div>
        <p>26.1 You agree, at your own expense, to:</p>
        <p>(a) execute and do everything else reasonably necessary or appropriate to bind you under
          these Terms; and</p>
        <p>(b) use your best endeavours to cause relevant third parties to do likewise.</p>
        <p>26.2 If we determine that any part of these Terms (or a transaction in connection with it
          including but not limited to provisions relating to the Reserve) is or contains a
          security interest under Hong Kong law, you agree to do anything (such as obtaining
          consents, signing and producing documents, getting documents completed and signed and
          supplying information) which we ask and consider necessary for the purposes of:</p>
        <p>(a) ensuring that the security interest is enforceable, perfected and otherwise
          effective; or</p>
        <p>(b) enabling us to apply for any registration, or give any notification, in connection
          with the security interest so that the security interest has the priority required by us;
          or</p>
        <p>(c) enabling us to exercise rights in connection with the security interest.</p>
        <p>26.3 Everything you are required to do under this clause 26 is at your expense (in
          particular, but not limited to, preparing, registering and maintaining any financing
          statement or financing change statement as required under Hong Kong law). You agree to
          pay or reimburse our reasonable costs, charges and expenses in connection with anything
          you are required to do under this clause 26.</p>
        <div className={`${styles.title}`}>27. Third-Party Rights</div>
        <p>27.1 A person who is not a party to these Terms has no rights under the Contracts (Rights
          of Third Parties) Ordinance (Cap. 623) (or any equivalent legislation under Applicable
          Law) to enforce any term of these Terms save that the obligations of the Merchant under
          these Terms are owed to Bindo’s Affiliates each of whom may enforce the terms of these
          Terms against Merchant.</p>
        <div className={`${styles.title}`}>Schedule 1. Definitions</div>
        <p>In these Terms, unless otherwise defined in these Terms itself, the following terms have
          the following meanings (for both the singular and plural):</p>
        <p><strong className="italic">Acquirer</strong> means (i) with respect to the Transactions for which we provide you with Gateway
          Service, a third party that has an agreement with you to settle funds to you, or (ii)
          with respect to the Transactions for which we provide you with Payment Processing
          Services, either Bindo itself or a third party that has an agreement with us to settle
          funds to us,;</p>
        <p><strong className="italic">Acquiring Agreement</strong> means an agreement between Merchant and an Acquirer for the
          collection and settlement by Acquirer of Payments to Merchant;</p>
        <p><strong className="italic">Additional Services</strong> means the Gateway Services, Fraud Control Service, Encryption
          Services, Customs Declaration Service and any other additional services under these Terms
          that the Merchant has requested that Bindo provide to Merchant;</p>
        <p><strong className="italic">Additional Terms</strong> means the additional terms which vary or amend these Terms, as set out
          in Schedule 2 (Card Payment Processing Terms), Schedule 3 (Local Payment Method
          Processing Terms), Schedule 4 (Customs Declaration Terms) and any other terms you agree
          are ‘Additional Terms’;</p>
        <p><strong className="italic">Affiliate</strong> means: (a) a director, officer, partner, member, manager, executor or trustee
          of such person and (b) any person directly or indirectly controlling, controlled by, or
          under common control with, that person. For purposes of this definition, “control,”
          “controlling,” and “controlled” mean having the right to elect a majority of the board of
          directors or other comparable body responsible for management and direction of a person
          by contract, by virtue of share ownership or otherwise;</p>
        <p><strong className="italic">Bindo API</strong> means the technical interface setting out the protocols and specifications
          required to effect an integration of the Merchant’s technical systems with the Wonder
          Platform for Authorised Users to use the Services;</p>
        <p><strong className="italic">Bindo Labs Limited Platform or Platform</strong> means the proprietary technology and associated
          products (including but not limited to those found on Wonder App and through an Bindo
          API) devised by Bindo to provide Merchant with Services under these Terms;</p>
        <p><strong className="italic">Aggregate Payment Amount</strong> means the aggregate amount in the Settlement Currency of all
          Payments which are due to be settled to Merchant;</p>
        <p><strong className="italic">Applicable Law</strong> means any applicable law, regulation, rule, policy, judgement, decree,
          order or directive, at a state or local level, including, without limitation, any
          regulatory guidelines or interpretations or regulatory permits and licences issued by
          governmental or regulatory authorities having jurisdiction over the relevant Party, that
          are applicable to a Party or its business or which the Party is otherwise subject to, in
          each case in force from time to time;</p>
        <p><strong className="italic">Assessment</strong> means any assessment, fine, liquidated damages, fee, cost, expense or charge
          of any nature which a Network, Local Payment Provider or any other third party levies on
          you or us at any time, directly or indirectly, in relation to the Services, Transaction
          or any other aspect of our or such third party’s relationship with you;</p>
        <p><strong className="italic">Authorisation</strong> means the confirmation at the time of a Transaction from the relevant
          Network or Local Payment Provider that the Payment Method used to pay for the Transaction
          has not been blocked for any reason or listed as lost or stolen or as having had its
          security compromised, that there are sufficient funds available for the relevant
          Transaction and that such a Transaction otherwise meets the requirements by the relevant
          Network or Local Payment Provider and ‘authorise’ and ‘authorised’ shall be construed
          accordingly;</p>
        <p><strong className="italic">Authorisation Request</strong> means a request for Authorisation;</p>
        <p><strong className="italic">Authorised User</strong> has the meaning given in clause 2.3;</p>
        <p><strong className="italic">Business Day</strong> means any day when banks are generally open for business in Hong Kong (other
          than a Saturday, Sunday or public holiday in Hong Kong, or any day on which a typhoon
          signal number 8 or above, or a black rainstorm warning is hoisted in Hong Kong);</p>
        <p><strong className="italic">Buyer</strong> means the person purchasing products or services from the Merchant;</p>
        <p><strong className="italic">Capture Request</strong> means the submission by you to the Payment Method Provider of Transaction
          Data relating to a specific Transaction after receipt of the Authorisation for the
          purposes of executing a payment instruction in respect of a Transaction;</p>
        <p><strong className="italic">Card</strong> means any of the cards we allow to be accepted including any such cards we have
          agreed in writing with you;</p>
        <p><strong className="italic">Card Network</strong> means any scheme governing the issue and use of Cards as may be approved and
          notified by us to you in writing from time to time.</p>
        <p><strong className="italic">Card Transaction</strong> means a sale and purchase transaction between Merchant and a Buyer for
          products or services (including shipping costs) for which the Buyer pays with a Card;</p>
        <p><strong className="italic">Chargeback</strong> means any circumstances where Networks or Local Payment Providers and / or
          their payment service providers refuse to Settle a Transaction or demand payment from us
          in respect of a Transaction that has been Settled and/or in respect of which a Settlement
          Payment has been made to you notwithstanding any Authorisation;</p>
        <p><strong className="italic">Chargeback Costs</strong> means our administrative charge for processing a Chargeback and any (i)
          reasonable costs, expenses, liabilities, and (ii) Assessments that we may incur as a
          result of or in connection with a Chargeback;</p>
        <p><strong className="italic">Chargeback Fee</strong> means our fee for processing Chargebacks as set out in the Fee Schedule;</p>
        <p><strong className="italic">Claim</strong>Claim means any action, proceeding, claim, demand or assessment (including Assessments),
          fine or similar charge whether arising in contract, tort (including negligence or breach
          of statutory duty) or otherwise;</p>
        <p><strong className="italic">Commencement Date</strong> means the date specified in the Merchant Services Agreement or the date
          you agree to these Terms in the Online Application (whatever is earliest);</p>
        <p><strong className="italic">Confidential Information</strong> means these Terms and information relating to it (other than
          Transaction Data), or provided pursuant to it, that is designated as "confidential" or
          which by its nature is clearly confidential, howsoever presented, whether in oral,
          physical or electronic form and which is disclosed by one Party to another hereunder,
          including (but not limited to) pricing and specifications relating to the Services;</p>
        <p><strong className="italic">Contract Year</strong> means the calendar year commencing from the date and month of the
          Commencement Date;</p>
        <p><strong className="italic">Merchant’s Details</strong> means the details set out in the Online Application or the Merchant
          Services Agreement (as may be applicable);</p>
        <p><strong className="italic">Customs Declaration Service</strong>Means as defined in Schedule 4;</p>
        <p><strong className="italic">Data Complaint</strong> has the meaning given to it in clause 15.3;</p>
        <p><strong className="italic">Data Subject in relation to Personal Data</strong> means the individual who is the subject of the
          data;</p>
        <p><strong className="italic">Dispute</strong> has the meaning given to it in clause 23.2;</p>
        <p><strong className="italic">Due Diligence Information</strong> has the meaning given to it in clause 3.1;</p>
        <p><strong className="italic">Encryption Service</strong> means the encryption and secure transfer of Transaction Data by Bindo
          on behalf of Merchant.</p>
        <p><strong className="italic">Fee Schedule</strong> means the fee schedule applicable to the Services published by Bindo on
          <label className="underline decoration-1 underline-offset-4">bindolabs.com</label> or as we have otherwise agreed in writing with you.</p>
        <p><strong className="italic">Foreign Currency Settlement Fee</strong> means the Foreign Currency Settlement Fee set out in the
          Fee Schedule;</p>
        <p><strong className="italic">Fraud Control Service</strong> means the monitoring and analysis of Transactions by Bindo to
          identify and block fraudulent Transactions.</p>
        <p><strong className="italic">FX Conversion Fee</strong> means the FX Conversion Fee set out in the Fee Schedule;</p>
        <p><strong className="italic">Gateway Fee</strong> means a fee per Transaction attempted or made using the Gateway Service;</p>
        <p><strong className="italic">Gateway Service</strong> means the processing and transmission by Bindo via a Hosted Checkout Page
          of Authorisation Requests, Capture Requests and Transaction Data between you and Buyer
          and/or between you and an Acquirer. When providing the Gateway Service, we do not enter
          into the possession of any funds at any time. In respect of the Gateway Service, the
          Acquirer will settle the resulting Payments to Merchant pursuant to an Acquiring
          Agreement.</p>
        <p><strong className="italic">Global Account</strong>Global Account means the unique account ledger registered under your name and which
          records the amount of funds collected from your payers by Bindo for you as part of the
          “Collection Services” provided under the Payout and FX Terms;</p>
        <p><strong className="italic">Hosted Checkout Page</strong> means the Bindo hosted payment pages which the Merchant uses to
          accept a Payment as part of the Gateway Service;</p>
        <p><strong className="italic">Insolvent</strong> means: (a) in respect of a person, that that person is unable to pay its debts
          as defined in s.178 of the Companies (Winding-up and Miscellaneous Provisions) Ordinance
          (Cap. 32) (‘CWUMPO’) EXCEPT THAT in the interpretation of this definition: (i) the words
          “it is proved to the satisfaction of the court that” in subsection (1)(c) of section 178
          of CWUMPO shall be deemed to be deleted; and (ii) a Party shall not be deemed to be
          unable to pay its debts if any demand under section 178(1)(a) of CWUMPO or section 6A of
          the Bankruptcy Ordinance (Cap. 6) is being contested in good faith by such Party and such
          Party has adequate funds to discharge the amount of such demand or if any such demand is
          satisfied before the expiration of 21 days from the date on which it is made; (b) (where
          you are an individual) you are the subject of a bankruptcy petition or order; (c) (where
          you are an individual) you are deemed either unable to pay your debts or as having no
          reasonable prospect of doing so, in either case, within the meaning of section 6A of the
          Bankruptcy Ordinance (Cap. 6); (d) (where you are a partnership) you have any partner to
          whom any of the foregoing apply; (e) (where you are an individual) you die, by reason of
          illness or incapacity (whether mental or physical), you are of unsound mind and are
          incapable of managing your own affairs or become a patient under any mental health
          legislation, or an order has been made or a receiver has been appointed in respect of you
          under the Mental Health Ordinance (Cap. 136);</p>
        <p><strong className="italic">Local Payment Method</strong> means any of the local payment methods brands that we allow you to
          use from time to time including any we have agreed in writing with you;</p>
        <p><strong className="italic">Local Payment Network</strong> means any scheme governing the issue and use of Local Payment
          Methods as may be approved and notified by us to you in writing from time to time.</p>
        <p><strong className="italic">Local Payment Provider</strong> means the legal entity providing each Local Payment Method or
          Local Payment Network;</p>
        <p><strong className="italic">Losses</strong> means any liabilities, losses, damages, charges, fines, costs and/or expenses
          (including reasonable and properly incurred legal fees and/or expenses);</p>
        <p><strong className="italic">LPM Transaction</strong> means a sale and purchase transaction between Merchant and a Buyer for
          products or services (including shipping costs) for which the Buyer pays with a Local
          Payment Method;</p>
        <p><strong className="italic">Mark</strong> means the names, logos, trade names, logotypes, trademarks, service marks, trade
          designations, and other designations, symbols, and marks that a Network or Local Payment
          Provider owns, manages, licences, or otherwise controls and makes available for use by
          its customers and other authorized entities in accordance with a licence.</p>
        <p><strong className="italic">Merchant Bank Account</strong> means the Merchant’s nominated bank account to which Settlement
          Payments can be paid specified in the Online Application or Merchant Services Agreement
          (as applicable);</p>
        <p><strong className="italic">MID</strong> means merchant identifier;</p>
        <p><strong className="italic">Net Settlement Amount</strong> means as defined in clause 6.3;</p>
        <p><strong className="italic">Network</strong> means the Card Network and Local Payment Network; any scheme governing the issue
          and use of Cards or Local Payment Methods, as may be approved and notified by us to you
          in writing from time to time;</p>
        <p><strong className="italic">Network Rules</strong> means all applicable rules, regulations, operating guidelines, policies,
          procedures, manuals, announcements, bulletins issued by the Networks or Local Payment
          Provider from time to time which relate to (amongst other things) Cards, Payments,
          Transactions, Local Payment Methods and the related processing of data including but not
          limited to: (a) such rules, regulations, operating guidelines, policies, procedures,
          manuals, announcements and bulletins issued by Visa Inc, Visa Worldwide Pte. Ltd.,
          Mastercard International Incorporated, Maestro International Inc., American Express
          Australia Limited, or any other Card Network and any of their respective subsidiaries and
          affiliates; and (b) such rules, regulations, operating guidelines, policies, procedures,
          manuals, announcements and bulletins issued by any Local Payment Method or Local Payment
          Network, and including, in each case, any requirements regarding the use of Network or
          Local Payment Method owned marks, risk management, Transaction processing, and any
          Network or Local Payment Method products, programs or services in which you are required
          to, or choose to participate.</p>
        <p><strong className="italic">OFAC</strong> means the Office of Foreign Assets Control of the US Department of Treasury;</p>
        <p><strong className="italic">Online Application</strong> means the online application whereby you apply for the Services and
          agree to be bound by these Terms (if applicable);</p>
        <p><strong className="italic">Payment</strong> means the payment in the relevant currency representing the relevant Transaction
          Value made or to be made by a Buyer for the purposes of completing the relevant
          Transaction;</p>
        <p><strong className="italic">Payout and FX Terms</strong> means the agreement entitled “Payout and FX Terms” entered into
          between you and us;</p>
        <p><strong className="italic">Payment Method</strong> means Cards and Local Payment Methods;</p>
        <p><strong className="italic">Payment Method Fee</strong> means, in respect of each Payment Method, the fee charged on the
          amount of the Transaction in the currency in which Bindo is to settle funds to you under
          these Terms;</p>
        <p><strong className="italic">Payment Method Providers</strong> means the providers of Cards and Local Payment Methods;</p>
        <p><strong className="italic">Payment Processing Service</strong> has the meaning given to it in clause 4.1(a);</p>
        <p><strong className="italic">PCI SSC Service</strong> has the meaning given to it in clause 7.3;</p>
        <p><strong className="italic">PCI SSC Standards</strong> means the Payment Card Industry Data Security Standard, Payment
          Application Data Security Standard and the PIN Transaction Security Standard as updated
          from time to time and published by the PCI Security Standards Council (the “PCI SSC”) at
          www.pcisecuritystandards.org;</p>
        <p><strong className="italic">Permissible Deductions</strong> has the meaning given to it in clause 6.4;</p>
        <p><strong className="italic">Personal Data</strong> means any data (a) relating directly or indirectly to a living individual;
          (b) from which it is practicable for the identity of the individual to be directly or
          indirectly ascertained; and (c) in a form in which access to or processing of the data is
          practicable;</p>
        <p><strong className="italic">Privacy Policy</strong> means our Hong Kong privacy policy which is made available on our website
          at bindolabs.com or provided to you separately as amended from time to time;</p>
        <p><strong className="italic">Product Documentation</strong> means the documentation and guidelines applicable to the Bindo
          products and services found on our website at bindolabs.com</p>
        <p><strong className="italic">Prohibited Act</strong> means:(a) to directly or indirectly offer, promise or give any person
          working for or engaged by Bindo a financial or other advantage as an inducement or reward
          for any improper performance of a relevant function or activity in relation to obtaining
          these Terms or any other contract with the Merchant; (b) to directly or indirectly
          request, agree to receive or accept any financial or other advantage as an inducement or
          a reward for improper performance of a relevant function or activity in connection with
          these Terms; (c) committing an offence: (i) under the Prevention of Bribery Ordinance
          (Cap. 201); (ii) under legislation or common law concerning fraudulent acts; (iii) of
          defrauding, attempting to defraud or conspiring to defraud Bindo; (d) any activity,
          practice or conduct which would constitute one of the offences listed under (c) above if
          such activity, practice or conduct had been carried out in Hong Kong;</p>
        <p><strong className="italic">Prohibited Transactions</strong> means transactions that violate or contravene the Acceptable Use
          Policy which is available on bindolabs.com or wonder.app or transactions prohibited by
          the Networks or Local Payment Providers;</p>
        <p><strong className="italic">Recurring Transaction</strong> means a recurring periodic Transaction including but not limited to
          subscriptions or a series of installment payments in respect of which Merchant
          periodically charges the Buyer’s Payment Method;</p>
        <p><strong className="italic">Recurring Transaction Authority</strong> means a prior written authority (provided by the Buyer to
          the Merchant at checkout process) authorizing a Recurring Transaction and containing at
          least the following: (a) the amount of the Recurring Transaction and whether it is fixed
          or variable; (b) the dates on which the Recurring Transaction will be charged by the
          Merchant to the Buyer’s Payment Method and whether the dates are fixed or variable; (c)
          the method by which the Merchant will communicate with the Buyer in respect of the
          Recurring Transaction Authority; and (d) a statement that the Buyer is entitled to cancel
          the Recurring Transaction Authority at any time;</p>
        <p><strong className="italic">Refund</strong> means the whole or partial reversing of a Transaction including the currency
          exchange;</p>
        <p><strong className="italic">Refund Fee</strong> means our fee for processing Refunds as set out in the Fee Schedule;</p>
        <p><strong className="italic">Regulator</strong> means any governmental or regulatory authority, and/or any self-regulatory
          authority, governmental department, agency, commission, board, tribunal, crown
          corporation, or court or other law, rule or regulation making entity having jurisdiction
          over any of the Parties and/or their businesses or any part or subdivision thereof in any
          territory in which the Services are made available or any local authority, district or
          other subdivision thereof (including, in respect of Hong Kong, the Customs and Excise
          Department and the Office of the Privacy Commissioner for Personal Data) and any body
          which succeeds or replaces any of the foregoing;</p>
        <p><strong className="italic">Reserve</strong> means an amount or percentage of your Settlement Payment(s) that we hold in order
          to protect against the risk of Refunds, Chargebacks, or any other risk, exposure and/or
          liability related to your use of the Services;</p>
        <p><strong className="italic">Sanctioned Person</strong> means a person that is (a) listed on, or owned or controlled by a
          person listed on any Sanctions List; (b) located in, incorporated under the laws of, or
          owned or controlled by, or acting on behalf of, a person located in or organised under
          the laws of a country or territory that is the target of country-wide sanctions; or (c)
          otherwise a target of Sanctions Laws;</p>
        <p><strong className="italic">Sanctions Authorities</strong> has the meaning given to it in the definition of Sanctions Laws;</p>
        <p><strong className="italic">Sanctions Laws</strong> means any economic sanctions laws, regulations, embargoes or restrictive
          measures administered, enacted or enforced by: (a) Australia; (b) the United States; (c)
          the United Nations; (d) the European Union; (e) the United Kingdom; or (e) the respective
          governmental institutions and agencies of any of the foregoing, including without
          limitation, the Australian Sanctions Office, OFAC, the United States Department of State,
          and Her Majesty’s Treasury (together ‘Sanctions Authorities’);</p>
        <p><strong className="italic">Sanctions List</strong> means the Consolidated List issued by the Australian Sanctions Office, the
          Consolidated List of Financial Sanctions Targets issued by Her Majesty’s Treasury (UK),
          the “Specially Designated Nationals and Blocked Persons” list issued by OFAC or any
          similar list issued or maintained or made public by any of the Sanctions Authorities;</p>
        <p><strong className="italic">Service Fees</strong> means the fees specified in the Fee Schedule including, but not limited to,
          the Gateway Fee, Payment Method Fee, Refund Fee and FX Management Fee;</p>
        <p><strong className="italic">Services</strong> has the meaning given to it in clause 4;</p>
        <p><strong className="italic">Settlement Currency</strong> means: (a) the currency you have requested to be used for Settlement
          in the in the Online Application or Merchant Services Agreement (as applicable) that we
          have approved; and (b) if no such request is made or approved, the currency we reasonably
          determine being either US Dollars or another Supported Currency;</p>
        <p><strong className="italic">Settlement Payment</strong> means payment of the Net Settlement Amount by us to the Merchant in
          accordance with clause 6;</p>
        <p><strong className="italic">Supported Currencies</strong> means in respect of each feature of a Service, each currency
          approved by Bindo from time to time that can be settled, collected, exchanged and/or paid
          out (as applicable) using that feature through the Wonder Platform;</p>
        <p><strong className="italic">Territory</strong> means Hong Kong and the territories approved by Bindo from time to time;</p>
        <p><strong className="italic">Transaction</strong> means a Card Transaction or an LPM Transaction (and Transactions; means any
          of them, as the context requires);</p>
        <p><strong className="italic">Transaction Data</strong> means documents, data and records of any kind relating to Transactions,
          Chargebacks, or Refunds (including, for the avoidance of doubt, data relating to Cards,
          Local Payment Methods and Buyers);</p>
        <p><strong className="italic">Transaction Limit</strong> means the maximum aggregate value of one or more Transactions that you
          may complete in respect of any specified period as determine by us from time to time or
          otherwise agreed in writing with you;</p>
        <p><strong className="italic">Transaction List</strong> has the meaning given to it in clause 6.6;</p>
        <p><strong className="italic">Transaction Mix</strong> means the amount, type and nature of Transactions projected for the
          Merchant, including the Transaction volume, the average Transaction value, the types of
          the Buyers, the spread of Transactions across Payment Methods, the geographical spread of
          Transactions, the relevant Transaction currencies and other relevant information in
          relation to the Merchant and the use of the Services;</p>
        <p><strong className="italic">Transaction Value</strong> means with respect to each Transaction, the amount of the purchase
          price of the relevant product or service offered by the Merchant; and</p>
        <p><strong className="italic">Web App</strong> means the online user interface that the Merchant and its Authorised Users may
          use to access the Wonder Platform and the Services.</p>
        <p><strong className="italic">Wonder Platform or Platform</strong> means the proprietary technology and associated products
          (including but not limited to those found on Wonder App and through an Bindo API) devised
          by Bindo to provide customers with Services under these Terms.</p>
        <div className={`${styles.title}`}>Schedule 2. Card Payment Processing Terms</div>
        <p><strong>1. General</strong></p>
        <p>1.1. You understand and agree that we have the right to terminate or limit our agreement
          with you at any time upon the Card Network’s request.</p>
        <p><strong>2. Acquiring Services</strong></p>
        <p>2.1 You understand and agree that we are entering into these Terms on behalf of and as an
          agent of the Acquirer.</p>
        <p>2.2 On an ongoing basis, you shall promptly provide to us with the current address of
          each of your offices, all “doing business as” (DBA) names used by you, and a complete
          description of goods sold and services provided.</p>
        <p>2.3 You understand and agree that in the event of any inconsistency between any provision
          of these Terms and the Network Rules, the Network Rules will govern.</p>
        <p>2.4 We are responsible for your card acceptance policies and procedures, and may require
          any changes to your website or otherwise that we deem necessary or appropriate to ensure
          that you remain in compliance with the Network Rules governing the use of the Marks.</p>
        <p>2.5 These Terms shall automatically and immediately terminate if the Card Network de￾registers us or if the Acquirer ceases to be a customer of Card Network for any reason or
          if the Acquirer fails to have a valid licence with the Card Network to use any Mark
          accepted by you.</p>
        <p>2.6 You acknowledge and agree:</p>
        <p>2.6.1 Card Network is the sole and exclusive owner of its Marks;</p>
        <p>2.6.2 not to contest the ownership of the Card Network’s Marks for any reason;</p>
        <p>2.6.3 Card Network may at any time, immediately and without advance notice, prohibit you
          from using any of the Card Network’s Marks for any reason;</p>
        <p>2.6.4 Card Network has the right to enforce any provision of the Network Rules issued by
          the relevant Card Network and to prohibit the you and/or us from engaging in any conduct
          the Card Network deems could injure or could create a risk of injury to the Card Network,
          including injury to reputation, or that could adversely affect the integrity of the
          interchange system, Card Network’s Confidential Information (as defined in the Network
          Rules), or both; and</p>
        <p>2.6.5 you will not take any action that could interfere with or prevent the exercise of
          this right by Card Network.</p>
        <p>2.7 Any use of the Card Network’s Mark by you in advertising, acceptance decals, or
          signs, must be in accordance with the Network Rules, including Card Network’s
          reproduction, usage, and artwork standards, as may be in effect from time to time; and
          your use or display of any Card Network’s Mark will terminate effective with the
          termination of these Terms, or upon notification by Card Network to discontinue such use
          or display.</p>
        <p><strong>3. Additional obligations</strong></p>
        <p>3.1 You acknowledge and agree:</p>
        <p>3.1.1 we may disclose Transaction Data, Merchant data, personal information and other
          information about the Merchant, or Personal Data to Card Network and their affiliates,
          agents, subcontractors and employees.</p>
        <p>3.1.2 the Card Network and their affiliates, agents, subcontractors and employees may use
          such information to perform their obligations under a relevant agreement with you,
          operate and promote the Card Networks, perform analytics and create reports, and for any
          other lawful business purpose.</p>
        <p>3.2 You shall display each Card Network’s Marks and give each Card Network equal
          representation with any signage, decals or other identification when promoting the
          acceptance of Cards and remove the same should these Terms terminate.</p>
        <p>3.3 You understand and agree that your refund policies for purchases on each Card
          Network’s cards must be at least as favorable as your refund policies for purchases on
          any other Payment Methods and the refund policy must be disclosed to, or reasonably
          accessible by, Buyers at the time of purchase and in compliance with Applicable Law.</p>
        <p>3.4 You shall indemnify us and each Card Network from and against any claim, suit,
          action, demand, damage, debt, loss, cost, expense (including litigation costs and
          attorneys’ fees) and liability arising from: (a) your use of the Card Network; (b) any
          sale or purported sale of products or services by you through the Card Network; (c) any
          breach by you of Applicable Law or mandatory codes, standards or best practice guidelines
          of any Regulator; or (d) any breach by you of these Terms in connection with a Card
          Network.</p>
        <p>3.5 You understand and agree that no Card Network (including a third party), its
          Affiliates, successors or assigns will be liable to you for any malfunction,
          unavailability or failure of, or delay in processing through, any point of sale terminal,
          direct payment service, direct connection or other devices or associated equipment
          operated by a Card Network or others which is beyond its reasonable control. Neither a
          Card Network nor we will be responsible or liable to the other for any incidental,
          indirect, speculative, consequential, special, punitive or exemplary damages of any kind
          (whether based in contract, tort, including negligence, strict liability, fraud, or
          otherwise, or statutes, regulations, or any other source of law) howsoever arising out of
          or in connection with these Terms. In addition, no Card Network nor we will be
          responsible to you for damages arising from delays or problems caused by
          telecommunications carriers or the banking system.</p>
        <p>3.6 You understand and agree that each Card Network has third-party beneficiary rights
          under these Terms that will fully provide each Card Network with the ability to enforce
          these Terms against you as necessary to protect the applicable Card Network.</p>
        <p>3.7 You shall not contain libellous, defamatory, obscene, pornographic, or profane
          material of any information that may cause harm to any individuals, any entity or to the
          brand of us or a Card Network.</p>
        <div className={`${styles.title}`}>Schedule 3. Local Payment Method Processing Terms</div>
        <p>You agree that the following additional terms will apply.</p>
        <p>1. Subject to these Terms, you may use, access and utilise the Local Payment Method to
          accept payments from Local Payment Users for sales of your goods and/or services.</p>
        <p>2. Your use of the Local Payment Method is subject to:</p>
        <p>2.1 your successful registration for and continued right to use the Local Payment Method in accordance with the Local Payment Rules;</p>
        <p>2.2 your acceptance of and agreement to be bound by the Local Payment Rules;</p>
        <p>2.3 conditions that may be notified by the Local Payment Provider to us from time to time.</p>
        <p>3. You shall be solely responsible for procuring the Supporting Infrastructure and
          Services, and in no event shall the Local Payment Provider be liable or responsible for
          any Supporting Infrastructure and Services and any and all related fees and costs shall
          be for your own account.</p>
        <p>4. You shall use the Local Payment Method solely for the Local Payment Transactions that
          involve products and/or services properly registered with and approved by the Local
          Payment Provider. Failure to comply with this requirement may result in us terminating
          these Terms immediately (in whole or in part) and we will not be responsible for any loss
          suffered by you as a result of such termination.</p>
        <p>5. You acknowledge and agree that: (1) the Local Payment Provider reserves all right,
          title and interest in its and its affiliates' Intellectual Property Rights; (2) all
          rights not expressly granted to you are reserved and retained by the Local Payment
          Provider, its affiliates or its licensors, suppliers, publishers, rights holders, or
          other content providers; and (3) the Intellectual Property Rights of the Local Payment
          Provider and its affiliates may not be reproduced, duplicated, copied, licensed, sold or
          resold without the Local Payment Provider’s express written consent.</p>
        <p>6. You acknowledge and agree that the Local Payment Provider shall own the Local Payment
          User Data, the Local Payment Provider’s Marks and all Intellectual Property Rights in or
          to Local Payment User Data and the Local Payment Provider's Marks. You undertake to
          assign or procure the assignment of all Intellectual Property Rights relating to Local
          Payment User Data to the Local Payment Provider or its nominee for this purpose for no
          additional consideration immediately upon creation. You acknowledge and agree that the
          Local Payment User Data shall be deemed to be the Local Payment Provider 's confidential
          information and you shall only use the Local Payment User Data for the purpose of
          complying with your obligations under these Terms.</p>
        <p>7. You shall display Local Payment Provider’s Marks in accordance with Local Payment
          Rules and at least as prominently as other Payment Method Provider’s Marks.</p>
        <p>8. You understand, acknowledge and agree that:</p>
        <p>8.1 you shall ensure the accuracy and completeness of the information and records kept in
          relation to the Local Payment User and Local Payment Transactions. You shall keep such
          information records for a period of at least seven (7) years or such longer period as
          required by the Local Payment Rules from the date of the Local Payment Transaction;</p>
        <p>8.2 you shall assume all liabilities for the legality, authenticity, completeness and
          validity of your instructions given in the course of your use of the Local Payment
          Method. You undertake to solely assume all risks relating to actions of the Local Payment
          Provider that are taken in accordance with your instructions or purported instructions;
          <p>8.3 you shall indemnify the Local Payment Provider, the Local Payment Provider's</p>
          partners, and the Local Payment Provider's affiliated companies from and against any
          claim, suit, action, demand, damage, debt, loss, cost, expense (including litigation
          costs and attorneys’ fees) and liability (each an “Local Payment Method Claim”) arising
          from: (1) your use of the Local Payment Method ; (2) any sale or purported sale of
          products or services by you through the Local Payment Method ; (3) any breach by you of
          Applicable Law or mandatory codes, standards or best practice guidelines; or (4) any
          breach by you of these Terms;</p>
        <p>8.4 you shall use counsel reasonably satisfactory to us and/or the Local Payment Provider
          to defend each indemnified claim. If at any time we and/or the Local Payment Provider
          reasonably determine that any indemnified claim might adversely affect us and/or the
          Local Payment Provider, we or the Local Payment Provider may take control of the defence
          at our or the Local Payment Provider's respective expense. You may not consent to the
          entry of any judgement or enter into any settlement of an indemnified claim without our
          and/or the Local Payment Provider's prior written consent, which may not be unreasonably
          withheld;</p>
        <p>8.5 you shall comply with Local Payment Rules, including but not limited to processing
          and accepting any Refund and Chargebacks in accordance with Local Payment Rules;</p>
        <p>8.6 The Local Payment Provider owes no direct obligations to you and shall not be
          responsible for any of your goods, services, actions or omissions or liable for your
          losses, expenses or charges;</p>
        <p>8.7 The Local Payment Provider makes no other representations or warranties of any kind,
          express or implied, to you;</p>
        <p>8.8 shall not restrict the Local Payment Method User in any way from using the Local
          Payment Method at checkout at any sales channel, including but not limited to requiring a
          minimum or maximum purchase amount from customers.</p>
        <p>9. The provisions of these Terms, which by their nature and content, are intended,
          expressly or impliedly, to continue to have effect notwithstanding the completion,
          rescission, termination or expiration of these Terms, shall survive and continue to bind
          you and the Local Payment Provider.</p>
        <p>10. In this section:</p>
        <ul>
          <li><p>“Applicable Taxes” means any and all sales, goods and services, use, excise, premium,
            import, export, value added, consumption and other taxes, regulatory fees, levies
            (including environmental levies) or charges and duties assessed, incurred or required
            to be collected or paid for any reason in connection with any advertisement, offer or
            sale of products or services by the Merchant in connection with the Local Payment
            Method.</p></li>
          <li><p>“Intellectual Property Rights” means rights in, without limitation, (1) patents,
            design rights, copyright (including rights in computer software), database rights,
            trademarks, service marks, logos, moral rights, trade or business names, domain
            names, confidential information and knowledge and rights protecting goodwill and
            reputation, in all cases whether registered or unregistered; (2) all other forms of
            protection having a similar nature or effect anywhere in the world to the right
            described in (1); and (3) applications for or registrations of any of the above
            rights described in (1) or (2).</p></li>
          <li><p>“Supporting Infrastructure and Services” means (1) point-of-sale terminals,
            supporting hardware and other equipment; (2) terminal financing; (3) local supporting
            functions; and (4) any other hardware or software systems reasonably required for the
            purpose of use of the Local Payment Method by a Merchant.</p></li>
          <li><p>“Local Payment Rules” means all applicable rules, regulations, operating guidelines,
            policies, procedures, manuals, announcements, bulletins, requirements issued by the
            Local Payment Networks or Local Payment Provider from time to time which relate to
            (amongst other things) Payments, Transactions, Local Payment Methods and the related
            processing of data.</p></li>
          <li><p>“Local Payment User” means any person who from time to time is accepted by the Local
            Payment Provider as a user of the Local Payment Method who purchases goods or
            services from the Merchant utilising Local Payment Method as the payment solution.</p></li>
          <li><p>
            “Local Payment User Data” means the Local Payment Users' names, account information,
            Local Payment Transaction information, Personal Data and any other information
            relating to the Local Payment Users and/or their use of the Local Payment Method,
            which are collected by, generated by, or otherwise coming into yours or the Local
            Payment Provider’s possession or control.</p></li>
          <li><p>“Local Payment Transaction” means any Transaction between a Merchant and a Local
            Payment User which utilises the Local Payment Method.</p></li>
        </ul>
        <div className={`${styles.title}`}>Schedule 4. Customs Declaration Terms</div>
        <p><strong>1. Services</strong></p>
        <p>1.1 You acknowledge and agree that:</p>
        <p>(a) To use the Customs Declaration Service, you shall upload the relevant Transaction
          Data as required by China Customs through the interface provided and maintained by Bindo
          and the Service Provider. You may check the status of the processing and transmission of
          the relevant Transaction Data on such an interface.</p>
        <p>(b) The Customs Declaration Service shall only be used for the Transactions subject to
          the Limit.</p>
        <p>(c) Bindo or the Service Provider may suspend or terminate the Customs Declaration
          Service immediately without notice if: (i) your business licence or any similar
          certificate is revoked; (ii) your business operation or use of the Customs Declaration
          Service does not comply with any applicable law or regulation, these Terms or any other
          rules of the Service Provider; (iii) you are directly or indirectly involved in fraud;
          (iv) you are unable to provide the services or goods under the Transactions by reason of
          actual or anticipated commercial or financial difficulties; (v) there is any proceeding
          against you seeking a judgement of insolvency or bankruptcy or any other relief under any
          bankruptcy or insolvency law or other similar law; (vi) you are dissolved or Insolvent;
          (vii) you have counterfeited the Card or delayed any payment purposely; (viii) Bindo or
          the Service Provider reasonably believes that there is any risk event in respect of the
          Merchant; or (ix) Any of your actions is detrimental to the interests of Bindo or the
          Service Provider.</p>
        <p>(d) Upon the request of Bindo or the Service Provider, you should provide to us and the
          Service Provider access to all records and documents for the purpose of reviewing your
          use of the Customs Declaration Service.</p>
        <p>(e) Bindo or the Service Provider may suspend or terminate the Customs Declaration
          Service at any time by giving you ten (10) Business Days’ prior written notice.</p>
        <p>(f) Neither Bindo nor the Service Provider shall be liable for: (i) any loss or damage
          arising out of or relating to your use of the Customs Declaration Service; (ii) the delay
          or failure to deliver the relevant Transaction Data; and (iii) any dispute (arising out
          of or relating to the Customs Declaration Service) between you and other entities or
          individuals.</p>
        <p>1.2 You represent and warrant that:</p>
        <p>(a) All Transaction Data sent to Bindo and the Service Provider for using the Customs
          declaration Service are true, valid, and accurate in any respect, and no material
          information has been omitted and no information has been withheld that results in the
          relevant Transaction Data being unlawful, untrue or misleading in any aspect.</p>
        <p>(b) All Transaction Data sent to Bindo and the Service Provider for using the Customs
          declaration Service are based on the genuinely commercial and valid Transactions which do
          not and will not conflict with any applicable law or regulation, or are beyond your
          business scope and category as registered with Bindo and Service Providers. Upon the
          request of Bindo or the Service Provider, you should promptly provide, or procure the
          provision of, all documents, records, statements or other evidence authenticating or
          validating the Transactions as requested by Bindo or the Service Provider or required
          under Applicable Laws or the regulatory authorities.</p>
        <p>(c) You should verify the identity of the Buyer in accordance with the applicable law and
          regulation.</p>
        <p><strong>2. Service Fee</strong></p>
        <p>2.1 We may charge and vary the service fee (as determined by Bindo and notified to you)
          in respect of the Customs Declaration Service at any time.</p>
        <p><strong>3. Indemnity</strong></p>
        <p>3.1 You should hold Bindo and harmless and indemnified from, against and in respect of
          all and any losses, damages, costs (including reasonable legal fees) and expenses in
          relation to your use of the Customs Declaration Service, or any Claims brought against
          Bindo or the Service Provider, to the extent such Claims arise out of or in consequence
          of or in connection with the Customs Declaration Service.</p>
        <p>3.2 You agree to indemnify us against all losses, damages, costs (including reasonable
          legal fees) and expenses incurred or suffered by us in connection with or as a result of
          any inaccuracy, incompleteness or error of the Transaction Data sent to Bindo and the
          Service Provider.</p>
        <p><strong>4. Definition</strong></p>
        <p>In this Schedule:</p>
        <ul>
          <li><p>“Customs Declaration Service” means the processing and transmission by Bindo and the
            Service Provider of the relevant Transaction Data to the Customs of the People’s
            Republic of China.</p></li>
          <li><p>“Limit” means the aggregate maximum value of one or more Transactions for which you
            may use the Customs Declaration Service, as determined and adjusted by Bindo or the
            Service Provider from time to time.</p></li>
          <li><p>“Service Provider” means any service provider providing services in connection with
            the processing and transmission of the Transaction Data for the purpose of the
            customs declaration.</p></li>
        </ul>
      </div>
    </div>
  );
};

export default React.memo(PaymentTerms);
