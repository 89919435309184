import React from 'react';
import type { IIconProps } from '@/types/common-types';

const XpayIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 36 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18" cy="18" r="18" fill="#F8FAFC" />
        <path
          d="M31.2651 14.7324L29.3403 18.2034L27.3925 14.7324H25.6523L28.4961 19.8043V22.0657H30.1384V19.7807L30.1557 19.7508L33.0467 14.7324H31.2651Z"
          fill="#AA00FF"
        />
        <path
          d="M19.2753 17.6615C19.2753 16.044 17.9593 14.7285 16.3423 14.7285H13.2246V18.0712H14.7098V16.2189H16.3423C16.7284 16.2189 17.0909 16.3685 17.3625 16.6407C17.6347 16.9129 17.7843 17.2748 17.7843 17.6609C17.7843 18.047 17.6347 18.4096 17.3625 18.6812C17.0904 18.9533 16.7278 19.103 16.3423 19.103H13.2246V22.052H14.7156V20.5933H16.3423C17.9598 20.5933 19.2753 19.2773 19.2753 17.6603V17.6615Z"
          fill="#AA00FF"
        />
        <path
          d="M24.9664 22.0657H26.6749L23.6101 14.7324H21.8689L18.8047 22.0657H20.5241L21.0569 20.6824H21.0604L21.9189 18.4848L22.7447 16.3477L23.9122 19.3353H23.9099L24.0106 19.5954H22.591L22.0634 20.9431H24.5343L24.9664 22.0657Z"
          fill="#AA00FF"
        />
        <path
          d="M5.1665 22.792L7.03724 20.9213C7.23519 20.7234 7.55685 20.7228 7.7548 20.9213L9.62553 22.792L11.7915 20.6261L8.22205 17.0567L9.62553 15.6532L10.1377 16.1654L9.24632 17.0567L10.0744 17.8847L11.792 16.1671L9.62553 14L7.74329 15.8822C7.54765 16.067 7.24152 16.0664 7.04702 15.8805L5.16593 14L3 16.1659H4.65264L5.16535 15.6532L7.90672 18.3946L5.16535 21.1359L4.65322 20.6238L6.52107 18.756H4.86843L3 20.6255L5.1665 22.792ZM9.62611 21.1382L8.22263 19.7348L8.73476 19.2226L10.1382 20.6261L9.62611 21.1382Z"
          fill="#AA00FF"
        />
        <path
          d="M4.88109 18.0452H6.53373L4.65264 16.1641H3L4.88109 18.0452Z"
          fill="#00FAAA"
        />
      </svg>
    </div>
  );
};

export default XpayIcon;
