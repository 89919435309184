import { Transition } from '@headlessui/react';
import { useEffect, useRef, useState } from 'react';
import type { FieldError } from 'react-hook-form';
import CheckedIcon from './checked-icon';
import CloseIcon from '@/components/svg/close-icon';
import DownIcon from '@/components/svg/down-icon';
import UpIcon from '@/components/svg/up-icon';
import { useTranslation } from 'react-i18next';
import { findNode } from '@/utils';
import Config from '@/libs/config';


interface ISelectProps {
  options: any[];
  value?: string;
  dataKey?: string;
  valueKey?: string;
  className?: string;
  dropdownClassName?: string;
  multiple?: boolean;
  disabled?: boolean;
  error?: FieldError;
  placeholder?: string;
  showSearch?: boolean;
  noAdd?: boolean;
  searchType?: string;
  enableTag?: boolean;
  height?: string;
  position?: 'top' | 'bottom';
  filterOption?: (inputValue: string, option: any) => boolean;
  renderItem?: (item: any) => void;
  renderImgItem?: (item: any) => void
  onChange?: (value: any) => void;
  itemHeight?: string;
  valueClassName?: string;
  valueSpanClassName?: string;
  renderValueItem?: (item: any) => void;
}

let placeholderValue = ''
export const Select: React.FC<ISelectProps> = (props) => {
  const {
    options = [],
    dataKey = 'value',
    valueKey = 'label',
    className = '',
    dropdownClassName = '',
    renderItem,
    onChange,
    filterOption,
    value = '',
    multiple = false,
    disabled = false,
    placeholder = '',
    showSearch = false,
    // searchType = 'string',
    noAdd = false,
    enableTag = false,
    error,
    position = 'top',
    height = 'h-10',
    itemHeight = 'h-10',
    valueClassName = '',
    valueSpanClassName = 'h-full',
    renderValueItem,
    renderImgItem
  } = props;

  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string | string[]>();
  const [currentOptions, setCurrentOptions] = useState<any[]>(options);
  const [searchValue, stSearchValue] = useState<string>('');


  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);
  const input = useRef<any>(null);
  const prefix = Config.staticPath;


  useEffect(() => {
    const clickHandler = (e: any) => {
      if (!dropdown.current) return;
      if (
        dropdown.current.contains(e.target) ||
        trigger.current.contains(e.target)
      )
        return;
      setDropdownOpen(false);
      stSearchValue('');
    };

    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCurrentOptions(options)
    // eslint-disable-next-line
  }, [options]);

  useEffect(() => {
    if (multiple) {
      setSelected(value ? value.split(',') : []);
    } else {
      setSelected(`${value}`);
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    const keyHandler = (e: any) => {
      if (!dropdownOpen || e.keyCode !== 27) return;
      setDropdownOpen(false);
      stSearchValue('');
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);

    // eslint-disable-next-line
  }, []);

  const handleItemDelete = (key: string) => {
    if (Array.isArray(selected)) {
      const filterItem = selected.filter((item) => item !== key);
      onChange && onChange(filterItem.join(','));
      !value && setSelected(filterItem);
    }
  };

  const getValue = () => {
    if (multiple && Array.isArray(selected)) {
      const data: any[] = [];
      selected.forEach((key) => {
        const option = findNode(options, dataKey, key);
        if (option) data.push(option);
      });

      return (
        <>
          {data.map((item, index) => (
            <span
              key={item[dataKey]}
              className={`${index !== 0 ? 'ml-1' : ''
                } bg-disabled-color text-color-text-1 inline-flex py-0.5 px-1.5 rounded-sm items-center`}
            >
              {renderItem ? renderItem(item) : item[valueKey]}
              <CloseIcon
                onClick={() => handleItemDelete(item[dataKey])}
                className="text-icon-color shrink-0 ml-1 fill-current hover:text-primary-color"
              />
            </span>
          ))}
        </>
      );
    } else {
      const option = options.filter((item) => item[dataKey] === selected)[0];
      if (enableTag && option) {
        return tagItem(option);
      }
      if (option) {
        placeholderValue = option[valueKey]
      }

      return option ? (renderValueItem ? (renderValueItem(option)) : renderItem ? (renderItem(option)) : (option[valueKey])
      ) : (
        <span className="text-color-text-5">{placeholder}</span>
      );
    }
  };


  const getImgValue = () => {
    const option = options.filter((item) => item[dataKey] === selected)[0];
    if (enableTag && option) {
      return tagItem(option);
    }
    if (option) {
      placeholderValue = option[valueKey]
    }
    return option ? (renderValueItem ? (renderValueItem(option)) : renderImgItem ? (renderImgItem(option)) : (option[valueKey])
    ) : (
      <span className="text-color-text-5">{placeholder}</span>
    );
  };

  const handleClick = (key: string) => {
    if (multiple && Array.isArray(selected)) {
      let newSelected = [...selected];
      if (selected.includes(key)) {
        newSelected = newSelected.filter((item) => item !== key);
      } else {
        newSelected.push(key);
      }
      !value && setSelected(newSelected);
      onChange && onChange(newSelected.join(','));
    } else {
      const checkedKey = key;
      !value && setSelected(checkedKey);
      setDropdownOpen(false);
      onChange && onChange(checkedKey);
    }
    stSearchValue('');
    setCurrentOptions(options);
  };

  const handleValueChange = (value: string) => {
    let newOptions: any[] = [];
    options.forEach((item) => {
      const valueCode = item[valueKey] || '';
      if (filterOption && filterOption(value, item)) {
        newOptions.push(item);
      } else if (
        valueCode &&
        valueCode.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      ) {
        newOptions.push(item);
      }
    });
    if (!value) newOptions = options;
    setDropdownOpen(true);
    setCurrentOptions(newOptions);
    stSearchValue(value);
  };

  const onSelectClick = () => {
    setDropdownOpen(!dropdownOpen);
    if (showSearch && currentOptions.length === 0) {
      setCurrentOptions(options);
    }
    if (showSearch && input.current) {
      input.current.focus();
    }
  };
  const tagItem = (option: any) => {
    return (
      <span
        className={`px-3 py-1.5 rounded-3xl text-xs ${option.color} bg-opacity-10`}
      >
        {option[valueKey]}
      </span>
    );
  };


  return (
    <div className={`w-full ${height} relative`}>
      <div
        className={`bg-transparent ${valueSpanClassName} font-medium text-color-text-1 text-sm inline-flex items-center justify-between border rounded leading-5 shadow-box-drop min-w-full px-3 py-2 box-border hover:border-primary-color
        ${disabled
            ? 'border-none pl-0 shadow-none text-color-text-1'
            : 'cursor-pointer'
          } ${error ? 'border-error-color' : 'border-border-color'} ${className}`}
        onClick={!disabled ? onSelectClick : undefined}
        ref={trigger}
      >
        <span
          className={`absolute left-3 right-6 ${multiple || !showSearch ? 'hidden' : ''
            }`}
        >
          {!renderValueItem && !searchValue && (
            <span className={`${dropdownOpen ? '' : 'hidden'} absolute left-0 top-[6px]`}>{getImgValue()}</span>
          )}
          <input
            className={`${!renderValueItem && noAdd ? 'indent-[28px]' : ''} font-medium  m-0 b-0 w-full h-8 appearance-none bg-transparent border-none outline-none cursor-pointer text-color-text-1 placeholder:!text-[black] placeholder:!font-medium`}
            value={searchValue}
            autoComplete="off"
            // type={searchType}
            type="tel"
            placeholder={dropdownOpen && !renderValueItem && noAdd ? '+' + placeholderValue : ''}
            ref={input}
            aria-haspopup="listbox"
            disabled={disabled}
            onChange={(e) => {
              // if (!isNaN(Number(e.target.value))) {
              handleValueChange(e.target.value)
              // }
            }}
          />
        </span>
        <span
          className={`text-sm flex items-center ${valueClassName} ${dropdownOpen && showSearch && !multiple
            ? 'text-color-text-5 opacity-0'
            : ''
            }`}
        >
          <span
            className={`${searchValue.length > 0 ? 'hidden' : ''
              }  ${valueClassName}`}
          >
            {options.length === 0 && (
              <div className="flex">
                <span className="flex items-center relative w-5 mr-2">
                  <img
                    width="20px"
                    height="20px"
                    src={`${prefix}/images/country-flag/hk.svg`}
                    alt=""
                  />
                </span>
                <span className="text-color-text-1 text-sm font-medium">
                  +852
                </span>
              </div>
            )}
            {options.length !== 0 && (
              <>
                {getValue()}
              </>
            )}
          </span>
          <span className={`${!multiple || !showSearch ? 'hidden' : ''}`}>
            <input
              className="m-0 b-0 w-full h-8 appearance-none bg-transparent outline-none text-color-text-1 "
              value={searchValue}
              autoComplete="off"
              aria-haspopup="listbox"
              disabled={disabled}
              onChange={(e) => handleValueChange(e.target.value)}
            />
          </span>
        </span>
        {!disabled &&
          (dropdownOpen ? (
            <UpIcon className="text-icon-color shrink-0 ml-1 fill-current hover:text-primary-color flex items-center" />
          ) : (
            <DownIcon className="text-icon-color shrink-0 ml-1 fill-current hover:text-primary-color flex items-center" />
          ))}
      </div>
      {error && (
        <div className="mt-1 text-sm text-error-color">{error.message}</div>
      )}
      <Transition
        show={dropdownOpen}
        className={`z-100 max-h-60 min-w-8 absolute ${position === 'bottom' ? 'bottom-full' : 'top-full'
          } left-0 w-full bg-primary-background-color border border-border-color py-1 rounded shadow-dropdown overflow-auto mt-1 ${dropdownClassName}`}
        // style={{ top: `${top}px`, left: `${left}px`, width: `${width}px` }}
        // enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 -translate-y-2"
        enterTo="opacity-100 translate-y-0"
        // leave="transition ease-out duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="font-medium text-sm text-color-text-1"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
          ref={dropdown}
        >
          {currentOptions.map((option, index) => {
            return (
              <button
                key={`${option[dataKey]}-${index}`}
                tabIndex={0}
                type="button"
                className={`flex border-none outline-none bg-white justify-between items-center w-full hover:bg-disabled-color leading-10 ${itemHeight} px-3 cursor-pointer ${(multiple && Array.isArray(selected)
                  ? selected.includes(option[dataKey])
                  : option[dataKey] === selected) && 'text-primary-color'
                  }`}
                onClick={() => handleClick(option[dataKey])}
              >
                {enableTag && tagItem(option)}
                {!enableTag && (
                  <span className="text-sm">
                    {renderItem ? renderItem(option) : option[valueKey]}
                  </span>
                )}
                <CheckedIcon
                  className={`shrink-0 fill-current text-primary-color ${(multiple && Array.isArray(selected)
                    ? !selected.includes(option[dataKey])
                    : option[dataKey] !== selected) && 'invisible'
                    }`}
                />
              </button>
            );
          })}
          {currentOptions.length === 0 && (
            <div className="text-center text-color-text-5 py-2">
              {t('common:no_data_yet')}
            </div>
          )}
        </div>
      </Transition>
    </div>
  );
};

export default Select;
