import Config from '@/libs/config';
import type { IObject } from '@/types/common-types';
// import api from '@/shared/utils/api';
import { getCookie } from '@/utils/index';
import { Constants } from '@/constants';
import api from '@/libs/api';

export interface IHandleExportParams {
  p_business_id: string;
  type: string;
  templateType?: 'mss' | 'bas' | 'tbr';
  token?: string;
  batchID?: string;
  currency?: string;
  startTime?: string;
  endTime?: string;
}

export const handleExport = async (params: IHandleExportParams) => {
  const {
    p_business_id,
    token,
    type,
    startTime = '',
    endTime = '',
    batchID = '',
    templateType = 'mss',
    currency,
  } = params;
  let accessToken = token;

  if (!accessToken) {
    accessToken = getCookie(Constants.TOKEN)||'';
  }

  let paramsObj = '';

  if (startTime && endTime) {
    paramsObj = `startTime=${startTime}&endTime=${endTime}&requestType=closed_at_range`;
  }

  if (batchID) {
    paramsObj = `batchID=${batchID}&requestType=day_end_ids`;
  }

  if (currency) {
    paramsObj = `${paramsObj}&currency=${currency}`;
  }

  let result: IObject = {};
  try {
    const resp:IObject  = await api.get(
      `${Config.urls.gatewayUrl}/api/reports/daily-merchant-statement/${templateType}/${type}?${paramsObj}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-p-business-id': p_business_id,
          'X-USER-ACCESS-TOKEN': accessToken,
          'x-client-id': Config.clientID,
        },
        timeout: 60 * 1000,
      }
    );

    if (resp && resp.code === 200 && resp.data && resp.data.url) {
      result = resp.data;
    }
  } catch (error) {
    console.error(error);
  }

  return result;
};

export const querySubStore = async (businessID: string) => {
  const accessToken = getCookie(Constants.TOKEN);
  let result = [];
  try {
    const resp:IObject = await api.get(
      `${Config.urls.gatewayUrl}/api/registry/businesses/subBusinesses`,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-p-business-id': businessID,
          'X-USER-ACCESS-TOKEN': accessToken,
          'x-client-id': Config.clientID,
        },
      }
    );

    if (resp && resp.code === 200 && resp.data && Array.isArray(resp.data)) {
      result = resp.data;
    }
  } catch (error) {
    console.error(error);
  }

  return result;
};
