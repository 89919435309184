import React from 'react';
import type { IIconProps } from '@/types/common-types';

const WechatIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 36 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18" cy="18" r="18" fill="#F8FAFC" />
        <path
          d="M15.1599 20.7331C15.0618 20.7839 14.9513 20.8133 14.8338 20.8133C14.5618 20.8133 14.3255 20.6599 14.2014 20.4328L14.1539 20.3262L12.174 15.871C12.1527 15.8225 12.1394 15.7677 12.1394 15.7144C12.1394 15.509 12.3018 15.3427 12.502 15.3427C12.5832 15.3427 12.6584 15.3703 12.7189 15.4164L15.0552 17.1223C15.2259 17.2367 15.4298 17.3038 15.6491 17.3038C15.7799 17.3038 15.9047 17.2789 16.021 17.2354L27.008 12.2209C25.0386 9.84076 21.7951 8.28516 18.1248 8.28516C12.1186 8.28516 7.25 12.4459 7.25 17.5787C7.25 20.3788 8.71479 22.8995 11.0076 24.6033C11.1914 24.738 11.3119 24.9592 11.3119 25.2089C11.3119 25.2915 11.2946 25.3668 11.2736 25.4456C11.0904 26.146 10.7974 27.2678 10.7838 27.3204C10.7609 27.4083 10.7252 27.5001 10.7252 27.5921C10.7252 27.7974 10.8874 27.9637 11.0879 27.9637C11.1665 27.9637 11.2308 27.9337 11.2972 27.8946L13.678 26.4849C13.8571 26.3791 14.0466 26.3133 14.2556 26.3133C14.3667 26.3133 14.4741 26.331 14.5751 26.3627C15.6857 26.6903 16.8841 26.8723 18.1249 26.8723C24.1307 26.8723 29 22.7113 29 17.5787C29 16.024 28.5507 14.5599 27.7611 13.2719L15.2396 20.686L15.1599 20.7331Z"
          fill="#1AAD19"
        />
      </svg>
    </div>
  );
};

export default WechatIcon;
