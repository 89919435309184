import { Switch } from 'antd';
import { useState, useEffect } from 'react';

interface ISwitchProps {
  checked: boolean;
  value?: string;
  className?: string;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  onClick?: () => void;
}
const AntdSwitch: React.FC<ISwitchProps> = (props) => {
  const { checked, disabled, value, className = '', onChange, onClick } = props;

  const [isChecked, setIsChecked] = useState(checked);
  useEffect(() => {
    setIsChecked(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <div className={`${className} flex items-center`}>
      {value && <span className="text-xs text-color-text-5 mr-2">{value}</span>}
      <Switch
        disabled={disabled}
        checked={isChecked}
        onChange={(checked) => {
          if (onChange) {
            setIsChecked(checked);
            onChange(checked);
          }
        }}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      />
    </div>
  );
};

export default AntdSwitch;
