import AccountWrapper from '../../components/account-wrapper';
import Button from '@/components/common/button';
import ResetPwdSuccess from '../../accounts-icons/reset-pwd-success';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ResetPasswordSuccess = () => {
  const { t } = useTranslation('signin');

  return (
    <AccountWrapper>
      <div className="flex flex-col w-120 bg-color-text-6 p-15 md:rounded-[30px] md:mr-[10%] md:shadow-sign-in-card">
        <ResetPwdSuccess />
        <div className="text-xl text-center text-color-text-1 mt-10 font-bold">
          {t('signin:your_password_has_been_reset')}
        </div>
        <div className="text-center mt-2 mb-6 text-color-text-2">
          {t('signin:login_with_new_password')}
        </div>
        <div className="flex justify-center">
          <Link to="/account/signin">
            <Button className="w-full h-12 rounded-lg">
              {t('signin:go_to_login')}
            </Button>
          </Link>
        </div>
      </div>
    </AccountWrapper>
  );
};

export default ResetPasswordSuccess;
