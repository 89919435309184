import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ExchangeIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 48 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill="#005986" />
        <circle
          cx="20.9998"
          cy="22.2"
          r="9.66357"
          stroke="white"
          strokeWidth="1.07285"
        />
        <path
          d="M19.9906 22.5206L21.8374 22.5206C22.3485 22.5206 22.7644 22.9102 22.7644 23.3892C22.7644 23.8698 22.3492 24.2578 21.8376 24.2578C21.8334 24.2578 21.8293 24.2578 21.8252 24.2578L20.056 24.2578C19.6209 24.2539 19.2002 24.0312 18.9584 23.6769L18.9491 23.6632C18.7319 23.3439 18.2796 23.25 17.9389 23.4536C17.5983 23.6571 17.4981 24.0809 17.7152 24.4002L17.7252 24.4149C18.2353 25.162 19.0674 25.6289 20.1825 25.6289L20.1825 26.4512C20.1825 26.8298 20.51 27.1367 20.914 27.1367C21.318 27.1367 21.6456 26.8298 21.6456 26.4512L21.6456 25.6289C22.3447 25.6289 22.95 25.5101 23.5212 24.9788C23.9767 24.5551 24.2275 23.9906 24.2275 23.3892C24.2275 22.1542 23.1553 21.1495 21.8374 21.1495L19.9906 21.1495C19.4795 21.1495 19.0637 20.7599 19.0637 20.2809C19.0637 19.8189 19.4543 19.4123 20.0029 19.4123L21.9332 19.4123C22.3134 19.4157 22.678 19.5825 22.9339 19.8703C23.1925 20.1613 23.6538 20.2005 23.9642 19.9583C24.2746 19.7159 24.3166 19.2836 24.058 18.9927C23.3734 18.2226 22.5766 18.0412 21.6456 18.0412L21.6456 17.219C21.6456 16.8404 21.3181 16.5335 20.9141 16.5335C20.5101 16.5335 20.1825 16.8404 20.1825 17.219L20.1825 18.0412C18.4785 18.0412 17.6006 19.1661 17.6006 20.2809C17.6005 21.5159 18.6727 22.5206 19.9906 22.5206Z"
          fill="white"
        />
        <circle cx="32.3997" cy="28.7996" r="7.2" fill="white" />
        <path
          d="M28.0796 29.9639H29.24C29.6867 32.0984 31.4366 33.1843 33.8442 33.1843C34.3034 33.1843 34.7687 33.1347 35.079 33.054V31.5648C34.7811 31.6206 34.2971 31.6517 33.9124 31.6517C32.4977 31.6517 31.4924 31.1056 31.1201 29.9639H34.5081V29.3434H30.9836C30.965 29.1882 30.9588 29.0207 30.9588 28.8407V28.8283C30.9588 28.6422 30.965 28.4622 30.9898 28.2885H34.5081V27.668H31.1325C31.5172 26.551 32.5163 26.005 33.9124 26.005C34.2971 26.005 34.7811 26.0422 35.079 26.0981V24.6088C34.7687 24.5282 34.3034 24.4785 33.8442 24.4785C31.449 24.4785 29.6991 25.552 29.2462 27.668H28.0796V28.2885H29.1531C29.1407 28.4622 29.1283 28.636 29.1283 28.8221L29.1345 28.8345C29.1345 29.0083 29.1407 29.182 29.1531 29.3434H28.0796V29.9639Z"
          fill="#005986"
        />
      </svg>
    </div>
  );
};

export default ExchangeIcon;
