import type { IObject } from '@/types/common-types';
import { IPaymentType } from './type';
import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import BigNumber from 'bignumber.js';
import {
  handleSymbolMount,
  getPaymentMethodIcon,
  geyPaymentDisplayName,
  handleType,
} from '@/utils/account-balance-untils';
import { getPaymentTransaction } from '@/data/account-balance';
import DefaultDetailsContent from './payment-details-content/default-details-content';
import VoidDetailsContent from './payment-details-content/void-detail-coneten';
import RefundDetailsContent from './payment-details-content/refund-details-content';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import BackIcon from '@/components/svg/back-icon';
import { plus } from '@/utils/galaxy-utils';
import UnusualDetailsContent from './payment-details-content/unusual-details-content';
import { useTranslation } from 'react-i18next';
import PreAuthDetailsContent from './payment-details-content/pre-auth-details-content';
import WarningIcon from '../account-balances-icons/warning-icon';
import { IBreadcrumbItem } from '@/types/header-type';
import ActionType from '@/actions/action-type';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';

const PaymentDetailsPage = () => {
  const { p_business_id: businessID = '', payment_id: recordId = '', lang = 'en' } = useParams();
  const [record, setRecord] = useState<IObject>({});
  const [initLoading, setInitLoaing] = useState(false);
  const { t } = useTranslation("account_balance");
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const from = searchParams.get('from')
  const dayEndId = searchParams.get('dayEndId')

  useEffect(() => {
    getReacord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessID, recordId]);

  useEffect(() => {
    let navigationData: IBreadcrumbItem[] = [
      {
        name: t('common:company_account'),
        key: '1',
        type: 'label',
      },
      {
        name: t('account_balance:account_balance'),
        key: '2',
        type: 'link',
        url: `/${lang}/businesses/${businessID}/account-balance/overview`,
      },
      {
        name: recordId,
        key: '3',
        type: 'label',
      },
    ];
  
    if (from === 'settlement' && dayEndId) {
      navigationData = [
        {
          name: t('common:company_account'),
          key: '1',
          type: 'label',
        },
        {
          name: t('account_balance:account_balance'),
          key: '2',
          type: 'link',
          url: `/${lang}/businesses/${businessID}/account-balance/overview`,
        },
        {
          name: t('account_balance:detail_for', { data: dayEndId }),
          key: '3',
          type: 'link',
          url: `/${lang}/businesses/${businessID}/account-balance/settlements/${dayEndId}`,
        },
        {
          name: recordId,
          key: '4',
          type: 'label',
        },
      ];
    }
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: navigationData
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId, dayEndId, from, lang])

  const {
    // id = '',
    amount = '',
    tipsAmount,
    currency = '',
    payment_method: paymentMethod = '',
    payment_type: paymentType = '',
    fee_amount: feeAmount = '',
    consumer_identify = '',
    void_at: voidAt,
  } = record || {};

  const handlePaymentType = () => {
    const { payment_type = '', void_at } = record || {};
    let paymentTypeLow = (payment_type || '').toLowerCase();

    if (void_at !== null) {
      paymentTypeLow = 'void'.toLowerCase();
    }

    return paymentTypeLow;
  };

  const currentPaymentType = handlePaymentType();

  const getReacord = async () => {
    setInitLoaing(true);
    const data = await getPaymentTransaction(businessID, recordId);
    if (data) {
      setRecord(data);
    }
    setInitLoaing(false);
  };

  const handleBack = () => {
    navigate(-1);
    // if (isLinkage) {
    //   router.back();
    // } else if (from === 'merchant') {
    //   router.push({
    //     pathname: `/businesses/${businessID}/account-balance/merchant-account`,
    //     query: {
    //       activekey: 'merchantAccount',
    //     },
    //   });
    // } else if (from === 'settlement' && dayEndId) {
    //   router.push(
    //     `/businesses/${businessID}/account-balance/settlements/${dayEndId}`
    //   );
    // }
  };

  const getPaymentMethod = (cardNO: string) => {
    const iconType = getPaymentMethodIcon(paymentMethod, 48);
    const returnString = geyPaymentDisplayName(paymentMethod, cardNO);
    const splitStrings = returnString.split('**');
    const paymentName = splitStrings[0];
    let followUp = '';
    if (splitStrings.length > 1) {
      followUp = t('account_balance:card_ending', {
        data: `***${splitStrings[1]}`,
      });
    }
    return (
      <div className="flex items-center space-x-1">
        {iconType && iconType}
        <div>
          <div className="font-medium text-lg text-color-text-1">
            {paymentName}
          </div>
          {followUp && (
            <div className="text-sm text-color-text-2 mt-0.5">{followUp}</div>
          )}
        </div>
      </div>
    );
  };

  const getSettledAmount = () => {
    const tempFee = feeAmount as string;
    const total = new BigNumber(amount);
    const fee = tempFee.includes('-') ? tempFee : `-${tempFee}`;
    const settled = total.plus(new BigNumber(fee).toFormat(2, 1)).toString();
    return `${handleSymbolMount(settled)} ${currency}`;
  };

  const handleAmountStyle = () => {
    let style = '';
    if (currentPaymentType === IPaymentType.VIOD) {
      style = 'line-through text-color-text-2';
    } else if (currentPaymentType === IPaymentType.REFUND) {
      style = 'text-error-color';
    } else if (currentPaymentType === IPaymentType.PRE_AUTH) {
      style = 'text-color-text-2';
    }

    return style;
  };

  const handleViewDetails = (id: string) => {
    navigate(
      `/${lang}/businesses/${businessID}/account-balance/payments/${id}?from=${from}${dayEndId ? `&dayEndId=${dayEndId}` : ''
      }&isLinkage=true`
    );
  };

  return (
    <Transition.Root show={true} appear={true}>
      <div className="px-4 md:px-6 lg:px-8 py-6">
        <div className="flex mb-6 justify-between">
          <div className="flex">
            <div
              onClick={handleBack}
              className="bg-disabled-color shadow-box-drop rounded-full w-10 h-10 flex items-center justify-center cursor-pointer hover:text-primary-color"
            >
              <BackIcon />
            </div>
            <div className="text-3xl leading-10 font-bold text-color-text-1 ml-2.5 invisible sm:visible">
              {t('account_balance:transaction_details')}
            </div>
          </div>
        </div>
        <Transition.Child
          className="rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 h-fit"
          enter="duration-700 transform transition ease-in-out"
          enterFrom="-translate-x-1 translate-y-10 opacity-0"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="duration-500 transform transition"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="items-center md:flex">
            <div className="flex flex-col">
              <div className="flex space-x-1 items-center text-3xl">
                <span className={`${handleAmountStyle()} font-bold`}>
                  {/* <ScrollNum
                    value={`${handleSymbolMount(amount)}`}
                    size={36}
                    boxClass={'h-9'}
                  /> */}
                  {`${handleSymbolMount(
                    plus(amount, tipsAmount),
                    false,
                    currentPaymentType === 'pre_dispute' ? '' : undefined
                  )}`}
                </span>
                <span
                  className={`${currentPaymentType === IPaymentType.PRE_AUTH
                    ? 'text-color-text-2'
                    : 'text-color-text-1'
                    } text-color-text-1 font-medium`}
                >
                  {`${currency}`}
                </span>
              </div>
              <span className="text-color-text-2 text-sm mt-2">
                {t('account_balance:transacted_amount')}
              </span>
            </div>
            {currentPaymentType === IPaymentType.PRE_AUTH && (
              <div className="flex items-center max-w-[20rem] ml-8">
                <WarningIcon />
                <span className="text-sm font-medium ml-2.5">
                  {t('account_balance:pre_auth_tips')}
                </span>
              </div>
            )}
            <div className="w-0 mb-4 h-0 bg-border-color mx-10 md:w-px md:mb-0 md:h-14" />
            <div>
              <div className="flex flex-col md:flex-row whitespace-nowrap">
                {getPaymentMethod(consumer_identify)}
              </div>
            </div>
          </div>
        </Transition.Child>
        {currentPaymentType === IPaymentType.SALE && (
          <DefaultDetailsContent
            record={record}
            initLoading={initLoading}
            showType={handleType(paymentType, voidAt)}
            settledAmount={getSettledAmount()}
            viewDetails={handleViewDetails}
          />
        )}
        {currentPaymentType === IPaymentType.VIOD && (
          <VoidDetailsContent
            record={record}
            initLoading={initLoading}
            showType={handleType(paymentType, voidAt)}
            settledAmount={getSettledAmount()}
          />
        )}
        {currentPaymentType === IPaymentType.REFUND && (
          <RefundDetailsContent
            record={record}
            initLoading={initLoading}
            showType={handleType(paymentType, voidAt)}
            settledAmount={getSettledAmount()}
            viewDetails={handleViewDetails}
          />
        )}
        {[IPaymentType.PRE_AUTH, IPaymentType.PRE_AUTH_COMPLETE].includes(
          currentPaymentType
        ) && (
            <PreAuthDetailsContent
              record={record}
              initLoading={initLoading}
              type={currentPaymentType}
              showType={handleType(paymentType, voidAt)}
            />
          )}
        {!Object.values(IPaymentType).includes(currentPaymentType) && (
          <UnusualDetailsContent
            record={record}
            initLoading={initLoading}
            showType={handleType(paymentType, voidAt)}
            settledAmount={getSettledAmount()}
            viewDetails={handleViewDetails}
          />
        )}
      </div>
    </Transition.Root>
  );
};

export default PaymentDetailsPage;
