import { useMemo, useState } from 'react';
import SelectedIcon from '../../payouts-icons/selected-icon';
import { IPayout } from '@/types/payouts-type';
import { useTranslation } from 'react-i18next';
import { payoutStatusUpdate } from '@/data/payouts';
import { Alert } from '@/components/common/alert/alert';
import Button from '@/components/common/button';
import AntdModal from '@/components/common/antd/modal';
import Textarea from '@/components/common/textarea';
import { useParams } from 'react-router-dom';

interface ICancelPayoutProps {
  payout: IPayout;
  businessID: string;
  callback: () => void;
  onCancel: () => void;
}

const CancelPayout: React.FC<ICancelPayoutProps> = (props) => {
  const { payout, businessID, callback, onCancel } = props;
  const { t } = useTranslation('payouts');
  const { t: ct } = useTranslation('common');
  const { lang = 'en' } = useParams();
  const { id } = payout;

  const [reason, setReason] = useState('');
  const [type, setType] = useState(-1);
  const [remark, setRemark] = useState('');
  const [cancelLoading, setCancelLoading] = useState(false);

  const typeOptions = useMemo(() => {
    return [
      t('incorrectly_payout'),
      t('no_need_payout'),
      t('fee_high'),
      t('others'),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const lastIndex = typeOptions.length - 1;

  const handleCancel = async () => {
    setCancelLoading(true);
    if (await payoutStatusUpdate(businessID, id, 'Cancelled', '', remark)) {
      Alert.success({
        message: t('payout_cancelled'),
        position: 'default',
        wrapClass: 'top-px',
      });
      await callback();
    } else {
      Alert.error({
        message: t('payout_cancel_failed'),
        position: 'default',
        wrapClass: 'top-px',
      });
    }
    setCancelLoading(false);
  };

  return (
    <AntdModal open={true} footer={null} onCancel={onCancel}>
      <div className="text-color-text-2 font-medium py-10 px-6">
        <div className="text-center text-color-text-1 font-bold text-xl">
          {t('cancel_reason')}
        </div>
        <div className="text-center pt-2 text-sm">
          {t('cancel_payout_tip')}
        </div>
        <div className="py-8 flex flex-col gap-y-4">
          {typeOptions.map((item, index) => {
            return (
              <div
                key={index}
                className={`p-4 border text-color-text-1 text-sm rounded-xl ${
                  type === index
                    ? 'border-primary-color'
                    : 'border-primary-color/10 hover:bg-disabled-color cursor-pointer'
                }`}
                onClick={() => {
                  setType(index);
                  if (index !== lastIndex) {
                    setRemark(item);
                  }
                }}
              >
                <div className="flex justify-between">
                  <div>{item}</div>
                  {type === index && <SelectedIcon size={20} />}
                </div>
                {index === lastIndex && type === index && (
                  <>
                    <div className="text-color-text-2 text-sm mt-2">
                      {t('input_other_reason')}
                    </div>
                    <Textarea
                      warpClass="mt-1"
                      value={reason}
                      allowClear={false}
                      maxLength={300}
                      onChange={(value) => {
                        setReason(value);
                        setRemark(value);
                      }}
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>
        <Button
          className="w-full text-base"
          disabled={!remark}
          onClick={handleCancel}
          loading={cancelLoading}
        >
          {t('confirm')}
        </Button>
        <Button
          styleType={'Border'}
          className="w-full mt-4 text-base"
          onClick={onCancel}
        >
          {ct('cancel')}
        </Button>
      </div>
    </AntdModal>
  );
};

export default CancelPayout;
