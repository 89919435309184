import styles from '@/pages/content.module.css';
import { Link, useParams } from 'react-router-dom';

const RemittancePayoutTerms = () => {
  const { lang = 'en' } = useParams();

  return (
    <>
      {lang !== 'zh' && lang !== 'tw' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.payment}`}>
            <h3 className={`${styles.heading}`}> Remittance & Payout Terms</h3>
            <div className={`${styles.title}`}>1. THESE TERMS</div>
            <p><strong>1.1 Parties.</strong> By using the Bindo Labs Limited Platform Services you (‘<strong>Customer/you/your</strong>’) agree to these terms applying between you and Bindo Labs Limited (Hong Kong) Limited (‘<strong>Bindo Labs Limited/us/we</strong>’). Please read these terms carefully and contact us if anything is unclear.</p>
            <p><strong>1.2 Additional Documents.</strong> The following additional related documents (which are updated from time to time) also apply to your use of the Bindo Labs Limited Platform and Services and should be read together with these terms:</p>
            <ul>
              <li><p>Acceptable Use Policy</p></li>
              <li><p>Privacy and Cookies Policy</p></li>
              <li><p>Payout Guide</p></li>
              <li><p>API Documentation</p></li>
              <li><p>FAQs with respect to the use of the Services</p></li>
            </ul>
            <p>Where we provide you with other services, you should refer to the terms and conditions of those other services.</p>
            <p><strong>1.3 Access to these terms.</strong> You can always see the most current version of those documents, including these terms, on our website at <Link to={'https://www.bindolabs.com'} target="_blank" rel="noopener">www.bindolabs.com. </Link>You can also download a copy of these documents and keep it for future reference or ask us for a copy at any time.</p>
            <p><strong>1.4 Contacting us.</strong> If we need to send you information in a form you can keep, we will either send you an email or provide information on our website or via the Bindo Labs Limited Platform that you can download. Please keep copies of all communications we send to you.</p>

            <div className={`${styles.title}`}>2. WHO BINDO LABS LIMITED IS AND HOW TO CONTACT US</div>
            <p><strong>2.1 Our company information.</strong> The Services are offered by Bindo Labs Limited (Hong Kong) Limited, which has its office at Suites 05-181, 5/F The Quayside, 77 Hoi Bun Road, Kwun Tong, Kowloon, Hong Kong. Bindo Labs Limited is authorised by the Customs and Excise Department under the Anti-Money Laundering and Counter-Terrorist Financing Ordinance to provide remittance services and money changing services. We may provide the Services either directly or via one or more of our Group Companies or service providers.</p>
            <p><strong>2.2 How to contact us.</strong> You can contact us by:</p>
            <ul>
              <li><p>emailing us at: support@bindo.com;</p></li>
              <li><p>sending mail to us at: Suites 05-181, 5/F The Quayside, 77 Hoi Bun Road, Kwun Tong, Kowloon, Hong Kong.; or</p></li>
              <li><p>sending us a message through the contact links on our website <Link to={'https://www.bindolabs.com'} target="_blank" rel="noopener">www.bindolabs.com.</Link></p></li>
            </ul>
            <p><strong>2.3 How we will contact you.</strong> We will contact you using the contact details you provided when you registered – please keep these up to date. By using our Services, you agree to receive electronic communications from us. If we have reasonable concerns either about the security of your Bindo Labs Limited Profile, or any suspected or actual fraudulent use of your Bindo Labs Limited Profile or its associated accounts, we will contact you via telephone, email, or both (unless contacting you would be unlawful or compromise our reasonable security measures).</p>

            <div className={`${styles.title}`}>3. CHANGES TO THESE TERMS</div>
            <p><strong>3.1 General changes.</strong> We may change these terms by giving you fourteen (14) days' prior written notice. You are deemed to have accepted the proposed changes if you do not terminate these terms by giving us written notice during the notice period.</p>
            <p><strong>3.2 Urgent changes.</strong> We may also make some changes immediately, without prior notice, if they:</p>
            <ul>
              <li><p>are required by Applicable Law; or</p></li>
              <li><p>relate to the addition of a new service or extra functionality to our Services and do not affect any terms relating to the existing Services.</p></li>
            </ul>
            <p><strong>3.3 If you do not agree to changes.</strong> You should stop using our Services if you do not agree to any changes. If you keep using our Services after we have announced such a change, you are deemed to have accepted the change.</p>
            <p><strong>3.4 Modifications.</strong> We may modify the configuration, appearance or functionality of the Bindo Labs Limited Platform at any time to facilitate the continued and proper operation of the Bindo Labs Limited Platform or to comply with Applicable Law. To the extent reasonably practicable, Bindo Labs Limited will provide you with advance notice if the modification would materially affect your ability to use the Bindo Labs Limited Platform.</p>
            <p><strong>3.5 Updates.</strong> We may issue updates for the Bindo Labs Limited Platform, including the Bindo Labs Limited APIs, from time to time. Some updates may require you to take steps to implement them. In some cases (for example, if there are security risks), you will not be able to use the Bindo Labs Limited Platform until you have implemented the update. If you have not implemented an update within 6 months of us releasing it, we may stop providing some of the Services to you or terminate these terms, by giving you fourteen (14) days’ prior written notice.</p>

            <div className={`${styles.title}`}>4. IDENTITY CHECKS & INFORMATION REQUESTS</div>
            <p><strong>4.1 Identity checks.</strong> In accordance with Applicable Law, before and from time to time, during our provision of Services to you, we will carry out customer due diligence checks on you, your directors, partners, ultimate beneficial owners, and employees (as relevant), together with any parties involved in your transactions. You must provide us with complete, accurate, and up to date information at all times, including providing personal information about your directors, partners, ultimate beneficial owners, and employees (as relevant).</p>
            <p><strong>4.2 Information requests.</strong> You will promptly provide any additional information we require at any time, at the latest within 2 Business Days of a request from us. For example, we may request information to confirm the authenticity of certain transactions. You acknowledge that we may not provide you with any of our Services until we have received all the information we require. We will not be responsible for any loss you may suffer or incur arising out of your failure to do so.</p>
            <p><strong>4.3 Third party verification.</strong> You agree that we may make, directly or using a third party, any inquiries we believe are necessary to verify information you provide to us, including checking commercial databases or credit reports. We may keep records of such customer due diligence checks in accordance with Applicable Law.</p>
            <p><strong>4.4 Credit assessments.</strong> You agree that our provision of, and your ability to use, the Services is subject to:</p>
            <ul>
              <li><p>successful completion of the initial, and any subsequent, credit evaluations; and</p></li>
              <li><p>there being no material changes to your credit status during the term of these terms.</p></li>
            </ul>
            <p><strong>4.5 Acting on own account.</strong> You confirm you a re acting on your own account, not on behalf of any other person or legal entity. If you are entering into these terms as trustee of a trust, you must disclose that to us. We will deem any use of the Bindo Labs Limited Profile and associated accounts, including any transfers into and out of those accounts, to have been carried out by you.</p>

            <div className={`${styles.title}`}>5. ABOUT OUR SERVICES</div>
            <p><strong>5.1 Bindo Labs Limited Profile and Platform.</strong> Once you have successfully registered with
              us, an Bindo Labs Limited Profile will be created for you. You can only have one Bindo
              Labs Limited Profile, unless we agree otherwise in writing. If we discover that you have
              more than Bindo Labs Limited Profile, at our discretion, we may merge or close any
              duplicate profiles, or terminate our Services to you, if we reasonably believe you have
              opened them in order to bypass any restrictions in the Services. You can use your Bindo
              Labs Limited Profile to log into and gain access to the Bindo Labs Limited Platform. The
              Bindo Labs Limited Platform provides you with an easy to use user interface to access and
              use our various Services.</p>
            <p><strong>5.2 Services overview.</strong> Our Services provided under these terms include:</p>
            <ul>
              <li><p>Collection Services – where Bindo Labs Limited receives funds in Supported Currency for you;</p></li>
              <li><p>Payment Services – where you can send Payments to payees; and</p></li>
              <li><p>FX Conversion Services – where you can convert funds from one Supported Currency to another Supported Currency.</p></li>
            </ul>
            <p>Each of these is a separate Service provided by Bindo Labs Limited.</p>
            <p><strong>5.3 Collection Services.</strong> In connection with the Collection Services, we will provide you with local bank account credentials in Supported Currency which you may provide your payers to send funds to you. The funds collected will be recorded in the Global Account, in each Supported Currency, that we set up for you. You may, at any time, withdraw funds to your own same name account by giving the relevant withdrawal instructions to Bindo Labs Limited. The funds collected for you must not stay in the Global Account for longer than the maximum period allowed under clause 8.4.</p>
            <p><strong>5.4 Payment Services and FX Conversions.</strong> In connection with the Payment Services and/or FX Conversion Services, Bindo Labs Limited will set up an Intermediary Account into which you may send funds to pay for your Payments and/or FX Conversions instructions. Bindo Labs Limited will, in turn, based on the specific instruction you give us at the time you send the funds to the Intermediary Account, remit those funds in a Supported Currency to your designated payee, and/or convert those funds from source currency to the buy currency.</p>
            <p><strong>5.5 Transaction records.</strong> Your transaction records will be recorded in the Bindo Labs Limited Profile which will display records of funds collected through the Global Account as part of the Collection Services, and funds paid into the Intermediary Account as part of the Payment Services and you r FX Conversions.</p>
            <p><strong>5.6 Deposit protection scheme. The Hong Kong Deposit Protection Scheme (‘DPS’) does not apply to the funds sent, exchanged or received in connection with the Services. You acknowledge and agree that we are not a bank and do not provide you with a bank account, and so our Services are not covered by the DPS. You further agree that the Global Account is a ‘virtual account’ and is not a bank account, that the funds transferred to us are beneficially ours and will not be held on trust for you, and that you will not earn interest or be entitled to any interest on any funds.</strong></p>
            <p><strong>5.7 Keeping your funds safe.</strong> To keep your funds safe, Bindo Labs Limited has implemented commercially reasonable administrative and technical measures to protect and safeguard the funds collected for or received from you in connection with the Services.</p>
            <p><strong>5.8 Restrictions.</strong> We may place interim or permanent restrictions on the use of all or any part of the Services depending on certain regulatory requirements, your country of residence, identity verification checks or business requirements, all of which are set out in the Acceptable Use Policy or any Additional Terms. These will be communicated to you at the time the restriction is put in place or, if that is not practical due to urgent or unforeseen circumstances, promptly after the restriction is put in place.</p>
            <p><strong>5.9 Your relationship with us.</strong> We act as a service provider to you under these terms. Except as otherwise expressly stated herein, Bindo Labs Limited does not act as a trustee, an escrow agent or stakeholder for you or your payer/payee. To the extent you operate a business, Bindo Labs Limited assumes no liability to or in respect of any products, services or any other part of your business.</p>

            <div className={`${styles.title}`}>6. GETTING STARTED</div>
            <p><strong>6.1 Creating your Bindo Labs Limited Profile.</strong> In order to use our Services, you must provide the necessary information as prompted through the Bindo Labs Limited Platform to register and create your Bindo Labs Limited Profile. If required by Bindo Labs Limited, you must provide your bank account details to be linked to your Bindo Labs Limited Profile.</p>
            <p><strong>6.2 Creating a Global Account.</strong> Once we have created your Bindo Labs Limited Profile in the Wonder Application, you can submit a request to open a Global Account by logging into the Bindo Labs Limited Platform and providing the necessary information, including any additional information that we may require from time to time. You will also need to agree to any Additional Terms. We will advise you of this requirement, if relevant, when you request to open a Global Account.</p>
            <p><strong>6.3 Setting up Bindo Labs Limited APIs.</strong> You may access the Bindo Labs Limited Platform through Bindo Labs Limited APIs as well as the Webapp. However, in order to use our Services through the Bindo Labs Limited APIs, you must first be approved by us. If you are approved by us, you will need to comply with our API Documentation and any reasonable directions regarding the integration and use of the Bindo Labs Limited APIs.</p>
            <p><strong>6.4 Information verification and Testing.</strong> We will only create your Bindo Labs Limited Profile and allow you to use our Services if we are satisfied with the information you have provided, and any subsequent information we have reasonably requested from time to time, and we have been able to verify this information to our satisfaction. Prior to us accepting you as a customer, we may create a Global Account and provide you access to the Bindo Labs Limited Platform (whether through Bindo Labs Limited APIs or Webapp) for testing purposes. In addition, we may ask you to transmit a small amount to the Global Account for testing or verification purposes. For the avoidance of doubt, you are not deemed to be our customer and you will not be able to use any of our Services unless and until we confirm that you havebeen accepted as our customer, and any amounts you provide to us for testing or verification purposes will be refunded to you in full if we do not accept you as a customer.</p>

            <div className={`${styles.title}`}>7. AUTHORISED USERS</div>
            <p><strong>7.1 Authorised User.</strong> You may appoint an Authorised User to act on your behalf to enter into and confirm FX Conversions and Payments under these terms. You must set up each Authorised User with a User Profile and promptly provide us with the following details of any of your proposed Authorised Users: Customer name, name of Authorised User, Customer address and billing address, phone and email address of Authorised User, and any other contact or identification information of the Authorised User that we may reasonably require. You will ensure that your Authorised Users comply with these terms and in respect of your obligations and liabilities under these terms, references to ‘you’ shall (where the context requires) be read as including your Authorised Users.</p>
            <p><strong>7.2 Instructions.</strong> We will receive instructions from your Authorised User(s) through the Bindo Labs Limited Platform via the Webapp or Bindo Labs Limited APIs or, under prior arrangements agreed with us, manually via a representative of Bindo Labs Limited.</p>
            <p><strong>7.3 Acknowledgement.</strong> You agree that:</p>
            <ul>
              <li><p>your Authorised Users have the authority to provide instructions for the provision of Services to us on your behalf;</p></li>
              <li><p>we will rely on the authority of the Authorised User, and you will be bound by the actions of your Authorised Users, until you provide us with written notice withdrawing or otherwise varying the authority of an Authorised User;</p></li>
              <li><p>you are responsible for ensuring that the appropriate person(s) are accorded the necessary authority to act as your Authorised Users;</p></li>
              <li><p>we may refuse access to your Authorised User(s) if we are concerned about unauthorised or fraudulent access; and</p></li>
              <li><p>you will promptly report to us any infringements or unauthorised access to the Bindo Labs Limited Platform, including the Webapp or Bindo Labs Limited APIs.</p></li>
            </ul>

            <div className={`${styles.title}`}>8. COLLECTION SERVICES</div>
            <p><strong>8.1 Collection of funds through Bindo Labs Limited.</strong> If we have approved a Supported
              Currency for you, you may receive funds in that Supported Currency through Bindo Labs
              Limited in accordance with these  terms. Each collection is an individual contract
              between you and Bindo Labs Limited.</p>
            <p><strong>8.2 Your right to withdraw collected funds.</strong> Bindo Labs Limited will collect and hold the collected funds in one or more underlying bank accounts in its name for the designated purpose of providing the Collection Services. The funds in any underlying bank account will be comingled and pooled together with those of other users of Services offered by Bindo Labs Limited. You are entitled to be paid the funds collected for you as part of the Collection Services provided to you. Bindo Labs Limited must pay to you such funds when you request a withdrawal, subject to the terms of these terms.</p>
            <p><strong>8.3 Recording receipt of funds.</strong> We will update your Global Account only when we receive the funds. We are not responsible for any funds transferred and will not record them as available in the Global Account, until after we have actually received the funds. The transfer of funds from your payers to Bindo Labs Limited’s designated underlying account is a service provided by third parties and is not part of our Services. We have no control over the time it may take for the transfer of such funds to clear and settle.</p>
            <p><strong>8.4 Withdrawals from the Global Account.</strong> You must promptly instruct us to make a withdrawal with respect to funds received in the Global Account. If you do not give us withdrawal instructions within a reasonable period of time as determined by us and, in any event within a maximum period of no longer than 90 days, we will, at our sole
              discretion: (a) return the funds to the sender; (b) remit the funds to your linked bank
              account as notified to us; or (c) remit the funds to you in any other way permitted by
              Applicable Law.</p>
            <p><strong>8.5 Source of funds.</strong> Unless we agree otherwise in writing, you must ensure that all funds we receive in connection with the Services come from a legitimate source (for example, proceeds from the sale of goods or services) and otherwise comply with our Acceptable Use Policy. You agree not to transfer (for example, top-up) any of your own funds into the Global Account at any time. If we ask you to, you agree to promptly provide evidence of the funding source.</p>
            <p><strong>8.6 Restriction on depositing funds into the Global Account.</strong> The Global Account is used to provide you with Collection Services only. You are not permitted to transfer your own funds into the Global Account.</p>
            <p><strong>8.7 Acting as limited agent.</strong> You represent and warrant to Bindo Labs Limited that each payment you receive through the Collection Services is solely in payment for your provision of bona fide goods and/or services to your customers (each, a ‘<strong>Payor</strong>’). For Collection Services in the United States, you hereby designate Bindo Labs Limited, and Bindo Labs Limited hereby agrees to serve, as your limited agent for the sole purpose of receiving such payments on your behalf from your Payors. You agree that upon Bindo Labs Limited receiving payment from a Payor: (a) you shall be deemed to have received payment
              from such Payor, (b) such Payor’s obligation to you in connection with such payment shall
              be satisfied in full, (c) any claim you have for such payment against such Payor shall be
              extinguished and (d) you are obligated to deliver the applicable goods and/or services to
              the Payor (if not already delivered), in each case regardless of whether or when Bindo
              Labs Limited remits such payment to you. Bindo Labs Limited will remit to you in
              accordance with these terms, or apply as an offset to any obligation you may have to
              Bindo Labs Limited, any such payments it receives on your behalf. You shall identify to
              your Payors that Bindo Labs Limited is acting as your agent for purposes of receiving
              payment on your behalf. Any receipt provided to the Payor shall be binding on you and
              shall satisfy all applicable regulatory requirements. This clause 8.7 states the entirety
              of Bindo Labs Limited’s duties as your agent for receipt of payment under the Collection
              Services in the United States, and no other duties shall be implied by Bindo Labs
              Limited’s undertaking to act in that capacity.</p>

            <div className={`${styles.title}`}>9. FX CONVERSION SERVICES</div>
            <p><strong>9.1 Your instructions for FX Conversions.</strong> You may instruct us to make a FX Conversion by providing the necessary details as prompted in the Bindo Labs Limited Platform.</p>
            <p><strong>9.2 Confirmation for FX Conversions.</strong> When we are satisfied your instructions are complete, we will issue you with a Confirmation with respect to the FX Conversion. We may decline your instructions if you do not have sufficient funds by the Settlement Cut-Off, you exceed any applicable limits, you request a FX Conversion which we deem to be a leveraged foreign exchange transaction, or in accordance with clause 19. Once a Confirmation is issued, the FX Conversion cannot be cancelled by you and is considered final. You must then settle with us the full amount of the FX Conversion in the relevant source currency in accordance with the terms of these terms.</p>
            <p><strong>9.3 FX Exchange Rate.</strong> You may access the indicative FX Exchange Rate for a FX Conversion through the Bindo Labs Limited Platform. The indicative FX Exchange Rate will be quoted to you when you instruct us through the Bindo Labs Limited Platform and the final FX Exchange Rate will be confirmed when we issue a Confirmation. We will ensure, as far as reasonably practicable, that the confirmed FX Exchange Rate reflects the rate quoted to you but you acknowledge that this is not always possible. The final FX Exchange Rate used to effect the FX Conversion may be different as the rate may have changed between the time of your instruction and the time we issue the Confirmation. You agree that changes to exchange rates come into effect immediately without notice.</p>
            <p><strong>9.4 Settlement requirements for FX Conversion.</strong> You must pay sufficient funds into the Intermediary Account in the relevant source currency to cover the full amount of any FX Conversion you wish to make on or before the Settlement Cut-Off. You may fund the FX Conversion by direct bank transfer to us or by other means acceptable to us. Without prejudice to any of our rights under these terms, you specifically acknowledge that if you have not paid sufficient funds into the Intermediary Account to cover the full amount, we may deduct or set-off such amount against funds collected and held for you.</p>
            <p><strong>9.5 Execution time for FX Conversions.</strong> If you ask us to make a FX Conversion, we will do so as soon as reasonably practicable and, in any event, generally within 24 hours after we accept your instructions.</p>
            <p><strong>9.6 Deposit for FX Conversions.</strong> You shall pay to us on demand a deposit for an FX Conversion transaction in the amount as we may require from time to time. For the avoidance of doubt, we have the right to require you to pay additional amounts to increase the amount of the deposit at any time prior to the final settlement of such FX Conversion transaction. You shall be obliged to deliver such amounts to the account designated by us within two (2) Business Days of our demand. If you fail to pay any deposit required by us in respect of any FX Conversion transaction, we may cancel the FX Conversion transaction without prior notice to you and apply the deposit to the payment of any amounts due to us.</p>

            <div className={`${styles.title}`}>10. PAYMENT SERVICES</div>
            <p><strong>10.1 Your instructions for Payments.</strong> You may instruct us to make a Payment by providing the necessary details as prompted in the Bindo Labs Limited Platform. If a Payment involves currency conversions between Supported Currencies (e.g. the source currency is different from the payout currency), we will also provide you with our FX Conversion Services as an ancillary service to the Payment Services. Each Payment, including any Payment with FX Conversion, is an individual contract between you and Bindo Labs Limited.</p>
            <p><strong>10.2 Confirmation of Payments.</strong> When we are satisfied your instructions are complete, we will issue you with a Confirmation with respect to the Payment. We may decline your instructions if you do not have sufficient funds by the Settlement Cut-Off, you exceed any applicable limits, or in accordance with clause 19. Once a Confirmation is issued, the Payment cannot be cancelled by you and is considered final. You must then settle with us the full amount of the in your Payment in accordance with the terms of these terms.</p>
            <p><strong>10.3 Settlement requirements for Payment Services.</strong> You must pay sufficient funds into the Intermediary Account in the relevant source currency to cover the full amount of any Payment you wish to make on or before the Settlement Cut-Off. You may pay us by way of direct bank transfer to us or by other means acceptable to us. Without prejudice to any of our rights under these terms, you specifically acknowledge that if you do not have sufficient funds in your Intermediary Account to cover the full amount, we may deduct or set-off such amount against any funds collected and held for you in your Global Account.
              If you transfer funds into the Intermediary Account in a currency which is not the
              Payment currency or if the funds held in your Global Account (which we use for the
              purposes of set-off) are not in the Payment currency, you authorise us to, on the date of
              Payment, convert such currency into the Payment currency at our standard exchange rates
              which are available upon request.</p>
            <p><strong>10.4 Execution time for Payments.</strong> If you ask us to make a Payment, we will make the Payment as soon as reasonably practicable and, in any event, generally within 24 hours after we accept your instructions. The methods of crediting the funds may be provided by third parties (for example, the bank where the payee has their bank account) and are not part of our Services. We do not have any control over the amount of time it may take for a payee’s bank or payment provider to credit funds to the payee, but would generally expect this to take less than 5 Business Days, unless a longer period is required by us to comply with Applicable Law.</p>


            <div className={`${styles.title}`}>11. REVERSALS AND REJECTIONS</div>
            <p><strong>11.1 Reversals.</strong> We may need to reverse any funds sent or received in connection with the Services, and either deduct funds we have received as part of the Services or return funds we have received as part of the Services, including in the following circumstances:</p>
            <ul>
              <li><p>the sender, or any relevant payment services provider, reverses a transaction or is likely to reverse a transaction;</p></li>
              <li><p>the sender made a transfer to you by mistake;</p></li>
              <li><p>you have provided us with incorrect instructions for the transaction;</p></li>
              <li><p>we suspect that a transaction is fraudulent, or is in breach of these terms or Applicable Law; or</p></li>
              <li><p>we exercise our rights under clause 19.</p></li>
            </ul>
            <p>You must reimburse us as soon as possible, as set out in clause 12.5. If we reverse a transaction, we will, in our sole discretion, do this at the prevailing FX Exchange Rate applicable at the time of the reversal or at the original FX Exchange Rate applicable to the transaction.</p>
            <p><strong>11.2 Rejected Transactions.</strong> We may withhold, reject, or delay any funds received, exchanged or sent in the following circumstances:</p>
            <ul>
              <li><p>no valid exchange rates are available from our correspondent banking partners, liquidity providers, or other financial institutions;</p></li>
              <li><p>any relevant anti-money laundering or counter financing of terrorism limits are exceeded;</p></li>
              <li><p>you have not complied with your obligations under these terms, the Acceptable Use Policy or under Applicable Law; or</p></li>
              <li><p>we exercise our rights under clause 19.</p></li>
            </ul>
            <p><strong>11.3 Failed Transactions.</strong> We may, in our sole discretion and without liability to you, cancel any FX Conversion or Payment, or delay any FX Conversion or Payment by rolling it over to the next Conversion Date or Payment Date (as applicable), where we need to do so due to reasons outside of our reasonable control, such as where there are insufficient funds.</p>
            <p><strong>11.4 Insufficient funds.</strong> Where there are insufficient funds for one or more FX Conversions and/or Payments at Settlement Cut-Off, Bindo Labs Limited reserves the right to make the FX Conversion or Payment at its discretion (for example, on a time basis, converting the earliest booked FX Conversion or Payment up to the value of the funds available to Bindo Labs Limited at the applicable Conversion Date or Payment Date). The remaining booked FX Conversions will be treated as failed transactions, and will incur the relevant break costs and administration fees.</p>
            <p><strong>11.5 Transfers using correct details.</strong> In each case, you will need to ensure the funds are transferred to us using the correct bank details and any unique transaction reference number details specified by Bindo Labs Limited. If you do not provide accurate details, then we may not be able to receive the funds and record the amount in the relevant account. In such cases, we will not be liable for any loss you may suffer or incur, although we will use reasonable efforts to investigate and credit or return incorrect and inaccurately referenced payments into or from your account.</p>
            <p><strong>11.6 Excess funds.</strong> When you pay into the Intermediary Account, you must give us an accurate and complete instruction in relation to a Payment or FX Conversion. If you do not give us an accurate or complete instruction we may, at our sole discretion: (a) return the funds to the sender; (b) remit the funds to your linked bank account as notified to us; or (c) remit the funds to you in any other way, subject to any Applicable Law.</p>


            <div className={`${styles.title}`}>12. SAFETY AND SECURITY</div>
            <p><strong>12.1 Keep your Bindo Labs Limited Profile safe.</strong> In order to use the Bindo Labs Limited Platform, you (or your Authorised User) must log in using the unique password and any multiple-factor authentication: for example, we may require your Authorised User to authenticate that they are the Authorised User when logging in to the Bindo Labs Limited Platform, or for certain transactions made using the Bindo Labs Limited Platform. You must store all log-in information and passwords to access the Bindo Labs Limited Platform safely and securely at all times and only allow Authorised Users to access the Services.</p>
            <p><strong>12.2 Contact us if you suspect your Bindo Labs Limited Profile may be compromised.</strong> You must contact us immediately by email to <Link to={'mailto:support@bindo.com'} target="_blank" rel="noopener">support@bindo.com</Link> and change your password if you suspect:</p>
            <ul>
              <li><p>your Bindo Labs Limited Profile, or access to the Bindo Labs Limited Platform or other security credentials are stolen, lost, used without your authorisation or otherwise compromised; or</p></li>
              <li><p>someone else finds out your log-in information and password for your Bindo Labs Limited Profile or User Profile.</p></li>
            </ul>
            <p>Any undue delay in notifying us may affect the security of your Bindo Labs Limited
              Profile, or access to the Bindo Labs Limited Platform and may result in you being
              responsible for any financial losses.</p>
            <p><strong>12.3 Check the transactions in your accounts regularly.</strong> All of your activity is displayed in the Bindo Labs Limited Platform. You must contact us immediately by email to <Link to={'mailto:support@bindo.com'} target="_blank" rel="noopener">support@bindo.com</Link> to report any suspected or actual unauthorised transaction or other security concerns regarding the Bindo Labs Limited Platform. We may be able to refund money you have lost if the transaction is due to our mistake, fraud, or errors with your transaction (see clause 12.4). If you do not notify us of any unauthorised, or incorrectly initiated or executed transactions immediately, you may lose the right to have the matter corrected or money refunded. You also may not be able to claim a refund if:</p>
            <ul>
              <li><p>you have breached these terms or any Applicable Law;</p></li>
              <li><p>you gave us incorrect instructions for the transaction (in which case, we may reasonably assist you to recover the funds, where possible, but we do not guarantee that this would be successful); or</p></li>
              <li><p>any losses are not the direct result of our act or omission.</p></li>
            </ul>
            <p><strong>12.4 Unauthorised transactions.</strong> We are not liable for unauthorised transactions that are caused outside of the direct control of Bindo Labs Limited. In particular, but without limitation, we are not liable (and you will be solely liable for all losses):</p>
            <ul>
              <li><p>if you do not promptly notify us of security issues;</p></li>
              <li><p>if we find that you or your Authorised Users, or anyone else acting on your behalf, has acted fraudulently or recklessly (and we will not refund you in any circumstances);</p></li>
              <li><p>if you have with intent or gross negligence compromised the security of your Bindo Labs Limited Profile or the Bindo Labs Limited Platform or failed to comply with your obligations to use them in accordance with these terms, and to keep all log-in information and passwords confidential and secure;</p></li>
              <li><p>if you gave us incorrect instructions for the Payment (we may reasonably assist you to recover the funds, where possible, but we do not guarantee that this would be successful); and</p></li>
              <li><p>in any other circumstance outside of our direct control to the extent permitted by Applicable Law.</p></li>
            </ul>
            <p><strong>12.5 Reimbursements.</strong> Where we refund you for an unauthorised transaction but subsequently find that you did not comply with your obligations under this clause 12, you must reimburse us for the value of the unauthorised transaction.</p>
            <p><strong>12.6 Acknowledgements.</strong> You acknowledge that:</p>
            <ul>
              <li><p>Bindo Labs Limited will not be responsible for or otherwise guarantee the performance of any transaction you enter into via the Bindo Labs Limited Platform;</p></li>
              <li><p>you must obtain and use any equipment or telecommunications lines and connections that may be necessary for you to use or connect with the Bindo Labs Limited Platform;</p></li>
              <li><p>certain software and equipment you use may not be capable of supporting certain features of the Bindo Labs Limited Platform; and</p></li>
              <li><p>it is your responsibility to configure and update your information technology, software and equipment in order to access the Services or the Bindo Labs Limited Platform.</p></li>
            </ul>
            <p><strong>12.7 Your liability.</strong> You will be liable for all costs, charges, claims, damages, fees, disbursements, losses, expenses, fines, and liability suffered or incurred by Bindo Labs Limited arising out of or in connection with: (i) (where applicable) your incorrect instructions, overpayment, payment error, or other invalid payment you cause; (ii) any error, default, negligence, misconduct, or fraud by you, your employees, directors, officers, or representatives, or anyone acting on your behalf; and (iii) any costs, charges, claims, damages, fees, disbursements, losses, expenses, fines and liability suffered or incurred by Bindo Labs Limited in respect of a failure by you to comply with these terms, including but not limited to, this clause 12.</p>

            <div className={`${styles.title}`}>13. FEES & CHARGES</div>
            <p><strong>13.1 Service Fees.</strong> You will pay us the Service Fees for the Services, which are set out in the fees table available on www.Bindo Labs Limited.com or as otherwise agreed in writing with us. The Service Fees are charged separately depending on the Service you use, for example:</p>
            <ul>
              <li><p><strong>Collection Services:</strong> Service Fees for Collection Services generally involve a set percentage fee on funds collected for you.</p></li>
              <li><p><strong>Payment Services:</strong> Service Fees for Payment Services generally involve a fee depending on whether you are paying using local channels or SWIFT payment channels.</p></li>
              <li><p><strong>FX Conversion Services.</strong> Service Fees for FX Conversion Services generally involve a Customer Margin charged on top of the FX Base Rate.</p></li>
            </ul>
            <p><strong>13.2 Changes to Service Fees.</strong> Unless we agree otherwise in writing, we may revise the Service Fees at any time and the revisions will take effect from the date we notify you that they will apply.</p>
            <p><strong>13.3 Tax.</strong> All amounts payable to us under these terms:</p>
            <ul>
              <li><p>are exclusive of all taxes and similar fees now in force or enacted in the future, all of which you will be responsible for and will pay in full;</p></li>
              <li><p>will be paid in full without any set-off, counterclaim, deduction or withholding
                unless prohibited by Applicable Law. If you are obliged by Applicable Law to deduct
                withholding tax from any payment: (i) you will promptly notify Bindo Labs Limited of
                the requirement; (ii) the Parties will make all necessary filings in order to ensure
                the provisions of any applicable tax treaty applies to the payment; (iii) you will
                pay to Bindo Labs Limited such additional amount as will result in the receipt by
                Bindo Labs Limited of the full amount which would otherwise have been receivable had
                no withholding or deduction been payable; (iv) you will pay to the relevant
                authorities the full amount required to be deducted or withheld when due; and (v) you
                will promptly forward to Bindo Labs Limited an official receipt (or a certified
                copy), or other documentation reasonably acceptable to Bindo Labs Limited, evidencing
                payment to such authorities.</p></li>
            </ul>
            <p><strong>13.4 Obligation to pay amounts owing.</strong> You must immediately pay any amount owing to us, including as a result of any reversals, returns, fees, charges or other transactions, and we may suspend your use of the Services and access to any associated accounts until we receive such payment.</p>
            <p><strong>13.5 Authority to deduct amounts you owe us.</strong> We may deduct any amounts that you owe us from time to time from the funds we collect or receive as part of the Services or from any amount provided as security pursuant to clause 13.6 of these terms. If you do not have enough funds to pay the amount you owe us, we may, at your cost, take reasonable steps to recover this amount (such as taking legal action or using debt collection services).</p>
            <p><strong>13.6 Security.</strong> We may, at any time, require that you procure, within 30 days (or such longer period as we may determine is reasonable) after receiving our written request, a guarantee, indemnity or other security (including the replacement of any existing security) in such form and over such assets as we may reasonably require to secure to our reasonable satisfaction the performance of your obligations (including contingent or potential obligations) from time to time under these terms</p>


            <div className={`${styles.title}`}>14. INTELLECTUAL PROPERTY RIGHTS</div>
            <p><strong>14.1 Licence.</strong> We grant you a personal, revocable, non-transferable, non-sublicensable and non- exclusive licence to access and use the Bindo Labs Limited Platform via any supported web browser for the management of our Services, provided you comply with these terms.</p>
            <p><strong>14.2 Ownership.</strong> We own all Intellectual Property Rights in and to the Bindo Labs Limited Platform and its proprietary technology, including its software (in source and object forms), algorithms, user interface designs, architecture, and documentation (both printed and electronic), and network designs, and including any modifications, improvements, and derivative work thereof. These terms does not transfer from Bindo Labs Limited to you any ownership rights in the Bindo Labs Limited Platform, and its proprietary technology.</p>
            <p><strong>14.3 Restriction on use.</strong> You do not have any right to our Services, including the Bindo Labs Limited Platform, other than the right to use them in accordance with the license granted in clause</p>
            <ul>
              <li><p>Except as allowed by Applicable Law or by Bindo Labs Limited, you cannot use,
                distribute, reproduce, modify, copy, adapt, translate, create derivative works from,
                transfer, loan, rent, sublicense, sell, frame or otherwise re-publish or
                redistribute, publicly perform or publicly display any part of our Bindo Labs Limited
                Platform, Services, or included software. You will not allow any unauthorised person
                to access or use the Bindo Labs Limited Platform, or trade on the Bindo Labs Limited
                Platform for speculative purposes. Except as expressly permitted by Applicable Law
                (but not otherwise), you also cannot reverse engineer, decompile, disassemble or
                attempt to extract the source code of the Bindo Labs Limited Platform or software.</p></li>
            </ul>
            <p><strong>14.4 Using your Data.</strong> You grant Bindo Labs Limited a royalty-free, non-exclusive, irrevocable, transferable and sub-licensable licence to any Group Company, third party supplier and outsourcer, to use your Customer Data and Payment Data, for the purposes of operating the Bindo Labs Limited Platform, providing the Services, and fulfilling Bindo Labs Limited’s rights and discharging its obligations under these terms. You are solely responsible for the quality, accuracy, and completeness of any Customer Data and Payment Data transmitted via the Bindo Labs Limited Platform.</p>
            <p><strong>14.5 Other people’s rights.</strong> You will not use our Services, including the Bindo Labs Limited Platform, in any way that infringes or violates our or anyone else’s copyright, trade mark or other intellectual property rights or otherwise breaks any Applicable Law. The Bindo Labs Limited Platform may display content that we do not own, and we are not responsible for. You may not use content from any of our Services, including the Bindo Labs Limited Platform, unless you get permission beforehand from us or the owner of the content, or you are permitted by law.</p>
            <p><strong>14.6 Bindo Labs Limited compensation.</strong> If a third party alleges that the authorised use of the Bindo Labs Limited Platform as permitted by these terms infringes any Intellectual Property Rights, then Bindo Labs Limited will:</p>
            <ul>
              <li><p>at its own expense defend you or, at Bindo Labs Limited’s option, settle any claim or
                action brought against you (‘<strong>Claim</strong>’) and will be responsible for any reasonable
                losses, damages, costs (including reasonable legal fees) and expenses incurred by or
                awarded against you as a result of or in connection with any such Claim, and clauses
                18.2 to 18.4 will apply accordingly; and</p></li>
              <li><p>if the quiet enjoyment of the Bindo Labs Limited Platform is prevented, Bindo Labs
                Limited will as soon as reasonably practicable secure the right for you to continue
                using the Bindo Labs Limited Platform or replace or modify the Bindo Labs Limited
                Platform to make it non-infringing (without degrading its performance or quality).</p></li>
            </ul>
            <p><strong>14.7 Exclusions.</strong> Clause 14.6 will not apply where the Claim is attributable to:</p>
            <ul>
              <li><p>any Customer Data;</p></li>
              <li><p>any modification of the Bindo Labs Limited Platform, other than by or on behalf of Bindo Labs Limited;</p></li>
              <li><p>your possession or use of the Bindo Labs Limited Platform (or any part of it): (i) other than in accordance with these terms; or (ii) in combination with any other products, services, or materials if the Bindo Labs Limited Platform would not be infringing without such combination;</p></li>
              <li><p>use of a non-current release of the Bindo Labs Limited Platform; or</p></li>
              <li><p>compliance with your specifications or instructions.</p></li>
            </ul>
            <p><strong>14.8 Promotion.</strong> You agree that we may include and use your company name, logos, trade name, trademarks and general business information in our promotional and marketing materials for our Services and on our website. You may at any time and upon reasonable notice request in writing that we stop using your company name, logos, trade name, trademarks and general business information for these purposes.</p>

            <div className={`${styles.title}`}>15. CONFIDENTIALITY, PRIVACY AND USE OF DATA</div>
            <p><strong>15.1 Confidential Information.</strong> You acknowledge that you may have access to Confidential Information belonging to us and that you must keep such information confidential in accordance with the terms of these terms.</p>
            <p><strong>15.2 Confidentiality Undertakings.</strong> You agree that you:</p>
            <ul>
              <li><p>will not disclose any of our Confidential Information to any third party except as
                required: (i) by law or any authority of competent jurisdiction; (ii) to your
                attorneys, accountants and other advisors as reasonably necessary; or (iii) for the
                purposes of defending itself in relation to actual or threatened proceedings,
                provided that in respect of (i) and (iii) above, you will give us reasonable notice
                in advance of such required disclosure, together with such details as we may request
                (where notice to us is permissible under Applicable Laws); and</p></li>
              <li><p>will take reasonable precautions to protect the confidentiality of such information,
                at least as stringent as those taken to protect its own Confidential Information.</p></li>
            </ul>
            <p><strong>15.3 Disclosures to employees and agents.</strong> In addition, you may only reveal our Confidential Information only to your agents, representatives and employees who have a ‘need to know’ such information in connection with these terms, and are informed of the confidential nature of such Confidential Information and agree to act in accordance with the terms and conditions of this clause 15. You will remain liable for any disclosure of Confidential Information by your agents, representatives and employees as if you had made such disclosure.</p>

            <p><strong>15.4 Privacy and Personal Data.</strong> The protection of Personal Data is very important to us. In addition to these terms, our Privacy Policy explains how and for what purposes we collect, use, retain, disclose, and safeguard the Personal Data you provide to us. You agree to review the terms of our Privacy Policy, which we update from time to time.</p>
            <p><strong>15.5 Purposes.</strong> You agree to share Personal Data with Bindo Labs Limited and authorise Bindo Labs Limited to collect, use, store or otherwise process such Personal Data for the following ‘<strong>Agreed Purposes</strong>’, to enable us to:</p>
            <ul>
              <li><p>assist in providing information to you about a product or service;</p></li>
              <li><p>consider your request for a product or service;</p></li>
              <li><p>enable us to provide a product or service;</p></li>
              <li><p>manage Bindo Labs Limited’s relationship with the Customer;</p></li>
              <li><p>manage accounts and perform other administrative and operational tasks (including risk</p></li>
              <li><p>management, systems development and testing, credit scoring, staff training, collecting debts and market or customer satisfaction research);</p></li>
              <li><p>design new products and services or improve existing products and services provided by us;</p></li>
              <li><p>general and specific communication between us and you;</p></li>
              <li><p>consider any concerns or complaints you raise against us, to manage any legal action involving Bindo Labs Limited, or both;</p></li>
              <li><p>carry out any enforcement activities including to collect any money due from you;</p></li>
              <li><p>identify you or establish your tax status under any local or foreign legislation, regulation or treaty or pursuant to an agreement with any tax authority;</p></li>
              <li><p>identify, prevent or investigate any fraud, unlawful activity or misconduct (or suspected fraud, unlawful activity or misconduct);</p></li>
              <li><p>comply with all Applicable Law, including conducting anti-money laundering, financial crime and other screening checks;</p></li>
              <li><p>verify any information and records relating you;</p></li>
              <li><p>comply with these terms and the Privacy Policy;</p></li>
              <li><p>enable an actual or proposed assignee(s) of all or any part of our business and/or assets to evaluate the transaction intended to be the subject of the assignment and to enable the actual assignee(s) to use the Personal Data provided by you in the operation of the business; and</p></li>
              <li><p>for any other purpose set out in our Privacy Policy.</p></li>
            </ul>
            <p><strong>15.6 Data Subjects.</strong> The categories of Data Subjects who we may collect Personal Data about may include the following where they are a natural person: the Customer, the
              directors and ultimate beneficial owner(s) of the Customer, your customers, employees and
              contractors, payers and payees. You may share with Bindo Labs Limited some or all of the
              following types of Personal Data regarding Data Subjects:</p>
            <ul>
              <li><p>full name;</p></li>
              <li><p>email address;</p></li>
              <li><p>phone number and other contact information;</p></li>
              <li><p>date of birth;</p></li>
              <li><p>nationality;</p></li>
              <li><p>public information about the data subject;</p></li>
              <li><p>other relevant verification or due diligence documentation as required under these terms; and</p></li>
              <li><p>any other data that is necessary or relevant to carry out the Agreed Purposes.</p></li>
            </ul>
            <p><strong>15.7 Compliance.</strong> Neither party will knowingly perform its obligations under these terms in a way that causes the other party to breach applicable Data Protection Legislation. Neither party will be responsible for any consequences resulting from the other party’s failure to comply with applicable Data Protection Legislation in relation to Personal Data that it shares.</p>
            <p><strong>15.8 Undertakings.</strong> To the extent either you or Bindo Labs Limited processes Personal Data, each party will:</p>
            <ul>
              <li><p>process the personal data fairly and lawfully and ensure it has legitimate grounds under Data Protection Legislation for such processing;</p></li>
              <li><p>obtain and maintain all appropriate registrations required under Data Protection Legislation to allow it to use the personal data in accordance with these terms;ensure appropriate technical and organisational security measures are in place to protect Personal Data under its control;</p></li>
              <li><p>ensure that all personnel who have access to and/or process the Personal Data are obliged to keep it confidential;</p></li>
              <li><p>transfer Personal Data between jurisdictions only where it has taken appropriate measures to make such a transfer lawful under Data Protection Legislation;</p></li>
              <li><p>notify the other party promptly if it receives any request, complaint or other communication from a data subject or a Regulatory Body that is addressed to or intended for the other party; and</p></li>
              <li><p>notify the other party promptly if it becomes aware of a Personal Data breach that is directly relevant to the other party.</p></li>
            </ul>
            <p><strong>15.9 Data Security.</strong> You will ensure that any Personal Data that is transferred to Bindo Labs Limited is transferred by a method and means that is secure and compliant with Data Protection Legislation in addition to any other reasonable information security requirements specified by us.</p>
            <p><strong>15.10 Cooperation.</strong> Each party will be responsible for responding to requests from supervisory authorities or individuals exercising their rights under applicable Data Protection Legislation. Each party will provide the other with reasonable cooperation and assistance to comply with all obligations imposed on them by the Data Protection Legislation and any relevant Applicable Law, including: (i) dealing with and responding to any communications from Data Subjects; (ii) dealing with, mitigating and responding to any breach of personal data; and (iii) with respect to security, impact assessments and consultations with supervisory authorities or Regulatory Bodies.</p>
            <p><strong>15.11 Data Disposal.</strong> On termination of these terms, or once processing of any Personal Data is no longer necessary to carry out the Agreed Purposes, each party will securely dispose of any such Personal Data in its possession required by Applicable Law.</p>
            <p><strong>15.12 Data Security.</strong> We will take all steps required under Data Protection Legislation to ensure appropriate information security safeguards are put in place regarding such disclosure.</p>
            <p><strong>15.13 Personal data you provide about someone else.</strong> If you give us Personal Data about someone else, including (but not limited to) your Authorised Users, your director and ultimate beneficial owner(s), your customers, employees and contractors, payers and payees, you undertake that we may rely on you to obtain such other persons' consent for disclosing their Personal Data to us which we will use in accordance with the terms of these terms. You are required to show them information about these provisions so that they may understand the manner in which their Personal Data may be collected, used and disclosed by Bindo Labs Limited in connection with your dealings with us and our use of their Personal Data including our use of Personal Data for Direct Marketing purposes and their consent to our use of their Personal Data provided by you on their behalf.</p>

            <div className={`${styles.title}`}>16. DISCLOSURE OF INFORMATION AND USE OF PERSONAL DATA FOR DIRECT MARKETING</div>
            <p><strong>16.1 We may disclose your information to others.</strong> Bindo Labs Limited may disclose your Customer Data, Payment Data and Personal Data, including any Confidential Information, to:</p>
            <ul>
              <li><p>any branch, subsidiary, holding company, associated company, affiliate or related entity of Bindo Labs Limited;</p></li>
              <li><p>any agent, contractor or service provider that we engage or propose to engage to carry out or assist us with our functions and activities who is under a duty of confidentiality to keep such information confidential;</p></li>
              <li><p>participants in the payments system including card schemes, financial institutions and payments organisations such as Society for Worldwide Interbank Financial Telecommunication;</p></li>
              <li><p>any person to whom Bindo Labs Limited may enter into any assignment, fundraising, share purchase or other commercial agreement who is under a duty of confidentiality to keep such information confidential;</p></li>
              <li><p>any person to whom information is permitted or required (or expected) to be disclosed by any applicable law or regulation or pursuant to any order of court;</p></li>
              <li><p>any referee or representative or yours (including any professional advisor, broker, introducer, attorney or executor);</p></li>
              <li><p>any third party provider of collateral, security or credit support (if any);</p></li>
              <li><p>credit reference agencies, and, in the event of default, to debt collection agencies;</p></li>
              <li><p>any of your agents you have authorised or who is authorised under law such as an administrator or trustee in bankruptcy or your legal representative; and</p></li>
              <li><p>any actual or proposed assignee of all or any part of our business and/or assets and/or interests of Bindo Labs Limited.</p></li>
            </ul>
            <p><strong>16.2 Use of Personal Data in Direct Marketing</strong></p>
            <p>We may use the Personal Data provided by you in direct marketing and we require consent
              (or an indication of no objection) from the Data Subject for that purpose. In this
              connection, please note that:</p>
            <ul>
              <li><p>the name, contact details, products and other service information, transaction pattern and behavior, financial background and demographic data of the Data Subjects held by us from time to time may be used by us in direct marketing;</p></li>
              <li><p>the following classes of services, products and subjects may be marketed:</p></li>
            </ul>
            <ol>
              <li><p>financial, insurance, credit card, banking and related services and products;</p></li>
              <li><p>reward, loyalty, co-branding or privileges programmes and related services and products;</p></li>
              <li><p>services and products offered by our co-branding partners (the names of such co-branding partners will be provided during the application for the relevant services and products, as the case may be); and</p></li>
              <li><p>donations and contributions for charitable and/or non-profit making purposes;</p></li>
            </ol>
            <ul>
              <li><p>the above services, products and subjects may be provided by us or (in the case of donations and contributions) solicited by us and/or any of the following:</p></li>
            </ul>
            <ol>
              <li><p>any branch, subsidiary, holding company, associated company, affiliate or related entity of Bindo Labs Limited;</p></li>
              <li><p>third party financial institutions, insurers, credit card companies, securities and investment services providers;</p></li>
              <li><p>third party reward, loyalty, co-branding or privileges programme providers;</p></li>
              <li><p>co-branding partners of Bindo Labs Limited or any branch, subsidiary, holding company, associated company, affiliate or related entity of Bindo Labs Limited (the names of such co- branding partners will be provided during the application of the relevant services and products, as the case may be); and</p></li>
              <li><p>charitable or non-profit making organisations.</p></li>
            </ol>
            <p>If you (or any persons for whom you provide the Personal Data to us) do not wish us to use or provide to other persons his or her Personal Data for use in direct marketing as described above, you and such persons for whom you provide the Personal Data to us (as the case may be) may exercise the right to opt-out by notifying us.</p>

            <div className={`${styles.title}`}>17. LIMITATION OF LIABILITY</div>
            <p><strong>17.1 Liabilities.</strong> Nothing in these terms excludes or limits either party’s liability where it would be unlawful to do so. This includes a party’s liability for:</p>
            <ul>
              <li><p>death or personal injury resulting from its negligence;</p></li>
              <li><p>fraud or fraudulent misrepresentation; or</p></li>
              <li><p>all sums properly due and payable under these terms.</p></li>
            </ul>
            <p><strong>17.2 Direct Losses.</strong> Direct Losses will be recoverable under these terms. However, save as otherwise stated in these terms, neither party will be liable for any Indirect or Consequential Losses.</p>
            <p><strong>17.3 Exclusions.</strong> We will not be liable to you for:</p>
            <ul>
              <li><p>any loss outside of our direct control that arises from the negligence, fraud or
                wilful misconduct or the insolvency of any third-party correspondent bank, liquidity
                provider, or other financial institution who is part of the payment network used to
                provide the Services;</p></li>
              <li><p>the non-execution, or defective execution, of a Payment if any information you
                provide in your instructions is incorrect; or</p></li>
              <li><p>errors, mistakes, or non-performance arising from the payee/beneficiary bank if the
                payee/beneficiary bank fails to process the payment correctly.
                In the case of (b) or (c), we will make reasonable efforts to recover the funds involved
                in the payment. You will be responsible for all costs incurred by us as part of any such
                recovery attempt, even if we are unable to successfully recover the funds.</p></li>
            </ul>
            <p><strong>17.4 Aggregate Liability.</strong> Save as set out in clause 17.5, Bindo Labs Limited’s total aggregate liability to you for all Actions arising out of or in connection with these terms will not exceed the total amount of Service Fees paid by you to Bindo Labs Limited during the one (1) year period prior to the date the liability first arose.</p>
            <p><strong>17.5 Liability with respect to FX Conversion.</strong> To the fullest extent permitted by Applicable Law, Bindo Labs Limited’s maximum liability for a FX Conversion, whether arising in contract, tort or otherwise, will in no circumstances exceed an amount equal to the currency sold by Bindo Labs Limited under that FX Conversion.</p>
            <p><strong>17.6 Equitable Relief.</strong> Damages alone may not be an adequate remedy for breach and accordingly either party will be entitled to seek the remedies of injunction, specific performance or other equitable relief for any threatened or actual breach.</p>
            <p><strong>17.7 Mitigation.</strong> Each party agrees to use reasonable endeavours to mitigate any loss in respect of which it is indemnified under these terms.</p>

            <div className={`${styles.title}`}>18. INDEMNITY</div>
            <p><strong>18.1 Customer’s indemnity.</strong> You will indemnify us on demand against all losses, damages, costs (including reasonable legal fees) and expenses incurred or suffered by us in connection with or as a result of:</p>
            <ul>
              <li><p>your breach of these terms, failure to comply with Applicable Law, failure to comply with the Acceptable Use Policy, or your use or misuse of our Services;</p></li>
              <li><p>a third party alleging that our use of the Customer Data as permitted by these terms infringes any Intellectual Property Rights;</p></li>
              <li><p>any of your Payments or FX Conversions, including us acting on any of your instructions which we reasonably believe to have been made by you or your Authorised User; or</p></li>
              <li><p>the closure or cancellation of all or any part of a FX Conversion or Payment before its Conversion Date or Payment Date, including if we have to do so due to you failing to provide funds for the FX Conversion or Payment.</p></li>
            </ul>
            <p><strong>18.2 Third party Claim.</strong> This clause 18.2 and clauses 18.3 and 18.4 apply in respect of the obligations in this clause 18, clause 14.6 and clause 22. If a party becomes aware of any claim or proceeding which might lead to a claim under this clause 18, clause 14.6 or clause 22 (each, a ‘<strong>Third party Claim</strong>’), the party will promptly notify the other party in writing and, subject to the other party complying at all times with clause 18.3, the first party will:</p>
            <ul>
              <li><p>allow the other party to control the defence of such Third party Claim; and</p></li>
              <li><p>at the request and expense of the other party, provide reasonable assistance for the other party to defend any such Third party Claim.</p></li>
            </ul>
            <p><strong>18.3 Defence.</strong> If the other party assumes the defence of a Third party Claim, the other party will:</p>
            <ul>
              <li><p>promptly notify the first party that it has assumed such defence;</p></li>
              <li><p>at all times have regard for the interests and reputation of the first party and comply with all applicable court rules in the defence of such Third party Claim;</p></li>
              <li><p>consult and keep the first party informed, in relation to any negotiations, settlement or litigation; and</p></li>
              <li><p>not, without the prior written consent of the first party, enter into any settlement or compromise of the Third party Claim that involves a remedy other than the payment of money.</p></li>
            </ul>
            <p><strong>18.4 Indemnification.</strong> If the other party does not assume the defence of a Third party Claim in accordance with clause 18.3 within 14 days of being notified in writing of that Third party Claim, the first party, or a Person designated by the first party, may defend the Third party Claim in such manner as it may deem appropriate, and the other party will indemnify the first party on demand in relation to all costs, claims and liabilities incurred by the first party in relation to such Third party Claim.</p>
            <p><strong>18.5 Break costs.</strong> Without limiting any other provision of these terms, if we have funded or arranged to fund all or part of any FX Conversion or Payment on your instruction, and you have cancelled or failed to fully fund that FX Conversion or Payment, you will indemnify us on demand against any losses, damages, costs (including reasonable legal fees) and expenses incurred or suffered by us as a result of the investment, deposit or other deployment of that funding or the unwinding of any arrangement for that funding.</p>

            <div className={`${styles.title}`}>19. TERMINATION AND SUSPENSION</div>
            <p><strong>19.1 Closing your Bindo Labs Limited Profile.</strong> You can close your Bindo Labs Limited Profile by stopping the use of the Services and terminating these terms at any time by giving us 30 calendar days prior written notice.</p>
            <p><strong>19.2 Bindo Labs Limited’s rights to suspend or terminate.</strong> We may terminate these terms (or any Additional Terms) at any time by giving you 14 calendar days prior written notice. In addition, we may without notice immediately suspend or terminate all or any part of these terms, any Additional Terms or any of the Services, or block any transactions, if:</p>
            <ul>
              <li><p>we suspect criminal activity in connection with your Bindo Labs Limited Profile, that your Bindo Labs Limited Profile is being used fraudulently, or we reasonably believe you have fraudulently requested a refund for an unauthorised transaction;</p></li>
              <li><p>we reasonably believe you are in breach of Applicable Law or about to breach an Applicable Law;</p></li>
              <li><p>we are legally required to do so, including as directed by any relevant Regulatory Body;</p></li>
              <li><p>you have breached any provision in these terms or breached any applicable limits or restrictions in the Acceptable Use Policy;</p></li>
              <li><p>you have given us false or inaccurate information, or we have been unable to verify any information you have provided;</p></li>
              <li><p>you notify us, or we suspect or identify, any suspected or actual unauthorised transactions;</p></li>
              <li><p>you have been placed on any national or international restricted or prohibited lists, which prevents us from doing business with you;</p></li>
              <li><p>you have been abusive to anyone at Bindo Labs Limited;</p></li>
              <li><p>you have not accepted changes to these terms;</p></li>
              <li><p>we suspend or stop all, or part of, the Services, including for technical or security reasons;</p></li>
              <li><p>we have reasonable concerns about your Bindo Labs Limited Profile, including the use of the Services;</p></li>
              <li><p>you haven’t paid or repaid any amounts owing to us by the date due;</p></li>
              <li><p>one of our banking partners, or other service provider necessary to provide the Services, requires us to terminate these terms; or</p></li>
              <li><p>you do not use your Bindo Labs Limited Profile for 12 months and it becomes inactive.</p></li>
            </ul>
            <p><strong>19.3 Blocking transactions.</strong> We may also block any transaction if your instructions are unclear, incomplete or contain an error.</p>
            <p><strong>19.4 We will give you notice of suspension where possible.</strong> We will give you notice of any suspension and the reasons for such suspension as soon as we can, either before the suspension is put in place, or immediately after, unless it would compromise our reasonable security measures or otherwise be unlawful. We will lift the suspension as soon as practicable after the reasons for the suspension have ceased to exist.</p>
            <p><strong>19.5 Immediate termination.</strong> Without affecting any other right or remedy available to it, either party may terminate these terms or any Additional Terms immediately on written notice to the other party and without liability to the other party:</p>
            <p>(a) if the other party:</p>
            <ol>
              <li><p>commits a material breach of these terms (or the Additional Terms), and, in the case of a breach capable of remedy, fails to remedy such breach within 30 days after receiving formal written notice to do so; or</p></li>
              <li><p>commits a series of breaches of these terms (or the Additional Terms) which when taken together have the impact or effect of, or otherwise amount to a material breach; or</p></li>
              <li><p>becomes subject to an Insolvency Event;</p></li>
            </ol>
            <p>(b) if the party reasonably determines that it has become unlawful to perform its obligations under these terms; or</p>
            <p>(c) in the event of a Force Majeure Event that has the effect of preventing the other party from performing any of its obligations under these terms for a period exceeding 1 month.</p>
            <p><strong>19.6 Repayment upon termination.</strong> On termination you will need to immediately repay any money you owe us. In the circumstances set out in clauses 19.2 or 19.5, we may charge you for any costs, expenses and losses we incur (including costs due to foreign exchange difference or any action we may take to cover or reduce the exposure). Once all amounts owing to us have been repaid, any excess amount held by us in respect of the Services will be made available to you.</p>
            <p><strong>19.7 Effect of termination.</strong> On termination of these terms or any Additional Terms:</p>
            <ul>
              <li><p>you will not be able to use the Bindo Labs Limited Platform (or the Services under those Additional Terms) and all rights granted to you under these terms or the Additional Terms will cease;</p></li>
              <li><p>you must immediately return or delete copies of any documentation, notes and other materials comprising or regarding the Bindo Labs Limited Platform (or the Services under those Additional Terms);</p></li>
              <li><p>all of your payment obligations under these terms (or under the Additional Terms) for Services provided through to the effective date of termination will immediately become due and payable;</p></li>
              <li><p>each party will return or delete all Confidential Information of the other party in its possession within 30 days of the termination of these terms or the Additional Terms, and will not make or retain any copies of such Confidential Information except as necessary to comply with Applicable Law; and</p></li>
              <li><p>any provision that expressly or by implication has effect after termination will continue in full force and effect.</p></li>
            </ul>

            <div className={`${styles.title}`}>20. THIRD PARTY SERVICE PROVIDERS</div>
            <p><strong>20.1 Acknowledgements.</strong> You acknowledge and agree that:</p>
            <ul>
              <li><p>we may work with third parties, including other Bindo Labs Limited Group Companies, to provide the Services;</p></li>
              <li><p>if you receive information from us regarding, or provided by, such third parties, you will not rely on such information and will not make any claims against us or the third party in relation to such information. You agree that you bear all risk and responsibility if you choose to rely on such information in contravention of this clause.</p></li>
            </ul>
            <p><strong>20.2 Services provided by Bindo Labs Limited.</strong> If we use a third party to provide the Services, you acknowledge that the Services are provided by Bindo Labs Limited to you and not by the third party, unless any Additional Terms for the use of a Service or feature of a Service specifically provide otherwise in writing.<strong>Compliance with Data Protection Legislation.</strong> If we use a third party to provide the Services, we will ensure that the third party complies with Data Protection Legislation relating to the processing of Personal Data pursuant to these terms.</p>

            <p>21. REPRESENTATIONS AND WARRANTIES</p>
            <p><strong>21.1 Customer warranties.</strong> You represent and warrant to us that you:</p>
            <ul>
              <li><p>will comply with these terms and all Applicable Law regarding your use of the Services, the Bindo Labs Limited Platform and Customer Data;</p></li>
              <li><p>have the right, power and authority to enter into these terms and to perform all of your obligations under it;</p></li>
              <li><p>have the valid right to grant to us the rights as defined in these terms without violating any Applicable Law or the proprietary rights of any third party;</p></li>
              <li><p>will provide us with accurate, up-to-date and complete customer due diligence information and data at all times, pursuant to clause 4 of these terms;</p></li>
              <li><p>will pay all sums to us free and clear of any claims, encumbrances or any other interest of any third person; and</p></li>
              <li><p>will use the Services and the Bindo Labs Limited Platform only for lawful purposes.</p></li>
            </ul>
            <p><strong>21.2 Customer conduct.</strong> You are solely responsible for any use of the Services, and other features of the Bindo Labs Limited Platform, including the content of any data or transmissions you execute through Bindo Labs Limited Platform yourself, or by any Authorised User you permit to access Bindo Labs Limited Platform. You will use all reasonable endeavours to ensure that no unauthorised person will or could access your Bindo Labs Limited Profile or other features of the Bindo Labs Limited Platform. You will not interfere with, disrupt, or cause any damage to other users of the Bindo Labs Limited Platform or Services.</p>
            <p><strong>21.3 You must not misuse the Bindo Labs Limited Platform.</strong> You cannot:</p>
            <ul>
              <li><p>access the Bindo Labs Limited Platform using a method other than the Bindo Labs Limited APIs or Webapp, for example you cannot use automated means (including harvesting bots, robots, spiders, or scrapers) without our permission;</p></li>
              <li><p>do anything that may disrupt, disable, overburden, or damage the Bindo Labs Limited Platform, such as a denial of service attack; and</p></li>
              <li><p>cause viruses or other malicious code to interfere with the use of the Bindo Labs Limited Platform.</p></li>
            </ul>
            <p><strong>21.4 Disclaimer.</strong> Except as expressly stated in these terms, all warranties, conditions and terms, whether express or implied by statute, common law or otherwise (including any implied warranties of satisfactory quality or fitness for a particular purpose or non- infringement) are excluded to the extent permitted by law. In particular, we:</p>
            <ul>
              <li><p>do not make any commitments about the content or data within the Bindo Labs Limited Platform, the specific functions of the Bindo Labs Limited Platform or its accuracy, reliability, availability or ability to meet your needs;</p></li>
              <li><p>do not guarantee that the Bindo Labs Limited Platform will operate uninterrupted or error-free, that it will always be available, that the information it contains is current or up-to-date, that it will be free from bugs or viruses, or never be faulty; and</p></li>
              <li><p>may occasionally have to interrupt your use of the Bindo Labs Limited Platform for operational, security or other reasonable reasons. In such a case, we will restore access as quickly as practicable.</p></li>
            </ul>
            <p><strong>21.5 Financial advice.</strong> You must not treat any information or comments by Bindo Labs Limited as financial advice. You should consider obtaining your own, independent advice with respect to any such information or comments and the use of the Services.</p>

            <div className={`${styles.title}`}>22. ANTI-BRIBERY, ANTI-CORRUPTION AND SANCTIONS</div>
            <p><strong>22.1</strong> You will (and will ensure that any person you use for the performance of its obligations under or in connection with these terms, including, Authorised Users, employees, agents, consultants and subcontractors will):</p>
            <ul>
              <li><p>comply with all Applicable Law relating to Sanctions, bribery and corruption including the Hong Kong Prevention of Bribery Ordinance (Cap. 201), UK Bribery Act 2010 and the US Foreign Corrupt Practices Act (‘<strong>ABC Legislation</strong>’);</p></li>
              <li><p>not do or omit to do anything likely to cause the other party to be in breach of any such ABC Legislation;</p></li>
              <li><p>not give or receive any bribes, including in relation to any public official;</p></li>
              <li><p>maintain a programme designed to ensure compliance with ABC Legislation, including an education and training programme and measures reasonably calculated to prevent and detect violations of ABC Legislation;</p></li>
              <li><p>if requested, and at the other party’s reasonable cost, provide us with sufficient reasonable assistance to enable us to perform any actions required by any government or agency in any jurisdiction for the purpose of compliance with ABC Legislation or in connection with any investigation relating to ABC Legislation; and</p></li>
              <li><p>promptly notify us of any allegation of sanctions violation, fraud, bribery or corrupt or unlawful practices made against it in court, arbitration or administrative proceedings, or any investigation commenced in respect of the same.</p></li>
            </ul>
            <p><strong>22.2</strong> You undertake that you are not, and will procure that none of your Authorised Users, directors, officers, agents, employees or persons acting on behalf of the foregoing is, a Restricted Person and do not act directly or indirectly on behalf of a Restricted Person. You must supply to us details of any claim, action, suit, proceedings or investigation against you or such persons with respect to Sanctions by any Sanctions Authority to the extent permitted by law, promptly upon becoming aware.</p>
            <p><strong>22.3</strong> You will indemnify us against all liabilities, losses, and expenses which they have suffered as a result of a breach of this clause by the party.</p>

            <div className={`${styles.title}`}>23. MARKET DISRUPTION, ERRORS & FORCE MAJEURE</div>
            <p><strong>23.1 Force majeure.</strong> Neither party will be responsible for any failure to fulfil any obligation for so long as, and to the extent to which the fulfilment of such obligation is impeded by a Force Majeure Event. The party subject to the Force Majeure Event will:</p>
            <ul>
              <li><p>promptly notify the other party of any circumstances which may result in failure to perform its obligations; and</p></li>
              <li><p>use reasonable efforts to mitigate the effect of the Force Majeure Event on the performance of its obligations.</p></li>
            </ul>
            <p><strong>23.2 Market Disruption.</strong> If, in our reasonable opinion, a Market Disruption occurs after we have provided you with an FX Exchange Rate, in relation to all or certain currency pairs affected by the Market Disruption, until we reasonably determine the period of Market Disruption hasended, we may revise any FX Exchange Rate, or any rate or margin component of any FX Exchange Rate, with immediate effect.</p>
            <p><strong>23.3 Error in FX Exchange Rate.</strong> If an FX Conversion is executed at an FX Exchange Rate that clearly and materially deviates from the market price, you must not rely on such obvious error. In such cases, we may declare that the FX Conversion is not binding and you will need to return to us any amounts made under the FX Conversion and we will reprocess such FX Conversion at the correct rate.</p>
            <p><strong>23.4 Fee Payment.</strong> This clause does not limit or otherwise affect your obligation to pay any fees or other charges or amounts due under these terms.</p>

            <p>24. ACTING AS A CONSUMER</p>
            <p><strong>24.1</strong> This clause 24 applies to you if you are a natural person. You represent to us that you operate a business and will only use the Services for business purposes unless you have told us otherwise before entering into this agreement. You must not use the Services for consumer purposes (or in your capacity as a consumer) unless we approve you to do so in which case you must only use the Services for consumer purposes.</p>
            <p><strong>24.2 If you are using our Services in your capacity as a consumer, i.e. you are not entering into this agreement in the course of business (and do not hold yourself as doing so):</strong></p>
            <ul>
              <li><p><strong>you must comply with our Acceptable Use Policy,</strong></p></li>
              <li><p><strong>clause 25.8 of these terms do not apply to you, and</strong></p></li>
              <li><p><strong>we draw your attention to clauses 17.4 (the limit on our aggregate liability to you) and to clause 18 (your indemnity obligations to us).</strong></p></li>
            </ul>
            <p><strong>24.3</strong> If you need to change the purpose in which you use the Services, please make a request to us in writing. We may require additional details from you if you change the purpose in which you use our Services (for example, we may require you to provide evidence of your business tax number).</p>

            <div className={`${styles.title}`}>25. GENERAL</div>
            <p><strong>25.1 Entire agreement.</strong> These terms represent the entire agreement between the Parties with respect to the subject matter of these terms and supersedes and extinguishes all previous agreements, representations, promises, and statements between us, whether written or oral, relating to its subject matter. Each party acknowledges that in entering into these terms it does not rely on, and will have no remedy in respect of, any representation or warranty (whether made innocently or negligently) that is not set out in these terms. Each party agrees that it shall have no claim for innocent or negligent misrepresentation or negligent misstatement based on any statement in these terms.</p>
            <p><strong>25.2 Complaints.</strong> Please let us know if we have made a mistake or you feel that we have not met your expectations in the delivery of our Services. We have internal procedures for handling complaints fairly and promptly in accordance with our  regulatory requirements. A copy of our complaints procedure is available upon request.</p>
            <p><strong>25.3 Notices.</strong> All notices, demands and other communications provided for or permitted under these terms will be made in writing to the Parties at their registered addresses and will be sent by:</p>
            <ul>
              <li><p>registered or certified first-class mail;</p></li>
              <li><p>courier or overnight service or personal delivery;</p></li>
              <li><p>email;</p></li>
            </ul>
            <p>and will be deemed received upon delivery. You agree and consent to receive notices and other communications from us by way of email and other electronic methods such through the Bindo Labs Limited Platform.</p>
            <p><strong>25.4 Relationship.</strong> Except as expressly provided for in these terms or in any Additional Terms, nothing in these terms will be construed as creating a partnership or joint venture between the Parties, constitute any party being the agent of the other party, or authorise any party to make or enter into any commitments for or on behalf of the other party. Each party confirms it is acting on its own behalf and not for the benefit of any other person.</p>
            <p><strong>25.5 Assignment.</strong> You will not assign these terms, in whole or in part, without our prior written consent. Any attempt to do so will be void and constitute a material breach of these terms. We may assign these terms, in whole or in part, or subcontract our obligations under it, without your consent.</p>
            <p><strong>25.6 Waiver.</strong> No failure or delay by a party to exercise any right or remedy provided under these terms or by law, or a single or partial exercise of such right or remedy, will constitute a waiver of that or any other right or remedy, nor will it preclude or restrict the further exercise of that or any other right or remedy.</p>
            <p><strong>25.7 Severability.</strong> If any provision of these terms is determined to be invalid, unenforceable or illegal by any court of competent jurisdiction, it will be deemed to have been deleted without affecting the remaining provisions. If such provision would be valid, enforceable and legal if some part of it were modified or deleted, the provision will apply with the minimum modification necessary to make it legal, valid and enforceable.</p>
            <p><strong>25.8 Non-solicitation.</strong> During the term of these terms and for a period of one (1) year after termination, you will not solicit the services of any staff of Bindo Labs Limited with whom you have had any material dealing within the preceding eighteen (18) months. However, for the avoidance of doubt, this shall not apply: (i) where prior written consent has been sought by you from us; or (ii) in relation to any public recruitment campaign by you or on your behalf which is not specifically targeted at any staff of Bindo Labs Limited. This clause 25.8 shall survive termination of these terms.</p>
            <p><strong>25.9 Third party rights.</strong> These terms is made specifically between and for the benefit of the Parties, and the provisions of the Contracts (Rights of Third Parties) Ordinance (Cap 623) do not apply to these terms. No person who is not a party to these terms (whether or not such person is named, referred to, or otherwise identified, or form part of a class of persons so named, referred to or identified in these terms) shall have any right under any Applicable Law, including the Contracts (Rights of Third Parties) Ordinance or otherwise to enforce these terms or to enjoy the benefit of any term of these terms. Neither party may declare itself a trustee of the rights under it for the benefit of any third party.</p>
            <p><strong>25.10 Set-off.</strong> We may, at any time, set off any payment liability you have to us against any payment liability that we have to you, whether either liability is present or future, liquidated or unliquidated, and whether or not either liability arises under these terms. If the liabilities to be set off are expressed in different currencies, we may convert either liability at a market rate ofexchange for the purpose of set-off. Our right to set off includes, but is not limited to, setting off the amount of any payment you have requested against any amount collected by us for you as part of the Collection Services.</p>
            <p><strong>25.11 Order of payments.</strong> We may determine the order of transactions we process with respect to your account, subject to any requirements at law. For example, if you have given us one or more Payment instructions without having paid sufficient funds into the Intermediary Account, we may use any funds we subsequently collect or receive for the purposes of one or more of those Payment instructions at our discretion, unless we accept instructions from you otherwise.</p>
            <p><strong>25.12 Records.</strong> You agree that, except in the case of manifest error, our records of your use of the Services and of transactions carried out through the Bindo Labs Limited Platform is conclusive evidence of its contents.</p>
            <p><strong>25.13 Other languages.</strong> These terms are made in the English language. It may be translated to other languages for convenience only and in the event of any inconsistency the English language version will prevail.</p>
            <p><strong>25.14 Governing law.</strong> These terms will be governed by and constructed in accordance with the laws of Hong Kong. The Hong Kong courts have exclusive jurisdiction to settle any dispute (including non-contractual disputes or claims arising out of, relating to or having any connection with these terms (for the purpose of this clause, a ‘<strong>Dispute</strong>’) and each party submits to the exclusive jurisdiction of the Hong Kong courts. For the purposes of this clause 25.14, each party waives any objection to the Hong Kong courts on the grounds that they are an inconvenient or inappropriate forum to settle any Dispute.</p>

            <div className={`${styles.title}`}>26. DEFINITIONS & INTERPRETATION</div>
            <p><strong>26.1</strong> In these terms, the following definitions apply:</p>
            <p><strong>Actions</strong> means any action, claim, demand or proceeding, in respect of any loss, damage, costs, fines, expenses and other liabilities of whatever nature (whether foreseeable or contingent and whether relating to a third-party claim), including in relation to any breach of contract, negligence, fraud, wilful misconduct, breach of statutory duty or other head of claim and however suffered, incurred or sustained.</p>
            <p><strong>Additional Terms</strong> means any additional terms of use applicable to the use of our Services found on our website including any specific terms applicable to the use of our Services in a particular region.</p>
            <p><strong>Bindo Labs Limited API</strong> means the technical interface setting out the protocols and specifications required to effect an integration of the Customer’s technical systems with the Bindo Labs Limited Platform for Authorised Users to use the Services.</p>
            <p><strong>Bindo Labs Limited Data</strong> means details of transactions using Bindo Labs Limited infrastructure, information used in fraud detection and analysis, aggregated or anonymised information generated in connection with the Services, and any other information created by or originating from Bindo Labs Limited or the Services.<strong>Bindo Labs Limited Platform</strong> means the proprietary technology and associated products (including but not limited to those found on the Webapp and through an Bindo Labs Limited API) devised by Bindo Labs Limited to provide customers with Services including access to the Global Account.</p>
            <p><strong>Bindo Labs Limited Profile</strong> means the electronic information profile that records your personal or business details (as applicable) and that is used to log into and use the Bindo Labs Limited Platform.</p>
            <p><strong>API Documentation</strong> means the documentation and guidelines applicable to the Bindo Labs Limited APIs found on our website.</p>
            <p><strong>Applicable Law</strong> means all laws, legislation, regulations, binding codes of practice, or rules or requirements of any relevant Regulatory Body applicable to the activities undertaken or procured by either party under these terms, including those relating to: (i) money services business; (ii) payment services; (iii) anti-money laundering, know your customer, counter terrorist financing and sanctions requirements; (iv) data protection; (v) consumer protection, and (vi) any code of practice or guidance issued by any Regulatory Body with which reputable financial institutions in Hong Kong are required or accustomed to comply.</p>
            <p><strong>Authorised User</strong> means any person (for example, any of your directors, officers, employees or professional advisors) appointed by you for the purposes set out in clause 7.1.</p>
            <p><strong>Business Day</strong> means any day when banks are generally open for business in Hong Kong (other than a Saturday, Sunday or public holiday in Hong Kong, or any day on which a typhoon signal number 8 or above, or a black rainstorm warning is hoisted in Hong Kong).</p>
            <p><strong>Collection Services</strong> means the collection agent services provided by Bindo Labs Limited to you under these terms relating to the collection of funds by Bindo Labs Limited in a Supported Currency.</p>
            <p><strong>Confidential Information</strong> means and includes all confidential information, whether verbal or written: (i) concerning the business and affairs of a party, that a party obtains or receives from the other party; or (ii) which arises out of the performance of any obligations under these terms. Confidential Information does not include information which is: (a) known by the receiving party without restriction in relation to disclosure prior to receipt from the disclosing party; (b) received from a third party who lawfully acquired it and who was under no obligation restricting its disclosure; (c) approved in writing for release from these terms by the disclosing party; (d) available in the public domain other than by breach of these terms; or (e) independently developed without access to any Confidential Information disclosed by the disclosing party.</p>
            <p><strong>Confirmation</strong> means Bindo Labs Limited’s response and confirmation to your instructions to us for a FX Conversion, a Payment, or both.</p>
            <p><strong>Conversion Date</strong> means the date that bought funds in an FX Conversion is available to the Customer in cleared funds. Conversion Date for any currency pair the Parties elect to transact in must be a Business Day in both countries (i.e. the countries where the Parties are located to transact business).</p>
            <p><strong>Customer Data</strong> means information that describes you and your business (as applicable) and its operations, your products or services, and orders placed by your customers.</p>
            <p><strong>Customer Margin</strong> means the amount specified as such in the Bindo Labs Limited Platform that applies to you, or any other percentage or amount that we may notify you in writing from time to time.Data means all Customer Data, Personal Data, Payment Data and Bindo Labs Limited Data.</p>
            <p><strong>Data Subject</strong> means any natural person who can be identified, directly or indirectly, via an identifier such as a name, an identification number, location data, or via factors specific to the person's physical, physiological, genetic, mental, economic, cultural or social identity.</p>
            <p><strong>Data Protection Legislation</strong> means applicable privacy and data protection laws and any applicable national implementing laws, regulations and secondary legislation in Hong Kong relating to the processing of Personal Data and the privacy of electronic communications, as amended, replaced or updated from time to time.</p>
            <p><strong>Direct Losses</strong> means, in respect of any breach, those losses that the breaching party knew, or a reasonable person in the breaching party’s position ought reasonably to have known, either: (i) at the time of entering into these terms; or (ii) at the time of the breach, were reasonably probable to result from the breach. For the avoidance of doubt, the following will not be Direct Losses, loss of: goodwill; business; profit; operation time; reputation; opportunity; or corruption of data or information; or loss of anticipated savings, even if the breaching party was aware or ought reasonably to have been aware of the possibility that such loss or damage could occur.</p>
            <p><strong>Force Majeure Event</strong> means an event beyond a party's reasonable control including: Black Swan events; strikes, lock- outs, labour troubles (but excluding strikes or other forms of industrial action by the employees, agents or subcontractors of that party); interruption or failure of a utility service including the internet, electric power, gas or water; riots, war, pandemic, or terrorist attack; nuclear, chemical or biological contamination; extreme abnormal weather conditions; the imposition of a sanction, embargo or breaking off of diplomatic relations; or any change in Applicable Law.</p>
            <p><strong>FX Base Rate</strong> means the currency exchange base rate we have specified for a FX Conversion and Conversion Date having regard to the costs provided by our foreign exchange rate service providers, the interbank exchange rates and relevant market conditions.</p>
            <p><strong>FX Conversion</strong> means an agreement between the Parties in which one currency is sold or bought against another currency at an agreed exchange rate through the Bindo Labs Limited Platform.</p>
            <p><strong>FX Conversion Services</strong> means the services provided by Bindo Labs Limited under these terms relating to FX Conversion.</p>
            <p><strong>FX Exchange Rate</strong> means the FX Base Rate plus any other margin that may be applicable to you, including the Customer Margin.</p>
            <p><strong>Global Account</strong> means a unique account ledger registered under your name and which records the amount of funds collected from your payers by Bindo Labs Limited for you as part of the Collection Services.</p>
            <p><strong>Group Company</strong> means any entity in respect of which Bindo Labs Limited or Bindo Labs Limited’s ultimate holding company: (i) owns (directly or indirectly) more than fifty (50) percent of the voting rights or issued share capital; or (ii) can ensure that the activities and business of that entity are conducted in accordance with its wishes.<strong>Hong Kong</strong> means the Special Administration Region of Hong Kong, People’s Republic of China.</p>
            <p><strong>Indirect or Consequential Losses</strong> means those losses that are not Direct Losses.</p>
            <p><strong>Insolvency Event</strong> means: (i) any procedure commenced with a view to the winding-up or re- organisation of such party; (ii) any step taken or any procedure is commenced with a view to the appointment of an administrator, receiver, administrative receiver or trustee in bankruptcy in relation to such party or all or substantially all of its assets; (iii) the holder of any security over all or substantially all of the assets of such party takes any step to enforce that security;</p>
            <p>(iv) all or substantially all of the assets of such party is subject to attachment,
              sequestration, execution or any similar process; (v) such party is unable to pay its
              debts as they fall due; (vi) such party enters into, or any step is taken, whether by the
              board of directors of such party or otherwise, towards entering into a composition or
              arrangement with its creditors or any class of them, including a company voluntary
              arrangement or a deed of arrangement; or (vii) such party enters into, or any step is
              taken, whether by the board of directors of such party or otherwise, towards any
              analogous procedure under the laws of any jurisdiction to the procedures set out in (i)
              to (vi) above, and in each case other than for the sole purpose of a scheme for a solvent
              amalgamation of that other party with one or more other companies or the solvent
              reconstruction of that other party.</p>
            <p><strong>Intellectual Property Rights</strong> means: (i) rights in, and in relation to, any patents, registered designs, design rights, trademarks, trade and business names (including goodwill associated with any trade marks or trade and business names), copyright and related rights, moral rights, databases, domain names, semi-conductor and other topography rights and utility models, and including registrations and applications for, and renewals or extensions of, such rights, and similar or equivalent rights or forms of protection in any part of the world; (ii) rights in the nature of unfair competition rights and to sue for passing off and for past infringement; and (iii) trade secrets, confidentiality and other proprietary rights, including rights to know how and other technical information.</p>
            <p><strong>Intermediary Account</strong> means a bank account in Bindo Labs Limited’s name into which you can send funds to pay for any FX Conversion or Payment transactions.</p>
            <p><strong>Market Disruption</strong> means any action, event or circumstance which, as determined by Bindo Labs Limited in its sole and absolute discretion: (i) has the direct or indirect effect of hindering, limiting or restricting the ability of Bindo Labs Limited or its Group Companies to obtain a firm quote of an offer price, convert any currency into any other currency, or to transfer any sum to any other country or within the same country; (ii) results in any purchase currency not being available in the interbank foreign exchange market in accordance with normal commercial practice; or (iii) constitutes unusual price volatility in the foreign exchange markets.</p>
            <p><strong>Network Rules</strong> means the guidelines, bylaws, rules, agreements and regulations imposed by the financial services providers that operate payment networks supported by Bindo Labs Limited from time to time (including the payment card scheme operating rules for Visa, MasterCard, or the American Express).</p>
            <p><strong>Payment</strong> means each and every individual payment to be made for you by Bindo Labs Limited to a third party payee through the Bindo Labs Limited Platform as part of the Payment Services.<strong>Payment Data</strong> means payment account details, information communicated to or by financial services providers, financial information specifically regulated by Applicable Laws and Network Rules, and any other transactional information generated as part of the use of our Services.</p>
            <p><strong>Payment Date</strong> means the date nominated by you in your instructions to us through the Bindo Labs Limited Platform, for the Payment to be transferred from Bindo Labs Limited to the payee.</p>
            <p><strong>Payment Services</strong> means, in connection with any Payments to be made in a Supported Currency to a payee by Bindo Labs Limited in accordance with the Payment instructions provided by you, the payment agent services provided to you by Bindo Labs Limited under these terms.</p>
            <p><strong>Personal Data</strong> means information that identifies a specific living person (not a company, legal entity, or machine) that is collected, transmitted to or accessible through the Services and as otherwise defined in the Data Protection Legislation.</p>
            <p><strong>Regulatory Body</strong> means any person or regulatory body concerned with the creation, enforcement or supervision of, or compliance with Applicable Law, including the Customs & Excise Department of Hong Kong and any regulatory body which replaces it.</p>
            <p><strong>Restricted Person</strong> means a person that is (i) listed on, or owned or controlled by a person listed on any Sanctions List; (ii) located in, incorporated under the laws of, or owned or controlled by, or acting on behalf of, a person located in or organised under the laws of a country or territory that is the target of country-wide Sanctions; or (iii) otherwise a target of Sanctions.</p>
            <p><strong>Sanctions</strong> means any economic sanctions laws, regulations, embargoes or restrictive measures administered, enacted or enforced by: (i) Hong Kong; (ii) the United States; (iii) the United Nations; (iv) the European Union; (v) the United Kingdom; (vi) any other jurisdiction in which Bindo Labs Limited operates; or (vii) the respective governmental institutions and agencies of any of the foregoing, including without limitation, the Office of Foreign Assets Control of the US Department of Treasury (‘<strong>OFAC</strong>’), the United States Department of State, and Her Majesty’s Treasury  (together ‘<strong>Sanctions Authorities</strong>’).</p>
            <p><strong>Sanctions List</strong> means the Specially Designated Nationals and Blocked Persons list issued by OFAC, the Consolidated List of Financial Sanctions Targets issued by Her Majesty’s Treasury, or any similar list issued or maintained or made public by any of the Sanctions Authorities.</p>
            <p><strong>Services</strong> means the services offered by Bindo Labs Limited to the Customer under these terms, including Collection Services, FX Conversion Services and Payment Services provided through the Bindo Labs Limited Platform.</p>
            <p><strong>Service Fees</strong> means all fees applicable to the use of each of the Services.</p>
            <p><strong>Settlement Cut-off</strong> means the time and date where payment of any monies in cleared funds under these terms is due to Bindo Labs Limited. Generally, it is one Business Day prior to the relevant Payment Date with respect to a Payment.</p>
            <p><strong>Supported Currency</strong> means, in respect of each feature of a Service, each currency approved by Bindo Labs Limited from time to time that can be collected, exchanged and/or paid out using that feature through the Bindo Labs Limited Platform.<strong>User Profile</strong> means, with respect to each Authorised User, the electronic information profile that records the Authorised User’s details and that is used by them to log into and use the Bindo Labs Limited Platform on your behalf.</p>
            <p><strong>Web app</strong> means the user interface that the Customer and its Authorised Users may use to access the Bindo Labs Limited Platform and the Services.</p>
            <p>Unless the context otherwise requires:</p>
            <ul>
              <li><p>use of the singular includes the plural and vice versa;</p></li>
              <li><p>a reference to a statute or statutory provision is a reference to it as amended, extended or re-enacted from time to time;</p></li>
              <li><p>any phrase introduced by the terms ‘including’, ‘include’, ‘in particular’, ‘for example’ or any similar expression will be construed as illustrative and will not limit the sense of the words preceding those terms.</p></li>
              <li><p>References to the Parties will include any successors, permitted assignees or
                transferees where permitted by these terms. The word ‘person’ will be deemed to
                include a body corporate, partnership or unincorporated association. References to
                any party indemnifying any person against any costs, claims or liabilities include
                indemnifying against any and all liabilities, losses and expenses, as well as (to the
                fullest extent, but only if permitted by Applicable Law) any civil or criminal fines
                imposed by any relevant Regulatory Body and any reasonably incurred legal fees, costs
                and expenses.</p></li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default RemittancePayoutTerms;
