import { queryJsonwebtoken } from '@/data/jsonwebtoken';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const IndividualCommissionOverview = () => {
  const [searchParams] = useSearchParams();
  const sales = searchParams.get('sales') || '';
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    getUrl();
    // eslint-disable-next-line
  }, []);

  const getUrl = async () => {
    const METABASE_SITE_URL = 'https://metabase3.bindolabs.com';
    const EXP = Math.round(Date.now() / 1000) + 365 * 24 * 60 * 60; // 365 days expiration
    
    let salesArr = sales.split(',');

    if (salesArr.length > 0) {
      salesArr = salesArr.map(
        (item) => item.charAt(0).toUpperCase() + item.slice(1)
      );
    }

    const payload = {
      resource: { dashboard: 10476 },
      params: {
        "sales": salesArr
      },
      exp: EXP,
    };
    const token = await queryJsonwebtoken(payload);
    const url = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";

    setUrl(url)
  }

  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 flex">
      <iframe title="myIframe" id="myIframe" className="flex-1 border-none" src={url} />
    </div>
  );
};

export default IndividualCommissionOverview;
