import { useEffect, useState } from 'react';
import { Constants } from '@/constants';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Label from '@/components/common/label';
import Input from '@/components/common/input';
import Button from '@/components/common/button';

const MetaBaseLogin = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const funderName = searchParams.get('funder_name');
  const token = Cookies.get(Constants.MEtABASE_TOKEN) || '';
  const token2 = Cookies.get(Constants.MEtABASE_TOKEN_2) || '';
  const token3 = Cookies.get(Constants.MEtABASE_TOKEN_3) || '';


  const [account, setAccount] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (token && funderName) {
      navigate(`/metabase/embedding/funder?funder_name=${funderName}`);
    } else if (token2) {
      navigate(`/metabase/embedding/heycars-taxi-orders`);
    } else if (token3) {
      navigate(`/metabase/embedding/tajimaya-report`);
    }
    // eslint-disable-next-line
  }, [])


  const localAccounts = [
    'emily.cheung@byfin.com',
    'zhu.min@byfin.com',
    'operations@byfin.com',
    'zhu.min@acorn-fs.com',
    'emily.cheung@acorn-fs.com',
    'pinky.fung@acorn-fs.com',
    'mandy.wong@acorn-fs.com',
    'kwan.tse@acorn-fs.com'
  ];
  const localPassword = 'BindoFunder';

  const localAccounts2 = [
    'jiyongjun@heycars.cn',
  ];

  const localPassword2 = 'skAi1@pwklqGn8m';

  const localAccounts3 = [
    'iris@tajimaya.com.hk',
    'sharon@tajimaya.com.hk',
    'tina.wong@tajimaya.com.hk',
    'giang.ong@bindo.com',
    'anson.chan@bindo.com',
    'jasmine.yu@bindo.com'
  ];

  const localPassword3 = 'Tajimaya123987';

  const handleLogin = () => {

    if (localAccounts.includes(account) && password === localPassword) {
      if (!funderName) {
        setError('Funder Name id not find');
        return;
      }
      const timeKey = new Date().getTime();
      Cookies.set(Constants.MEtABASE_TOKEN, `${timeKey}`, {
        expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      });
      navigate(`/metabase/embedding/funder?funder_name=${funderName}`);
    } else if (localAccounts2.includes(account) && password === localPassword2) {
      const timeKey = new Date().getTime();
      Cookies.set(Constants.MEtABASE_TOKEN_2, `${timeKey}`, {
        expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      });
      navigate(`/metabase/embedding/heycars-taxi-orders`);
    } else if (localAccounts3.includes(account) && password === localPassword3) {
      const timeKey = new Date().getTime();
      Cookies.set(Constants.MEtABASE_TOKEN_3, `${timeKey}`, {
        expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      });
      navigate(`/metabase/embedding/tajimaya-report`);
    } else {
      setError('Account or password error');
    }
  };

  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 flex bg-disabled-color">
      <div className="flex-1 flex justify-center items-center">
        <div className="flex flex-col rounded-[30px] min-w-[500px] bg-color-text-6 shadow-sign-in-card p-15">
          <div className="text-center font-semibold text-4xl">
            Wonder Analysis
          </div>
          <Label className="mt-8" value="Account" required />
          <Input
            autoComplete="off"
            className="border-error-color"
            style={{ WebkitBoxShadow: '0 0 0 1000px white inset' }}
            onChange={(value) => {
              setAccount(value);
              setError('');
            }}
          />
          <div></div>
          <Label className="mt-6" value="Password" required />
          <Input
            autoComplete="off"
            type="password"
            style={{ WebkitBoxShadow: '0 0 0 1000px white inset' }}
            onChange={(value) => {
              setPassword(value);
              setError('');
            }}
          />
          <Button
            className="w-full h-12 rounded-lg mt-6"
            disabled={!account || !password}
            onClick={handleLogin}
          >
            Sign In
          </Button>

          <div className="mt-4 text-sm text-error-color">{error}</div>
        </div>
      </div>
    </div>
  );
};

export default MetaBaseLogin;
