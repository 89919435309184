import React from 'react';
import type { IIconProps } from '@/types/common-types';

const WarningSideIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 80 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 80 80"
        fill="none"
      >
        <rect opacity="0.1" width="80" height="80" rx="40" fill="#FFA217" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 20C29 20 20 29 20 40C20 51 29 60 40 60C51 60 60 51 60 40C60 29 51 20 40 20ZM40 50C38.5 50 37.5 49 37.5 47.5C37.5 46 38.5 45 40 45C41.5 45 42.5 46 42.5 47.5C42.5 49 41.5 50 40 50ZM37.5 42.5H42.5V30H37.5V42.5Z"
          fill="#FFA217"
        />
      </svg>
    </div>
  );
};

export default WarningSideIcon;
