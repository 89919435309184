import { TFunction } from 'i18next';
import { transformField } from './field-utils';

export const getPaymentMethodTranslate = (value: string, t: TFunction<"translation", undefined>) => {
  let translateValue = transformField(value);

  switch (value) {
    case 'visa':
      translateValue = t('transaction_advice:visa');
      break;
    case 'mastercard':
    case 'master card':
      translateValue = t('transaction_advice:mastercard');
      break;
    case 'jcb':
      translateValue = t('transaction_advice:jcb');
      break;
    case 'tap_go':
      translateValue = t('transaction_advice:tap_go');
      break;
    case 'wechat':
      translateValue = t('transaction_advice:wechat');
      break;
    case 'wechat_pay':
      translateValue = t('transaction_advice:wechat_pay');
      break;
    case 'store credit':
      translateValue = t('transaction_advice:store_credit');
      break;
    case 'alipay':
      translateValue = t('transaction_advice:alipay');
      break;
    case 'unionpay_wallet':
      translateValue = t('transaction_advice:unionpay_wallet');
      break;
    case 'cup':
      translateValue = t('transaction_advice:cup');
      break;
    case 'cash':
      translateValue = t('transaction_advice:cash');
      break;
    case 'apple pay':
    case 'apple_pay':
      translateValue = t('transaction_advice:apple_pay');
      break;
    case 'fps':
      translateValue = t('transaction_advice:fps');
      break;
    case 'octopus':
    case 'octopus_business':
      translateValue = t('transaction_advice:octopus');
      break;
    case 'crypto_coin':
      translateValue = 'Corporate Income';
      break;
    default:
      break;
  }

  return translateValue;
};

export const getTransactionAdviceTranslate = (value: string, t: TFunction<"translation", undefined>) => {
  let translateValue = transformField(value);

  switch (value) {
    case 'sale':
    case 'sales':
      translateValue = t('transaction_advice:sales');
      break;
    case 'void':
      translateValue = t('transaction_advice:void');
      break;
    case 'refund':
      translateValue = t('transaction_advice:refund');
      break;
    case 'tips':
      translateValue = t('transaction_advice:tips');
      break;
    case 'hold_reversal':
      translateValue = t('transaction_advice:hold_reversal');
      break;
    case 'dispute':
      translateValue = t('transaction_advice:dispute');
      break;
    case 'hold':
      translateValue = t('transaction_advice:hold');
      break;
    case 'Fees Charged':
      translateValue = t('transaction_advice:fees_charged');
      break;
    case 'Rolling Reserve':
      translateValue = t('transaction_advice:rolling_reserve');
      break;
    case 'Adjustment Amount':
      translateValue = t('transaction_advice:adjustment_amount');
      break;
    case 'charge_back':
      translateValue = t('transaction_advice:charge_back');
      break;
    case 'pre_auth_complete':
      translateValue = t('transaction_advice:pre_auth_complete');
      break;
    case 'pre_auth':
      translateValue = t('transaction_advice:pre_auth');
      break;
    case 'dispute_reversal':
      translateValue = t('transaction_advice:dispute_reversal');
      break;
    case 'Payout Request':
      translateValue = t('transaction_advice:payout_request');
      break;
    case 'Payout Settlement':
      translateValue = t('transaction_advice:payout_settlement');
      break;
    case 'Payin Request':
      translateValue = t('transaction_advice:payin_request');
      break;
    case 'Payout Process':
      translateValue = t('transaction_advice:payout_process');
      break;
    case 'Payin Reversal':
      translateValue = t('transaction_advice:payin_reversal');
      break;
    case 'FX Buy Settlement':
      translateValue = t('transaction_advice:fx_buy_settlement');
      break;
    case 'System Adjustment':
      translateValue = t('transaction_advice:system_adjustment');
      break;
    case 'FX Sell Request':
      translateValue = t('transaction_advice:fx_sell_request');
      break;
    case 'FX Buy Reversa':
      translateValue = t('transaction_advice:fx_buy_reversal');
      break;
    case 'Payout Reversal':
      translateValue = t('transaction_advice:payout_reversal');
      break;
    case 'FX Sell Settlement':
      translateValue = t('transaction_advice:fx_sell_settlement');
      break;
    case 'FX Buy Request':
      translateValue = t('transaction_advice:fx_buy_request');
      break;
    case 'FX Sell':
      translateValue = t('transaction_advice:fx_sell');
      break;
    case 'FX Sell Reversal':
      translateValue = t('transaction_advice:fx_sell_reversal');
      break;
    case 'Incoming':
      translateValue = t('transaction_advice:incoming');
      break;
    case 'Outgoing':
      translateValue = t('transaction_advice:outgoing');
      break;
    case 'Available':
      translateValue = t('transaction_advice:available');
      break;
    case 'representment':
      translateValue = t('transaction_advice:representment');
      break;
    case 'pre_dispute':
      translateValue = t('transaction_advice:pre_dispute');
      break;

    default:
      break;
  }

  return translateValue;
};
