import React from 'react';
import type { IIconProps } from '@/types/common-types';

const WarningIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 36 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 12C7.4 12 7 11.6 7 11C7 10.4 7.4 10 8 10C8.6 10 9 10.4 9 11C9 11.6 8.6 12 8 12ZM7 9H9V4H7V9Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default WarningIcon;
