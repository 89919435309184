import type { IExchangeDetailsProps } from '../type';
import type { IObject } from '@/types/common-types';
import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import Label from '@/components/common/label';
import DetailsItem from '@/pages/account-balance/components/details-item-component';
import {
  handleMount,
  handleUtcDate,
  getExchangeStatusLabel,
} from '@/utils/account-balance-untils';
import { getCurrencyIcon } from '@/utils';
import Skeleton from '@/components/skeleton';
import { getFxConversionDetails } from '@/data/account-balance';
import ScrollNum from '@/components/common/scroll-number';
import CopyIcon from '@/components/svg/copy-icon';
import ExchangeIcon from '@/pages/account-balance/account-balances-icons/exchange-icon';
import { useTranslation } from 'react-i18next';

const ExchangeDetailsPage = (props: IExchangeDetailsProps) => {
  const { t } = useTranslation("account_balance");
  const [record, setRecord] = useState<IObject>({});
  const [copySuccess, setCopySuccess] = useState(false);
  const [initLoading, setInitLoaing] = useState(false);
  const { businessId, recordId } = props;

  const {
    id,
    sell_currency = '',
    buy_currency = '',
    sell_amount,
    buy_amount,
    exchange_rate,
    settled_at = '',
    error_code = '',
    error_message = '',
    transfer_type = '',
    p_created_at = '',
    transfer_status = '',
  } = record || {};

  useEffect(() => {
    getReacord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, recordId]);

  const { disabled = true } = props;

  const getReacord = async () => {
    setInitLoaing(true);
    const data = await getFxConversionDetails({
      recordId,
      pBusinessId: businessId,
    });
    if (data) {
      setRecord(data);
      setInitLoaing(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(id).then(() => {
      setCopySuccess(true);
    });
  };

  const { status: analysisStatus, component } =
    getExchangeStatusLabel(transfer_status);

  const showErrorCode = ['declined', 'reversed', 'terminated'].includes(
    analysisStatus
  );

  const handleAmountStyle = (color = 'text-color-text-1') => {
    let style = color;
    if (showErrorCode) {
      style = 'line-through text-color-text-2';
    }
    return style;
  };

  return (
    <Transition.Root show={true} appear={true}>
      <Transition.Child
        className="rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 h-fit"
        enter="duration-700 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-10 opacity-0"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="items-center lg:flex">
          <div>
            <div className="flex flex-col md:flex-row whitespace-nowrap">
              <ExchangeIcon />
            </div>
          </div>
          <div className="flex flex-col ml-4">
            {/* <span>{`+${fx_buy_amount} ${fx_buy_currency}`}</span> */}
            <div className="flex items-center space-x-1 text-3xl font-bold">
              <span className={`${handleAmountStyle()}`}>
                <ScrollNum
                  value={buy_amount}
                  prefix="+"
                  size={36}
                  boxClass={'h-9'}
                />
              </span>
              <span className="text-color-text-1 font-medium">{`${buy_currency}`}</span>
            </div>
            <div className="text-color-text-2 space-x-1 text-sm">
              <span
                className={`${handleAmountStyle('text-color-text-2')}`}
              >{`-${handleMount(sell_amount)}`}</span>
              <span>{sell_currency}</span>
            </div>
          </div>
        </div>
      </Transition.Child>

      <div className="flex w-full space-x-6 mt-6">
        <Transition.Child
          className="flex-1"
          enter="duration-350 transform transition ease-in-out"
          enterFrom="-translate-x-1 translate-y-8 opacity-60"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="duration-500 transform transition"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div
            className={`w-full rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit`}
          >
            <div className="font-bold text-xl text-color-text-1 flex items-center md:text-xl">
              {t('account_balance:wallet_conversion')}
            </div>
            <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
                <DetailsItem
                  title={t('account_balance:sell_amount')}
                  loading={initLoading}
                  content={
                    <div className="flex space-x-1.5 items-center text-sm">
                      <span>{`-${handleMount(sell_amount)}`}</span>
                      <span>{sell_currency}</span>
                      <span>{getCurrencyIcon(sell_currency)}</span>
                    </div>
                  }
                />
                <DetailsItem
                  title={t('account_balance:status')}
                  loading={initLoading}
                  content={component}
                />
                <div className="w-full col-auto md:col-span-2">
                  <Label disabled={disabled} value={'ID'}></Label>
                  {initLoading && (
                    <Skeleton
                      row={2}
                      width={['50%', '50%']}
                      skeletonItemClass={`h-4 rounded-2xl mb-4`}
                    />
                  )}
                  {!initLoading && (
                    <div className="py-2 text-color-text-1 font-medium pr-6 flex items-center space-x-2">
                      <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-color-text-1 font-medium text-sm">
                        {id}
                      </span>
                      <span
                        className="cursor-pointer group"
                        onClick={handleCopy}
                      >
                        <CopyIcon className="text-primary-color" />
                      </span>
                      <span
                        className={`text-primary-color text-xs cursor-pointer ${
                          copySuccess ? 'visible' : 'invisible'
                        }`}
                      >
                        {t('account_balance:copied')}
                      </span>
                    </div>
                  )}
                </div>
                {/* <DetailsItem
                  title="Type"
                  content={`${type.slice(0, 1).toUpperCase()}${type
                    .slice(1)
                    .toLowerCase()}`}
                /> */}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
                <DetailsItem
                  title={t('account_balance:buy_amount')}
                  loading={initLoading}
                  // content={`${handleMount(fx_buy_amount)} ${fx_buy_currency} ${getCurrencyIcon(fx_buy_currency)}`}
                  content={
                    <div className="flex space-x-1.5 items-center text-sm">
                      <span>{`+${handleMount(buy_amount)}`}</span>
                      <span>{buy_currency}</span>
                      <span>{getCurrencyIcon(buy_currency)}</span>
                    </div>
                  }
                />
                <DetailsItem
                  title={t('account_balance:type')}
                  loading={initLoading}
                  content={transfer_type}
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
                <DetailsItem
                  title={t('account_balance:exchange_rate')}
                  content={`${exchange_rate} ${sell_currency} to ${buy_currency}`}
                  loading={initLoading}
                />
                <DetailsItem
                  title={t('account_balance:transacted_on')}
                  loading={initLoading}
                  content={handleUtcDate(p_created_at)}
                />
                {['settled', 'Initiated'].includes(analysisStatus) && (
                  <DetailsItem
                    title={`${
                      analysisStatus === 'settled'
                        ? t('account_balance:settled_on')
                        : t('account_balance:est_settled_on')
                    }`}
                    loading={initLoading}
                    content={handleUtcDate(settled_at)}
                  />
                )}
                {showErrorCode && (
                  <DetailsItem
                    title={t('account_balance:reason_code')}
                    content={
                      <>
                        {error_code && error_message ? (
                          <>
                            <span className="text-ellipsis overflow-hidden text-[#FC2E01]">
                              {error_code}{' '}
                            </span>
                            <span className="text-ellipsis overflow-hidden">
                              {error_message}
                            </span>
                          </>
                        ) : (
                          '--'
                        )}
                      </>
                    }
                    loading={initLoading}
                  />
                )}
              </div>
            </>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};

export default ExchangeDetailsPage;
