import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ApplePayIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="52"
        height="35"
        viewBox="0 0 52 35"
        fill="none"
      >
        <path
          d="M14.7741 13.4299C13.9632 13.4299 13.309 13.9247 12.8865 13.9247C12.4435 13.9247 11.8507 13.4574 11.1488 13.4574C9.81313 13.4574 8.45703 14.5776 8.45703 16.6736C8.45703 17.9862 8.96131 19.3676 9.58143 20.2678C10.1062 21.0169 10.5764 21.6354 11.2442 21.6354C11.9052 21.6354 12.1982 21.1956 13.0228 21.1956C13.8542 21.1956 14.045 21.6217 14.7741 21.6217C15.5033 21.6217 15.9871 20.9551 16.4437 20.2953C16.948 19.5325 17.166 18.7903 17.1728 18.7559C17.1319 18.7422 15.7418 18.1718 15.7418 16.5637C15.7418 15.1755 16.8321 14.557 16.8934 14.5089C16.1779 13.4574 15.0808 13.4299 14.7741 13.4299ZM14.3993 12.5434C14.7264 12.138 14.9581 11.5882 14.9581 11.0315C14.9581 10.9559 14.9513 10.8803 14.9377 10.8185C14.4061 10.8391 13.7519 11.1758 13.3635 11.6363C13.0637 11.9868 12.7775 12.5434 12.7775 13.1001C12.7775 13.1825 12.7911 13.2719 12.7979 13.2994C12.832 13.3062 12.8865 13.3131 12.941 13.3131C13.4248 13.3131 14.0381 12.9832 14.3993 12.5434Z"
          fill="black"
        />
        <path
          d="M20.5937 11.595V21.5117H22.3519V18.2611H24.4848C26.4747 18.2611 27.8649 16.9142 27.8649 14.9487C27.8649 12.9489 26.5224 11.595 24.5598 11.595H20.5937ZM22.3519 13.0795H24.0964C25.3639 13.0795 26.0794 13.7323 26.0794 14.9487C26.0794 16.1307 25.3435 16.7905 24.0896 16.7905H22.3519V13.0795Z"
          fill="black"
        />
        <path
          d="M31.9059 20.2953C31.1563 20.2953 30.6179 19.9174 30.6179 19.2851C30.6179 18.6735 31.0609 18.3299 32.0081 18.268L33.6913 18.1581V18.7559C33.6913 19.6287 32.9281 20.2953 31.9059 20.2953ZM31.4084 21.6354C32.3829 21.6354 33.2006 21.2093 33.6163 20.4809H33.7322V21.5117H35.3609V16.3781C35.3609 14.7838 34.2842 13.8423 32.3693 13.8423C30.5975 13.8423 29.3641 14.6876 29.2278 16.0139H30.8224C30.9791 15.5054 31.5106 15.2305 32.2875 15.2305C33.2006 15.2305 33.6913 15.6428 33.6913 16.3781V17.0104L31.7696 17.1272C29.9569 17.2372 28.9416 18.0206 28.9416 19.3813C28.9416 20.7558 29.9706 21.6354 31.4084 21.6354Z"
          fill="black"
        />
        <path
          d="M37.9845 24.24C39.654 24.24 40.4513 23.6215 41.0851 21.7522L43.7768 13.9935H41.9914L40.2537 19.8624H40.1379L38.3933 13.9935H36.5398L39.2043 21.5461L39.1157 21.8759C38.8976 22.57 38.4955 22.8449 37.78 22.8449C37.6642 22.8449 37.412 22.8381 37.3166 22.8174V24.2125C37.4257 24.2331 37.8822 24.24 37.9845 24.24Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default ApplePayIcon;
