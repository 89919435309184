import React from 'react';
import type { IIconProps } from '@/types/common-types';

const AlipayIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 36 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18" cy="18" r="18" fill="#F8FAFC" />
        <mask
          id="mask0_8856_139127"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="6"
          y="6"
          width="25"
          height="25"
        >
          <circle cx="18.1256" cy="18.1256" r="11.9107" fill="#1677FF" />
        </mask>
        <g mask="url(#mask0_8856_139127)">
          <circle cx="18.1256" cy="18.1256" r="11.9107" fill="#1677FF" />
          <path
            d="M24.7877 20.9605C23.9826 20.6883 22.8997 20.2715 21.695 19.8319C22.4182 18.5625 22.9968 17.1179 23.3774 15.5478H19.4055V14.1052H24.27V13.3002H19.4055V10.8945H17.4204C17.0722 10.8945 17.0722 11.2409 17.0722 11.2409V13.2983H12.1524V14.1033H17.0722V15.5459H13.0089V16.351H20.888C20.6007 17.352 20.2124 18.2941 19.7537 19.1486C17.1978 18.2979 14.4686 17.609 12.7557 18.0334C11.6595 18.3055 10.9534 18.7908 10.5385 19.3009C8.63534 21.6361 9.99992 25.1836 14.0194 25.1836C16.3965 25.1836 18.6861 23.8476 20.4598 21.6456C23.1072 22.9284 30.036 26.0669 30.036 26.0669V22.9267C30.036 22.9267 27.69 21.9387 24.7877 20.9605ZM13.6445 23.8267C10.51 23.8267 9.58312 21.3373 11.1323 19.9746C11.65 19.5141 12.594 19.2895 13.0964 19.24C14.9577 19.0554 16.682 19.771 18.7165 20.7721C17.2872 22.6524 15.4659 23.8267 13.6445 23.8267Z"
            fill="white"
          />
        </g>
      </svg>
    </div>
  );
};

export default AlipayIcon;
