import type { ISettlementDetailsContent } from './type';
import Label from '@/components/common/label';
import { Transition } from '@headlessui/react';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';
import Skeleton from '@/components/skeleton';
import { handleUtcDate } from '../../../utils/account-balance-untils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';


const SettlementDetailsContent = (props: ISettlementDetailsContent) => {
  const { dayEndData, disabled = true, initLoading } = props;
  const { t } = useTranslation("account_balance");
  const { lang = 'en' } = useParams();

  const {
    id,
    transfer_status = '',
    total_amount: totalAmount = '',
    currency = 'HKD',
    total_count,
    open_at,
    note,
    closed_schedule_at,
    estimated_settled_at,
    settled_at,
    treasury_services_payin_amount: treasuryServicesPayinAmount = '',
  } = dayEndData || {};

  const handleSettlementStatus = () => {
    let color = 'text-primary-color bg-primary-color';
    let returnString = '--';
    //   "Initiated
    //   "Settled"
    //   "Reversed"
    //   "Suspended"
    //   "Exception"

    //   ///Card
    //  "Approved"
    //   "Decline"
    //   "Authorized"
    if (transfer_status === 'Initiated' || !transfer_status) {
      returnString = 'pending';
    } else {
      returnString = transfer_status;
    }

    if (returnString === 'Settled') {
      color = 'text-success-color bg-success-color';
    } else if (returnString === 'Reversed') {
      color = 'text-error-color bg-error-color';
    } else if (returnString === 'pending') {
      color = 'text-warning-color bg-warning-color';
    } else if (returnString === 'Reversed') {
      color = 'text-purple-tag-color bg-purple-tag-color';
    }

    return (
      <span
        className={`px-3 py-1.5 rounded-3xl text-xs ${color} bg-opacity-10`}
      >
        {returnString.toUpperCase()}
      </span>
    );
  };

  const getAmount = () => {
    let amount = '';
    if (treasuryServicesPayinAmount) {
      amount = treasuryServicesPayinAmount;
    } else {
      amount = totalAmount;
    }
    return amount;
  };

  const handleAmount = () => {
    let returnValue = getAmount();
    const temp = new BigNumber(returnValue);
    returnValue = `${temp.toFormat(2, 1)}`;

    return <span>{`${returnValue} ${currency ? currency : 'HKD'}`}</span>;
  };

  const handleDate = (date: string) => {
    if (!date) return <span>--</span>;
    const dayDate = dayjs(date);

    return (
      <span className="whitespace-nowrap">
        {`${dayDate.format('MMM D, YYYY HH:mm')}`}
      </span>
    );
  };

  return (
    <div className="flex w-full space-x-6 mt-6">
      <Transition.Child
        className="flex-1"
        enter="duration-350 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-8 opacity-60"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div
          className={`w-full rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit`}
        >
          {!initLoading && (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
                <div className="w-full">
                  <Label
                    disabled={disabled}
                    value={t('account_balance:batch_id')}
                  ></Label>
                  <div className="py-2 text-color-text-1 text-sm font-medium pr-6">
                    {id}
                  </div>
                </div>
                <div className="w-full">
                  <Label
                    disabled={disabled}
                    value={t('account_balance:settlement_status')}
                  ></Label>
                  <div className="py-2 text-color-text-1 text-xs font-medium pr-6">
                    {handleSettlementStatus()}
                  </div>
                </div>
                <div className="w-full">
                  <Label
                    disabled={true}
                    value={t('account_balance:tota_settled_amount')}
                  ></Label>
                  <div className="py-2 text-color-text-1 text-sm font-medium pr-6">
                    {handleAmount()}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-2 md:gap-y-6">
                <div className="w-full">
                  <Label
                    disabled={disabled}
                    value={t('account_balance:number_of_transactions')}
                  ></Label>
                  <div className="py-2 text-color-text-1 text-sm font-medium pr-6">
                    {total_count}
                  </div>
                </div>
                <div className="w-full">
                  <Label
                    disabled={true}
                    value={t('account_balance:opened_on')}
                  ></Label>
                  <div className="py-2 text-color-text-1 text-sm font-medium pr-6">
                    {handleUtcDate(open_at)}
                  </div>
                </div>
                <div className="w-full">
                  <Label
                    disabled={true}
                    value={t('account_balance:closed_on')}
                  ></Label>
                  <div className="py-2 text-color-text-1 text-sm font-medium pr-6">
                    {handleDate(closed_schedule_at)}
                  </div>
                </div>
                <div className="w-full">
                  <Label
                    disabled={disabled}
                    value={
                      transfer_status === 'Settled'
                        ? t('account_balance:settlement_time')
                        : t('account_balance:est_settlement_time')
                    }
                  ></Label>
                  <div className="py-2 text-color-text-1 text-sm font-medium pr-6">
                    {handleUtcDate(settled_at || estimated_settled_at)}
                  </div>
                </div>
                {note && (
                  <div className="w-[420%]">
                    <Label
                      disabled={true}
                      value={t('account_balance:remark')}
                    ></Label>
                    <div className="py-2 text-color-text-1 text-sm font-medium pr-6">
                      {lang === 'en' ? note['en-US'] : note[lang]}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {initLoading && <Skeleton row={2} width={['70%', '100%']} />}
        </div>
      </Transition.Child>
    </div>
  );
};

export default SettlementDetailsContent;
