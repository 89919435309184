import type { IObject } from '@/types/common-types';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getPaymentMethodTranslate } from '@/utils/transaction-utils';
import { transformField } from '@/utils/field-utils';
import CopyIcon from '@/components/svg/copy-icon';
import { triggerWonderRoute, handlePrice, ConvertTimeZone } from '../../../utils/terminal-untils';

interface IPaymentDetailsPanelParam {
  record: IObject;
  dateFormat: string;
  type: React.ReactNode;
}

let tipsTimeout: any;

const PaymentDetailsPanel: React.FC<IPaymentDetailsPanelParam> = (props) => {
  const { record = {}, type, dateFormat } = props;
  const {
    amount,
    payment_method: paymentMethod,
    created_at = '',
    payment_data = {},
    currency = 'HKD',
    payment,
  } = record;
  const {
    new_gateway_txn_id = '',
    rrn = '',
    brn = '',
    last_4_digits = '',
    credit_card_type,
    payment_method,
  } = payment_data;
  const { t } = useTranslation(["extended_service", "transaction_advice"]);

  const [copySuccess, setCopySuccess] = useState<boolean>(false);

  useEffect(() => {
    getPaymentMethod();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (copySuccess) {
      clearTimeout(tipsTimeout);
      tipsTimeout = setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }
    // eslint-disable-next-line
  }, [copySuccess]);

  const getPaymentMethod = () => {
    let returnValue = '';
    if (paymentMethod === 4 || paymentMethod === 18) {
      if (credit_card_type === 'cup') {
        return `UnionPay**${last_4_digits}`
      }
      returnValue = transformField(`${credit_card_type}**${last_4_digits}`);
    } else if (payment_method === 'other_payment') {
      returnValue = payment;
    } else {
      returnValue = getPaymentMethodTranslate(payment_method, t);
    }
    return returnValue;
  };

  const handleCopy = (copyValue: string) => {
    triggerWonderRoute(copyValue, 'copy');
  };

  return (
    <>
      <div className="bg-primary-background-color rounded-xl p-4 text-base">
        <div>
          <div className="flex justify-between items-center">
            <span className="text-[#8E8E93]">
              {t('extended_service:payment_amount')}
            </span>
            <span className={` max-w-[190px] truncate `}>{`${handlePrice(
              amount
            )} ${currency}`}</span>
          </div>
          <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
        </div>
        <div>
          <div className="flex justify-between items-center">
            <div className="text-[#8E8E93]">{t('extended_service:type')}</div>
            <div className={`  max-w-[180px] break-words text-right  flex`}>
              {type}
              {/* <div
                                className={`font-medium rounded-3xl text-xs px-3 py-1 bg-[#808080] text-white`}
                            >
                                Void
                            </div> */}
            </div>
          </div>
          <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
        </div>
        <div>
          <div className="flex justify-between items-center ">
            <div className="text-[#8E8E93]">
              {t('extended_service:paid_via')}
            </div>
            <div className={`  max-w-[180px] break-words text-right  `}>
              {getPaymentMethod()}
            </div>
          </div>
          <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
        </div>
        <div>
          <div className="flex justify-between items-center">
            <span className="text-[#8E8E93]">
              {t('extended_service:transaction_time')}
            </span>
            <span className={`  max-w-[180px] break-words text-right  `}>
              {/* {dayjs(created_at).format(`${dateFormat} HH:mm`)} */}
              {ConvertTimeZone(created_at, `${dateFormat} HH:mm:ss`)}
            </span>
          </div>
          <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
        </div>
        <div>
          <div className="flex justify-between items-center">
            <span className="text-[#8E8E93]">
              {t('extended_service:transaction_id')}
            </span>
            <span className={` flex `}>
              <span className={`  max-w-[180px] break-words text-right  `}>
                {' '}
                {new_gateway_txn_id}
              </span>
              <span>
                <CopyIcon
                  onClick={() => handleCopy(new_gateway_txn_id)}
                  className="text-primary-color"
                />
              </span>
            </span>
          </div>
          <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
        </div>
        <div>
          <div className="flex justify-between items-center">
            <span className="text-[#8E8E93]">{t('extended_service:brn')}</span>
            <span className={``}>{brn}</span>
          </div>
          <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
        </div>
        <div>
          <div className="flex justify-between items-center">
            <span className="text-[#8E8E93]">{t('extended_service:rrn')}</span>
            <span className={`max-w-[180px] break-words text-right  `}>
              {rrn}
            </span>
          </div>
          {/* <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " /> */}
        </div>
      </div>
    </>
  );
};

export default PaymentDetailsPanel;
