import React from 'react';
import type { IIconProps } from '@/types/common-types';

const SearchIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.4371 17.8743C6.33609 17.8743 3 14.5382 3 10.4371C3 6.33609 6.33609 3 10.4371 3C14.5382 3 17.8743 6.33609 17.8743 10.4371C17.8743 14.5382 14.5382 17.8743 10.4371 17.8743ZM10.4373 5.12505C7.50813 5.12505 5.12505 7.50813 5.12505 10.4373C5.12505 13.3665 7.50813 15.7495 10.4373 15.7495C13.3665 15.7495 15.7495 13.3665 15.7495 10.4373C15.7495 7.50813 13.3665 5.12505 10.4373 5.12505ZM17.1429 15.6367L19.6854 18.1792C20.1008 18.5946 20.1008 19.266 19.6854 19.6815C19.4782 19.8886 19.2062 19.9928 18.9342 19.9928C18.6622 19.9928 18.3902 19.8886 18.1831 19.6815L15.6406 17.139C16.2027 16.7024 16.7063 16.1988 17.1429 15.6367Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default SearchIcon;
