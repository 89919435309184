import AddCreditCardIcon from '../terminal-icons/add-credit-card-icon';
import AlipayIcon from '../terminal-icons/alipay-icon';
import ApplePayIcon from '../terminal-icons/apple-pay-icon';
import FPSIcon from '../terminal-icons/fps-icon';
// import LikeIcon from '../terminal-icons/like-icon';
import SelectedIcon from '../terminal-icons/selected-icon';
import UnionPayWalletIcon from '../terminal-icons/unionpay-wallet-icon';
import UnselectedIcon from '../terminal-icons/unselected-icon';
// import WarningIcon from '../terminal-icons/warning-icon';
import WeChatPayIcon from '../terminal-icons/wechat-pay-icon';
// import WonderIcon from '../terminal-icons/wonder-icon';
import PayTypes from '../utils/payment/pay-types';
import { Transition } from '@headlessui/react';
import { useState } from 'react';
import { IMask, IMaskInput } from 'react-imask';
import type { ExtendFactoryArgOptions } from 'imask/masked/factory';
import trim from 'lodash/trim';
import { Controller, useForm } from 'react-hook-form';
import AlipayHKIcon from '../terminal-icons/alipay-hk-icon';
import CloseCreditCardIcon from '../terminal-icons/close-credit-card-icon';
import DeleteCreditCardPCIcon from '../terminal-icons/delete-credit-card-pc-icon';
import { ICreditCard, ITerminalOrdersPaymentPageProps } from '@/types/terminal-type';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getCardType, handlePrice } from '@/utils/terminal-untils';
import FormViewContext from '@/components/form-view';
import Label from '@/components/common/label';
import Input from '@/components/common/input';
import Button from '@/components/common/button';
import AntdModal from '@/components/common/antd/modal';
import StepWrapper from '../components/step-wrapper';
import PanelTitle from '../components/panel-title';
import Skeleton from '@/components/skeleton';
import { useUserAction } from '@/utils/common';

interface IPaymentWebPageProps extends ITerminalOrdersPaymentPageProps {
  businessName: string;
  showApplePay: boolean;
  cards: ICreditCard[];
  orderNumber: string;
  handlePay: () => void;
  handleSaveCard: (card: ICreditCard) => Promise<boolean>;
  setMethod: (method: PayTypes | ICreditCard | undefined) => void;
  handleDeleteCard: (card: ICreditCard) => void;
  loadingData: boolean;
  method?: PayTypes | ICreditCard | 'balance';
}

const mmformet: ExtendFactoryArgOptions<any> = {
  mask: IMask.MaskedRange,
  form: 1,
  to: 12,
};

const PaymentWebPage: React.FC<IPaymentWebPageProps> = (props) => {
  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const navigate = useNavigate();
  const { lang = 'en' } = useParams();
  const [newCard, setNewCard] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [cardDate, setCardDate] = useState('');
  const [cardCVV, setCardCVV] = useState('');
  const [showBack, setShowBack] = useState(false);
  const [selectCard, setSelectCard] = useState<ICreditCard | undefined>(
    undefined
  );

  const form = useForm({ mode: 'all' });

  const { handleSubmit, control, reset } = form;

  const {
    businessID,
    isPhone,
    inApp,
    usePaymentMethods,
    orderNumber,
    handlePay,
    method,
    setMethod,
    invoice,
    // available,
    // currencyCode,
    cards,
    showApplePay,
    handleSaveCard,
    // businessName,
    handleDeleteCard,
    loadingData,
  } = props;
  const { unpaid_total = 0, currency = 'HKD' } = invoice || {};
  useUserAction(inApp ? true : false);
  
  const handleCancel = () => {
    setNewCard(!newCard);
    reset({
      number: '',
      holder_name: '',
      card_date: '',
      cvv: '',
    });
    setCardNumber('');
    setCardName('');
    setCardDate('');
    setCardCVV('');
  };

  // const sufficient = minus(available, unpaid_total) >= 0;

  const handleSave = async () => {
    const dateArr = cardDate.split('/');
    if (dateArr[1]) {
      const success = await handleSaveCard({
        number: cardNumber,
        holder_name: cardName,
        exp_month: dateArr[0],
        exp_year: dateArr[1],
        id: '',
        token: '',
      });

      if (success) {
        handleCancel();
      }
    }
  };

  const handleCardDeleteInquiries = (card: ICreditCard) => {
    setSelectCard(card);
  };

  const handleDelete = async () => {
    if (selectCard) {
      await handleDeleteCard(selectCard);
      setSelectCard(undefined);
    }
  };

  const handleBack = () => {
    if (orderNumber) {
      navigate(`/${lang}/businesses/${businessID}/terminal/orders/${orderNumber}`);
    } else {
      navigate(`/${lang}/businesses/${businessID}/terminal/orders/check`);
    }
  };

  return (
    <StepWrapper active={2} businessID={businessID}>
      <Transition.Child
        className="min-h-full flex flex-col"
        enter="duration-500 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-10 opacity-40"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="flex-1 bg-primary-background-color p-6 rounded">
          <PanelTitle title={t('extended_service:choose_method')} />
          <div className="mb-6 mt-8 px-6 py-4 bg-disabled-color rounded-2xl">
            {loadingData && (
              <Skeleton 
                row={1}
                width={['100%']}
                skeletonItemClass="h-9 rounded-2xl"
              />
            )}
            {!loadingData && (
              <div className="text-[30px] leading-[36px] text-color-text-1">
                <span className="font-bold">{handlePrice(unpaid_total)}</span>{' '}
                {currency}
              </div>
            )}
            <div className="mt-2 text-color-text-2 text-sm font-medium">
              {t('extended_service:invoice_amount')}
            </div>
          </div>

          {/* <div className="mb-4 bg-[#E7F1FB] rounded-2xl">
            <div className="flex p-4 pb-3 items-center">
              <WonderIcon />
              <div className="ml-2 font-semibold flex-1">
                {t('extended_service:merchant_account')}
              </div>
              <LikeIcon />
            </div>
            <div className="p-0.5">
              <div className="bg-white p-4 flex rounded-2xl justify-between leading-5">
                <div className="">
                  <div className="font-semibold">{businessName}</div>
                  <div className="text-primary-color mt-1">
                    {t('extended_service:current_balance')}{' '}
                    {handlePrice(available)} {currencyCode}
                  </div>
                  {!sufficient && (
                    <div className="mt-2 text-sm leading-[18px] flex items-center">
                      <WarningIcon />
                      <div className="ml-1 text-error-color">
                        {t('extended_service:insufficient')}
                      </div>
                    </div>
                  )}
                </div>
                {method === 'balance' ? <SelectedIcon /> : ''}
              </div>
            </div>
          </div> */}

          <div className="mb-2 font-medium text-color-text-1 leading-5">
            {t('extended_service:credit_card')}
          </div>

          <div className="mb-4 text-color-text-1 leading-5 flex flex-col gap-y-4">
            {loadingData && (
              <>
                <Skeleton 
                  row={1}
                  width={['100%']}
                  skeletonItemClass="h-16 rounded-2xl"
                />
                <Skeleton 
                  row={1}
                  width={['100%']}
                  skeletonItemClass="h-16 rounded-2xl"
                />
              </>
            )}
            {cards.map((card) => {
              const { number, id } = card;
              const { icon, type } = getCardType(number);
              const isSelected =
                method && typeof method !== 'string' && method.id === id;
              return (
                <div
                  onClick={() => setMethod(card)}
                  key={id}
                  className={`${
                    isSelected
                      ? 'border-primary-color'
                      : 'border-border-color hover:border-primary-color'
                  } flex items-center justify-between px-4 py-3 cursor-pointer border rounded-lg text-xs`}
                >
                  <div className="flex items-center">
                    {icon}
                    <div className="mx-2 text-sm">{type}</div>
                    <DeleteCreditCardPCIcon
                      className="hover:text-primary-color text-icon-color"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCardDeleteInquiries(card);
                      }}
                    />
                  </div>
                  {isSelected ? <SelectedIcon /> : <UnselectedIcon />}
                </div>
              );
            })}
            <div
              className={`${
                newCard
                  ? 'border-primary-color'
                  : 'border-border-color hover:border-primary-color'
              } border rounded-lg`}
            >
              <div
                className="flex items-center text-color-text-1 p-4 cursor-pointer"
                onClick={() => {
                  handleCancel();
                  setMethod(undefined);
                }}
              >
                {newCard ? <CloseCreditCardIcon /> : <AddCreditCardIcon />}
                <div className="flex-1 ml-2 font-medium text-sm leading-[26px]">
                  {t('extended_service:new_credit_card')}
                </div>
                {newCard ? <SelectedIcon /> : null}
              </div>
              <div
                className="transition-all ease-in duration-300 overflow-hidden"
                style={{ maxHeight: newCard ? '380px' : '0px' }}
              >
                <div className="p-4 pt-0 flex flex-col gap-y-4">
                  <FormViewContext.Provider value={{ viewConfig: undefined }}>
                    <Controller
                      name={'number'}
                      control={control}
                      rules={{
                        validate: (value) => {
                          let msg = undefined;
                          if (value) {
                            if (
                              !(
                                /^(?:6011|65\d{0,2}|64[4-9]\d?)\d{12}/.test(
                                  value
                                ) ||
                                /^3(?:0([0-5]|9)|[689]\d?)\d{11}/.test(value) ||
                                /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{12}/.test(
                                  value
                                ) ||
                                /^(?:2131|1800)\d{11}/.test(value) ||
                                /^(?:35\d{0,2})\d{12}/.test(value) ||
                                /^4\d{15}/.test(value) ||
                                /^62\d{14}/g.test(value)
                              )
                            ) {
                              msg = t('extended_service:format_error');
                            }
                          } else {
                            msg = t('common:no_data_hint');
                          }
                          return msg;
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <div>
                          <Label
                            value={t('extended_service:card_number')}
                            required
                          />
                          <IMaskInput
                            mask={[{ mask: '0000 0000 0000 0000' }]}
                            unmask={true}
                            value={value}
                            placeholder={t(
                              'extended_service:enter_card_number'
                            )}
                            onAccept={(value) => {
                              onChange(value);
                              setCardNumber(value);
                            }}
                            className={`w-full h-10 hover:border-primary-color rounded text-sm py-2.5 px-3 border ${error
                              ? 'border-error-color'
                              : 'border-border-color'
                              } shadow-box-drop outline-none placeholder:text-color-text-5`}
                          />
                          {error && (
                            <div className="mt-1 text-sm text-error-color">
                              {error.message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                    <Controller
                      name={'holder_name'}
                      control={control}
                      rules={{
                        required: t('common:no_data_hint'),
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <div>
                          <Label
                            value={t('extended_service:holder_name')}
                            required
                          />
                          <Input
                            autoComplete="off"
                            maxLength={256}
                            value={value}
                            error={error}
                            allowClear={false}
                            placeholder={t(
                              'extended_service:enter_holder_name'
                            )}
                            onChange={(value) => {
                              let holderName = value;
                              if (value.startsWith(' ')) {
                                holderName = trim(value);
                              }
                              onChange(holderName);
                              setCardName(holderName);
                            }}
                          />
                        </div>
                      )}
                    />
                    <div className="flex gap-x-4">
                      <Controller
                        name={'card_date'}
                        control={control}
                        rules={{
                          validate: (value) => {
                            let msg = undefined;
                            if (value) {
                              if (value.length !== 5) {
                                msg = t('extended_service:format_error');
                              }
                            }
                            return msg;
                          },
                          required: t('common:no_data_hint'),
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <div className="flex-1">
                            <Label
                              value={t('extended_service:expiry_date')}
                              required
                            />
                            <IMaskInput
                              mask={'MM/YY'}
                              blocks={{
                                YY: '00',
                                MM: mmformet,
                              }}
                              value={value}
                              placeholder="MM/YY"
                              className={`w-full h-10 hover:border-primary-color rounded text-sm py-2.5 px-3 border ${
                                error
                                  ? 'border-error-color'
                                  : 'border-border-color'
                              } shadow-box-drop outline-none placeholder:text-color-text-5`}
                              onAccept={(value) => {
                                onChange(value);
                                setCardDate(value);
                              }}
                            />
                            {error && (
                              <div className="mt-1 text-sm text-error-color">
                                {error.message}
                              </div>
                            )}
                          </div>
                        )}
                      />
                      <Controller
                        name={'cvv'}
                        control={control}
                        rules={{
                          required: t('common:no_data_hint'),
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <div className="flex-1">
                            <Label value="CVV" required />
                            <Input
                              type="number"
                              inputMode="numeric"
                              autoComplete="off"
                              maxLength={4}
                              value={value}
                              error={error}
                              placeholder={t('extended_service:enter_cvv')}
                              onChange={(value) => {
                                onChange(value);
                                setCardCVV(value);
                              }}
                            />
                          </div>
                        )}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-x-4">
                      <Button onClick={handleCancel} styleType="Secondary">
                        {t('common:cancel')}
                      </Button>
                      <Button
                        onClick={handleSubmit(handleSave)}
                        disabled={
                          !(cardNumber && cardDate && cardName && cardCVV)
                        }
                      >
                        {t('common:save')}
                      </Button>
                    </div>
                  </FormViewContext.Provider>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2 font-medium leading-5 text-color-text-1">
            {t('extended_service:others')}
          </div>

          <div className="text-sm flex flex-col gap-y-4">
            {loadingData && (
              <>
                <Skeleton 
                  row={1}
                  width={['100%']}
                  skeletonItemClass="h-16 rounded-2xl"
                />
                <Skeleton 
                  row={1}
                  width={['100%']}
                  skeletonItemClass="h-16 rounded-2xl"
                />
              </>
            )}
            {usePaymentMethods.includes(PayTypes.APPLE_PAY) && showApplePay && (
              <div
                className={`${
                  method === PayTypes.APPLE_PAY
                    ? 'border-primary-color'
                    : 'border-border-color hover:border-primary-color'
                } flex items-center px-4 py-3 cursor-pointer border rounded-lg`}
                onClick={() => setMethod(PayTypes.APPLE_PAY)}
              >
                <ApplePayIcon />
                <div className="flex-1 ml-2">Apple Pay</div>
                {method === PayTypes.APPLE_PAY ? (
                  <SelectedIcon />
                ) : (
                  <UnselectedIcon />
                )}
              </div>
            )}
            {usePaymentMethods.includes(PayTypes.WECHAT_PAY) && (
              <div
                className={`${
                  method === PayTypes.WECHAT_PAY
                    ? 'border-primary-color'
                    : 'border-border-color hover:border-primary-color'
                } flex items-center px-4 py-3 cursor-pointer border rounded-lg`}
                onClick={() => setMethod(PayTypes.WECHAT_PAY)}
              >
                <WeChatPayIcon className="text-xs" />
                <div className="flex-1 ml-2">
                  {t('extended_service:wechat_pay')}
                </div>
                {method === PayTypes.WECHAT_PAY ? (
                  <SelectedIcon />
                ) : (
                  <UnselectedIcon />
                )}
              </div>
            )}
            {usePaymentMethods.includes(PayTypes.ALIPAYCN) && (
              <div
                className={`${
                  method === PayTypes.ALIPAYCN
                    ? 'border-primary-color'
                    : 'border-border-color hover:border-primary-color'
                } flex items-center px-4 py-3 cursor-pointer border rounded-lg`}
                onClick={() => setMethod(PayTypes.ALIPAYCN)}
              >
                <AlipayIcon className="text-xs" />
                <div className="flex-1 ml-2">
                  {t('extended_service:alipay')}
                </div>
                {method === PayTypes.ALIPAYCN ? (
                  <SelectedIcon />
                ) : (
                  <UnselectedIcon />
                )}
              </div>
            )}
            {usePaymentMethods.includes(PayTypes.ALIPAYHK) && (
              <div
                className={`${
                  method === PayTypes.ALIPAYHK
                    ? 'border-primary-color'
                    : 'border-border-color hover:border-primary-color'
                } flex items-center px-4 py-3 cursor-pointer border rounded-lg`}
                onClick={() => setMethod(PayTypes.ALIPAYHK)}
              >
                <AlipayHKIcon className="text-xs" />
                <div className="flex-1 ml-2">
                  {t('extended_service:alipayhk')}
                </div>
                {method === PayTypes.ALIPAYHK ? (
                  <SelectedIcon />
                ) : (
                  <UnselectedIcon />
                )}
              </div>
            )}
            {usePaymentMethods.includes(PayTypes.UNIONPAY_WALLET) && (
              <div
                className={`${
                  method === PayTypes.UNIONPAY_WALLET
                    ? 'border-primary-color'
                    : 'border-border-color hover:border-primary-color'
                } flex items-center px-4 py-3 cursor-pointer border rounded-lg`}
                onClick={() => setMethod(PayTypes.UNIONPAY_WALLET)}
              >
                <UnionPayWalletIcon className="text-xs" />
                <div className="flex-1 ml-2">
                  {t('extended_service:unionpay_wallet')}
                </div>
                {method === PayTypes.UNIONPAY_WALLET ? (
                  <SelectedIcon />
                ) : (
                  <UnselectedIcon />
                )}
              </div>
            )}
            {!inApp && !isPhone && usePaymentMethods.includes(PayTypes.FPS) && (
              <div
                className={`${
                  method === PayTypes.FPS
                    ? 'border-primary-color'
                    : 'border-border-color hover:border-primary-color'
                } flex items-center px-4 py-3 cursor-pointer border rounded-lg`}
                onClick={() => setMethod(PayTypes.FPS)}
              >
                <FPSIcon className="text-xs" />
                <div className="flex-1 ml-2">FPS</div>
                {method === PayTypes.FPS ? (
                  <SelectedIcon />
                ) : (
                  <UnselectedIcon />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-6 mt-6 pb-6">
          <Button onClick={() => setShowBack(true)} styleType="Secondary">
            {t('common:back')}
          </Button>
          <Button disabled={!method} onClick={handlePay}>
            {t('extended_service:pay_now')}
          </Button>
        </div>
      </Transition.Child>
      <AntdModal
        open={selectCard !== undefined}
        type={'warn'}
        onCancel={handleDelete}
        okText={t('common:back')}
        cancelText={t('extended_service:remove_this_record')}
        onOk={() => setSelectCard(undefined)}
        closable={false}
        maskClosable={false}
      >
        <div className="pt-4 flex flex-col items-center text-color-text-1">
          <div className="text-xl font-bold">
            {t('extended_service:remove_card')}
          </div>
          <div className="text-sm font-medium mt-2">
            {t('extended_service:remove_card_tip')}
          </div>
        </div>
      </AntdModal>
      <AntdModal
        open={showBack}
        type={'warn'}
        onCancel={handleBack}
        okText={t('extended_service:continue')}
        cancelText={t('extended_service:leave')}
        onOk={() => setShowBack(false)}
        closable={false}
        maskClosable={false}
      >
        <div className="pt-4 flex flex-col items-center text-color-text-1">
          <div className="text-xl font-bold">
            {t('extended_service:discontinue_payment')}
          </div>
          <div className="text-sm font-medium mt-2">
            {t('extended_service:leave_tip')}
          </div>
        </div>
      </AntdModal>
    </StepWrapper>
  );
};

export default PaymentWebPage;
