import { Tooltip } from 'antd';
import TipsIcon from '@/components/svg/tips-icon';
import type { IObject } from '@/types/common-types';

interface IAntdTipsProps {
  content: string;
  overlayInnerStyle?: IObject;
  className?: string;
  children?: React.ReactNode;
}

const AntdTips: React.FC<IAntdTipsProps> = (props) => {
  const { content, children, className = '', overlayInnerStyle = {} } = props;

  return (
    <Tooltip
      overlayInnerStyle={overlayInnerStyle}
      className={`${className}`}
      title={content}
    >
      <div className="cursor-pointer">{children || <TipsIcon className="text-primary-color" />}</div>
    </Tooltip>
  );
};

export default AntdTips;
