import { IDispatch } from "@/actions";
import ActionType from "@/actions/action-type";
import Button from "@/components/common/button";
import i18n from "@/i18n";
import { BusinessStatus, IBusiness } from "@/types/business-types";
import { getCurrentI18nValue } from "@/utils";
import { getStatusStyle, resolvingBusinessStatus, translateStatusFuc } from "@/utils/business-utils";
import { transformField } from "@/utils/field-utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
interface IInfoContentProps {
  business?: IBusiness;
  status: BusinessStatus;
}

const InfoContent = (props: IInfoContentProps) => {
  const { t } = useTranslation('personal_space');
  const navigate = useNavigate();
  const { business, status } = props;
  const data = business || {};
  const lang = i18n.language;
  const dispatch: IDispatch = useDispatch();
  const address1 = getCurrentI18nValue(data, 'address_1', lang);
  const address2 = getCurrentI18nValue(data, 'address_2', lang);
  const city = getCurrentI18nValue(data, 'city', lang);
  const countryName = getCurrentI18nValue(data, 'country_name', lang);

  let currentStatus = '';
  let translateStatus = '';

  if (business) {
    currentStatus = resolvingBusinessStatus(business);
    translateStatus = translateStatusFuc(business, currentStatus, lang, t);
  }

  let storeInfo = [address1, address2, city, countryName].filter(
    (item) => item && item.length > 0
  );

  if (lang !== 'en') {
    storeInfo = storeInfo.reverse();
  }

  const handleClick = () => {
    if (business) {
      // navigate(`/businesses/${business.id}/settings/business-settings`);
      dispatch({
        type: ActionType.LOADING_MAIN_DATA,
        payload: {
          navigate,
          business,
        }
      });
    }
  };

  const getText = () => {
    let text = '';

    switch (status) {
      case BusinessStatus.REQUIRED_ADDITIONAL_CPMPANY_MEMBERS:
        text = t('personal_space:invite_tips');
        break;
      case BusinessStatus.UNDER_REVIEW:
        text = t('personal_space:under_review_tips');
        break;
      case BusinessStatus.MANUAL_UNDERWRITING:
        text = t('personal_space:manual_underwriting_tips');
        break;
      case BusinessStatus.REQUIRED_APPLICANT_VERIFICATION:
        text = t('personal_space:required_user_verification_tips');
        break;
      case BusinessStatus.PENDING_PRIMARY_NATURAL_PERSON:
        text = t('personal_space:pending_primary_natural_person_tips');
        break;
      case BusinessStatus.LEGACY_PAYMENT_ACCOUNT:
      case BusinessStatus.LEGACY_POS_ACCOUNT:
        text = t('personal_space:legacy_payment_tips');
        break;

      default:
        break;
    }

    return text;
  };

  return (
    <div className="flex flex-col items-center text-center text-color-text-1 px-10 pt-8 pb-10">
      <div className="font-bold text-xl text-color-text-1">
        {getCurrentI18nValue(data, 'business_name', lang)}
      </div>
      <div className="text-color-text-2 font-medium text-sm mt-1">
        {storeInfo.join(', ')}
      </div>
      <div
        className="py-1 px-3 mt-6 mb-2 text-xs font-medium rounded-3xl"
        style={getStatusStyle(currentStatus)}
      >
        {transformField(translateStatus).toUpperCase()}
      </div>
      <div className="bg-primary-color bg-opacity-10 px-4 py-3 rounded flex w-full">
        <svg
          className="self-center"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M2.625 12C2.625 6.82171 6.82171 2.625 12 2.625C17.1783 2.625 21.375 6.82171 21.375 12C21.375 17.1783 17.1783 21.375 12 21.375C6.82171 21.375 2.625 17.1783 2.625 12Z"
            stroke="#0094FF"
            strokeWidth="2.25"
          />
          <path
            d="M14.4252 15.9708H12.6779V8.79102C12.6779 8.38291 12.3478 8.05273 11.9396 8.05273H11.2014H9.9709C9.56279 8.05273 9.23262 8.38291 9.23262 8.79102C9.23262 9.19912 9.56279 9.5293 9.9709 9.5293H11.2014V15.9708H9.4541C9.046 15.9708 8.71582 16.301 8.71582 16.7091C8.71582 17.1172 9.046 17.4474 9.4541 17.4474H11.2014H12.6779H14.4252C14.8333 17.4474 15.1635 17.1172 15.1635 16.7091C15.1635 16.301 14.8333 15.9708 14.4252 15.9708Z"
            fill="#0094FF"
          />
          <path
            d="M11.2002 6.02148C11.2002 6.42923 11.5307 6.75977 11.9385 6.75977C12.3462 6.75977 12.6768 6.42923 12.6768 6.02148C12.6768 5.61374 12.3462 5.2832 11.9385 5.2832C11.5307 5.2832 11.2002 5.61374 11.2002 6.02148H11.2002Z"
            fill="#0094FF"
          />
        </svg>
        <div className="text-left text-sm font-medium ml-2">
          <div className="text-color-text-1">
            {t('personal_space:activated_tips')}
          </div>
          <div className="text-color-text-2">{getText()}</div>
        </div>
      </div>
      {/* <div
        className="flex w-full"
        style={{
          background: 'linear-gradient(208deg, #01BBDA 0%, #0197FD 100%)',
        }}
      >
        <div className="text-color-text-6 text-left pl-10 pt-10 max-w-[60%]">
          <div className="font-bold text-xl">
            {t('personal_space:request_for_fnformation')}
          </div>
          <div className="text-sm font-medium mt-2">
            {t('personal_space:submit_documents_tips')}
          </div>
        </div>
        <div className="pt-12 ml-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="155"
            height="212"
            viewBox="0 0 155 212"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M35.4396 109.197L29.168 158.241C29.168 158.241 30.3594 159.606 31.7146 159.941C33.0736 160.274 34.5964 159.574 34.5964 159.574L41.668 110.325L35.4396 109.197Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.3283 52.8711C8.30664 78.1313 18.4281 101.519 35.8888 105.008C53.3515 108.494 71.6425 90.7824 76.6661 65.5241C81.6859 40.2658 71.5644 16.8781 54.1037 13.389C36.6696 9.8998 18.35 27.6128 13.3264 52.8711H13.3283ZM40.1697 117.531C37.9607 117.531 35.744 117.317 33.5312 116.876C9.5444 112.083 -4.82942 82.3091 1.48912 50.5077C1.48912 50.5057 1.49103 50.5057 1.49103 50.5057C7.81528 18.7082 32.4762 -3.27091 56.4669 1.52001C80.4556 6.31284 94.8294 36.0872 88.5109 67.8886C82.7675 96.7601 61.9132 117.531 40.1697 117.531Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M53.6133 109.706L131.047 103.1L131.805 213.756L53.6133 220.709V109.706Z"
              fill="#FFBE00"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M74.0168 121.952C69.0111 121.952 64.9375 125.877 64.9375 130.704C64.9375 135.529 69.0111 139.455 74.0168 139.455C79.0239 139.455 83.0975 135.529 83.0975 130.704C83.0975 125.877 79.0239 121.952 74.0168 121.952ZM74.019 141.38C67.9493 141.38 63.0117 136.591 63.0117 130.704C63.0117 124.816 67.9493 120.027 74.019 120.027C80.0888 120.027 85.0278 124.816 85.0278 130.704C85.0278 136.591 80.0888 141.38 74.019 141.38Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M90.7559 126.547C90.2601 126.547 89.8383 126.167 89.7962 125.665C89.7528 125.135 90.1471 124.67 90.6776 124.625L115.877 122.533C116.39 122.482 116.873 122.881 116.918 123.411C116.962 123.942 116.567 124.407 116.035 124.45L90.8371 126.544C90.8096 126.546 90.7835 126.547 90.7559 126.547Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M90.7989 135.991C90.3031 135.991 89.8813 135.611 89.8392 135.108C89.7957 134.579 90.19 134.114 90.7206 134.069L115.922 131.976C116.433 131.923 116.918 132.325 116.962 132.855C117.006 133.386 116.612 133.851 116.08 133.894L90.8801 135.988C90.8525 135.99 90.8264 135.991 90.7989 135.991Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M64.5215 155.287C64.0316 155.287 63.6126 154.917 63.5633 154.419C63.5111 153.89 63.8982 153.419 64.4288 153.367L117.165 148.159C117.685 148.1 118.168 148.492 118.22 149.022C118.272 149.552 117.885 150.022 117.355 150.074L64.6172 155.283C64.5853 155.286 64.5534 155.287 64.5215 155.287Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M65.3106 166.912C64.8206 166.912 64.4017 166.541 64.3524 166.044C64.3002 165.515 64.6872 165.043 65.2178 164.991L117.954 159.784C118.471 159.72 118.957 160.117 119.009 160.647C119.061 161.175 118.674 161.647 118.144 161.699L65.4063 166.908C65.3744 166.91 65.3425 166.912 65.3106 166.912Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M66.0958 178.537C65.6058 178.537 65.1868 178.167 65.1375 177.669C65.0853 177.141 65.4724 176.669 66.003 176.617L118.74 171.409C119.265 171.354 119.742 171.742 119.796 172.272C119.848 172.802 119.461 173.272 118.93 173.324L66.1914 178.533C66.1595 178.536 66.1276 178.537 66.0958 178.537Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M66.8809 190.162C66.3909 190.162 65.972 189.791 65.9227 189.293C65.8705 188.765 66.2576 188.293 66.7881 188.241L119.526 183.035C120.047 182.975 120.529 183.368 120.581 183.898C120.633 184.426 120.246 184.898 119.715 184.95L66.9766 190.158C66.9447 190.16 66.9128 190.162 66.8809 190.162Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M67.67 201.786C67.18 201.786 66.761 201.416 66.7117 200.918C66.6596 200.389 67.0466 199.918 67.5772 199.866L120.313 194.657C120.832 194.595 121.316 194.99 121.369 195.519C121.421 196.049 121.034 196.519 120.503 196.572L67.7657 201.782C67.7338 201.785 67.7019 201.786 67.67 201.786Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.51382 174.801L46.9346 171.438L46.6288 227.635L6.96875 230.919L7.51382 174.801Z"
              fill="#FFD2E1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.9004 181.508C15.6114 181.508 13.75 183.294 13.75 185.491C13.75 187.688 15.6114 189.474 17.9004 189.474C20.188 189.474 22.0479 187.688 22.0479 185.491C22.0479 183.294 20.188 181.508 17.9004 181.508ZM17.9027 191.399C14.5496 191.399 11.8242 188.749 11.8242 185.491C11.8242 182.233 14.5496 179.583 17.9027 179.583C21.2543 179.583 23.9782 182.233 23.9782 185.491C23.9782 188.749 21.2543 191.399 17.9027 191.399Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.42 183.849C25.9242 183.849 25.5023 183.468 25.4603 182.967C25.4168 182.436 25.8111 181.972 26.3403 181.927L39.1698 180.86C39.7192 180.808 40.1657 181.209 40.2121 181.739C40.2556 182.27 39.8599 182.734 39.3307 182.778L26.5026 183.846C26.4736 183.847 26.4475 183.849 26.42 183.849Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.4434 188.656C25.9476 188.656 25.5258 188.276 25.4837 187.775C25.4403 187.244 25.8346 186.779 26.3637 186.734L39.1947 185.668C39.7369 185.637 40.1906 186.018 40.237 186.546C40.2805 187.076 39.8847 187.54 39.3556 187.587L26.5261 188.653C26.4971 188.655 26.471 188.656 26.4434 188.656Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.0606 198.477C12.5706 198.477 12.1517 198.107 12.1024 197.609C12.0502 197.08 12.4372 196.609 12.9678 196.557L39.8157 193.905C40.3317 193.853 40.8174 194.237 40.871 194.768C40.9232 195.297 40.5347 195.769 40.0056 195.821L13.1563 198.473C13.1244 198.476 13.0925 198.477 13.0606 198.477Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.5169 204.324C12.024 204.324 11.6036 203.947 11.5587 203.45C11.5108 202.918 11.9008 202.451 12.4299 202.403L39.3865 199.957C39.9113 199.908 40.3867 200.3 40.4346 200.829C40.4824 201.358 40.091 201.826 39.5619 201.875L12.6068 204.319C12.5763 204.322 12.5473 204.324 12.5169 204.324Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.4462 210.25C11.9576 210.25 11.5387 209.88 11.4894 209.385C11.4357 208.855 11.8199 208.383 12.3505 208.331C12.6042 208.303 37.8255 205.755 39.5912 205.503C40.1058 205.419 40.6059 205.792 40.6813 206.319C40.7553 206.845 40.3899 207.332 39.8623 207.408C38.0589 207.665 13.5856 210.14 12.5447 210.244C12.5114 210.249 12.478 210.25 12.4462 210.25Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.3809 216.301C11.8909 216.301 11.472 215.929 11.4227 215.433C11.3705 214.905 11.7576 214.433 12.2881 214.381L39.697 211.676C40.2203 211.629 40.6973 212.01 40.7509 212.538C40.8031 213.068 40.416 213.54 39.8869 213.592L12.4766 216.297C12.4447 216.3 12.4128 216.301 12.3809 216.301Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M118.496 73.808C118.496 64.0327 126.474 56.1094 136.316 56.1094C146.158 56.1094 154.136 64.0327 154.136 73.808C154.136 83.5818 146.158 91.5052 136.316 91.5052C126.474 91.5052 118.496 83.5818 118.496 73.808Z"
              fill="#0CF0FF"
            />
          </svg>
        </div>
      </div> */}
      <Button onClick={handleClick} className="w-full mt-6">
        {t('personal_space:go_business_settings')}
      </Button>
    </div>
  );
};

export default InfoContent;
