import RefreshIcon from '../analytics-icons/refresh-icon';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';
import PayoutsReports from './chart-details';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IObject } from '@/types/common-types';
import { getBusinessWallets } from '@/data/global-account';
import { findNode } from '@/utils';
import { currencies } from '@/utils/currencies';
import { getReportRecords } from '@/data/analytics';
import Select from '@/components/common/select';
import DateRange from '@/components/common/antd/date-range';
import { getDays } from '@/utils/dayjs';
import ActionType from '@/actions/action-type';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';

interface ILoadDataParams {
  currency_code?: string;
  start_date?: string;
  end_date?: string;
}

const PayoutsReportsPage = () => {
  const { t } = useTranslation('analytics');
  const { lang = 'en', p_business_id: businessID = '' } = useParams();
  const dispatch: IDispatch = useDispatch();
  
  const [time, setTime] = useState<dayjs.Dayjs>();
  const [data, setData] = useState<IObject>({});
  const [currency, setCurrency] = useState<string>('HKD');
  const [startDate, setStartDate] = useState<string>(
    dayjs().subtract(7, 'day').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [refreshPage, setRefreshPage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [records, setRecords] = useState<any[]>([]);

  const { ledger_accounts = [] } = data;

  useEffect(() => {
    loadData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, refreshPage]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('common:analytics'),
          key: '1',
          type: 'label',
        },
        {
          name: t('common:payouts_reports'),
          key: '2',
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  useEffect(() => {
    getState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const getState = async () => {
    const data = await getBusinessWallets(businessID, lang);
    const { default_currency_code = 'HKD' } = data || {};
    setCurrency(default_currency_code);
    setData(data);
  }

  const options: any[] = [];

  if (Array.isArray(ledger_accounts)) {
    ledger_accounts.forEach((item) => {
      const { currency_code } = item.currency || {};
      const currencyobj: any = findNode(
        currencies,
        'currency_code',
        currency_code
      );

      if (currencyobj) {
        options.push(currencyobj);
      }
    });
  }

  const loadData = async (params: ILoadDataParams) => {
    if (loading) return;
    setLoading(true);
    const currentDate = dayjs();
    const {
      currency_code = currency,
      start_date = startDate,
      end_date = endDate,
    } = params;

    setStartDate(start_date);
    setEndDate(end_date);

    const allResult = await getReportRecords({
      p_business_id: businessID,
      start_date,
      end_date,
      currency_code,
      active: 'payout',
    });

    setCurrency(currency_code);
    setRecords(allResult);
    setLoading(false);
    setTime(currentDate);
  };

  const handleCurrencyChange = (value: string) => {
    if (value !== currency) {
      loadData({ currency_code: value });
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const handleDateChange = async (dates: any) => {
    if (dates && Array.isArray(dates)) {
      let newStartDate: any = dates[0];
      let newEndDate: any = dates[1];

      if (newStartDate && newEndDate) {
        newStartDate = newStartDate.format('YYYY-MM-DD');
        newEndDate = newEndDate.format('YYYY-MM-DD');
        await loadData({ start_date: newStartDate, end_date: newEndDate });
      }
    }
  };

  return (
    <div className="p-4 flex flex-col md:p-6 lg:px-8 lg:py-6">
      <div className="md:flex justify-between items-center mb-6">
        <div className="text-3xl leading-10 font-bold text-color-text-1">
          {t('common:payouts_reports')}
        </div>
        <div
          className={`flex items-start flex-col md:flex-row md:items-center hidden_scrollbar`}
        >
          <div className="flex items-center my-2 md:mr-2 md:my-0">
            <span className="text-xs text-primary-color whitespace-nowrap font-medium">
              {t('analytics:last_update_at', {
                data: time ? time.format('HH:mm') : '00:00',
              })}
            </span>
            <RefreshIcon
              size={20}
              className={`text-primary-color shrink-0 ml-1 fill-current hover:text-primary-color/70 cursor-pointer`}
              onClick={() => setRefreshPage((refreshPage) => !refreshPage)}
            />
          </div>
          <div className="bg-primary-background-color">
            <Select
              className="shadow-none"
              options={options}
              dataKey={'currency_code'}
              valueKey={'currency_code'}
              value={currency}
              onChange={handleCurrencyChange}
              renderItem={(item: any) => (
                <div className="flex" key={item.country_code}>
                  <span className="flex items-center relative w-5 mr-2">
                    <img
                      width="20px"
                      height="20px"
                      src={`/images/country-flag/${
                        item.alpha_2 ? item.alpha_2.toLocaleLowerCase() : 'hk'
                      }.svg`}
                      alt=""
                    />
                  </span>
                  <span className="truncate text-color-text-1 font-medium">
                    {item.currency_code}
                  </span>
                </div>
              )}
            />
          </div>
          <DateRange
            format="MMM D, YYYY"
            className="bg-primary-background-color h-10 border-border-color shadow-none mt-2 md:ml-2 md:mt-0"
            textColor="text-color-text-1"
            presets={[
              { label: t('analytics:this_week'), value: getDays('Week') },
              {
                label: t('analytics:this_month'),
                value: getDays('Month'),
              },
              {
                label: t('analytics:past_30_days'),
                value: [dayjs().subtract(30, 'd'), dayjs()],
              },
              { label: t('analytics:this_year'), value: getDays('Year') },
            ]}
            disabledDate={disabledDate}
            allowClear={false}
            size={'large'}
            value={[dayjs(startDate), dayjs(endDate)]}
            onChange={handleDateChange}
          />
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <PayoutsReports
          businessID={businessID}
          loading={loading}
          startDate={startDate}
          endDate={endDate}
          currency={currency}
          records={records}
        />
      </div>
    </div>
  );
};

export default PayoutsReportsPage;
