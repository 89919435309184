interface IDetailsItemProps {
  label: string;
  value: React.ReactNode;
  className?: string;
}

const DetailsItem = (props: IDetailsItemProps) => {
  const { label, value, className = '' } = props;
  return (
    <div className={`flex items-center text-sm font-medium ${className}`}>
      <div className="text-right w-40 text-color-text-2">{label}</div>
      <div className="ml-8 text-color-text-1">{value || ''}</div>
    </div>
  );
};

export default DetailsItem;
