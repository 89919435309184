import Action from '@/actions';
import { IQueryMe } from '@/actions/account-action';
import ActionType from '@/actions/action-type';
import { Constants } from '@/constants';
import { queryMe } from '@/data/account';
import { IReducersState } from '@/reducers';
import { ILoginUser } from '@/types/account-type';
import Cookies from 'js-cookie';
import { put, select, takeEvery } from 'redux-saga/effects';

export function* handleQueryMe(params: IQueryMe) {
  const { loadingProgressCallback } = params;
  const { loginUser }: ILoginUser = yield select((state: IReducersState) => state.account);
  let success = true;

  let accessToken = '';

  try {
    
    if (!loginUser.id) {
      if (loadingProgressCallback) {
        loadingProgressCallback({
          stepNum: 1,
        });
      }

      const user: ILoginUser = yield queryMe();

      if (user && user.id) {
        yield put<Action>({
          type: ActionType.SET_LOGIN_USER,
          loginUser: user,
        });
        accessToken = user.access_token;
      }
    }

  } catch (error) {
    success = false;
  }

  if (accessToken) {
    Cookies.set(Constants.TOKEN, accessToken);
  }

  return success;
}

function* accountSaga() {
  yield takeEvery<string, any>(ActionType.QUERY_ME, handleQueryMe);
}

export default accountSaga;
