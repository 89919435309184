import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import ApproximatelyEqualIcon from '../payouts-icons/approximately-equal-icon';
import CopyIcon from '@/components/svg/copy-icon';
import SelectedIcon from '../payouts-icons/selected-icon';
import TipsIcon from '@/components/svg/tips-icon';
import MoreIcon from '@/components/svg/ellipsis-icon';
import { ICategory, IPayout, ISupportingDocuments } from '@/types/payouts-type';
import { useTranslation } from 'react-i18next';
import { useFrontendRender } from '@/data/payouts';
import { Alert } from '@/components/common/alert/alert';
import PayeeItem from '../components/payee-item';
import { getState, handleMount } from '@/utils/payouts-utils';
import Label from '@/components/common/label';
import Textarea from '@/components/common/textarea';
import AntdModal from '@/components/common/antd/modal';
import Config from '@/libs/config';
import { useParams } from 'react-router-dom';

interface IPayoutDetailsProps {
  payout: IPayout;
  categories: ICategory[];
  selectCategory: ICategory;
  isView: boolean;
  data: ISupportingDocuments;
  setSelectCategory: (selectCategory: ICategory) => void;
  setData: (data: ISupportingDocuments) => void;
}

const PayoutDetails: React.FC<IPayoutDetailsProps> = (props) => {
  const {
    payout,
    categories,
    selectCategory,
    setSelectCategory,
    isView,
    data,
    setData,
  } = props;
  const {
    id,
    error_code,
    error_message,
    i18n,
    transfer_purpose,
    payout_category_name,
    currency,
    destination_currency,
    destination_amount,
    fx_exchange_rate,
    calculated_fee,
    display_name,
    p_created_at,
    settled_at,
    estimated_settled_at,
    total_amount,
    transfer_status,
  } = payout || {};
  const [copySuccess, setCopySuccess] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const { t } = useTranslation('payouts');
  const { t: ct } = useTranslation('common');
  const { lang = 'en' } = useParams();
  const isFronend = useFrontendRender();
  const { remark } = data;

  const multiCurrency = useMemo(() => {
    return currency !== destination_currency;
  }, [currency, destination_currency]);

  const normal = useMemo(() => {
    return !error_code || !error_message || transfer_status === 'Refunded';
  }, [error_code, error_message, transfer_status]);

  const handleCopy = () => {
    navigator.clipboard.writeText(id).then(() => {
      Alert.success({
        message: t('copied'),
        position: 'default',
        wrapClass: 'top-px',
      });
    });
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  const getTitle = (value: string) => {
    if (i18n && i18n[value]) {
      if (i18n[value][lang]) {
        return i18n[value][lang];
      }
    }
    return value;
  };

  return (
    <div className="rounded shadow-card-drop bg-primary-background-color p-6 mb-6 mx-4">
      <div className="font-bold text-xl text-color-text-1">
        {t('payout_details')}
      </div>
      <div className="border-b border-border-color mb-6 mt-4"></div>
      <div className="grid grid-cols-4 gap-y-6 gap-x-6">
        <PayeeItem
          title={t('transaction_amount')}
          content={`${handleMount(
            destination_amount,
            2,
            true
          )} ${destination_currency}`}
        />
        <PayeeItem
          title={t('status')}
          content={getState(payout, lang)}
        />
        <PayeeItem
          className="col-span-2"
          title={'ID'}
          content={
            <div className="flex items-center space-x-1">
              <span className="whitespace-nowrap overflow-ellipsis overflow-hidden">
                {id}
              </span>
              <span className="cursor-pointer group" onClick={handleCopy}>
                <CopyIcon className="text-primary-color" />
              </span>
              <span
                className={`text-primary-color text-xs cursor-pointer ${
                  copySuccess ? 'visible' : 'invisible'
                }`}
              >
                {t('copied')}
              </span>
            </div>
          }
        />

        {multiCurrency ? (
          <PayeeItem
            title={t('rate')}
            content={
              <div className="flex items-center">
                <ApproximatelyEqualIcon className="mr-0.5" />
                <div>
                  {fx_exchange_rate} {currency}-{destination_currency}
                </div>
                <Tooltip
                  className="ml-0.5 leading-3 cursor-pointer"
                  overlayClassName="max-w-none"
                  title={
                    <div className="font-normal text-sm">
                      <p className="font-medium leading-6">
                        {t('estimated_exchange_rate')}
                      </p>
                      <p className="whitespace-pre-line">
                        {t('estimated_exchange_rate_details')}
                      </p>
                    </div>
                  }
                >
                  <span>
                    <TipsIcon size={16} className="text-primary-color" />
                  </span>
                </Tooltip>
              </div>
            }
          />
        ) : (
          <PayeeItem
            title={t('fees_charged')}
            content={`${handleMount(calculated_fee, 2, true)} ${currency}`}
          />
        )}
        <PayeeItem
          title={t('purpose_of_transfer')}
          content={getTitle(transfer_purpose)}
        />
        {!isView && (
          <div>
            <Label value={t('category')} />
            <div
              className="border border-border-color hover:border-primary-color shadow-box-drop flex items-center h-10 rounded cursor-pointer group"
              onClick={() => setShowCategoryModal(true)}
            >
              <div className="font-medium text-color-text-1 flex-1 py-2.5 pl-3 truncate text-sm">
                {selectCategory.category_name || ''}
              </div>
              <span className="text-icon-color px-2 group-hover:text-primary-color">
                <MoreIcon />
              </span>
            </div>
          </div>
        )}
        {isView && (
          <PayeeItem
            title={t('category')}
            content={getTitle(payout_category_name)}
          />
        )}
        <PayeeItem title={t('transfer_by')} content={display_name} />

        {multiCurrency ? (
          <PayeeItem
            title={t('fees_charged')}
            content={`${handleMount(calculated_fee, 2, true)} ${currency}`}
          />
        ) : (
          <PayeeItem
            title={t('billing_amount')}
            content={`${handleMount(total_amount, 2, true)} ${currency}`}
          />
        )}
        <PayeeItem
          className="capitalize"
          title={ct('created_on')}
          content={
            isFronend
              ? dayjs(p_created_at).format('MMMM DD, YYYY HH:mm:ss')
              : ''
          }
        />
        {normal && settled_at && (
          <PayeeItem
            className="col-span-2"
            title={t('settled_on')}
            content={dayjs(settled_at).format('MMMM DD, YYYY')}
          />
        )}
        {normal && !settled_at && (
          <PayeeItem
            className="col-span-2"
            title={t('est_settled_on')}
            content={dayjs(estimated_settled_at).format('MMMM DD, YYYY')}
          />
        )}
        {!normal && (
          <PayeeItem
            className="col-span-2"
            title={t('reason')}
            content={
              <>
                <div className="text-error-color">{error_code}</div>
                <div>{error_message}</div>
              </>
            }
          />
        )}

        {multiCurrency ? (
          <PayeeItem
            title={t('billing_amount')}
            content={
              <div className="flex items-center">
                {multiCurrency && <ApproximatelyEqualIcon className="mr-0.5" />}
                <div>{`${handleMount(total_amount, 2, true)} ${currency}`}</div>
                {multiCurrency && (
                  <Tooltip
                    className="ml-0.5 leading-3 cursor-pointer"
                    overlayClassName="max-w-none"
                    placement="bottom"
                    title={
                      <div className="font-normal text-sm">
                        <p className="font-medium">
                          {t('estimated_billing_amount')}
                        </p>
                        <p className="whitespace-pre-line">
                          {t('estimated_billing_amount_details')}
                        </p>
                      </div>
                    }
                  >
                    <span>
                      <TipsIcon size={16} className="text-primary-color" />
                    </span>
                  </Tooltip>
                )}
              </div>
            }
          />
        ) : undefined}
        <div className={`${multiCurrency ? 'col-span-3' : 'col-span-4'}`}>
          <Label value={t('remark_reference')} />
          {!isView ? <Textarea
            disabled={isView}
            value={remark}
            onChange={(value) => setData({ ...data, remark: value })}
          /> : <div className="text-sm text-color-text-1 font-medium">{remark || '--'}</div>}
        </div>
      </div>
      <AntdModal
        open={showCategoryModal}
        maskClosable={true}
        footer={null}
        size="medium"
        onCancel={() => setShowCategoryModal(false)}
      >
        <div className="max-h-[80vh] overflow-y-hidden flex flex-col py-8 px-6">
          <div className="font-bold text-color-text-1 text-xl text-center">
            {t('categories')}
          </div>
          <div
            className={`mt-8 grid gap-y-2 overflow-y-auto flex-1 hidden_scrollbar`}
          >
            {categories.map((item) => (
              <div
                className="flex items-center pl-4 py-3 hover:bg-disabled-color cursor-pointer rounded"
                key={item.id}
                onClick={() => {
                  setSelectCategory(item);
                  setShowCategoryModal(false);
                }}
              >
                <div
                  className="w-8 h-8 rounded-full p-1.5"
                  style={{ backgroundColor: item.color }}
                >
                  <img
                    width={20}
                    height={20}
                    src={`${Config.urls.gatewayUrl}/api/directus/treasury-services/files/${item.icon_file}.svg`}
                    alt=""
                  />
                </div>
                <div className="text-sm font-medium text-color-text-1 flex-1 ml-2">
                  {item.category_name}
                </div>
                {item.id === selectCategory.id && <SelectedIcon size={24} />}
              </div>
            ))}
          </div>
        </div>
      </AntdModal>
    </div>
  );
};

export default React.memo(PayoutDetails);
