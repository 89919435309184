import { BigNumber } from 'bignumber.js';

export const plus = (
  num1: number,
  num2: number,
  decimal?: number | undefined
) => {
  let num: any = new BigNumber(num1).plus(num2);

  if (decimal !== undefined && decimal !== null) {
    num = num.toFixed(decimal);
  }

  return Number(num);
};

export const minus = (
  num1: number,
  num2: number,
  decimal?: number | undefined
) => {
  let num: any = new BigNumber(num1).minus(num2);

  if (decimal !== undefined && decimal !== null) {
    num = num.toFixed(decimal);
  }

  return Number(num);
};

export const times = (
  num1: number,
  num2: number,
  decimal?: number | undefined
) => {
  let num: any = new BigNumber(num1).times(num2);

  if (decimal !== undefined && decimal !== null) {
    num = num.toFixed(decimal);
  }

  return Number(num);
};

export const dividedBy = (
  num1: number,
  num2: number,
  decimal?: number | undefined
) => {
  let num: any = new BigNumber(num1).dividedBy(num2);

  if (decimal !== undefined && decimal !== null) {
    num = num.toFixed(decimal);
  }

  return Number(num);
};

export const getColorByPamentType = (type: string) => {
  let color = '';
  switch (type) {
    case 'alipay':
      color = '#00ADEE';
      break;
    case 'cup':
      color = '#E02424';
      break;
    case 'mastercard':
      color = '#F79E1B';
      break;
    case 'visa':
      color = '#0032BE';
      break;
    case 'wechat':
      color = '#1AAD19';
      break;
    case 'unionPay':
      color = '#FE02424';
      break;
    case 'fps':
      color = '#175189';
      break;
    case 'americanexpress':
      color = '#2C6FC9';
      break;
    case 'octopus_business':
      color = '#BD528A';
      break;
    case 'jcb':
      color = '#0A7C3D';
      break;
    case 'discover':
      color = '#FF6600';
      break;
    case 'tap&go':
      color = '#EA9450';
      break;
    case 'eps':
      color = '#334580';
      break;

    default:
      break;
  }

  return color;
};
