import Button from '@/components/common/button';
import { useState, useEffect } from 'react';
import Config from '@/libs/config';
import { useTranslation } from 'react-i18next';
import {
  getCountryByCurrency,
  handleMount,
} from '@/utils/payouts-utils';
import { useLocation } from 'react-router-dom';
const prefix = Config.staticPath;
interface IPayoutsStep {
  key: string;
  title: string;
  name: string;
}
interface IAddfundTransferDetailsProps {
  setSteps: (steps: IPayoutsStep[]) => void;
  setStepCount: (stepCount: number) => void;
  setAmount: (amount: string) => void;
}

const AddfundTransferDetails = (props: IAddfundTransferDetailsProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryCurrencyCode = searchParams.get('currency_code');
  const { setStepCount, setSteps, setAmount } = props;
  const { t } = useTranslation(["global_accounts",
    "payouts",
    "account_balance",
    "related_company_accounts",
    "payees"]);
  const [sellMsg, setSellMsg] = useState('');
  const [upAmount, setUpAmount] = useState('');
  const [currencyCode, setCurrencyCode] = useState<any>('USD');

  useEffect(() => {
    setCurrencyCode(queryCurrencyCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = () => {
    if (upAmount) {
      const steps: IPayoutsStep[] = [
        {
          key: '1',
          title: t('global_accounts:choose_method'),
          name: '1',
        },
        {
          key: '3',
          title: t('payouts:fps_edda'),
          name: '2',
        },
      ];
      if (upAmount.includes('.')) {
        setAmount(upAmount);
      } else {
        const newAmount = upAmount + '.00';
        setAmount(newAmount);
      }
      setSteps(steps);
      setStepCount(1);
    }
  };

  const handleChange = (value: string) => {
    let amount = value;
    let points = 0;
    if (value === '.') {
      return {
        amount: '0.',
        points,
      };
    }
    if (value === '') {
      return {
        amount: '',
        points,
      };
    }
    const index = value.indexOf('.');
    if (
      index !== -1 &&
      value.length - 1 !== index &&
      value[value.length - 1] === '.'
    ) {
      amount = value.substring(0, value.length - 1);
    }
    const values = amount.split('.');
    if (values.length > 1) {
      amount = `${values[0]}.${values[1].slice(0, 2)}`;
      if (values[1].length < 3) {
        points = values[1].length;
      } else {
        points = 2;
      }
    }
    return {
      amount,
      points,
    };
  };

  const handleInputChange = (inputValue: string) => {
    setSellMsg('');

    const { amount, points } = handleChange(inputValue);
    let value = amount;
    let total = amount.replace(/[^\d.]/g, '');
    const lastLetter = total[total.length - 1];
    if (lastLetter === '.') {
      total = total.replace('.', '');
    }
    if (!total) {
      value = '';
    }

    setUpAmount(
      total && lastLetter !== '.' ? handleMount(total, points) : value
    );
  };

  return (
    <div>
      <>
        <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
          <div className="flex justify-between">
            <div>
              <div className="font-semibold text-xl text-color-text-1">
                {t('payouts:fps_edda')}
              </div>
            </div>
          </div>
          <div className="mt-4 mb-6 border-b border-border-color" />
          <div
            className={`${sellMsg ? 'border-error-color' : 'border-primary-color'
              } border-2 border-solid shadow-box-drop rounded-2xl px-6 py-3.5`}
          >
            <div className="flex items-center">
              <div className="flex-1">
                <div className="text-color-text-1 font-medium mb-1">
                  {t('payouts:input_add')}
                </div>
                <input
                  className={`focus:outline-none font-bold ${sellMsg ? 'text-error-color' : 'text-color-text-1'
                    } text-3xl bg-inherit placeholder:text-color-text-5 placeholder:font-bold placeholder:text-3xl caret-primary-color`}
                  type="text"
                  value={upAmount}
                  inputMode="decimal"
                  placeholder={'0.00'}
                  maxLength={14}
                  onChange={(eve) => {
                    handleInputChange(eve.target.value);
                  }}
                />
              </div>
              <div
                className={`bg-primary-color rounded-lg w-[120px] h-12 flex items-center justify-center `}
              >
                <img
                  // layout="intrinsic"
                  width="24px"
                  height="24px"
                  src={`${prefix}/images/country-flag/${getCountryByCurrency(
                    currencyCode
                  )}.svg`}
                  alt=""
                />
                <div className="text-white font-bold text-xl mx-1.5">
                  {currencyCode}
                </div>
              </div>
            </div>
            <div className="text-sm text-error-color">{sellMsg}</div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <Button
            className="flex-1 mr-6"
            onClick={() => setStepCount(0)}
            styleType="Secondary"
          >
            {t('common:back')}
          </Button>
          <Button
            className="flex-1"
            onClick={handleNext}
            styleType="Primary"
            disabled={upAmount ? false : true}
          >
            {t('common:next')}
          </Button>
        </div>
      </>
    </div>
  );
};

export default AddfundTransferDetails;
