import AccountWrapper from './components/account-wrapper';
import { Link } from 'react-router-dom';
import Button from '@/components/common/button';
import { useTranslation } from 'react-i18next';
import EmailIcon from './accounts-icons/email-icon';

const EmailSendPage = () => {
  const { t } = useTranslation('signin');
  return (
    <AccountWrapper>
      <div className="flex flex-col w-120 bg-color-text-6 box-border p-15 md:rounded-[30px] md:mr-[10%] md:shadow-sign-in-card">
        <EmailIcon className="text-icon-color" />
        <h1 className="text-3xl text-color-text-1 text-center font-bold mb-6 mt-14">
          {t('signin:email_sent_successfully')}
        </h1>
        <div className="text-center mb-6 text-color-text-2">
          {t('signin:send_email_tips')}
        </div>
        <div className="flex items-center justify-center">
          <Link className="w-full no-underline" to="/account/signin">
            <Button className="w-full h-12 rounded-lg">
              {t('signin:back_login')}
            </Button>
          </Link>
        </div>
      </div>
    </AccountWrapper>
  );
};

export default EmailSendPage;
