import React from 'react';
import type { IIconProps } from '@/types/common-types';

const SuccessfulIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 16 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8C0 3.6 3.6 0 8 0C12.4 0 16 3.6 16 8C16 12.4 12.4 16 8 16C3.6 16 0 12.4 0 8ZM3.6 8L7 11.4L12.4 6L11 4.6L7 8.6L5 6.6L3.6 8Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default SuccessfulIcon;
