import EditIcon from '../terminal-icons/edit-icon';
import Skeleton from '@/components/skeleton';

interface IPanelTitleParam {
  title: string;
  loadingData?: boolean;
  showEditIcon?: boolean;
  className?: string;
}
const PanelTitle: React.FC<IPanelTitleParam> = (props) => {
  const {
    title,
    className = '',
    showEditIcon = false,
    loadingData = false,
  } = props;

  return (
    <div
      className={`flex items-center justify-between pb-4 border-solid border-0 border-b mb-6 border-border-color ${className}`}
    >
      {loadingData && (
        <Skeleton
          row={1}
          width={['70%']}
          skeletonItemClass={`h-6 rounded-2xl`}
        />
      )}
      {!loadingData && (
        <>
          <div className="font-bold text-xl">{title}</div>
          {showEditIcon && <EditIcon />}
        </>
      )}
    </div>
  );
};

export default PanelTitle;
