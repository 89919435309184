import React from 'react';
import type { IIconProps } from '@/types/common-types';

const RegisteredPayeeIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="46"
        height="45"
        viewBox="0 0 46 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.0737 25.6499C28.5907 25.8521 29.0959 26.0834 29.5867 26.3427C30.1951 26.664 30.4278 27.4177 30.1065 28.0262C29.7852 28.6346 29.0315 28.8673 28.4231 28.546C26.6729 27.6217 24.7204 27.1318 22.6946 27.1318C16.7857 27.1318 11.7454 31.3342 10.6398 37.061C10.5093 37.7366 9.8559 38.1785 9.18033 38.0481C8.50472 37.9177 8.0628 37.2642 8.19323 36.5887C9.17044 31.527 12.6981 27.457 17.3176 25.651C13.9549 23.7672 11.6823 20.1711 11.6823 16.0445C11.6823 9.96617 16.6129 5.03906 22.6947 5.03906C28.7763 5.03906 33.7069 9.96621 33.7069 16.0445C33.7069 20.1704 31.4352 23.7658 28.0737 25.6499ZM31.2152 16.0445C31.2152 11.3427 27.4006 7.53076 22.6947 7.53076C17.9886 7.53076 14.174 11.3427 14.174 16.0445C14.174 20.7463 17.9886 24.5582 22.6947 24.5582C27.4006 24.5582 31.2152 20.7463 31.2152 16.0445Z"
          fill="black"
        />
        <path
          d="M32.0481 31.7682V28.0318C32.0477 27.8106 32.1064 27.5933 32.2182 27.4023C32.3265 27.2175 32.4804 27.0635 32.6653 26.9552C32.8562 26.8435 33.0735 26.7848 33.2948 26.7852C33.516 26.7848 33.7333 26.8435 33.9242 26.9552C34.1091 27.0635 34.2631 27.2175 34.3713 27.4023C34.4831 27.5933 34.5418 27.8106 34.5414 28.0318V31.7682H38.2407C38.9287 31.7682 39.4864 32.326 39.4864 33.0141C39.4864 33.7021 38.9287 34.2598 38.2407 34.2598H34.5414V37.9547C34.5418 38.176 34.483 38.3933 34.3713 38.5842C34.263 38.7691 34.1091 38.923 33.9242 39.0313C33.7333 39.1431 33.516 39.2018 33.2948 39.2014C33.0735 39.2018 32.8562 39.1431 32.6653 39.0313C32.4804 38.923 32.3265 38.7691 32.2182 38.5842C32.1064 38.3933 32.0477 38.176 32.0481 37.9547V34.2598H28.3073C27.6193 34.2598 27.0615 33.7021 27.0615 33.0141C27.0615 32.326 27.6193 31.7682 28.3073 31.7682H32.0481Z"
          fill="#FFDA44"
        />
      </svg>
    </div>
  );
};

export default RegisteredPayeeIcon;
