import React from 'react';
import type { IIconProps } from '@/types/common-types';

const FilterIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={'24'}
        height={'24'}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 19H11C10.448 19 10 18.552 10 18C10 17.448 10.448 17 11 17H13C13.552 17 14 17.448 14 18C14 18.552 13.552 19 13 19Z"
          fill="currentColor"
        />
        <path
          d="M15 15H9C8.448 15 8 14.552 8 14C8 13.448 8.448 13 9 13H15C15.552 13 16 13.448 16 14C16 14.552 15.552 15 15 15Z"
          fill="currentColor"
        />
        <path
          d="M17 11H7C6.448 11 6 10.552 6 10C6 9.448 6.448 9 7 9H17C17.552 9 18 9.448 18 10C18 10.552 17.552 11 17 11Z"
          fill="currentColor"
        />
        <path
          d="M19 7H5C4.448 7 4 6.552 4 6C4 5.448 4.448 5 5 5H19C19.552 5 20 5.448 20 6C20 6.552 19.552 7 19 7Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default FilterIcon;
