import styles from '@/pages/content.module.css';
import React from 'react';

const PaymentTerms = () => {
  return (
    <div className={`${styles.header}`}>
      <div className={`${styles.payment} font-medium`}>
        <div className={`${styles.title} underline decoration-4 underline-offset-4 text-center`}>ELECTRONIC DIRECT DEBIT AUTHORITY (“EDDA”) TERMS AND CONDITIONS</div>
        <p>The Electronic Direct Debit Authority ("EDDA") Terms and Conditions with Bindo Labs Limited (“Bindo”) delineate your legal rights and obligations pertaining to the EDDA. These terms specify the governing provisions of Your use of the EDDA services, outlining the specific obligations, responsibilities, and liabilities that You has agreed to upon executing the EDDA and in relation to the executed Merchant Service Agreement. The provisions detailed within these terms and conditions are binding and enforceable to the extent permitted by applicable laws and regulations.</p>
        <div className={`${styles.title}`}>TERMS & CONDITIONS</div>
        <p className="italic underline decoration-2 underline-offset-4">Your authorization and consent for EDDA</p>
        <p>You hereby confer an irrevocable mandate upon Bindo to institute and operationalize an Electronic Direct Debit Authority (“EDDA”) against Your designated bank account, with the intent to extinguish any and all pecuniary obligations, liabilities, or debitums arising from transactions intermediated by Bindo's services and owed by You, whether constituted by, but not limited to, refunds, chargebacks, disputes, and penalties (“financial obligations”). Such obligations shall be deemed liquidated debts exigible by Bindo from You upon accrual.</p>
        <p>By virtue of this EDDA acceptance, You hereby confer that Bindo shall be explicitly vested with the authority to debit Your designated bank account in satisfaction of any outstanding financial obligations, without requiring any additional consent. Bindo shall notify You as soon as reasonably practicable of any applicable financial obligations which have occurred or been incurred, to the extent of the terms stipulated herein as well as any applicable regulatory mandates governing such transactions.</p>
        <p>You agree that, by <label className="underline decoration-2 underline-offset-4">linking a bank account and/or adding any new additional bank account within the Linked Bank Accounts section</label>, such action shall constitute explicit accession to, acknowledgment of, and acceptance of the EDDA Terms and Conditions.</p>
        <p>You agree that, by <label className="underline decoration-2 underline-offset-4">undertaking transactions executed via the Add Fund feature by means of the EDDA mechanism</label>, such action shall constitute explicit accession to, acknowledgment of, and acceptance of the EDDA Terms and Conditions. In addition, You hereby assent to the pre-filled and retention of Your designated bank account information, which will be treated in accordance with Bindo’s Privacy Policy.</p>
        <p>You agree that, pursuant to the provisions set forth herein, Bindo is vested with the authority to <label className="underline decoration-2 underline-offset-4">establish a same-day expiry date EDDA mandate of a nominal HKD to Your designated bank account, effectuated solely for the purposes of conducting verification and authorization procedures to confirm the account’s capability to support and execute the EDDA</label> in accordance with the stipulated terms and conditions.</p>
        <p>You agree that, pursuant to the provisions set forth herein, Bindo reserves the right to suspend, in whole or in part, the provision of Services should the financial obligations incurred by You be deemed excessive and Bindo, at its sole and absolute discretion, may elect to offset such exigible amounts against Your designated bank account, to the extent permitted by the applicable regulatory mandates and the terms stipulated herein.</p>
      
        <p className="italic underline decoration-2 underline-offset-4">Your obligation under EDDA</p>
        <p>You hereby agree that, pursuant to the provisions set forth herein, You bears the sole responsibility for ensuring that Your designated bank account is capable of accepting EDDA instructions and maintaining a sufficient balance to effectuate such EDDA transactions. In any eventualities wherein EDDA instructions are rendered ineffectual and returned to Bindo, irrespective of the causal factors, Bindo reserves the right to impose additional charges on You, in addition to any fees levied by Your banking institution. in any eventualities wherein the EDDA instructions are rendered ineffectual due to a paucity of funds within Your designated bank account, You are obligated to promptly fund the account to facilitate the reinitiation and successful execution of the EDDA by Bindo</p>
        <p>You hereby agree that, pursuant to the provisions set forth herein, You are responsible and obligated to review and verify Your designated bank account statement to ensure the accuracy of all amounts debited from Your designtated bank account in relation to any EDDA transactions.</p>
        <p>You hereby agree that, pursuant to the provisions set forth herein, You are responsible and obligated to certify that You has obtained the requisite authorization from all natural or legal persons who maintain the status of account holders in relation to Your designated bank account, and You hereby represents and warrants unto Bindo that You are duly authorized to act in such capacity and shall persevere in retaining said authorization. </p>
        <p>You acknowledges and consents that Bindo shall retain the right to require You to provide documented proof of Your authorized capacity before permitting any payment transactions linked to an EDDA to be executed</p>
        <p>You hereby agree that this authorization shall remain in full force and effect until further notice is provided. You accedes that any notice of amendment, cancellation or modification of this authorization issued by You to Your banking institution must be provided in accordance to the amendment, modification and cancellation provision below prior to the effective date of such intended with concurrent notice to Bindo Labs Limited following the same rules and time limits as per provided under amendment, modification and cancellation provision below.</p>
      
        <div className={`${styles.title}`}>DISPUTES</div>
        <p>You hereby agree that, pursuant to the provisions set forth herein, if You believes that an error has occurred in debiting Your designated bank account, You are obligated to notify Bindo via telephone and subsequently confirm said notice in writing to Bindo at the earliest opportunity, thereby facilitating the <label className="underline decoration-2 underline-offset-4">expeditious</label> resolution of Your query by Bindo.</p>
        <p>In the event that Bindo concludes, as a result of its investigations, that Your account has been incorrectly debited, Bindo shall undertake to address Your query by arranging for Your financial institution to adjust Your designated bank account, including any applicable interest and charges. Bindo shall also notify You in writing of the amount by which Your designated bank account has been adjusted.</p>
        <p>Conversely, should Bindo conclude, as a result of its investigations, that Your designated bank account has not been incorrectly debited, Bindo shall respond to Your query by providing You with the reasons and any supporting evidence for this determination in writing</p>
      
        <div className={`${styles.title}`}>AMENDMENTS, MODIFICATION AND CANCELLATION</div>
        <p className="underline decoration-2 underline-offset-4 font-bold">By Us</p>
        <p>Bindo may vary any details of these Terms and Conditions at any time, provided that Bindo gives You no less than fourteen (14) days’ written notice of such variations.</p>
        <p className="underline decoration-2 underline-offset-4 font-bold">By You.</p>
        <p>You hereby agree that, pursuant to the provisions set forth herein, You may change, defer, or stop, cancel, or terminate this EDDA by providing Bindo with no less than fourteen (14) days' prior notification through one of the following methods:</p>
        <ol style={{listStyleType: 'lower-alpha'}}>
          <li><p className="!mb-0">writing to Bindo at: Suite 05-181, 5/F Quayside, 77 Hoi Bun Road, Kwun Tong, Kowloon;</p></li>
          <li><p className="!mb-0">emailing Bindo at: support@Bindo.com;</p></li>
          <li><p className="!mb-0">telephoning Bindo at +852 – 2777 1177 during business hours;</p></li>
          <li><p>arranging it through Your financial institution, which is required to act promptly on Your instructions.</p></li>
        </ol>
        <p>In the event that You, Your financial institution, or Bindo exercises the right to cancel this authorization, You are obligated to arrange a suitable alternative payment method. Furthermore, if You alters or modifies Your designated bank account, You are obligated to furnish Bindo with any and all information pertaining to the new account and authorization as Bindo deems necessary.</p>
      
        <div className={`${styles.title}`}>CONFIDENTIALITY</div>
        <p>Bindo shall keep any information, including Your designated bank account details provided in the EDDA, confidential. Bindo shall make reasonable efforts to ensure the security of such information and shall ensure that any of Bindo's employees or agents who have access to Yourinformation do not engage in any unauthorized use, modification, reproduction, or disclosure of that information.</p>
        <p>Bindo may disclose information pertaining to You under the following circumstances: to the extent permitted or required by law; for the purposes of this EDDA (including disclosing information in connection with any query or claim); and as otherwise set forth in Bindo's Privacy Policy</p>
      
        <div className={`${styles.title}`}>MERCHANTS & AFFILIATES</div>
        <p>You hereby agree that, pursuant to the provisions set forth herein, by virtue of the EDDA, You acknowledges that Bindo is acting solely as an agent for You and does not provide any goods or services to You.</p>
        <p>You acknowledges that Bindo may have entered into, or may enter into, arrangements with Bindo's affiliates, either under Bindo's control or under common control with Bindo, for the provision of certain services to You. These affiliates may benefit from the authorization provided by You in connection with this EDDA and may rely on this EDDA even if they are not a party to it. Furthermore, You acknowledges that Bindo may permit these affiliates to act as agents or sub-agents for Bindo, or Bindo may agree to act as agent or sub-agent for them, in connection with this EDDA.</p>
      
        <div className={`${styles.title}`}>NOTICE</div>
        <p>You hereby agree that, pursuant to the provisions set forth herein, if You wishes to notify Bindo in writing regarding any matter related to this EDDA, You should contact Bindo through one of the following methods:</p>
        <p className="!mb-0">(a) writing to Bindo at: Suite 05-181, 5/F Quayside, 77 Hoi Bun Road, Kwun Tong, Kowloon;</p>
        <p className="!mb-0">(b) emailing Bindo at: support@bindo.com;</p>
        <p>(c) telephoning Bindo at: +852 2777 1177 during business hours.</p>
        <p>Bindo shall notify You by sending a notice via ordinary post to the postal address (if any) or to the email address provided by You in the EDDA form	.</p>
        <p>Any notice sent by Bindo via post will be deemed to have been received on the third Business Day following the date of posting. Any notice sent by Bindo via email will be deemed to have been received on the day the email is sent.</p>
        <p>You agrees to enter into this EDDA by means of electronic communication and consents to signing or otherwise indicating Youragreement through electronic communication. You unconditionally accepts and is bound by these terms and conditions through the usage of the stipulated services.</p>
      </div>
    </div>
  );
};

export default React.memo(PaymentTerms);
