import React, { useEffect, useState } from 'react';
import Menu, { Item as MenuItem } from 'rc-menu';
import dayjs from 'dayjs';
import { Dropdown } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { getCountryByCurrency, getValueMultilingual, handleMount } from '@/utils/payouts-utils';
import { Link, useNavigate, useParams } from 'react-router-dom';
import EllipsisIcon from '@/components/svg/ellipsis-icon';
import { getCookie } from '@/utils';
import { Constants } from '@/constants';
import api from '@/libs/api';
import Config from '@/libs/config';
import Table from '@/components/table';
import { TableRecord } from '@/components/table/interface';
import { useTranslation } from 'react-i18next';
import NoPayoutIcon from '@/pages/payouts/payouts-icons/no-payout-icon';
import Skeleton from '@/components/skeleton';

interface IPayoutsTable {
  businessID: string;
  id: string;
}

const PayoutsTable: React.FC<IPayoutsTable> = (props) => {
  const { businessID, id } = props;
  const [records, setRecords] = useState<TableRecord[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [recordLoading, setRecordLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const prefix = Config.staticPath || '';

  const { t } = useTranslation('payees');
  const { t: ct } = useTranslation('common');
  const { lang = 'en' } = useParams();
  const dateFormat = lang === 'en' ? 'MMM DD, YYYY' : 'MMM DD日, YYYY';
  

  const getState = (data: any, lang: string) => {
    const { schedule_status, transfer_status, i18n } = data;
    const scheduleStatus = schedule_status || '';
    const transferStatus = transfer_status || '';
    const schedule = scheduleStatus.toLocaleLowerCase();
    const transfer = transferStatus.toLocaleLowerCase();
    if (schedule === 'pending approval' && transfer === 'initiated') {
      return (
        <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-warning-color bg-warning-color/10 uppercase">
          {getValueMultilingual('Pending Approval', i18n, lang)}
        </span>
      );
    }
    if (
      (schedule === 'approved' && transfer === 'initiated') ||
      (schedule === 'pending verification' && transfer === 'suspended')
    ) {
      return (
        <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-warning-color bg-warning-color/10 bg-opacity-10 uppercase">
          {getValueMultilingual('Pending for Transfer', i18n, lang)}
        </span>
      );
    }
    if (schedule === 'approved' && transfer === 'processing transfer') {
      return (
        <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-primary-color bg-primary-color/10 uppercase">
          {getValueMultilingual('Processing Transfer', i18n, lang)}
        </span>
      );
    }
    if (schedule === 'approved' && transfer === 'settled') {
      return (
        <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#4CD964] bg-[#4CD964]/10 uppercase">
          {getValueMultilingual('Settled', i18n, lang)}
        </span>
      );
    }
    if (schedule === 'rejected' && transfer === 'reversed') {
      return (
        <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#828282] bg-[#828282]/10 uppercase">
          {getValueMultilingual('Rejected', i18n, lang)}
        </span>
      );
    }
    if (schedule === 'cancelled' && transfer === 'reversed') {
      return (
        <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#8E8E93] bg-[#8E8E93]/10 uppercase">
          {getValueMultilingual('Reversed', i18n, lang)}
        </span>
      );
    }

    return (
      <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#8E8E93] bg-[#8E8E93]/10 uppercase">
        {getValueMultilingual(transfer, i18n, lang)}
      </span>
    );
  };

  const columns: ColumnsType<TableRecord> = [
    {
      key: 'payee_name',
      title: t('payee_name'),
      dataIndex: 'payee_name',
      width: 160,
    },
    {
      key: 'payout_category_name',
      title: t('transfer_category'),
      dataIndex: 'payout_category_name',
      width: 160,
      render: (value, record) => {
        const { i18n = {} } = record;
        return getValueMultilingual(value, i18n, lang);
      },
    },
    {
      key: 'transfer_method',
      title: t('transfer_method'),
      dataIndex: 'transfer_method',
      width: 180,
      render: (value, record) => {
        return (
          <div className="flex">
            <div className="flex items-center relative w-4 mr-1">
              <img
                width="16px"
                height="16px"
                src={`${prefix}/images/country-flag/${getCountryByCurrency(
                  record['destination_currency']
                )}.svg`}
                alt=""
              />
            </div>
            <div className="text-color-text-1 flex-1">{value}</div>
          </div>
        );
      },
    },
    {
      key: 'schedule_status',
      title: ct('status'),
      dataIndex: 'schedule_status',
      width: 210,
      render: (_value, record) => {
        return getState(record, lang);
      },
    },
    {
      key: 'total_amount',
      title: t('billing_amount'),
      dataIndex: 'total_amount',
      align: 'right',
      width: 170,
      render: (value, record) => {
        const { transfer_status = '' } = record;
        const transferStatus = transfer_status || '';
        const transfer = transferStatus.toLocaleLowerCase();
        return (
          <div>
            <label
              className={`${
                transfer === 'reversed' ? 'text-color-text-2 line-through' : ''
              }`}
            >
              {handleMount(value, 2, true)}
            </label>{' '}
            {`${record['currency']}`}
          </div>
        );
      },
    },
    {
      key: 'destination_amount',
      title: t('transfer_amount'),
      dataIndex: 'destination_amount',
      align: 'right',
      width: 170,
      render: (value, record) => {
        const { transfer_status = '' } = record;
        const transferStatus = transfer_status || '';
        const transfer = transferStatus.toLocaleLowerCase();
        return (
          <div className="text-[#4C5259]">
            <label
              className={`${transfer === 'reversed' ? 'line-through' : ''}`}
            >
              {handleMount(value, 2, true)}
            </label>{' '}
            {`${record['destination_currency']}`}
          </div>
        );
      },
    },
    {
      key: 'p_created_at',
      title: t('created_at'),
      dataIndex: 'p_created_at',
      align: 'right',
      width: 126,
      render: (value, _record) => {
        let time = value;
        if (time && time.indexOf('T') > -1 && time.indexOf('Z') < 0) {
          time = `${time}Z`;
        }
        if (time) {
          return (
            <span className="whitespace-nowrap">
              {dayjs(time).format(dateFormat)}
              <br />
              {dayjs(time).format('HH:mm:ss')}
            </span>
          );
        } else {
          return <span></span>;
        }
      },
    },
    {
      title: ct('actions'),
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 88,
      render: (_value, record) => {
        return (
          <Dropdown
            destroyPopupOnHide={true}
            dropdownRender={() => (
              <Menu className="bg-white">
                <MenuItem key="view">
                  <Link
                    to={`/${lang}/businesses/${businessID}/payouts/approval/${record.id}`}
                  >
                    <div className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5">
                      <span className="text-color-text-2 text-sm font-medium">
                        {ct('view')}
                      </span>
                    </div>
                  </Link>
                </MenuItem>
              </Menu>
            )}
          >
            <div className="justify-center flex">
              <div className="flex items-center cursor-pointer p-1.5 rounded-full w-max hover:bg-primary-color/10 group">
                <EllipsisIcon className="text-icon-color group-hover:text-primary-color" />
              </div>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    loadRecords(page, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessID]);

  const loadRecords = async (page = 1, pageSize = 50) => {
    setRecordLoading(true);
    const token = getCookie(Constants.TOKEN) || '';
    try {
      const resp = await api.get(
        `${
          Config.urls.gatewayUrl
        }/api/treasury-services/api/payout_requests?payee_id=${id}&limit=${
          pageSize + 1
        }&offset=${(page - 1) * pageSize}`,
        {
          headers: {
            'x-user-access-token': token,
            'x-p-business-id': businessID,
          },
        }
      );
      const { data } = resp || {};
      if (data && Array.isArray(data)) {
        setRecords(data);
      }
    } catch (error) {
      console.error(error);
    }
    setRecordLoading(false);
    setPage(page);
    setPageSize(pageSize);
    setLoading(false);
  };

  const hadleView = (record: any) => {
    navigate(`/${lang}/businesses/${businessID}/payouts/approval/${record.id}`);
  };

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      {loading && (
        <div className={`bg-white rounded pt-8 px-4 mx-3`}>
          <Skeleton
            row={10}
            width={[
              '60%',
              '75%',
              '90%',
              '100%',
              '100%',
              '90%',
              '80%',
              '80%',
              '75%',
              '60%',
            ]}
          />
        </div>
      )}
      {!loading && records.length === 0 && (
        <div className="h-full flex flex-col justify-center items-center">
          <NoPayoutIcon />
          <div className="mt-6 text-sm text-color-text-1 font-medium mb-11">
            {t('no_payout')}
          </div>
        </div>
      )}
      {!loading && records.length > 0 && (
        <>
          <Table
            columns={columns}
            records={records}
            className="mb-6 mx-3"
            loadingData={recordLoading}
            pagination={{
              className: 'mx-3',
              pageSize,
              current: page,
              currentSize: records.length,
              changePageCallback: loadRecords,
            }}
            onRowCell={(record, dataKey) => {
              return {
                onClick: (evt) => {
                  evt.stopPropagation();
                  if (dataKey === 'action') return;
                  if (record.id) {
                    hadleView(record);
                  }
                },
              };
            }}
          />
        </>
      )}
    </div>
  );
};

export default PayoutsTable;
