import { useEffect } from 'react';

const DownloadWonderJek = () => {
  
  useEffect(() => {
    let destination = 'https://play.google.com/store/apps/details?id=jek.app';
    const userAgentInfo = window.navigator.userAgent;
    if (userAgentInfo.indexOf('MSIE') >= 0) {
    } else if (userAgentInfo.indexOf('Firefox') >= 0) {
    } else if (userAgentInfo.indexOf('Chrome') >= 0) {
    } else if (userAgentInfo.indexOf('Opera') >= 0) {
    } else if (userAgentInfo.indexOf('Safari') >= 0) {
      destination = 'https://apps.apple.com/hk/app/jek/id6476124305';
    }
    window.location.href = destination;
  }, []);
  return <div></div>;
};

export default DownloadWonderJek;
