import BankAccountDetails from './bank-account-details';
import {
  initRelatedForm,
  type INewRelatedFormData,
  // type IRelatedDetailsProps,
} from './type';
import Button from '@/components/common/button';
import { useEffect, useState } from 'react';
import { getCookie } from '@/utils/index';
import { Constants } from '@/constants';
import { Alert } from '@/components/common/alert/alert';
import { useTranslation } from 'react-i18next';
import type { IPayee } from '@/types/payouts-type';
import type { IFileObject, IObject } from '@/types/common-types';
import { getBankList, getPayeeDetail, getTransferMethodDetail, payeeRemove, payeeUpdate } from './server';
import Skeleton from '@/components/skeleton';
import BankStatements from './bank-statements';
import ModalEmpty from '@/components/common/modal/modal-empty';
import WarningIcon from '@/components/svg/warning-icon';
import BackIcon from '@/components/svg/back-icon';
import GlobalLoading from '@/components/common/global-loading';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getAvailableFeatures } from '@/utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { IReducersState } from '@/reducers';
import { loadAreaCodesData } from '@/data/area-codes';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';

const RelatedFormView = () => {
  const permissions = useSelector((state: IReducersState) => state.businessState.permissions);
  const [related, setRelated] = useState<IPayee>();
  const { t } = useTranslation(["settings", "business_account_settings", "account_balance","related_company_accounts"]);
  const { lang = 'en', p_business_id: businessID = '', related_company_account_id: recordId = '' } = useParams();
  const token = getCookie(Constants.TOKEN || '') ?? '';
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryEdit = searchParams.get('edit');
  const [relatedFormDate, setRelatedFormDate] =
    useState<INewRelatedFormData>(initRelatedForm);
  const useFormReturn = useForm({
    mode: 'all',
  });
  const [banks, setbanks] = useState<any>([])
  const [transferMethod, settransferMethod] = useState<any>([])
  const [areaCodes, setareaCodes] = useState<any>([])
  const {
    canUpdate,
    // canCreate,
    canDelete,
  } = getAvailableFeatures(permissions);
  const [isView, setIsView] = useState(queryEdit == null);
  const [cancelModal, setCancelModal] = useState(false);
  const [hasEdit, setHasEdit] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [isDefaultBank, setDefaultBank] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [payoutAccounts, setPayoutAccounts] = useState<any>();
  const [saveDataloading, setSaveDataLoading] = useState<boolean>(false);

  const hasSuccess = () => {
    const { verification_method, verified_at } = payoutAccounts || {};
    return !(verification_method != null && verified_at != null);
  };
  useEffect(() => {
    getRelated(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  useEffect(() => {
    initLoad();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('settings:settings'),
          key: '1',
          type: 'label',
        },
        {
          name: t('related_company_accounts:related_company'),
          key: '2',
          type: 'link',
          url: `/businesses/${businessID}/settings/related-company-accounts`,
        },
        {
          name: `${related?.nickname || ''}`,
          key: '3',
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, related?.nickname])

  const initLoad = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    const allPromise: any[] = [
      getTransferMethodDetail(businessID, lang, token),
      loadAreaCodesData(),
      getBankList(businessID, lang, token),
    ];
    const resp: any = await Promise.all(allPromise);
    if (resp.length > 0) {
      settransferMethod(resp[0] ?? {})
      setareaCodes(resp[1] ?? [])
      setbanks(resp[2] ?? [])
    }
  }

  const getRelated = async (loading: boolean) => {
    if (loading) {
      setLoading(true);
    }
    try {
      if (businessID) {
        const resp = await getPayeeDetail(businessID, lang, recordId, token);

        const { data } = resp;
        if (resp && data && data.id) {
          const { is_default_bank } = data;
          const result: IPayee = {
            id: data.id,
            type: data.type,
            nickname: data.nickname,
            p_business_id: data.p_business_id,
            company_name: data.company_name,
            payout_category_id: data.payout_category_id,
            categories: data.categories,
            payout_accounts: data.payout_accounts,
            i18n: data.i18n,
            email: '',
            phone_country_code: '',
            phone_number: ''
          };
          const { payout_accounts } = result;

          setRelated(result);
          setRelatedFormDate(handleRelatedFormDate(result));
          setPayoutAccounts(payout_accounts);
          setDefaultBank(is_default_bank);
        }
      }
    } catch (error) {
      throw error;
    }

    setLoading(false);
  };

  const handleRelatedFormDate = (data: any) => {
    const { payout_accounts, nickname, id, i18n, bank_settlement_files: newData } = data || {};
    const {
      currency,
      banks,
      country,
      bank_settlement_files,
      identifier_1_value,
      identifier_2_value,
      identifier_3_value,
    } = payout_accounts;
    const files: IFileObject[] = [];
    let fileData: IFileObject[] = bank_settlement_files;
    if (Array.isArray(newData)) {
      fileData = newData
    }
    if (Array.isArray(fileData)) {
      fileData.forEach((item: any) => {
        files.push({
          id: item.id,
          fileUrl: item.image_url ? item.image_url : item.fileUrl,
          filenameDownload: item.filename_download ? item.filename_download : item.filenameDownload,
        });
      });
    }
    const relatedFormdata: INewRelatedFormData = {
      nickname: nickname || '',
      country: country || '',
      currency: currency,
      identifier_1_value: identifier_1_value || '',
      identifier_2_value: identifier_2_value || '',
      identifier_3_value: identifier_3_value || '',
      bank: banks,
      bank_settlement_files: files || [],
      payout_accounts: payout_accounts,
      id,
      i18n,
    };
    setRelatedFormDate(relatedFormdata)
    return relatedFormdata;
  };

  const handleBack = () => {
    if ((queryEdit != null || isView) && !hasEdit) {
      navigate(
        `/businesses/${businessID}/settings/related-company-accounts`
      );
    } else if (hasEdit) {
      setCancelModal(true);
    } else {
      setIsView(true);
    }
  };

  const handleSave = async () => {
    const { trigger } = useFormReturn;

    if (!(await trigger())) {
      return;
    }
    const {
      currency,
      nickname,
      identifier_1_value,
      identifier_2_value,
      identifier_3_value,
    } = relatedFormDate;
    setSaveDataLoading(true);
    setRemoveLoading(true);

    const settlementFiles = handleSettlementFiles();
    try {
      const result: IObject = await payeeUpdate(businessID, lang, token, recordId, {
        currency,
        identifier_1_value,
        nickname,
        identifier_2_value,
        identifier_3_value,
        del_bank_settlement_files: settlementFiles.delete,
        add_bank_settlement_files: settlementFiles.create,
      });
      const { code, data, message } = result.data;

      if (code === 200 && data != null) {
        await getRelated(false);
        Alert.success({
          message: t('common:changes_saved'),
          position: 'default',
          wrapClass: 'top-px',
        });
        setIsView(true);
        setHasEdit(false);
      } else {
        Alert.error({
          message: `${t(
            'related_company_accounts:save_failed_tips'
          )}${code}-${message}`,
          position: 'default',
          wrapClass: 'top-px',
        });
      }
    } catch (error) { }
    setRemoveLoading(false);
    setSaveDataLoading(false);
  };

  const handleSettlementFiles = () => {
    const { bank_settlement_files } = payoutAccounts || {};
    const newData: IFileObject[] = relatedFormDate.bank_settlement_files || [];
    const initData: IFileObject[] = [];

    if (bank_settlement_files && Array.isArray(bank_settlement_files)) {
      bank_settlement_files.forEach((item) => {
        initData.push({
          id: item.id,
          fileUrl: item.image_url,
          filenameDownload: item.filename_download,
        });
      });
    }

    const initDataMap: Map<string, IFileObject> = new Map();
    const createArry: string[] = [];
    const deleteArry: string[] = [];
    if (Array.isArray(initData) && initData.length > 0) {
      initData.forEach((item) => {
        initDataMap.set(item.id, item);
      });
    }

    if (Array.isArray(newData) && newData.length > 0) {
      newData.forEach((item) => {
        if (!initDataMap.has(item.id)) {
          // new data
          // const newObj: IObject = {};
          createArry.push(item.id);
        } else {
          // old data
          initDataMap.delete(item.id);
        }
      });

      [...initDataMap.values()].forEach((item) => {
        if (item.id) {
          deleteArry.push(item.id);
        }
      });
    }

    if (newData.length === 0) {
      // delete all
      [...initDataMap.values()].forEach((item) => {
        if (item.id) {
          deleteArry.push(item.id);
        }
      });
    }

    return {
      create: createArry,
      delete: deleteArry,
    };
  };

  const handleCancel = () => {
    if (hasEdit) {
      setCancelModal(true);
    } else if (queryEdit != null) {
      navigate(
        `/businesses/${businessID}/settings/related-company-accounts`
      );
    } else {
      setIsView(true);
    }
  };

  const handleRelatedFormData = (data: INewRelatedFormData) => {
    setRelatedFormDate(data);
    setHasEdit(true);
  };

  const handleKeepEdit = () => {
    setCancelModal(false);
  };

  const handleDiscardChanges = () => {
    setCancelModal(false);
    if (queryEdit != null) {
      navigate(
        `/businesses/${businessID}/settings/related-company-accounts`
      );
    } else {
      setIsView(true);
      setRelatedFormDate(handleRelatedFormDate(related));
      const { reset } = useFormReturn;
      reset({
        ...related,
      });
      setHasEdit(false);
    }
  };

  const handleRemove = async () => {
    setRemoveModal(false);
    setRemoveLoading(true);
    const result = await payeeRemove(businessID, lang, token, recordId);
    const { success, message } = result || {};
    setRemoveLoading(false);

    if (success) {
      navigate(
        `/businesses/${businessID}/settings/related-company-accounts`
      );
      Alert.success({
        message: t('related_company_accounts:selected_account_removed'),
        position: 'default',
        wrapClass: 'top-px',
      });
    } else {
      Alert.error({
        message: message || t('related_company_accounts:creation_failed'),
        position: 'default',
        wrapClass: 'top-px',
      });
    }
  };

  return (
    <>
      <div className="px-4 md:px-6 lg:px-8 py-6">
        {loading && (
          <div className="md:flex justify-between mb-6 flex">
            <Skeleton
              row={1}
              className={`mr-0`}
              width={['50%']}
              skeletonItemClass={`h-10 rounded-2xl`}
            />
            <div className="flex">
              <Skeleton
                row={1}
                className={`mr-0`}
                width={['120px']}
                skeletonItemClass={`h-10 rounded-2xl`}
              />
              <Skeleton
                row={1}
                className={`h-10`}
                width={['120px']}
                skeletonItemClass={`h-10 rounded-2xl`}
              />
            </div>
          </div>
        )}
        {!loading && (
          <div className="flex mb-6 justify-between">
            <div className="flex">
              <div className="bg-disabled-color shadow-box-drop rounded-full w-10 h-10 flex items-center justify-center cursor-pointer">
                <BackIcon
                  className="hover:text-primary-color"
                  onClick={handleBack}
                />
              </div>
              <div className="text-3xl leading-10 font-bold text-color-text-1 ml-2.5">
                {related?.nickname || related?.id || '--'}
              </div>
            </div>
            <div className="flex space-x-4">
              {!isView && (
                <>
                  <Button onClick={handleCancel} styleType="Border">
                    {t('common:cancel')}
                  </Button>
                  <Button onClick={handleSave} loading={saveDataloading}>
                    {t('common:save')}
                  </Button>
                </>
              )}
              {isView && (
                <>
                  {canDelete && (
                    <Button
                      styleType={'Secondary Danger'}
                      onClick={() => setRemoveModal(true)}
                    >
                      {t('related_company_accounts:remove_bank_account')}
                    </Button>
                  )}
                  {canUpdate && hasSuccess() && (
                    <Button onClick={() => setIsView(false)}>
                      {t('related_company_accounts:edit_bank')}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        <BankAccountDetails
          // {...props}
          isView={isView}
          loading={loading}
          related={related}
          relatedFormData={relatedFormDate}
          setRelatedFormDate={handleRelatedFormData}
          banks={banks}
          useFormReturn={useFormReturn}
          areaCodes={areaCodes}
          businessID={businessID}
          transferMethod={transferMethod} />
        <BankStatements
          // {...props}
          areaCodes={areaCodes}
          transferMethod={transferMethod}
          isView={isView}
          loading={loading}
          relatedFormData={relatedFormDate}
          setRelatedFormDate={handleRelatedFormDate}
          // isAdd={true}
          banks={banks}
          useFormReturn={useFormReturn}
          related={related}
          businessID={businessID}
        />
      </div>
      <GlobalLoading loading={removeLoading} />
      <ModalEmpty visible={cancelModal} onCancel={() => setCancelModal(false)}>
        <div className="text-center">
          <div className="bg-warning-color/10 p-5 rounded-full  w-20 h-20 justify-center flex mb-4 m-auto">
            <WarningIcon className="text-warning-color" size={40} />
          </div>
          <div className="mt-2 text-sm font-medium">
            {t('related_company_accounts:unsaved_changes_tips')}
          </div>
          <Button onClick={handleKeepEdit} className="mt-6 w-full">
            {t('related_company_accounts:keep_editing')}
          </Button>
          <Button
            onClick={handleDiscardChanges}
            styleType="Border"
            className="mt-4 w-full"
          >
            {t('related_company_accounts:discard_changes')}
          </Button>
        </div>
      </ModalEmpty>
      <ModalEmpty
        visible={removeModal}
        onCancel={() => setRemoveModal(false)}
        icon={<span></span>}
      >
        <div className="text-center">
          <div className="bg-warning-color/10 p-5 rounded-full  w-20 h-20 justify-center flex mb-4 m-auto">
            <WarningIcon className="text-warning-color" size={40} />
          </div>
          <div className="text-xl font-bold">
            {isDefaultBank
              ? t('related_company_accounts:unable_remove')
              : t('related_company_accounts:remove_bank')}
          </div>
          <div className="mt-2 text-sm font-medium">
            {isDefaultBank
              ? t('related_company_accounts:unable_remove_tips')
              : t('related_company_accounts:account_removed')}
          </div>
          {isDefaultBank ? (
            <>
              <Button
                onClick={() => setRemoveModal(false)}
                className="mt-6 w-full"
                styleType="Primary"
              >
                {t('common:back')}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleRemove} className="mt-6 w-full">
                {t('common:confirm')}
              </Button>
              <Button
                onClick={() => setRemoveModal(false)}
                styleType="Border"
                className="mt-4 w-full"
              >
                {t('common:cancel')}
              </Button>
            </>
          )}
        </div>
      </ModalEmpty>
    </>
  );
};

export default RelatedFormView;
