import Table from '@/components/table';
import { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import type { TableRecord } from '@/components/table/interface';
import { useTranslation } from 'react-i18next';
import ModalEmpty from '@/components/common/modal/modal-empty';
import dayjs from 'dayjs';
import { Dropdown } from 'antd';
import Menu, { Item as MenuItem } from 'rc-menu';
import { IObject } from '@/types/common-types';
import Button from '@/components/common/button';
import Label from '@/components/common/label';
import {
  NewUpdateApiCredential,
  newGenerateApiCredential,
  newGetApiCredential,
} from './server';
import { Alert } from '@/components/common/alert/alert';
import EllipsisIcon from '@/components/svg/ellipsis-icon';
import CopyIcon from '@/components/svg/copy-icon';
import { useParams } from 'react-router-dom';
import ActionType from '@/actions/action-type';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';
import NewIcon from '@/pages/terminal/terminal-icons/new-icon';
import WarningIcon from '@/components/svg/warning-side-icon';
import UploadIcon from '@/components/svg/upload-icon';
import ErrorIcon from '@/components/svg/failed-icon';
import { selectColumn } from '@/utils';
import Textarea from '@/components/common/textarea';


const NewApiCredentialsView = () => {
  const { t } = useTranslation(["settings", "business_account_settings", "account_balance"]);
  const { lang = 'en', p_business_id: businessID = '' } = useParams();
  const dispatch: IDispatch = useDispatch();
  const [colums, setColums] = useState<ColumnsType<TableRecord>>([]);
  const [records, setRecords] = useState<IObject[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [currentRecord, setCurrentRecord] = useState<IObject>({});
  const [deactivateModal, setDeactivateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [publicModal, setPublicModal] = useState<boolean>(false);
  const [appKey, setAppKey] = useState<string>('');
  const [publicKey, setPublicKey] = useState<string>('');
  const [appId, setAppId] = useState<string>('');
  const [appSecret, setAppSecret] = useState<string>('');
  const [errorCode, setErrorCode] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [generateModal, setGenerateModal] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [tryLoading, setTryLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(30);
  const [page, setPage] = useState<number>(1);
  const dateFormat = lang === 'en' ? 'MMM D, YYYY HH:mm:ss' : 'MMM DD日, YYYY HH:mm:ss';



  useEffect(() => {
    handleColums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          key: '1',
          name: t('settings:settings'),
          type: 'label',
        },
        {
          key: '2',
          name: t('settings:api_credentials'),
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  const loadData = async (
    currentPage = page,
    limit = pageSize,
  ) => {
    setLoadingData(true);
    const newdata = await newGetApiCredential(businessID, (currentPage - 1) * limit, limit + 1)
    setRecords(newdata);
    setPage(currentPage);
    setPageSize(limit);
    setLoadingData(false);
  };

  const handleDeactivate = (record: IObject, type: string) => {
    if (record && record.id) {
      setCurrentRecord(record);
      if (type === 'delete') {
        setDeleteModal(true)
      } else {
        setDeactivateModal(true);
      }
    }
  };

  const handleColums = () => {
    const columns: ColumnsType<TableRecord> = [
      {
        key: 'app_id',
        title: t('settings:new_app_slug'),
        dataIndex: 'app_id',
        width: 600,
        render: (value, _record) => {
          return (
            <div className="flex items-center justify-between">
              <div className="w-[600px] truncate">{value}</div>
              <CopyIcon size={20} className="text-primary-color " onClick={() => handleCopy(value)} />
            </div>
          );
        },
      },
      {
        key: 'status',
        title: t('common:status'),
        dataIndex: 'status',
        width: 120,
        render: (value, record) => {
          return selectColumn({
            key: 'status',
            record,
            options: [
              {
                label: `${t('settings:active')}`,
                value: 'active',
                color: 'text-success-color bg-success-color',
              },
              {
                label: `${t('settings:inactive')}`,
                value: 'inactive',
                color: 'text-error-color bg-error-color',
              }
            ],
          });
        },
      },
      {
        key: 'p_created_at',
        title: t('settings:created_at'),
        dataIndex: 'p_created_at',
        width: 120,
        render: (value, _record) => {
          return dayjs(value).format(dateFormat);
        },
      },
      {
        key: 'actions',
        title: t('common:actions'),
        fixed: 'right',
        dataIndex: 'actions',
        align: 'left',
        width: 60,
        render: (_value, record) => {
          return (
            <Dropdown
              destroyPopupOnHide={true}
              dropdownRender={() => (
                <Menu className="bg-primary-background-color">
                  {record.status === 'active' && (
                    <MenuItem key="deactivate">
                      <div
                        className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                        onClick={() => {
                          handleDeactivate(record, 'deactivate');
                        }}
                      >
                        <span className="text-color-text-1 text-base font-medium">
                          {t('settings:deactivate')}
                        </span>
                      </div>
                    </MenuItem>
                  )}

                  <MenuItem key="delete">
                    <div
                      className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                      onClick={() => {
                        handleDeactivate(record, 'delete');
                      }}
                    >
                      <span className="text-color-text-1 text-base font-medium">
                        {t('settings:delete')}
                      </span>
                    </div>
                  </MenuItem>
                </Menu>
              )}
            >
              <div className="justify-center flex">
                <div className="flex items-center cursor-pointer p-1.5 rounded-full w-max hover:bg-primary-color/10 group">
                  <EllipsisIcon className="text-icon-color group-hover:text-primary-color" />
                </div>
              </div>
            </Dropdown>
          );
        },
      },
    ];
    setColums(columns);
    return columns;
  };

  // const handleGenerateModalCancel = () => {
  //   setGenerateModal(false);
  //   setAppKey('');
  // };

  const handleDeactivateModalCancel = () => {
    setGenerateModal(false);
    setAppKey('');
    setDeactivateModal(false);
    setDeleteModal(false);
    setPublicModal(false)
    setErrorModal(false)
    setPublicKey('')
    setCurrentRecord({});
  };

  const handleCopy = (value = appKey) => {
    navigator.clipboard.writeText(value).then(() => {
      Alert.success({
        message: t('common:copied'),
        wrapClass: 'top-px',
      });
    });
  };

  const handleCreat = async () => {
    setPublicModal(true)
  };

  const handleUploadPublic = async () => {
    try {
      setTryLoading(true)
      const resp = await newGenerateApiCredential(businessID, btoa(publicKey))
      const { app_id, webhook_public_key } = resp
      setPublicModal(false)
      if (app_id) {
        setErrorModal(false)
        setAppId(app_id)
        setAppSecret(webhook_public_key)
        setGenerateModal(true)
        Alert.success({
          message: t('settings:new_created'),
          wrapClass: 'top-px',
        });
        loadData()
      } else {
        const { error_code, error_message } = resp
        setErrorCode(error_code)
        setErrorMsg(error_message)
        setErrorModal(true)
        Alert.error({
          message: t('settings:created_failed'),
          wrapClass: 'top-px',
        });
      }
      setTryLoading(false)
    } catch {
      setTryLoading(false)
      Alert.error({
        message: t('settings:key_error'),
        wrapClass: 'top-px',
      });
    }
  };

  const handleUpdate = async (status: string, recordId?: string) => {
    let id = currentRecord.id
    if (recordId) {
      id = recordId
    }
    const resp = await NewUpdateApiCredential(businessID, id, {
      action: status
    })
    if (resp.code === 200) {
      let tips = 'settings:api_deactivated'
      if (status === 'active') tips = 'settings:api_activated'
      if (status === 'delete') tips = 'settings:api_delete'

      handleDeactivateModalCancel()
      loadData()
      Alert.success({
        message: t(tips),
        wrapClass: 'top-px',
      });
    }
  };

  const formatPublicKey = (value: string) => {
    const publicKey = atob(value)
      .replace('-----BEGIN PUBLIC KEY-----', '')
      .replace('-----END PUBLIC KEY-----', '')
      .replace(/\n/g, '');
    const encodedStr = btoa(publicKey);
    const decodedStr = atob(encodedStr);
    const decodedPEM = `-----BEGIN PUBLIC KEY-----\n${decodedStr}\n-----END PUBLIC KEY-----`;
    decodedPEM.replace(/\n/g, '<br>')
    return decodedPEM
  }

  return (
    <>
      <div className="flex overflow-hidden h-full">
        <div className="bg-system-background-color w-full flex flex-col px-8 py-6 overflow-y-scroll">
          <div className="md:flex justify-between px-1">
            <div className="text-xl leading-[30px] md:text-3xl md:leading-10 font-bold text-color-text-1 mb-4 md:mb-0">
              {t('settings:api_credentials')}
            </div>
            <div className="flex space-x-4">
              <Button onClick={handleCreat}
                iconInstance={<NewIcon />}>
                {t('settings:generate_new_credential')}
              </Button>
            </div>
          </div>
          <div className="mt-6">
            <Table
              columns={colums}
              records={records}
              loadingData={loadingData}
              pagination={{
                className: 'mt-6',
                current: page,
                currentSize: records.length,
                pageSize,
                pageSizeOptions: [30, 60, 120],
                changePageCallback: loadData,
              }}
            />
          </div>
        </div>
      </div>

      <ModalEmpty visible={generateModal} onCancel={handleDeactivateModalCancel}>
        <div className="text-center">
          <div className="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
            >
              <rect
                opacity="0.1"
                width="80"
                height="80"
                rx="40"
                fill="#0094FF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40 20C29 20 20 29 20 40C20 51 29 60 40 60C51 60 60 51 60 40C60 29 51 20 40 20ZM37.5 50V37.5H42.5V50H37.5ZM37.5 32.5C37.5 34 38.5 35 40 35C41.5 35 42.5 34 42.5 32.5C42.5 31 41.5 30 40 30C38.5 30 37.5 31 37.5 32.5Z"
                fill="#0094FF"
              />
            </svg>
          </div>
          <div className="font-bold text-color-text-1 text-xl mt-2">
            {t('settings:new_copy_aip_tips')}
          </div>
          <Label className="mt-4" value={t('settings:new_app_slug')} />
          <div className="truncate py-2 px-3 border  text-sm bg-primary-color/10 border-primary-color relative text-left">
            {appId}
            <div className='absolute top-[10px] right-2'>
              <CopyIcon size={16} className="text-primary-color cursor-pointer" onClick={() => handleCopy(appId)} />
            </div>
          </div>

          <Label className="mt-4" value={t('settings:webhook_public_key')} />
          <div className="whitespace-pre-wrap break-words py-2 px-3 border  text-sm bg-primary-color/10 border-primary-color relative text-left">
            {formatPublicKey(appSecret)}
            <div className='absolute top-[10px] right-2'>
              <CopyIcon size={16} className="text-primary-color cursor-pointer" onClick={() => handleCopy(formatPublicKey(appSecret))} />
            </div>
          </div>
          <Button className="w-full mt-8" onClick={handleDeactivateModalCancel}>
            {t('common:back')}
          </Button>
        </div>
      </ModalEmpty>

      <ModalEmpty
        visible={deactivateModal}
        onCancel={handleDeactivateModalCancel}
      >
        <div className="text-center flex flex-col items-center">
          <WarningIcon />
          <div className="font-bold text-color-text-1 text-xl mt-2">
            {t('settings:new_deactived_tip')}
          </div>
          <div className=" text-color-text-1 text-sm mt-2">
            {t('settings:new_deactived_tip_second')}
          </div>
          <Button className="w-full mt-8" onClick={handleDeactivateModalCancel}>
            {t('common:back')}
          </Button>
          <Button styleType="Border" className="w-full mt-4" onClick={() => handleUpdate('inactive')}>
            {t('settings:deactivate')}
          </Button>
        </div>
      </ModalEmpty>


      <ModalEmpty
        visible={deleteModal}
        onCancel={handleDeactivateModalCancel}
      >
        <div className="text-center flex flex-col items-center">
          <WarningIcon />
          <div className="font-bold text-color-text-1 text-xl mt-2">
            {t('settings:new_delete_tip')}
          </div>
          <div className=" text-color-text-1 text-sm mt-2">
            {t('settings:new_delete_tip_second')}
          </div>
          <Button className="w-full mt-8" onClick={handleDeactivateModalCancel}>
            {t('common:back')}
          </Button>
          <Button styleType="Border" className="w-full mt-4" onClick={() => handleUpdate('delete')}>
            {t('settings:delete')}
          </Button>
        </div>
      </ModalEmpty>

      <ModalEmpty
        visible={publicModal}
        onCancel={handleDeactivateModalCancel}
      >
        <div className="text-center flex flex-col items-center ">
          <UploadIcon />
          <div className="font-bold text-color-text-1 text-xl mt-2">
            {t('settings:upload_signature')}
          </div>
          <div className=" text-color-text-2 text-sm mt-2">
            {t('settings:upload_first')}
          </div>
          <div className='flex items-start w-[100%]'>
            <Label className="mt-8" value={t('settings:public_key')} required />
          </div>
          <Textarea
            warpClass="mt-1 w-[100%] h-[100%]"
            value={publicKey}
            allowClear={true}
            maxLength={5000}
            placeholder={t('settings:public_placeholder')}
            onChange={(value) => {
              setPublicKey(value);
            }}
          />
          <Button className="w-full mt-8" onClick={handleUploadPublic} disabled={!publicKey} disabledStyle={true} loading={tryLoading}>
            {t('settings:upload')}
          </Button>
          <Button styleType="Secondary" className="w-full mt-4" onClick={handleDeactivateModalCancel}>
            {t('common:cancel')}
          </Button>
        </div>
      </ModalEmpty>

      <ModalEmpty
        visible={errorModal}
        onCancel={handleDeactivateModalCancel}
      >
        <div className="text-center flex flex-col items-center">
          <ErrorIcon />
          <div className="font-bold text-color-text-1 text-xl mt-2">
            {t('settings:upload_failed')}
          </div>
          <div className='text-[red] mt-2'>{errorCode}</div>
          <div className='text-[red] mt-2'>{errorMsg}</div>
          <div className=" text-color-text-1 text-sm mt-2">
            {t('settings:re_enter')}
          </div>
          <div className='flex items-start w-[100%]'>
            <Label className="mt-8" value={t('settings:public_key')} required />
          </div>
          <Textarea
            warpClass="mt-1 w-[100%] h-[100%]"
            value={publicKey}
            allowClear={true}
            maxLength={5000}
            placeholder={t('settings:public_placeholder')}
            onChange={(value) => {
              setPublicKey(value);
            }}
          />
          <Button className="w-full mt-8" onClick={handleUploadPublic} disabled={!publicKey} loading={tryLoading}>
            {t('settings:retry')}
          </Button>
          <Button styleType="Border" className="w-full mt-4" onClick={handleDeactivateModalCancel}>
            {t('common:cancel')}
          </Button>
        </div>
      </ModalEmpty>
    </>
  );
};

export default NewApiCredentialsView;
