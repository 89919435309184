import CopyIcon from '@/components/svg/copy-icon';
import type { IObject } from '@/types/common-types';
import dayjs from 'dayjs';
import { useEffect, useState, useRef } from 'react';
import DefaultItemIcon from '../terminal-icons/default-item-icon';
import {
  handlePrice,
  insetHead,
  triggerWonderRoute,
  getClassByStatus,
  ConvertTimeZone,
} from '../../../utils/terminal-untils';
import type { ITerminalLineItemsPage } from '../../../types/terminal-type';
import { useTranslation } from 'react-i18next';
import PaymentDetailsPanel from './payment-detail-panel';
import Config from '@/libs/config';
import Skeleton from '@/components/skeleton';
import { transformField } from '@/utils/field-utils';
import debounce from 'lodash.debounce';
import { querySellerBusiness } from '../../../data/terminal';
import { useParams, useNavigate } from 'react-router-dom';
// import api from '@/libs/api';
import axios from 'axios';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-hk';
import 'dayjs/locale/en';
import NetworkError from '../components/network-error';


let flag = false;
interface IOrderHistoryListViewProps extends ITerminalLineItemsPage {
  orderNumber: any;
  record: IObject;
  dataTimeOut: boolean;
  voidFun: () => Promise<boolean>;
  handlePayNow: () => void;
  handleExportPdf: () => void;
  loadData: () => void;
}

const OrderHistoryFormView: React.FC<IOrderHistoryListViewProps> = (props) => {
  const {
    businessID,
    record,
    token,
    inApp,
    dataTimeOut,
    orderNumber,
    voidFun,
    loadData,
    tokenExpire = false,
  } = props;
  const {
    line_items = [],
    oms_delivery_note: oms = {},
    state = '',
    correspondence_state: correspondenceState = '',
    number = '',
    currency,
    created_at = '',
    initial_delivery: initialDelivery = 0,
    subtotal,
    discount_total: discountTotal = 0,
    initial_total,
    reference_number,
    note,
    sale_transactions: saleTransactions = [],
    void_transactions: voidTransactions = [],
    refund_transactions: refundTransactions = [],
    delivery_order: deliveryOrder = {},
  } = record || {};

  const {
    state: deliveryState,
    tracking_number,
    shipper,
  } = deliveryOrder;

  const { max_delivery_time, min_delivery_time } = oms;
  const lineItems = line_items || [];
  const cardList = lineItems.filter(
    (item: any) => item.purchasable_type === 'Listing'
  );
  let cartTotal = 0;
  cardList.forEach((item: any) => {
    cartTotal += item.quantity;
  });
  const navigate = useNavigate();
  const [newNote, setnewNote] = useState('');
  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const { lang = 'en' } = useParams();
  const isVoid = state === 'voided';
  const isUnpaid = correspondenceState === 'unpaid';
  const isRefunded = correspondenceState === 'refunded';
  const [paymentRecord, setPaymentRecord] = useState<IObject | undefined>(
    undefined
  );
  const [refundedRecord, setRefundedRecord] = useState<IObject | undefined>(
    undefined
  );
  const [pickTime, setpickTime] = useState(false);
  const windowRef = useRef(null);

  let scheduleAt = '-';
  const dateFormat = lang === 'en' ? 'MMM DD YYYY' : 'YYYY年MM月DD日';

  if (max_delivery_time && min_delivery_time) {

    scheduleAt = `${t('extended_service:estimated')} ${dayjs(
      min_delivery_time
    ).tz('Asia/Hong_Kong').format(dateFormat)} ${dayjs(min_delivery_time).tz('Asia/Hong_Kong').format(
      'HH:mm'
    )} ~ ${dayjs(max_delivery_time).tz('Asia/Hong_Kong').format('HH:mm')}`;
  }

  useEffect(() => {
    let canPay = isWithinOneHour(min_delivery_time)
    setpickTime(!canPay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min_delivery_time]);

  useEffect(() => {
    dayjs.locale(lang.toLocaleLowerCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  useEffect(() => {
    getPaymentDefails();
    if (note) {
      const data = {
        note: note,
      };
      triggerWonderRoute(JSON.stringify(data), 'default_value');
      setnewNote(note);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  useEffect(() => {
    if (tokenExpire) {
      triggerWonderRoute('true', 'is401');
    }
    window.addEventListener(
      'myCustomEvent',
      (e: any) => {
        if (e.detail.note) {
          setnewNote(e.detail.note);
        }
        if (e.detail.void_invoice && e.detail.void_invoice === 'true') {
          if (!flag) {
            flag = true;
            handleVoid();
          }
        }
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isWithinOneHour = (givenDateString: string) => {
    const givenDate = dayjs(givenDateString).tz('Asia/Hong_Kong');
    const now = dayjs().tz('Asia/Hong_Kong');
    return now.isAfter(givenDate.subtract(1, 'hour').subtract(10, 'minute'));
  }

  const getLatestTransactions = (data: any) => {
    let paymentDefails = undefined;
    if (Array.isArray(data)) {
      for (let i = data.length - 1; i >= 0; i--) {
        const record = data[i];
        if (record.success) {
          paymentDefails = record;
          break;
        }
      }
    }
    return paymentDefails;
  };

  const getPaymentDefails = () => {
    let paymentDefails = undefined;
    if (voidTransactions.length > 0 && isUnpaid) {
      paymentDefails = getLatestTransactions(voidTransactions);
    } else {
      paymentDefails = getLatestTransactions(saleTransactions);
    }
    if (isRefunded) {
      setRefundedRecord(getLatestTransactions(refundTransactions));
    }
    setPaymentRecord(paymentDefails);
  };

  const handleVoid = async () => {
    triggerWonderRoute('show', 'loading');
    const result = await voidFun();
    if (result) {
      triggerWonderRoute('void_invoice_success', 'option_result');
    } else {
      triggerWonderRoute('void_invoice_fail', 'option_result');
    }
    flag = false;
  };

  const handleClick = debounce(() => {
    const routeAddress = `/businesses/${businessID}/terminal/orders/payment?number=${orderNumber}&token=${token}&isApp=${inApp}`;
    if (inApp) {
      triggerWonderRoute(routeAddress, 'route');
    } else {
      navigate(routeAddress);
    }
  }, 500);

  const handleMobileCopy = () => {
    triggerWonderRoute(reference_number, 'copy');
  };

  const alert = (value: any, action?: any) => {
    triggerWonderRoute(value, action);
  };

  const invoice = async () => {
    triggerWonderRoute('void_invoice', 'confirm');
  };

  const handlePaymentStatus = (status: string) => {
    const { className, showStatus } = getClassByStatus(status, t);
    if (!status) return '-';

    return (
      <div
        className={` h-[26px] font-normal rounded-3xl text-base px-3 pt-[2px] ${className} !text-white`}
      >
        {showStatus}
        {/* {transformField(status)} */}
      </div>
    );
  };

  const sharePdf = async () => {
    triggerWonderRoute('show', 'loading');
    try {
      const business = await querySellerBusiness();
      const resp = await axios({
        method: 'post',
        url: `${Config.urls.gatewayUrl}/api/helper/generate-pdf`,
        data: {
          templateType: 'wonder_order_details',
          fileName: `text.pdf`,
          folderName: businessID,
          fileData: {
            liquid_config: {
              locale: lang,
              money_currency: 'HKD',
              money_currency_symbol: 'HK$',
              money_decimal_points: 2,
            },
            business: business,
            order: record,
          }
        },
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
        }
      })
      const PDFurl = resp.data.data.url ? resp.data.data.url : '';
      const data = {
        pdfUrl: PDFurl,
        referenceNumber: reference_number,
      };
      triggerWonderRoute(JSON.stringify(data), 'pdf_share');
    } catch (error) {
      console.error(error)
    }
  };

  const handleTouchStart = (event: any) => {
    event.stopPropagation();
    const targetElement = event.target;
    if (targetElement.className.includes('text-[#FC2E01]')) {
      targetElement.style.backgroundColor = '#ffd5cd';
    } else if (targetElement.className.includes('flag')) {
      targetElement.style.backgroundColor = '#0094FF';
      targetElement.style.color = 'white';
    } else {
      targetElement.style.opacity = '50%';
    }
  };
  const handleTouchEnd = (event: any) => {
    event.stopPropagation();
    const targetElement = event.target;
    if (targetElement.className.includes('text-[#FC2E01]')) {
      targetElement.style.backgroundColor = 'white';
    } else if (targetElement.className.includes('flag')) {
      targetElement.style.backgroundColor = 'rgba(0,148,255,0.2)';
      targetElement.style.color = '#0094FF';
    } else {
      targetElement.style.opacity = '100%';
    }
  };

  return (
    <div
      className="min-h-screen bg-[#F5F5F5] overflow-auto"
      style={{ WebkitTapHighlightColor: 'transparent' }}
      ref={windowRef}
    >
      {insetHead()}
      <div
        className={`absolute inset-0 h-full flex flex-col font-sfPro overflow-x-hidden overflow-y-auto lg:hidden hidden_scrollbar pb-16`}
      >
        <div className={`bg-[#F5F5F5] flex-1 hidden_scrollbar`}>
          {Object.keys(record).length !== 0 && (
            <div className="p-5 pb-24">
              <div className="font-bold mt-5 mb-2 flex justify-between">
                {t('extended_service:ordered_items')}{' '}
              </div>
              <div className="px-4 py-5 bg-primary-background-color rounded-2xl">
                {cardList.map((item: any) => {
                  return (
                    <div className="flex mt-[14px] pb-[14px]" key={item.id}>
                      <div className="p-2 bg-disabled-color rounded-2xl">
                        {item.image_url && (
                          <div
                            className="w-[72px] h-[72px] flex justify-center items-center bg-center bg-no-repeat bg-contain"
                            style={{
                              backgroundImage: `url(${item.image_url})`,
                            }}
                          />
                        )}
                        {!item.image_url && (
                          <div
                            style={{
                              backgroundColor: 'rgba(226, 228, 231, 0.15)',
                            }}
                            className="w-[72px] h-[72px] flex justify-center items-center rounded"
                          >
                            <DefaultItemIcon />
                          </div>
                        )}
                      </div>
                      <div className="ml-2 flex-1 relative">
                        <div className="w-[85%] text-base">{item.label}</div>
                        <div className=" absolute bottom-0 left-0 font-semibold text-sm">
                          <div>
                            {handlePrice(item.price)} {currency}
                          </div>
                        </div>
                        {(item.label || '').includes('Smart POS PAX') && (
                          <div
                            className="absolute  right-0 top-0 w-[14px] h-[14px] rounded-full bg-[#0094FF] text-white text-center leading-[14px] text-xs"
                            onClick={() => alert('terminal_fee', 'alert')}
                          >
                            i
                          </div>
                        )}
                        <div
                          className={`absolute right-0 bottom-[-4px] flex w-24 justify-end`}
                        >
                          <div className={`text-base leading-7`}>
                            x {item.quantity}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="border-[1px] border-solid mt-[14px] rounded-2xl border-[#0094FF] px-4 py-2 text-base ">
                  <div className="flex justify-between items-center mt-1">
                    <span className="text-[#8E8E93]">
                      {t('extended_service:total_items')}
                    </span>
                    <span className={` max-w-[180px] break-words text-right `}>
                      {cartTotal} {t('extended_service:items')}
                    </span>
                  </div>
                  <div className="flex justify-between items-center mt-1">
                    <span className="text-[#8E8E93]">
                      {t('extended_service:subtotal')}
                    </span>
                    <span className={` max-w-[180px] break-words text-right `}>
                      {handlePrice(subtotal)} {currency}
                    </span>
                  </div>
                  <div className="flex justify-between items-center mt-1">
                    <span className="text-[#8E8E93]">
                      {t('extended_service:delivery_fee')}
                    </span>
                    <span className={` max-w-[180px] break-words text-right `}>
                      {handlePrice(initialDelivery)} {currency}
                    </span>
                  </div>
                  {discountTotal !== 0 && (
                    <div className="flex justify-between items-center mt-1">
                      <span className="text-[#8E8E93]">
                        {t('extended_service:discount')}
                      </span>
                      <span
                        className={` max-w-[180px] break-words text-right `}
                      >
                        - {handlePrice(discountTotal)} {currency}
                      </span>
                    </div>
                  )}
                  <div className="flex justify-between items-center mt-1 font-bold">
                    <span>{t('extended_service:total')}</span>
                    <span className={` max-w-[180px] break-words text-right `}>
                      {handlePrice(initial_total)} {currency}
                    </span>
                  </div>
                </div>
              </div>

              <div className="text-colot-text-1 font-bold text-lg mt-5 mb-2 flex justify-between">
                {t('extended_service:invoice_details')}
              </div>
              <div className="bg-primary-background-color rounded-xl p-4 text-base">
                <div>
                  <div className="flex justify-between items-center  relative">
                    <div className="text-[#8E8E93]">
                      {t('common:invoice_number_common')}
                    </div>
                    <div
                      className={` max-w-[170px] break-words text-right absolute top-[0] right-[0]`}
                    >
                      {number}
                    </div>
                  </div>
                  <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 mt-[30px]" />
                </div>
                <div>
                  <div className="flex justify-between items-center">
                    <div className="text-[#8E8E93]">
                      {t('extended_service:reference_number')}
                    </div>
                    <div className={` max-w-[160px]  flex`}>
                      {reference_number}
                      <CopyIcon
                        onClick={handleMobileCopy}
                        className="text-primary-color"
                      />
                    </div>
                  </div>
                  <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
                </div>
                <div>
                  <div className="flex justify-between items-center ">
                    <div className="text-[#8E8E93]">
                      {t('extended_service:payment_status')}
                    </div>
                    <div className={` max-w-[180px] break-words text-right `}>
                      {handlePaymentStatus(correspondenceState)}
                    </div>
                  </div>
                  <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
                </div>
                <div>
                  <div className="flex justify-between items-center">
                    <span className="text-[#8E8E93]">
                      {t('extended_service:delivery_status')}
                    </span>
                    <span className={` max-w-[180px] break-words text-right `}>
                      {handlePaymentStatus(deliveryState)}
                    </span>
                  </div>
                  <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
                </div>
                <div>
                  <div className="flex justify-between items-center">
                    <span className="text-[#8E8E93]">
                      {t('extended_service:created_at')}
                    </span>
                    <span className={`max-w-[180px] break-words text-right `}>
                      {/* {dayjs(created_at).format(`${dateFormat} HH:mm:ss`)} */}
                      {ConvertTimeZone(created_at, `${dateFormat} HH:mm:ss`)}
                    </span>
                  </div>
                  <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
                </div>
                <div>
                  <div className="flex justify-between items-center">
                    <span className="text-[#8E8E93]">
                      {t('extended_service:note')}
                    </span>
                    {/* <VectorIcon onClick={() => alert('note', 'bootom_sheet')} /> */}
                  </div>
                  <div>{newNote}</div>
                </div>
              </div>

              <div className="text-colot-text-1 font-bold text-lg mt-5 mb-2 flex justify-between">
                {t('extended_service:shipping_information')}
              </div>
              <div className="bg-primary-background-color rounded-xl p-4 text-base">
                <div>
                  <div className="items-center">
                    <div className="flex justify-between">
                      <span className="text-base font-semibold">
                        {oms.name ? oms.name : '-'}
                      </span>
                    </div>
                    <div className={`mt-1 font-normal`}>
                      {oms.phone ? oms.phone : '-'}
                    </div>
                    <div className={`mt-1 font-normal`}>
                      {oms.address1 ? oms.address1 : '-'}{' '}
                      {oms.street ? oms.street : '-'}
                    </div>
                    <div className={`mt-1 font-normal`}>
                      {t('extended_service:hong_kong')}
                    </div>
                  </div>
                  <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
                </div>
                <div>
                  <div className="items-center">
                    <div className="text-[#8E8E93] flex">
                      {t('extended_service:delivery_via')}
                      <div
                        className=" w-[14px] h-[14px] rounded-full bg-[#0094FF] text-white text-center leading-[14px] text-xs mt-1 ml-1"
                        onClick={() => alert('delivery_via', 'alert')}
                      >
                        i
                      </div>
                    </div>
                    <div className={` max-w-[260px] break-words  mt-1`}>
                      {deliveryState
                        ? deliveryState.toLowerCase().includes('manual')
                          ? t('extended_service:wonder_staff')
                          : shipper
                            ? transformField(shipper)
                            : '-'
                        : ''}
                    </div>
                  </div>
                  <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
                </div>
                <div>
                  <div className="items-center">
                    <div className="text-[#8E8E93] flex">
                      {t('extended_service:delivery_schedule')}
                      <div
                        className=" w-[14px] h-[14px] rounded-full bg-[#0094FF] text-white text-center leading-[14px] text-xs mt-1 ml-1"
                        onClick={() => alert('delivery_scheule', 'alert')}
                      >
                        i
                      </div>
                    </div>
                    <div className={`mt-1`}>
                      {deliveryState
                        ? deliveryState.toLowerCase().includes('manual')
                          ? t('extended_service:contant_support')
                          : scheduleAt
                        : ''}
                    </div>
                  </div>
                  <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
                </div>
                <div>
                  <div className="items-center">
                    <div className="text-[#8E8E93] flex">
                      {t('extended_service:tracking_number')}
                      <div
                        className=" w-[14px] h-[14px] rounded-full bg-[#0094FF] text-white text-center leading-[14px] text-xs mt-1 ml-1"
                        onClick={() => alert('tracking_number', 'alert')}
                      >
                        i
                      </div>
                    </div>
                    <div className={` max-w-[260px] break-words  mt-1`}>
                      {deliveryState
                        ? deliveryState.toLowerCase().includes('manual')
                          ? t('extended_service:contant_support')
                          : tracking_number
                            ? tracking_number
                            : '-'
                        : ''}
                    </div>
                  </div>
                </div>
              </div>

              {paymentRecord && (
                <>
                  <div className="text-colot-text-1 font-bold text-lg mt-5 mb-2 flex justify-between">
                    {t('extended_service:payment_details')}
                  </div>
                  {isRefunded && refundedRecord && (
                    <>
                      <PaymentDetailsPanel
                        record={refundedRecord}
                        dateFormat={dateFormat}
                        type={
                          <span
                            className={`h-[26px] font-medium rounded-3xl text-base px-3 pt-[2px] text-white  bg-primary-color`}
                          >
                            Refunded
                          </span>
                        }
                      />
                    </>
                  )}
                  <PaymentDetailsPanel
                    record={paymentRecord}
                    dateFormat={dateFormat}
                    type={
                      <span
                        className={`h-[26px] font-medium rounded-3xl text-base px-3 pt-[2px] text-white ${paymentRecord.voided_at
                          ? 'text-[#828282] bg-[#828282]'
                          : 'text-[#4CD964] bg-[#4CD964]'
                          } `}
                      >
                        {paymentRecord.voided_at ? 'Void' : 'Sale'}
                      </span>
                    }
                  />
                </>
              )}
            </div>
          )}

          {Object.keys(record).length === 0 && !dataTimeOut && (
            <div className="p-5 pb-24">
              <div className="mt-5 mb-2 flex">
                <Skeleton
                  row={1}
                  width={['100%']}
                  skeletonItemClass={`h-[21px] !w-[227px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                />
              </div>
              <div className="px-4 py-5 bg-primary-background-color rounded-2xl h-[399px]">
                <Skeleton
                  avatar={true}
                  row={2}
                  className="items-center !h-[96px]"
                  width={['100%']}
                  avatarClass="h-[80px] w-[80px] mr-3.5 bg-[#D3CFCF] !rounded-2xl"
                  skeletonItemClass="h-[17px]  mb-1.5 last:mb-0 rounded-[50px] w-[200px] mt-[12px] last:mt-8 bg-[#D3CFCF]"
                />
                <Skeleton
                  avatar={true}
                  row={2}
                  className="items-center !h-[96px] mt-4"
                  width={['100%']}
                  avatarClass="h-[80px] w-[80px] mr-3.5 bg-[#D3CFCF] !rounded-2xl"
                  skeletonItemClass="h-[17px]  mb-1.5 last:mb-0 rounded-[50px] w-[200px] mt-[12px] last:mt-8 bg-[#D3CFCF]"
                />

                <div className="border-[1px] border-solid mt-[14px] rounded-2xl border-[#0094FF] px-4 py-2 text-base h-[127px] ">
                  <div className="flex justify-between mt-2">
                    <Skeleton
                      row={1}
                      className={'w-[60px]'}
                      skeletonItemClass={`h-[10px] !w-[60px] rounded-2xl  bg-[#D3CFCF]`}
                    />
                    <Skeleton
                      row={1}
                      className={'w-[60px] ml-36'}
                      skeletonItemClass={`h-[10px] !w-[60px] rounded-2xl  bg-[#D3CFCF]`}
                    />
                  </div>
                  <div className="flex justify-between mt-4">
                    <Skeleton
                      row={1}
                      className={'w-[60px]'}
                      skeletonItemClass={`h-[10px] !w-[60px] rounded-2xl  bg-[#D3CFCF]`}
                    />
                    <Skeleton
                      row={1}
                      className={'w-[60px] ml-16'}
                      skeletonItemClass={`h-[10px] !w-[100px] rounded-2xl  bg-[#D3CFCF]`}
                    />
                  </div>
                  <div className="flex justify-between mt-4">
                    <Skeleton
                      row={1}
                      className={'w-[60px]'}
                      skeletonItemClass={`h-[10px] !w-[60px] rounded-2xl  bg-[#D3CFCF]`}
                    />
                    <Skeleton
                      row={1}
                      className={'w-[60px] ml-36'}
                      skeletonItemClass={`h-[10px] !w-[60px] rounded-2xl  bg-[#D3CFCF]`}
                    />
                  </div>
                  <div className="flex justify-between mt-4">
                    <Skeleton
                      row={1}
                      className={'w-[60px]'}
                      skeletonItemClass={`h-[17px] !w-[91px] rounded-2xl  bg-[#D3CFCF]`}
                    />
                    <Skeleton
                      row={1}
                      className={'w-[60px] ml-6'}
                      skeletonItemClass={`h-[17px] !w-[124px] rounded-2xl  bg-[#D3CFCF]`}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-8 mb-2 flex">
                <Skeleton
                  row={1}
                  width={['100%']}
                  skeletonItemClass={`h-[21px] !w-[227px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                />
              </div>
              <div className="bg-primary-background-color rounded-xl p-4 text-base h-[280px]">
                <div className="border-b-[1px] border-[#F5F5F5] py-5">
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-[21px] !w-[204px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                  />
                </div>
                <div className="border-b-[1px] border-[#F5F5F5] py-5">
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-[21px] !w-[299px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                  />
                </div>
                <div className="border-b-[1px] border-[#F5F5F5] py-5">
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-[21px] !w-[204px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                  />
                </div>
                <div className="border-b-[1px] border-[#F5F5F5] py-5">
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-[21px] !w-[299px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                  />
                </div>
              </div>
            </div>
          )}

          {dataTimeOut && Object.keys(record).length === 0 && (
            <NetworkError refresh={() => loadData()} />
          )}

          {!dataTimeOut && (
            <div
              className={`!text-base !font-semibold fixed bottom-0 w-[100%] bg-primary-background-color z-[999] pt-[22px] pb-[24px]`}
            >
              {Object.keys(record).length !== 0 && (
                <>
                  {!isVoid && isUnpaid && (
                    <>
                      {pickTime && (
                        <div className="flex items-center px-4 w-full ">
                          <div
                            className="text-center !leading-[44px]  font-sfPro bg-[#0094FF] !shadow-none w-full h-[44px] rounded-xl font-sfPro text-white !text-base !font-semibold duration-300"
                            onClick={handleClick}
                            onTouchStart={handleTouchStart}
                            onTouchEnd={handleTouchEnd}
                          >
                            {t('extended_service:pay_now')}
                          </div>
                        </div>)
                      }
                      <div
                        className="w-[92%] ml-[4%] text-center px-4   text-[#FC2E01] pb-[16px] rounded-xl h-[44px] mt-2 leading-[44px] duration-300"
                        onClick={invoice}
                        onTouchStart={handleTouchStart}
                        onTouchEnd={handleTouchEnd}
                      >
                        {t('extended_service:void_invoice')}
                      </div>
                    </>
                  )}
                  {(isVoid || !isUnpaid) && (
                    <div className="flex items-center px-4 w-full ">
                      <div
                        className="w-full h-[44px] text-center !leading-[44px] rounded-xl font-sfPro text-[#0094FF]  bg-[rgba(0,148,255,0.2)] !border-none !text-base !font-semibold flag duration-300"
                        onClick={sharePdf}
                        onTouchStart={handleTouchStart}
                        onTouchEnd={handleTouchEnd}
                      >
                        {t('extended_service:share_invoice_pdf')}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}

        </div>

      </div>
    </div>
  );
};

export default OrderHistoryFormView;
