/**
 *  Settled Transactions (Previous Settlements)
 */
import type { IObject } from '@/types/common-types';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Table from '@/components/table';
import type { ColumnsType } from 'antd/es/table';
import type { TableRecord } from '@/components/table/interface';
import Button from '@/components/common/button';
import { Transition } from '@headlessui/react';
import BigNumber from 'bignumber.js';
import { getTransactionsColumns } from '../account-balance-data';
import SettlementDetailsContent from './settlement-details-content';
import SettlementDetailsRolling from './settlement-details-rolling';
import { HadleGoPaymentDetails } from '@/utils/account-balance-untils';
import { getMerchantTransactions } from '@/data/account-balance';
import SendEmailModal from '@/pages/account-balance/components/send-email-modal';
import SendExportModal from '@/pages/account-balance/components/export-modal';
import BackIcon from '@/components/svg/back-icon';
import { useTranslation } from 'react-i18next';
import { plus } from '@/utils/galaxy-utils';
import type {
  IFilterField,
  IFilterOptions,
} from '@/components/common/filter';
import Filter, {
  FilterOperation,
  IFilterType,
} from '@/components/common/filter';
import { useDispatch, useSelector } from 'react-redux';
import { IReducersState } from '@/reducers';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';

const SettlementDetails = () => {
  const { t } = useTranslation("account_balance");
  const user = useSelector((state: IReducersState) => state.account.loginUser);
  const { lang = 'en', p_business_id: businessID = '', settlement_id: dayEndID = '' } = useParams();
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const [colums, setColums] = useState<ColumnsType<TableRecord>>([]);
  const [dayEndData, setDayEndData] = useState<IObject>({});
  const [transationRecords, setTransationRecords] = useState<IObject[]>([]);
  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [showSendEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [showSendExportModal, setShowExportModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<IFilterOptions[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  useEffect(() => {
    handleColums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    handleInitdta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('common:company_account'),
          key: '1',
          type: 'label',
        },
        {
          name: t('account_balance:account_balance'),
          key: '2',
          type: 'link',
          url: `/${lang}/businesses/${businessID}/account-balance/merchant-account`,
        },
        {
          name: t('account_balance:detail_for', { data: dayEndID }),
          key: '3',
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayEndID, lang])

  const handleColums = () => {
    const columns = getTransactionsColumns(t, true, lang);
    setColums(columns);
    return columns;
  };

  const handleInitdta = async (
    search = searchValue,
    filterOptions = options
  ) => {
    try {
      setTableLoading(true);
      const { result, storeDayEnd } = await getMerchantTransactions({
        dayEndId: dayEndID,
        pBusinessId: businessID,
        lang,
      });
      setInitLoading(false);
      setTableLoading(false);
      setDayEndData(storeDayEnd);
      setTransationRecords(handleSearchRecords(result, search, filterOptions));
      setSearchValue(search);
      setOptions(filterOptions);
    } catch (error) { }
  };

  const handleBack = () => {
    navigate({
      pathname: `/${lang}/businesses/${businessID}/account-balance/merchant-account`,
    });
  };

  const handleTotalAmount = (amount: string) => {
    let returnValue = amount;
    const temp = new BigNumber(returnValue);
    if (temp.toNumber() > 0) {
      returnValue = `+${temp.toFormat(2, 1)}`;
    } else {
      returnValue = temp.toFormat(2, 1);
    }
    return returnValue;
  };

  const filterFields: IFilterField[] = [
    {
      name: 'payment_type',
      label: t('account_balance:payment_type'),
      type: IFilterType.MULTISELECT,
      operations: [FilterOperation.EQUALS],
      options: [
        {
          name: 'sale',
          label: 'Sale',
        },
        {
          name: 'void',
          label: 'Void',
        },
        {
          name: 'refund',
          label: 'Refund',
        },
        {
          name: 'increment_pre_auth',
          label: 'Increased Pre-auth',
        },
        {
          name: 'pre_auth_complete',
          label: 'Pre-auth Completed',
        },
        {
          name: 'charge_back',
          label: 'Chargeback',
        },
        {
          name: 'dispute',
          label: 'Dispute',
        },
        {
          name: 'dispute_cancel',
          label: 'Cancelled Dispute',
        },
        {
          name: 'hold',
          label: 'On Hold',
        },
        {
          name: 'risk_hold',
          label: 'Risk Hold',
        },
        {
          name: 'pre_dispute',
          label: 'Pre-dispute',
        },
        {
          name: 'hold_reversal',
          label: 'Release',
        },
        {
          name: 'representment',
          label: 'Representment',
        },
      ],
    },
    {
      name: 'amount',
      label: t('account_balance:transaction_amount'),
      type: IFilterType.NUMBER,
      operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
    },
    {
      name: 'payment_method',
      label: t('common:payment_method'),
      type: IFilterType.MULTISELECT,
      operations: [FilterOperation.EQUALS],
      options: [
        {
          name: 'mastercard',
          label: 'Mastercard',
        },
        {
          name: 'visa',
          label: 'Visa',
        },
        {
          name: 'cup',
          label: 'UnionPay',
        },
        {
          name: 'unionpay_wallet',
          label: 'UnionPay Wallet',
        },
        {
          name: 'alipay',
          label: 'Alipay',
        },
        {
          name: 'wechat',
          label: 'Wechat Pay',
        },
        {
          name: 'fps',
          label: 'FPS',
        },
        {
          name: 'jcb',
          label: 'JCB',
        },
        {
          name: 'discover',
          label: 'Discover',
        },
        {
          name: 'diners',
          label: 'Diners Club',
        },
        {
          name: 'xpay',
          label: 'Xpay',
        },
      ],
    },
  ];

  const handleSearchRecords = (
    records: IObject[],
    search: string,
    filterOptions: IFilterOptions[]
  ) => {
    let transationRecords: IObject[] = [];
    if (!search && filterOptions.length === 0) {
      transationRecords = records;
    } else {
      for (let i = 0; i < records.length; i++) {
        const {
          transaction_id = '',
          order_reference_number = '',
          amount,
          tipsAmount = 0,
          payment_type = '',
          void_at,
          payment_method,
        } = records[i] || {};
        if (
          search &&
          transaction_id.indexOf(search) === -1 &&
          order_reference_number.indexOf(search) === -1
        ) {
          continue;
        }
        if (filterOptions.length > 0) {
          let disappointed = false;
          for (let n = 0; n < filterOptions.length; n++) {
            const filterOption = filterOptions[n];
            if (filterOption.value) {
              if (filterOption.name === 'payment_method') {
                const value = filterOption.value.split(',');
                if (!value.includes(payment_method)) {
                  disappointed = true;
                  break;
                }
              }
              if (filterOption.name === 'payment_type') {
                const value = filterOption.value.split(',');
                const type = void_at === null ? payment_type : 'void';
                if (!value.includes(type)) {
                  disappointed = true;
                  break;
                }
              }
              if (filterOption.name === 'amount') {
                const tranAmount = plus(amount, tipsAmount);
                if (filterOption.operation === FilterOperation.EQUALS) {
                  if (tranAmount !== Number(filterOption.value)) {
                    disappointed = true;
                    break;
                  }
                }
                if (filterOption.operation === FilterOperation.IS_BETWEEN) {
                  const values = filterOption.value.split('&');
                  if (values[0] && tranAmount < Number(values[0])) {
                    disappointed = true;
                    break;
                  }
                  if (values[1] && tranAmount > Number(values[1])) {
                    disappointed = true;
                    break;
                  }
                }
              }
            }
          }
          if (disappointed) {
            continue;
          }
        }

        transationRecords.push(records[i]);
      }
    }
    return transationRecords;
  };

  return (
    <>
      <Transition.Root show={true} appear={true}>
        <div className="px-4 md:px-6 lg:px-8 py-6">
          <div className="flex mb-6 justify-between">
            <div className="flex">
              <div
                onClick={handleBack}
                className="bg-disabled-color shadow-box-drop rounded-full w-10 h-10 flex items-center justify-center cursor-pointer hover:text-primary-color"
              >
                <BackIcon />
              </div>
              <div className="text-3xl leading-10 font-bold text-color-text-1 ml-2.5 invisible sm:visible">
                {t('account_balance:detail_for', { data: dayEndID })}
              </div>
            </div>
            <div className="flex space-x-4">
              <Button
                styleType="Secondary"
                onClick={() => setShowExportModal(true)}
              >
                {t('account_balance:export_statement')}
              </Button>
              {/* <Button
                styleType="Secondary"
                onClick={() => setShowEmailModal(true)}
              >
                {t('account_balance:send_statement_via_email')}
              </Button> */}
            </div>
          </div>
          <SettlementDetailsContent
            dayEndData={dayEndData}
            initLoading={initLoading}
            user={user}
            businessID={businessID}
            dayEndID={dayEndID} />
          <SettlementDetailsRolling
            dayEndData={dayEndData}
            initLoading={initLoading}
            user={user}
            businessID={businessID}
            dayEndID={dayEndID} />
          <Transition.Child
            className="rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 h-fit"
            enter="duration-700 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-10 opacity-0"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="items-center lg:flex lg:justify-between">
              <div>
                <div className="flex flex-col md:flex-row whitespace-nowrap">
                  <span className="font-bold text-xl text-color-text-1 flex items-center md:text-xl">
                    {t('account_balance:transactions')}
                  </span>
                  {!initLoading && (
                    <span
                      className={`px-3 py-1.5 rounded-lg text-color-text-1 text-sm font-medium bg-primary-color bg-opacity-10 ml-4`}
                    >
                      {`${handleTotalAmount(
                        plus(
                          dayEndData.total_sales_amount,
                          dayEndData.total_refund_amount
                        ).toString()
                      )} ${dayEndData.currency}`}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex space-x-4 justify-start mt-4 md:justify-end md:mt-0">
                {/* <Button styleType="Secondary">Export</Button> */}
                <Filter
                  fields={filterFields}
                  onFilter={handleInitdta}
                  searchValue={searchValue}
                  options={options}
                  placeholder={t('account_balance:search_id_ref')}
                />
              </div>
            </div>
            <div className="">
              <div className="w-full mt-6">
                <Table
                  columns={colums}
                  className="max-h-108"
                  records={transationRecords}
                  search={searchValue.length > 0 || options.length > 0}
                  loadingData={initLoading || tableLoading}
                  onRowCell={(record, dataKey) => {
                    return {
                      onClick: (evt) => {
                        evt.stopPropagation();
                        HadleGoPaymentDetails(
                          businessID,
                          record.id,
                          navigate,
                          'settlement',
                          dayEndID
                        );
                      },
                    };
                  }}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
      <SendEmailModal
        hiddenDate
        businessId={businessID}
        title={t('account_balance:send_statement_via_email')}
        subtitle={t('account_balance:tell_us_email')}
        visible={showSendEmailModal}
        onCancel={() => setShowEmailModal(false)}
        batchId={dayEndData.id}
      />
      <SendExportModal
        hiddenDate
        visible={showSendExportModal}
        onCancel={() => setShowExportModal(false)}
        businessId={businessID}
        dayEndData={dayEndData}
      />
    </>
  );
};

export default SettlementDetails;
