import Button from '@/components/common/button';
import Skeleton from '@/components/skeleton';
import { getCookie } from '@/utils/index';
import Config from '@/libs/config';
import { Constants } from '@/constants';
import api from '@/libs/api';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ChangeIcon from '../../global-accounts-icons/change-icon';
import { useEffect, useMemo, useState } from 'react';
import type {
  IPayout,
  IRetrieveBalance,
  IAvailableCurrency,
} from '../../../../types/global-accounts-type';
import SelectedIcon from '../../global-accounts-icons/selected-icon';
import {
  getCountryByCurrency,
  handleMount,
} from '../../../../utils/global-account-untils';
import TipsIcon from '@/components/svg/tips-icon';
import TriangleIcon from '../../global-accounts-icons/triangle-icon';
import dayjs from 'dayjs';
import RateUpdateCountdownModal from '../rate-update-modal';
import AntdModal from '@/components/common/antd/modal';
// import { getRetrieveBalances } from '../../../../data/global-account';
import UnsuccessfulIcon from '@/components/svg/failed-icon';
import { IObject } from '@/types/common-types';
import { Alert } from '@/components/common/alert/alert';

interface IfxConvertProps {
  businessID: string;
  recordId: string;
  availableCurrencies: IAvailableCurrency[];
  retrieveBalances: IRetrieveBalance[];
  payout: IPayout;
  // setRetrieveBalances: (retrieveBalances: IRetrieveBalance[]) => void;
  setPayout: (payout: IPayout) => void;
  setCreateLoading: (createLoading: boolean) => void;
}

interface IFxQuote {
  sell_amount: string;
  buy_amount: string;
  fee_amount: string;
  convert_amount: string;
  fx_quote_id: string;
  exchange_rate: string;
  error_msg: string;
}

let timer: any = null;
let cycleTimer: any = null;

const FxConvert = (props: IfxConvertProps) => {
  const {
    retrieveBalances,
    availableCurrencies,
    setCreateLoading,
    businessID,
    recordId,
    // setRetrieveBalances,
  } = props;
  const { t } = useTranslation(["global_accounts", "account_balance"]);
  const { lang = 'en' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryCurrencyCode = searchParams.get('currency_code');
  const prefix = Config.staticPath;

  const getDefaultCurrency = () => {
    if (queryCurrencyCode) {
      return queryCurrencyCode;
    }
    const retrieveBalance = retrieveBalances[0];
    return retrieveBalance?.currency.currency_code;
  };

  const getDefaultBuyCurrency = () => {
    const currency_code = availableCurrencies[0]?.currency_code;
    return currency_code;
  };
  const [upAmount, setUpAmount] = useState('');
  const [downAmount, setDownAmount] = useState('');
  const [rate, setRate] = useState('0.00');
  const [modalType, setModalType] = useState('1');
  const [sellMsg, setSellMsg] = useState('');
  const [buyMsg, setBuyMsg] = useState('');
  const [type, setType] = useState<'Buy' | 'Sell'>('Buy');
  const [fxQuote, setFxQuote] = useState<IFxQuote | null>(null);
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showRateModal, setRateShowModal] = useState(false);
  const [time, setTime] = useState(30);
  const [disbale, setDisbale] = useState(false);
  const [selectType, setSelectType] = useState<'Buy' | 'Sell'>('Sell');
  const [selectCurrencyCode, setSelectCurrencyCode] = useState<any>(
    getDefaultCurrency()
  );
  const [buyCurrency, setBuyCurrency] = useState<string>(
    getDefaultBuyCurrency()
  );

  useEffect(() => {
    for (let i = 0; i < availableCurrencies.length; i++) {
      for (let k = 0; k < retrieveBalances.length; k++) {
        if (
          availableCurrencies[i].currency_code ===
          retrieveBalances[k].currency.currency_code
        ) {
          Object.defineProperty(availableCurrencies[i], 'available', {
            value: retrieveBalances[k].available,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
      }
    }
    availableCurrencies.sort((a, b) => b.available - a.available);
  }, [availableCurrencies, retrieveBalances]);

  const selectCurrency: IRetrieveBalance = useMemo(() => {
    let retrieveBalance = retrieveBalances[0];
    for (let i = 0; i < retrieveBalances.length; i++) {
      const item = retrieveBalances[i];
      if (item.currency.currency_code === selectCurrencyCode) {
        retrieveBalance = item;
        break;
      }
    }
    return retrieveBalance;
  }, [selectCurrencyCode, retrieveBalances]);

  const selectBuyCurrency = useMemo(() => {
    let currency_code = 'HKD';
    if (selectCurrency && selectCurrency.currency.currency_code && availableCurrencies && availableCurrencies[0].currency_code) {
      if (selectCurrency.currency.currency_code !== availableCurrencies[0].currency_code && !buyCurrency) {
        currency_code = availableCurrencies[0].currency_code;
        setBuyCurrency(currency_code)
      } else if (selectCurrency.currency.currency_code === availableCurrencies[0].currency_code && !buyCurrency) {
        currency_code = availableCurrencies[1].currency_code;
        setBuyCurrency(currency_code)
      }
      for (let i = 0; i < availableCurrencies.length; i++) {
        const item = availableCurrencies[i];
        if (item.currency_code === buyCurrency) {
          currency_code = item.currency_code;
          break;
        }
      }
    }

    return currency_code;
  }, [availableCurrencies, selectCurrency, buyCurrency]);

  const { available, currency } = selectCurrency || {};
  const { currency_code } = currency || {};
  const buyCurrency_code = selectBuyCurrency;


  const handleError = () => {
    const upTotal = Number(upAmount.replace(/[^\d.]/g, ''));
    const balance = Number(available);
    if ((!isNaN(upTotal) && !isNaN(balance) && upTotal > available) || sellMsg.includes('Insufficient') || sellMsg.includes('余额') || sellMsg.includes('餘額')) {
      return true;
    }
  };

  useEffect(() => {
    return () => clearInterval(cycleTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const total = upAmount.replace(/[^\d.]/g, '');
    const amount = Number(total || 0);
    if (amount > 0) {
      setLoading(true);
      handleFxQuote('Sell', amount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCurrencyCode, buyCurrency]);

  const timerStart = (amount: number) => {
    if (currency_code !== buyCurrency_code) {
      const now = dayjs();
      cycleTimer = setInterval(() => handleFxQuoteAgain(now, amount), 1000);
    }
  };

  const handleFxQuoteAgain = (startTime: dayjs.Dayjs, amount: number) => {
    const intervalTime = Math.floor(dayjs().diff(startTime) / 1000);
    const newTime = 30 - intervalTime;
    setTime(newTime);
    if (newTime < 0) {
      setLoading(true);
      handleFxQuote('Buy', amount);
    }
  };

  const handleFxQuote = async (type: 'Buy' | 'Sell', amount: number) => {
    clearInterval(cycleTimer);
    cycleTimer = null;
    setTime(30);
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    try {
      const token = getCookie(Constants.TOKEN) || '';
      const resp: IObject = await api.post(
        `${Config.urls.gatewayUrl}/api/treasury-services/api/fx_quote`,
        {
          buy_amount: type === 'Buy' ? amount : 0,
          buy_currency: buyCurrency_code,
          dealt_side: type,
          sell_amount: type === 'Sell' ? amount : 0,
          sell_currency: currency_code,
        },
        {
          headers: {
            'x-p-business-id': businessID,
            'x-client-id': Config.clientID,
            'x-platform-id': Config.platformId,
            'X-USER-ACCESS-TOKEN': token,
            'x-i18n-lang': lang,
            'Content-Type': 'application/json',
          },
        }
      );
      // const balances = await getRetrieveBalances(businessID, token);
      // if (Array.isArray(balances) && balances.length > 0) {
      //   setRetrieveBalances(balances);
      // }
      const { data, message, code } = resp || {};
      const {
        sell_amount,
        buy_amount,
        error_code,
        sell_error_msg,
        buy_error_msg,
        exchange_rate,
      } = data || {};
      setLoading(false);
      setDisbale(false);
      setFxQuote(data);
      setRate(exchange_rate);
      if (error_code) {
        setSellMsg(sell_error_msg);
        setBuyMsg(buy_error_msg);
        if (type === 'Buy') {
          setUpAmount('');
        } else {
          setDownAmount('');
        }
        clearInterval(cycleTimer);
        cycleTimer = null;
        setTime(30);
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        return;
      }
      if (message && code !== 200) {
        setSellMsg(message);
        return;
      }
      setSellMsg('');
      setBuyMsg('');
      if (type === 'Buy') {
        setUpAmount(handleMount(sell_amount));
      } else {
        setDownAmount(handleMount(buy_amount));
      }
      timerStart(buy_amount);

    } catch (error: any) {
      setLoading(false);
      setDisbale(false);
      setUpAmount('');
      if (error.response.data.code === 'ET500008') {
        setSellMsg(error.response.data.message);
        return
      }
      Alert.error({
        message: t('common:time_out'),
        wrapClass: '-top-10',
      });
    }
  };

  const handleChange = (value: string) => {
    let amount = value;
    let points = 0;
    if (value === '.') {
      return {
        amount: '0.',
        points,
      };
    }
    if (value === '') {
      return {
        amount: '',
        points,
      };
    }
    const index = value.indexOf('.');
    if (
      index !== -1 &&
      value.length - 1 !== index &&
      value[value.length - 1] === '.'
    ) {
      amount = value.substring(0, value.length - 1);
    }
    const values = amount.split('.');
    if (values.length > 1) {
      amount = `${values[0]}.${values[1].slice(0, 2)}`;
      if (values[1].length < 3) {
        points = values[1].length;
      } else {
        points = 2;
      }
    }
    return {
      amount,
      points,
    };
  };

  const handleInputChange = (inputValue: string, type: 'Buy' | 'Sell') => {
    setBuyMsg('');
    setSellMsg('');
    setLoading(true);
    setType(type);
    clearInterval(cycleTimer);
    cycleTimer = null;
    setTime(30);
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    const { amount, points } = handleChange(inputValue);
    let value = amount;
    let total = amount.replace(/[^\d.]/g, '');
    const lastLetter = total[total.length - 1];
    if (lastLetter === '.') {
      total = total.replace('.', '');
    }
    if (!total) {
      value = '';
    }
    if (type === 'Buy') {
      setDownAmount(
        total && lastLetter !== '.' ? handleMount(total, points) : value
      );
      setUpAmount('');
    } else {
      setUpAmount(
        total && lastLetter !== '.' ? handleMount(total, points) : value
      );
      setDownAmount('');
    }

    timer = setTimeout(() => {
      const sum = Number(total || 0);
      if (sum > 0) {
        setDisbale(true);
        handleFxQuote(type, sum);
      } else {
        setLoading(false);
        setFxQuote(null);
      }
      clearTimeout(timer);
      timer = null;
    }, 700);
  };

  const handleNext = async () => {
    if (fxQuote) {
      setCreateLoading(true);
      clearInterval(cycleTimer);
      cycleTimer = null;
      setTime(30);
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      const token = getCookie(Constants.TOKEN) || '';
      const data: any = {
        fx_quote_id: fxQuote.fx_quote_id,
      };
      const resp: IObject = await api.post(
        `${Config.urls.gatewayUrl}/api/treasury-services/api/fx_conversion`,
        data,
        {
          headers: {
            'X-USER-ACCESS-TOKEN': token,
            'x-p-business-id': businessID,
            'Content-Type': 'application/json',
            'x-i18n-lang': lang,
          },
        }
      );
      const { code, message } = resp;

      if (code === 200) {
        navigate(`/${lang}/businesses/${businessID}/global-accounts/${recordId}/fx-results?fx_conversion_id=${resp.data.fx_conversion_id}`);
      } else {
        setErrorCode(code || '500');
        setShow(true);
        setCreateLoading(false);
        setErrorMsg(message || t('payouts:check_network'));
      }
    }
  };

  const handleCurrencyChange = (currency: IRetrieveBalance) => {
    setShowModal(false);
    if (buyCurrency_code === currency.currency.currency_code) {
      setModalType('2');
      setRateShowModal(true);
      return;
    }
    setSelectCurrencyCode(currency.currency.currency_code);
    setSellMsg('');
  };

  const handleBuyChange = (buy_code: string) => {
    setShowModal(false);
    if (currency_code === buy_code) {
      setModalType('2');
      setRateShowModal(true);
      return;
    }
    setBuyCurrency(buy_code);
    setBuyMsg('');
  };

  const buyOrSellChange = (type: 'Buy' | 'Sell') => {
    setSelectType(type);
    setShowModal(true);
  };

  const closeModal = () => {
    setRateShowModal(false);
    setModalType('1');
  };

  const changeBuySell = () => {
    for (let i = 0; i < retrieveBalances.length; i++) {
      if (retrieveBalances[i].currency.currency_code === buyCurrency_code) {
        setBuyCurrency(currency_code);
        setSelectCurrencyCode(buyCurrency_code);
        return;
      }
    }
  };

  return (
    <>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        <div className="flex justify-between">
          <div>
            <div className="font-semibold text-xl text-color-text-1">
              {t('global_accounts:currency_conversion')}
            </div>
          </div>

          <div
            className="bg-primary-color/10 rounded h-10 flex justify-center items-center pl-2.5 pr-3 text-primary-color cursor-pointer"
            onClick={() => setRateShowModal(true)}
          >
            <div className="font-medium mr-1">
              {time}
              {t('global_accounts:s')}
            </div>
            <TipsIcon size={16} />
          </div>
        </div>
        <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
        <div
          className={`${sellMsg ? 'border-error-color' : 'border-primary-color'
            } border-2 border-solid shadow-box-drop rounded-2xl px-6 py-3.5`}
        >
          <div className="flex items-center">
            <div className="flex-1">
              <div className="text-color-text-1 font-medium mb-1">
                {t('global_accounts:you_convert')}
              </div>
              {loading && type === 'Buy' ? (
                <div className={`overflow-hidden inline-block w-60`}>
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-4 rounded-2xl mb-0`}
                  />
                </div>
              ) : (
                <input
                  className={`focus:outline-none font-bold ${sellMsg ? 'text-error-color' : 'text-color-text-1'
                    } text-3xl bg-inherit placeholder:text-color-text-5 placeholder:font-bold placeholder:text-3xl caret-primary-color`}
                  type="text"
                  value={upAmount}
                  inputMode="decimal"
                  placeholder={'0.00'}
                  maxLength={14}
                  disabled={disbale}
                  onChange={(eve) => {
                    handleInputChange(eve.target.value, 'Sell');
                  }}
                />
              )}
            </div>
            <div
              className={`bg-primary-color rounded-lg w-[120px] h-12 flex items-center justify-center cursor-pointer`}
              onClick={() => buyOrSellChange('Sell')}
            >
              <img
                // layout="intrinsic"
                width="24px"
                height="24px"
                src={`${prefix}/images/country-flag/${getCountryByCurrency(
                  currency_code
                )}.svg`}
                alt=""
              />
              <div className="text-white font-bold text-xl mx-1.5">
                {currency_code}
              </div>
              <TriangleIcon className="text-white" />
            </div>
          </div>
          <div className="text-sm text-error-color">{sellMsg}</div>
        </div>
        <div className=" py-8 ml-6 border-l-2 border-0 border-dashed border-primary-color pl-4 relative">
          <div
            className={`absolute bottom-[50px] left-[-19px]  hover:opacity-80 ${loading ? 'pointer-events-none' : 'cursor-pointer'
              }`}
            onClick={() => changeBuySell()}
          >
            <ChangeIcon size={36} />
          </div>
          <div
            className={`text-color-text-1 ${handleError()
              ? 'text-error-color'
              : sellMsg
                ? 'text-color-text-2'
                : ''
              }`}
          >
            {t('global_accounts:available_balance')}: {handleMount(available)}{' '}
            {currency_code}
          </div>
          <div className="border-b border-primary-color border-opacity-10 mt-2"></div>
          <div className="flex items-center justify-between pt-2 font-medium text-color-text-2"></div>
          {buyCurrency_code !== currency_code && (
            <div className="flex items-center justify-between pt-2 font-medium text-color-text-2">
              {loading && (
                <div className={`overflow-hidden inline-block w-60`}>
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-8 rounded-2xl mb-0`}
                  />
                </div>
              )}
              {!loading && (
                <div className="py-1 px-3 bg-primary-color bg-opacity-10 rounded-lg">
                  x{rate}
                </div>
              )}
              <div>{t('global_accounts:exchange_rate')}</div>
            </div>
          )}
        </div>
        <div
          className={` border-solid border-2 shadow-box-drop rounded-2xl px-6 py-3.5 ${buyMsg ? 'border-error-color' : 'border-primary-color'
            }`}
        >
          <div className="flex items-center">
            <div className="flex-1">
              <div className="text-color-text-1 font-medium mb-1">
                {t('global_accounts:your_will_get')}
              </div>
              {loading && type === 'Sell' ? (
                <div className={`overflow-hidden inline-block w-60`}>
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-8 rounded-2xl mb-0`}
                  />
                </div>
              ) : (
                <input
                  className="focus:outline-none font-bold text-color-text-1 text-3xl bg-inherit placeholder:text-color-text-5 placeholder:font-bold placeholder:text-3xl caret-primary-color"
                  type="text"
                  value={downAmount}
                  inputMode="decimal"
                  placeholder={'0.00'}
                  maxLength={14}
                  disabled={disbale}
                  onChange={(eve) => {
                    handleInputChange(eve.target.value, 'Buy');
                  }}
                />
              )}
            </div>
            <div
              className="bg-primary-color rounded-lg w-[120px] h-12 flex items-center justify-center cursor-pointer"
              onClick={() => buyOrSellChange('Buy')}
            >
              <img
                // layout="intrinsic"
                width="24px"
                height="24px"
                src={`${prefix}/images/country-flag/${getCountryByCurrency(
                  buyCurrency_code
                )}.svg`}
                alt=""
              />
              <div className="text-white font-bold text-xl mx-1.5">
                {buyCurrency_code}
              </div>
              <TriangleIcon className="text-white" />
            </div>
          </div>
          <div className="text-sm text-error-color">{buyMsg}</div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <Button
          className="flex-1"
          onClick={handleNext}
          styleType="Primary"
          disabled={loading || sellMsg !== '' || buyMsg !== '' || !downAmount || !upAmount}
          loading={loading}
        >
          {t('common:next')}
        </Button>
      </div>
      <AntdModal
        className="!w-[484px]"
        open={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
      >
        <div className="p-4 pt-8 max-h-[88vh] flex flex-col">
          <div className="font-bold text-xl text-color-text-1 text-center">
            {t('global_accounts:available_accounts')}
          </div>

          {selectType === 'Sell' && (
            <div
              className={`mt-6 text-base leading-snug grid grid-cols-1 divide-y overflow-y-auto justify-start hidden_scrollbar`}
            >
              {retrieveBalances.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="flex py-3 cursor-pointer hover:bg-disabled-color hover:rounded"
                    onClick={() => handleCurrencyChange(item)}
                  >
                    <div>
                      <img
                        // layout="intrinsic"
                        width="40px"
                        height="40px"
                        src={`${prefix}/images/country-flag/${getCountryByCurrency(
                          item.currency.currency_code
                        )}.svg`}
                        alt=""
                      />
                    </div>
                    <div className="flex-1 ml-4">
                      <div className="text-color-text-2 font-medium text-sm">
                        {t('global_accounts:current_account')} -{' '}
                        {item.currency.currency_code}
                      </div>
                      <div
                        className={`mt-1 font-semibold text-lg leading-6 text-color-text-1`}
                      >
                        {handleMount(item.available)}{' '}
                        {item.currency.currency_code}
                      </div>
                    </div>

                    {selectCurrency.id === item.id && (
                      <div className="flex justify-center items-center">
                        <SelectedIcon size={24} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}

          {selectType === 'Buy' && (
            <div
              className={`mt-6 text-base leading-snug grid grid-cols-1 divide-y overflow-y-auto justify-start hidden_scrollbar`}
            >
              {availableCurrencies.map((item) => {
                return (
                  <div
                    key={item.numeric_code}
                    className="flex py-3 cursor-pointer hover:bg-disabled-color hover:rounded"
                    onClick={() => handleBuyChange(item.currency_code)}
                  >
                    <div>
                      <img
                        // layout="intrinsic"
                        width="40px"
                        height="40px"
                        src={`${prefix}/images/country-flag/${getCountryByCurrency(
                          item.currency_code
                        )}.svg`}
                        alt=""
                      />
                    </div>
                    <div className="flex-1 ml-4">
                      <div className="text-color-text-2 font-medium text-sm">
                        {t('global_accounts:current_account')} -{' '}
                        {item.currency_code}
                      </div>
                      <div
                        className={`mt-1 font-semibold text-lg leading-6 text-color-text-1`}
                      >
                        {handleMount(item.available)} {item.currency_code}
                      </div>
                    </div>

                    {buyCurrency_code === item.currency_code && (
                      <div className="flex justify-center items-center">
                        <SelectedIcon size={24} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </AntdModal>
      <AntdModal
        open={show}
        closeIcon={null}
        closable={false}
        onCancel={() => setShow(false)}
      >
        <div className="text-center text-color-text-1 p-8 text-sm font-medium">
          <div className="justify-center flex text-error-color">
            <UnsuccessfulIcon />
          </div>
          <div className="mt-4 font-semibold text-lg leading-6">
            {t('global_accounts:unsuccessful_transfer')}
          </div>
          <div className="mt-2">
            {t('global_accounts:error_code')}: {errorCode}
            <br />
            {t('global_accounts:server_request_failed')} {errorMsg}
          </div>
          <div>{t('global_accounts:payout_again')}</div>
          <Button
            className="w-full mt-6"
            styleType="Border"
            onClick={() => setShow(false)}
          >
            {t('common:back')}
          </Button>
        </div>
      </AntdModal>
      {showRateModal && (
        <RateUpdateCountdownModal
          onCancel={() => closeModal()}
          interval={30}
          modalType={modalType}
        />
      )}
    </>
  );
};

export default FxConvert;
