import React from 'react';
import type { IIconProps } from '@/types/common-types';

const StepTwoIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="62"
        height="94"
        viewBox="0 0 62 94"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.01088 1.20673H38.0853C41.8362 1.20673 44.8894 4.26014 44.8894 8.01088V77.6332C44.8894 81.3839 41.836 84.4373 38.0853 84.4373H8.01088C4.26016 84.4373 1.20673 81.3839 1.20673 77.6332V8.01088C1.20673 4.26016 4.26016 1.20673 8.01088 1.20673Z"
          fill="#535454"
          stroke="black"
          strokeWidth="2.41346"
        />
        <path
          d="M4.43776 77.633V8.01088C4.43776 3.5937 8.03129 0 12.4485 0H8.01088C3.5937 0 0 3.5937 0 8.01088V77.6332C0 82.0504 3.5937 85.6441 8.01088 85.6441H12.4485C8.03129 85.6439 4.43776 82.0502 4.43776 77.633Z"
          fill="#444443"
        />
        <path
          d="M41.6568 77.6323C41.6568 79.6052 40.0573 81.2047 38.0843 81.2047H8.00995C6.03697 81.2047 4.4375 79.6052 4.4375 77.6323V8.01012C4.4375 6.03713 6.03697 4.43767 8.00995 4.43767H11.758C12.4964 4.43767 13.0949 5.03617 13.0949 5.77452V6.62226C13.0949 7.75971 14.0254 8.69025 15.1629 8.69025H30.9312C32.0687 8.69025 32.9992 7.75971 32.9992 6.62226V5.39614C32.9992 4.86672 33.4285 4.4375 33.9579 4.4375H38.0843C40.0573 4.4375 41.6568 6.03697 41.6568 8.00995V77.6323Z"
          fill="#0B1147"
        />
        <g clipPath="url(#clip0_19259_319426)">
          <g clipPath="url(#clip1_19259_319426)">
            <rect
              x="10.9023"
              y="26.1953"
              width="24.4038"
              height="24.4038"
              rx="12.2019"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M29.8917 35.553V34.1852L23.8799 31.7519C23.7213 31.6877 23.544 31.6877 23.3855 31.7519L17.3737 34.1852L17.3737 35.553H29.8917ZM17.1265 33.5745C16.8777 33.6752 16.7148 33.9168 16.7148 34.1852V35.553C16.7148 35.9168 17.0098 36.2118 17.3737 36.2118H29.8917C30.2556 36.2118 30.5505 35.9168 30.5505 35.553V34.1852C30.5505 33.9168 30.3877 33.6752 30.1389 33.5745L24.1271 31.1412C23.81 31.0128 23.4554 31.0128 23.1383 31.1412L17.1265 33.5745Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M29.5636 43.4596H17.7044C17.3752 43.4596 17.1946 43.461 17.0693 43.4779C17.0676 43.4781 17.066 43.4783 17.0645 43.4785C17.0642 43.4801 17.064 43.4817 17.0638 43.4833C17.047 43.6086 17.0456 43.7893 17.0456 44.1185C17.0456 44.4477 17.047 44.6283 17.0638 44.7536C17.064 44.7552 17.0642 44.7568 17.0645 44.7584C17.066 44.7586 17.0676 44.7588 17.0693 44.7591C17.1946 44.7759 17.3752 44.7773 17.7044 44.7773H29.5636C29.8928 44.7773 30.0734 44.7759 30.1987 44.7591C30.2003 44.7588 30.2019 44.7586 30.2035 44.7584C30.2037 44.7568 30.2039 44.7552 30.2042 44.7536C30.221 44.6283 30.2224 44.4477 30.2224 44.1185C30.2224 43.7893 30.221 43.6086 30.2042 43.4833C30.2039 43.4817 30.2037 43.4801 30.2035 43.4785C30.2019 43.4783 30.2003 43.4781 30.1987 43.4779C30.0734 43.461 29.8928 43.4596 29.5636 43.4596ZM30.2679 43.4915C30.2679 43.4915 30.2677 43.4914 30.2673 43.4913L30.2679 43.4915ZM30.1907 43.4147C30.1906 43.4143 30.1905 43.4141 30.1905 43.4141L30.1907 43.4147ZM30.1905 44.8228C30.1905 44.8228 30.1906 44.8226 30.1907 44.8222L30.1905 44.8228ZM17.0775 44.8228C17.0774 44.8228 17.0774 44.8226 17.0772 44.8222L17.0775 44.8228ZM17.0775 43.4141C17.0775 43.4141 17.0774 43.4143 17.0772 43.4147L17.0775 43.4141ZM17.0007 43.4913C17.0003 43.4914 17.0001 43.4915 17.0001 43.4915L17.0007 43.4913ZM16.5797 42.9938C16.3867 43.1867 16.3867 43.4973 16.3867 44.1185C16.3867 44.7396 16.3867 45.0502 16.5797 45.2432C16.7727 45.4361 17.0832 45.4361 17.7044 45.4361H29.5636C30.1847 45.4361 30.4953 45.4361 30.6883 45.2432C30.8812 45.0502 30.8812 44.7396 30.8812 44.1185C30.8812 43.4973 30.8812 43.1867 30.6883 42.9938C30.4953 42.8008 30.1847 42.8008 29.5636 42.8008H17.7044C17.0832 42.8008 16.7727 42.8008 16.5797 42.9938Z"
              fill="black"
            />
            <path
              d="M17.707 35.8828H18.3659V42.8007H17.707V35.8828Z"
              fill="black"
            />
            <path
              d="M22.3164 35.8828H22.9752V42.8007H22.3164V35.8828Z"
              fill="black"
            />
            <path
              d="M27.2539 35.8828H27.9127V42.8007H27.2539V35.8828Z"
              fill="black"
            />
            <path
              d="M19.3477 35.8828H20.0065V42.8007H19.3477V35.8828Z"
              fill="black"
            />
            <path
              d="M24.293 35.8828H24.9518V42.8007H24.293V35.8828Z"
              fill="black"
            />
            <path
              d="M28.9023 35.8828H29.5612V42.8007H28.9023V35.8828Z"
              fill="black"
            />
            <path
              d="M22.9727 33.2467C22.9727 32.8829 23.2676 32.5879 23.6315 32.5879C23.9954 32.5879 24.2903 32.8829 24.2903 33.2467V34.235C24.2903 34.5989 23.9954 34.8938 23.6315 34.8938C23.2676 34.8938 22.9727 34.5989 22.9727 34.235V33.2467Z"
              fill="#0094FF"
            />
          </g>
        </g>
        <path
          d="M8.69946 77.6332V8.01106C8.69946 6.14513 10.1305 4.61541 11.9546 4.45466C11.747 4.42388 11.9678 4.4453 8.00995 4.4386C6.03697 4.4386 4.4375 6.03807 4.4375 8.01106V77.6334C4.4375 79.6063 6.03697 81.2058 8.00995 81.2058H12.2717C10.2989 81.2057 8.69946 79.6064 8.69946 77.6332Z"
          fill="#060A2E"
        />
        <g clipPath="url(#clip2_19259_319426)">
          <path
            d="M59.7681 75.028C59.7681 84.6994 51.9279 92.5396 42.2565 92.5396C32.5851 92.5396 24.7449 84.6994 24.7449 75.028C24.7449 65.3566 32.5851 57.5164 42.2565 57.5164C51.9279 57.5164 59.7681 65.3566 59.7681 75.028Z"
            fill="#FFBE00"
            stroke="black"
            strokeWidth="1.8101"
          />
          <path
            d="M42.2573 56.6113C41.9286 56.6113 41.6019 56.6203 41.2773 56.6373C50.993 57.1468 58.7141 65.1855 58.7141 75.028C58.7141 84.8705 50.993 92.9092 41.2774 93.4186C41.602 93.4356 41.9286 93.4446 42.2574 93.4446C52.4286 93.4446 60.674 85.1992 60.674 75.028C60.674 64.8567 52.4286 56.6113 42.2573 56.6113Z"
            fill="#FF992E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.9333 64.996C42.3571 64.8668 42.8054 65.1056 42.9346 65.5293L45.0405 72.4347C45.1697 72.8585 44.931 73.3068 44.5072 73.436C44.0835 73.5652 43.6352 73.3265 43.506 72.9027L41.965 67.8496L35.0142 80.9C34.806 81.291 34.3201 81.4392 33.9291 81.2309C33.5381 81.0227 33.39 80.5369 33.5982 80.1458L40.549 67.0955L35.4959 68.6365C35.0722 68.7657 34.6239 68.5269 34.4946 68.1032C34.3654 67.6794 34.6042 67.2312 35.0279 67.1019L41.9333 64.996Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.5667 85.3028C42.1429 85.432 41.6946 85.1933 41.5654 84.7695L39.4595 77.8641C39.3303 77.4404 39.569 76.9921 39.9928 76.8628C40.4165 76.7336 40.8648 76.9724 40.994 77.3961L42.535 82.4492L49.4858 69.3988C49.694 69.0078 50.1799 68.8596 50.5709 69.0679C50.9619 69.2762 51.11 69.762 50.9018 70.153L43.951 83.2034L49.0041 81.6624C49.4278 81.5331 49.8761 81.7719 50.0054 82.1956C50.1346 82.6194 49.8958 83.0677 49.4721 83.1969L42.5667 85.3028Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_19259_319426">
            <rect
              width="24.4038"
              height="24.4038"
              fill="white"
              transform="translate(10.8477 26.2109)"
            />
          </clipPath>
          <clipPath id="clip1_19259_319426">
            <rect
              width="24.4038"
              height="24.4038"
              fill="white"
              transform="translate(10.9023 26.1953)"
            />
          </clipPath>
          <clipPath id="clip2_19259_319426">
            <rect
              width="37.945"
              height="37.945"
              fill="white"
              transform="translate(23.2852 56.0547)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default StepTwoIcon;
