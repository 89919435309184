import React from 'react';
import { createRoot } from 'react-dom/client';
import type { IAlertWidgetProps } from './alert-basic';
import AlertWidget from './alert-basic';

const alertMap: Map<string, boolean> = new Map();

function showAlert(params: IAlertWidgetProps) {
  if (!alertMap.get('alert')) {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const root = createRoot(div);
    alertMap.set('alert', true);
    root.render(
      <AlertWidget
        {...params}
        onClose={() => {
          root.unmount();
          document.body.removeChild(div);
          alertMap.clear();
        }}
      />
    );
  }
}

interface IAlertFunctions {
  [key: string]: (opts: IAlertWidgetProps) => void;
}

export const Alert: IAlertFunctions = {
  success: (opts: IAlertWidgetProps) =>
    showAlert({
      ...opts,
      type: 'success',
    }),
  warn: (opts: IAlertWidgetProps) =>
    showAlert({
      ...opts,
      type: 'warn',
    }),
  error: (opts: IAlertWidgetProps) =>
    showAlert({
      ...opts,
      type: 'error',
    }),
};
