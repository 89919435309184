import type { ReactNode } from 'react';

interface IPaginationItemProps {
  children?: ReactNode;
  className?: string;
  actived?: boolean;
  onClick?: (data?: any) => void;
}

const PaginationItem: React.FC<IPaginationItemProps> = (props) => {
  const { children, className, actived = false, onClick } = props;

  return (
    <div
      className={`
        bg-primary-background-color relative hover:bg-gray-50 hover:text-primary-color w-8 h-8 inline-flex items-center justify-center border border-solid text-xs font-medium cursor-pointer
        ${
          actived
            ? 'text-primary-color border-primary-color bg-primary-color/10  z-10'
            : 'border-border-color text-color-text-3'
        }
        ${className}
      `}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
export default PaginationItem;
