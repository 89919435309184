import { useTranslation } from "react-i18next";
import PayeeDetails from "../../components/payee-details";
import { IPayee } from "@/types/payouts-type";
import Button from "@/components/common/button";

interface INewPayeeDetailsProps {
  heade: React.ReactNode;
  payee: IPayee;
  handleCancel: () => void;
  handleNext: () => void;
}

const NewPayeeDetails: React.FC<INewPayeeDetailsProps> = (props) => {
  const { heade, payee, handleNext, handleCancel } = props;
  const { t } = useTranslation('payouts');
  const { t: ct } = useTranslation('common');

  return (
    <div>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        {heade}
        <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
        <div className="text-sm font-medium text-color-text-2 mb-3">
          {t('payee_confirmation')}
        </div>
        <PayeeDetails
          payee={payee}
          nickname={false}
          className="pt-3 rounded bg-disabled-color p-4"
        />
      </div>
      <div className="flex items-center justify-between">
        <Button
          className="flex-1 mr-6"
          onClick={handleCancel}
          styleType="Secondary"
        >
          {ct('back')}
        </Button>
        <Button className="flex-1" styleType="Primary" onClick={handleNext}>
          {ct('next')}
        </Button>
      </div>
    </div>
  );
};

export default NewPayeeDetails;
