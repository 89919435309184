import type { IAccountBalanceMerchant } from '@/types/account-balance-type';
import AccountBalanceCurrentProcess from './account-balance-currently-processing';
import AccountBalancePreviousSettlements from './account-balance-previous-settlements';
import { Transition } from '@headlessui/react';

const AccountBalanceMerchant = (props: IAccountBalanceMerchant) => {
  return (
    <>
      <Transition.Root show={true} appear={true}>
        <div className="mt-6"></div>
        <AccountBalanceCurrentProcess {...props} />
        <AccountBalancePreviousSettlements {...props} />
      </Transition.Root>
    </>
  );
};

export default AccountBalanceMerchant;
