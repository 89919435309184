import { useSearchParams } from 'react-router-dom';
import ForgotPhonePage from '../../components/forgot-phone-page';
import ForgotEmailPage from '../../components/forgot-email-page';

const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const method = searchParams.get('method') || 'phone';
  
  return (
    <>
      {method === 'phone' && <ForgotPhonePage />}
      {method === 'email' && <ForgotEmailPage />}
    </>
  );
};

export default ForgotPassword;
