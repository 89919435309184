import React from 'react';
import type { IIconProps } from '@/types/common-types';

const SelectedIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 20 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://WWW.W3.org/2000/svg"
      >
        <rect
          width="24"
          height="24"
          rx="12"
          fill="currentColor"
          fillOpacity="0.1"
        />
        <g clipPath="url(#clip0_11481_246395)">
          <path
            d="M7.33447 12.0013L10.6678 15.3346L17.3345 8.66797"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_11481_246395">
            <rect
              width="16"
              height="16"
              fill="White"
              transform="translate(4 4)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default SelectedIcon;
