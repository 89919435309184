import { createContext } from 'react';

interface IFormViewContext {
  viewConfig: | undefined;
}

const FormViewContext = createContext<IFormViewContext>({
  viewConfig: undefined,
});

export default FormViewContext;
