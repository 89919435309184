import { Fragment } from 'react';
import { IPaymentGatewayReportsRecord } from '@/types/analytics-type';
import { Transition } from '@headlessui/react';
import SettlementAdvice from './settlement-advice';
import SettlementsOverview from './settlements-overview';
// import SettlementsPaymentMethods from './chart-details/settlements-payment-methods';
// import SettlementsSummaryPaymentMethods from './chart-details/settlements-summary-payment-methods';

interface ISettlementHistoryProps {
  records: IPaymentGatewayReportsRecord[];
  currency: string;
  loading: boolean;
  startDate: string;
  endDate: string;
  businessID: string;
  openExportModal: () => void;
}

const SettlementHistory: React.FC<ISettlementHistoryProps> = (props) => {
  const {
    records,
    currency,
    loading,
    startDate,
    endDate,
    businessID,
    openExportModal,
  } = props;

  const baseProps = {
    loading,
    startDate,
    endDate,
    currency,
    businessID,
    openExportModal,
  };

  return (
    <Transition.Root show={true} as={Fragment} appear={true}>
      <div className="grid grid-cols-1 gap-6 mt-6 md:grid-cols-2">
        <SettlementsOverview {...baseProps} data={records[0]} />

        <SettlementAdvice {...baseProps} data={records[1]} />

        {/* <SettlementsPaymentMethods data={records[7]} currency={currency} />

        <SettlementsSummaryPaymentMethods
          data={records[8]}
          currency={currency}
        /> */}
      </div>
    </Transition.Root>
  );
};

export default SettlementHistory;
