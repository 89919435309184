import React from 'react';
import type { IIconProps } from '@/types/common-types';

const TipsIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 16 } = props;
  return (
    <div
      className={`${className}`}
      onClick={onClick}
    >
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.75 8C1.75 4.54781 4.54781 1.75 8 1.75C11.4522 1.75 14.25 4.54781 14.25 8C14.25 11.4522 11.4522 14.25 8 14.25C4.54781 14.25 1.75 11.4522 1.75 8Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M9.6168 10.6459H8.45195V5.85937C8.45195 5.5873 8.23184 5.36719 7.95977 5.36719H7.46758H6.64727C6.3752 5.36719 6.15508 5.5873 6.15508 5.85937C6.15508 6.13145 6.3752 6.35156 6.64727 6.35156H7.46758V10.6459H6.30273C6.03066 10.6459 5.81055 10.866 5.81055 11.1381C5.81055 11.4102 6.03066 11.6303 6.30273 11.6303H7.46758H8.45195H9.6168C9.88887 11.6303 10.109 11.4102 10.109 11.1381C10.109 10.866 9.88887 10.6459 9.6168 10.6459Z"
          fill="currentColor"
        />
        <path
          d="M7.4668 4.01562C7.4668 4.28745 7.68716 4.50781 7.95898 4.50781C8.23081 4.50781 8.45117 4.28745 8.45117 4.01562C8.45117 3.7438 8.23081 3.52344 7.95898 3.52344C7.68716 3.52344 7.4668 3.7438 7.4668 4.01562H7.4668Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default TipsIcon;
