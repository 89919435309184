import { useForm } from 'react-hook-form';
import { useMemo, useState } from 'react';
import { IAreaCodes, IObject } from '@/types/common-types';
import { INewPayeeFormData, IPayoutMethod } from '@/types/payouts-type';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Alert } from '@/components/common/alert/alert';
import { findNode, getCookie } from '@/utils';
import { Constants } from '@/constants';
import { getBank, verifyBusiness } from '@/data/payouts';
import Label from '@/components/common/label';
import MethodInformationItem from '@/pages/payees/new-page/method-information/method-information-item';
import FormViewContext from '@/components/form-view';
import UpIcon from '@/components/svg/up-icon';
import DownIcon from '@/components/svg/down-icon';
import DownArrowDashedIcon from '../../payouts-icons/down-arrow-dashed-icon';
import RegisteredPayeeIcon from '../../payouts-icons/registered-payee-icon';
import RequestBusinessIDIcon from '../../payouts-icons/request-business-id-icon';
import Button from '@/components/common/button';
import InputBusinessIDIcon from '../../payouts-icons/input-business-id-icon';

interface IMethodInformationProps {
  businessID: string;
  areaCodes: IAreaCodes[];
  heade: React.ReactNode;
  payoutMethod: IPayoutMethod;
  payeeData: INewPayeeFormData;
  setPayeeData: (payeeData: INewPayeeFormData) => void;
  setCurrentStep: (currentStep: number) => void;
}

interface IVerifyData {
  type: string;
  value: string;
  name: string;
  key: 'identifier_1_value' | 'identifier_2_value' | 'identifier_3_value';
  result: {
    isThrough: boolean;
    businessLegalName: string;
    nickname: string;
  };
}

const MethodInformation: React.FC<IMethodInformationProps> = (props) => {
  const {
    areaCodes,
    businessID,
    payoutMethod,
    heade,
    payeeData,
    setCurrentStep,
    setPayeeData,
  } = props;
  const {
    identifier_1_name,
    identifier_1_type,
    identifier_2_name,
    identifier_2_type,
    identifier_3_name,
    identifier_3_type,
    transfer_method,
  } = payoutMethod;

  const { country, bank: initBank } = payeeData;
  let bank = initBank;
  const [business, setBusiness] = useState<IObject | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('payouts');
  const { t: ct } = useTranslation('common');
  const { lang = 'en' } = useParams();
  const useFormReturn = useForm({
    mode: 'all',
    defaultValues: {
      ...payeeData,
    },
  });

  const { getValues, trigger, setError } = useFormReturn;

  const handleNext = async () => {
    if (!(await trigger())) {
      return;
    }
    const values = getValues();

    const { identifier_1_value, identifier_2_value, identifier_3_value } =
      values;
    const identifierValue1 = identifier_1_value.trim();
    const identifierValue2 = identifier_2_value.trim();
    const identifierValue3 = identifier_3_value.trim();
    const result = {
      isThrough: true,
      businessLegalName: '',
      nickname: '',
    };
    if (!business) {
      if (identifier_1_type) {
        if (!identifierValue1) {
          return;
        } else {
          await verifyData({
            type: identifier_1_type,
            value: identifierValue1,
            name: identifier_1_name,
            key: 'identifier_1_value',
            result,
          });
        }
      }
      if (identifier_2_type) {
        if (!identifierValue2) {
          return;
        } else {
          await verifyData({
            type: identifier_2_type,
            value: identifierValue2,
            name: identifier_2_name,
            key: 'identifier_2_value',
            result,
          });
        }
      }
      if (identifier_3_type) {
        if (!identifierValue3) {
          return;
        } else {
          await verifyData({
            type: identifier_3_type,
            value: identifierValue3,
            name: identifier_3_name,
            key: 'identifier_3_value',
            result,
          });
        }
      }

      const { isThrough, businessLegalName, nickname } = result || {};
      if (!isThrough) {
        return;
      }
      setPayeeData({
        ...payeeData,
        bank,
        identifier_1_value: identifierValue1,
        identifier_2_value: identifierValue2,
        identifier_3_value: identifierValue3,
        nickname,
        company_name: businessLegalName || nickname,
      });
    }


    if ((transfer_method !== 'Platform Transfer' || business)) {
      setCurrentStep(3);
    }
  };

  const verifyData = async (params: IVerifyData) => {
    const { type, value, key, result, name } = params;
    const token = getCookie(Constants.TOKEN) || '';
    let resp: any = {};
    if (type === 'SWIFT') {
      resp = await getBank(value, businessID, token, lang);
      const { data } = resp || {};
      if (data && data.id) {
        bank = data;
      } else {
        Alert.error({
          message: t('swift_code_error'),
          position: 'default',
          wrapClass: 'top-px',
        });
        result.isThrough = false;
        return
      }

    }
    if (type === 'Platform Business ID') {
      resp = await verifyBusiness(value, token, lang);
    }
    if (name === 'Payee Full Name' || name === 'Account Holder Name') {
      result.nickname = value;
    }
    const { code, message, data } = resp || {};
    const { business_legal_name, p_business_id } = data || {};
    if (business_legal_name) {
      result.businessLegalName = business_legal_name;
    }
    if (p_business_id) {
      setBusiness(data);
    }
    if (code !== 200 && code) {
      setError(key, {
        type: 'manual',
        message: `${code} - ${message}`,
      });
      Alert.error({
        message: `${code} - ${message}`,
        position: 'default',
        wrapClass: 'top-px',
      });
      result.isThrough = false;
    }
  };

  const handleBack = () => {
    if (business) {
      setBusiness(undefined);
      return;
    }
    setPayeeData({
      ...payeeData,
      identifier_1_value: '',
      identifier_2_value: '',
      identifier_3_value: '',
      company_name: '',
      bank: {
        id: '',
        bank_code: '',
        bank_name: '',
        country: '',
        swift_code: '',
      },
    });

    setCurrentStep(1);
  };

  const businessName = useMemo(() => {
    const { business_name, translations } = business || {};
    let businessName = business_name || '';
    if (Array.isArray(translations)) {
      for (let i = 0; i < translations.length; i++) {
        const item = translations[i];
        if (lang === item.languages_code) {
          businessName = item.business_name;
          break;
        }
      }
    }
    return businessName;
  }, [business, lang]);

  const countryName = useMemo(() => {
    let countryName = '';
    const areaCode = findNode<IAreaCodes>(areaCodes, 'isoAlpha2Code', country);
    if (areaCode) {
      const { name, translations } = areaCode;
      countryName = name;
      if (Array.isArray(translations)) {
        for (let i = 0; i < translations.length; i++) {
          const item = translations[i];
          if (lang === item.languages_code) {
            countryName = item.country_name;
            break;
          }
        }
      }
    }

    return countryName;
    // eslint-disable-next-line
  }, [business, lang]);

  return (
    <div>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        {heade}
        <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />

        {!business && (
          <>
            {(transfer_method === 'RTGS' ||
              transfer_method === 'SWIFT' ||
              transfer_method.indexOf('Alipay') !== -1) && (
                <>
                  <Label
                    className="mb-0 mt-4 md:mt-6 md:mb-1.5"
                    value={t('country')}
                    required={true}
                  />
                  <div className="h-10 px-3 bg-disabled-color border-border-color border rounded flex items-center font-medium text-color-text-1">
                    {countryName}
                  </div>
                </>
              )}
            <form>
              <FormViewContext.Provider value={{ viewConfig: undefined }}>
                {identifier_1_name && identifier_1_type && (
                  <MethodInformationItem
                    name={'identifier_1_value'}
                    identifierName={identifier_1_name}
                    type={identifier_1_type}
                    option={transfer_method}
                    payoutMethod={payoutMethod}
                    payeeData={payeeData}
                    areaCodes={areaCodes}
                    businessID={businessID}
                    useFormReturn={useFormReturn}
                    setPayeeData={setPayeeData}
                  />
                )}
                {identifier_2_name && identifier_2_type && (
                  <MethodInformationItem
                    name={'identifier_2_value'}
                    identifierName={identifier_2_name}
                    type={identifier_2_type}
                    option={transfer_method}
                    payoutMethod={payoutMethod}
                    payeeData={payeeData}
                    areaCodes={areaCodes}
                    businessID={businessID}
                    useFormReturn={useFormReturn}
                    setPayeeData={setPayeeData}
                  />
                )}
                {identifier_3_name && identifier_3_type && (
                  <MethodInformationItem
                    name={'identifier_3_value'}
                    identifierName={identifier_3_name}
                    type={identifier_3_type}
                    option={transfer_method}
                    payoutMethod={payoutMethod}
                    payeeData={payeeData}
                    areaCodes={areaCodes}
                    businessID={businessID}
                    useFormReturn={useFormReturn}
                    setPayeeData={setPayeeData}
                  />
                )}
              </FormViewContext.Provider>
            </form>
          </>
        )}
        {transfer_method === 'Platform Transfer' && !business && (
          <>
            <div
              className="flex items-center mt-6 cursor-pointer"
              onClick={() => setOpen(!open)}
            >
              <div className="text-color-text-2 text-sm font-medium mr-1">
                {t('what_is_business_id')}
              </div>
              {open && <UpIcon className="text-icon-color" size={20} />}
              {!open && <DownIcon className="text-icon-color" size={20} />}
            </div>
            <div
              className={`font-medium transition-all ease-in duration-300 overflow-hidden ${open ? 'max-h-102' : 'max-h-0 overflow-hidden'
                }`}
            >
              <div className="flex my-6">
                <div className="flex justify-center flex-col items-center mr-7">
                  <div className="w-7 h-7 bg-primary-color flex items-center justify-center text-white rounded-full mb-6">
                    1
                  </div>
                  <DownArrowDashedIcon />
                </div>
                <div className="font-medium">
                  <RegisteredPayeeIcon />
                  <div className="text-color-text-1 mt-5.5 mb-2">
                    {t('registered_payee')}
                  </div>
                  <div className="text-color-text-2 whitespace-pre-line text-sm">
                    {t('registered_payee_info')}
                  </div>
                </div>
              </div>
              <div className="flex mb-6">
                <div className="flex justify-center flex-col items-center mr-7">
                  <div className="w-7 h-7 bg-primary-color flex items-center justify-center text-white rounded-full mb-6">
                    2
                  </div>
                  <DownArrowDashedIcon />
                </div>
                <div className="font-medium">
                  <RequestBusinessIDIcon />
                  <div className="text-color-text-1 mt-5.5 mb-2">
                    {t('request_business_id')}
                  </div>
                  <div className="text-color-text-2 whitespace-pre-line text-sm">
                    {t('request_business_id_info')}
                  </div>
                </div>
              </div>
              <div className="flex mb-6">
                <div className="mr-7">
                  <div className="w-7 h-7 bg-primary-color flex items-center justify-center text-white rounded-full mb-6">
                    3
                  </div>
                </div>
                <div className="font-medium">
                  <InputBusinessIDIcon />
                  <div className="text-color-text-1 mt-5.5 mb-2">
                    {t('input_business_id')}
                  </div>
                  <div className="text-color-text-2 whitespace-pre-line text-sm">
                    {t('input_business_id_info')}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {business && (
          <div className="font-medium text-color-text-1">
            <Label
              className="mb-0 mt-4 md:mt-6 md:mb-1.5"
              value={t('country')}
            />
            <div className="h-10 px-3 bg-disabled-color border-border-color border rounded flex items-center">
              {countryName}
            </div>
            <Label
              className="mb-0 mt-4 md:mt-6 md:mb-1.5"
              value={t('wonder_account_id')}
            />
            <div className="h-10 px-3 bg-disabled-color border-border-color border rounded flex items-center">
              {business.p_business_id}
            </div>
            <Label
              className="mb-0 mt-4 md:mt-6 md:mb-1.5"
              value={t('business_legal_name')}
            />
            <div className="h-10 px-3 bg-disabled-color border-border-color border rounded flex items-center">
              {business.business_legal_name}
            </div>
            <Label
              className="mb-0 mt-4 md:mt-6 md:mb-1.5"
              value={t('business_account_name')}
            />
            <div className="h-10 px-3 bg-disabled-color border-border-color border rounded flex items-center">
              {businessName}
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center justify-between">
        <Button
          className="flex-1 mr-6"
          onClick={handleBack}
          styleType="Secondary"
        >
          {ct('back')}
        </Button>
        <Button className="flex-1" onClick={handleNext} styleType="Primary">
          {ct('next')}
        </Button>
      </div>
    </div>
  );
};

export default MethodInformation;
