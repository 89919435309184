import React from 'react';
import type { IIconProps } from '@/types/common-types';

const AlipayDetailsIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clipPath="url(#clip0_15429_680454)">
          <path
            d="M20.1567 0H3.84499C1.72072 0 0 1.72199 0 3.84414V20.1559C0 22.278 1.72072 24 3.84499 24H20.1567C22.281 24 23.9994 22.278 23.9994 20.1559V3.84414C24.0017 1.71975 22.281 0 20.1567 0Z"
            fill="#00ADEE"
          />
          <path
            d="M19.7601 15.1957C18.8012 14.8715 17.5112 14.375 16.0761 13.8513C16.9376 12.3392 17.6268 10.6185 18.0802 8.74812H13.3488V7.02966H19.1435V6.07068H13.3488V3.20508H10.9842C10.5694 3.20508 10.5694 3.61769 10.5694 3.61769V6.06841H4.70893V7.0274H10.5694V8.74585H5.72912V9.70483H15.1149C14.7725 10.8973 14.3101 12.0195 13.7637 13.0375C10.719 12.0241 7.46798 11.2034 5.4276 11.7089C4.12175 12.0331 3.28066 12.6112 2.78644 13.2188C0.519346 16.0005 2.14485 20.2264 6.93294 20.2264C9.76454 20.2264 12.4919 18.6349 14.6048 16.0119C17.7583 17.5399 23.9996 20.1652 23.9996 20.1652V16.4245C24.0019 16.4245 23.2175 16.361 19.7601 15.1957ZM6.48633 18.61C2.75243 18.61 1.64836 15.6446 3.49377 14.0214C4.11042 13.4727 5.23489 13.2052 5.83341 13.1463C8.05062 12.9264 10.1046 13.7788 12.5281 14.9713C10.8255 17.2112 8.65593 18.61 6.48633 18.61Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_15429_680454">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default AlipayDetailsIcon;
