import React from 'react';
import type { IIconProps } from '@/types/common-types';

const TriangleIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="fill-current"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.86131 0H1.13869C0.686509 0 0.439492 0.52739 0.72897 0.874764L4.59028 5.50834C4.80351 5.76421 5.19649 5.76421 5.40972 5.50834L9.27103 0.874765C9.56051 0.527391 9.31349 0 8.86131 0Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default TriangleIcon;
