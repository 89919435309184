import React from 'react';
import type { IIconProps } from '@/types/common-types';

const SiderBarIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 24 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2"
          y="3.5"
          width="20"
          height="2.5"
          rx="1.25"
          fill="currentColor"
        />
        <rect
          x="2"
          y="11.25"
          width="13"
          height="2.5"
          rx="1.25"
          fill="currentColor"
        />
        <path
          d="M18.5312 10.1358C18.5312 9.77499 18.9674 9.59431 19.2225 9.84943L21.3947 12.0216C21.6583 12.2852 21.6583 12.7126 21.3947 12.9761L19.2225 15.1483C18.9674 15.4035 18.5312 15.2228 18.5312 14.862L18.5312 10.1358Z"
          fill="currentColor"
        />
        <rect
          x="2"
          y="19"
          width="20"
          height="2.5"
          rx="1.25"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default SiderBarIcon;
