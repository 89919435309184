import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import langs from '@/i18n/langs';

export const defaultLng = 'en';
export const defaultNS = 'common';

export const supportedLngs = ['en', 'zh-CN', 'zh-HK'];

const getPathLang = () => {
  let pathname = window.location.pathname;
  const pathnameSplit = pathname.split('/');

  let lang = '';
  if (pathnameSplit.length > 1 && pathnameSplit[1]) {
    lang = pathnameSplit[1];
    lang = lang.replaceAll('_', '-');
  }

  if (!lang || !langs.includes(lang.toLocaleLowerCase())) {
    lang = defaultLng;
  }

  for (let i = 0; i < supportedLngs.length; i++) {
    const tempLng = supportedLngs[i];
    if (lang.toLocaleLowerCase() === tempLng.toLocaleLowerCase()) {
      lang = tempLng;
      break;
    }
  }

  return lang;
};

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: defaultLng,
    fallbackLng: defaultLng,
    ns: defaultNS,
    defaultNS: defaultNS,
    supportedLngs: supportedLngs,
    backend: {
      //網頁載入時去下載語言檔的位置
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      // 是否要讓字詞 escaped 來防止 xss 攻擊，這裡因為 React.js 已經做了，就設成 false即可
      escapeValue: false,
    },
  });

const pathLang = getPathLang();

if (pathLang !== defaultLng) {
  i18n.changeLanguage(pathLang);
}

export default i18n;
