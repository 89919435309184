import { useTranslation } from 'react-i18next';
import React, { useState, useRef, useEffect } from 'react';
import type { ICreateViewParams } from '.';

type IImageViewProps = ICreateViewParams;

const ImageView: React.FC<IImageViewProps> = (props) => {
  const {
    data,
    splashData,
    selectedIndex,
    handleIndexChange,
    handleImageChange,
  } = props;
  const { t } = useTranslation('signin');
  const handleClick = (index: number) => {
    handleIndexChange(index);
  };
  const handleImageClick = (index: number) => {
    handleImageChange(index, xCoordinate);
  };
  const [xCoordinate, setxCoordinate] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const mousePosition = (event: { clientX: number; clientY: number }) => {
    const rect = ref.current?.getBoundingClientRect();
    if (rect) {
      const x = event.clientX - rect.x;
      setxCoordinate(x);
    }
  };

  useEffect(() => {
    const currentRef = ref.current;
    
    if (currentRef) {
      currentRef.addEventListener('click', mousePosition);
    }
    
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('click', mousePosition);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex p-20  w-full flex-col items-center h-full">
      <div className="w-full flex flex-col items-center overflow-hidden text-color-text-1">
        {data.title && (
          <div className="mt-3 text-center font-bold text-4xl">
            {t(`signin:${data.title}`)}
          </div>
        )}
        {data.subTitle && (
          <div className="mt-3 text-center font-normal text-xl">
            {t(`signin:${data.subTitle}`)}
          </div>
        )}
      </div>
      <div className="w-full flex justify-center my-4">
        {splashData.length > 1 &&
          splashData.map((item, index) => (
            <span
              className={`w-2 h-2 bg-primary-color rounded-full mr-2 cursor-pointer ${
                Math.abs(selectedIndex) === index
                  ? 'w-8 rounded-lg opacity-100'
                  : 'opacity-10'
              }`}
              key={item.id}
              onClick={() => handleClick(index)}
            />
          ))}
      </div>
      {data.imageUrl && (
        <div
          className="flex-1 w-full px-6"
          ref={ref}
          onClick={() => handleImageClick(selectedIndex)}
        >
          <div
            className="w-full h-full bg-center bg-no-repeat bg-contain"
            style={{ backgroundImage: `url(${data.imageUrl})` }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageView;
