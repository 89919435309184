import Input from '@/components/common/antd/input/input';
import Label from '@/components/common/label';
import PhoneNumber from '@/components/common/phone-number';
import Select from '@/components/common/select';
import { IAreaCodes } from '@/types/common-types';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import AntdModal from '@/components/common/antd/modal';
import Button from '@/components/common/button';
import { useEffect, useState } from 'react';
import type { IObject } from '@/types/common-types';
import { createAddressRecord } from '../../../data/terminal';
import type { IAddressObject } from '../../../types/terminal-type';
import AutocompleteInput from '../components/autocomplete-input';
import { Alert } from '@/components/common/alert/alert';

interface IAddAddressModalProps {
  visible: boolean;
  title: string;
  areaCodes: IAreaCodes[];
  businessID: string;
  token: string;
  customerID: string;
  loading: boolean;
  districtData: any;
  onCancel: () => void;
  handleDete: () => void;
  loadAddress: () => Promise<IObject | undefined>;
  handleUpdateRecord: (record: IAddressObject) => void;
  record?: IAddressObject;
}

const AddressDetailsModal: React.FC<IAddAddressModalProps> = (props) => {
  const {
    visible,
    areaCodes,
    onCancel,
    title,
    record,
    businessID,
    token,
    customerID,
    districtData,
    loading: deleteLoading,
    loadAddress,
    handleDete,
    handleUpdateRecord,
  } = props;
  const { t } = useTranslation(["extended_service","transaction_advice"]);

  const [loading, setLoading] = useState<boolean>(false);
  const [addressRecord, setAddressRecord] = useState<IObject | undefined>(
    undefined
  );
  const { handleSubmit, control, getValues, reset } = useForm({
    mode: 'all',
  });

  const isEdit = record && record.id;

  useEffect(() => {
    if (isEdit) {
      reset({ ...record });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const handleSave = async () => {
    if (loading) return;
    setLoading(true);
    let currentRecord: any = getValues();

    if (addressRecord) {
      currentRecord = {
        ...currentRecord,
        lat: addressRecord.lat,
        lng: addressRecord.lng,
      };
    }

    if (isEdit) {
      handleUpdateRecord({
        ...record,
        ...currentRecord,
      });
      await loadAddress();
    } else {
      const neRecord = {
        ...currentRecord,
        country: '334',
        province: 'Hong Kong',
        customer: customerID,
        city: 'Hong Kong',
      };
      const data = await createAddressRecord(businessID, token, neRecord);
      if (data && data.id) {
        Alert.success({
          message: t('extended_service:create_address_success'),
          wrapClass: '-top-10',
        });
        await loadAddress();
      } else {
        Alert.error({
          message: data.error,
          wrapClass: '-top-10',
        });
      }

      onCancel();
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setAddressRecord(undefined);
    onCancel();
  };

  return (
    <AntdModal className="h-full" open={visible} onCancel={handleCancel}>
      <div className="p-6 h-full flex flex-col">
        <div className="text-center text-xl font-bold mb-6">{title}</div>
        <form
          className="flex-1 w-full flex flex-col"
          onSubmit={handleSubmit(handleSave)}
        >
          <Label required value={t('extended_service:receiver_name')} />
          <Controller
            name={'name'}
            control={control}
            rules={{
              required: t('extended_service:field_is_required'),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                onChange={(data) => {
                  onChange(data);
                }}
                value={value}
                error={error}
                placeholder={t('extended_service:enter_receiver_name')}
              />
            )}
          />
          <Label
            className="mb-0 mt-4 md:mt-6 md:mb-1.5"
            value={t('common:phone_number')}
            required
          />
          <Controller
            name={'phone'}
            control={control}
            rules={{
              validate: (value) => {
                const msg = t('extended_service:field_is_required');
                if (!value) return msg;
                if (value && typeof value === 'string') {
                  const newValue = value.substring(value.indexOf(' ') + 1);
                  const code = value.substring(0, value.indexOf(' '));
                  return newValue && code ? undefined : msg;
                }
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <PhoneNumber
                value={value}
                showSearch={true}
                options={areaCodes}
                error={error}
                placeholder={t('extended_service:enter_phone_number')}
                name={'phone'}
                onChange={(data) => {
                  onChange(data);
                }}
              />
            )}
          />
          <Label
            className="mb-0 mt-4 md:mt-6 md:mb-1.5"
            required
            value={t('extended_service:address')}
          />
          <Controller
            name={'address'}
            control={control}
            rules={{
              required: t('extended_service:field_is_required'),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const handleChange = (record: IObject) => {
                onChange(record.value || '');
                setAddressRecord(record);
              };
              return (
                <AutocompleteInput
                  placeholder={t('extended_service:enter_address')}
                  businessID={businessID}
                  token={token}
                  value={value}
                  error={error}
                  onChange={handleChange}
                />
              );
            }}
          />
          <Label
            className="mb-0 mt-4 md:mt-6 md:mb-1.5"
            required
            value={t('extended_service:address_details')}
          />
          <Controller
            name={'street'}
            control={control}
            rules={{
              required: t('extended_service:field_is_required'),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                onChange={(data) => {
                  onChange(data);
                }}
                value={value}
                error={error}
                placeholder={t('extended_service:enter_address_details')}
              />
            )}
          />
          <Label
            className="mb-0 mt-4 md:mt-6 md:mb-1.5"
            required
            value={t('extended_service:district')}
          />
          <Controller
            name={'district'}
            control={control}
            rules={{
              required: t('extended_service:field_is_required'),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                placeholder={t('extended_service:select_district')}
                options={districtData}
                dataKey={'value'}
                valueKey={'label'}
                error={error}
                value={value}
                onChange={(data: any) => {
                  onChange(data);
                }}
              />
            )}
          />
          <Label
            className="mb-0 mt-4 md:mt-6 md:mb-1.5"
            required
            value={t('extended_service:country')}
          />
          <div className="flex h-10 px-3 py-2.5 bg-disabled-color rounded shadow-box-drop border border-border-color">
            <span className="flex items-center relative w-5 mr-2">
              <img
                // layout="intrinsic"
                width="20px"
                height="20px"
                src={`/images/country-flag/hk.svg`}
                alt=""
              />
            </span>
            <span className="font-medium text-sm">
              {t('extended_service:hong_kong')}
            </span>
          </div>

          <div className="flex-1 w-full flex flex-col justify-end">
            <div className={`flex ${isEdit ? 'grid grid-cols-2 gap-6' : ''}`}>
              {isEdit && (
                <Button
                  onClick={handleDete}
                  className="w-full"
                  type="button"
                  loading={deleteLoading}
                  styleType="Secondary Danger"
                >
                  {t('common:remove')}
                </Button>
              )}
              <Button className="w-full" loading={loading} type="submit">
                {t('common:save')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </AntdModal>
  );
};

export default AddressDetailsModal;
