import type { IDefultCategoryProps } from '../../type';
import { Transition } from '@headlessui/react';
import Label from '@/components/common/label';
import DetailsItem from '@/pages/account-balance/components/details-item-component';
import {
  getPayoutTranferStatusLabel,
  handleSymbolMount,
  handleUtcDate,
} from '@/utils/account-balance-untils';
import Skeleton from '@/components/skeleton';
import CopyIcon from '@/components/svg/copy-icon';
import { useEffect, useState } from 'react';
import { TransferMethodType } from '@/types/account-balance-type';
import { useTranslation } from 'react-i18next';

let tipsTimeout: any;
let refTipsTimeout: any;

const DefaultCategory = (props: IDefultCategoryProps) => {
  const { record, initLoading, disabled = true } = props;
  const { t } = useTranslation("account_balance");

  const {
    id = '',
    total_amount,
    calculated_fee = '',
    transfer_method: transferMethod = '',
    currency: currencyCode = 'HKD',
    transfer_status: status = '',
    payout_category_name: payoutCategoryName = '',
    transfer_type: transferType = '',
    p_created_at: createdAt = '',
    estimated_settled_at: estimeatedSettledAt = '',
    destination_amount: destinationAmount = '',
    destination_currency: destinationCurrency = 'HKD',
    vendor_reference_id: vendorReferenceId = '',
    error_code: errorCode = '',
    error_message: errorMessage = '',
    settled_at = '',
    // ref_id,
    remark,
    fx_exchange_rate: fxExchangeRate,
    transfer_purpose: transferPurpose,
  } = record || {};

  const [copySuccess, setCopySuccess] = useState(false);
  const [copyRefSuccess, setCopyRefSuccess] = useState(false);

  useEffect(() => {
    if (copySuccess) {
      clearTimeout(tipsTimeout);
      tipsTimeout = setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }
  }, [copySuccess]);

  useEffect(() => {
    if (copyRefSuccess) {
      clearTimeout(refTipsTimeout);
      refTipsTimeout = setTimeout(() => {
        setCopyRefSuccess(false);
      }, 3000);
    }
  }, [copyRefSuccess]);

  const handleCopy = () => {
    navigator.clipboard.writeText(id).then(() => {
      setCopySuccess(true);
    });
  };

  // const handleCopyRef = () => {
  //   navigator.clipboard.writeText(ref_id).then(() => {
  //     setCopyRefSuccess(true);
  //   });
  // };

  const getSettledDate = () => {
    let date = handleUtcDate(settled_at, 'MMM D, YYYY');
    if (status === 'Initiated' || !settled_at) {
      date = handleUtcDate(estimeatedSettledAt, 'MMM D, YYYY');
    }
    return date;
  };

  const isFPS = transferMethod === TransferMethodType.FPSBankAccount;

  const showTime = [
    'settled',
    'initiated',
    'processing transfer',
    'refunded',
  ].includes(status.toLowerCase());

  const isMultiCurrency = destinationCurrency !== currencyCode;

  return (
    <div className="flex w-full space-x-6 mt-6">
      <Transition.Child
        className="flex-1"
        enter="duration-350 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-8 opacity-60"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div
          className={`w-full rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit`}
        >
          <div className="font-bold text-xl text-color-text-1 flex items-center">
            {t('account_balance:payout')}
          </div>
          <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
              <DetailsItem
                title={t('account_balance:transaction_amount')}
                content={`${handleSymbolMount(
                  destinationAmount,
                  false,
                  '-'
                )} ${destinationCurrency}`}
                loading={initLoading}
              />
              <DetailsItem
                title={t('account_balance:status')}
                content={getPayoutTranferStatusLabel(record).component}
                loading={initLoading}
              />
              <div className="w-full col-auto md:col-span-2">
                <Label disabled={disabled} value={'ID'}></Label>
                {initLoading && (
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-4 rounded-2xl mb-0`}
                  />
                )}
                {!initLoading && (
                  <div className="py-2 text-color-text-1 font-medium pr-6 flex items-center text-sm">
                    <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-color-text-1 text-sm font-medium">
                      {id}
                    </span>
                    <span className="cursor-pointer group" onClick={handleCopy}>
                      <CopyIcon className="text-primary-color" />
                    </span>
                    <span
                      className={`text-primary-color text-xs cursor-pointer ${
                        copySuccess ? 'visible' : 'invisible'
                      }`}
                    >
                      {t('account_balance:copied')}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
              <DetailsItem
                title={t('account_balance:fees_charged')}
                content={`${handleSymbolMount(
                  calculated_fee,
                  false,
                  '-'
                )} ${currencyCode}`}
                loading={initLoading}
              />
              <DetailsItem
                title={t('account_balance:type')}
                content={transferType}
                loading={initLoading}
              />
              <DetailsItem
                title={t('account_balance:purpose_of_transfer')}
                loading={initLoading}
                content={transferPurpose}
              />
              <DetailsItem
                title={t('account_balance:category')}
                loading={initLoading}
                content={payoutCategoryName}
              />
              {/* <div className="w-full col-auto md:col-span-2">
                <Label
                  disabled={disabled}
                  value={t('account_balance:reference_id')}
                ></Label>
                {initLoading && (
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-4 rounded-2xl mb-0`}
                  />
                )}
                {!initLoading && (
                  <div className="py-2 text-color-text-1 font-medium pr-6 flex items-center space-x-2">
                    <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-color-text-1 font-medium">
                      {ref_id || '--'}
                    </span>
                    {ref_id && (
                      <span
                        className="cursor-pointer group"
                        onClick={handleCopyRef}
                      >
                        <CopyIcon className="text-primary-color" />
                      </span>
                    )}
                    <span
                      className={`text-primary-color text-xs cursor-pointer ${copyRefSuccess ? 'visible' : 'invisible'
                        }`}
                    >
                      {t('account_balance:copied')}
                    </span>
                  </div>
                )}
              </div> */}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
              {isMultiCurrency && (
                <DetailsItem
                  title={t('account_balance:exchange_rate')}
                  content={`${fxExchangeRate} ${currencyCode} to ${destinationCurrency}`}
                  loading={initLoading}
                />
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
              <DetailsItem
                title={t('account_balance:billing_amount')}
                content={`${handleSymbolMount(
                  total_amount,
                  false,
                  '-'
                )} ${currencyCode}`}
                loading={initLoading}
              />
              <DetailsItem
                title={t('account_balance:created_on')}
                content={handleUtcDate(createdAt)}
                loading={initLoading}
              />
              {showTime && (
                <DetailsItem
                  title={
                    getPayoutTranferStatusLabel(record).status === 'settled'
                      ? t('account_balance:settled_on')
                      : t('account_balance:est_settled_on')
                  }
                  content={getSettledDate()}
                  loading={initLoading}
                />
              )}
              {!showTime && (
                <DetailsItem
                  title={t('account_balance:reason_code')}
                  content={
                    <>
                      {errorCode && errorMessage ? (
                        <>
                          <div className="text-ellipsis overflow-hidden text-[#FC2E01]">
                            {errorCode}
                          </div>
                          <div className="text-ellipsis overflow-hidden">
                            {errorMessage}
                          </div>
                        </>
                      ) : (
                        '--'
                      )}
                    </>
                  }
                  loading={initLoading}
                />
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
              {isFPS && (
                <DetailsItem
                  title={t('account_balance:fps_reference_id')}
                  content={vendorReferenceId}
                  loading={initLoading}
                />
              )}
              <div className={`${isFPS ? 'col-span-2' : 'col-span-3'}`}>
                <DetailsItem
                  title={t('account_balance:remark_reference')}
                  content={remark}
                  loading={initLoading}
                />
              </div>
            </div>
          </>
        </div>
      </Transition.Child>
    </div>
  );
};
export default DefaultCategory;
