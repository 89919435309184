import React from 'react';
import type { IIconProps } from '@/types/common-types';

const VisaIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 36 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18" cy="18" r="18" fill="#F8FAFC" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3142 22.25H9.1778L7.57579 16.112C7.49976 15.8296 7.33831 15.58 7.10082 15.4624C6.50814 15.1667 5.85504 14.9314 5.14258 14.8128V14.5765H8.58408C9.05906 14.5765 9.41529 14.9314 9.47466 15.3437L10.3059 19.7713L12.4412 14.5765H14.5182L11.3142 22.25ZM15.7048 22.25H13.6872L15.3486 14.5765H17.3662L15.7048 22.25ZM19.9773 16.7012C20.0367 16.2879 20.3929 16.0516 20.8085 16.0516C21.4616 15.9923 22.1731 16.111 22.7668 16.4056L23.123 14.7534C22.5293 14.5171 21.8762 14.3984 21.2835 14.3984C19.3253 14.3984 17.9004 15.4613 17.9004 16.9365C17.9004 18.0588 18.9097 18.648 19.6221 19.003C20.3929 19.357 20.6898 19.5933 20.6304 19.9472C20.6304 20.4782 20.0367 20.7145 19.444 20.7145C18.7316 20.7145 18.0191 20.5375 17.367 20.2419L17.0108 21.895C17.7233 22.1897 18.4941 22.3083 19.2065 22.3083C21.4023 22.3666 22.7668 21.3048 22.7668 19.7109C22.7668 17.7038 19.9773 17.5861 19.9773 16.7012V16.7012ZM29.8277 22.25L28.2257 14.5765H26.505C26.1487 14.5765 25.7925 14.8128 25.6738 15.1667L22.7072 22.25H24.7842L25.1988 21.1288H27.7507L27.9882 22.25H29.8277ZM26.8032 16.6438L27.3959 19.5359H25.7345L26.8032 16.6438Z"
          fill="#0032BE"
        />
      </svg>
    </div>
  );
};

export default VisaIcon;
