import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import CloseIcon from '@/components/svg/close-icon';

export interface IImagePreviewProps {
  url: string;
  show: boolean;
  setShowModal: (show: boolean) => void;
}

const ImagePreview: React.FC<IImagePreviewProps> = (props) => {
  const { url, setShowModal, show } = props;

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-100"
          onClose={() => setShowModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-select-secondary-background-color/60 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 h-4/5 m-auto w-7/12 bg-disabled-color rounded-lg">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 overflow-y-auto overflow-hidden h-full rounded-lg">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative rounded overflow-hidden transform transition-all max-w-max">
                  <div className="flex gap-x-4 items-start max-w-max">
                    <div className="bg-disabled-color shadow-xl rounded relative">
                      <img
                        src={url}
                        className="flex-1 rounded px-4"
                        loading="lazy"
                        width={'100%'}
                        height={'100%'}
                        alt=""
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <CloseIcon
                className="text-icon-color bg-white rounded-full p-2.5 hover:text-primary-color absolute -right-14 top-0"
                size={20}
                onClick={() => setShowModal(false)}
              />
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ImagePreview;
