import React from 'react';
import type { IIconProps } from '@/types/common-types';

const NoRelatedPayeeIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 20 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M73.3327 16.6654C73.3327 14.8244 71.8403 13.332 69.9993 13.332H9.99934C8.15839 13.332 6.66602 14.8244 6.66602 16.6654V59.9987C6.66602 61.8396 8.1584 63.332 9.99935 63.332H30.2853C31.1694 63.332 32.0172 63.6832 32.6423 64.3083L37.6423 69.3083C38.9441 70.6101 41.0546 70.6101 42.3564 69.3083L47.3564 64.3083C47.9815 63.6832 48.8293 63.332 49.7134 63.332H69.9993C71.8403 63.332 73.3327 61.8396 73.3327 59.9987V16.6654Z"
          stroke="black"
          strokeWidth="3.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.9993 39.9987C44.6017 39.9987 48.3327 36.2677 48.3327 31.6654C48.3327 27.063 44.6017 23.332 39.9993 23.332C35.397 23.332 31.666 27.063 31.666 31.6654C31.666 36.2677 35.397 39.9987 39.9993 39.9987Z"
          stroke="#0094FF"
          strokeWidth="3.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55 53.3333C55 45.9695 48.2843 40 40 40C31.7157 40 25 45.9695 25 53.3333"
          stroke="#0094FF"
          strokeWidth="3.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default NoRelatedPayeeIcon;
