import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ErrorIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
      >
        <rect y="0.677734" width="12" height="12" rx="6" fill="#F43F5E" />
        <rect
          x="5.25"
          y="5.17773"
          width="1.5"
          height="5.25"
          rx="0.75"
          fill="white"
        />
        <rect
          x="5.25"
          y="2.92773"
          width="1.5"
          height="1.5"
          rx="0.75"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default ErrorIcon;
