import type {
  ICreditCard,
  ITerminalOrdersPaymentPageProps,
} from '../../../types/terminal-type';
import PayTypes from '../utils/payment/pay-types';
// import WonderIcon from '../terminal-icons/wonder-icon';
// import LikeIcon from '../terminal-icons/like-icon';
import SelectedIcon from '../terminal-icons/selected-icon';
import ApplePayIcon from '../terminal-icons/apple-pay-icon';
import UnselectedIcon from '../terminal-icons/unselected-icon';
import WeChatPayIcon from '../terminal-icons/wechat-pay-icon';
import AddCreditCardIcon from '../terminal-icons/add-credit-card-icon';
import AlipayIcon from '../terminal-icons/alipay-icon';
import UnionPayWalletIcon from '../terminal-icons/unionpay-wallet-icon';
import { handlePrice, triggerWonderRoute } from '../../../utils/terminal-untils';
import { useEffect, useRef, useState } from 'react';
import type { ChangeEvent } from 'react';
import Button from '@/components/common/button';
import { useTranslation } from 'react-i18next';
// import { minus } from '@/utils/galaxy-utils';
import LeftIcon from '../terminal-icons/left-icon';
import ExitIcon from '../terminal-icons/exit-icon';
import CloseIcon from '@/components/svg/close-icon';
import Label from '@/components/common/label';
import { IMask, IMaskInput } from 'react-imask';
import type { ExtendFactoryArgOptions } from 'imask/masked/factory';
import trim from 'lodash/trim';
import { useNavigate } from 'react-router-dom';
import CreditCartItem from '../components/credit-card-item';
import AntdModal from '@/components/common/antd/modal';
import WarnErrorIcon from '../terminal-icons/warn-error-icon';
import Skeleton from '@/components/skeleton';

interface PaymentMobilePageProps extends ITerminalOrdersPaymentPageProps {
  businessName: string;
  showApplePay: boolean;
  cards: ICreditCard[];
  handlePay: () => void;
  handleBack: () => void;
  handleSaveCard: (card: ICreditCard) => Promise<boolean>;
  setMethod: (method: PayTypes | ICreditCard | 'balance') => void;
  handleDeleteCard: (card: ICreditCard) => void;
  loadingData: boolean;
  method?: PayTypes | ICreditCard | 'balance';
}

const mmformet: ExtendFactoryArgOptions<any> = {
  mask: IMask.MaskedRange,
  form: 1,
  to: 12,
};

const PaymentMobilePage: React.FC<PaymentMobilePageProps> = (props) => {
  const {
    // token,
    showApplePay,
    inApp,
    // isPhone,
    businessID,
    usePaymentMethods,
    method,
    cards,
    setMethod,
    handlePay,
    invoice,
    // available,
    // currencyCode,
    handleSaveCard,
    // businessName,
    handleBack,
    tokenExpire = false,
    handleDeleteCard,
    loadingData,
  } = props;
  const { unpaid_total = 0, currency = 'HKD' } = invoice || {};
  const { t } = useTranslation(["extended_service","transaction_advice"]);
  const navigate = useNavigate();

  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [cardDate, setCardDate] = useState('');
  const [cardCVV, setCardCVV] = useState('');
  const [newCard, setNewCard] = useState(false);
  const [showRemoveCardID, setShowRemoveCardID] = useState('');
  const selectCard = useRef<ICreditCard | undefined>(undefined);

  useEffect(() => {
    if (tokenExpire) {
      triggerWonderRoute('true', 'is401');
    }
    window.addEventListener(
      'myCustomEvent',
      (e: any) => {
        try {
          const { detail } = e || {};
          const {
            card_number,
            holder_name,
            expiry_date = '',
            remove_card,
          } = detail || {};
          if (card_number) {
            const dateArr = expiry_date.split('/');
            handleSaveCard({
              id: '',
              token: '',
              number: card_number.replace(/[^\d]/g, ''),
              holder_name,
              exp_month: dateArr[0],
              exp_year: dateArr[1],
            });
          }
          if (remove_card === 'true' && selectCard.current) {
            handleDeleteCard(selectCard.current);
          }
        } catch (error) { }
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleSave = async () => {
    const dateArr = cardDate.split('/');
    if (dateArr[1]) {
      const success = await handleSaveCard({
        id: '',
        token: '',
        number: cardNumber,
        holder_name: cardName,
        exp_month: dateArr[0],
        exp_year: dateArr[1],
      });

      if (success) {
        setNewCard(false);
      }
    }
  };

  const handleClose = () => {
    if (inApp) {
      triggerWonderRoute(
        `/businesses/${businessID}/terminal/orders/check`,
        'close'
      );
    } else {
      navigate(`/businesses/${businessID}/terminal/orders/check`);
    }
  };

  const handleCardDeleteInquiries = (card: ICreditCard) => {
    selectCard.current = card;
  };

  const handleDelete = async () => {
    if (selectCard.current) {
      await handleDeleteCard(selectCard.current);
      selectCard.current = undefined;
    }
  };

  // const sufficient = minus(available, unpaid_total) >= 0;

  return (
    <div
      className="min-h-screen bg-[#F5F5F5] overflow-auto"
      style={{ WebkitTapHighlightColor: 'transparent' }}
    >
      <div className="p-4 flex fixed top-0 left-0 right-0 bg-[#F5F5F5]">
        <LeftIcon onClick={handleBack} />
        <div className="font-semibold flex-1 text-center">
          {t('extended_service:choose_method')}
        </div>
        <ExitIcon onClick={handleClose} />
      </div>
      <div className="p-4 pb-32 pt-[72px]">
        {loadingData && (
          <Skeleton 
            row={1}
            width={['100%']}
            skeletonItemClass="h-9 mb-6 rounded-2xl"
          />
        )}
        {!loadingData && (
          <div className="text-center mb-6 text-[32px] leading-[38px] font-light">
            <span className="font-bold">{handlePrice(unpaid_total)}</span>{' '}
            {currency}
          </div>
        )}
        {/* <div className="mb-4 bg-primary-color/20 rounded-2xl">
          <div className="flex p-4 pb-3 items-center">
            <WonderIcon />
            <div className="ml-2 font-semibold flex-1">
              {t('extended_service:merchant_account')}
            </div>
            <LikeIcon />
          </div>
          <div className="p-0.5">
            <div className="bg-white p-4 flex rounded-2xl justify-between leading-5">
              <div className="">
                <div className="font-semibold">{businessName}</div>
                <div className="text-primary-color mt-1">
                  {t('extended_service:current_balance')}{' '}
                  {handlePrice(available)} {currencyCode}
                </div>
                {!sufficient && (
                  <div className="mt-2 text-sm leading-[18px] flex items-center">
                    <WarningIcon />
                    <div className="ml-1 text-error-color">
                      {t('extended_service:insufficient')}
                    </div>
                  </div>
                )}
              </div>
              {method === 'balance' ? <SelectedIcon /> : ''}
            </div>
          </div>
        </div> */}
        {usePaymentMethods.includes(PayTypes.CREDIT_CARD) && (
          <div className="rounded-2xl bg-white mb-4 leading-5">
            <div className="flex items-center p-4">
              <div className="font-semibold">
                {t('extended_service:credit_card')}
              </div>
            </div>
            <div className="mx-4 border-solid border-0 border-b border-[#F5F5F5]"></div>
            {loadingData && (
              <Skeleton 
                row={2}
                width={['100%', '100%']}
                skeletonItemClass="h-9 my-4 rounded-2xl"
                className="px-4"
              />
            )}
            {cards.map((card) => {
              return (
                <CreditCartItem
                  key={card.id}
                  inApp={inApp}
                  card={card}
                  showRemoveCardID={showRemoveCardID}
                  method={method}
                  setMethod={setMethod}
                  setShowRemoveCardID={setShowRemoveCardID}
                  handleCardDeleteInquiries={handleCardDeleteInquiries}
                />
              );
            })}
            <div
              className="flex items-center py-4 cursor-pointer px-4"
              onClick={() => {
                inApp
                  ? triggerWonderRoute('add_credit_card', 'bootom_sheet')
                  : setNewCard(true);
              }}
            >
              <AddCreditCardIcon />
              <div className="flex-1 ml-2 text-primary-color font-medium">
                {t('extended_service:new_credit_card')}
              </div>
            </div>
          </div>
        )}

        <div className="px-4 rounded-2xl bg-white divide-y leading-5">
          {loadingData && (
            <Skeleton 
              row={2}
              width={['100%', '100%']}
              skeletonItemClass="h-9 my-4 rounded-2xl"
            />
          )}
          {usePaymentMethods.includes(PayTypes.APPLE_PAY) && showApplePay && (
            <div
              className="flex items-center py-4 cursor-pointer"
              onClick={() => setMethod(PayTypes.APPLE_PAY)}
            >
              <ApplePayIcon />
              <div className="flex-1 ml-2">Apple Pay</div>
              {method === PayTypes.APPLE_PAY ? (
                <SelectedIcon />
              ) : (
                <UnselectedIcon />
              )}
            </div>
          )}
          {usePaymentMethods.includes(PayTypes.WECHAT_PAY) && (
            <div
              className="flex items-center py-4 cursor-pointer"
              onClick={() => setMethod(PayTypes.WECHAT_PAY)}
            >
              <WeChatPayIcon className="text-xs" />
              <div className="flex-1 ml-2">
                {t('extended_service:wechat_pay')}
              </div>
              {method === PayTypes.WECHAT_PAY ? (
                <SelectedIcon />
              ) : (
                <UnselectedIcon />
              )}
            </div>
          )}
          {usePaymentMethods.includes(PayTypes.ALIPAYCN) && (
            <div
              className="flex items-center py-4 cursor-pointer"
              onClick={() => setMethod(PayTypes.ALIPAYCN)}
            >
              <AlipayIcon className="text-xs" />
              <div className="flex-1 ml-2">{t('extended_service:alipay')}</div>
              {method === PayTypes.ALIPAYCN ? (
                <SelectedIcon />
              ) : (
                <UnselectedIcon />
              )}
            </div>
          )}
          {usePaymentMethods.includes(PayTypes.ALIPAYHK) && (
            <div
              className="flex items-center py-4 cursor-pointer"
              onClick={() => setMethod(PayTypes.ALIPAYHK)}
            >
              <AlipayIcon className="text-xs" />
              <div className="flex-1 ml-2">
                {t('extended_service:alipayhk')}
              </div>
              {method === PayTypes.ALIPAYHK ? (
                <SelectedIcon />
              ) : (
                <UnselectedIcon />
              )}
            </div>
          )}
          {usePaymentMethods.includes(PayTypes.UNIONPAY_WALLET) && (
            <div
              className="flex items-center py-4 cursor-pointer"
              onClick={() => setMethod(PayTypes.UNIONPAY_WALLET)}
            >
              <UnionPayWalletIcon className="text-xs" />
              <div className="flex-1 ml-2">
                {t('extended_service:unionpay_wallet')}
              </div>
              {method === PayTypes.UNIONPAY_WALLET ? (
                <SelectedIcon />
              ) : (
                <UnselectedIcon />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="fixed bottom-0 w-full py-6 pb-8 px-4 bg-white">
        <Button
          className="rounded-xl w-full h-11 disabled:bg-primary-color/50 disabled:text-white"
          disabled={!method}
          onClick={handlePay}
        >
          {t('extended_service:pay_now')}
        </Button>
      </div>
      {newCard && (
        <div className="fixed left-0 right-0 top-0 bottom-0 h-screen bg-select-secondary-background-color bg-opacity-30">
          <div className="fixed bottom-0 rounded-t-2xl bg-white left-0 right-0 divide-y divide-border-color">
            <div className="py-4 text-center font-semibold">
              {t('extended_service:add_card')}
            </div>
            <div className="absolute top-4 right-4 border-none">
              <CloseIcon
                size={24}
                className="text-primary-color"
                onClick={() => setNewCard(false)}
              />
            </div>
            <div className="px-4 py-6 flex flex-col gap-y-4">
              <div>
                <Label
                  className="!text-[#8E8E93] !text-base font-normal"
                  value={t('extended_service:card_number')}
                  required
                />
                <IMaskInput
                  mask={[{ mask: '0000 0000 0000 0000' }]}
                  unmask={true}
                  placeholder={t('extended_service:enter_card_number')}
                  onAccept={(value: any) => {
                    setCardNumber(value);
                  }}
                  className={`w-full h-14 rounded-2xl text-base p-4 outline-none bg-[#F5F5F5] placeholder:text-[#8E8E93]`}
                />
              </div>
              <div>
                <Label
                  className="!text-[#8E8E93] !text-base font-normal"
                  value={t('extended_service:holder_name')}
                  required
                />
                <input
                  type="text"
                  autoComplete="off"
                  maxLength={256}
                  placeholder={t('extended_service:enter_holder_name')}
                  onInput={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.startsWith(' ')) {
                      setCardName(trim(e.target.value));
                    } else {
                      setCardName(e.target.value);
                    }
                  }}
                  className={`w-full h-14 rounded-2xl text-base p-4 outline-none bg-[#F5F5F5] placeholder:text-[#8E8E93]`}
                />
              </div>
              <div>
                <Label
                  className="!text-[#8E8E93] !text-base font-normal"
                  value={t('extended_service:expiry_date')}
                  required
                />
                <IMaskInput
                  mask={'MM/YY'}
                  blocks={{
                    YY: '00',
                    MM: mmformet,
                  }}
                  placeholder="MM/YY"
                  className={`w-full h-14 rounded-2xl text-base p-4 outline-none bg-[#F5F5F5] placeholder:text-[#8E8E93]`}
                  onAccept={(value: any) => {
                    setCardDate(value);
                  }}
                />
              </div>
              <div>
                <Label
                  className="!text-[#8E8E93] !text-base font-normal"
                  value="CVV"
                  required
                />
                <input
                  type="number"
                  inputMode="numeric"
                  autoComplete="off"
                  maxLength={4}
                  placeholder={t('extended_service:enter_cvv')}
                  onInput={(e: ChangeEvent<HTMLInputElement>) => {
                    setCardCVV(e.target.value);
                  }}
                  className={`w-full h-14 rounded-2xl text-base p-4 outline-none bg-[#F5F5F5] placeholder:text-[#8E8E93]`}
                />
              </div>
            </div>
            <div className="w-full py-6 px-4 bg-white">
              <Button
                className="rounded-xl w-full h-11 bg-primary-color text-white disabled:opacity-50"
                onClick={handleSave}
                disabled={
                  !(cardNumber.length > 13 && cardDate && cardName && cardCVV)
                }
              >
                {t('common:save')}
              </Button>
            </div>
          </div>
        </div>
      )}
      <AntdModal
        open={selectCard.current && !inApp}
        size={'large'}
        className="!h-auto rounded-xl"
        closable={false}
        maskClosable={false}
      >
        <div className="flex flex-col items-center text-color-text-1 p-6">
          <WarnErrorIcon />
          <div className="text-xl font-bold mt-2">
            {t('extended_service:remove_card')}
          </div>
          <div className="text-sm font-medium mt-2">
            {t('extended_service:remove_card_tip')}
          </div>
          <Button
            className="w-full mt-4 !rounded-xl"
            onClick={() => (selectCard.current = undefined)}
          >
            {t('common:back')}
          </Button>
          <Button
            className="w-full mt-2 !rounded-xl !bg-primary-color/20"
            styleType="Secondary"
            onClick={handleDelete}
          >
            {t('extended_service:remove_this_card')}
          </Button>
        </div>
      </AntdModal>
    </div>
  );
};

export default PaymentMobilePage;
