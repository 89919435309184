import React from 'react';
import type { IIconProps } from '@/types/common-types';

const CheckIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 20 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 20 20"
        fill="none"
      >
        <g clipPath="url(#clip0_15796_15423)">
          <path
            d="M4.16797 10.0026L8.33464 14.1693L16.668 5.83594"
            stroke="#0094FF"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_15796_15423">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CheckIcon;
