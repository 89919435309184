import { Alert } from "@/components/common/alert/alert";
import { archiveBusinessApplication, withdrawBusinessApplication } from "@/data/businesses";
import { BusinessStatus } from "@/types/business-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import WarningIcon from "@/components/svg/warning-side-icon";
import Button from "@/components/common/button";

interface IWarnContentProps {
  status: BusinessStatus;
  businessID: string;
  onCancel: (reload?: boolean) => void;
}

const WarnContent = (props: IWarnContentProps) => {
  const { status, businessID, onCancel } = props;

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation('personal_space');

  let title = '';
  let content = '';

  switch (status) {
    case BusinessStatus.ABANDONED:
      title = t('personal_space:application_abandoned');
      content = t('personal_space:application_abandoned_tips');
      break;
    case BusinessStatus.INCOMPLETE_APPLICATION:
      title = t('personal_space:incomplete_application');
      content = t('personal_space:incomplete_application_tips');
      break;
    case BusinessStatus.REJECTED:
      title = t('personal_space:application_rejected');
      content = t('personal_space:application_rejected_tips');
      break;
    case BusinessStatus.DORMANT:
      title = t('personal_space:business_dormant');
      content = t('personal_space:business_dormant_tips');
      break;
    case BusinessStatus.SUSPENDED:
      title = t('personal_space:business_suspended');
      content = t('personal_space:business_suspended_tips');
      break;
    default:
      break;
  }

  const handleArchive = async () => {
    setLoading(true);
    const res = await archiveBusinessApplication(businessID);
    setLoading(false);

    if (res && res.code === 200 && res.data === 'successful') {
      onCancel(true);
      Alert.success({
        message: t('personal_space:archive_successfully'),
        wrapClass: '-top-10',
      });
    } else {
      onCancel();
      Alert.success({
        message: t('personal_space:archive_failed'),
        wrapClass: '-top-10',
      });
    }
  };

  const handleWithdraw = async () => {
    setLoading(true);
    const res = await withdrawBusinessApplication(businessID);
    setLoading(false);

    if (res && res.code === 200 && res.data === 'successful') {
      onCancel(true);
      Alert.success({
        message: t('personal_space:withdraw_successfully'),
        wrapClass: '-top-10',
      });
    } else {
      onCancel();
      Alert.error({
        message: t('personal_space:withdraw_failed'),
        wrapClass: '-top-10',
      });
    }
  };

  return (
    <div className="flex flex-col items-center text-center text-color-text-1 px-10 pt-8 pb-10">
      <WarningIcon />
      <div className="font-bold text-xl mt-4">{title}</div>
      <div className="text-sm font-medium mt-1">{content}</div>
      <div className="mt-2 w-full">
        {status === BusinessStatus.ABANDONED && (
          <Button
            loading={loading}
            styleType="Secondary Danger"
            onClick={handleArchive}
            className="w-full mt-4"
          >
            {t('personal_space:archive_application')}
          </Button>
        )}
      </div>
      <Button onClick={() => onCancel()} className="w-full mt-4">
        {t('common:back')}
      </Button>
      {status === BusinessStatus.INCOMPLETE_APPLICATION && (
        <Button
          loading={loading}
          styleType="Border"
          onClick={handleWithdraw}
          className="w-full mt-4"
        >
          {t('personal_space:withdraw_application')}
        </Button>
      )}
    </div>
  );
};

export default WarnContent;
