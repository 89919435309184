import React from 'react';
import type { IIconProps } from '@/types/common-types';

const DefaultImageIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 20 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.0625 5.4375H4.9375V33.5625H33.0625V5.4375Z"
          stroke="currentColor"
          strokeWidth="1.5625"
          strokeLinecap="square"
        />
        <path
          d="M4.9375 22.625L11.1875 16.375L28.375 33.5625"
          stroke="currentColor"
          strokeWidth="1.5625"
          strokeLinecap="round"
        />
        <path
          d="M21.3438 16.375C23.5011 16.375 25.25 14.6261 25.25 12.4688C25.25 10.3114 23.5011 8.5625 21.3438 8.5625C19.1864 8.5625 17.4375 10.3114 17.4375 12.4688C17.4375 14.6261 19.1864 16.375 21.3438 16.375Z"
          stroke="currentColor"
          strokeWidth="1.5625"
          strokeLinecap="square"
        />
        <path
          d="M21.3438 26.5312L33.0625 14.8125"
          stroke="currentColor"
          strokeWidth="1.5625"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default DefaultImageIcon;
