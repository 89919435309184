import React from 'react';
import type { IIconProps } from '@/types/common-types';

const DeleteIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 16 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        fill="none"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="40"
          height="40"
          rx="20"
          fill="currentColor"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 12C18.8143 12.0001 18.6324 12.0519 18.4745 12.1496C18.3166 12.2472 18.189 12.3869 18.106 12.553L17.382 14H14C13.7348 14 13.4804 14.1054 13.2929 14.2929C13.1054 14.4804 13 14.7348 13 15C13 15.2652 13.1054 15.5196 13.2929 15.7071C13.4804 15.8946 13.7348 16 14 16V26C14 26.5304 14.2107 27.0391 14.5858 27.4142C14.9609 27.7893 15.4696 28 16 28H24C24.5304 28 25.0391 27.7893 25.4142 27.4142C25.7893 27.0391 26 26.5304 26 26V16C26.2652 16 26.5196 15.8946 26.7071 15.7071C26.8946 15.5196 27 15.2652 27 15C27 14.7348 26.8946 14.4804 26.7071 14.2929C26.5196 14.1054 26.2652 14 26 14H22.618L21.894 12.553C21.811 12.3869 21.6834 12.2472 21.5255 12.1496C21.3676 12.0519 21.1857 12.0001 21 12H19ZM17 18C17 17.7348 17.1054 17.4804 17.2929 17.2929C17.4804 17.1054 17.7348 17 18 17C18.2652 17 18.5196 17.1054 18.7071 17.2929C18.8946 17.4804 19 17.7348 19 18V24C19 24.2652 18.8946 24.5196 18.7071 24.7071C18.5196 24.8946 18.2652 25 18 25C17.7348 25 17.4804 24.8946 17.2929 24.7071C17.1054 24.5196 17 24.2652 17 24V18ZM22 17C21.7348 17 21.4804 17.1054 21.2929 17.2929C21.1054 17.4804 21 17.7348 21 18V24C21 24.2652 21.1054 24.5196 21.2929 24.7071C21.4804 24.8946 21.7348 25 22 25C22.2652 25 22.5196 24.8946 22.7071 24.7071C22.8946 24.5196 23 24.2652 23 24V18C23 17.7348 22.8946 17.4804 22.7071 17.2929C22.5196 17.1054 22.2652 17 22 17Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default DeleteIcon;
