import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ProcessUnsuccessfulIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.87 22.7089C27.7164 22.9647 27.4675 23.1491 27.178 23.2214C26.8885 23.2938 26.5822 23.2481 26.3263 23.0946C26.0705 22.941 25.8861 22.6921 25.8138 22.4026C25.7414 22.1131 25.7871 21.8067 25.9406 21.5509C27.1284 19.5725 27.754 17.3076 27.75 15C27.75 7.95825 22.0417 2.25 15 2.25C7.95825 2.25 2.25 7.95825 2.25 15C2.25 22.0417 7.95825 27.75 15 27.75C17.3072 27.7541 19.5719 27.1288 21.5501 25.9414C21.6768 25.8654 21.8172 25.8151 21.9634 25.7933C22.1095 25.7716 22.2585 25.7788 22.4018 25.8146C22.5451 25.8505 22.68 25.9142 22.7987 26.0021C22.9174 26.0901 23.0176 26.2006 23.0936 26.3272C23.1696 26.4539 23.2199 26.5943 23.2417 26.7405C23.2634 26.8866 23.2562 27.0356 23.2204 27.1789C23.1845 27.3222 23.1208 27.4571 23.0329 27.5758C22.9449 27.6945 22.8344 27.7947 22.7077 27.8707C20.3798 29.268 17.715 30.0041 15 30C6.71588 30 0 23.2841 0 15C0 6.71588 6.71588 0 15 0C23.2841 0 30 6.71588 30 15C30 17.7525 29.2564 20.3989 27.87 22.7089Z"
          fill="black"
        />
        <path
          d="M16.4009 13.881L19.441 16.9212C19.5422 17.0207 19.6226 17.1392 19.6777 17.2699C19.7328 17.4007 19.7615 17.541 19.7621 17.6829C19.7627 17.8248 19.7351 17.9654 19.6811 18.0966C19.6271 18.2278 19.5476 18.347 19.4473 18.4473C19.347 18.5476 19.2278 18.6271 19.0966 18.6811C18.9654 18.7351 18.8248 18.7627 18.6829 18.7621C18.541 18.7615 18.4007 18.7328 18.2699 18.6777C18.1392 18.6226 18.0207 18.5422 17.9212 18.441L14.881 15.4009L11.8409 18.441C11.7414 18.5422 11.6229 18.6226 11.4921 18.6777C11.3614 18.7328 11.221 18.7615 11.0792 18.7621C10.9373 18.7627 10.7967 18.7351 10.6655 18.6811C10.5343 18.6271 10.4151 18.5476 10.3148 18.4473C10.2145 18.347 10.135 18.2278 10.081 18.0966C10.0269 17.9654 9.99943 17.8248 10 17.6829C10.0006 17.541 10.0293 17.4007 10.0844 17.2699C10.1395 17.1392 10.2199 17.0207 10.321 16.9212L13.3612 13.881L10.321 10.8409C10.2199 10.7414 10.1395 10.6229 10.0844 10.4921C10.0293 10.3614 10.0006 10.221 10 10.0792C9.99943 9.93728 10.0269 9.79669 10.081 9.6655C10.135 9.53431 10.2145 9.41511 10.3148 9.31479C10.4151 9.21446 10.5343 9.135 10.6655 9.08097C10.7967 9.02694 10.9373 8.99943 11.0792 9.00001C11.221 9.00059 11.3614 9.02926 11.4921 9.08436C11.6229 9.13946 11.7414 9.2199 11.8409 9.32104L14.881 12.3612L17.9212 9.32104C18.0207 9.2199 18.1392 9.13946 18.2699 9.08436C18.4007 9.02926 18.541 9.00059 18.6829 9.00001C18.8248 8.99943 18.9654 9.02694 19.0966 9.08097C19.2278 9.135 19.347 9.21446 19.4473 9.31479C19.5476 9.41511 19.6271 9.53431 19.6811 9.6655C19.7351 9.79669 19.7627 9.93728 19.7621 10.0792C19.7615 10.221 19.7328 10.3614 19.6777 10.4921C19.6226 10.6229 19.5422 10.7414 19.441 10.8409L16.4009 13.881Z"
          fill="#FFDA44"
        />
      </svg>
    </div>
  );
};

export default ProcessUnsuccessfulIcon;
