import React from 'react';
import type { IIconProps } from '@/types/common-types';

const FailedIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 80 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 80 80"
        fill="none"
      >
        <rect opacity="0.1" width="80" height="80" rx="40" fill="#F43F5E" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 40C20 29 29 20 40 20C51 20 60 29 60 40C60 51 51 60 40 60C29 60 20 51 20 40ZM45.2502 48.748L48.7502 45.248L43.5002 39.998L48.7502 34.748L45.2502 31.248L40.0002 36.498L34.7502 31.248L31.2502 34.748L36.5002 39.998L31.2502 45.248L34.7502 48.748L40.0002 43.498L45.2502 48.748Z"
          fill="#F43F5E"
        />
      </svg>
    </div>
  );
};

export default FailedIcon;
