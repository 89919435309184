import { useEffect, useState } from 'react';
import Button from '@/components/common/button';
// import Input from '@/components/common/input/input';
import Label from '@/components/common/label';
import ModalEmpty from '@/components/common/modal/modal-empty';
import Select from '@/components/common/select';
import dayjs from 'dayjs';
import type { IHandleExportParams } from '@/utils/gateway-utils';
import { handleExport } from '@/utils/gateway-utils';
import { useSelector } from 'react-redux';
import type { IObject } from '@/types/common-types';
import { useTranslation } from 'react-i18next';
import type { RangePickerProps } from 'antd/es/date-picker';
import DateRange from '@/components/common/antd/date-range';
import { transformField } from '@/utils/field-utils';
import { Alert } from '@/components/common/alert/alert';
import { IReducersState } from '@/reducers';

interface IExportComponent {
  businessId: string;
  visible: boolean;
  hiddenDate?: boolean;
  onCancel?: () => void;
  templateType?: string;
  dayEndData?: IObject;
}

const SendExportComponent = (props: IExportComponent) => {
  const {
    businessId,
    visible,
    onCancel,
    hiddenDate = false,
    templateType = 'merchant_services_statement',
    dayEndData,
  } = props;

  const { t } = useTranslation("account_balance");

  const business = useSelector((state: IReducersState) => state.businessState.business);
  // const { default_currency = 'HKD' } = business || {};
  const defaultDateRange: [dayjs.Dayjs, dayjs.Dayjs] = [
    dayjs().subtract(7, 'day'),
    dayjs(),
  ];

  useEffect(() => {
    if (!!visible) {
      let fileTile = '';
      setFileType('pdf');
      fileTile = handleTitle(defaultDateRange, dayEndData?.id);
      const type = transformField(templateType);
      // if (templateType === 'merchant_services_statement') {
      //   type = `${type} - ${dayjs(dayEndData?.created_at)
      //     .tz(business.timezone || 'Asia/Hong_Kong')
      //     .format('MMM D,YYYY')}`
      // }
      // if(templateType === 'business_account_snapshot') {
      //   fileTile = dayjs().subtract(7, 'day').format('MMM D, YYYY') + ' - ' + dayjs().format('MMM D, YYYY') + ' Business Account Snapshop';
      // } else if (templateType === 'merchant_services_statement') {
      //   fileTile = 'Merchant Services Statement' + ' - ' + dayjs().format('MMM D, YYYY');
      // }
      setType(type);
      setFileTitle(fileTile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const [fileTitle, setFileTitle] = useState<string>('');
  const [rangeDate, setRangeDate] =
    useState<[dayjs.Dayjs, dayjs.Dayjs]>(defaultDateRange);
  const [fileType, setFileType] = useState<string>('');
  const [sendLoading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>('');

  const verfityDisable = () => {
    let disbale = true;
    if (hiddenDate) {
      disbale = !fileType;
    } else {
      disbale = !(rangeDate && rangeDate.length > 0 && fileType);
    }
    return disbale;
  };

  const saveFile = async () => {
    let startTime = '';
    let endTime = '';
    if (!hiddenDate && rangeDate) {
      startTime = dayjs(rangeDate[0]).format('YYYY-MM-DD');
      endTime = dayjs(rangeDate[1]).format('YYYY-MM-DD');
    }

    setLoading(true);
    let success = false;
    try {
      let obj: IHandleExportParams = {
        p_business_id: businessId,
        type: fileType,
        batchID: dayEndData?.id,
      };

      if (startTime && endTime) {
        obj = {
          p_business_id: businessId,
          type: fileType,
          startTime,
          currency: '',
          templateType: 'bas',
          endTime,
        };
      }

      const { url = '' } = await handleExport(obj);
      if (url) {
        success = true;
        //得到文件地址, 再次请求
        const type = fileType === 'excel' ? 'xlsx' : 'pdf';
        fetch(url).then((res) =>
          res.blob().then((blob) => {
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${fileTitle}.${type}`;
            a.click();
            window.URL.revokeObjectURL(url);
          })
        );
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      success = false;
    }

    if (success) {
      Alert.success({
        message: t('common:exported_successfully'),
        wrapClass: '-top-10',
      });
    } else {
      Alert.error({
        message: t('common:exported_failed'),
        wrapClass: '-top-10',
      });
    }

    if (onCancel) {
      onCancel();
    }
  };

  const handleTitle = (dates: any, dayEndID = '') => {
    let fileTile = '';
    if (templateType === 'business_account_snapshot') {
      fileTile = `bas_${dayjs(dates[0])
        .tz(business.timezone || 'Asia/Hong_Kong')
        .format('YYYYMMDD')}_${dayjs(dates[1])
          .tz(business.timezone || 'Asia/Hong_Kong')
          .format('YYYYMMDD')}_${businessId.slice(-6)}`;
    } else if (templateType === 'merchant_services_statement') {
      fileTile = `mss_${dayjs(dayEndData?.created_at)
        .tz(business.timezone || 'Asia/Hong_Kong')
        .format('YYYYMMDD')}_${businessId.slice(-6)}${dayEndID ? `_${dayEndID.slice(-6)}` : ''}`;
    }

    return fileTile;
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };

  return (
    <>
      <ModalEmpty
        visible={visible}
        title={t('common:export')}
        onCancel={onCancel}
      >
        <>
          <div className={`w-full mt-8`}>
            <Label value={t('account_balance:report_type')} required={true} />
            <div className="bg-disabled-color rounded shadow-box-drop border text-sm border-border-color px-3 py-2 text-color-text-1 font-medium">
              {type}
            </div>
          </div>
          <div className={`w-full mt-8`}>
            <Label value={t('account_balance:file_format')} required={true} />
            <Select
              className="text-color-text-1"
              options={[
                {
                  label: 'PDF',
                  value: 'pdf',
                },
                {
                  label: 'EXCEL',
                  value: 'excel',
                },
              ]}
              value={fileType}
              onChange={(value: any) => {
                setFileType(value);
              }}
            />
          </div>
          {!hiddenDate && (
            <div className={`w-full mt-8`}>
              <Label value={t('account_balance:date_range')} required={true} />
              <DateRange
                format="MMM D, YYYY"
                className="w-full"
                disabledDate={disabledDate}
                allowClear={false}
                size={'large'}
                value={rangeDate}
                onChange={(dateArray) => {
                  setRangeDate(dateArray);
                  setFileTitle(handleTitle(dateArray, dayEndData?.id));
                }}
              />
            </div>
          )}
          {/* <div className={`w-full my-8`}>
            <Label
              disabled={true}
              value={t('account_balance:file_title')}
              required={false}
            />
            <Input
              onChange={(data) => {
                setFileTitle(data);
              }}
              value={fileTitle}
            />
          </div> */}
          <Button
            className={`w-full mt-8`}
            styleType="Primary"
            disabled={verfityDisable()}
            onClick={saveFile}
            loading={sendLoading}
          >
            {templateType === 'merchant_services_statement'
              ? t('account_balance:generate_statement')
              : t('account_balance:generate_snapshot')}
          </Button>
        </>
      </ModalEmpty>
    </>
  );
};
export default SendExportComponent;
