import type { IFileObject } from '@/types/common-types';
import type { InputHTMLAttributes } from 'react';
import type { FieldError } from 'react-hook-form';
import { useEffect, useState } from 'react';
import Upload from './upload';
import api from '@/libs/api';
import { getCookie } from '@/utils/index';
import Config from '@/libs/config';
import { Constants } from '@/constants';
import ImagePreview from './image-preview';
import PreviewIcon from '@/components/svg/preview-icon';
import DownloadIcon from '@/components/svg/download-icon';
import DeleteIcon from '@/components/svg/delete-icon';
import NoFileIcon from './icons/no-file-icon';
import { useTranslation } from 'react-i18next';
import './index.css'



export interface IFileInputProps
  extends Omit<InputHTMLAttributes<HTMLElement>, 'onChange' | 'defaultValue'> {
  name: string;
  proportion: number;
  rowQuantity: number;
  maxSize?: number;
  maxCount?: number;
  disabled?: boolean;
  onChange?: (value: IFileObject[]) => void;
  error?: FieldError;
  defaultValue?: IFileObject[];
  dataBase?: string;
  label?: string;
  onUploading?: (loading: boolean) => void;
}

const fileTypes = ['csv', 'pdf', 'xlsx', 'doc', 'docx'];
const imageTypes = ['jpeg', 'jpg', 'png'];

const aspect: { [key: number]: string } = {
  1: 'aspect-square',
  2: 'aspect-4/3',
  3: 'aspect-3/4',
};

const FileInput: React.FC<IFileInputProps> = (props) => {
  const {
    name,
    rowQuantity = 4,
    proportion = 1,
    onChange,
    disabled = false,
    error,
    maxSize = 100,
    maxCount = 10,
    defaultValue,
    dataBase = '',
    label,
    onUploading,
  } = props;

  useEffect(() => {
    setFiles(defaultValue || []);
    // eslint-disable-next-line
  }, [defaultValue]);

  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [currentIndex, seCurrentIndex] = useState(0);
  const [files, setFiles] = useState<IFileObject[]>(
    defaultValue ? defaultValue : []
  );
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState('');
  const { t } = useTranslation();

  const loadingChange = (value: boolean) => {
    setLoading(value);
    if (onUploading) {
      onUploading(value);
    }
  };

  const handlefilesUpdate = (
    fileObject: IFileObject,
    type: 'ADD' | 'DELETE'
  ) => {
    let newFiles: IFileObject[] = [];
    if (type === 'ADD') {
      newFiles = files;
      newFiles.push(fileObject);
    } else if (type === 'DELETE') {
      files.forEach((file) => {
        if (file.fileUrl !== fileObject.fileUrl) {
          newFiles.push(file);
        }
      });
    }
    setFiles(newFiles);
    if (onChange) {
      onChange(newFiles);
    }
    loadingChange(false);
  };

  const onDownloadFile = (file: string, fileName: string) => {
    const downloadUrl = file && file.split('?')[0];
    downloadUrl &&
      fetch(downloadUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
        });
  };

  const handleDownload = (file: IFileObject, fileName: string) => {
    if (file) {
      onDownloadFile(file.fileUrl, fileName);
    } else {
      console.info(1);
    }
  };

  const handleDelete = async (file: IFileObject) => {
    if (file && file.id) {
      const token = getCookie(Constants.TOKEN) || '';
      try {
        await api.remove(
          `${Config.urls.gatewayUrl}/api/directus/${dataBase}/files/${file.id}`,
          {
            method: 'DELETE',
            headers: {
              'x-user-access-token': token,
            },
          }
        ).then((data: any) => data.json());
      } catch (e) { }
    }
  };
 

  return (
    <>
      <div
        className={`block gap-x-4 sm:grid`}
        style={{
          gridTemplateColumns: `repeat(${rowQuantity}, minmax(0, 1fr))`,
        }}
      >
        {files.map((file, index) => {
          const isLoading = loadingImage && currentIndex === index;
          let fileType = '';
          let fileName = '';
          if (file && file.filenameDownload) {
            const array = file.filenameDownload.split('.');
            fileType = array[array.length - 1].toLocaleLowerCase();
            fileName = file.filenameDownload;
          } else {
            const fileArray = file?.fileUrl.split('/');
            const fileLast = fileArray[fileArray.length - 1].split('.');
            fileType = fileLast[fileLast.length - 1].toLocaleLowerCase();
            fileName = fileLast[0];
          }
          return (
            <div className="mb-4" key={`${file}_${index}`}>
              <div
                className={`${aspect[proportion]} border-border-color border rounded relative`}
              >
                {(fileTypes.includes(fileType) ||
                  !imageTypes.includes(fileType)) && (
                    <div
                      className="bg-repeat h-[100%]"
                      style={{
                        backgroundImage: `url(/images/upload/${fileTypes.includes(fileType) ? fileType : 'other'
                          }.svg)`,
                      }}
                    ></div>
                  )}
                {imageTypes.includes(fileType) && (
                  <div
                    className={`${aspect[proportion]} ${isLoading ? `loading image_loading` : ''} rounded relative`}
                  >
                    <div>
                      <img
                        className="rounded absolute w-[100%] h-[100%]"
                        style={{
                          objectFit: 'cover'
                        }}
                        src={file.fileUrl}
                        loading="lazy"
                        alt=""
                        onLoad={() => {
                          setLoadingImage(false);
                          seCurrentIndex(index);
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="flex justify-center items-end opacity-0 p-2 gap-x-2 hover:opacity-100 absolute bottom-0 left-0 w-[100%]">
                  <PreviewIcon
                    className="text-icon-color cursor-pointer hover:text-primary-color"
                    size={40}
                    onClick={() => {
                      if (fileTypes.includes(fileType)) {
                        window.open(file.fileUrl);
                      } else {
                        setShow(true);
                        setUrl(file.fileUrl);
                      }
                    }}
                  />
                  <DownloadIcon
                    className="text-icon-color bg-icon-color/10 rounded-full p-2.5 cursor-pointer hover:text-primary-color hover:bg-primary-color/10"
                    size={20}
                    onClick={() => handleDownload(file, file.title || '')}
                  />
                  {!disabled && (
                    <DeleteIcon
                      className="text-icon-color cursor-pointer hover:text-error-color"
                      size={40}
                      onClick={() => {
                        handlefilesUpdate(file, 'DELETE');
                        if (file.id) {
                          handleDelete(file);
                        }
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="flex text-color-text-3 text-xs leading-5">
                <div className="truncate">{fileName}</div>
              </div>
            </div>
          );
        })}
        {files.length < maxCount && !disabled && (
          <Upload
            className={aspect[proportion]}
            name={name}
            type="FILE"
            label={label}
            loading={loading}
            setLoading={loadingChange}
            maxSize={maxSize}
            handlefilesUpdate={handlefilesUpdate}
            dataBase={dataBase}
          />
        )}
        {files.length === 0 && disabled && (
          <div className="aspect-square">
            <div className="flex flex-col justify-center items-center">
              <NoFileIcon />
              <div className="mt-4 text-sm text-color-text-2 font-normal">
                {t('common:no_file_yet')}
              </div>
            </div>
          </div>
        )}
      </div>
      {error && (
        <div className="mt-1 text-sm text-error-color">{error.message}</div>
      )}
      <ImagePreview show={show} setShowModal={() => setShow(false)} url={url} />
    </>
  );
};

export default FileInput;
