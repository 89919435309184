import type { IFilterField } from '@/components/common/filter';
import type { IFilterOptions } from '@/components/common/filter';
import type { IObject } from '@/types/common-types';
import { IBusiness } from '@/types/business-types';
import type dayjs from 'dayjs';

export interface IAccountBalanceFormViewProps {
  businessID: string;
  ledgerAccount: ILedgerAccount | IObject;
  lederEnteryData: IObject;
  merchantAccountData: IObject;
  upComingData: IObject;
  processInitData?: IObject[];
  refreshPage: boolean;
  store?: IBusiness;
  permissions?: string[];
  activekey?: 'overview' | 'merchantAccount' | 'currentAccount';
}

export interface IAccountBalanceOverview extends IAccountBalanceFormViewProps {
  storeLedgerAccount: ILedgerAccount | IObject;
  currentDate: dayjs.Dayjs | undefined;
  initLoading: boolean;
}
export interface IAccountBalanceCurrentAccount
  extends IAccountBalanceFormViewProps {
  storeLedgerAccount: ILedgerAccount | IObject;
  currentDate: dayjs.Dayjs | undefined;
  refreshPage: boolean;
  initLoading: boolean;
  filterFields: IFilterField[];
}
export interface IAccountBalanceCurrentActivities
  extends IAccountBalanceFormViewProps {
  storeLedgerAccount: ILedgerAccount | IObject;
  currentDate: dayjs.Dayjs | undefined;
  refreshPage: boolean;
  initLoading: boolean;
  filterFields: IFilterField[];
}

export interface IAccountBalanceMerchant extends IAccountBalanceFormViewProps {
  currentDate: dayjs.Dayjs | undefined;
  processTotal: number;
  initLoading: boolean;
}
export interface ILedgerAccount extends IObject {
  aggregated_current_balance: string;
  aggregated_incoming_fund: string;
  aggregated_outgoing_fund: string;
  projected_balance: string;
  default_currency: string;
  incoming_t_0: string;
  incoming_t_1: string;
  incoming_t_2: string;
  incoming_t_0_date: string;
  incoming_t_1_date: string;
  incoming_t_2_date: string;
  ledger_accounts: IObject[];
}

export interface IAccountContext {
  storeLedgerAccountInit: ILedgerAccount | IObject;
}

export interface IGetActivitiesParams {
  pBusinessId: string;
  type?: string;
  limit?: number;
  offset?: number;
  upcoming?: string;
  ledger_account_id?: string;
}

export interface IGetActiveLedgerSearch {
  est_settlement_date_from?: string;
  est_settlement_date_to?: string;
  settlement_date_from?: string;
  settlement_date_to?: string;
  created_at_data_from?: string;
  created_at_data_to?: string;
  currency?: string[];
  amount_from?: number;
  amount_to?: number;
  activity_type?: string[];
  activity_status?: string[];
  id_or_name?: string;
}

export interface IGetActiveLedgerParams {
  pBusinessId: string;
  recordId: string;
}

export interface IGetActivitesCount {
  pBusinessId: string;
  type?: string;
  limit?: number;
  offset?: number;
  upcoming?: string;
}

export enum TransactionType {
  payIn = 'payin',
  payOut = 'payout',
  card = 'card',
  exChange = 'exchange',
}

// pay in transaction type
export enum PayinTransferType {
  merchantServices = 'Merchant Services',
  inboundTransfer = 'Inbound Transfer',
  releasedReserve = 'Rolling Reserve Released',
  systemAdjustment = 'System Adjustment',
  addFund = 'Add Fund',
}

export enum PayoutCategoryType {
  MerchantDisbursement = 'Merchant Disbursement',
  Transportation = 'Transportation',
  TravelExpenses = 'Travel Expenses',
  OfficeSupplies = 'Office Supplies',
  RelatedCompanyTransfer = 'Related Company Transfer',
  Investment = 'Investment',
  StaffTraining = 'Staff Training',
  CharityOrDonation = 'Charity or Donation',
  LoanRepayment = 'Loan & repayment',
  MedicalServices = 'Medical Services',
  Pension = 'Pension',
  SpecialisedServices = 'Specialised Services',
  Taxes = 'Taxes',
  Payroll = 'Payroll',
  Freight = 'Freight',
  GoodsTransfer = 'Goods Transfer',
  CommercialRent = 'Commercial Rent',
  StaffAccommodation = 'Staff Accommodation',
  OtherStaffLivingExpenses = 'Other Staff Living Expenses',
  Miscellaneous = 'Miscellaneous',
  Default = 'Default',
}

export enum TransferMethodType {
  PlatformTransfer = 'Platform Transfer',
  SWIFT = 'SWIFT',
  BankTransfer = 'Bank Transfer',
  RTGS = 'RTGS',
  FPSBankAccount = 'HK FPS - Account Number',
  FPSEmail = 'HK FPS - Email',
  FPSId = 'HK FPS - FPS ID',
  FPSMobile = 'HK FPS - Mobile',
  AlipayMobile = 'Alipay - Phone Number',
  AlipayEmail = 'Alipay - Email',
}

export enum PayoutTransferIdentifyType {
  String = 'String',
  Number = 'Number',
  SWIFT = 'SWIFT',
  Email = 'Email',
  Bank = 'Bank',
  AccountNumber = 'Account Number',
  PlatformBusinessId = 'Platform Business ID',
}

export interface IGetDayEndClosedRecords {
  pBusinessId: string;
  limit?: number;
  offset?: number;
  searchValue?: string;
  options?: IFilterOptions[];
}
export interface IGetCurrentlyProcessing {
  pBusinessId: string;
  limit?: number;
  offset?: number;
  searchValue?: string;
  options?: IFilterOptions[];
}
export interface IGetMerchantTransactions {
  pBusinessId: string;
  dayEndId: string;
  lang: string;
  limit?: number;
  offset?: number;
}

export interface ISendReportEmail {
  email: string;
  url: string;
  businessId: string;
  title: string;
}

export interface ISendExportReport {
  type: string;
  businessId: string;
  dateRange?: string[];
  batchID?: string;
  templateType?: string;
  fileName?: string;
}
