import React from 'react';
import type { IIconProps } from '@/types/common-types';

const RefreshIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 24 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.71857 11.5306L3.69665 13.5086C3.83967 13.6514 4.02698 13.7229 4.21432 13.7228C4.4017 13.7229 4.58904 13.6514 4.73182 13.5084L6.71275 11.5273C6.99871 11.2414 6.99871 10.7779 6.71275 10.492C6.56979 10.3492 6.38247 10.2777 6.19518 10.2777C6.0079 10.2777 5.82034 10.3492 5.67762 10.4921L4.90016 11.2697C5.27562 7.16037 8.73955 3.93033 12.9451 3.93033C17.4005 3.93033 21.0252 7.55502 21.0252 12.0104C21.0252 16.4658 17.4005 20.0906 12.9451 20.0906C10.7869 20.0906 8.7578 19.2501 7.23152 17.724C6.94556 17.438 6.48232 17.438 6.19636 17.724C5.9104 18.0098 5.9104 18.4733 6.19636 18.7591C7.99905 20.562 10.3958 21.5547 12.9451 21.5547C18.2078 21.5547 22.4893 17.2731 22.4893 12.0104C22.4893 6.7478 18.2078 2.46625 12.9451 2.46625C7.96219 2.46625 3.86133 6.30532 3.43904 11.1805L2.75373 10.4952C2.61077 10.3524 2.42345 10.2809 2.23616 10.2809C2.04888 10.2809 1.86156 10.3524 1.7186 10.4953C1.43261 10.7812 1.43261 11.2447 1.71857 11.5306Z"
          fill="currentColor"
        />
        <path
          d="M12.2246 7.5V13.051L16.0409 16"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default RefreshIcon;
