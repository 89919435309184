import { Fragment } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import type { ReactNode } from 'react';
import CloseIcon from '@/components/svg/close-icon';

export interface IDetailsModalProps {
  title: string;
  visible: boolean;
  onCancel?: () => void;
  children?: ReactNode;
}

const DetailsModal: React.FC<IDetailsModalProps> = (props) => {
  const { visible = false, onCancel, children, title = '' } = props;

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-99" onClose={handleCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-select-secondary-background-color/60" />
        </Transition.Child>

        <div className="fixed z-99 inset-0">
          <div className="flex items-end sm:items-center justify-center h-full pb-0 pt-16 md:py-16 text-center overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded text-left overflow-hidden shadow-xl py-6 transform transition-all sm:my-8 w-full lg:w-5/6 flex flex-col h-full">
                <div className="flex justify-between items-center px-4 md:px-6 pb-4 md:pb-6 border-b border-b-border-color">
                  <div className="text-color-text-1 font-bold text-xl">
                    {title}
                  </div>
                  <CloseIcon
                    className="text-[#9CA3AF] cursor-pointer rounded-full hover:text-primary-color"
                    size={20}
                    onClick={handleCancel}
                  />
                </div>
                <div className="px-4 md:px-6 overflow-hidden flex-1 flex flex-col">
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DetailsModal;
