import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectedIcon from './store-settings-icons/selected-icon';
import NoRecordIcon from './store-settings-icons/no-record-icon';
import Button from '@/components/common/button';

interface IBankAccountListProps {
  onChange?: (value: any) => void;
  data: any[];
  select?: string;
  handleClick?: () => void;
}

const BankAccountList: React.FC<IBankAccountListProps> = (props) => {
  const { onChange, data = [], select = '', handleClick } = props;
  const { t } = useTranslation(["settings", "business_account_settings", "account_balance","related_company_accounts"]);
  const [selectKeys, setSelectKeys] = useState(select);

  const handleSelect = (item: any) => {
    setSelectKeys(item.id);
    if (onChange) {
      onChange(item);
    }
  };

  const handleBtn = () => {
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <>
      {data.length > 0 && (
        <div className="max-h-[80vh] flex flex-col min-h-[288px]">
          <div className="font-bold text-color-text-1 text-xl text-center">
            {t('settings:choose_verified_bank')}
          </div>
          <div className="mt-2 text-sm text-color-text-1 text-center font-medium">
            {t('settings:choose_verified_tip')}
          </div>
          <div className={`mt-8 overflow-y-scroll hidden_scrollbar`}>
            {data.map((item: any, index) => {
              return (
                <div
                  className="justify-between flex cursor-pointer mb-6 items-center"
                  onClick={() => handleSelect(item)}
                  key={item.id}
                >
                  <div>
                    <div className="text-sm text-color-text-2 font-medium">
                      {item.bankCode} {item.bankName}
                    </div>
                    <div className="text-xl text-color-text-1 font-bold">
                      {item.accountNumber}
                    </div>
                  </div>
                  {selectKeys === item.id && (
                    <SelectedIcon size={24} className="text-primary-color" />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {data.length === 0 && (
        <div className="flex flex-col h-72">
          <div className="font-bold text-color-text-1 text-xl text-center">
            {t('settings:choose_verified_bank')}
          </div>
          <div className="mt-10 text-center">
            <div className="flex justify-center items-center">
              <NoRecordIcon className="text-primary-color" />
            </div>
            <div className="text-xl font-bold mt-4 text-color-text-1">
              {t('settings:no_verified_account_yet')}
            </div>
            <div className="text-sm font-medium mt-4 text-color-text-2">
              {t('settings:new_bank_button')}
            </div>
            <Button className="w-full mt-16" onClick={handleBtn}>
              {t('settings:add_new_bank')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default BankAccountList;
