import { Input } from 'antd';
import type { IAntdInputProps } from './interface';
import { useMemo } from 'react';
import CleanIcon from '@/components/svg/clean-icon';

const AntdInput: React.FC<IAntdInputProps> = (props) => {
  const { onChange, allowClear = true, disabled, value, error } = props;
  const clear = useMemo(() => {
    if (allowClear) {
      return {
        clearIcon: (
          <CleanIcon
            size={20}
            className="cursor-pointer text-color-text-5 hover:text-color-text-3"
          />
        ),
      };
    }
    return false;
  }, [allowClear]);
  if (disabled) {
    return (
      <div className="h-10 pr-3 py-2 cursor-auto text-ellipsis text-color-text-1 text-sm font-medium">
        {value || '--'}
      </div>
    );
  }
  return (
    <>
      <Input
        {...props}
        allowClear={clear}
        size="large"
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
        className="rounded hover:border-primary-color shadow-box-drop border-border-color text-color-text-1 text-sm px-3 py-2 h-10 font-medium"
      />
      {error && (
        <div className="mt-1 text-sm text-error-color">{error.message}</div>
      )}
    </>
  );
};

export default AntdInput;
