import TerminalInput from '@/components/common/terminal-input';
import Label from '@/components/common/label';
import { IAreaCodes } from '@/types/common-types';
import { useForm, Controller } from 'react-hook-form';
import type { IObject } from '@/types/common-types';
import { useEffect, useState } from 'react';
import CleanIcon from '@/components/svg/clean-icon';
import { insetHead, triggerWonderRoute } from '../../../utils/terminal-untils';
import PostionIcon from '../terminal-icons/address-postion-icon';
import { useTranslation } from 'react-i18next';
import {
  updateAddressRecord,
  createAddressRecord,
  deleteAddressRecord,
} from '../../../data/terminal';
import type {
  IAddressObject,
  ITerminalOrdersCheckPageProps,
} from '../../../types/terminal-type';
import { queryGoogleService } from '../../../data/terminal';
import debounce from 'lodash.debounce';
import { useLocation, useParams } from 'react-router-dom';
import NetworkError from '../components/network-error';

interface IOptionParam {
  value: string;
  label: string;
  details: string;
}
interface IShopAddressProps extends ITerminalOrdersCheckPageProps {
  code: string;
  currency: string;
  areaCodes: IAreaCodes[];
  token: string;
  customerID: string;
  dataTimeOut: boolean;
  addressList: IAddressObject[];
  selectedAddress?: IAddressObject;
  loadAddress: () => void;
}

const ShopAddress: React.FC<IShopAddressProps> = (props) => {
  const {
    businessID,
    token,
    customerID,
    addressList,
    areaCodes,
    dataTimeOut,
    tokenExpire = false,
    loadAddress,
  } = props;
  const { address_Id } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const [showAddress, setshowAddress] = useState(false);
  const [phoneValue, setPhoneValue] = useState('');
  const [countryCode, setcountryCode] = useState('');
  const [areaCode, setAreaCode] = useState('');
  const [districtValue, setDistrictValue] = useState('');
  const [currentDistrict, setCurrentDistrict] = useState('');
  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const addressId = params.get('addressId');
  // const address_Id = params.get('address_Id'); 
  const [selectAdressItem, setSelectAdressItem] = useState<any>();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [street, setStreet] = useState('');
  const [lat, setlat] = useState('');
  const [lng, setlng] = useState('');
  const [options, setOptions] = useState<IOptionParam[]>([]);

  useEffect(() => {
    if (tokenExpire) {
      triggerWonderRoute('true', 'is401');
    }
    if (addressId && addressList.length > 0) {
      addressList.forEach((item: any) => {
        if (item.id === addressId) {
          setSelectAdressItem(item);
          const data = {
            code: item.phone.split(' ')[0],
            district: item.district,
          };
          const parts = item.phone.split(' ');
          const phoneNumber = parts.slice(1).join('');
          triggerWonderRoute(JSON.stringify(data), 'default_value');
          setName(item.name);
          setPhoneValue(phoneNumber);
          setValue('phone', phoneNumber);
          setAddress(item.address);
          setStreet(item.street);
          setcountryCode(newGetCountryByCurrency(parts[0].replace('+', '')));
          setDistrictValue(item.district);
          setAreaCode(item.phone.split(' ')[0]);
          setlat(item.lat);
          setlng(item.lng);
        }
      });
    }

    if (address_Id === 'is_address_add') {
      setcountryCode('hk');
      setAreaCode('+852');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressList]);

  const newGetCountryByCurrency = (currency: string) => {
    let countryCode = '';
    for (let i = 0; i < areaCodes.length; i++) {
      const item = areaCodes[i];
      if (item.phoneCountryCode === currency) {
        countryCode = item.isoAlpha2Code;
        break;
      }
    }
    return countryCode.toLocaleLowerCase();
  };

  useEffect(() => {
    window.addEventListener(
      'myCustomEvent',
      (e: any) => {
        if (e.detail.name === 'phone_number') {
          setcountryCode(e.detail.country);
          setAreaCode(e.detail.code);
        }
        if (e.detail.name === 'district') {
          setDistrictValue(e.detail.district);
          setValue('district', e.detail.district);
          setCurrentDistrict(e.detail.currentDistrict);
        }

        if (e.detail.remove_address === 'true') {
          if (selectAdressItem) {
            removeAdress();
          }
        }
        if (e.detail.is_address_back === 'true') {
          setshowAddress(false);
        }
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAdressItem]);

  const { control, getValues, setValue } = useForm({
    mode: 'all',
  });

  const handleSearch = async (value: string) => {
    setSearchValue(value);
    const { predictions = [] } = await queryGoogleService({
      businessID,
      token,
      query: `region=HK&input=${value}`,
      action: 'autocomplete',
    });
    const options: IOptionParam[] = [];
    predictions.forEach((item: IObject) => {
      const { structured_formatting = {}, description = '', place_id } = item;
      const { main_text = '' } = structured_formatting;
      const newItem = {
        value: place_id,
        label: main_text,
        details: description,
      };
      options.push(newItem);
    });
    setOptions(options);
  };

  const handleAddressItemClick = async (value: any) => {
    setAddress(value.details);
    setValue('address', value.details);
    const records = options.filter((item) => item.value === value.value);
    if (records.length > 0) {
      const id = records[0].value;
      const { result = {} } = await queryGoogleService({
        businessID,
        token,
        query: `place_id=${id}`,
        action: 'details',
      });
      const { location = {} } = result.geometry || {};
      const { lat, lng } = location;
      setlat(lat);
      setlng(lng);
      triggerWonderRoute('false', 'is_address');
      setshowAddress(false);
    }
  };
  const openShowAddress = () => {
    triggerWonderRoute('true', 'is_address');
    setshowAddress(true);
  };

  const setNumberCode = () => {
    triggerWonderRoute('phone_number', 'bootom_sheet');
  };

  const setDistrict = () => {
    triggerWonderRoute('district', 'bootom_sheet');
  };

  const removeAdress = async () => {
    triggerWonderRoute('show', 'loading');
    if (selectAdressItem) {
      const result = await deleteAddressRecord(
        businessID,
        token,
        selectAdressItem.id
      );
      if (result) {
        triggerWonderRoute('remove_address_success', 'option_result');
      } else {
        triggerWonderRoute('remove_address_fail', 'option_result');
      }
    }
  };

  const remove = () => {
    triggerWonderRoute('remove_address', 'confirm');
  };

  const save = debounce(() => {
    triggerWonderRoute('show', 'loading');
    setValue('name', name);
    setValue('phone', phoneValue);
    setValue('address', address);
    setValue('street', street);
    setValue('district', districtValue);
    setValue('country', '443');
    confirmAddress();
  }, 300);

  const confirmAddress = async () => {
    let currentRecord: any = getValues();
    currentRecord = {
      ...currentRecord,
      lat: lat,
      lng: lng,
    };
    if (address_Id === 'is_address_edit') {
      if (selectAdressItem) {
        for (const key in currentRecord) {
          if (selectAdressItem.hasOwnProperty(key)) {
            selectAdressItem[key] = currentRecord[key];
          }
        }
      }
      selectAdressItem.phone = areaCode + ' ' + phoneValue;
      const result = await handleUpdateRecord(selectAdressItem);
      if (result?.id) {
        triggerWonderRoute('address_editsave_success', 'option_result');
      } else {
        const data = {
          status: 'address_editsave_fail',
          msg: result?.error,
        };
        triggerWonderRoute(JSON.stringify(data), 'option_result');
      }
    } else {
      const newRecord = {
        ...currentRecord,
        country: '334',
        province: 'Hong Kong',
        customer: customerID,
        city: 'Hong Kong',
      };
      newRecord.phone = areaCode + ' ' + phoneValue;
      const result = await createAddressRecord(businessID, token, newRecord);
      if (result.error) {
        const data = {
          status: 'address_save_fail',
          msg: result.error,
        };
        triggerWonderRoute(JSON.stringify(data), 'option_result');
      } else {
        triggerWonderRoute('address_save_success', 'option_result');
      }
    }
  };

  const handleUpdateRecord = async (record: IAddressObject) => {
    if (record && record.id) {
      const newRecord = {
        ...record,
      };
      const result = await updateAddressRecord(businessID, token, newRecord);
      return result;
    }
  };

  const handleHightlight = (value: string) => {
    let returnValue = value;
    if (value && searchValue) {
      const index = value
        .toLocaleLowerCase()
        .indexOf(searchValue.toLocaleLowerCase());
      const hightStr = value.slice(index, index + searchValue.length);
      returnValue = value.replace(
        hightStr,
        `<span style="color: #10B981">${hightStr}</span>`
      );
    }
    return returnValue;
  };


  return (
    <>
      {insetHead()}
      <div
        className={`bg-primary-background-color min-h-[100vh] w-full flex flex-col  hidden_scrollbar overflow-x-hidden overflow-y-auto`}
      >
        {!dataTimeOut && (
          <div className="flex-1 py-5 pb-40 bg-[#F5F5F5]  relative min-h-[92vh]">
            <div className="bg-[#F5F5F5] ">
              <form>
                <div className="px-4 lg:px-0">
                  <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
                    <div>
                      <Label
                        value={t('extended_service:receiver_name')}
                        className="!text-[#8E8E93] !text-base !font-normal"
                        required
                      />
                      <Controller
                        name={'name'}
                        control={control}
                        rules={{
                          required: t('common:no_data_hint'),
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TerminalInput
                            onChange={(data) => {
                              onChange(data);
                              setName(data);
                            }}
                            value={name}
                            warpClass={`!shadow-none !font-normal bg-primary-background-color h-[54px] rounded-2xl ${error ? '!border-error-color' : '!border-[white] '
                              } `}
                            error={error}
                            // isBold={true}
                            placeholder={t(
                              'extended_service:enter_receiver_name'
                            )}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <Label
                        value={t('extended_service:phone_number')}
                        className="!text-[#8E8E93] !text-base !font-normal"
                        required
                      />
                      <Controller
                        name={'phone'}
                        control={control}
                        rules={{
                          required: t('common:no_data_hint'),
                          validate: (value) => {
                            const phoneNumber = value.substring(
                              value.indexOf(' ') + 1
                            );
                            const msg = t('common:no_data_hint');
                            if (!phoneNumber) return msg;
                            if (value && typeof value === 'string') {
                              const newValue = value.substring(
                                value.indexOf(' ') + 1
                              );
                              if (newValue.length > 11) {
                                return t('extended_service:phone_number_error');
                              }
                              return newValue ? undefined : msg;
                            }
                          },
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <div
                              className={`flex items-center bg-primary-background-color rounded-2xl h-[54px] px-2 border hover:border-primary-color focus:border-primary-color ${error && error.message
                                  ? 'border-error-color'
                                  : 'border-[white]'
                                } `}
                            >
                              <div
                                className="flex items-center"
                                onClick={() => setNumberCode()}
                              >
                                <span className="w-5 flex items-center">
                                  <img
                                    // layout="intrinsic"
                                    width="20px"
                                    height="20px"
                                    src={`/images/country-flag/${countryCode.toLocaleLowerCase()}.svg`}
                                    alt=""
                                  />
                                </span>
                                <span className="mx-2 font-normal">
                                  {areaCode}
                                </span>
                                <span>
                                  <svg
                                    width="9"
                                    height="7"
                                    viewBox="0 0 9 7"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.8"
                                      d="M7.60876 1.51728C7.60876 1.6034 7.58005 1.69041 7.52264 1.76307L4.46464 5.56472C4.39018 5.65712 4.27805 5.71094 4.15875 5.71094C4.04034 5.71094 3.92731 5.65712 3.85375 5.56472L0.811898 1.77922C0.676445 1.61058 0.703356 1.36389 0.871999 1.22844C1.04064 1.09298 1.28733 1.1199 1.42278 1.28854L4.15875 4.6937L6.91176 1.2715C7.04721 1.10285 7.2939 1.07594 7.46254 1.21139C7.55852 1.28944 7.60876 1.40246 7.60876 1.51728Z"
                                      fill="#0094FF"
                                      stroke="#0094FF"
                                    />
                                  </svg>
                                </span>
                              </div>
                              <input
                                type="tel"
                                defaultValue=""
                                id="input-id"
                                value={value}
                                style={{
                                  WebkitAppearance: 'none',
                                  WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                                }}
                                placeholder={t(
                                  'extended_service:enter_phone_number'
                                )}
                                onChange={(e) => {
                                  if (!isNaN(Number(e.target.value))) {
                                    onChange(e.target.value);
                                    setPhoneValue(e.target.value);
                                  }
                                }}
                                className="border-none outline-none w-full h-9 pl-2  focus:outline-none flex-grow font-normal text-[black] bg-inherit
                              placeholder:!text-[#8E8E93] placeholder:!font-normal placeholder:!text-base text-base pr-2"
                              />
                            </div>
                            <div className="mt-1 text-sm text-error-color">
                              {error?.message}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <Label
                    value={t('extended_service:address_1')}
                    className="!text-[#8E8E93] !text-base !font-normal mt-4"
                    required
                  />
                  <Controller
                    name={'address'}
                    control={control}
                    rules={{
                      required: t('common:no_data_hint'),
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <div
                          className={` relative flex items-center justify-between bg-primary-background-color rounded-2xl h-[54px] px-2 border  ${error?.message
                              ? '!border-error-color'
                              : '!border-[white]'
                            }`}
                          onClick={openShowAddress}
                        >
                          <div className="flex items-center w-[94%] overflow-hidden">
                            <span className="truncate ml-2  font-normal text-[black]">
                              {address}
                            </span>
                          </div>
                          {!address && (
                            <div className="absolute top-[14] left-[14px]   text-[#8E8E93] text-base">
                              {t('extended_service:enter_address')}
                            </div>
                          )}
                        </div>
                        <div className="mt-1 text-sm text-error-color">
                          {error?.message}
                        </div>
                      </>
                    )}
                  />
                  <Label
                    value={t('extended_service:address_details')}
                    className="!text-[#8E8E93] !text-base !font-normal mt-4"
                    required
                  />
                  <Controller
                    name={'street'}
                    control={control}
                    rules={{
                      required: t('common:no_data_hint'),
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TerminalInput
                        onChange={(data) => {
                          onChange(data);
                          setStreet(data);
                        }}
                        value={street}
                        warpClass={`!shadow-none bg-primary-background-color h-[54px] rounded-2xl  !font-normal ${error ? '!border-error-color' : '!border-[white]'
                          }`}
                        error={error}
                        placeholder={t('extended_service:enter_address_details')}
                      />
                    )}
                  />
                  <div>
                    <Label
                      value={t('extended_service:district')}
                      className="!text-[#8E8E93] !text-base !font-normal mt-4"
                      required
                    />
                    <Controller
                      name={'district'}
                      control={control}
                      rules={{
                        required: t('common:no_data_hint'),
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <div
                            className={`relative flex items-center justify-between bg-primary-background-color rounded-2xl h-[54px] px-2 border  ${error?.message
                                ? '!border-error-color'
                                : '!border-[white]'
                              }`}
                            onClick={() => setDistrict()}
                          >
                            <div className="flex items-center">
                              <span className="truncate ml-2 font-normal text-[black]">
                                {currentDistrict
                                  ? currentDistrict
                                  : districtValue}
                              </span>
                            </div>
                            <svg
                              width="9"
                              height="7"
                              viewBox="0 0 9 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="absolute right-[16px] top-[22px]"
                            >
                              <path
                                opacity="0.8"
                                d="M7.60876 1.51728C7.60876 1.6034 7.58005 1.69041 7.52264 1.76307L4.46464 5.56472C4.39018 5.65712 4.27805 5.71094 4.15875 5.71094C4.04034 5.71094 3.92731 5.65712 3.85375 5.56472L0.811898 1.77922C0.676445 1.61058 0.703356 1.36389 0.871999 1.22844C1.04064 1.09298 1.28733 1.1199 1.42278 1.28854L4.15875 4.6937L6.91176 1.2715C7.04721 1.10285 7.2939 1.07594 7.46254 1.21139C7.55852 1.28944 7.60876 1.40246 7.60876 1.51728Z"
                                fill="#0094FF"
                                stroke="#0094FF"
                              />
                            </svg>
                            {!districtValue && (
                              <div className="absolute top-[14] left-[14px]  text-[#8E8E93] text-base">
                                {t('extended_service:select_district')}
                              </div>
                            )}
                          </div>
                          <div className="mt-1 text-sm text-error-color">
                            {error?.message}
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div>
                    <Label
                      value={t('extended_service:country')}
                      className="!text-[#8E8E93] !text-base !font-normal mt-4"
                      required
                    />
                    <Controller
                      name={'country'}
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <div className="flex items-center justify-between bg-[#E7F1FB] rounded-2xl h-[54px] px-2 ">
                            <div className="flex items-center">
                              <img
                                // layout="intrinsic"
                                width="20px"
                                height="20px"
                                src={`/images/country-flag/hk.svg`}
                                alt=""
                              />
                              <span className="truncate ml-2 text-base !font-normal">
                                {t('extended_service:hong_kong')}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  {/* </div> */}
                </div>
                <div className="h-1 border-t-[1px] border-[#F5F5F5] my-4" />
                {!showAddress && (
                  <>
                    <div className="fixed bottom-0 w-[100%] bg-primary-background-color py-[22px]">
                      {address_Id === 'is_address_edit' && (
                        <>
                          <div className="items-center px-4  w-full">
                            {(!name ||
                              !phoneValue ||
                              !address ||
                              !street ||
                              !districtValue) && (
                                <>
                                  <div className="text-center w-full !leading-[48px] h-12 rounded-xl font-sfPro text-white hover:text-color-text-6 opacity-50 bg-[#0094FF] !text-base !font-semibold">
                                    {t('common:save')}
                                  </div>
                                  <div
                                    className="text-center px-4  pt-4 text-[#FC2E01] pb-[14px] !text-base !font-semibold"
                                    onClick={remove}
                                  >
                                    {t('common:remove')}
                                  </div>
                                </>
                              )}
                            {name &&
                              phoneValue &&
                              address &&
                              street &&
                              districtValue && (
                                <>
                                  <div
                                    className="text-center w-full !leading-[48px] h-12 rounded-xl font-sfPro text-white hover:text-color-text-6  bg-[#0094FF] !text-base !font-semibold"
                                    onClick={save}
                                  >
                                    {t('common:save')}
                                  </div>
                                  <div
                                    className="text-center px-4  pt-4 text-[#FC2E01] pb-[14px] !text-base !font-semibold"
                                    onClick={remove}
                                  >
                                    {t('common:remove')}
                                  </div>
                                </>
                              )}
                          </div>
                        </>
                      )}

                      {address_Id === 'is_address_add' && (
                        <>
                          <div className="flex  items-center px-4  w-full pb-[2px]">
                            {(!name ||
                              !phoneValue ||
                              !address ||
                              !street ||
                              !districtValue) && (
                                <div className="text-center w-full leading-[48px] h-12 bg-[#0094FF] rounded-xl font-sfPro text-white hover:text-color-text-6 opacity-50  text-[16px] !font-semibold">
                                  {t('common:save')}
                                </div>
                              )}
                            {name &&
                              phoneValue &&
                              address &&
                              street &&
                              districtValue && (
                                <div
                                  className="w-full h-12 rounded-xl font-sfPro bg-[#0094FF] !shadow-none btn text-center leading-[48px] font-semibold text-base text-[white] duration-300"
                                  onClick={save}
                                >
                                  {t('common:save')}
                                </div>
                              )}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        )}

        {dataTimeOut && (
          <NetworkError refresh={() => loadAddress()} />
        )}
      </div>
      {showAddress && (
        <div
          className={`absolute top-0 left-0 w-[100%] bg-[#F5F5F5] min-h-[100vh] `}
        >
          <div className="text-lg mt-2  text-[#8e8e93] pl-4">
            {t('extended_service:address_1')}
            <span className="text-[red]">*</span>
          </div>
          <div className=" rounded-xl pl-4 pr-4 pb-4">
            <div className="font-sfPro lg:font-inter ">
              <div className="rounded-xl overflow-hidden relative  flex items-center bg-white h-[54px] ">
                <input
                  type="text"
                  value={searchValue}
                  style={{
                    WebkitAppearance: 'none',
                    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                  }}
                  placeholder={t('common:search')}
                  onChange={(e) => handleSearch(e.target.value)}
                  className="border-none outline-none w-full h-9 pl-8 bg-inherit placeholder:!text-[#8E8E93] placeholder:!font-normal  placeholder:!text-base"
                />
                {searchValue && (
                  <CleanIcon
                    onClick={() => {
                      setSearchValue('');
                    }}
                    className="absolute right-2 top-2  text-disabled-color hover:text-[#0174FC]"
                  />
                )}
              </div>
              {searchValue && (
                <div
                  className={` overflow-y-auto hidden_scrollbar mt-2 bg-white min-h-[87vh] rounded-xl`}
                >
                  {options.map((item) => (
                    <div
                      key={item.value}
                      className="flex items-center justify-between mt-8 relative"
                      onClick={() => handleAddressItemClick(item)}
                    >
                      <div className="flex items-cente">
                        <div className="flex items-center relative w-[36px]  ml-6">
                          <PostionIcon />
                        </div>
                        <div className="flex flex-col w-[271px]">
                          <div
                            className="truncate text-[#000000]"
                            dangerouslySetInnerHTML={{
                              __html: handleHightlight(item.label),
                            }}
                          ></div>
                          <div className="text-[#8E8E93]">{item.details}</div>
                        </div>
                      </div>
                      <div className="bg-[#F5F5F5] h-[1px] absolute left-[58px] bottom-[-12px] w-[271px]"></div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShopAddress;
