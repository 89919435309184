import { useMemo } from 'react';
import PaginationItem from './pagination-item';
import UpIcon from '@/components/svg/up-icon';
import DownIcon from '@/components/svg/down-icon';
import { useTranslation } from 'react-i18next';
import Select from '../common/select';

export interface IPaginationProps {
  current: number; // 当前页码
  currentSize: number; // 当前页数据量
  pageSize: number; // 一页显示的数量
  changePageCallback: (page: number, pageSize: number) => void;
  pageSizeOptions?: number[]; // 一页显示的选项
  className?: string;
  total?: number;
}

interface ISizeOption {
  label: string;
  value: string;
}

const Pagination: React.FC<IPaginationProps> = (props) => {
  const {
    current = 1,
    pageSize = 50,
    currentSize = 50,
    className,
    pageSizeOptions = [50, 100, 200],
    changePageCallback,
  } = props;

  const haveMore = currentSize > pageSize;
  const { t } = useTranslation();

  const sizeOptions = useMemo(() => {
    const sizeOptions: ISizeOption[] = [];
    pageSizeOptions.forEach((item) => {
      sizeOptions.push({
        label: item.toString(),
        value: item.toString(),
      });
    });
    return sizeOptions;
  }, [pageSizeOptions]);

  //向前翻一页
  const prevPage = () => {
    if (current === 1) {
      return;
    }
    changePageCallback && changePageCallback(current - 1, pageSize);
  };

  //向后翻一页
  const nextPage = () => {
    if (!haveMore) {
      return;
    }
    changePageCallback && changePageCallback(current + 1, pageSize);
  };

  const pageSizeChange = (sizePage: number) => {
    changePageCallback && changePageCallback(1, sizePage);
  };

  const changePage = (pageNum: number) => {
    return () => {
      //小型分页器
      changePageCallback && changePageCallback(pageNum, pageSize);
    };
  };

  return (
    <div className={`flex items-center justify-between ${className}`}>
      <div className="flex-1 flex justify-between sm:hidden">
        <PaginationItem className="rounded-md w-auto px-3" onClick={prevPage}>
          Previous
        </PaginationItem>
        <PaginationItem className="rounded-md w-auto px-3" onClick={nextPage}>
          Next
        </PaginationItem>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-end">
        <div className="font-semibold text-xs leading-5 text-color-text-3 pr-2.5 uppercase">
          {t('common:view')}
        </div>
        <div className="w-auto pr-[30px]">
        <Select
          value={pageSize.toString()}
          position={'bottom'}
          options={sizeOptions}
          onChange={(value) => pageSizeChange(parseInt(value))}
          className="!py-1.5 !px-2.5"
          height="h-8"
        />
        </div>
        <div
          className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          {current !== 1 && (
            <PaginationItem className="rounded-l-md " onClick={prevPage}>
              <DownIcon className="text-icon-color shrink-0 ml-1 fill-current hover:text-primary-color rotate-90" />
            </PaginationItem>
          )}

          {current - 1 > 0 && (
            <PaginationItem onClick={changePage(current - 1)}>
              {current - 1}
            </PaginationItem>
          )}

          <PaginationItem
            className={`${current === 1 ? 'rounded-l-md ' : ''}${
              !haveMore && 'rounded-r-md '
            }`}
            actived={true}
            onClick={changePage(current)}
          >
            {current}
          </PaginationItem>

          {haveMore && (
            <PaginationItem onClick={changePage(current + 1)}>
              {current + 1}
            </PaginationItem>
          )}
          {haveMore && (
            <PaginationItem
              className={`rounded-r-md ${
                !haveMore && 'cursor-not-allowed bg-gray-50'
              }`}
              onClick={nextPage}
            >
              <UpIcon className="text-icon-color shrink-0 ml-1 fill-current hover:text-primary-color rotate-90" />
            </PaginationItem>
          )}
        </div>
      </div>
    </div>
  );
};
export default Pagination;
