interface IPieChartSkeletonProps {
  number?: number;
  wrapperClass?: string;
}

const PieChartSkeleton: React.FC<IPieChartSkeletonProps> = (props) => {
  const { wrapperClass = '', number = 248 } = props;

  return (
    <div className={`${wrapperClass} flex-1 flex justify-center items-center`}>
      <div
        style={{
          width: `${number}px`,
          height: `${number}px`,
        }}
        className={`skeleton_base rounded-full bg-gradient-to-r from-system-background-color to-system-background-color/0`}
      />
    </div>
  );
};

export default PieChartSkeleton;
