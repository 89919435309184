import { Constants } from '@/constants';
import { IObject } from '@/types/common-types';
import { i18nTransform } from '@/types/language-type';
import Cookies from 'js-cookie';
import trim from 'lodash/trim';

export const verifyEmail = (value: string) => {
  const reg = RegExp(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);
  if (reg.test(value)) {
    return true;
  }
  return false;
};

export const cleanUpCookie = () => {
  Cookies.remove(Constants.TOKEN);
};

export const getCookie = (name: string) => {
  return Cookies.get(name);
}

export const setCookie = (name: string, value: string) => {
  Cookies.set(name, value);
};

export const deleteCookie = (name: string) => {
  Cookies.remove(name);
}

export const getAvatarsName = (text: string) => {
  if (text) {
    const value = trim(text.replace(/[^\u4e00-\u9fa50-9A-Za-z ]/g, ''));
    if (value) {
      const tArray = value.split(/\s+/);
      const first = tArray[0].toLocaleUpperCase();
      const end = tArray[tArray.length - 1].toLocaleUpperCase();

      if (tArray.length === 1) {
        if (first.charCodeAt(0) > 255) {
          return first.substring(first.length > 1 ? first.length - 2 : 0);
        }
        return first.charAt(0);
      }

      return `${first.charAt(0)}${end.charAt(0)}`;
    }
  }
};

export const getCurrentI18nValue = (
  record: IObject,
  valueKey: string,
  lang: string,
  key = 'translations',
  langKey = 'languages_code'
) => {
  let cuurentValue = record[valueKey] || '';
  const languageCode = i18nTransform[lang];
  const i18nArr = record[key];
  if (i18nArr && Array.isArray(i18nArr)) {
    const i18Obj: IObject | undefined = findNode(
      i18nArr,
      langKey,
      languageCode
    );
    if (i18Obj) {
      const i18Value = i18Obj[valueKey];
      cuurentValue = i18Value || cuurentValue;
    }
  }

  return cuurentValue;
};

export const insertComma = (
  str: string,
  isReverse = true,
  digit = 3,
  symbol = ',',
  need = true
) => {
  if (str.length < digit || !need) {
    return str;
  }
  const arr = [];
  const flag = true;
  let temp = '';
  let startIndex = digit;
  if (isReverse) {
    startIndex = -digit;
  }
  let endIndex = 0;
  while (flag) {
    if (endIndex === 0) {
      temp = isReverse ? str.slice(startIndex) : str.slice(0, startIndex);
    } else {
      temp = isReverse
        ? str.slice(startIndex, endIndex)
        : str.slice(endIndex, startIndex);
    }
    if (temp !== '') {
      arr.push(temp);
    }
    if (temp.length === digit) {
      endIndex = startIndex;
      if (isReverse) {
        startIndex += -digit;
      } else {
        startIndex += digit;
      }
    } else {
      break;
    }
  }
  return isReverse ? arr.reverse().join(symbol) : arr.join(symbol);
};

export function findNode<T>(
  list: any[] = [],
  key: string,
  value: any,
  childrenKey = 'children'
): T | undefined {
  if (!(typeof key === 'string') || !Array.isArray(list)) {
    return undefined;
  }

  for (let i = 0; i < list.length; i++) {
    const node = list[i];
    // eslint-disable-next-line
    if (node[key] === value) {
      return node;
    } else if (node[childrenKey] && node[childrenKey].length > 0) {
      const tempNode = findNode<T>(node[childrenKey], key, value, childrenKey);
      if (tempNode) {
        return tempNode;
      }
    }
  }

  return undefined;
}

interface IAvatarColumnParams {
  key: string;
  record: any;
  boundField: string;
}
export const avatarColumn = (params: IAvatarColumnParams) => {
  const { boundField , record, key } = params;
  const value = record[key];
  let image: any = '';
  let text = '';
  if (boundField && record[boundField]) {
    text = record[boundField];
    image = (
      <span className="inline-flex items-center justify-center h-10 w-10 bg-primary-color/10 rounded-full">
        <span className="font-semibold text-color-text-1 text-xs leading-normal">
          {getAvatarsName(text)}
        </span>
      </span>
    );
  }
  if (value) {
    image = (
      <img
        src={value}
        width="56px"
        height="56px"
        className="rounded-full"
        alt=""
      ></img>
    );
  }
  return (
    <div className="truncate">
      {image}
      <label className="text-color-text-1 font-medium text-sm ml-1.5">
        {text}
      </label>
    </div>
  );
};

interface ISelectColumnParams {
  key: string;
  record: any;
  options: any[];
}
interface ISelectOption {
  value: string;
  label: string;
  tip?: string;
  color?: string;
}
export const selectColumn = (params: ISelectColumnParams) => {
  const { record, key, options } = params;
  const option: ISelectOption | undefined = findNode(
    options,
    'value',
    record[key]
  );
  const optionClass =
    option && option.color ? `${option.color} bg-opacity-10` : '';
  return (
    <span
      className={`px-3 py-1.5 rounded-3xl text-xs font-medium whitespace-nowrap ${optionClass}`}
    >
      {option && option.label}
    </span>
  );
};

export const getCurrencyIcon = (currency: string) => {
  if (currency.length === 3) {
    const icon = currency.toLowerCase().substring(0, 2);

    return (
      <span className="flex items-center relative w-5 mr-2">
        <img
          // layout="intrinsic"
          width="20px"
          height="20px"
          src={`/images/country-flag/${icon}.svg`}
          alt=""
        />
      </span>
    );
  }
  return null;
};

export const currencySymbols: any = {
  ALL: 'L',
  AED: 'د.إ',
  AFN: '؋',
  ARS: '$',
  AWG: 'ƒ',
  AUD: '$',
  AZN: '₼',
  BSD: '$',
  BBD: '$',
  BYR: 'p.',
  BZD: 'BZ$',
  BMD: '$',
  BOB: 'Bs.',
  BAM: 'KM',
  BWP: 'P',
  BGN: 'лв',
  BRL: 'R$',
  BND: '$',
  KHR: '៛',
  CAD: '$',
  KYD: '$',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  HRK: 'kn',
  CUP: '₱',
  CZK: 'Kč',
  DKK: 'kr',
  DOP: 'RD$',
  XCD: '$',
  EGP: '£',
  SVC: '$',
  EEK: 'kr',
  EUR: '€',
  FKP: '£',
  FJD: '$',
  GHC: '¢',
  GIP: '£',
  GTQ: 'Q',
  GGP: '£',
  GYD: '$',
  HNL: 'L',
  HKD: 'HK$',
  HUF: 'Ft',
  ISK: 'kr',
  INR: '₹',
  IDR: 'Rp',
  IRR: '﷼',
  IMP: '£',
  ILS: '₪',
  JMD: 'J$',
  JPY: '¥',
  JEP: '£',
  KES: 'KSh',
  KZT: 'лв',
  KPW: '₩',
  KRW: '₩',
  KGS: 'лв',
  LAK: '₭',
  LVL: 'Ls',
  LBP: '£',
  LRD: '$',
  LTL: 'Lt',
  MKD: 'ден',
  MYR: 'RM',
  MUR: '₨',
  MXN: '$',
  MNT: '₮',
  MZN: 'MT',
  NAD: '$',
  NPR: '₨',
  ANG: 'ƒ',
  NZD: '$',
  NIO: 'C$',
  NGN: '₦',
  NOK: 'kr',
  OMR: '﷼',
  PKR: '₨',
  PAB: 'B/.',
  PYG: 'Gs',
  PEN: 'S/.',
  PHP: '₱',
  PLN: 'zł',
  QAR: '﷼',
  RON: 'lei',
  RUB: '₽',
  SHP: '£',
  SAR: '﷼',
  RSD: 'Дин.',
  SCR: '₨',
  SGD: '$',
  SBD: '$',
  SOS: 'S',
  ZAR: 'R',
  LKR: '₨',
  SEK: 'kr',
  CHF: 'CHF',
  SRD: '$',
  SYP: '£',
  TZS: 'TSh',
  TWD: 'NT$',
  THB: '฿',
  TTD: 'TT$',
  TRY: 'TRY',
  TRL: '₤',
  TVD: '$',
  UGX: 'USh',
  UAH: '₴',
  GBP: '£',
  USD: '$',
  UYU: '$U',
  UZS: 'лв',
  VEF: 'Bs',
  VND: '₫',
  YER: '﷼',
  ZWD: 'Z$',
};