import { Constants } from "@/constants";
import { getSignatureUrl } from "@/data/onboarding";
import { ISignatureType } from "@/types/signature-type";
import { setCookie } from "@/utils";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";


const AgentSignPage = () => {
  const [searchParams] = useSearchParams();
  const { lang = 'en'} = useParams();
  const access_token = searchParams.get('access_token');

  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    if (access_token) {
      let callBackUrl = `http://localhost:3102/agent-onboarding/sign/succeeded?lang=${lang}&access_token=${access_token}`;
      let domain = window.location.hostname;
  
      if (domain !== 'localhost') {
        if (domain && domain.indexOf('https') === -1) {
          domain = `https://${domain}`;
        }
        callBackUrl = `${domain}/agent-onboarding/sign/succeeded?lang=${lang}&access_token=${access_token}`;
      };
  
      const { sign_page_url = '' } = await getSignatureUrl({
        callBackUrl,
        token: access_token,
        type: ISignatureType.PARTNERSHIP_AGREEMENT
      });
      setCookie(Constants.SIGN_URL, sign_page_url);
      setUrl(sign_page_url);
    }
  }

  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 flex bg-primary-background-color">
      {url && (
        // eslint-disable-next-line
        <iframe id="iframe" className="w-full h-full border-none" src={url} />
      )}
    </div>
  )
}

export default AgentSignPage;