import AccountWrapper from './account-wrapper';
import Label from '@/components/common/label';
import { Alert } from '@/components/common/alert/alert';
import { minus } from '@/utils/galaxy-utils';
import { useEffect, useState } from 'react';
import PhoneNumber from '@/components/common/phone-number';
import Authentication from './authentication';
import Button from '@/components/common/button';
import { b2cOtpSend } from '@/data/account';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import BackIcon from '@/components/svg/back-icon';
import { IAreaCodes } from '@/types/common-types';
import { loadAreaCodesData } from '@/data/area-codes';


let verificationTimout: any = null;

const ForgotPhonePage = () => {
  
  const { t } = useTranslation('signin');

  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [showVfc, setShowVfc] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [areaCodes, setAreaCodes] = useState<IAreaCodes[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (count === 0) {
      clearTimeout(verificationTimout);
      verificationTimout = null;
    } else {
      verificationTimout = setTimeout(() => {
        verificationTimout = null;
        setCount(minus(count, 1));
      }, 1000);
    }
    // eslint-disable-next-line
  }, [count]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [])

  const loadData = async () => {
    const codes = await loadAreaCodesData();
    setAreaCodes(codes)
  }

  const handleSendCode = async () => {
    if (!handleCheckValue(phone)) return;
    setLoading(true);
    if (count > 0) {
      setShowVfc(true);
      setLoading(false);
      return;
    }

    const sendData = await b2cOtpSend(
      {
        phone,
        email: '',
        action: 'pwdreset',
      },
    );

    if (sendData.code === 200) {
      setShowVfc(true);
      setCount(60);
      Alert.success({
        message: t('signin:verification_code_sent_successfully'),
        wrapClass: '-top-10',
      });
    } else if (sendData.message) {
      Alert.error({
        message: sendData.message,
        wrapClass: '-top-10',
      });
    }
    setLoading(false);
  };

  const handleCheckValue = (value: string) => {
    let result = false;
    const newValue = value.substring(value.indexOf(' ') + 1);
    const code = value.substring(0, value.indexOf(' '));
    if (!newValue || !code) {
      setError(t('signin:this_field_is_required'));
    } else {
      setError('');
      result = true;
    }
    setPhone(value);
    return result;
  };

  const handleBack = () => {
    if (showVfc) {
      setShowVfc(false);
    } else {
      navigate('/account/signin');
    }
  };

  return (
    <AccountWrapper className="flex flex-col">
      <div className="flex flex-col w-120 bg-color-text-6 p-15 md:rounded-[30px] md:mr-[10%] md:shadow-sign-in-card">
        {!showVfc && (
          <>
            <h1 className="text-3xl text-center font-bold mb-6 text-color-text-1">
              {t('signin:forgot_password')}! ✨
            </h1>
            <div className="text-sm text-center text-color-text-2">
              {t('signin:tell_us_phone_number')}
            </div>
            <div className="mt-15">
              <Label
                textClass="text-color-text-1"
                value={`${t('signin:phone_number')}`}
              >
                <Link
                  to={`/account/password/forgot?method=email`}
                >
                  <div className="whitespace-nowrap text-xs text-primary-color cursor-pointer px-1">
                    {t('signin:reset_with_email')}
                  </div>
                </Link>
              </Label>
              <PhoneNumber
                name="forgot"
                options={areaCodes}
                showSearch
                onChange={handleCheckValue}
                value={phone}
                wrapperClass="h-12 rounded-lg"
                placeholder={t('signin:phone_number')}
                error={{
                  type: 'required',
                  message: error,
                }}
              />
              <Button
                className="w-full mt-10 h-12 rounded-lg"
                loading={loading}
                onClick={handleSendCode}
              >
                {t('signin:send_verification_code')}
              </Button>
            </div>
          </>
        )}
        {showVfc && (
          <Authentication
            title={`${t('signin:forgot_password')}! ✨`}
            showTip={true}
            values={{
              phone,
            }}
            token={''}
            count={count}
            setCount={setCount}
            callback={(result) => {
              const { token = '' } = result;
              navigate(`/account/password/reset?token=${token}`);
            }}
            sendCodeType="pwdreset"
          />
        )}
      </div>
      <div
        onClick={handleBack}
        className="flex justify-center items-center text-center text-sm text-color-text-3 hover:text-primary-color cursor-pointer mt-15 md:mr-[10%]"
      >
        <BackIcon />
        <span className="ml-2">{t('signin:back')}</span>
      </div>
    </AccountWrapper>
  );
};

export default ForgotPhonePage;
