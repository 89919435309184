import React from 'react';
import type { IIconProps } from '@/types/common-types';

const SendIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 20 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M8.55078 13.2H3.30078C2.90296 13.2 2.52143 13.0419 2.24012 12.7606C1.95882 12.4793 1.80078 12.0978 1.80078 11.7V4.19995C1.80078 3.80213 1.95882 3.4206 2.24012 3.13929C2.52143 2.85799 2.90296 2.69995 3.30078 2.69995H13.8008C14.1986 2.69995 14.5801 2.85799 14.8614 3.13929C15.1427 3.4206 15.3008 3.80213 15.3008 4.19995V9.82495"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.80078 4.19946L8.55078 8.69946L15.3008 4.19946"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8008 13.1995H15.3008"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.0508 10.9495L15.3008 13.1995L13.0508 15.4495"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default SendIcon;
