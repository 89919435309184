import { Navigate, RouteObject } from "react-router-dom";
import EmailSendPage from "@/pages/accounts/email-send";
import GuidePage from "@/pages/accounts/guide";
import LoginPage from "@/pages/accounts/login";
import ForgotPassword from "@/pages/accounts/password/forgot";
import ResetPassword from "@/pages/accounts/password/reset";
import ResetPasswordSuccess from "@/pages/accounts/password/reset/successful";
import SuspensionZone from "@/pages/accounts/suspension-zone";
import OpenWonder from "@/pages/open-wonder";
import ScanInvalid from "@/pages/qrcode-links/scan-invalid";
import LoggedOutAlertPage from "@/pages/logged-out-alert";
import DownloadWonderApp from "@/pages/download-app";
import HelloSignPage from "@/pages/business-onboarding/sign/esignatures";
import SignSucceededPage from "@/pages/business-onboarding/sign/succeeded";
import MetaBaseLogin from "@/pages/metabase/login";
import FunderView from "@/pages/metabase/funder";
import HeycarsTaxiOrders from "@/pages/metabase/heycars-taxi-orders";
import DailyBreakdown from "@/pages/metabase/daily-breakdown";
import RealData from "@/pages/metabase/real-data";
import IndividualCommissionOverview from "@/pages/metabase/individual-commission-overview";
import WonderAboutUs from '@/pages/statements/wonder-app/about-us';
import WonderIssuingTerms from '@/pages/statements/wonder-app/issuing-terms';
import WonderPaymentTerms from '@/pages/statements/wonder-app/payment-terms';
import WonderPersonal from '@/pages/statements/wonder-app/personal-information-collection-statement';
import WonderPrivacy from '@/pages/statements/wonder-app/privacy-policy';
import WonderRemittance from '@/pages/statements/wonder-app/remittance-payout-terms';
import WonderTerms from '@/pages/statements/wonder-app/terms-and-condition';
import WonderEDDATermsAndConditions from '@/pages/statements/wonder-app/edda-terms-and-conditions';
import StatementsAboutUs from '@/pages/statements/wonder-app/about-us';
import StatementsIssuingTerms from '@/pages/statements/wonder-app/issuing-terms';
import StatementsPaymentTerms from '@/pages/statements/wonder-app/payment-terms';
import StatementsPersonal from '@/pages/statements/wonder-app/personal-information-collection-statement';
import StatementsPrivacy from '@/pages/statements/wonder-app/privacy-policy';
import StatementsRemittance from '@/pages/statements/wonder-app/remittance-payout-terms';
import StatementsTerms from '@/pages/statements/wonder-app/terms-and-condition';
import DriverAssistantPersonal from '@/pages/statements/driver-assistant/personal-information-collection-statement';
import DriverAssistantPrivacy from '@/pages/statements/driver-assistant/privacy-policy';
import StatementsEDDATermsAndConditions from '@/pages/statements/wonder-app/edda-terms-and-conditions';
import JekPersonal from '@/pages/statements/jek/personal-information-collection-statement';
import JekPrivacy from '@/pages/statements/jek/privacy-policy';
import JekTerms from '@/pages/statements/jek/terms-and-condition';
import JekPaymentRule from '@/pages/statements/jek/paymentRule';
import JekCancelRule from '@/pages/statements/jek/cancelRule';
import HktaxigoPersonal from '@/pages/statements/hktaxigo/personal-information-collection-statement';
import HktaxigoPrivacy from '@/pages/statements/hktaxigo/privacy-policy';
import HktaxigoTerms from '@/pages/statements/hktaxigo/terms-and-condition';
import HktaxigoPaymentRule from '@/pages/statements/hktaxigo/paymentRule';
import HktaxigoCancelRule from '@/pages/statements/hktaxigo/cancelRule';
import RunningtaxiPersonal from '@/pages/statements/runningtaxi/personal-information-collection-statement';
import RunningtaxiPrivacy from '@/pages/statements/runningtaxi/privacy-policy';
import RunningtaxiTerms from '@/pages/statements/runningtaxi/terms-and-condition';
import RunningtaxiPaymentRule from '@/pages/statements/runningtaxi/paymentRule';
import RunningtaxiCancelRule from '@/pages/statements/runningtaxi/cancelRule';
import { ReactNode, Suspense } from "react";
import DownloadWonderDA from "@/pages/download-da";
import AgentSignPage from "@/pages/agent-onboarding/sign/esignatures";
import SignedSucceededPage from "@/pages/agent-onboarding/sign/succeeded";
import PayQR from "@/pages/pay-qr";
import DownloadWonderJek from "@/pages/download-jek";
import DownloadHktaxigo from "@/pages/download-hktaxigo";
import DownloadRunningtaxi from "@/pages/download-runningtaxi";
import TajimayaReport from "@/pages/metabase/tajimaya-report";

const lazyComponent = (element: ReactNode): ReactNode => {
  return <Suspense fallback={<></>}> {element} </Suspense>
}


const routerWithoutToken: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/account/signin" />
  },
  {
    path: '/:lang?/account',
    children: [
      {
        path: 'signin',
        element: lazyComponent(<LoginPage />),
      },
      {
        path: 'email-send',
        element: lazyComponent(<EmailSendPage />),
      },
      {
        path: 'guide',
        element: lazyComponent(<GuidePage />),
      },
      {
        path: 'password/forgot',
        element: lazyComponent(<ForgotPassword />),
      },
      {
        path: 'password/reset',
        element: lazyComponent(<ResetPassword />),
      },
      {
        path: 'password/reset/successful',
        element: lazyComponent(<ResetPasswordSuccess />),
      },
      {
        path: 'suspension-zone',
        element: lazyComponent(<SuspensionZone />),
      },
    ]
  },
  {
    path: '/:lang?/download-da',
    element: lazyComponent(<DownloadWonderDA />),
  },
  {
    path: '/:lang?/download-jek',
    element: lazyComponent(<DownloadWonderJek />),
  },
  {
    path: '/:lang?/download-hktaxigo',
    element: lazyComponent(<DownloadHktaxigo />),
  },
  {
    path: '/:lang?/download-runningtaxi',
    element: lazyComponent(<DownloadRunningtaxi />),
  },
  {
    path: '/:lang?/download-app',
    element: lazyComponent(<DownloadWonderApp />),
  },
  {
    path: '/:lang?/open-wonder',
    element: lazyComponent(<OpenWonder />),
  },
  {
    path: '/:lang?/qrcode-links/:action',
    element: lazyComponent(<ScanInvalid />),
  },
  {
    path: '/:lang?/logged-out-alert',
    element: lazyComponent(<LoggedOutAlertPage />),
  },
  {
    path: '/:lang?/pay-qrcode',
    element: lazyComponent(<PayQR />),
  },
  {
    path: '/:lang?/business-onboarding/sign',
    children: [
      {
        path: 'esignatures',
        element: lazyComponent(<HelloSignPage />),
      },
      {
        path: 'succeeded',
        element: lazyComponent(<SignSucceededPage />),
      }
    ]
  },
  {
    path: '/:lang?/agent-onboarding/sign',
    children: [
      {
        path: 'esignatures',
        element: lazyComponent(<AgentSignPage />),
      },
      {
        path: 'succeeded',
        element: lazyComponent(<SignedSucceededPage />),
      }
    ]
  },
  {
    path: '/:lang?/agent-onboarding/sign',
    children: [
      {
        path: 'esignatures',
        // Component: HelloSignPage,
        element: <AgentSignPage />,
      },
      {
        path: 'succeeded',
        // Component: SignSucceededPage
        element: <SignedSucceededPage />,
      }
    ]
  },
  {
    path: '/:lang?/metabase/embedding',
    children: [
      {
        path: 'login',
        element: lazyComponent(<MetaBaseLogin />),
      },
      {
        path: 'funder',
        element: lazyComponent(<FunderView />),
      },
      {
        path: 'heycars-taxi-orders',
        element: lazyComponent(<HeycarsTaxiOrders />),
      },
      {
        path: 'daily-breakdown',
        element: lazyComponent(<DailyBreakdown />),
      },
      {
        path: 'real-data',
        element: lazyComponent(<RealData />),
      },
      {
        path: 'individual-commission-overview',
        element: lazyComponent(<IndividualCommissionOverview />),
      },
      {
        path: 'tajimaya-report',
        element: lazyComponent(<TajimayaReport />),
      }
    ]
  },
  {
    path: '/:lang?/wonder-app-content',
    children: [{
      path: 'about-us',
      element: lazyComponent(<WonderAboutUs />),
    }, {
      path: 'issuing-terms',
      element: lazyComponent(<WonderIssuingTerms />),
    }, {
      path: 'payment-terms',
      element: lazyComponent(<WonderPaymentTerms />),
    }, {
      path: 'personal-information-collection-statement',
      element: lazyComponent(<WonderPersonal />),
    }, {
      path: 'privacy-policy',
      element: lazyComponent(<WonderPrivacy />),
    }, {
      path: 'remittance-payout-terms',
      element: lazyComponent(<WonderRemittance />),
    }, {
      path: 'terms-and-condition',
      element: lazyComponent(<WonderTerms />),
    }]
  },
  {
    path: '/:lang?/statements',
    children: [{
      path: 'about-us',
      element: lazyComponent(<StatementsAboutUs />),
    }, {
      path: 'issuing-terms',
      element: lazyComponent(<StatementsIssuingTerms />),
    }, {
      path: 'payment-terms',
      element: lazyComponent(<StatementsPaymentTerms />),
    }, {
      path: 'personal-information-collection-statement',
      element: lazyComponent(<StatementsPersonal />),
    }, {
      path: 'privacy-policy',
      element: lazyComponent(<StatementsPrivacy />),
    }, {
      path: 'remittance-payout-terms',
      element: lazyComponent(<StatementsRemittance />),

    }, {
      path: 'terms-and-condition',
      element: lazyComponent(<StatementsTerms />),
    }, {
      path: 'edda-terms-and-conditions',
      element: lazyComponent(<StatementsEDDATermsAndConditions />),
    }]
  },
  {
    path: '/:lang?/driver-assistant',
    children: [{
      path: 'personal-information-collection-statement',
      element: lazyComponent(<DriverAssistantPersonal />),
    }, {
      path: 'privacy-policy',
      element: lazyComponent(<DriverAssistantPrivacy />),
    }]
  },
  {
    path: '/:lang?/jek',
    children: [{
      path: 'personal-information-collection-statement',
      element: lazyComponent(<JekPersonal />),
    }, {
      path: 'privacy-policy',
      element: lazyComponent(<JekPrivacy />),
    }, {
      path: 'terms-and-condition',
      element: lazyComponent(<JekTerms />),
    }, {
      path: 'paymentRule',
      element: lazyComponent(<JekPaymentRule />),
    }, {
      path: 'cancelRule',
      element: lazyComponent(<JekCancelRule />),
    }]
  },
  // 准备替换上面久路由
  {
    path: '/:lang?/statements',
    children: [
      {
        path: 'wonder-app',
        children: [{
          path: 'about-us',
          element: lazyComponent(<WonderAboutUs />),
        }, {
          path: 'issuing-terms',
          element: lazyComponent(<WonderIssuingTerms />),
        }, {
          path: 'payment-terms',
          element: lazyComponent(<WonderPaymentTerms />),
        }, {
          path: 'personal-information-collection-statement',
          element: lazyComponent(<WonderPersonal />),
        }, {
          path: 'privacy-policy',
          element: lazyComponent(<WonderPrivacy />),
        }, {
          path: 'remittance-payout-terms',
          element: lazyComponent(<WonderRemittance />),
        }, {
          path: 'terms-and-condition',
          element: lazyComponent(<WonderTerms />),
        }, {
          path: 'edda-terms-and-conditions',
          element: lazyComponent(<WonderEDDATermsAndConditions />),
        }]
      },
      {
        path: 'driver-assistant',
        children: [{
          path: 'personal-information-collection-statement',
          element: lazyComponent(<DriverAssistantPersonal />),
        }, {
          path: 'privacy-policy',
          element: lazyComponent(<DriverAssistantPrivacy />),
        }],
      },
      {
        path: 'jek',
        children: [{
          path: 'personal-information-collection-statement',
          element: lazyComponent(<JekPersonal />),
        }, {
          path: 'privacy-policy',
          element: lazyComponent(<JekPrivacy />),
        }, {
          path: 'terms-and-condition',
          element: lazyComponent(<JekTerms />),
        }, {
          path: 'paymentRule',
          element: lazyComponent(<JekPaymentRule />),
        }, {
          path: 'cancelRule',
          element: lazyComponent(<JekCancelRule />),
        }]
      },
      {
        path: 'hktaxigo',
        children: [{
          path: 'personal-information-collection-statement',
          element: lazyComponent(<HktaxigoPersonal />),
        }, {
          path: 'privacy-policy',
          element: lazyComponent(<HktaxigoPrivacy />),
        }, {
          path: 'terms-and-condition',
          element: lazyComponent(<HktaxigoTerms />),
        }, {
          path: 'paymentRule',
          element: lazyComponent(<HktaxigoPaymentRule />),
        }, {
          path: 'cancelRule',
          element: lazyComponent(<HktaxigoCancelRule />),
        }]
      },
      {
        path: 'runningtaxi',
        children: [{
          path: 'personal-information-collection-statement',
          element: lazyComponent(<RunningtaxiPersonal />),
        }, {
          path: 'privacy-policy',
          element: lazyComponent(<RunningtaxiPrivacy />),
        }, {
          path: 'terms-and-condition',
          element: lazyComponent(<RunningtaxiTerms />),
        }, {
          path: 'paymentRule',
          element: lazyComponent(<RunningtaxiPaymentRule />),
        }, {
          path: 'cancelRule',
          element: lazyComponent(<RunningtaxiCancelRule />),
        }]
      },
    ]
  },
];

export default routerWithoutToken;