import Table from '@/components/table';
import { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import type { TableRecord } from '@/components/table/interface';
import { useTranslation } from 'react-i18next';
import ModalEmpty from '@/components/common/modal/modal-empty';
import AntdSwitch from '@/components/common/antd/antd-switch';
import dayjs from 'dayjs';
import { Dropdown } from 'antd';
import Menu, { Item as MenuItem } from 'rc-menu';
import { IObject } from '@/types/common-types';
import Button from '@/components/common/button';
import Input from '@/components/common/input';
import Label from '@/components/common/label';
import {
  generateApiCredential,
  getApiCredential,
  updateApiCredential,
} from './server';
import { Alert } from '@/components/common/alert/alert';
import EllipsisIcon from '@/components/svg/ellipsis-icon';
import CopyIcon from '@/components/svg/copy-icon';
import { useParams } from 'react-router-dom';
import ActionType from '@/actions/action-type';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';

// interface IApiCredentialsViewProps {
//   generateModal: boolean;
//   businessID: string;
//   setGenerateModal: (item: boolean) => void;
// }

const ApiCredentialsView = () => {
  const { t } = useTranslation(["settings", "business_account_settings", "account_balance"]);
  const { lang = 'en', p_business_id: businessID = '' } = useParams();
  const dispatch: IDispatch = useDispatch();


  useEffect(() => {
    handleColums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const [colums, setColums] = useState<ColumnsType<TableRecord>>([]);
  const [records, setRecords] = useState<IObject[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [currentRecord, setCurrentRecord] = useState<IObject>({});
  const [deactivateModal, setDeactivateModal] = useState<boolean>(false);
  const [psw, setPsw] = useState<string>('');
  const [successfully, setSuccessfully] = useState<boolean>(false);
  const [domain, setDomain] = useState<string>('');
  const [appKey, setAppKey] = useState<string>('');
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>('text');
  const [generateModal, setGenerateModal] = useState<boolean>(false);
  const dateFormat = lang === 'en' ? 'MMM DD, YYYY' : 'MMM DD日, YYYY';


  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          key: '1',
          name: t('settings:settings'),
          type: 'label',
        },
        {
          key: '2',
          name: t('settings:api_credentials'),
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  const loadData = async (refresh = false) => {
    setLoadingData(true);

    const data = await getApiCredential(businessID);

    setRecords(data);
    if (refresh) {
      setRefreshKey((prevKey) => prevKey + 1);
    }
    setLoadingData(false);
  };

  const handleDeactivate = (record: IObject) => {
    if (record && record.id) {
      setCurrentRecord(record);
      setDeactivateModal(true);
    }
  };

  const handleColums = () => {
    const columns: ColumnsType<TableRecord> = [
      {
        key: 'slug',
        title: t('settings:app_slug'),
        dataIndex: 'slug',
        width: 120,
      },
      {
        key: 'app_secret',
        title: t('settings:app_secret'),
        dataIndex: 'app_secret',
        width: 250,
        render: (value, _record) => {
          return (
            <div className="flex items-center">
              <div className="w-[250px] truncate mr-2">{value}</div>
              <CopyIcon size={20} className="text-primary-color" onClick={() => handleCopy(value)} />
            </div>
          );
        },
      },
      {
        key: 'activate',
        title: t('settings:activate'),
        dataIndex: 'activate',
        width: 120,
        render: (value, _record) => {
          return <AntdSwitch checked={value} disabled />;
        },
      },
      {
        key: 'p_created_at',
        title: t('settings:created_at'),
        dataIndex: 'p_created_at',
        width: 120,
        render: (value, _record) => {
          return dayjs(value).format(dateFormat);
        },
      },
      {
        key: 'actions',
        title: t('common:actions'),
        fixed: 'right',
        dataIndex: 'actions',
        align: 'left',
        width: 60,
        render: (_value, record) => {
          return (
            <Dropdown
              destroyPopupOnHide={true}
              dropdownRender={() => (
                <Menu className="bg-primary-background-color">
                  <MenuItem key="deactivate">
                    <div
                      className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                      onClick={() => {
                        handleDeactivate(record);
                      }}
                    >
                      <span className="text-color-text-2 text-base font-medium">
                        {t('settings:deactivate')}
                      </span>
                    </div>
                  </MenuItem>
                </Menu>
              )}
            >
              <div className="justify-center flex">
                <div className="flex items-center cursor-pointer p-1.5 rounded-full w-max hover:bg-primary-color/10 group">
                  <EllipsisIcon className="text-icon-color group-hover:text-primary-color" />
                </div>
              </div>
            </Dropdown>
          );
        },
      },
    ];
    setColums(columns);
    return columns;
  };

  const handleGenerateModalCancel = () => {
    setGenerateModal(false);
    setSuccessfully(false);
    setAppKey('');

    setTimeout(() => {
      // loadData();
      setPsw('');
      setDomain('');
      setType('text');
    }, 500);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = await generateApiCredential(businessID, psw, domain);

    if (data && data.id) {
      setSuccessfully(true);
      setAppKey(data.app_key || '');

      setTimeout(() => {
        loadData();
        setPsw('');
        setDomain('');
        setType('text');
      }, 1000);
    } else {
      if (data.message) {
        Alert.error({
          message: data.message,
          wrapClass: '-top-10',
        });
      }
    }

    setLoading(false);
  };

  const handleDeactivateModalCancel = () => {
    setDeactivateModal(false);
    setCurrentRecord({});
    setPsw('');
    setType('text');
  };

  const handleDeactivateSubmit = async () => {
    setLoading(true);
    const result = await updateApiCredential(businessID, currentRecord.id, {
      password: psw,
      activate: !currentRecord.activate,
    });

    if (result.code === 200) {
      setDeactivateModal(false);
      Alert.success({
        message: t('settings:deactivated_tips'),
        wrapClass: 'top-px',
      });
      loadData(true);
      setTimeout(() => {
        setPsw('');
      }, 1000);
    } else {
      if (result.message) {
        Alert.error({
          message: result.message,
          wrapClass: '-top-10',
        });
      }
    }
    setLoading(false);
  };

  const handleCopy = (value = appKey) => {
    navigator.clipboard.writeText(value).then(() => {
      Alert.success({
        message: t('common:copied'),
        wrapClass: 'top-px',
      });
    });
  };

  return (
    <>
      <div className="flex overflow-hidden h-full">
        <div className="bg-system-background-color w-full flex flex-col px-8 py-6 overflow-y-scroll">
          <div className="md:flex justify-between px-1">
            <div className="text-xl leading-[30px] md:text-3xl md:leading-10 font-bold text-color-text-1 mb-4 md:mb-0">
              {t('settings:api_credentials')}
            </div>
            <div className="flex space-x-4">
              <Button onClick={() => setGenerateModal(true)}>
                {t('settings:generate_new_token')}
              </Button>
            </div>
          </div>
          <div className="mt-6">
            <Table
              key={refreshKey}
              columns={colums}
              records={records}
              loadingData={loadingData}
            />
          </div>
        </div>
      </div>

      <ModalEmpty visible={generateModal} onCancel={handleGenerateModalCancel}>
        <div className="text-center">
          {!successfully && (
            <>
              <div className='text-xl font-bold'>{t('settings:authorization')}</div>
              <div className="mt-4 text-color-text-2 text-sm">{t('settings:verify_password_tips')}</div>
              <Label className="mt-8" value={t('settings:domain')} />
              <Input
                value={domain}
                name="domain"
                autoComplete="off"
                placeholder={t('settings:enter_domain')}
                onChange={(value) => setDomain(value)}
              />
              <Label className="mt-4" value={t('settings:password')} required />
              <Input
                value={psw}
                type={type}
                onFocus={() => setType('password')}
                autoComplete="off"
                placeholder={t('settings:enter_password')}
                onChange={(value) => setPsw(value)}
              />
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 mt-8">
                <Button styleType="Border" onClick={handleGenerateModalCancel}>
                  {t('common:cancel')}
                </Button>
                <Button
                  loading={loading}
                  disabled={!psw}
                  onClick={handleSubmit}
                >
                  {t('settings:submit')}
                </Button>
              </div>
            </>
          )}
          {successfully && (
            <>
              <div className="flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="80"
                  viewBox="0 0 80 80"
                  fill="none"
                >
                  <rect
                    opacity="0.1"
                    width="80"
                    height="80"
                    rx="40"
                    fill="#0094FF"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40 20C29 20 20 29 20 40C20 51 29 60 40 60C51 60 60 51 60 40C60 29 51 20 40 20ZM37.5 50V37.5H42.5V50H37.5ZM37.5 32.5C37.5 34 38.5 35 40 35C41.5 35 42.5 34 42.5 32.5C42.5 31 41.5 30 40 30C38.5 30 37.5 31 37.5 32.5Z"
                    fill="#0094FF"
                  />
                </svg>
              </div>
              <div className="font-bold text-color-text-1 text-xl">
                {t('settings:copy_aip_tips')}
              </div>
              <Label className="mt-4" value={t('settings:app_key')} />
              <div className="truncate py-2 px-3 border text-color-text-2 bg-primary-color/10 border-primary-color">
                {appKey}
              </div>
              <Button className="w-full mt-8" onClick={() => handleCopy()}>
                {t('settings:copy')}
              </Button>
            </>
          )}
        </div>
      </ModalEmpty>

      <ModalEmpty
        visible={deactivateModal}
        onCancel={handleDeactivateModalCancel}
      >
        <div className="text-center">
          <div className='text-xl font-bold'>{t('settings:authorization')}</div>
          {/* <div className="mt-4">{t('settings:select_deactivate_tips')}</div> */}
          <Label className="mt-8" value={t('settings:password')} required />
          <Input
            value={psw}
            type={type}
            onFocus={() => setType('password')}
            autoComplete="off"
            placeholder={t('settings:enter_password')}
            onChange={(value) => setPsw(value)}
          />
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 mt-8">
            <Button styleType="Border" onClick={handleDeactivateModalCancel}>
              {t('common:cancel')}
            </Button>
            <Button
              loading={loading}
              disabled={!psw}
              onClick={handleDeactivateSubmit}
            >
              {t('settings:submit')}
            </Button>
          </div>
        </div>
      </ModalEmpty>
    </>
  );
};

export default ApiCredentialsView;
