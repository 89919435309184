import React from 'react';
import type { IIconProps } from '@/types/common-types';

const HuaweiPayIcon: React.FC<IIconProps> = (props) => {
    const { className = '', onClick } = props;
    return (
        <div className={`${className}`} onClick={onClick}>
            <svg width="40" height="28" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.0784 12.8867H7.99902V20.956H9.5914V19.1131H12.0784C13.7977 19.1131 15.1916 17.7193 15.1916 15.9999C15.1916 14.2805 13.7977 12.8867 12.0784 12.8867ZM9.5724 14.2636V17.6988L11.9238 17.6779C12.8537 17.6697 13.6059 16.9186 13.6157 15.9888C13.6257 15.0389 12.8585 14.2636 11.9086 14.2636H9.5724Z" fill="#E60012" />
                <path d="M7.99902 11.3616V7.33594H8.83994V8.94621H10.647V7.33594H11.47V11.3616H10.647V9.71556H8.83994V11.3616H7.99902Z" fill="black" />
                <path fillRule="evenodd" clipRule="evenodd" d="M13.3684 7.36719H12.5459V9.78259C12.5459 10.7411 13.3229 11.5181 14.2814 11.5181C15.2399 11.5181 16.0169 10.7411 16.0169 9.78259V7.36719H15.1934V9.78259C15.1934 10.2865 14.7849 10.6951 14.2809 10.6951C13.777 10.6951 13.3684 10.2865 13.3684 9.78259V7.36719Z" fill="black" />
                <path fillRule="evenodd" clipRule="evenodd" d="M18.1824 7.36719L16.4111 11.375H17.2699L17.6099 10.5341H19.4349L19.8106 11.375H20.6694L18.916 7.36719H18.1824ZM18.5224 8.40787L17.932 9.82133H19.1307L18.5224 8.40787Z" fill="black" />
                <path d="M21.7234 11.3789L20.3457 7.37109H21.2224L22.0991 10.0907L22.9937 7.37109H23.6736L24.5682 10.0907L25.4628 7.37109H26.3037L24.926 11.3789H24.2282L23.3336 8.78455L22.4212 11.3789H21.7234Z" fill="black" stroke="black" strokeWidth="0.0286112" />
                <path d="M26.9482 11.361V7.37109H29.8825V8.10466H27.7534V8.92769H29.2384V9.66125H27.7534V10.6453H29.9541V11.361H26.9482Z" fill="black" />
                <rect x="30.8984" y="7.37109" width="0.823025" height="4.00778" fill="black" />
                <path d="M26.7681 19.1108L23.6191 12.9023H25.4441L27.6627 17.2859L29.8992 12.9023H31.7421L27.6448 20.9358H25.8198L26.7681 19.1108Z" fill="#E60012" />
                <path fillRule="evenodd" clipRule="evenodd" d="M21.9065 12.8867H16.2305V14.2834H21.2762C21.5132 14.2834 21.7054 14.4755 21.7054 14.7125V19.0773H23.2799V14.2601C23.2799 13.5016 22.665 12.8867 21.9065 12.8867Z" fill="#E60012" />
                <path fillRule="evenodd" clipRule="evenodd" d="M17.8024 15.2031C16.7352 15.2031 15.8701 16.0683 15.8701 17.1354C15.8701 18.2026 16.7352 19.0678 17.8024 19.0678H21.7029V15.2031H17.8024ZM17.9624 16.6319C17.6758 16.6319 17.4435 16.8642 17.4435 17.1508C17.4435 17.4373 17.6758 17.6696 17.9624 17.6696H21.7018V16.6319H17.9624Z" fill="#E60012" />
            </svg>


        </div>
    );
};

export default HuaweiPayIcon;
