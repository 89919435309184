import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import PayoutHistory from './payout-history';
import PayoutByPayoutCategories from './payout-by-payout-categories';
import PayoutByPayoutCategoriesPie from './payout-by-payout-categories-pie';
import PayoutByTransferMethods from './payout-by-transfer-methods';
import BreakdownOfPayoutByPayer from './breakdown-of-payout-by-payer';
import ProjectedPayoutForecast from './projected-payout-forecast';

interface IPayinsReportsProps {
  records: any[];
  currency: string;
  loading: boolean;
  startDate: string;
  endDate: string;
  businessID: string;
}

const PayinsReports: React.FC<IPayinsReportsProps> = (props) => {
  const {
    records = [],
    currency,
    startDate,
    endDate,
    loading,
    businessID,
  } = props;

  const baseProps = {
    loading,
    startDate,
    endDate,
    currency,
    businessID,
  };

  return (
    <Transition.Root show={true} as={Fragment} appear={true}>
      <div className="grid grid-cols-1 gap-6 mt-6 md:grid-cols-2">
        <div className="flex flex-col">
          <PayoutHistory {...baseProps} data={records[0]} />

          <PayoutByPayoutCategoriesPie {...baseProps} data={records[1]} />

          <BreakdownOfPayoutByPayer {...baseProps} data={records[3]} />
        </div>

        <div className="flex flex-col">
          <PayoutByPayoutCategories {...baseProps} data={records[1]} />

          <PayoutByTransferMethods {...baseProps} data={records[2]} />

          <ProjectedPayoutForecast {...baseProps} data={records[4]} />
        </div>
      </div>
    </Transition.Root>
  );
};

export default PayinsReports;
