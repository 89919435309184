import api from "@/libs/api";
import Config from "@/libs/config";
import { IObject } from "@/types/common-types";


export const queryJsonwebtoken = async (payload: IObject): Promise<string> => {
  let token = ''

  try {
    const result: IObject =  await api.post(`${Config.urls.gatewayUrl}/api/registry/businesses/jsonwebtoken`,
    {
      ...payload,
    })

    if (result && result.code === 200) {
      token = result.data
    }

  } catch (error) {
    console.error(error);
  }

  return token
  
};