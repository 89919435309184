import React from 'react';
import type { IIconProps } from '@/types/common-types';

const CartIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div
      className={`inline-flex items-center justify-center ${className}`}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M5.9283 19.6467C5.44255 19.6467 5.02815 19.4752 4.6851 19.1321C4.34139 18.7884 4.16954 18.3737 4.16954 17.8879C4.16954 17.4022 4.34139 16.9874 4.6851 16.6437C5.02815 16.3007 5.44255 16.1291 5.9283 16.1291C6.41406 16.1291 6.82845 16.3007 7.1715 16.6437C7.51521 16.9874 7.68707 17.4022 7.68707 17.8879C7.68707 18.3737 7.51521 18.7884 7.1715 19.1321C6.82845 19.4752 6.41406 19.6467 5.9283 19.6467ZM15.6769 19.6467C15.1911 19.6467 14.7767 19.4752 14.4337 19.1321C14.09 18.7884 13.9181 18.3737 13.9181 17.8879C13.9181 17.4022 14.09 16.9874 14.4337 16.6437C14.7767 16.3007 15.1911 16.1291 15.6769 16.1291C16.1626 16.1291 16.577 16.3007 16.9201 16.6437C17.2638 16.9874 17.4357 17.4022 17.4357 17.8879C17.4357 18.3737 17.2638 18.7884 16.9201 19.1321C16.577 19.4752 16.1626 19.6467 15.6769 19.6467ZM4.79767 3.76753L7.33531 9.09408H14.3704L17.1593 4.04391C17.1928 3.97691 17.1928 3.91426 17.1593 3.85597C17.1258 3.79701 17.0755 3.76753 17.0085 3.76753H4.79767ZM5.9283 14.6216C5.2583 14.6216 4.75579 14.3329 4.42079 13.7553C4.08578 13.1771 4.07741 12.6032 4.39566 12.0337L5.8278 9.44583L2.15952 1.75751H0.978631C0.77763 1.75751 0.605773 1.68616 0.463062 1.54345C0.321021 1.40141 0.25 1.22151 0.25 1.00376C0.25 0.786005 0.321021 0.605773 0.463062 0.463062C0.605773 0.321021 0.786005 0.25 1.00376 0.25H2.5364C2.7039 0.25 2.86302 0.295896 3.01377 0.387686C3.16453 0.480147 3.28178 0.610128 3.36553 0.77763L4.06903 2.26002H18.0135C18.4825 2.26002 18.8135 2.43154 19.0065 2.77458C19.1988 3.1183 19.1944 3.48278 18.9934 3.86803L15.7523 9.69708C15.6015 9.98184 15.3921 10.2036 15.1241 10.3624C14.8561 10.5219 14.5546 10.6016 14.2196 10.6016H6.88306L5.7273 12.7372C5.67705 12.821 5.67303 12.9047 5.71524 12.9885C5.75678 13.0722 5.8278 13.1141 5.9283 13.1141H16.707C16.908 13.1141 17.0799 13.1895 17.2226 13.3402C17.3646 13.491 17.4357 13.6669 17.4357 13.8679C17.4357 14.0856 17.3646 14.2655 17.2226 14.4076C17.0799 14.5503 16.8997 14.6216 16.6819 14.6216H5.9283Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default CartIcon;
