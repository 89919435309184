import { DatePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import SelectedIcon from '../../payouts-icons/selected-icon';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import MinusIcon from '../../payouts-icons/minus-icon';
import PlusIcon from '../../payouts-icons/plus-icon';
import DateIcon from '@/components/svg/date-icon';
import CheckIcon from '@/components/svg/check-icon';
import MoreIcon from '@/components/svg/ellipsis-icon';
import { IAttachmentFile, ICategory, IPayee, IPayout, IPurpose } from '@/types/payouts-type';
import { useTranslation } from 'react-i18next';
import { initFiles } from '@/utils/payouts-utils';
import PayeeDetails from '../../components/payee-details';
import Label from '@/components/common/label';
import { AntdSelect, Option } from '@/components/common/antd/select/select';
import Config from '@/libs/config';
import Input from '@/components/common/input/input';
import FileInput from '@/components/common/file-input/file-input';
import Textarea from '@/components/common/textarea';
import Button from '@/components/common/button';
import AntdModal from '@/components/common/antd/modal';

interface IPayoutsTransferDetailsProps {
  businessID: string;
  payee: IPayee;
  categories: ICategory[];
  payout: IPayout;
  purposes: IPurpose[];
  setPayout: (payout: IPayout) => void;
  setStepCount: (stepCount: number) => void;
}

type IDateType = 'Immediate' | 'Specific Date';

const PayoutsTransferDetails: React.FC<IPayoutsTransferDetailsProps> = (
  props
) => {
  const { payee, setStepCount, categories, payout, setPayout, purposes } =
    props;
  const { t } = useTranslation('payouts');
  const { t: ct } = useTranslation('common');

  const getPayee = () => {
    if (payout.payout_category_id) {
      return {
        id: payout.payout_category_id,
        category_name: payout.payout_category_name,
        color: payout.payout_category_color,
        icon_file: payout.payout_category_icon,
      };
    } else {
      return payee.categories;
    }
  };

  const initInvoiceFiles = useMemo(() => {
    return initFiles(payout.attachment_files, 'Invoice');
    // eslint-disable-next-line
  }, []);
  const initPOFiles = useMemo(() => {
    return initFiles(payout.attachment_files, 'Purchase Order');
    // eslint-disable-next-line
  }, []);

  const getFiles = (type: 'Invoice' | 'Purchase Order') => {
    const files: IAttachmentFile[] = payout.attachment_files.filter(
      (item) => item.out_type === type
    );
    return files;
  };

  const [invoiceUploading, setInvoiceUploading] = useState(false);
  const [poUploading, setPoUploading] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [reference, setReference] = useState(payout.reference);
  const [poNumber, setPoNumber] = useState(payout.po_number);
  const [remark, setRemark] = useState(payout.remark);
  const [dateType, setDateType] = useState<IDateType>(
    payout.schedule_payout_at ? 'Specific Date' : 'Immediate'
  );
  const [selectDate, setSelectDate] = useState<dayjs.Dayjs | null>(
    payout.schedule_payout_at ? dayjs(payout.schedule_payout_at) : null
  );
  const [temDate, setTemDate] = useState<dayjs.Dayjs | null>(
    payout.schedule_payout_at ? dayjs(payout.schedule_payout_at) : null
  );
  const [selectCategory, setSelectCategory] = useState<ICategory>(getPayee());
  const [showInvoiceInput, setShowInvoiceInput] = useState(
    initInvoiceFiles.length > 0
  );
  const [invoiceFiles, setInvoiceFiles] = useState<IAttachmentFile[]>(
    getFiles('Invoice')
  );
  const [showPOInput, setShowPOInput] = useState(initPOFiles.length > 0);
  const [poFiles, setpoFiles] = useState<IAttachmentFile[]>(
    getFiles('Purchase Order')
  );

  const handleSelectPurpose = (value: any, option: any) => {
    setPayout({
      ...payout,
      transfer_purpose: option.label,
      transfer_purpose_id: value,
    });
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs().endOf('day');
  };

  const handleCloseDateModal = () => {
    if (selectDate === null) {
      setDateType('Immediate');
    }
    setShowDateModal(false);
    setTemDate(null);
  };

  const handleSaveDate = () => {
    setSelectDate(temDate);
    setShowDateModal(false);
    setTemDate(null);
  };

  const hadnleSelectDateType = (type: IDateType) => {
    if (type === 'Immediate') {
      setSelectDate(null);
      setShowDateModal(false);
    }
    setDateType(type);
  };

  const handleNext = () => {
    setPayout({
      ...payout,
      reference,
      remark,
      po_number: poNumber,
      schedule_payout_at: selectDate ? selectDate.format('YYYY-MM-DD') : '',
      payout_category_id: selectCategory.id,
      payout_category_color: selectCategory.color,
      payout_category_icon: selectCategory.icon_file,
      payout_category_name: selectCategory.category_name,
      attachment_files: invoiceFiles.concat(poFiles),
    });
    setStepCount(2);
  };

  const handleFilesChange = (fileObjects: any[], type: string) => {
    const files: IAttachmentFile[] = [];
    fileObjects.forEach((file) => {
      files.push({
        id: file.id,
        title: file.title || '',
        type: file.type || '',
        filename_disk: file.filename_disk || '',
        filename_download: file.filename_download || '',
        out_id: '',
        payout_request: '',
        out_type: type,
      });
    });

    if (type === 'Invoice') {
      setInvoiceFiles(files);
    } else if (type === 'Purchase Order') {
      setpoFiles(files);
    }
  };

  return (
    <div>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        <div className="font-semibold text-xl text-color-text-1">
          {t('transfer_details')}
        </div>
        <div className="mt-4 mb-6 border-solid border-0 border-b border-border-color" />
        <PayeeDetails
          payee={payee}
          nickname={false}
          fold={true}
          className="rounded bg-disabled-color p-4"
        />
        <Label
          className="mb-0 mt-4 md:mt-6 md:mb-1.5"
          value={t('transfer_date')}
          required={true}
        />
        <div className="w-full">
          <div
            className="border border-border-color hover:border-primary-color shadow-box-drop flex items-center h-10 rounded cursor-pointer group"
            onClick={() => {
              setShowDateModal(true);
              setTemDate(selectDate);
            }}
          >
            <input
              className="border-none text-color-text-1 text-sm font-medium outline-none shadow-none flex-1 cursor-pointer bg-inherit py-2 px-3"
              type="text"
              value={
                dateType === 'Immediate'
                  ? t('immediate')
                  : selectDate?.format('YYYY-MM-DD')
              }
              disabled
            />
            <span className="text-icon-color px-2 group-hover:text-primary-color">
              <MoreIcon />
            </span>
          </div>
        </div>
        <Label
          className="mb-0 mt-4 md:mt-6 md:mb-1.5"
          value={t('purpose_of_transfer')}
          required={true}
        />
        <AntdSelect
          optionLabelProp="label"
          onSelect={handleSelectPurpose}
          value={payout.transfer_purpose_id}
        >
          {purposes.map((item) => {
            return (
              <Option key={item.id} value={item.id} label={item.purpose}>
                <div className="flex justify-between items-center">
                  <div>
                    <div className="text-color-text-1">{item.purpose}</div>
                    <div className="text-color-text-2">{item.remark}</div>
                  </div>
                  {payout.transfer_purpose_id === item.id && <CheckIcon />}
                </div>
              </Option>
            );
          })}
        </AntdSelect>
        <Label
          className="mb-0 mt-4 md:mt-6 md:mb-1.5"
          value={t('category')}
          required={true}
        />

        <AntdSelect
          onSelect={(_value, option) => setSelectCategory(option.data)}
          value={selectCategory.id}
          optionLabelProp="label"
        >
          {categories.map((item) => {
            return (
              <Option
                key={item.id}
                data={item}
                value={item.id}
                label={
                  <div className="flex items-center h-full">
                    <div
                      className="w-5 h-5 rounded-full p-1 flex items-center"
                      style={{ backgroundColor: item.color }}
                    >
                      <img
                        width={12}
                        height={12}
                        src={`${Config.urls.gatewayUrl}/api/directus/treasury-services/files/${item.icon_file}.svg`}
                        alt=""
                      />
                    </div>
                    <div className="text-sm font-medium text-color-text-1 flex-1 ml-2">
                      {item.category_name}
                    </div>
                  </div>
                }
              >
                <div className="flex items-center">
                  <div
                    className="w-5 h-5 rounded-full p-1 flex items-center"
                    style={{ backgroundColor: item.color }}
                  >
                    <img
                      width={12}
                      height={12}
                      src={`${Config.urls.gatewayUrl}/api/directus/treasury-services/files/${item.icon_file}.svg`}
                      alt=""
                    />
                  </div>
                  <div className="text-sm font-medium text-color-text-1 flex-1 ml-2">
                    {item.category_name}
                  </div>
                  {selectCategory.id === item.id && <CheckIcon />}
                </div>
              </Option>
            );
          })}
        </AntdSelect>
        <Label
          className="mb-0 mt-4 md:mt-6 md:mb-1.5"
          value={t('internal_reference_number')}
        />
        <Input
          value={reference}
          placeholder={`${t('enter')}${t(
            'payouts:internal_reference_number'
          )}`}
          onChange={(value) => setReference(value)}
        />
        <div
          className="my-4 text-primary-color font-medium flex items-center cursor-pointer hover:text-primary-color/70"
          onClick={() => setShowInvoiceInput(!showInvoiceInput)}
        >
          {showInvoiceInput ? (
            <MinusIcon size={20} className="mr-1" />
          ) : (
            <PlusIcon size={20} className="mr-1" />
          )}
          <div>{t('attach_invoice_receipt_file')}</div>
        </div>
        {showInvoiceInput && (
          <FileInput
            name={'invoice'}
            rowQuantity={3}
            proportion={1}
            defaultValue={initInvoiceFiles}
            dataBase={'treasury-services'}
            onChange={(value) => {
              handleFilesChange(value, 'Invoice');
            }}
            onUploading={setInvoiceUploading}
          />
        )}
        <Label
          className="mb-0 mt-4 md:mt-6 md:mb-1.5"
          value={t('purchase_order_number')}
        />
        <Input
          value={poNumber}
          placeholder={`${t('enter')}${t(
            'payouts:purchase_order_number'
          )}`}
          onChange={(value) => setPoNumber(value)}
        />
        <div
          className="my-4 text-primary-color font-medium flex items-center cursor-pointer hover:text-primary-color/70"
          onClick={() => setShowPOInput(!showPOInput)}
        >
          {showPOInput ? (
            <MinusIcon size={20} className="mr-1" />
          ) : (
            <PlusIcon size={20} className="mr-1" />
          )}
          <div>{t('attach_purchase_order_file')}</div>
        </div>
        {showPOInput && (
          <FileInput
            name={'po'}
            rowQuantity={3}
            proportion={1}
            dataBase={'treasury-services'}
            defaultValue={initPOFiles}
            onChange={(value) => {
              handleFilesChange(value, 'Purchase Order');
            }}
            onUploading={setPoUploading}
          />
        )}
        <Label
          className="mb-0 mt-4 md:mt-6 md:mb-1.5"
          value={t('remark_reference')}
        />
        <Textarea
          value={remark}
          placeholder={`${t('enter')}${t('remark_reference')}`}
          onChange={(value) => setRemark(value)}
        />
      </div>
      <div className="flex items-center justify-between">
        <Button
          className="flex-1 mr-6"
          onClick={() => setStepCount(0)}
          styleType="Secondary"
        >
          {ct('back')}
        </Button>
        <Button
          className="flex-1"
          onClick={handleNext}
          styleType="Primary"
          disabled={
            !selectCategory.category_name ||
            invoiceUploading ||
            poUploading ||
            !payout.transfer_purpose_id
          }
        >
          {ct('next')}
        </Button>
      </div>
      <AntdModal
        open={showDateModal}
        footer={null}
        onCancel={handleCloseDateModal}
      >
        <div className="p-6 pt-8">
          <div className="font-bold text-color-text-1 text-xl text-center">
            {t('transfer_date')}
          </div>
          <div className="mt-6 text-sm">
            <div
              className="p-4 flex justify-between cursor-pointer hover:bg-disabled-color rounded border border-border-color"
              onClick={() => hadnleSelectDateType('Immediate')}
            >
              <div>
                <div className="font-semibold text-lg leading-6 text-color-text-1">
                  {t('immediate')}
                </div>
                <div className="font-medium text-color-text-2 pt-1">
                  {t('real_time_bank_transfer')}
                </div>
              </div>
              {dateType === 'Immediate' && <SelectedIcon size={20} />}
            </div>
            <div
              className="mt-6 p-4 cursor-pointer hover:bg-disabled-color rounded border border-border-color"
              onClick={() => hadnleSelectDateType('Specific Date')}
            >
              <div className="flex justify-between">
                <div>
                  <div className="font-semibold text-lg leading-6 text-color-text-1">
                    {t('specific_date')}
                  </div>
                  <div className="font-medium text-color-text-2 pt-1">
                    {t('sent_specific_date')}
                  </div>
                </div>
                {dateType === 'Specific Date' && <SelectedIcon size={20} />}
              </div>
              {dateType === 'Specific Date' && (
                <>
                  <div className="mt-4 pt-4 pb-1 text-color-text-2 text-sm font-medium border-t border-border-color">
                    {t('date')}
                  </div>
                  <DatePicker
                    defaultValue={selectDate || undefined}
                    disabledDate={disabledDate}
                    placeholder={t('choose_date')}
                    className="w-full text-base py-2 border-border-color rounded"
                    placement="bottomLeft"
                    showToday={false}
                    suffixIcon={<DateIcon size={20} className="text-icon-color" />}
                    onChange={(value) => {
                      setTemDate(value as dayjs.Dayjs);
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <Button className="w-full mt-7" disabled={temDate === null} onClick={handleSaveDate}>
            {ct('save')}
          </Button>
        </div>
      </AntdModal>
    </div>
  );
};

export default PayoutsTransferDetails;
