import api from "@/libs/api";
import Config from "@/libs/config";
import { IBusiness } from "@/types/business-types";
import { IObject } from "@/types/common-types";

export const queryBusiness = async (businessID: string) => {
  let business: IObject = {};

  try {
    const result: any = await api.get(
      `${Config.urls.gatewayUrl}/api/registry/businesses/detail`,
      {
        method: 'GET',
        headers: {
          'x-p-business-id': businessID,
        },
      }
    );

    const { data } = result;
    
    if (data.business && data.business.id) {
      business = {
        ...data.business,
      };
    }
  } catch (error) {

  }

  return business;
};

export const queryBusinessList = async () => {

  try {

    const res = await api.get(`${Config.urls.gatewayUrl}/api/galaxy/associates/businesses`);

    const { data = [] } = res;
    const result: IBusiness[] = data;

    return result;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const archiveBusinessApplication = async (id: string) => {
  try {

    const res: IObject = await api.remove(`${Config.urls.gatewayUrl}/api/registry/business-applications/archive/${id}`);

    return res;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const withdrawBusinessApplication = async (id: string) => {

  try {

    const res: IObject = await api.patch(`${Config.urls.gatewayUrl}/api/registry/business-applications/withdraw/${id}`);

    return res;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
