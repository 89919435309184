/**
 * modal basic
 * @returns react fc
 */
import { Fragment } from 'react';
import { Transition, Dialog } from '@headlessui/react';

import type { IModalEmptyProps } from './type';
// import Button from '../button';
import CloseIcon from '@/components/svg/close-icon';

const ModalContent: React.FC<IModalEmptyProps> = (props) => {
  const {
    visible = false,
    onCancel,
    children,
    icon,
    // iconClass = '',
    title = '',
    showCloseIcon = true,
  } = props;

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-99" onClose={handleCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-select-secondary-background-color/60" />
        </Transition.Child>

        <div className="fixed z-99 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded p-5 text-left shadow-xl transform transition-all sm:my-8 sm:max-w-md sm:p-10 sm:w-full">
                <div
                  className="text-color-text-4 hover:text-color-text-5 absolute cursor-pointer top-2 right-2"
                  onClick={handleCancel}
                >
                  {showCloseIcon &&
                    (icon || (
                      <CloseIcon
                        className="p-2.5 bg-primary-background-color rounded-full hover:text-primary-color"
                        size={20}
                      />
                    ))}
                </div>
                {title && (
                  <div className=" text-xl font-bold text-color-text-1 mt-4 text-center">
                    {title}
                  </div>
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalContent;
