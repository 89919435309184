import styles from '@/pages/content.module.css';
import { useLocation, useParams } from 'react-router-dom';
import Config from '@/libs/config';


const PersonalInformationCollectionStatement = () => {
  const { lang = 'en' } = useParams();
  const prefix = Config.staticPath;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const insert = searchParams.get('insert');

  const triggerWonderRoute = () => {
    const message = JSON.stringify({
      context: 'Navigator',
      action: 'route',
      content: 'support@wonder.app',
    });

    if (insert) {
      (window as any).flutter_inappwebview?.callHandler(
        'handleWonder',
        message
      );
    } else {
      window.location.href = 'mailto:support@wonder.app';
    }
  };

  return (
    <>
      <head>
        <link
          rel="preload"
          href={`${prefix}/font/Outfit-Light.woff`}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        ></link>
      </head>
      {lang !== 'zh-CN' && lang !== 'zh-HK' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title}`}>
              Personal Information Collection Statement
            </div>
            <div className={`${styles.content}`}>
              <p>
                Driver Assistant will collect your personal data to help us
                operate as a service provider. Broadly, Driver Assistant will
                collect, process and store the personal data you provide to us
                (such as your name, date of birth, identity card number,
                correspondence address, phone number, email address,
                nationality, or “sensitive” data such as your facial image, and
                videos and voice recordings of you that you make while
                communicating with us) to keep you and Driver Assistant secure,
                meet our business obligations and comply with the law. This
                includes establishing, maintaining and operating your Driver
                Assistant Account or other accounts, Driver Assiatant Card (and
                any other Driver Assistant product or service you use), also
                providing rewards and running competitions and games. The
                provisions of this PICS form part of the account terms and
                conditions (including the terms applicable to your use of our
                App) and any other agreement or arrangements you enter into with
                Driver Assistant.
              </p>
              <p>
                We collect your data so we can provide the best possible service
                to you. If you do not provide us with the personal data we
                require from you, we may not be able to establish, maintain or
                provide our products and services to you.
              </p>
              <p>
                We may also collect your data, directly or indirectly, from your
                transactions with or through Driver Assistant in the ordinary
                course of our business, including information received from
                third parties, the public domain, collected through your use of
                our App, websites, cookies, behavioral or location tracking
                tools, financial services or other services provided by Driver
                Assistant and/or when you deposit money or execute transactions
                through your Driver Assistant Card. Understanding your spending
                and saving behavior helps us make suggestions to you, to make
                informed financial decisions for you, and to help keep your
                account(s) and data secure.
              </p>
              <p>
                Please note that we also collect data to help us comply with
                laws, regulations, guidelines and requests or investigations by
                the authorities.
              </p>
              <h3>1. Who we collect data from</h3>
              <p>
                We will collect personal data from our customers and other
                individuals in connection with the purposes set out in this
                PICS. These customers and other individuals may include the
                following, and we refer to them collectively as “you/your” in
                this PICS:
              </p>
              <p>
                A. applicants or account-holders of Driver Assistant products or
                services;
              </p>
              <p>B. customers; and</p>
              <p>C. any third party transacting with or through us.</p>
              <h3>2. Use of your data</h3>
              <p>We may use your data for any of the following purposes:</p>
              <p>
                A. considering and processing your application/s (including
                assessing the merits and/or suitability of your application/s)
                for Driver Assistant products and services;
              </p>
              <p>
                B. operating, maintaining and informing you of Driver Assistant
                products and services;
              </p>
              <p>
                C. developing, improving and designing Driver Assistant products
                and services;
              </p>
              <p>
                D. meeting our internal operational requirements (including
                credit and risk management, system or product development and
                planning, carrying out testing and analysis and insurance, audit
                and administrative purposes);
              </p>
              <p>
                E. marketing services, products and other subjects (see
                paragraph below, “Direct Marketing”);
              </p>
              <p>
                F. meeting or complying with any obligations, requirements or
                arrangements for disclosing and using data that apply to us,
                including those that we or any such member is expected to comply
                with according to:
              </p>
              <ul>
                <li>
                  (a) any present or future law or regulation within or outside
                  Hong Kong (e.g. the Inland Revenue Ordinance and its
                  provisions including those concerning automatic exchange of
                  financial account information);
                </li>
                <li>
                  (b) any present or future guidelines or guidance issued by any
                  legal, regulatory, governmental, tax, law enforcement or other
                  authorities, or self-regulatory or industry bodies or
                  associations of financial services providers within or outside
                  Hong Kong (e.g. guidelines or guidance given or issued by the
                  Inland Revenue Department including those concerning automatic
                  exchange of financial account information);
                </li>
                <li>
                  (c) any present or future contractual or other commitment with
                  local or foreign legal, regulatory, governmental, tax, law
                  enforcement or other authorities, or self- regulatory or
                  industry bodies or associations of financial services
                  providers applicable to us by reason of its financial,
                  commercial, business or other interests or activities in or
                  related to the relevant jurisdiction (each an “Authority”); or
                </li>
                <li>
                  (d) any investigation, demand or request from an Authority;
                </li>
              </ul>
              <p>
                G. enabling an actual or potential transferee, assignee of all
                or any part of our business and/or asset or participant or
                sub-participant of our rights in respect of you, to evaluate the
                transaction intended to be the subject of the transfer,
                assignment, participation or sub-participation;
              </p>
              <p>
                H. in connection with us defending or responding to any legal,
                governmental, or regulatory or quasi-governmental related
                matter, action or proceeding (including any prospective action
                or legal proceedings), including where it is in the legitimate
                interests of us to seek professional advice, for obtaining legal
                advice or for establishing, exercising or defending legal
                rights;
              </p>
              <p>
                I. in connection with investigating an insurance-related matter;
              </p>
              <p>J. organising and delivering seminars to you;</p>
              <p>
                K. managing, monitoring and assessing the performance of any
                agent, contractor or third party service provider who provides
                administrative, telecommunications, computer, payment or
                securities clearing or other services to us in connection with
                the establishment, operation or maintenance of any Driver
                Assistant product or service; and
              </p>
              <p>L. any other purposes relating thereto.</p>
              <h3>3. Disclosure of your Data</h3>
              <p>
                Data we hold is kept confidential but we may provide, transfer
                or disclose such data or information to other parties (whether
                within or outside Hong Kong*) if it will help with any of the
                uses we’ve listed in the “Use of your data” paragraph above.
                These other parties include:
              </p>
              <p>
                A. any organisation, agent, contractor or third party service
                provider who provides administrative, telecommunications,
                identity verification/ know-your-customer, computer, payment/
                transaction, cloud storage or services, data analytics,
                cybersecurity or securities clearing or other services to us in
                connection with the establishment, operation, maintenance or
                provision of any Driver Assistant product or service to you;
              </p>
              <p>
                B. anyone who works for (or provides services to) us (or any of
                the parties referred to in paragraph A);
              </p>
              <p>
                C. any person who owes a duty of confidentiality to Driver
                Assistant;
              </p>
              <p>
                D. any financial institution or merchant acquiring company which
                you would like to, or already have, dealings with;
              </p>
              <p>
                E. any person or organisation that Driver Assistant owes an
                obligation to (which may exist now or in the future);
              </p>
              <p>
                F. any actual or proposed assignee or transferee of all or any
                part of Driver Assistant’s business and/or assets or participant
                or sub-participant or transferee of Driver Assistant’s rights in
                respect of you;
              </p>
              <p>G. charitable or non-profit making organisations;</p>
              <p>
                H. any external service provider that we engage to provide
                marketing services (including anyone who works for such a
                service provider);
              </p>
              <p>
                I. any interface (such as an application programming interface)
                that links to, or in any way makes available information about,
                our products and/or services;
              </p>
              <p>
                J. third party financial institutions, insurers, credit card
                companies, securities and investment service providers;
              </p>
              <p>
                K. third party reward, loyalty, co-branding and privileges
                program providers;
              </p>
              <p>
                L. our co-branding partners (the names of such co-branding
                partners can be found in the application form(s) for the
                relevant services and products, as the case may be);
              </p>
              <p>
                M. any person or company who has a direct or indirect
                shareholding in Driver Assistant and their affiliates (each, a
                “Shareholder”) (for example, to find out whether or not you are
                a customer of theirs or their affiliates and what products and
                services they provide or could provide to you), and anyone who
                works for (or provides services to) a Shareholder;
              </p>
              <p>N. any Authority;</p>
              <p>
                O. any other Driver Assistant customers in connection with your
                use of Driver Assistant products and services; and
              </p>
              <p>
                P. any other person (1) where the public interest requires or
                (2) with your express or implied consent.
              </p>
              <p>
                *This may mean your data is disclosed, transferred, stored or
                processed outside of Hong Kong. If this happens, then we may
                need to comply with another country’s laws and requirements on
                personal data. Such parties may be located in the following
                countries: Australia, Germany, Hong Kong, India, Ireland, Japan,
                Mainland China, Malaysia, Netherlands, Philippines, Singapore,
                United Arab Emirates, United Kingdom, United States of America.
              </p>
              <h3>4. Direct Marketing</h3>
              <p>
                We would like to use your data in direct marketing and we
                require your consent (which includes an indication of no
                objection) for that purpose. The data that we may use in direct
                marketing includes:
              </p>
              <p>A. your name and contact details;</p>
              <p>B. your demographic data;</p>
              <p>
                C. the products and services provided to you by Driver
                Assistant, or any Shareholder;
              </p>
              <p>D. your saving and spending patterns and behaviour; and</p>
              <p>E. your financial background.</p>
              <p>
                We may directly market the following classes of services,
                products, and subjects:
              </p>
              <p>
                A. financial, fiduciary, banking and related services and
                products;
              </p>
              <p>
                B. reward, loyalty or privileges programs and related services
                and products;
              </p>
              <p>C. services and products offered by any Shareholder;</p>
              <p>
                D. services and products offered by our co-branding partners
                (the names of such co-branding partners can be found in the
                application form(s) for the relevant services and products, as
                the case may be); and
              </p>
              <p>
                E. donations and contributions for charitable and/or non-profit
                making purposes.
              </p>
              <p>
                Along with us, the following persons may provide or solicit (in
                the case of donations and contributions) the above services,
                products and subjects:
              </p>
              <p>A. third party financial institutions;</p>
              <p>
                B. third party reward, loyalty, co-branding or privileges
                program providers;
              </p>
              <p>C. our co-branding partners; and</p>
              <p>D. charitable or non-profit making organisations.</p>
              <p>
                We may also provide your data to any of these people for them to
                use in directly marketing the same services, products and
                subjects to you. Driver Assistant will always first obtain your
                consent (which includes an indication of no objection) for that
                purpose. We may receive money or other property in return for
                providing your data to these other persons, but we’ll tell you
                if this is the case when obtaining your consent.
              </p>
              <p>
                You can change your mind about giving consent for us to use or
                provide to other persons your data for use in direct marketing,
                as set out above. Just let us know at any time.
              </p>
              <h3>5. Personal data of another person</h3>
              <p>
                Where you have provided us with another person’s personal data,
                you should provide him/her with a copy of this PICS and inform
                them of how we may use his/her data.
              </p>
              <h3>6. Access and correction of your personal data</h3>
              <p>You have the following rights according to the law:</p>
              <p>
                A. to check what data of yours we hold and be provided with a
                copy of it;
              </p>
              <p>
                B. to require us to make changes to any data that is inaccurate;
              </p>
              <p>
                C. to withdraw any consent that you have previously given us
                with respect to our use of your personal data;
              </p>
              <p>D. to know our policies and practices on data;</p>
              <p>
                E. to be told what kind of data we hold and what you have access
                to; and
              </p>
              <p>
                If you want to exercise any of these rights, see the Privacy
                Policy page on our website or let us know via the Driver
                Assistant, by email to{' '}
                <button className={`${styles.linkButton}`} onClick={triggerWonderRoute}>support@wonder.app</button>, or send
                a letter to:
              </p>
              <p>Data Protection Officer</p>
              <p>
                Suite 05-181, 5/F, The Quayside, 77 Hoi Bun Road, Kwun Tong,
                Hong Kong
              </p>
              <p>
                We have the right to charge a reasonable fee for the processing
                of any data access request you make. We will never charge a fee
                unless you are requesting a paper record to be sent to you.
              </p>
              <h3>7. Security</h3>
              <p>
                Once we receive your data, we’ll do our best to protect it
                because the security of your personal data is important to us.
                We have technical and organisational security measures in place
                to safeguard your personal data (including personal data in
                transit and storage). These security measures ensure that the
                confidentiality and integrity of your personal data is not
                compromised. Multiple layers of protection have been put in
                place to protect against leakage of personal data to external
                parties. Personal data will be encrypted by strong data
                encryption algorithms using encryption keys unique to us and
                with proper key management. When using external service
                providers, we require that they adhere to certain security
                standards mandated by us (as applicable). Regardless of where
                personal data is transferred, we take all steps reasonably
                necessary to ensure that personal data is kept securely.
              </p>
              <p>
                You should also be aware that the Internet (including
                applications which use the Internet for data transfer) may not
                be a secure form of communication and sending us personal data
                over the Internet may carry with it risks including the risk of
                access and interference by unauthorised third parties.
                Information passing over the Internet may be transmitted
                internationally (even when the sender and recipient are located
                in the same country) via countries with weaker privacy and data
                protection laws than your country of residence.
              </p>
              <p>
                Driver Assistant retains personal data in line with applicable
                legal and regulatory obligations and for business and
                operational purposes. In the majority of cases, this will be for
                7 years from the end of your relationship with us. You can ask
                us to permanently delete some or all of your data earlier than
                this but we can only do so if:
              </p>
              <p>
                A. we have no legal or regulatory obligation to retain it; or
              </p>
              <p>
                B. we don’t need it to provide a service that you would still
                like us to provide to you.
              </p>
              <p>
                If we can’t permanently delete your data promptly after you ask
                us, please be sure that we’ll let you know.
              </p>
              <p>
                We may record and monitor electronic communications with you to
                ensure compliance with legal and regulatory obligations and
                internal policies.
              </p>
              <p>
                You should also read our Privacy Policy when using our online
                services.
              </p>
              <p>
                The English version prevails if there is any inconsistency
                between the English and Chinese versions of any of this Driver
                Assiatant PICS.
              </p>
              <p>
                This Driver Assistant PICS is provided to you under Hong Kong’s
                Personal Data (Privacy) Ordinance and Code of Practice on
                Consumer Credit Data.
              </p>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-CN' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title}`}>个人信息收集声明</div>
            <div className={`${styles.content}`}>
              <p>
                Driver Assistant
                将收集你的个人数据，以帮助我们作为服务提供商进行运作。广义上讲，Driver
                Assiatant
                将收集、处理和储存您提供给我们的个人数据（如你的姓名、出生日期、身份证号码、通信地址、电话号码、电子邮件地址、国籍，或
                &quot;敏感
                &quot;数据，如你的面部图像，以及您在与我们交流时的视频和语音记录），以保证您和
                Driver Assistant
                的安全，履行我们的商业义务并遵守法律。这包括建立、维护和运营你的Driver
                Assiatant 账户或其他账户、Driver Assistant
                卡（以及您使用的任何其他 Driver Assiatant
                产品或服务），还包括提供奖励和开展竞赛及游戏。本PICS的规定构成账户条款和条件的一部分（包括适用于你的使用我们的App的条款）以及您与Driver
                Assiatant达成的任何其他协议或安排。
              </p>
              <p>
                我们收集你的，以便我们能够向您提供最好的服务。如果你不向我们提供我们要求你提供的个人数据，我们可能无法建立、维护或向你提供我们的产品和服务。
              </p>
              <p>
                你的我们也可能直接或间接收集你的，在我们的正常业务过程中与Driver
                Assiatant进行交易或通过Driver
                Assiatant进行交易的数据，包括从第三方、公共领域收到的信息，通过你的使用我们的App、网站、cookies、行为或位置跟踪工具、金融服务或Driver
                Assiatant提供的其他服务和/或当您通过你的Driver
                Assiatant卡存款或执行交易时收集的信息。了解你的消费和储蓄行为有助于我们向您提出建议，为您做出明智的财务决策，并帮助保持你的账户和数据的安全。
              </p>
              <p>
                请注意，我们也收集数据以帮助我们遵守法律、法规、准则和当局的要求或调查。
              </p>
              <h3>1.我们向谁收集数据</h3>
              <p>
                我们将从我们的客户和其他个人那里收集与本PICS中规定的目的有关的个人数据。这些客户和其他个人可能包括以下内容，我们在本声明中把他们统称为
                &quot;您/你的&quot;。
              </p>
              <p>A. Driver Assistant产品或服务的申请人或账户持有人。</p>
              <p>B. 客户；以及</p>
              <p>C. 与我们进行交易或通过我们进行交易的任何第三方。</p>
              <h3>2.使用你的数据</h3>
              <p>我们可能将你的数据用于以下任何目的。</p>
              <p>
                A.
                考虑和处理你的申请（包括评估你的申请的优点和/或适合性），以获得
                Driver Assistant 产品和服务。
              </p>
              <p>B. 运营、维护和通知您Driver Assistant的产品和服务。</p>
              <p>C. 开发、改进和设计Driver Assistant产品和服务。</p>
              <p>
                D.
                满足我们的内部运作要求（包括信贷和风险管理、系统或产品开发和规划、进行测试和分析以及保险、审计和行政管理目的）。
              </p>
              <p>
                E. 营销服务、产品和其他主题（见下文 &quot;直接营销
                &quot;段落）。
              </p>
              <p>
                F.
                满足或遵守适用于我们的披露和使用数据的任何义务、要求或安排，包括我们或任何此类成员应根据的那些义务、要求或安排。
              </p>
              <ul>
                <li>
                  (a)
                  香港境内或境外任何现行或未来的法律或法规（例如《税务条例》及其规定，包括有关自动交换财务账户信息的规定）。
                </li>
                <li>
                  (b)
                  香港境内外任何法律、监管、政府、税务、执法或其他机构，或金融服务供应商的自律或行业组织或协会所发出的任何现行或未来的指引或指导（例如，由税务局给予或发出的指引或指导，包括有关自动交换金融账户信息的指引）。
                </li>
                <li>
                  (c)
                  与当地或外国的法律、监管、政府、税务、执法或其他机构，或自我监管或行业机构，或因其在相关司法管辖区的金融、商业、业务或其他利益或活动而适用于我们的金融服务供应商协会（各为
                  &quot;机构&quot;）的任何当前或未来的合同或其他承诺；或
                </li>
                <li>(d) 某个机构的任何调查、要求或请求。</li>
              </ul>
              <p>
                G.
                使实际或潜在的受让人、我们全部或任何部分业务和/或资产的受让人或我们对您的权利的参与人或次级参与人能够评估打算作为转让、指派、参与或次级参与的交易。
              </p>
              <p>
                H.
                与我们捍卫或回应任何法律、政府或监管或准政府相关的事项、行动或诉讼（包括任何预期的行动或法律诉讼）有关，包括为了寻求专业意见、获得法律意见或建立、行使或捍卫法律权利而符合我们的合法利益。
              </p>
              <p>I. 在调查与保险有关的事项方面。</p>
              <p>J. 组织并向您提供研讨会。</p>
              <p>
                K.
                管理、监督和评估向我们提供行政、电信、计算机、支付或证券清算或其他服务的任何代理人、承包商或第三方服务提供商的表现，这些服务与建立、运营或维护任何Driver
                Assiatant产品或服务有关；以及
              </p>
              <p>L. 与此有关的任何其他目的。</p>
              <h3>3.你的数据的披露</h3>
              <p>
                我们所持有的数据是保密的，但如果有助于我们在上述
                &quot;你的数据的使用
                &quot;段落中所列的任何用途，我们可能会向其他各方提供、转让或披露这些数据或信息（无论在香港境内或境外*）。这些其他方包括
              </p>
              <p>
                A.
                向我们提供行政、电信、身份验证/知-你的-客、计算机、支付/交易、云存储或服务、数据分析、网络安全或证券清算或其他服务的任何组织、代理人、承包商或第三方服务提供商，这些服务与建立、运营、维护或向您提供任何万达应用产品或服务有关。
              </p>
              <p>
                B. 任何为我们（或A段中提到的任何一方）工作（或提供服务）的人。
              </p>
              <p>C. 任何对Driver Assistant负有保密义务的人。</p>
              <p>D. 您希望或已经与之打交道的任何金融机构或商户收单公司。</p>
              <p>
                E. Driver
                Assiatant对其负有义务的任何个人或组织（现在或将来可能存在）。
              </p>
              <p>
                F. 任何实际或拟议的Driver
                Assiatant全部或任何部分业务和/或资产的受让人或受让人，或Driver
                Assiatant对您的权利的参与者或次参与者或受让人。
              </p>
              <p>G. 慈善或非营利组织。</p>
              <p>
                H.
                我们聘请来提供营销服务的任何外部服务提供商（包括为该服务提供商工作的任何人）。
              </p>
              <p>
                I.
                链接到我们的产品和/或服务，或以任何方式提供有关信息的任何界面（如应用程序接口）。
              </p>
              <p>
                J. 第三方金融机构、保险公司、信用卡公司、证券和投资服务提供者。
              </p>
              <p>K. 第三方奖励、忠诚度、联合品牌和特权计划供应商。</p>
              <p>
                L.
                我们的联合品牌合作伙伴（这些联合品牌合作伙伴的名称可以在相关服务和产品的申请表中找到，视情况而定）。
              </p>
              <p>
                M. 直接或间接持有Driver Assistant及其关联公司（各为
                &quot;股东&quot;）股份的任何个人或公司（例如，了解您是否为其或其关联公司的客户，以及他们向您提供或可以提供哪些产品和服务），以及为股东工作（或向其提供服务）的任何人。
              </p>
              <p>N. 任何当局。</p>
              <p>
                O. 任何其他Driver Assistant客户，与你的使用Driver
                Assiatant产品和服务有关；以及
              </p>
              <p>
                P.
                任何其他人（1）在公共利益需要的情况下，或（2）在你的明示或默示同意的情况下。
              </p>
              <p>
                *这可能意味着你的数据在香港以外的地方被披露、转移、储存或处理。如果发生这种情况，那么我们可能需要遵守另一个国家关于个人数据的法律和要求。这些当事方可能位于以下国家。澳大利亚，德国，香港，印度，爱尔兰，日本，中国大陆，马来西亚，荷兰，菲律宾，新加坡，阿拉伯联合酋长国，英国，美国。
              </p>
              <h3>4.直接营销</h3>
              <p>
                我们希望在直接营销中使用你的数据，为此我们需要你的同意（包括表示不反对）。我们可能在直接营销中使用的数据包括。
              </p>
              <p>A.你的姓名和联系方式。</p>
              <p>B.你的人口统计学数据。</p>
              <p>C. Driver Assistant或任何股东向您提供的产品和服务。</p>
              <p>D.你的储蓄和支出模式及行为；以及</p>
              <p>E.你的财务背景。</p>
              <p>我们可以直接推销以下类别的服务、产品和主题。</p>
              <p>A. 金融、信托、银行及相关服务和产品。</p>
              <p>B. 奖励、忠诚或特权计划及相关服务和产品。</p>
              <p>C. 任何股东提供的服务和产品。</p>
              <p>
                D.
                我们的联合品牌伙伴提供的服务和产品（这些联合品牌伙伴的名称可在相关服务和产品的申请表中找到，视情况而定）；以及
              </p>
              <p>E. 用于慈善和/或非营利目的的捐赠和捐款。</p>
              <p>
                与我们一起，以下人员可以提供或征求（在捐赠和捐助的情况下）上述服务、产品和主题。
              </p>
              <p>A. 第三方金融机构。</p>
              <p>B. 第三方奖励、忠诚度、联合品牌或特权计划提供者。</p>
              <p>C. 我们的联合品牌合作伙伴；以及</p>
              <p>D. 慈善或非营利组织。</p>
              <p>
                我们也可能将你的数据提供给任何这些人，让他们用于直接向您推销相同的服务、产品和主题。为此，Driver
                Assiatant将首先获得你的同意（包括表示不反对）。我们可能会收到金钱或其他财产，作为向这些其他人提供你的数据的回报，但我们会在获得你的同意时告诉您是否如此。
              </p>
              <p>
                你可以改变你的，同意我们使用或向其他人提供你的，用于直接营销，如上所述。只要在任何时候让我们知道。
              </p>
              <h3>5.另一个人的个人数据</h3>
              <p>
                如果您向我们提供了另一个人的个人资料，您应该向他/她提供一份本PICS的副本，并告知他们我们可能会如何使用他/她的资料。
              </p>
              <h3>6.访问和纠正你的个人数据</h3>
              <p>根据法律规定，你有以下权利。</p>
              <p>A. 检查我们持有您的哪些资料，并向您提供一份资料的副本。</p>
              <p>B. 要求我们对任何不准确的数据进行修改。</p>
              <p>C. 撤销您之前对我们使用你的个人数据的任何同意。</p>
              <p>D. 了解我们关于数据的政策和做法。</p>
              <p>E. 被告知我们持有什么类型的数据以及您可以访问什么；以及</p>
              <p>
                如果你想行使任何这些权利，请参阅我们网站上的隐私政策页面，或让我们知道于Driver
                Assiatant，通过电子邮件发送至
                <button className={`${styles.linkButton}`} onClick={triggerWonderRoute}>support@wonder.app</button>
                ，或寄信至。
              </p>
              <p>数据保护官</p>
              <p>香港观塘海滨路77号码头大厦5楼05-181室</p>
              <p>
                我们有权为处理你提出的任何数据访问请求收取合理的费用。除非你要求将纸质记录寄给你，否则我们永远不会收取费用。
              </p>
              <h3>7.安全问题</h3>
              <p>
                一旦我们收到你的数据，我们会尽力保护它，因为你的个人数据的安全对我们很重要。我们有技术和组织上的安全措施来保护你的个人数据（包括运输和储存中的个人数据）。这些安全措施确保你的个人数据的保密性和完整性不受影响。多层的保护措施已经到位，以防止个人数据泄漏给外部各方。个人数据将通过强大的数据加密算法进行加密，使用我们特有的加密密钥并进行适当的密钥管理。在使用外部服务提供商时，我们要求他们遵守我们规定的某些安全标准（如适用）。无论个人数据被转移到哪里，我们都会采取一切合理的必要步骤，确保个人数据被安全地保存。
              </p>
              <p>
                您还应该知道，互联网（包括使用互联网传输数据的应用程序）可能不是一种安全的通信方式，通过互联网向我们发送个人数据可能会带来风险，包括未经授权的第三方访问和干扰的风险。通过互联网传输的信息可能会在国际范围内传输（即使发送方和接收方位于同一国家）于，这些国家的隐私和数据保护法律比你的居住国弱。
              </p>
              <p>
                Driver
                Assiatant会根据适用的法律和监管义务以及商业和运营目的保留个人数据。在大多数情况下，这将是在你的与我们的关系结束后的7年内。您可以要求我们提前永久删除部分或全部你的数据，但我们只能在以下情况下这样做。
              </p>
              <p>A. 我们没有法律或法规义务保留它；或</p>
              <p>B. 我们不需要它来提供你仍然希望我们为你提供的服务。</p>
              <p>
                如果我们不能在你提出要求后及时永久删除你的，请相信我们会让你知道。
              </p>
              <p>
                我们可能会记录和监测与您的电子通信，以确保遵守法律和监管义务以及内部政策。
              </p>
              <p>在使用我们的在线服务时，你也应该阅读我们的隐私政策。</p>
              <p>
                如果本Driver Assistant
                PICS的中英文版本有任何不一致的地方，则以英文版本为准。
              </p>
              <p>
                这个Driver Assistant
                PICS是根据香港《个人资料（私隐）条例》和《个人信贷资料实务守则》提供给你的。
              </p>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-HK' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title}`}>個人資料收集聲明</div>
            <div className={`${styles.content}`}>
              <p>
                司機助理應用程式將收集
                你的個人數據，以幫助我們作為服務提供者運營。從廣義上講，Driver
                Assiatant將收集，處理和存儲您提供給我們的個人數據（例如
                你的姓名、出生日期、身份證號碼、通訊位址、
                電話、電子郵件地址、國籍或「敏感」數據，例如
                你的面部圖像，以及您在與我們溝通時製作的視頻和錄音），以確保您和
                Driver Assistant
                的安全，履行我們的業務義務並遵守法律。這包括建立、維護和運營
                你的Driver Assistant Account或其他帳戶、Driver Assistant
                Card（以及您使用的任何其他 Driver Assistant
                產品或服務），還提供獎勵並舉辦比賽和遊戲。本聲明的規定構成帳戶條款及細則（包括適用於以下各項的條款）的一部分
                你的使用我們的應用程式）以及您與 Driver Assistant
                應用程式簽訂的任何其他協定或安排。
              </p>
              <p>
                我們收集
                你的數據，以便我們為您提供最好的服務。如果您不向我們提供我們要求您提供的個人數據，我們可能無法建立、維護或向您提供我們的產品和服務。
              </p>
              <p>
                我們還可能收集 你的數據，直接或間接來自
                你的在我們的正常業務過程中與 Driver Assistant 或通過 Driver
                Assistant
                進行交易，包括從第三方（公共領域）收到的資訊，通過以下方式收集
                你的使用我們的應用程式、網站、Cookie、行為或位置跟蹤工具、金融服務或
                Driver Assistant
                提供的其他服務和/或當您通過以下方式存款或執行交易時
                你的想知道應用程式卡。理解
                你的消費和儲蓄行為有助於我們向您提出建議，為您做出明智的財務決策，並説明您保持
                你的帳戶和數據安全。
              </p>
              <p>
                請注意，我們還收集數據以幫助我們遵守法律、法規、準則以及當局的要求或調查。
              </p>
              <h3>1. 我們從誰那裡收集數據</h3>
              <p>
                我們將收集與本聲明中所列目的相關的客戶和其他個人的個人數據。這些客戶和其他個人可能包括以下內容，我們將他們統稱為「您/你的“在此圖片中：
              </p>
              <p>A. Driver Assistant產品或服務的申請人或帳戶持有人;</p>
              <p>B. 客戶;和</p>
              <p>C. 與我們交易或通過我們進行交易的任何第三方。</p>
              <h3>2. 使用 你的數據</h3>
              <p>我們可能會使用 你的用於以下任何目的的資料：</p>
              <p>
                A. 考慮和處理 你的申請（包括評估其優點和/或適用性）
                你的應用程式） 用於 Driver Assistant 產品和服務;
              </p>
              <p>B. 運營、維護和通知您司機助理App的產品和服務;</p>
              <p>C. 開發、改進和設計司機助理App產品和服務;</p>
              <p>
                D.
                滿足我們的內部運營要求（包括信用和風險管理、系統或產品開發和規劃、執行測試和分析以及保險、審計和管理目的）;
              </p>
              <p>E. 行銷服務、產品及其他標的（見下文「直接促銷」）;</p>
              <p>
                F.
                滿足或遵守披露和使用適用於我們的數據的任何義務、要求或安排，包括我們或任何此類會員根據以下規定應遵守的義務、要求或安排：
              </p>
              <ul>
                <li>
                  （a）
                  香港境內外任何現時或未來的法律或規例（例如《稅務條例》及其條文，包括有關自動交換金融帳戶資料的條文）;
                </li>
                <li>
                  （b）
                  由香港境內外任何法律、監管、政府、稅務、執法或其他機關，或金融服務提供者的自律或行業團體或協會發出的任何現時或未來的指引或指引（例如稅務局提供或發出的指引或指引，包括有關自動交換金融帳戶資料的指引或指引）;
                </li>
                <li>
                  （c）
                  由於金融、商業、業務或其他利益或活動（均稱為“當局”）而與當地或外國法律、監管、政府、稅務、執法或其他當局，或適用於我們的金融服務提供者的自律或行業機構或協會，目前或將來的任何合同或其他承諾;或
                </li>
                <li>（d） 管理局的任何調查、要求或請求;</li>
              </ul>
              <p>
                G.
                使實際或潛在的受讓人、我們全部或任何部分業務和/或資產的受讓人，或我們與您有關的權利的參與者或次級參與者，能夠評估擬成為轉讓、轉讓、參與或再參與標的交易;
              </p>
              <p>
                H.
                就我們辯護或回應任何法律、政府、監管或準政府相關事項、行動或程式（包括任何未來的行動或法律程式）有關，包括尋求專業意見、獲取法律意見或確立、行使或捍衛合法權利符合我們的合法利益;
              </p>
              <p>I. 與調查保險相關事宜有關;</p>
              <p>J. 組織和舉辦研討會給您;</p>
              <p>
                K.
                管理、監控和評估任何代理、承包商或第三方服務提供者的表現，這些代理、承包商或第三方服務提供者向我們提供行政、電信、計算機、支付或證券結算或其他與建立、運營或維護任何
                Driver Assistant 產品或服務相關的服務;和
              </p>
              <p>L. 與此相關的任何其他目的。</p>
              <h3>3. 披露 你的數據</h3>
              <p>
                我們持有的資料會保密，但我們可能會向其他人士（不論在香港境內或境外*）提供、轉移或披露該等資料或資料，惟有關用途有助於「使用」
                你的數據「段落」。這些其他各方包括：
              </p>
              <p>
                A.
                任何提供行政、電訊、身份核實/知識的組織、代理、承辦商或第三方服務供應商你的-客戶、計算機、支付/交易、雲存儲或服務、數據分析、網路安全或證券清算或與我們建立、運營、維護或向您提供任何
                Driver Assistant 產品或服務相關的其他服務;
              </p>
              <p>
                B. 為我們工作（或向其提供服務）的任何人（或A段所述的任何一方）;
              </p>
              <p>C. 對司機助理App負有保密責任的任何人士;</p>
              <p>D. 您希望或已經與之打交道的任何金融機構或商戶收購公司;</p>
              <p>
                E. Driver Assistant
                對其負有義務的任何個人或組織（現在或將來可能存在）;
              </p>
              <p>
                F. Driver
                Assiatant全部或任何部分業務和/或資產的任何實際或擬議的受讓人或受讓人，或
                Driver Assistant對您的權利的參與者或次級參與者或受讓人;
              </p>
              <p>G. 慈善或非牟利機構;</p>
              <p>
                H.
                我們聘請來提供行銷服務的任何外部服務提供者（包括為此類服務提供者工作的任何人）;
              </p>
              <p>
                I.
                鏈接到我們的產品和/或服務或以任何方式提供有關我們的產品和/或服務的任何介面（例如應用程式程式設計介面）;
              </p>
              <p>
                J. 第三方金融機構、保險公司、信用卡公司、證券和投資服務提供者;
              </p>
              <p>K. 第三方獎勵、忠誠度、聯合品牌和特權計劃供應商;</p>
              <p>
                L.
                我們的聯合品牌合作夥伴（此類聯合品牌合作夥伴的名稱可在相關服務和產品的申請表中找到，視情況而定）;
              </p>
              <p>
                M.
                直接或間接持有司機助理App及其關聯公司（均稱為“股東”）的任何個人或公司（例如，瞭解您是否是其或其關聯公司的客戶，以及他們向您提供或可能向您提供哪些產品和服務），以及為股東工作（或向股東提供服務）的任何人;
              </p>
              <p>N. 任何授權;</p>
              <p>O. 與 你的使用司機助理應用程式產品和服務;和</p>
              <p>
                P. （1）在公共利益需要的情況下或（2）與 你的明示或默示同意。
              </p>
              <p>
                *這可能意味著
                你的數據在香港境外披露、轉移、儲存或處理。如果發生這種情況，我們可能需要遵守其他國家/地區有關個人數據的法律和要求。此類當事方可能位於以下國家/地區：澳大利亞、德國、香港、印度、愛爾蘭、日本、中國大陸、馬來西亞、荷蘭、菲律賓、新加坡、阿拉伯聯合大公國、英國、美利堅合眾國。
              </p>
              <h3>4. 直接促銷</h3>
              <p>
                我們想用 你的直接營銷中的數據，我們需要
                你的為此目的同意（包括表示不反對）。我們可能用於直接促銷的數據包括：
              </p>
              <p>一個。 你的姓名和聯繫方式;</p>
              <p>B. 你的人口統計數據;</p>
              <p>C. 司機助理App或任何股東向您提供的產品和服務;</p>
              <p>D. 你的儲蓄和支出模式和行為;和</p>
              <p>E. 你的財務背景。</p>
              <p>我們可能會直接行銷以下類別的服務、產品和主題：</p>
              <p>A. 金融、信託、銀行及相關服務及產品;</p>
              <p>b. 獎勵、忠誠或優惠計劃及相關服務和產品;</p>
              <p>C. 任何股東提供的服務和產品;</p>
              <p>
                D.
                我們的聯合品牌合作夥伴提供的服務和產品（此類聯合品牌合作夥伴的名稱可在相關服務和產品的申請表中找到，視情況而定）;和
              </p>
              <p>E. 用於慈善和/或非營利目的的捐贈和捐款。</p>
              <p>
                以下人士可與我們一同提供或招攬（在捐款及捐款的情況下）上述服務、產品及標的：
              </p>
              <p>A. 第三方金融機構;</p>
              <p>B. 第三方獎勵、忠誠度、聯合品牌或特權計劃供應商;</p>
              <p>C. 我們的聯合品牌合作夥伴;和</p>
              <p>D. 慈善或非營利組織。</p>
              <p>
                我們還可能提供
                你的數據提供給任何這些人，供他們用於直接行銷相同的服務，產品和主題。司機助理App將永遠首先獲得
                你的為此目的同意（包括表示不反對）。我們可能會收到金錢或其他財產作為提供
                你的數據給這些其他人，但我們會在獲取時告訴您是否屬於這種情況
                你的同意。
              </p>
              <p>
                您可以更改 你的關於同意我們使用或提供給他人的思想
                你的用於直接促銷的數據，如上所述。請隨時告知我們。
              </p>
              <h3>5. 他人的個人數據</h3>
              <p>
                如果您向我們提供了其他人的個人數據，您應向他/她提供本PICS的副本，並告知他們我們可能如何使用他/她的數據。
              </p>
              <h3>6. 訪問和更正 你的個人數據</h3>
              <p>根據法律，您擁有以下權利：</p>
              <p>A. 檢查我們持有您的哪些數據，並獲取其副本;</p>
              <p>B. 要求我們更改任何不準確的數據;</p>
              <p>C. 撤銷您之前就我們使用 你的個人數據;</p>
              <p>D. 瞭解我們在數據方面的政策和做法;</p>
              <p>E. 被告知我們持有何種數據以及您有權訪問哪些內容;和</p>
              <p>
                如果您想行使任何這些權利，請參閱我們網站上的隱私政策頁面或告知我們
                於Driver Assistant，通過電子郵件發送給{' '}
                <button  className={`${styles.linkButton}`} onClick={triggerWonderRoute}>support@wonder.app</button>
                ，或發送信件至：
              </p>
              <p>數據保護專員</p>
              <p>香港觀塘海垣道77號碼頭區5樓05-181室</p>
              <p>
                我們有權對處理您提出的任何數據訪問請求收取合理的費用。除非您要求將紙質記錄發送給您，否則我們絕不會收取費用。
              </p>
              <h3>7. 安全</h3>
              <p>
                一旦我們收到 你的數據，我們將盡最大努力保護它，因為
                你的個人數據對我們很重要。我們有技術和組織安全措施來保障
                你的個人數據（包括傳輸和存儲中的個人數據）。這些安全措施確保了其機密性和完整性
                你的個人數據不會受到損害。已經實施了多層保護措施，以防止個人數據洩露給外部各方。個人數據將通過強大的數據加密演算法進行加密，這些演算法使用我們獨有的加密密鑰並進行適當的密鑰管理。使用外部服務提供者時，我們要求他們遵守我們規定的某些安全標準（如適用）。無論個人數據被轉移到何處，我們都會採取一切合理必要的措施來確保個人數據得到安全保存。
              </p>
              <p>
                您還應該意識到，互聯網（包括使用互聯網進行數據傳輸的應用程式）可能不是一種安全的通信形式，通過互聯網向我們發送個人數據可能會帶來風險，包括未經授權的第三方訪問和干擾的風險。透過互聯網傳遞的資訊可能會在國際上傳輸（即使寄件人和收件者位於同一國家/地區）
                於隱私和數據保護法律較弱的國家 你的居住國。
              </p>
              <p>
                Driver Assistant
                根據適用的法律和監管義務以及出於業務和運營目的保留個人數據。在大多數情況下，這將是從結束到7年
                你的與我們的關係。您可以要求我們永久刪除部分或全部
                你的數據早於此，但我們只能在以下情況下執行此操作：
              </p>
              <p>A. 我們沒有法律或監管義務保留它;或</p>
              <p>B. 我們不需要它來提供您仍然希望我們為您提供的服務。</p>
              <p>
                如果我們無法永久刪除 你的數據在您詢問我們後立即，請務必告知您。
              </p>
              <p>
                我們可能會記錄和監控與您之間的電子通信，以確保遵守法律和監管義務以及內部政策。
              </p>
              <p>使用我們的在線服務時，您還應該閱讀我們的隱私政策。</p>
              <p>
                如本司機助理App圖片的中、英文版本有任何歧異，概以英文版本為準。
              </p>
              <p>
                此司機助理應用程式圖片是根據香港的《個人資料（私隱）條例》及《消費者信貸資料實務守則》提供給閣下的。
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PersonalInformationCollectionStatement;
