import React from 'react';
import type { IIconProps } from '@/types/common-types';

const NextIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 16 } = props;
  return (
    <div className={`flex items-center ${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.002 9.71477L3.66862 9.71477M16.002 9.71477L10.7162 4.42906M16.002 9.71477L10.7162 15.0005"
          stroke="currentColor"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default NextIcon;
