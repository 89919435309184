import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import type { ColumnsType } from 'antd/es/table';
import { Dropdown, Menu } from 'antd';
import ArchivePayee from '../components/archive-modal';
import Table from '@/components/table';
import Button from '@/components/common/button';
import AddIcon from '@/components/svg/add-icon';
import { getAvatarsName, getCookie } from '@/utils';
import { Constants } from '@/constants';
import Config from '@/libs/config';
import api from '@/libs/api';
import EllipsisIcon from '@/components/svg/ellipsis-icon';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getCountryByCurrency } from '@/utils/payouts-utils';
import { TableRecord } from '@/components/table/interface';
import { useTranslation } from 'react-i18next';
import { IReducersState } from '@/reducers';
import { useDispatch, useSelector } from 'react-redux';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';

const MenuItem = Menu.Item;

const PayeesListPage = () => {
  const viewId = 'payees';
  const permissions = useSelector((state: IReducersState) => state.businessState.permissions);
  const [records, setRecords] = useState<TableRecord[]>([]);
  const [archiveRecord, setArchiveRecord] = useState<TableRecord>({});
  const [showArchive, setShowArchive] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [recordLoading, setRecordLoading] = useState<boolean>(true);
  const prefix = Config.staticPath || '';
  const { t: ct } = useTranslation('common');
  const { t } = useTranslation('payees');
  const { lang = 'en', p_business_id: businessID = '' } = useParams();
  const dateFormat = lang === 'en' ? 'MMM DD, YYYY' : 'MMM DD日, YYYY';
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();

  useEffect(() => {
    loadRecords();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          key: '1',
          name: ct('payouts'),
          type: 'label',
        },
        {
          key: '2',
          name: ct('payees'),
          type: 'label',
        },
      ]
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  const columns: ColumnsType<TableRecord> = useMemo(() => {
    return [
      {
        key: 'nickname',
        title: t('nickname'),
        dataIndex: 'id',
        width: 200,
        render: (_key, record) => {
          return (
            <div className="flex items-center">
              <div>
                <span className={`inline-flex items-center justify-center h-12 w-12 ${record.nickname ? 'bg-primary-color/10' : ''} rounded-full`}>
                  <span className="font-semibold text-color-text-1 text-lg leading-6">
                    {getAvatarsName(record.nickname)}
                  </span>
                </span>
              </div>
              <div className="font-medium text-sm text-color-text-1 ml-2">
                {record.nickname}
              </div>
            </div>
          );
        },
      },
      {
        key: 'type',
        title: t('type'),
        dataIndex: 'type',
        width: 120,
        render: (_key, record) => {
          const { type } = record;
          let color = '';
          let value = '';
          if (type === 'Business') {
            color = 'text-color-text-7 bg-color-text-7';
            value = t('business');
          } else if (type === 'People') {
            color = 'text-primary-color bg-primary-color';
            value = t('people');
          }
          return (
            <div className="flex">
              <div
                className={`py-1 text-xs px-3 rounded-3xl ${color} bg-opacity-10 font-medium`}
              >
                {value}
              </div>
            </div>
          );
        },
      },
      {
        key: 'company_name',
        title: t('destination_country'),
        dataIndex: 'company_name',
        width: 180,
        render: (_key, record) => {
          const { payout_accounts } = record;
          const { country_codes } = payout_accounts;
          const { alpha_2, country_name } = country_codes;
          return (
            <div className="flex">
              <div className="flex items-center w-4 mr-1">
                <img
                  width="16px"
                  height="16px"
                  src={`${prefix}/images/country-flag/${
                    alpha_2 ? alpha_2.toLocaleLowerCase() : 'hk'
                  }.svg`}
                  alt=""
                />
              </div>
              <div className="text-color-text-1 flex-1">{country_name}</div>
            </div>
          );
        },
      },
      {
        key: 'p_business_id',
        title: t('destination_currency'),
        dataIndex: 'p_business_id',
        width: 180,
        render: (_key, record) => {
          const { payout_accounts } = record;
          const { currency } = payout_accounts;
          return (
            <div className="flex">
              <div className="flex items-center relative w-4 mr-1">
                <img
                  width="16px"
                  height="16px"
                  src={`${prefix}/images/country-flag/${getCountryByCurrency(
                    currency
                  )}.svg`}
                  alt=""
                />
              </div>
              <div className="text-color-text-1 flex-1">{currency}</div>
            </div>
          );
        },
      },
      {
        key: 'id',
        title: t('transfer_method'),
        dataIndex: 'id',
        width: 160,
        render: (_key, record) => {
          const { payout_accounts } = record;
          const { transfer_method } = payout_accounts;
          return transfer_method;
        },
      },
      {
        key: 'p_created_at',
        title: t('created_at'),
        dataIndex: 'p_created_at',
        align: 'right',
        width: 126,
        render: (value, _record) => {
          let time = value;
          if (time && time.indexOf('T') > -1 && time.indexOf('Z') < 0) {
            time = `${time}Z`;
          }
          if (time) {
            return (
              <span className="whitespace-nowrap">
                {dayjs(time).format(dateFormat)}
                <br />
                {dayjs(time).format('HH:mm:ss')}
              </span>
            );
          } else {
            return <span></span>;
          }
        },
      },
      {
        title: ct('actions'),
        key: 'action',
        fixed: 'right',
        align: 'center',
        width: 88,
        render: (_value, record) => {
          return (
            <Dropdown
              destroyPopupOnHide={true}
              dropdownRender={() => (
                <Menu className="bg-white">
                  <MenuItem key="view">
                    <Link
                      to={`/${lang}/businesses/${businessID}/${viewId}/${record.id}/information`}
                    >
                      <div className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5">
                        <span className="text-color-text-1 text-sm font-medium">
                          {ct('view')}
                        </span>
                      </div>
                    </Link>
                  </MenuItem>
                  {permissions.includes('payees:update') && (
                    <MenuItem key="edit">
                      <Link
                        to={`/${lang}/businesses/${businessID}/${viewId}/${record.id}/information?isEdit=true`}
                      >
                        <div className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5">
                          <span className="text-color-text-1 text-sm font-medium">
                            {ct('edit')}
                          </span>
                        </div>
                      </Link>
                    </MenuItem>
                  )}
                  {permissions.includes('payees:delete') && (
                    <MenuItem key="archive">
                      <div
                        className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                        onClick={() => {
                          setArchiveRecord(record);
                          setShowArchive(true);
                        }}
                      >
                        <span className="text-error-color text-sm font-medium">
                          {t('archive')}
                        </span>
                      </div>
                    </MenuItem>
                  )}
                </Menu>
              )}
            >
              <div className="justify-center flex">
                <div className="flex items-center cursor-pointer p-1.5 rounded-full w-max hover:bg-primary-color/10 group">
                  <EllipsisIcon className="text-icon-color group-hover:text-primary-color" />
                </div>
              </div>
            </Dropdown>
          );
        },
      },
    ];
    // eslint-disable-next-line
  }, [lang]);

  const loadRecords = async (
    page = 1,
    pageSize = 50,
    type = 'All',
    transferMethod = 'All',
    keyword?: string
  ) => {
    setRecordLoading(true);
    const token = getCookie(Constants.TOKEN) || '';
    let records: any[] = [];
    const offset = (page - 1) * pageSize;
    let url = `${
      Config.urls.gatewayUrl
    }/api/treasury-services/api/payees?limit=${
      pageSize + 1
    }&offset=${offset}&type=${type}`;
    if (transferMethod !== 'All') {
      url = `${url}&transfer_method=${transferMethod}`;
    }
    if (keyword) {
      url = `${url}&keyword=${keyword}`;
    }
    try {
      const result = await api.get(url, {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
        },
      });
      const { data } = result || {};
      if (data && Array.isArray(data)) {
        records = data;
      }
    } catch (error) {
      console.error(error);
    }
    setPage(page);
    setPageSize(pageSize);
    setRecords(records);
    setRecordLoading(false);
  };

  const handleNew = () => {
    navigate(`/${lang}/businesses/${businessID}/${viewId}/new`);
  };

  return (
    <>
      <div className="flex overflow-hidden h-full">
        <div className="bg-system-background-color pt-5 px-3 pb-2.5 md:px-5 md:py-6 lg:px-8 w-full flex flex-col">
          <div className="md:flex justify-between">
            <div className="text-xl leading-[30px] md:text-3xl md:leading-10 font-bold text-color-text-1 mb-4 md:mb-0">
              {ct('payees')}
            </div>
            <div className="flex flex-col-reverse md:flex-row">
              <div className="flex gap-x-4 justify-start">
                {permissions.includes('payees:create') && (
                  <Button
                    iconInstance={<AddIcon />}
                    type="button"
                    onClick={handleNew}
                  >
                    {t('new_payee')}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col overflow-hidden">
            <Table
              className="my-6"
              columns={columns}
              records={records}
              loadingData={recordLoading}
              pagination={{
                current: page,
                pageSize,
                currentSize: records.length,
                changePageCallback: loadRecords,
              }}
              onRowCell={(record, dataKey) => {
                return {
                  onClick: (evt) => {
                    evt.stopPropagation();
                    if (dataKey === 'action') return;
                    if (record.id) {
                      navigate(
                        `/${lang}/businesses/${businessID}/${viewId}/${record.id}/information`
                      );
                    }
                  },
                };
              }}
            />
          </div>
        </div>
      </div>
      {showArchive && archiveRecord && (
        <ArchivePayee
          payee={archiveRecord}
          businessID={businessID}
          onCancel={() => setShowArchive(false)}
          callback={() => {
            loadRecords(page, pageSize);
          }}
        />
      )}
    </>
  );
};

export default PayeesListPage;
