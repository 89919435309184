import Button from '@/components/common/button';
import { IAreaCodes } from '@/types/common-types';
import type { ICartParams } from '../../../types/terminal-type';
import type { IObject } from '@/types/common-types';
import { handlePrice, insetHead, triggerWonderRoute } from '../../../utils/terminal-untils';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import NewAddIcon from '../terminal-icons/newadd-icon';
import ReduceIcon from '../terminal-icons/reduce-icon';
import ClockIcon from '../terminal-icons/clock-icon';
import RightIcon from '../terminal-icons/right-icon';
import Input from '@/components/common/terminal-input';
import Label from '@/components/common/label';
import Textarea from '@/components/common/textarea';
import ErrorIcon from '../terminal-icons/error-icon';
import DefaultItemIcon from '../terminal-icons/default-item-icon';
import CheckIcon from '../terminal-icons/address-check-icon';
import Skeleton from '@/components/skeleton';
import { getCookie } from '@/utils';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';

import type {
  IAddressObject,
  ITerminalOrdersCheckPageProps,
} from '../../../types/terminal-type';
import NetworkError from '../components/network-error';

interface ICheckOrderWebPage extends ITerminalOrdersCheckPageProps {
  cart: ICartParams;
  code: string;
  currency: string;
  areaCodes: IAreaCodes[];
  token: string;
  nodeValue: string;
  customerID: string;
  codePass: boolean;
  loading: boolean;
  dataTimeOut: boolean;
  cartLoading: boolean;
  addressList: IAddressObject[];
  selectedAddress?: IAddressObject;
  deliverySchedule: string[];
  loadAddress: () => void;
  handleCheckout: () => void;
  handleBack: () => void;
  handleCodeChange: (value: string) => void;
  handleNodeChange: (e: any) => void;
  handleMinus: (record: IObject) => void;
  handlePlus: (record: IObject) => void;
  setDeliverySchedule: (value: string[]) => void;
}

const ConfirmOrderInformation: React.FC<ICheckOrderWebPage> = (props) => {
  const {
    businessID,
    cart,
    code,
    currency,
    loading,
    codePass,
    token,
    inApp,
    cartLoading,
    selectedAddress,
    deliverySchedule,
    dataTimeOut,
    tokenExpire = false,
    loadAddress,
    handleCodeChange,
    handleMinus,
    handlePlus,
    handleCheckout,
    handleNodeChange,
    setDeliverySchedule
  } = props;

  const navigate = useNavigate();
  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const time = params.get('time');
  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const [isKeyBoard, setisKeyBoard] = useState(false);
  const [isFocus, setIsFous] = useState(false);
  const noteValue = getCookie('noteValue') as string;
  const pageView = useRef<any>(null);
  let count = 0;
  const { lang = 'en' } = useParams();
  const dateFormat = lang === 'en' ? 'MMM D' : 'MM月DD日';

  cart.listings.forEach((item) => {
    count += item.cartQuantity || 0;
  });

  useEffect(() => {
    if (tokenExpire) {
      triggerWonderRoute('true', 'is401');
    }
    window.addEventListener(
      'myCustomEvent',
      (e: any) => {
        if (e.detail.delivery_schedule) {
          const arr = [];
          arr.push(e.detail.delivery_schedule.split(',')[0]);
          arr.push(e.detail.delivery_schedule.split(',')[1]);
          setDeliverySchedule(arr);
        }
        if (e.detail.isLoading === 'true') {
          loadAddress();
        }
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener(
      'myCustomEvent',
      (e: any) => {
        if (e.detail.is_keyboard === 'true') {
          setisKeyBoard(true);
          if (isFocus) {
            setTimeout(() => {
              pageView.current.scrollTo(0, 650);
            }, 100);
          }
        }
        if (e.detail.is_keyboard === 'false') {
          setisKeyBoard(false);
        }
      },
      false
    );
  }, [isFocus]);

  const goShopAddress = () => {
    if (cartLoading) return;
    const routeAddress = `/businesses/${businessID}/address?token=${token}&isApp=${inApp}&time=${deliverySchedule}`;
    if (inApp) {
      triggerWonderRoute(routeAddress, 'route');
    } else {
      navigate(routeAddress);
    }
  };

  const alert = (vlaue: any, action: any) => {
    triggerWonderRoute(vlaue, action);
  };

  const isToday = (time: any) => {
    const today = new Date();
    const yesterday = new Date().setDate(today.getDate() - 1);
    const nowDate = dayjs(today).format(dateFormat);
    const yesterdayDate = dayjs(yesterday).format(dateFormat);
    const itemDate = dayjs(time).format(dateFormat);
    if (nowDate === itemDate) {
      return t('extended_service:today') + ' ' + itemDate;
    } else if (yesterdayDate === itemDate) {
      return t('extended_service:yesterday') + ' ' + itemDate;
    } else {
      return itemDate;
    }
  };

  const handleFocus = () => {
    setIsFous(true);
  };

  const handleBlur = () => {
    setIsFous(false);
  };

  const handleTouchStart = (event: any) => {
    const targetElement = event.target;
    targetElement.parentNode.style.opacity = '50%';
  };

  const handleTouchEnd = (event: any) => {
    const targetElement = event.target;
    targetElement.parentNode.style.opacity = '100%';
  };

  const handleMinusCheck = (item: any) => {
    handleMinus(item);
  };

  const handleAddCheck = (item: any) => {
    handlePlus(item);
  };

  const handleTimeClick = debounce(() => {
    alert('delivery_scheule', 'bootom_sheet');
  }, 200);

  const checkOut = () => {
    if (cartLoading) { return }
    handleCheckout()
  }

  return (
    <div
      className="min-h-screen bg-[#F5F5F5] overflow-auto"
      style={{ WebkitTapHighlightColor: 'transparent' }}
    >
      {insetHead()}
      <div
        className={`absolute inset-0 h-full flex flex-col font-sfPro overflow-x-hidden overflow-y-auto lg:hidden hidden_scrollbar pb-16`}
        ref={pageView}
      >
        <div className={`bg-[#F5F5F5] flex-1 hidden_scrollbar`}>
          {loading && (
            <div className="p-5 pb-24">
              <Skeleton
                row={1}
                width={['100%']}
                skeletonItemClass={`h-[21px] !w-[227px] rounded-2xl mb-0 bg-[#D3CFCF]`}
              />
              <div className="h-[140px] bg-white px-4 mt-4 rounded-xl ">
                <div className="border-b-[1px] border-[#F5F5F5] py-5">
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-[17px] !w-[124px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                    className={'!h-[17px]'}
                  />
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-[17px] !w-[311px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                    className={'!h-[17px] mt-4'}
                  />
                </div>
                <div className="flex justify-between mt-4">
                  <Skeleton
                    row={1}
                    className={'w-[60px] mt-1'}
                    skeletonItemClass={`h-[10px] !w-[100px] rounded-2xl  bg-[#D3CFCF]`}
                  />
                  <Skeleton
                    row={1}
                    className={'w-[60px] ml-14'}
                    skeletonItemClass={`h-[17px] !w-[124px] rounded-2xl  bg-[#D3CFCF]`}
                  />
                </div>
              </div>
              <Skeleton
                row={1}
                width={['100%']}
                skeletonItemClass={`h-[21px] !w-[227px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                className={'mt-[24px]'}
              />
              <div className="h-[256px] bg-white px-4 mt-4 rounded-xl overflow-hidden">
                <Skeleton
                  avatar={true}
                  row={2}
                  className="items-center !h-[96px] mt-5"
                  width={['100%']}
                  avatarClass="h-[80px] w-[80px] mr-3.5 bg-[#D3CFCF] !rounded-2xl"
                  skeletonItemClass="h-[17px]  mb-1.5 last:mb-0 rounded-[50px] w-[200px] mt-[12px] last:mt-8 bg-[#D3CFCF]"
                />
                <Skeleton
                  avatar={true}
                  row={2}
                  className="items-center !h-[96px] mt-4"
                  width={['100%']}
                  avatarClass="h-[80px] w-[80px] mr-3.5 bg-[#D3CFCF] !rounded-2xl"
                  skeletonItemClass="h-[17px]  mb-1.5 last:mb-0 rounded-[50px] w-[200px] mt-[12px] last:mt-8 bg-[#D3CFCF]"
                />
              </div>
              <Skeleton
                row={1}
                width={['100%']}
                skeletonItemClass={`h-[21px] !w-[227px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                className={'mt-[24px]'}
              />
              <div className="h-[225px] bg-white px-4 mt-4 rounded-xl overflow-hidden">
                <Skeleton
                  row={1}
                  width={['100%']}
                  skeletonItemClass={`h-[17px] !w-[124px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                  className={'!h-[17px] mt-4'}
                />
                <Skeleton
                  row={1}
                  width={['100%']}
                  skeletonItemClass={`h-[17px] !w-[311px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                  className={'!h-[17px] mt-4'}
                />
                <div className="border-[1px] border-solid rounded-2xl border-[#0094FF] px-4 py-2 text-base h-[127px] mt-4 ">
                  <div className="flex justify-between mt-2">
                    <Skeleton
                      row={1}
                      className={'w-[60px]'}
                      skeletonItemClass={`h-[10px] !w-[60px] rounded-2xl  bg-[#D3CFCF]`}
                    />
                    <Skeleton
                      row={1}
                      className={'w-[60px] ml-36'}
                      skeletonItemClass={`h-[10px] !w-[60px] rounded-2xl  bg-[#D3CFCF]`}
                    />
                  </div>
                  <div className="flex justify-between mt-4">
                    <Skeleton
                      row={1}
                      className={'w-[60px]'}
                      skeletonItemClass={`h-[10px] !w-[60px] rounded-2xl  bg-[#D3CFCF]`}
                    />
                    <Skeleton
                      row={1}
                      className={'w-[60px] ml-16'}
                      skeletonItemClass={`h-[10px] !w-[100px] rounded-2xl  bg-[#D3CFCF]`}
                    />
                  </div>
                  <div className="flex justify-between mt-4">
                    <Skeleton
                      row={1}
                      className={'w-[60px]'}
                      skeletonItemClass={`h-[10px] !w-[60px] rounded-2xl  bg-[#D3CFCF]`}
                    />
                    <Skeleton
                      row={1}
                      className={'w-[60px] ml-36'}
                      skeletonItemClass={`h-[10px] !w-[60px] rounded-2xl  bg-[#D3CFCF]`}
                    />
                  </div>
                  <div className="flex justify-between mt-4">
                    <Skeleton
                      row={1}
                      className={'w-[60px]'}
                      skeletonItemClass={`h-[17px] !w-[91px] rounded-2xl  bg-[#D3CFCF]`}
                    />
                    <Skeleton
                      row={1}
                      className={'w-[60px] ml-6'}
                      skeletonItemClass={`h-[17px] !w-[124px] rounded-2xl  bg-[#D3CFCF]`}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {!loading && !dataTimeOut && (
            <div className="p-5 pb-24">
              <div className="text-colot-text-1 font-semibold text-lg mt-1 mb-2 flex justify-between">
                {t('extended_service:shipping_address')}
              </div>

              <div className="bg-primary-background-color rounded-xl p-4 mt-2">
                <div>
                  {!selectedAddress && !loading && (
                    <div>
                      <Button className="rounded-xl" onClick={goShopAddress}>
                        {t('extended_service:add_address')}
                      </Button>
                      <div className="text-sm mt-2">
                        {t('extended_service:add_address_tips')}
                      </div>
                    </div>
                  )}

                  {selectedAddress && !loading && (
                    <div className="text-base" onClick={goShopAddress}>
                      <div className="flex justify-between items-center font-semibold">
                        <span>{selectedAddress.name || ''}</span>
                        <RightIcon />
                      </div>
                      <div
                        className={`max-w-[160px] truncate mt-1 font-normal`}
                      >
                        {selectedAddress.phone || ''}
                      </div>
                      <div className={`mt-1 font-normal`}>
                        {selectedAddress.address || ''}{' '}
                        {selectedAddress.street || ''}
                      </div>
                      <div className={`font-normal`}>
                        {/* {selectedAddress.city || ''} */}
                        {t('extended_service:hong_kong')}
                      </div>
                    </div>
                  )}
                  <div className="w-full h-1 border-t-[1px] my-4 border-border-color" />
                </div>
                <div className="flex justify-between items-center font-normal" onClick={handleTimeClick}>
                  <div className="text-[#8E8E93]">
                    {t('extended_service:delivery_schedule')}
                    <span className="text-[red]">*</span>
                  </div>
                  <div className={` max-w-[160px] truncate  flex items-center`}>
                    <div>
                      <div className="text-right">
                        {isToday(deliverySchedule[0])}
                      </div>
                      <div>{deliverySchedule[1]}</div>
                    </div>
                    <ClockIcon />
                  </div>
                </div>
              </div>

              <div className="text-colot-text-1 font-semibold text-lg mt-5 mb-2 flex justify-between">
                {t('extended_service:ordered_item_service')}
              </div>

              <div
                className={`px-4 py-4 bg-primary-background-color rounded-2xl`}
              >
                {cart.listings.map((item: any) => (
                  <div className="flex mt-[14px] pb-[14px]" key={item.id}>
                    <div className="p-2 bg-disabled-color rounded-2xl">
                      {item.imageUrl && (
                        <div
                          className="w-[72px] h-[72px] flex justify-center items-center bg-center bg-no-repeat bg-contain"
                          style={{ backgroundImage: `url(${item.imageUrl})` }}
                        />
                      )}
                      {!item.imageUrl && (
                        <div
                          style={{
                            backgroundColor: 'rgba(226, 228, 231, 0.15)',
                          }}
                          className="w-[72px] h-[72px] flex justify-center items-center rounded"
                        >
                          <DefaultItemIcon />
                        </div>
                      )}
                    </div>
                    <div className="ml-2 flex-1 relative">
                      <div className="w-[85%] text-base font-normal">
                        {item.name}
                      </div>
                      <div className=" absolute bottom-0 left-0 font-semibold text-sm">
                        <div>{handlePrice(item.price)} HKD</div>
                      </div>
                      {item.name.includes('Smart POS PAX') && (
                        <div
                          className="absolute right-0 top-0 w-[14px] h-[14px] rounded-full bg-[#0094FF] text-white text-center leading-[14px] text-xs"
                          onClick={() => alert('terminal_fee', 'alert')}
                        >
                          i
                        </div>
                      )}
                      <div
                        className={`absolute right-0 bottom-0 flex w-24  ${!item.cartQuantity ? 'justify-end' : 'justify-between'
                          }`}
                      >
                        <div
                          onTouchStart={handleTouchStart}
                          onTouchEnd={handleTouchEnd}
                          className="duration-300"
                          style={{
                            WebkitAppearance: 'none',
                            WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                          }}
                        >
                          <ReduceIcon
                            onClick={() => handleMinusCheck(item)}
                            className={`${item.cartQuantity > 0 ? '' : 'hidden'
                              }`}
                          />
                        </div>

                        <div
                          className={`${item.cartQuantity > 0 ? '' : 'hidden'
                            } text-base leading-7`}
                        >
                          {item.cartQuantity}
                        </div>
                        <div
                          onTouchStart={handleTouchStart}
                          onTouchEnd={handleTouchEnd}
                          className="duration-300"
                          style={{
                            WebkitAppearance: 'none',
                            WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                          }}
                        >
                          <NewAddIcon
                            onClick={() => {
                              if (item.cartQuantity > 4) {
                                return;
                              }
                              handleAddCheck(item);
                              // debounce(() => {
                              //   handlePlus(item);
                              // }, 500);
                            }}
                          />
                        </div>
                        {item.cartQuantity > 4 && (
                          <div className="absolute bottom-[-28px] right-0 w-[200px] text-right text-[#FC2E01] flex items-center justify-end">
                            <div className=" w-[14px] h-[14px] rounded-full bg-[#FC2E01] text-white text-center leading-[14px] text-xs mr-1">
                              i
                            </div>
                            <div>{t('extended_service:maximum')}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="text-colot-text-1 font-semibold text-lg mt-5 mb-2 flex justify-between">
                {t('extended_service:payment_information')}
              </div>

              <div className="px-4 py-5 bg-primary-background-color rounded-2xl mt-2 text-base">
                <div className=" w-full lg:w-1/2 relative">
                  <Label
                    value={t('extended_service:promotion_code')}
                    className="!text-[black] !text-base !font-normal"
                  />
                  <Input
                    warpClass={`h-12 rounded-2xl bg-[#F5F5F5] !border-[#F5F5F5]  !shadow-none  ${code.length > 0
                      ? codePass
                        ? '!border-[#F5F5F5]'
                        : '!border-rose-600'
                      : ''
                      }`}
                    value={code}
                    placeholder={t('extended_service:enter_promotion_code')}
                    onChange={(value) => handleCodeChange(value)}
                    allowClear={false}
                  />
                  {code.length > 0 && !codePass && (
                    <div className="flex  text-error-color mt-2">
                      <ErrorIcon className="mt-1" />
                      <span className="text-sm ml-[2px]  font-normal">
                        {t('extended_service:promotion_code_error_tips')}
                      </span>
                    </div>
                  )}
                  {code.length > 0 && codePass && (
                    <div className="flex items-center text-error-color absolute top-[40px] right-[16px]">
                      <CheckIcon className="z-[999]" />
                    </div>
                  )}
                </div>
                <div className="border-[1px] border-solid mt-[14px] solid rounded-2xl border-[#0094FF] px-4 py-2 text-base">
                  <div className="flex justify-between items-center mt-1">
                    <span className="text-[#8E8E93]">
                      {t('extended_service:total_items')}
                    </span>
                    <span className={` max-w-[160px] truncate `}>
                      {' '}
                      {count} {t('extended_service:items')}
                    </span>
                  </div>
                  <div className="flex justify-between items-center mt-1">
                    <span className="text-[#8E8E93]">
                      {t('extended_service:subtotal')}
                    </span>
                    <span className={` max-w-[160px] truncate `}>
                      {handlePrice(cart.origin_total)} {currency}
                    </span>
                  </div>
                  <div className="flex justify-between items-center mt-1">
                    <span className="text-[#8E8E93]">
                      {t('extended_service:delivery_fee')}
                    </span>
                    <span className={` max-w-[160px] truncate `}>
                      {handlePrice(cart.delivery_fee)} {currency}
                    </span>
                  </div>
                  {cart.discount_total > 0 && (
                    <div className="flex justify-between items-center mt-1 ">
                      <span className="text-[#8E8E93]">
                        {t('extended_service:discount')}
                      </span>
                      <span className={` max-w-[160px] truncate `}>
                        - {handlePrice(cart.discount_total)} {currency}
                      </span>
                    </div>
                  )}
                  <div className="flex justify-between items-center mt-1 font-semibold">
                    <span>{t('extended_service:total')}</span>
                    <span className={` max-w-[160px] truncate `}>
                      {handlePrice(cart.total)} {currency}
                    </span>
                  </div>
                </div>
              </div>

              <div className="text-colot-text-1 font-semibold text-lg mt-5 mb-2 flex justify-between">
                {t('extended_service:note')}
              </div>

              <div className="px-4 py-4 bg-primary-background-color rounded-2xl mt-2">
                <div className=" w-full lg:w-1/2">
                  <Textarea
                    placeholderStyle={'placeholder:text-[#8E8E93]'}
                    warpClass={
                      '!bg-[#F5F5F5] !border-[#F5F5F5] h-[92px] rounded-2xl'
                    }
                    onChange={handleNodeChange}
                    placeholder={t('extended_service:enter_note_here')}
                    maxLength={120}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    value={noteValue}
                  />
                </div>
              </div>
            </div>
          )}
          {isKeyBoard && <div className="h-[200px]"></div>}

          {/* <ClockIcon
                     onClick={() => alert('delivery_scheule', 'bootom_sheet')}
                   /> */}
          {!dataTimeOut && (
            <div className="fixed bottom-0 w-[100%] bg-primary-background-color z-[999]">
              <div className="flex  items-center px-4 lg:shadow-none lg:px-10 w-full  pb-[24px] pt-[22px] ">
                {(count < 1 || !selectedAddress || cartLoading) && (
                  <div className=" bg-[#0094FF] w-full h-12 rounded-xl font-sfPro text-white hover:text-color-text-6  opacity-50  flex justify-center items-center">
                    <span className="text-base font-semibold">
                      {t('extended_service:checkout')}
                    </span>
                  </div>
                )}
                {count >= 1 && selectedAddress && !cartLoading && (
                  <>
                    <div
                      className=" bg-[#0094FF] w-full h-12 rounded-xl font-sfPro text-white hover:text-color-text-6  flex justify-center items-center duration-300"
                      onClick={checkOut}
                      onTouchStart={handleTouchStart}
                      onTouchEnd={handleTouchEnd}
                    >
                      <span className="text-base font-semibold">
                        {t('extended_service:checkout')}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          {dataTimeOut && (
            <NetworkError refresh={() => loadAddress()} />
          )}
        </div>



      </div>
    </div>
  );
};

export default ConfirmOrderInformation;
