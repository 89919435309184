import type { IObject } from '@/types/common-types';
import { useEffect, useState } from 'react';
import Table from '@/components/table';
import { Transition } from '@headlessui/react';
import { getActivities } from '@/data/account-balance';
import { useTranslation } from 'react-i18next';
import {
  HadleGoTranscationDetails,
  parseGetActivitiesParams,
} from '../../utils/account-balance-untils';
import type { ColumnsType } from 'antd/es/table';
import type { TableRecord } from '@/components/table/interface';
import type {
  IFilterField,
  IFilterOptions,
} from '@/components/common/filter';
import Filter, {
  FilterOperation,
  IFilterType,
} from '@/components/common/filter';
import { getCurrentAccountColumns } from '../account-balance/account-balance-data';
import { useParams, useNavigate } from 'react-router-dom';


interface IBusinessWalletsUpcomingProps {
  recordId: string;
  businessID: string;
  filterFields: IFilterField[];
}

const BusinessWalletsUpcoming = (props: IBusinessWalletsUpcomingProps) => {
  const { businessID, recordId, filterFields } = props;
  const { t } = useTranslation(["global_accounts", "account_balance"]);
  const { lang = 'en' } = useParams();
  const upcomingFilterFields = [...filterFields];
  upcomingFilterFields[0] = {
    name: 'settlement_date',
    label: t('account_balance:est_settled_on'),
    type: IFilterType.DATE,
    operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
  };

  const [activitiesEntity, setActivities] = useState<IObject[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<IFilterOptions[]>([]);
  const [columns, setColumns] = useState<ColumnsType<TableRecord>>([]);
  const navigate = useNavigate();


  useEffect(() => {
    getColums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    //init data
    getRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColums = () => {
    const columns = getCurrentAccountColumns(t, true, lang);

    setColumns(columns);
  };

  const getRecords = async (
    limit = pageSize,
    offset = 0,
    page = 1,
    search = searchValue,
    filterOptions = options
  ) => {
    setTableLoading(true);
    const params = parseGetActivitiesParams(search, filterOptions);
    if (businessID) {
      const activities = await getActivities(
        {
          pBusinessId: businessID,
          limit: limit + 1,
          offset,
          upcoming: 'true',
          type: 'ledger_account',
          ledger_account_id: recordId,
        },
        params
      );
      setPage(page);
      setPageSize(limit);
      setTableLoading(false);
      setSearchValue(search);
      setOptions(filterOptions);
      if (Array.isArray(activities)) {
        setActivities(activities);
      }
    }
  };

  return (
    <>
      {(activitiesEntity.length > 0 ||
        searchValue.length > 0 ||
        options.length > 0) && (
          <Transition.Child
            className="rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 my-6 h-fit"
            enter="duration-700 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-10 opacity-30"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="items-center lg:flex lg:justify-between">
              <div>
                <div className="flex flex-col md:flex-row whitespace-nowrap">
                  <span className="font-bold text-xl text-color-text-1 flex items-center">
                    {t('account_balance:upcoming_activities')}
                  </span>
                </div>
              </div>
              <Filter
                fields={upcomingFilterFields}
                onFilter={(search, options) =>
                  getRecords(pageSize, 0, 1, search, options)
                }
                searchValue={searchValue}
                options={options}
                placeholder={t('account_balance:search_id_name')}
              />
              {/* <div className="flex space-x-4 justify-start mt-4 md:justify-end md:mt-0">
            <Button styleType="Secondary">New Invoice Link</Button>
          </div> */}
            </div>
            <div className="">
              <div className="w-full mt-6 overflow-auto">
                <Table
                  columns={columns}
                  rowKey={(record) => {
                    const {
                      payin_request_id,
                      payout_request_id,
                      fx_conversion_id,
                      card_transaction_id,
                    } = record || {};
                    return (
                      payin_request_id ||
                      payout_request_id ||
                      fx_conversion_id ||
                      card_transaction_id
                    );
                  }}
                  records={activitiesEntity}
                  loadingData={tableLoading}
                  pagination={{
                    className: 'mt-6',
                    current: page,
                    currentSize: activitiesEntity.length,
                    pageSize,
                    pageSizeOptions: [30, 60, 120],
                    changePageCallback: (page, pageSize) => {
                      getRecords(pageSize, (page - 1) * pageSize, page);
                    },
                  }}
                  className={`!max-h-96`}
                  search={searchValue.length > 0 || options.length > 0}
                  onRowCell={(record, dataKey) => {
                    return {
                      onClick: (evt) => {
                        evt.stopPropagation();
                        HadleGoTranscationDetails(
                          businessID,
                          record,
                          navigate,
                          'wallets',
                          recordId
                        );
                      },
                    };
                  }}
                />
              </div>
            </div>
          </Transition.Child>
        )}
    </>
  );
};

export default BusinessWalletsUpcoming;
