import React from 'react';
import type { IIconProps } from '@/types/common-types';

const LeftIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M20 12C20 11.4477 19.5523 11 19 11L7.83 11L12.71 6.12C13.1017 5.72829 13.1006 5.09284 12.7075 4.70251C12.3164 4.31412 11.6848 4.31524 11.295 4.705L5.06667 10.9333C4.47756 11.5224 4.47756 12.4776 5.06667 13.0667L11.2947 19.2947C11.6842 19.6842 12.3158 19.6842 12.7053 19.2947C13.0946 18.9054 13.0949 18.2743 12.7059 17.8847L7.83 13L19 13C19.5523 13 20 12.5523 20 12Z"
          fill="#0094FF"
        />
      </svg>
    </div>
  );
};

export default LeftIcon;
