import { IBaseCartProps } from "@/types/analytics-type";
import { getName, getXAndYStyle } from "@/utils/analytics-utils";
import { handleNumber } from "@/utils/field-utils";
import Highcharts from "highcharts";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TransitionNode from "../../components/transition-node";
import MoreIcon from "../../analytics-icons/more-icon";
import Skeleton from "@/components/skeleton";
import BarChartSkeleton from "../../components/bar-chart-skeleton";
import HighchartsReact from "highcharts-react-official";
import NodataContent from "../../components/no-data-content";
import TransactionsDetails from "../transactions-details";
import { IAnalyticsTypes } from "@/data/analytics";
import './style.css'

const GatewayOverview: React.FC<IBaseCartProps> = (props) => {
  const {
    data,
    currency,
    startDate,
    endDate,
    businessID,
    loading: loadAllData,
    openExportModal,
  } = props;

  const { t } = useTranslation(['analytics', 'transaction_advice']);

  const { points = [], total_amount = 0, total_count = 0 } = data || {};

  const [showMore, setShowMore] = useState<boolean>(false);
  const [options, setOptions] = useState<Highcharts.Options>({
    title: {
      text: undefined,
    },
  });

  const total = handleNumber(total_amount);
  const title = t('analytics:transaction_overview');
  const disabled = !points || points.length === 0 || total_count === 0;

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getOptions = () => {
    const labels: string[] = [];
    const lineData: { name: string; data: number[]; color: string } = {
      name: '',
      data: [],
      color: '#44D600',
    };

    const barData: { name: string; data: number[]; color: string } = {
      name: '',
      data: [],
      color: '#0094FF',
    };

    points?.forEach((point) => {
      const { x_axis, y_axis, name, type } = point;

      if (type === 'no_of_transactions') {
        lineData.name = getName(name);
        lineData.data.push(y_axis);
      } else if (type === 'transaction_amount') {
        barData.name = getName(name);
        barData.data.push(y_axis);
      }

      if (!labels.includes(x_axis)) {
        labels.push(x_axis);
      }
    });
    const options: Highcharts.Options = {
      chart: {
        type: 'column',
        style: {
          fontFamily: 'Roboto',
        },
      },
      title: {
        text: undefined,
      },
      xAxis: {
        categories: labels,
        labels: {
          style: getXAndYStyle(),
        },
      },
      yAxis: [
        {
          title: {
            text: null,
          },
          stackLabels: {
            enabled: false,
          },
          labels: {
            style: getXAndYStyle(),
          },
        },
      ],
      tooltip: {
        formatter: function () {
          let s = '<b>' + this.x + '</b>';
          const { points = [] } = this;
          Highcharts.each(points, function (point: any, index: number) {
            s +=
              '<br/>' +
              '<span style="color:' +
              point.color +
              '">\u25A0</span>  ' +
              point.series.name +
              ': ' +
              `${index === 0 ? handleNumber(point.y) : point.y}`;
          });

          return s;
        },
        shared: true,
      },
      legend: {
        enabled: true,
        useHTML: true,
        className: 'customized-legend',
        labelFormatter: function () {
          const { options = {} } = this;
          const { type = '' } = options as any;
          if (type === 'column') {
            return (
              '<div class="series-label flex items-center">' +
              '<div style="color: ' +
              this.color +
              '">' +
              '<svg width="14" height="14" viewBox="0 0 14 14">' +
              '<rect x="0.300781" y="5.59961" width="2.80009" height="8.40028" fill="currentColor"/>' +
              '<rect x="5.10156" width="2.80009" height="14.0005" fill="currentColor"/>' +
              '<rect x="9.90234" y="8.40039" width="2.80009" height="5.60019" fill="currentColor"/>' +
              '</svg>' +
              '</div>' +
              '<div class="ml-2 text-sm font-medium leading-5">' +
              this.name +
              '</div>' +
              '</div>'
            );
          } else {
            return (
              '<div class="series-label flex items-center">' +
              '<span style="color: ' +
              this.color +
              '">' +
              '<svg width="15" height="4" viewBox="0 0 15 4" fill="none">' +
              '<rect x="5.58984" y="0.0917969" width="3.81818" height="3.81818" rx="1.90909" fill="currentColor"/>' +
              '<rect x="0.5" y="1.36523" width="14" height="1.27273" rx="0.636364" fill="currentColor"/>' +
              '</svg>' +
              '</span>' +
              '<div class="ml-2 text-sm font-medium leading-5">' +
              this.name +
              '</div>' +
              '</div>'
            );
          }
        },
      },
      credits: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: false,
            // formatter: function () {
            //   const value = this.y || 0;
            //   if (value !== 0) {
            //     return handleNumber(value);
            //   }
            //   return '';
            // },
          },
        },
      },
      series: [
        {
          ...barData,
          type: 'column',
        },
        {
          ...lineData,
          type: 'line',
        },
      ],
    };
    setOptions(options);
  };

  const handleMore = async () => {
    if (!disabled) {
      setShowMore(true);
    }
  };

  const handleCancel = () => {
    setShowMore(false);
  };

  const handleOpenExport = () => {
    handleCancel();
    if (openExportModal) {
      openExportModal();
    }
  };

  return (
    <TransitionNode>
      <div className="px-6 py-6 md:px-9 flex flex-col h-full">
        <div className="flex justify-between mb-6">
          <div className="font-bold text-xl text-color-text-1">{title}</div>
          <div
            onClick={handleMore}
            className={`p-2 rounded-full h-8 w-8 ${disabled
                ? 'bg-disabled-color text-icon-color'
                : 'cursor-pointer bg-opacity-10 hover:bg-opacity-100 hover:text-color-text-6 text-primary-color bg-primary-color'
              }`}
          >
            <MoreIcon />
          </div>
        </div>
        <div className="mb-4 flex">
          <div className="flex flex-col flex-1">
            <div className="text-[30px] text-color-text-1">
              {loadAllData && (
                <div className="h-[45px] flex items-center">
                  <Skeleton
                    className="h-auto"
                    skeletonItemClass="h-6 rounded-2xl"
                    row={1}
                  />
                </div>
              )}
              {!loadAllData && (
                <>
                  <span className="font-bold mr-2">{total}</span>
                  <span>{currency}</span>
                </>
              )}
            </div>
            <div className="text-color-text-2 font-medium">
              {t('analytics:total_amount')}
            </div>
          </div>
          <div className="flex flex-col ml-8 flex-1">
            {loadAllData && (
              <div className="h-[45px] flex items-center">
                <Skeleton
                  className="h-auto"
                  skeletonItemClass="h-6 rounded-2xl"
                  row={1}
                />
              </div>
            )}
            {!loadAllData && (
              <div className="text-[30px] font-bold">{total_count}</div>
            )}
            <div className="text-color-text-2 font-medium">
              {t('analytics:total_count')}
            </div>
          </div>
        </div>
        {loadAllData && <BarChartSkeleton />}
        {!loadAllData && !disabled && (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}
        {!loadAllData && disabled && <NodataContent />}
        <TransactionsDetails
          visible={showMore}
          total={`${total.indexOf('-') === -1 ? `+${total}` : total
            } ${currency}`}
          startDate={startDate}
          endDate={endDate}
          businessID={businessID}
          currency={currency}
          type={IAnalyticsTypes.GATEWAY_OVERVIEW}
          openExportModal={handleOpenExport}
          onCancel={handleCancel}
        />
      </div>
    </TransitionNode>
  );
};

export default GatewayOverview;
