import React from 'react';
import type { IIconProps } from '@/types/common-types';

const MyAccountIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 16 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.311 11.527C13.15 11.134 12.461 10.702 12.168 10.352C13.268 9.639 14 8.406 14 7V6C14 3.794 12.206 2 10 2C7.794 2 6 3.794 6 6V7C6 8.406 6.732 9.639 7.832 10.352C7.54 10.702 6.851 11.134 5.69 11.527C4.081 12.07 3 13.57 3 15.26V18H17V15.26C17 13.57 15.919 12.07 14.311 11.527ZM8 6C8 4.897 8.897 4 10 4C11.103 4 12 4.897 12 6V7C12 8.103 11.103 9 10 9C8.897 9 8 8.103 8 7V6ZM5 15.26V16H15V15.26C15 14.429 14.466 13.69 13.671 13.422C11.825 12.798 10.67 11.986 10.218 11H9.782C9.33 11.986 8.175 12.798 6.33 13.422C5.534 13.691 5 14.429 5 15.26Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default MyAccountIcon;
