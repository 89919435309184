import Filter, { FilterOperation, IFilterField, IFilterOptions, IFilterType } from '@/components/common/filter';
import { TableRecord } from '@/components/table/interface';
import { IPayoutsReportsTypes, getGlobalCategories, loadReportsData } from '@/data/analytics';
import { IObject } from '@/types/common-types';
import { handleNumber } from '@/utils/field-utils';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DetailsModal from './details-modal';
import Table from '@/components/table';

interface ITransactionsDetailsProps {
  visible: boolean;
  startDate: string;
  endDate: string;
  total: string;
  businessID: string;
  currency: string;
  type: IPayoutsReportsTypes;
  onCancel: () => void;
}

const PayoutTransactionsDetails: React.FC<ITransactionsDetailsProps> = (props) => {
  const {
    visible,
    onCancel,
    startDate,
    endDate,
    total,
    businessID,
    currency,
    type,
  } = props;

  const { t } = useTranslation('analytics');

  const [searchValue, setSearchValue] = useState('');
  const [filterOptions, setFilterOptions] = useState<IFilterOptions[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [records, setRecords] = useState<IObject[]>([]);
  const [pageSize, setPageSize] = useState<number>(30);
  const [page, setPage] = useState<number>(1);
  const [filterFields, setFilterFields] = useState<IFilterField[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState('');

  useEffect(() => {
    if (visible) {
      loadData();
      getOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const columns: ColumnsType<TableRecord> = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'id',
      width: 180,
    },
    {
      key: 'from_to',
      dataIndex: 'from_to',
      title: t('analytics:from_to'),
      align: 'left',
      width: 156,
      render: (_key, record) => {
        const {
          payout_category_icon_url = '',
          payout_category_color = '',
          payee_name = '',
        } = record;
        return (
          <div className="py-2 text-color-text-2 font-medium pr-6 flex items-center space-x-2">
            <div
              className={`w-8 h-8 flex justify-center items-center rounded-full`}
              style={{ backgroundColor: payout_category_color }}
            >
              <div
                style={{
                  backgroundImage: `url(${payout_category_icon_url})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '20px',
                  height: '20px',
                }}
              />
            </div>
            <span className="max-w-16 whitespace-nowrap overflow-ellipsis text-color-text-1 font-medium">
              {payee_name}
            </span>
          </div>
        );
      },
    },
    {
      key: 'transfer_type',
      dataIndex: 'transfer_type',
      title: t('analytics:transfer_type'),
      align: 'left',
      width: 170,
    },
    {
      key: 'transfer_method',
      dataIndex: 'transfer_method',
      title: t('analytics:transfer_method'),
      align: 'left',
      width: 170,
    },
    {
      key: 'transaction_amount',
      dataIndex: 'amount',
      title: t('analytics:transaction_amount'),
      align: 'left',
      width: 170,
      render: (_key, record) => {
        const { amount, currency } = record;
        let returnValue = handleNumber(amount);
        if (Number(amount) > 0) {
          returnValue = `-${returnValue}`;
        }
        return `${returnValue} ${currency}`;
      },
    },
    {
      key: 'fees_charged',
      dataIndex: 'calculated_fee',
      title: t('analytics:fees_charged'),
      align: 'left',
      width: 170,
      render: (_key, record) => {
        const { calculated_fee, currency } = record;
        let returnValue = handleNumber(calculated_fee);
        if (Number(calculated_fee) > 0) {
          returnValue = `-${returnValue}`;
        }
        return `${returnValue} ${currency}`;
      },
    },
    {
      key: 'total_amount',
      dataIndex: 'amount',
      title: t('analytics:billing_amount'),
      align: 'left',
      width: 170,
      render: (_key, record) => {
        const { total_amount, currency } = record;
        let returnValue = handleNumber(total_amount);
        if (Number(total_amount) > 0) {
          returnValue = `-${returnValue}`;
        }
        return `${returnValue} ${currency}`;
      },
    },
    {
      key: 'transfer_status',
      dataIndex: 'transfer_status',
      title: t('analytics:status'),
      align: 'left',
      width: 170,
      render: (_key, record) => {
        const { transfer_status = '' } = record;
        const status = transfer_status.toLocaleLowerCase();
        let color = '';
        if (status === 'settled') {
          color = 'text-success-color bg-success-color';
        } else if (status === 'initiated') {
          color = 'text-warning-color bg-warning-color';
        } else if (status === 'reversed' || status === 'terminated') {
          color = 'text-icon-color bg-icon-color';
        } else if (status === 'suspended') {
          color = 'text-error-color bg-error-color';
        }

        return (
          <span
            className={`px-3 py-1.5 rounded-3xl text-xs ${color} bg-opacity-10`}
          >
            {status.toUpperCase()}
          </span>
        );
      },
    },
    {
      key: 'settled_on',
      dataIndex: 'settled_at',
      title: t('analytics:settled_on'),
      align: 'right',
      width: 170,
      render: (_key, record) => {
        const { transfer_status = '', settled_at } = record;
        const status = transfer_status.toLocaleLowerCase();
        ['Reversed', 'Suspended'].includes(status);

        const ymdRes = dayjs(settled_at).format('MMM D, YYYY');
        // const timeRes = dayjs(settled_at).format('HH:mm:ss');
        return (
          <div className="flex flex-col">
            <span className=" whitespace-nowrap">{`${ymdRes}`}</span>
            {/* {<span className=" whitespace-nowrap">{`${timeRes}`}</span>} */}
          </div>
        );
      },
    },
  ];

  const getFilter = (searchValue: string, options: IFilterOptions[]) => {
    const filterObj: any = {};
    if (searchValue) {
      filterObj.keyword = searchValue;
    }

    options.forEach((option) => {
      const { operation, name, value, type } = option;
      if (value) {
        if (type === IFilterType.MULTISELECT) {
          filterObj[name] = value.split(',');
        } else {
          let firstValue = '';
          let secondValue = '';
          if (operation === FilterOperation.IS_BETWEEN) {
            const values = value.split('&');
            firstValue = values[0] || '';
            secondValue = values[1] || '';
          } else if (operation === FilterOperation.EQUALS) {
            firstValue = value;
            secondValue = value;
          }

          switch (name) {
            case 'created_at':
              firstValue && (filterObj.start_created_at = firstValue);
              secondValue && (filterObj.end_created_at = secondValue);
              break;
            case 'settled_at':
              firstValue && (filterObj.start_settled_at = firstValue);
              secondValue && (filterObj.end_settled_at = secondValue);
              break;
            case 'amount':
              firstValue && (filterObj.min_amount = Number(firstValue));
              secondValue && (filterObj.max_amount = Number(secondValue));
              break;

            default:
              break;
          }
        }
      }
    });

    return filterObj;
  };

  const loadData = async (
    currentPage = page,
    limit = pageSize,
    search = searchValue,
    options: IFilterOptions[] = filterOptions
  ) => {
    setLoading(true);
    setSearchValue(search);
    setFilterOptions(options);

    const filter = getFilter(search, options);

    const result = await loadReportsData(
      {
        end_date: endDate,
        start_date: startDate,
        p_business_id: businessID,
        currency_code: currency,
        type,
        activity: true,
        offset: (currentPage - 1) * limit,
        limit: limit + 1,
        filter,
      }
    );
    const { activity = [], total_activity_count, total_activity_amount } = result || {};

    setRecords(activity);
    setPage(currentPage);
    setPageSize(limit);
    setLoading(false);
    setTotalCount(total_activity_count);
    setTotalAmount(total_activity_amount)
  };

  const handleFilter = (
    search?: string | undefined,
    options?: IFilterOptions[]
  ) => {
    loadData(1, 30, search, options);
  };

  const getOptions = async () => {
    const categories = await getGlobalCategories();

    const options: any[] = [];

    categories.forEach((item) => {
      const { category_name = '', id } = item;
      options.push({
        name: id,
        label: category_name,
      });
    });

    const filterFields: IFilterField[] = [
      {
        type: IFilterType.DATE,
        name: 'created_at',
        label: t('analytics:created_on'),
        operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
      },
      {
        type: IFilterType.DATE,
        name: 'settled_at',
        label: t('analytics:settled_on'),
        operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
      },
      {
        name: 'amount',
        type: IFilterType.NUMBER,
        label: t('analytics:amount'),
        operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
      },
      {
        name: 'payout_category',
        label: t('analytics:activity_type'),
        type: IFilterType.MULTISELECT,
        operations: [FilterOperation.EQUALS],
        options,
      },
    ];

    setFilterFields(filterFields);
  };

  const handleCancel = () => {
    setPageSize(30);
    setPage(1);
    setSearchValue('');
    setFilterOptions([]);
    setTimeout(() => {
      setRecords([]);
    }, 500);
    onCancel();
  };

  const hasFilter =
    filterOptions.filter((item) => item.value).length > 0 ||
    searchValue.length > 0;
  const showTotal = hasFilter && totalCount > 0;

  return (
    <DetailsModal
      visible={visible}
      title={t('analytics:payout_list')}
      onCancel={handleCancel}
    >
      <div className="hidden md:flex justify-between items-center mb-6 mt-2">
        <div className="flex items-cenrer text-color-text-1 truncate">
          <span className="text-xl font-bold">
            {t('analytics:transactions')}
          </span>
          <span className="bg-primary-color text-sm bg-opacity-10 font-medium px-3 py-1 rounded-lg ml-1">
            +{handleNumber(totalAmount)} {currency}
            {/* {total} */}
          </span>
        </div>
        <div className="flex items-center">
          {!showTotal && (
            <span className="text-color-text-1 text-sm mr-2 font-medium truncate">
              {dayjs(startDate).format('MMM D, YYYY')} to{' '}
              {dayjs(endDate).format('MMM D, YYYY')}
            </span>
          )}
          {showTotal && (
            <span className="text-color-text-1 text-sm mr-2 font-medium truncate">
              {t('analytics:filter_result', { count: totalCount })}
            </span>
          )}
          <Filter
            fields={filterFields}
            onFilter={handleFilter}
            searchValue={searchValue}
            options={filterOptions}
            placeholder={t('analytics:filter_placeholder')}
          />
        </div>
      </div>
      <div className="mb-4 md:hidden">
        <div className="text-xl font-bold">{t('analytics:transactions')}</div>
        <div className="text-color-text-1 w-fit bg-primary-color bg-opacity-10 font-medium px-3 py-1 rounded-lg mt-2 mb-2">
          {total}
        </div>
        <Filter
          fields={filterFields}
          onFilter={handleFilter}
          searchValue={searchValue}
          options={filterOptions}
          placeholder={t('analytics:filter_placeholder')}
        />
      </div>
      <Table
        columns={columns}
        records={records}
        search={hasFilter}
        searchEmptyClass="flex-1 justify-center"
        loadingData={loading}
        pagination={{
          className: 'mt-6',
          current: page,
          currentSize: records.length,
          pageSize,
          pageSizeOptions: [30, 60, 120],
          changePageCallback: loadData,
        }}
      />
    </DetailsModal>
  );
};

export default PayoutTransactionsDetails;
