import React from 'react';
import type { IIconProps } from '@/types/common-types';

const LoadingIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        className="animate-spin"
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.7226 16C30.7043 16 31.5109 15.2015 31.3948 14.2267C31.1164 11.8879 30.2898 9.63867 28.972 7.66645C27.3238 5.19971 24.9811 3.27712 22.2403 2.14181C19.4994 1.00649 16.4834 0.709442 13.5736 1.28822C10.6639 1.867 7.99119 3.29561 5.8934 5.3934C3.79561 7.49119 2.367 10.1639 1.78822 13.0736C1.20944 15.9834 1.50649 18.9994 2.64181 21.7403C3.77712 24.4811 5.69971 26.8238 8.16645 28.472C10.1387 29.7898 12.3879 30.6164 14.7267 30.8948C15.7015 31.0109 16.5 30.2043 16.5 29.2226C16.5 28.241 15.6995 27.4594 14.7297 27.3076C13.0953 27.0517 11.5285 26.4433 10.1413 25.5164C8.25917 24.2588 6.7922 22.4713 5.92593 20.3799C5.05966 18.2886 4.83301 15.9873 5.27463 13.7671C5.71625 11.547 6.8063 9.50761 8.40696 7.90696C10.0076 6.3063 12.047 5.21625 14.2671 4.77463C16.4873 4.33301 18.7886 4.55966 20.8799 5.42593C22.9713 6.2922 24.7588 7.75917 26.0164 9.64134C26.9433 11.0285 27.5517 12.5953 27.8076 14.2297C27.9594 15.1995 28.741 16 29.7226 16Z"
          fill="url(#paint0_angular_11754_237118)"
        />
        <defs>
          <radialGradient
            id="paint0_angular_11754_237118"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(16.5 16) rotate(90) scale(15)"
          >
            <stop stopColor="#0094FF" />
            <stop offset="1" stopColor="#0094FF" stopOpacity="0.4" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

export default LoadingIcon;
