import '../index.css';

interface IBarChartSkeletonProps {
  wrapperClass?: string;
}

const BarChartSkeleton: React.FC<IBarChartSkeletonProps> = (props) => {
  const { wrapperClass = '' } = props;

  const bars = 10;

  const arr = [
    '30%',
    '60%',
    '90%',
    '90%',
    '100%',
    '100%',
    '70%',
    '70%',
    '30%',
    '80%',
  ];

  return (
    <div
      className={`${wrapperClass} min-h-[25rem] flex-1 flex justify-between items-end pb-10`}
    >
      {new Array(bars).fill('').map((r, i) => {
        return (
          <div
            key={`${r}_${i}`}
            style={{
              height: `${arr[i]}`,
            }}
            className={`w-6 mr-4 relative overflow-hidden bg-gradient-to-t rounded-2xl from-system-background-color to-system-background-color/0`}
          >
            <div
              className={`bar_chart_skeleton w-full h-full`}
              style={{
                animationDelay: `-0.${(i + 1) * 1}s`,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default BarChartSkeleton;
