import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ResultShareIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="39"
        height="42"
        viewBox="0 0 39 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.5193 8.19447C26.9779 7.73586 26.9779 7.00034 26.5193 6.54173L20.3237 0.346121C20.0987 0.12114 19.7959 -3.8147e-06 19.5017 -3.8147e-06C19.2075 -3.8147e-06 18.9046 0.112488 18.6796 0.346121L12.484 6.54173C12.0254 7.00034 12.0254 7.73586 12.484 8.19447C12.9426 8.65308 13.6781 8.65308 14.1368 8.19447L18.3335 3.99772V24.0556C18.3335 24.7046 18.8527 25.2237 19.5017 25.2237C20.1507 25.2237 20.6698 24.7046 20.6698 24.0556L20.6698 3.99772L24.8666 8.19447C25.3252 8.65308 26.0694 8.65308 26.5193 8.19447Z"
          fill="#0094FF"
        />
        <path
          d="M38.5974 26.5995L34.5304 11.8893C34.4352 11.5518 34.2016 11.2836 33.8901 11.1365L33.7776 11.0845C33.6218 11.0153 33.4574 10.9807 33.293 10.9807H25.9639C25.3149 10.9807 24.7957 11.4999 24.7957 12.1489C24.7957 12.7978 25.3149 13.317 25.9639 13.317H32.5056L35.9409 25.7602H26.8551C26.2061 25.7602 25.687 26.2794 25.687 26.9283V27.984C25.687 29.5329 24.4323 30.7876 22.8834 30.7876H15.7532C14.2043 30.7876 12.9496 29.5329 12.9496 27.984V26.9283C12.9496 26.2794 12.4304 25.7602 11.7814 25.7602H3.05048L6.50307 13.2911H13.0448C13.6938 13.2911 14.213 12.7719 14.213 12.1229C14.213 11.4739 13.6938 10.9547 13.0448 10.9547H5.70698C5.54257 10.9547 5.37816 10.9894 5.22241 11.0586L5.10992 11.1105C4.79841 11.2576 4.55612 11.5258 4.46959 11.8633L0.40264 26.5736C0.376681 26.6774 0.359375 26.7812 0.359375 26.8851V33.0201C0.359375 37.6668 4.14077 41.4482 8.78748 41.4482H30.2125C34.8592 41.4482 38.6406 37.6668 38.6406 33.0201V26.911C38.6406 26.7985 38.632 26.6947 38.5974 26.5995ZM36.3043 33.0201C36.3043 36.3775 33.5699 39.1119 30.2125 39.1119H8.78748C5.43008 39.1119 2.69571 36.3775 2.69571 33.0201V28.0965H10.6306C10.6912 30.8741 12.9756 33.1153 15.7705 33.1153H22.9007C25.6956 33.1153 27.98 30.8741 28.0406 28.0965H36.3216V33.0201H36.3043Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default ResultShareIcon;
