import { useTranslation } from 'react-i18next';
import type { ITerminalOrdersDetailsPageProps } from '@/types/terminal-type';
import type { IObject } from '@/types/common-types';
import Button from '@/components/common/button';
import BackIcon from '@/components/svg/back-icon';
import CopyIcon from '@/components/svg/copy-icon';
import { Transition } from '@headlessui/react';
import DefaultItemIcon from '../terminal-icons/default-item-icon';
import { ConvertTimeZone, getClassByStatus, handlePrice } from '@/utils/terminal-untils';
import FieldItem from '../components/field-item';
import dayjs from 'dayjs';
import Config from '@/libs/config';
import AntdTips from '@/components/common/antd/tips';
import { useEffect, useState } from 'react';
import AntdModal from '@/components/common/antd/modal';
import PanelTitle from '../components/panel-title';
import PaymentDetailsPanel from '../components/payment-details';
import Skeleton from '@/components/skeleton';
import { transformField } from '@/utils/field-utils';
import ActionType from '@/actions/action-type';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';
import { useParams } from 'react-router-dom';

interface IDetailsWebPageProps extends ITerminalOrdersDetailsPageProps {
  record: IObject;
  downloadLoading: boolean;
  loadingData: boolean;
  voidFun: () => Promise<boolean>;
  handleBack: () => void;
  handlePayNow: () => void;
  handleExportPdf: () => void;
}

let tipsTimeout: any;

const DetailsWebPage: React.FC<IDetailsWebPageProps> = (props) => {
  const {
    businessID,
    record,
    downloadLoading,
    loadingData,
    voidFun,
    handlePayNow,
    handleBack,
    handleExportPdf,
  } = props;

  const prefix = Config.staticPath;
  const dispatch: IDispatch = useDispatch();
  const { lang = 'en' } = useParams();
  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const {
    line_items = [],
    discount_total: discountTotal = 0,
    initial_total: total,
    subtotal,
    state,
    currency = 'HKD',
    reference_number: referenceNumber,
    note = '',
    number = '',
    created_at: createdAt = '',
    initial_delivery: initialDelivery = 0,
    oms_delivery_note = {},
    correspondence_state: correspondenceState = '',
    sale_transactions: saleTransactions = [],
    void_transactions: voidTransactions = [],
    refund_transactions: refundTransactions = [],
    delivery_order: deliveryOrder = {},
  } = record;

  const { state: deliveryState, tracking_number, shipper } = deliveryOrder;
  const lineItems = line_items || [];
  const cardList = lineItems.filter(
    (item: any) => item.purchasable_type === 'Listing'
  );
  let cartTotal = 0;
  cardList.map((item: any) => {
    return cartTotal += item.quantity;
  });

  const {
    name = '',
    phone = '',
    address1 = '',
    street = '',
    district = '',
    max_delivery_time,
    min_delivery_time,
  } = oms_delivery_note;

  let scheduleAt = '--';


  const [showNodeVoidModal, setShowVoidModal] = useState<boolean>(false);
  const [paymentRecord, setPaymentRecord] = useState<IObject | undefined>(
    undefined
  );
  const [refundedRecord, setRefundedRecord] = useState<IObject | undefined>(
    undefined
  );
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const [pickTime, setpickTime] = useState(false);

  useEffect(() => {
    let canPay = isWithinOneHour(min_delivery_time)
    setpickTime(!canPay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min_delivery_time]);

  useEffect(() => {
    getPaymentDefails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  useEffect(() => {
    if (copySuccess) {
      clearTimeout(tipsTimeout);
      tipsTimeout = setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copySuccess]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('common:extended_service'),
          key: '1',
          type: 'label',
        },
        {
          name: t('common:order_terminal'),
          key: '2',
          type: 'link',
          url: `/businesses/${businessID}/terminal/orders`,
        },
        {
          name: referenceNumber,
          key: '3',
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, referenceNumber])

  const dateFormat = lang === 'en' ? 'MMM DD YYYY' : 'YYYY年MM月DD日';

  if (max_delivery_time && min_delivery_time) {
    scheduleAt = `${t('extended_service:estimated')} ${dayjs(
      min_delivery_time
    ).tz('Asia/Hong_Kong').format(dateFormat)} ${dayjs(min_delivery_time).tz('Asia/Hong_Kong').format(
      'HH:mm'
    )} ~ ${dayjs(max_delivery_time).tz('Asia/Hong_Kong').format('HH:mm')}`;
  }

  const isVoid = state === 'voided';
  const isUnpaid = correspondenceState === 'unpaid';
  // const isPaid = ['over_paid', 'paid'].includes(correspondenceState);
  const isRefunded = correspondenceState === 'refunded';

  const isWithinOneHour = (givenDateString: string) => {
    const givenDate = dayjs(givenDateString).tz('Asia/Hong_Kong');
    const now = dayjs().tz('Asia/Hong_Kong');
    return now.isAfter(givenDate.subtract(1, 'hour').subtract(10, 'minute'));
  }

  const handleCopy = (copyValue: string) => {
    navigator.clipboard.writeText(copyValue).then(() => {
      setCopySuccess(true);
    });
  };

  const getLatestTransactions = (data: any) => {
    let paymentDefails = undefined;
    if (Array.isArray(data)) {
      for (let i = data.length - 1; i >= 0; i--) {
        const record = data[i];
        if (record.success) {
          paymentDefails = record;
          break;
        }
      }
    }
    return paymentDefails;
  };

  const getPaymentDefails = () => {
    let paymentDefails: any = undefined;
    if (voidTransactions.length > 0 && isUnpaid) {
      paymentDefails = getLatestTransactions(voidTransactions);
    } else {
      paymentDefails = getLatestTransactions(saleTransactions);
    }

    if (isRefunded) {
      setRefundedRecord(getLatestTransactions(refundTransactions));
    }

    setPaymentRecord(paymentDefails);
  };

  const handleVoid = async () => {
    const result = await voidFun();

    if (result) {
      setShowVoidModal(false);
    }
  };

  const getCountryValue = () => {
    return (
      <div className="flex">
        <span className="flex items-center relative w-5 mr-2">
          <img
            // layout="intrinsic"
            width="20px"
            height="20px"
            src={`${prefix}/images/country-flag/hk.svg`}
            alt=""
          />
        </span>
        <span className="text-color-text-1 text-sm font-medium">
          {t('extended_service:hong_kong')}
        </span>
      </div>
    );
  };

  const handlePaymentStatus = (status: string) => {
    const { className, showStatus } = getClassByStatus(status, t);
    if (!status) return '--';

    return (
      <span
        className={`font-medium rounded-3xl text-xs px-3 py-1 bg-opacity-10 ${className}`}
      >
        {showStatus.toUpperCase()}
      </span>
    );
  };

  return (
    <>
      <Transition.Root show={true} appear={true}>
        <div className="p-4 md:p-6 lg:px-8 lg:py-6">
          <div className="flex flex-col md:flex-row md:items-center justify-between mb-6">
            {loadingData && (
              <>
                <div className="flex-1">
                  <div className="w-20 md:w-60 lg:w-80">
                    <Skeleton
                      row={1}
                      width={['100%']}
                      skeletonItemClass="h-10 rounded-2xl"
                    />
                  </div>
                </div>
                <div className="flex flex-col-reverse md:flex-row md:items-center">
                  <Skeleton
                    row={1}
                    skeletonItemClass="h-10 !w-32 rounded-2xl"
                  />
                  <Skeleton
                    row={1}
                    className="ml-4"
                    skeletonItemClass="h-10 !w-32 rounded-2xl"
                  />
                </div>
              </>
            )}
            {!loadingData && (
              <>
                <div className="flex flex-1">
                  <div className="bg-disabled-color shadow-box-drop rounded-full w-10 h-10 flex items-center justify-center cursor-pointer">
                    <BackIcon
                      className="hover:text-primary-color"
                      onClick={handleBack}
                    />
                  </div>
                  <div className="text-3xl leading-10 font-bold text-color-text-1 ml-2.5">
                    {t('extended_service:order_details')}
                  </div>
                </div>
                <div className="flex flex-col-reverse md:flex-row md:items-center">
                  <Button
                    onClick={handleExportPdf}
                    loading={downloadLoading}
                    className="mb-2 md:mb-0"
                    styleType="Border"
                  >
                    {t('extended_service:download_invoice_pdf')}
                  </Button>
                  {!isVoid && isUnpaid && (
                    <>
                      <Button
                        className="mb-2 md:mb-0 md:ml-4"
                        onClick={() => setShowVoidModal(true)}
                        styleType="Secondary Danger"
                      >
                        {t('extended_service:void_invoice')}
                      </Button>
                      {pickTime && (
                        <Button
                          className="mb-2 md:mb-0 md:ml-4"
                          onClick={handlePayNow}
                        >
                          {t('extended_service:pay_now')}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <Transition.Child
              className="rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6"
              enter="duration-700 transform transition ease-in-out"
              enterFrom="-translate-x-1 translate-y-10 opacity-0"
              enterTo="translate-x-0 translate-y-0 opacity-100"
              leave="duration-500 transform transition"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <PanelTitle
                loadingData={loadingData}
                title={t('extended_service:ordered_items')}
              />
              {loadingData && (
                <>
                  {new Array(2).fill('').map((r, i) => (
                    <div key={`${r}_${i}`} className="flex mt-8">
                      <Skeleton
                        row={1}
                        className="mr-4 !h-20 !w-20"
                        skeletonItemClass={`h-20 !w-20 rounded-2xl`}
                      />
                      <div className="ml-4 flex-1 flex flex-col justify-between">
                        <Skeleton
                          row={1}
                          width={['100%']}
                          skeletonItemClass={`h-6 rounded-2xl`}
                        />
                        <Skeleton
                          row={1}
                          width={['50%']}
                          skeletonItemClass={`h-6 rounded-2xl`}
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
              {!loadingData &&
                Array.isArray(cardList) &&
                cardList.length > 0 &&
                cardList.map((item) => (
                  <div key={item.id} className="mb-4 flex justify-between">
                    <div className="flex">
                      <div className="p-2 bg-disabled-color rounded-2xl">
                        {item.image_url && (
                          <div
                            className="w-16 h-16 flex justify-center items-center bg-center bg-no-repeat bg-contain"
                            style={{
                              backgroundImage: `url(${item.image_url})`,
                            }}
                          />
                        )}
                        {!item.image_url && (
                          <div
                            style={{
                              backgroundColor: 'rgba(226, 228, 231, 0.15)',
                            }}
                            className="w-16 h-16 flex justify-center items-center rounded"
                          >
                            <DefaultItemIcon />
                          </div>
                        )}
                      </div>
                      <div className="ml-2 font-medium flex flex-col justify-between">
                        <div className="text-color-text-1 flex items-center text-sm max-w-16 line-clamp-2 overflow-ellipsis lg:max-w-28">
                          <span>{item.label}</span>
                          {`${item.label}`.includes('POS') && (
                            <AntdTips
                              overlayInnerStyle={{
                                width: '320px',
                              }}
                              className="ml-1"
                              content={t('extended_service:cart_item_tips')}
                            />
                          )}
                        </div>
                        <div className="text-color-text-1 text-base">
                          {handlePrice(item.price)} {currency}
                        </div>
                      </div>
                    </div>
                    <div className="whitespace-nowrap font-medium text-sm self-end">
                      x {item.quantity}
                    </div>
                  </div>
                ))}
              <div className="mt-6 p-4 rounded-2xl border text-sm font-medium text-color-text-1 border-primary-color">
                {loadingData && (
                  <>
                    {new Array(4).fill('').map((r, i) => (
                      <div
                        key={`${r}_${i}`}
                        className="flex items-center justify-between mb-3"
                      >
                        <Skeleton
                          row={1}
                          width={[`${i === 3 ? '80%' : '60%'}`]}
                          skeletonItemClass={`${i === 3 ? 'h-6' : 'h-4'
                            } rounded-2xl`}
                        />
                        <Skeleton
                          row={1}
                          width={[`${i === 3 ? '80%' : '60%'}`]}
                          skeletonItemClass={`${i === 3 ? 'h-6' : 'h-4'
                            } self-end rounded-2xl`}
                        />
                      </div>
                    ))}
                  </>
                )}
                {!loadingData && (
                  <>
                    <div className="flex items-center justify-between mb-3">
                      <div className="text-color-text-2">
                        {t('extended_service:total_items')}
                      </div>
                      <div>
                        {cartTotal} {t('extended_service:items')}
                      </div>
                    </div>
                    <div className="flex items-center justify-between mb-3">
                      <div className="text-color-text-2">
                        {t('extended_service:subtotal')}
                      </div>
                      <div>
                        {handlePrice(subtotal)} {currency}
                      </div>
                    </div>
                    <div className="flex items-center justify-between mb-3">
                      <div className="text-color-text-2">
                        {t('extended_service:delivery_fee')}
                      </div>
                      <div>
                        {handlePrice(initialDelivery)} {currency}
                      </div>
                    </div>
                    {discountTotal !== 0 && (
                      <div className="flex items-center justify-between mb-3">
                        <div className="text-color-text-2">
                          {t('extended_service:discount')}
                        </div>
                        <div>
                          -{handlePrice(discountTotal)} {currency}
                        </div>
                      </div>
                    )}
                    <div className="flex items-center justify-between text-base font-bold">
                      <div>{t('extended_service:total_amount')}</div>
                      <div>
                        {handlePrice(total)} {currency}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Transition.Child>
            <Transition.Child
              className="rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6"
              enter="duration-700 transform transition ease-in-out"
              enterFrom="-translate-x-1 translate-y-10 opacity-0"
              enterTo="translate-x-0 translate-y-0 opacity-100"
              leave="duration-500 transform transition"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <PanelTitle
                loadingData={loadingData}
                title={t('extended_service:invoice_details')}
              />
              <FieldItem
                loadingData={loadingData}
                label={t('extended_service:reference_number')}
                value={
                  <div className="flex items-center">
                    <span>{referenceNumber}</span>
                    <CopyIcon
                      onClick={() => handleCopy(referenceNumber)}
                      className="text-primary-color cursor-pointer ml-1"
                    />
                    <span
                      className={`text-primary-color text-xs ml-1 ${copySuccess ? 'visible' : 'invisible'
                        }`}
                    >
                      {t('extended_service:copied')}
                    </span>
                  </div>
                }
              />
              <div className="grid grid-cols-2 mt-6">
                <FieldItem
                  loadingData={loadingData}
                  label={t('extended_service:payment_status')}
                  value={handlePaymentStatus(correspondenceState)}
                />
                <FieldItem
                  loadingData={loadingData}
                  label={t('extended_service:delivery_status')}
                  value={handlePaymentStatus(deliveryState)}
                />
              </div>
              <div className="grid grid-cols-2 mt-6">
                <FieldItem
                  loadingData={loadingData}
                  label={t('common:invoice_number_common')}
                  value={number}
                />
                <FieldItem
                  loadingData={loadingData}
                  label={t('extended_service:created_on')}
                  // value={dayjs(ConvertTimeZone(createdAt)).utc().tz('Asia/Hong_Kong').locale('zh-cn').format(`${dateFormat} HH:mm`)}
                  value={ConvertTimeZone(createdAt, `${dateFormat} HH:mm:ss`)}
                />
              </div>
              <FieldItem
                loadingData={loadingData}
                className="mt-6"
                label={t('extended_service:note')}
                value={note}
              />
            </Transition.Child>
          </div>
          <Transition.Child
            className="rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 h-fit mt-6"
            enter="duration-700 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-10 opacity-0"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <PanelTitle
              loadingData={loadingData}
              title={t('extended_service:shipping_information')}
            />
            <div className="grid grid-cols-3 mt-6">
              <FieldItem
                loadingData={loadingData}
                label={t('extended_service:receiver_name')}
                value={name}
              />
              <FieldItem
                loadingData={loadingData}
                label={t('extended_service:phone_number')}
                value={phone}
              />
            </div>
            <div className="grid grid-cols-3 mt-6">
              <FieldItem
                loadingData={loadingData}
                label={t('extended_service:address')}
                value={address1}
              />
              <FieldItem
                loadingData={loadingData}
                label={t('extended_service:address_details')}
                value={street}
              />
            </div>
            <div className="grid grid-cols-3 mt-6">
              <FieldItem
                loadingData={loadingData}
                label={t('extended_service:district')}
                value={district}
              />
              <FieldItem
                loadingData={loadingData}
                label={t('extended_service:country')}
                value={getCountryValue()}
              />
            </div>
            <div className="grid grid-cols-3 mt-6">
              <FieldItem
                loadingData={loadingData}
                label={t('extended_service:delivery_via')}
                tips={t('extended_service:delivery_via_tips')}
                value={
                  deliveryState
                    ? deliveryState.toLowerCase().includes('manual')
                      ? t('extended_service:wonder_staff')
                      : transformField(shipper)
                    : ''
                }
              />
              <FieldItem
                loadingData={loadingData}
                label={t('extended_service:delivery_schedule')}
                tips={t('extended_service:delivery_schedule_tips')}
                value={
                  deliveryState
                    ? deliveryState.toLowerCase().includes('manual')
                      ? t('extended_service:contant_support')
                      : scheduleAt
                    : ''
                }
              />
              <FieldItem
                loadingData={loadingData}
                label={t('extended_service:tracking_number')}
                tips={t('extended_service:tracking_number_tips')}
                tipStype={{
                  width: '320px',
                }}
                value={
                  deliveryState
                    ? deliveryState.toLowerCase().includes('manual')
                      ? t('extended_service:contant_support')
                      : tracking_number
                    : ''
                }
              />
            </div>
          </Transition.Child>
          {paymentRecord && (
            <Transition.Child
              className="rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 h-fit mt-6"
              enter="duration-700 transform transition ease-in-out"
              enterFrom="-translate-x-1 translate-y-10 opacity-0"
              enterTo="translate-x-0 translate-y-0 opacity-100"
              leave="duration-500 transform transition"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <PanelTitle
                loadingData={loadingData}
                title={t('extended_service:payment_details')}
              />
              {isRefunded && refundedRecord && (
                <>
                  <PaymentDetailsPanel
                    record={refundedRecord}
                    dateFormat={dateFormat}
                    type={
                      <span
                        className={`px-3 py-1 rounded-3xl text-primary-color bg-primary-color bg-opacity-10`}
                      >
                        REFUNDED
                      </span>
                    }
                  />
                  <div className="border-b border-border-color w-full h-1 my-4"></div>
                </>
              )}
              <PaymentDetailsPanel
                record={paymentRecord}
                dateFormat={dateFormat}
                type={
                  <span
                    className={`px-3 py-1 rounded-3xl ${paymentRecord.voided_at
                      ? 'text-[#828282] bg-[#828282]'
                      : 'text-[#4CD964] bg-[#4CD964]'
                      } bg-opacity-10`}
                  >
                    {paymentRecord.voided_at ? 'VOID' : 'SALE'}
                  </span>
                }
              />
            </Transition.Child>
          )}
        </div>
      </Transition.Root>
      <AntdModal
        onOk={handleVoid}
        type="warn"
        open={showNodeVoidModal}
        onCancel={() => setShowVoidModal(false)}
      >
        <div className="font-bold text-xl mt-4">
          {t('extended_service:void_invoice')}
        </div>
        <div className="font-medium text-sm mt-2">
          {t('extended_service:void_invoice_tips')}
        </div>
      </AntdModal>
    </>
  );
};

export default DetailsWebPage;
