import { IObject } from '@/types/common-types';
import { useEffect, useRef, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { b2cOtpSend, b2cOtpVerify, b2cSignin } from '@/data/account';

import { useTranslation } from 'react-i18next';
import { insertComma } from '@/utils';
import Button from '@/components/common/button';
import { Alert } from '@/components/common/alert/alert';

interface IAuthenticationProps {
  values: IObject;
  token: string;
  title: string;
  count: number;
  sendCodeType: 'signin' | 'pwdreset';
  showTip?: boolean;
  successMsg?: string;
  callback: (token: IObject) => void;
  setCount: (num: number) => void;
}

const Authentication: React.FC<IAuthenticationProps> = (props) => {
  const { t } = useTranslation('signin');

  const {
    values,
    token,
    count,
    setCount,
    sendCodeType,
    title,
    showTip = false,
    callback,
    successMsg = t('signin:verify_successfully'),
  } = props;

  const ref = useRef<any>(null);

  const [autCode, setAutCode] = useState('');
  const [currentToken, setCurrentToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (ref && ref.current && ref.current.inputs) {
      ref.current.inputs[0].focus();
    }
    // eslint-disable-next-line
  }, []);

  const handleClick = async () => {
    if (count > 0 || isSending) return;

    setIsSending(true);
    const data = await b2cOtpSend(
      {
        phone: values.phone,
        email: values.email,
        action: 'signin',
        token,
      }
    );

    if (data.code === 200) {
      const record = data.data || {};
      setCurrentToken(record.temporaryToken || '');
      Alert.success({
        message: t('signin:verification_code_sent_successfully'),
        wrapClass: '-top-10',
      });
      setCount(60);
    } else if (data.message) {
      Alert.error({
        message: data.message,
        wrapClass: '-top-10',
      });
    }

    setIsSending(false);
  };

  const handleVerification = async () => {
    setLoading(true);

    let data: any = {};

    if (sendCodeType === 'signin') {
      data = await b2cSignin(
        {
          aut_code: autCode,
          email: values.email || '',
          phone: values.phone || '',
          token: currentToken || token,
        }
      );
    } else {
      data = await b2cOtpVerify(
        {
          action: sendCodeType,
          aut_code: autCode,
          email: values.email || '',
          phone: values.phone || '',
          token: currentToken || token,
        }
      );
    }

    if (data.code === 200) {
      const result = data.data || {};
      callback(result);
      Alert.success({
        message: successMsg,
        wrapClass: '-top-10',
      });
      setLoading(false);
    } else if (data.message) {
      Alert.error({
        message: data.message,
        wrapClass: '-top-10',
      });
      setLoading(false);
    }
  };

  const inputProps: any = {
    className: 'react-input',
    inputStyle: {
      borderRadius: '6px',
      marginRight: '4px',
      height: '76px',
      fontSize: '32px',
      textAlign: 'center',
      flex: 1,
    },
  };

  return (
    <>
      <h1 className="text-3xl text-color-text-1 text-center font-bold mb-6">
        {title}
      </h1>
      <div className="text-sm text-center text-color-text-2">{`${
        values.phone
          ? t('signin:code_is_sent_to_your_phone')
          : t('signin:code_is_sent_to_your_email')
      } `}</div>
      <div className="text-color-text-1 text-center font-medium">
        {values.phone ? insertComma(values.phone, true, 4, ' ') : values.email}
      </div>
      <div className="mt-6 mb-2 text-color-text-1 text-sm">
        {t('signin:enter_verification_code')}
      </div>
      <ReactCodeInput
        {...inputProps}
        fields={6}
        name="code"
        type="number"
        inputMode="verbatim"
        onChange={(code: any) => {
          setAutCode(code);
        }}
      />
      <div
        className={`my-6 text-sm font-medium text-color-text-2 ${
          count === 0 ? 'cursor-pointer underline' : ''
        }`}
        onClick={() => handleClick()}
      >
        {t('signin:send_again')} {count !== 0 ? `(${count})` : ''}
      </div>
      <Button
        disabled={autCode.length < 6}
        onClick={() => handleVerification()}
        loading={loading}
        className="w-full h-12 rounded-lg"
      >
        {t('signin:verify')}
      </Button>
      {showTip && (
        <div className="text-sm text-color-text-2 text-center mt-2 mx-1.5">
          {t('signin:lost_device_tips')}
        </div>
      )}
    </>
  );
};

export default Authentication;
