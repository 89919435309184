import Skeleton from '@/components/skeleton';

const SkeletonPanel = () => {
  return (
    <div className="w-full mb-6 md:mb-0">
      <div className={`h-4 className`}>
        <Skeleton
          row={1}
          skeletonItemClass="h-4 rounded-[50px]"
          className="h-4"
        />
      </div>
      <div className="h-6">
        <Skeleton
          row={1}
          skeletonItemClass="h-6 rounded-[50px]"
          className="h-6 mt-3"
        />
      </div>
    </div>
  );
};
const BusinessSettingsSkeleton = () => {
  return (
    <>
      <div className="px-8 py-6">
        <div className="flex mb-6 justify-between">
          <div className="h-10 w-1/2">
            <Skeleton
              row={1}
              skeletonItemClass="h-10 rounded-[50px]"
              className="h-10"
            />
          </div>
          <div className="h-10 w-32">
            <Skeleton
              row={1}
              skeletonItemClass="h-10 rounded-[50px]"
              className="h-10"
            />
          </div>
        </div>

        <div className="w-full mt-6">
          <div
            className={`w-full flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit`}
          >
            <div className="h-6 md:w-1/3">
              <Skeleton
                row={1}
                skeletonItemClass="h-6 rounded-[50px]"
                className="h-10"
              />
            </div>
            <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
            <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                {new Array(3).fill(1).map((item, index) => {
                  return (
                    <div key={`${item}_${index}`}>
                      <Skeleton row={1} skeletonItemClass="rounded h-32" />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mt-6">
          <div
            className={`w-full flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit`}
          >
            <div className="h-6 md:w-1/3">
              <Skeleton
                row={1}
                skeletonItemClass="h-6 rounded-[50px]"
                className="h-10"
              />
            </div>
            <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
            <div className="grid grid-cols-1 gap-x-6 gap-y-2 md:gap-y-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-6 mb-0 md:mb-4">
                <SkeletonPanel />
                <SkeletonPanel />
                <SkeletonPanel />
              </div>
              <div className="w-full mb-6 md:mb-0">
                <div className="h-4 md:w-1/3">
                  <Skeleton
                    row={1}
                    skeletonItemClass="h-4 rounded-[50px]"
                    className="h-4"
                  />
                </div>
                <div className="h-6">
                  <Skeleton
                    row={1}
                    skeletonItemClass="h-6 rounded-[50px]"
                    className="h-6 mt-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-6">
          <div
            className={`w-full flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit`}
          >
            <div className="h-6 md:w-1/3">
              <Skeleton
                row={1}
                skeletonItemClass="h-6 rounded-[50px]"
                className="h-10"
              />
            </div>
            <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
            <div className="grid grid-cols-1 gap-x-6 gap-y-2 md:gap-y-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-6 mb-0 md:mb-4">
                <SkeletonPanel />
                <SkeletonPanel />
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-6 mb-0 md:mb-4">
                <SkeletonPanel />
                <SkeletonPanel />
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-6 mb-0 md:mb-4">
                <SkeletonPanel />
                <SkeletonPanel />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessSettingsSkeleton;
