/**
 * token 失效的中间页面
 */

import { Alert } from "@/components/common/alert/alert";
import i18n from "@/i18n";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const LoggedOutAlertPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = i18n.language;
  
  useEffect(() => {
    Alert.warn({
      message: t('common:logged_out_tips'),
      position: 'default',
      wrapClass: 'top-px',
    });
    navigate(`/${lang}/account/signin`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export default LoggedOutAlertPage;
