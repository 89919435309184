import { useEffect, useState } from 'react';
import HistoryWebPage from '../web/history-web';
import HistoryMobilePage from '../mobile/records';
import { getCustomerID, queryOrderList, resetListing } from '../../../data/terminal';
import type { IObject } from '@/types/common-types';
import type { IFilterOptions } from '@/components/common/filter';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getCookie, setCookie } from '@/utils';
import { Constants } from '@/constants';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-hk';
import 'dayjs/locale/en';
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Hong_Kong');

const TerminalOrdersPage = () => {
  const { p_business_id: businessID = '', lang = 'en' } = useParams();
  const navigate = useNavigate();
  const [records, setRecords] = useState<IObject[]>([]);
  const [customerID, setCustomerID] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [pageSize, setPageSize] = useState<number>(30);
  const [page, setPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState('');
  const [filterOptions, setFilterOptions] = useState<IFilterOptions[]>([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryToken = searchParams.get('token');
  let inApp = false
  const [dataTimeOut, setdataTimeOut] = useState<boolean>(false);
  const queryInApp = searchParams.get('isApp') || false;
  if (typeof queryInApp === 'string' && queryInApp === 'true') {
    inApp = true;
  }
  let access_token = '';
  if (typeof queryToken === 'string' && queryToken.length > 0) {
    access_token = queryToken;
  }
  const cookieToken = getCookie(Constants.TOKEN) || '';
  const token = access_token || cookieToken;


  useEffect(() => {
    window.addEventListener(
      'myCustomEvent',
      (e: any) => {
        if (e.detail.isBack === 'true') {
          setCookie('isNew', 'true');
          loadData();
        }
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    setCookie('isNew', 'true');
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async (
    currentPage = page,
    limit = pageSize,
    search = searchValue,
    options: IFilterOptions[] = filterOptions
  ) => {
    setLoading(true);
    let currentCustomerID = customerID;
    if (!currentCustomerID) {
      currentCustomerID = await getCustomerID(businessID, token);
      if (currentCustomerID === 'ERR_NETWORK') {
        setdataTimeOut(true)
        setLoading(false);
        return
      }
      setCustomerID(currentCustomerID);
    }
    const isNew = getCookie('isNew');
    if (isNew === 'true' && inApp) {
      resetListing(currentCustomerID, businessID, token)
    }
    if (currentCustomerID) {
      const { data = [], msg = '' } = await queryOrderList({
        businessID,
        token,
        offset: (currentPage - 1) * limit,
        limit: inApp ? limit : limit + 1,
        customerID: currentCustomerID,
        filter: '',
      });

      setRecords(data);
      setPageSize(limit);
      setPage(currentPage);
      setSearchValue(search);
      setFilterOptions(options);
      if (msg === 'timeout of 10000ms exceeded') {
        setdataTimeOut(true)
        setLoading(false);
        return
      }
    }
    setdataTimeOut(false)
    setLoading(false);
  };

  const handleGotoDetaislPage = (orderNumber: string) => {
    if (orderNumber) {
      navigate(`/${lang}/businesses/${businessID}/terminal/orders/${orderNumber}`);
    }
  };

  return (
    <>
      {!inApp && (
        <HistoryWebPage
          loading={loading}
          records={records}
          searchValue={searchValue}
          filterOptions={filterOptions}
          page={page}
          pageSize={pageSize}
          loadData={loadData}
          handleGotoDetaislPage={handleGotoDetaislPage}
          inApp={inApp}
          businessID={businessID}
          token={token}
          customerID={customerID}
        />
      )}
      {inApp && (
        <HistoryMobilePage
          loading={loading}
          records={records}
          loadData={loadData}
          searchValue={searchValue}
          filterOptions={filterOptions}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          inApp={inApp}
          businessID={businessID}
          token={token}
          dataTimeOut={dataTimeOut}
        />
      )}
      {/* <div>
        <HistoryMobilePage
          loading={loading}
          records={records}
          loadData={loadData}
          searchValue={searchValue}
          filterOptions={filterOptions}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          inApp={inApp}
          dataTimeOut={dataTimeOut}
          businessID={businessID}
          token={token}
        />
      </div> */}
    </>
  );
};

export default TerminalOrdersPage;
