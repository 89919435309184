import { createBrowserRouter } from "react-router-dom";
import routerWithoutSidebar from "./router-without-sidebar";
import routerWithSidebar from "./router-with-sidebar";
import routerWithoutToken from "./router-without-token";


const router = createBrowserRouter([
  ...routerWithoutSidebar,
  ...routerWithSidebar,
  ...routerWithoutToken,
])

export default router;