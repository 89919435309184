import type { IAccountDetailProps } from './type';
import Skeleton from '@/components/skeleton';
import NoFileIcon from './related-company-icons/no-file-icon';
import { useTranslation } from 'react-i18next';
import FileInput from '@/components/common/file-input/file-input';

const BankStatements = (props: IAccountDetailProps) => {
  const {
    loading = true,
    relatedFormData,
    setRelatedFormDate,
    isView,
    isAdd = false,
  } = props;
  const { bank_settlement_files = [] } = relatedFormData;
  const { t } = useTranslation(["settings", "business_account_settings", "account_balance"]);

  const handleBanlStatements = (data: any) => {
    setRelatedFormDate({
      ...relatedFormData,
      bank_settlement_files: data,
    });
  };
  
  return (
    <>
      <div className="p-6 rounded bg-white mb-6 shadow-dropdown">
        {loading ? (
          <Skeleton
            row={1}
            className={`mr-0`}
            width={['20%']}
            skeletonItemClass={`h-6 rounded-2xl`}
          />
        ) : (
          <div className="font-bold text-xl text-color-text-1 mb-1">
            {t('related_company_accounts:bank_statements')}
          </div>
        )}
       <div className="mt-4 h-[1px] w-[100%] bg-[#E2E8F0] mb-6" />
        <div
          className={`block gap-4 sm:grid`}
          style={{
            gridTemplateColumns: `repeat(7, minmax(0, 1fr))`,
          }}
        >
          {loading &&
            new Array(3).fill('').map((r, i) => {
              return (
                <Skeleton
                  row={1}
                  className={`mr-0`}
                  skeletonItemClass={`rounded aspect-w-4 aspect-h-4 flex flex-1`}
                  key={`${r}_${i}`}
                />
              );
            })}
          {!loading &&
            isView &&
            bank_settlement_files.length === 0 &&
            !isAdd && (
              <div className="aspect-w-6 aspect-h-6 flex flex-1">
                <div className="flex-1 justify-center items-center flex">
                  <div>
                    <NoFileIcon className="text-primary-color justify-center items-center flex w-14 h-11 font-normal m-auto" />
                    <div className=" text-color-text-2 text-sm text-center mt-3">
                      {t('related_company_accounts:no_file_yet')}
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
        {!loading && (bank_settlement_files.length > 0 || isAdd || !isView) && (
          <FileInput
            className="w-1/4"
            name={'bankStatements'}
            proportion={2}
            rowQuantity={6}
            maxCount={10}
            onChange={(data) => {
              handleBanlStatements(data);
              // onChange(data);
              // setPictures(data);
            }}
            defaultValue={[...bank_settlement_files]}
            // error={error}
            disabled={isView}
            dataBase={'treasury-services'}
          />
        )}
      </div>
    </>
  );
};

export default BankStatements;
