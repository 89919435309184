import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import memoize from 'memoize-one';
import React from 'react';
import Skeleton from '../skeleton';
import type { TableRecord } from '../table/interface';
import type { TableOnRow, TableOnRowCell } from './interface';
import { useTranslation } from 'react-i18next';
import type { TableRowSelection } from 'antd/es/table/interface';
import type { IPaginationProps } from '../pagination/pagination';
import Pagination from '../pagination/pagination';
import NoResultsIcon from './no-results-icon';
import EmptyDataIcon from './empty-data-icon';
import './table.css'

interface ITableProps {
  records?: TableRecord[];
  columns?: ColumnsType<TableRecord>;
  onRow?: TableOnRow;
  onRowCell?: TableOnRowCell;
  rowSelection?: TableRowSelection<TableRecord>;
  loadingData?: boolean;
  rowKey?: string | ((record: TableRecord) => string);
  className?: string;
  hiddenNoData?: boolean;
  emptyTitle?: string;
  emptyMessage?: string;
  pagination?: IPaginationProps;
  search?: boolean;
  searchEmptyClass?: string;
}

const getColumns = memoize(
  (columns?: ColumnsType<TableRecord>, onRowCell?: TableOnRowCell) => {
    const columnList = columns || [];
    const newColumns: ColumnsType<TableRecord> = [];

    for (let i = 0; i < columnList.length; i++) {
      const column = columnList[i];
      newColumns.push({
        ...column,
        onCell: (record) =>
          onRowCell ? onRowCell(record, column.key?.toString()) : {},
      });
    }

    return newColumns;
  }
);

const AntdTable: React.FC<ITableProps> = (props) => {
  const {
    loadingData,
    rowKey = 'id',
    className,
    onRowCell,
    hiddenNoData,
    rowSelection,
    onRow,
    emptyMessage,
    emptyTitle,
    pagination,
    records = [],
    search,
    searchEmptyClass,
  } = props;
  const { t } = useTranslation();
  const columns = getColumns(props.columns, onRowCell);
  const dataSource = [...records];
  if (pagination && records.length > pagination.pageSize) {
    dataSource.pop();
  }
  return (
    <>
      {search && !loadingData && records.length === 0 ? (
        <div
          className={`flex flex-col items-center text-color-text-1 mt-6 pb-2.5 ${searchEmptyClass}`}
        >
          <NoResultsIcon size={176} />
          <div className="mt-4 font-bold text-xl">
            {t('common:no_find_any')}
          </div>
          <div className="mt-2 text-sm font-medium">
            {t('common:no_find_any_tips')}
          </div>
        </div>
      ) : (
        <>
          <div className={`w-full flex-1 flex flex-col overflow-hidden`}>
            <div
              className={`h-auto shadow-card-drop max-h-full border border-border-color rounded overflow-auto ${className} hidden_scrollbar`}
            >
              <Table
                className={"hidden_scrollbar"}
                columns={columns}
                rowClassName="cursor-pointer"
                dataSource={loadingData ? [] : dataSource}
                pagination={false}
                scroll={{ x: 'max-content' }}
                sticky={true}
                rowKey={rowKey}
                onRow={(record, index) => (onRow ? onRow(record, index) : {})}
                rowSelection={rowSelection}
              />
              {loadingData && (
                <div className={`bg-white rounded pt-8 px-4`}>
                  <Skeleton
                    loading={loadingData}
                    row={10}
                    width={[
                      '60%',
                      '70%',
                      '80%',
                      '90%',
                      '100%',
                      '100%',
                      '90%',
                      '80%',
                      '70%',
                      '60%',
                    ]}
                  />
                </div>
              )}
            </div>
            {records.length === 0 && !loadingData && (
              <div className="flex-1 flex flex-col items-center justify-center my-6">
                {!hiddenNoData && <EmptyDataIcon />}
                <div className="text-color-text-1 text-xl mt-6 font-bold">
                  {emptyTitle != null ? emptyTitle : t('common:no_activity')}
                </div>
                <div className="text-color-text-2 text-sm mt-2 font-medium">
                  {emptyMessage != null
                    ? emptyMessage
                    : t('common:will_show_up')}
                </div>
              </div>
            )}
          </div>
          {pagination && <Pagination {...pagination} />}
        </>
      )}
    </>
  );
};

export default AntdTable;
