import React from 'react';
import type { IIconProps } from '@/types/common-types';

const QrCodeIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M58.1266 58.5063C58.3986 58.0721 58.5461 57.5668 58.5461 57.0463C58.5461 56.3169 58.2564 55.6175 57.7407 55.1017C57.225 54.586 56.5255 54.2963 55.7961 54.2963C55.2756 54.2963 54.7703 54.4439 54.3362 54.7158L58.1266 58.5063Z"
          fill="#71829A"
        />
        <path
          d="M41.3648 41.7445C41.6368 41.3103 41.7843 40.805 41.7843 40.2845C41.7843 39.5551 41.4946 38.8557 40.9789 38.34C40.4632 37.8242 39.7637 37.5345 39.0344 37.5345C38.5138 37.5345 38.0085 37.6821 37.5744 37.9541L41.3648 41.7445Z"
          fill="#71829A"
        />
        <path
          d="M11.9276 12.3073L8.92188 9.30156C10.6572 6.78071 13.563 5.1284 16.8555 5.1284C22.1722 5.1284 26.4805 9.4367 26.4805 14.7533C26.4805 18.0459 24.8282 20.9517 22.3073 22.687L19.3016 19.6813C21.112 18.7814 22.3555 16.9132 22.3555 14.7533C22.3555 11.7146 19.8943 9.25337 16.8555 9.25337C14.6957 9.25337 12.8275 10.4968 11.9276 12.3073Z"
          fill="#71829A"
        />
        <path
          d="M16.9399 17.3196L13.9314 14.311C13.9939 13.6816 14.2721 13.0897 14.7233 12.6386C15.239 12.1228 15.9385 11.8331 16.6678 11.8331C17.3972 11.8331 18.0967 12.1228 18.6124 12.6386C19.1281 13.1543 19.4178 13.8537 19.4178 14.5831C19.4178 15.3124 19.1281 16.0119 18.6124 16.5276C18.1612 16.9788 17.5693 17.257 16.9399 17.3196Z"
          fill="#71829A"
        />
        <path
          d="M47.4589 14.5831C47.4589 15.3124 47.7486 16.0119 48.2644 16.5276C48.7801 17.0433 49.4795 17.3331 50.2089 17.3331C50.9382 17.3331 51.6377 17.0433 52.1534 16.5276C52.6691 16.0119 52.9589 15.3124 52.9589 14.5831C52.9589 13.8537 52.6691 13.1543 52.1534 12.6386C51.6377 12.1228 50.9382 11.8331 50.2089 11.8331C49.4795 11.8331 48.7801 12.1228 48.2644 12.6386C47.7486 13.1543 47.4589 13.8537 47.4589 14.5831Z"
          fill="#71829A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.7542 14.7533C40.7542 9.43669 45.0625 5.12839 50.3791 5.12839C55.6957 5.12839 60.0041 9.43669 60.0041 14.7533C60.0041 20.0699 55.6957 24.3783 50.3791 24.3783C45.0625 24.3783 40.7542 20.0699 40.7542 14.7533ZM44.8792 14.7533C44.8792 17.792 47.3404 20.2533 50.3791 20.2533C53.4179 20.2533 55.8791 17.792 55.8791 14.7533C55.8791 11.7146 53.4179 9.25336 50.3791 9.25336C47.3404 9.25336 44.8792 11.7146 44.8792 14.7533Z"
          fill="#71829A"
        />
        <path
          d="M31.5026 16.5276C30.9868 16.0119 30.6971 15.3124 30.6971 14.5831C30.6971 13.8537 30.9868 13.1543 31.5026 12.6386C32.0183 12.1228 32.7178 11.8331 33.4471 11.8331C34.1764 11.8331 34.8759 12.1228 35.3916 12.6386C35.9074 13.1543 36.1971 13.8537 36.1971 14.5831C36.1971 15.3124 35.9074 16.0119 35.3916 16.5276C34.8759 17.0433 34.1764 17.3331 33.4471 17.3331C32.7178 17.3331 32.0183 17.0433 31.5026 16.5276Z"
          fill="#71829A"
        />
        <path
          d="M30.6971 8.99582C30.6971 9.72516 30.9868 10.4246 31.5026 10.9404C32.0183 11.4561 32.7178 11.7458 33.4471 11.7458C34.1764 11.7458 34.8759 11.4561 35.3916 10.9404C35.9074 10.4246 36.1971 9.72516 36.1971 8.99582C36.1971 8.26648 35.9074 7.56701 35.3916 7.05129C34.8759 6.53557 34.1764 6.24584 33.4471 6.24584C32.7178 6.24584 32.0183 6.53557 31.5026 7.05129C30.9868 7.56701 30.6971 8.26648 30.6971 8.99582Z"
          fill="#71829A"
        />
        <path
          d="M37.0898 25.4672C36.5741 24.9515 36.2844 24.252 36.2844 23.5227C36.2844 22.7934 36.5741 22.0939 37.0898 21.5782C37.6056 21.0625 38.305 20.7727 39.0344 20.7727C39.7637 20.7727 40.4632 21.0625 40.9789 21.5782C41.4946 22.0939 41.7843 22.7934 41.7843 23.5227C41.7843 24.252 41.4946 24.9515 40.9789 25.4672C40.4632 25.983 39.7637 26.2727 39.0344 26.2727C38.305 26.2727 37.6056 25.983 37.0898 25.4672Z"
          fill="#71829A"
        />
        <path
          d="M30.6971 29.11C30.6971 29.8393 30.9868 30.5388 31.5026 31.0545C32.0183 31.5702 32.7178 31.8599 33.4471 31.8599C34.1764 31.8599 34.8759 31.5702 35.3916 31.0545C35.9074 30.5388 36.1971 29.8393 36.1971 29.11C36.1971 28.3806 35.9074 27.6812 35.3916 27.1654C34.8759 26.6497 34.1764 26.36 33.4471 26.36C32.7178 26.36 32.0183 26.6497 31.5026 27.1654C30.9868 27.6812 30.6971 28.3806 30.6971 29.11Z"
          fill="#71829A"
        />
        <path
          d="M31.5026 22.1149C30.9868 21.5992 30.6971 20.8997 30.6971 20.1703C30.6971 19.441 30.9868 18.7415 31.5026 18.2258C32.0183 17.7101 32.7178 17.4204 33.4471 17.4204C34.1764 17.4204 34.8759 17.7101 35.3916 18.2258C35.9074 18.7415 36.1971 19.441 36.1971 20.1703C36.1971 20.8997 35.9074 21.5992 35.3916 22.1149C34.8759 22.6306 34.1764 22.9203 33.4471 22.9203C32.7178 22.9203 32.0183 22.6306 31.5026 22.1149Z"
          fill="#71829A"
        />
        <path
          d="M53.0462 34.6972C53.0462 35.4266 53.3359 36.126 53.8516 36.6418C54.3673 37.1575 55.0668 37.4472 55.7961 37.4472C56.5255 37.4472 57.225 37.1575 57.7407 36.6418C58.2564 36.126 58.5461 35.4266 58.5461 34.6972C58.5461 33.9679 58.2564 33.2684 57.7407 32.7527C57.225 32.237 56.5255 31.9472 55.7961 31.9472C55.0668 31.9472 54.3673 32.237 53.8516 32.7527C53.3359 33.2684 53.0462 33.9679 53.0462 34.6972Z"
          fill="#71829A"
        />
        <path
          d="M53.8516 31.0545C53.3359 30.5388 53.0462 29.8393 53.0462 29.11C53.0462 28.3806 53.3359 27.6812 53.8516 27.1654C54.3673 26.6497 55.0668 26.36 55.7961 26.36C56.5255 26.36 57.225 26.6497 57.7407 27.1654C58.2564 27.6812 58.5461 28.3806 58.5461 29.11C58.5461 29.8393 58.2564 30.5388 57.7407 31.0545C57.225 31.5702 56.5255 31.8599 55.7961 31.8599C55.0668 31.8599 54.3673 31.5702 53.8516 31.0545Z"
          fill="#71829A"
        />
        <path
          d="M47.4589 29.11C47.4589 29.8393 47.7486 30.5388 48.2644 31.0545C48.7801 31.5702 49.4795 31.8599 50.2089 31.8599C50.9382 31.8599 51.6377 31.5702 52.1534 31.0545C52.6691 30.5388 52.9589 29.8393 52.9589 29.11C52.9589 28.3806 52.6691 27.6812 52.1534 27.1654C51.6377 26.6497 50.9382 26.36 50.2089 26.36C49.4795 26.36 48.7801 26.6497 48.2644 27.1654C47.7486 27.6812 47.4589 28.3806 47.4589 29.11Z"
          fill="#71829A"
        />
        <path
          d="M42.6771 31.0545C42.1614 30.5388 41.8716 29.8393 41.8716 29.11C41.8716 28.3806 42.1614 27.6812 42.6771 27.1654C43.1928 26.6497 43.8923 26.36 44.6216 26.36C45.351 26.36 46.0504 26.6497 46.5662 27.1654C47.0819 27.6812 47.3716 28.3806 47.3716 29.11C47.3716 29.8393 47.0819 30.5388 46.5662 31.0545C46.0504 31.5702 45.351 31.8599 44.6216 31.8599C43.8923 31.8599 43.1928 31.5702 42.6771 31.0545Z"
          fill="#71829A"
        />
        <path
          d="M48.2644 47.8163C47.7486 47.3006 47.4589 46.6011 47.4589 45.8718C47.4589 45.1424 47.7486 44.4429 48.2644 43.9272C48.7801 43.4115 49.4795 43.1218 50.2089 43.1218C50.9382 43.1218 51.6377 43.4115 52.1534 43.9272C52.6691 44.4429 52.9589 45.1424 52.9589 45.8718C52.9589 46.6011 52.6691 47.3006 52.1534 47.8163C51.6377 48.332 50.9382 48.6217 50.2089 48.6217C49.4795 48.6217 48.7801 48.332 48.2644 47.8163Z"
          fill="#71829A"
        />
        <path
          d="M53.0462 45.8718C53.0462 46.6011 53.3359 47.3006 53.8516 47.8163C54.3673 48.332 55.0668 48.6217 55.7961 48.6217C56.5255 48.6217 57.225 48.332 57.7407 47.8163C58.2564 47.3006 58.5461 46.6011 58.5461 45.8718C58.5461 45.1424 58.2564 44.4429 57.7407 43.9272C57.225 43.4115 56.5255 43.1218 55.7961 43.1218C55.0668 43.1218 54.3673 43.4115 53.8516 43.9272C53.3359 44.4429 53.0462 45.1424 53.0462 45.8718Z"
          fill="#71829A"
        />
        <path
          d="M42.6771 36.6418C42.1614 36.126 41.8716 35.4266 41.8716 34.6972C41.8716 33.9679 42.1614 33.2684 42.6771 32.7527C43.1928 32.237 43.8923 31.9472 44.6216 31.9472C45.351 31.9472 46.0504 32.237 46.5662 32.7527C47.0819 33.2684 47.3716 33.9679 47.3716 34.6972C47.3716 35.4266 47.0819 36.126 46.5662 36.6418C46.0504 37.1575 45.351 37.4472 44.6216 37.4472C43.8923 37.4472 43.1928 37.1575 42.6771 36.6418Z"
          fill="#71829A"
        />
      </svg>
    </div>
  );
};

export default QrCodeIcon;
