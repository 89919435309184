import Button from '@/components/common/button';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import PayoutResultIcon from '../global-accounts-icons/payout-result-icon';
import RightIcon from '../global-accounts-icons/right-icon';
import CloseIcon from '@/components/svg/close-icon';
import { useTranslation } from 'react-i18next';
import { getCookie } from '@/utils/index';
import { getCountryByCurrency } from '../../../utils/global-account-untils';
import { useEffect, useState } from 'react';
import Config from '@/libs/config';
import { Constants } from '@/constants';
import api from '@/libs/api';
import UnsuccessfulIcon from '@/components/svg/failed-icon';
import { IObject } from '@/types/common-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

dayjs.extend(utc);

interface ResultData {
  sell_amount?: string;
  buy_amount?: string;
  sell_currency?: string;
  p_business_id?: string;
  fx_conversion_id?: string;
  exchange_rate?: string;
  buy_currency?: string;
  p_created_at?: string;
  est_settled_at?: string;
  error_code?: string;
  id?: string;
}
const FxResult = () => {
  const { p_business_id: businessID = '', ledger_account_id: recordId = '' } = useParams();
  const { t } = useTranslation( ["global_accounts", "account_balance"]);
  const prefix = Config.staticPath;
  const [finalData, setFinalData] = useState<ResultData>({});
  const [resultData, setResultData] = useState<ResultData>({});
  const navigate = useNavigate();
  const location = useLocation();
  const { lang = 'en' } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const fx_conversion_id = searchParams.get('fx_conversion_id');
  const handleCancel = () => {
    if (recordId === 'noAccountId') {
      navigate(`/${lang}/businesses/${businessID}/global-accounts`);
    } else {
      navigate(
        `/${lang}/businesses/${businessID}/global-accounts/${recordId}/current-activities`
      );
    }
  };
  useEffect(() => {
    getResultData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getResultData = async () => {
    // const fx_conversion_id = query.fx_conversion_id;
    const token = getCookie(Constants.TOKEN) || '';
    const resp: IObject = await api.get(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/fx_conversions/${fx_conversion_id}`,
      {
        method: 'GET',
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': businessID,
        },
      }
    );
    setResultData(resp);
    setFinalData(resp.data);
  };
  return (
    <div className="bg-system-background-color min-h-screen flex justify-center overflow-auto py-8 leading-snug">
      <CloseIcon
        size={20}
        onClick={handleCancel}
        className="text-color-text-1 cursor-pointer hover:text-primary-color p-3 bg-white rounded-full absolute right-10 top-8"
      />
      {!resultData.error_code && (
        <div className="my-auto">
          <div className="flex flex-col items-center p-6 max-w-xl justify-center m-auto bg-white rounde shadow-card-drop  w-[614px]">
            <PayoutResultIcon className="text-center" />
            <div className="mt-[24px]">
              {t('global_accounts:amount_exchanged')}
            </div>

            <div className="mt-[8px] text-color-text-1 font-bold text-xl flex justify-center">
              <span className="mr-2">{finalData.sell_currency}</span>
              <span>
                {' '}
                <RightIcon />
              </span>
              <span className="ml-2">{finalData.buy_currency}</span>
            </div>

            <div className="border border-border-color rounded-[4px] px-4 w-full py-1 mt-6 text-sm">
              <div className="flex justify-between items-center font-medium py-3">
                <div className="text-color-text-2">
                  {t('global_accounts:from')}
                </div>
                <div className="text-color-text-1 flex">
                  <img
                    // layout="intrinsic"
                    width="20px"
                    height="20px"
                    src={`${prefix}/images/country-flag/${getCountryByCurrency(
                      finalData.sell_currency || ''
                    )}.svg`}
                    alt=""
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {finalData.sell_amount} {finalData.sell_currency}
                </div>
              </div>
              <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">
                  {t('global_accounts:to')}
                </div>
                <div className="text-color-text-1 flex">
                  <img
                    // layout="intrinsic"
                    width="20px"
                    height="20px"
                    src={`${prefix}/images/country-flag/${getCountryByCurrency(
                      finalData.buy_currency || ''
                    )}.svg`}
                    alt=""
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {finalData.buy_amount} {finalData.buy_currency}
                </div>
              </div>

              <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">
                  {t('global_accounts:rate')}
                </div>
                <div className="text-color-text-1">
                  <div className="flex items-center">
                    <div>
                      {finalData.exchange_rate} {finalData.sell_currency} -{' '}
                      {finalData.buy_currency}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">
                  {t('global_accounts:created_at')}
                </div>
                <div className="text-color-text-1 flex items-center">
                  {dayjs(finalData.p_created_at).format(
                    'MMMM DD, YYYY HH:mm:ss'
                  )}
                </div>
              </div>
              <div className="flex justify-between font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">
                  {t('global_accounts:est_settled_at')}
                </div>
                <div className="text-color-text-1 max-w-[230px] text-right">
                  {dayjs(finalData.p_created_at).format(
                    'MMMM DD, YYYY HH:mm:ss'
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">ID</div>
                <div className="text-color-text-1">{finalData.id}</div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center w-full mt-6">
            <Button
              className="flex-1"
              onClick={handleCancel}
              styleType="Primary"
            >
              {t('common:done')}
            </Button>
          </div>
        </div>
      )}
      {resultData.error_code && (
        <div className="my-auto">
          <div className="flex flex-col items-center p-6 max-w-xl justify-center m-auto bg-white rounde shadow-card-drop  w-[614px]">
            <UnsuccessfulIcon className="text-center" />
            <div className="mt-[24px] text-xl font-bold text-error-color">
              {t('global_accounts:conversion_failed')}
            </div>
            <div className="text-sm text-error-color mt-2 font-normal">
              {t('global_accounts:reason_code')}: {resultData.error_code}
            </div>
            <div className="mt-[2px] text-sm text-error-color font-normal">
              {t('global_accounts:error_failed')}
            </div>
            <div className="mt-[8px] text-color-text-1 flex justify-center text-sm font-medium">
              {t('global_accounts:try_again')}
            </div>

            <div className="border border-border-color rounded-[4px] px-4 w-full py-1 mt-6 text-sm">
              <div className="flex justify-between items-center font-medium py-3">
                <div className="text-color-text-2">
                  {t('global_accounts:from')}
                </div>
                <div className="text-color-text-1 flex">
                  <img
                    // layout="intrinsic"
                    width="20px"
                    height="20px"
                    src={`${prefix}/images/country-flag/${getCountryByCurrency(
                      finalData.sell_currency || ''
                    )}.svg`}
                    alt=""
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {finalData.sell_amount} {finalData.sell_currency}
                </div>
              </div>
              <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">
                  {t('global_accounts:to')}
                </div>
                <div className="text-color-text-1 flex">
                  <img
                    // layout="intrinsic"
                    width="20px"
                    height="20px"
                    src={`${prefix}/images/country-flag/${getCountryByCurrency(
                      finalData.buy_currency || ''
                    )}.svg`}
                    alt=""
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {finalData.buy_amount} {finalData.buy_currency}
                </div>
              </div>

              <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">
                  {t('global_accounts:rate')}
                </div>
                <div className="text-color-text-1">
                  <div className="flex items-center">
                    <div>
                      {finalData.exchange_rate} {finalData.sell_currency} -{' '}
                      {finalData.buy_currency}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center w-full mt-6">
            <Button
              className="flex-1"
              onClick={handleCancel}
              styleType="Primary"
            >
              {t('common:back')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FxResult;
