import { queryMe } from "@/data/account";
import { getSignatureUrl, sendCompletedEmail } from "@/data/onboarding";
import api from "@/libs/api";
import Config from "@/libs/config";
import { ILoginUser } from "@/types/account-type";
import { IObject } from "@/types/common-types";
import { ISignatureType } from "@/types/signature-type";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from 'react-router-dom';

const HelloSignPage = () => {

  const [url, setUrl] = useState<string>('');
  const [contractId, setContractId] = useState<string>('');
  const [user, setUser] = useState<ILoginUser | undefined>(undefined);
  const [isSigned, setIsSigned] = useState<boolean>(false);
  const [businessId, setBusinessId] = useState<string>('');

  const [searchParams] = useSearchParams();
  const { lang = 'en'} = useParams();
  const business_application_id = searchParams.get('business_application_id');
  const access_token = searchParams.get('access_token');

  useEffect(() => {
    const listenerMessage = (e: any) => {
      if (e.data === 'signed' && !isSigned) {
        handleSigned();
      } else if (e.data === 'next') {
        handleNext();
      }
    };
    window.addEventListener('message', listenerMessage, false);
    return () => document.removeEventListener('message', listenerMessage);
    // eslint-disable-next-line
  }, [url, isSigned]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const handleSigned = async () => {
    if ((!url && url.length < 1) || isSigned) return;
    const res: IObject = await api.post(
      `${Config.urls.gatewayUrl}/api/registry/business-applications/signing`,
      {
        signature_file_url: url,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-p-business-id': businessId,
          'X-USER-ACCESS-TOKEN': access_token,
          'x-i18n-lang': lang,
        },
      }
    );
    
    if (res && res.code === 200) {
      // const allPromise: any[] = [];
      // allPromise.push(
      //   fetch(
      //     `${Config.urls.gatewayUrl}/api/registry/business-applications/signing`,
      //     {
      //       method: 'POST',
      //       body: {
      //         signature_file_url: sign_page_url,
      //       },
      //       headers: {
      //         'x-p-business-id': business_id,
      //         'X-USER-ACCESS-TOKEN': access_token,
      //       },
      //     }
      //   )
      // );
      // allPromise.push(
      //   sendSignedEmail(
      //     user.email,
      //     user.name || user.full_name,
      //     [
      //       {
      //         name: 'Payment Terms',
      //         url: `${config.urls.baseDomain}/en/wonder-app-content/payment-terms`,
      //       },
      //       {
      //         name: 'Remittance & Payout Terms',
      //         url: `${config.urls.baseDomain}/en/wonder-app-content/remittance-payout-terms`,
      //       },
      //       {
      //         name: 'Issuing Terms',
      //         url: `${config.urls.baseDomain}/en/wonder-app-content/issuing-terms`,
      //       },
      //       {
      //         name: 'Wonder General Merchant Services Agreement',
      //         url: sign_page_url,
      //       },
      //     ],
      //     lang
      //   )
      // );
      // allPromise.push(
      //   fetch.post(
      //     `/api/hellosign/msa`,
      //     {
      //       natural_person_id,
      //       fileUrl: sign_page_url,
      //       business_application_id,
      //       user_id: user.id,
      //     },
      //     {
      //       headers: {
      //         'x-user-access-token': access_token,
      //       },
      //     }
      //   )
      // );
      // await Promise.all(allPromise);
    } else if (res && res.message) {
      (window as any).handleWonder?.postMessage(
        JSON.stringify({
          context: 'Navigator',
          action: 'error',
          data: {
            message: res.message,
            code: res.code,
          },
        })
      );
    }

    setIsSigned(true)
  };

  const handleNext = () => {
    if (user && access_token) {
      sendCompletedEmail(user.name || user.full_name, user.email, access_token, lang);
    }
    (window as any).handleWonder?.postMessage(
      JSON.stringify({
        context: 'Navigator',
        action: 'success',
        data: { signatureId: contractId },
      })
    );
  };

  const loadData = async () => {
    if (business_application_id && access_token) {
      const user = await queryMe({
        headers: {
          'X-USER-ACCESS-TOKEN': access_token,
        },
        httpStatusCallback: () => {console.info('callback')}
      });
      let callBackUrl = `http://localhost:3102/business-onboarding/sign/succeeded?lang=${lang}`;
      let domain = window.location.hostname;

      if (domain !== 'localhost') {
        if (domain && domain.indexOf('https') === -1) {
          domain = `https://${domain}`;
        }
        callBackUrl = `${domain}/business-onboarding/sign/succeeded?lang=${lang}`;
      };

      const { sign_page_url = '', contract_id = '', business_id = '' } = await getSignatureUrl({
        callBackUrl,
        token: access_token,
        businessApplicationId: business_application_id,
        type: ISignatureType.MERCHANT_SERCICE_AGREEMENT
      });

      setUser(user)
      setUrl(sign_page_url);
      setContractId(contract_id);
      setBusinessId(business_id);
    }

    setTimeout(() => {
      (window as any).handleWonder?.postMessage(
        JSON.stringify({
          context: 'Navigator',
          action: 'close_loading',
        })
      );
    }, 1000);
  }

  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 flex pt-12 bg-primary-background-color">
      {url && (
        // eslint-disable-next-line
        <iframe id="iframe" className="w-full h-full border-none" src={url} />
      )}
    </div>
  )
}

export default HelloSignPage;