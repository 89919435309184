import React from 'react';
import type { IIconProps } from '@/types/common-types';

const CloseIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 16 } = props;
  return (
    <div
      className={`inline-flex items-center justify-center ${className}`}
      onClick={onClick}
    >
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2514_112999)">
          <path
            d="M2.1889 7.80901C1.985 7.60511 1.98499 7.27452 2.1889 7.07062L7.07634 2.18317C7.28024 1.97927 7.61083 1.97927 7.81474 2.18317C8.01864 2.38708 8.01864 2.71766 7.81474 2.92157L2.92729 7.80901C2.72339 8.01291 2.3928 8.01291 2.1889 7.80901Z"
            fill="currentColor"
          />
          <path
            d="M2.1889 2.18317C2.3928 1.97927 2.72339 1.97927 2.92729 2.18317L7.81474 7.07062C8.01864 7.27452 8.01864 7.60511 7.81473 7.80901C7.61083 8.01291 7.28024 8.01291 7.07634 7.80901L2.1889 2.92157C1.98499 2.71766 1.985 2.38708 2.1889 2.18317Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_2514_112999">
            <rect
              width="10.0003"
              height="10.0003"
              fill="white"
              transform="translate(10.001) rotate(90.003)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CloseIcon;
