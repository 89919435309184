import type { ServerResponse } from 'http';

class HeaderTimeHandle {
  headerName: string;
  startTime: number;

  constructor(headerName: string) {
    this.headerName = headerName;
    this.startTime = new Date().getTime();
  }

  static handle(headerName: string) {
    return new HeaderTimeHandle(headerName);
  }

  write(res: ServerResponse) {
    const endTime = new Date().getTime();

    res.setHeader(this.headerName, `${endTime - this.startTime}ms`);
  }
}

export default HeaderTimeHandle;
