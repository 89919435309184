import React from 'react';
import type { IIconProps } from '@/types/common-types';

const RtgsDetailsIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 32 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.80024 3.1654C4.97751 3.61399 3.56014 5.07001 3.13928 6.92619C2.93739 7.81663 2.95808 24.3097 3.1622 25.1608C3.5996 26.9851 5.01119 28.3967 6.83547 28.834C7.76144 29.056 24.2355 29.056 25.1615 28.834C26.9858 28.3967 28.396 26.9913 28.8309 25.1771C29.0565 24.2362 29.06 7.77391 28.8348 6.83473C28.398 5.01275 26.9897 3.59975 25.1778 3.16532C24.2583 2.94488 7.69587 2.94488 6.80024 3.1654ZM13.49 8.21969C13.7378 8.34645 13.9217 8.70322 13.9217 9.05718C13.9217 9.42871 13.8854 9.47855 12.8461 10.5376C12.4011 10.9911 12.4011 10.9911 17.3336 11.0282C21.9453 11.0628 22.279 11.074 22.465 11.2002C22.9072 11.5004 23.0426 11.9454 22.8272 12.3905C22.537 12.9899 22.8592 12.9567 17.3407 12.9567C14.6443 12.9567 12.4382 12.9824 12.4382 13.0139C12.4382 13.0455 12.7243 13.3542 13.074 13.7C14.0905 14.7054 14.2157 15.3639 13.4736 15.8022C12.8671 16.1606 12.697 16.0544 10.7347 14.092C8.53774 11.8947 8.53321 12.1776 10.8022 9.89073C12.6865 7.99146 12.8478 7.89118 13.49 8.21969ZM19.5524 16.2526C20.0619 16.6286 22.7496 19.3947 22.8585 19.6553C23.0772 20.1788 23.0166 20.2682 21.2031 22.0973C19.2306 24.0868 18.9681 24.23 18.353 23.6525C17.8131 23.1456 17.932 22.7082 18.8715 21.7462C19.5959 21.0044 19.5959 21.0044 14.6634 20.9673C10.0517 20.9327 9.71798 20.9215 9.53203 20.7953C9.08981 20.4951 8.95437 20.0501 9.16984 19.605C9.46 19.0056 9.1378 19.0388 14.6563 19.0388C17.3527 19.0388 19.5588 19.0131 19.5588 18.9816C19.5588 18.9501 19.2727 18.6414 18.923 18.2955C18.2088 17.5891 18.0753 17.3845 18.0753 16.9957C18.0753 16.2318 18.9368 15.7985 19.5524 16.2526Z"
          fill="#0094FF"
        />
      </svg>
    </div>
  );
};

export default RtgsDetailsIcon;
