import { useEffect, useState } from 'react';
import MobileDetail from '../mobile/order-view';
import {
  downloadInvoicePDF,
  getOrderDetails,
  handleVoidOrder,
  querySellerBusiness,
} from '../../../data/terminal';
import { IObject } from '@/types/common-types';
import DetailsWebPage from '../web/details-web-page';
import { useTranslation } from 'react-i18next';
import { Alert } from '@/components/common/alert/alert';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getCookie } from '@/utils';
import { Constants } from '@/constants';
import { useSelector } from 'react-redux';
import { IReducersState } from '@/reducers';

const TerminalOrdersDetailsPage = () => {
  const user = useSelector((state: IReducersState) => state.account.loginUser);
  const [record, setRecord] = useState<IObject>({});
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataTimeOut, setdataTimeOut] = useState<boolean>(false);
  const [business, setBusiness] = useState<IObject>({});
  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const navigate = useNavigate();
  const { lang = 'en', p_business_id: businessID = '', order_number: orderNumber = '' } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryToken = searchParams.get('token');
  let inApp = false
  const queryInApp = searchParams.get('isApp') || false;
  if (typeof queryInApp === 'string' && queryInApp === 'true') {
    inApp = true;
  }
  let access_token = '';
  if (typeof queryToken === 'string' && queryToken.length > 0) {
    access_token = queryToken;
  }
  const cookieToken = getCookie(Constants.TOKEN) || '';
  const token = access_token || cookieToken;

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const loadData = async () => {
    setdataTimeOut(false)
    setLoading(true);
    const record = await getOrderDetails(orderNumber, businessID, token);
    if (record === 'ERR_NETWORK') {
      setdataTimeOut(true)
      setLoading(false);
      return
    }
    const business = await querySellerBusiness();
    setBusiness(business);
    if (record) {
      setRecord(record);
    }
    setdataTimeOut(false)
    setLoading(false);
  };

  const handleExportPdf = async () => {
    setDownloadLoading(true);
    const { file_name = '', url = '' } = await downloadInvoicePDF(
      record,
      business,
      lang
    );
    if (url) {
      try {
        await fetch(url).then((res) =>
          res.blob().then((blob) => {
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${`${file_name}`}`;
            a.click();
            window.URL.revokeObjectURL(url);
            Alert.success({
              message: t('common:exported_successfully'),
              wrapClass: '-top-10',
            });
          })
        );
      } catch (error) {
        console.error(error);
        Alert.error({
          message: t('common:exported_failed'),
          wrapClass: '-top-10',
        });
      }
    }
    setDownloadLoading(false);
  };

  const handleVoid = async () => {
    let isSuccess = false;
    const data = await handleVoidOrder({
      number: orderNumber,
      token,
      businessID,
      userID: user.id,
    });

    if (data) {
      Alert.success({
        message: t('extended_service:void_invoice_successfully'),
        wrapClass: '-top-10',
      });
      setRecord(data);
      isSuccess = true;
    } else {
      Alert.error({
        message: t('extended_service:void_invoice_failed'),
        wrapClass: '-top-10',
      });
    }

    return isSuccess;
  };

  const handlePayNow = () => {
    navigate(
      `/${lang}/businesses/${businessID}/terminal/orders/payment?number=${orderNumber}`
    );
  };

  const handleBack = () => {
    navigate(`/${lang}/businesses/${businessID}/terminal/orders`);
  };

  return (
    <>
      {!inApp && (
        <DetailsWebPage
          record={record}
          downloadLoading={downloadLoading}
          handleBack={handleBack}
          loadingData={loading}
          voidFun={handleVoid}
          handlePayNow={handlePayNow}
          handleExportPdf={handleExportPdf}
          orderNumber={orderNumber}
          inApp={inApp}
          businessID={businessID}
          token={token}
        />
      )}
      {inApp && (
        <MobileDetail
          record={record}
          // handleBack={handleBack}
          voidFun={handleVoid}
          // orderNumber={orderNumber}
          loadData={loadData}
          handlePayNow={handlePayNow}
          handleExportPdf={handleExportPdf}
          orderNumber={orderNumber}
          inApp={inApp}
          businessID={businessID}
          token={token}
          dataTimeOut={dataTimeOut}
        />
      )}
      {/* <div>
        <MobileDetail
           record={record}
           // handleBack={handleBack}
           voidFun={handleVoid}
           // orderNumber={orderNumber}
           loadData={loadData}
           handlePayNow={handlePayNow}
           handleExportPdf={handleExportPdf}
           orderNumber={orderNumber}
           inApp={inApp}
           businessID={businessID}
           token={token}
           dataTimeOut={dataTimeOut}
        />
      </div> */}
    </>
  );
};

export default TerminalOrdersDetailsPage;
