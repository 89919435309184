import styles from '@/pages/content.module.css';
import Config from '@/libs/config';
import { useParams } from 'react-router-dom';

const PrivacyPolicy = () => {
  const { lang = 'en' } = useParams();
  const prefix = Config.staticPath;

  return (
    <>
      <head>
        <link
          rel="preload"
          href={`${prefix}/font/Outfit-Light.woff`}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="preload"
          href={`${prefix}/font/Outfit-Bold.woff`}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        ></link>
      </head>
      {lang !== 'zh-CN' && lang !== 'zh-HK' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title} text-center`}>Privacy Policy</div>
            <div className={`${styles.content} break-all`}>
              <h3>I. General Provisions</h3>
              <p>
                Welcome to our ride-hailing service! To better provide services and protect your personal privacy, we have established the following privacy policy. This policy covers how we collect, use, disclose, transfer, and store your information. Please read this privacy policy carefully before using our services.
              </p>
              <h3>II. Information Collection</h3>
              <p>2.1 Information You Provide</p>
              <ul>
                <li>Registration Information: Your name, phone number, email address, etc., provided during the registration process.</li>
                <li>Payment Information: Bank card information, credit card information, etc., provided for payment services.</li>
              </ul>
              <p>2.2 Information We Automatically Collect</p>
              <ul>
                <li>Location Information: When you use our service, we collect your real-time geographic location information to provide you with the nearest vehicle service.</li>
                <li>Device Information: We collect information about the type of device you use, the operating system version, device identifiers, etc.</li>
              </ul>
              <p>2.3 Information from Third-Party Sources We may obtain some of your information from other lawful sources, including business partners and social media platforms.</p>
              <h3>III. Use of Information</h3>
              <p>The information we collect is mainly used for the following purposes:</p>
              <ul>
                <li>Providing, maintaining, and improving our services.</li>
                <li>Processing transactions and sending related information, including booking confirmations and updates.</li>
                <li>Contacting you via email, SMS, etc., to send service updates or other promotional information.</li>
                <li>Conducting internal audits, data analysis, and research to improve our products and services.</li>
              </ul>
              <h3>IV. Information Sharing and Disclosure</h3>
              <p>4.1 Partners We may share your information with technology service providers, payment service providers, and other business partners to support our business operations.</p>
              <p>4.2 Legal Requirements We may disclose your information when required by law, government demands, or judicial procedures.</p>
              <p>4.3 Asset Transfers If we are involved in a merger, acquisition, or asset sale, your personal information may be part of the transferred assets.</p>
              <h3>V. Information Security</h3>
              <p>We take various security measures to protect your personal information from unauthorized access and use. However, please note that no security measures can provide absolute security.</p>
              <h3>VI. Your Rights</h3>
              <p>You have the right to request access to, correction of, or deletion of your personal information. You also have the right to object to our processing of your information. If you wish to exercise these rights, please contact us.</p>
              <h3>VII. Changes to the Privacy Policy</h3>
              <p>We may update this privacy policy from time to time. If we make any significant changes, we will notify you through our website or by other means. We encourage you to review this policy regularly to stay informed about how we protect your information.</p>
              <h3>VIII. Contact Us</h3>
              <p>If you have any questions or concerns about this privacy policy, please contact us through the following means:</p>
              <ul>
                <li>Email: compliance@wonder.app</li>
                <li>Phone: 4600 4630</li>
                <li>Address: Suite 05-181, 5/F The Quayside, 77 Hoi Bun Road, Kwun Tong, Kowloon, Hong Kong</li>
              </ul>
              <p>Thank you for reading and understanding our privacy policy. We look forward to providing you with safe and reliable ride-hailing services!</p>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-CN' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title} text-center`}>隐私政策</div>
            <div className={`${styles.content} break-all`}>
            <h3>一、总则</h3>
              <p>
                欢迎您使用我们的叫车服务！为了更好地提供服务并保护您的个人隐私，我们制定了以下隐私政策。本政策涵盖了我们如何收集、使用、披露、传输和存储您的信息。请在使用我们的服务前详细阅读本隐私政策。
              </p>
              <h3>二、信息收集</h3>
              <p>2.1 您提供的信息</p>
              <ul>
                <li>注册信息： 您在注册过程中提供的姓名、电话号码、电子邮件地址等。</li>
                <li>支付信息： 您为支付服务提供的银行卡信息、信用卡信息等。</li>
              </ul>
              <p>2.2 我们自动收集的信息</p>
              <ul>
                <li>位置信息： 当您使用我们的服务时，我们会收集您的实时地理位置信息，以便为您提供距离最近的车辆服务。</li>
                <li>设备信息： 我们会收集您使用的设备类型、操作系统版本、设备标识码等信息。</li>
              </ul>
              <p>2.3 第三方来源的信息 我们可能会从其他合法来源获取您的一些信息，包括商业合作伙伴和社交媒体平台。</p>
              <h3>三、信息使用</h3>
              <p>我们收集的信息主要用于以下目的：</p>
              <ul>
                <li>提供、维护和改善我们的服务。</li>
                <li>处理交易和发送相关信息，包括预订确认和更新。</li>
                <li>通过电子邮件、短信等方式与您联系，以发送服务更新或其他宣传信息。</li>
                <li>进行内部审计、数据分析和研究，以改善我们的产品和服务。</li>
              </ul>
              <h3>四、信息分享和披露</h3>
              <p>4.1 合作伙伴 我们可能会与技术服务提供商、支付服务提供商及其他商业伙伴分享您的信息，以支持我们的业务运作。</p>
              <p>4.2 法律要求 在法律要求、政府要求或司法程序的情况下，我们可能会披露您的信息。</p>
              <p>4.3 资产转让 如我们涉及合并、收购或资产销售，您的个人信息可能作为转让资产的一部分。</p>
              <h3>五、信息安全</h3>
              <p>我们采取各种安全措施来保护您的个人信息免受未经授权的访问和使用。然而，请注意，没有任何安全措施能够提供绝对的安全保障。</p>
              <h3>六、您的权利</h3>
              <p>您有权要求访问、更正或删除您的个人信息。您也有权对我们处理您的信息提出异议。若您希望行使这些权利，请联系我们。</p>
              <h3>七、隐私政策的变更</h3>
              <p>我们可能会不时更新本隐私政策。若我们进行任何重大更改，我们将通过我们的网站或通过其他方式通知您。我们鼓励您定期查看本政策，以保持对我们如何保护您信息的了解。</p>
              <h3>八、联系我们</h3>
              <p>如果您对本隐私政策有任何疑问或担忧，请通过以下方式联系我们：</p>
              <ul>
                <li>邮箱： compliance@wonder.app</li>
                <li>电话： 4600 4630</li>
                <li>地址： 香港观塘海滨道77号港滨汇5楼05-181室</li>
              </ul>
              <p>感谢您对我们隐私政策的阅读和理解，我们期待为您提供安全、可靠的叫车服务！</p>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-HK' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title} text-center`}>隱私政策</div>
            <div className={`${styles.content} break-all`}>
            <h3>一、總則</h3>
              <p>
                歡迎您使用我們的叫車服務！為了更好地提供服務並保護您的個人隱私，我們制定了以下隱私政策。本政策涵蓋了我們如何收集、使用、披露、傳輸和存儲您的信息。請在使用我們的服務前詳細閱讀本隱私政策。
              </p>
              <h3>二、信息收集</h3>
              <p>2.1 您提供的信息</p>
              <ul>
                <li>註冊信息： 您在註冊過程中提供的姓名、電話號碼、電子郵件地址等。</li>
                <li>支付信息： 您為支付服務提供的銀行卡信息、信用卡信息等。</li>
              </ul>
              <p>2.2 我們自動收集的信息</p>
              <ul>
                <li>位置信息： 當您使用我們的服務時，我們會收集您的實時地理位置信息，以便為您提供距離最近的車輛服務。</li>
                <li>設備信息： 我們會收集您使用的設備類型、操作系統版本、設備標識碼等信息。</li>
              </ul>
              <p>2.3 第三方來源的信息 我們可能會從其他合法來源獲取您的一些信息，包括商業合作夥伴和社交媒體平臺。</p>
              <h3>三、信息使用</h3>
              <p>我們收集的信息主要用於以下目的：</p>
              <ul>
                <li>提供、維護和改善我們的服務。</li>
                <li>處理交易和發送相關信息，包括預訂確認和更新。</li>
                <li>通過電子郵件、短信等方式與您聯繫，以發送服務更新或其他宣傳信息。</li>
                <li>進行內部審計、數據分析和研究，以改善我們的產品和服務。</li>
              </ul>
              <h3>四、信息分享和披露</h3>
              <p>4.1 合作夥伴 我們可能會與技術服務提供商、支付服務提供商及其他商業夥伴分享您的信息，以支持我們的業務運作。</p>
              <p>4.2 法律要求 在法律要求、政府要求或司法程序的情況下，我們可能會披露您的信息。</p>
              <p>4.3 資產轉讓 如我們涉及合併、收購或資產銷售，您的個人信息可能作為轉讓資產的一部分。</p>
              <h3>五、信息安全</h3>
              <p>我們採取各種安全措施來保護您的個人信息免受未經授權的訪問和使用。然而，請注意，沒有任何安全措施能夠提供絕對的安全保障。</p>
              <h3>六、您的權利</h3>
              <p>您有權要求訪問、更正或刪除您的個人信息。您也有權對我們處理您的信息提出異議。若您希望行使這些權利，請聯繫我們。</p>
              <h3>七、隱私政策的變更</h3>
              <p>我們可能會不時更新本隱私政策。若我們進行任何重大更改，我們將通過我們的網站或通過其他方式通知您。我們鼓勵您定期查看本政策，以保持對我們如何保護您信息的了解。</p>
              <h3>八、聯繫我們</h3>
              <p>如果您對本隱私政策有任何疑問或擔憂，請通過以下方式聯繫我們：</p>
              <ul>
                <li>郵箱： compliance@wonder.app</li>
                <li>電話： 4600 4630</li>
                <li>地址： 香港觀塘海濱道77號港濱匯5樓05-181室</li>
              </ul>
              <p>感謝您對我們隱私政策的閱讀和理解，我們期待為您提供安全、可靠的叫車服務！</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivacyPolicy;
