enum PayTypes {
  APPLE_PAY = 'APPLE_PAY',
  ALIPAYCN = 'ALIPAYCN',
  ALIPAYHK = 'ALIPAYHK',
  TAP_GO = 'TAP_GO',
  WECHAT_PAY = 'WECHAT_PAY',
  WECHAT_OFFICIAL_ACCOUNT_PAY = 'WECHAT_OFFICIAL_ACCOUNT_PAY',
  CREDIT_CARD = 'CREDIT_CARD',
  FPS = 'FPS',
  OCTOPUS = 'OCTOPUS',
  UNIONPAY_WALLET = 'UNIONPAY_WALLET',
}

export default PayTypes;
