import React from 'react';
import type { IIconProps } from '@/types/common-types';

const VectorIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.81202 25.046L11.616 18.242L17.272 23.9L23.586 17.586L20 14H30V24L26.414 20.414L17.272 29.556L11.616 23.9L6.66602 28.848C8.91284 32.2297 12.3613 34.6308 16.3125 35.5647C20.2637 36.4986 24.4222 35.8954 27.9452 33.8774C31.4683 31.8594 34.0924 28.5775 35.2859 24.6968C36.4793 20.8161 36.1528 16.6268 34.3725 12.9779C32.5922 9.32893 29.4913 6.49326 25.6981 5.04549C21.9049 3.59772 17.7032 3.64614 13.9444 5.18094C10.1856 6.71573 7.15082 9.62211 5.45508 13.3111C3.75934 17.0001 3.52946 21.1958 4.81202 25.048V25.046ZM1.73602 28.162L1.71602 28.142L1.72402 28.134C0.583874 25.574 -0.0035754 22.8024 1.63715e-05 20C1.63715e-05 8.954 8.95402 0 20 0C31.046 0 40 8.954 40 20C40 31.046 31.046 40 20 40C11.86 40 4.86002 35.14 1.73602 28.162Z"
          fill="#0094FF"
        />
      </svg>
    </div>
  );
};

export default VectorIcon;
