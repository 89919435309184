import { minus, plus } from '@/utils/galaxy-utils';
import type { ISplash } from '@/utils/platform-utils';
import React, { useState, useEffect } from 'react';
import { createView } from './splash-view';

interface ISplashProps {
  data: ISplash[];
  logo: string;
  direction?: 'left' | 'right';
  className?: string;
  width?: string;
}

let splashTimout: any;

const Splash: React.FC<ISplashProps> = (props) => {
  const {
    data,
    logo,
    className = '',
    direction = 'right',
    width = '472',
  } = props;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [defaultWidth, setDefaultWidth] = useState(0);

  const method = direction === 'left' ? plus : minus;
  const translatePX = selectedIndex * defaultWidth;
  const symbol = direction === 'left' ? '-' : '';

  useEffect(() => {
    if (splashTimout) {
      clearTimeout(splashTimout);
      splashTimout = null;
    }

    if (width.indexOf('%') > -1) {
      const percentage = width.split('%')[0];
      const num = Math.round(
        (document.body.clientWidth / 100) * Number(percentage)
      );
      window.addEventListener('resize', handleScroll);
      setDefaultWidth(num);
    } else {
      setDefaultWidth(Number(width));
    }
    splashTimout = setTimeout(() => {
      splashTimout = null;
      if (Math.abs(selectedIndex) === data.length - 1) {
        setSelectedIndex(0);
      } else {
        setSelectedIndex(method(selectedIndex, 1));
      }
    }, 3000);
    return () => window.removeEventListener('resize', handleScroll);
    // eslint-disable-next-line
  }, [selectedIndex]);

  const handleScroll = () => {
    const percentage = width.split('%')[0];
    const num = Math.round(
      (document.body.clientWidth / 100) * Number(percentage)
    );
    setDefaultWidth(num);
  };

  const handleIndexChange = (index: number) => {
    if (index !== 0) {
      setSelectedIndex(-index);
    } else {
      setSelectedIndex(index);
    }
  };

  const handleImageChange = (index: number, xCoordinate: number) => {
    if (xCoordinate > defaultWidth / 2) {
      //鼠标位置靠右
      if (index === -3) {
        setSelectedIndex(0);
      } else {
        setSelectedIndex(index - 1);
      }
    } else if (xCoordinate < defaultWidth / 2) {
      //鼠标位置靠左
      if (index === 0) {
        setSelectedIndex(-3);
      } else {
        setSelectedIndex(index + 1);
      }
    }
  };

  return (
    <div className={`${className} h-full w-full`}>
      <div className="relative block overflow-hidden m-0 p-0 h-full w-full transform-gpu box-border">
        <ul
          className="transform-gpu relative flex m-0 p-0 h-full box-border duration-300"
          style={{
            transform: `translate3d(${symbol}${Math.round(
              translatePX
            )}px, 0px, 0px)`,
            width: `${defaultWidth * data.length}px`,
          }}
        >
          {data.map((item, index) => (
            <li
              key={item.id}
              className="h-full flex"
              style={{
                left: `${index * defaultWidth}px`,
                width: `${defaultWidth}px`,
              }}
            >
              {createView({
                data: item,
                logo,
                selectedIndex,
                splashData: data,
                handleIndexChange: handleIndexChange,
                handleImageChange: handleImageChange,
              })}
            </li>
          ))}
        </ul>
        {/* <div className="absolute top-28 flex justify-center w-full">
          <div dangerouslySetInnerHTML={{ __html: logo }} />
        </div> */}
        {/* <div className="absolute top-60 left-0 w-full flex justify-center">
          {data.length > 1 &&
            data.map((item, index) => (
              <span
                className={`w-2 h-2 bg-primary-color rounded-full mr-2 ${
                  Math.abs(selectedIndex) === index
                    ? 'w-8 rounded-lg opacity-100'
                    : 'opacity-50'
                }`}
                key={item.id}
              />
            ))}
        </div> */}
      </div>
    </div>
  );
};

export default Splash;
