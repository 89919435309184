import React from 'react';
import type { IIconProps } from '@/types/common-types';

const SystemAdjustmentIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 32 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="22.5" cy="22.5" r="22.5" fill="#3168C2" />
        <circle
          cx="21.9079"
          cy="21.9079"
          r="10.8765"
          stroke="white"
          strokeWidth="1.4924"
        />
        <path
          d="M20.7581 22.2732L22.8624 22.2732C23.4448 22.2732 23.9187 22.7172 23.9187 23.263C23.9187 23.8106 23.4456 24.2528 22.8626 24.2528C22.8579 24.2528 22.8532 24.2527 22.8485 24.2527L20.8326 24.2527C20.3367 24.2483 19.8574 23.9945 19.5819 23.5908L19.5713 23.5752C19.3237 23.2114 18.8084 23.1044 18.4202 23.3363C18.032 23.5682 17.9179 24.0512 18.1653 24.415L18.1767 24.4317C18.7579 25.2831 19.7061 25.8151 20.9766 25.8151L20.9766 26.752C20.9766 27.1835 21.3499 27.5332 21.8102 27.5332C22.2706 27.5332 22.6438 27.1834 22.6438 26.752L22.6438 25.8151C23.4405 25.8151 24.1302 25.6797 24.7811 25.0743C25.3001 24.5915 25.5859 23.9482 25.5859 23.263C25.5859 21.8557 24.3641 20.7109 22.8624 20.7109L20.7581 20.7109C20.1757 20.7109 19.7019 20.2669 19.7019 19.7212C19.7019 19.1947 20.1469 18.7314 20.772 18.7314L22.9716 18.7314C23.4048 18.7352 23.8203 18.9253 24.1118 19.2533C24.4066 19.5848 24.9322 19.6296 25.2859 19.3535C25.6396 19.0773 25.6875 18.5847 25.3928 18.2533C24.6127 17.3757 23.7047 17.1691 22.6439 17.1691L22.6439 16.2321C22.6439 15.8007 22.2706 15.451 21.8103 15.451C21.35 15.451 20.9767 15.8008 20.9767 16.2321L20.9767 17.1691C19.0351 17.1691 18.0347 18.4508 18.0347 19.7212C18.0346 21.1284 19.2563 22.2732 20.7581 22.2732V22.2732Z"
          fill="white"
        />
        <circle cx="30.8577" cy="28.928" r="5.14286" fill="white" />
        <path
          d="M30.0229 27.3246C30.0945 27.2531 30.1915 27.2129 30.2927 27.2129H33.5988C33.7 27.2129 33.797 27.2531 33.8685 27.3246C33.9401 27.3962 33.9803 27.4932 33.9803 27.5944C33.9803 27.6955 33.9401 27.7926 33.8685 27.8641C33.797 27.9357 33.7 27.9758 33.5988 27.9758H30.2927C30.1915 27.9758 30.0945 27.9357 30.0229 27.8641C29.9514 27.7926 29.9112 27.6955 29.9112 27.5944C29.9112 27.4932 29.9514 27.3962 30.0229 27.3246Z"
          fill="#3168C2"
        />
        <path
          d="M26.9711 28.7234C27.0426 28.6518 27.1397 28.6116 27.2409 28.6116H30.547C30.6481 28.6116 30.7452 28.6518 30.8167 28.7234C30.8883 28.7949 30.9284 28.8919 30.9284 28.9931C30.9284 29.0943 30.8883 29.1913 30.8167 29.2629C30.7452 29.3344 30.6481 29.3746 30.547 29.3746H27.2409C27.1397 29.3746 27.0426 29.3344 26.9711 29.2629C26.8996 29.1913 26.8594 29.0943 26.8594 28.9931C26.8594 28.8919 26.8996 28.7949 26.9711 28.7234Z"
          fill="#3168C2"
        />
        <path
          d="M30.0229 30.1221C30.0945 30.0506 30.1915 30.0104 30.2927 30.0104H33.5988C33.7 30.0104 33.797 30.0506 33.8685 30.1221C33.9401 30.1937 33.9803 30.2907 33.9803 30.3919C33.9803 30.493 33.9401 30.5901 33.8685 30.6616C33.797 30.7331 33.7 30.7733 33.5988 30.7733H30.2927C30.1915 30.7733 30.0945 30.7331 30.0229 30.6616C29.9514 30.5901 29.9112 30.493 29.9112 30.3919C29.9112 30.2907 29.9514 30.1937 30.0229 30.1221Z"
          fill="#3168C2"
        />
      </svg>
    </div>
  );
};

export default SystemAdjustmentIcon;
