// import type { IPayee } from '@/types/payouts-type';
import { parsePayees } from '@/utils/payouts-utils';
import Config from '@/libs/config';
import { IObject } from '@/types/common-types';
import api from '@/libs/api';
import axios from 'axios';

export const getPayeeList = async (
  businessID: string,
  lang: string,
  token: string,
  pageSize: number,
  offset: number
) => {
  let records: any[] = [];

  try {
    const resp = await api.get(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/payees?limit=${pageSize}&offset=${offset}&related_company_payee=true`,
      {
        headers: {
          'x-user-access-token': token,
          'x-client-id': Config.clientID,
          'x-platform-id': Config.platformId,
          'x-p-business-id': businessID,
          'x-i18n-lang': lang,
        },
      }
    );
    const { data } = resp || {};
    if (data && Array.isArray(data)) {
      records = parsePayees(data);
    }
  } catch (error) {
    console.error(error);
  }
  return records;
};

export const getPayeeDetail = async (
  businessID: string,
  lang: string,
  id: string,
  token: string
) => {
  return await api.get(
    `${Config.urls.gatewayUrl}/api/treasury-services/api/payees/${id}`,
    {
      headers: {
        'x-user-access-token': token,
        'x-client-id': Config.clientID,
        'x-platform-id': Config.platformId,
        'x-p-business-id': businessID,
        'x-i18n-lang': lang,
      },
    }
  );
};

export const getTransferMethodDetail = async (
  businessID: string,
  lang: string,
  token: string,
  transferMethod?: string
) => {
  let transfer = transferMethod;
  if (transferMethod == null) {
    transfer = 'HK FPS - Account Number';
  }

  try {
    const result = await api.get(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/transfer_methods/${transfer}`,
      {
        headers: {
          'x-user-access-token': token,
          'x-client-id': Config.clientID,
          'x-platform-id': Config.platformId,
          'x-p-business-id': businessID,
          'x-i18n-lang': lang,
        },
      }
    );
    const { data = {} } = result || {};

    if (data.transfer_method != null) {
      return data;
    }
  } catch (error) {
    throw error;
  }
  return {};
};

export const payeeUpdate = async (
  businessID: string,
  lang: string,
  token: string,
  id: string,
  body: IObject
) => {
  return await axios({
    method: 'put',
    url: `${Config.urls.gatewayUrl}/api/treasury-services/api/payees/${id}`,
    data: body,
    headers: {
      'x-user-access-token': token,
      'x-client-id': Config.clientID,
      'x-platform-id': Config.platformId,
      'x-p-business-id': businessID,
      'x-i18n-lang': lang,
    },
  })
};
export const payeeCreate = async (
  businessID: string,
  lang: string,
  token: string,
  body: IObject
) => {
  try {
    const resp: IObject = await api.post(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/payees`,
      { ...body },
      {
        headers: {
          'x-user-access-token': token,
          'x-client-id': Config.clientID,
          'x-platform-id': Config.platformId,
          'x-p-business-id': businessID,
          'x-i18n-lang': lang,
        },
      }
    );
    const { code, data = {} } = resp || {};
    if (code === 200 && data['payee'] !== null) {
      const { payee } = data || {};
      return payee.id;
    }
  } catch (error) {
    console.error(error);
  }
};

export const payeeRemove = async (
  businessID: string,
  lang: string,
  token: string,
  id: string
) => {
  let result = {
    success: false,
    message: '',
  };
  try {
    const resp: IObject = await api.remove(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/payees/${id}`,
      {
        headers: {
          'x-user-access-token': token,
          'x-client-id': Config.clientID,
          'x-platform-id': Config.platformId,
          'x-p-business-id': businessID,
          'x-i18n-lang': lang,
        },
      }
    );
    const { code, data } = resp;
    if (code === 200 && data !== null) {
      result = { success: true, message: resp['message'] };
    } else {
      result = { success: false, message: resp['message'] };
    }
  } catch (error) {
    console.error(error);
  }
  return result;
};

export const getBankList = async (
  businessID: string,
  lang: string,
  token: string
) => {
  const records: any[] = [];
  const url = `${Config.urls.gatewayUrl}/api/treasury-services/api/banks?offset=0&limit=1000000`;
  try {
    const resp = await api.get(url, {
      headers: {
        'x-user-access-token': token,
        'x-p-business-id': businessID,
        'x-i18n-lang': lang,
        'x-client-id': Config.clientID,
      },
    });
    const { data } = resp || {};
    if (data && Array.isArray(data)) {
      if (Array.isArray(data)) {
        data.forEach((item) => {
          records.push({
            id: item.id,
            bank_code: `${item.bank_code} ${item.bank_name}`,
          });
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
  return records;
};
