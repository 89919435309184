import { useEffect, useMemo, useState } from 'react';
import SelectedIcon from '../../payouts-icons/selected-icon';
import TipsIcon from '@/components/svg/tips-icon';
import TriangleIcon from '../../payouts-icons/triangle-icon';
import dayjs from 'dayjs';
import RateUpdateCountdownModal from '../rate-update-modal';
import { IPayee, IPayout, IRetrieveBalance } from '@/types/payouts-type';
import { useTranslation } from 'react-i18next';
import Config from '@/libs/config';
import { useParams } from 'react-router-dom';
import { getCountryByCurrency, handleMount } from '@/utils/payouts-utils';
import { getCookie } from '@/utils';
import { Constants } from '@/constants';
import api from '@/libs/api';
import { getRetrieveBalances } from '@/data/payouts';
import Skeleton from '@/components/skeleton';
import Button from '@/components/common/button';
import AntdModal from '@/components/common/antd/modal';

interface IPayoutsPayoutAmountProps {
  businessID: string;
  payee: IPayee;
  retrieveBalances: IRetrieveBalance[];
  payout: IPayout;
  setRetrieveBalances: (retrieveBalances: IRetrieveBalance[]) => void;
  setPayout: (payout: IPayout) => void;
  setStepCount: (stepCount: number) => void;
}

interface IFxQuote {
  sell_amount: string;
  buy_amount: string;
  fee_amount: string;
  convert_amount: string;
  fx_quote_id: string;
  exchange_rate: string;
  error_msg: string;
}

let timer: any = null;
let cycleTimer: any = null;

const PayoutsPayoutAmount: React.FC<IPayoutsPayoutAmountProps> = (props) => {
  const {
    payee,
    retrieveBalances,
    setStepCount,
    businessID,
    payout,
    setPayout,
    setRetrieveBalances,
  } = props;
  const { t } = useTranslation('payouts');
  const { t: ct } = useTranslation('common');
  const { lang = 'en' } = useParams();
  const { payout_accounts } = payee;
  const { currency: payoutAccountCurrency, transfer_method } = payout_accounts;
  const prefix = Config.staticPath || '';

  const getDefaultCurrency = () => {
    const { fx_sell_currency } = payout;
    let retrieveBalance = retrieveBalances[0];
    const defaultCurrency = fx_sell_currency || payoutAccountCurrency;
    retrieveBalances.forEach((item) => {
      if (item.currency.currency_code === defaultCurrency) {
        retrieveBalance = item;
      }
    });

    return retrieveBalance.currency.currency_code;
  };
  const [upAmount, setUpAmount] = useState('');
  const [downAmount, setDownAmount] = useState('');
  const [rate, setRate] = useState('0.00');
  const [chargedFee, setChargedFee] = useState(0);
  const [convertAmount, setConvertAmount] = useState(0);
  const [sellMsg, setSellMsg] = useState('');
  const [buyMsg, setBuyMsg] = useState('');
  const [type, setType] = useState<'Buy' | 'Sell'>('Buy');
  const [fxQuote, setFxQuote] = useState<IFxQuote | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectCurrencyCode, setSelectCurrencyCode] = useState<string>(
    getDefaultCurrency()
  );
  const [showModal, setShowModal] = useState(false);
  const [showRateModal, setRateShowModal] = useState(false);
  const [time, setTime] = useState(30);

  const selectCurrency: IRetrieveBalance = useMemo(() => {
    let retrieveBalance = retrieveBalances[0];
    for (let i = 0; i < retrieveBalances.length; i++) {
      const item = retrieveBalances[i];
      if (item.currency.currency_code === selectCurrencyCode) {
        retrieveBalance = item;
        break;
      }
    }
    return retrieveBalance;
  }, [selectCurrencyCode, retrieveBalances]);

  const { available, currency } = selectCurrency || {};
  const { currency_code } = currency || {};

  const isFPS =
    transfer_method.indexOf('FPS') !== -1 ||
    transfer_method === 'Platform Transfer';
  useEffect(() => {
    if (payout.destination_amount) {
      setDownAmount(handleMount(payout.destination_amount));
      setLoading(true);
      handleFxQuote('Buy', Number(payout.destination_amount));
    }
    // eslint-disable-next-line
  }, []);

  const handleError = () => {
    const total = Number(upAmount.replace(/[^\d.]/g, ''));
    const balance = Number(available);
    if (!isNaN(total) && !isNaN(balance) && total > available) {
      return true;
    }
  };

  useEffect(() => {
    const total = downAmount.replace(/[^\d.]/g, '');
    const amount = Number(total || 0);
    if (amount > 0) {
      setLoading(true);
      handleFxQuote('Buy', amount);
    }
    // eslint-disable-next-line
  }, [selectCurrencyCode]);

  const timerStart = (amount: number) => {
    if (currency_code !== payoutAccountCurrency) {
      const now = dayjs();
      cycleTimer = setInterval(() => handleFxQuoteAgain(now, amount), 1000);
    }
  };

  const handleFxQuoteAgain = (startTime: dayjs.Dayjs, amount: number) => {
    const intervalTime = Math.floor(dayjs().diff(startTime) / 1000);
    const newTime = 30 - intervalTime;
    setTime(newTime);
    if (newTime < 0) {
      setLoading(true);
      handleFxQuote('Buy', amount);
    }
  };

  const handleFxQuote = async (type: 'Buy' | 'Sell', amount: number) => {
    clearInterval(cycleTimer);
    setTime(30);
    const token = getCookie(Constants.TOKEN) || '';
    const resp: any = await api.post(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/payout_requests/fx_quote`,
      {
        buy_amount: type === 'Buy' ? amount : 0,
        buy_currency: payoutAccountCurrency,
        dealt_side: type,
        sell_amount: type === 'Sell' ? amount : 0,
        payee_id: payee.id,
        sell_currency: currency_code,
      },
      {
        headers: {
          'x-p-business-id': businessID,
          'x-client-id': Config.clientID,
          'x-platform-id': Config.platformId,
          'X-USER-ACCESS-TOKEN': token,
          'x-i18n-lang': lang,
          'Content-Type': 'application/json',
        },
      }
    );
    const balances = await getRetrieveBalances(businessID, token);
    if (Array.isArray(balances) && balances.length > 0) {
      setRetrieveBalances(balances);
    }
    const { data, message, code } = resp || {};
    const {
      sell_amount,
      buy_amount,
      fee_amount,
      convert_amount,
      error_code,
      sell_error_msg,
      buy_error_msg,
      exchange_rate,
    } = data || {};
    setLoading(false);
    if (fee_amount) {
      setChargedFee(fee_amount);
    }
    setConvertAmount(convert_amount || 0);
    setFxQuote(data);
    setRate(exchange_rate);
    if (error_code) {
      setSellMsg(sell_error_msg);
      setBuyMsg(buy_error_msg);
      if (type === 'Buy') {
        setUpAmount(handleMount(sell_amount));
      } else {
        setDownAmount(handleMount(buy_amount));
      }
      return;
    }
    if (message && code !== 200) {
      setSellMsg(message);
      return;
    }
    setSellMsg('');
    setBuyMsg('');
    if (type === 'Buy') {
      setUpAmount(handleMount(sell_amount));
    } else {
      setDownAmount(handleMount(buy_amount));
    }
    timerStart(buy_amount);
  };

  const handleChange = (value: string) => {
    let amount = value;
    let points = 0;
    if (value === '.') {
      return {
        amount: '0.',
        points,
      };
    }
    if (value === '') {
      return {
        amount: '',
        points,
      };
    }
    const index = value.indexOf('.');
    if (
      index !== -1 &&
      value.length - 1 !== index &&
      value[value.length - 1] === '.'
    ) {
      amount = value.substring(0, value.length - 1);
    }
    const values = amount.split('.');
    if (values.length > 1) {
      amount = `${values[0]}.${values[1].slice(0, 2)}`;
      if (values[1].length < 3) {
        points = values[1].length;
      } else {
        points = 2;
      }
    }

    return {
      amount,
      points,
    };
  };

  const handleInputChange = (inputValue: string, type: 'Buy' | 'Sell') => {
    setBuyMsg('');
    setSellMsg('');
    setType(type);
    setLoading(true);
    clearInterval(cycleTimer);
    setTime(30);
    const { amount, points } = handleChange(inputValue);
    let value = amount;
    let total = amount.replace(/[^\d.]/g, '');
    const lastLetter = total[total.length - 1];
    if (lastLetter === '.') {
      total = total.replace('.', '');
    }
    if (!total) {
      value = '';
    }
    if (type === 'Buy') {
      setDownAmount(
        total && lastLetter !== '.' ? handleMount(total, points) : value
      );
      setUpAmount('');
    } else {
      setUpAmount(
        total && lastLetter !== '.' ? handleMount(total, points) : value
      );
      setDownAmount('');
    }
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      const sum = Number(total || 0);
      if (sum > 0) {
        handleFxQuote(type, sum);
      } else {
        setLoading(false);
        setConvertAmount(0);
        setChargedFee(0);
        setFxQuote(null);
      }
      clearTimeout(timer);
      timer = null;
    }, 700);
  };

  const handleNext = () => {
    if (fxQuote) {
      clearInterval(cycleTimer);
      setPayout({
        ...payout,
        fx_buy_currency: payoutAccountCurrency,
        fx_quote_id: fxQuote.fx_quote_id,
        fx_sell_currency: currency_code,
        fx_exchange_rate: fxQuote.exchange_rate,
        total_amount: fxQuote.sell_amount,
        calculated_fee: fxQuote.fee_amount,
        destination_amount: fxQuote.buy_amount,
        destination_currency: payoutAccountCurrency,
      });
      setStepCount(3);
    }
  };

  const handleCurrencyChange = (currency: IRetrieveBalance) => {
    setShowModal(false);
    setSelectCurrencyCode(currency.currency.currency_code);
    setSellMsg('');
  };

  return (
    <>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        <div className="flex justify-between">
          <div>
            <div className="font-semibold text-xl text-color-text-1">
              {t('payout_amount')}
            </div>
            <div className="pt-0.5 text-color-text-2 text-sm">
              {t('enter_payout_amount')}
            </div>
          </div>
          {selectCurrency.currency.currency_code !== payoutAccountCurrency && (
            <div
              className="bg-primary-color/10 rounded h-10 flex justify-center items-center pl-2.5 pr-3 text-primary-color cursor-pointer"
              onClick={() => setRateShowModal(true)}
            >
              <div className="font-medium mr-1">
                {time}
                {t('s')}
              </div>
              <TipsIcon size={16} />
            </div>
          )}
        </div>
        <div className="mt-4 mb-6 border-solid border-0 border-b border-border-color" />
        <div
          className={`${
            sellMsg ? 'border-error-color' : 'border-primary-color'
          } border-2 border-solid shadow-box-drop rounded-2xl px-6 py-3.5`}
        >
          <div className="flex items-center">
            <div className="flex-1">
              <div className="text-color-text-1 font-medium mb-1">
                {t('you_input')}
              </div>
              {loading && type === 'Buy' ? (
                <div className={`overflow-hidden inline-block w-60`}>
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-4 rounded-2xl mb-0`}
                  />
                </div>
              ) : (
                <input
                  className={`focus:outline-none font-bold ${
                    sellMsg ? 'text-error-color' : 'text-color-text-1'
                  } text-3xl bg-inherit placeholder:text-color-text-5 placeholder:font-bold placeholder:text-3xl caret-primary-color`}
                  type="text"
                  value={upAmount}
                  inputMode="decimal"
                  placeholder={'0.00'}
                  maxLength={14}
                  onChange={(eve) => {
                    handleInputChange(eve.target.value, 'Sell');
                  }}
                />
              )}
            </div>
            <div
              className={`bg-primary-color rounded-lg w-[120px] h-12 flex items-center justify-center ${
                isFPS ? '' : 'cursor-pointer'
              }`}
              onClick={() => !isFPS && setShowModal(true)}
            >
              <img
                width="24px"
                height="24px"
                src={`${prefix}/images/country-flag/${getCountryByCurrency(
                  currency_code
                )}.svg`}
                alt=""
              />
              <div className="text-white font-bold text-xl mx-1.5">
                {currency_code}
              </div>
              {!isFPS && <TriangleIcon className="text-white" />}
            </div>
          </div>
          <div className="text-sm text-error-color">{sellMsg}</div>
        </div>
        <div className="py-8 ml-6 border-0 border-l-2 border-dashed border-primary-color pl-4">
          <div
            className={`text-color-text-1 ${
              handleError() ? 'text-error-color' : ''
            }`}
          >
            {t('available_balance')}: {handleMount(available)}{' '}
            {currency_code}
          </div>
          <div className="flex items-center justify-between pt-2 font-medium text-color-text-2">
            {loading && (
              <div className={`overflow-hidden inline-block w-60`}>
                <Skeleton
                  row={1}
                  width={['100%']}
                  skeletonItemClass={`h-8 rounded-2xl mb-0`}
                />
              </div>
            )}
            {!loading && (
              <div className="py-1 px-3 bg-primary-color bg-opacity-10 rounded-lg">
                {handleMount(chargedFee, 2, true)} {currency_code}
              </div>
            )}
            <div>{t('charged_fee')}</div>
          </div>
          <div className="border-0 border-b border-solid border-primary-color border-opacity-10 mt-2"></div>
          <div className="flex items-center justify-between pt-2 font-medium text-color-text-2">
            {loading && (
              <div className={`overflow-hidden inline-block w-60`}>
                <Skeleton
                  row={1}
                  width={['100%']}
                  skeletonItemClass={`h-8 rounded-2xl mb-0`}
                />
              </div>
            )}
            {!loading && (
              <div className="py-1 px-3 bg-primary-color bg-opacity-10 rounded-lg">
                ={handleMount(convertAmount)} {currency_code}
              </div>
            )}
            {payoutAccountCurrency === currency_code && (
              <div>{t('total_amount')}</div>
            )}
            {payoutAccountCurrency !== currency_code && (
              <div>{t('total_convert')}</div>
            )}
          </div>
          {payoutAccountCurrency !== currency_code && (
            <div className="flex items-center justify-between pt-2 font-medium text-color-text-2">
              {loading && (
                <div className={`overflow-hidden inline-block w-60`}>
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-8 rounded-2xl mb-0`}
                  />
                </div>
              )}
              {!loading && (
                <div className="py-1 px-3 bg-primary-color bg-opacity-10 rounded-lg">
                  x{rate}
                </div>
              )}
              <div>{t('exchange_rate')}</div>
            </div>
          )}
        </div>
        <div
          className={`border-2 border-solid shadow-box-drop rounded-2xl px-6 py-3.5 ${
            buyMsg ? 'border-error-color' : 'border-primary-color'
          }`}
        >
          <div className="flex items-center">
            <div className="flex-1">
              <div className="text-color-text-1 font-medium mb-1">
                {t('recipient_gets')}
              </div>
              {loading && type === 'Sell' ? (
                <div className={`overflow-hidden inline-block w-60`}>
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-8 rounded-2xl mb-0`}
                  />
                </div>
              ) : (
                <input
                  className="focus:outline-none font-bold text-color-text-1 text-3xl bg-inherit placeholder:text-color-text-5 placeholder:font-bold placeholder:text-3xl caret-primary-color"
                  type="text"
                  value={downAmount}
                  inputMode="decimal"
                  placeholder={'0.00'}
                  maxLength={14}
                  onChange={(eve) => {
                    handleInputChange(eve.target.value, 'Buy');
                  }}
                />
              )}
            </div>
            <div className="bg-primary-color rounded-lg w-[120px] h-12 flex items-center justify-center">
              <img
                width="24px"
                height="24px"
                src={`${prefix}/images/country-flag/${getCountryByCurrency(
                  payoutAccountCurrency
                )}.svg`}
                alt=""
              />
              <div className="text-white font-bold text-xl mx-1.5">
                {payoutAccountCurrency}
              </div>
            </div>
          </div>
          <div className="text-sm text-error-color">{buyMsg}</div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <Button
          className="flex-1 mr-6"
          onClick={() => {
            clearInterval(cycleTimer);
            setStepCount(1);
          }}
          styleType="Secondary"
        >
          {ct('back')}
        </Button>
        <Button
          className="flex-1"
          onClick={handleNext}
          styleType="Primary"
          disabled={
            loading || sellMsg !== '' || buyMsg !== '' || convertAmount === 0
          }
        >
          {ct('next')}
        </Button>
      </div>
      <AntdModal
        open={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
      >
        <div className="p-4 pt-8 max-h-[88vh] flex flex-col">
          <div className="font-bold text-xl text-color-text-1 text-center">
            {t('active_accounts')}
          </div>
          <div
            className={`mt-6 text-base leading-snug grid grid-cols-1 divide-x-0 divide-y divide-border-color divide-solid overflow-y-auto justify-start hidden_scrollbar`}
          >
            {retrieveBalances.map((item) => {
              return (
                <div
                  key={item.id}
                  className="flex py-3 cursor-pointer hover:bg-disabled-color hover:rounded"
                  onClick={() => handleCurrencyChange(item)}
                >
                  <div>
                    <img
                      width="40px"
                      height="40px"
                      src={`${prefix}/images/country-flag/${getCountryByCurrency(
                        item.currency.currency_code
                      )}.svg`}
                      alt=""
                    />
                  </div>
                  <div className="flex-1 ml-4">
                    <div className="text-color-text-2 font-medium text-sm">
                      {t('current_account')} -{' '}
                      {item.currency.currency_code}
                    </div>
                    <div
                      className={`mt-1 font-semibold text-lg leading-6 text-color-text-1`}
                    >
                      {handleMount(item.available)}{' '}
                      {item.currency.currency_code}
                    </div>
                  </div>
                  {selectCurrency.id === item.id && (
                    <div className="flex justify-center items-center">
                      <SelectedIcon size={24} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </AntdModal>
      {showRateModal && (
        <RateUpdateCountdownModal
          onCancel={() => setRateShowModal(false)}
          interval={30}
        />
      )}
    </>
  );
};

export default PayoutsPayoutAmount;
