import { useEffect, useState, useCallback } from 'react';
import LineItemsWebPage from '../web/line-items-web';
import LineItemsMobilePage from '../mobile/choose-service';
import type { ICartParams } from '../../../types/terminal-type';
import { OrderCartAPI, getCustomerID, queryListing } from '../../../data/terminal';
import type { IObject } from '@/types/common-types';
import {
  calculationCart,
  getCascaderOptions,
  getDeliverySchedule,
  parseCart,
  parseDeliverySchedule,
} from '../../../utils/terminal-untils';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { createUuid } from '@/utils/common';
import { useLocation, useParams } from 'react-router-dom';
import { getCookie, setCookie } from '@/utils';
import { Constants } from '@/constants';

let uuids: string[] = [];
const TerminalLineItemsPage = () => {
  const { p_business_id: businessID = '', lang = 'en' } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryToken = searchParams.get('token');
  let inApp = false
  const queryInApp = searchParams.get('isApp') || false;
  if (typeof queryInApp === 'string' && queryInApp === 'true') {
    inApp = true;
  }
  let access_token = '';
  if (typeof queryToken === 'string' && queryToken.length > 0) {
    access_token = queryToken;
  }
  const cookieToken = getCookie(Constants.TOKEN) || '';
  const token = access_token || cookieToken;
  // const isNew = getCookie('isNew');

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultCart = {
    discount_total: 0,
    listings: [],
    origin_total: 0,
    total: 0,
    delivery_fee: 0,
  };

  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const options = getCascaderOptions(t, lang);
  const defoutValue = options[0];
  const [currentCart, setCurrentCart] = useState<ICartParams>(defaultCart);
  const [originalCart, setOriginalCart] = useState<ICartParams>(defaultCart);
  const [listings, setListings] = useState<any[]>([]);
  const [customerID, setCustomerID] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [dataTimeOut, setdataTimeOut] = useState<boolean>(false);
  const [currency] = useState('HKD');
  const [deliverySchedule, setDeliverySchedule] = useState<string[]>([
    defoutValue.value,
    defoutValue.children && defoutValue.children?.length > 0
      ? defoutValue.children[0].value
      : '',
  ]);


  const loadData = async () => {
    setLoading(true);
    const allPromise: any[] = [];
    let listings: any[] = [];
    let cart: ICartParams = defaultCart;
    let currentCustomerID = customerID;
    if (!currentCustomerID) {
      currentCustomerID = await getCustomerID(businessID, token);
      if(currentCustomerID==='ERR_NETWORK'){
        setdataTimeOut(true)
        setLoading(false);
        return
      }
      setCustomerID(currentCustomerID);
    }
    // if (isNew === 'true' && inApp) {
    // if (isNew === 'true') {
    //   resetListing(currentCustomerID, businessID, token)
    // }
    allPromise.push(queryListing());
    allPromise.push(
      OrderCartAPI({
        businessID,
        customerID: currentCustomerID,
        token,
      })
    );
    const allReasult = await Promise.all(allPromise);

    if (allReasult.length > 0) {
      listings = allReasult[0];
    }

    if (allReasult.length > 1) {
      cart = allReasult[1];
    }
    if (cart && cart.listings) {
      const { max_delivery_time = '', min_delivery_time = '' } = cart;
      const newCart = mergeData(cart, listings);
      setCurrentCart(newCart);
      setOriginalCart(cart);
      if (max_delivery_time && min_delivery_time) {
        const times = getDeliverySchedule(min_delivery_time, max_delivery_time);
        setDeliverySchedule(times);
      } else {
        setDeliverySchedule(deliverySchedule)
      }
    }
    setListings(listings);
    setCookie('isNew', 'false');
    setdataTimeOut(false)
    setLoading(false);
  };


  const mergeData = (cart: ICartParams, currentListings: any[] = listings) => {
    const { listings: cartListings = [] } = cart;
    const newListings: IObject[] = [...currentListings];
    if (cartListings.length > 0) {
      currentListings.forEach((listing, index) => {
        cartListings.forEach((item) => {
          if (
            listing.id === item.listing_id &&
            listing.favoriteID === item.favorite_id
          ) {
            let newListing = { ...listing };
            newListing = {
              ...listing,
              cartQuantity: item.quantity,
              cartID: item.id,
            };
            newListings.splice(index, 1, newListing);
          }
        });
      });
    }

    const newCart = { ...cart };
    newCart.listings = newListings;
    return newCart;
  };

  const queryOrderCart = async (order: IObject, uuid: string) => {
    const { startTime, endTime } = parseDeliverySchedule(deliverySchedule);
    const res = await OrderCartAPI({
      businessID,
      customerID,
      method: 'POST',
      token: token,
      cartData: {
        discount_code: '',
        listings: order,
        min_delivery_time: startTime,
        max_delivery_time: endTime,
      },
    });
    let isLast = false;
    if (uuids[uuids.length - 1] === uuid) {
      uuids = [];
      isLast = true;
    }

    if (Object.keys(res).length > 0) {
      const { card, newCart } = parseCart(res, listings);
      setOriginalCart(card);
      if (isLast) {
        setCurrentCart(newCart);
      }
    } else {
      if (isLast) {
        const newCart = mergeData(originalCart);
        setCurrentCart(newCart);
      }
    }
  };

  const handleMinus = async (record: IObject) => {
    const { cartQuantity = 0 } = record;
    if (cartQuantity === 0) return;
    const cartListing = calculationCart(record, currentCart, 'minus');
    cartAddOrDel(cartListing);
  };

  const handlePlus = async (record: IObject) => {
    const { cartQuantity = 0 } = record;
    if (cartQuantity >= 5) return;
    const cartListing = calculationCart(record, currentCart);
    cartAddOrDel(cartListing);
  };

  const cartAddOrDel = (cartListing: any) => {
    const currentList: IObject[] = [];
    if (originalCart.listings) {
      currentCart.listings.forEach((item) => {
        if (item.id === cartListing.listing_id) {
          currentList.push(cartListing);
        } else {
          currentList.push({
            favorite_id: item.favoriteID,
            id: item.cartID,
            listing_id: item.id,
            quantity: item.cartQuantity || 0,
          });
        }
      });
      const { newCart } = parseCart(
        {
          ...originalCart,
          listings: currentList,
        },
        listings
      );
      const uuid = createUuid();
      uuids.push(uuid);
      setCurrentCart(newCart);
      debouncedUpdateCart(cartListing, uuid);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateCart = useCallback(
    debounce(async (cartListing: any, uuid: any) => {
      await queryOrderCart(cartListing, uuid);
    }, 300),
    [customerID, originalCart, listings, uuids,deliverySchedule]
  );

  const handleTimeChange = async (value: string[]) => {
    setDeliverySchedule(value);
    const { startTime, endTime } = parseDeliverySchedule(value);
    if (startTime && endTime) {
      await OrderCartAPI({
        businessID,
        customerID,
        method: 'POST',
        token: token,
        cartData: {
          min_delivery_time: startTime,
          max_delivery_time: endTime,
        },
      });
    }
  };

  return (
    <>
      {!inApp && (
        <LineItemsWebPage
          handleMinus={handleMinus}
          handlePlus={handlePlus}
          loadingData={loading}
          cart={currentCart}
          currency={currency}
          options={options}
          deliverySchedule={deliverySchedule}
          setDeliverySchedule={handleTimeChange}
          // isPhone={isPhone}
          inApp={inApp}
          businessID={businessID}
          token={token}
          defoutValue={defoutValue}
        />
      )}
      {inApp && (
        <LineItemsMobilePage
          handleMinus={handleMinus}
          handlePlus={handlePlus}
          loading={loading}
          loadData={loadData}
          currency={currency}
          cart={currentCart}
          options={options}
          deliverySchedule={deliverySchedule}
          setDeliverySchedule={handleTimeChange}
          inApp={inApp}
          businessID={businessID}
          token={token}
          defoutValue={defoutValue}
          dataTimeOut={dataTimeOut}
        />
      )}
      {/* <div>
        <LineItemsMobilePage
          handleMinus={handleMinus}
          handlePlus={handlePlus}
          loading={loading}
          loadData={loadData}
          currency={currency}
          cart={currentCart}
          options={options}
          deliverySchedule={deliverySchedule}
          setDeliverySchedule={handleTimeChange}
          inApp={inApp}
          businessID={businessID}
          token={token}
          defoutValue={defoutValue}
          dataTimeOut={dataTimeOut}
        />
      </div> */}
    </>
  );
};

export default TerminalLineItemsPage;
