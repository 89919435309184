import { useEffect, useState } from 'react';
import type {
  IAddressObject,
} from '../../../types/terminal-type';
import EditAddress from '../mobile/edit-address';
import { getAddressList, getCustomerID } from '../../../data/terminal';
import { loadAreaCodesData } from '@/data/area-codes';
import { IAreaCodes } from '@/types/common-types';
import { useLocation, useParams } from 'react-router-dom';
import { getCookie } from '@/utils';
import { Constants } from '@/constants';


const MobileEditAddress = () => {
  const { p_business_id: businessID = '' } = useParams();
  const [customerID, setCustomerID] = useState<string>('');
  const [code] = useState('');
  const [areaCodes, setAreaCodes] = useState<IAreaCodes[]>([]);
  const [addressList, setAddressList] = useState<IAddressObject[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<IAddressObject>();
  const [dataTimeOut, setdataTimeOut] = useState<boolean>(false);
  const [currency] = useState('HKD');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryToken = searchParams.get('token');
  let inApp = false
  const queryInApp = searchParams.get('isApp') || false;
  if (typeof queryInApp === 'string' && queryInApp === 'true') {
    inApp = true;
  }
  let access_token = '';
  if (typeof queryToken === 'string' && queryToken.length > 0) {
    access_token = queryToken;
  }
  const cookieToken = getCookie(Constants.TOKEN) || '';
  const token = access_token || cookieToken;

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const loadData = async () => {
    const allPromise: any[] = [];
    let areaCodes: IAreaCodes[] = [];
    let currentCustomerID = customerID;
    if (!currentCustomerID) {
      currentCustomerID = await getCustomerID(businessID, token);
      if (currentCustomerID === 'ERR_NETWORK') {
        setdataTimeOut(true)
        return
      }
    }
    allPromise.push(loadAreaCodesData());
    allPromise.push(loadAddress(currentCustomerID));
    const allReasult = await Promise.all(allPromise);
    if (allReasult.length > 0) {
      areaCodes = allReasult[0];
    }
    setCustomerID(currentCustomerID);
    setAreaCodes(areaCodes);
    setdataTimeOut(false)
  };

  const loadAddress = async (currentCustomerID = customerID) => {
    const addressList = await getAddressList(
      businessID,
      token,
      currentCustomerID
    );
    const isDefaultRecord = addressList.filter(
      (item: IAddressObject) => item.is_default
    );
    if (isDefaultRecord.length > 0) {
      setSelectedAddress(isDefaultRecord[0]);
    }
    setAddressList(addressList);
  };

  return (
    <>
      <div>
        <EditAddress
          code={code}
          currency={currency}
          areaCodes={areaCodes}
          customerID={customerID}
          loadAddress={loadData}
          addressList={addressList}
          selectedAddress={selectedAddress} 
          token={token} 
          inApp={inApp} 
          businessID={businessID} 
          dataTimeOut={dataTimeOut}
        />
      </div>
    </>
  );
};

export default MobileEditAddress;
