import Button from '@/components/common/button';
import { IObject } from '@/types/common-types';
import { useEffect } from 'react';
import CopyIcon from '@/components/svg/copy-icon';
import { handlePrice, triggerWonderRoute } from '../../../utils/terminal-untils';
// import api from '@/libs/api';
import Config from '@/libs/config';
import dayjs from 'dayjs';
import ShareIcon from '../terminal-icons/result-share-icon';
import ConfirmedIcon from '../terminal-icons/result-confirmed-icon';
import FailIcon from '../terminal-icons/result-fail-icon';
import { useTranslation } from 'react-i18next';
import type { ITerminalOrdersPaymentResultPageProps } from '../../../types/terminal-type';
import DefaultItemIcon from '../terminal-icons/default-item-icon';
import { getClassByStatus } from '../../../utils/terminal-untils';
import ExitIcon from '../terminal-icons/exit-icon';
import debounce from 'lodash.debounce';
import { querySellerBusiness } from '../../../data/terminal';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import NetworkError from '../components/network-error';
import { getCookie } from '@/utils';


interface IChoosePaymentMethodProps
  extends ITerminalOrdersPaymentResultPageProps {
  businessID: string;
  token: string;
  record: IObject;
  dataTimeOut: boolean;
  loadData: () => void;
}

const PaymentRresultPage: React.FC<IChoosePaymentMethodProps> = (props) => {
  const {
    businessID,
    token,
    inApp,
    errorCode,
    dataTimeOut,
    message,
    record,
    loadData,
    tokenExpire = false,
  } = props;
  const isError = errorCode.length > 0;
  const {
    line_items = [],
    oms_delivery_note: oms = {},
    note,
    currency = 'HKD',
    correspondence_state: correspondenceState = '',
    number = '',
    created_at = '',
    subtotal,
    initial_delivery: initialDelivery = 0,
    discount_total,
    initial_total,
    reference_number,
    delivery_order: deliveryOrder = {},
  } = record || {};
  const { state: deliveryState, tracking_number, shipper } = deliveryOrder;
  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const { lang = 'en' } = useParams();
  const dateFormat = lang === 'en' ? 'MMM DD YYYY' : 'YYYY年MM月DD日';
  const navigate = useNavigate();
  const lineItems = line_items || [];
  const newDateFormat = lang === 'en' ? 'MMM DD YYYY HH:mm:ss' : 'YYYY年MM月DD日 HH:mm:ss';
  const cardList = lineItems.filter(
    (item: any) => item.purchasable_type === 'Listing'
  );
  let cartTotal = 0;
  cardList.forEach((item: any) => {
    cartTotal += item.quantity;
    return undefined;
  });

  const { max_delivery_time, min_delivery_time } = oms;

  let scheduleAt = '-';

  if (max_delivery_time && min_delivery_time) {
    scheduleAt = `${t('extended_service:estimated')} ${dayjs(
      min_delivery_time
    ).tz('Asia/Hong_Kong').format(dateFormat)} ${dayjs(min_delivery_time).tz('Asia/Hong_Kong').format(
      'HH:mm'
    )} ~ ${dayjs(max_delivery_time).tz('Asia/Hong_Kong').format('HH:mm')}`;
  }

  useEffect(() => {
    if (tokenExpire) {
      triggerWonderRoute('true', 'is401');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleMobileCopy = () => {
    triggerWonderRoute(reference_number, 'copy');
  };

  const handleClick = debounce(() => {
    const routeAddress = `/businesses/${businessID}/terminal/orders?token=${token}&isApp=${inApp}`;
    if (inApp) {
      triggerWonderRoute(routeAddress, 'route');
    } else {
      navigate(routeAddress);
    }
  }, 300);

  const goPdf = async () => {
    triggerWonderRoute('show', 'loading');
    try {
      const business = await querySellerBusiness();
      const resp = await axios({
        method: 'post',
        url: `${Config.urls.gatewayUrl}/api/helper/generate-pdf`,
        data: {
          templateType: 'wonder_order_details',
          fileName: `text.pdf`,
          folderName: businessID,
          fileData: {
            liquid_config: {
              locale: lang,
              money_currency: 'HKD',
              money_currency_symbol: 'HK$',
              money_decimal_points: 2,
            },
            business: business,
            order: record,
          },
        },
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
        }
      })
      triggerWonderRoute('hidden', 'loading');
      const PDFurl = resp.data.data.url ? resp.data.data.url : '';
      const data = {
        pdfUrl: PDFurl,
        referenceNumber: reference_number,
      };
      triggerWonderRoute(JSON.stringify(data), 'pdf');
    } catch (error) {
      console.error(error)
    }
    triggerWonderRoute('hidden', 'loading');
  };

  const alert = (value: any, action?: any) => {
    triggerWonderRoute(value, action);
  };

  const handleTryAgain = debounce(() => {
    let routeAddress = `/businesses/${businessID}/terminal/orders/payment?token=${token}&number=${number}&isApp=${inApp}`;
    const isDiscountCoupon = getCookie('__coupon') === 'true';
    if (isDiscountCoupon) {
      routeAddress = `/businesses/${businessID}/terminal/orders/check?token=${token}&isApp=${inApp}`;
    }
    if (inApp) {
      triggerWonderRoute(routeAddress, 'try_again');
    } else {
      navigate(routeAddress);
    }
  }, 300);

  const handlePaymentStatus = (status: string) => {
    const { className, showStatus } = getClassByStatus(status, t);
    if (!status) return '-';

    return (
      <div
        className={`font-medium rounded-3xl text-base px-3 py-1 ${className} !text-white`}
      >
        {showStatus}
        {/* {transformField(status)} */}
      </div>
    );
  };

  const handleTouchStart = (event: any) => {
    // touchStyle(event, '50%', '#F2FAFF')
    const targetElement = event.target;
    targetElement.parentNode.style.opacity = '50%';
  };
  const handleTouchEnd = (event: any) => {
    const targetElement = event.target;
    targetElement.parentNode.style.opacity = '100%';
  };

  const touchStyle = (event: any, color: string) => {
    event.stopPropagation();
    const targetElement = event.target;
    if (targetElement.className === '') {
      targetElement.parentNode.style.backgroundColor = color;
    } else if (targetElement.className.baseVal === '') {
    } else if (targetElement.className.includes('h-[112px]')) {
      targetElement.style.backgroundColor = color;
    } else if (
      targetElement.className.includes('flag') ||
      targetElement.className.includes('mt-4')
    ) {
      targetElement.parentNode.parentNode.style.backgroundColor = color;
    }
  };

  const newhandleTouchStart = (event: any) => {
    touchStyle(event, '#F2FAFF');
  };
  const newhandleTouchEnd = (event: any) => {
    touchStyle(event, 'white');
  };

  const handleClose = () => {
    triggerWonderRoute(
      `/businesses/${businessID}/terminal/orders/check`,
      'close'
    );
  };

  return (
    <div
      className="min-h-screen bg-[#F5F5F5] overflow-auto"
      style={{ WebkitTapHighlightColor: 'transparent' }}
    >
      {/* {insetHead()} */}
      <div
        className={`absolute inset-0 h-full flex flex-col font-sfPro overflow-x-hidden overflow-y-auto lg:hidden  pb-16`}
      >
        {!dataTimeOut &&(
          <div className={`bg-[#F5F5F5] flex-1 `}>
          {!isError && (
            <>
              <div className="p-4 flex fixed top-0 left-0 right-0 bg-[#F5F5F5] text-center !z-[999]">
                <div className="font-semibold flex-1 text-base"></div>
                <ExitIcon onClick={handleClose} />
              </div>
              <div className="p-5 pb-24">
                <div className="flex justify-center mt-10">
                  <ConfirmedIcon />
                </div>
                <div className="px-4 py-5 bg-primary-background-color rounded-2xl mt-5">
                  {cardList.map((item: any) => {
                    return (
                      <div className="flex mt-[14px] pb-[14px]" key={item.id}>
                        <div className="p-2 bg-disabled-color rounded-2xl">
                          {item.image_url && (
                            <div
                              className="w-[72px] h-[72px] flex justify-center items-center bg-center bg-no-repeat bg-contain"
                              style={{
                                backgroundImage: `url(${item.image_url})`,
                              }}
                            />
                          )}
                          {!item.image_url && (
                            <div
                              style={{
                                backgroundColor: 'rgba(226, 228, 231, 0.15)',
                              }}
                              className="w-[72px] h-[72px] flex justify-center items-center rounded"
                            >
                              <DefaultItemIcon />
                            </div>
                          )}
                        </div>
                        <div className="ml-2 flex-1 relative">
                          <div className="w-[85%]">{item.label}</div>
                          <div className=" absolute bottom-0 left-0 font-semibold">
                            <div>
                              {handlePrice(item.price)} {currency}
                            </div>
                          </div>
                          {(item.label || '').includes('Smart POS PAX') && (
                            <div
                              className="absolute  right-0 top-0 w-[14px] h-[14px] rounded-full bg-[#0094FF] text-white text-center leading-[14px] text-xs"
                              onClick={() => alert('terminal_fee', 'alert')}
                            >
                              i
                            </div>
                          )}
                          <div
                            className={`absolute right-0 bottom-0 flex w-24 justify-end`}
                          >
                            <div className={`text-base leading-7`}>
                              x {item.quantity}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="border-[1px] border-solid mt-[14px] rounded-2xl border-[#0094FF] px-4 py-2 text-base">
                    <div className="flex justify-between items-center mt-1">
                      <span className="text-[#8E8E93]">
                        {t('extended_service:total_items')}
                      </span>
                      <span className={` max-w-[180px] break-words `}>
                        {cartTotal} {t('extended_service:items')}
                      </span>
                    </div>
                    <div className="flex justify-between items-center mt-1">
                      <span className="text-[#8E8E93]">
                        {t('extended_service:subtotal')}
                      </span>
                      <span className={` max-w-[180px] break-words `}>
                        {handlePrice(subtotal)} {currency}
                      </span>
                    </div>
                    <div className="flex justify-between items-center mt-1">
                      <span className="text-[#8E8E93]">
                        {t('extended_service:delivery_fee')}
                      </span>
                      <span className={` max-w-[180px] break-words `}>
                        {handlePrice(initialDelivery)} {currency}
                      </span>
                    </div>
                    {discount_total !== 0 && (
                      <div className="flex justify-between items-center mt-1">
                        <span className="text-[#8E8E93]">
                          {t('extended_service:discount')}
                        </span>
                        <span className={` max-w-[180px] break-words `}>
                          -{handlePrice(discount_total)} {currency}
                        </span>
                      </div>
                    )}
                    <div className="flex justify-between items-center mt-1">
                      <span>{t('extended_service:total')}</span>
                      <span className={` max-w-[180px] break-words font-bold`}>
                        {handlePrice(initial_total)} {currency}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="text-colot-text-1 font-bold text-lg mt-5 mb-2 flex justify-between">
                  {t('extended_service:shipping_information')}
                </div>
                <div className="bg-primary-background-color rounded-xl p-4">
                  <div>
                    <div className="items-center">
                      <div className="flex justify-between">
                        <span>{oms.name}</span>
                      </div>
                      <div className={`mt-1`}>{oms.phone}</div>
                      <div className={` mt-1`}>
                        {oms.address1} {oms.street}
                      </div>
                      <div className={`mt-1`}>{oms.city}</div>
                    </div>
                    <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
                  </div>
                  <div>
                    <div className="items-center">
                      <div className="text-[#8E8E93] flex">
                        {t('extended_service:delivery_via')}
                        <div
                          className=" w-[14px] h-[14px] rounded-full bg-[#0094FF] text-white text-center leading-[14px] text-xs mt-1 ml-1"
                          onClick={() => alert('delivery_via', 'alert')}
                        >
                          i
                        </div>
                      </div>
                      <div className={` max-w-[180px] break-words mt-1`}>
                        {shipper ? shipper : '-'}
                      </div>
                    </div>
                    <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
                  </div>
                  <div>
                    <div className="items-center">
                      <div className="text-[#8E8E93] flex">
                        {t('extended_service:delivery_schedule')}
                        <div
                          className=" w-[14px] h-[14px] rounded-full bg-[#0094FF] text-white text-center leading-[14px] text-xs mt-1 ml-1"
                          onClick={() => alert('delivery_scheule', 'alert')}
                        >
                          i
                        </div>
                      </div>
                      <div className={` max-w-[280px] break-words mt-1`}>
                        {scheduleAt}
                      </div>
                    </div>
                    <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
                  </div>
                  <div>
                    <div className="items-center">
                      <div className="text-[#8E8E93] flex">
                        {t('extended_service:tracking_number')}
                        <div
                          className=" w-[14px] h-[14px] rounded-full bg-[#0094FF] text-white text-center leading-[14px] text-xs mt-1 ml-1"
                          onClick={() => alert('tracking_number', 'alert')}
                        >
                          i
                        </div>
                      </div>
                      <div className={` max-w-[180px] break-words mt-1`}>
                        {tracking_number ? tracking_number : '-'}
                      </div>
                    </div>
                    {/* <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " /> */}
                  </div>
                </div>

                <div className="text-colot-text-1 font-bold text-lg mt-5 mb-2 flex justify-between">
                  {t('extended_service:invoice_details')}
                </div>
                <div className="bg-primary-background-color rounded-xl p-4">
                  <div>
                    <div className="flex justify-between items-center relative">
                      <span className="text-[#8E8E93]">
                        {t('common:invoice_number_common')}
                      </span>
                      <span
                        className={`max-w-[180px] break-words text-right absolute top-[0] right-[0]`}
                      >
                        {number}
                      </span>
                    </div>
                    <div className="w-full h-1 border-t-[1px] my-4 mt-[30px] border-border-color" />
                  </div>
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="text-[#8E8E93]">
                        {t('extended_service:reference_number')}
                      </div>
                      <div className={` max-w-[180px] break-words flex`}>
                        {reference_number}
                        <CopyIcon
                          onClick={handleMobileCopy}
                          className="text-primary-color"
                        />
                      </div>
                    </div>
                    <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
                  </div>
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="text-[#8E8E93]">
                        {t('extended_service:payment_status')}
                      </div>
                      <div className={` max-w-[180px] break-words `}>
                        {handlePaymentStatus(correspondenceState)}
                      </div>
                    </div>
                    <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
                  </div>
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="text-[#8E8E93]">
                        {t('extended_service:delivery_status')}
                      </div>
                      <div className={` max-w-[180px] break-words `}>
                        {handlePaymentStatus(deliveryState)}
                      </div>
                    </div>
                    <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
                  </div>
                  <div>
                    <div className="flex justify-between items-center">
                      <span className="text-[#8E8E93]">
                        {t('extended_service:created_at')}
                      </span>
                      <span className={` max-w-[200px] break-words `}>
                        {dayjs(created_at).tz('Asia/Hong_Kong').format(newDateFormat)}
                      </span>
                    </div>
                    <div className="w-full h-[1px] bg-[#F5F5F5]  my-4 " />
                  </div>
                  <div>
                    <div className="flex justify-between items-center">
                      <span className="text-[#8E8E93]">
                        {t('extended_service:note')}
                      </span>
                      {/* <VectorIcon /> */}
                    </div>
                    <div>{note}</div>
                  </div>
                </div>

                <div
                  className="bg-primary-background-color rounded-xl p-4 flex justify-center items-center mt-4 h-[112px]"
                  onTouchStart={newhandleTouchStart}
                  onTouchEnd={newhandleTouchEnd}
                >
                  <div onClick={goPdf}>
                    <div className="flex justify-center flag">
                      <ShareIcon />
                    </div>
                    <div className="mt-4">{t('extended_service:share')}</div>
                  </div>
                </div>
              </div>
            </>
          )}

          {isError && (
            <>
              <div className="p-4 flex fixed top-0 left-0 right-0 bg-[#F5F5F5] text-center !z-[999]">
                <div className="font-semibold flex-1 text-base indent-6">
                  {t('extended_service:paymen_failed')}
                </div>
                <ExitIcon onClick={handleClose} />
              </div>
              <div className="p-5 pb-24 text-center">
                <div className="flex justify-center mt-[100px] ">
                  <FailIcon />
                </div>
                <div className="mt-2 text-[32px]">
                  <span className="font-bold">
                    {handlePrice(initial_total)}
                  </span>{' '}
                  {currency}
                </div>
                <div className="mt-4 text-[#FC2E01]">
                  {' '}
                  {t('extended_service:reason_code')}: {errorCode}
                </div>
                <div className="text-[#FC2E01]">{message}</div>
                <div className="mt-4">
                  {t('extended_service:paymen_failed_tips')}
                </div>
              </div>
            </>
          )}

          {!isError && (
            <div className="fixed bottom-0 w-[100%] bg-primary-background-color py-4">
              <div className="flex items-center px-4 w-full pb-[8px]">
                <Button
                  className="w-full h-12 rounded-xl font-sfPro text-white hover:text-color-text-6 !text-base !font-semibold duration-300"
                  onClick={handleClick}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                >
                  {t('common:done')}
                </Button>
              </div>
            </div>
          )}

          {isError && (
            <div className="fixed bottom-0 w-[100%] bg-primary-background-color pt-[22px] pb-4">
              {errorCode !== '100103' && (
                <div className="flex items-center px-4 w-full pb-[8px]">
                  <Button
                    className="w-full h-12 rounded-xl font-sfPro text-white hover:text-color-text-6 duration-300"
                    onClick={handleTryAgain}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                  >
                    <span className="!text-base !font-semibold">
                      {t('extended_service:try_again')}
                    </span>
                  </Button>
                </div>
              )}
              <div className="flex  items-center px-4 w-full pb-[8px]">
                <Button
                  className="w-full h-12 rounded-xl !border-white  bg-[rgba(0,148,255,0.2)] duration-300"
                  onClick={handleClick}
                  styleType={'Secondary'}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                >
                  <span className="!text-base !font-semibold">
                    {t('common:cancel')}
                  </span>
                </Button>
              </div>
            </div>
          )}
        </div>
        )}
        {dataTimeOut && (
          <NetworkError refresh={() => loadData()} />
        )}
      </div>
    </div>
  );
};

export default PaymentRresultPage;
