import Button from '@/components/common/button';
import { getCookie } from '@/utils/index';
import config from '@/libs/config';
import { Constants } from '@/constants';
import api from '@/libs/api';
import { useTranslation } from 'react-i18next';
import VectorIcon from '@/pages/global-accounts/global-accounts-icons/vector-icon';
import type { IPayoutsNewStep } from '@/types/global-accounts-type';
import { handlePrice } from '../utils';
import { useLocation, useParams } from 'react-router-dom';

interface IAddfundConfirmation {
  businessID: string;
  globalPayees: any;
  globalFrom: any;
  amount: string;
  free: string;
  setSteps: (steps: IPayoutsNewStep[]) => void;
  setStepCount: (stepCount: number) => void;
  setCreateLoading: (createLoading: boolean) => void;
  handleBack: () => void;
  setDetail: (detail: any) => void;
  setGlobalFrom: (fromdata: any) => void;
}

const AddfundConfirmation = (props: IAddfundConfirmation) => {
  const {
    // businessID,
    globalPayees,
    globalFrom,
    amount,
    free,
    setStepCount,
    setCreateLoading,
    setSteps,
    handleBack,
    setDetail,
    setGlobalFrom,
  } = props;
  const { t } = useTranslation(["global_accounts",
    "payouts",
    "account_balance",
    "related_company_accounts",
    "payees"]);
  const { lang = 'en' } = useParams();
  const { p_business_id: businessID = '', ledger_account_id: recordId = '' } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryCurrencyCode = searchParams.get('currency_code');
  const token = getCookie(Constants.TOKEN) || '';

  const handleNext = async () => {
    setCreateLoading(true);
    let param = null;
    setGlobalFrom({})
    const newAmount = amount.split(',').join('');
    if (globalPayees.payout_accounts) {
      param = {
        amount: newAmount,
        ledger_account: recordId,
        p_business_id: businessID,
        payee: globalPayees.id,
      };
    } else {
      param = {
        amount: newAmount,
        ledger_account: recordId,
        p_business_id: businessID,
        new_payee: {
          p_business_id: businessID,
          nickname: globalFrom.nickname,
          payout_account: {
            currency: queryCurrencyCode,
            identifier_1_value: globalFrom.id,
            identifier_2_value: globalFrom.name,
            identifier_3_value: globalFrom.number,
            transfer_method: 'HK FPS - Account Number',
            country: 'HK',
          },
        },
      };
    }

    try {
      const resp = await api.post(
        `${config.urls.gatewayUrl}/api/treasury-services/api/add_funds`,
        { ...param },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-p-business-id': businessID,
            'X-USER-ACCESS-TOKEN': token,
            'x-i18n-lang': lang,
          },
        }
      );
      setDetail(resp);
    } catch (error: any) {
      setDetail(error.response.data);
    }

    const steps: IPayoutsNewStep[] = [
      {
        key: '1',
        title: t('payouts:choose_payee'),
      },
      {
        key: '5',
        title: t('payouts:transfer_details'),
        name: '2',
      },
    ];
    setCreateLoading(false);
    setSteps(steps);
    setStepCount(1);
  };

  const computationalCost = (amount: string, free: string) => {
    const newAmount = amount.split(',').join('');
    const res = Number(newAmount) - Number(free);
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const formattedNum = formatter.format(res);
    return formattedNum;
  };

  const goBack = () => {
    const steps: IPayoutsNewStep[] = [
      {
        key: '1',
        title: t('global_accounts:choose_method'),
        name: '1',
      },
      {
        key: '3',
        title: t('payouts:fps_edda'),
        name: '2',
      },
    ];
    setSteps(steps);
    setStepCount(1);
  };

  return (
    <div>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        <div className="flex justify-between">
          <div className="font-semibold text-3xl text-color-text-1 flex">
            <VectorIcon className="mt-2" />
            <div className="ml-4">
              <div>
                {amount} {queryCurrencyCode}
              </div>
              <div className="text-[#4C5259] text-sm font-normal">
                {t('payouts:top_up_amount')}
              </div>
            </div>
          </div>
          <div
            className="font-medium text-[#8E8E93] cursor-pointer"
            onClick={handleBack}
          >
            {t('common:cancel')}
          </div>
        </div>

        <div className="border border-border-color rounded-[16px] px-4 w-full py-1 mt-6 text-sm font-medium">
          <div className="flex justify-between items-center py-4  border-border-color ">
            <div className="text-color-text-2">
              {t('payouts:transaction_amount')}
            </div>
            <div className="text-color-text-1">
              {amount} {queryCurrencyCode}
            </div>
          </div>
          <div className="flex justify-between items-center py-4 border-t border-border-color">
            <div className="text-color-text-2">{t('common:fee_charged')}</div>
            <div className="text-color-text-1">
              -{handlePrice(Number(free))} {queryCurrencyCode}
            </div>
          </div>
          <div className="flex justify-between items-center py-4 border-t border-border-color">
            <div className="text-color-text-2">
              {t('account_balance:payin_amount')}
            </div>
            <div className="text-color-text-1">
              {computationalCost(amount, free)} {queryCurrencyCode}
            </div>
          </div>

          <div className="flex justify-between items-center py-4 border-t border-border-color">
            <div className="text-color-text-2">{t('payouts:sending_bank')}</div>
            <div className="text-color-text-1">
              {globalPayees.payout_accounts && !globalFrom.bankCode && (
                <div>
                  {globalPayees.payout_accounts.banks.bank_code}{' '}
                  {globalPayees.payout_accounts.banks.bank_name}
                </div>
              )}
              {globalFrom.bankCode && (
                <div>
                  {globalFrom.bankCode} {globalFrom.bank}
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-between items-center py-4 border-t border-border-color">
            <div className="text-color-text-2">{t('payouts:sending_name')}</div>
            <div className="text-color-text-1">
              {globalPayees.payout_accounts && !globalFrom.name && (
                <div>{globalPayees.payout_accounts.identifier_2_value}</div>
              )}
              {globalFrom.name && <div>{globalFrom.name}</div>}
            </div>
          </div>
          <div className="flex justify-between items-center py-4 border-t border-border-color">
            <div className="text-color-text-2">
              {t('payouts:sending_number')}
            </div>
            <div className="text-color-text-1">
              {globalPayees.payout_accounts && !globalFrom.number && (
                <div>{globalPayees.payout_accounts.identifier_3_value}</div>
              )}
              {globalFrom.number && <div>{globalFrom.number}</div>}
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <Button className="flex-1 mr-6" onClick={goBack} styleType="Secondary">
          {t('common:back')}
        </Button>
        <Button className="flex-1" onClick={handleNext} styleType="Primary">
          {t('payouts:confirm_add_fund')}
        </Button>
      </div>
    </div>
  );
};

export default AddfundConfirmation;
