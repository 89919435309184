import Label from '@/components/common/label';
import Skeleton from '@/components/skeleton';
import type { ReactNode } from 'react';

export interface IPayeeItemProps {
  title: string;
  content: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  contentClass?: string;
  className?: string;
}

const PayeeItem = (props: IPayeeItemProps) => {
  const {
    title = '',
    loading = false,
    contentClass = 'text-sm py-2.5 text-color-text-1 font-medium pr-6',
    className = '',
  } = props;

  let { content } = props;

  if (content === '' || content === undefined || content === null) {
    content = '--';
  }

  return (
    <div className={`w-full ${className}`}>
      <Label disabled={true} value={title}></Label>

      {loading && (
        <div className={`overflow-hidden inline-block w-60`}>
          <Skeleton
            row={1}
            width={['100%']}
            className={`h-4 rounded-2xl mb-0`}
          />
        </div>
      )}
      {!loading && <div className={`${contentClass}`}>{content}</div>}
    </div>
  );
};

export default PayeeItem;
