import { handleBuildOrderPayment } from '../utils';
import { payInvoice } from '../../../../../data/terminal';
import type { ICreditCardMethod } from '../interface';

interface ICreditCardPayParams {
  businessID: string;
  orderNumber: string;
  payData: ICreditCardMethod;
  payment: any;
  email: string;
  lang: string;
}

const UseCreditCardPay3DS = async (params: ICreditCardPayParams) => {
  const { businessID, orderNumber, payment, payData, email, lang } = params;

  const { amount, returnUrl, token, currenToken } = payData;

  const newPayment = {
    credit_cards: [
      {
        amount,
        token,
        card_reader_mode: 'manual',
        '3ds': {
          enable: true,
          success_url: returnUrl,
          fail_url: returnUrl,
        },
        success_url: returnUrl,
        fail_url: returnUrl,
      },
    ],
  };

  const order = handleBuildOrderPayment(newPayment, payment);

  try {
    const resp :any= await payInvoice({
      businessID,
      orderNumber,
      order,
      email,
      lang,
      token: currenToken,
    });

    const { code, data: fulfilOrder } = resp || {};
    if (
      fulfilOrder &&
      code === 200 &&
      fulfilOrder.sale_transactions &&
      fulfilOrder.sale_transactions.length > 0
    ) {
      let acquirer: any = '';
      let transaction: any = '';
      if (fulfilOrder.state !== 'completed' && fulfilOrder.state !== 'voided') {
        fulfilOrder.sale_transactions.forEach((item: any) => {
          if (item.payment_method === 4 && item.is_pending) {
            transaction = item;
          }
        });
      }

      if (
        transaction &&
        transaction['3ds'] &&
        transaction['3ds'].redirect_url
      ) {
        acquirer = transaction['3ds'].redirect_url;
      }

      if (acquirer) {
        window.location.href = acquirer;
        return;
      }
      return {
        order: fulfilOrder,
      };
    } else {
      return {
        status: 500,
        ...resp,
      };
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export default UseCreditCardPay3DS;
