import { IBaseCartProps, IPointsObject } from '@/types/analytics-type';
import { handleNumber } from '@/utils/field-utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TransitionNode from '../../components/transition-node';
import MoreIcon from '../../analytics-icons/more-icon';
import Skeleton from '@/components/skeleton';
import NodataContent from '../../components/no-data-content';
import PayoutTransactionsDetails from '../../components/payout-transactions-details';
import { IPayoutsReportsTypes } from '@/data/analytics';

const PayoutByPayoutCategories: React.FC<IBaseCartProps> = (props) => {
  const {
    data,
    currency,
    startDate,
    endDate,
    businessID,
    loading: loadAllData,
  } = props;

    const { t } = useTranslation('analytics');

  const { points = [], total_amount = 0 } = data || {};
  points?.sort((a: IPointsObject, b: IPointsObject) => b.y_axis - a.y_axis);
  const [showMore, setShowMore] = useState<boolean>(false);

  const total = handleNumber(total_amount);
  const title = t('analytics:payout_by_payout_categories');
  const disabled = !points || points.length === 0;

  const handleMore = async () => {
    if (!disabled) {
      setShowMore(true);
    }
  };

  const handleCancel = () => {
    setShowMore(false);
  };

  return (
    <TransitionNode className="h-fit">
      <div className="px-6 py-6 md:px-9 flex flex-col h-full">
        <div className="flex justify-between mb-6">
          <div className="font-bold text-xl text-color-text-1">{title}</div>
          <div
            onClick={handleMore}
            className={`p-2 rounded-full h-8 w-8 ${
              disabled
                ? 'bg-disabled-color text-icon-color'
                : 'cursor-pointer bg-opacity-10 hover:bg-opacity-100 hover:text-color-text-6 text-primary-color bg-primary-color'
            }`}
          >
            <MoreIcon />
          </div>
        </div>
        <div className="flex flex-col mb-4">
          {loadAllData && (
            <div className="h-[45px] flex items-center">
              <Skeleton
                className="h-auto w-48"
                skeletonItemClass="h-6 rounded-2xl"
                row={1}
              />
            </div>
          )}
          {!loadAllData && (
            <div className="text-[30px] text-color-text-1">
              <span className="font-bold mr-2">
                {handleNumber(total_amount)}
              </span>
              <span>{currency}</span>
            </div>
          )}
          <div className="text-color-text-2 font-medium">
            {t('analytics:total_amount')}
          </div>
        </div>
        {/* {loadAllData && <BarChartSkeleton />} */}
        {/* {!loadAllData && !disabled && (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )} */}
        <div className="mt-6 w-full flex text-color-text-1 rounded overflow-hidden h-10">
          {loadAllData && (
            <div className="h-[45px] w-full flex items-center">
              <Skeleton
                className="h-auto w-48"
                skeletonItemClass="h-10 rounded"
                row={1}
              />
            </div>
          )}
          {!loadAllData &&
            points?.map((item, index) => {
              let percentage: any = (
                (item.y_axis / total_amount) *
                100
              ).toFixed();
              percentage = Math.round(percentage);
              return (
                <div
                  key={index}
                  className="leading-10 text-center"
                  style={{
                    width: `${percentage}%`,
                    backgroundColor: `${item.revenue_color}`,
                  }}
                >
                  {percentage > 3 ? `${percentage}%` : ''}
                </div>
              );
            })}
        </div>
        <div className="grid grid-cols-2 gap-2 mt-6">
          {loadAllData &&
            new Array(8)
              .fill('')
              .map((_r, i) => (
                <Skeleton
                  key={i}
                  className="h-auto"
                  skeletonItemClass="h-14 rounded"
                  row={1}
                />
              ))}
          {!loadAllData &&
            points?.map((item, index) => (
              <div
                key={index}
                className="flex items-center rounded border border-border-color px-4 py-2 overflow-hidden"
              >
                <div
                  className={`w-8 h-8 flex shrink-0 justify-center items-center rounded-full`}
                  style={{ backgroundColor: item.revenue_color }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${item.revenue_icon_file_url})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      width: '20px',
                      height: '20px',
                    }}
                  />
                </div>
                <div className="ml-2 text-sm text-color-text-1 font-medium">
                  <div className="text-color-text-2 truncate">{item.name}</div>
                  <div>
                    {handleNumber(item.y_axis)} {currency}
                  </div>
                </div>
              </div>
            ))}
        </div>
        {!loadAllData && disabled && <NodataContent />}
        <PayoutTransactionsDetails
          visible={showMore}
          total={`${
            total.indexOf('-') === -1 ? `+${total}` : total
          } ${currency}`}
          startDate={startDate}
          endDate={endDate}
          onCancel={handleCancel}
          businessID={businessID}
          currency={currency}
          type={IPayoutsReportsTypes.PAYOUT_CATEGORY}
        />
      </div>
    </TransitionNode>
  );
};

export default PayoutByPayoutCategories;
