import React from 'react';
import type { IIconProps } from '@/types/common-types';

const AlipayDetailsIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 32 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M18.7973 2H5.20416C3.43394 2 2 3.435 2 5.20345V18.7966C2 20.565 3.43394 22 5.20416 22H18.7973C20.5675 22 21.9995 20.565 21.9995 18.7966V5.20345C22.0014 3.43312 20.5675 2 18.7973 2Z"
          fill="#00ADEE"
        />
        <path
          d="M18.4661 14.6641C17.667 14.3939 16.592 13.9802 15.3961 13.5438C16.114 12.2836 16.6884 10.8497 17.0662 9.29107H13.1234V7.85903H17.9523V7.05988H13.1234V4.67188H11.1529C10.8071 4.67188 10.8071 5.01572 10.8071 5.01572V7.05799H5.92346V7.85714H10.8071V9.28918H6.77361V10.0883H14.5951C14.3098 11.0821 13.9244 12.0173 13.4691 12.8655C10.9318 12.021 8.22266 11.3371 6.52234 11.7584C5.43414 12.0286 4.73323 12.5103 4.32138 13.0167C2.43214 15.3348 3.78672 18.8563 7.7768 18.8563C10.1365 18.8563 12.4092 17.5301 14.17 15.3442C16.7979 16.6176 21.999 18.8053 21.999 18.8053V15.688C22.0009 15.688 21.3472 15.6351 18.4661 14.6641ZM7.40462 17.5093C4.29304 17.5093 3.37298 15.0381 4.91082 13.6855C5.4247 13.2283 6.36176 13.0053 6.86052 12.9562C8.7082 12.7729 10.4199 13.4833 12.4395 14.477C11.0206 16.3436 9.21262 17.5093 7.40462 17.5093Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default AlipayDetailsIcon;
