import React from 'react';
import type { IIconProps } from '@/types/common-types';

const WeChatPayIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        width="4em"
        height="3em"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 46 35"
        fill="none"
      >
        <path
          d="M18.7294 21.1265C18.6024 21.194 18.4595 21.2331 18.3076 21.2331C17.9559 21.2331 17.6502 21.0292 17.4897 20.7274L17.4283 20.5858L14.8679 14.6647C14.8403 14.6003 14.8231 14.5275 14.8231 14.4566C14.8231 14.1837 15.0331 13.9627 15.292 13.9627C15.3971 13.9627 15.4943 13.9993 15.5725 14.0606L18.594 16.3277C18.8146 16.4798 19.0784 16.569 19.3619 16.569C19.5312 16.569 19.6925 16.5358 19.8429 16.4781L34.0516 9.81374C31.5047 6.65054 27.3102 4.58313 22.5636 4.58313C14.7962 4.58313 8.5 10.1127 8.5 16.9343C8.5 20.6556 10.3943 24.0057 13.3594 26.27C13.5972 26.449 13.753 26.743 13.753 27.0749C13.753 27.1846 13.7306 27.2847 13.7034 27.3894C13.4666 28.3203 13.0876 29.8112 13.07 29.881C13.0404 29.9978 12.9942 30.1199 12.9942 30.2421C12.9942 30.515 13.204 30.7361 13.4633 30.7361C13.5649 30.7361 13.6481 30.6961 13.734 30.6441L16.8128 28.7707C17.0444 28.6301 17.2896 28.5426 17.5599 28.5426C17.7035 28.5426 17.8424 28.5661 17.973 28.6082C19.4093 29.0436 20.959 29.2855 22.5637 29.2855C30.3306 29.2855 36.6277 23.7556 36.6277 16.9343C36.6277 14.8682 36.0467 12.9223 35.0256 11.2106L18.8324 21.0639L18.7294 21.1265Z"
          fill="#1AAD19"
        />
      </svg>
    </div>
  );
};

export default WeChatPayIcon;
