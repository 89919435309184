import type { IObject } from '@/types/common-types';
import type { IFileObject } from '@/types/common-types';
import { currencies } from '@/utils/currencies';
import Config from '@/libs/config';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import type { IAttachmentFile, IPayout, IPayee } from '@/types/global-accounts-type';
import { TransactionType } from '@/types/global-accounts-type';

// import { parsePayees } from '../payouts-step-new/utils';
// import type { IObject } from '@/shared/types/common';

export const initPayout: IPayout = {
    id: '',
    approved_at: '',
    attachment_files: [],
    calculated_fee: '',
    cancelled_at: '',
    currency: '',
    destination_amount: '',
    destination_currency: '',
    display_name: '',
    estimated_settled_at: '',
    fx_buy_currency: '',
    fx_exchange_rate: '',
    fx_quote_id: '',
    fx_sell_currency: '',
    total_amount: '',
    amount: '',
    payee_id: '',
    payee_name: '',
    payout_category_color: '',
    payout_category_icon: '',
    payout_category_id: '',
    payout_category_name: '',
    po_number: '',
    ref_id: '',
    reference: '',
    remark: '',
    reversed_at: '',
    schedule_payout_at: '',
    schedule_status: '',
    settled_at: '',
    transfer_method: '',
    transfer_status: '',
    transfer_type: '',
    updated_by: '',
    vendor_reference_id: '',
    p_created_at: '',
    transfer_purpose: '',
    transfer_purpose_id: '',
    i18n: {},
    payee: undefined,
};

export const getDays = (tag: 'Week' | 'Month' | 'Year') => {
    let arr: any = [];
    const today = dayjs();

    if (tag === 'Week') {
        let startOfWeek = today.startOf('week');
        const todayOfWeek = today.day();
        if (todayOfWeek === 0) {
            startOfWeek = today.subtract(6, 'day');
        } else {
            const dayOfWeek = startOfWeek.day();
            if (dayOfWeek === 0) {
                startOfWeek = startOfWeek.add(1, 'day');
            }
        }
        arr = [startOfWeek, today];
    } else if (tag === 'Month') {
        arr = [dayjs().startOf('month'), today];
    } else if (tag === 'Year') {
        arr = [dayjs().startOf('year'), today];
    }

    return arr;
};

export const plus = (
    num1: number,
    num2: number,
    decimal?: number | undefined
) => {
    let num: any = new BigNumber(num1).plus(num2);

    if (decimal !== undefined && decimal !== null) {
        num = num.toFixed(decimal);
    }

    return Number(num);
};



export const handleAmoutDisplay = (value: string, symbol?: string) => {
    let returnValue = value;
    if (!isNaN(Number(returnValue))) {
        const temp = new BigNumber(returnValue);
        if (symbol) {
            const value = temp.toFormat(2, 1);
            if (value.indexOf(symbol) === -1) {
                returnValue = `${symbol}${value}`;
            }
        } else {
            if (temp.toNumber() > 0) {
                returnValue = `+${temp.toFormat(2, 1)}`;
            } else {
                returnValue = temp.toFormat(2, 1);
            }
        }
    }

    return returnValue;
};

export const getTranstionType = (record: IObject) => {
    let type;

    if (record.card_id) {
        type = TransactionType.card;
    } else if (record.payout_request_id) {
        type = TransactionType.payOut;
    } else if (record.payin_request_id) {
        type = TransactionType.payIn;
    } else if (record.fx_conversion_id) {
        type = TransactionType.exChange;
    }

    return type;
};

export const parsePayees = (data: any) => {
    const payees: IPayee[] = [];
    if (Array.isArray(data)) {
        data.forEach((item) => {
            payees.push({
                id: item.id,
                type: item.type,
                nickname: item.nickname,
                p_business_id: item.p_business_id,
                company_name: item.company_name,
                payout_category_id: item.payout_category_id,
                categories: item.categories,
                payout_accounts: item.payout_accounts,
                i18n: item.i18n,
                is_default_bank: item.is_default_bank,
            });
        });
    }
    return payees;
};

export const getPayoutsI18nValue = (
    i18n: IObject,
    key: string,
    lang: string
) => {
    const value = key;
    if (i18n[value]) {
        if (i18n[value][lang]) {
            return i18n[value][lang];
        }
    }
    return value;
};


export const getState = (data: any, lang: string) => {
    const { schedule_status = '', transfer_status = '', i18n } = data;
    const schedule = schedule_status.toLocaleLowerCase();
    const transfer = transfer_status.toLocaleLowerCase();
    if (schedule === 'pending approval' && transfer === 'initiated') {
        return (
            <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-warning-color bg-warning-color/10 uppercase">
                {getValueMultilingual('Pending Approval', i18n, lang)}
            </span>
        );
    }
    if (
        (schedule === 'approved' && transfer === 'initiated') ||
        (schedule === 'pending verification' && transfer === 'suspended')
    ) {
        return (
            <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-warning-color bg-warning-color/10 bg-opacity-10 uppercase">
                {getValueMultilingual('Pending for Transfer', i18n, lang)}
            </span>
        );
    }
    if (schedule === 'approved' && transfer === 'processing transfer') {
        return (
            <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-primary-color bg-primary-color/10 uppercase">
                {getValueMultilingual('Processing Transfer', i18n, lang)}
            </span>
        );
    }
    if (schedule === 'approved' && transfer === 'settled') {
        return (
            <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#4CD964] bg-[#4CD964]/10 uppercase">
                {getValueMultilingual('Settled', i18n, lang)}
            </span>
        );
    }
    if (schedule === 'rejected' && transfer === 'reversed') {
        return (
            <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#828282] bg-[#828282]/10 uppercase">
                {getValueMultilingual('Rejected', i18n, lang)}
            </span>
        );
    }
    if (schedule === 'cancelled' && transfer === 'reversed') {
        return (
            <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#8E8E93] bg-[#8E8E93]/10 uppercase">
                {getValueMultilingual('Reversed', i18n, lang)}
            </span>
        );
    }
    if (transfer === 'refunded') {
        return (
            <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#F43F5E] bg-[#F43F5E]/10 uppercase">
                {getValueMultilingual('Refunded', i18n, lang)}
            </span>
        );
    }

    return (
        <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#8E8E93] bg-[#8E8E93]/10 uppercase">
            {getValueMultilingual(transfer_status, i18n, lang)}
        </span>
    );
};

export const getValueMultilingual = (
    value: string,
    i18n: IObject,
    lang: string
) => {
    if (i18n[value]) {
        if (i18n[value][lang]) {
            return i18n[value][lang];
        }
    }
    return value;
};

export const handleMount = (value: any, points = 2, minus = false) => {
    let returnValue = '0.00';
    const temp = new BigNumber(value);
    if (!temp.isNaN()) {
        returnValue = temp.toFormat(points, 1);
        if (minus) {
            returnValue = `-${returnValue}`;
        }
    }
    return returnValue;
};

export const initFiles = (allFiles: IAttachmentFile[], type: string) => {
    const files: IFileObject[] = [];
    if (Array.isArray(allFiles)) {
        allFiles.forEach((item) => {
            if (item.out_type === type && item.id) {
                files.push({
                    id: item.id,
                    directusID: item.payout_request,
                    fileUrl: `${Config.urls.gatewayUrl}/api/directus/treasury-services/files/${item.id}`,
                    type: item.type,
                    title: item.title,
                    filenameDownload: item.filename_download,
                });
            }
        });
    }
    return files;
};

export const getLicense = (
    permissions: string[],
    payout: any,
    userID: string
) => {
    const {
        schedule_status = '',
        transfer_status = '',
        estimated_settled_at,
        created_by,
    } = payout || {};
    const schedule = schedule_status.toLocaleLowerCase();
    const transfer = transfer_status.toLocaleLowerCase();
    let canApprove = false;
    let canReject = false;
    let canCancel = false;
    let canEdit = false;
    if (
        (schedule === 'approved' && transfer === 'initiated') ||
        (schedule === 'pending verification' && transfer === 'suspended')
    ) {
        if (dayjs(estimated_settled_at).isAfter(dayjs(), 'D')) {
            if (
                permissions.includes('payouts:approve_and_reject') ||
                created_by === userID
            ) {
                canEdit = true;
                canCancel = true;
            }
        }
    }
    if (schedule === 'pending approval' && transfer === 'initiated') {
        if (permissions.includes('payouts:approve_and_reject')) {
            canApprove = true;
            canReject = true;
            canCancel = true;
        }
        if (created_by === userID) {
            canCancel = true;
        }
        if (permissions.includes('payouts:update')) {
            canEdit = true;
        }
    }

    return {
        canApprove,
        canReject,
        canCancel,
        canEdit,
    };
};

export const getInitSupportingDocuments = (payout: IPayout) => {
    const { reference, po_number, remark, attachment_files } = payout || {};
    return {
        reference: reference || '',
        poNumber: po_number || '',
        remark: remark || '',
        poFiles: initFiles(attachment_files, 'Purchase Order'),
        invoiceFiles: initFiles(attachment_files, 'Invoice'),
    };
};

export const getCountryByCurrency = (currency: string) => {
    let countryCode = 'hk';
    for (let i = 0; i < currencies.length; i++) {
        const item = currencies[i];
        if (item.currency_code === currency) {
            countryCode = item.alpha_2;
            break;
        }
    }

    return countryCode.toLocaleLowerCase();
};

export const prasePayout = (data: any) => {
    const { payee } = data;
    const payees = parsePayees([payee]);
    const payout: IPayout = {
        ...data,
    };

    if (payees.length > 0) {
        payout.payee = payees[0];
    }

    return payout;
};

