import { useNavigate } from 'react-router-dom';
import langs from './langs';
import { supportedLngs } from '@/i18n';
import i18n from 'i18next';
import { useMemo } from 'react';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { Dropdown, MenuProps } from 'antd';
import EnIcon from '@/components/svg/en-icon';
import SelectIcon from './i18n-icon/select-icon';
import ZhCNIcon from '@/components/svg/zh-cn-icon';
import ZhHKIcon from '@/components/svg/zh-hk-icon';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-hk';
import 'dayjs/locale/en';
import dayjs from 'dayjs';


const LanguageSelector = () => {
  const navigate = useNavigate();
  const lang = i18n.language;

  // const [showDropdown, setShowDropdown] = useState(false);


  const changeLanguage = (lng: string) => {
    if (!supportedLngs.includes(lng)) {
      return;
    }

    let pathname = window.location.pathname;
    const pathnameSplit = pathname.split('/');
    let lang = '';
    if (pathnameSplit.length > 1 && pathnameSplit[1]) {
      lang = pathnameSplit[1];
      lang = lang.replaceAll('_', '-');
    }

    if (lang === lng) {
      return;
    }

    if (lang && langs.includes(lang.toLocaleLowerCase())) {
      pathname = pathname.replace(`/${lang}`, '');
    }

    if (pathname.startsWith('/')) {
      pathname = `/${lng}${pathname}`;
    } else {
      pathname = `/${lng}/${pathname}`;
    }

    dayjs.locale(lng.toLocaleLowerCase());
    i18n.changeLanguage(lng);
    navigate(pathname);
  };

  const handleItemClick = (info: MenuInfo) => {
    changeLanguage(info.key);
  };

  const langNode = useMemo(() => {
    let node = <EnIcon />;
    // let langText = 'English';

    if (lang === 'zh-CN') {
      node = <ZhCNIcon />;
      // langText = '简体中文';
    } else if (lang === 'zh-HK') {
      node = <ZhHKIcon />;
      // langText = '繁體中文';
    }

    return (
      <div className="flex items-center mr-2">
        {node}{' '}
        {/* <span className="text-color-text-3 text-sm ml-2">{langText}</span> */}
      </div>
    );
  }, [lang]);

  const languageItems: MenuProps['items'] = [
    {
      key: 'en',
      label: (
        <div className='flex items-center justify-between cursor-pointer hover:bg-disabled-color p-5'>
         <div className="flex items-center mr-4">
            <EnIcon />{' '}
            <span className="text-color-text-2 font-medium ml-2">English</span>
          </div>
          {lang === 'en' && <SelectIcon />}
        </div>
      )
    },
    {
      key: 'zh-HK',
      label: (
        <div className='flex items-center justify-between cursor-pointer hover:bg-disabled-color p-5'>
         <div className="flex items-center mr-4">
            <ZhHKIcon />{' '}
            <span className="text-color-text-2 font-medium ml-2">繁體中文</span>
          </div>
          {lang === 'zh-HK' && <SelectIcon />}
        </div>
      )
    },
    {
      key: 'zh-CN',
      label: (
        <div className='flex items-center justify-between cursor-pointer hover:bg-disabled-color p-5'>
         <div className="flex items-center mr-4">
            <ZhCNIcon />{' '}
            <span className="text-color-text-2 font-medium ml-2">简体中文</span>
          </div>
          {lang === 'zh-CN' && <SelectIcon />}
        </div>
      )
    },
  ]

  return (
    <div>
      <Dropdown
        trigger={['click']}
        // onOpenChange={(open) => setShowDropdown(open)}
        menu={{
          items: languageItems,
          onClick: handleItemClick
        }}
      >
        <div className="cursor-pointer flex items-center">
          {langNode}
          {/* {showDropdown ? (
            <UpIcon className="text-icon-color ml-1 hidden lg:block group-hover:text-primary-color" />
          ) : (
            <DownIcon className="text-icon-color ml-1 hidden lg:block group-hover:text-primary-color" />
          )} */}
          {/* <DividerIcon className='ml-3'/> */}
        </div>
      </Dropdown>
    </div>
  );
};

export default LanguageSelector;
