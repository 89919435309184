import type { IObject } from '@/types/common-types';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import updateLocale from 'dayjs/plugin/updateLocale';
import { getCookie } from '@/utils/index';
import { Constants } from '@/constants';
import { TransactionType } from '@/types/account-balance-type';
import {
  handleAmoutDisplay,
  getPaymentMethodIcon,
  getTransferListIcon,
  getTranstionStatusLabel,
  getTranstionType,
  geyPaymentDisplayName,
  handleSymbolMount,
  handleType,
} from '../../utils/account-balance-untils';
import { plus } from '@/utils/galaxy-utils';
import type { ColumnsType } from 'antd/es/table';
import type { TableRecord } from '@/components/table/interface';
import { TFunction } from 'i18next';

dayjs.extend(utc);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  monthsShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ],
});

export const getCurrentAccountColumns = (t: TFunction<"translation", undefined>, upcoming = false,lang:string) => {
  const titles = [
    t('account_balance:from_to'),
    t('account_balance:transfer_type'),
    t('account_balance:transfer_method'),
    t('account_balance:status'),
    t('common:amount'),
    upcoming
      ? t('account_balance:est_settled_on')
      : t('account_balance:settled_on'),
  ];
  const columns: ColumnsType<TableRecord> = [
    {
      key: 'ledger_id',
      fixed: 'left',
      title: 'ID',
      width: 210,
      dataIndex: 'ledger_id',
      render: (_key, record) => {
        const {
          payin_request_id,
          payout_request_id,
          fx_conversion_id,
          card_transaction_id,
        } = record || {};
        const showID =
          payin_request_id ||
          payout_request_id ||
          fx_conversion_id ||
          card_transaction_id ||
          '';

        return <div>{showID}</div>;
      },
    },
    {
      key: 'form_to',
      align: 'left',
      title: titles[0],
      dataIndex: 'form_to',
      width: 275,
      render: (key, record) => {
        const { display_name, fx_sell_currency, fx_buy_currency } =
          record || {};
        const transtionType = getTranstionType(record);
        let returnValue = '';
        const ele = getTransferListIcon(record);
        switch (transtionType) {
          case TransactionType.payIn:
          case TransactionType.payOut:
          case TransactionType.card:
            returnValue = display_name;
            break;
          // case TransactionType.card:
          //   returnValue = card_title;
          //   break;
          case TransactionType.exChange:
            returnValue = `${fx_sell_currency} to ${fx_buy_currency}`;
            break;
        }

        return (
          <div className="py-2 text-color-text-2 font-medium pr-6 flex items-center space-x-2">
            <span className="group">{ele}</span>
            <span className="max-w-16 whitespace-nowrap truncate text-color-text-1 font-medium">
              {returnValue}
            </span>
          </div>
        );
      },
    },
    {
      key: 'transfer_type',
      align: 'left',
      title: titles[1],
      dataIndex: 'transfer_type',
      width: 180,
    },
    {
      key: 'transfer_method',
      align: 'left',
      title: titles[2],
      width: 180,
      dataIndex: 'transfer_method',
      render: (key, record) => {
        const { transfer_method, merchant_mcc } = record || {};

        const transtionType = getTranstionType(record);
        let returnValue = '';

        switch (transtionType) {
          case TransactionType.payIn:
          case TransactionType.payOut:
            returnValue = transfer_method;
            break;
          case TransactionType.card:
            returnValue = merchant_mcc;
            break;
        }

        return <span>{returnValue || '--'}</span>;
      },
    },
    {
      key: 'status',
      align: 'left',
      title: titles[3],
      dataIndex: 'status',
      width: 180,
      render: (key, record) => {
        const component = getTranstionStatusLabel(record).component;
        return component;
      },
    },
    {
      key: 'amount',
      align: 'right',
      title: titles[4],
      dataIndex: 'amount',
      width: 180,
      render: (_key, record) => {
        const {
          local_currency = 'HKD',
          transaction_currency = 'HKD',
          local_amount = '',
          transaction_amount = '',
          status,
          fx_buy_amount,
          fx_buy_currency,
          fx_sell_amount,
          fx_sell_currency,
        } = record || {};
        let returnValue = local_amount;
        let style = '';
        let isDifferent = false;
        let transactionAmount = transaction_amount;
        const transtionType = getTranstionType(record);

        if (local_currency !== transaction_currency) {
          isDifferent = true;
        }
        let currencyColor = '';

        if (
          ['terminated', 'reversed', 'declined'].includes(status.toLowerCase())
        ) {
          style = 'line-through text-color-text-4';
          currencyColor = 'text-color-text-4';
        }

        returnValue = handleAmoutDisplay(returnValue);

        if (isDifferent) {
          if (transtionType === TransactionType.payOut) {
            transactionAmount = new BigNumber(transactionAmount).toFormat(2, 1);
            if (`${transactionAmount}`.indexOf('-') === -1) {
              transactionAmount = `-${transactionAmount}`;
            }
            return (
              <div>
                <div>
                  <span className={`whitespace-nowrap ${style}`}>
                    {returnValue}
                  </span>
                  <span className={`${currencyColor}`}> {local_currency}</span>
                </div>
                {isDifferent && (
                  <div className="text-xs">
                    <span className={`whitespace-nowrap ${style}`}>
                      {transactionAmount}
                    </span>
                    <span className={`${currencyColor}`}>
                      {' '}
                      {transaction_currency}
                    </span>
                  </div>
                )}
              </div>
            );
          } else if (transtionType === TransactionType.exChange) {
            const fxBuyAmount = handleAmoutDisplay(fx_buy_amount);
            const fxSellAmount = handleAmoutDisplay(fx_sell_amount, '-');

            return (
              <div>
                <div>
                  <span className={`whitespace-nowrap ${style}`}>
                    {fxBuyAmount}
                  </span>
                  <span className={`${currencyColor}`}> {fx_buy_currency}</span>
                </div>
                {isDifferent && (
                  <div className="text-xs">
                    <span className={`whitespace-nowrap ${style}`}>
                      {fxSellAmount}
                    </span>
                    <span className={`${currencyColor}`}>
                      {' '}
                      {fx_sell_currency}
                    </span>
                  </div>
                )}
              </div>
            );
          }
        }

        return (
          <div>
            <span className={`whitespace-nowrap ${style}`}>{returnValue}</span>
            <span className={`${currencyColor}`}> {transaction_currency}</span>
          </div>
        );
      },
    },
    {
      key: 'estimated_settled_at',
      align: 'right',
      title: titles[5],
      width: 140,
      dataIndex: 'estimated_settled_at',
      render: (key, record) => {
        const { estimated_settled_at, status, settled_at } = record;
        const transtionType = getTranstionType(record);
        let returnValue = estimated_settled_at;

        if (['Settled'].includes(status)) {
          returnValue = settled_at;
        }

        if (
          (transtionType === TransactionType.payIn &&
            ['Reversed', 'Suspended'].includes(status)) ||
          (transtionType === TransactionType.payOut &&
            ['Terminated', 'Reversed', 'Suspended'].includes(status)) ||
          (transtionType === TransactionType.card && status !== 'Settled')
        ) {
          return '--';
        }

        if (
          transtionType === TransactionType.payOut &&
          ['Settled', 'Initiated'].includes(status)
        ) {
          return dateInTabale(returnValue, false, lang);
        }

        return dateInTabale(returnValue, false, lang);
      },
    },
  ];

  return columns;
};

/**
 * 首字母大写
 * @param str
 * @returns
 */
export const titleCase = (str: string) => {
  let tmp = str.toLowerCase();

  //第一种方法
  tmp = tmp.charAt(0).toUpperCase() + tmp.slice(1);

  // //第二种方法
  // tmp = tmp.slice(0,1).toUpperCase() + tmp.slice(1)

  // //第三种方法
  // tmp = tmp.substring(0,1).toUpperCase() + tmp.substring(1)

  return tmp;
};

/**
 * 使用dayjs 处理utc时间
 */
export const dayjsUtc = (utcStr: string) => {
  return dayjs.utc(utcStr);
};

/**
 *
 * @param businessID
 * @returns
 */
export const getTokenBuinessID = (businessID: string): IObject => {
  const token = getCookie(Constants.TOKEN) || '';

  return {
    token: token,
    pBusinessID: businessID,
  };
};

export const dateInTabale = (date: string, showTiem = true, lang: string) => {
  const isUtc = date.indexOf('Z') > -1;
  let utcDate = dayjs(date);
  if (isUtc) {
    utcDate = dayjs.utc(date).tz();
  }
  const dateFormat = lang === 'en' ? 'MMM D, YYYY' : 'MMM DD日, YYYY';
  const ymdRes = utcDate.format(dateFormat);
  const timeRes = utcDate.format('HH:mm:ss');
  return (
    <div className="flex flex-col">
      <span className=" whitespace-nowrap">{`${ymdRes}`}</span>
      {showTiem && <span className=" whitespace-nowrap">{`${timeRes}`}</span>}
    </div>
  );
};

export const getTransactionsColumns = (
  t: any,
  isTransactionId = false,
  lang: string
) => {
  const columns: ColumnsType<TableRecord> = [
    {
      key: 'payment_method',
      fixed: 'left',
      width: 250,
      title: t('common:payment_method'),
      render: (key, record) => {
        const { payment_method = '', consumer_identify } = record || {};
        const returnValue = geyPaymentDisplayName(
          payment_method,
          consumer_identify
        );
        const iconType = getPaymentMethodIcon(payment_method);

        return (
          <div className="flex items-center space-x-1">
            {iconType && iconType}
            <span>{`${returnValue}`}</span>
          </div>
        );
      },
    },
    {
      key: 'transaction_id',
      width: 200,
      title: t('account_balance:transaction_id'),
      render: (key, record) => {
        const { transaction_id, id } = record;
        return isTransactionId ? transaction_id : id;
      },
    },
    {
      key: 'order_num',
      align: 'left',
      width: 200,
      title: t('account_balance:reference_number'),
      render: (key, record) => {
        const { order_reference_number: orderNum } = record;
        return orderNum || '--';
      },
    },
    {
      key: 'payment_type',
      align: 'left',
      width: 200,
      title: t('account_balance:type'),
      render: (key, record) => {
        const {
          payment_type = '',
          void_at,
          // updated_at,
          tipsAmount,
        } = record || {};
        return handleType(payment_type, void_at, tipsAmount);
      },
    },
    {
      key: 'amount',
      align: 'right',
      width: 190,
      title: isTransactionId
        ? t('account_balance:transaction_amount')
        : t('common:amount'),
      render: (key, record) => {
        const {
          amount = '',
          currency = 'HKD',
          void_at,
          payment_type = '',
          tipsAmount,
        } = record || {};
        const returnValue = handleSymbolMount(plus(amount, tipsAmount || 0));
        let style = '';
        const transferStatus = (payment_type || '').toUpperCase();

        if (void_at !== null) {
          style = 'line-through text-color-text-4';
        }

        if (transferStatus === 'REFUND') {
          style = 'text-error-color';
        }

        return (
          <div>
            <span className={`whitespace-nowrap ${style}`}>
              {`${returnValue}`}
            </span>
            &nbsp;
            <span className="whitespace-nowrap">{`${currency}`}</span>
          </div>
        );
      },
    },
    {
      key: 'created_at',
      align: 'right',
      width: 160,
      title: t('account_balance:transaction_time'),
      render: (key, record) => {
        const { created_at = '' } = record || {};
        if (!created_at) return <span>{``}</span>;

        return dateInTabale(created_at, true, lang);
      },
    },
  ];

  return columns;
};
