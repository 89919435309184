import api from "@/libs/api";
import { IObject } from "@/types/common-types";

export const loadLocalAddress = async () => {
  let address = undefined;

  try {
    const coords = await getCoordinates();
    if (coords) {
      const { latitude, longitude } = coords;
      const res: IObject = await api.get(
        `https://maps.google.com/maps/api/geocode/json?latlng=${latitude},${longitude}&language=zh-CN&sensor=false&key=AIzaSyAUPtj763fFh12_cey048TjF-DFjBwEL0A`
      );
      if (res && res.results && Array.isArray(res.results)) {
        address = parseData(res.results);
      }
    }
  } catch (error) {
    console.error(error);
  }
  return address;
};

const parseData = (data: any[]) => {
  let res: IObject = {};

  if (data.length < 1) return res;

  const record = data[data.length - 1];
  const typeArr: string[] = record['types'] || [];
  const arr = record['address_components'] || [];

  if (
    typeArr.indexOf('country') > -1 &&
    typeArr.indexOf('political') > -1 &&
    arr &&
    arr.length > 0
  ) {
    const data = arr[0] || {};
    const dataTypes = data['types'] || [];
    if (
      dataTypes.indexOf('country') > -1 &&
      dataTypes.indexOf('political') > -1
    ) {
      res = data;
    }
  }

  return res;
};

export const getCoordinates = (): Promise<
  GeolocationCoordinates | undefined
> => {
  return new Promise((resolve) => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          resolve(pos.coords);
        },
        () => {
          resolve(undefined);
        },
        {
          enableHighAccuracy: true,
          timeout: 2000,
        }
      );
    } else {
      resolve(undefined);
    }
  });
};