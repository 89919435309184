import AccountBalanceCurrentAccountPage from "@/pages/account-balance/current-account";
import AccountBalanceMerchantAccountPage from "@/pages/account-balance/merchant-account";
import TranscationDetails from "@/pages/account-balance/transcation-details-page";
import AccountBalanceOverviewPage from "@/pages/account-balance/overview";
import PayinsReportsPage from "@/pages/analytics/payins-reports";
import PayoutsReportsPage from "@/pages/analytics/payouts-reports";
import SettlementReportsPage from "@/pages/analytics/settlement-reports";
import PaymentGatewayReportsPage from "@/pages/analytics/transaction-reports";
import AssociatesDetailPage from "@/pages/associates/details";
import AssociateListPage from "@/pages/associates/list-view";
import PayeesDetailsPage from "@/pages/payees/details-page";
import PayeesListPage from "@/pages/payees/list-page";
import PayoutsDetailsPage from "@/pages/payouts/details-page";
import PayoutsListPage from "@/pages/payouts/list-page";
import { RouteObject } from "react-router-dom";
import SettlementDetails from "@/pages/account-balance/settlement-details-page";
import PaymentDetails from "@/pages/account-balance/payment-details-page";
import AdjustmentFeeDetails from "@/pages/account-balance/payment-details-page/adjustment-fee-details";
import GloballAccountsPage from "@/pages/global-accounts";
import GlobalAccountsDetails from "@/pages/global-accounts/account-details";
import GlobalAccountsActivities from "@/pages/global-accounts/current-activities";
import TerminalOrdersPage from "@/pages/terminal/pages/orders";
import TerminalOrdersDetailsPage from "@/pages/terminal/pages/details";
import { ApiCredentialsView } from "@/pages/setting/api-credentials-page";
import { NewApiCredentialsView } from "@/pages/setting/new-api-credentials-page";
import { StoreSettingsFormView } from "@/pages/setting/store-settings-page";
import { RelatedAccountListView, RelatedFormNew, RelatedFormView } from "@/pages/setting/related-company-account-page";
import LayoutBasic from "@/components/layout/BasicLayout";
import { ReactNode, Suspense } from "react";
import GlobalCheckComponent from "@/components/common/global-check-component";

const lazyComponent = (element: ReactNode): ReactNode => {
  return <Suspense fallback={<></>}> {element} </Suspense>
}


const routerWithSidebar: RouteObject[] = [
  {
    element: <GlobalCheckComponent />,
    children: [
      {
        element: <LayoutBasic />,
        path: '/:lang?/businesses/:p_business_id',
        children: [
          {
            path: 'analytics',
            children: [
              {
                path: 'payins-reports',
                element: lazyComponent(<PayinsReportsPage />),
              },
              {
                path: 'payouts-reports',
                element: lazyComponent(<PayoutsReportsPage />),
              },
              {
                path: 'transaction-reports',
                element: lazyComponent(<PaymentGatewayReportsPage />),
              },
              {
                path: 'settlement-reports',
                element: lazyComponent(<SettlementReportsPage />),
              },
            ]
          },
          {
            path: 'associates',
            children: [
              {
                path: '',
                element: lazyComponent(<AssociateListPage />),
              },
              {
                path: ':associate_id',
                element: lazyComponent(<AssociatesDetailPage />),
              },
            ]
          },
          {
            path: 'payouts/:type',
            children: [
              {
                path: '',
                element: lazyComponent(<PayoutsListPage />),
              },
              {
                path: ':payout_id',
                element: lazyComponent(<PayoutsDetailsPage />),
              },
            ]
          },
          {
            path: 'payees',
            children: [
              {
                path: '',
                element: lazyComponent(<PayeesListPage />),
              },
              {
                path: ':payee_id/:type',
                element: lazyComponent(<PayeesDetailsPage />),
              },
            ]
          },
          {
            path: 'account-balance',
            children: [
              {
                path: 'overview',
                element: lazyComponent(<AccountBalanceOverviewPage />),
              },
              {
                path: 'merchant-account',
                element: lazyComponent(<AccountBalanceMerchantAccountPage />),
              },
              {
                path: 'current-account',
                element: lazyComponent(<AccountBalanceCurrentAccountPage />),
              },
              {
                path: 'transactions/:type',
                children: [
                  {
                    path: ':transaction_id',
                    element: lazyComponent(<TranscationDetails />),
                  },
                ]
              },
              {
                path: 'settlements',
                children: [
                  {
                    path: ':settlement_id',
                    element: lazyComponent(<SettlementDetails />),
                  },
                ]
              },
              {
                path: 'payments',
                children: [
                  {
                    path: ':payment_id',
                    element: lazyComponent(<PaymentDetails />),
                  },
                  {
                    path: 'adjustment-fee/:adjustment-fee_id',
                    element: lazyComponent(<AdjustmentFeeDetails />),
                  },
                ]
              },
            ]
          },
          {
            path: 'global-accounts',
            children: [
              {
                path: '',
                element: lazyComponent(<GloballAccountsPage />),
              },
              {
                path: ':ledger_account_id',
                children: [
                  {
                    path: 'account-details',
                    element: lazyComponent(<GlobalAccountsDetails />),
                  },
                  {
                    path: 'current-activities',
                    element: lazyComponent(<GlobalAccountsActivities />),
                  },
                ]
              }
            ]
          },
          {
            path: 'terminal',
            children: [
              {
                path: 'orders',
                children: [
                  {
                    path: '',
                    element: lazyComponent(<TerminalOrdersPage />),
                  },
                  {
                    path: ':order_number',
                    element: lazyComponent(<TerminalOrdersDetailsPage />),
                  },
                ]
              }
            ]
          },
          {
            path: 'settings',
            children: [
              {
                path: 'api-credentials',
                element: lazyComponent(<ApiCredentialsView />),
              },
              {
                path: 'new-api-credentials',
                element: lazyComponent(<NewApiCredentialsView />),
              },
              {
                path: 'business-settings',
                element: lazyComponent(<StoreSettingsFormView />),
              },
              {
                path: 'related-company-accounts',
                children: [
                  {
                    path: '',
                    element: lazyComponent(<RelatedAccountListView />),
                  },
                  {
                    path: ':related_company_account_id',
                    element: lazyComponent(<RelatedFormView />),
                  },
                  {
                    path: 'new',
                    element: lazyComponent(<RelatedFormNew />),
                  },
                ],
              },
            ]
          },
        ]
      }
    ]
  }
]

export default routerWithSidebar;