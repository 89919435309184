import type { IObject } from '@/types/common-types';
import type { ICreditCardMethod } from './interface';

const isNumber = (val: any) => {
  if (parseFloat(val).toString() === 'NaN') {
    return false;
  } else {
    return true;
  }
};

export const handleBuildOrderPayment = (
  newPayment: IObject,
  payment?: IObject
) => {
  if (!payment) payment = {};
  return {
    payment: {
      ...payment,
      ...newPayment,
    },
    auto_void_other_payments_when_fail: true,
  };
};

export const handle3DSMethod = (params: ICreditCardMethod) => {
  const {
    amount,
    getawapSafetyVerification,
    appSafetyVerification,
    safetyVerification,
    safetyVerificationAmount,
    appSafetyVerificationAmount,
  } = params;

  let use3DSAmount: string | boolean = false;
  let store3DSAmount: string | boolean = false;
  let app3DSAmount: string | boolean = false;

  if (
    safetyVerificationAmount !== undefined &&
    safetyVerificationAmount !== null
  ) {
    store3DSAmount = isNumber(parseFloat(safetyVerificationAmount.toString()))
      ? safetyVerificationAmount.toString()
      : false;
  }

  if (
    appSafetyVerificationAmount !== undefined &&
    safetyVerificationAmount !== null
  ) {
    app3DSAmount = isNumber(parseFloat(appSafetyVerificationAmount.toString()))
      ? appSafetyVerificationAmount.toString()
      : false;
  }

  const use3DS =
    getawapSafetyVerification && appSafetyVerification && safetyVerification;
  use3DSAmount = store3DSAmount || app3DSAmount;

  return use3DS && use3DSAmount && amount >= parseFloat(use3DSAmount);
};
