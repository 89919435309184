import AntdModal from '@/components/common/antd/modal';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Button from '@/components/common/button';
import type { IAddressObject } from '../../../types/terminal-type';

interface IAddressListModalProps {
  visible: boolean;
  addressList: IAddressObject[];
  selectedRecord?: IAddressObject;
  selectedAddress?: IAddressObject;
  setSelectedRecord: (record: IAddressObject) => void;
  handleAdd: () => void;
  handleUpdateRecord: (
    record?: IAddressObject,
    isUpdateDefault?: boolean
  ) => void;
  handleEditRecord: (record: IAddressObject) => void;
  onCancel: () => void;
}

const AddressListModal: React.FC<IAddressListModalProps> = (props) => {
  const {
    visible,
    addressList,
    selectedAddress,
    selectedRecord,
    setSelectedRecord,
    handleEditRecord,
    handleUpdateRecord,
    handleAdd,
    onCancel,
  } = props;
  const { t } = useTranslation(["extended_service","transaction_advice"]);
  const [currentList, setCurrentList] = useState<IAddressObject[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setCurrentList(addressList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressList]);

  const handleSelect = (record: IAddressObject) => {
    const newList: IAddressObject[] = [];
    currentList.forEach((item) => {
      const newItem = {
        ...item,
        is_default: false,
      };
      if (item.id === record.id) {
        newItem.is_default = true;
      }

      newList.push(newItem);
    });
    setSelectedRecord(record);
    setCurrentList(newList);
  };

  const handleConfirm = async () => {
    setLoading(true);
    if (selectedRecord && selectedRecord.id !== selectedAddress?.id) {
      await handleUpdateRecord(undefined, true);
    } else {
      onCancel();
    }
    setLoading(false);
  };

  return (
    <AntdModal
      className="h-full flex flex-col max-w-[75%] lg:max-w-[35%]"
      open={visible}
      onCancel={onCancel}
    >
      <div className="p-6 h-full flex flex-col overflow-auto">
        <div className="text-center text-xl font-bold mb-6">
          {t('extended_service:shipping_address')}
        </div>
        <div className={`flex-1 overflow-auto hidden_scrollbar`}>
          {currentList.map((item) => {
            return (
              <div
                key={item.id}
                onClick={() => handleSelect(item)}
                className="border border-border-color flex justify-between rounded-lg py-3 px-4 mb-4"
              >
                <div className="font-medium text-sm">
                  <div className="bold text-base">{item.name}</div>
                  <div className="mt-3">{item.phone}</div>
                  <div>{item.address}</div>
                </div>
                <div className="flex flex-col justify-between">
                  {item.is_default && (
                    <div className="cursor-pointer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <circle
                          cx="12.5"
                          cy="12.5"
                          r="12.5"
                          fill="#0094FF"
                          fillOpacity="0.2"
                        />
                        <path
                          d="M8.33398 13.4264L11.5747 16.6671L16.6673 9.72266"
                          stroke="#0094FF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  )}
                  {!item.is_default && (
                    <div className="cursor-pointer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <circle
                          cx="12.5"
                          cy="12.5"
                          r="11.75"
                          stroke="#0094FF"
                          strokeOpacity="0.2"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                  )}
                  <div
                    onClick={() => handleEditRecord(item)}
                    className="cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M9.00733 1.83211C9.10664 1.73749 9.23908 1.68559 9.37623 1.68755C9.51337 1.68951 9.64428 1.74517 9.74084 1.84258L12.5963 4.72265C12.6467 4.77345 12.6862 4.83403 12.7123 4.90067C12.7384 4.96731 12.7506 5.03859 12.7481 5.11012C12.7456 5.18165 12.7285 5.25191 12.6977 5.31656C12.667 5.38121 12.6234 5.43889 12.5696 5.48603L5.68925 11.5123C5.59089 11.5986 5.46379 11.645 5.33298 11.6423L2.78246 11.5878C2.64542 11.5849 2.51497 11.5284 2.41908 11.4305C2.32319 11.3325 2.2695 11.2009 2.26953 11.0638V8.4735C2.26952 8.40257 2.28391 8.33238 2.31183 8.26718C2.33974 8.20197 2.38061 8.14312 2.43195 8.09417L9.00733 1.83211ZM9.70259 10.2559L13.6295 10.3324C13.6983 10.3338 13.7662 10.3486 13.8292 10.3762C13.8923 10.4038 13.9493 10.4435 13.997 10.4931C14.0447 10.5427 14.0821 10.6012 14.1072 10.6653C14.1323 10.7294 14.1445 10.7978 14.1432 10.8666C14.1419 10.9354 14.127 11.0032 14.0994 11.0663C14.0718 11.1294 14.0321 11.1864 13.9825 11.2341C13.9329 11.2818 13.8744 11.3192 13.8103 11.3443C13.7463 11.3694 13.6779 11.3816 13.6091 11.3803L9.68216 11.3038C9.61336 11.3024 9.5455 11.2875 9.48246 11.2599C9.41942 11.2323 9.36243 11.1926 9.31475 11.1429C9.26707 11.0933 9.22964 11.0348 9.20458 10.9707C9.17952 10.9066 9.16733 10.8382 9.1687 10.7694C9.17008 10.7006 9.185 10.6327 9.2126 10.5697C9.2402 10.5066 9.27995 10.4497 9.32957 10.402C9.3792 10.3543 9.43773 10.3169 9.50182 10.2918C9.56591 10.2667 9.63431 10.2546 9.70312 10.2559H9.70259ZM9.35837 2.94494L3.3174 8.69775V10.5509L5.15117 10.5902L11.4599 5.06478L9.35837 2.94494ZM13.6038 12.8756C13.7428 12.8746 13.8764 12.9288 13.9754 13.0263C14.0744 13.1238 14.1306 13.2567 14.1317 13.3956C14.1327 13.5346 14.0785 13.6682 13.981 13.7672C13.8835 13.8662 13.7506 13.9224 13.6117 13.9235L3.32159 14C3.18264 14.001 3.04896 13.9468 2.94996 13.8493C2.85097 13.7518 2.79477 13.6189 2.79373 13.48C2.79269 13.341 2.84689 13.2073 2.94441 13.1083C3.04193 13.0093 3.17478 12.9531 3.31373 12.9521L13.6038 12.8756Z"
                        fill="#71829A"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            );
          })}
          <div
            onClick={handleAdd}
            className="text-primary-color font-medium cursor-pointer p-4 flex items-center border border-primary-color rounded-lg mt-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <circle
                cx="10"
                cy="10"
                r="7.25"
                stroke="#0094FF"
                strokeWidth="1.5"
              />
              <path
                d="M10 6V14"
                stroke="#0094FF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 10H14"
                stroke="#0094FF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="ml-2">{t('extended_service:add_new_address')}</div>
          </div>
        </div>
        <Button
          onClick={handleConfirm}
          className="w-full mt-6"
          loading={loading}
        >
          {t('common:confirm')}
        </Button>
      </div>
    </AntdModal>
  );
};

export default AddressListModal;
