import React from 'react';
import type { IIconProps } from '@/types/common-types';

const AlipayIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        width="4em"
        height="3em"
        viewBox="0 0 46 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.3348 4.58398H14.0033C11.7463 4.58398 9.91797 6.41363 9.91797 8.66844V25.9999C9.91797 28.2547 11.7463 30.0844 14.0033 30.0844H31.3348C33.5919 30.0844 35.4178 28.2547 35.4178 25.9999V8.66844C35.4202 6.41124 33.5919 4.58398 31.3348 4.58398Z"
          fill="#00ADEE"
        />
        <path
          d="M30.9117 20.7304C29.8928 20.386 28.5222 19.8584 26.9974 19.302C27.9127 17.6953 28.645 15.867 29.1268 13.8797H24.0996V12.0539H30.2565V11.0349H24.0996V7.99017H21.5872C21.1463 7.99017 21.1463 8.42858 21.1463 8.42858V11.0325H14.9195V12.0514H21.1463V13.8773H16.0035V14.8963H25.976C25.6123 16.1633 25.1209 17.3557 24.5404 18.4372C21.3053 17.3605 17.8511 16.4885 15.6831 17.0257C14.2957 17.3701 13.402 17.9844 12.8769 18.6299C10.468 21.5856 12.1952 26.0756 17.2826 26.0756C20.2912 26.0756 23.189 24.3846 25.434 21.5976C28.7847 23.2212 35.4162 26.0106 35.4162 26.0106V22.036C35.4186 22.036 34.5852 21.9686 30.9117 20.7304ZM16.8081 24.3581C12.8407 24.3581 11.6676 21.2074 13.6284 19.4827C14.2836 18.8997 15.4784 18.6155 16.1143 18.5529C18.4701 18.3192 20.6525 19.2249 23.2276 20.492C21.4185 22.8719 19.1133 24.3581 16.8081 24.3581Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default AlipayIcon;
