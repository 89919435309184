import styles from '@/pages/content.module.css';
import Config from '@/libs/config';
import { useParams } from 'react-router-dom';

const TermsAndCondition = () => {
  const { lang = 'en' } = useParams();
  const prefix = Config.staticPath;

  return (
    <>
      <head>
        <link
          rel="preload"
          href={`${prefix}/font/Outfit-Light.woff`}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="preload"
          href={`${prefix}/font/Outfit-Bold.woff`}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        ></link>
      </head>
      {lang !== 'zh-CN' && lang !== 'zh-HK' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title} text-center`}>Terms and Conditions</div>
            <div className={`${styles.content} break-all`}>
              <h3>I. General Provisions</h3>
              <p>
                Welcome to our ride-hailing platform! Before using our services, please read the following terms and conditions (hereinafter referred to as "the Terms"). By registering as a user and using our services, you agree to comply with these Terms which are established in accordance with the laws of the Hong Kong Special Administrative Region.
              </p>
              <h3>II. Service Description</h3>
              <p>We provide a ride-hailing platform that enables you to book vehicles through our mobile application or website. This service includes, but is not limited to, instant ride-hailing services, reservation services, etc.</p>
              <h3>III. User Registration and Account</h3>
              <p>3.1 Registration Obligations During the registration process, you must provide true, accurate, current, and complete personal information. If there are any changes to your personal information, you must update it promptly.</p>
              <p>3.2 Account Security You will receive a username and password to access your account. It is your responsibility to secure your account. We recommend choosing a strong password and changing it regularly, and not sharing your account information with others.</p>
              <h3>IV. Usage Norms</h3>
              <p>4.1 Lawful Use You agree to use our services only for lawful purposes and to comply with all relevant Hong Kong laws and regulations.</p>
              <p>4.2 Prohibited Activities You may not use our services to engage in the following activities:</p>
              <ul>
                <li>Sending or transmitting illegal, harassing, defamatory, privacy-invasive, or otherwise inappropriate messages.</li>
                <li>Infringing others' intellectual property or other rights.</li>
                <li>Damaging, interfering with the operation of our services, or stealing user data.</li>
                <li>Engaging in any activities that may threaten the security of our systems.</li>
              </ul>
              <h3>V. Fees and Payment</h3>
              <p>You will incur charges when using our ride-hailing services, which we will provide through the application or website prior to the service. You agree to pay the service fees as indicated. Payment methods may include credit cards, debit cards, electronic wallets, or other supported payment methods.</p>
              <h3>VI. Disclaimer</h3>
              <p>We strive to ensure the reliability and security of our services, but do not guarantee that the services will be uninterrupted or error-free. We are not liable for any form of loss or damage resulting from the use of our services, unless such losses are caused by our intentional misconduct or gross negligence.</p>
              <h3>VII. Modifications and Termination</h3>
              <p>We reserve the right to modify or terminate the services at any time. In the event of such changes, we will post the modifications on the application or website. If you disagree with the changes, you have the right to stop using our services and cancel your account.</p>
              <h3>VIII. Privacy Policy</h3>
              <p>Your privacy is important to us. We will collect, use, protect, and share your personal information in accordance with our privacy policy, which describes these practices in detail. By using our services, you agree to the terms of this privacy policy.</p>
              <h3>IX. Intellectual Property</h3>
              <p>9.1 Copyright All content on this platform, including but not limited to text, images, interface design, trademarks, and software, is owned by us or the respective rights holders and is protected by the laws of the Hong Kong Special Administrative Region and international copyright laws.</p>
              <p>9.2 Licensing Restrictions Without explicit written permission from us or the respective rights holders, you may not copy, modify, rent, lend, sell, distribute, or create derivative works from any content on our platform or use it in any other way.</p>
              <h3>X. Dispute Resolution</h3>
              <p>The interpretation and application of these Terms shall be governed by the laws of the Hong Kong Special Administrative Region. Any disputes arising from or related to these Terms or services should first be addressed through friendly negotiations. If negotiation fails, disputes should be submitted to the Hong Kong International Arbitration Centre for arbitration under its rules.</p>
              <h3>XI. Miscellaneous</h3>
              <p>11.1 Force Majeure We are not liable for any service interruptions or delays caused by force majeure events, which include but are not limited to natural disasters, wars, government actions, global pandemics, public network failures, etc.</p>
              <p>11.2 Severability If any part of these Terms is found to be invalid or unenforceable, that part shall be omitted without affecting the validity and enforceability of the remaining terms.</p>
              <p>11.3 Assignment You may not assign any rights or obligations under these Terms without our prior written consent.</p>
              <p>11.4 Entire Agreement These Terms, along with the privacy policy and any other legal documents you agree to through the use of our services, constitute the complete agreement between the parties regarding the services.</p>
              <h3>XII. Contact Us</h3>
              <p>If you have any questions about these Terms or need further information, please contact us through the contact options provided on our application or official website.</p>
              <p>The right to interpret, modify, and update these Terms belongs to our company, and all legal rights are reserved. Please regularly review these Terms to ensure you are aware of the most current version.</p>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-CN' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title} text-center`}>服务条款</div>
            <div className={`${styles.content} break-all`}>
            <h3>一、总则</h3>
              <p>
                欢迎使用我们的打车平台！在使用我们的服务之前，请仔细阅读以下服务条款（以下简称“条款”）。通过注册成为用户并使用我们的服务，您同意遵守这些条款，这些条款是根据香港特别行政区的法律制定的。
              </p>
              <h3>二、服务描述</h3>
              <p>我们提供一个打车平台，使您能通过我们的移动应用程序或网站预订车辆。此服务包括但不限于即时打车服务、预约服务等。</p>
              <h3>三、用户注册与账户</h3>
              <p>3.1 注册义务 在注册过程中，您必须提供真实、准确、最新和完整的个人信息。如果您的个人信息有任何变更，您必须及时更新。</p>
              <p>3.2 账户安全 您将收到一个用户名和密码以访问您的账户。保护您的账户安全是您的责任。我们建议选择一个强密码并定期更换，不与他人共享您的账户信息。</p>
              <h3>四、使用规范</h3>
              <p>4.1 合法使用 您同意仅出于合法目的使用我们的服务，并遵守所有相关的香港法律和法规。</p>
              <p>4.2 禁止活动 您不得使用我们的服务从事以下活动：</p>
              <ul>
                <li>发送或传输非法、骚扰性、诽谤性、侵犯隐私或其他不当的消息。</li>
                <li>侵犯他人的知识产权或其他权利。</li>
                <li>损害、干扰我们服务的运作或窃取用户数据。</li>
                <li>从事任何可能威胁我们系统安全的活动。</li>
              </ul>
              <h3>五、费用和支付</h3>
              <p>您在使用我们的打车服务时将产生费用，我们将通过应用程序或网站在服务前提供。您同意支付所示的服务费用。支付方式可能包括信用卡、借记卡、电子钱包或其他支持的支付方法。</p>
              <h3>六、免责声明</h3>
              <p>我们努力确保我们的服务的可靠性和安全性，但不保证服务将不间断或无错误。除非这些损失是由我们的故意行为或重大过失造成的，我们不对使用我们的服务可能导致的任何形式的损失或损害承担责任。</p>
              <h3>七、修改和终止</h3>
              <p>我们保留随时修改或终止服务的权利。在此类变更发生时，我们将在应用程序或网站上发布修改内容。如果您不同意这些更改，您有权停止使用我们的服务并取消您的账户。</p>
              <h3>八、隐私政策</h3>
              <p>您的隐私对我们至关重要。我们将根据我们的隐私政策收集、使用、保护和共享您的个人信息，该政策详细描述了这些实践。通过使用我们的服务，您同意此隐私政策的条款。</p>
              <h3>九、知识产权</h3>
              <p>9.1 版权 本平台上的所有内容，包括但不限于文本、图像、界面设计、商标和软件，均由我们或相应权利持有者拥有，并受香港特别行政区法律和国际版权法的保护。</p>
              <p>9.2 许可限制 未经我们或相应权利持有者的明确书面许可，您不得复制、修改、出租、出借、销售、分发或从我们平台上的任何内容创建衍生作品或以其他方式使用。</p>
              <h3>十、争议解决</h3>
              <p>这些条款的解释和应用应受香港特别行政区法律的管辖。任何因这些条款或服务引起的争议应首先通过友好协商解决。如果协商失败，争议应提交至香港国际仲裁中心，按照其规则进行仲裁。</p>
              <h3>十一、杂项</h3>
              <p>11.1 不可抗力 对于因不可抗力事件导致的服务中断或延迟，我们不承担责任，这些事件包括但不限于自然灾害、战争、政府行动、全球大流行、公共网络故障等。</p>
              <p>11.2 可分割性 如果这些条款的任何部分被发现无效或不可执行，应将该部分省略，不影响其余条款的有效性和可执行性。</p>
              <p>11.3 转让 未经我们事先书面同意，您不得转让这些条款下的任何权利或义务。</p>
              <p>11.4 完整协议 这些条款与隐私政策以及您通过使用我们的服务同意的任何其他法律文件，构成双方关于服务的完整协议。</p>
              <h3>十二、联系我们</h3>
              <p>如果您对这些条款有任何疑问或需要更多信息，请通过我们应用程序或官方网站提供的联系方式与我们联系。</p>
              <p>解释、修改和更新这些条款的权利属于我们公司，所有法律权利保留。请定期查看这些条款，确保您了解最新版本。</p>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-HK' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title} text-center`}>服務條款</div>
            <div className={`${styles.content} break-all`}>
            <h3>一、總則</h3>
              <p>
                歡迎使用我們的叫車平台！ 在使用我們的服務之前，請仔細閱讀以下服務條款（以下簡稱「條款」）。 透過註冊成為使用者並使用我們的服務，您同意遵守這些條款，這些條款是根據香港特別行政區的法律制定的。
              </p>
              <h3>二、服務描述</h3>
              <p>我們提供一個打車平台，讓您可以透過我們的行動應用程式或網站預訂車輛。 此服務包括但不限於即時叫車服務、預約服務等。</p>
              <h3>三、用戶註冊與帳戶</h3>
              <p>3.1 註冊義務 在註冊過程中，您必須提供真實、準確、最新和完整的個人資訊。 如果您的個人資訊有任何變更，您必須及時更新。</p>
              <p>3.2 帳戶安全 您將收到一個使用者名稱和密碼以存取您的帳戶。 保護您的帳戶安全是您的責任。 我們建議選擇一個強密碼並定期更換，不與他人分享您的帳戶資訊。</p>
              <h3>四、使用規範</h3>
              <p>4.1 合法使用 您同意僅出於合法目的使用我們的服務，並遵守所有相關的香港法律和法規。</p>
              <p>4.2 禁止活動 您不得使用我們的服務從事以下活動：</p>
              <ul>
                <li>發送或傳輸非法、騷擾性、誹謗性、侵犯隱私或其他不當的訊息。</li>
                <li>侵害他人的智慧財產權或其他權利。</li>
                <li>損害、幹擾我們服務的運作或竊取使用者資料。</li>
                <li>從事任何可能威脅我們系統安全的活動。</li>
              </ul>
              <h3>五、費用和支付</h3>
              <p>您在使用我們的叫車服務時將產生費用，我們將透過應用程式或網站在服務前提供。 您同意支付所示的服務費用。 付款方式可能包括信用卡、金融卡、電子錢包或其他支援的付款方法。</p>
              <h3>六、免責聲明</h3>
              <p>我們努力確保我們的服務的可靠性和安全性，但不保證服務將不間斷或沒有錯誤。 除非這些損失是由我們的故意行為或重大過失造成的，我們不對使用我們的服務可能導致的任何形式的損失或損害承擔責任。</p>
              <h3>七、修改和終止</h3>
              <p>我們保留隨時修改或終止服務的權利。 在此類變更發生時，我們將在應用程式或網站上發布修改內容。 如果您不同意這些更改，您有權停止使用我們的服務並取消您的帳戶。</p>
              <h3>八、隱私權政策</h3>
              <p>您的隱私對我們至關重要。 我們將根據我們的隱私權政策收集、使用、保護和分享您的個人信息，該政策詳細描述了這些實踐。 使用我們的服務，即表示您同意此隱私權政策的條款。</p>
              <h3>九、智慧財產權</h3>
              <p>9.1 版權 本平台上的所有內容，包括但不限於文字、圖像、介面設計、商標和軟體，均由我們或相應權利持有者擁有，並受香港特別行政區法律和國際版權法的保護。</p>
              <p>9.2 許可限制 未經我們或相應權利持有者的明確書面許可，您不得複製、修改、出租、出借、銷售、散佈或從我們平台上的任何內容創建衍生作品或以其他方式使用。</p>
              <h3>十、爭議解決</h3>
              <p>這些條款的解釋和應用應受香港特別行政區法律的管轄。 任何因這些條款或服務引起的爭議應首先透過友好協商解決。 若協商失敗，爭議應提交至香港國際仲裁中心，依其規則進行仲裁。</p>
              <h3>十一、雜項</h3>
              <p>11.1 不可抗力 對於因不可抗力事件導致的服務中斷或延遲，我們不承擔責任，這些事件包括但不限於自然災害、戰爭、政府行動、全球大流行、公共網路故障等。</p>
              <p>11.2 可分割性 如果這些條款的任何部分被發現無效或不可執行，應將該部分省略，不影響其餘條款的有效性和可執行性。</p>
              <p>11.3 轉讓 未經我們事先書面同意，您不得轉讓本條款下的任何權利或義務。</p>
              <p>11.4 完整協議 這些條款與隱私權政策以及您透過使用我們的服務同意的任何其他法律文件，構成雙方關於服務的完整協議。</p>
              <h3>十二、聯絡我們</h3>
              <p>如果您對這些條款有任何疑問或需要更多信息，請透過我們應用程式或官方網站提供的聯絡資訊與我們聯絡。</p>
              <p>解釋、修改和更新這些條款的權利屬於我們公司，所有法律權利保留。 請定期查看這些條款，確保您了解最新版本。</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TermsAndCondition;
