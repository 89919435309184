import { useEffect, useState, useMemo } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { IAreaCodes } from '@/types/common-types';
import { useTranslation } from 'react-i18next';
import { TableRecord } from '@/components/table/interface';
import Config from '@/libs/config';
import { findNode, getCookie } from '@/utils';
import { Constants } from '@/constants';
import api from '@/libs/api';
import AntdModal from '@/components/common/antd/modal';
import CloseIcon from '@/components/svg/close-icon';
import SearchIcon from '@/pages/payouts/payouts-icons/search-icon';
import CleanIcon from '@/components/svg/clean-icon';
import Table from '@/components/table';
import Button from '@/components/common/button';
import { useParams } from 'react-router-dom';

interface IPayeesProps {
  businessID: string;
  areaCodes: IAreaCodes[];
  setShowModal: (visible: boolean) => void;
  onChange: (bank: any) => void;
  bank?: any;
}

const Banks: React.FC<IPayeesProps> = (props) => {
  const { businessID, setShowModal, onChange, areaCodes, bank } = props;
  const { t } = useTranslation('payees');
  const { lang = 'en' } = useParams();
  const [records, setRecords] = useState<TableRecord[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [selectRecord, setSelectRecord] = useState<TableRecord | null>(bank);

  const prefix = Config.staticPath || '';

  useEffect(() => {
    loadingBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessID]);

  const columns: ColumnsType<TableRecord> = useMemo(() => {
    return [
      {
        key: 'bank_code',
        title: t('bank_code'),
        dataIndex: 'bank_code',
        width: 200,
      },
      {
        key: 'bank_name',
        title: t('bank_name'),
        dataIndex: 'bank_name',
        width: 450,
      },
      {
        key: 'country',
        title: t('country'),
        dataIndex: 'country',
        width: 200,
        render: (_key, record) => {
          const { country } = record;
          const item = findNode<IAreaCodes>(
            areaCodes,
            'isoAlpha2Code',
            country
          );
          const { translations, name } = item || {};

          let countryName = name || '';
          if (Array.isArray(translations)) {
            for (let i = 0; i < translations.length; i++) {
              const item = translations[i];
              if (lang === item.languages_code) {
                countryName = item.country_name;
                break;
              }
            }
          }
          return (
            <div className="flex">
              <span className="flex items-center relative w-4 mr-1.5">
                <img
                  width="16px"
                  height="16px"
                  src={`${prefix}/images/country-flag/${country.toLocaleLowerCase()}.svg`}
                  alt=""
                />
              </span>
              <span className="text-color-text-1">{countryName}</span>
            </div>
          );
        },
      },
    ];
    // eslint-disable-next-line
  }, []);

  const loadingBanks = async (page = 1, pageSize = 30, keyword = '') => {
    setLoading(true);
    const token = getCookie(Constants.TOKEN) || '';
    let records: any[] = [];
    const offset = (page - 1) * pageSize;
    const url = `${
      Config.urls.gatewayUrl
    }/api/treasury-services/api/banks?offset=${offset}&limit=${
      pageSize + 1
    }&search_key=${keyword}`;
    try {
      const result = await api.get(url, {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'x-i18n-lang': lang,
          'x-client-id': Config.clientID,
        },
      });
      const { data } = result || {};
      if (data && Array.isArray(data)) {
        records = data;
      }
    } catch (error) {
      console.error(error);
    }
    setPage(page);
    setPageSize(pageSize);
    setRecords(records);
    setLoading(false);
  };

  const handleConfirm = () => {
    if (selectRecord && selectRecord.id) {
      onChange(selectRecord);
      setShowModal(false);
    }
  };

  const handleChange = (value: string) => {
    setSearchValue(value);
    loadingBanks(1, pageSize, value);
  };

  const handleSelect = (data: TableRecord) => {
    setSelectRecord(data);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <AntdModal
      open={true}
      footer={null}
      size="large"
      closeIcon={false}
      closable={false}
    >
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-center p-6">
          <div className=" text-lg font-semibold text-color-text-1">
            {t('banks')}
          </div>
          <div
            className="text-color-text-4 hover:text-color-text-5 cursor-pointer"
            onClick={handleCancel}
          >
            <CloseIcon
              className="bg-primary-background-color rounded-full hover:text-primary-color"
              size={20}
            />
          </div>
        </div>
        <div className="border-border-color border-0 border-b border-solid"></div>
        <div className="px-6 flex-1 overflow-hidden flex flex-col">
          <div className="pt-6 pb-4">
            <div className="flex-grow group col-span-2">
              <div className="group rounded flex items-center h-10 px-3 py-2 cursor-auto border hover:border-primary-color border-border-color">
                <span>
                  <SearchIcon size={15} />
                </span>
                <input
                  placeholder="Search"
                  value={searchValue}
                  className="focus:outline-none flex-grow font-medium text-color-text-2 bg-inherit placeholder:text-color-text-5 placeholder:font-normal placeholder:text-sm text-base px-2 text-ellipsis"
                  autoComplete="none"
                  onChange={(evt) => {
                    handleChange(evt.target.value);
                  }}
                />
                <span className="invisible group-hover:visible">
                  <CleanIcon
                    size={20}
                    className="text-color-text-5 hover:text-color-text-3"
                    onClick={() => {
                      handleChange('');
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            records={records}
            loadingData={loading}
            pagination={{
              className: 'my-4',
              current: page,
              currentSize: records.length,
              pageSize,
              pageSizeOptions: [30, 60, 120],
              changePageCallback: (page, pageSize) =>
                loadingBanks(page, pageSize, searchValue),
            }}
            rowSelection={{
              type: 'checkbox',
              hideSelectAll: true,
              onSelect: handleSelect,
              selectedRowKeys: selectRecord ? [selectRecord.id] : undefined,
            }}
            onRowCell={(record, _dataKey) => {
              return {
                onClick: () => {
                  handleSelect(record);
                },
              };
            }}
          ></Table>
        </div>
        <div className="border-border-color border-0 border-b border-solid"></div>
        <div className="mt-4 pb-6 px-6 flex justify-end">
          <Button
            disabled={!selectRecord}
            className={`w-36 ${
              selectRecord ? '' : 'bg-primary-color/50 text-white'
            }`}
            onClick={handleConfirm}
          >
            {t('select')}
          </Button>
        </div>
      </div>
    </AntdModal>
  );
};

export default Banks;
