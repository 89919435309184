import React from 'react';
import type { IIconProps } from '@/types/common-types';

const PreviewIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 16 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        fill="none"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="40"
          height="40"
          rx="20"
          fill="currentColor"
          fillOpacity="0.1"
        />
        <path
          d="M19.9987 21.6693C20.9192 21.6693 21.6654 20.9231 21.6654 20.0026C21.6654 19.0821 20.9192 18.3359 19.9987 18.3359C19.0782 18.3359 18.332 19.0821 18.332 20.0026C18.332 20.9231 19.0782 21.6693 19.9987 21.6693Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.3346 19.9974C26.1121 23.8866 23.3346 25.8307 20.0013 25.8307C16.668 25.8307 13.8905 23.8866 11.668 19.9974C13.8905 16.1082 16.668 14.1641 20.0013 14.1641C23.3346 14.1641 26.1121 16.1082 28.3346 19.9974Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default PreviewIcon;
