import React from 'react';
import type { IIconProps } from '@/types/common-types';

const UpArrowDashedIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="8"
        height="20"
        viewBox="0 0 8 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 1L4 7M4 1L1 4M4 1L7 4M4 19L4 17M4 13L4 11"
          stroke="#0094FF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default UpArrowDashedIcon;
