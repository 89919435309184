
import { trim } from 'lodash';
import { transformField } from './field-utils';

export const handleName = (name: string) => {
  let value = name;

  if (name === 'Fps') {
    value = name.toLocaleUpperCase();
  } else if (name === 'Cup') {
    value = 'Unionpay';
  }

  return value;
};

export const getXAndYStyle = () => {
  return {
    fontWeight: '300',
    color: '#5E6D83',
    fontSize: '14px',
  };
};

export const getName = (value: string) => {
  let rValue = value;
  if (trim(value).indexOf('_') > -1) {
    rValue = transformField(value);
  } else {
    rValue = transformField(value, ' ');
  }

  return rValue;
};

export const getColorByType = (type: string) => {
  let color = '';
  switch (type.toLocaleLowerCase()) {
    case 'void':
      color = '#A3A4AA';
      break;
    case 'rolling reserve':
    case 'rolling_reserve':
      color = '#B50583';
      break;
    case 'fees charged':
    case 'fees_charged':
      color = '#FF17A2';
      break;
    case 'adjustment amount':
    case 'adjustment_amount':
      color = '#2C6FC9';
      break;
    case 'hold':
      color = '#CE0310';
      break;
    case 'on_hold':
      color = '#CE0310';
      break;
    case 'refund':
      color = '#FC2E01';
      break;
    case 'sale':
    case 'representment':
      color = '#0094FF';
      break;
    case 'pre_auth':
      color = '#2C6FC9';
      break;
    case 'pre_auth_complete':
      color = '#BD528A';
      break;
    case 'tips':
      color = '#44D600';
      break;
    case 'pre_dispute':
    case 'dispute':
      color = '#6780DA';
      break;

    default:
      break;
  }

  return color;
};

export const getColorByPayinType = (type: string) => {
  let color = '';

  switch (type) {
    case 'Merchant Services':
      color = '#FF9901';
      break;
    case 'Add Fund':
      color = '#0094FF';
      break;
    case 'Fund Reversal':
      color = '#0059B2';
      break;
    case 'Inbound Transfer':
      color = '#44D600';
      break;
    case 'Rolling Reserve Released':
      color = '#A3A4AA';
      break;

    default:
      break;
  }

  return color;
};

export const getColorByTransactionMethods = (type: string) => {
  let color = '';

  switch (type) {
    case 'FPS - ID':
      color = '#003F7F';
      break;
    case 'HK FPS - Account Number':
      color = '#0072E5';
      break;
    case 'Alipay - Phone Number':
      color = '#0094FF';
      break;
    case 'HK FPS - Email':
      color = '#80BFFF';
      break;
    case 'SWIFT':
      color = '#FFC328';
      break;
    case 'RTGS':
      color = '#61DDAA';
      break;
    case 'ACT':
      color = '#F96AD9';
      break;
    case 'Platform Transfer':
      color = '#0000000';
      break;
    case 'Alipay':
      color = '#A3A4AA';
      break;
    default:
      break;
  }

  return color;
};

const originColors = [
  '#0094FF',
  '#1D42C2',
  '#61DDAA',
  '#F6BD16',
  '#008685',
  '#F08BB4',
  '#7262FD',
  '#E19348',
  '#8C8C47',
  '#65789B',
  '#255634',
  '#78D3F8',
  '#9661BC',
  '#9FB40F',
  '#F6903D',
  '#BBE800',
  '#D64BC0',
  '#8E283B',
  '#791DC9',
];

export const getColorByPayinPayer = (index: number) => {
  return (
    originColors[index] ||
    originColors[Math.floor(Math.random() * originColors.length)]
  );
};

export const getColorByPayinStatus = (status: string) => {
  let color = '';

  switch (status) {
    case 'Initiated':
      color = '#FFA217';
      break;
    case 'Settled':
      color = '#4CD964';
      break;
    case 'Reversed':
      color = '#828282';
      break;
    case 'Suspended':
      color = '#FF2E00';
      break;

    default:
      break;
  }

  return color;
};
