import type { IObject } from '@/types/common-types';
import type { TableRecord } from '@/components/table/interface';
import { useEffect, useState } from 'react';
import Table from '@/components/table';
import { Dropdown } from 'antd';
import Button from '@/components/common/button';
import { Constants } from '@/constants';
import Menu, { MenuItem } from 'rc-menu';
import { Alert } from '@/components/common/alert/alert';
import { getCookie } from '@/utils/index';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import Skeleton from '@/components/skeleton';
import ModalEmpty from '@/components/common/modal/modal-empty';
import WarningIcon from '@/components/svg/warning-icon';
import EllipsisIcon from '@/components/svg/ellipsis-icon';
import AddIcon from '@/components/svg/add-icon';
import PendingModal from './pending-modal';
import { getPayeeList, payeeRemove } from './server';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IReducersState } from '@/reducers';
import { getAvailableFeatures } from '@/utils/common';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';

const RelatedAccountListView = () => {
  const permissions = useSelector((state: IReducersState) => state.businessState.permissions);

  const {
    canUpdate,
    canCreate,
    canDelete,
  } = getAvailableFeatures(permissions);

  const { t } = useTranslation(["settings", "business_account_settings", "account_balance", "related_company_accounts"]);
  const { lang = 'en', p_business_id: businessID = '' } = useParams();
  const token = getCookie(Constants.TOKEN || '') ?? '';
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryPending = searchParams.get('pending');
  const [removeModal, setRemoveModal] = useState(false);
  const [failedModal, setFailedModal] = useState(false);
  const [recordLoading, setRecordLoading] = useState<boolean>(true);
  const [records, setRecords] = useState<TableRecord[]>([]);
  const [currentData, setCurrentData] = useState<TableRecord>({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [columns, setColums] = useState<ColumnsType<TableRecord>>([]);
  const [pendingModal, setPendingModal] = useState(queryPending != null);
  const accountNumber = (number: string) => {
    if (number.trim().length < 3) {
      return `*** ****** ${number}`;
    }
    return `*** ****** ${number.substring(number.length - 3)}`;
  };

  useEffect(() => {
    setColums(getColumns());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    loadRecords();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('settings:settings'),
          key: '1',
          type: 'label',
        },
        {
          name: t('related_company_accounts:related_company'),
          key: '2',
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  const loadRecords = async (page = 1, pageSize = 50) => {
    setRecordLoading(true);
    const offset = (page - 1) * pageSize;

    let records: any[] = [];
    records = await getPayeeList(businessID, lang, token, pageSize + 1, offset);

    setPage(page);
    setPageSize(pageSize);
    setRecords(records);
    setRecordLoading(false);
  };

  const getCurrencyIcon = (
    currency: string,
    currencyName?: string | undefined
  ) => {
    const icon = currency.length >= 3 ? currency.substring(0, 2) : currency;

    return (
      <div className="flex rounded h-10 items-center">
        <img
          // layout="intrinsic"
          width="16px"
          height="16px"
          src={`/images/country-flag/${icon.toLowerCase()}.svg`}
          alt=""
        />
        <div className="text-sm text-color-text-1 ml-1 flex items-center font-medium">
          {currencyName ? currencyName : currency ?? ''}
        </div>
      </div>
    );
  };

  const getLang = () => {
    let langCode: string = lang;
    if (lang === 'en') {
      langCode = 'en-US';
    }
    return langCode;
  };

  const getColumns = () => {
    const columns: ColumnsType<TableRecord> = [
      {
        key: 'account_nickname',
        title: t('related_company_accounts:account_nickname'),
        width: 160,
        render: (key, record) => {
          return record.nickname;
        },
      },
      {
        key: 'country',
        title: t('related_company_accounts:country'),
        align: 'left',
        render: (key, record) => {
          const { payout_accounts, i18n } = record;
          const { country_codes } = payout_accounts;
          const { alpha_2, country_name } = country_codes;
          const name = i18n[country_name][getLang()];
          return getCurrencyIcon(alpha_2, name || country_name);
        },
      },
      {
        key: 'currency',
        title: t('related_company_accounts:currency'),
        align: 'left',
        width: 110,
        render: (key, record) => {
          const { payout_accounts } = record;
          const { currency } = payout_accounts;
          return getCurrencyIcon(currency);
        },
      },
      {
        key: 'bank_code_name',
        title: t('related_company_accounts:bank'),
        render: (key, record) => {
          const { payout_accounts } = record;
          const { banks } = payout_accounts;
          const { bank_code = '', bank_name = '' } = banks || {};
          let bankCode = bank_code || '';
          if (bank_name != null) {
            bankCode = bankCode + ' ' + bank_name;
          }
          return bankCode || '--';
        },
      },
      {
        key: 'account_number',
        title: t('related_company_accounts:account_number'),
        width: 150,
        render: (key: string, record) => {
          const { payout_accounts } = record;
          return accountNumber(payout_accounts?.identifier_3_value ?? '');
        },
      },
      {
        key: 'status',
        title: t('related_company_accounts:status'),
        align: 'left',
        // align: 'right',
        render: (key: string, record) => {
          const { payout_accounts } = record;
          const { verification_method, verified_at } = payout_accounts;
          const verificationSucess = !(
            verification_method != null && verified_at == null
          );
          let className = 'text-warning-color bg-warning-color';
          let status = t('related_company_accounts:unverified');
          if (!verificationSucess) {
            className = 'text-error-color bg-error-color';
            status = t('related_company_accounts:failed_verification');
          } else if (verification_method != null && verified_at != null) {
            className = 'text-primary-color bg-primary-color ';
            status = t('related_company_accounts:verified');
          }
          return (
            <span
              className={`px-3 py-1.5 rounded-3xl text-xs font-medium whitespace-nowrap  bg-opacity-10 ${className}`}
            >
              {status?.toLocaleUpperCase()}
            </span>
          );
        },
      },
      {
        key: 'created_at',
        align: 'right',
        title: t('related_company_accounts:created_at'),
        width: 130,
        render: (key, record) => {
          const { payout_accounts } = record;
          const { p_created_at } = payout_accounts;
          return (
            <>
              <div className="whitespace-nowrap">
                {p_created_at ? dayjs(p_created_at).format('MMM D, YYYY') : ''}
              </div>
              <div>
                {p_created_at ? dayjs(p_created_at).format('HH:mm:ss') : ''}
              </div>
            </>
          );
        },
      },
      {
        title: t('common:actions'),
        key: 'action',
        fixed: 'right',
        align: 'center',
        width: 90,
        render: (key, record) => {
          const { payout_accounts } = record;
          const { verification_method, verified_at } = payout_accounts;
          return (
            <Dropdown
              destroyPopupOnHide={true}
              dropdownRender={() => (
                <Menu className="bg-white">
                  <MenuItem key="view">
                    <div
                      className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                      onClick={() => {
                        hadleView(record);
                      }}
                    >
                      <span className="text-color-text-1 text-sm font-medium">
                        {t('common:view')}
                      </span>
                    </div>
                  </MenuItem>
                  {canUpdate &&
                    !(verification_method != null && verified_at != null) && (
                      <MenuItem key="edit">
                        <div
                          className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                          onClick={() => {
                            handleEdit(record);
                          }}
                        >
                          <span className="text-color-text-1 text-sm font-medium">
                            {t('related_company_accounts:edit_bank')}
                          </span>
                        </div>
                      </MenuItem>
                    )}
                  {canDelete && (
                    <MenuItem key="delete">
                      <div
                        className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                        onClick={() => {
                          handleRemoveModel(record);
                        }}
                      >
                        <span className="text-error-color text-sm font-medium">
                          {t('related_company_accounts:remove_bank')}
                        </span>
                      </div>
                    </MenuItem>
                  )}
                </Menu>
              )}
              overlayClassName="shadow-card-drop border border-border-color bg-[white]"
            >
              <div className="flex justify-center">
                <div className="flex items-center cursor-pointer p-1.5 rounded-full w-max hover:bg-primary-color/10 group">
                  <EllipsisIcon className="text-icon-color group-hover:text-primary-color" />
                </div>
              </div>
            </Dropdown>
          );
        },
      },
    ];
    return columns;
  };

  const handleRemoveModel = (record: IObject) => {
    setCurrentData(record);
    setRemoveModal(true);
  };
  const handleRemove = async () => {
    setRemoveModal(false);
    const result = await payeeRemove(businessID, lang, token, currentData.id);
    const { success, message } = result || {};

    if (success) {
      Alert.success({
        message: t('related_company_accounts:selected_account_removed'),
        position: 'default',
        wrapClass: 'top-px',
      });
      await loadRecords();
    } else {
      Alert.error({
        message: message || t('related_company_accounts:creation_failed'),
        position: 'default',
        wrapClass: 'top-px',
      });
    }
  };

  const hadleView = (record: any) => {
    // TODO：暂时隐藏查看
    const { payout_accounts } = record;
    const { verification_method, verified_at } = payout_accounts;
    const verificationFailed =
      verification_method != null && verified_at == null;
    setCurrentData(record);
    if (verificationFailed) {
      setFailedModal(true);
    } else {
      handleViewRecord(record);
    }
  };

  const handleEdit = (record: any) => {
    navigate(
      `/businesses/${businessID}/settings/related-company-accounts/${record.id}?edit=true`
    );
  };

  const handleNew = () => {
    navigate(
      `/businesses/${businessID}/settings/related-company-accounts/new`
    );
  };

  const handleViewRecord = (record: any) => {
    let id = record.id;
    if (id == null) {
      id = currentData.id;
    }
    navigate(
      `/businesses/${businessID}/settings/related-company-accounts/${id}`
    );
  };

  const handleFailedView = () => {
    setFailedModal(true);
  };

  const handleFailedCancel = () => {
    setFailedModal(false);
  };

  function getFailedCodeMsg(current: TableRecord) {
    const { payout_accounts } = current;
    const { verification_error_code = '', verification_error_message = '' } =
      payout_accounts || {};
    let msg = verification_error_code;
    if (verification_error_message?.trim().length > 0) {
      msg = msg + msg.length > 0 ? '\n' : '' + verification_error_message;
    }
    return msg;
  }

  const getFailedTips = () => {
    const script = t('related_company_accounts:failed_verification_tip');
    const text = script.split('{{email}}');
    return (
      <div className="text-center leading-snug whitespace-pre-line mt-2 text-sm font-medium">
        {text[0]}
        {
          <label className="font-bold text-primary-color">
            {'support@wonder.app'}
          </label>
        }
        {text.length > 1 ? text[1] : ''}
        <div className="text-error-color">{getFailedCodeMsg(currentData)}</div>
      </div>
    );
  };

  const handleDone = () => {
    setPendingModal(false);
    navigate(`/businesses/${businessID}/settings/related-company-accounts`);
  };
  return (
    <>
      <div className="flex overflow-hidden h-full">
        <div className="bg-system-background-color pt-5 px-3 pb-2.5 md:px-5 md:py-6 lg:px-7 w-full flex flex-col">
          <div className="md:flex mb-4 justify-between px-1 md:mb-6">
            {recordLoading ? (
              <div className="h-10 w-full">
                <Skeleton
                  row={1}
                  className={`mr-0`}
                  width={['50%']}
                  skeletonItemClass={`h-10 rounded-2xl`}
                />
              </div>
            ) : (
              <div className="text-xl leading-[30px] md:text-3xl md:leading-10 font-bold text-color-text-1 mb-4 md:mb-0">
                {t('related_company_accounts:related_company')}
              </div>
            )}
            {canCreate && (
              <div className="flex space-x-4">
                {recordLoading ? (
                  <>
                    <Skeleton
                      row={1}
                      className={`mr-0`}
                      width={['120px']}
                      skeletonItemClass={`h-10 rounded-2xl`}
                    />
                    <Skeleton
                      row={1}
                      className={`h-10`}
                      width={['120px']}
                      skeletonItemClass={`h-10 rounded-2xl`}
                    />
                  </>
                ) : (
                  <>
                    {/*
                  TODO：暂时不做筛选
                    <Filter
                    fields={[]}
                    onFilter={loadRecords}
                    searchValue={searchValue}
                    options={filterOptions}
                  /> */}
                    <Button
                      iconInstance={<AddIcon />}
                      onClick={() => handleNew()}
                    >
                      {t('related_company_accounts:add_new')}
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="flex-1 flex flex-col overflow-hidden">
            <Table
              columns={columns}
              records={records}
              loadingData={recordLoading}
              className="mb-6"
              pagination={{
                current: page,
                currentSize: records.length,
                pageSize,
                changePageCallback: loadRecords,
              }}
              emptyTitle={t('related_company_accounts:empty_title')}
              emptyMessage={t('related_company_accounts:empty_message')}
              onRowCell={(record, dataKey) => {
                return {
                  onClick: (evt) => {
                    evt.stopPropagation();
                    if (dataKey === 'action') return;
                    if (record.id) {
                      hadleView(record);
                    }
                  },
                };
              }}
            />
          </div>
        </div>
      </div>
      <ModalEmpty
        visible={failedModal}
        onCancel={handleFailedView}
        icon={<div></div>}
      >
        <div className="text-center">
          <div className="bg-warning-color/10 p-5 rounded-full  w-20 h-20 justify-center flex mb-4 m-auto">
            <WarningIcon className="text-warning-color" size={40} />
          </div>
          <div className="text-xl font-bold">
            {t('related_company_accounts:failed_account_ver')}
          </div>
          {getFailedTips()}
          <Button onClick={handleViewRecord} className="mt-6 w-full">
            {t('related_company_accounts:view_record')}
          </Button>
          <Button
            onClick={handleFailedCancel}
            styleType="Border"
            className="mt-4 w-full"
          >
            {t('common:back')}
          </Button>
        </div>
      </ModalEmpty>
      <ModalEmpty
        visible={removeModal}
        onCancel={() => setRemoveModal(false)}
        icon={<></>}
      >
        <div className="text-center">
          <div className="bg-warning-color/10 p-5 rounded-full  w-20 h-20 justify-center flex mb-4 m-auto">
            <WarningIcon className="text-warning-color" size={40} />
          </div>
          <div className="text-xl font-bold">
            {currentData.is_default_bank
              ? t('related_company_accounts:unable_remove')
              : t('related_company_accounts:remove_bank')}
          </div>
          <div className="mt-2 text-sm font-medium">
            {currentData.is_default_bank
              ? t('related_company_accounts:unable_remove_tips')
              : t('related_company_accounts:account_removed')}
          </div>
          {currentData.is_default_bank ? (
            <>
              <Button
                onClick={() => setRemoveModal(false)}
                className="mt-6 w-full"
                styleType="Primary"
              >
                {t('common:back')}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleRemove} className="mt-6 w-full">
                {t('common:confirm')}
              </Button>
              <Button
                onClick={() => setRemoveModal(false)}
                styleType="Border"
                className="mt-4 w-full"
              >
                {t('common:cancel')}
              </Button>
            </>
          )}
        </div>
      </ModalEmpty>
      <PendingModal
        visible={pendingModal}
        onCancel={() => handleDone()}
        icon={<div></div>}
      ></PendingModal>
    </>
  );
};

export default RelatedAccountListView;
