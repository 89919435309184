import React from 'react';
import type { IIconProps } from '@/types/common-types';

const DefaultItemIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="25"
        height="26"
        viewBox="0 0 25 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.26562 23.8147C1.26562 24.5728 1.8802 25.1873 2.63831 25.1873H22.7711C23.5292 25.1873 24.1438 24.5728 24.1438 23.8147V5.96973H1.26562V23.8147Z"
          fill="#C9C9CA"
          fillOpacity="0.04"
          stroke="#C9C9CA"
          strokeWidth="0.686344"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.59378 1.64979C4.94028 1.20003 5.47589 0.936538 6.04365 0.936538H19.3657C19.9335 0.936538 20.4691 1.20003 20.8156 1.64979L24.1438 5.96973H1.26562L4.59378 1.64979Z"
          fill="#C9C9CA"
          fillOpacity="0.04"
          stroke="#C9C9CA"
          strokeWidth="0.686344"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.27148 15.4721C9.27148 15.7836 9.52398 16.0361 9.83545 16.0361H15.571C15.8824 16.0361 16.1349 15.7836 16.1349 15.4721V5.96973H9.27148V15.4721Z"
          stroke="#C9C9CA"
          strokeWidth="0.686344"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8832 0.936539H14.5232L16.1349 5.96973H9.27148L10.8832 0.936539Z"
          stroke="#C9C9CA"
          strokeWidth="0.686344"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.5"
          d="M18.6543 18.7812L21.8572 18.7812"
          stroke="#C9C9CA"
          strokeWidth="0.457563"
          strokeLinecap="round"
        />
        <path
          opacity="0.5"
          d="M18.6543 20.6123H21.8572"
          stroke="#C9C9CA"
          strokeWidth="0.457563"
          strokeLinecap="round"
        />
        <path
          opacity="0.5"
          d="M16.8203 22.4424L21.8535 22.4424"
          stroke="#C9C9CA"
          strokeWidth="0.457563"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default DefaultItemIcon;
