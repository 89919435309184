import Config from '@/libs/config';
import PayeeItem from '@/pages/payouts/components/payee-item';
import { IPayee } from '@/types/payouts-type';
import { getCountryByCurrency, getIdentifierValue, getValueMultilingual } from '@/utils/payouts-utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface IPayoutInformationProps {
  payee: IPayee;
}

const PayoutInformation: React.FC<IPayoutInformationProps> = (props) => {
  const { payee } = props;
  const { t } = useTranslation('payees');
  const { lang = 'en'} = useParams();

  const { payout_accounts, i18n, categories } = payee;
  const {
    transfer_method,
    transfer_methods,
    country_codes,
    business_name,
    business_legal_name,
    currency,
  } = payout_accounts;
  const { category_name } = categories;
  const { alpha_2, country_name } = country_codes;
  const { identifier_1_name, identifier_2_name, identifier_3_name } =
    transfer_methods;

  const prefix = Config.staticPath || '';

  const transferMethodDetail = useMemo(() => {
    if (transfer_method === 'Platform Transfer') {
      return (
        <>
          <PayeeItem
            title={t('business_legal_name')}
            content={business_legal_name || business_name}
          />
          <PayeeItem
            title={t('business_account_name')}
            content={business_name || business_legal_name}
          />
        </>
      );
    }

    return (
      <>
        {identifier_1_name && (
          <PayeeItem
            title={getValueMultilingual(identifier_1_name, i18n, lang)}
            content={getIdentifierValue('identifier_1_type', payee)}
          />
        )}
        {identifier_2_name && (
          <PayeeItem
            title={getValueMultilingual(identifier_2_name, i18n, lang)}
            content={getIdentifierValue('identifier_2_type', payee)}
          />
        )}
        {identifier_3_name && (
          <PayeeItem
            className={`${
              transfer_method.indexOf('Alipay') < 0 ? '' : 'col-span-2'
            }`}
            title={getValueMultilingual(identifier_3_name, i18n, lang)}
            content={getIdentifierValue('identifier_3_type', payee)}
          />
        )}
      </>
    );
    // eslint-disable-next-line
  }, [lang, payee]);

  return (
    <div className="p-6 bg-primary-background-color rounded shadow-card-drop mb-6 mx-3">
      <div className="font-bold text-xl text-color-text-1 leading-[26px]">
        {t('payout_information')}
      </div>
      <div className="border-b border-border-color mb-6 mt-4"></div>
      <div className="grid grid-cols-4 gap-6">
        <PayeeItem
          title={t('destination_country')}
          content={
            <div className="flex text-sm">
              <div className="flex items-center relative w-4 mr-1.5">
                <img
                  width="16px"
                  height="16px"
                  src={`${prefix}/images/country-flag/${
                    alpha_2 ? alpha_2.toLocaleLowerCase() : 'hk'
                  }.svg`}
                  alt=""
                />
              </div>
              <div>{country_name}</div>
            </div>
          }
        />
        <PayeeItem
          title={t('destination_currency')}
          content={
            <div className="flex">
              <div className="flex items-center relative w-4 mr-1.5">
                <img
                  width="16px"
                  height="16px"
                  src={`${prefix}/images/country-flag/${getCountryByCurrency(
                    currency
                  )}.svg`}
                  alt=""
                />
              </div>
              <div className="text-color-text-1">{currency}</div>
            </div>
          }
        />
        <PayeeItem
          title={t('payout_category')}
          className="col-span-2"
          content={getValueMultilingual(category_name, i18n, lang)}
        />
        <PayeeItem
          title={t('payout_method')}
          content={transfer_method}
        />
        {transferMethodDetail}
      </div>
    </div>
  );
};

export default PayoutInformation;
