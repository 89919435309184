import type { ChangeEvent, TextareaHTMLAttributes } from 'react';
import { useEffect, useState } from 'react';
import type { FieldError } from 'react-hook-form';
import CleanIcon from '@/components/svg/clean-icon';
import type { IObject } from '@/types/common-types';
import { useTranslation } from 'react-i18next';
import MultiLangIcon from '@/components/svg/multi-lang-icon';
import ModalContent from '../modal/modal-content';
import Textarea from '../textarea';
// import Label from '../label';
import Button from '../button';
import TipsIcon from '@/components/svg/tips-icon';
import { Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
export interface TextareaProps
  extends Omit<
    TextareaHTMLAttributes<HTMLElement>,
    'size' | 'onChange' | 'value'
  > {
  disabled?: boolean;
  onChange?: (value: string) => void;
  onChangeMultiLang?: (value: IObject) => void;
  warpClass?: string;
  allowClear?: boolean;
  placeholder?: string;
  maxLength?: number;
  minLength?: number;
  error?: FieldError;
  value?: IObject;
  showMultiLanguage?: boolean;
  title?: string;
}

const MultiLangTextarea: React.FC<TextareaProps> = (props) => {
  const {
    rows = 3,
    disabled = false,
    allowClear = true,
    showMultiLanguage = false,
    warpClass = '',
    // defaultValue = '',
    placeholder = '',
    maxLength,
    minLength,
    onChange,
    error,
    onChangeMultiLang,
    value = {},
    title = '',
    ...restProps
  } = props;
  const [textareaValue, setTextareaValue] = useState<string>('');
  const { t } = useTranslation();
  const { lang = 'en' } = useParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [englishValue, setEnglish] = useState<string>(value['en-US'] || '');
  const [traditionalValue, setTraditional] = useState<string>(
    value['zh-HK'] || ''
  );
  const [simplifiedValue, setSimplified] = useState<string>(
    value['zh-CN'] || ''
  );
  const [newValue, setNewTextareaValue] = useState<IObject>(value);

  useEffect(() => {
    if (value) {
      const value1 = value['en-US'];
      setTextareaValue(value1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const changeValue = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(evt.target.value);
    if (onChangeMultiLang) {
      const oldData = {
        'en-US': englishValue,
        'zh-HK': traditionalValue,
        'zh-CN': simplifiedValue,
      };
      const newData = {
        ...oldData,
        'en-US': evt.target.value,
      };
      onChangeMultiLang(newData);
      setNewTextareaValue(newData);
      setEnglish(evt.target.value);
    }
  };

  const onChangeTraditionalValue = (evt: string) => {
    setTraditional(evt);
    setNewTextareaValue({
      'en-US': englishValue,
      'zh-HK': evt,
      'zh-CN': simplifiedValue,
    });
  };

  const onChangeSimplifiedValue = (evt: string) => {
    setSimplified(evt);
    setNewTextareaValue({
      'en-US': englishValue,
      'zh-HK': traditionalValue,
      'zh-CN': evt,
    });
  };

  const setMultiLang = () => {
    if (onChangeMultiLang) {
      const data: { [key: string]: string } = {
        'en-US': textareaValue,
        'zh-HK': traditionalValue,
        'zh-CN': simplifiedValue,
      };
      onChangeMultiLang(data);
      setShowModal(false);
    }
  };

  const showMultiLanguageIcon = showMultiLanguage && !disabled;

  return (
    <>
      <div
        className={`group flex hover:border-primary-color border px-3 border-color-text-5 rounded bg-transparent ${warpClass} ${disabled ? 'border-none pl-0' : 'shadow-box-drop py-2 '
          }`}
      >
        {!disabled && (
          <textarea
            {...restProps}
            className={`bg-transparent block w-full text-sm outline-none rounded focus:outline-none border-none resize-none text-color-text-1 font-medium hidden_scrollbar`}
            rows={rows}
            disabled={disabled}
            autoComplete="none"
            onChange={changeValue}
            value={disabled && !textareaValue ? '--' : textareaValue}
            placeholder={placeholder}
            maxLength={maxLength}
            minLength={minLength}
          />
        )}
        {disabled && (
          <span className="text-sm text-color-text-1 font-medium py-2">
            {!textareaValue ? '--' : textareaValue}
          </span>
        )}

        <span
          className={`self-center invisible ${allowClear && textareaValue && !disabled
            ? 'group-hover:visible'
            : ''
            }`}
        >
          <CleanIcon
            size={20}
            className="cursor-pointer text-color-text-5 hover:text-color-text-3 self-center"
            onClick={() => {
              setTextareaValue('');
              onChangeMultiLang &&
                onChangeMultiLang({
                  'en-US': '',
                  'zh-HK': traditionalValue,
                  'zh-CN': simplifiedValue,
                });
            }}
          />
        </span>
        <span
          className={`self-center cursor-pointer ${showMultiLanguageIcon ? 'visible' : 'invisible'
            }`}
        >
          <MultiLangIcon
            size={20}
            className="text-icon-color ml-2 hover:text-primary-color"
            onClick={() => setShowModal(true)}
          />
        </span>
      </div>
      {error && (
        <p className="mt-1 text-sm text-error-color">{error.message}</p>
      )}
      <ModalContent
        visible={showModal}
        visibleIcon={false}
        className="px-6 sm:px-6"
        onCancel={() => setShowModal(false)}
      >
        <div className="max-h-[80vh] overflow-y-scroll flex flex-col">
          <div className="font-bold text-color-text-1 text-xl text-center">
            {title}
          </div>
          <div className="font-semibold text-color-text-1 text-lg mt-7">
            {t('business_account_settings:multi_language')}
          </div>
          <div className="w-full mt-6">
            <div
              className={`text-sm flex items-center mb-2 justify-start font-medium ${lang === 'en' ? 'text-color-text-2' : 'text-color-text-1'
                }`}
            >
              <span className="relative mr-2">
                {t('business_account_settings:english')}
              </span>
              <Tooltip
                placement="bottomLeft"
                arrow={{ pointAtCenter: true }}
                className="pl-1 leading-3 cursor-pointer"
                overlayClassName="max-w-[25%]"
                destroyTooltipOnHide={true}
                title={
                  <p className="font-normal text-sm">
                    {t('business_account_settings:modify_english_tips')}
                  </p>
                }
              >
                <span>
                  <TipsIcon size={16} className="text-primary-color" />
                </span>
              </Tooltip>
            </div>
            <Textarea
              className=""
              rows={rows}
              value={textareaValue}
              autoComplete="off"
              disabled={true}
            />
          </div>
          <div className="w-full mt-6">
            <div
              className={`text-sm flex items-center mb-2 justify-start font-medium ${lang === 'zh-HK' ? 'text-color-text-2' : 'text-color-text-1'
                }`}
            >
              <span className="relative mr-2">
                {t('business_account_settings:traditional_chinese')}
              </span>
            </div>

            <Textarea
              className=""
              onChange={(evt) => {
                onChangeTraditionalValue(evt);
              }}
              rows={rows}
              value={newValue['zh-HK']}
              autoComplete="off"
            />
          </div>
          <div className="w-full mt-6">
            <div
              className={`text-sm flex items-center mb-2 justify-start font-medium ${lang === 'zh-CN' ? 'text-color-text-2' : 'text-color-text-1'
                }`}
            >
              <span className="relative mr-2">
                {t('business_account_settings:simplified_chinese')}
              </span>
            </div>
            <Textarea
              className=""
              onChange={(evt) => {
                onChangeSimplifiedValue(evt);
              }}
              rows={rows}
              value={newValue['zh-CN']}
              autoComplete="off"
            />
          </div>

          <div className="w-full mt-6">
            <Button
              className={`w-full`}
              styleType="Primary"
              onClick={() => setMultiLang()}
            >
              {t('common:save')}
            </Button>
          </div>
        </div>
      </ModalContent>
    </>
  );
};

export default MultiLangTextarea;
