/**
 * modal basic
 * @returns react fc
 */
import { Fragment, useEffect } from 'react';
import { Transition, Dialog } from '@headlessui/react';

import type { IModalBasicProps } from './type';
import Button from '../button';
import WarningIcon from '@/components/svg/warning-side-icon';
import { useTranslation } from 'react-i18next';

interface IModalTypePropsItem {
  icon: React.ReactNode;
  iconWarpClass: string;
  confirmClass: string;
  okButtonType?:
    | 'Primary'
    | 'Secondary'
    | 'Border'
    | 'Text'
    | 'Danger'
    | 'Secondary Danger';
}
interface IModalTypeProps {
  [key: string]: IModalTypePropsItem;
}

const ModalBasic: React.FC<IModalBasicProps> = (props) => {
  const { t } = useTranslation();
  const modalType: IModalTypeProps = {
    info: {
      icon: <WarningIcon className="text-primary-color" />,
      iconWarpClass: 'bg-primary-color/10',
      confirmClass: 'bg-primary-color hover:bg-primary-color/70',
    },
    warn: {
      icon: <WarningIcon className="text-error-color" />,
      iconWarpClass: 'bg-error-color/10',
      confirmClass: 'bg-error-color hover:bg-error-color/70',
      okButtonType: 'Danger',
    },
    success: {
      icon: <WarningIcon className="text-success-color" />,
      iconWarpClass: 'bg-success-color/10',
      confirmClass: 'bg-primary-color hover:bg-primary-color/70',
    },
  };

  const {
    title,
    content,
    type = 'info',
    visible = false,
    okText = t('common:save'),
    cancelText = t('common:cancel'),
    onCancel,
    children,
    onOk,
    onDelyClose,
  } = props;

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const modalTypePropsItem = modalType[type];

  useEffect(() => {
    let delyCloseTime: any;
    if (onDelyClose && onDelyClose > 0) {
      delyCloseTime = setTimeout(() => {
        clearTimeout(delyCloseTime);
        handleCancel();
      }, onDelyClose * 1000);
    }
    return () => clearTimeout(delyCloseTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-99" onClose={handleCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-select-secondary-background-color/60" />
        </Transition.Child>

        <div className="fixed z-99 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded p-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-md sm:w-full sm:p-6">
                <div className="flex flex-col text-center">
                  <div className="flex flex-col items-center">
                    <div
                      className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full ${modalTypePropsItem.iconWarpClass}`}
                    >
                      {modalTypePropsItem.icon}
                    </div>
                    <div className="text-center mt-6">
                      <Dialog.Title
                        as="h3"
                        className="leading-6 font-semibold text-lg text-color-text-1"
                      >
                        {title}
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-2 text-sm text-color-text-1 font-semibold break-words">
                    {content || children}
                  </div>
                </div>

                <div className="mt-5">
                  <Button
                    styleType={modalTypePropsItem.okButtonType}
                    onClick={onOk}
                    className="w-full"
                  >
                    {okText}
                  </Button>
                  <Button
                    styleType="Border"
                    onClick={handleCancel}
                    className="w-full mt-4"
                  >
                    {cancelText}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalBasic;
