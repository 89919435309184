import dayjs from 'dayjs';
import config from '@/libs/config';
import { getCookie } from '@/utils/index';
import { Constants } from '@/constants';
import HeaderTimeHandle from '@/libs/header-time-handle';
import type { ServerResponse } from 'http';
import api from '@/libs/api';
import type { IObject } from '@/types/common-types';


export const newgetAvailableCurrencies = async (
  businessID: string,
  lang: string
) => {
  const token = getCookie(Constants.TOKEN) || '';
  let result: IObject[] = [];
  try {
    const resp: IObject = await api.get(
      `${config.urls.gatewayUrl}/api/treasury-services/api/global_information/available_currencies`,
      {
        headers: {
          'x-p-business-id': businessID,
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-i18n-lang': lang === 'en' ? 'en-US' : lang,
          'x-client-id': config.clientID,
        },
      }
    );
    if (resp && resp.code === 200) {
      const { data } = resp || {};
      const { available_currencies } = data || {};
      if (Array.isArray(available_currencies)) {
        result = available_currencies;
      }
    }
  } catch (error) { }

  return result;
};

export const getBusinessWallets = async (
  pBusinessId: string,
  lang: string,
  currentToken?: string
) => {
  let token = currentToken;

  if (!token) {
    token = getCookie(Constants.TOKEN) || '';
  }
  const now = dayjs(new Date()).utc().format();
  const timeStr = `${now.substring(0, 11) + '00:00:00.000Z'}`;

  try {
    const { data } = await api.get(
      `${config.urls.gatewayUrl}/api/retrieve-balances?p_business_id=${pBusinessId}&estimated_settled_at=${timeStr}`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': currentToken || token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessId,
          'x-i18n-lang': lang,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

interface IGetActivitiesParams {
  ledger_account_id: string;
  pBusinessId: string;
  type?: 'all' | 'ledger' | 'ledger_account' | 'card_transaction';
  limit?: number;
  offset?: number;
  currentToken?: string;
}

interface IGetActiveLedgerSearch {
  settlement_date_from?: string;
  settlement_date_to?: string;
  created_at_data_from?: string;
  created_at_data_to?: string;
  currency?: string[];
  amount_from?: number;
  amount_to?: number;
  activity_type?: string[];
  activity_status?: string[];
  id_or_name?: string;
}

export const getActivities = async (
  params: IGetActivitiesParams,
  search: IGetActiveLedgerSearch
) => {
  const {
    limit = 50,
    offset = 0,
    type = 'ledger_account',
    currentToken,
    ledger_account_id,
    pBusinessId,
  } = params;

  let token = currentToken;

  const param = {
    ...search,
    ledger_account_id,
    limit,
    offset,
    type,
  };

  if (!token) {
    token = getCookie(Constants.TOKEN) || '';
  }

  let list = [];
  try {
    const { data } = await api.post(
      `${config.urls.gatewayUrl}/api/treasury-services/api/activities`,
      { ...param },
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessId,
        },
      }
    );
    const { activities } = data || {};
    if (Array.isArray(activities) && activities.length > 0) {
      list = activities;
    }

    return list;
  } catch (error) {
    console.error(error);
  }
};

interface IGetVirtualAccountsParams {
  pBusinessId: string;
  limit?: number;
  offset?: number;
  currentToken?: string;
}

export const getVirtualAccounts = async (params: IGetVirtualAccountsParams) => {
  const { limit = 1000, offset = 0, currentToken, pBusinessId } = params;

  let token = currentToken;

  if (!token) {
    token = getCookie(Constants.TOKEN) || '';
  }

  let virtualAccount = [];
  try {
    const resp = await api.get(
      `${config.urls.gatewayUrl}/api/treasury-services/api/virtual_accounts?limit=${limit}&offset=${offset}`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessId,
        },
      }
    );
    const { data } = resp;
    if (Array.isArray(data)) {
      virtualAccount = data;
    }

    return virtualAccount;
  } catch (error) {
    console.error(error);
  }
};

interface IGetPdfUrlParams {
  business: IObject;
  virtualAccount: IObject;
  lang: string;
}

export const getPdfUrl = async (params: IGetPdfUrlParams) => {
  const { virtualAccount, business, lang } = params;
  const token = getCookie(Constants.TOKEN) || '';
  let data = {};
  let param = {
    templateType: 'account_confirmation',
    fileName: `${virtualAccount.id}.pdf`,
    folderName: business.id,
    fileData: {
      business,
      virtualAccount,
      liquid_config: {
        locale: lang,
      },
    },
  }
  try {
    const resp: IObject = await api.post(
      `${config.urls.gatewayUrl}/api/helper/generate-pdf`,
      { ...param },
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
        },
      }
    );

    if (resp && resp.code === 200) {
      data = resp.data;
    }
  } catch (error) {
    console.error(error);
  }

  return data;
};

interface IExportBasParms {
  startTime: string;
  endTime: string;
  pBusinessId: string;
  type: 'pdf' | 'excel';
  currency?: string;
}

export const exportBas = async (params: IExportBasParms) => {
  const { startTime, endTime, pBusinessId, currency, type } = params;

  const token = getCookie(Constants.TOKEN) || '';
  let data: IObject = {};

  try {
    let query = `startTime=${startTime}&endTime=${endTime}`;

    if (currency) {
      query = `${query}&currency=${currency}`;
    }
    const resp: IObject = await api.get(
      `${config.urls.gatewayUrl}/api/reports/daily-merchant-statement/bas/${type}?${query}`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessId,
        },
        timeout: 60 * 1000,
      }
    );

    if (resp && resp.code === 200) {
      data = resp.data;
    }
  } catch (error) {
    console.error(error);
  }

  return data;
};

export const getAvailableCurrencies = async (
  businessID: string,
  token: string,
  lang: string
) => {
  let data: IObject = {};
  try {
    const resp = await api.get(
      `${config.urls.gatewayUrl}/api/treasury-services/api/global_information/available_currencies`,
      {
        headers: {
          'x-p-business-id': businessID,
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-i18n-lang': lang === 'en' ? 'en-US' : lang,
          'x-client-id': 1,
        },
      }
    );
    data = resp.data || {};
  } catch (error) {
    console.error(error);
  }
  const { available_currencies } = data || {};
  return available_currencies || [];
};

export const activeAccount = async (businessID: string, currency: string) => {
  const token = getCookie(Constants.TOKEN) || '';
  let ledgerAccountId = null;
  try {
    const resp: IObject = await api.post(
      `${config.urls.gatewayUrl}/api/treasury-services/application/account_provisioning/webhook`,
      {
        p_business_id: businessID,
        currency: currency,
      },
      {
        headers: {
          'x-p-business-id': businessID,
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
        },
      }
    );
    if (resp && resp.code === 200) {
      const { ledger_account_id = '' } = resp.data || {};
      ledgerAccountId = ledger_account_id;
    }
  } catch (error) {
    console.error(error);
  }

  return ledgerAccountId;
};

export const activeVirtualAccounts = async (businessID: string) => {
  const token = getCookie(Constants.TOKEN) || '';
  try {
    const resp = await api.post(
      `${config.urls.gatewayUrl}/api/treasury-services/api/virtual_accounts`,
      {
        p_business_id: businessID,
      },
      {
        headers: {
          'x-p-business-id': businessID,
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
        },
      }
    );

    return resp;
  } catch (error: any) {
    console.error(error);
    return error.response.data
  }
};

export const getRetrieveBalances = async (
  businessID: string,
  token: string,
  res?: ServerResponse
) => {
  let data: IObject = {};
  try {
    const timeHandle = HeaderTimeHandle.handle('x-query-retrieve-balances-time');
    const resp = await api.get(
      `${config.urls.gatewayUrl}/api/retrieve-balances?p_business_id=${businessID}`,
      {
        headers: {
          'x-p-business-id': businessID,
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
        },
      }
    );
    if (res) {
      timeHandle.write(res);
    }
    data = resp.data || {};
  } catch (error) {
    console.error(error);
  }
  const { ledger_accounts } = data || {};
  return ledger_accounts || [];
};
