import Filter, { FilterOperation, IFilterField, IFilterOptions, IFilterType } from "@/components/common/filter";
import { TableRecord } from "@/components/table/interface";
import { IAnalyticsTypes, loadAnalyticsData } from "@/data/analytics";
import { IObject } from "@/types/common-types";
import { getPaymentMethodIcon } from "@/utils/account-balance-untils";
import { handleNumber, transformField } from "@/utils/field-utils";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { isNumber } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DetailsModal from "../components/details-modal";
import Button from "@/components/common/button";
import Table from "@/components/table";

interface ISettlementsDetailsProps {
  visible: boolean;
  startDate: string;
  endDate: string;
  total: string;
  businessID: string;
  currency: string;
  type: IAnalyticsTypes;
  onCancel: () => void;
  openExportModal: () => void;
}

const SettlementsDetails: React.FC<ISettlementsDetailsProps> = (props) => {
  const {
    visible,
    onCancel,
    startDate,
    endDate,
    total,
    businessID,
    currency,
    type,
    openExportModal,
  } = props;

  const { t } = useTranslation(['analytics', 'transaction_advice']);

  const [loading, setLoading] = useState<boolean>(false);
  const [ortherloading, setOrtherLoading] = useState<boolean>(false);
  const [records, setRecords] = useState<IObject[]>([]);
  const [otherTyperecords, setOtherTyperecords] = useState<IObject[]>([]);
  const [otherTypeTotal, setOtherTypeTotal] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(30);
  const [page, setPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState('');
  // const [totalAmount, setTotalAmount] = useState('');
  const [filterOptions, setFilterOptions] = useState<IFilterOptions[]>([]);

  useEffect(() => {
    if (visible) {
      loadData();
      loadOrtherData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleType = (record: IObject) => {
    const { payment_type: type = '', void_at } = record;
    let className = '';
    let newType = type;

    if (void_at) {
      newType = 'void';
    }

    switch (newType) {
      case 'sale':
        className = 'bg-[#4CD964] text-[#4CD964]';
        break;
      case 'void':
        className = 'text-icon-color bg-icon-color';
        break;
      case 'refund':
      case 'representment':
        className = 'text-primary-color bg-primary-color';
        break;
      case 'pre_auth':
      case 'Increased Pre-auth':
        newType = 'pre-auth';
        className = 'text-[#F59E0B] bg-[#F59E0B]';
        break;
      case 'pre_auth_completed':
        newType = 'pre-auth completed';
        className = 'text-[#4CD964] bg-[#4CD964]';
        break;
      case 'hold':
        className = 'text-[#CE0310] bg-[#CE0310]';
        break;
      case 'dispute':
        className = 'text-[#6780DA] bg-[#6780DA]';
        break;
      case 'pre_dispute':
        newType = 'pre-dispute';
        className = 'text-[#6780DA] bg-[#6780DA]';
        break;
      default:
        break;
    }
    return {
      newType,
      className,
    };
  };

  const otherTypehandleType = (record: IObject) => {
    const { payment_type: type = '', void_at } = record;
    let className = '';
    let newType = type;

    if (void_at) {
      newType = 'void';
    }
    switch (newType) {
      case 'rolling_reserve':
        className = 'bg-[#31E2D8] text-[#31E2D8]';
        newType = 'rolling reserve';
        break;
      case 'void':
        className = 'text-icon-color bg-icon-color';
        break;
      default:
        className = 'text-primary-color bg-primary-color';
        break;
    }
    return {
      newType,
      className,
    };
  };

  const getPaymentMethod = (record: IObject, getCreditCard = true) => {
    const {
      payment_method,
      consumer_identify,
    } = record;
    let value = payment_method;
    let iconNode = null;

    switch (value) {
      case 'mastercard':
        value = 'MasterCard';
        break;
      case 'wechat':
        value = 'WeChat';
        break;
      case 'xpay':
        value = 'Xpay';
        break;
      case 'amex':
        value = 'AMEX';
        break;
      case 'diners':
        value = 'Diners Club';
        break;
      case 'jcb':
        value = 'JCB';
        break;
      case 'fps':
        value = 'FPS';
        break;
      case 'cup':
        value = 'UnionPay';
        break;
      case 'crypto_coin':
      case 'corporate_income':
        value = 'Corporate Income';
        break;
      default:
        value = transformField(value);
        break;
    }

    if (getCreditCard) {
      if (!['wechat', 'alipay', 'unionpay wallet', 'unionpay_wallet'].includes(payment_method) && consumer_identify.length > 4) {
        const num = consumer_identify.slice(-4);
        value = `${value}***${num}`;
      }
    } else {
      iconNode = getPaymentMethodIcon(payment_method);
    }

    return {
      value,
      iconNode,
    };
  };

  const columns: ColumnsType<TableRecord> = [
    {
      key: 'business_name',
      dataIndex: 'business_name',
      title: t('analytics:business_name'),
      fixed: 'left',
      width: 220,
    },
    {
      key: 'day_end_id',
      dataIndex: 'day_end_id',
      title: t('analytics:settlement_batch_id'),
      align: 'left',
      width: 156,
    },
    {
      key: 'txn_id',
      dataIndex: 'txn_id',
      title: t('analytics:transaction_id'),
      align: 'left',
      width: 156,
    },
    {
      key: 'payment_scheme',
      title: t('common:payment_scheme'),
      align: 'left',
      width: 120,
      render: (_key, record) => {
        const { value, iconNode } = getPaymentMethod(record, false);

        return (
          <div className="flex items-center">
            {iconNode}
            <span className="whitespace-nowrap ml-1">{value}</span>
          </div>
        );
      },
    },
    {
      key: 'payment_method',
      title: t('common:payment_method'),
      align: 'left',
      width: 120,
      render: (_key, record) => {
        const { value } = getPaymentMethod(record);

        return (
          <span className="whitespace-nowrap text-color-text-1">{value}</span>
        );
      },
    },
    {
      key: 'payment_type',
      title: t('analytics:type'),
      width: 60,
      render: (_key, record) => {
        const { newType, className } = handleType(record);

        return (
          <>
            <span
              className={`${className} px-3 py-1.5 text-xs whitespace-nowrap rounded-3xl bg-opacity-10`}
            >
              {newType.toUpperCase()}{newType.toUpperCase() === 'SALE' ? 'S' : ''}
            </span>
            {record.has_tips_data && (
              <span
                className={`${newType.toUpperCase() === 'SALE' ? 'text-[#EE8888] bg-[#EE8888]' : className} px-3 py-1.5 text-xs whitespace-nowrap rounded-3xl bg-opacity-10`}
              >
                {'TIPS'}
              </span>
            )}
          </>

        );
      },
    },
    {
      key: 'tran_amount',
      title: t('analytics:transaction_amount'),
      align: 'right',
      width: 170,
      render: (_key, record) => {
        const { tran_amount = '', tran_currency = '' } = record;
        let value = handleNumber(tran_amount);
        const { newType } = handleType(record);
        if (value.indexOf('-') === -1) {
          value = `+${value}`;
        }

        let className = '';
        let wrapperClass = '';

        if (newType === 'void') {
          className = 'line-through';
          wrapperClass = 'text-color-text-4';
        } else if (newType === 'refund') {
          className = 'text-error-color';
        }

        return (
          <span className={`whitespace-nowrap ${wrapperClass}`}>
            <span className={className}>{value}</span> {tran_currency}
          </span>
        );
      },
    },
    {
      key: 'fee_amount',
      title: t('analytics:fees_charged'),
      align: 'right',
      width: 120,
      render: (_key, record) => {
        const { fee_amount = '', fee_currency = '' } = record;
        let value = handleNumber(fee_amount);
        if (isNumber(Number(fee_amount)) && Number(fee_amount) > 0) {
          value = `-${value}`;
        }
        return (
          <span className="whitespace-nowrap">
            {value} {fee_currency}
          </span>
        );
      },
    },
    {
      key: 'settlement_amount',
      title: t('analytics:settled_amount'),
      align: 'right',
      width: 156,
      render: (_key, record) => {
        const { settlement_amount = '', settlement_currency = '' } = record;
        let value = handleNumber(settlement_amount);
        if (value.indexOf('-') === -1) {
          value = `+${value}`;
        }
        return (
          <span className="whitespace-nowrap">
            {value} {settlement_currency}
          </span>
        );
      },
    },
    {
      key: 'created_at',
      title: t('analytics:transaction_time'),
      align: 'right',
      width: 156,
      render: (_key, record) => {
        const { created_at } = record;
        const ymdRes = dayjs(created_at).format('MMM D, YYYY');
        const timeRes = dayjs(created_at).format('HH:mm:ss');
        return (
          <div className="flex flex-col">
            <span className="whitespace-nowrap">{`${ymdRes}`}</span>
            <span className="whitespace-nowrap">{`${timeRes}`}</span>
          </div>
        );
      },
    },
  ];

  const otherTypeColumns: ColumnsType<TableRecord> = [
    {
      key: 'business_name',
      dataIndex: 'business_name',
      title: t('analytics:business_name'),
      fixed: 'left',
      width: 220,
    },
    {
      key: 'day_end_id',
      dataIndex: 'day_end_id',
      title: t('analytics:settlement_batch_id'),
      align: 'left',
      width: 156,
    },
    {
      key: 'payment_type',
      title: t('analytics:type'),
      width: 60,
      render: (_key, record) => {
        const { newType, className } = otherTypehandleType(record);

        return (
          <span
            className={`${className} px-3 py-1.5 text-xs whitespace-nowrap rounded-3xl bg-opacity-10`}
          >
            {newType.toUpperCase()}
          </span>
        );
      },
    },
    {
      key: 'tran_amount',
      title: t('analytics:transaction_amount'),
      align: 'right',
      width: 170,
      render: (_key, record) => {
        const { tran_amount = '', tran_currency = '' } = record;
        let value = handleNumber(tran_amount);
        const { newType } = handleType(record);
        if (value.indexOf('-') === -1) {
          value = `+${value}`;
        }

        let className = '';
        let wrapperClass = '';

        if (newType === 'void') {
          className = 'line-through';
          wrapperClass = 'text-color-text-4';
        } else if (newType === 'refund') {
          className = 'text-error-color';
        }

        return (
          <span className={`whitespace-nowrap ${wrapperClass}`}>
            <span className={className}>{value}</span> {tran_currency}
          </span>
        );
      },
    },
    {
      key: 'fee_amount',
      title: t('analytics:fees_charged'),
      align: 'right',
      width: 120,
      render: (_key, record) => {
        const { fee_amount = '', fee_currency = '' } = record;
        let value = handleNumber(fee_amount);
        if (isNumber(Number(fee_amount)) && Number(fee_amount) > 0) {
          value = `-${value}`;
        }
        return (
          <span className="whitespace-nowrap">
            {value} {fee_currency}
          </span>
        );
      },
    },
    {
      key: 'settlement_amount',
      title: t('analytics:settled_amount'),
      align: 'right',
      width: 156,
      render: (_key, record) => {
        const { settlement_amount = '', settlement_currency = '' } = record;
        let value = handleNumber(settlement_amount);
        if (value.indexOf('-') === -1) {
          value = `+${value}`;
        }
        return (
          <span className="whitespace-nowrap">
            {value} {settlement_currency}
          </span>
        );
      },
    },
    {
      key: 'created_at',
      title: t('analytics:transaction_time'),
      align: 'right',
      width: 156,
      render: (_key, record) => {
        const { created_at } = record;
        const ymdRes = dayjs(created_at).format('MMM D, YYYY');
        const timeRes = dayjs(created_at).format('HH:mm:ss');
        return (
          <div className="flex flex-col">
            <span className="whitespace-nowrap">{`${ymdRes}`}</span>
            <span className="whitespace-nowrap">{`${timeRes}`}</span>
          </div>
        );
      },
    },
  ];

  const filterFields: IFilterField[] = [
    {
      type: IFilterType.MULTISELECT,
      name: 'payment_type',
      label: t('analytics:payment_type'),
      operations: [FilterOperation.EQUALS],
      options: [
        {
          label: t('transaction_advice:sales'),
          name: 'sale',
        },
        {
          label: t('transaction_advice:void'),
          name: 'void',
        },
        {
          label: t('transaction_advice:refund'),
          name: 'refund',
        },
        {
          label: t('transaction_advice:increment_pre_auth'),
          name: 'increment_pre_auth',
        },
        {
          label: t('transaction_advice:pre_auth_complete'),
          name: 'pre_auth_complete',
        },
        {
          label: t('transaction_advice:charge_back'),
          name: 'charge_back',
        },
        {
          label: t('transaction_advice:dispute'),
          name: 'dispute',
        },
        {
          label: t('transaction_advice:dispute_reversal'),
          name: 'dispute_reversal',
        },
        {
          label: t('transaction_advice:hold'),
          name: 'hold',
        },
        {
          label: t('transaction_advice:hold_reversal'),
          name: 'hold_reversal',
        },
        {
          label: t('transaction_advice:pre_dispute'),
          name: 'pre_dispute',
        },
        {
          label: t('transaction_advice:representment'),
          name: 'representment',
        },
      ],
    },
    {
      name: 'amount',
      type: IFilterType.NUMBER,
      label: t('analytics:amount'),
      operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
    },
    {
      type: IFilterType.MULTISELECT,
      name: 'payment_method',
      label: t('common:payment_method'),
      operations: [FilterOperation.EQUALS],
      options: [
        {
          label: t('transaction_advice:mastercard'),
          name: 'mastercard',
        },
        {
          label: t('transaction_advice:visa'),
          name: 'visa',
        },
        {
          label: t('transaction_advice:union_pay'),
          name: 'unionpay',
        },
        {
          label: t('transaction_advice:unionpay_wallet'),
          name: 'unionpay_wallet',
        },
        {
          label: t('transaction_advice:alipay'),
          name: 'alipay',
        },
        {
          label: t('transaction_advice:wechat_pay'),
          name: 'wechat',
        },
        {
          label: t('transaction_advice:fps'),
          name: 'fps',
        },
        {
          label: t('transaction_advice:jcb'),
          name: 'jcb',
        },
        {
          label: t('transaction_advice:discover'),
          name: 'discover',
        },
        {
          label: t('transaction_advice:diners_club'),
          name: 'diners',
        },
        {
          label: t('transaction_advice:xpay'),
          name: 'xpay',
        },
        {
          label: t('transaction_advice:octopus'),
          name: 'octopus',
        },
        {
          label: t('transaction_advice:american_express'),
          name: 'amex',
        },
      ],
    },
    {
      type: IFilterType.DATE,
      name: 'transaction_date',
      label: t('analytics:transaction_date'),
      operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
    },
  ];

  const getFilter = (searchValue: string, options: IFilterOptions[]) => {
    const filterObj: any = {};

    if (searchValue) {
      filterObj.keyword = searchValue;
    }

    options.forEach((option) => {
      const { operation, name, value, type } = option;
      if (value) {
        if (type === IFilterType.MULTISELECT) {
          filterObj[name] = value.split(',');
        } else {
          let firstValue = '';
          let secondValue = '';
          if (operation === FilterOperation.IS_BETWEEN) {
            const values = value.split('&');
            firstValue = values[0] || '';
            secondValue = values[1] || '';
          } else if (operation === FilterOperation.EQUALS) {
            firstValue = value;
            secondValue = value;
          }

          if (name === 'transaction_date') {
            firstValue && (filterObj.transaction_start_date = firstValue);
            secondValue && (filterObj.transaction_end_date = secondValue);
          } else if (name === 'amount') {
            firstValue && (filterObj.amount_range_begin = Number(firstValue));
            secondValue && (filterObj.amount_range_end = Number(secondValue));
          }
        }
      }
    });

    return filterObj;
  };

  const loadData = async (
    currentPage = page,
    limit = pageSize,
    search = searchValue,
    options: IFilterOptions[] = filterOptions
  ) => {
    setLoading(true);
    setSearchValue(search);
    setFilterOptions(options);
    const filter = getFilter(search, options);
    const records = await loadAnalyticsData(
      {
        end_date: endDate,
        start_date: startDate,
        p_business_id: businessID,
        currency_code: currency,
        type,
        detail: true,
        offset: (currentPage - 1) * limit,
        limit: limit + 1,
        ...filter,
      }
    );
    setRecords(records);
    // setTotalAmount(records.total_amount)
    setPage(currentPage);
    setPageSize(limit);
    setLoading(false);
  };

  const loadOrtherData = async (
    currentPage = page,
    limit = pageSize,
    search = searchValue,
    options: IFilterOptions[] = filterOptions
  ) => {
    setOrtherLoading(true);
    setSearchValue(search);
    setFilterOptions(options);
    const filter = getFilter(search, options);
    const otherTypeRecords = await loadAnalyticsData(
      {
        end_date: endDate,
        start_date: startDate,
        p_business_id: businessID,
        currency_code: currency,
        type,
        detail: true,
        offset: 0,
        limit,
        search_type: 'fee_list',
        ...filter,
      }
    );
    const otherTypeTotal = await loadAnalyticsData(
      {
        end_date: endDate,
        start_date: startDate,
        p_business_id: businessID,
        currency_code: currency,
        type,
        detail: true,
        offset: (currentPage - 1) * limit,
        limit,
        search_type: 'fee_total',
        ...filter,
      }
    );
    const total_fee_charge = otherTypeTotal.total_fee_charge;
    setOtherTyperecords(otherTypeRecords);
    setOtherTypeTotal(
      total_fee_charge.indexOf('-') === -1
        ? '-' + total_fee_charge + ' ' + currency
        : total_fee_charge + ' ' + currency
    );

    setOrtherLoading(false);
  };

  const handleFilter = (
    search?: string | undefined,
    options?: IFilterOptions[]
  ) => {
    loadData(1, 30, search, options);
  };

  const handleExport = () => {
    openExportModal();
    onCancel();
  };

  const handleCancel = () => {
    setPageSize(30);
    setPage(1);
    onCancel();
    setTimeout(() => {
      setRecords([]);
      setSearchValue('');
      setFilterOptions([]);
    }, 500);
  };

  const hasFilter =
    filterOptions.filter((item) => item.value).length > 0 ||
    searchValue.length > 0;
  const showTotal = hasFilter && records.length > 0;


  return (
    <DetailsModal
      visible={visible}
      title={t('analytics:settlement_overview')}
      onCancel={handleCancel}
    >
      <div className="flex flex-col h-full">
        <div className="hidden md:flex justify-between items-center pb-6 pt-2">
          <div className="flex items-cenrer text-color-text-1 truncate">
            <span className="text-xl font-bold">
              {t('analytics:settings_transaction')}
            </span>
            <span className="text-color-text-1 bg-primary-color bg-opacity-10 font-medium px-3 py-1 rounded-lg ml-2">
              {total}
              {/* {handleNumber(totalAmount)} {currency} */}
            </span>
          </div>
          <div className="flex items-center">
            {!showTotal && (
              <span className="text-color-text-1 text-sm mr-2 font-medium truncate">
                {dayjs(startDate).format('MMM D, YYYY')} to{' '}
                {dayjs(endDate).format('MMM D, YYYY')}
              </span>
            )}
            {showTotal && (
              <span className="text-color-text-1 text-sm mr-2 font-medium truncate">
                {t('analytics:filter_result', { count: records.length })}
              </span>
            )}
            <div className="max-w-[60%] mr-2 md:max-w-max">
              <Filter
                fields={filterFields}
                onFilter={handleFilter}
                searchValue={searchValue}
                options={filterOptions}
                placeholder={t('analytics:filter_placeholder2')}
              />
            </div>
            <Button
              styleType="Border"
              disabled={loading}
              onClick={handleExport}
              className="bg-primary-background-color text-base font-medium px-3 h-10 text-color-text-1"
            >
              {t('common:export')}
            </Button>
          </div>
        </div>
        <div className="flex flex-col mb-4 md:hidden">
          <div className="flex justify-between mt-2">
            <div className="flex flex-col mb-2">
              <span className="text-xl font-bold">
                {t('analytics:settings_transaction')}
              </span>
              <span className="text-color-text-1 w-fit bg-primary-color bg-opacity-10 font-medium px-3 py-1 rounded-lg mt-2">
                {total}
              </span>
            </div>
            <Button
              styleType="Border"
              disabled={loading}
              onClick={handleExport}
              className="bg-primary-background-color text-base font-medium px-3 h-10 text-color-text-1"
            >
              {t('common:export')}
            </Button>
          </div>
          <Filter
            fields={filterFields}
            onFilter={handleFilter}
            searchValue={searchValue}
            options={filterOptions}
            placeholder={t('analytics:filter_placeholder2')}
          />
        </div>
        <Table
          className="overflow-y-auto"
          columns={columns}
          records={records}
          loadingData={loading}
          search={hasFilter}
          searchEmptyClass="flex-1 justify-center"
          pagination={{
            className: 'mt-6',
            current: page,
            currentSize: records.length,
            pageSize,
            pageSizeOptions: [30, 60, 120],
            changePageCallback: loadData,
          }}
        />
        {!hasFilter && (
          <>
            <div className="flex justify-between items-center mb-6 mt-2">
              <div className="flex items-cenrer text-color-text-1">
                <span className="text-xl font-bold">
                  {t('analytics:other_charges')}
                </span>
                {otherTypeTotal ? (
                  <span className="text-color-text-1 bg-primary-color bg-opacity-10 font-medium px-3 py-1 rounded-lg ml-2">
                    {otherTypeTotal}
                  </span>
                ) : null}
              </div>
            </div>
            <Table
              loadingData={ortherloading}
              columns={otherTypeColumns}
              records={otherTyperecords}
            />
          </>
        )}
      </div>
    </DetailsModal>
  );
};

export default SettlementsDetails;
