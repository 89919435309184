import { useEffect, useState } from 'react';
import type { FieldError } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import isString from 'lodash/isString';
import CleanIcon from '@/components/svg/clean-icon';
import Select from '../select';
import Config from '@/libs/config';
import { findNode, insertComma } from '@/utils';
import Cookies from 'js-cookie';
import { IAreaCodes } from '@/types/common-types';
import { Constants } from '@/constants';
import { loadLocalAddress } from '@/utils/google-utils';

interface IPhoneNumberProps {
  value: any;
  name: string;
  options: IAreaCodes[];
  placeholder?: string;
  showSearch?: boolean;
  isSetting?: boolean;
  onChange?: (value: any) => void;
  error?: FieldError;
  disabled?: boolean;
  className?: string;
  wrapperClass?: string;
  position?: 'top' | 'bottom';
}

const PhoneNumber: React.FC<IPhoneNumberProps> = (props) => {
  const {
    error,
    placeholder = '',
    onChange,
    value = '',
    options,
    showSearch = false,
    isSetting = false,
    disabled,
    className = '',
    wrapperClass = '',
    position = 'top',
  } = props;
  const [classes, setClasses] = useState('');
  const [dialCode, setDialCode] = useState('');
  const [inputValue, setInputValue] = useState('');
  const prefix = Config.staticPath;

  useEffect(() => {
    const defaultDialCode = '852';
    let dialCode = '';
    let inputValue = '';
    let dialItem: IAreaCodes | undefined = undefined;
    if (value) {
      if (typeof value === 'string') {
        if (value.startsWith('+')) {
          inputValue = value.substring(value.indexOf(' ') + 1);
          dialCode = value.substring(0, value.indexOf(' ')).replace('+', '');
        } else {
          inputValue = value;
        }
      } else if (Array.isArray(value)) {
        if (value[0] && value[0].startsWith('+')) {
          dialCode = value[0].replace('+', '');
        } else {
          dialCode = value[0];
        }
        inputValue = value[1] || '';
      }
      dialItem = findNode<IAreaCodes>(options, 'phoneCountryCode', dialCode);
    } else {
      if (!disabled) {
        dialCode = defaultDialCode;
        if (dialCode === '1') {
          dialItem = findNode<IAreaCodes>(options, 'isoAlpha2Code', 'US');
        } else {
          dialItem = findNode<IAreaCodes>(
            options,
            'phoneCountryCode',
            dialCode
          );
        }
      }
    }
    if (dialItem) {
      dialCode = dialItem.isoAlpha2Code;
    }

    setDialCode(dialCode);
    setInputValue(insertComma(inputValue, true, 4, ' '));
    // eslint-disable-next-line
  }, [disabled, value, options]);

  useEffect(() => {
    if (!value && !disabled) {
      getDefaultCode();
    }
    // eslint-disable-next-line
  }, []);

  // const analysisNavigatorLan = () => {
  //   let defaultDialCode = '852';
  //   if (navigator && navigator.language) {
  //     let lan = navigator.language;
  //     if (lan.indexOf('-') > -1) {
  //       lan = lan.split('-')[1];
  //     }

  //     const dialItem = findNode<IAreaCodes>(options, 'isoAlpha2Code', lan);

  //     if (dialItem) {
  //       defaultDialCode = dialItem.phoneCountryCode;
  //     }
  //   }

  //   return defaultDialCode;
  // };

  const getDefaultCode = async () => {
    const defaultCodeValue = Cookies.get(Constants.DEFAULT_CODE);
    if (defaultCodeValue && isString(defaultCodeValue)) {
      const item = findNode<IAreaCodes>(
        options,
        'phoneCountryCode',
        defaultCodeValue
      );
      if (item) {
        setDialCode(item.isoAlpha2Code);
      }
      return;
    }
    const address = await loadLocalAddress();
    if (address) {
      const { short_name } = address;
      const item = findNode<IAreaCodes>(options, 'isoAlpha2Code', short_name);
      if (item) {
        Cookies.set(Constants.DEFAULT_CODE, item.phoneCountryCode, {
          expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
        });
        setDialCode(item.isoAlpha2Code);
      }
    }
  };

  const handleValueChange = (changeValue: string, isSelect = false) => {
    let newValue: any = '';
    if (isSelect) {
      const dialItem = findNode<IAreaCodes>(
        options,
        'isoAlpha2Code',
        changeValue
      );
      if (!dialItem) return;
      if (typeof value === 'string') {
        newValue = `+${dialItem.phoneCountryCode} ${inputValue.replace(
          / /g,
          ''
        )}`;
      } else if (Array.isArray(value)) {
        newValue = value;
        newValue[0] = `+${dialItem.phoneCountryCode}`;
      }
      setDialCode(changeValue);
    } else {
      const trimNum = changeValue.replace(/ /g, '');
      if (isNaN(Number(trimNum))) return;
      if (typeof value === 'string') {
        const dialItem = findNode<IAreaCodes>(
          options,
          'isoAlpha2Code',
          dialCode
        );
        if (!dialItem) return;
        newValue = `+${dialItem.phoneCountryCode} ${trimNum}`;
        setInputValue(trimNum);
      } else if (Array.isArray(value)) {
        newValue = value;
        newValue[1] = changeValue;
        setInputValue(trimNum);
      }
    }

    if (onChange) {
      onChange(newValue);
    }
  };

  const handlefilter = (inputValue: string, option: IAreaCodes) => {
    const value = inputValue.toLocaleLowerCase();
    const { phoneCountryCode, name } = option;
    if (
      phoneCountryCode.toLocaleLowerCase().includes(value) ||
      name.toLocaleLowerCase().includes(value)
    ) {
      return true;
    }
    return false;
  };

  const handleMask = (value: string) => {
    const reallyValue = value.replaceAll(' ', '');
    const mask = [];
    for (let i = 0; i < reallyValue.length; i++) {
      if (i > 0 && i % 4 === 0) {
        mask.push(' ');
      }
      mask.push(/\d/);
    }
    return mask.reverse();
  };

  return (
    <>
      <div
        className={`w-full border border-solid rounded flex items-center h-10 hover:border-primary-color focus:border-primary-color ${classes} ${disabled ? 'border-none pl-0' : 'shadow-box-drop'
          } ${error && error.message ? 'border-error-color' : 'border-border-color'
          } ${wrapperClass}`}
        onFocus={() => setClasses('border-primary-color')}
        onBlur={() => setClasses('')}
      >
        <div
          className={`w-auto ${!dialCode && disabled ? 'w-0' : ''
            } ${className}`}
        >
          <Select
            value={dialCode}
            position={position}
            options={options}
            showSearch={showSearch}
            noAdd={true}
            searchType="number"
            dataKey={'isoAlpha2Code'}
            disabled={disabled}
            valueKey={'phoneCountryCode'}
            className={`border-none outline-none shadow-none min-w ${disabled ? 'w-auto pr-1' : 'w-28'
              }`}
            onChange={(value: any) => handleValueChange(value, true)}
            filterOption={handlefilter}
            renderItem={(item: IAreaCodes) => (
              <div className="flex" key={item.isoAlpha2Code}>
                <span className="flex items-center relative w-5 mr-2">
                  <img
                    width="20px"
                    height="20px"
                    src={`${prefix}/images/country-flag/${item.isoAlpha2Code
                      ? item.isoAlpha2Code.toLocaleLowerCase()
                      : 'hk'
                      }.svg`}
                    alt=""
                  />
                </span>
                <span className="text-color-text-1 text-sm font-medium">
                  +{item.phoneCountryCode}
                </span>
              </div>
            )}
            renderImgItem={(item: IAreaCodes) => (
              <div className="flex" key={item.isoAlpha2Code}>
                <span className="flex items-center relative w-5 mr-2">
                  <img
                    width="20px"
                    height="20px"
                    src={`${prefix}/images/country-flag/${item.isoAlpha2Code
                      ? item.isoAlpha2Code.toLocaleLowerCase()
                      : 'hk'
                      }.svg`}
                    alt=""
                  />
                </span>
              </div>
            )}
          />
        </div>
        <div className={`flex-1 group`}>
          <div
            className={`group rounded flex items-center h-10 px-3 py-2 cursor-auto border hover:border-primary-color border-none outline-none pl-0 shadow-none ${isSetting ? 'mt-[1px]' : ''} `}
          >
            <MaskedInput
              mask={handleMask}
              style={{
                WebkitBoxShadow: '0 0 0 1000px white inset',
                fontFamily: 'Roboto',
              }}
              value={disabled && !inputValue ? '--' : inputValue}
              placeholder={placeholder}
              className={`
                focus:outline-none border-none flex-grow font-medium text-color-text-1 bg-inherit
              placeholder:text-color-text-5 placeholder:font-normal placeholder:text-sm text-sm pr-2 ${disabled ? 'text-ellipsis' : ''
                }
                w-full
              `}
              autoComplete="none"
              guide={false}
              disabled={disabled}
              onChange={(e: any) => {
                handleValueChange(e.target.value);
              }}
            />
            <span
              className={`invisible ${!disabled && inputValue.length > 0 ? 'group-hover:visible' : ''
                }`}
            >
              <CleanIcon
                size={20}
                className="cursor-pointer text-color-text-5 hover:text-color-text-3"
                onClick={() => {
                  handleValueChange('');
                }}
              />
            </span>
          </div>
        </div>
      </div>
      {error && (
        <div className="mt-1 text-sm text-error-color">{error.message}</div>
      )}
    </>
  );
};

export default PhoneNumber;
