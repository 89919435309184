import type {  ILedgerAccount } from '@/types/account-balance-type';
import type { IObject } from '@/types/common-types';
import AccountBalanceCurrentProcess from './account-balance-page/account-balance-current-account/account-balance-current-account';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import RefreshIcon from '@/pages/account-balance/account-balances-icons/refresh-icon';
import {
  getBusinessAccount,
  getMerchantAccount,
} from '@/data/account-balance';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import type {
  IBaseFilterField,
  IFilterField,
} from '@/components/common/filter';
import {
  FilterOperation,
  IFilterType,
} from '@/components/common/filter';
import ActionType from '@/actions/action-type';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';

const AccountBalanceCurrentAccountPage = () => {
  const { t } = useTranslation("account_balance");
  const { lang = 'en', p_business_id: businessID = '' } = useParams();
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const [currentDate, setCurrentdate] = useState<dayjs.Dayjs>();
  const [refreshPage, setRefreshPage] = useState<boolean>(false);
  const [initLoading, setInitLoading] = useState<boolean>(false);
  const [ledgerAccount, setLedgerAccount] = useState<ILedgerAccount | IObject>(
    {}
  );
  const [merchantAccountData, setMerchantAccountData] = useState<IObject>({});

  useEffect(() => {
    refreshDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  useEffect(() => {
    getInitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage, lang]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('common:company_account'),
          key: '1',
          type: 'label',
        },
        {
          name: t('account_balance:account_balance'),
          key: '2',
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  const getInitData = async () => {
    if (businessID) {
      const allPromise: any[] = [];

      let promiseIndex = -1;
      let ledgerAccountIndex = -1;
      let merchantAccountIndex = -1;

      ledgerAccountIndex = ++promiseIndex;
      allPromise.push(getBusinessAccount(businessID, lang));

      merchantAccountIndex = ++promiseIndex;
      allPromise.push(getMerchantAccount(businessID));

      setInitLoading(true);
      const resultData = await Promise.all(allPromise);
      setInitLoading(false);

      if (resultData && resultData.length > 0) {
        if (ledgerAccountIndex > -1 && resultData[ledgerAccountIndex]) {
          const data = resultData[ledgerAccountIndex] || {};
          setLedgerAccount(data);
        }

        if (merchantAccountIndex > -1 && resultData[merchantAccountIndex]) {
          const data = resultData[merchantAccountIndex] || {};
          setMerchantAccountData(data);
        }
      }
    }
  };

  const refreshDate = () => {
    const currentDate = dayjs();
    setCurrentdate(currentDate);
    // setMenuData(undefined)
  };

  const tabs = [
    t('account_balance:overview'),
    t('account_balance:merchant_account'),
    t('account_balance:current_account'),
  ];

  const handleItemClick = (index: number) => {
    if (index !== 2) {
      let routeStr = '';
      switch (index) {
        case 0:
          routeStr = `/${lang}/businesses/${businessID}/account-balance/overview`;
          break;
        case 1:
          routeStr = `/${lang}/businesses/${businessID}/account-balance/merchant-account`;
          break;

        default:
          break;
      }

      if (routeStr) {
        navigate(routeStr);
      }
    }
  };

  const ledgerAccounts = ledgerAccount.ledger_accounts;
  const currencyOptions: IBaseFilterField[] = [];
  if (Array.isArray(ledgerAccounts)) {
    ledgerAccounts.forEach((item) => {
      if (
        item.currency &&
        item.currency.currency_name &&
        item.currency.currency_code
      ) {
        currencyOptions.push({
          name: item.currency.currency_code,
          label: `${item.currency.currency_name} ${item.currency.currency_code}`,
        });
      }
    });
  }
  const filterFields: IFilterField[] = [
    {
      name: 'settlement_date',
      label: t('account_balance:settled_on'),
      type: IFilterType.DATE,
      operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
    },
    {
      name: 'created_at_data',
      label: t('account_balance:created_on'),
      type: IFilterType.DATE,
      operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
    },
    {
      name: 'currency',
      label: t('account_balance:wallet'),
      type: IFilterType.MULTISELECT,
      operations: [FilterOperation.EQUALS],
      options: currencyOptions,
    },
    {
      name: 'amount',
      label: t('common:amount'),
      type: IFilterType.NUMBER,
      operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
    },
    {
      name: 'activity_type',
      label: t('account_balance:activity_type'),
      type: IFilterType.MULTISELECT,
      operations: [FilterOperation.EQUALS],
      options: [
        {
          name: 'Merchant Services',
          label: 'Merchant Services',
        },
        {
          name: 'Add Fund',
          label: 'Add Fund',
        },
        {
          name: 'Inbound Transfer',
          label: 'Inbound Transfer',
        },
        {
          name: 'Outbound Transfer',
          label: 'Outbound Transfer',
        },
        {
          name: 'Fund Reversal',
          label: 'Fund Reversal',
        },
        {
          name: 'Wallet Conversion',
          label: 'Wallet Conversion',
        },
        {
          name: 'Rolling Reserve Released',
          label: 'Rolling Reserve Released',
        },
      ],
    },
    {
      name: 'activity_status',
      label: t('common:status'),
      type: IFilterType.MULTISELECT,
      operations: [FilterOperation.EQUALS],
      options: [
        {
          name: 'Initiated',
          label: 'Initiated',
        },
        {
          name: 'Pending for Transfer',
          label: 'Pending for Transfer',
        },
        {
          name: 'Processing Transfer',
          label: 'Processing Transfer',
        },
        {
          name: 'Settled',
          label: 'Settled',
        },
        {
          name: 'Rejected',
          label: 'Rejected',
        },
        {
          name: 'Reversed',
          label: 'Reversed',
        },
        {
          name: 'Suspended',
          label: 'Suspended',
        },
      ],
    },
  ];

  return (
    <div className="p-4 md:p-6 lg:px-8 lg:py-6">
      <div className="flex mb-4 flex-col mb:mb-6">
        <div className="flex items-center space-x-2 justify-between">
          <div className="text-3xl leading-10 font-bold text-color-text-1 ml-2.5">
            {t('account_balance:account_balance')}
          </div>
          <div className="flex items-center space-x-2">
            <div className="text-sm text-primary-color">
              {t('account_balance:last_updated_at', {
                data: currentDate?.format('HH:mm'),
              })}
            </div>
            <RefreshIcon
              size={20}
              className={`text-primary-color shrink-0 ml-1 fill-current hover:text-primary-color/70 cursor-pointer`}
              onClick={() => setRefreshPage((refreshPage) => !refreshPage)}
            />
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <div
          className={`flex space-x-4 h-fit overflow-x-auto hidden_scrollbar `}
          aria-label="line-tabs"
        >
          {tabs.map((item, index) => (
            <div
              key={index}
              className={`
                  py-2 px-4 rounded-3xl text-sm whitespace-nowrap font-medium
                  ${index === 2
                  ? 'bg-primary-color text-color-text-6'
                  : 'bg-color-text-6 text-color-text-2 cursor-pointer hover:text-primary-color border-border-color border'
                }  
                `}
              onClick={() => handleItemClick(index)}
            >
              {item}
            </div>
          ))}
        </div>
        <AccountBalanceCurrentProcess
          storeLedgerAccount={ledgerAccount}
          currentDate={currentDate}
          merchantAccountData={merchantAccountData}
          refreshPage={refreshPage}
          activekey={'currentAccount'}
          initLoading={initLoading}
          filterFields={filterFields}
          businessID={businessID} 
          ledgerAccount={ledgerAccount} 
          lederEnteryData={Object} 
          upComingData={Object} /> 
      </div>
    </div>
  );
};

export default AccountBalanceCurrentAccountPage;
