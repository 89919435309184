import Authentication from './authentication';
import AccountWrapper from './account-wrapper';
import { Alert } from '@/components/common/alert/alert';
import Button from '@/components/common/button';
import Input from '@/components/common/input';
import Label from '@/components/common/label';
import { verifyEmail } from '@/utils';
import { minus } from '@/utils/galaxy-utils';
import { b2cOtpSend } from '@/data/account';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Config from '@/libs/config';
import BackIcon from '@/components/svg/back-icon';

const ForgotEmailPage = () => {
  const { t } = useTranslation('signin');

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [count, setCount] = useState(0);
  const [showVfc, setShowVfc] = useState(false);
  const [loading, setLoading] = useState(false);

  const verificationTimout = useRef<any>(null);

  useEffect(() => {
    if (count === 0) {
      clearTimeout(verificationTimout.current);
      verificationTimout.current = null;
    } else {
      verificationTimout.current = setTimeout(() => {
        verificationTimout.current = null;
        setCount(minus(count, 1));
      }, 1000);
    }
    // eslint-disable-next-line
  }, [count]);

  const navigate = useNavigate();

  const handleClick = async () => {
    if (!handleValueChange(email)) return;
    setLoading(true);
    if (count > 0) {
      setShowVfc(true);
      setLoading(false);
      return;
    }

    const sendData = await b2cOtpSend(
      {
        phone: '',
        email,
        action: 'pwdreset',
        domainName: Config.urls.baseDomain,
      }
    );

    if (sendData.code === 200) {
      // router.push('/account/email-send');
      setShowVfc(true);
      setCount(60);
      Alert.success({
        message: t('signin:verification_code_sent_successfully'),
        wrapClass: '-top-10',
      });
    } else if (sendData.message) {
      Alert.error({
        message: sendData.message,
        wrapClass: '-top-10',
      });
    }

    setLoading(false);
  };

  const handleValueChange = (value: string) => {
    let result = false;
    if (!value) {
      setError(t('signin:invalid_email_address'));
    } else {
      if (verifyEmail(value)) {
        setError('');
        result = true;
      } else {
        setError(t('signin:invalid_email_address'));
      }
    }
    setEmail(value);
    return result;
  };

  const handleBack = () => {
    if (showVfc) {
      setShowVfc(false);
    } else {
      navigate('/account/signin');
    }
  };

  return (
    <AccountWrapper className="flex flex-col">
      <div className="flex flex-col w-120 bg-color-text-6 p-15 md:rounded-[30px] md:mr-[10%] md:shadow-sign-in-card">
        {!showVfc && (
          <>
            <h1 className="text-3xl text-center font-bold mb-6 text-color-text-1">
              {t('signin:forgot_password')}! ✨
            </h1>
            <div className="text-sm text-center text-color-text-2">
              {t('signin:tell_us_email')}
            </div>
            <div className="mt-15">
              <Label textClass="text-color-text-1" value={t('signin:email')}>
                <Link
                  to={`/account/password/forgot`}
                >
                  <div className="whitespace-nowrap text-xs text-primary-color cursor-pointer px-1">
                    {t('signin:reset_with_phone')}
                  </div>
                </Link>
              </Label>
              <Input
                onChange={handleValueChange}
                placeholder={t('signin:email_address')}
                warpClass={`h-12 rounded-lg ${
                  error ? 'border-error-color' : 'border-border-color'
                }`}
              />
              {error && (
                <div className="mt-2 text-sm text-error-color">{error}</div>
              )}
              <Button
                className="w-full h-12 mt-10 rounded-lg"
                loading={loading}
                onClick={handleClick}
              >
                {t('signin:send_verification_code')}
              </Button>
            </div>
          </>
        )}
        {showVfc && (
          <Authentication
            title={`${t('signin:forgot_password')}! ✨`}
            showTip={true}
            values={{
              email,
            }}
            token={''}
            count={count}
            setCount={setCount}
            callback={(result) => {
              const { token = '' } = result;
              navigate(`/account/password/reset?token=${token}`);
            }}
            sendCodeType="pwdreset"
          />
        )}
      </div>
      <div
        onClick={handleBack}
        className="flex justify-center items-center text-center text-sm text-color-text-3 hover:text-primary-color cursor-pointer mt-15 md:mr-[10%]"
      >
        <BackIcon />
        <span className="ml-2">{t('signin:back')}</span>
      </div>
    </AccountWrapper>
  );
};

export default ForgotEmailPage;
