import React from 'react';
import type { IIconProps } from '@/types/common-types';

const EyeOffIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 2.5L17.5 17.5"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.8207 8.82031C8.50797 9.13283 8.33219 9.55677 8.33203 9.99889C8.33188 10.441 8.50736 10.8651 8.81987 11.1778C9.13238 11.4905 9.55633 11.6663 9.99845 11.6665C10.4406 11.6666 10.8646 11.4912 11.1774 11.1786"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4655 14.4549C13.1063 15.3716 11.6196 15.8308 10.0013 15.8308C6.66797 15.8308 3.89047 13.8866 1.66797 9.99745C2.8088 8.00161 4.09547 6.51828 5.52797 5.54661M7.8038 4.46828C8.51832 4.26388 9.25813 4.16148 10.0013 4.16411C13.3346 4.16411 16.1121 6.10828 18.3346 9.99745C17.6863 11.1316 16.9913 12.1008 16.2488 12.9041L7.8038 4.46828Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default EyeOffIcon;
