import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { IBaseCartProps } from '@/types/analytics-type';
import { useTranslation } from 'react-i18next';
import { handleNumber } from '@/utils/field-utils';
import { getColorByPayinType, getXAndYStyle } from '@/utils/analytics-utils';
import TransitionNode from '../../components/transition-node';
import MoreIcon from '../../analytics-icons/more-icon';
import Skeleton from '@/components/skeleton';
import BarChartSkeleton from '../../components/bar-chart-skeleton';
import NodataContent from '../../components/no-data-content';
import PayinTransactionsDetails from '../../components/payin-transactions-details';
import { IPayinsReportsTypes } from '@/data/analytics';

const PayinByTransferType: React.FC<IBaseCartProps> = (props) => {
  const {
    currency,
    data,
    endDate,
    startDate,
    businessID,
    loading: loadAllData,
  } = props;

    const { t } = useTranslation('analytics');

  const { points = [], total_amount = 0 } = data || {};

  const [showMore, setShowMore] = useState<boolean>(false);
  const [options, setOptions] = useState<Highcharts.Options>({
    title: {
      text: undefined,
    },
  });

  const total = handleNumber(total_amount);
  const title = t('analytics:payin_by_transfer_type');
  const disabled = !points || points.length === 0;

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getOptions = () => {
    const labels: string[] = [];

    const barData: {
      [key: string]: { name: string; data: number[]; color: string; type: any };
    } = {};

    points?.forEach((point) => {
      const { x_axis, y_axis, name, type } = point;

      if (type) {
        if (!barData[type]) {
          barData[type] = {
            name: name,
            data: [Number(y_axis)],
            color: getColorByPayinType(type),
            type: 'column',
          };
        } else {
          if (barData[type]) {
            const newData = barData[type].data;
            newData.push(Number(y_axis));
            barData[type].data = newData;
          }
        }
      }

      const date = dayjs(x_axis).format('MM-DD');

      if (!labels.includes(date)) {
        labels.push(date);
      }
    });

    const chartDatas = Object.values(barData);

    const options: Highcharts.Options = {
      chart: {
        type: 'column',
        style: {
          fontFamily: 'Roboto',
        },
      },
      title: {
        text: undefined,
      },
      xAxis: {
        categories: labels,
        labels: {
          style: getXAndYStyle(),
        },
      },
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          style: getXAndYStyle(),
        },
        stackLabels: {
          enabled: false,
          // formatter: function () {
          //   const total = handleNumber(this.total);
          //   return total === '0.00' ? '' : `${total}`;
          // },
        },
      },
      tooltip: {
        formatter: function () {
          let s = '<b>' + this.x + '</b>';
          const { points = [] } = this;
          Highcharts.each(points, function (point: any) {
            s +=
              '<br/>' +
              '<span style="color:' +
              point.color +
              '">\u25A0</span>  ' +
              point.series.name +
              ': ' +
              handleNumber(point.y);
          });

          return s;
        },
        shared: true,
      },
      legend: {
        useHTML: true,
        className: 'customized-legend',
        labelFormatter: function () {
          return (
            '<div class="series-label flex items-center">' +
            '<div style="color: ' +
            this.color +
            '">' +
            '<svg width="14" height="14" viewBox="0 0 14 14">' +
            '<rect x="0.300781" y="5.59961" width="2.80009" height="8.40028" fill="currentColor"/>' +
            '<rect x="5.10156" width="2.80009" height="14.0005" fill="currentColor"/>' +
            '<rect x="9.90234" y="8.40039" width="2.80009" height="5.60019" fill="currentColor"/>' +
            '</svg>' +
            '</div>' +
            '<div class="ml-2 text-sm font-medium leading-5">' +
            this.name +
            '</div>' +
            '</div>'
          );
        },
      },
      credits: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      series: chartDatas,
    };
    setOptions(options);
  };

  const handleMore = async () => {
    if (!disabled) {
      setShowMore(true);
    }
  };

  const handleCancel = () => {
    setShowMore(false);
  };

  return (
    <TransitionNode>
      <div className="px-6 py-6 md:px-9 flex flex-col h-full">
        <div className="flex justify-between mb-6">
          <div className="font-bold text-xl text-color-text-1">{title}</div>
          <div
            onClick={handleMore}
            className={`p-2 rounded-full h-8 w-8 ${
              disabled
                ? 'bg-disabled-color text-icon-color'
                : 'cursor-pointer bg-opacity-10 hover:bg-opacity-100 hover:text-color-text-6 text-primary-color bg-primary-color'
            }`}
          >
            <MoreIcon />
          </div>
        </div>
        <div className="flex flex-col mb-4">
          {loadAllData && (
            <div className="h-[45px] flex items-center">
              <Skeleton
                className="h-auto w-48"
                skeletonItemClass="h-6 rounded-2xl"
                row={1}
              />
            </div>
          )}
          {!loadAllData && (
            <div className="text-[30px] text-color-text-1">
              <span className="font-bold mr-2">
                {handleNumber(total_amount)}
              </span>
              <span>{currency}</span>
            </div>
          )}
          <div className="text-color-text-2 font-medium">
            {t('analytics:total_amount')}
          </div>
        </div>
        {loadAllData && <BarChartSkeleton />}
        {!loadAllData && !disabled && (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}
        {!loadAllData && disabled && <NodataContent />}
        <PayinTransactionsDetails
          visible={showMore}
          total={`${
            total.indexOf('-') === -1 ? `+${total}` : total
          } ${currency}`}
          startDate={startDate}
          endDate={endDate}
          onCancel={handleCancel}
          businessID={businessID}
          currency={currency}
          type={IPayinsReportsTypes.PAYIN_TRANSFER_TYPE_BY_DAY}
        />
      </div>
    </TransitionNode>
  );
};

export default PayinByTransferType;
