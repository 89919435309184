import { produce } from 'immer';
import { handleActions } from 'redux-actions';
import ActionType from '@/actions/action-type';
import { IBusiness } from '@/types/business-types';
import { ISetBreadcrumb, ISetBusiness, ISetMenus } from '@/actions/business-action';
import { ISetPermissions } from '@/actions/common-action';
import { IMenuType } from '@/types/side-bar-type';
import { IBreadcrumbItem } from '@/types/header-type';

export interface IBusinessState {
  business: IBusiness;
  permissions: string[];
  menus: IMenuType[];
  breadcrumbData: IBreadcrumbItem[];
  isDisableTimer: boolean;
}

const defaultBusiness: IBusiness = {
  id: '',
  business_name: '',
  country_code: '',
  city: '',
  country_name: '',
  status: '',
  state: '',
  address_1: '',
  address_2: '',
  zip_code: '',
  associate_status: '',
  currency_code: '',
  projectedBalance: 0,
  timezone: '',
  translations: [],
  business_type: '',
  business_application_status: '',
  business_application_uuid: '',
  default_currency: '',
  business_legal_name: '',
  settlement_entity_type: '',
}

const initState: IBusinessState = {
  business: defaultBusiness,
  permissions: [],
  menus: [],
  breadcrumbData: [],
  isDisableTimer: false,
};

const actions: any = {};

actions[ActionType.SET_BUSINESS] = (state: IBusinessState, action: ISetBusiness) => produce(state, draft => {
  if (action.business) {
    draft.business = action.business;
  }
});

actions[ActionType.SET_PERMISSIONS] = (state: IBusinessState, action: ISetPermissions) => produce(state, draft => {
  if (action.permissions) {
    draft.permissions = action.permissions;
  }
});

actions[ActionType.SET_MENUS] = (state: IBusinessState, action: ISetMenus) => produce(state, draft => {
  if (action.menus) {
    draft.menus = action.menus
  }
});

actions[ActionType.SET_BREADCRUMB] = (state: IBusinessState, action: ISetBreadcrumb) => produce(state, draft => {
  if (action.breadcrumbData) {
    draft.breadcrumbData = action.breadcrumbData;
  }
});

export default handleActions(actions, initState);
