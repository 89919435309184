import { useEffect, useState } from 'react';
import AccountWrapper from '../components/account-wrapper';
import { Controller, useForm } from 'react-hook-form';
import { IAreaCodes, IObject } from '@/types/common-types';
import { minus } from '@/utils/galaxy-utils';
import i18n from '@/i18n';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { b2cGetCode, b2cGetLoginStatus, b2cOtpSend, b2cPreSignin, encryptPassword, getQrCodeLink, queryMe } from '@/data/account';
import { Alert } from '@/components/common/alert/alert';
import { useTranslation } from 'react-i18next';
import { Constants } from '@/constants';
import Cookies from 'js-cookie';
import { languageTransform } from '@/types/language-type';
import LockKeyIcon from '../accounts-icons/lock-key-icon';
import QrCodeIcon from '../accounts-icons/qr-code-icon';
import Label from '@/components/common/label';
import PhoneNumber from '@/components/common/phone-number';
import { loadAreaCodesData } from '@/data/area-codes';
import Input from '@/components/common/input';
import EyeIcon from '../accounts-icons/eye-icon';
import EyeOffIcon from '../accounts-icons/eye-off-icon';
import Button from '@/components/common/button';
import QrCode from '@/components/common/qr-code';
import LoadingIcon from '@/components/svg/loading-icon';
import RefreshIcon from '../accounts-icons/refresh-icon';
import Authentication from '../components/authentication';
import BackIcon from '@/components/svg/back-icon';
import { getPlatformConfig } from '@/App';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';
import ActionType from '@/actions/action-type';

let verificationTimout: any = null;
let queryQrLoginStatusInterval: any = null;

interface IPreLoginRes {
  kyc_status: string;
  status: string;
  token: string;
  suspended: boolean;
}

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const method = searchParams.get('method') || 'phone';
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const { t } = useTranslation('signin');
  const Form = useForm({ mode: 'onChange' });
  const lang = i18n.language;

  const { handleSubmit, control, getValues, setError, setValue } = Form;

  const phoneLogin = method === 'phone';
  const showCode = method === 'qrcode';

  const [loginQrCode, setLoginQrCode] = useState('');
  const [loadingQrCode, setLoadingQrCode] = useState(false);
  const [values, setValues] = useState<IObject>({});
  const [showVerification, setShowVerification] = useState(false);
  const [count, setCount] = useState(0);
  const [passwordFlag, setPasswordFlag] = useState(false);
  const [preLoginToken, setPreLoginToken] = useState(''); // 预登陆临时token
  const [loading, setLoading] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isScan, setIsScan] = useState(false);
  const [areaCodes, setAreaCodes] = useState<IAreaCodes[]>([]);
  
  const platformData = getPlatformConfig();
  const { platform = {} } = platformData || {};
  const { systemLogo = '' } = platform;

  useEffect(() => {
    // checkToken();
    loadData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (count === 0) {
      clearTimeout(verificationTimout);
      verificationTimout = null;
    } else {
      verificationTimout = setTimeout(() => {
        verificationTimout = null;
        setCount(minus(count, 1));
      }, 1000);
    }
     // eslint-disable-next-line
  }, [count]);

  useEffect(() => {
    if (showCode) {
      hanleShowCode();
    }
    // eslint-disable-next-line
  }, [showCode]);

  // const checkToken = async() => {
  //   const token = Cookies.get(Constants.TOKEN);
  //   if (token) {
  //     const user = await queryMe();
  //     if (user && user.id) {
  //       const { preferred_lang = 'en' } = user;
  //       const currentLanguage = languageTransform[preferred_lang.replace('-', '_')];
  //       navigate(`/${currentLanguage}/personal-space/business-overview`)
  //     }
  //   }
  // }

  const loadData = async () => {
    navigate(`/${lang}/account/signin`);
    const codes = await loadAreaCodesData();
    setAreaCodes(codes)
  }

  const handlePreLogin = async () => {
    const formValues = getValues();
    if (loading) return;
    setLoading(true);
    // if (!checkeBeforLogin(formValues)) {
    //   setShowVerification(true);
    //   setLoading(false);
    //   return;
    // }
    const newValues = { ...formValues };
    if (phoneLogin) {
      newValues.email = null;
    } else {
      newValues.phone = null;
    }
    const password = encryptPassword(newValues.password);
    const data = await b2cPreSignin(
      {
        phone: newValues.phone,
        email: newValues.email,
        password,
      },
    );

    if (data.code === 200) {
      const record: IPreLoginRes = data.data || {};
      const temporaryToken = record.token;

      if (!checkeBeforSend(record)) return;

      setPreLoginToken(temporaryToken);
      const sendData = await b2cOtpSend(
        {
          phone: newValues.phone,
          email: newValues.email,
          action: 'signin',
          token: temporaryToken,
        }
      );

      if (sendData.code === 200) {
        setShowVerification(true);
        if (verificationTimout) {
          clearTimeout(verificationTimout);
          verificationTimout = null;
        }
        setCount(60);
        Alert.success({
          message: t('signin:verification_code_sent_successfully'),
          wrapClass: '-top-10',
        });
      } else if (sendData.message) {
        Alert.error({
          message: sendData.message,
          wrapClass: '-top-10',
        });
      }
    } else if (data.message) {
      Alert.error({
        message: data.message,
        wrapClass: '-top-10',
      });
    }

    setLoading(false);
    setValues(newValues);
  };

  const checkeBeforSend = (record: IPreLoginRes) => {
    let pass = false;
    const { status, kyc_status, suspended } = record;
    if (
      ['secured', 'active'].includes(status) &&
      kyc_status === 'approved' &&
      !suspended
    ) {
      pass = true;
    } else if (suspended) {
      navigate(`/${lang}/account/suspension-zone`);
    } else {
      navigate(`/${lang}/account/guide`);
    }

    return pass;
  };

  // const checkeBeforLogin = (formValues: IObject) => {
  //   let pass = true;

  //   if (
  //     count > 0 &&
  //     ((phoneLogin && values.phone && values.phone === formValues.phone) ||
  //       (!phoneLogin && values.email && values.email === formValues.email))
  //   ) {
  //     pass = false;
  //   }

  //   return pass;
  // };

  const hanleShowCode = async () => {
    handleClearTimer();
    setLoadingQrCode(true);
    const res = await b2cGetCode();
    if (res.code === 200 && res.data && res.data.id) {
      const result = await getQrCodeLink(res.data.id);
      const { shortChain = {} } = result;
      const { sUrl } = shortChain;
      if (sUrl) {
        setLoginQrCode(sUrl);
        setLoadingQrCode(false);

        queryQrLoginStatusInterval = setInterval(
          () => getQRCodeLoginStatus(res.data.id),
          1000
        );
      }
    }
  };

  const handleHiddenQrCode = () => {
    handleClearTimer();
    navigate(`/${lang}/account/signin`);
  };

  const handleShowCode = () => {
    setIsExpired(false);
    setIsScan(false);
    navigate(`/${lang}/account/signin?method=qrcode`);
  };

  const getQRCodeLoginStatus = async (code: string) => {
    const res = await b2cGetLoginStatus(code, lang);
    const data = res.data || {};
    if (res.code === 200) {
      const { is_scan = false, access_token = '', preferred_lang = 'en', business_id = '' } = res.data || {};
      if (data.is_scan && data.access_token) {

        setIsScan(is_scan);
        handleClearTimer();
        Cookies.set(Constants.TOKEN, access_token);

        const acountLang =
          languageTransform[preferred_lang.replace('-', '_')] || lang;
        if (business_id) {
          dispatch({
            type: ActionType.GOTO_HOME_PAGE,
            payload: {
              navigate,
              businessId: business_id,
              isScan: true,
            }
          });
        } else {
          const user = await queryMe();
          dispatch({
            type: ActionType.GOTO_PERSONAL_SPACE,
            payload: {
              navigate,
              user
            }
          });
        }

        if (acountLang !== lang) {
          i18n.changeLanguage(acountLang)
        };

      }

      if (data.is_expired || data.is_cancel) {
        setIsExpired(true);
        handleClearTimer();
      }
    } else {
      if (res.message) {
        Alert.error({
          message: res.message,
          wrapClass: '-top-10',
        });
      }
      handleClearTimer();
    }
  };

  const handleCallback = (result: IObject) => {
    const { user = {} } = result;
    const { access_token = '', preferred_lang = '' } = user;
    if (access_token) {
      const acountLang =
        languageTransform[preferred_lang.replace('-', '_')] || lang;

      Cookies.set(Constants.TOKEN, access_token);
      dispatch({
        type: ActionType.GOTO_PERSONAL_SPACE,
        payload: {
          navigate,
          user,
        }
      });

      if (acountLang !== lang) {
        i18n.changeLanguage(acountLang)
      }
    }
  };

  const handleRefresh = async () => {
    handleClearTimer();
    setIsExpired(false);
    setIsScan(false);
    await hanleShowCode();
  };

  const handleClearTimer = () => {
    if (queryQrLoginStatusInterval) {
      clearInterval(queryQrLoginStatusInterval);
      queryQrLoginStatusInterval = null;
    }

    if (verificationTimout) {
      clearTimeout(verificationTimout);
      verificationTimout = null;
    }
  };

  const handleChangeMethod = () => {
    let routerStr = '';
    let newVslues = {...values};
    let name = 'email';
    if (method === 'phone') {
      routerStr = `/${lang}/account/signin?method=email`;
    } else if (method === 'email') {
      routerStr = `/${lang}/account/signin`;
      newVslues.phone = '';
      name = 'phone'
    }

    if (routerStr) {
      setValue(name, '');
      setValue('password', '');
      setError(name, {})
      navigate(routerStr);
    }
  };

  return (
    <AccountWrapper>
      <>
        {!showVerification && (
          <div className="flex flex-col w-120 text-color-text-1 bg-color-text-6 pb-16 pt-4 md:rounded-[30px] md:mr-[10%] md:shadow-sign-in-card">
            <div className="flex justify-end pr-4">
              {showCode && (
                <LockKeyIcon
                  className="text-icon-color cursor-pointer hover:text-primary-color"
                  onClick={() => handleHiddenQrCode()}
                />
              )}
              {!showCode && (
                <QrCodeIcon
                  className="text-icon-color cursor-pointer hover:text-primary-color"
                  onClick={handleShowCode}
                />
              )}
            </div>
            <div className="px-6 md:px-15">
              <div className="flex justify-center items-center mt-2">
                <div dangerouslySetInnerHTML={{ __html: systemLogo }} />
              </div>
              {!showCode && (
                <form className="mt-20" onSubmit={handleSubmit(handlePreLogin)}>
                  <Label
                    value={
                      phoneLogin ? t('signin:phone_number') : t('signin:email')
                    }
                  >
                    <div
                      className="text-center whitespace-nowrap text-xs text-primary-color cursor-pointer px-1"
                      onClick={handleChangeMethod}
                    >
                      {phoneLogin
                        ? t('signin:sign_in_with_email')
                        : t('signin:sign_in_with_phone')}
                    </div>
                  </Label>
                  {phoneLogin && (
                    <Controller
                      name={'phone'}
                      control={control}
                      rules={{
                        validate: (value) => {
                          const msg = t('signin:enter_phone_number');
                          if (!value) return msg;
                          if (value && typeof value === 'string') {
                            const newValue = value.substring(
                              value.indexOf(' ') + 1
                            );
                            const code = value.substring(0, value.indexOf(' '));
                            return newValue && code ? undefined : msg;
                          }
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <PhoneNumber
                          name="signin"
                          options={areaCodes}
                          wrapperClass="h-12 rounded-lg"
                          showSearch
                          placeholder={t('signin:phone_number')}
                          onChange={(data) => {
                            onChange(data);
                          }}
                          value={value}
                          error={error}
                        />
                      )}
                    />
                  )}
                  {!phoneLogin && (
                    <Controller
                      name={'email'}
                      control={control}
                      rules={{
                        required: t('signin:this_field_is_required'),
                        pattern: {
                          value:
                            /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                          message: t('signin:invalid_email_address'),
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Input
                          onChange={(data) => {
                            onChange(data);
                          }}
                          value={value}
                          error={error}
                          warpClass="rounded-lg h-12 py-[0]"
                          autoComplete="off"
                          placeholder={t('signin:email_address')}
                        />
                      )}
                    />
                  )}
                  <Label className="mt-6" value={t('signin:password')}>
                    {passwordFlag && (
                      <EyeIcon
                        className="cursor-pointer text-color-text-5 hover:text-icon-color"
                        onClick={() => setPasswordFlag(!passwordFlag)}
                      />
                    )}
                    {!passwordFlag && (
                      <EyeOffIcon
                        className="cursor-pointer text-color-text-5 hover:text-icon-color"
                        onClick={() => setPasswordFlag(!passwordFlag)}
                      />
                    )}
                  </Label>
                  <Controller
                    name={'password'}
                    control={control}
                    rules={{
                      required: t('signin:this_field_is_required'),
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        onChange={(data) => {
                          onChange(data);
                        }}
                        autoComplete="off"
                        warpClass="rounded-lg h-12 py-[0]"
                        type={passwordFlag ? 'text' : 'password'}
                        placeholder={t('signin:password')}
                        style={{ WebkitBoxShadow: '0 0 0 1000px white inset' }}
                        value={value}
                        error={error}
                      />
                    )}
                  />
                  <Link
                    to={`${
                      phoneLogin
                        ? '/account/password/forgot'
                        : '/account/password/forgot?method=email'
                    }`}
                  >
                    <div className="mt-6 mb-10 cursor-pointer text-sm text-color-text-2 underline hover:text-color-text-1">
                      {t('signin:forgot_password')}?
                    </div>
                  </Link>
                  <Button
                    className="w-full h-12 rounded-lg"
                    type="submit"
                    loading={loading}
                  >
                    {t('signin:sign_in')}
                  </Button>
                </form>
              )}
              {showCode && (
                <>
                  <div className="text-sm text-center mt-6">
                    {t('signin:scan_to_login')}
                  </div>
                  <div
                    className={`mt-6 flex flex-col justify-center items-center`}
                  >
                    <div className="p-4 border rounded-3xl relative">
                      <QrCode
                        value={loginQrCode}
                        className={`${
                          loadingQrCode || isExpired || isScan
                            ? 'opacity-10'
                            : ''
                        }`}
                      />
                      {loadingQrCode && (
                        <div className="text-center items-center flex flex-col justify-center absolute inset-0">
                          <div className="text-center cursor-pointer items-center flex flex-col justify-center">
                            <LoadingIcon />
                            <div className="text-sm mt-2 text-[#0094FF]">
                              {t('signin:loading')}
                            </div>
                          </div>
                        </div>
                      )}
                      {isScan && (
                        <div className="text-center items-center flex flex-col justify-center absolute inset-0">
                          <div className="text-center items-center flex flex-col justify-center">
                            <svg
                              width="81"
                              height="80"
                              viewBox="0 0 81 80"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.1"
                                x="0.5"
                                width="80"
                                height="80"
                                rx="40"
                                fill="#10B981"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M24.5 40C24.5 31.2 31.7 24 40.5 24C49.3 24 56.5 31.2 56.5 40C56.5 48.8 49.3 56 40.5 56C31.7 56 24.5 48.8 24.5 40ZM38.2203 46.5144C38.3765 46.6706 38.6298 46.6706 38.786 46.5144L49.0203 36.2801C49.1765 36.1239 49.1765 35.8706 49.0203 35.7144L46.786 33.4801C46.6298 33.3239 46.3765 33.3239 46.2203 33.4801L38.786 40.9144C38.6298 41.0706 38.3765 41.0706 38.2203 40.9144L34.786 37.4801C34.6298 37.3239 34.3765 37.3239 34.2203 37.4801L31.986 39.7144C31.8298 39.8706 31.8298 40.1239 31.986 40.2801L38.2203 46.5144Z"
                                fill="#10B981"
                              />
                            </svg>
                            <div className="font-medium text-success-color mt-4">
                              {t('signin:scanned_successfully')}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="flex items-center mt-6 group cursor-pointer"
                      onClick={handleRefresh}
                    >
                      <RefreshIcon
                        className={`${
                          loadingQrCode ? 'text-color-text-5' : 'text-[#0094FF]'
                        }`}
                      />
                      <span
                        className={`${
                          loadingQrCode ? 'text-color-text-5' : 'text-[#0094FF]'
                        } ml-3`}
                      >
                        {t('signin:refresh')}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {showVerification && (
          <div className="flex flex-col w-120 px-6 md:px-0 md:mr-[10%]">
            <div className="flex flex-col w-full bg-color-text-6 md:rounded-[30px] md:shadow-sign-in-card md:p-15">
              <Authentication
                title={`${t('signin:id_verification')} ✨`}
                showTip={true}
                values={values}
                token={preLoginToken}
                count={count}
                setCount={setCount}
                sendCodeType="signin"
                successMsg={`${t('signin:login_successfully')} !`}
                callback={handleCallback}
              />
            </div>
            <div
              onClick={() => setShowVerification(false)}
              className="flex justify-center items-center text-center text-sm text-color-text-3 hover:text-primary-color cursor-pointer mt-15"
            >
              <BackIcon />
              <span className="ml-2">{t('signin:back')}</span>
            </div>
          </div>
        )}
      </>
    </AccountWrapper>
  )
}

export default LoginPage;
