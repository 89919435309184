import React from 'react';
import type { IIconProps } from '@/types/common-types';

const Adjustment: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="20" fill="#3168C2" />
        <circle
          cx="19.4748"
          cy="19.4836"
          r="9.47147"
          stroke="white"
          strokeWidth="1.71964"
        />
        <path
          d="M18.4537 19.8088L20.3242 19.8088C20.8419 19.8088 21.2631 20.2035 21.2631 20.6886C21.2631 21.1754 20.8426 21.5684 20.3244 21.5684C20.3202 21.5684 20.316 21.5684 20.3118 21.5684L18.5199 21.5684C18.0792 21.5645 17.6531 21.3389 17.4082 20.98L17.3987 20.9661C17.1787 20.6427 16.7207 20.5476 16.3756 20.7538C16.0305 20.9599 15.929 21.3892 16.149 21.7126L16.1591 21.7275C16.6757 22.4843 17.5186 22.9572 18.648 22.9572L18.648 23.79C18.648 24.1735 18.9797 24.4844 19.3889 24.4844C19.7981 24.4844 20.1299 24.1735 20.1299 23.79L20.1299 22.9572C20.838 22.9572 21.4512 22.8368 22.0297 22.2987C22.4911 21.8695 22.7451 21.2977 22.7451 20.6887C22.7451 19.4377 21.6591 18.4201 20.3242 18.4201L18.4537 18.4201C17.936 18.4201 17.5148 18.0255 17.5148 17.5404C17.5148 17.0724 17.9104 16.6606 18.4661 16.6606L20.4212 16.6606C20.8063 16.664 21.1757 16.8329 21.4348 17.1244C21.6968 17.4192 22.164 17.4589 22.4784 17.2135C22.7928 16.968 22.8354 16.5302 22.5734 16.2356C21.88 15.4555 21.0729 15.2718 20.13 15.2718L20.13 14.439C20.13 14.0555 19.7982 13.7446 19.389 13.7446C18.9798 13.7446 18.648 14.0555 18.648 14.439L18.648 15.2718C16.9221 15.2718 16.0329 16.4112 16.0329 17.5404C16.0328 18.7912 17.1188 19.8088 18.4537 19.8088V19.8088Z"
          fill="white"
        />
        <circle cx="27.4289" cy="25.7121" r="4.57143" fill="white" />
        <path
          d="M26.6851 24.2907C26.7487 24.2271 26.8349 24.1914 26.9249 24.1914H29.8636C29.9536 24.1914 30.0398 24.2271 30.1034 24.2907C30.167 24.3543 30.2027 24.4406 30.2027 24.5305C30.2027 24.6204 30.167 24.7067 30.1034 24.7703C30.0398 24.8339 29.9536 24.8696 29.8636 24.8696H26.9249C26.8349 24.8696 26.7487 24.8339 26.6851 24.7703C26.6215 24.7067 26.5858 24.6204 26.5858 24.5305C26.5858 24.4406 26.6215 24.3543 26.6851 24.2907Z"
          fill="#3168C2"
        />
        <path
          d="M23.9724 25.5341C24.036 25.4705 24.1222 25.4347 24.2121 25.4347H27.1509C27.2408 25.4347 27.3271 25.4705 27.3907 25.5341C27.4543 25.5976 27.49 25.6839 27.49 25.7738C27.49 25.8638 27.4543 25.95 27.3907 26.0136C27.3271 26.0772 27.2408 26.1129 27.1509 26.1129H24.2121C24.1222 26.1129 24.036 26.0772 23.9724 26.0136C23.9088 25.95 23.873 25.8638 23.873 25.7738C23.873 25.6839 23.9088 25.5976 23.9724 25.5341Z"
          fill="#3168C2"
        />
        <path
          d="M26.6851 26.7774C26.7487 26.7138 26.8349 26.6781 26.9249 26.6781H29.8636C29.9536 26.6781 30.0398 26.7138 30.1034 26.7774C30.167 26.841 30.2027 26.9272 30.2027 27.0172C30.2027 27.1071 30.167 27.1933 30.1034 27.2569C30.0398 27.3205 29.9536 27.3562 29.8636 27.3562H26.9249C26.8349 27.3562 26.7487 27.3205 26.6851 27.2569C26.6215 27.1933 26.5858 27.1071 26.5858 27.0172C26.5858 26.9272 26.6215 26.841 26.6851 26.7774Z"
          fill="#3168C2"
        />
      </svg>
    </div>
  );
};

export default Adjustment;
