import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import BackIcon from '@/components/svg/back-icon';
import { useEffect, useState } from 'react';
import { getOtheradjustmentFee } from '@/data/account-balance';
import type { IObject } from '@/types/common-types';
import { handleSymbolMount } from '../../../utils/account-balance-untils';
import Adjustment from '../account-balances-icons/adjustment-icon';
import Skeleton from '@/components/skeleton';
import DetailsItem from '@/pages/account-balance/components/details-item-component';
import dayjs from 'dayjs';
import { useParams, useNavigate } from 'react-router-dom';
import { IDispatch } from '@/actions';
import { useDispatch } from 'react-redux';
import ActionType from '@/actions/action-type';

const AdjustmentFeeDetails = () => {
  const { t } = useTranslation("account_balance");
  const { lang = 'en', p_business_id: businessID = '', radjustment_fee_id: recordId = '' } = useParams();
  const [record, setRecord] = useState<IObject>({});
  const [initLoading, setInitLoaing] = useState(false);
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const {
    fee_amount = '',
    fee_currency = '',
    customer_fee_type = '',
    fee_type = '',
    created_at = '',
    remark = '',
    id,
  } = record;

  useEffect(() => {
    getReacord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessID, recordId, lang]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('common:company_account'),
          key: '1',
          type: 'label',
        },
        {
          name: t('account_balance:account_balance'),
          key: '2',
          type: 'link',
          url: `/${lang}/businesses/${businessID}/account-balance/overview`,
        },
        {
          name: t('account_balance:detail_for', { data: recordId }),
          key: '3',
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId, lang])

  const getReacord = async () => {
    setInitLoaing(true);
    const data = await getOtheradjustmentFee({
      businessID,
      id: recordId,
      lang,
    });

    setRecord(data);
    setInitLoaing(false);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Transition.Root show={true} appear={true}>
      <div className="px-4 md:px-6 lg:px-8 py-6">
        <div className="flex mb-6 justify-between">
          <div className="flex">
            <div className="bg-disabled-color shadow-box-drop rounded-full w-10 h-10 flex items-center justify-center cursor-pointer">
              <BackIcon
                className="hover:text-primary-color"
                onClick={handleBack}
              />
            </div>
            <div className="text-3xl leading-10 font-bold text-color-text-1 ml-2.5 invisible sm:visible">
              {t('account_balance:transaction_details')}
            </div>
          </div>
        </div>

        <Transition.Child
          className="rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 h-fit"
          enter="duration-700 transform transition ease-in-out"
          enterFrom="-translate-x-1 translate-y-10 opacity-0"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="duration-500 transform transition"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="items-center md:flex">
            <div className="flex flex-col">
              <div className="flex space-x-1 items-center text-3xl">
                <span className="font-bold">
                  {`${handleSymbolMount(fee_amount)}`}
                </span>
                <span className="text-color-text-1 font-medium">
                  {fee_currency}
                </span>
              </div>
              <span className="text-color-text-2 text-sm mt-2">
                {t('account_balance:adjustment_amount')}
              </span>
            </div>
            <div className="w-0 mb-4 h-0 bg-border-color mx-10 md:w-px md:mb-0 md:h-14" />
            <div>
              <div className="flex flex-col md:flex-row whitespace-nowrap">
                <Adjustment />
                <div className="flex items-center font-medium ml-2 text-color-text-1">
                  <span className="text-color-text-2">
                    {t('account_balance:from')}
                  </span>
                  <span className="ml-1">{customer_fee_type}</span>
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>

        <Transition.Child
          className="flex-1 mt-6"
          enter="duration-350 transform transition ease-in-out"
          enterFrom="-translate-x-1 translate-y-8 opacity-60"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="duration-500 transform transition"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div
            className={`w-full rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit`}
          >
            <div className="font-semibold text-lg text-color-text-1 flex items-center md:text-xl">
              <div className="font-bold text-xl">
                {t('account_balance:payment_details')}
              </div>
              <div className="w-44">
                {initLoading && (
                  <div className={`overflow-hidden inline-block w-60`}>
                    <Skeleton
                      row={1}
                      width={['100%']}
                      skeletonItemClass={`h-4 rounded-2xl mb-0`}
                    />
                  </div>
                )}
                {!initLoading && (
                  <div className="text-primary-color inline-block bg-primary-color bg-opacity-10 font-medium ml-2 px-3 py-1 rounded-3xl text-xs">
                    {fee_type.toLocaleUpperCase()}
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
              <DetailsItem
                title={t('account_balance:adjustment_amount')}
                content={`${handleSymbolMount(fee_amount)} ${fee_currency}`}
                loading={initLoading}
              />
              <DetailsItem
                title={t('account_balance:transaction_time')}
                content={dayjs(created_at).format('MMM D, YYYY, HH:mm')}
                loading={initLoading}
              />
              <DetailsItem
                title={t('account_balance:reference_id')}
                content={id}
                loading={initLoading}
              />
            </div>
            <DetailsItem
              title={t('account_balance:remark')}
              content={remark}
              loading={initLoading}
            />
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};

export default AdjustmentFeeDetails;
