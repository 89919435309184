import ActionType from "@/actions/action-type";
import { IGotoHomePage, ILoadingMainData, PersonalSpacePage } from "@/actions/common-action";
import { queryMe } from "@/data/account";
import { IReducersState } from "@/reducers";
import { select, takeEvery, call, put } from "redux-saga/effects";
import Action from '@/actions';
import { queryBusiness } from "@/data/businesses";
import { IBusiness } from "@/types/business-types";
import { IPermissionsData } from "@/types/permissions-type";
import { queryPermissions } from "@/data/permissions";
import { parsePermissions } from "@/utils/permissions-utils";
import { getMenuData, parseMenuData } from "@/utils/common";
import { IMenuType } from "@/types/side-bar-type";
import i18n from "@/i18n";
import { handleRoutePath } from "@/utils/router-utils";
import { handleQueryBusiness } from "./business.saga";


function* handlePersonalSpacePage(params: PersonalSpacePage) {
  let { navigate, user } = params.payload;
  const lang = i18n.language;

  let url = '/personal-space/business-overview';

  const reducersState: IReducersState = yield select((state: IReducersState) => state);
  const { loginUser } = reducersState.account;

  user = user || loginUser;

  try {
    if (!user) {
      user = yield call(queryMe);
    }
  
    if (!user) {
      navigate(`/${lang}/account/signin`);
      return;
    } else {
      yield put<Action>({
        type: ActionType.SET_LOGIN_USER,
        loginUser: user,
      });
    };
  
    navigate(`/${lang}${url}`);
    
  } catch (error) {
    console.error(error);
  }

}

function* handleLoadingData(params: ILoadingMainData) {
  const { navigate, business: payloadBusiness, businessId, withOutValidate = false } = params.payload;
  if (withOutValidate) return;
  const reducersState: IReducersState = yield select((state: IReducersState) => state);
  const { business, permissions, menus } = reducersState.businessState;
  const { loginUser } = reducersState.account;
  const lang = i18n.language;

  let currentMenus = menus;

  try {

    /* 个人中心进去首页 */
    if (payloadBusiness) {

      yield put<Action>({
        type: ActionType.SET_BUSINESS,
        business: payloadBusiness,
      });

      if ((permissions.length < 1 || menus.length < 1) || payloadBusiness.id !== business.id) {

        const permissionsData: IPermissionsData = yield queryPermissions(payloadBusiness.id);
        const permissions = parsePermissions(permissionsData);
        const { user } = permissionsData;

        if (permissions.length > 0) {

          yield put<Action>({
            type: ActionType.SET_PERMISSIONS,
            permissions,
          });

          const menuData: IMenuType[] = yield getMenuData();
          const newMenuData = parseMenuData(menuData, permissions);
          currentMenus = newMenuData;

          yield put<Action>({
            type: ActionType.SET_MENUS,
            menus: newMenuData,
          });
        }

        if (user && !loginUser.id) {
          yield put<Action>({
            type: ActionType.SET_LOGIN_USER,
            loginUser: user,
          });
        }

        if (permissions.length < 0) {

          navigate(`/${lang}/personal-space/business-overview`);

          return;
        }
  
      }

      yield handleGoHomePage({
        type: ActionType.GOTO_HOME_PAGE,
        payload: {
          navigate,
          businessId: payloadBusiness.id,
          menus: currentMenus
        }
      })

    } else {

      if (businessId && (permissions.length < 1 || menus.length < 1 || businessId !== business.id)) {

        const permissionsData: IPermissionsData = yield queryPermissions(businessId);

        if (permissionsData) {
          const permissions = parsePermissions(permissionsData);
          const { user } = permissionsData;
  
          if (permissions.length > 0) {
  
            yield put<Action>({
              type: ActionType.SET_PERMISSIONS,
              permissions,
            });
  
            const menuData: IMenuType[] = yield getMenuData();
            const newMenuData = parseMenuData(menuData, permissions);
            
            yield put<Action>({
              type: ActionType.SET_MENUS,
              menus: newMenuData,
            });
          }
  
          if (user && !loginUser.id) {
            yield put<Action>({
              type: ActionType.SET_LOGIN_USER,
              loginUser: user,
            });
          }
  
          if (permissions.length < 0) {
            navigate(`/${lang}/personal-space/business-overview`)
          }

        } else {
          navigate(`/${lang}/personal-space/business-overview`)
        }
      }

      if (businessId) {
        if (!business || !business.id) {
          const currentBusiness: IBusiness = yield queryBusiness(businessId);

          yield put<Action>({
            type: ActionType.SET_BUSINESS,
            business: currentBusiness,
          });
        }
      }

    }
    
  } catch (error) {
    console.error(error);
  }
}

function* handleGoHomePage(params: IGotoHomePage) {
  const { navigate, businessId, menus, isScan } = params.payload;
  const reducersState: IReducersState = yield select((state: IReducersState) => state);
  const { menus: stateMenus } = reducersState.businessState;
  const lang = i18n.language;

  let currentMenus = menus || stateMenus;

  try {

    let redirectUrl = '';

    if (isScan) {
      const permissionsData: IPermissionsData = yield queryPermissions(businessId);

      if (permissionsData) {
        const permissions = parsePermissions(permissionsData);
        const { user } = permissionsData;

        if (permissions.length > 0) {

          yield put<Action>({
            type: ActionType.SET_PERMISSIONS,
            permissions,
          });

          const menuData: IMenuType[] = yield getMenuData();
          const newMenuData = parseMenuData(menuData, permissions);
          currentMenus = newMenuData;

          yield put<Action>({
            type: ActionType.SET_MENUS,
            menus: newMenuData,
          });
        }

        if (user) {
          yield put<Action>({
            type: ActionType.SET_LOGIN_USER,
            loginUser: user,
          });
        }

        yield handleQueryBusiness({
          type: ActionType.QUERY_BUSINESS,
          payload: {
            businessId,
          }
        })

        if (permissions.length < 0) {
          redirectUrl = '/personal-space/business-overview';
        }

      } else {
        redirectUrl = '/personal-space/business-overview';
      }
    }

    if (currentMenus.length > 0) {
      const firstApp = currentMenus[0];
      if (firstApp && Array.isArray(firstApp.children)) {
        const menuData = firstApp.children[0];
        redirectUrl = handleRoutePath(menuData, businessId);
      } else {
        redirectUrl = '/personal-space/business-overview';
      }

    }

    if (redirectUrl) {
      navigate(`/${lang}${redirectUrl}`)
    }

  } catch (error) {
    console.error(error);
  }

}

function* commonSaga() {
  yield takeEvery<string, any>(ActionType.GOTO_PERSONAL_SPACE, handlePersonalSpacePage);
  yield takeEvery<string, any>(ActionType.GOTO_HOME_PAGE, handleGoHomePage);
  yield takeEvery<string, any>(ActionType.LOADING_MAIN_DATA, handleLoadingData);
}

export default commonSaga;