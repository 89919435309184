import Button from '@/components/common/button';
import AlipayDetailsIcon from '@/pages/payouts/payouts-icons/alipay-details-icon';
import DownIcon from '@/components/svg/down-icon';
import FPSBankIcon from '@/pages/payouts/payouts-icons/fps-bank-icon';
import FPSEmailIcon from '@/pages/payouts/payouts-icons/fps-email-icon';
import FPSIDIcon from '@/pages/payouts/payouts-icons/fps-id-icon';
import FPSPhoneIcon from '@/pages/payouts/payouts-icons/fps-phone-icon';
import FpsDetailsIcon from '@/pages/payouts/payouts-icons/fps-transfer-details-icon';
import PlatformTransferDetailsIcon from '@/pages/payouts/payouts-icons/platformtransfer-details-icon';
import RtgsDetailsIcon from '@/pages/payouts/payouts-icons/rtgs-details-icon';
import SelectedIcon from '@/pages/payouts/payouts-icons/selected-icon';
import SwiftDetailsIcon from '@/pages/payouts/payouts-icons/swift-details-icon';
import UpIcon from '@/components/svg/up-icon';
import { IPayoutMethod } from '@/types/payouts-type';
import { getValueMultilingual, handleMount } from '@/utils/payouts-utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface ICountryCurrencySelectProps {
  heade: React.ReactNode;
  payoutMethod: IPayoutMethod | undefined;
  payoutMethods: IPayoutMethod[];
  currency: string;
  setPayoutMethod: (payoutMethod: IPayoutMethod) => void;
  setCurrentStep: (currentStep: number) => void;
  setStepCount: (stepCount: number) => void;
}

const other = ['Platform Transfer', 'RTGS', 'SWIFT'];

const PayoutMethodSelect: React.FC<ICountryCurrencySelectProps> = (props) => {
  const {
    heade,
    payoutMethods,
    payoutMethod,
    currency,
    setCurrentStep,
    setPayoutMethod,
    setStepCount,
  } = props;
  const { t } = useTranslation('payees');
  const { t: ct } = useTranslation('common');
  const { lang = 'en' } = useParams();
  const {
    fee_mapping_rules,
    transfer_day_min,
    transfer_day_max,
    limits,
    transfer_method = '',
  } = payoutMethod || {};
  const { max_amount, min_amount } = limits || {};
  const { FPSMethods, otherMethods, alipayMethods } = useMemo(() => {
    const FPSMethods: IPayoutMethod[] = [];
    const otherMethods: IPayoutMethod[] = [];
    const alipayMethods: IPayoutMethod[] = [];
    for (let i = 0; i < payoutMethods.length; i++) {
      const item = payoutMethods[i];
      if (item.transfer_method.indexOf('FPS') !== -1) {
        FPSMethods.push(item);
      } else if (other.includes(item.transfer_method)) {
        otherMethods.push(item);
      }
      if (item.transfer_method.indexOf('Alipay') !== -1) {
        alipayMethods.push(item);
      }
    }

    return {
      FPSMethods,
      otherMethods,
      alipayMethods,
    };
    // eslint-disable-next-line
  }, []);
  const isFPS = transfer_method.indexOf('FPS') !== -1;
  const isAlipay = transfer_method.indexOf('Alipay') !== -1;

  const getInfo = () => {
    return (
      <div>
        {!isFPS && !isAlipay && (
          <div className="mt-3 border-t border-border-color"></div>
        )}
        <div className="grid grid-cols-3 text-sm text-color-text-1 gap-x-6 mt-3">
          <div className="block">
            <div className="text-color-text-2 mb-1">
              {t('transfer_fee')}
            </div>
            <div>
              {handleMount(
                fee_mapping_rules && fee_mapping_rules.length > 0
                  ? fee_mapping_rules[0].fixed_fee
                  : 0,
                2
              )}{' '}
              {currency}
            </div>
          </div>
          <div className="block">
            <div className="text-color-text-2 mb-1">{t('speed')}</div>
            <div>
              {transfer_day_min}-{transfer_day_max} {t('business_day')}
            </div>
          </div>
          <div className="block">
            <div className="text-color-text-2 mb-1">
              {t('transfer_limit')}
            </div>
            {max_amount && min_amount && (
              <div>
                {`${handleMount(min_amount, 2)} - ${handleMount(
                  max_amount,
                  2
                )} ${currency}`}
              </div>
            )}
            {!max_amount && min_amount && (
              <div>
                {t('from')} {handleMount(min_amount, 2)} {currency}
              </div>
            )}
            {!max_amount && !min_amount && <div>{t('no_limit')}</div>}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        {heade}
        <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
        <div className="font-medium text-color-text-3 text-sm mb-2">
          {t('payout_method')}
        </div>
        <div className="flex flex-col gap-y-6">
          {FPSMethods.length > 0 && (
            <div
              className={`rounded-lg border p-4 font-medium text-color-text-1 ${
                isFPS
                  ? 'border-primary-color'
                  : 'border-border-color hover:cursor-pointer hover:bg-disabled-color'
              }`}
              onClick={() => {
                if (!isFPS) {
                  setPayoutMethod(FPSMethods[0]);
                }
              }}
            >
              <div className="flex">
                <div className="mr-3">
                  <FpsDetailsIcon size={24} />
                </div>
                <div className="flex-1">
                  <div>FPS</div>
                  <div className="text-sm text-color-text-2 mt-0.5">
                    {getValueMultilingual(
                      FPSMethods[0].remarks,
                      FPSMethods[0].i18n,
                      lang
                    )}
                  </div>
                </div>
                <div className="flex items-center">
                  {isFPS ? <UpIcon size={20} /> : <DownIcon size={20} />}
                </div>
              </div>
              {isFPS && (
                <>
                  <div className="mt-3 border-b border-border-color" />
                  {FPSMethods.map((item) => {
                    return (
                      <div
                        key={item.transfer_method}
                        className={`mt-3 rounded border px-4 py-2.5 flex items-center text-color-text-1 ${
                          transfer_method === item.transfer_method
                            ? 'border-primary-color'
                            : 'border-border-color hover:cursor-pointer hover:bg-disabled-color'
                        }`}
                        onClick={() => setPayoutMethod(item)}
                      >
                        {item.transfer_method === 'HK FPS - Email' && (
                          <FPSEmailIcon size={24} />
                        )}
                        {item.transfer_method === 'HK FPS - FPS ID' && (
                          <FPSIDIcon size={24} />
                        )}
                        {item.transfer_method === 'HK FPS - Mobile' && (
                          <FPSPhoneIcon size={24} />
                        )}
                        {item.transfer_method === 'HK FPS - Account Number' && (
                          <FPSBankIcon size={24} />
                        )}
                        <div className="flex-1 ml-3">
                          {item.transfer_method}
                        </div>
                        {transfer_method === item.transfer_method && (
                          <SelectedIcon size={16} />
                        )}
                      </div>
                    );
                  })}
                  {getInfo()}
                </>
              )}
            </div>
          )}
          {otherMethods.map((item) => {
            return (
              <div
                key={item.transfer_method}
                className={`rounded-lg border p-4 font-medium text-color-text-1 ${
                  transfer_method === item.transfer_method
                    ? 'border-primary-color'
                    : 'border-border-color hover:cursor-pointer hover:bg-disabled-color'
                }`}
                onClick={() => setPayoutMethod(item)}
              >
                <div className="flex">
                  <div className="mr-3">
                    {item.transfer_method === 'Platform Transfer' && (
                      <PlatformTransferDetailsIcon size={24} />
                    )}
                    {item.transfer_method === 'RTGS' && (
                      <RtgsDetailsIcon size={24} />
                    )}
                    {item.transfer_method === 'SWIFT' && (
                      <SwiftDetailsIcon size={24} />
                    )}
                  </div>
                  <div className="flex-1">
                    <div>{item.transfer_method}</div>
                    <div className="text-sm text-color-text-2 mt-0.5">
                      {getValueMultilingual(item.remarks, item.i18n, lang)}
                    </div>
                  </div>
                  {transfer_method === item.transfer_method && (
                    <div className="flex items-center">
                      <SelectedIcon size={16} />
                    </div>
                  )}
                </div>
                {transfer_method === item.transfer_method && getInfo()}
              </div>
            );
          })}
          {alipayMethods.length > 0 && (
            <div
              className={`rounded-lg border p-4 font-medium text-color-text-1 ${
                isAlipay
                  ? 'border-primary-color'
                  : 'border-border-color hover:cursor-pointer hover:bg-disabled-color'
              }`}
              onClick={() => {
                if (!isAlipay) {
                  setPayoutMethod(alipayMethods[0]);
                }
              }}
            >
              <div className="flex">
                <div className="mr-3">
                  <AlipayDetailsIcon size={24} />
                </div>
                <div className="flex-1">
                  <div>{t('alipay')}</div>
                  <div className="text-sm text-color-text-2 mt-0.5">
                    {getValueMultilingual(
                      alipayMethods[0].remarks,
                      alipayMethods[0].i18n,
                      lang
                    )}
                  </div>
                </div>
                <div className="flex items-center">
                  {isAlipay ? <UpIcon size={20} /> : <DownIcon size={20} />}
                </div>
              </div>
              {isAlipay && (
                <>
                  <div className="mt-3 border-b border-border-color" />
                  {alipayMethods.map((item) => {
                    return (
                      <div
                        key={item.transfer_method}
                        className={`mt-3 rounded border px-4 py-2.5 flex items-center text-color-text-1 ${
                          transfer_method === item.transfer_method
                            ? 'border-primary-color'
                            : 'border-border-color hover:cursor-pointer hover:bg-disabled-color'
                        }`}
                        onClick={() => setPayoutMethod(item)}
                      >
                        {item.transfer_method === 'Alipay - Phone Number' && (
                          <FPSPhoneIcon size={24} />
                        )}
                        {item.transfer_method === 'Alipay - Email' && (
                          <FPSEmailIcon size={24} />
                        )}
                        <div className="flex-1 ml-3">
                          {item.transfer_method}
                        </div>
                        {transfer_method === item.transfer_method && (
                          <SelectedIcon size={16} />
                        )}
                      </div>
                    );
                  })}
                  {getInfo()}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between">
        <Button
          className="flex-1 mr-6"
          onClick={() => {
            setCurrentStep(0);
            setStepCount(0);
          }}
          styleType="Secondary"
        >
          {ct('back')}
        </Button>
        <Button
          className="flex-1"
          onClick={() => setCurrentStep(2)}
          styleType="Primary"
          disabled={!payoutMethod}
        >
          {ct('next')}
        </Button>
      </div>
    </div>
  );
};

export default PayoutMethodSelect;
