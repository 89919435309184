import { AntdSelect, Option } from "@/components/common/antd/select/select";
import Button from "@/components/common/button";
import Label from "@/components/common/label";
import Skeleton from "@/components/skeleton";
import Config from "@/libs/config";
import CheckIcon from '@/components/svg/check-icon';
import { IAreaCodes } from "@/types/common-types";
import { IAvailableCountry, IAvailableCurrency, INewPayeeFormData } from "@/types/payouts-type";
import { getCountryByCurrency } from "@/utils/payouts-utils";
import { useTranslation } from "react-i18next";


interface ICountryCurrencySelectProps {
  availableCurrencies: IAvailableCurrency[];
  availableCountries: IAvailableCountry[];
  areaCodes: IAreaCodes[];
  heade: React.ReactNode;
  payeeData: INewPayeeFormData;
  loading: boolean;
  setPayeeData: (payeeData: INewPayeeFormData) => void;
  handleCurrecyNext: () => void;
}

const CountryCurrencySelect: React.FC<ICountryCurrencySelectProps> = (
  props
) => {
  const {
    availableCurrencies,
    availableCountries,
    heade,
    payeeData,
    loading,
    setPayeeData,
    handleCurrecyNext,
  } = props;
  const { country, currency } = payeeData;
  const prefix = Config.staticPath || '';
  const { t } = useTranslation('payees');
  const { t: ct } = useTranslation('common');

  const handleCountrySelect = (option: any) => {
    const { value, data } = option;
    const { highlighted_currencies: currencies } = data;
    let money = '';
    if (Array.isArray(currencies)) {
      for (let i = 0; i < currencies.length; i++) {
        const currency = currencies[i];
        for (let k = 0; k < availableCurrencies.length; k++) {
          const item = availableCurrencies[k];
          if (item.currency_code === currency) {
            money = currency;
            break;
          }
        }
        if (money) {
          break;
        }
      }
    }

    setPayeeData({ ...payeeData, country: value, currency: money || currency });
  };

  return (
    <div>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        {heade}
        <div className="mt-4 mb-5 border-b border-border-color" />
        <Label
          className="mb-1"
          value={t('destination_country')}
          required={true}
        />
        {loading && <Skeleton row={1} skeletonItemClass="h-10 rounded-3xl" />}
        {!loading && (
          <AntdSelect
            value={country}
            optionLabelProp="label"
            onSelect={(_value, option) => handleCountrySelect(option)}
            showSearch={true}
            filterOption={(value, option) => {
              const key = value.toLocaleLowerCase();
              const { data } = option || {};
              const { display_name = '' } = data || {};
              if (display_name.toLocaleLowerCase().indexOf(key) !== -1) {
                return true;
              }
              return false;
            }}
          >
            {availableCountries.map((item) => {
              const { alpha_2, display_name } = item;
              return (
                <Option
                  key={alpha_2}
                  value={alpha_2}
                  data={item}
                  label={
                    <div className="flex items-center h-full">
                      <img
                        width={16}
                        height={16}
                        src={`${prefix}/images/country-flag/${alpha_2.toLocaleLowerCase()}.svg`}
                        alt=""
                      />
                      <div className="text-sm font-medium text-color-text-1 flex-1 ml-2">
                        {display_name}
                      </div>
                    </div>
                  }
                >
                  <div className="flex items-center">
                    <img
                      width={16}
                      height={16}
                      src={`${prefix}/images/country-flag/${alpha_2.toLocaleLowerCase()}.svg`}
                      alt=""
                    />
                    <div className="text-sm font-medium text-color-text-1 flex-1 ml-2">
                      {display_name}
                    </div>
                    {country === item.alpha_2 && <CheckIcon />}
                  </div>
                </Option>
              );
            })}
          </AntdSelect>
        )}
        <Label
          className="mt-6 mb-1"
          value={t('destination_currency')}
          required={true}
        />
        {loading && <Skeleton row={1} skeletonItemClass="h-10 rounded-3xl" />}
        {!loading && (
          <AntdSelect
            value={currency}
            optionLabelProp="label"
            onSelect={(_value, option) =>
              setPayeeData({ ...payeeData, currency: option.value })
            }
            showSearch={true}
          >
            {availableCurrencies.map((item) => {
              const { currency_code } = item;
              return (
                <Option
                  key={currency_code}
                  value={currency_code}
                  label={
                    <div className="flex items-center h-full">
                      <img
                        width={16}
                        height={16}
                        src={`${prefix}/images/country-flag/${getCountryByCurrency(
                          currency_code
                        )}.svg`}
                        alt=""
                      />
                      <div className="text-sm font-medium text-color-text-1 flex-1 ml-2">
                        {currency_code}
                      </div>
                    </div>
                  }
                >
                  <div className="flex items-center">
                    <img
                      width={16}
                      height={16}
                      src={`${prefix}/images/country-flag/${getCountryByCurrency(
                        currency_code
                      )}.svg`}
                      alt=""
                    />
                    <div className="text-sm font-medium text-color-text-1 flex-1 ml-2">
                      {currency_code}
                    </div>
                    {currency === currency_code && <CheckIcon />}
                  </div>
                </Option>
              );
            })}
          </AntdSelect>
        )}
      </div>
      <div className="flex items-center justify-between">
        <Button
          className="flex-1"
          onClick={handleCurrecyNext}
          styleType="Primary"
          disabled={loading}
        >
          {ct('next')}
        </Button>
      </div>
    </div>
  );
};

export default CountryCurrencySelect;
