import React from 'react';
import type { IIconProps } from '@/types/common-types';

const AddressAddIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="10.9883"
          cy="10"
          r="7.25"
          stroke="#0094FF"
          strokeWidth="1.5"
        />
        <path
          d="M10.9883 6V14"
          stroke="#0094FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.98828 10H14.9883"
          stroke="#0094FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default AddressAddIcon;
