import { all } from 'redux-saga/effects';
import accountSaga from './account-saga';
import CommonSaga from './common-saga';

const rootSaga = function* () {
  yield all([
    accountSaga(),
    CommonSaga(),
  ]);
}

export default rootSaga;
