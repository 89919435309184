import React from 'react';
import type { IIconProps } from '@/types/common-types';

const DownloadIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 20 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.69141 11.8965V13.149C3.69141 13.4811 3.82336 13.7997 4.05824 14.0346C4.29313 14.2695 4.6117 14.4014 4.94387 14.4014H12.4587C12.7908 14.4014 13.1094 14.2695 13.3443 14.0346C13.5792 13.7997 13.7111 13.4811 13.7111 13.149V11.8965"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.57031 8.13916L8.70148 11.2703L11.8326 8.13916"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.69922 3.75391V11.2687"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default DownloadIcon;
