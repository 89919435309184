import { ConfigProvider, DatePicker } from 'antd';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import zhHK from 'antd/lib/locale/zh_HK';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/en';
import 'dayjs/locale/zh-hk';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';


interface IDateRangeProps {
  presets?: {
    label: ReactNode;
    value: any;
  }[];
  format?: string;
  className?: string;
  allowClear?: boolean;
  textColor?: string;
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
  value?: [dayjs.Dayjs, dayjs.Dayjs];
  size?: 'large' | 'middle' | 'small';
  disabledDate?: (date: dayjs.Dayjs) => boolean;
  onChange?: (value: any) => void;
}

const DateRange: React.FC<IDateRangeProps> = (props) => {
  const {
    format = 'MMM D, YYYY',
    presets = [],
    value,
    className = '',
    textColor = 'text-color-text-1',
    allowClear = true,
    size = 'middle',
    placement,
    disabledDate,
    onChange,
  } = props;

  // const { t } = useTranslation();
  const { lang = 'en'} = useParams();
  const [showDate, setShowDate] = useState<boolean>(false);
  const [dateLocale, setDateLocale] = useState<any>(zhHK);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDate(true);
    }, 10);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (lang) {
      let dateLocale = zhHK;
      switch (lang) {
        case 'en':
          dateLocale = enUS;
          break;
        case 'zh_CN':
          dateLocale = zhCN;
          break;
        default:
          break;
      }

      setDateLocale(dateLocale);
      dayjs.locale(lang);
      setRefreshKey((prevKey) => prevKey + 1);
    }
    // eslint-disable-next-line
  }, [lang]);

  const handleDateChange = (value: any) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <>
      {showDate && (
        <ConfigProvider locale={dateLocale}>
          <DatePicker.RangePicker
            locale={dateLocale}
            format={format}
            className={`${className} ${textColor}`}
            presets={presets}
            disabledDate={disabledDate}
            allowClear={allowClear}
            size={size}
            value={value}
            onChange={handleDateChange}
            placement={placement}
            key={refreshKey}
          />
        </ConfigProvider>
      )}
    </>
  );
};

export default DateRange;
