import api from "@/libs/api";
import Config from "@/libs/config";
import { ILoginUser } from "@/types/account-type";
import { IObject } from "@/types/common-types";

export const queryMe = async (config?: IObject): Promise<ILoginUser | undefined> => {

  try {
    return await api.get(`${Config.urls.mainBindo}/user/b2c/me`, {
      ...config
    }).then(( { data } ) => {
      let result = undefined
      if (data.id) {
        result = data;
      }
      return result
    })
  } catch (error) {
    console.error(error);
    return;
  }
}

/* 修改个人信息 */
interface IB2cMeChangeParams {
  display_name: string;
  country: string;
  nationality: string;
}

export const b2cMeChange = async (params: IB2cMeChangeParams) => {
  let result: IObject = {};

  try {

    result = await api.put(`${Config.urls.mainBindo}/user/b2c/me`, params);

  } catch (error) {
    console.error(error);
  }

  return result;
};

/* 验证手机，邮箱是否存在 */

type IB2cSignupJudgeParams =
  | {
      phone: string;
    }
  | {
      email: string;
    };

export const b2cSignupJudge = async (params: IB2cSignupJudgeParams) => {

  let result: IObject = {};

  try {
  
    result = await api.put(`${Config.urls.mainBindo}/user/b2c/signup/judge`, params);

  } catch (error) {
    console.error(error);
  }

  return result;
};

/* 预登陆 */
interface IB2cPreSigninParams {
  phone?: string;
  email?: string;
  password: string;
}

export const b2cPreSignin = async (params: IB2cPreSigninParams) => {

  let result: IObject = {};

  try {
    result = await api.post(`${Config.urls.mainBindo}/user/b2c/signin/pre`,
      params,
      {
        headers: {
          'X-User-Encrypted-Password': 'true',
        }
      }
    )
  } catch (error) {
    console.error(error);
  }

  return result;
}

/* 登陆 */
interface IB2cSigninParams {
  phone?: string;
  email?: string;
  token: string;
  aut_code: string;
}

export const b2cSignin = async (params: IB2cSigninParams) => {
  let result: IObject = {};

  try {
    result = await api.post(`${Config.urls.mainBindo}/user/b2c/signin`, params)
  } catch (error) {
    console.error(error);
  }

  return result;
};

/* 发送验证码 */
interface IB2cOtpSendParams {
  action:
  | 'signin'
  | 'signup'
  | 'pwdreset'
  | 'reset_login_phone_verify'
  | 'reset_login_phone'
  | 'reset_login_email_verify'
  | 'reset_login_email';
  phone?: string;
  email?: string;
  token?: string;
  domainName?: string;
}

export const b2cOtpSend = async (params: IB2cOtpSendParams) => {
  let result: IObject = {};

  try {

    result = await api.post(`${Config.urls.mainBindo}/user/b2c/otp/send`, params )

  } catch (error) {
    console.error(error);
  }

  return result;
}


/* 验证 手机 | 邮箱 */
type IB2cChangePhoneJudgeParams =
  | {
      phone: string;
    }
  | {
      email: string;
    };

export const b2cChangePhoneJudge = async (params: IB2cChangePhoneJudgeParams) => {

  let result: IObject = {};

  try {

    result = await api.post(`${Config.urls.mainBindo}/user/b2c/change_phone/judge`, params);

  } catch (error) {
    console.error(error);
  }

  return result;

};

export const b2cChangeEmailJudge = async (params: IB2cChangePhoneJudgeParams) => {
  
  let result: IObject = {};

  try {

    result = await api.post(`${Config.urls.mainBindo}/user/b2c/change_email/judge`, params);

  } catch (error) {
    console.error(error);
  }

  return result;
  
};

/* 预注册 */

interface IB2cPreSignupParams {
  token: string;
  user: {
    phone: string;
    email: string;
    display_name: string;
    country: string;
    nationality: string;
    user_reference: string;
    referral_code: string;
  };
}

export const b2cPreSignup = async (params: IB2cPreSignupParams) => {

  let result: IObject = {};

  try {

    result = await api.post(`${Config.urls.mainBindo}/user/b2c/signup/pre`, params);

  } catch (error) {
    console.error(error);
  }

  return result;
  
};

/* 修改kyc状态 */
interface IB2cKycStatusParams {
  token: string;
}

export const b2cKycStatus = async (params: IB2cKycStatusParams) => {

  let result: IObject = {};

  try {

    result = await api.post(`${Config.urls.mainBindo}/user/b2c/kyc/status`, params);

  } catch (error) {
    console.error(error);
  }

  return result;
};

/* 检查kyc状态 */

export const b2cKycStatusCheck = async (user_reference: string) => {

  let result: IObject = {};

  try {

    result = await api.get(`${Config.urls.mainBindo}/user/b2c/kyc/${user_reference}`);

  } catch (error) {
    console.error(error);
  }

  return result;
};

/* 注册 */
interface IB2cSigupParams {
  token: string;
  password: string;
  user_reference: string;
}

export const b2cSigup = async (params: IB2cSigupParams) => {

  let result: IObject = {};

  try {

    result = await api.post(`${Config.urls.mainBindo}/user/b2c/signup`, params);

  } catch (error) {
    console.error(error);
  }

  return result;
};


/* 验证验证码 */
interface IB2cOtpVerifyParams {
  action:
  | 'signin'
  | 'signup'
  | 'pwdreset'
  | 'reset_login_phone_verify'
  | 'reset_email_phone_verify';
  aut_code: string;
  token?: string;
  email?: string;
  phone?: string;
}

export const b2cOtpVerify = async (params: IB2cOtpVerifyParams) => {

  let result: IObject = {};

  try {

    result = await api.post(`${Config.urls.mainBindo}/user/b2c/otp/verify`, params);

  } catch (error) {
    console.error(error);
  }

  return result;
};

/* 获取二维码code */
export const b2cGetCode = async () => {
  let result: IObject = {};

  try {

    result = await api.post(`${Config.urls.mainBindo}/user/b2c/qr_code`);
  
  } catch (error) {
    console.error(error);
  }

  return result;
};

/* 获取二维码url */
export const getQrCodeLink = async (id: string) => {
  let result: IObject = {};

  try {
    const resp: IObject = await api.post(`${Config.urls.gatewayUrl}/api/short-chain/qrcode-links`,
      {
        type: 'Normal',
        link_type: 'Scan Code Login',
        data: {
          uuid: id,
          client_id: Config.clientID,
        },
      },
      {
        headers: {
          'authorization': Config.appAuthorization,
        },
      }
    )

    if (resp && resp.code === 200 && resp.data) {
      result = resp.data;
    }

  } catch (error) {
    console.error(error);
  }

  return result;
}

/* 验证token */

export const b2cVerifyToken = async (accessToken: string) => {

  let result: IObject = {};

  try {

    result = await api.get(`${Config.urls.mainBindo}/user/b2c/token/verify`);

  } catch (error) {
    console.error(error);
  }

  return result;
};


/* 查询code状态 */
export const b2cGetLoginStatus = async (code: string, lang: string) => {
  let result: IObject = {};

  try {
  
    result = await api.get(`${Config.urls.mainBindo}/user/b2c/qr_code/info?id=${code}`);

  } catch (error) {
    console.error(error);
  }

  return result;
};

/* 修改密码前验证密码 */
export const b2cChangePwdVerify = async (password: string) => {
  let result: IObject = {};

  try {
    result = await api.post(`${Config.urls.mainBindo}/user/b2c/change_pwd/old/verify`,
      {
        password,
      },
    )
  } catch (error) {
    console.error(error);
  }

  return result;
}


/* 修改密码 */
interface IB2cChangePwdParams {
  new_password: string;
  token: string;
}

export const b2cChangePwd = async (params: IB2cChangePwdParams) => {
  let result: IObject = {};

  try {
    result = await api.post(`${Config.urls.mainBindo}/user/b2c/change_pwd`,
      params,
      {
        headers: {
          'X-User-Encrypted-Password': 'true',
        }
      }
    )
  } catch (error) {
    console.error(error);
  }

  return result;
}

/* 重置密码 */
interface IB2cResetPwdParams {
  password: string;
  token: string;
}

export const b2cResetPwd = async (params: IB2cResetPwdParams) => {
  let result: IObject = {};

  try {
    result = await api.post(`${Config.urls.mainBindo}/user/b2c/forget_pwd`,
      params,
      {
        headers: {
          'X-User-Encrypted-Password': 'true',
        }
      }
    )
  } catch (error) {
    console.error(error);
  }

  return result;
}

/* 修改邮箱 */

interface IB2cChangeEmailParams {
  email: string;
  token: string;
  aut_code: string;
}

export const b2cChangeEmail = async (params: IB2cChangeEmailParams) => {
  let result: IObject = {};

  try {

    result = await api.post(`${Config.urls.mainBindo}/user/b2c/change_email`, params);

  } catch (error) {
    console.error(error);
  }

  return result;
};

/* 修改手机 */
interface IB2cChangePhoneParams {
  phone: string;
  token: string;
}

export const b2cChangePhone = async (params: IB2cChangePhoneParams) => {

  let result: IObject = {};

  try {

    result = await api.post(`${Config.urls.mainBindo}/user/b2c/change_phone`, params);

  } catch (error) {
    console.error(error);
  }

  return result;
};


/* 密码加密 */

export const encryptPassword = (psw: string) => {
  const { JSEncrypt } = require('jsencrypt');
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(Config.publicKey);

  return jsEncrypt.encrypt(psw);
};