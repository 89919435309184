import React from 'react';
import type { IIconProps } from '@/types/common-types';

const MerchantServicesIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 32 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="17.5"
          cy="17.5"
          r="17.5"
          transform="rotate(180 17.5 17.5)"
          fill="#FFA217"
        />
        <circle
          cx="16.2094"
          cy="16.7651"
          r="7.01888"
          stroke="white"
          strokeWidth="1.4924"
        />
        <path
          d="M15.4425 17.0102L16.8484 17.0102C17.2375 17.0102 17.5541 17.3069 17.5541 17.6715C17.5541 18.0374 17.238 18.3328 16.8485 18.3328C16.8454 18.3328 16.8422 18.3327 16.8391 18.3327L15.4923 18.3327C15.161 18.3298 14.8408 18.1602 14.6567 17.8905L14.6496 17.8801C14.4842 17.637 14.1399 17.5655 13.8806 17.7205C13.6212 17.8754 13.545 18.1981 13.7103 18.4411L13.7179 18.4523C14.1062 19.0211 14.7397 19.3766 15.5885 19.3766L15.5885 20.0025C15.5885 20.2908 15.8379 20.5244 16.1454 20.5244C16.453 20.5244 16.7024 20.2907 16.7024 20.0025L16.7024 19.3766C17.2346 19.3766 17.6954 19.2861 18.1303 18.8816C18.477 18.5591 18.668 18.1293 18.668 17.6715C18.668 16.7313 17.8517 15.9665 16.8484 15.9665L15.4425 15.9665C15.0534 15.9665 14.7368 15.6698 14.7368 15.3052C14.7368 14.9535 15.0342 14.644 15.4518 14.644L16.9213 14.644C17.2108 14.6465 17.4883 14.7735 17.6831 14.9926C17.88 15.2141 18.2312 15.244 18.4675 15.0596C18.7038 14.8751 18.7358 14.546 18.5389 14.3245C18.0177 13.7382 17.4112 13.6002 16.7024 13.6002L16.7024 12.9742C16.7024 12.686 16.453 12.4523 16.1455 12.4523C15.8379 12.4523 15.5886 12.686 15.5886 12.9742L15.5886 13.6002C14.2914 13.6002 13.623 14.4565 13.623 15.3052C13.6229 16.2454 14.4392 17.0102 15.4425 17.0102Z"
          fill="white"
        />
        <rect
          x="19.7113"
          y="16.7113"
          width="7.68889"
          height="2.35556"
          rx="1.17778"
          fill="#FFA217"
          stroke="white"
          strokeWidth="1.2"
        />
        <rect
          x="19.7113"
          y="19.3773"
          width="7.68889"
          height="2.35556"
          rx="1.17778"
          fill="#FFA217"
          stroke="white"
          strokeWidth="1.2"
        />
        <rect
          x="19.7113"
          y="22.0443"
          width="7.68889"
          height="2.35556"
          rx="1.17778"
          fill="#FFA217"
          stroke="white"
          strokeWidth="1.2"
        />
      </svg>
    </div>
  );
};

export default MerchantServicesIcon;
