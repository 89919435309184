import api from "@/libs/api";
import Config from "@/libs/config";
import { IPaymentGatewayReportsRecord } from "@/types/analytics-type";
import { IObject } from "@/types/common-types";
import { CancelTokenSource } from "axios";

export enum IAnalyticsTypes {
  GATEWAY_OVERVIEW = 'gesid_payment_gateway_overview',
  TRANSACTION_TYPES = 'gesid_payment_gateway_breakdown_by_transaction_types',
  PAYMENT_METHODS = 'gesid_payment_gateway_breakdown_by_payment_methods',
  SUMMARY_PAYMENT_METHODS = 'gesid_payment_gateway_summary_by_payment_methods',
  TRANSACTION_TICKET = 'gesid_average_transaction_ticket_by_days',

  SETTLEMENTS_OVERVIEW = 'gesid_settlements_overview',
  SETTLEMENTS_ADVICE = 'gesid_settlement_advice',
  // SETTLEMENTS_PAYMENT_METHODS = 'gesid_settlements_breakdown_by_payment_methods',
  // SETTLEMENTS_SUMMARY_PAYMENT_METHODS = 'gesid_settlements_summary_by_payment_methods',
}

interface IGetAnalyticsParams {
  p_business_id: string;
  start_date: string;
  end_date: string;
  type: IAnalyticsTypes;
  currency_code: string;
  detail?: boolean;
  offset?: number;
  limit?: number;
  search_type?: string;
}

export const loadAnalyticsData = async (params: IGetAnalyticsParams, cancelTokenSource?: CancelTokenSource) => {
  
  let result: any = params.detail ? [] : {};
  try {
    const resp: IObject = await api.post(
      `${Config.urls.gatewayUrl}/api/gateway/query/report_analytics`,
      params,
      {
        headers: {
          'x-p-business-id': params.p_business_id,
        },
        cancelToken: cancelTokenSource?.token,
        timeout: 30 * 1000,
      }
    );

    if (resp && resp.code === 200 && resp.data) {
      result = resp.data;
    }
  } catch (error) {
    console.error(error);
  }

  return result;
};

interface IGetAllRecordsParams {
  p_business_id: string;
  start_date: string;
  end_date: string;
  currency_code: string;
  active?: 'transactions' | 'settlement';
}

export const getAllRecords = async (params: IGetAllRecordsParams, cancelTokenSource?: CancelTokenSource) => {
  let allResult: IPaymentGatewayReportsRecord[] = [];
  const { active = 'transactions' } = params;
  try {
    let types = [
      IAnalyticsTypes.GATEWAY_OVERVIEW,
      IAnalyticsTypes.TRANSACTION_TYPES,
      IAnalyticsTypes.PAYMENT_METHODS,
      IAnalyticsTypes.SUMMARY_PAYMENT_METHODS,
      IAnalyticsTypes.TRANSACTION_TICKET,
    ];

    if (active === 'settlement') {
      types = [
        IAnalyticsTypes.SETTLEMENTS_OVERVIEW,
        IAnalyticsTypes.SETTLEMENTS_ADVICE,
      ];
    }

    const allPromise: any[] = [];

    types.forEach((type) => {
      allPromise.push(
        loadAnalyticsData(
          {
            p_business_id: params.p_business_id,
            start_date: params.start_date,
            end_date: params.end_date,
            type: type,
            currency_code: params.currency_code,
            detail: false,
          }, cancelTokenSource
        )
      );
    });

    allResult = await Promise.all(allPromise);
  } catch (error) {
    console.error(error);
  }

  return allResult;
};

interface IloadReportsDataParams {
  p_business_id: string;
  start_date: string;
  end_date: string;
  type: string;
  currency_code: string;
  activity?: boolean;
  offset?: number;
  limit?: number;
  filter?: IObject;
}

export const loadReportsData = async (params: IloadReportsDataParams) => {
  let result: any = params.activity ? [] : {};

  try {
    const resp: IObject = await api.post(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/analytics/search`,
      params,
      {
        headers: {
          'x-p-business-id': params.p_business_id,
        },
      }
    );

    if (resp && resp.code === 200 && resp.data) {
      result = resp.data;
      const { points = [] } = result || {};

      points.sort(
        (a: any, b: any) =>
          new Date(a.x_axis).getTime() - new Date(b.x_axis).getTime()
      );
    }
  } catch (error) {
    console.error(error);
  }

  return result;
};

export enum IPayinsReportsTypes {
  PAYIN_HISTORY = 'payin_history',
  PAYIN_TRANSFER_TYPE_BY_DAY = 'payin_transfer_type_by_day',
  PAYIN_TRANSFER_MEtHOD_BY_DAY = 'payin_transfer_method_by_day',
  PAYIN_TRANSFER_MEtHOD = 'payin_transfer_method',
  PAYIN_PAYER = 'payin_payer',
  PAYIN_TRANSFER_STATUS_BY_DAY = 'payin_transfer_status_by_day',
}

export enum IPayoutsReportsTypes {
  PAYOUT_HISTORY = 'payout_history',
  PAYOUT_CATEGORY = 'payout_category',
  PAYOUT_TRANSFER_METHOD = 'payout_transfer_method',
  PAYOUT_PAYEE = 'payout_payee',
  PAYOUT_TRANSFER_STATUS_BY_DAY = 'payout_transfer_status_by_day',
}

interface IGetReportRecordsParams {
  p_business_id: string;
  start_date: string;
  end_date: string;
  currency_code: string;
  active?: 'payin' | 'payout';
}

export const getReportRecords = async (params: IGetReportRecordsParams) => {
  const {
    active = 'payin',
    p_business_id,
    start_date,
    end_date,
    currency_code,
  } = params;

  let allType: string[] = Object.values(IPayinsReportsTypes);

  if (active === 'payout') {
    allType = Object.values(IPayoutsReportsTypes);
  }
  const allPromise: any[] = [];
  let allResult: any[] = [];

  try {
    allType.forEach((type) => {
      allPromise.push(
        loadReportsData(
          {
            p_business_id,
            start_date,
            end_date,
            type: type,
            currency_code,
          }
        )
      );
    });

    allResult = await Promise.all(allPromise);
  } catch (error) {
    console.error(error);
  }

  return allResult;
};

export const getGlobalCategories = async () => {
  let result: any[] = [];
  try {
    const resp: IObject = await api.get(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/global_information/revenue_expense_categories`);

    if (resp && resp.code === 200 && resp.data && Array.isArray(resp.data)) {
      result = resp.data;
    }
  } catch (error) {
    console.error(error);
  }

  return result;
};
