import React from 'react';

interface IIconProps {
  className?: string;
  svgClassName?: string;
  size?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const AbountUsWonderIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="5em"
        height="1em"
        viewBox="0 0 516 111"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M75.0224 55.5256C74.461 53.2334 73.8529 50.3564 73.198 46.8947C72.5898 43.3862 72.0986 40.7197 71.7244 38.8953L70.6586 33.0976C70.5204 32.3456 69.4412 32.3494 69.3083 33.1025L68.2861 38.8953C67.9118 40.7197 67.3972 43.3862 66.7423 46.8947C66.1342 50.3564 65.5494 53.2334 64.9881 55.5256L53.4583 105.313C52.9781 107.387 51.1311 108.855 49.0027 108.855H27.185C25.0742 108.855 23.2375 107.41 22.7402 105.359L0.257841 12.619C-0.439992 9.74044 1.74069 6.96793 4.70263 6.96793H17.0653C19.1983 6.96793 21.048 8.44237 21.5236 10.5217L31.6573 54.8239C33.201 61.8409 34.5343 67.4545 35.657 71.6647L39.5163 88.5757L57.5047 10.5145C57.983 8.43864 59.8312 6.96793 61.9614 6.96793H77.9819C80.1108 6.96793 81.9581 8.43675 82.4378 10.5108L100.494 88.5757C102.038 81.699 103.371 76.062 104.494 71.6647L118.487 10.5212C118.962 8.44213 120.812 6.96793 122.945 6.96793H135.308C138.27 6.96793 140.45 9.74043 139.753 12.619L117.27 105.359C116.773 107.41 114.936 108.855 112.825 108.855H91.0078C88.8794 108.855 87.0323 107.387 86.5521 105.313L75.0224 55.5256Z"
          fill="#0094FF"
        />
        <path
          d="M177.757 31.8081C190.154 31.8081 199.65 35.1997 206.246 41.9828C212.842 48.7191 216.14 58.4026 216.14 71.0332C216.14 83.6638 212.842 93.3941 206.246 100.224C199.65 107.054 190.154 110.469 177.757 110.469C165.501 110.469 156.051 107.077 149.408 100.294C142.765 93.4642 139.444 83.7106 139.444 71.0332C139.444 58.4026 142.742 48.7191 149.338 41.9828C155.934 35.1997 165.407 31.8081 177.757 31.8081ZM177.757 47.9472C166.67 47.9005 161.127 55.5958 161.127 71.0332C161.127 86.4706 166.67 94.1893 177.757 94.1893C188.891 94.1893 194.457 86.4706 194.457 71.0332C194.457 55.5958 188.891 47.9005 177.757 47.9472Z"
          fill="#0094FF"
        />
        <path
          d="M245.109 42.6753C245.232 43.2277 245.936 43.4048 246.32 42.9886C252.842 35.9092 261.262 32.3695 271.58 32.3695C279.439 32.3695 285.497 34.6851 289.754 39.3163C294.011 43.9008 296.139 50.4032 296.139 58.8236V104.281C296.139 106.807 294.092 108.855 291.566 108.855H279.171C276.645 108.855 274.597 106.807 274.597 104.281V60.0165C274.597 55.6192 273.732 52.4381 272.001 50.4734C270.27 48.5086 267.487 47.5262 263.651 47.5262C260.189 47.5262 256.961 48.3215 253.967 49.912C252.32 50.7614 250.85 51.7808 249.556 52.9701C247.487 54.873 246.74 57.7315 246.74 60.5427V104.281C246.74 106.807 244.692 108.855 242.166 108.855H229.701C227.175 108.855 225.127 106.807 225.127 104.281V38.1359C225.127 35.61 227.175 33.5624 229.701 33.5624H239.419C241.564 33.5624 243.421 35.0529 243.885 37.147L245.109 42.6753Z"
          fill="#0094FF"
        />
        <path
          d="M359.965 103.31C359.371 101.707 357.284 101.293 356.015 102.438C353.629 104.591 351.145 106.262 348.562 107.451C345.054 109.042 341.054 109.837 336.563 109.837C326.225 109.837 318.155 106.586 312.354 100.084C306.554 93.5812 303.653 84.4357 303.653 72.6471C303.653 60.1101 307.255 50.2629 314.459 43.1055C321.71 35.9482 331.651 32.3695 344.282 32.3695C347.931 32.3695 352.141 32.8607 356.912 33.8431V5.3665C356.912 2.84061 358.96 0.792969 361.486 0.792969H373.951C376.477 0.792969 378.525 2.84061 378.525 5.36651V104.141C378.525 106.667 376.477 108.715 373.951 108.715H365.149C363.235 108.715 361.524 107.523 360.859 105.728L359.965 103.31ZM340.984 94.6103C344.726 94.6103 348.001 93.5578 350.808 91.4527C353.214 89.6771 355.039 87.3191 356.281 84.3787C356.748 83.2731 356.912 82.0702 356.912 80.8701V52.2642C356.912 50.1451 355.459 48.2899 353.385 47.8537C352.261 47.6171 351.308 47.4377 350.527 47.3157C349.03 47.035 347.346 46.8947 345.475 46.8947C338.972 46.8947 333.99 49.0232 330.528 53.2802C327.067 57.4904 325.336 63.7121 325.336 71.9454C325.336 87.1021 330.552 94.6571 340.984 94.6103Z"
          fill="#0094FF"
        />
        <path
          d="M409.796 75.2434C408.454 75.2434 407.392 76.397 407.598 77.7229C409.327 88.8876 415.824 94.47 427.088 94.47C430.83 94.47 434.596 94.049 438.385 93.2069C441.567 92.4607 445.028 91.3518 448.77 89.8802C449.967 89.4093 451.329 89.9867 451.794 91.1866L455.364 100.399C455.799 101.524 455.287 102.796 454.183 103.282C443.517 107.977 433.62 110.302 424.492 110.258C412.61 110.258 403.277 106.797 396.494 99.8731C389.711 92.9496 386.319 83.3363 386.319 71.0332C386.319 58.7768 389.641 49.2337 396.283 42.4038C402.926 35.5271 412.235 32.0888 424.211 32.0888C446.712 32.0888 457.963 44.8364 457.963 70.3315V72.9566C457.963 74.2196 456.939 75.2434 455.676 75.2434H409.796ZM435.751 62.7531C437.067 62.7531 438.121 61.6413 437.952 60.3371C436.803 51.4691 432.27 47.035 424.351 47.035C419.58 47.035 415.814 48.3449 413.054 50.9646C410.811 53.0911 409.208 56.1445 408.244 60.1246C407.913 61.4915 408.994 62.7531 410.401 62.7531H435.751Z"
          fill="#0094FF"
        />
        <path
          d="M486.131 42.4207C486.278 43.0124 487.075 43.1378 487.418 42.6336C492.071 35.7909 498.394 32.3695 506.386 32.3695C508.21 32.3695 509.754 32.4864 511.017 32.7203C511.368 32.7705 511.744 32.8341 512.146 32.9111C514.38 33.3399 515.78 35.5185 515.486 37.7747L514.279 47.01C513.901 49.9065 510.944 51.6662 508.082 51.0795C506.22 50.6978 504.485 50.5192 502.877 50.5435C499.509 50.5435 496.655 51.1517 494.316 52.368C491.978 53.5375 490.013 55.4087 488.422 57.9816V104.281C488.422 106.807 486.375 108.855 483.849 108.855H471.383C468.857 108.855 466.81 106.807 466.81 104.281V38.1359C466.81 35.61 468.857 33.5624 471.383 33.5624H480.355C482.456 33.5624 484.287 34.9943 484.793 37.0337L486.131 42.4207Z"
          fill="#0094FF"
        />
      </svg>
    </div>
  );
};

export default AbountUsWonderIcon;
