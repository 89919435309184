import type { FieldError } from 'react-hook-form';
import type { ChangeEvent, InputHTMLAttributes, ReactElement } from 'react';

import { useEffect, useState } from 'react';
import CleanIcon from '@/components/svg/clean-icon';

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLElement>, 'size' | 'onChange'> {
  /**是否禁用 Input */
  disabled?: boolean;
  /**设置 input 大小，支持 lg md sm */
  // size?: InputSize;

  /**添加前缀 用于配置一些固定组合 */
  prepend?: string | ReactElement;
  /**添加后缀 用于配置一些固定组合 */
  append?: string | ReactElement;
  onChange?: (value: string) => void;
  warpClass?: string;
  allowClear?: boolean;
  error?: FieldError;
  defaultValue?: string;
  value?: string;
  type?: string;
}

export const Input: React.FC<InputProps> = (props) => {
  const {
    className = '',
    warpClass = '',
    disabled = false,
    allowClear = true,
    error,
    onChange,
    value = '',
    type = 'text',
    ...restProps
  } = props;

  useEffect(() => {
    setInputValue(value || '');
    // eslint-disable-next-line
  }, [value]);

  const [inputValue, setInputValue] = useState<string>();

  const changeIpt = (evt: ChangeEvent<HTMLInputElement>) => {
    //改变文本框
    setInputValue(evt.target.value);
    if (onChange) {
      onChange(evt.target.value);
    }
  };

  const showClean =
    allowClear && inputValue && inputValue.length > 0 && !disabled;

  return (
    <div className={`flex-grow ${className} group`}>
      <div
        className={`group rounded flex flex-1 items-center h-10 px-3 py-2 cursor-auto border border-solid hover:border-primary-color ${warpClass}
           ${disabled ? 'border-none pl-0' : 'shadow-box-drop'} ${error && error.message ? 'border-error-color' : 'border-border-color'
          }
          `}
      >
        <input
          {...restProps}
          style={{
            WebkitBoxShadow: '0 0 0 1000px white inset',
            ...restProps.style,
            fontFamily: 'Roboto',
          }}
          value={disabled && !inputValue && !value ? '--' : value || inputValue}
          className={`
            focus:outline-none border-none flex-grow font-medium text-color-text-1 bg-inherit
          placeholder:text-color-text-5 placeholder:font-normal placeholder:text-sm text-sm pr-2 ${disabled ? 'text-ellipsis' : ''
            }
            w-full
          `}
          autoComplete="none"
          disabled={disabled}
          onChange={(evt) => {
            changeIpt(evt);
          }}
          type={type}
        />
        <span className={`invisible ${showClean ? 'group-hover:visible' : ''}`}>
          <CleanIcon
            size={20}
            className="cursor-pointer text-color-text-5 hover:text-color-text-3"
            onClick={() => {
              setInputValue('');
              onChange && onChange('');
            }}
          />
        </span>
      </div>
      {error && (
        <div className="mt-1 text-sm text-error-color">{error.message}</div>
      )}
    </div>
  );
};

export default Input;
