import { IObject } from '@/types/common-types';
import { insertComma } from '@/utils';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import Config from '@/libs/config';
import type { ICartParams, IPaymentSettings } from '../types/terminal-type';
import { minus, plus } from '@/utils/galaxy-utils';
import PayTypes from '../pages/terminal/utils/payment/pay-types';
import DiscoverIcon from '../pages/terminal/terminal-icons/discover-icon';
import DinersIcon from '../pages/terminal/terminal-icons/diners-icon';
import MasterCardIcon from '../pages/terminal/terminal-icons/mastercard-icon';
import JCBIcon from '../pages/terminal/terminal-icons/jcb-icon';
import VisaIcon from '../pages/terminal/terminal-icons/visa-icon';
import UnionPayIcon from '../pages/terminal/terminal-icons/union-pay-icon';
import type { HolidaysTypes } from 'date-holidays';
import Holidays from 'date-holidays';
import { trim } from 'lodash';
import utc from 'dayjs/plugin/utc';
import { transformField } from '@/utils/field-utils';
import { TFunction } from 'i18next';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Hong_Kong');

export const isAfterSpecifiedTime = (specifiedTime: any) => {
  const [dateString, timeRange] = specifiedTime;
  if (timeRange) {
    const endTime = timeRange.split(' ~ ')[1];
    const currentTime = dayjs();
    const date = dayjs(dateString);
    const end = dayjs(`${date.format('YYYY-MM-DD')} ${endTime}`);
    const modifiedEndTime = end.subtract(1, 'hour').subtract(10, 'minute');
    return currentTime.isAfter(modifiedEndTime);
  } else {
    return true
  }
}

export const handlePrice = (value: number) => {
  const total = new BigNumber(value).absoluteValue().toFixed(2).split('.');

  let amount = '';
  if (value) {
    if (total.length > 1) {
      amount = `${insertComma(total[0])}.${insertComma(
        total[1],
        false,
        3,
        ','
      )}`;
    } else {
      amount = `${insertComma(total[0])}`;
    }
  } else {
    amount = '0.00';
  }

  return amount;
};

export const handleExit = () => {
  (window as any).handleWonder?.postMessage(
    JSON.stringify({
      context: 'Navigator',
      action: 'close',
    })
  );
};

export const triggerWonderRoute = (value = '', action = 'route') => {
  const message = JSON.stringify({
    context: 'Navigator',
    action: action,
    content: value,
  });

  (window as any).flutter_inappwebview?.callHandler('handleWonder', message);
};

export const judgeBrowserAndTerminal = (info: any) => {
  const userAgentInfo = info ? info : navigator && navigator.userAgent;
  let isPC = false;
  let isSafari = false;
  let isPhone = false;
  let isWX = false;
  let isAlipayCN = false;
  let isAlipayHK = false;
  const client =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgentInfo
    );
  if (client) {
    isPhone = true;
  } else {
    isPC = true;
  }
  if (userAgentInfo.indexOf('MSIE') >= 0) {
  } else if (userAgentInfo.indexOf('Firefox') >= 0) {
  } else if (userAgentInfo.indexOf('Chrome') >= 0) {
  } else if (userAgentInfo.indexOf('Opera') >= 0) {
  } else if (userAgentInfo.indexOf('Safari') >= 0) {
    isSafari = true;
  }

  if (isPhone) {
    isSafari = !!userAgentInfo.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  }

  if (userAgentInfo.toLowerCase().indexOf('micromessenger') !== -1) {
    isWX = true;
  }
  if (userAgentInfo.toLowerCase().indexOf('alipayclienthk') !== -1) {
    isAlipayHK = true;
  } else if (userAgentInfo.toLowerCase().indexOf('alipayclient') !== -1) {
    isAlipayCN = true;
  }

  return {
    isPC,
    isSafari,
    isPhone,
    isWX,
    isAlipayCN,
    isAlipayHK,
  };
};

export const insetHead = () => {
  const prefix = Config.staticPath;
  return (
    <head>
      <link
        rel="preload"
        href={`${prefix}/font/SF-Pro-Display-Regular.otf`}
        as="font"
        type="font/otf"
        crossOrigin="anonymous"
      ></link>
      <link
        rel="preload"
        href={`${prefix}/font/SF-Pro-Display-Semibold.otf`}
        as="font"
        type="font/otf"
        crossOrigin="anonymous"
      ></link>
      <link
        rel="preload"
        href={`${prefix}/font/SF-Pro-Display-Bold.otf`}
        as="font"
        type="font/otf"
        crossOrigin="anonymous"
      ></link>
      <link
        rel="preload"
        href={`${prefix}/font/Inter-Regular.otf`}
        as="font"
        type="font/otf"
        crossOrigin="anonymous"
      ></link>
      <link
        rel="preload"
        href={`${prefix}/font/Inter-SemiBold.otf`}
        as="font"
        type="font/otf"
        crossOrigin="anonymous"
      ></link>
      <link
        rel="preload"
        href={`${prefix}/font/Inter-Bold.otf`}
        as="font"
        type="font/otf"
        crossOrigin="anonymous"
      ></link>
    </head>
  );
};

const compareIsHolidays = (list: HolidaysTypes.Holiday[], date: string) => {
  let isHolidays = false;

  const dateValue = dayjs(date);
  const today = dayjs().tz('Asia/Hong_Kong');
  const isToday = today.isSame(dateValue, 'day');
  let isOverTime = false;
  if (isToday) {
    // const minute = today.minute();
    const hour = today.hour();
    // if (hour >= 17 || (hour === 16 && minute > 54)) {
    if (hour >= 16) {
      isOverTime = true;
    }
  }
  const dayOfWeek = dateValue.day();
  const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;

  if (isWeekend || isOverTime) {
    isHolidays = true;
  } else {
    for (let i = 0; i < list.length; i++) {
      const holiday = dayjs(list[i].date);
      const isSameeDay = dateValue.isSame(holiday, 'day');
      if (isSameeDay) {
        isHolidays = true;
        break;
      }
    }
  }

  return isHolidays;
};

const handleFilterOption = (options: ICascaderOptions[]) => {
  const newOptions: ICascaderOptions[] = [];
  const today = dayjs().tz('Asia/Hong_Kong');
  let hour = today.hour();
  const minute = today.minute();
  if (minute > 31) hour += 1;
  options.forEach((option) => {
    const times = option.value.split('~');
    const value = times[1].split(':')[0];
    if (Number(value) > hour + 2) {
      newOptions.push(option);
    }
  });

  return newOptions;
};

export interface ICascaderOptions {
  value: string;
  label: string;
  children?: ICascaderOptions[];
}

export const getCascaderOptions = (t: TFunction<"translation", undefined>, lang: string, count = 10) => {
  const hd = new Holidays('HK');
  const list = hd.getHolidays();
  const dateFormat = lang === 'en' ? 'MMM D' : 'MM月DD日';

  const options: ICascaderOptions[] = [];

  const optionValues: ICascaderOptions[] = [
    // {
    //   value: '11:00 ~ 12:00',
    //   label: '11:00 ~ 12:00',
    // },
    {
      value: '12:00 ~ 13:00',
      label: '12:00 ~ 13:00',
    },
    {
      value: '13:00 ~ 14:00',
      label: '13:00 ~ 14:00',
    },
    {
      value: '15:00 ~ 16:00',
      label: '15:00 ~ 16:00',
    },
    {
      value: '16:00 ~ 17:00',
      label: '16:00 ~ 17:00',
    },
    {
      value: '17:00 ~ 18:00',
      label: '17:00 ~ 18:00',
    },
    {
      value: '18:00 ~ 19:00',
      label: '18:00 ~ 19:00',
    },
  ];

  let passCount = 0;
  let dayKey = 0;

  while (passCount < count) {
    let label = '';
    let children = optionValues;
    const today = dayjs().tz('Asia/Hong_Kong');
    const targetDate = today.add(dayKey, 'day');
    const value = targetDate.format('YYYY-MM-DD');

    if (!compareIsHolidays(list, value)) {
      const isToday = today.isSame(targetDate, 'day');
      const tomorrow = today.add(1, 'day');
      const isTomorrow = targetDate.isSame(tomorrow, 'day');
      if (isToday) {
        label = `${t('extended_service:today')} ${today.format(dateFormat)}`;
        children = handleFilterOption(optionValues);
      } else if (isTomorrow) {
        label = `${t('extended_service:tomorrow')} ${tomorrow.format(dateFormat)}`;
      } else {
        label = `${targetDate.format(dateFormat)}`;
      }

      options.push({
        value,
        label,
        children,
      });

      passCount++;
    }

    dayKey++;
  }

  return options;
};

export const calculationCart = (
  record: IObject,
  cart: ICartParams,
  type: 'plus' | 'minus' = 'plus'
) => {
  let cartListing: IObject = {};
  const listings = cart.listings;
  listings.forEach((listing) => {
    if (record.id === listing.id && record.favoriteID === listing.favoriteID) {
      const quantity =
        type === 'plus'
          ? plus(listing.cartQuantity || 0, 1)
          : minus(listing.cartQuantity || 0, 1);
      cartListing = {
        listing_id: listing.id,
        favorite_id: listing.favoriteID,
        quantity: quantity,
        id: listing.cartID,
      };
      return;
    }
  });
  return cartListing;
};

export const parseCart = (
  cartRecord: ICartParams,
  listings: any[],
  isMarge = true
) => {
  const { listings: cartListings = [] } = cartRecord;
  const newListings: IObject[] = isMarge ? [...listings] : [];
  if (cartListings.length > 0) {
    listings.forEach((listing, index) => {
      cartListings.forEach((item) => {
        if (
          listing.id === item.listing_id &&
          listing.favoriteID === item.favorite_id
        ) {
          let newListing = { ...listing };
          newListing = {
            ...listing,
            cartQuantity: item.quantity,
            cartID: item.id,
          };
          newListings.splice(index, 1, newListing);
        }
      });
    });
  }
  const newCart = { ...cartRecord };
  newCart.listings = newListings;
  return {
    card: cartRecord,
    newCart,
  };
};

export const getDeliveryDate = (n: number) => {
  const currentDate = new Date();
  const preDate = new Date(currentDate.getTime() + n * 24 * 3600 * 1000);
  const y = preDate.getFullYear();
  const m = preDate.getMonth() + 1;
  const d = preDate.getDate();
  const s = y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d);
  return s;
};

export const getClassByStatus = (status: string, t: TFunction<"translation", undefined>) => {
  let className = '';
  let showStatus = transformField(status);
  switch (status) {
    case 'unpaid':
      className = 'bg-warning-color text-warning-color';
      showStatus = t('extended_service:unpaid');
      break;
    case 'Unfullfilled':
    case 'unfullfilled':
      className = 'bg-warning-color text-warning-color';
      showStatus = t('extended_service:unfullfilled');
      break;
    case 'Delivery Requested':
      className = 'bg-warning-color text-warning-color';
      showStatus = t('extended_service:delivery_requested');
      break;
    case 'Pending Delivery':
      className = 'bg-warning-color text-warning-color';
      showStatus = t('extended_service:pending_delivery');
      break;
    case 'paid':
      showStatus = t('extended_service:paid');
      className = 'bg-[#4CD964] text-[#4CD964]';
      break;
    case 'partial_paid':
      showStatus = t('extended_service:partial_paid');
      className = 'bg-[#4CD964] text-[#4CD964]';
      break;
    case 'Delivered':
      showStatus = t('extended_service:delivered');
      className = 'bg-[#4CD964] text-[#4CD964]';
      break;
    case 'refund':
      showStatus = t('extended_service:refund');
      className = 'bg-primary-color text-primary-color';
      break;
    case 'refunded':
      showStatus = t('extended_service:refunded');
      className = 'bg-primary-color text-primary-color';
      break;

    case 'Delivering':
      showStatus = t('extended_service:delivering');
      className = 'bg-primary-color text-primary-color';
      break;
    case 'Delivery Confirmed':
      showStatus = t('extended_service:delivery_confirmed');
      className = 'bg-primary-color text-primary-color';
      break;
    case 'void':
      showStatus = t('extended_service:void');
      className = 'bg-[#828282] text-[#828282]';
      break;
    case 'Cancelled':
      showStatus = t('extended_service:cancelled');
      className = 'bg-[#828282] text-[#828282]';
      break;
    case 'Manual Delivery':
      showStatus = t('extended_service:manual_delivery');
      className = 'bg-[#00BFD9] text-[#00BFD9]';
      break;
    case 'manual_delivery':
      showStatus = t('extended_service:manual_delivery');
      className = 'bg-[#00BFD9] text-[#00BFD9]';
      break;
    case 'over_paid':
    case 'Over Paid':
      showStatus = t('extended_service:over_paid');
      className = 'bg-[#FF0000] text-[#FF0000]';
      break;
    default:
      break;
  }

  return {
    className,
    showStatus,
  };
};

export const bindoBusinessID =
  Config.env === 'staging'
    ? '84be4d74-b292-11ec-a3d9-42010aaa001d'
    : '8624b366-b292-11ec-a3d9-42010aaa001d';

export const getBusinessPaymentMethods = (
  paymentMethod: any,
  settings: IPaymentSettings
) => {
  let usePaymentMethods: PayTypes[] = [];
  let paymentProcessRules = paymentMethod.payment_process_rule;
  if (!Array.isArray(paymentProcessRules)) {
    paymentProcessRules = [];
  }
  let weChatPC = false;
  let weChatQR = false;
  let alipayPC = false;
  let alipayQR = false;
  let unionpayWalletPC = false;
  let unionpayWalletQR = false;
  let wxAccountPay = false;
  let showWX = false;
  const { isAlipayCN, isAlipayHK, inApp, isSafari, isWX } = settings;
  if (Config.env !== 'production' || !inApp) {
    showWX = true;
  }
  const isSpecialWallet = isWX || isAlipayCN || isAlipayHK;
  for (let i = 0; i < paymentProcessRules.length; i++) {
    const rule = paymentProcessRules[i];
    if (!rule) {
      continue;
    }
    let paymentEntryTypes = rule.payment_entry_types;
    let paymentMethods = rule.payment_methods;
    if (!Array.isArray(paymentEntryTypes)) {
      paymentEntryTypes = [];
    }
    if (!Array.isArray(paymentMethods)) {
      paymentMethods = [];
    }
    if (paymentEntryTypes.length < 1 && paymentMethods.length < 1) {
      usePaymentMethods = [PayTypes.CREDIT_CARD];
      if (isSpecialWallet) {
        if (isWX) {
          usePaymentMethods.push(PayTypes.WECHAT_PAY);
          usePaymentMethods.push(PayTypes.WECHAT_OFFICIAL_ACCOUNT_PAY);
        }
        if (isAlipayCN) {
          usePaymentMethods.push(PayTypes.ALIPAYCN);
        }
        if (isAlipayHK) {
          usePaymentMethods.push(PayTypes.ALIPAYHK);
        }
      } else {
        usePaymentMethods = [
          PayTypes.CREDIT_CARD,
          PayTypes.FPS,
          PayTypes.UNIONPAY_WALLET,
          PayTypes.ALIPAYCN,
          PayTypes.ALIPAYHK,
        ];
        if (isSafari) {
          usePaymentMethods.push(PayTypes.APPLE_PAY);
        }
        if (showWX) {
          usePaymentMethods.push(PayTypes.WECHAT_PAY);
        }
      }
    }

    if (
      paymentMethods.includes('visa') ||
      paymentMethods.includes('mastercard') ||
      paymentMethods.includes('cup') ||
      paymentMethods.includes('jcb') ||
      paymentMethods.includes('diners') ||
      paymentMethods.includes('discover')
    ) {
      if (paymentEntryTypes.length < 1) {
        if (isSafari) {
          usePaymentMethods.push(PayTypes.APPLE_PAY);
        }
        usePaymentMethods.push(PayTypes.CREDIT_CARD);
      } else {
        if (paymentEntryTypes.includes('apple_pay') && isSafari) {
          usePaymentMethods.push(PayTypes.APPLE_PAY);
        }
        if (paymentEntryTypes.includes('manual')) {
          usePaymentMethods.push(PayTypes.CREDIT_CARD);
        }
      }
    }

    if (paymentMethods.includes('wechat')) {
      if (paymentEntryTypes.length < 1) {
        weChatPC = true;
        weChatQR = true;
      }
      if (paymentEntryTypes.includes('merchant_presented_qr_code')) {
        weChatQR = true;
      }
      if (paymentEntryTypes.includes('in_web')) {
        weChatPC = true;
      }
      if (paymentEntryTypes.includes('official_account') && isWX) {
        wxAccountPay = true;
      }
    }

    if (paymentMethods.includes('alipay')) {
      if (paymentEntryTypes.length < 1) {
        alipayPC = true;
        alipayQR = true;
      }
      if (paymentEntryTypes.includes('merchant_presented_qr_code')) {
        alipayQR = true;
      }
      if (paymentEntryTypes.includes('in_web')) {
        alipayPC = true;
      }
    }
    if (!isSpecialWallet) {
      if (
        paymentMethods.includes('fps') &&
        (paymentEntryTypes.length < 1 ||
          paymentEntryTypes.includes('merchant_presented_qr_code'))
      ) {
        usePaymentMethods.push(PayTypes.FPS);
      }
    }

    if (paymentMethods.includes('unionpay_wallet')) {
      if (paymentEntryTypes.length < 1) {
        unionpayWalletPC = true;
        unionpayWalletQR = true;
      }
      if (paymentEntryTypes.includes('merchant_presented_qr_code')) {
        unionpayWalletQR = true;
      }
      if (paymentEntryTypes.includes('in_web')) {
        unionpayWalletPC = true;
      }
    }

    if (paymentMethods.length < 1) {
      if (paymentEntryTypes.includes('apple_pay') && isSafari) {
        usePaymentMethods.push(PayTypes.APPLE_PAY);
      }

      if (paymentEntryTypes.includes('manual')) {
        usePaymentMethods.push(PayTypes.CREDIT_CARD);
      }

      if (paymentEntryTypes.includes('in_web')) {
        alipayPC = true;
        weChatPC = true;
      }

      if (paymentEntryTypes.includes('merchant_presented_qr_code')) {
        alipayQR = true;
        weChatQR = true;
        if (!isSpecialWallet) {
          usePaymentMethods.push(PayTypes.FPS);
        }
      }
    }
  }
  if (weChatPC && weChatQR && !isSpecialWallet) {
    if (showWX) {
      usePaymentMethods.push(PayTypes.WECHAT_PAY);
    }
  }
  if (wxAccountPay) {
    usePaymentMethods.push(PayTypes.WECHAT_PAY);
    usePaymentMethods.push(PayTypes.WECHAT_OFFICIAL_ACCOUNT_PAY);
  }
  if (alipayPC && alipayQR && !isWX) {
    if (!isSpecialWallet) {
      usePaymentMethods.push(PayTypes.ALIPAYCN);
      usePaymentMethods.push(PayTypes.ALIPAYHK);
    }
    if (isAlipayCN) {
      usePaymentMethods.push(PayTypes.ALIPAYCN);
    }
    if (isAlipayHK) {
      usePaymentMethods.push(PayTypes.ALIPAYHK);
    }
  }
  if (unionpayWalletPC && unionpayWalletQR && !isSpecialWallet) {
    usePaymentMethods.push(PayTypes.UNIONPAY_WALLET);
  }

  return usePaymentMethods;
};

export const parseDeliverySchedule = (deliverySchedule: string[]) => {
  let startTime: any = '';
  let endTime: any = '';
  if (deliverySchedule.length > 1) {
    const date = trim(deliverySchedule[0]);
    const times = deliverySchedule[1].split('~');
    if (times.length > 1) {
      startTime = `${date} ${trim(times[0])}`;
      endTime = `${date} ${trim(times[1])}`;
      startTime = dayjs.tz(startTime, 'Asia/Hong_Kong').toISOString();
      endTime = dayjs.tz(endTime, 'Asia/Hong_Kong').toISOString();
    }
  }
  return {
    startTime,
    endTime,
  };
};

export const getDeliverySchedule = (startTime: string, endTime: string) => {
  const deliverySchedule: string[] = [];
  if (startTime && endTime) {
    const start = dayjs(startTime).tz('Asia/Hong_Kong').format('YYYY-MM-DD,HH:mm');
    const end = dayjs(endTime).tz('Asia/Hong_Kong').format('HH:mm');
    const formattedRange = `${start} ~ ${end}`;
    deliverySchedule.push(formattedRange.split(',')[0]);
    deliverySchedule.push(formattedRange.split(',')[1]);
  }
  return deliverySchedule;
};

export const isDeliveryScheduleLater = (deliverySchedule: any, times: any) => {
  if (deliverySchedule[1]) {
    const deliveryDateTime = dayjs(deliverySchedule[0] + ' ' + deliverySchedule[1].split(' ')[0]).tz('Asia/Hong_Kong').format('YYYY-MM-DD,HH:mm');
    const timeDateTime = dayjs(times[0] + ' ' + times[1].split(' ')[0]).tz('Asia/Hong_Kong').format('YYYY-MM-DD,HH:mm');
    if (deliverySchedule[0] === times[0] && deliverySchedule[1] === times[1]) {
      return true
    }
    return new Date(deliveryDateTime.replace(/,/g, 'T')) > new Date(timeDateTime.replace(/,/g, 'T'))
  }
}

export const ConvertTimeZone = (time: any, format: any) => {
  const hkTimestamp = dayjs(time).tz('Asia/Hong_Kong').format(format);
  return hkTimestamp
};

export const getCardType = (number: string) => {
  let icon: any = '';
  const lastDigits = `**${number.substring(number.length - 4)}`;
  let type = ``;
  // discover
  if (number.match('^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}') !== null) {
    icon = <DiscoverIcon />;
    type = 'Discover';
  }
  // diners
  if (number.match('^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}') !== null) {
    icon = <DinersIcon />;
    type = 'Diners';
  }
  // mastercard
  if (
    number.match(
      '^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}'
    ) !== null
  ) {
    icon = <MasterCardIcon />;
    type = 'Mastercard';
  }
  // jcb
  if (
    number.match('^(?:2131|1800)\\d{0,11}') !== null ||
    number.match('^(?:2131|1800)\\d{0,11}') !== null
  ) {
    icon = <JCBIcon />;
    type = 'JCB';
  }
  // visa
  if (number.match('^4\\d{0,15}') !== null) {
    icon = <VisaIcon />;
    type = 'Visa';
  }
  // unionpay
  if (number.match('^62\\d{0,14}') !== null) {
    icon = <UnionPayIcon />;
    type = 'UnionPay';
  }

  return { icon, type: `${type} ${lastDigits}` };
};
