import React from 'react';
import type { IIconProps } from '@/types/common-types';

const AppPayIcon: React.FC<IIconProps> = (props) => {
    const { className = '', onClick } = props;
    return (
        <div className={`${className}`} onClick={onClick}>
            <svg width="40" height="27" viewBox="0 0 40 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.2814 7.8345C10.6614 7.35118 10.9192 6.70222 10.8512 6.03906C10.2949 6.06719 9.61612 6.41225 9.22312 6.89595C8.87024 7.31016 8.55792 7.9863 8.63934 8.62166C9.26378 8.67673 9.88765 8.30426 10.2814 7.8345Z" fill="black" />
                <path d="M10.8426 8.7451C9.93576 8.69017 9.16472 9.26846 8.73166 9.26846C8.29836 9.26846 7.6352 8.77278 6.91793 8.78614C5.98437 8.80008 5.11813 9.33683 4.64438 10.1905C3.66997 11.8983 4.38724 14.4316 5.3348 15.8225C5.79496 16.5106 6.34953 17.2683 7.08026 17.2411C7.77067 17.2135 8.04129 16.7865 8.88052 16.7865C9.71914 16.7865 9.9629 17.2411 10.6938 17.2273C11.4517 17.2135 11.9255 16.5388 12.3856 15.85C12.9135 15.0656 13.1296 14.3082 13.1432 14.2666C13.1296 14.2528 11.6818 13.688 11.6683 11.9944C11.6547 10.5763 12.8051 9.90182 12.8592 9.85998C12.2096 8.88294 11.1945 8.77278 10.8426 8.7451Z" fill="black" />
                <path d="M18.7368 6.82486C20.7078 6.82486 22.0803 8.20642 22.0803 10.2179C22.0803 12.2365 20.6796 13.6252 18.6874 13.6252H16.5051V17.1542H14.9284V6.82486L18.7368 6.82486ZM16.5051 12.2794H18.3143C19.687 12.2794 20.4683 11.5279 20.4683 10.225C20.4683 8.92233 19.687 8.17784 18.3213 8.17784H16.5051V12.2794Z" fill="black" />
                <path d="M22.4919 15.014C22.4919 13.6968 23.4844 12.8879 25.2444 12.7877L27.2717 12.666V12.0863C27.2717 11.2487 26.7155 10.7477 25.7865 10.7477C24.9063 10.7477 24.3572 11.1771 24.2236 11.85H22.7876C22.8721 10.4899 24.0124 9.48774 25.8427 9.48774C27.6377 9.48774 28.7851 10.4541 28.7851 11.9645V17.1542H27.3279V15.9159H27.2928C26.8635 16.7534 25.9272 17.2831 24.9558 17.2831C23.5056 17.2831 22.4919 16.3668 22.4919 15.014ZM27.2717 14.334V13.7398L25.4484 13.8543C24.5403 13.9188 24.0265 14.3268 24.0265 14.971C24.0265 15.6295 24.5615 16.0591 25.378 16.0591C26.4409 16.0591 27.2717 15.3146 27.2717 14.334Z" fill="black" />
                <path d="M30.1599 19.9252V18.6724C30.2723 18.701 30.5257 18.701 30.6525 18.701C31.3564 18.701 31.7366 18.4004 31.9688 17.6274C31.9688 17.613 32.1027 17.1692 32.1027 17.1621L29.4278 9.62438H31.0748L32.9475 15.7519H32.9755L34.8482 9.62438H36.4531L33.6794 17.5485C33.0461 19.374 32.314 19.9609 30.7793 19.9609C30.6525 19.9609 30.2723 19.9466 30.1599 19.9252Z" fill="black" />
            </svg>
        </div>
    );
};

export default AppPayIcon;
