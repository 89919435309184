export const currencies: any = [
  {
    alpha_3: 'AFG',
    alpha_2: 'AF',
    country_code: '971',
    currency_code: 'AFN',
    currency_name: 'Afghani',
  },
  {
    alpha_3: 'MMR',
    alpha_2: 'MM',
    country_code: '104',
    currency_code: 'MMK',
    currency_name: 'Kyat',
  },
  {
    alpha_3: 'BDI',
    alpha_2: 'BI',
    country_code: '108',
    currency_code: 'BIF',
    currency_name: 'Burundi Franc',
  },
  {
    alpha_3: 'KHM',
    alpha_2: 'KH',
    country_code: '116',
    currency_code: 'KHR',
    currency_name: 'Riel',
  },
  {
    alpha_3: 'DZA',
    alpha_2: 'DZ',
    country_code: '12',
    currency_code: 'DZD',
    currency_name: 'Algerian Dinar',
  },
  {
    alpha_3: 'CAN',
    alpha_2: 'CA',
    country_code: '124',
    currency_code: 'CAD',
    currency_name: 'Canadian Dollar',
  },
  {
    alpha_3: 'CPV',
    alpha_2: 'CV',
    country_code: '132',
    currency_code: 'CVE',
    currency_name: 'Cabo Verde Escudo',
  },
  {
    alpha_3: 'CYM',
    alpha_2: 'KY',
    country_code: '136',
    currency_code: 'KYD',
    currency_name: 'Cayman Islands Dollar',
  },
  {
    alpha_3: 'LKA',
    alpha_2: 'LK',
    country_code: '144',
    currency_code: 'LKR',
    currency_name: 'Sri Lanka Rupee',
  },
  {
    alpha_3: 'CHL',
    alpha_2: 'CL',
    country_code: '152',
    currency_code: 'CLP',
    currency_name: 'Chilean Peso',
  },
  {
    alpha_3: 'CHN',
    alpha_2: 'CN',
    country_code: '156',
    currency_code: 'CNY',
    currency_name: 'Yuan Renminbi',
  },
  {
    alpha_3: 'COL',
    alpha_2: 'CO',
    country_code: '170',
    currency_code: 'COP',
    currency_name: 'Colombian Peso',
  },
  {
    alpha_3: 'COM',
    alpha_2: 'KM',
    country_code: '174',
    currency_code: 'KMF',
    currency_name: 'Comorian Franc',
  },
  {
    alpha_3: 'CRI',
    alpha_2: 'CR',
    country_code: '188',
    currency_code: 'CRC',
    currency_name: 'Costa Rican Colon',
  },
  {
    alpha_3: 'HRV',
    alpha_2: 'HR',
    country_code: '191',
    currency_code: 'HRK',
    currency_name: 'Kuna',
  },
  {
    alpha_3: 'CUB',
    alpha_2: 'CU',
    country_code: '192',
    currency_code: 'CUP',
    currency_name: 'Cuban Peso',
  },
  {
    alpha_3: 'CZE',
    alpha_2: 'CZ',
    country_code: '203',
    currency_code: 'CZK',
    currency_name: 'Czech Koruna',
  },
  {
    alpha_3: 'DNK',
    alpha_2: 'DK',
    country_code: '208',
    currency_code: 'DKK',
    currency_name: 'Danish Krone',
  },
  {
    alpha_3: 'DOM',
    alpha_2: 'DO',
    country_code: '214',
    currency_code: 'DOP',
    currency_name: 'Dominican Peso',
  },
  {
    alpha_3: 'SLV',
    alpha_2: 'SV',
    country_code: '222',
    currency_code: 'SVC',
    currency_name: 'El Salvador Colon',
  },
  {
    alpha_3: 'ERI',
    alpha_2: 'ER',
    country_code: '232',
    currency_code: 'ERN',
    currency_name: 'Nakfa',
  },
  {
    alpha_3: 'FLK',
    alpha_2: 'FK',
    country_code: '238',
    currency_code: 'FKP',
    currency_name: 'Falkland Islands Pound',
  },
  {
    alpha_3: 'FJI',
    alpha_2: 'FJ',
    country_code: '242',
    currency_code: 'FJD',
    currency_name: 'Fiji Dollar',
  },
  {
    alpha_3: 'DJI',
    alpha_2: 'DJ',
    country_code: '262',
    currency_code: 'DJF',
    currency_name: 'Djibouti Franc',
  },
  {
    alpha_3: 'GMB',
    alpha_2: 'GM',
    country_code: '270',
    currency_code: 'GMD',
    currency_name: 'Dalasi',
  },
  {
    alpha_3: 'GIB',
    alpha_2: 'GI',
    country_code: '292',
    currency_code: 'GIP',
    currency_name: 'Gibraltar Pound',
  },
  {
    alpha_3: 'ARG',
    alpha_2: 'AR',
    country_code: '32',
    currency_code: 'ARS',
    currency_name: 'Argentine Peso',
  },
  {
    alpha_3: 'GTM',
    alpha_2: 'GT',
    country_code: '320',
    currency_code: 'GTQ',
    currency_name: 'Quetzal',
  },
  {
    alpha_3: 'GIN',
    alpha_2: 'GN',
    country_code: '324',
    currency_code: 'GNF',
    currency_name: 'Guinean Franc',
  },
  {
    alpha_3: 'GUY',
    alpha_2: 'GY',
    country_code: '328',
    currency_code: 'GYD',
    currency_name: 'Guyana Dollar',
  },
  {
    alpha_3: 'HTI',
    alpha_2: 'HT',
    country_code: '332',
    currency_code: 'HTG',
    currency_name: 'Gourde',
  },
  {
    alpha_3: 'HND',
    alpha_2: 'HN',
    country_code: '340',
    currency_code: 'HNL',
    currency_name: 'Lempira',
  },
  {
    alpha_3: 'HKG',
    alpha_2: 'HK',
    country_code: '344',
    currency_code: 'HKD',
    currency_name: 'Hong Kong Dollar',
  },
  {
    alpha_3: 'HUN',
    alpha_2: 'HU',
    country_code: '348',
    currency_code: 'HUF',
    currency_name: 'Forint',
  },
  {
    alpha_3: 'ISL',
    alpha_2: 'IS',
    country_code: '352',
    currency_code: 'ISK',
    currency_name: 'Iceland Krona',
  },
  {
    alpha_3: 'IND',
    alpha_2: 'IN',
    country_code: '356',
    currency_code: 'INR',
    currency_name: 'Indian Rupee',
  },
  {
    alpha_3: 'AUS',
    alpha_2: 'AU',
    country_code: '36',
    currency_code: 'AUD',
    currency_name: 'Australian Dollar',
  },
  {
    alpha_3: 'IDN',
    alpha_2: 'ID',
    country_code: '360',
    currency_code: 'IDR',
    currency_name: 'Rupiah',
  },
  {
    alpha_3: 'IRN',
    alpha_2: 'IR',
    country_code: '364',
    currency_code: 'IRR',
    currency_name: 'Iranian Rial',
  },
  {
    alpha_3: 'IRQ',
    alpha_2: 'IQ',
    country_code: '368',
    currency_code: 'IQD',
    currency_name: 'Iraqi Dinar',
  },
  {
    alpha_3: 'ISR',
    alpha_2: 'IL',
    country_code: '376',
    currency_code: 'ILS',
    currency_name: 'New Israeli Sheqel',
  },
  {
    alpha_3: 'JAM',
    alpha_2: 'JM',
    country_code: '388',
    currency_code: 'JMD',
    currency_name: 'Jamaican Dollar',
  },
  {
    alpha_3: 'JPN',
    alpha_2: 'JP',
    country_code: '392',
    currency_code: 'JPY',
    currency_name: 'Yen',
  },
  {
    alpha_3: 'KAZ',
    alpha_2: 'KZ',
    country_code: '398',
    currency_code: 'KZT',
    currency_name: 'Tenge',
  },
  {
    alpha_3: 'JOR',
    alpha_2: 'JO',
    country_code: '400',
    currency_code: 'JOD',
    currency_name: 'Jordanian Dinar',
  },
  {
    alpha_3: 'KEN',
    alpha_2: 'KE',
    country_code: '404',
    currency_code: 'KES',
    currency_name: 'Kenyan Shilling',
  },
  {
    alpha_3: 'PRK',
    alpha_2: 'KP',
    country_code: '408',
    currency_code: 'KPW',
    currency_name: 'North Korean Won',
  },
  {
    alpha_3: 'KOR',
    alpha_2: 'KR',
    country_code: '410',
    currency_code: 'KRW',
    currency_name: 'Won',
  },
  {
    alpha_3: 'KWT',
    alpha_2: 'KW',
    country_code: '414',
    currency_code: 'KWD',
    currency_name: 'Kuwaiti Dinar',
  },
  {
    alpha_3: 'KGZ',
    alpha_2: 'KG',
    country_code: '417',
    currency_code: 'KGS',
    currency_name: 'Som',
  },
  {
    alpha_3: 'LAO',
    alpha_2: 'LA',
    country_code: '418',
    currency_code: 'LAK',
    currency_name: 'Lao Kip',
  },
  {
    alpha_3: 'LBN',
    alpha_2: 'LB',
    country_code: '422',
    currency_code: 'LBP',
    currency_name: 'Lebanese Pound',
  },
  {
    alpha_3: 'LSO',
    alpha_2: 'LS',
    country_code: '426',
    currency_code: 'LSL',
    currency_name: 'Loti',
  },
  {
    alpha_3: 'LBR',
    alpha_2: 'LR',
    country_code: '430',
    currency_code: 'LRD',
    currency_name: 'Liberian Dollar',
  },
  {
    alpha_3: 'LBY',
    alpha_2: 'LY',
    country_code: '434',
    currency_code: 'LYD',
    currency_name: 'Libyan Dinar',
  },
  {
    alpha_3: 'BHS',
    alpha_2: 'BS',
    country_code: '44',
    currency_code: 'BSD',
    currency_name: 'Bahamian Dollar',
  },
  {
    alpha_3: 'MAC',
    alpha_2: 'MO',
    country_code: '446',
    currency_code: 'MOP',
    currency_name: 'Pataca',
  },
  {
    alpha_3: 'MWI',
    alpha_2: 'MW',
    country_code: '454',
    currency_code: 'MWK',
    currency_name: 'Malawi Kwacha',
  },
  {
    alpha_3: 'MYS',
    alpha_2: 'MY',
    country_code: '458',
    currency_code: 'MYR',
    currency_name: 'Malaysian Ringgit',
  },
  {
    alpha_3: 'MDV',
    alpha_2: 'MV',
    country_code: '462',
    currency_code: 'MVR',
    currency_name: 'Rufiyaa',
  },
  {
    alpha_3: 'BHR',
    alpha_2: 'BH',
    country_code: '48',
    currency_code: 'BHD',
    currency_name: 'Bahraini Dinar',
  },
  {
    alpha_3: 'MUS',
    alpha_2: 'MU',
    country_code: '480',
    currency_code: 'MUR',
    currency_name: 'Mauritius Rupee',
  },
  {
    alpha_3: 'MEX',
    alpha_2: 'MX',
    country_code: '484',
    currency_code: 'MXN',
    currency_name: 'Mexican Peso',
  },
  {
    alpha_3: 'MNG',
    alpha_2: 'MN',
    country_code: '496',
    currency_code: 'MNT',
    currency_name: 'Tugrik',
  },
  {
    alpha_3: 'MDA',
    alpha_2: 'MD',
    country_code: '498',
    currency_code: 'MDL',
    currency_name: 'Moldovan Leu',
  },
  {
    alpha_2: 'EU',
    country_code: '978',
    currency_code: 'EUR',
    currency_name: 'Euro',
  },
  {
    alpha_3: 'BGD',
    alpha_2: 'BD',
    country_code: '50',
    currency_code: 'BDT',
    currency_name: 'Taka',
  },
  {
    alpha_3: 'MAR',
    alpha_2: 'MA',
    country_code: '504',
    currency_code: 'MAD',
    currency_name: 'Moroccan Dirham',
  },
  {
    alpha_3: 'ARM',
    alpha_2: 'AM',
    country_code: '51',
    currency_code: 'AMD',
    currency_name: 'Armenian Dram',
  },
  {
    alpha_3: 'OMN',
    alpha_2: 'OM',
    country_code: '512',
    currency_code: 'OMR',
    currency_name: 'Rial Omani',
  },
  {
    alpha_3: 'NAM',
    alpha_2: 'NA',
    country_code: '516',
    currency_code: 'NAD',
    currency_name: 'Namibia Dollar',
  },
  {
    alpha_3: 'BRB',
    alpha_2: 'BB',
    country_code: '52',
    currency_code: 'BBD',
    currency_name: 'Barbados Dollar',
  },
  {
    alpha_3: 'NPL',
    alpha_2: 'NP',
    country_code: '524',
    currency_code: 'NPR',
    currency_name: 'Nepalese Rupee',
  },
  {
    alpha_3: 'ABW',
    alpha_2: 'AW',
    country_code: '533',
    currency_code: 'AWG',
    currency_name: 'Aruban Florin',
  },
  {
    alpha_3: 'VUT',
    alpha_2: 'VU',
    country_code: '548',
    currency_code: 'VUV',
    currency_name: 'Vatu',
  },
  {
    alpha_3: 'NZL',
    alpha_2: 'NZ',
    country_code: '554',
    currency_code: 'NZD',
    currency_name: 'New Zealand Dollar',
  },
  {
    alpha_3: 'NIC',
    alpha_2: 'NI',
    country_code: '558',
    currency_code: 'NIO',
    currency_name: 'Cordoba Oro',
  },
  {
    alpha_3: 'NGA',
    alpha_2: 'NG',
    country_code: '566',
    currency_code: 'NGN',
    currency_name: 'Naira',
  },
  {
    alpha_3: 'NOR',
    alpha_2: 'NO',
    country_code: '578',
    currency_code: 'NOK',
    currency_name: 'Norwegian Krone',
  },
  {
    alpha_3: 'PAK',
    alpha_2: 'PK',
    country_code: '586',
    currency_code: 'PKR',
    currency_name: 'Pakistan Rupee',
  },
  {
    alpha_3: 'PNG',
    alpha_2: 'PG',
    country_code: '598',
    currency_code: 'PGK',
    currency_name: 'Kina',
  },
  {
    alpha_3: 'BMU',
    alpha_2: 'BM',
    country_code: '60',
    currency_code: 'BMD',
    currency_name: 'Bermudian Dollar',
  },
  {
    alpha_3: 'PRY',
    alpha_2: 'PY',
    country_code: '600',
    currency_code: 'PYG',
    currency_name: 'Guarani',
  },
  {
    alpha_3: 'PER',
    alpha_2: 'PE',
    country_code: '604',
    currency_code: 'PEN',
    currency_name: 'Sol',
  },
  {
    alpha_3: 'PHL',
    alpha_2: 'PH',
    country_code: '608',
    currency_code: 'PHP',
    currency_name: 'Philippine Peso',
  },
  {
    alpha_3: 'QAT',
    alpha_2: 'QA',
    country_code: '634',
    currency_code: 'QAR',
    currency_name: 'Qatari Rial',
  },
  {
    alpha_3: 'BTN',
    alpha_2: 'BT',
    country_code: '64',
    currency_code: 'BTN',
    currency_name: 'Ngultrum',
  },
  {
    alpha_3: 'RUS',
    alpha_2: 'RU',
    country_code: '643',
    currency_code: 'RUB',
    currency_name: 'Russian Ruble',
  },
  {
    alpha_3: 'RWA',
    alpha_2: 'RW',
    country_code: '646',
    currency_code: 'RWF',
    currency_name: 'Rwanda Franc',
  },
  {
    alpha_3: 'SHN',
    alpha_2: 'SH',
    country_code: '654',
    currency_code: 'SHP',
    currency_name: 'Saint Helena Pound',
  },
  {
    alpha_3: 'BOL',
    alpha_2: 'BO',
    country_code: '68',
    currency_code: 'BOB',
    currency_name: 'Boliviano',
  },
  {
    alpha_3: 'SAU',
    alpha_2: 'SA',
    country_code: '682',
    currency_code: 'SAR',
    currency_name: 'Saudi Riyal',
  },
  {
    alpha_3: 'SYC',
    alpha_2: 'SC',
    country_code: '690',
    currency_code: 'SCR',
    currency_name: 'Seychelles Rupee',
  },
  {
    alpha_3: 'SLE',
    alpha_2: 'SL',
    country_code: '694',
    currency_code: 'SLL',
    currency_name: 'Leone',
  },
  {
    alpha_3: 'SGP',
    alpha_2: 'SG',
    country_code: '702',
    currency_code: 'SGD',
    currency_name: 'Singapore Dollar',
  },
  {
    alpha_3: 'VNM',
    alpha_2: 'VN',
    country_code: '704',
    currency_code: 'VND',
    currency_name: 'Dong',
  },
  {
    alpha_3: 'SOM',
    alpha_2: 'SO',
    country_code: '706',
    currency_code: 'SOS',
    currency_name: 'Somali Shilling',
  },
  {
    alpha_3: 'ZAF',
    alpha_2: 'ZA',
    country_code: '710',
    currency_code: 'ZAR',
    currency_name: 'Rand',
  },
  {
    alpha_3: 'BWA',
    alpha_2: 'BW',
    country_code: '72',
    currency_code: 'BWP',
    currency_name: 'Pula',
  },
  {
    alpha_3: 'SSD',
    alpha_2: 'SS',
    country_code: '728',
    currency_code: 'SSP',
    currency_name: 'South Sudanese Pound',
  },
  {
    alpha_3: 'SWZ',
    alpha_2: 'SZ',
    country_code: '748',
    currency_code: 'SZL',
    currency_name: 'Lilangeni',
  },
  {
    alpha_3: 'SWE',
    alpha_2: 'SE',
    country_code: '752',
    currency_code: 'SEK',
    currency_name: 'Swedish Krona',
  },
  {
    alpha_3: 'CHE',
    alpha_2: 'CH',
    country_code: '756',
    currency_code: 'CHF',
    currency_name: 'Swiss Franc',
  },
  {
    alpha_3: 'SYR',
    alpha_2: 'SY',
    country_code: '760',
    currency_code: 'SYP',
    currency_name: 'Syrian Pound',
  },
  {
    alpha_3: 'THA',
    alpha_2: 'TH',
    country_code: '764',
    currency_code: 'THB',
    currency_name: 'Baht',
  },
  {
    alpha_3: 'TON',
    alpha_2: 'TO',
    country_code: '776',
    currency_code: 'TOP',
    currency_name: "Pa'anga",
  },
  {
    alpha_3: 'TTO',
    alpha_2: 'TT',
    country_code: '780',
    currency_code: 'TTD',
    currency_name: 'Trinidad and Tobago Dollar',
  },
  {
    alpha_3: 'ARE',
    alpha_2: 'AE',
    country_code: '784',
    currency_code: 'AED',
    currency_name: 'UAE Dirham',
  },
  {
    alpha_3: 'TUN',
    alpha_2: 'TN',
    country_code: '788',
    currency_code: 'TND',
    currency_name: 'Tunisian Dinar',
  },
  {
    alpha_3: 'ALB',
    alpha_2: 'AL',
    country_code: '8',
    currency_code: 'ALL',
    currency_name: 'Lek',
  },
  {
    alpha_3: 'UGA',
    alpha_2: 'UG',
    country_code: '800',
    currency_code: 'UGX',
    currency_name: 'Uganda Shilling',
  },
  {
    alpha_3: 'MKD',
    alpha_2: 'MK',
    country_code: '807',
    currency_code: 'MKD',
    currency_name: 'Denar',
  },
  {
    alpha_3: 'EGY',
    alpha_2: 'EG',
    country_code: '818',
    currency_code: 'EGP',
    currency_name: 'Egyptian Pound',
  },
  {
    alpha_3: 'GBR',
    alpha_2: 'GB',
    country_code: '826',
    currency_code: 'GBP',
    currency_name: 'Pound Sterling',
  },
  {
    alpha_3: 'TZA',
    alpha_2: 'TZ',
    country_code: '834',
    currency_code: 'TZS',
    currency_name: 'Tanzanian Shilling',
  },
  {
    alpha_3: 'BLZ',
    alpha_2: 'BZ',
    country_code: '84',
    currency_code: 'BZD',
    currency_name: 'Belize Dollar',
  },
  {
    alpha_3: 'USA',
    alpha_2: 'US',
    country_code: '840',
    currency_code: 'USD',
    currency_name: 'US Dollar',
  },
  {
    alpha_3: 'URY',
    alpha_2: 'UY',
    country_code: '858',
    currency_code: 'UYU',
    currency_name: 'Peso Uruguayo',
  },
  {
    alpha_3: 'UZB',
    alpha_2: 'UZ',
    country_code: '860',
    currency_code: 'UZS',
    currency_name: 'Uzbekistan Sum',
  },
  {
    alpha_3: 'WSM',
    alpha_2: 'WS',
    country_code: '882',
    currency_code: 'WST',
    currency_name: 'Tala',
  },
  {
    alpha_3: 'SLB',
    alpha_2: 'SB',
    country_code: '90',
    currency_code: 'SBD',
    currency_name: 'Solomon Islands Dollar',
  },
  {
    alpha_3: 'BRN',
    alpha_2: 'BN',
    country_code: '96',
    currency_code: 'BND',
    currency_name: 'Brunei Dollar',
  },
  {
    alpha_3: 'TWN',
    alpha_2: 'TW',
    country_code: '158',
    currency_code: 'TWD',
    currency_name: 'New Taiwan Dollar',
  },
];
