import { useEffect, useState } from 'react';
import MobileResult from '../mobile/payment-result';
import PaymentResultWeb from '../web/payment-result-web';
import { IObject } from '@/types/common-types';
import { getOrderDetails } from '../../../data/terminal';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getCookie, setCookie } from '@/utils';
import { Constants } from '@/constants';

const TerminalOrdersPaymentResultPage = () => {
  const { p_business_id: businessID = '', lang = 'en' } = useParams();
  const [record, setRecord] = useState<IObject>({});
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [dataTimeOut, setdataTimeOut] = useState<boolean>(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const number = searchParams.get('number')|| '';
  const errorCode = searchParams.get('e')|| '' ;
  const message = searchParams.get('m')|| '';

  const queryToken = searchParams.get('token');
  let inApp = false
  const queryInApp = searchParams.get('isApp') || false;
  if (typeof queryInApp === 'string' && queryInApp === 'true') {
    inApp = true;
  }
  let access_token = '';
  if (typeof queryToken === 'string' && queryToken.length > 0) {
    access_token = queryToken;
  }
  const cookieToken = getCookie(Constants.TOKEN) || '';
  const token = access_token || cookieToken;

  const navigate = useNavigate();

  useEffect(() => {
    setCookie('noteValue', '');
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    setLoadingData(true);
    const record = await getOrderDetails(number, businessID, token);
    if (record === 'ERR_NETWORK') {
      setdataTimeOut(true)
      return
    }
    if (record) {
      setRecord(record);
    }
    // setLoadingData(false);
    setdataTimeOut(false)
  };

  const handleBack = () => {
    navigate(`/${lang}/businesses/${businessID}/terminal/orders`);
  };

  const handleTryAgain = () => {
    const isDiscountCoupon = getCookie('__coupon') === 'true';
    if (isDiscountCoupon) {
      navigate(`/${lang}/businesses/${businessID}/terminal/orders/check`);
    } else {
      navigate(
        `/${lang}/businesses/${businessID}/terminal/orders/payment?number=${number}`
      );
    }
  };

  return (
    <>
      {!inApp && (
        <PaymentResultWeb
          record={record}
          loadingData={loadingData}
          handleBack={handleBack}
          handleTryAgain={handleTryAgain}
          number={number}
          errorCode={errorCode}
          message={message}
          inApp={inApp}
          businessID={businessID}
          token={token}
        />
      )}
      {inApp && (
        <MobileResult
          record={record}
          businessID={businessID}
          token={token}
          number={number}
          errorCode={errorCode}
          message={message}
          inApp={inApp}
          dataTimeOut={dataTimeOut}
          loadData={loadData}
        />
      )}
      {/* <MobileResult record={record} {...props} /> */}
    </>
  );
};

export default TerminalOrdersPaymentResultPage;
