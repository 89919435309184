import React from 'react';
import type { IIconProps } from '@/types/common-types';

const NoImageIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 48 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 48 48"
        fill="none"
      >
        <path
          d="M4.80078 28.2674L13.3341 19.7341L36.8008 43.2008M27.2008 33.6008L43.2008 17.6008M4.80078 4.80078H43.2008V43.2008H4.80078V4.80078ZM32.5341 14.4008C32.5341 17.3463 30.1463 19.7341 27.2008 19.7341C24.2553 19.7341 21.8674 17.3463 21.8674 14.4008C21.8674 11.4553 24.2553 9.06745 27.2008 9.06745C30.1463 9.06745 32.5341 11.4553 32.5341 14.4008Z"
          stroke="#0094FF"
          strokeWidth="2.13139"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default NoImageIcon;
