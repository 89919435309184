import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import CloseIcon from '@/components/svg/close-icon';
import GlobalLoading from '@/components/common/global-loading';
import FxConvert from './fx-convert';
import type {
  IAvailableCurrency,
  IPayout,
  IRetrieveBalance,
} from '../../../types/global-accounts-type';
import { initPayout } from '../../../utils/global-account-untils';
import {
  getAvailableCurrencies,
  getRetrieveBalances,
} from '../../../data/global-account';
import { getCookie } from '@/utils/index';
import { Constants } from '@/constants';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';


const FxCurrent = () => {
  const { t } = useTranslation(["global_accounts", "account_balance"]);
  const { lang = 'en', p_business_id: businessID = '', ledger_account_id: recordId = '' } = useParams();
  const [payout, setPayout] = useState<IPayout>(initPayout);
  const [retrieveBalances, setRetrieveBalances] = useState<IRetrieveBalance[]>(
    []
  );
  const [availableCurrencies, setAvailableCurrencies] = useState<
    IAvailableCurrency[]
  >([]);
  const [createLoading, setCreateLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessID]);

  const loadingData = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    const allPromise: any[] = [
      getRetrieveBalances(businessID, token),
      getAvailableCurrencies(businessID, token, lang),
    ];

    const resp = await Promise.all(allPromise);
    for (let i = 0; i < resp[1].length; i++) {
      const bItem = resp[1][i];
      const existsInA = resp[0].some((aItem: any) => aItem.currency.currency_code === bItem.currency_code);
      if (!existsInA) {
        const newItem = { id: bItem.numeric_code, currency: bItem };
        resp[0].push(newItem);
      }
    }
    setRetrieveBalances(resp[0]);
    setAvailableCurrencies(resp[1]);
  };

  return (
    <div className="h-auto min-h-screen bg-system-background-color text-sm font-medium">
      <Transition.Root show={true} appear={true} className="h-full">
        <Transition.Child
          className="flex items-center justify-between p-4 md:px-6 md:py-8 lg:px-12"
          enter="duration-350 transform transition ease-in-out"
          enterFrom="-translate-x-1 translate-y-10 opacity-40"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="duration-500 transform transition"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="font-bold text-xl leading-[30px] md:text-3xl md:leading-10 text-color-text-1">
            {t('global_accounts:currency_conversion')}
          </div>
          <CloseIcon
            size={20}
            onClick={() => {
              if (recordId === 'noAccountId') {
                navigate(`/${lang}/businesses/${businessID}/global-accounts`);
              } else {
                navigate(
                  `/${lang}/businesses/${businessID}/global-accounts/${recordId}/current-activities`
                );
              }
            }}
            className="text-color-text-1 cursor-pointer hover:text-primary-color p-3 bg-white rounded-full"
          />
        </Transition.Child>
        <div className="flex-1 flex flex-col pb-6 md:flex-row lg:mt-6 lg:justify-center w-4/5 lg:w-3/5 mx-auto">
          <Transition.Child
            className="flex-[7]"
            enter="duration-500 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-10 opacity-40"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="pl-0  mx-auto w-[614px]">
              <FxConvert
                businessID={businessID}
                retrieveBalances={retrieveBalances}
                availableCurrencies={availableCurrencies}
                payout={payout}
                recordId={recordId}
                setPayout={setPayout}
                setCreateLoading={setCreateLoading}
                // setRetrieveBalances={setRetrieveBalances}
              />
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
      <GlobalLoading loading={createLoading} />
    </div>
  );
};

export default FxCurrent;
