import React from 'react';
import type { IIconProps } from '@/types/common-types';

const UploadIcon: React.FC<IIconProps> = (props) => {
    const { className = '', onClick } = props;
    return (
        <div className={`${className}`} onClick={onClick}>
            <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.1" y="0.324219" width="80" height="80" rx="40" fill="#0094FF" />
                <path fillRule="evenodd" clip-rule="evenodd" d="M54.0516 32.2121C53.3883 25.3576 47.6158 20 40.589 20C33.6484 20 27.9362 25.2289 27.1574 31.9613C20.4355 33.0406 15.2949 38.855 15.2949 45.8824C15.2949 53.6791 21.6158 60 29.4126 60H36.6006H43.6595H51.7655V59.9392C59.0105 59.3416 64.7067 53.282 64.7067 45.8824C64.7067 39.282 60.1715 33.7586 54.0516 32.2121ZM42.25 41.0355L46.7322 45.5178L48.5 47.2855L52.0355 43.75L50.2678 41.9822L41.5178 33.2322L39.75 31.4645L37.9822 33.2322L29.2322 41.9822L27.4645 43.75L31 47.2855L32.7678 45.5178L37.25 41.0355V52.5V55H42.25V52.5V41.0355Z" fill="#0094FF" />
            </svg>
        </div>
    );
};

export default UploadIcon;
