
import i18n from '@/i18n';
import { IBusiness } from '@/types/business-types';
import { getCurrentI18nValue } from '@/utils';
import { getStatusStyle, resolvingBusinessStatus, translateStatusFuc } from '@/utils/business-utils';
import { handleNumber, transformField } from '@/utils/field-utils';
import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import Button from '../common/button';
import NextIcon from '../svg/next-icon';

interface IBusinessItemProps {
  data: IBusiness;
  index: number;
  active?: boolean;
  onClick?: (business: IBusiness, active: boolean) => void;
}

const StoreCard = (props: IBusinessItemProps) => {
  const { data, active = false, onClick, index } = props;
  const { t } = useTranslation();

  const { currency_code, projectedBalance } = data;

  const lang = i18n.language;
  const currentStatus = resolvingBusinessStatus(data);
  const translateStatus = translateStatusFuc(data, currentStatus, lang, t);

  const address1 = getCurrentI18nValue(data, 'address_1', lang);
  const address2 = getCurrentI18nValue(data, 'address_2', lang);
  const city = getCurrentI18nValue(data, 'city', lang);
  const countryName = getCurrentI18nValue(data, 'country_name', lang);

  let storeInfo = [address1, address2, city, countryName].filter(
    (item) => item && item.length > 0
  );

  if (lang !== 'en') {
    storeInfo = storeInfo.reverse();
  }

  const handleClick = () => {
    if (onClick && currentStatus !== 'pending admission') {
      onClick(data, active);
    }
  };

  return (
    <Transition.Child
      style={{ transitionDuration: `${index}ms` }}
      className="flex"
      enter={`transform transition ease-in-out`}
      enterFrom="translate-y-6 opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave={`transform transition`}
      leaveFrom="translate-y-6"
    >
      <div
        className="rounded border flex-1 flex flex-col justify-between border-border-color py-4 px-6 overflow-hidden cursor-pointer duration-200 hover:shadow-dropdown hover:-translate-y-1.5"
        onClick={handleClick}
      >
        <div>
          <div
            className="py-1.5 px-4 rounded-3xl max-w-fit text-sm truncate "
            style={getStatusStyle(currentStatus)}
          >
            {transformField(translateStatus).toUpperCase()}
          </div>
          <div className="text-color-text-1 font-semibold text-lg mt-5 mb-3">
            {getCurrentI18nValue(data, 'business_name', lang)}
          </div>
          <div className="text-color-text-4 text-sm line-clamp-3">
            {storeInfo.join(', ')}
          </div>
        </div>
        {active && (
          <div className="">
            <div className="border-b border-border-color w-full my-3"></div>
            <div className="flex justify-between items-center font-medium text-sm">
              <div className="text-success-color truncate">
                {handleNumber(projectedBalance)} {currency_code}
              </div>
              <Button
                styleType="Text"
                iconPosition="Right"
                className="truncate"
                iconInstance={<NextIcon />}
              >
                {t('personal_space:explore')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Transition.Child>
  );
};

export default StoreCard;
