export { default } from './filter';

export interface IBaseFilterField {
  name: string;
  label: string;
}

export interface IFilterField extends IBaseFilterField {
  options?: IBaseFilterField[];
  children?: IFilterField[];
  type?: IFilterType;
  operations?: FilterOperation[];
}

export interface IFilterOptions {
  name: string;
  operation: FilterOperation;
  value: any;
  type: IFilterType;
}

export enum IFilterType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  SELECT = 'SELECT',
  NUMBER = 'NUMBER',
  MULTISELECT = 'MULTISELECT',
  ONE_TO_MANY = 'ONE_TO_MANY',
  MANY_TO_ONE = 'MANY_TO_ONE',
}

export enum FilterOperation {
  CONTAINS = '_contains',
  NO_CONTAINS = '_ncontains',
  EQUALS = '_eq',
  NO_EQUALS = '_neq',
  IS_NULL = '_null',
  IS_NOT_NULL = '_nnull',
  IS_BETWEEN = '_between',
}

export const operationTransform: any = {
  [FilterOperation.CONTAINS]: '_contains',
  [FilterOperation.NO_CONTAINS]: '_not_contains',
  [FilterOperation.EQUALS]: '_eq',
  [FilterOperation.NO_EQUALS]: '_neq',
  [FilterOperation.IS_NULL]: '_null',
  [FilterOperation.IS_NOT_NULL]: '_null',
  [FilterOperation.IS_BETWEEN]: '_between',
};

export const pathDivisionMark = '/';

export interface IFilterProps {
  fields: IFilterField[];
  searchValue: string;
  options: IFilterOptions[];
  onFilter: (search?: string, options?: IFilterOptions[]) => void;
  placeholder?: string;
}
