import { Constants } from '@/constants';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { queryJsonwebtoken } from '@/data/jsonwebtoken';

const DailyBreakdown = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const funderName = searchParams.get('funder_name');
  const businessID = searchParams.get('business_id') || '';
  const token = Cookies.get(Constants.MEtABASE_TOKEN) || '';

  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    getUrl()
  // eslint-disable-next-line
  }, [])

  const getUrl = async () => {
    if (!token) {
      navigate(`/metabase/embedding/login?funder_name=${funderName}`)
    } else {
      const METABASE_SITE_URL = 'https://metabase3.bindolabs.com';
      
      const EXP = Math.round(Date.now() / 1000) + 365 * 24 * 60 * 60; // 365 days expiration
      const payload = {
        resource: { dashboard: 326 },
        params: {
          business_id: businessID,
          funder_name: funderName,
        },
        exp: EXP,
      };
      const token = await queryJsonwebtoken(payload);
      const url =
        METABASE_SITE_URL +
        '/embed/dashboard/' +
        token +
        `?bordered=true&titled=true#fullscreen`;

        setUrl(url)
    }
  }

  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 flex">
      <iframe id="myIframe" title="myIframe" className="flex-1 border-none" src={url} />
    </div>
  );
};

export default DailyBreakdown;
