import { useEffect, useState } from 'react';
import Table from '@/components/table';
import type { IObject } from '@/types/common-types';
import { findNode } from '@/utils';
import { currencies } from '@/utils/currencies';
import Config from '@/libs/config';
import { handleMount } from '../../utils/account-balance-untils';
import {
  activeAccount,
  newgetAvailableCurrencies,
  getBusinessWallets,
} from '../../data/global-account';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import type { TableRecord } from '@/components/table/interface';
import Button from '@/components/common/button';
import ExportStatementModal from './export-statement-modale';
import { Dropdown } from 'antd';
import Menu, { Item as MenuItem } from 'rc-menu';
import { useParams, useNavigate } from 'react-router-dom';
import EllipsisIcon from '@/components/svg/ellipsis-icon';
import { getCookie } from '@/utils';
import { Constants } from '@/constants';
import ActionType from '@/actions/action-type';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';


const GloballAccountsPage = () => {
  const { lang = 'en', p_business_id: businessID = '' } = useParams();
  const { t } = useTranslation(["global_accounts", "account_balance"]);
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const [colums, setColums] = useState<ColumnsType<TableRecord>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [currencyCode, setCurrencyCode] = useState<string>('');
  const prefix = Config.staticPath;
  const token = getCookie(Constants.TOKEN) || '';

  useEffect(() => {
    getColums();
    getDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const getDataSource = async () => {
    const data = await newgetAvailableCurrencies(businessID, lang);
    const retrieveBalances = await getBusinessWallets(
      businessID,
      lang,
      token as string
    );
    const { ledger_accounts = [] } = retrieveBalances || {};

    const currentData = mergeData(data, ledger_accounts);

    setData(currentData);
    setLoading(false);
  };

  const mergeData = (data: IObject[], accounts: IObject[]) => {
    let returnArr: IObject[] = [];

    if (!Array.isArray(data) || !Array.isArray(accounts)) {
      return returnArr;
    }

    let newData: IObject[] = data;

    accounts.forEach((account) => {
      const currency = account.currency || {};
      const { currency_code } = currency;
      returnArr.push({
        ...account,
        ...currency,
      });
      newData = newData.filter((item) => item.currency_code !== currency_code);
    });

    returnArr = returnArr.concat(newData);

    return returnArr;
  };

  const handleNew = async (record: IObject, router: string) => {
    let ledgerAccountId = '';
    if (record.id) {
      ledgerAccountId = record.id;
    } else {
      const { currency_code } = record;
      ledgerAccountId = await activeAccount(businessID, currency_code);
    }

    if (ledgerAccountId) {
      if (router === 'fx') {
        navigate(`/${lang}/businesses/${businessID}/global-accounts/${ledgerAccountId}/fx?currency_code=${record.currency_code}`);
      }
      if (router === 'addfund') {
        navigate(`/${lang}/businesses/${businessID}/global-accounts/${ledgerAccountId}/addfund?currency_code=${record.currency_code}`);
      }
    }
  };

  const toFx = () => {
    const ledgerAccountId = 'noAccountId';
    navigate(
      `/${lang}/businesses/${businessID}/global-accounts/${ledgerAccountId}/fx`
    );
  };

  const getColums = () => {
    const columns: ColumnsType<TableRecord> = [
      {
        key: 'title',
        align: 'left',
        title: t('global_accounts:currency_account'),
        render: (_key, record) => {
          const { currency_code, display_name, currency_name } = record || {};
          const currencyobj: any = findNode(
            currencies,
            'currency_code',
            currency_code
          );
          if (!currencyobj) return;

          return (
            <>
              <div className="flex">
                <img
                  // layout="intrinsic"
                  width="33px"
                  height="33px"
                  src={`${prefix}/images/country-flag/${currencyobj.alpha_2.toLocaleLowerCase()}.svg`}
                  alt=""
                />
                <div className="ml-2">
                  <div className="text-color-text-1 text-sm font-semibold">
                    {currency_code}
                  </div>
                  <div className="text-color-text-2 text-xs">
                    {display_name || currency_name}
                  </div>
                </div>
              </div>
            </>
          );
        },
      },
      {
        key: 'current_balance',
        align: 'right',
        title: t('global_accounts:current_balance'),
        render: (_key, record) => {
          const { currency_code, currency_symbol, available = '0' } = record;
          return `${currency_code === 'HKD' ? 'HK' : ''
            }${currency_symbol}${handleMount(available)}`;
        },
      },
      {
        key: 'incoming',
        align: 'right',
        title: t('global_accounts:incoming_fund'),
        render: (_key, record) => {
          const {
            currency_code,
            currency_symbol,
            incoming = '0',
          } = record || {};
          return `${currency_code === 'HKD' ? 'HK' : ''
            }${currency_symbol}${handleMount(incoming)}`;
        },
      },
      {
        key: 'projected_balance',
        align: 'right',
        title: t('global_accounts:projected_balance'),
        render: (_key, record) => {
          const {
            currency_code,
            currency_symbol,
            incoming = '0',
            available = '0',
          } = record || {};
          return `${currency_code === 'HKD' ? 'HK' : ''
            }${currency_symbol}${handleMount(
              Number(available) + Number(incoming)
            )}`;
        },
      },
      {
        key: 'action',
        fixed: 'right',
        align: 'center',
        title: t('common:actions'),
        render: (_value, record) => {
          const { id } = record;
          return (
            <Dropdown
              destroyPopupOnHide={true}
              dropdownRender={() => (
                <Menu className="bg-white z-10">
                  <MenuItem key="view">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCellClick(record);
                      }}
                      className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                    >
                      <span className="text-color-text-1 text-sm font-medium">
                        {t('common:view')}
                      </span>
                    </div>
                  </MenuItem>
                  {id && (
                    <MenuItem key="export">
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          hanadleExport(record);
                        }}
                        className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                      >
                        <span className="text-color-text-1 text-sm font-medium">
                          {t('global_accounts:export_statement')}
                        </span>
                      </div>
                    </MenuItem>
                  )}
                  {id && (
                    <MenuItem key="fx">
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleNew(record, 'fx');
                        }}
                        className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                      >
                        <span className="text-color-text-1 text-sm font-medium">
                          {t('global_accounts:currency_conversion')}
                        </span>
                      </div>
                    </MenuItem>
                  )}
                  {id && (
                    <MenuItem key="addFund">
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleNew(record, 'addfund');
                        }}
                        className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                      >
                        <span className="text-color-text-1 text-sm font-medium">
                          {t('common:add_fund')}
                        </span>
                      </div>
                    </MenuItem>
                  )}
                </Menu>
              )}
            >
              <div className="justify-center flex">
                <div className="flex items-center cursor-pointer p-1.5 rounded-full w-max hover:bg-primary-color/10 group">
                  <EllipsisIcon className="text-icon-color group-hover:text-primary-color" />
                </div>
              </div>
            </Dropdown>
          );
        },
      },
    ];

    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('common:company_account'),
          key: '1',
          type: 'label',
        },
        {
          name: t('global_accounts:global_accounts'),
          key: '2',
          type: 'label',
        },
      ]
    });

    setColums(columns);
  };

  const handleCellClick = async (record: IObject) => {
    let ledgerAccountId = '';
    if (record.id) {
      ledgerAccountId = record.id;
    } else {
      const { currency_code } = record;
      ledgerAccountId = await activeAccount(businessID, currency_code);
    }

    if (ledgerAccountId) {
      navigate(
        `/${lang}/businesses/${businessID}/global-accounts/${ledgerAccountId}/current-activities`
      );
    }
  };

  const hanadleExport = (record: IObject) => {
    setCurrencyCode(record.currency_code);
    setShow(true);
  };

  return (
    <>
      <div className="p-4 md:p-6 lg:px-8 lg:py-6">
        <div className="flex items-center justify-between">
          <div className="text-3xl leading-10 font-bold text-color-text-1 ml-2.5">
            {t('global_accounts:global_accounts')}
          </div>
          <div className="flex">
            <Button
              onClick={() => setShow(true)}
              styleType="Secondary"
              className="mr-2"
            >
              {t('global_accounts:export_statement')}
            </Button>
            <Button onClick={() => toFx()} styleType="Secondary">
              {t('global_accounts:currency_conversion')}
            </Button>
          </div>
        </div>
        <div className="mt-6">
          <Table
            columns={colums}
            records={data}
            loadingData={loading}
            onRowCell={(record, dataKey) => {
              return {
                onClick: (evt) => {
                  evt.stopPropagation();
                  handleCellClick(record);
                },
              };
            }}
          />
        </div>
      </div>
      <ExportStatementModal
        show={show}
        onCancel={() => {
          setShow(false);
          setCurrencyCode('');
        }}
        currencyCode={currencyCode}
        pBusinessId={businessID}
      />
    </>
  );
};

export default GloballAccountsPage;
