import React from 'react';
import type { IIconProps } from '@/types/common-types';

const AddFundIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 32 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="22.5" cy="22.5" r="22.5" fill="#5EBCFF" />
        <circle
          cx="22.4816"
          cy="22.4816"
          r="10.4854"
          stroke="white"
          strokeWidth="1.4924"
        />
        <path
          d="M21.3717 22.8355L23.4052 22.8355C23.968 22.8355 24.4259 23.2645 24.4259 23.7919C24.4259 24.3211 23.9688 24.7484 23.4054 24.7484C23.4008 24.7484 23.3963 24.7483 23.3918 24.7483L21.4437 24.7483C20.9645 24.7441 20.5013 24.4988 20.2351 24.1087L20.2248 24.0936C19.9856 23.742 19.4876 23.6386 19.1125 23.8628C18.7373 24.0869 18.627 24.5536 18.8661 24.9052L18.8771 24.9213C19.4388 25.744 20.3551 26.2582 21.5829 26.2582L21.5829 27.1636C21.5829 27.5805 21.9436 27.9185 22.3885 27.9185C22.8333 27.9185 23.194 27.5805 23.194 27.1636L23.194 26.2582C23.9638 26.2582 24.6304 26.1273 25.2594 25.5423C25.7609 25.0757 26.0371 24.4541 26.0371 23.7919C26.0371 22.432 24.8564 21.3257 23.4052 21.3257L21.3717 21.3257C20.8089 21.3257 20.351 20.8967 20.351 20.3693C20.351 19.8606 20.7811 19.4128 21.3852 19.4128L23.5107 19.4128C23.9294 19.4165 24.3309 19.6002 24.6126 19.9171C24.8974 20.2375 25.4054 20.2808 25.7472 20.014C26.089 19.7471 26.1352 19.2711 25.8505 18.9508C25.0966 18.1028 24.2192 17.903 23.1941 17.903L23.1941 16.9976C23.1941 16.5807 22.8334 16.2428 22.3885 16.2428C21.9437 16.2428 21.583 16.5808 21.583 16.9976L21.583 17.903C19.7066 17.903 18.7399 19.1417 18.7399 20.3693C18.7398 21.7291 19.9205 22.8355 21.3717 22.8355Z"
          fill="white"
        />
        <rect
          x="27"
          y="29.4341"
          width="6.62421"
          height="6.12547"
          transform="rotate(-45 27 29.4341)"
          fill="#5EBCFF"
        />
        <path
          d="M27.5198 25.2697C25.3302 27.4593 25.3302 31.0221 27.5198 33.2117C29.7094 35.4013 33.2722 35.4013 35.4618 33.2117C37.6513 31.0221 37.6513 27.4593 35.4618 25.2697C33.2722 23.0802 29.7094 23.0802 27.5198 25.2697ZM33.9727 28.5388C34.3603 28.5387 34.6747 28.8531 34.6746 29.2407C34.6747 29.4345 34.596 29.6101 34.469 29.7371C34.342 29.8641 34.1664 29.9427 33.9727 29.9426H32.1927V31.7226C32.1928 31.9164 32.1142 32.0919 31.9872 32.219C31.8602 32.346 31.6846 32.4246 31.4908 32.4245C31.1031 32.4246 30.7888 32.1103 30.7889 31.7226V29.9426H29.0089C28.6213 29.9427 28.3069 29.6284 28.307 29.2407C28.3068 28.8532 28.6214 28.5386 29.0089 28.5388H30.7889V26.7589C30.7886 26.3713 31.1032 26.0567 31.4908 26.057C31.8785 26.0568 32.1928 26.3712 32.1927 26.7589V28.5388H33.9727Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default AddFundIcon;
