import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@/components/svg/close-icon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getCustomerID, resetListing } from '@/data/terminal';
import { getCookie } from '@/utils';
import { Constants } from '@/constants';

interface IStepWrapperParam {
  businessID: string;
  active: number;
  children: React.ReactNode;
}

const StepWrapper: React.FC<IStepWrapperParam> = (props) => {
  const { businessID, children, active } = props;

  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const navigate = useNavigate();
  const { lang = 'en' } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryToken = searchParams.get('token');
  let access_token = '';
  if (typeof queryToken === 'string' && queryToken.length > 0) {
    access_token = queryToken;
  }
  const cookieToken = getCookie(Constants.TOKEN) || '';
  const token = access_token || cookieToken;

  const steps = [
    {
      key: '1',
      title: t('extended_service:choose_item'),
    },
    {
      key: '2',
      title: t('extended_service:confirmation'),
    },
    {
      key: '3',
      title: t('extended_service:payment'),
    },
  ];

  const handleClose = () => {
    navigate(`/${lang}/businesses/${businessID}/terminal/orders`);
    reset()
  };

  const reset = async () => {
    let currentCustomerID = await getCustomerID(businessID, token);
    resetListing(currentCustomerID, businessID, token)
  }

  return (
    <div className="absolute inset-0 h-full flex flex-col bg-system-background-color overflow-hidden">
      <Transition.Root
        show={true}
        appear={true}
        className="h-full flex flex-col"
      >
        <Transition.Child
          className="flex items-center justify-between p-4 md:px-6 md:py-8 lg:px-12"
          enter="duration-350 transform transition ease-in-out"
          enterFrom="-translate-x-1 translate-y-10 opacity-40"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="duration-500 transform transition"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="font-bold text-xl leading-[30px] md:text-3xl md:leading-10 text-color-text-1">
            {t('extended_service:new_order')}
          </div>
          <CloseIcon
            size={20}
            onClick={handleClose}
            className="text-color-text-1 cursor-pointer hover:text-primary-color p-3 bg-white rounded-full"
          />
        </Transition.Child>
        <div
          className={`overflow-y-auto flex-1 flex hidden_scrollbar`}
        >
          <div
            className={`flex flex-col md:flex-row lg:mt-6 lg:justify-center w-full mx-auto px-2 md:px-0 lg:w-3/5`}
          >
            <Transition.Child
              className="flex items-center flex-col mb-4 md:mb-0 md:mr-12 md:block"
              enter="duration-450 transform transition ease-in-out"
              enterFrom="-translate-x-1 translate-y-10 opacity-40"
              enterTo="translate-x-0 translate-y-0 opacity-100"
              leave="duration-500 transform transition"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <ul className="flex justify-between w-full md:w-auto px-6 md:block md:px-0">
                {steps.map((step, index) => (
                  <div
                    className={`flex md:block ${index === steps.length - 1 ? '' : 'flex-1'
                      }`}
                    key={step.key}
                  >
                    <li
                      className={`flex items-center text-color-text-4 hover:text-primary-color`}
                    >
                      <span
                        className={`rounded-full w-6 h-6 flex-none flex items-center justify-center ${index <= active
                          ? 'bg-primary-color text-color-text-6'
                          : 'bg-disabled-color text-color-text-2 border border-border-color'
                          }`}
                      >
                        {step.key}
                      </span>
                      <span
                        className={`ml-3 font-medium whitespace-nowrap hidden md:block ${active <= index
                          ? 'text-color-text-2'
                          : 'text-color-text-1'
                          }`}
                      >
                        {step.title}
                      </span>
                    </li>
                    {index !== steps.length - 1 && (
                      <div
                        className={`mt-3 flex-1 h-0.5 md:w-0.5 md:h-[46px] md:ml-3 md:mt-0 ${index <= active
                          ? 'bg-primary-color'
                          : 'bg-border-color opacity-60'
                          }`}
                        aria-hidden="true"
                      ></div>
                    )}
                  </div>
                ))}
              </ul>
            </Transition.Child>
            <div className="flex-1">{children}</div>
          </div>
        </div>
      </Transition.Root>
    </div>
  );
};

export default StepWrapper;
