import { useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import AntdModal from '@/components/common/antd/modal';
import { IAssociateDetail } from '@/types/associates-type';
import { useTranslation } from 'react-i18next';
import Button from '@/components/common/button';
import WarningIcon from '@/components/svg/warning-icon';
import { IAreaCodes } from '@/types/common-types';
import CheckIcon from '@/components/svg/check-icon';
import { AntdSelect, Option } from '@/components/common/antd/select/select';
import { Constants } from '@/constants';
import { Alert } from '@/components/common/alert/alert';
import Label from '@/components/common/label';
import Input from '@/components/common/input';
import { getAvatarsName, getCookie } from '@/utils';
import { insertComma, findNode } from '@/utils';
import FormViewContext from '@/components/form-view';
import { approveAssociate } from '@/data/associates';

interface IPendingAdmissionModalProps {
  associate: IAssociateDetail;
  areaCodes: IAreaCodes[];
  businessID: string;
  roles: {
    id: string;
    name: string;
  }[];
  callback: () => void;
  onCancel: () => void;
}

const PendingAdmissionModal: React.FC<IPendingAdmissionModalProps> = (
  props
) => {
  const { associate, areaCodes, businessID, roles, callback, onCancel } = props;
  const { display_name, full_name, phone, email, calling_code, id } = associate;
  const [show, setShow] = useState(true);
  const [reject, setReject] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [approve, setApprove] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [role, setRole] = useState('')
  const [jobPosition, setJobPosition] = useState('')

  const useFormReturn = useForm({
    mode: 'all',
  });

  const { handleSubmit, getValues } = useFormReturn;

  const { t } = useTranslation('associates');
  const { t: ct } = useTranslation('common');

  const handleApprove = async () => {
    setApproveLoading(true);
    const values = getValues();
    const token = getCookie(Constants.TOKEN) || '';
    const success = await approveAssociate(
      id,
      businessID,
      { ...values, status: 'approve' },
      token
    );
    if (success) {
      Alert.success({
        message: t('new_associate_added'),
        position: 'default',
        wrapClass: 'top-px',
      });
      callback();
      onCancel();
    } else {
      Alert.error({
        message: t('approve_associate_failed'),
        position: 'default',
        wrapClass: 'top-px',
      });
    }
    setApproveLoading(false);
  };

  const handleReject = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    setRejectLoading(true);
    const success = await approveAssociate(
      id,
      businessID,
      { status: 'reject' },
      token
    );
    if (success) {
      setDone(true);
      setReject(false);
    } else {
      Alert.error({
        message: t('reactivate_associate_failed'),
        position: 'default',
        wrapClass: 'top-px',
      });
    }
    setRejectLoading(false);
  };

  const parsePhone = useMemo(() => {
    const phoneNumber = `${calling_code ? `+${calling_code} ` : ''}${phone}`;
    if (phoneNumber && phoneNumber.charAt(0) === '+') {
      const tArray: string[] = phone.split(/\s+/);
      const dialItem = findNode<IAreaCodes>(
        areaCodes,
        'phoneCountryCode',
        tArray[0].replace('+', '')
      );
      return (
        <div className="group rounded flex items-center h-10 px-3 py-2.5 cursor-auto pl-0 text-sm text-color-text-1 font-medium">
          {dialItem && (
            <span className="flex items-center relative w-4 mr-1">
              <img
                width="16px"
                height="16px"
                src={`/images/country-flag/${dialItem.isoAlpha2Code.toLocaleLowerCase()}.svg`}
                alt=""
              />
            </span>
          )}
          <span>
            {tArray[0]}{' '}
            {insertComma(
              tArray[tArray.length - 1].replace(/ /g, ''),
              true,
              4,
              ' '
            )}
          </span>
        </div>
      );
    } else {
      return <div>{phoneNumber}</div>;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AntdModal size="medium" open={show} footer={null} onCancel={onCancel}>
        <div className="p-6 pt-8 flex flex-col text-color-text-1">
          <div className="grid grid-cols-1 gap-y-6">
            <div className="font-bold text-xl text-center">
              {t('information')}
            </div>
            <div className="flex items-center">
              <div className="w-16 h-16 rounded-full flex justify-center items-center bg-primary-color/10 text-xl font-bold">
                {getAvatarsName(display_name)}
              </div>
              <div className="ml-4 text-lg font-semibold text-color-text-1">
                {display_name}
              </div>
            </div>
            <div>
              <Label value={t('full_name')}></Label>
              <Input value={full_name} disabled={true} />
            </div>
            <div>
              <Label value={t('phone_number')}></Label>
              {parsePhone}
            </div>
            <div>
              <Label value={t('email')}></Label>
              <Input value={email} disabled={true} />
            </div>
            <div>
              <Label value={t('notes')}></Label>
              <Input value={''} disabled={true} />
            </div>
          </div>
          <div className="flex mt-11">
            <Button
              className="w-full"
              styleType="Secondary Danger"
              onClick={() => {
                setShow(false);
                setReject(true);
              }}
            >
              {t('reject')}
            </Button>
            <Button
              className="w-full ml-6"
              onClick={() => {
                setShow(false);
                setApprove(true);
              }}
            >
              {t('approve')}
            </Button>
          </div>
        </div>
      </AntdModal>

      <AntdModal size="medium" open={approve} footer={null} onCancel={onCancel}>
        <div className="p-6 pt-8 flex flex-col text-color-text-1">
          <form>
            <div className="grid grid-cols-1 gap-y-6">
              <div className="font-bold text-xl text-center">
                {t('set_role')}
              </div>
              <div className="flex items-center">
                <div className="w-16 h-16 rounded-full flex justify-center items-center bg-primary-color/10 text-xl font-bold">
                  {getAvatarsName(display_name)}
                </div>
                <div className="ml-4 text-lg font-semibold text-color-text-1">
                  {display_name}
                </div>
              </div>
              <FormViewContext.Provider value={{ viewConfig: undefined }}>
                <Controller
                  name={'job_position'}
                  control={useFormReturn.control}
                  rules={{
                    required: t('field_is_required'),
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div>
                      <Label
                        value={t('job_title')}
                        required={true}
                      ></Label>
                      <Input
                        onChange={(data) => {
                          onChange(data);
                          setJobPosition(data);
                        }}
                        placeholder={t('enter_job_title')}
                        value={value}
                        error={error}
                      />
                    </div>
                  )}
                />
                <Controller
                  name={'role_id'}
                  control={useFormReturn.control}
                  rules={{
                    required: t('field_is_required'),
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div>
                      <Label
                        value={t('role')}
                        required={true}
                      ></Label>
                      <AntdSelect
                        showSearch={true}
                        optionLabelProp="label"
                        filterOption={(inputValue, option) => {
                          const value = inputValue.toLocaleLowerCase();
                          if (option && option.data) {
                            return (
                              option.data.name
                                .toLocaleLowerCase()
                                .indexOf(value) !== -1
                            );
                          }
                          return false;
                        }}
                        onSelect={(_data, option) => {
                          onChange(option.value);
                          setRole(option.value);
                        }}
                        placeholder={t('choose_role')}
                        value={value}
                        error={error}
                      >
                        {roles.map((item) => {
                          return (
                            <Option
                              key={item.id}
                              value={item.id}
                              label={item.name}
                              data={item}
                            >
                              <div className="flex justify-between items-center">
                                <div className="text-color-text-1">
                                  {item.name}
                                </div>
                                {value === item.id && <CheckIcon />}
                              </div>
                            </Option>
                          );
                        })}
                      </AntdSelect>
                    </div>
                  )}
                />
              </FormViewContext.Provider>
            </div>
          </form>
          <Button
            className="w-full mt-11"
            onClick={handleSubmit(handleApprove)}
            loading={approveLoading}
            disabled={!jobPosition || !role}
          >
            {ct('confirm')}
          </Button>
        </div>
      </AntdModal>

      <AntdModal
        open={reject}
        footer={null}
        closeIcon={null}
        closable={false}
        onCancel={() => {
          setReject(false);
          setShow(true);
        }}
      >
        <div className="p-10 pt-8 flex text-center flex-col items-center text-color-text-1">
          <div className="flex p-5 bg-warning-color/10 text-warning-color rounded-full">
            <WarningIcon size={40} />
          </div>
          <div className="text-xl font-bold mt-4">
            {t('reject_record')}
          </div>
          <div className="text-sm font-medium mt-2">
            {t('reject_record_info')}
          </div>
          <Button
            className="w-full mt-6"
            onClick={handleReject}
            loading={rejectLoading}
          >
            {ct('confirm')}
          </Button>
          <Button
            className="w-full mt-4"
            styleType="Border"
            onClick={() => {
              setReject(false);
              setShow(true);
            }}
          >
            {ct('cancel')}
          </Button>
        </div>
      </AntdModal>

      <AntdModal open={done} footer={null} closeIcon={null} closable={false}>
        <div className="p-10 pt-8 flex text-center flex-col items-center text-color-text-1">
          <div className="flex p-5 bg-warning-color/10 text-warning-color rounded-full">
            <WarningIcon size={40} />
          </div>
          <div className="text-xl font-bold mt-4">
            {t('request_rejected')}
          </div>
          <div className="text-sm font-medium mt-2">
            {t('request_rejected_info')}
          </div>
          <Button
            className="w-full mt-6"
            onClick={() => {
              callback();
              onCancel();
            }}
          >
            {t('done')}
          </Button>
        </div>
      </AntdModal>
    </>
  );
};

export default PendingAdmissionModal;
