import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ResetPwdSuccess: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div
      className={`inline-flex items-center justify-center ${className}`}
      onClick={onClick}
    >
      <svg
        width="176"
        height="176"
        viewBox="0 0 176 176"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M138.704 45.1634C119.369 44.8363 100.094 39.3194 82.6094 29.0195C65.1413 39.3194 45.8661 44.8363 26.5271 45.1634C22.5859 87.236 35.3191 137.861 82.6094 156.67C129.916 137.861 142.601 87.236 138.704 45.1634Z"
          fill="#0094FF"
          stroke="#0094FF"
        />
        <path
          d="M129.33 53.1363C113.227 52.8639 97.1746 48.2694 82.6134 39.6914C68.0656 48.2694 52.0129 52.8639 35.907 53.1363C32.6247 88.1752 43.2291 130.337 82.6134 146.001C122.011 130.337 132.576 88.1752 129.33 53.1363Z"
          fill="#CDE7FF"
        />
        <path
          d="M75.4515 86.3137V76.5641C75.4516 75.5864 75.6454 74.6185 76.0216 73.7162C76.3979 72.8139 76.9493 71.9951 77.6438 71.307C78.3384 70.619 79.1624 70.0754 80.0682 69.7077C80.974 69.3399 81.9437 69.1552 82.9213 69.1644H83.7343C84.712 69.1549 85.6819 69.3393 86.5879 69.7069C87.4939 70.0745 88.318 70.6181 89.0126 71.3062C89.7073 71.9943 90.2586 72.8133 90.6348 73.7157C91.0109 74.6182 91.2045 75.5863 91.2042 76.5641V79.8485C91.2059 80.0687 91.2939 80.2795 91.4492 80.4356C91.6045 80.5918 91.8148 80.6809 92.035 80.6838L95.9688 80.7016C96.0788 80.702 96.1878 80.6807 96.2896 80.6389C96.3914 80.5972 96.4839 80.5357 96.5619 80.4581C96.6399 80.3806 96.7019 80.2883 96.7442 80.1868C96.7865 80.0852 96.8083 79.9763 96.8085 79.8663V77.1075C96.8089 75.4151 96.4757 73.7393 95.8278 72.1758C95.1799 70.6124 94.2301 69.192 93.0328 67.996C91.8354 66.8 90.414 65.8518 88.8498 65.2056C87.2856 64.5595 85.6093 64.2281 83.917 64.2305H82.7387C81.0467 64.229 79.3711 64.561 77.8075 65.2074C76.244 65.8539 74.8232 66.8022 73.6263 67.9981C72.4295 69.194 71.4801 70.6141 70.8324 72.1772C70.1847 73.7402 69.8514 75.4156 69.8516 77.1075V86.5743L75.4515 86.3137Z"
          fill="#0094FF"
        />
        <path
          d="M98.6718 114.351C99.6565 114.348 100.6 113.956 101.296 113.26C101.993 112.564 102.385 111.62 102.387 110.635V89.5045C102.385 88.5199 101.993 87.5762 101.296 86.88C100.6 86.1837 99.6565 85.7915 98.6718 85.7891H67.9928C67.0081 85.7915 66.0645 86.1837 65.3682 86.88C64.672 87.5762 64.2798 88.5199 64.2773 89.5045V110.635C64.2798 111.62 64.672 112.564 65.3682 113.26C66.0645 113.956 67.0081 114.348 67.9928 114.351H98.6718Z"
          fill="#0094FF"
        />
        <path
          d="M83.3282 93.0391C82.2734 93.0427 81.2629 93.4634 80.5171 94.2093C79.7714 94.9553 79.351 95.9659 79.3477 97.0207C79.3659 97.6196 79.5213 98.2063 79.8018 98.7357C80.0824 99.2652 80.4806 99.7232 80.966 100.075V104.747C80.966 105.373 81.2148 105.974 81.6578 106.417C82.1008 106.86 82.7017 107.109 83.3282 107.109C83.9547 107.109 84.5556 106.86 84.9986 106.417C85.4416 105.974 85.6904 105.373 85.6904 104.747V100.075C86.175 99.7225 86.5727 99.2643 86.8532 98.735C87.1336 98.2057 87.2894 97.6194 87.3087 97.0207C87.3054 95.9659 86.885 94.9553 86.1392 94.2093C85.3935 93.4634 84.383 93.0427 83.3282 93.0391Z"
          fill="#014BDC"
        />
        <path
          d="M142.109 15.6445C124.464 15.6445 110.027 30.0815 110.027 47.7266C110.027 65.3717 124.464 79.8086 142.109 79.8086C159.755 79.8086 174.191 65.3717 174.191 47.7266C174.191 30.0815 159.755 15.6445 142.109 15.6445Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M142 80C159.673 80 174 65.6731 174 48C174 30.3269 159.673 16 142 16C124.327 16 110 30.3269 110 48C110 65.6731 124.327 80 142 80ZM157.771 41.0191C158.747 40.0428 158.747 38.4599 157.771 37.4836C156.794 36.5073 155.211 36.5073 154.235 37.4836L138.503 53.2158L131.521 46.2336C130.544 45.2573 128.961 45.2573 127.985 46.2336C127.009 47.2099 127.009 48.7928 127.985 49.7691L136.735 58.5191C137.711 59.4954 139.294 59.4954 140.271 58.5191L157.771 41.0191Z"
          fill="#10B981"
        />
        <path
          d="M157.771 41.0191L158.124 41.3727L158.124 41.3727L157.771 41.0191ZM154.235 37.4836L154.589 37.8372L154.589 37.8372L154.235 37.4836ZM138.503 53.2158L138.149 53.5694L138.503 53.9229L138.856 53.5694L138.503 53.2158ZM131.521 46.2336L131.167 46.5872L131.167 46.5872L131.521 46.2336ZM140.271 58.5191L139.917 58.1656L139.917 58.1656L140.271 58.5191ZM173.5 48C173.5 65.397 159.397 79.5 142 79.5V80.5C159.949 80.5 174.5 65.9493 174.5 48H173.5ZM142 16.5C159.397 16.5 173.5 30.603 173.5 48H174.5C174.5 30.0507 159.949 15.5 142 15.5V16.5ZM110.5 48C110.5 30.603 124.603 16.5 142 16.5V15.5C124.051 15.5 109.5 30.0507 109.5 48H110.5ZM142 79.5C124.603 79.5 110.5 65.397 110.5 48H109.5C109.5 65.9493 124.051 80.5 142 80.5V79.5ZM157.417 37.8372C158.198 38.6182 158.198 39.8845 157.417 40.6656L158.124 41.3727C159.296 40.2011 159.296 38.3016 158.124 37.13L157.417 37.8372ZM154.589 37.8372C155.37 37.0561 156.636 37.0561 157.417 37.8372L158.124 37.13C156.952 35.9585 155.053 35.9585 153.881 37.13L154.589 37.8372ZM138.856 53.5694L154.589 37.8372L153.881 37.13L138.149 52.8623L138.856 53.5694ZM131.167 46.5872L138.149 53.5694L138.856 52.8623L131.874 45.88L131.167 46.5872ZM128.339 46.5872C129.12 45.8061 130.386 45.8061 131.167 46.5872L131.874 45.88C130.702 44.7085 128.803 44.7085 127.631 45.88L128.339 46.5872ZM128.339 49.4156C127.557 48.6345 127.557 47.3682 128.339 46.5872L127.631 45.88C126.46 47.0516 126.46 48.9511 127.631 50.1227L128.339 49.4156ZM137.089 58.1656L128.339 49.4156L127.631 50.1227L136.381 58.8727L137.089 58.1656ZM139.917 58.1656C139.136 58.9466 137.87 58.9466 137.089 58.1656L136.381 58.8727C137.553 60.0443 139.452 60.0443 140.624 58.8727L139.917 58.1656ZM157.417 40.6656L139.917 58.1656L140.624 58.8727L158.124 41.3727L157.417 40.6656Z"
          fill="#10B981"
        />
      </svg>
    </div>
  );
};

export default ResetPwdSuccess;
