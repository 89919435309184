import type { IObject } from '@/types/common-types';
import PaymentErrorIcon from '../terminal-icons/payment-error-icon';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@/components/svg/close-icon';
import type { ITerminalOrdersPaymentResultPageProps } from '../../../types/terminal-type';
import { getClassByStatus, handlePrice } from '../../../utils/terminal-untils';
import Button from '@/components/common/button';
import { Transition } from '@headlessui/react';
import PaymentSuccessIcon from '../terminal-icons/payment-success-icon';
import DefaultItemIcon from '../terminal-icons/default-item-icon';
import AntdTips from '@/components/common/antd/tips';
import PanelTitle from '../components/panel-title';
import { transformField } from '@/utils/field-utils';
import dayjs from 'dayjs';
import { useState } from 'react';
import { downloadInvoicePDF } from '../../../data/terminal';
import { useParams } from 'react-router-dom';
import { queryBusiness } from '@/data/businesses';
import { useUserAction } from '@/utils/common';
import { Alert } from '@/components/common/alert/alert';

interface IPaymentResultWebPage extends ITerminalOrdersPaymentResultPageProps {
  record: IObject;
  loadingData: boolean;
  handleBack: () => void;
  handleTryAgain: () => void;
}

const PaymentResultWeb: React.FC<IPaymentResultWebPage> = (props) => {
  const { errorCode, message, record, businessID, inApp, handleBack, handleTryAgain } =
    props;
  const {
    initial_total = '',
    currency = 'HKD',
    line_items = [],
    subtotal,
    initial_total: total,
    discount_total: discountTotal = 0,
    oms_delivery_note = {},
    initial_delivery: initialDelivery = 0,
    number,
    reference_number: referenceNumber = '',
    correspondence_state: correspondenceState = '',
    delivery_order: deliveryOrder = {},
    created_at: createdAt = '',
    note,
  } = record;

  const { state, tracking_number, shipper } = deliveryOrder;
  const lineItems = line_items || [];
  const cardList = lineItems.filter(
    (item: any) => item.purchasable_type === 'Listing'
  );
  let cartTotal = 0;
  cardList.map((item: any) => {
    return cartTotal += item.quantity;
  });

  const {
    name = '',
    phone = '',
    address1 = '',
    street = '',
    city = '',
    max_delivery_time = '',
    min_delivery_time = '',
  } = oms_delivery_note;

  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const { lang = 'en' } = useParams();
  const dateFormat = lang === 'en' ? 'MMM DD YYYY' : 'YYYY年MM月DD日';
  useUserAction(inApp ? true : false);
  let scheduleAt = '--';

  if (max_delivery_time && min_delivery_time) {
    scheduleAt = `${t('extended_service:estimated')} ${dayjs(
      min_delivery_time
    ).tz('Asia/Hong_Kong').format(dateFormat)} ${dayjs(min_delivery_time).tz('Asia/Hong_Kong').format(
      'HH:mm'
    )} ~ ${dayjs(max_delivery_time).tz('Asia/Hong_Kong').format('HH:mm')}`;
  }

  const [loading, setLoading] = useState<boolean>(false);

  const isError = errorCode.length > 0;

  const handlePaymentStatus = (status: string) => {
    const { className, showStatus } = getClassByStatus(status, t);
    if (!status) return '';

    return (
      <span
        className={`font-medium rounded-3xl text-xs px-3 py-1 bg-opacity-10 ${className}`}
      >
        {showStatus.toUpperCase()}
      </span>
    );
  };

  const handleDownload = async () => {
    setLoading(true);
    try {
      const business = await queryBusiness(businessID);
      const { file_name = '', url = '' } = await downloadInvoicePDF(
        record,
        business,
        lang
      );
      if (url) {
        await fetch(url).then((res) =>
          res.blob().then((blob) => {
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${`${file_name}`}`;
            a.click();
            window.URL.revokeObjectURL(url);
            Alert.success({
              message: t('common:exported_successfully'),
              wrapClass: '-top-10',
            });
          })
        );
      }
    } catch (error) {
      console.error(error);
      Alert.error({
        message: t('common:exported_failed'),
        wrapClass: '-top-10',
      });
    }
    setLoading(false);
  };

  const addressInfo = [address1, street, city].filter(
    (item) => item && item.length > 0
  );

  return (
    <div className="absolute inset-0 h-full text-color-text-1 flex flex-col bg-system-background-color overflow-hidden font-medium">
      {isError && (
        <div className="flex flex-col flex-1 items-center justify-center relative p-10 pt-[12%] md:px-[30%]">
          <CloseIcon
            size={20}
            onClick={handleBack}
            className="absolute top-8 right-8 cursor-pointer hover:text-primary-color p-3 bg-white rounded-full"
          />
          <div className="font-bold text-3xl mb-[60px]">
            {t('extended_service:paymen_failed')}
          </div>
          <PaymentErrorIcon />
          <div className="text-3xl mt-4">
            <span className="font-bold mr-1">{handlePrice(initial_total)}</span>
            <span className="font-light">{currency}</span>
          </div>
          <div className="text-error-color text-sm text-center my-4">
            <div>
              {t('extended_service:reason_code')}: {errorCode}
            </div>
            <div>{message}</div>
          </div>
          <div className="text-sm text-center">
            {t('extended_service:paymen_failed_tips')}
          </div>
          <div className="mt-auto w-full">
            {errorCode !== '100103' && (
              <Button onClick={handleTryAgain} className="w-full mb-6">
                {t('extended_service:try_again')}
              </Button>
            )}
            <Button
              onClick={handleBack}
              className="w-full"
              styleType="Secondary"
            >
              {t('common:cancel')}
            </Button>
          </div>
        </div>
      )}
      {!isError && (
        <div
          className={`flex flex-col flex-1 items-center overflow-y-auto hidden_scrollbar pt-14 md:p-14`}
        >
          <CloseIcon
            size={20}
            onClick={handleBack}
            className="absolute top-8 right-8 cursor-pointer hover:text-primary-color p-3 bg-white rounded-full z-10"
          />
          <div className="flex-1">
            <div className="flex items-center flex-col">
              <PaymentSuccessIcon />
              <div className="font-bold text-xl">
                {t('extended_service:order_confirmed')}
              </div>
            </div>
            <Transition.Root className="mt-6" show={true} appear={true}>
              <Transition.Child
                className="rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 min-w-full md:min-w-[30rem]"
                enter="duration-700 transform transition ease-in-out"
                enterFrom="-translate-x-1 translate-y-10 opacity-0"
                enterTo="translate-x-0 translate-y-0 opacity-100"
                leave="duration-500 transform transition"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                {Array.isArray(cardList) &&
                  cardList.length > 0 &&
                  cardList.map((item) => (
                    <div
                      key={item.id}
                      className="mb-4 flex py-4 justify-between"
                    >
                      <div className="flex">
                        <div className="p-2 bg-disabled-color rounded-2xl">
                          {item.image_url && (
                            <div
                              className="w-16 h-16 flex justify-center items-center bg-center bg-no-repeat bg-contain"
                              style={{
                                backgroundImage: `url(${item.image_url})`,
                              }}
                            />
                          )}
                          {!item.image_url && (
                            <div
                              style={{
                                backgroundColor: 'rgba(226, 228, 231, 0.15)',
                              }}
                              className="w-16 h-16 flex justify-center items-center rounded"
                            >
                              <DefaultItemIcon />
                            </div>
                          )}
                        </div>
                        <div className="ml-2 font-medium flex flex-col justify-between">
                          <div className="flex items-center text-sm max-w-16 line-clamp-2 overflow-ellipsis lg:max-w-28">
                            <span>{item.label}</span>
                            {`${item.label}`.includes('POS') && (
                              <AntdTips
                                overlayInnerStyle={{
                                  width: '320px',
                                }}
                                className="ml-1"
                                content={t('extended_service:cart_item_tips')}
                              />
                            )}
                          </div>
                          <div className="text-base">
                            {handlePrice(item.price)} {currency}
                          </div>
                        </div>
                      </div>
                      <div className="whitespace-nowrap font-medium text-sm self-end">
                        x {item.quantity}
                      </div>
                    </div>
                  ))}
                <div className="mt-6 p-4 rounded-2xl border text-sm font-medium border-primary-color">
                  <div className="flex items-center justify-between mb-3">
                    <div className="text-color-text-2">
                      {t('extended_service:total_items')}
                    </div>
                    <div>
                      {cartTotal} {t('extended_service:items')}
                    </div>
                  </div>
                  <div className="flex items-center justify-between mb-3">
                    <div className="text-color-text-2">
                      {t('extended_service:subtotal')}
                    </div>
                    <div>
                      {handlePrice(subtotal)} {currency}
                    </div>
                  </div>
                  <div className="flex items-center justify-between mb-3">
                    <div className="text-color-text-2">
                      {t('extended_service:delivery_fee')}
                    </div>
                    <div>
                      {handlePrice(initialDelivery)} {currency}
                    </div>
                  </div>
                  {discountTotal !== 0 && (
                    <div className="flex items-center justify-between mb-3">
                      <div className="text-color-text-2">
                        {t('extended_service:discount')}
                      </div>
                      <div>
                        -{handlePrice(discountTotal)} {currency}
                      </div>
                    </div>
                  )}
                  <div className="flex items-center justify-between text-base font-bold">
                    <div>{t('extended_service:total_amount')}</div>
                    <div>
                      {handlePrice(total)} {currency}
                    </div>
                  </div>
                </div>
              </Transition.Child>
              <Transition.Child
                className="rounded overflow-hidden shadow-card-drop bg-primary-background-color mt-6 p-4 md:p-8 min-w-[24rem]"
                enter="duration-700 transform transition ease-in-out"
                enterFrom="-translate-x-1 translate-y-10 opacity-0"
                enterTo="translate-x-0 translate-y-0 opacity-100"
                leave="duration-500 transform transition"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <PanelTitle title={t('extended_service:shipping_address')} />
                <div className="font-semibold">{name}</div>
                <div className="text-sm mt-2">{phone}</div>
                <div className="text-sm">{addressInfo.join(', ')}</div>
                <div className="w-full border-b border-border-color h-1 my-4"></div>
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <span className="text-color-text-2 mr-1">
                      {t('extended_service:delivery_via')}
                    </span>
                    <AntdTips
                      content={t('extended_service:delivery_via_tips')}
                    />
                  </div>
                  <div>{transformField(shipper) || '--'}</div>
                </div>
                <div className="w-full border-b border-border-color h-1 my-4"></div>
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <span className="text-color-text-2 mr-1">
                      {t('extended_service:delivery_schedule')}
                    </span>
                    <AntdTips
                      content={t('extended_service:delivery_schedule_tips')}
                    />
                  </div>
                  <div>{scheduleAt}</div>
                </div>
                <div className="w-full border-b border-border-color h-1 my-4"></div>
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <span className="text-color-text-2 mr-1">
                      {t('extended_service:tracking_number')}
                    </span>
                    <AntdTips
                      content={t('extended_service:tracking_number_tips')}
                    />
                  </div>
                  <div>{tracking_number || '--'}</div>
                </div>
              </Transition.Child>
              <Transition.Child
                className="rounded overflow-hidden shadow-card-drop bg-primary-background-color mt-6 p-4 md:p-6 min-w-[24rem]"
                enter="duration-700 transform transition ease-in-out"
                enterFrom="-translate-x-1 translate-y-10 opacity-0"
                enterTo="translate-x-0 translate-y-0 opacity-100"
                leave="duration-500 transform transition"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <PanelTitle title={t('extended_service:invoice_details')} />
                <div className="flex items-center justify-between text-sm">
                  <div className="text-color-text-2">
                    {t('common:invoice_number_common')}
                  </div>
                  <div>{number}</div>
                </div>
                <div className="w-full border-b border-border-color h-1 my-4"></div>
                <div className="flex items-center justify-between text-sm">
                  <div className="text-color-text-2">
                    {t('extended_service:reference_number')}
                  </div>
                  <div>{referenceNumber}</div>
                </div>
                <div className="w-full border-b border-border-color h-1 my-4"></div>
                <div className="flex items-center justify-between text-sm">
                  <div className="text-color-text-2">
                    {t('extended_service:payment_status')}
                  </div>
                  <div>{handlePaymentStatus(correspondenceState)}</div>
                </div>
                <div className="w-full border-b border-border-color h-1 my-4"></div>
                <div className="flex items-center justify-between text-sm">
                  <div className="text-color-text-2">
                    {t('extended_service:delivery_status')}
                  </div>
                  <div>{handlePaymentStatus(state)}</div>
                </div>
                <div className="w-full border-b border-border-color h-1 my-4"></div>
                <div className="flex items-center justify-between text-sm">
                  <div className="text-color-text-2">
                    {t('extended_service:created_at')}
                  </div>
                  <div>{dayjs(createdAt).tz('Asia/Hong_Kong').format('MMM D, YYYY HH:mm:ss')}</div>
                </div>
                <div className="w-full border-b border-border-color h-1 my-4"></div>
                <div className="text-sm">
                  <div className="text-color-text-2">
                    {t('extended_service:note')}
                  </div>
                  <div className="mt-2">{note}</div>
                </div>
              </Transition.Child>
              <div className="mt-6 grid grid-cols-2 gap-6">
                <Button
                  className="w-full"
                  loading={loading}
                  styleType="Secondary"
                  onClick={handleDownload}
                >
                  {t('extended_service:download_invoice_pdf')}
                </Button>
                <Button className="w-full" onClick={handleBack}>
                  {t('common:done')}
                </Button>
              </div>
            </Transition.Root>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentResultWeb;
