import { useEffect, useState } from 'react';
import type { IStoreOverviewFormViewProps } from './type';
import { Transition } from '@headlessui/react';
import { getCookie } from '@/utils/index';
import Config from '@/libs/config';
import { Constants } from '@/constants';
import api from '@/libs/api';
import { useTranslation } from 'react-i18next';
import { getPaymentMethodIcon } from '@/utils/account-balance-untils';
import TipIcon from '@/components/svg/tip-icon';
import RedTipIcon from '@/components/svg/red-tip.icon';
import AntdModal from '@/components/common/antd/modal';
import WarningIcon from '@/components/svg/warning-icon';
import Button from '@/components/common/button';


const FeesSettlement = (props: IStoreOverviewFormViewProps) => {
    const { businessID = '' } = props;
    const { t } = useTranslation(["settings", "business_account_settings", "account_balance", 'transaction_advice', 'extended_service', 'payment_link']);
    const [currency, setCurrency] = useState('');
    const [period, setPeriod] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [modalType, setModalType] = useState<String>('');
    const [offlineList, setOfflineList] = useState([{
        iconType: 'Mastercard',
        active: false,
        key: 'mastercard',
    }, {
        iconType: 'Visa',
        active: false,
        key: 'visa',
    }, {
        iconType: 'UnionPay',
        active: false,
        key: 'cup',
    }, {
        iconType: 'JCB',
        active: false,
        key: 'jcb',
    }, {
        iconType: 'American Express',
        active: false,
        key: 'amex',
    }, {
        iconType: 'Discover',
        active: false,
        key: 'discover',
    }, {
        iconType: 'Diners Club',
        active: false,
        key: 'diners',
    }, {
        iconType: 'Apple Pay',
        active: false,
        key: 'apple_pay',
    }, {
        iconType: 'Google Pay',
        active: false,
        key: 'google_pay',
    }, {
        iconType: 'Samsung Pay',
        active: false,
        key: 'samsung_pay',
    }, {
        iconType: 'Huawei Pay',
        active: false,
        key: 'huawei_pay',
    }, {
        iconType: 'WeChat Pay',
        active: false,
        key: 'wechat',
    }, {
        iconType: 'AliPayHK',
        active: false,
        key: 'alipay',
    }, {
        iconType: 'Alipay',
        active: false,
        key: 'alipay',
    }, {
        iconType: 'FPS',
        active: false,
        key: 'fps',
    }, {
        iconType: 'UnionPay Wallet',
        active: false,
        key: 'unionpay_wallet',
    }, {
        iconType: 'BoC Pay',
        active: false,
        key: 'unionpay_wallet',
    }, {
        iconType: 'Xpay',
        active: false,
        key: 'xpay',
    }, {
        iconType: 'Octopus',
        active: false,
        key: 'octopus_business',
    }, {
        iconType: 'Tap&Go',
        active: false,
        key: 'tap_go',
    }]);

    const [onlineList, setOnlineList] = useState([{
        iconType: 'Mastercard',
        active: false,
        key: 'mastercard',
    }, {
        iconType: 'Visa',
        active: false,
        key: 'visa',
    }, {
        iconType: 'UnionPay',
        active: false,
        key: 'cup',
    }, {
        iconType: 'JCB',
        active: false,
        key: 'jcb',
    }, {
        iconType: 'American Express',
        active: false,
        key: 'amex',
    }, {
        iconType: 'Discover',
        active: false,
        key: 'discover',
    }, {
        iconType: 'Diners Club',
        active: false,
        key: 'diners',
    }, {
        iconType: 'Apple Pay',
        active: false,
        key: 'apple_pay',
    }, {
        iconType: 'Google Pay',
        active: false,
        key: 'google_pay',
    }, {
        iconType: 'WeChat Pay',
        active: false,
        key: 'wechat',
    }, {
        iconType: 'AliPayHK',
        active: false,
        key: 'alipay',
    }, {
        iconType: 'Alipay',
        active: false,
        key: 'alipay',
    }, {
        iconType: 'FPS',
        active: false,
        key: 'fps',
    }, {
        iconType: 'UnionPay Wallet',
        active: false,
        key: 'unionpay_wallet',
    }, {
        iconType: 'Octopus',
        active: false,
        key: 'octopus_business',
    }, {
        iconType: 'BoC Pay',
        active: false,
        key: 'unionpay_wallet',
    }
    ])


    useEffect(() => {
        getBankAccountList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getBankAccountList = async () => {
        setLoading(true)
        const token = getCookie(Constants.TOKEN) || '';
        try {
            const result: any = await api.get(
                `${Config.urls.gatewayUrl}/api/treasury-services/settlement/details`,
                {
                    headers: {
                        'x-user-access-token': token,
                        'x-p-business-id': businessID || '',
                    },
                }
            )
            if (result.code === 200) {
                setCurrency(result.data.settlement_currency)
                setPeriod(result.data.settlement_period)
            }
            const paymentResult: any = await api.get(
                `${Config.urls.mainBindo}/api/b2c/app/payment_link/store/${businessID}/payment_methods`,
                {
                    headers: {
                        'x-user-access-token': token,
                        'x-p-business-id': businessID || '',
                    },
                }
            )
            if (paymentResult.payment_process_rule.length > 0) {
                getList(paymentResult.payment_process_rule)
            }
        } catch (e) { console.error(e) }
        setLoading(false)
    };

    const getList = (list: any) => {
        let offlineArr: any[] = []
        let onlineArr: any[] = []
        let allList: any[] = []
        let flag = false
        const allEntryType: any[] = ['mastercard', 'visa', 'jcb', 'diners', 'discover', 'cup']


        list.forEach((ruleList: any) => {
            let entryArr: any[] = []
            if (ruleList.payment_entry_types === null && ruleList.payment_methods !== null) {
                ruleList.payment_methods.forEach((method: String) => {
                    onlineArr.push(method)
                    offlineArr.push(method)
                    if (allEntryType.includes(method)) {
                        onlineArr.push('google_pay')
                        onlineArr.push('apple_pay')
                    }
                })
            }
            if (ruleList.payment_entry_types !== null && ruleList.payment_methods === null) {
                ruleList.payment_entry_types.forEach((item: String) => {
                    entryArr.push(item)
                    //apple pay
                    if (item === 'apple_pay') {
                        onlineArr.push('apple_pay')
                    }
                    //google pay
                    if (item === 'google_pay') {
                        onlineArr.push('google_pay')
                    }

                    if (item === 'manual' || item === 'apple_pay' || item === 'google_pay') {
                        allEntryType.forEach((method: String) => {
                            onlineArr.push(method)
                        })
                    }

                    if (item === 'merchant_presented_qr_code' || item === 'consumer_presented_qr_code' || item === 'in_web' || item === 'in_app' || item === 'cit' || item === 'mit' || item === 'official_account') {
                        //微信
                        onlineArr.push('wechat')
                        //fps
                        if (item === 'merchant_presented_qr_code') {
                            onlineArr.push('fps')
                        }
                        //unionpay wallet
                        if (item === 'in_web' || item === 'in_app') {
                            onlineArr.push('unionpay_wallet')
                        }
                    }
                })
                //支付宝
                if (entryArr.includes('merchant_presented_qr_code') && entryArr.includes('in_web')) {
                    onlineArr.push('alipay')
                }
            }
            if (ruleList.payment_entry_types !== null) {
                ruleList.payment_entry_types.forEach((item: String) => {
                    allList.push(item)
                    //离线激活逻辑
                    if (item === 'contactless' || item === 'swipe' || item === 'contact') {
                        //entryType为contactless支持以下任一method则为支持apple pay、google pay、Samsung Pay、Huawei Pay 
                        if (item === 'contactless') {
                            ruleList.payment_methods.forEach((method: String) => {
                                if (method === 'mastercard' || method === 'visa' || method === 'cup' || method === 'jcb' || method === 'discover' || method === 'diners' || method === 'octopus_business' || method === 'amex')
                                    flag = true
                            })
                        }
                        ruleList.payment_methods.forEach((method: String) => {
                            if (method === 'mastercard' || method === 'visa' || method === 'cup' || method === 'jcb' || method === 'amex' || method === 'discover' || method === 'diners' || method === 'octopus_business')
                                offlineArr.push(method)
                        })
                    }
                    if (item === 'consumer_presented_qr_code') {
                        ruleList.payment_methods.forEach((method: String) => {
                            if (method === 'wechat' || method === 'alipay' || method === 'tap_go' || method === 'xpay' || method === 'unionpay_wallet')
                                offlineArr.push(method)
                        })
                    }

                    if (item === 'merchant_presented_qr_code') {
                        ruleList.payment_methods.forEach((method: String) => {
                            if (method === 'fps') {
                                offlineArr.push(method)
                                //支持fps同样就支持tapGo
                                offlineArr.push('tap_go')
                            }
                        })
                    }


                    //在线激活逻辑
                    if (item === 'manual' || item === 'apple_pay' || item === 'google_pay' || item === 'merchant_presented_qr_code' || item === 'in_web' || item === 'official_account' || item === 'in_app' || item === 'mit' || item === 'cit') {
                        ruleList.payment_methods.forEach((method: String) => {
                            if (method !== 'alipay') {
                                onlineArr.push(method)
                            }
                        })
                        if (item === 'apple_pay' || item === 'google_pay') {
                            onlineArr.push(item)
                        }
                    }
                    //alipay
                    if (allList.includes('merchant_presented_qr_code') && allList.includes('in_web')) {
                        ruleList.payment_methods.forEach((method: String) => {
                            if (method === 'alipay') {
                                onlineArr.push(method)
                            }
                        })
                    }

                })
            }

        })

        offlineArr = Array.from(new Set(offlineArr))
        onlineArr = Array.from(new Set(onlineArr))

        const paymentArr = ['apple_pay', 'google_pay', 'samsung_pay', 'huawei_pay']
        const newofflineList = offlineList.map((item: any) => {
            if (offlineArr.includes(item.key) || (flag && paymentArr.includes(item.key))) {
                return {
                    ...item,
                    active: true,
                };
            }
            return {
                ...item
            }
        });
        const newOnlineList = onlineList.map((item: any) => {
            if (onlineArr.includes(item.key)) {
                return {
                    ...item,
                    active: true,
                };
            }
            return {
                ...item
            }
        });
        setOfflineList(newofflineList)
        setOnlineList(newOnlineList)
    }


    const onCancel = () => {
        setShowModal(false)
    }

    const cilckIcon = (type: String) => {
        setModalType(type)
        setShowModal(true)
    }

    const getLang = (type: String) => {
        let srt = type
        switch (type) {
            case 'UnionPay':
                srt = t('transaction_advice:union_pay')
                break;
            case 'Huawei Pay':
                srt = t('transaction_advice:huawei_pay')
                break;
            case 'AliPayHK':
                srt = t('payment_link:alipayhk')
                break;
            case 'Alipay':
                srt = t('transaction_advice:alipay')
                break;
            case 'WeChat Pay':
                srt = t('transaction_advice:wechat_pay')
                break;
            case 'UnionPay Wallet':
                srt = t('transaction_advice:unionpay_wallet')
                break;
            case 'Octopus':
                srt = t('transaction_advice:octopus')
                break;
        }
        return srt
    }


    return (
        <>
            <div className="w-full mt-6">
                <Transition
                    show={true}
                    appear={true}
                    className="z-80 w-full flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit"
                    enter="duration-700 transform transition ease-in-out"
                    enterFrom="-translate-x-1 translate-y-10 opacity-30"
                    enterTo="translate-x-0 translate-y-0 opacity-100"
                    leave="duration-500 transform transition"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                >
                    <div className="font-semibold text-lg text-color-text-1 mb-4">
                        {t('business_account_settings:settlement_details')}
                    </div>
                    <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />

                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-2 md:gap-y-6 mb-2 md:mb-6">
                        <div className="w-full">
                            <div className="text-sm font-medium text-color-text-1 mb-1.5 flex items-center">
                                {t('business_account_settings:settlement_period')} <TipIcon className='ml-1 cursor-pointer' onClick={() => cilckIcon('0')} />
                            </div>
                            <div className="flex border-border-color border bg-disabled-color rounded h-10 pl-3 items-center">
                                {!loading && (
                                    <div className="text-sm text-color-text-1 flex items-center font-medium">
                                        T+{period}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-2">
                        <div className="w-full">
                            <div className="text-sm font-medium text-color-text-1 mb-1.5 flex items-center">
                                {t('business_account_settings:settlement_currency')} <TipIcon className='ml-1 cursor-pointer' onClick={() => cilckIcon('1')} />
                            </div>
                            <div className="border-border-color border bg-disabled-color rounded h-10 pl-3 text-sm text-color-text-1 flex items-center font-medium">
                                {!loading && (
                                    <> {currency}</>
                                )}
                            </div>
                        </div>
                    </div>
                </Transition>
                <Transition
                    show={true}
                    appear={true}
                    className="z-70 w-full flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit"
                    enter="duration-350 transform transition ease-in-out"
                    enterFrom="-translate-x-1 translate-y-8 opacity-60"
                    enterTo="translate-x-0 translate-y-0 opacity-100"
                    leave="duration-500 transform transition"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                >
                    <div className="font-semibold text-lg text-color-text-1 mb-4 flex items-center">
                        {t('business_account_settings:offline_payment')} <TipIcon className='ml-1 cursor-pointer' onClick={() => cilckIcon('2')} />
                    </div>
                    <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
                    <div className="flex justify-between flex-wrap">
                        {/* {!loading && ( */}
                        <>
                            {offlineList.map((item: any, i: number) => (
                                <div className='w-[45%] flex justify-between items-center m-[24px]' key={`${item.iconType}-${i}`}>
                                    <div className='flex items-center'>
                                        <div className={`${item.active ? '' : 'opacity-50'}`}>{getPaymentMethodIcon(item.iconType)}</div>
                                        <div className={`ml-[2px] ${item.active ? '' : 'text-[#8E8E93]'}`}>{getLang(item.iconType)}</div>
                                        {!item.active && (<RedTipIcon className='ml-1 cursor-pointer' onClick={() => cilckIcon('3')} />)}
                                    </div>
                                    {item.active ?
                                        <div className='bg-[#4CD964] text-[#4CD964] font-medium rounded-3xl text-xs px-3 py-1 bg-opacity-10 h-[24px]'>
                                            {t('settings:active')}
                                        </div>
                                        : <div className='bg-[#FF0000] text-[#FF0000] font-medium rounded-3xl text-xs px-3 py-1 bg-opacity-10 h-[24px]'>
                                            {t('settings:inactive')}
                                        </div>}
                                </div>
                            ))}
                        </>
                        {/* )} */}
                    </div>
                </Transition>
                <div
                    className={`w-full flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit`}
                >
                    <div className="font-semibold text-lg text-color-text-1 mb-4 flex items-center">
                        {t('settings:online_payment')} <TipIcon className='ml-1 cursor-pointer' onClick={() => cilckIcon('4')} />
                    </div>
                    <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
                    <div className="flex justify-between flex-wrap">
                        {/* {loading && ( */}
                        <>
                            {onlineList.map((item, i) => (
                                <div className='w-[45%] flex justify-between items-center m-[24px]' key={`${item.key}-${i}`}>
                                    <div className='flex items-center'>
                                        <div className={`${item.active ? '' : 'opacity-50'}`}>{getPaymentMethodIcon(item.iconType)}</div>
                                        <div className={`ml-[2px] ${item.active ? '' : 'text-[#8E8E93]'}`}>{getLang(item.iconType)}</div>
                                        {!item.active && (<RedTipIcon className='ml-1 cursor-pointer' onClick={() => cilckIcon('3')} />)}
                                    </div>
                                    {item.active ?
                                        <div className='bg-[#4CD964] text-[#4CD964] font-medium rounded-3xl text-xs px-3 py-1 bg-opacity-10 h-[24px]'>
                                            {t('settings:active')}
                                        </div>
                                        : <div className='bg-[#FF0000] text-[#FF0000] font-medium rounded-3xl text-xs px-3 py-1 bg-opacity-10 h-[24px]'>
                                            {t('settings:inactive')}
                                        </div>}
                                </div>
                            ))
                            }
                        </>
                    </div>
                </div>
            </div>

            <AntdModal
                open={showModal}
                footer={null}
                onCancel={onCancel}
                closable={false}
                className="!w-[456px]"
            >
                <div className="p-10 flex flex-col items-center">
                    <div className="bg-warning-color/10 p-[18px] rounded-full">
                        <WarningIcon size={36} className="text-warning-color" />
                    </div>
                    {modalType === '0' && (
                        <>
                            <div className="text-color-text-1 font-bold text-xl mt-6 whitespace-pre-line break-all text-center ">
                                {t('business_account_settings:settlement_period')}
                            </div>
                            <div className="text-color-text-1 text-sm font-medium text-center mt-2">
                                {t('business_account_settings:settlement_period_tips')}
                            </div>
                        </>
                    )}
                    {modalType === '1' && (
                        <>
                            <div className="text-color-text-1 font-bold text-xl mt-6 whitespace-pre-line break-all text-center ">
                                {t('business_account_settings:settlement_currency')}
                            </div>
                            <div className="text-color-text-1 text-sm font-medium text-center mt-2">
                                {t('business_account_settings:settlement_currency_tips')}
                            </div>
                        </>
                    )}
                    {modalType === '2' && (
                        <>
                            <div className="text-color-text-1 font-bold text-xl mt-6 whitespace-pre-line break-all text-center ">
                                {t('business_account_settings:offline')}
                            </div>
                            <div className="text-color-text-1 text-sm font-medium text-center mt-2">
                                {t('business_account_settings:offline_payment_tips')}
                                <span className='text-[#0094FF]'> support@wonder.app</span>
                            </div>
                        </>
                    )}
                    {modalType === '3' && (
                        <>
                            <div className="text-color-text-1 font-bold text-xl mt-6 whitespace-pre-line break-all text-center ">
                                {t('business_account_settings:inactive_payment')}
                            </div>
                            <div className="text-color-text-1 text-sm font-medium text-center mt-2">
                                {t('business_account_settings:inactive_payment_tips')}
                                <span className='font-bold'>
                                    {t('business_account_settings:inactive_payment_tips_one')}
                                </span>
                                {t('business_account_settings:inactive_payment_tips_two')}
                                <span className='font-bold'>
                                    {t('business_account_settings:inactive_payment_tips_three')}
                                </span>
                                {t('business_account_settings:inactive_payment_tips_four')}
                                <span className='text-[#0094FF]'> support@wonder.app</span>
                            </div>
                        </>
                    )}
                    {modalType === '4' && (
                        <>
                            <div className="text-color-text-1 font-bold text-xl mt-6 whitespace-pre-line break-all text-center ">
                                {t('settings:online')}
                            </div>
                            <div className="text-color-text-1 text-sm font-medium text-center mt-2">
                                {t('business_account_settings:online_payment_tips')}
                                <span className='text-[#0094FF]'> support@wonder.app</span>
                            </div>
                        </>
                    )}

                    <Button
                        className="bg-primary-color text-sm w-full mt-6 h-9 font-medium"
                        onClick={onCancel}
                    >
                        {t('common:back')}
                    </Button>
                </div>


            </AntdModal>

        </>
    );
};

export default FeesSettlement;
