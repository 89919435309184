import { handleBuildOrderPayment } from '../utils';
import { payInvoice } from '../../../../../data/terminal';
import type { ICreditCardMethod } from '../interface';

interface ICreditCardPayParams {
  businessID: string;
  orderNumber: string;
  payData: ICreditCardMethod;
  payment: any;
  email: string;
  lang: string;
}

const UseCreditCardPay = async (params: ICreditCardPayParams) => {
  const { businessID, orderNumber, payment, payData, email, lang } = params;

  const { amount, returnUrl, token, currenToken } = payData;

  const newPayment = {
    credit_cards: [
      {
        amount,
        token,
        card_reader_mode: 'manual',
        success_url: returnUrl,
        fail_url: returnUrl,
      },
    ],
  };

  const order = handleBuildOrderPayment(newPayment, payment);
  try {
    const resp: any = await payInvoice({
      businessID,
      orderNumber,
      order,
      email,
      lang,
      token: currenToken,
    });

    const { data: resOrder } = resp || {};

    if (
      resOrder &&
      resOrder.sale_transactions &&
      resOrder.sale_transactions.length > 0
    ) {
      let acquirer: any = '';
      let transaction: any = '';
      if (resOrder.state !== 'completed' && resOrder.state !== 'voided') {
        resOrder.sale_transactions.forEach((item: any) => {
          if (item.payment_method === 4 && item.is_pending) {
            transaction = item;
          }
        });
      }

      if (
        transaction &&
        transaction['3ds'] &&
        transaction['3ds'].redirect_url
      ) {
        acquirer = transaction['3ds'].redirect_url;
      }

      if (acquirer) {
        window.location.href = acquirer;
        return;
      }
      return {
        order: resOrder,
      };
    } else {
      return {
        status: 500,
        ...resp,
      };
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export default UseCreditCardPay;
