import React from 'react';
import type { IIconProps } from '@/types/common-types';

const AddressPositionIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="15"
        height="19"
        viewBox="0 0 15 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.97356 4.17858C8.82136 4.17858 9.63443 4.51537 10.2339 5.11485C10.8334 5.71433 11.1702 6.52741 11.1702 7.3752C11.1702 8.223 10.8334 9.03607 10.2339 9.63556C9.63443 10.235 8.82136 10.5718 7.97356 10.5718C7.12576 10.5718 6.31269 10.235 5.71321 9.63556C5.11373 9.03607 4.77694 8.223 4.77694 7.3752C4.77694 6.52741 5.11373 5.71433 5.71321 5.11485C6.31269 4.51537 7.12576 4.17858 7.97356 4.17858ZM14.9862 7.37661C14.9861 6.28487 14.7312 5.20822 14.2417 4.23239C13.7521 3.25656 13.0415 2.40851 12.1664 1.75576C11.2913 1.10301 10.2759 0.663597 9.20097 0.47251C8.12609 0.281424 7.02143 0.343942 5.97497 0.655087C4.92851 0.966231 3.96915 1.51741 3.17328 2.26472C2.37741 3.01204 1.76701 3.93486 1.3907 4.95969C1.01439 5.98452 0.88255 7.08306 1.00569 8.16784C1.12883 9.25261 1.50354 10.2936 2.09998 11.2081L7.34575 18.2024C7.404 18.3193 7.49366 18.4176 7.60469 18.4863C7.71571 18.555 7.84369 18.5914 7.97426 18.5914C8.10483 18.5914 8.23281 18.555 8.34384 18.4863C8.45486 18.4176 8.54453 18.3193 8.60277 18.2024L13.8584 11.2081C14.5662 10.1041 14.9862 8.79233 14.9862 7.37661Z"
          fill="#222222"
        />
      </svg>
    </div>
  );
};

export default AddressPositionIcon;
