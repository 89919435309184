import ActionType from "@/actions/action-type";
import { IQueryBusiness } from "@/actions/business-action";
import { queryBusiness } from "@/data/businesses";
import { IReducersState } from "@/reducers";
import { IBusiness } from "@/types/business-types";
import Action from '@/actions';
import { put, select, takeEvery } from "redux-saga/effects";

export function* handleQueryBusiness(params: IQueryBusiness) {
  const { businessId } = params.payload;
  const reducersState: IReducersState = yield select((state: IReducersState) => state);
  const { business } = reducersState.businessState;

  try {
    
    if (!business || business.id !== businessId) {
      const businessData: IBusiness = yield queryBusiness(businessId);

      if (businessData && businessData.id) {
        
        yield put<Action>({
          type: ActionType.SET_BUSINESS,
          business: businessData,
        });
        
      }

    }

  } catch (error) {
    
  }
}
  

function* businessSaga() {
  yield takeEvery<string, any>(ActionType.QUERY_BUSINESS, handleQueryBusiness);
}

export default businessSaga;