import React from 'react';
import type { IIconProps } from '@/types/common-types';

const WarnErrorIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="54"
        height="53"
        viewBox="0 0 54 53"
        fill="none"
      >
        <g clipPath="url(#clip0_83016_165422)">
          <path
            d="M26.8334 4C22.5152 4 18.2939 5.28051 14.7034 7.6796C11.1129 10.0787 8.3145 13.4886 6.66198 17.4781C5.00946 21.4677 4.57709 25.8576 5.41953 30.0929C6.26198 34.3282 8.34141 38.2185 11.3949 41.272C14.4483 44.3254 18.3387 46.4049 22.5739 47.2473C26.8092 48.0898 31.1992 47.6574 35.1887 46.0049C39.1783 44.3524 42.5882 41.5539 44.9873 37.9634C47.3864 34.3729 48.6669 30.1517 48.6669 25.8334C48.6669 20.0428 46.3666 14.4894 42.272 10.3949C38.1774 6.3003 32.624 4 26.8334 4ZM26.8334 44.0279C23.2349 44.0279 19.7172 42.9609 16.7251 40.9616C13.733 38.9624 11.401 36.1208 10.0239 32.7962C8.64679 29.4716 8.28648 25.8132 8.98852 22.2838C9.69056 18.7545 11.4234 15.5125 13.968 12.968C16.5125 10.4234 19.7545 8.69055 23.2839 7.98851C26.8133 7.28647 30.4716 7.64678 33.7962 9.02388C37.1208 10.401 39.9624 12.733 41.9616 15.7251C43.9609 18.7172 45.028 22.2349 45.028 25.8334C45.028 28.2228 44.5573 30.5887 43.643 32.7962C42.7286 35.0036 41.3884 37.0094 39.6989 38.6989C38.0094 40.3884 36.0036 41.7286 33.7962 42.643C31.5887 43.5573 29.2228 44.0279 26.8334 44.0279Z"
            fill="#FC2E01"
          />
          <path
            d="M26.834 29.5985C26.2067 29.5985 25.6051 29.3493 25.1615 28.9057C24.7179 28.4621 24.4688 27.8605 24.4688 27.2332V16.3165C24.4688 15.6891 24.7179 15.0875 25.1615 14.6439C25.6051 14.2004 26.2067 13.9512 26.834 13.9512C27.4613 13.9512 28.063 14.2004 28.5065 14.6439C28.9501 15.0875 29.1993 15.6891 29.1993 16.3165V27.2332C29.1993 27.8605 28.9501 28.4621 28.5065 28.9057C28.063 29.3493 27.4613 29.5985 26.834 29.5985Z"
            fill="#FC2E01"
          />
          <path
            d="M26.7409 37.6957C28.2482 37.6957 29.4701 36.4738 29.4701 34.9665C29.4701 33.4592 28.2482 32.2373 26.7409 32.2373C25.2336 32.2373 24.0117 33.4592 24.0117 34.9665C24.0117 36.4738 25.2336 37.6957 26.7409 37.6957Z"
            fill="#FC2E01"
          />
        </g>
        <defs>
          <clipPath id="clip0_83016_165422">
            <rect
              width="53"
              height="53"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default WarnErrorIcon;
