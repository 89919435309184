import { Alert } from '@/components/common/alert/alert';
import { AntdSelect, Option } from '@/components/common/antd/select/select';
import Button from '@/components/common/button';
import Input from '@/components/common/input';
import Label from '@/components/common/label';
import PhoneNumber from '@/components/common/phone-number';
import Select from '@/components/common/select/select';
import FormViewContext from '@/components/form-view';
import { Constants } from '@/constants';
import { createPayee } from '@/data/payouts';
import Config from '@/libs/config';
import CheckIcon from '@/components/svg/check-icon';
import { IAreaCodes } from '@/types/common-types';
import { ICategory, INewPayeeFormData, IPayoutMethod } from '@/types/payouts-type';
import { getCookie, verifyEmail } from '@/utils';
import { useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

interface IPayeeInformationProps {
  businessID: string;
  areaCodes: IAreaCodes[];
  heade: React.ReactNode;
  payoutMethod: IPayoutMethod;
  categories: ICategory[];
  payeeData: INewPayeeFormData;
  setCurrentStep: (currentStep: number) => void;
  setStepCount: (stepCount: number) => void;
}

const PayeeInformation: React.FC<IPayeeInformationProps> = (props) => {
  const {
    areaCodes,
    payeeData,
    businessID,
    categories,
    payoutMethod,
    heade,
    setCurrentStep,
    setStepCount,
  } = props;
  const {
    identifier_1_type,
    identifier_2_type,
    identifier_3_type,
    transfer_method,
  } = payoutMethod;
  const { t } = useTranslation('payees');
  const { t: ct } = useTranslation('common');
  const { lang = 'en' } = useParams();
  const navigate = useNavigate();

  const isPlatform =
    identifier_1_type === 'Platform Business ID' ||
    identifier_2_type === 'Platform Business ID' ||
    identifier_3_type === 'Platform Business ID';

  const [payeeType, setPayeeType] = useState('Business');
  const [loading, setLoading] = useState(false);

  const initValues = useMemo(() => {
    let email = '';
    let phone = '';
    if (identifier_1_type === 'Email') {
      email = payeeData.identifier_1_value;
    }
    if (identifier_2_type === 'Email') {
      email = payeeData.identifier_2_value;
    }
    if (identifier_3_type === 'Email') {
      email = payeeData.identifier_3_value;
    }
    if (identifier_1_type === 'Phone Number') {
      phone = payeeData.identifier_1_value;
    }
    if (identifier_2_type === 'Phone Number') {
      phone = payeeData.identifier_2_value;
    }
    if (identifier_3_type === 'Phone Number') {
      phone = payeeData.identifier_3_value;
    }

    return {
      ...payeeData,
      type: 'Business',
      email,
      phone,
    };
    // eslint-disable-next-line
  }, []);

  const useFormReturn = useForm({
    mode: 'all',
    defaultValues: {
      ...initValues,
    },
  });

  const { getValues, handleSubmit } = useFormReturn;

  const handleNext = async () => {
    setLoading(true);
    const values = getValues();
    const {
      company_name,
      email,
      nickname,
      billing_address_1,
      billing_address_2,
      city,
      country,
      currency,
      state,
      zip_code,
      payout_category_id,
      phone,
      type,
    } = values;

    let phone_country_code = '';
    let phone_number = '';
    const phones = phone.split(' ');
    if (phones.length > 1 && phones[1]) {
      phone_country_code = phones[0].replace('+', '');
      phone_number = phones[1];
    }

    const { identifier_1_value, identifier_2_value, identifier_3_value, bank } =
      payeeData;

    if (payout_category_id && nickname && (type === 'People' || company_name)) {
      const newPayeeData = {
        company_name,
        email,
        is_default_bank: false,
        nickname,
        p_business_id: businessID,
        payout_account: {
          billing_address_1,
          billing_address_2,
          city,
          country,
          currency,
          identifier_1_value:
            identifier_1_type === 'SWIFT' ? bank.id : identifier_1_value,
          identifier_2_value,
          identifier_3_value,
          state,
          transfer_method,
          zip_code,
        },
        payout_category_id,
        phone_country_code,
        phone_number,
        related_company_payee: false,
        type,
      };
      const token = getCookie(Constants.TOKEN) || '';
      const { code, message, data } = await createPayee(
        businessID,
        token,
        newPayeeData,
        lang
      );

      if (code === 200 && data.payee && data.payee.id) {
        Alert.success({
          message: t('new_payee_successfully'),
          position: 'default',
          wrapClass: 'top-px',
        });
        navigate(`/${lang}/businesses/${businessID}/payees`);
        return;
      }
      Alert.error({
        message,
        position: 'default',
        wrapClass: 'top-px',
      });
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        {heade}
        <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
        <form>
          <FormViewContext.Provider value={{ viewConfig: undefined }}>
            <Label
              className="mb-0 mt-4 md:mt-6 md:mb-1.5"
              value={t('payee_type')}
            />
            <Controller
              name={'type'}
              control={useFormReturn.control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  options={[
                    {
                      label: t('business'),
                      value: 'Business',
                      color: 'bg-[#FFA217] text-[#FFA217]',
                    },
                    {
                      label: t('people'),
                      value: 'People',
                      color: 'bg-primary-color text-primary-color',
                    },
                  ]}
                  renderItem={(item) => {
                    const { label, color } = item;

                    return (
                      <span
                        className={`px-3 py-1.5 rounded-3xl text-xs ${color} bg-opacity-10 uppercase`}
                      >
                        {label}
                      </span>
                    );
                  }}
                  disabled={isPlatform}
                  value={value}
                  onChange={(data) => {
                    setPayeeType(data);
                    onChange(data);
                  }}
                />
              )}
            />

            <Label
              className="mb-0 mt-4 md:mt-6 md:mb-1.5"
              value={t('payee_nickname')}
              required={true}
            />
            <Controller
              name={'nickname'}
              control={useFormReturn.control}
              rules={{
                required: ct('no_data_hint'),
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  onChange={(data) => {
                    onChange(data);
                  }}
                  value={value}
                  error={error}
                  placeholder={t('enter_payee_nickname')}
                />
              )}
            />

            {payeeType === 'Business' && (
              <>
                <Label
                  className="mb-0 mt-4 md:mt-6 md:mb-1.5"
                  value={t('payee_company')}
                  required={true}
                />
                <Controller
                  name={'company_name'}
                  control={useFormReturn.control}
                  rules={{
                    required: ct('no_data_hint'),
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      {isPlatform && (
                        <div className="bg-disabled-color border-border-color border h-10 flex items-center rounded px-3 leading-6 text-color-text-2 shadow-box-drop text-sm">
                          {value}
                        </div>
                      )}
                      {!isPlatform && (
                        <Input
                          onChange={(data) => {
                            onChange(data);
                          }}
                          value={value}
                          error={error}
                          placeholder={t('enter_payee_company')}
                        />
                      )}
                    </>
                  )}
                />
              </>
            )}

            <Label
              className="mb-0 mt-4 md:mt-6 md:mb-1.5"
              value={t('payee_email')}
            />
            <Controller
              name={'email'}
              control={useFormReturn.control}
              rules={{
                validate: (value) => {
                  let msg = undefined;
                  if (!verifyEmail(value) && value) {
                    msg = t('invalid_email_address');
                  }
                  return msg;
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  onChange={(data) => {
                    onChange(data);
                  }}
                  value={value}
                  error={error}
                  placeholder={t('enter_payee_email')}
                />
              )}
            />

            <Label
              className="mb-0 mt-4 md:mt-6 md:mb-1.5"
              value={t('payee_phone_number')}
            />
            <Controller
              name={'phone'}
              control={useFormReturn.control}
              render={({ field: { onChange, value } }) => (
                <PhoneNumber
                  value={value}
                  showSearch={true}
                  options={areaCodes}
                  name={''}
                  onChange={(data) => {
                    onChange(data);
                  }}
                />
              )}
            />

            <Label
              className="mb-0 mt-4 md:mt-6 md:mb-1.5"
              value={t('payout_category')}
              required={true}
            />
            <Controller
              name={'payout_category_id'}
              control={useFormReturn.control}
              rules={{
                required: ct('no_data_hint'),
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AntdSelect
                  onSelect={(_value, option) => onChange(option.value)}
                  value={value}
                  error={error}
                  optionLabelProp="label"
                  placeholder={t('choose_payout_category')}
                >
                  {categories.map((item) => {
                    return (
                      <Option
                        key={item.id}
                        data={item}
                        value={item.id}
                        label={
                          <div className="flex items-center h-full">
                            <div
                              className="w-5 h-5 rounded-full p-1 flex items-center"
                              style={{ backgroundColor: item.color }}
                            >
                              <img
                                width={12}
                                height={12}
                                src={`${Config.urls.gatewayUrl}/api/directus/treasury-services/files/${item.icon_file}.svg`}
                                alt=""
                              />
                            </div>
                            <div className="text-sm font-medium text-color-text-1 flex-1 ml-2">
                              {item.category_name}
                            </div>
                          </div>
                        }
                      >
                        <div className="flex items-center">
                          <div
                            className="w-5 h-5 rounded-full p-1 flex items-center"
                            style={{ backgroundColor: item.color }}
                          >
                            <img
                              width={12}
                              height={12}
                              src={`${Config.urls.gatewayUrl}/api/directus/treasury-services/files/${item.icon_file}.svg`}
                              alt=""
                            />
                          </div>
                          <div className="text-sm font-medium text-color-text-1 flex-1 ml-2">
                            {item.category_name}
                          </div>
                          {value === item.id && <CheckIcon />}
                        </div>
                      </Option>
                    );
                  })}
                </AntdSelect>
              )}
            />

            {(transfer_method === 'RTGS' || transfer_method === 'SWIFT') && (
              <>
                <Label
                  className="mb-0 mt-4 md:mt-6 md:mb-1.5"
                  value={t('billing_address_1')}
                  required={true}
                />
                <Controller
                  name={'billing_address_1'}
                  control={useFormReturn.control}
                  rules={{
                    required: ct('no_data_hint'),
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      onChange={(data) => {
                        onChange(data);
                      }}
                      value={value}
                      error={error}
                      placeholder={t('enter_billin_address_1')}
                    />
                  )}
                />

                <Label
                  className="mb-0 mt-4 md:mt-6 md:mb-1.5"
                  value={t('billing_address_2')}
                  required={true}
                />
                <Controller
                  name={'billing_address_2'}
                  control={useFormReturn.control}
                  rules={{
                    required: ct('no_data_hint'),
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      onChange={(data) => {
                        onChange(data);
                      }}
                      value={value}
                      error={error}
                      placeholder={t('enter_billing_address_2')}
                    />
                  )}
                />

                <Label
                  className="mb-0 mt-4 md:mt-6 md:mb-1.5"
                  value={t('city')}
                  required={true}
                />
                <Controller
                  name={'city'}
                  control={useFormReturn.control}
                  rules={{
                    required: ct('no_data_hint'),
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      onChange={(data) => {
                        onChange(data);
                      }}
                      value={value}
                      error={error}
                      placeholder={t('enter_city')}
                    />
                  )}
                />
                <Label
                  className="mb-0 mt-4 md:mt-6 md:mb-1.5"
                  value={t('state')}
                />
                <Controller
                  name={'state'}
                  control={useFormReturn.control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      onChange={(data) => {
                        onChange(data);
                      }}
                      value={value}
                      error={error}
                      placeholder={t('enter_state')}
                    />
                  )}
                />
                <Label
                  className="mb-0 mt-4 md:mt-6 md:mb-1.5"
                  value={t('zip_code')}
                  required={true}
                />
                <Controller
                  name={'zip_code'}
                  control={useFormReturn.control}
                  rules={{
                    required: ct('no_data_hint'),
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      onChange={(data) => {
                        onChange(data);
                      }}
                      value={value}
                      error={error}
                      placeholder={t('enter_zip_code')}
                    />
                  )}
                />
              </>
            )}
          </FormViewContext.Provider>
        </form>
      </div>
      <div className="flex items-center justify-between">
        <Button
          className="flex-1 mr-6"
          onClick={() => {
            setCurrentStep(2);
            setStepCount(1);
          }}
          styleType="Secondary"
        >
          {ct('back')}
        </Button>
        <Button
          className="flex-1"
          onClick={handleSubmit(handleNext)}
          styleType="Primary"
          loading={loading}
        >
          {ct('next')}
        </Button>
      </div>
    </div>
  );
};

export default PayeeInformation;
