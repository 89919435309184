import React from 'react';
import type { IIconProps } from '@/types/common-types';

const SelectedIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 16 } = props;
  return (
    <div
      className={`inline-flex items-center justify-center ${className}`}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 20 20"
        fill="none"
      >
        <rect width="20" height="20" rx="10" fill="#0094FF" />
        <path
          d="M9.12926 13.7982C8.89495 14.0325 8.51505 14.0326 8.28074 13.7982L4.86176 10.3793C4.62745 10.1449 4.62745 9.76505 4.86176 9.53074L5.78074 8.61176C6.01505 8.37745 6.39495 8.37745 6.62926 8.61176L8.28074 10.2632C8.51505 10.4976 8.89495 10.4976 9.12926 10.2632L13.2807 6.11176C13.5151 5.87745 13.8949 5.87745 14.1293 6.11176L15.0482 7.03074C15.2826 7.26505 15.2826 7.64495 15.0482 7.87926L9.12926 13.7982Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default SelectedIcon;
