import type { IFilterOptions } from '@/components/common/filter';
import { useState, useEffect, useRef } from 'react';
import type { IObject } from '@/types/common-types';
import { useNavigate, useParams } from 'react-router-dom';
import {
  triggerWonderRoute,
  insetHead,
  getClassByStatus,
  handlePrice,
} from '../../../utils/terminal-untils';
import dayjs from 'dayjs';
import NoDataIcon from '../terminal-icons/no-data-icon';
import React from 'react';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import Skeleton from '@/components/skeleton';
import type { ITerminalLineItemsPage } from '../../../types/terminal-type';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import NetworkError from '../components/network-error';
import { setCookie } from '@/utils';

interface IOrderTerminalRecordsProps extends ITerminalLineItemsPage {
  token: string;
  businessID: string;
  records: IObject[];
  loading: boolean;
  filterOptions: IFilterOptions[];
  searchValue: string;
  page: number;
  pageSize: number;
  dataTimeOut: boolean;
  loadData: (
    currentPage?: number,
    limit?: number,
    search?: string,
    options?: IFilterOptions[]
  ) => void;
  setPage: (currentPage: number) => void;
}

const OrderTerminalRecords: React.FC<IOrderTerminalRecordsProps> = (props) => {
  const {
    businessID,
    token,
    page,
    loading,
    records,
    inApp,
    dataTimeOut,
    loadData,
    tokenExpire = false,
  } = props;

  const navigate = useNavigate();
  const { lang = 'en' } = useParams();
  const [hasMore, setHasMore] = useState(true);
  const [isPull, setisPull] = useState(false);
  const [showLoading, setshowLoading] = useState(false);
  const [data, setData] = useState<IObject>({});
  const [refreshText, setrefreshText] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(-1);
  const { t } = useTranslation(["extended_service", "transaction_advice"]);

  useEffect(() => {
    dayjs.locale(lang.toLocaleLowerCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  useEffect(() => {
    if (tokenExpire) {
      triggerWonderRoute('true', 'is401');
    }
    window.addEventListener(
      'myCustomEvent',
      (e: any) => {
        if (e.detail.isLoading === 'true') {
          setCurrentPage(-1);
          loadData(1);
        }
        if(e.detail.isBack === 'true'){
          setCookie('isNew', 'true');
        }
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (records.length > 0) {
      const newData = analysisRecord(records);
      setData(newData);
    }
    if (records.length < 30) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    if (records.length === 0 && page === 1) {
      setData({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records]);

  const dateFormat = lang === 'en' ? 'DD MMM' : 'MM月DD日';

  const analysisRecord = (records: IObject[]) => {
    if (page === currentPage) return data;
    let newData: IObject = {};
    if (currentPage !== -1) {
      newData = { ...data };
    }
    records.forEach((item) => {
      const keys = Object.keys(newData);
      let key = dayjs(item.created_at).format(dateFormat);
      const today = new Date();
      const yesterday = new Date().setDate(today.getDate() - 1);
      const nowDate = dayjs(today).format(dateFormat);
      const yesterdayDate = dayjs(yesterday).format(dateFormat);
      const itemDate = dayjs(item.created_at).format(dateFormat);
      if (nowDate === itemDate) {
        key = t('extended_service:today') + ' ' + itemDate;
      } else if (yesterdayDate === itemDate) {
        key = t('extended_service:yesterday') + ' ' + itemDate;
      }
      if (keys.includes(key)) {
        const newArr = [...newData[key]];
        newArr.push(item);
        newData[key] = newArr;
      } else {
        newData[key] = [item];
      }
    });
    setCurrentPage(page);
    return newData;
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (scrollPosition + windowHeight >= documentHeight - 20) {
        if (hasMore && !loading) {
          loadMore();
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, loading, hasMore]);

  useEffect(() => {
    document.documentElement.style.backgroundColor = '#F5F5F5';
    return () => {
      document.documentElement.style.backgroundColor = 'white';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleView = (record: IObject) => {
    const routeAddress = `/businesses/${businessID}/terminal/orders/${record.number}?token=${token}&isApp=${inApp}`;
    if (inApp) {
      triggerWonderRoute(routeAddress, 'route');
    } else {
      navigate(routeAddress);
    }
  };

  const handleClick = debounce(() => {
    const routeAddress = `/businesses/${businessID}/terminal/line-items?token=${token}&isApp=${inApp}`;
    if (inApp) {
      triggerWonderRoute(routeAddress, 'route');
    } else {
      navigate(routeAddress);
    }
  }, 300);

  const isNoData = !records;

  const loadMore = async () => {
    setisPull(true);
    await loadData(page + 1);
  };

  const handlePaymentStatus = (correspondenceState: any) => {
    const { className, showStatus } = getClassByStatus(correspondenceState, t);
    if (!correspondenceState) return '';

    return (
      <div
        className={`h-[26px] rounded-[10px] font-normal text-base !leading-[26px] px-[8px]  ${className} !text-white`}
      >
        {showStatus}
      </div>
    );
  };

  const PullBox = useRef<HTMLDivElement | null>(null);
  let disY = 0;
  let y = 0;
  let startY = 0;
  let endY = 0;
  let scale = 0;
  // let clientHeights = 0;
  // let moveHeight = 0;
  let scrollTop = 0;

  const GetBox = (): HTMLDivElement => {
    return PullBox.current as HTMLDivElement;
  };

  const GetChild = (n: number): HTMLDivElement => {
    return GetBox().children[n] as HTMLDivElement;
  };

  const FnMove = (ev: TouchEvent) => {
    scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop <= 0) {
      startY = y;
      y = ev.changedTouches[0].pageY - disY;
      endY = y;
      // moveHeight = clientHeights / 3;
      scale = Math.pow(y, 0.8);
      setrefreshText(t('extended_service:pull_load'));
      if (scale > 60) {
        setisPull(false);
        setrefreshText(t('extended_service:release_to_refresh'));
      }
      if (startY < endY) {
        ev.preventDefault && ev.preventDefault();
      }
      GetChild(0).style.height = scale + 'px';
    }
  };

  const FnEnd = () => {
    GetChild(0).style.transition = '.4s ease height';
    startY = 0;
    endY = 0;
    if (scale > 60) {
      setisPull(false);
      setCurrentPage(-1);
      loadData(1);
      setshowLoading(true);
      setrefreshText('');
      y = 60;
      GetChild(0).style.height = `${y}px`;
      setTimeout(() => {
        y = 0;
        GetChild(0).style.height = '.4s ease height';
        GetChild(0).style.height = `${y}px`;
      }, 1000);
    } else {
      y = 0;
      GetChild(0).style.height = `${y}px`;
    }
    GetChild(1).ontouchmove = null;
    GetChild(1).ontouchend = null;
  };

  const FnStart = (ev: TouchEvent) => {
    GetChild(0).style.transition = 'none';
    disY = ev.changedTouches[0].pageY - y;
    GetChild(1).ontouchmove = FnMove;
    GetChild(1).ontouchend = FnEnd;
  };

  useEffect(() => {
    GetChild(1).ontouchstart = FnStart;
    // clientHeights =
    //   document.documentElement.clientHeight || document.body.clientHeight;
  });

  const handleTouchStart = (event: any) => {
    const targetElement = event.target;
    let color;
    if (targetElement.className.includes('btn')) {
      color = '#73c9fc';
    } else {
      color = '#F2FAFF';
    }
    touchStyle(event, color);
  };

  const handleTouchEnd = (event: any) => {
    const targetElement = event.target;
    let color;
    if (targetElement.className.includes('btn')) {
      color = '#0094FF';
    } else {
      color = 'white';
    }
    touchStyle(event, color);
  };

  const touchStyle = (event: any, color: string) => {
    event.stopPropagation();
    const targetElement = event.target;
    if (targetElement.className.includes('flag')) {
      targetElement.style.backgroundColor = color;
    } else if (targetElement.className.includes('spanTag')) {
      targetElement.parentNode.parentNode.parentNode.style.backgroundColor = color;
    } else if (targetElement.tagName === 'SPAN') {
      targetElement.parentNode.parentNode.style.backgroundColor = color;
    } else if (targetElement.className.includes('h-[26px]')) {
      targetElement.parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
        color;
    } else if (targetElement.className.includes('btn')) {
      targetElement.style.backgroundColor = color;
    } else if (targetElement.className.includes('child')) {
      targetElement.parentNode.style.backgroundColor = color;
    }
  };

  return (
    <div
      className="pulldown  h-[100vh] relative min-h-screen bg-[#F5F5F5] "
      style={{ WebkitTapHighlightColor: 'transparent' }}
    >
      {insetHead()}
      <div ref={PullBox}>
        <div className="pulltop  overflow-hidden text-center text-xs text-[#666666]">
          {refreshText}
        </div>
        <div className="pulldown-box ">
          <div className=" inset-0 h-full flex flex-col font-sfPro overflow-y-auto min-h-[93vh]">
            <div
              className={`bg-[#F5F5F5] flex-1 relative flex flex-col overflow-x-hidden  h-full hidden_scrollbar`}
            >
              <div className={`px-4 flex-1 ${isNoData ? 'mb-20' : ''}`}>
                <div>
                  {loading && !isPull && (
                    <div>
                      {showLoading && (
                        <div className="flex justify-center px-2 py-2">
                          <Spin
                            indicator={
                              <Loading3QuartersOutlined
                                style={{
                                  fontSize: 30,
                                }}
                                spin
                                rev={undefined}
                              />
                            }
                          />
                        </div>
                      )}
                      {/* <div
                        className={`h-[81vh] flex flex-col bg-primary-background-color mb-4  justify-center items-center rounded-2xl mt-4`}
                      >
                        <div className="mt-[-20px]">
                          <Spin
                            indicator={
                              <Loading3QuartersOutlined
                                style={{
                                  fontSize: 30,
                                }}
                                spin
                                rev={undefined}
                              />
                            }
                          />
                        </div>

                        <div className="mt-4 font-normal text-base">
                          {t('common:loading')}
                        </div>
                      </div> */}
                      <div className={`overflow-hidden inline-block w-60 mt-2`}>
                        <Skeleton
                          row={1}
                          width={['100%']}
                          skeletonItemClass={`h-[10px] !w-[129px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                        />
                      </div>
                      <div className="h-[259px] bg-white px-4 rounded-2xl overflow-hidden">
                        <div className="h-[65px] py-3 mt-4">
                          <Skeleton
                            row={1}
                            width={['100%']}
                            skeletonItemClass={`h-[17px] rounded-2xl bg-[#D3CFCF]`}
                            className={'h-[17px]'}
                          />
                          <Skeleton
                            row={1}
                            width={['100%']}
                            skeletonItemClass={`h-[10px] mt-[-4px] !w-[220px] rounded-2xl bg-[#D3CFCF]`}
                            className={'h-[10px] '}
                          />
                        </div>
                        <div className="h-[65px] py-3 mt-3">
                          <Skeleton
                            row={1}
                            width={['100%']}
                            skeletonItemClass={`h-[17px] rounded-2xl bg-[#D3CFCF]`}
                            className={'h-[17px]'}
                          />
                          <Skeleton
                            row={1}
                            width={['100%']}
                            skeletonItemClass={`h-[10px] mt-[-4px] !w-[220px] rounded-2xl bg-[#D3CFCF]`}
                            className={'h-[10px] '}
                          />
                        </div>
                        <div className="h-[65px] py-3 mt-3">
                          <Skeleton
                            row={1}
                            width={['100%']}
                            skeletonItemClass={`h-[17px] rounded-2xl bg-[#D3CFCF]`}
                            className={'h-[17px]'}
                          />
                          <Skeleton
                            row={1}
                            width={['100%']}
                            skeletonItemClass={`h-[10px] mt-[-4px] !w-[220px] rounded-2xl bg-[#D3CFCF]`}
                            className={'h-[10px] '}
                          />
                        </div>
                      </div>
                      <div
                        className={`overflow-hidden inline-block w-60 mt-[18px]`}
                      >
                        <Skeleton
                          row={1}
                          width={['100%']}
                          skeletonItemClass={`h-[10px] !w-[129px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                        />
                      </div>
                      <div className="h-[178px] bg-white px-4 rounded-2xl overflow-hidden">
                        <div className="h-[65px] py-3 mt-4">
                          <Skeleton
                            row={1}
                            width={['100%']}
                            skeletonItemClass={`h-[17px] rounded-2xl bg-[#D3CFCF]`}
                            className={'h-[17px]'}
                          />
                          <Skeleton
                            row={1}
                            width={['100%']}
                            skeletonItemClass={`h-[10px] mt-[-4px] !w-[220px] rounded-2xl bg-[#D3CFCF]`}
                            className={'h-[10px] '}
                          />
                        </div>
                        <div className="h-[65px] py-3 mt-3">
                          <Skeleton
                            row={1}
                            width={['100%']}
                            skeletonItemClass={`h-[17px] rounded-2xl bg-[#D3CFCF]`}
                            className={'h-[17px]'}
                          />
                          <Skeleton
                            row={1}
                            width={['100%']}
                            skeletonItemClass={`h-[10px] mt-[-4px] !w-[220px] rounded-2xl bg-[#D3CFCF]`}
                            className={'h-[10px] '}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {(!loading || isPull) && (
                    <>
                      {Object.keys(data).length > 0 && (
                        <div className="pb-24 min-h-[93vh]">
                          {Object.keys(data).map((key: string, index) => {
                            const dataArr = data[key];
                            const isToday = key.includes(
                              dayjs().format(dateFormat)
                            );
                            return (
                              <div key={index}>
                                <div className=" font-semibold  text-base mt-4">
                                  {key}
                                </div>
                                <div className="flex flex-col bg-primary-background-color !rounded-2xl mt-1 overflow-hidden ">
                                  {dataArr.map((record: IObject, i: number) => (
                                    <div
                                      className={`p-4  flag`}
                                      key={`${record.id}-${i}`}
                                      onClick={() => handleView(record)}
                                      onTouchStart={handleTouchStart}
                                      onTouchEnd={handleTouchEnd}
                                    >
                                      <div className="text-[#333333] font-semibold  flex justify-between child">
                                        <span
                                          className={`font-semibold text-base`}
                                        >
                                          {record.reference_number}
                                        </span>
                                        <span className="text-base">
                                          {handlePrice(record.initial_total)}
                                        </span>
                                      </div>
                                      <div className="flex flex-row justify-between items-center text-[#666666] text-sm font-normal mt-1 child">
                                        <span className="max-w-[163px] truncate">
                                          {record.oms_delivery_note.address1}
                                        </span>
                                        <span className="text-xs">
                                          {record.currency}
                                        </span>
                                      </div>
                                      <div className="flex  justify-between">
                                        <div className="text-[#666666] text-sm font-normal flex items-start">
                                          {isToday && (
                                            <span className='spanTag'>
                                              {record.created_at
                                                ? dayjs(
                                                  record.created_at
                                                ).tz('Asia/Hong_Kong').format('HH:mm')
                                                : ''}
                                            </span>
                                          )}
                                          {!isToday && (
                                            <span className='spanTag'>
                                              {record.created_at
                                                ? dayjs(
                                                  record.created_at
                                                ).tz('Asia/Hong_Kong').format(`${dateFormat}, HH:mm`)
                                                : ''}
                                            </span>
                                          )}
                                        </div>
                                        <div className="flex-1 flex items-center justify-end flex-wrap gap-[2px] max-w-[49%]">
                                          <span className=" inline-block">
                                            {handlePaymentStatus(
                                              record.correspondence_state
                                            )}
                                          </span>
                                          <span className="inline-block">
                                            {handlePaymentStatus(
                                              record.delivery_order.state
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            );
                          })}
                          <div className="flex items-center justify-center mt-2 text-[#666666] text-sm">
                            {hasMore && (
                              <div> {t('extended_service:has_more')}</div>
                            )}
                            {!hasMore && (
                              <div> {t('extended_service:no_more')}</div>
                            )}
                          </div>
                        </div>
                      )}

                      {Object.keys(data).length === 0 &&
                        records.length === 0 && !dataTimeOut && (
                          <div
                            className={`h-[81vh] flex flex-col bg-primary-background-color mb-4  justify-center items-center rounded-2xl mt-4`}
                          >
                            <NoDataIcon className="mb-4" />
                            <div className="my-4 text-[#000000]">
                              {t('extended_service:no_order')}
                            </div>
                            <div className="text-[#8E8E93] text-sm text-center w-[90%] ">
                              {t('extended_service:no_order_msg')}
                            </div>
                          </div>
                        )}
                      {dataTimeOut && (
                        <NetworkError refresh={() => loadData(1)} noPadding={true} />
                      )}
                    </>
                  )}
                </div>
              </div>
              {!dataTimeOut && (
                <div className="flex  items-center px-4 pb-[24px] pt-[22px]  w-full  fixed bottom-0 bg-[white] ">
                  <div
                    className="w-full h-12 rounded-xl font-sfPro bg-[#0094FF] !shadow-none btn text-center leading-[48px] font-semibold text-base text-[white] duration-300"
                    onClick={handleClick}
                  // onTouchStart={handleTouchStart}
                  // onTouchEnd={handleTouchEnd}
                  >
                    {t('extended_service:new_order')}
                  </div>
                </div>
              )}
            </div>
            {/* {dataTimeOut && (
              <NetworkError refresh={() => loadData(1)} />
            )} */}

          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTerminalRecords;
