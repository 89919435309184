import { useEffect, useState, useCallback } from 'react';
import type {
  IAddressObject,
  ICartParams,
} from '../../../types/terminal-type';
import CheckOrderWebPage from '../web/check-order-web';
import CheckOrderMobilePage from '../mobile/confirm-order';

import {
  OrderCartAPI,
  checkPromotionCode,
  discountCoupon,
  getAddressList,
  getCustomerID,
  queryListing,
  valuationOrder,
} from '../../../data/terminal';
import type { IAreaCodes, IObject } from '@/types/common-types';
import {
  calculationCart,
  getCascaderOptions,
  getDeliveryDate,
  getDeliverySchedule,
  parseCart,
  parseDeliverySchedule,
  triggerWonderRoute,
} from '../../../utils/terminal-untils';
import { loadAreaCodesData } from '@/data/area-codes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { createUuid } from '@/utils/common';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getCookie, setCookie } from '@/utils';
import { Constants } from '@/constants';


dayjs.extend(utc);

let uuids: string[] = [];

const TerminalOrdersCheckPage = () => {
  const { p_business_id: businessID = '', lang = 'en' } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const options = getCascaderOptions(t, lang);

  const defaultCart = {
    discount_total: 0,
    listings: [],
    origin_total: 0,
    total: 0,
    delivery_fee: 0,
  };

  const [currentCart, setCurrentCart] = useState<ICartParams>(defaultCart);
  const [originalCart, setOriginalCart] = useState<ICartParams>(defaultCart);
  const [customerID, setCustomerID] = useState<string>('');
  const [listings, setListings] = useState<any[]>([]);
  const [code, setCode] = useState('');
  const [codePass, setCodePass] = useState<boolean>(false);
  const [nodeValue, setNodeValue] = useState('');
  const [areaCodes, setAreaCodes] = useState<IAreaCodes[]>([]);
  const [addressList, setAddressList] = useState<IAddressObject[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<IAddressObject>();
  const [deliverySchedule, setDeliverySchedule] = useState<string[]>([]);
  const [checkoutLoding, setCheckoutLoding] = useState<boolean>(false);
  const [currency] = useState('HKD');
  // const [loading, setLoading] = useState<boolean>(true);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [cartLoading, setCartLoading] = useState<boolean>(false);
  const [dataTimeOut, setdataTimeOut] = useState<boolean>(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryToken = searchParams.get('token');
  let inApp = false
  const queryInApp = searchParams.get('isApp') || false;
  if (typeof queryInApp === 'string' && queryInApp === 'true') {
    inApp = true;
  }
  let access_token = '';
  if (typeof queryToken === 'string' && queryToken.length > 0) {
    access_token = queryToken;
  }
  const cookieToken = getCookie(Constants.TOKEN) || '';
  const token = access_token || cookieToken;


  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    setLoadingData(true);
    const allPromise: any[] = [];
    let listings: any[] = [];
    let cart: ICartParams = defaultCart;
    let areaCodes: IAreaCodes[] = [];
    let currentCustomerID = customerID;

    if (!currentCustomerID) {
      currentCustomerID = await getCustomerID(businessID, token);
      if (currentCustomerID === 'ERR_NETWORK') {
        setdataTimeOut(true)
        setLoadingData(false);
        return
      }
    }

    allPromise.push(queryListing());
    allPromise.push(
      OrderCartAPI({
        businessID,
        customerID: currentCustomerID,
        token,
      })
    );
    allPromise.push(loadAreaCodesData());

    const allReasult = await Promise.all(allPromise);

    if (allReasult.length > 0) {
      listings = allReasult[0];
    }

    if (allReasult.length > 1) {
      cart = allReasult[1];
    }

    if (allReasult.length > 2) {
      areaCodes = allReasult[2];
    }

    if (cart && cart.listings) {
      const {
        listings: cartListings = [],
        note = '',
        discount_code = '',
        max_delivery_time = '',
        min_delivery_time = '',
      } = cart;
      const newListings: IObject[] = [];
      if (cartListings.length > 0) {
        listings.forEach((listing) => {
          cartListings.forEach((item: any) => {
            if (
              listing.id === item.listing_id &&
              listing.favoriteID === item.favorite_id
            ) {
              let newListing = { ...listing };
              newListing = {
                ...listing,
                cartQuantity: item.quantity,
                cartID: item.id,
              };
              newListings.push(newListing);
            }
          });
        });
      }
      const resetRecord = await loadAddress(
        currentCustomerID,
        false,
        cart,
        discount_code
      );
      const newCart = { ...cart, ...resetRecord };
      newCart.listings = newListings;

      let times = getDeliverySchedule(min_delivery_time, max_delivery_time);
      if (times.length < 1) {
        const defoutValue = options[0];
        times = [
          defoutValue.value,
          defoutValue.children ? defoutValue.children[0]?.value : '',
        ];
      }
      setCurrentCart(newCart);
      setNodeValue(note);
      setDeliverySchedule(times);
      setCode(discount_code);
      setCustomerID(currentCustomerID);
      setListings(listings);
      setAreaCodes(areaCodes);
      setOriginalCart({ ...cart, ...resetRecord });
      setLoadingData(false);
      setdataTimeOut(false)
      if (discount_code) {
        setCodePass(true);
      }
    }
  };

  const loadAddress = async (
    currentCustomerID = customerID,
    isReset = true,
    cart = originalCart,
    discount_code = code
  ) => {
    // setLoading(true);
    let resetRecord: IObject | undefined = undefined;
    const addressList = await getAddressList(
      businessID,
      token,
      currentCustomerID
    );
    const isDefaultRecord = addressList.filter(
      (item: IAddressObject) => item.is_default
    );
    if (isDefaultRecord.length > 0) {
      const selectedAddress = isDefaultRecord[0];
      setSelectedAddress(isDefaultRecord[0]);
      const data = await valuationOrder(businessID, token, {
        discount_code,
        listings: cart.listings,
        delivery_district: selectedAddress.district,
      });
      if (data) {
        const {
          discount_total = 0,
          subtotal = 0,
          initial_total = 0,
          initial_delivery = 0,
        } = data;
        resetRecord = {
          discount_total,
          total: initial_total,
          origin_total: subtotal,
          delivery_fee: initial_delivery,
        };
        isReset && handleResetOrder(resetRecord, true);
        // setValuationRecord(data);
      }
      if (discount_code) {
        const result = await checkPromotionCode(discount_code, businessID, token);
        if (!result) {
          setCodePass(false);
        }
      }
    } else {
      setSelectedAddress(undefined);
    }
    setAddressList(addressList);
    // setLoading(false);
    return resetRecord;
  };

  const queryOrderCart = async (order: IObject, uuid: string) => {
    const res = await OrderCartAPI({
      businessID,
      customerID,
      method: 'POST',
      token: token,
      cartData: {
        discount_code: code,
        listings: order,
      },
    });
    let isLast = false;
    if (uuids[uuids.length - 1] === uuid) {
      uuids = [];
      isLast = true;
    }
    if (Object.keys(res).length > 0) {
      let { newCart } = parseCart(res, listings, false);
      let originalCart = res;
      const data = await valuationOrder(businessID, token, {
        discount_code: code,
        listings: res.listings,
        delivery_district: selectedAddress ? selectedAddress.district : '',
      });
      if (data) {
        const {
          discount_total = 0,
          initial_total = 0,
          initial_delivery = 0,
          subtotal = 0,
        } = data;
        newCart = {
          ...newCart,
          discount_total,
          total: initial_total,
          origin_total: subtotal,
          delivery_fee: initial_delivery,
        };
        originalCart = {
          ...originalCart,
          discount_total,
          total: initial_total,
          origin_total: subtotal,
          delivery_fee: initial_delivery,
        };
        // setValuationRecord(data);
      }
      setOriginalCart(originalCart);
      if (isLast) {
        setCurrentCart(newCart);
      }
    } else {
      if (isLast) {
        const {
          listings: cartListings = [],
          // note = '',
          discount_code = '',
          // max_delivery_time = '',
          // min_delivery_time = '',
        } = originalCart;
        const newListings: IObject[] = [];
        if (cartListings.length > 0) {
          listings.forEach((listing) => {
            cartListings.forEach((item: any) => {
              if (
                listing.id === item.listing_id &&
                listing.favoriteID === item.favorite_id &&
                item.quantity
              ) {
                let newListing = { ...listing };
                newListing = {
                  ...listing,
                  cartQuantity: item.quantity,
                  cartID: item.id,
                };
                newListings.push(newListing);
              }
            });
          });
        }
        const resetRecord = await loadAddress(
          customerID,
          false,
          originalCart,
          discount_code
        );
        const newCart = { ...originalCart, ...resetRecord };
        newCart.listings = newListings;
        setCurrentCart(newCart);
      }
    }
  };

  const handleMinus = async (record: IObject) => {
    const { cartQuantity = 0 } = record;
    if (cartQuantity === 0) return;
    const cartListing = calculationCart(record, currentCart, 'minus');
    cartAddOrDel(cartListing);
  };

  const handlePlus = async (record: IObject) => {
    const { cartQuantity = 0 } = record;
    if (cartQuantity >= 5) return;
    const cartListing = calculationCart(record, currentCart);
    cartAddOrDel(cartListing);
  };

  const cartAddOrDel = (cartListing: any) => {
    setCartLoading(true);
    const currentList: IObject[] = [];
    if (originalCart.listings) {
      currentCart.listings.forEach((item) => {
        if (item.id === cartListing.listing_id) {
          currentList.push(cartListing);
        } else {
          currentList.push({
            favorite_id: item.favoriteID,
            id: item.cartID,
            listing_id: item.id,
            quantity: item.cartQuantity || 0,
          });
        }
      });
      const { newCart } = parseCart(
        {
          ...originalCart,
          listings: currentList,
        },
        listings,
        false
      );
      const uuid = createUuid();
      uuids.push(uuid);
      setCurrentCart(newCart);
      debouncedUpdateCart(cartListing, uuid);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateCart = useCallback(
    debounce(async (cartListing: any, uuid: any) => {
      await queryOrderCart(cartListing, uuid);
      setCartLoading(false);
    }, 300),
    [customerID, originalCart, listings, uuids, code]
  );

  const handleCheckout = async () => {
    if (checkoutLoding) return;
    setCheckoutLoding(true);
    const { startTime, endTime } = parseDeliverySchedule(deliverySchedule);
    await handleCartChange({
      note: nodeValue,
      discount_code: codePass ? code : '',
      min_delivery_time: startTime,
      max_delivery_time: endTime,
    });
    if (currentCart.total === 0 && selectedAddress) {
      setCookie('__coupon', 'true');
      const listings: IObject[] = [];
      for (let i = 0; i < currentCart.listings.length; i++) {
        const item = currentCart.listings[i];
        listings.push({
          favorite_id: item.favoriteID,
          id: item.cartID,
          listing_id: item.id,
          quantity: item.cartQuantity,
        });
      }
      const valuation = await valuationOrder(businessID, token, {
        discount_code: codePass ? code : '',
        listings,
        delivery_district: selectedAddress.district,
      });
      const invoice = {
        due_date: getDeliveryDate(7),
        note: nodeValue,
        from: 5,
        discount_code: codePass ? code : '',
        sale_type: 'invoice_sale',
        currency: 'HKD',
        ignore_token: false,
        customer_reference: customerID,
        delivery_district: selectedAddress.district,
        oms_delivery_note: {
          ...selectedAddress,
          address1: selectedAddress.address,
          min_delivery_time: startTime,
          max_delivery_time: endTime,
          auto_booking: true,
        },
        listings,
        valuation_token: valuation?.token,
      };
      const { number, errorCode, errorMessage } = await discountCoupon(token, businessID, lang, invoice);
      if (number) {
        if (inApp) {
          const routeAddress = `/businesses/${businessID}/terminal/orders/payment/result?number=${number}&token=${token}&isApp=${inApp}`;
          triggerWonderRoute(routeAddress);
        } else {
          const routeAddress = `/${lang}/businesses/${businessID}/terminal/orders/payment/result?number=${number}&token=${token}&isApp=${inApp}`;
          navigate(routeAddress);
        }
      } else {
        if (inApp) {
          const routeAddress = `/businesses/${businessID}/terminal/orders/payment/result?token=${token}&isApp=${inApp}&e=${errorCode}&m=${errorMessage}`;
          triggerWonderRoute(routeAddress);
        } else {
          const routeAddress = `/${lang}/businesses/${businessID}/terminal/orders/payment/result?token=${token}&isApp=${inApp}&e=${errorCode}&m=${errorMessage}`;
          navigate(routeAddress);
        }
      }
      setCheckoutLoding(false);
      return;
    }
    setCookie('__coupon', 'false');
    setCheckoutLoding(false);
    if (inApp) {
      const routeAddress = `/businesses/${businessID}/terminal/orders/payment?token=${token}&isApp=${inApp}`;
      triggerWonderRoute(routeAddress);
    } else {
      const routeAddress = `/${lang}/businesses/${businessID}/terminal/orders/payment?token=${token}&isApp=${inApp}`;
      navigate(routeAddress);
    }
  };

  const handleBack = () => {
    navigate(`/${lang}/businesses/${businessID}/terminal/line-items`);
  };

  const handleNodeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNodeValue(e.target.value || '');
  };

  const MobilehandleNodeChange = (e: any) => {
    setCookie('noteValue', e);
    setNodeValue(e || '');
  };

  const handleCodeChange = async (value: string) => {
    setCode(value);

    if (!value) {
      setCodePass(false);
      handleResetOrder(
        {
          discount_total: 0,
          total: originalCart.total,
        },
        true
      );
      return;
    }
    debouncedCodeChange(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCodeChange = useCallback(
    debounce(async (value: any) => {
      const result = await checkPromotionCode(value, businessID, token);
      if (result) {
        setCodePass(true);
        handelCodeValue(value);
      } else {
        setCodePass(false);
        handleResetOrder({
          discount_total: 0,
          total: originalCart.total,
        });
        if (currentCart.discount_total > 0) {
          handleCartChange({
            discount_code: '',
          });
          handelCodeValue('');
        }
      }
    }, 500),
    [originalCart, selectedAddress]
  );

  const handelCodeValue = async (discount_code: any) => {
    const data = await valuationOrder(businessID, token, {
      discount_code: discount_code,
      listings: originalCart.listings,
      delivery_district: selectedAddress ? selectedAddress.district : '',
    });
    if (data) {
      const {
        discount_total = 0,
        subtotal = 0,
        initial_total = 0,
        initial_delivery = 0,
      } = data;
      handleResetOrder(
        {
          discount_total,
          total: initial_total,
          origin_total: subtotal,
          delivery_fee: initial_delivery,
        },
        true
      );
      // setValuationRecord(data);
    }
  };

  const handleResetOrder = (record: IObject, isReset = false) => {
    const newCart = {
      ...currentCart,
      ...record,
    };
    if (isReset) {
      const newOriginalCart = {
        ...originalCart,
        ...record,
        listings: originalCart.listings,
      };
      setOriginalCart(newOriginalCart);
    }
    setCurrentCart(newCart);
  };

  const handleCartChange = async (value: any) => {
    await OrderCartAPI({
      businessID,
      customerID,
      method: 'POST',
      token: token,
      cartData: {
        ...value,
      },
    });
  };

  return (
    <>
      {!inApp && (
        <CheckOrderWebPage
          cart={currentCart}
          code={code}
          loadingData={loadingData}
          codePass={codePass}
          currency={currency}
          areaCodes={areaCodes}
          customerID={customerID}
          loadAddress={loadAddress}
          addressList={addressList}
          selectedAddress={selectedAddress}
          deliverySchedule={deliverySchedule}
          options={options}
          checkoutLoding={checkoutLoding}
          nodeValue={nodeValue}
          setDeliverySchedule={setDeliverySchedule}
          handleMinus={handleMinus}
          handlePlus={handlePlus}
          handleBack={handleBack}
          handleCheckout={handleCheckout}
          handleNodeChange={handleNodeChange}
          handleCodeChange={handleCodeChange}
          inApp={inApp}
          businessID={businessID}
          token={token}
          cartLoading={cartLoading}
        />
      )}

      {inApp && (
        <CheckOrderMobilePage
          cart={currentCart}
          code={code}
          currency={currency}
          loading={loadingData}
          areaCodes={areaCodes}
          customerID={customerID}
          codePass={codePass}
          nodeValue={nodeValue}
          loadAddress={loadData}
          addressList={addressList}
          deliverySchedule={deliverySchedule}
          selectedAddress={selectedAddress}
          handleMinus={handleMinus}
          handlePlus={handlePlus}
          handleBack={handleBack}
          handleCheckout={handleCheckout}
          handleNodeChange={MobilehandleNodeChange}
          handleCodeChange={handleCodeChange}
          setDeliverySchedule={setDeliverySchedule}
          inApp={inApp}
          businessID={businessID}
          token={token}
          cartLoading={cartLoading}
          dataTimeOut={dataTimeOut}
        />
      )}

      {/* <div>
        <CheckOrderMobilePage
          cart={currentCart}
          code={code}
          currency={currency}
          loading={loadingData}
          areaCodes={areaCodes}
          customerID={customerID}
          codePass={codePass}
          nodeValue={nodeValue}
          loadAddress={loadData}
          addressList={addressList}
          deliverySchedule={deliverySchedule}
          selectedAddress={selectedAddress}
          handleMinus={handleMinus}
          handlePlus={handlePlus}
          handleBack={handleBack}
          handleCheckout={handleCheckout}
          handleNodeChange={MobilehandleNodeChange}
          handleCodeChange={handleCodeChange}
          setDeliverySchedule={setDeliverySchedule}
          inApp={inApp}
          businessID={businessID}
          token={token}
          cartLoading={cartLoading}
          dataTimeOut={dataTimeOut}
        />
      </div> */}
    </>
  );
};

export default TerminalOrdersCheckPage;
