import type { IObject } from '@/types/common-types';
import { useState, useEffect } from 'react';
import type { ITerminalLineItemsPage } from '../../../types/terminal-type';
import NewAddIcon from '../terminal-icons/newadd-icon';
import ReduceIcon from '../terminal-icons/reduce-icon';
import CartIcon from '../terminal-icons/shop-cart-icon';
import ClockIcon from '../terminal-icons/clock-icon';
import DefaultItemIcon from '../terminal-icons/default-item-icon';
import Skeleton from '@/components/skeleton';
import type { ICartParams } from '../../../types/terminal-type';
import type { ICascaderOptions } from '../../../utils/terminal-untils';
import { handlePrice, triggerWonderRoute, insetHead, isDeliveryScheduleLater } from '../../../utils/terminal-untils';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NetworkError from '../components/network-error';
// import { getCookie } from '@/utils';

interface IChooseServiceProps extends ITerminalLineItemsPage {
  cart: ICartParams;
  loading: boolean;
  dataTimeOut: boolean;
  options: ICascaderOptions[];
  deliverySchedule: string[];
  currency: string;
  defoutValue: any;
  setDeliverySchedule: (value: string[]) => void;
  handleMinus: (record: IObject) => void;
  handlePlus: (record: IObject) => void;
  loadData: () => void;
}

const ChooseService: React.FC<IChooseServiceProps> = (props) => {
  const {
    cart,
    businessID,
    currency,
    // deliverySchedule,
    defoutValue,
    setDeliverySchedule,
    handleMinus,
    handlePlus,
    loadData,
    token,
    inApp,
    loading,
    dataTimeOut,
    tokenExpire = false,
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const [errTime, seterrTime] = useState(false);
  const { lang = 'en' } = useParams();
  const dateFormat = lang === 'en' ? 'MMM D' : 'MM月DD日';
  // const [pickTime, setpickTime] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryTimeError = searchParams.get('timeError') || 'false';
  const [timeError, settimeError] = useState(false);
  const [appDeliverySchedule, setAppDeliverySchedule] = useState<string[]>([]);


  useEffect(() => {
    triggerWonderRoute('ready', 'ready');
  }, []);


  useEffect(() => {
    window.addEventListener(
      'myCustomEvent',
      (e: any) => {
        if (e.detail.timeError === 'true') {
          settimeError(true)
        }
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tokenExpire) {
      triggerWonderRoute('true', 'is401');
    }
    if (Object.keys(cart).length > 0) {
      window.addEventListener(
        'myCustomEvent',
        (e: any) => {
          if (e.detail.isLoading === 'true') {
            loadData();
          }
        },
        false
      );
      window.addEventListener(
        'myCustomEvent',
        (e: any) => {
          if (e.detail.delivery_schedule) {
            const arr = [];
            arr.push(formatDate(e.detail.delivery_schedule.split(',')[0]));
            arr.push(e.detail.delivery_schedule.split(',')[1]);
            setDeliverySchedule(arr);
            setAppDeliverySchedule(arr)
          }

          if (e.detail.no_time) {
            seterrTime(true);
          }
        },
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);


  useEffect(() => {
    let defoutTime = [
      defoutValue.value,
      defoutValue.children && defoutValue.children?.length > 0
        ? defoutValue.children[0].value
        : '',
    ]
    if (defoutTime[1] && appDeliverySchedule) {
      let checkTime = isDeliveryScheduleLater(appDeliverySchedule, defoutTime)
      // setpickTime(!checkTime)
      if (checkTime) {
        triggerWonderRoute(appDeliverySchedule.toString(), 'default_value');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDeliverySchedule]);

  let count = 0;
  let total = 0;

  cart.listings.forEach((item) => {
    count += item.cartQuantity || 0;
    total += item.price * item.cartQuantity || 0;
  });

  const formatDate = (dateString: any) => {
    const [year, month, day] = dateString.split('-');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  const handleClick = debounce(() => {
    setDeliverySchedule(appDeliverySchedule)
    // let defoutTime = [
    //   defoutValue.value,
    //   defoutValue.children && defoutValue.children?.length > 0
    //     ? defoutValue.children[0].value
    //     : '',
    // ]
    // let checkTime = isDeliveryScheduleLater(appDeliverySchedule, defoutTime)
    // setpickTime(!checkTime)
    // if (!checkTime) {
    //   return
    // }

    const routeAddress = `/businesses/${businessID}/terminal/orders/check?token=${token}&time=${appDeliverySchedule}&isApp=${inApp}`;
    if (inApp) {
      triggerWonderRoute(routeAddress, 'route');
    } else {
      navigate(routeAddress);
    }
  }, 500);

  const handleTimeClick = debounce(() => {
    let defoutTime = [
      defoutValue.value,
      defoutValue.children && defoutValue.children?.length > 0
        ? defoutValue.children[0].value
        : '',
    ]
    let checkTime = isDeliveryScheduleLater(appDeliverySchedule, defoutTime)
    if (checkTime) {
      triggerWonderRoute(appDeliverySchedule.toString(), 'default_value');
    } else {
      triggerWonderRoute(defoutTime.toString(), 'default_value');
    }
    alert('delivery_scheule', 'bootom_sheet');
  }, 200);

  const alert = (vlaue: any, action: any) => {
    if (vlaue === 'delivery_scheule' && !appDeliverySchedule && !errTime) {
      return;
    }
    triggerWonderRoute(vlaue, action);
  };

  const isToday = (time: any) => {
    if (!time) return
    const today = new Date();
    const yesterday = new Date().setDate(today.getDate() - 1);
    const nowDate = dayjs(today).format(dateFormat);
    const yesterdayDate = dayjs(yesterday).format(dateFormat);
    const itemDate = dayjs(time).format(dateFormat);
    if (nowDate === itemDate) {
      return t('extended_service:today') + ' ' + itemDate;
    } else if (yesterdayDate === itemDate) {
      return t('extended_service:yesterday') + ' ' + itemDate;
    } else {
      return itemDate;
    }
  };

  const handleMinusCheck = (item: any) => {
    handleMinus(item);
  };

  const handleAddCheck = (item: any) => {
    handlePlus(item);
  };

  const handleTouchStart = (event: any) => {
    const targetElement = event.target;
    targetElement.parentNode.style.opacity = '50%';
  };

  const handleTouchEnd = (event: any) => {
    const targetElement = event.target;
    targetElement.parentNode.style.opacity = '100%';
  };

  return (
    <div
      className="min-h-screen bg-[#F5F5F5] overflow-auto"
      style={{ WebkitTapHighlightColor: 'transparent' }}
    >
      {insetHead()}
      <div
        className={`absolute inset-0 h-full flex flex-col font-sfPro overflow-x-hidden overflow-y-auto lg:hidden hidden_scrollbar ${!dataTimeOut ? 'pb-[150px]' : ''}`}
      >
        {/* {!dataTimeOut && ( */}
        <div className={`bg-[#F5F5F5] flex-1 hidden_scrollbar`}>
          <div className="p-5 pb-24">
            {!dataTimeOut && (
              <>
                {(queryTimeError === 'true' || timeError) && (
                  <div className='bg-primary-background-color rounded-2xl border-[#FC2E01] border-[1px] border-solid h-[48px] leading-[48px] text-[#FC2E01] font-normal relative '>
                    <div
                      className="absolute left-[16px] top-[16px] w-[14px] h-[14px] rounded-full bg-[#FC2E01] text-white text-center leading-[14px] text-xs"
                    >
                      i
                    </div>
                    <div className='indent-9'>
                      {t('extended_service:time_error')}
                    </div>
                  </div>
                )}
                <div
                  className="bg-primary-background-color rounded-xl p-4 mt-4"
                  onClick={handleTimeClick}
                >
                  <div className="flex justify-between items-center text-base font-normal">
                    <div className="text-[#8E8E93]">
                      {t('extended_service:delivery_schedule')}{' '}
                      <span className="text-[red]">*</span>
                    </div>
                    <div
                      className={` max-w-[160px] truncate flex items-center text-right`}
                    >
                      {/* <div className="">
                        {(deliverySchedule || errTime) && !loading && (
                          <div className="flex items-center">
                            <div>
                              <div>{isToday(deliverySchedule[0])}</div>
                              <div>{deliverySchedule[1]}</div>
                            </div>
                            <div>
                              <ClockIcon />
                            </div>
                          </div>
                        )}
                        {(!deliverySchedule && !errTime) ||
                          (loading && (
                            <Skeleton
                              row={1}
                              width={['100%']}
                              skeletonItemClass={`h-[10px] !w-[129px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                            />
                          ))}
                      </div> */}
                      <div className="">
                        {(appDeliverySchedule || errTime) && !loading && (
                          <div className="flex items-center">
                            <div>
                              <div>{isToday(appDeliverySchedule[0])}</div>
                              <div>{appDeliverySchedule[1]}</div>
                            </div>
                            <div>
                              <ClockIcon />
                            </div>
                          </div>
                        )}
                        {(!appDeliverySchedule && !errTime) ||
                          (loading && (
                            <Skeleton
                              row={1}
                              width={['100%']}
                              skeletonItemClass={`h-[10px] !w-[129px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}


            {!loading && (
              <>
                {/* {getCookie('isNew')} */}
                {cart.listings.map((item: any) => (
                  <div
                    className="px-4 py-4 bg-primary-background-color rounded-2xl mt-5"
                    key={item.favoriteID}
                  >
                    <div className="flex mt-[14px] pb-[14px]">
                      <div className="p-2 bg-disabled-color rounded-2xl">
                        {item.imageUrl && (
                          <div
                            className="w-[72px] h-[72px] flex justify-center items-center bg-center bg-no-repeat bg-contain"
                            style={{ backgroundImage: `url(${item.imageUrl})` }}
                          />
                        )}
                        {!item.imageUrl && (
                          <div
                            style={{
                              backgroundColor: 'rgba(226, 228, 231, 0.15)',
                            }}
                            className="w-[72px] h-[72px] flex justify-center items-center rounded"
                          >
                            <DefaultItemIcon />
                          </div>
                        )}
                      </div>
                      <div className="ml-2 flex-1 relative">
                        <div className="w-[85%] text-base font-normal">
                          {item.name}
                        </div>
                        <div className=" absolute bottom-0 left-0 font-semibold text-sm">
                          <div>{handlePrice(item.price)} HKD</div>
                        </div>
                        {item.name && item.name.includes('Smart POS PAX') && (
                          <div
                            className="absolute right-0 top-0 w-[14px] h-[14px] rounded-full bg-[#0094FF] text-white text-center leading-[14px] text-xs"
                            onClick={() => alert('terminal_fee', 'alert')}
                          >
                            i
                          </div>
                        )}
                        <div
                          className={`absolute right-0 bottom-0 flex w-24  ${!item.cartQuantity
                            ? 'justify-end'
                            : 'justify-between'
                            }`}
                        >
                          <div
                            onTouchStart={handleTouchStart}
                            onTouchEnd={handleTouchEnd}
                            className="duration-300"
                            style={{
                              WebkitAppearance: 'none',
                              WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                            }}
                          >
                            <ReduceIcon
                              onClick={() => handleMinusCheck(item)}
                              className={`${item.cartQuantity > 0 ? '' : 'hidden'
                                } `}
                            />
                          </div>
                          <div
                            className={`${item.cartQuantity > 0 ? '' : 'hidden'
                              } text-base leading-7`}
                          >
                            {item.cartQuantity}
                          </div>
                          <div
                            onTouchStart={handleTouchStart}
                            onTouchEnd={handleTouchEnd}
                            className="duration-300"
                            style={{
                              WebkitAppearance: 'none',
                              WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                            }}
                          >
                            <NewAddIcon
                              onClick={() => {
                                if (item.cartQuantity > 4) {
                                  return;
                                }
                                handleAddCheck(item);
                                // debounce(() => {
                                //   handlePlus(item);
                                // }, 500);
                              }}
                            />
                          </div>
                          {item.cartQuantity > 4 && (
                            <div className="absolute bottom-[-28px] right-0 w-[200px] text-right text-[#FC2E01] flex items-center justify-end">
                              <div className=" w-[14px] h-[14px] rounded-full bg-[#FC2E01] text-white text-center leading-[14px] text-xs mr-1">
                                i
                              </div>
                              <div>{t('extended_service:maximum')}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}

            {loading && (
              <>
                <div className="h-[112px] bg-white px-4 mt-4 rounded-xl pt-2">
                  <Skeleton
                    avatar={true}
                    row={2}
                    className="items-center !h-[96px]"
                    width={['100%', '60%']}
                    avatarClass="h-[80px] w-[80px] mr-3.5 bg-[#D3CFCF] !rounded-2xl"
                    skeletonItemClass="h-[17px]  mb-1.5 last:mb-0 rounded-[50px] w-[200px] mt-[12px] last:mt-8 bg-[#D3CFCF]"
                  />
                </div>
                <div className="h-[112px] bg-white px-4 mt-4 rounded-xl pt-2">
                  <Skeleton
                    avatar={true}
                    row={2}
                    className="items-center !h-[96px]"
                    width={['100%', '60%']}
                    avatarClass="h-[80px] w-[80px] mr-3.5 bg-[#D3CFCF] !rounded-2xl"
                    skeletonItemClass="h-[17px]  mb-1.5 last:mb-0 rounded-[50px] w-[200px] mt-[12px] last:mt-8 bg-[#D3CFCF]"
                  />
                </div>
                <div className="h-[112px] bg-white px-4 mt-4 rounded-xl pt-2">
                  <Skeleton
                    avatar={true}
                    row={2}
                    className="items-center !h-[96px]"
                    width={['100%', '60%']}
                    avatarClass="h-[80px] w-[80px] mr-3.5 bg-[#D3CFCF] !rounded-2xl"
                    skeletonItemClass="h-[17px]  mb-1.5 last:mb-0 rounded-[50px] w-[200px] mt-[12px] last:mt-8 bg-[#D3CFCF]"
                  />
                </div>
              </>
            )}

            {dataTimeOut && !loading && (
              <NetworkError refresh={() => loadData()} noPadding={true} />
            )}
          </div>
          {!dataTimeOut && (
            <div className="fixed bottom-0 w-[100%] bg-primary-background-color ">
              <div className="flex  items-center px-4  w-full  pt-4  pb-[24px]">
                {total >= 1 && appDeliverySchedule[1] && (
                  <div
                    className=" bg-[#0094FF] w-full h-12 rounded-xl font-sfPro text-white hover:text-color-text-6 !text-base !font-semibold flex justify-center items-center flag duration-300"
                    onClick={handleClick}
                  // onTouchStart={newHandleTouchStart}
                  // onTouchEnd={newHandleTouchEnd}
                  >
                    <div className="relative">
                      <CartIcon className="mr-2 " />
                      <div className=" text-xs absolute right-0 top-[-8px] w-[16px] h-[16px] text-center bg-white rounded-full text-[#0094FF] leading-4">
                        {count}
                      </div>
                    </div>
                    <span>
                      {t('extended_service:pay')} {handlePrice(cart.total)}{' '}
                      {currency}
                    </span>
                  </div>
                )}

                {(total < 1 || !appDeliverySchedule[1]) && (
                  <div
                    className=" bg-[#0094FF] w-full h-12 rounded-xl font-sfPro text-white hover:text-color-text-6 !text-base !font-semibold flex justify-center items-center opacity-50"
                  // onClick={goConfirmation}
                  >
                    <div className="relative">
                      <CartIcon className="mr-2 " />
                      <div className="text-xs absolute right-0 top-[-8px] w-[16px] h-[16px] text-center bg-white rounded-full text-[#0094FF] leading-4">
                        {count}
                      </div>
                    </div>
                    <span>
                      {t('extended_service:pay')}{' '}
                      {cart.total ? `${handlePrice(cart.total)} ${currency}` : ''}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}

        </div>
        {/* )} */}
      </div>

    </div>
  );
};

export default ChooseService;
