import React, { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import SuccessfulIcon from '@/components/svg/successful-icon';
import WarningIcon from '@/components/svg/warning-icon';
import ErrorIcon from '@/components/svg/error-icon';
import CloseIcon from '@/components/svg/close-icon';

interface IAlertTypePropsItem {
  icon: React.ReactNode;
  wrapClass: string;
}

interface IAlertTypeProps {
  [key: string]: IAlertTypePropsItem;
}

export interface IAlertWidgetProps {
  type?: 'success' | 'warn' | 'error';
  className?: string;
  wrapClass?: string;
  message?: string;
  time?: number;
  position?: 'left' | 'default' | 'right';
  onClose?: () => void;
}

let alertTimout: any;

const AlertWidget: React.FC<IAlertWidgetProps> = (props) => {
  const alertType: IAlertTypeProps = {
    success: {
      icon: (
        <SuccessfulIcon className="text-primary-background-color" size={16} />
      ),
      wrapClass: 'bg-success-color',
    },
    warn: {
      icon: <WarningIcon className="text-primary-background-color" size={16} />,
      wrapClass: 'bg-warning-color',
    },
    error: {
      icon: <ErrorIcon className="text-primary-background-color" size={16} />,
      wrapClass: 'bg-error-color',
    },
  };
  const {
    type = 'warn',
    className = '',
    message = 'A successful toast.',
    time = 3,
    position = 'default',
    onClose,
    wrapClass = '',
  } = props;

  const [showAlert, setAlertStatus] = useState(true);

  useEffect(() => {
    if (showAlert) {
      clearTimeout(alertTimout);
      alertTimout = setTimeout(() => {
        if (onClose) {
          onClose();
        }
        setAlertStatus(false);
      }, time * 1000);
    }
  }, [showAlert, time, onClose]);

  const handleClose = () => {
    clearTimeout(alertTimout);
    setAlertStatus(false);
    if (onClose) {
      onClose();
    }
  };

  const alertTypePropsItem = alertType[type];
  const positionClass =
    position === 'left'
      ? 'md:w-1/2'
      : position === 'right'
      ? 'right-0 md:w-1/2'
      : '';

  return (
    <Transition
      appear={true}
      className={`flex justify-center fixed ${positionClass} w-full ${wrapClass} z-[9999]`}
      show={showAlert}
      enter="duration-300 transform transition"
      enterFrom={`-translate-y-`}
      enterTo="translate-y-20"
      leave="duration-300 transform transition"
      leaveFrom="translate-y-20"
      leaveTo="-translate-y-20"
    >
      <div
        className={`rounded-sm px-4 py-2 shadow-dropdown text-primary-background-color min-w-15 max-w-28 break-all ${alertTypePropsItem.wrapClass} ${className} `}
      >
        <div className="flex">
          <div className="flex-shrink-0 py-0.5">{alertTypePropsItem.icon}</div>
          <div className="ml-3">
            <div className={`text-sm break-normal`}>{message}</div>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <div
                className="inline-flex rounded-md p-1.5 py-2 cursor-pointer"
                onClick={handleClose}
              >
                <span className="sr-only">Dismiss</span>
                <CloseIcon size={16} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default AlertWidget;
