import dayjs from 'dayjs';
import { Transition } from '@headlessui/react';
import { useEffect, useRef, useState } from 'react';
import TimeIcon from '@/components/svg/time-icon';
import { useTranslation } from 'react-i18next';

export interface ITimePickerProps {
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  value?: string | number | Date;
  displaySecond?: boolean;
  onChange?: (value: string) => void;
  isSpecial?: boolean;
}

const TimePicker: React.FC<ITimePickerProps> = (props) => {
  const {
    onChange,
    className,
    defaultValue,
    disabled = false,
    displaySecond = false,
    value,
    isSpecial = false,
  } = props;
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const timeValue = value
    ? `${dayjs(new Date()).format('YYYY-MM-DD')} ${value}`
    : defaultValue;
  const [hour, setHour] = useState<string>(dayjs(timeValue).format('HH'));
  const [minute, setMinute] = useState<string>(dayjs(timeValue).format('mm'));
  const [second, setSecond] = useState<string>(dayjs(timeValue).format('ss'));
  const dropdown = useRef<any>(null);
  const trigger = useRef<any>(null);
  const { t } = useTranslation();

  const hourList = Array.from({ length: 24 }, (_, index) => index);
  const minuteList = isSpecial
    ? [0]
    : Array.from({ length: 60 }, (_, index) => index);
  const secondList = Array.from({ length: 60 }, (_, index) => index);
  const selectMinute = (value: string) => {
    setMinute(value);
    if (!displaySecond) {
      setDropdownOpen(false);
    }
    if (onChange) {
      onChange(
        displaySecond ? `${hour}:${value}:${second}` : `${hour}:${value}`
      );
    }
  };
  const selectSecond = (value: string) => {
    setDropdownOpen(false);
    setSecond(value);
    if (onChange)
      onChange(
        displaySecond ? `${hour}:${minute}:${value}` : `${hour}:${value}`
      );
  };
  const selectHour = (value: string) => {
    setHour(value);
    if (onChange)
      onChange(
        displaySecond ? `${value}:${minute}:${second}` : `${value}:${second}`
      );
  };

  useEffect(() => {
    const clickHandler = (e: any) => {
      if (!dropdown.current) return;
      if (
        dropdown.current.contains(e.target) ||
        trigger.current.contains(e.target)
      )
        return;
      setDropdownOpen(false);
    };

    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setHour(
      dayjs(`${dayjs(new Date()).format('YYYY-MM-DD')} ${value}`).format('HH')
    );
  }, [value]);

  const renderItem = (
    timeList: any[],
    time: string,
    onSelectClick: (value: string) => void
  ) => {
    return (
      <div className="font-medium text-sm text-color-text-1 overflow-auto rounded-l-sm border-r border-border-color">
        {timeList.map((item: any) => (
          <div
            key={item}
            className={`w-14 h-8 hover:bg-disabled-color hover:text-color-text-1 flex justify-center items-center cursor-pointer ${time === item ? 'text-primary-color' : 'text-color-text-1'
              }`}
            onClick={() => onSelectClick(item < 10 ? `0${item}` : `${item}`)}
          >
            {item < 10 ? `0${item}` : `${item}`}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={`h-10 relative flex ${className}`}>
      <div
        ref={trigger}
        onClick={() => (!disabled ? setDropdownOpen(true) : undefined)}
        className={`h-full font-medium text-color-text-1 text-sm inline-flex items-center justify-between border border-border-color rounded leading-5 shadow-box-drop min-w-full px-3 py-2 ${!disabled ? 'cursor-pointer' : ''
          }
        ${disabled
            ? 'shadow-none bg-disabled-color'
            : 'bg-transparent hover:border-primary-color'
          }`}
      >
        {displaySecond && (
          <span>
            {hour}:{minute}:{second} {t('settings:dayend_everyday')}
          </span>
        )}
        {!displaySecond && (
          <span>
            {hour}:{minute} {t('settings:dayend_everyday')}
          </span>
        )}
        {!disabled && (
          <TimeIcon
            className="text-icon-color absolute inset-0 left-auto right-3 flex items-center pointer-events-none"
            size={20}
          />
        )}
      </div>
      <Transition
        show={dropdownOpen}
        className={`z-100 max-h-56 flex absolute ${'top-full'} left-0 bg-primary-background-color border border-border-color rounded shadow-dropdown mt-1`}
        enterFrom="opacity-0 -translate-y-2"
        enterTo="opacity-100 translate-y-0"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        ref={dropdown}
      >
        {renderItem(hourList, hour, selectHour)}
        {renderItem(minuteList, minute, selectMinute)}
        {displaySecond && renderItem(secondList, second, selectSecond)}
      </Transition>
    </div>
  );
};

export default TimePicker;
