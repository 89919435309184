import React from 'react';

interface ILabelProps {
  value: string;
  className?: string;
  textClass?: string;
  required?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

const Label: React.FC<ILabelProps> = (props) => {
  const {
    className = '',
    value,
    required = false,
    children,
    textClass = '',
    disabled = false,
  } = props;

  return (
    <label
      className={`text-sm flex items-center justify-between mb-1 font-medium text-color-text-2 ${className}`}
    >
      <span className={textClass}>
        {value}
        {required && !disabled && <span className="text-error-color">*</span>}
      </span>
      {children}
    </label>
  );
};

export default Label;
