import type { IObject } from '@/types/common-types';
import { findNode } from '@/utils';
import { MenuItem, SubMenu } from 'rc-menu';
import type { MenuTitleInfo } from 'rc-menu/lib/interface';
import type { IFilterField, IFilterOptions } from '.';
import { operationTransform } from '.';
import { pathDivisionMark } from '.';
import { FilterOperation, IFilterType } from '.';
import type { IFilterContentProps } from './filter-content';
import DownIcon from '@/components/svg/down-icon';

export const getFilter = (
  options: IFilterOptions[],
  fields: IFilterField[],
  defaultOption: IObject[] = []
) => {
  const filterItmes: IObject[] = defaultOption;

  options.forEach((option) => {
    const { name, value, operation } = option;
    const pathArr = name.split(pathDivisionMark).reverse();
    let filterValue =
      operation === FilterOperation.IS_BETWEEN ? value.split('&') : value;

    if (
      [FilterOperation.IS_NULL, FilterOperation.IS_NOT_NULL].includes(operation)
    ) {
      filterValue = true;
    }
    let obj: IObject = {
      [pathArr[0]]: {
        [operation]: filterValue,
      },
    };

    if (pathArr.length > 1) {
      pathArr.shift();
      pathArr.forEach((path) => {
        const item = findNode<IFilterField>(fields, 'name', path);
        if (item) {
          obj = { [path]: obj };
        }
      });
    }

    filterItmes.push(obj);
  });

  return JSON.stringify({
    _and: [...filterItmes],
  });
};

export const getFilterOption = (
  options: IFilterOptions[],
  fields: IFilterField[]
) => {
  let filter = '';

  options.forEach((option) => {
    const { name, value, operation } = option;
    const pathArr = name.split(pathDivisionMark);
    let filterName = name;
    if (pathArr.length > 1) {
      filterName = `${pathArr[0]}.${pathArr[1]}`;
    }
    const filterOption = operationTransform[operation];
    if ([FilterOperation.IS_NULL].includes(operation)) {
      filter += `filter[${filterName}][_null]=true&`;
    } else if ([FilterOperation.IS_NOT_NULL].includes(operation)) {
      filter += `filter[${filterName}][_null]=false&`;
    } else if ([FilterOperation.IS_BETWEEN].includes(operation)) {
      const gte = value.split('&')[0];
      const lte = value.split('&')[1];
      filter += `filter[${filterName}][_gte]=${gte}&filter[${filterName}][_lte]=${lte}&`;
    } else {
      filter += `filter[${filterName}][${filterOption}]=${value}&`;
    }
  });

  return filter;
};

interface ICreatMenuParams extends IFilterContentProps {
  openKeys: string[];
  setOpenKeys: (values: string[]) => void;
  index?: number;
}

export const creatMenu = (params: ICreatMenuParams, parentName = '') => {
  const { fields, openKeys } = params;
  const menuData: any[] = [];

  fields.forEach((field) => {
    const { type = IFilterType.TEXT, children = [], name } = field;
    if ([IFilterType.MANY_TO_ONE].includes(type) && children.length > 0) {
      const parentPath = `${
        parentName ? `${parentName}${pathDivisionMark}${name}` : name
      }`;
      menuData.push(
        <SubMenu
          data-menu="open"
          key={field.name}
          data-path={parentPath}
          className="text-color-text-2 cursor-pointer hover:bg-system-background-color"
          title={
            <div
              data-menu="open"
              data-path={parentPath}
              className="flex items-center justify-between py-2 pr-4"
            >
              <div data-path={parentPath} data-menu="open">
                {field.label}
              </div>
              <DownIcon
                size={14}
                data-menu="open"
                data-path={parentPath}
                onClick={(e) => handleIconClick(e, field, params)}
                className={`transform duration-150 ${
                  openKeys.includes(field.name) ? '' : '-rotate-90'
                }`}
              />
            </div>
          }
          onTitleClick={(e) => hanledSubMenuClick(e, params)}
        >
          {creatMenu(
            {
              ...params,
              fields: children,
            },
            parentPath
          )}
        </SubMenu>
      );
    } else {
      menuData.push(
        <MenuItem
          data-menu="open"
          key={field.name}
          onClick={(e) => handleMenuClick(e, params)}
          className="text-color-text-1 px-5 py-3.5 cursor-pointer text-sm font-medium hover:bg-disabled-color first:pt-5 last:pb-5"
        >
          {field.label}
        </MenuItem>
      );
    }
  });
  return menuData;
};

const handleIconClick = (
  e: any,
  field: IFilterField,
  params: ICreatMenuParams
) => {
  e.stopPropagation();
  const { openKeys, setOpenKeys } = params;
  const newOpenKeys = [...openKeys];
  if (newOpenKeys.includes(field.name)) {
    newOpenKeys.splice(
      newOpenKeys.findIndex((item) => item === field.name),
      1
    );
  } else {
    newOpenKeys.push(field.name);
  }
  setOpenKeys(newOpenKeys);
};

const hanledSubMenuClick = (e: MenuTitleInfo, params: ICreatMenuParams) => {
  const dom: any = e.domEvent.target;
  if (dom.nodeName === 'svg') return;
  handleMenuClick(e, params);
};

const handleMenuClick = (e: any, params: ICreatMenuParams) => {
  const { options, searchValue, onFilter, index, fields, setShowMenu } = params;
  const { keyPath = [], domEvent = {} } = e;
  let value = keyPath.reverse().join(`${pathDivisionMark}`);
  if (!value) {
    const { path } = domEvent.target?.dataset;
    value = path;
  }
  const paths = value.split(pathDivisionMark);
  const optionName = paths[paths.length - 1];
  const field = findNode<IFilterField>(fields, 'name', optionName);
  const option = findNode<IFilterOptions>(options, 'name', value);
  const newOptions = [...options];
  setShowMenu(false);
  if (option) return;
  if (typeof index === 'number') {
    const newOption = options[index];
    if (
      newOption.name !== value &&
      field &&
      field.operations &&
      field.operations.length > 0
    ) {
      newOption.operation = field.operations[0];
    }

    if (field && field.type) {
      newOption.type = field.type
    }
    newOption.name = value;
    newOption.value = '';
    newOptions.splice(index, 1, newOption);
  } else {
    let operation = FilterOperation.EQUALS;
    if (field && field.operations && field.operations.length > 0) {
      operation = field.operations[0];
    }
    newOptions.push({
      name: value,
      value: '',
      operation,
      type: field?.type || IFilterType.TEXT,
    });
  }
  onFilter(searchValue, newOptions);
};
