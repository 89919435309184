import type { ICreditCard } from '../../../types/terminal-type';
import { getCardType, triggerWonderRoute } from '../../../utils/terminal-untils';
import React, { useEffect, useState } from 'react';
import type PayTypes from '../utils/payment/pay-types';
import SelectedIcon from '../terminal-icons/selected-icon';
import UnselectedIcon from '../terminal-icons/unselected-icon';
import DeleteCreditCardMobileIcon from '../terminal-icons/delete-credit-card-mobile-icon';
import { useTranslation } from 'react-i18next';

interface ICreditCartItemProps {
  inApp: boolean;
  card: ICreditCard;
  showRemoveCardID: string;
  setShowRemoveCardID: (id: string) => void;
  setMethod: (method: PayTypes | ICreditCard | 'balance') => void;
  handleCardDeleteInquiries: (card: ICreditCard) => void;
  method?: PayTypes | ICreditCard | 'balance';
}

const CreditCartItemPage: React.FC<ICreditCartItemProps> = (props) => {
  const distance = 92;
  const { card, setMethod, method, showRemoveCardID, setShowRemoveCardID } =
    props;
  const { number, id } = card;
  const { icon, type } = getCardType(number);
  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const [moveDistance, setMoveDistance] = useState(0);
  const [startX, setStartX] = useState(0);

  useEffect(() => {
    if (showRemoveCardID && showRemoveCardID !== card.id) {
      setMoveDistance(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRemoveCardID]);

  const handleTouchstart = (e: any) => {
    setMoveDistance(0);
    setStartX(e.targetTouches[0].clientX);
  };
  const handleTouchMove = (e: any) => {
    const move = startX - e.targetTouches[0].clientX;
    if (move > 0 && move < 100) {
      setMoveDistance(move);
      setShowRemoveCardID('');
    }
  };
  const handleTouchEnd = () => {
    if (moveDistance > distance / 2) {
      setMoveDistance(distance);
      setShowRemoveCardID(card.id);
    } else {
      setMoveDistance(0);
    }
  };

  const handleDeleteCard = () => {
    const { card, handleCardDeleteInquiries, inApp } = props;
    handleCardDeleteInquiries(card);
    if (inApp) {
      triggerWonderRoute('remove_card', 'confirm');
    }
  };

  return (
    <>
      <div className="overflow-hidden flex justify-between items-center">
        <div
          className="flex-1 flex items-center p-4 cursor-pointer text-xs"
          style={{
            marginLeft: `-${moveDistance === distance ? '16' : moveDistance}px`,
          }}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchstart}
          onClick={() => setMethod(card)}
        >
          {moveDistance !== distance && icon}
          <div className="ml-2 text-base leading-5">{type}</div>
          <div className="flex-1 flex justify-end">
            {method && typeof method !== 'string' && method.id === id ? (
              <SelectedIcon />
            ) : (
              <UnselectedIcon />
            )}
          </div>
        </div>
        <div
          className="h-[68px] bg-[#FC2E01]/20"
          style={{
            width: `${moveDistance}px`,
            transition: `300ms`,
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteCard();
          }}
        >
          <div className="h-full flex flex-col items-center justify-center overflow-hidden">
            <DeleteCreditCardMobileIcon />
            <div className="text-[#FC2E01] text-xs">{t('common:remove')}</div>
          </div>
        </div>
      </div>
      <div className="mx-4 border-b border-[#F5F5F5]"></div>
    </>
  );
};

export default CreditCartItemPage;
