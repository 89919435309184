import React from 'react';
import type { IIconProps } from '@/types/common-types';

const AddIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 20 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 20 20"
        fill="fill-current"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 4.16602V15.8327"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.16797 10H15.8346"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default AddIcon;
