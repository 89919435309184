import account, { IAccountState } from './account-reducer';
import businessState, { IBusinessState } from './business-reducer';

export interface IReducersState {
  account: IAccountState;
  businessState: IBusinessState;
}

const reducers = {
  account,
  businessState,
};

export default reducers;
