import type { InputHTMLAttributes } from 'react';
import type { FieldError } from 'react-hook-form';

import { Fragment, useState } from 'react';
import Upload from '../file-input/upload';
import { Dialog, Transition } from '@headlessui/react';

import PreviewIcon from '@/components/svg/preview-icon';
import DownloadIcon from '@/components/svg/download-icon';
import DeleteIcon from '@/components/svg/delete-icon';
import CloseIcon from '@/components/svg/close-icon';
import { IFileObject } from '@/types/common-types';
import { getAvatarsName } from '@/utils';

interface IFileWidget {
  label: string;
  proportion: number;
  rowQuantity: number;
  multiValue: boolean;
  maxSize?: number;
  maxCount?: number;
  shape?: 'Rectangular' | 'Circular';
  boundField?: string;
  defaultValue?: IFileObject[];
}

export interface IAvatarProps
  extends Omit<IFileWidget, 'defaultValue'>,
    Omit<
      InputHTMLAttributes<HTMLElement>,
      'onChange' | 'defaultValue' | 'value'
    > {
  name: string;
  type: 'AVATAR';
  displayName: string;
  value: IFileObject;
  disabled?: boolean;
  onChange?: (value: IFileObject) => void;
  error?: FieldError;
  defaultValue?: IFileObject[];
}

const aspect: { [key: number]: string } = {
  1: 'aspect-square',
  2: 'aspect-4/3',
  3: 'aspect-3/4',
};

const Avatar: React.FC<IAvatarProps> = (props) => {
  const {
    name,
    rowQuantity = 4,
    proportion = 1,
    onChange,
    error,
    disabled = false,
    maxSize = 200,
    displayName,
    value,
  } = props;

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState('');
  const [file, setFile] = useState<IFileObject>(value);

  const handlefilesUpdate = (file: IFileObject, type: 'ADD' | 'DELETE') => {
    let newFile;
    if (type === 'ADD') {
      newFile = file;
    } else if (type === 'DELETE') {
    }
    if (newFile) {
      setFile(newFile);
    }
    if (onChange && newFile) {
      onChange(newFile);
    }
    setLoading(false);
  };

  return (
    <>
      <div
        className={`block gap-x-4 sm:grid`}
        style={{
          gridTemplateColumns: `repeat(${
            !file && disabled ? '1' : rowQuantity
          }, minmax(0, 1fr))`,
        }}
      >
        {file && (
          <div>
            <div className="rounded-full">
              <div className="aspect-square">
                <img
                  className="rounded-full"
                  src={file.fileUrl}
                  loading="lazy"
                  alt=""
                />
                <div className="flex justify-center opacity-0 p-2 gap-x-2 hover:opacity-100 items-center">
                  <PreviewIcon
                    className="text-icon-color"
                    size={40}
                    onClick={() => {
                      setShow(true);
                      setUrl(file.fileUrl);
                    }}
                  />
                  <DownloadIcon className="text-icon-color bg-icon-color/10 rounded-full p-2.5" size={20} />
                  {!disabled && (
                    <DeleteIcon
                      className="text-error-color"
                      size={40}
                      onClick={() => handlefilesUpdate(file, 'DELETE')}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {!file && disabled && (
          <div className="truncate">
            <span className="inline-flex items-center justify-center h-16 w-16 bg-primary-color/10 rounded-full">
              <span className="font-bold text-color-text-1 text-xl leading-normal">
                {displayName ? getAvatarsName(displayName) : ''}
              </span>
            </span>
            <label className="text-color-text-1 font-semibold text-lg leading-snug ml-4">
              {displayName}
            </label>
          </div>
        )}
        {!file && !disabled && (
          <Upload
            className={aspect[proportion]}
            name={name}
            type="IMAGE"
            loading={loading}
            setLoading={setLoading}
            maxSize={maxSize}
            handlefilesUpdate={handlefilesUpdate}
            shape={'Circular'}
          />
        )}
      </div>
      {error && (
        <div className="mt-1 text-sm text-error-color" id="input-error">
          {error.message}
        </div>
      )}
      <Transition.Root show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setShow}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="max-w-max relative rounded overflow-hidden transform transition-all">
                  <div className="flex gap-x-4 items-start">
                    <img
                      src={url}
                      className="flex-1 shadow-xl rounded"
                      alt=""
                    />
                    <CloseIcon
                      className="text-icon-color bg-white rounded-full p-2.5"
                      size={20}
                      onClick={() => setShow(false)}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Avatar;
