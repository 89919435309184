import React from 'react';
import type { IIconProps } from '@/types/common-types';

const CorporateIncomeIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 45 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width={`${size}`} height={`${size}`} viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="45" height="45" rx="22.5" fill="#ECECEC" />
        <path d="M33.75 16.75C33.75 19.5125 28.7125 21.75 22.5 21.75C16.2875 21.75 11.25 19.5125 11.25 16.75M33.75 16.75C33.75 13.9875 28.7125 11.75 22.5 11.75C16.2875 11.75 11.25 13.9875 11.25 16.75M33.75 16.75V23M11.25 16.75V23M11.25 23C11.25 25.7625 16.2875 28 22.5 28M11.25 23V29.25C11.25 32.0125 16.2875 34.25 22.5 34.25M30 26.75V30.5M30 30.5V34.25M30 30.5H33.75M30 30.5H26.25" stroke="url(#paint0_linear_16233_113127)" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        <defs>
          <linearGradient id="paint0_linear_16233_113127" x1="22.5" y1="11.75" x2="22.5" y2="34.25" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0F086F" />
            <stop offset="1" stop-color="#4080D6" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default CorporateIncomeIcon;
