import React from 'react';
import type { IIconProps } from '@/types/common-types';

const SearchIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 20 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.54345 9.98089C3.094 9.98089 1.10881 7.99466 1.10881 5.54394C1.10881 3.09322 3.094 1.10699 5.54345 1.10699C7.99289 1.10699 9.97808 3.09322 9.97808 5.54394C9.97808 7.99466 7.99289 9.98089 5.54345 9.98089ZM9.8343 9.05608C10.6225 8.09243 11.0937 6.86014 11.0867 5.51692C11.0711 2.43879 8.57147 -0.0257939 5.49494 0.000203799C2.47038 0.0262015 0.0226728 2.47692 0.000153182 5.50479C-0.0223664 8.58466 2.44093 11.0804 5.52093 11.0926C6.85998 11.0978 8.08816 10.6281 9.04958 9.84295V9.84122L13.0546 13.8466C13.2711 14.0632 13.6211 14.0632 13.8376 13.8466C14.0541 13.63 14.0541 13.2799 13.8376 13.0632L9.8343 9.05608Z"
          fill="#C0C8D6"
        />
      </svg>
    </div>
  );
};

export default SearchIcon;
