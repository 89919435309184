import { Transition } from '@headlessui/react';

interface ITransitionNodeProps {
  children: React.ReactNode;
  className?: string;
}

const TransitionNode: React.FC<ITransitionNodeProps> = (props) => {
  const { children, className = '' } = props;
  return (
    <Transition.Child
      className={`flex shadow-card-drop rounded bg-primary-background-color ${className}`}
      enter="duration-350 transform transition ease-in-out"
      enterFrom="-translate-x-1 translate-y-8 opacity-60"
      enterTo="translate-x-0 translate-y-0 opacity-100"
      leave="duration-500 transform transition"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
    >
      <div className="flex-1 overflow-auto">{children}</div>
    </Transition.Child>
  );
};

export default TransitionNode;
