import styles from '@/pages/content.module.css';
import AbountUsWonderIcon from './abount-us-wonder-icon';
import { Link, useParams } from 'react-router-dom';

const AboutUs = () => {
  const { lang = 'en' } = useParams();

  return (
    <>
      {lang && lang !== 'zh-CN' && lang !== 'zh-HK' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.ab_us_content_top}`}>
              <figure className={`${styles.ab_us_content_figure}`}>
                <div className={`${styles.ab_us_content_img}`}>
                  <AbountUsWonderIcon className="text-[30px] mm:text-[65px]" />
                </div>
              </figure>
              <p>
                Wonder is a digital merchant wallet that integrates established
                merchant payment technologies with many possibilities. Utilising
                our digital wallet will enable you and your business to do any
                transactions quickly. With Wonder, we aim to empower your
                business to grow and operate anywhere and anytime. Use Wonder to
                accept payments as well as manage your business fund online.
                Wonder is here to serve the next generation of business
              </p>
            </div>
            <div>
              <div>
                <div>
                  <div>
                    <Link
                      to={
                        '/en/statements/personal-information-collection-statement'
                      }
                    >
                      <button className={`${styles.ab_us_content_a} ${styles.linkButton}`}>
                        <div className={`${styles.ab_us_content_a_text_div}`}>
                          <div>Personal Information Collection Statement</div>
                          <div className={`${styles.ab_us_content_svg}`}>
                            <svg
                              width="15px"
                              height="15px"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 13L7 7L1 0.999999"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div
                          className={`${styles.ab_us_content_a_broder}`}
                        ></div>
                      </button>
                    </Link>
                  </div>
                  <div>
                    <Link to={'/en/statements/privacy-policy'}>
                      <button className={`${styles.ab_us_content_a} ${styles.linkButton}`}>
                        <div className={`${styles.ab_us_content_a_text_div}`}>
                          <div className="about-us-text">Privacy Policy</div>
                          <div className={`${styles.ab_us_content_svg}`}>
                            <svg
                              width="15px"
                              height="15px"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 13L7 7L1 0.999999"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div
                          className={`${styles.ab_us_content_a_broder}`}
                        ></div>
                      </button>
                    </Link>
                  </div>
                  <div>
                    <Link to={'/en/statements/terms-and-condition'}>
                      <button className={`${styles.ab_us_content_a} ${styles.linkButton}`}>
                        <div className={`${styles.ab_us_content_a_text_div}`}>
                          <div className="about-us-text">
                            Terms and Condition
                          </div>
                          <div className={`${styles.ab_us_content_svg}`}>
                            <svg
                              width="15px"
                              height="15px"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 13L7 7L1 0.999999"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div
                          className={`${styles.ab_us_content_a_broder}`}
                        ></div>
                      </button>
                    </Link>
                  </div>
                  <div>
                    <Link to={'/en/statements/edda-terms-and-conditions'}>
                      <button className={`${styles.ab_us_content_a} ${styles.linkButton}`}>
                        <div className={`${styles.ab_us_content_a_text_div}`}>
                          <div className="about-us-text">eDDA Terms and Conditions</div>
                          <div className={`${styles.ab_us_content_svg}`}>
                            <svg
                              width="15px"
                              height="15px"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 13L7 7L1 0.999999"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div
                          className={`${styles.ab_us_content_a_broder}`}
                        ></div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-CN' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.ab_us_content_top}`}>
              <figure className={`${styles.ab_us_content_figure}`}>
                <div className={`${styles.ab_us_content_img}`}>
                  <AbountUsWonderIcon className="text-[30px] mm:text-[65px]" />
                </div>
              </figure>
              <p>
                Wonder是一个数字商户钱包，它整合了成熟的商户支付技术，具有许多可能性。利用我们的数字钱包将使您和你的企业能够快速进行任何交易。有了Wonder，我们的目标是使你的企业能够随时随地发展和经营。使用Wonder接受付款，以及在线管理你的业务资金。万得
                &quot;是为下一代企业服务的。
              </p>
            </div>
            <div>
              <div>
                <div>
                  <div>
                    <Link
                      to={
                        '/zh-CN/statements/personal-information-collection-statement'
                      }
                    >
                      <button className={`${styles.ab_us_content_a} ${styles.linkButton}`}>
                        <div className={`${styles.ab_us_content_a_text_div}`}>
                          <div>个人信息收集声明</div>
                          <div className={`${styles.ab_us_content_svg}`}>
                            <svg
                              width="15px"
                              height="15px"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 13L7 7L1 0.999999"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div
                          className={`${styles.ab_us_content_a_broder}`}
                        ></div>
                      </button>
                    </Link>
                  </div>
                  <div>
                    <Link to={'/zh-CN/statements/privacy-policy'}>
                      <button className={`${styles.ab_us_content_a} ${styles.linkButton}`}>
                        <div className={`${styles.ab_us_content_a_text_div}`}>
                          <div className="about-us-text">隐私政策</div>
                          <div className={`${styles.ab_us_content_svg}`}>
                            <svg
                              width="15px"
                              height="15px"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 13L7 7L1 0.999999"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div
                          className={`${styles.ab_us_content_a_broder}`}
                        ></div>
                      </button>
                    </Link>
                  </div>
                  <div>
                    <Link to={'/zh-CN/statements/terms-and-condition'}>
                      <button className={`${styles.ab_us_content_a} ${styles.linkButton}`}>
                        <div className={`${styles.ab_us_content_a_text_div}`}>
                          <div className="about-us-text">条款和条件</div>
                          <div className={`${styles.ab_us_content_svg}`}>
                            <svg
                             width="15px"
                             height="15px"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 13L7 7L1 0.999999"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div
                          className={`${styles.ab_us_content_a_broder}`}
                        ></div>
                      </button>
                    </Link>
                  </div>
                  <div>
                    <Link to={'/zh-CN/statements/edda-terms-and-conditions'}>
                      <button className={`${styles.ab_us_content_a} ${styles.linkButton}`}>
                        <div className={`${styles.ab_us_content_a_text_div}`}>
                          <div className="about-us-text">eDDA 直接付款授权书</div>
                          <div className={`${styles.ab_us_content_svg}`}>
                            <svg
                             width="15px"
                             height="15px"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 13L7 7L1 0.999999"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div
                          className={`${styles.ab_us_content_a_broder}`}
                        ></div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-HK' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.ab_us_content_top}`}>
              <figure className={`${styles.ab_us_content_figure}`}>
                <div className={`${styles.ab_us_content_img}`}>
                  <AbountUsWonderIcon className="text-[30px] mm:text-[65px]" />
                </div>
              </figure>
              <p>
                Wonder是一個數位商家錢包，它將已建立的商家支付技術與許多可能性集成在一起。利用我們的數位錢包將使您能夠和
                你的業務快速進行任何交易。通過 Wonder，我們的目標是賦予權力
                你的隨時隨地發展和運營業務。使用 Wonder 接受付款和管理
                你的商業基金在線。Wonder 在這裡為下一代企業提供服務
              </p>
            </div>
            <div>
              <div>
                <div>
                  <div>
                    <Link
                      to={
                        '/zh-HK/statements/personal-information-collection-statement'
                      }
                    >
                      <button className={`${styles.ab_us_content_a} ${styles.linkButton}`}>
                        <div className={`${styles.ab_us_content_a_text_div}`}>
                          <div>個人資料收集聲明</div>
                          <div className={`${styles.ab_us_content_svg}`}>
                            <svg
                              width="15px"
                              height="15px"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 13L7 7L1 0.999999"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div
                          className={`${styles.ab_us_content_a_broder}`}
                        ></div>
                      </button>
                    </Link>
                  </div>
                  <div>
                    <Link to={'/zh-HK/statements/privacy-policy'}>
                      <button className={`${styles.ab_us_content_a} ${styles.linkButton}`}>
                        <div className={`${styles.ab_us_content_a_text_div}`}>
                          <div className="about-us-text">隱私策略</div>
                          <div className={`${styles.ab_us_content_svg}`}>
                            <svg
                              width="15px"
                              height="15px"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 13L7 7L1 0.999999"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div
                          className={`${styles.ab_us_content_a_broder}`}
                        ></div>
                      </button>
                    </Link>
                  </div>
                  <div>
                    <Link to={'/zh-HK/statements/terms-and-condition'}>
                      <button className={`${styles.ab_us_content_a} ${styles.linkButton}`}>
                        <div className={`${styles.ab_us_content_a_text_div}`}>
                          <div className="about-us-text">條款及細則</div>
                          <div className={`${styles.ab_us_content_svg}`}>
                            <svg
                              width="15px"
                              height="15px"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 13L7 7L1 0.999999"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div
                          className={`${styles.ab_us_content_a_broder}`}
                        ></div>
                      </button>
                    </Link>
                  </div>
                  <div>
                    <Link to={'/zh-HK/statements/edda-terms-and-conditions'}>
                      <button className={`${styles.ab_us_content_a} ${styles.linkButton}`}>
                        <div className={`${styles.ab_us_content_a_text_div}`}>
                          <div className="about-us-text">eDDA 直接付款授權書</div>
                          <div className={`${styles.ab_us_content_svg}`}>
                            <svg
                              width="15px"
                              height="15px"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 13L7 7L1 0.999999"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div
                          className={`${styles.ab_us_content_a_broder}`}
                        ></div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AboutUs;
