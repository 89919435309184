import React from 'react';
// import CurrencySymbols from '@/constants/currency-symbols';
import BigNumber from 'bignumber.js';
interface ProjectBalanceItemProps {
  title: string;
  index: number;
  amount: string;
  color: string;
  showCurrenBalance: boolean;
  currency?: string;
}

const ProjectBalanceItem = (props: ProjectBalanceItemProps) => {
  const { title, amount, showCurrenBalance, color } = props;

  const handleAmountElement = () => {
    // let showSymbol = false;
    let temp = new BigNumber(amount);
    let returnString = '';
    if (temp.toNumber() < 0) {
      // showSymbol = true;
      temp = new BigNumber(temp.absoluteValue());
    }
    returnString = temp.toFormat(2, 1);

    if (!showCurrenBalance) {
      returnString = '****';
    }

    return (
      <div>
        {/* <span className="ml-3 font-medium text-base">
          {showSymbol ? '-' : ''}
        </span>
        <span className="font-medium text-base">
          {currencySymbol(currency)}
        </span> */}
        <span className="font-medium text-lg ml-3">{returnString}</span>
      </div>
    );
  };

  return (
    <div className="mb-2">
      <div className="flex items-center">
        <div
          className="rounded-full bg-primary-color w-1.5 h-1.5"
          style={{
            backgroundColor: color,
          }}
        >
          {' '}
        </div>

        <span className="ml-1.5 text-color-text-2 text-sm">{title}</span>
      </div>
      <>{handleAmountElement()}</>
    </div>
  );
};

export default React.memo(ProjectBalanceItem);
