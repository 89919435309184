import { useEffect, useMemo, useState } from 'react';
import { Transition } from '@headlessui/react';
import CountryCurrencySelect from './country-currency-select';
import PayoutMethodSelect from './payout-method-select';
import MethodInformation from './method-information';
import PayeeInformation from './payee-information';
import AccountDetails from './account-details';
import { useTranslation } from 'react-i18next';
import { IAvailableCountry, IAvailableCurrency, ICategory, INewPayeeFormData, IPayoutMethod } from '@/types/payouts-type';
import { initNewPayeeData } from '@/utils/payouts-utils';
import { IAreaCodes, IObject } from '@/types/common-types';
import { getCookie } from '@/utils';
import { Constants } from '@/constants';
import { getAvailableCountries, getAvailableCurrencies, getCategories, getPayoutMethod } from '@/data/payouts';
import CloseIcon from '@/components/svg/close-icon';
import { loadAreaCodesData } from '@/data/area-codes';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserAction } from '@/utils/common';
import GlobalLoading from '@/components/common/global-loading';

const PayeesNewPage = () => {
  const { t } = useTranslation('payees');
  const { t: ct } = useTranslation();
  const { lang = 'en', p_business_id: businessID = '' } = useParams();
  const navigate = useNavigate();
  const steps = [
    {
      key: '1',
      title: t('payout_destination'),
    },
    {
      key: '2',
      title: t('payout_method'),
    },
    {
      key: '3',
      title: t('payee_information'),
    },
  ];
  const [stepCount, setStepCount] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [payeeData, setPayeeData] = useState<INewPayeeFormData>(initNewPayeeData);
  const [payoutMethod, setPayoutMethod] = useState<IPayoutMethod | undefined>(
    undefined
  );
  const [payoutMethods, setPayoutMethods] = useState<IPayoutMethod[]>([]);
  const [availableCurrencies, setAvailableCurrencies] = useState<
    IAvailableCurrency[]
  >([]);
  const [availableCountries, setAvailableCountries] = useState<
    IAvailableCountry[]
  >([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [loading, setLoading] = useState(true);
  const [business, setBusiness] = useState<IObject | undefined>(undefined);
  const [businessLoading, setBusinessLoading] = useState(false);
  // const [lottie] = useState<any | null>(null);
  const [areaCodes, setAreaCodes] = useState<IAreaCodes[]>([]);

  useEffect(() => {
    loadingData();
    // eslint-disable-next-line
  }, [businessID]);

  useUserAction();

  // useEffect(() => {
  //   require('@dotlottie/player-component');
  //   setLottie(
  //     <div className="App">
  //       <dotlottie-player src="/json/loading.json" autoplay loop />
  //     </div>
  //   );
  // }, []);

  const loadingData = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    const allPromise: any[] = [
      getCategories(token, lang),
      getAvailableCurrencies(businessID, token, lang),
      getAvailableCountries(token, lang),
      loadAreaCodesData(),
    ];

    const resp = await Promise.all(allPromise);
    setCategories(resp[0]);
    setAvailableCurrencies(resp[1]);
    setAvailableCountries(resp[2]);
    setAreaCodes(resp[3]);
    setLoading(false);
  };

  const handleCurrecyNext = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    const payoutMethods = await getPayoutMethod(
      businessID,
      token,
      payeeData.currency,
      payeeData.country,
      lang
    );
    setPayoutMethods(payoutMethods);
    setCurrentStep(1);
    setStepCount(1);
  };

  const handleCancel = () => {
    navigate(`/${lang}/businesses/${businessID}/payees`);
  };

  const spaceHead = useMemo(() => {
    const setps = [
      t('select_payout_destination'),
      t('select_payout_method'),
      t('enter_account_details'),
      t('confirm_account_details'),
      t('enter_payee_information'),
    ];
    return (
      <>
        <div className="flex justify-between font-semibold text-color-text-1">
          <div className="text-xl leading-[26px]">{t('new_payee')}</div>
          <div
            className="text-color-text-2 font-medium text-sm cursor-pointer hover:text-color-text-2/80"
            onClick={handleCancel}
          >
            {ct('cancel')}
          </div>
        </div>
        <div className="text-color-text-2 text-sm mt-1">
          {setps[currentStep]}
        </div>
      </>
    );
    // eslint-disable-next-line
  }, [currentStep, payoutMethod, lang]);

  return (
    <div className="h-auto min-h-screen bg-system-background-color text-sm font-medium">
      <Transition.Root show={true} appear={true} className="h-full">
        <Transition.Child
          className="flex items-center justify-between p-4 md:px-6 md:py-8 lg:px-12"
          enter="duration-350 transform transition ease-in-out"
          enterFrom="-translate-x-1 translate-y-10 opacity-40"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="duration-500 transform transition"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="font-bold text-xl leading-[30px] md:text-3xl md:leading-10 text-color-text-1">
            {t('new_payee')}
          </div>
          <CloseIcon
            size={20}
            onClick={handleCancel}
            className="text-color-text-1 cursor-pointer hover:text-primary-color p-3 bg-white rounded-full"
          />
        </Transition.Child>
        <div className="flex-1 flex flex-col pb-6 md:flex-row lg:mt-6 lg:justify-center w-4/5 lg:w-3/5 mx-auto">
          <Transition.Child
            className="flex flex-[3] items-center flex-col mb-4 md:block"
            enter="duration-450 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-10 opacity-40"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <ul className="flex justify-between w-full md:w-auto px-6 md:block md:px-0">
              {steps.map((step, index) => (
                <div
                  className={`flex md:block ${index === steps.length - 1 ? '' : 'flex-1'
                    }`}
                  key={step.key}
                >
                  <li
                    className={`flex items-center text-color-text-4 hover:text-primary-color`}
                  >
                    <span
                      className={`rounded-full w-6 h-6 flex-none flex items-center justify-center ${stepCount >= index
                          ? 'bg-primary-color text-color-text-6'
                          : 'bg-disabled-color text-color-text-2 border border-border-color'
                        }`}
                    >
                      {step.key}
                    </span>
                    <span
                      className={`ml-3 font-medium whitespace-nowrap hidden md:block ${stepCount < index
                          ? 'text-color-text-2'
                          : 'text-color-text-1'
                        }`}
                    >
                      {step.title}
                    </span>
                  </li>
                  {index !== steps.length - 1 && (
                    <div
                      className={`mt-3 flex-1 h-0.5 md:w-0.5 md:h-[46px] md:ml-3 md:mt-0 ${stepCount >= index
                          ? 'bg-primary-color'
                          : 'bg-border-color opacity-60'
                        }`}
                      aria-hidden="true"
                    ></div>
                  )}
                </div>
              ))}
            </ul>
          </Transition.Child>
          <Transition.Child
            className="flex-[7]"
            enter="duration-500 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-10 opacity-40"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="pl-0 md:pl-6">
              {currentStep === 0 && (
                <CountryCurrencySelect
                  availableCurrencies={availableCurrencies}
                  availableCountries={availableCountries}
                  areaCodes={areaCodes}
                  heade={spaceHead}
                  payeeData={payeeData}
                  loading={loading}
                  setPayeeData={setPayeeData}
                  handleCurrecyNext={handleCurrecyNext}
                />
              )}
              {currentStep === 1 && (
                <PayoutMethodSelect
                  heade={spaceHead}
                  payoutMethods={payoutMethods}
                  payoutMethod={payoutMethod}
                  currency={payeeData.currency}
                  setPayoutMethod={setPayoutMethod}
                  setCurrentStep={setCurrentStep}
                  setStepCount={setStepCount}
                />
              )}
              {currentStep === 2 && payoutMethod && (
                <MethodInformation
                  businessID={businessID}
                  areaCodes={areaCodes}
                  heade={spaceHead}
                  payoutMethod={payoutMethod}
                  payeeData={payeeData}
                  setPayeeData={setPayeeData}
                  setCurrentStep={setCurrentStep}
                  setBusiness={setBusiness}
                  setStepCount={setStepCount}
                  setBusinessLoading={setBusinessLoading}
                />
              )}
              {currentStep === 3 && business && (
                <AccountDetails
                  areaCodes={areaCodes}
                  heade={spaceHead}
                  payeeData={payeeData}
                  business={business}
                  setCurrentStep={setCurrentStep}
                  setStepCount={setStepCount}
                />
              )}
              {currentStep === 4 && payoutMethod && (
                <PayeeInformation
                  businessID={businessID}
                  areaCodes={areaCodes}
                  heade={spaceHead}
                  payoutMethod={payoutMethod}
                  payeeData={payeeData}
                  categories={categories}
                  setCurrentStep={setCurrentStep}
                  setStepCount={setStepCount}
                />
              )}
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
      <GlobalLoading loading={businessLoading} />
      {/* <div
        className={`z-50 fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-secondary-background-color opacity-70 ${
          businessLoading ? 'block' : 'hidden'
        }`}
      />
      <div
        className={`z-100 fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center ${
          businessLoading ? 'block' : 'hidden'
        }`}
      >
        <div className="w-20 h-20 px-3 flex justify-center items-center rounded-2xl bg-primary-background-color">
          {lottie}
        </div>
      </div> */}
    </div>
  );
};

export default PayeesNewPage;
