import styles from '@/pages/content.module.css';
import Config from '@/libs/config';
import { Link, useParams } from 'react-router-dom';

const TermsAndCondition = () => {
  const { lang = 'en' } = useParams();
  const prefix = Config.staticPath;

  return (
    <>
      <head>
        <link
          rel="preload"
          href={`${prefix}/font/Outfit-Light.woff`}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="preload"
          href={`${prefix}/font/Outfit-Bold.woff`}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        ></link>
      </head>
      {lang !== 'zh-CN' && lang !== 'zh-HK' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title}`}>Terms and Condition</div>
            <div className={`${styles.content}`}>
              <h3>1. The Wonder App</h3>
              <p>
                (a) The Wonder App is a payments management application offered
                by Wonder VenturesLimited for business owners and his/her staffs
                to manage day-to-day operations. The app is intended only for
                registered businesses in Hong Kong Special Administrative Region
                (HKSAR) to access to our payment management tools.
              </p>
              <p>
                (b) Wonder Ventures is a certified Money Service Operator in
                HKSAR and is bind by the laws and regulations set forth by the
                Hong Kong Customs and Excise Department. We do not intend to
                offer our products and services to any business or persons
                outside HKSAR.
              </p>
              <p>
                (c) The Wonder App is available to download for free from the
                App Store or Google Play on devices owned by you which runs
                Apple’s iOS or Google’s Android operating system.
              </p>
              <p>
                (d) You’ll need an active internet connection to download and
                use the Wonder App.
              </p>
              <p>
                (e) You’re responsible for any fees charged by your internet or
                mobile service provider relating to your use of the Wonder App.
                If you’re going oversea, you should check if your service
                provider will charge you any additional fees for using the
                Wonder App oversea.
              </p>
              <p>
                (f) We may use your mobile phone number and/or email address you
                registered with us to send you SMS codes and any information
                relevant to your use of or access to the Wonder App.
              </p>
              <h3>2. Registering a Device</h3>
              <p>
                (a) Your device will be registered with us as soon as you
                download the Wonder App, even if you haven’t accepted our terms
                and conditions and we haven’t yet approved the products or
                services you’re applying for.
              </p>
              <p>
                (b) You can log in to the Wonder App on your device by providing
                your passcode or using any biometric authentication method
                provided by your device provider (if activated on the Wonder
                App), which we will use to verify your identity (along with any
                other relevant information or documents you provide us).
              </p>
              <p>
                (c) You can change your login settings or passcode at any time
                while signed in to the Wonder App.
              </p>
              <p>
                (d) If you change your device, you can login to your Wonder App
                account on your new device with your pre-existing login
                credentials. Please note that your registered account can only
                be accessed by one device at any given time (cannot login on
                multiple devices at the same time).
              </p>
              <h3>3. Protecting your device and the Wonder App</h3>
              <p>To keep your device and the Wonder App secure, you must:</p>
              <p>
                (a) use a passcode, biometric authentication method or other
                method provided by your device provider to access your device;
              </p>
              <p>
                (b) ensure only your biometrics are registered on the device;
              </p>
              <p>
                (c) not use face ID to access the Wonder App if it may not be a
                secure or reliable way to authenticate your identity (eg. if you
                have an identical twin sibling);
              </p>
              <p>
                (d) not share your Wonder App passcode with any person. Any
                other person who uses your Wonder App passcode to access the
                Wonder App will be able to act on your behalf and you will be
                responsible for anything they do;
              </p>
              <p>
                (e) change your Wonder App passcodes regularly to prevent any
                unauthorised access to your device and the Wonder App;
              </p>
              <p>
                (f) keep your devices safe, secure and in your own custody. In
                particular, do not leave your device unattended while you are
                logged in to the Wonder App; and
              </p>
              <p>
                (g) delete the Wonder App and remove all relevant information
                associated with Wonder App before you sell, recycle, discard or
                permanently give your device away.{' '}
              </p>
              <p>You must let us know if you find or believe that:</p>
              <p>
                (a) any other person knows your Wonder App passcode or has
                managed to unlawfully access your Wonder App;
              </p>
              <p>(b) your device has been compromised, lost or stolen; or</p>
              <p>
                (c) unauthorised transactions have been conducted over your
                accounts.
              </p>
              <p>
                <strong>
                  You will be liable for all losses if you have acted
                  fraudulently, with gross negligence or have failed to inform
                  us as soon as reasonably practicable after you find or believe
                  that your Wonder App passcode or device for accessing the
                  Wonder App has been compromised, lost or stolen, or that
                  unauthorised transactions have been conducted over your
                  accounts, including if you have failed to follow the
                  safeguards and or meet your obligations set out in this clause
                  3 and your failure has caused the loss.
                </strong>
              </p>
              <p>For additional security, we recommend:</p>
              <p>
                (a) protecting your device from malware by being wary of
                suspicious emails and hyperlinks and downloading only known or
                trusted software, setting strong passwords and keeping the
                operating system and software of your device up-to-date; and
              </p>
              <p>
                (b) enabling or installing remote wipe functionality (ie. the
                ability to send a command to your device that will completely
                remove all the data stored on the device) on your device in case
                it is lost or stolen.
              </p>
              <h3>4. Wonder App Updates</h3>
              <p>
                (a) We may update the Wonder App from time to time, including by
                making enhancements, adding new features or carrying out
                security updates.
              </p>
              <p>
                (b) Sometimes we may require you to update the Wonder App before
                you continue using it.
              </p>
              <p>(c) You should:</p>
              <ul>
                <li>
                  keep the operating system of your device up to date. We will
                  notify you in advance before we stop supporting a version of
                  an operating system and remind you to update your device’s
                  operating system; and
                </li>
                <li>
                  keep the Wonder App up to date. Otherwise, certain features of
                  the Wonder App might not work as intended. If you can, we
                  recommend turning on automatic app updates in your device
                  settings.
                </li>
              </ul>
              <h3>5. Wonder App Updates</h3>
              <p>
                (a) The Wonder App may be temporarily unavailable when we’re
                conducting essential maintenance or system upgrades. We’ll let
                you know when we plan to do so and for how long the Wonder App
                will be unavailable (if at all).
              </p>
              <p>
                (b) The availability of the Wonder App is dependent on the
                reliability and availability of third party service providers
                including software, network and other service providers that
                enable your access to the Wonder App.
              </p>
              <p>
                (c) If any third party service is required for your use of the
                Wonder App, you will comply with the terms of use applicable to
                that third party. For example, the Wonder App uses Google Maps
                features and content and your use of such Google Maps features
                and content in the Wonder App is subject to the Google
                Maps/Google Earth Additional Terms of Service at{' '}
                <Link to={'https://maps.google.com/help/terms_maps.html'}>
                  <button className={`${styles.linkButton}`}>https://maps.google.com/help/terms_maps.html</button>
                </Link>{' '}
                (including the Google Privacy Policy at{' '}
                <Link to={'https://www.google.com/policies/privacy/'}>
                  <button className={`${styles.linkButton}`}>https://www.google.com/policies/privacy/</button>
                </Link>
                ).
              </p>
              <p>
                (d) Wonder VenturesLimited is not a party to any contractual
                arrangements entered into between you and a third party unless
                Wonder VenturesLimited expressly specifies or agrees otherwise.
              </p>
              <h3>6. Hyperlinks</h3>
              <p>
                (a) The Wonder App may contain hyperlinks to third party
                websites or mobile applications.
              </p>
              <p>
                (b) Wonder VenturesLimited does not maintain or control any
                third party website or mobile application hyperlinked on the
                Wonder App and is not responsible for them – the hyperlinks
                appear for information purposes and are for your convenience
                only.
              </p>
              <p>
                (c) Your access to and use of any third party website or mobile
                application is at your own risk and subject to the terms of that
                third party website or mobile application.
              </p>
              <h3>7. Third Party Contributions</h3>
              <p>
                <em>
                  (a) The Wonder App may contain materials and other
                  contributions from third parties (including merchants and
                  individuals).
                </em>
              </p>
              <p>
                (b) The display of any materials and other contributions from a
                third party on the Wonder App do not:
              </p>
              <ul>
                <li>
                  constitute an endorsement by Wonder Ventures of such materials
                  and contributions (including any opinions expressed by the
                  third party) – any opinions expressed by the third party are
                  those of the third party and do not necessarily reflect the
                  opinions of Wonder Ventures Limited; or
                </li>
                <li>
                  assert or imply Wonder Ventures Limited’s endorsement,
                  recommendation, favouring, investigation, verification or
                  monitoring of the third party or their products or services.
                </li>
              </ul>
              <h3>8. Third Party Contributions</h3>
              <p>
                (a) The Wonder App belongs to Wonder Ventures Limted and you
                must only use it for the purposes set out in these terms.
              </p>
              <p>
                (b) All content on the Wonder App is the responsibility of the
                person from whom it originated. This means that you, and not us,
                are entirely responsible for all content that you upload or
                otherwise make available via the Wonder App.
              </p>
              <p>
                (c) You must only install applications from the App Store or
                Google Play on your device on which you install the Wonder App
                and not override the device’s operating system, eg. by
                “jailbreaking” or “rooting” the device.
              </p>
              <p>
                (d) You must not install or launch the Wonder App if your device
                contains any pirated, hacked, fake or unauthorised applications.
              </p>
              <p>
                (e) You must not act fraudulently or maliciously in relation to
                the Wonder App or its features, including by reselling, copying,
                modifying, adversely effecting, reverse engineering or tampering
                with the Wonder App in any way, or assist anyone else to do any
                of these things, unless you have been authorised to do so.
              </p>
              <p>
                (f) You must not use the Wonder App in any unlawful manner or in
                contravention of any agreement with us.
              </p>
              <p>
                (g) Any information or document transmitted through the Wonder
                App is subject to risks of delay, loss, diversion, alteration,
                corruption and other risks associated with hardware and software
                failure.
              </p>
              <h3>9. Switching on certain functions on your device</h3>
              <p>
                On the device you install the Wonder App on, we may ask you to
                switch on certain functions, such as the camera function and
                location services, and to share your contacts list with us. This
                will allow you to enjoy certain functions on the Wonder App. If
                you agree to turn on these functions, we may for example, track
                your geolocation and IP address when you make transactions. For
                security reasons, taking screenshots and video recording will
                not be available on the Wonder App.
              </p>
              <h3>10. If you suffer any loss</h3>
              <p>
                <strong>
                  We will not be responsible for any loss you suffer from any
                  use of or access to the Wonder App, or as a consequence of:
                </strong>
              </p>
              <p>
                <strong>
                  (a) not being able to use or access the Wonder App or any
                  services within the Wonder App, unless your loss is caused by
                  our negligence, fraud or wilful default;
                </strong>
              </p>
              <p>
                <strong>
                  (b) issues with your device or network connection;
                </strong>
              </p>
              <p>
                <strong>
                  (c) access or use of the Wonder App by any other person who
                  uses your passcode to access the Wonder App on your device;
                </strong>
              </p>
              <p>
                <strong>
                  (d) any information, goods or services provided via a third
                  party website or mobile application which the Wonder App
                  provides a hyperlink to;
                </strong>
              </p>
              <p>
                <strong>
                  (e) any third party’s fraudulent or negligent actions; or
                </strong>
              </p>
              <p>
                <strong>
                  (f) you not complying with your obligations under these terms.
                </strong>
              </p>
              <p>
                <strong>
                  If any law requires us to be responsible for any of your loss,
                  we will be responsible only to the extent of re-supplying the
                  services via the Wonder App or the cost of re-supplying those
                  services.
                </strong>
              </p>
              <h3>11. If we suffer any loss</h3>
              <p>
                You will indemnify, hold harmless and defend us against any
                liabilities and costs suffered by us as a result of:
              </p>
              <p>(a) use of the Wonder App; or</p>
              <p>(b) breach of these terms,</p>
              <p>
                by you or any other person (where such person was able to use
                the Wonder App using your logon credentials).
              </p>
              <h3>12. Suspending or cancelling access</h3>
              <p>
                We may suspend or cancel your access to the Wonder App at our
                sole discretion, for example:
              </p>
              <p>(a) to protect our business;</p>
              <p>(b) to protect you or us from fraud or other loss;</p>
              <p>
                (c) if you have not used the Wonder App in accordance with these
                terms; or
              </p>
              <p>
                (d) where we have reasonable grounds to believe that your use of
                the Wonder App breaches the laws or regulations of Hong Kong or
                any other country.
              </p>
              <p>
                If we do suspend or cancel your access, we’ll usually give you
                advance notice with reasons for our decision. However, we’re not
                required to give you any such notice or reasons.
              </p>
              <h3>13. We own all intellectual property</h3>
              <p>
                (a) All rights (such as copyright) in relation to the contents
                (such as trademarks, text, graphics, images, links and sounds)
                on the Wonder App are owned by or licensed to Wonder Ventures
                Limited. Unless we have said otherwise, the contents of the
                Wonder App may not be copied, modified, downloaded, distributed,
                published, reused, reposted, reverse engineered, decompiled or
                made any use of in any way without our prior written consent.
              </p>
              <p>
                (b) “Wonder”, “Wonder App” and all associated logos (“Trade
                Marks”) are pending or registered trade marks of Wonder Ventures
                Limited and are used under licence by Wonder Ventures Limited.
                You may not use the Trade Marks without prior written consent
                from Wonder Ventures Limited.
              </p>
              <p>
                (c) You grant to Wonder Ventures Limited and our controlling
                entities a worldwide, royalty-free, perpetual, irrevocable,
                sub-licensable right and licence to use any information or
                materials which you submit to Wonder Ventures using the Wonder
                App for any purpose we deem appropriate, including, without
                limitation, the copying, modification, transmission,
                distribution and publication thereof, unless restricted by
                applicable law. You warrant and will ensure that any such
                information or material you submit to Wonder Ventures does not
                infringe the rights of any third party.
              </p>
              <p>
                (d) Wonder Ventures is not under any obligation of
                confidentiality to you regarding any such information or
                materials submitted to us while using the Wonder App, unless
                required by law.
              </p>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-CN' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title}`}>条款和条件</div>
            <div className={`${styles.content}`}>
              <h3>1.神奇的应用程序</h3>
              <p>
                (a) Wonder App是Wonder
                VenturesLimited提供的一个支付管理应用程序，供企业主及其员工管理日常业务。该应用程序仅适用于在香港特别行政区（香港特区）注册的企业，以使用我们的支付管理工具。
              </p>
              <p>
                (b) Wonder
                Ventures是香港特别行政区的认证货币服务运营商，并受香港海关规定的法律和法规的约束。我们不打算向香港特区以外的任何企业或个人提供我们的产品和服务。
              </p>
              <p>
                (c) Wonder App可从App Store或Google
                Play免费下载到您拥有的运行苹果iOS或谷歌Android操作系统的设备上。
              </p>
              <p>(d) 你需要一个活跃的互联网连接来下载和使用Wonder App。</p>
              <p>
                (e) 您负责支付由你的互联网或移动服务提供商收取的与你的使用Wonder
                App有关的任何费用。如果你要去海外，你应该检查你的服务提供商是否会对你在海外使用Wonder
                App收取任何额外费用。
              </p>
              <p>
                (f)
                我们可能使用你的您在我们这里注册的手机号码和/或电子邮件地址，向您发送短信代码以及与你的使用或访问Wonder
                App有关的任何信息。
              </p>
              <h3>2.注册一个设备</h3>
              <p>
                (a)你的一旦你下载了Wonder
                App，设备就会在我们这里注册，即使你还没有接受我们的条款和条件，我们也还没有批准你所申请的产品或服务。
              </p>
              <p>
                你的(b)
                您可以通过提供你的密码或使用你的设备提供商提供的任何生物识别认证方法（如果在Wonder
                App上激活），在 设备上登录Wonder
                App，我们将使用该方法来验证你的身份（以及您向我们提供的任何其他相关信息或文件）。
              </p>
              <p>
                (c) 您可以在登录Wonder App的过程中，随时更改你的登录设置或密码。
              </p>
              <p>
                (d)
                如果您更换了你的设备，您可以在你的新设备上用你的以前的登录凭证登录到你的Wonder
                App账户。请注意，你的注册账户在任何时候只能由一个设备访问（不能同时在多个设备上登录）。
              </p>
              <h3>3.保护你的设备和Wonder App</h3>
              <p>为了保证你的设备和Wonder App的安全，你必须。</p>
              <p>
                (a)
                使用密码、生物识别认证方法或其他由你的设备提供商提供的方法来访问你的设备。
              </p>
              <p>(b) 确保只有你的生物识别技术被登记在设备上。</p>
              <p>
                (c) 不使用面部识别来访问Wonder
                App，如果它可能不是一个安全或可靠的方式来验证你的身份（例如，如果你有一个相同的双胞胎兄弟姐妹）。
              </p>
              <p>
                (d) 不与任何人分享你的Wonder App的密码。任何其他使用你的Wonder
                App密码访问Wonder
                App的人将能够代表你的，你将对他们的任何行为负责。
              </p>
              <p>
                (e) 定期更改你的Wonder
                App的密码，以防止任何未经授权的访问你的设备和Wonder App。
              </p>
              <p>
                (f)
                保持你的设备的安全、可靠并由你的自己保管。特别是，当您登录Wonder
                App时，不要让你的设备无人看管；以及
              </p>
              <p>
                (g) 在您出售、回收、丢弃或永久赠送你的设备之前，删除Wonder
                App并删除与Wonder App有关的所有相关信息。{' '}
              </p>
              <p>如果你发现或相信这一点，你必须让我们知道。</p>
              <p>
                (a) 任何其他人知道你的Wonder App的密码或设法非法访问你的Wonder
                App。
              </p>
              <p>(b)你的设备已被破坏、丢失或被盗；或</p>
              <p>(c) 通过你的账户进行了未经授权的交易。</p>
              <p>
                <strong>
                  如果您有欺诈行为、严重疏忽或在发现或相信你的Wonder
                  App密码或访问Wonder
                  App的设备被泄露、丢失或被盗，或在你的账户上进行了未经授权的交易后，未能在合理可行的情况下尽快通知我们，包括您未能遵循本第3条规定的保障措施和或履行你的义务，且你的，导致损失，您将对所有损失负责。
                </strong>
              </p>
              <p>为了增加安全性，我们建议。</p>
              <p>
                (a)
                通过警惕可疑的电子邮件和超链接，并只下载已知或可信的软件，设置强大的密码，以及保持你的设备的操作系统和软件的更新，来保护你的设备免受恶意软件的侵害；以及
              </p>
              <p>
                (b)
                在你的设备上启用或安装远程擦除功能（即能够向你的设备发送命令，完全删除存储在该设备上的所有数据），以防其丢失或被盗。
              </p>
              <h3>4.神奇的应用程序更新</h3>
              <p>
                (a) 我们可能会不时地更新Wonder
                App，包括进行改进、增加新功能或进行安全更新。
              </p>
              <p>(b) 有时我们可能要求您在继续使用Wonder App之前更新它。</p>
              <p>(c) 你应该。</p>
              <ul>
                <li>
                  保持你的设备的操作系统是最新的。在我们停止支持某一版本的操作系统之前，我们会提前通知你，并提醒你更新你的设备的操作系统；以及
                </li>
                <li>
                  保持Wonder App的更新。否则，Wonder
                  App的某些功能可能无法按预期运行。如果可以，我们建议在你的设备设置中打开自动应用程序更新。
                </li>
              </ul>
              <h3>5.神奇的应用程序更新</h3>
              <p>
                (a) 当我们进行必要的维护或系统升级时，Wonder
                App可能会暂时不可用。我们会让您知道我们计划何时这样做，以及
                &quot;奇迹 &quot;应用程序将不可用多长时间（如果有的话）。
              </p>
              <p>
                (b) Wonder
                App的可用性取决于第三方服务提供商的可靠性和可用性，包括软件、网络和其他服务提供商，使你的访问Wonder
                App。
              </p>
              <p>
                (c) 如果你的使用Wonder
                App需要任何第三方服务，您将遵守适用于该第三方的使用条款。例如，Wonder
                App使用谷歌地图的功能和内容，你的，在Wonder
                App中使用这种谷歌地图的功能和内容，要遵守谷歌地图/谷歌地球的附加服务条款，网址是
                <Link to={'https://maps.google.com/help/terms_maps.html'}>
                  <button className={`${styles.linkButton}`}>https://maps.google.com/help/terms_maps.html</button>
                </Link>
                （包括谷歌隐私政策，网址是
                <Link to={'https://www.google.com/policies/privacy/'}>
                  <button className={`${styles.linkButton}`}>https://www.google.com/policies/privacy/</button>
                </Link>
                。
              </p>
              <p>
                (d) 除非Wonder VenturesLimited明确规定或同意，否则Wonder
                VenturesLimited不是您和第三方之间签订的任何合同安排的一方。
              </p>
              <h3>6.超链接</h3>
              <p>
                (a) Wonder App可能包含指向第三方网站或移动应用程序的超链接。
              </p>
              <p>
                (b) Wonder VenturesLimited并不维护或控制Wonder
                App上的任何第三方网站或移动应用程序的超链接，也不对其负责--超链接出现的目的是为了提供信息，仅是为了你的方便。
              </p>
              <p>
                (c)你的访问和使用任何第三方网站或移动应用程序的风险由你的，并受该第三方网站或移动应用程序的条款约束。
              </p>
              <h3>7.第三方捐款</h3>
              <p>
                <em>
                  (a) Wonder
                  App可能包含第三方（包括商家和个人）的材料和其他贡献。
                </em>
              </p>
              <p>(b) 在Wonder App上显示来自第三方的任何材料和其他贡献并不：</p>
              <ul>
                <li>
                  构成Wonder
                  Ventures对这些材料和贡献的认可（包括第三方表达的任何意见）--第三方表达的任何意见是第三方的意见，不一定反映Wonder
                  Ventures Limited的意见；或
                </li>
                <li>
                  宣称或暗示万得利投资有限公司对第三方或其产品或服务的认可、推荐、赞成、调查、核实或监督。
                </li>
              </ul>
              <h3>8.第三方捐款</h3>
              <p>
                (a) Wonder App属于Wonder Ventures
                Limted，您只能将其用于这些条款中规定的目的。
              </p>
              <p>
                (b) Wonder
                App上的所有内容都是由其来源的人负责。这意味着，您，而不是我们，对您上传或以其他方式提供的所有内容完全负责，于Wonder
                App。
              </p>
              <p>
                (c) 您必须只在您安装Wonder App的你的设备上安装来自App
                Store或Google Play的应用程序，而不是覆盖设备的操作系统，例如通过
                &quot;越狱 &quot;或 &quot;ROOT &quot;设备。
              </p>
              <p>
                (d)
                如果你的设备含有任何盗版、黑客、假冒或未经授权的应用程序，您不得安装或启动Wonder
                App。
              </p>
              <p>
                (e) 您不得对Wonder
                App或其功能采取欺诈或恶意行为，包括以任何方式转售、复制、修改、产生不利影响、反向工程或篡改Wonder
                App，或协助其他人做任何这些事情，除非您已被授权这样做。
              </p>
              <p>
                (f) 您不得以任何非法方式或违反与我们的任何协议使用Wonder App。
              </p>
              <p>
                (g) 通过Wonder
                App传输的任何信息或文件都有延迟、丢失、转移、更改、损坏的风险以及与硬件和软件故障有关的其他风险。
              </p>
              <h3>9.在你的设备上开启某些功能</h3>
              <p>
                在您安装Wonder
                App的设备上，我们可能会要求您打开某些功能，如相机功能和定位服务，并与我们分享你的联系人列表。这将使您能够享受Wonder
                App的某些功能。如果您同意开启这些功能，我们可能会在您进行交易时跟踪你的地理定位和IP地址。出于安全原因，Wonder
                App上将不能进行截图和录像。
              </p>
              <h3>10.如果你遭受任何损失</h3>
              <p>
                <strong>
                  对于您因使用或访问Wonder
                  App而遭受的任何损失，或由于以下原因而导致的损失，我们将不承担责任。
                </strong>
              </p>
              <p>
                <strong>
                  (a) 无法使用或访问Wonder App或Wonder
                  App内的任何服务，除非你的，损失是由我们的疏忽、欺诈或故意违约造成的。
                </strong>
              </p>
              <p>
                <strong>(b)你的设备或网络连接的问题。</strong>
              </p>
              <p>
                <strong>
                  (c) 任何其他使用你的密码访问你的设备上的Wonder
                  App的人访问或使用Wonder App。
                </strong>
              </p>
              <p>
                <strong>
                  (d)于第三方网站或移动应用程序提供的任何信息、商品或服务，Wonder
                  App提供了一个超链接。
                </strong>
              </p>
              <p>
                <strong>(e) 任何第三方的欺诈或疏忽行为；或</strong>
              </p>
              <p>
                <strong>(f) 你不遵守这些条款规定的你的义务。</strong>
              </p>
              <p>
                <strong>
                  如果任何法律要求我们对任何你的损失负责，我们将只在重新提供服务的范围内负责于Wonder
                  App或重新提供这些服务的费用。
                </strong>
              </p>
              <h3>11.如果我们遭受任何损失</h3>
              <p>
                对于我们因以下原因而遭受的任何责任和费用，您将对我们进行赔偿，使之免受伤害并为我们辩护。
              </p>
              <p>(a) 使用 Wonder App；或</p>
              <p>(b) 违反这些条款。</p>
              <p>
                由您或任何其他人（如果该人能够使用你的登录凭证）使用Wonder App。
              </p>
              <h3>12.暂停或取消访问</h3>
              <p>我们可以自行决定暂停或取消你的，例如，对Wonder App的访问。</p>
              <p>(a) 保护我们的业务。</p>
              <p>(b) 保护您或我们免受欺诈或其他损失。</p>
              <p>(c) 如果您没有按照这些条款使用 Wonder App；或</p>
              <p>
                (d) 我们有合理的理由相信你的，对Wonder
                App的使用违反了香港或任何其他国家的法律或法规。
              </p>
              <p>
                如果我们确实暂停或取消你的，我们通常会提前通知你，并说明决定的理由。然而，我们不需要给你任何此类通知或理由。
              </p>
              <h3>13.我们拥有所有的知识产权</h3>
              <p>
                (a) 与 &quot;沃德
                &quot;应用程序上的内容（如商标、文本、图形、图像、链接和声音）有关的所有权利（如版权）均由Wonder
                Ventures
                Limited拥有或授权。除非我们另有说明，未经我们事先书面同意，不得复制、修改、下载、分发、出版、重用、转贴、反向工程、反编译或以任何方式使用
                &quot;沃德 &quot;App的内容。
              </p>
              <p>
                (b) &quot;Wonder&quot;、&quot;Wonder App
                &quot;和所有相关标识（&quot;商标&quot;）是Wonder Ventures
                Limited的待定或注册商标，由Wonder Ventures
                Limited许可使用。未经沃德文创有限公司事先书面同意，您不得使用这些商标。
              </p>
              <p>
                (c)
                您授予沃德风险投资有限公司和我们的控制实体一个全球性的、免版税的、永久的、不可撤销的、可分许可的权利和许可，以使用您使用沃德应用程序提交给沃德风险投资有限公司的任何信息或材料，用于我们认为适当的目的，包括但不限于复制、修改、传输、分发和出版，除非受适用法律限制。您保证并将确保您提交给沃德风险投资公司的任何此类信息或材料不侵犯任何第三方的权利。
              </p>
              <p>
                (d) 对于您在使用 &quot;沃德
                &quot;应用程序时提交给我们的任何此类信息或材料，沃德风险投资公司不承担任何保密义务，除非法律要求。
              </p>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-HK' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.center}`}>
            <div className={`${styles.title}`}>條款及細則</div>
            <div className={`${styles.content}`}>
              <h3>1. 奇蹟應用程式</h3>
              <p>
                （a） Wonder App是Wonder
                VenturesLimited為企業主及其員工提供的支付管理應用程式，用於管理日常運營。該應用程式僅適用於香港特別行政區（香港特區）的註冊企業，以訪問我們的付款管理工具。
              </p>
              <p>
                （b）
                萬德創投是香港特區的認證貨幣服務營運商，受香港海關所訂明的法律及規例約束。我們無意向香港特別行政區以外的任何企業或人士提供我們的產品和服務。
              </p>
              <p>
                （c） Wonder App可從 App Store 或 Google Play
                在您擁有的裝置上免費下載，這些裝置執行 Apple 的 iOS 或 Google
                Android 作業系統。
              </p>
              <p>（d） 您需要有效的網路連接才能下載和使用 Wonder 應用程式。</p>
              <p>
                （e） 您負責支付以下各項收取的任何費用：
                你的互聯網或行動服務提供者
                你的使用奇跡應用程式。如果您要出國，您應該檢查是否
                你的服務提供者將向您收取任何額外費用，以使用Wonder應用程式海外。
              </p>
              <p>
                （f） 我們可以使用 你的移動
                電話和/或您向我們註冊的電子郵件位址，以便向您發送簡訊代碼和任何相關信息
                你的使用或訪問神奇寶貝應用程式。
              </p>
              <h3>2. 註冊設備</h3>
              <p>
                （一） 你的一旦您下載了 Wonder
                應用程式，設備就會立即向我們註冊，即使您尚未接受我們的條款和條件，我們尚未批准您申請的產品或服務。
              </p>
              <p>
                （b） 您可以登入奇蹟應用程式 你的設備通過提供
                你的密碼或使用以下機構提供的任何生物識別身份驗證方法
                你的設備提供者（如果在 Wonder
                應用程式上啟動），我們將使用該提供程式進行驗證
                你的身份（以及您提供給我們的任何其他相關信息或檔）。
              </p>
              <p>
                （c） 您可以變更 你的登錄神奇寶貝應用程式時隨時登錄設置或密碼。
              </p>
              <p>
                （d） 如果您變更 你的設備，您可以登錄 你的神奇的應用程式帳戶在
                你的新設備 你的預先存在的登錄憑據。請注意：
                你的註冊帳戶在任何給定時間只能由一台設備訪問（不能同時登錄多個設備）。
              </p>
              <h3>3. 保護 你的設備和神奇應用程式</h3>
              <p>保留 你的裝置和萬興應用程式安全，您必須：</p>
              <p>
                （a） 使用密碼、生物識別認證方法或 你的要訪問的設備提供者
                你的設備;
              </p>
              <p>（b） 確保僅 你的生物識別技術在設備上註冊;</p>
              <p>
                （c） 如果 Wonder App
                可能不是安全或可靠的身份驗證方式，則不得使用面容 ID 訪問
                你的身份（例如，如果您有同卵雙胞胎兄弟姐妹）;
              </p>
              <p>
                （d） 不分享 你的想知道任何人的應用程式密碼。使用的任何其他人
                你的訪問奇跡應用程式的密碼將能夠採取行動
                你的代表，您將對他們所做的任何事情負責;
              </p>
              <p>
                （e） 變更 你的Wonder 應用程式密碼定期，以防止任何未經授權的訪問
                你的設備和神奇應用程式;
              </p>
              <p>
                （f） 保持 你的設備安全，可靠和在
                你的自己的監護權。特別是，不要離開
                你的設備無人值守，而您登錄到奇跡應用程式;和
              </p>
              <p>
                （g）
                刪除萬德應用程式並刪除與萬德應用程式相關的所有相關信息，然後再出售、回收、丟棄或永久贈送
                你的設備離開。{' '}
              </p>
              <p>如果您發現或相信以下情況，您必須告知我們：</p>
              <p>
                （a） 任何其他人知道 你的Wonder App密碼或設法非法訪問
                你的神奇的應用程式;
              </p>
              <p>（二） 你的設備已損壞、丟失或被盜;或</p>
              <p>（c） 未經授權的交易 你的帳戶。</p>
              <p>
                <strong>
                  如果您發現或認為以下情況，您以欺詐行為、重大過失或未能在合理可行的情況下儘快通知我們，您將對所有損失負責：
                  你的Wonder App的密碼或用於訪問 Wonder App
                  的設備已被洩露、丟失或被盜，或者未經授權的交易已被過度使用
                  你的帳戶，包括您未能遵守保護措施和/或滿足 你的本第3條和
                  你的故障造成了損失。
                </strong>
              </p>
              <p>為了提高安全性，我們建議：</p>
              <p>
                （a） 保護
                你的設備從惡意軟體通過警惕可疑的電子郵件和超連結，並僅下載已知或受信任的軟體，設置強密碼並保持操作系統和軟體
                你的設備最新;和
              </p>
              <p>
                （b） 啟用或安裝遠端擦除功能（即能夠將命令發送到
                你的將完全刪除存儲在設備上的所有數據的設備）在
                你的設備，以防丟失或被盜。
              </p>
              <h3>4.奇跡應用程式更新</h3>
              <p>
                （a） 我們可能會不時更新 Wonder
                應用程式，包括進行增強、添加新功能或進行安全更新。
              </p>
              <p>
                （b） 有時我們可能會要求您在繼續使用 Wonder App
                之前對其進行更新。
              </p>
              <p>（c） 你應當：</p>
              <ul>
                <li>
                  保持作業系統
                  你的設備是最新的。在我們停止支持某個版本的操作系統之前，我們會提前通知您，並提醒您更新
                  你的設備的作業系統;和
                </li>
                <li>
                  使 Wonder 應用程式保持最新狀態。否則，Wonder
                  App的某些功能可能無法按預期工作。如果可以，我們建議您在
                  你的設備設置。
                </li>
              </ul>
              <h3>5.奇跡應用程式更新</h3>
              <p>
                （a） 當我們進行必要的維護或系統升級時，Wonder App
                可能會暫時不可用。我們會告知您我們計劃何時這樣做，以及 Wonder
                App 將不可用多長時間（如果有的話）。
              </p>
              <p>
                （b） Wonder App
                的可用性取決於第三方服務提供者的可靠性和可用性，包括軟體、網路和其他服務提供者，這些服務提供者能夠
                你的訪問奇跡應用程式。
              </p>
              <p>
                （c） 如果需要任何第三方服務 你的使用 Wonder
                應用程式，您將遵守適用於該第三方的使用條款。例如，Wonder App
                使用 Google 地圖的功能和內容，以及 你的在 Wonder App 中使用此類
                Google 地圖功能和內容須遵守{' '}
                <Link to={'https://maps.google.com/help/terms_maps.html'}>
                  <button className={`${styles.linkButton}`}>https://maps.google.com/help/terms_maps.html</button>
                </Link>{' '}
                google Maps/Google Earth 附加服務條款（包括{' '}
                <Link to={'https://www.google.com/policies/privacy/'}>
                  <button className={`${styles.linkButton}`}>google</button>
                </Link>{' '}
                https://www.google.com/policies/privacy/ 的隱私政策）。
              </p>
              <p>
                （d）
                除非萬德創投明確規定或同意，否則萬德創投有限公司不是您與第三方之間達成的任何合同安排的一方。
              </p>
              <h3>6. 超連結</h3>
              <p>
                （a） 萬德應用程式可能包含指向第三方網站或行動應用程式的超連結。
              </p>
              <p>
                （b） Wonder VenturesLimited不會維護或控制 Wonder App
                上超連結的任何第三方網站或行動應用程式，也不對它們負責 –
                超連結僅供參考，並用於 你的僅提供便利。
              </p>
              <p>
                （三） 你的訪問和使用任何第三方網站或移動應用程式位於
                你的自行承擔風險，並受該第三方網站或移動應用程式的條款的約束。
              </p>
              <h3>7. 第三方貢獻</h3>
              <p>
                <em>
                  （a） Wonder
                  App可能包含來自第三方（包括商家和個人）的材料和其他貢獻。
                </em>
              </p>
              <p>
                （b） 在 Wonder App 上顯示來自第三方的任何材料和其他貢獻不會：
              </p>
              <ul>
                <li>
                  構成萬德創投對該等材料及貢獻的認可（包括第三方表達的任何意見）–
                  第三方表達的任何意見均為第三方意見，並不一定反映萬德創投有限公司的意見;或
                </li>
                <li>
                  斷言或暗示萬德創投有限公司認可、推薦、偏袒、調查、驗證或監控第三方或其產品或服務。
                </li>
              </ul>
              <h3>8. 第三方貢獻</h3>
              <p>
                （a） Wonder App屬於Wonder Ventures
                Limted，您只能將其用於本條款中規定的目的。
              </p>
              <p>
                （b） Wonder
                App上的所有內容均由其來源方負責。這意味著您（而非我們）對您上傳或以其他方式提供的所有內容負全部責任。
                於奇跡應用程式。
              </p>
              <p>
                （c） 您只能從 App Store 或 Google Play 安裝應用程式
                你的例如，您安裝 Wonder App
                且不會覆蓋裝置作業系統的設備。通過「越獄」或「生根」設備。
              </p>
              <p>
                （d） 在以下情況下，您不得安裝或啟動 Wonder 應用程式：
                你的設備包含任何盜版，駭客攻擊，偽造或未經授權的應用程式。
              </p>
              <p>
                （e） 您不得對 Wonder App
                或其功能採取欺詐或惡意行為，包括以任何方式轉售、複製、修改、不利影響、反向工程或篡改
                Wonder App，或協助任何其他人執行上述任何操作，除非您已獲得授權。
              </p>
              <p>
                （f） 您不得以任何非法方式或違反與我們的任何協定使用 Wonder
                應用程式。
              </p>
              <p>
                （g） 通過 Wonder App
                傳輸的任何資訊或文件都存在延遲、丟失、轉移、更改、損壞以及與硬體和軟體故障相關的其他風險的風險。
              </p>
              <h3>9. 打開某些功能 你的裝置</h3>
              <p>
                在您安裝 Wonder App
                的設備上，我們可能會要求您打開某些功能，例如相機功能和定位服務，並共用
                你的我們的聯繫人清單。這將允許您在奇蹟應用程式上享受某些功能。如果您同意打開這些功能，例如，我們可能會跟蹤
                你的進行交易時的地理位置和IP位址。出於安全原因，Wonder
                App上將無法截取螢幕截圖和錄製視頻。
              </p>
              <h3>10. 如果您遭受任何損失</h3>
              <p>
                <strong>
                  對於您因使用或訪問 Wonder
                  應用程式而遭受的任何損失，或因以下原因造成的任何損失，我們概不負責：
                </strong>
              </p>
              <p>
                <strong>
                  （a）
                  無法使用或訪問萬德應用程式或萬德應用程式內的任何服務，除非
                  你的損失是由我們的疏忽，欺詐或故意違約造成的;
                </strong>
              </p>
              <p>
                <strong>（b） 問題 你的設備或網路連接;</strong>
              </p>
              <p>
                <strong>
                  （c） 使用奇跡應用程式的任何其他人存取或使用 Wonder 應用程式
                  你的訪問神奇應用程式的密碼 你的設備;
                </strong>
              </p>
              <p>
                <strong>
                  （d） 提供的任何資訊、商品或服務
                  於萬德應用程式提供超連結的第三方網站或移動應用程式;
                </strong>
              </p>
              <p>
                <strong>（e） 任何第三方的欺詐或疏忽行為;或</strong>
              </p>
              <p>
                <strong>（f） 您未遵守 你的這些條款下的義務。</strong>
              </p>
              <p>
                <strong>
                  如果任何法律要求我們對以下任何
                  你的損失，我們將只對重新提供服務的範圍內負責 於Wonder
                  App或重新提供這些服務的成本。
                </strong>
              </p>
              <h3>11. 如果我們遭受任何損失</h3>
              <p>
                您將賠償，使我們免受損害，並保護我們免受因以下原因而遭受的任何責任和費用：
              </p>
              <p>（a） 使用萬德應用程式;或</p>
              <p>（b） 違反這些條款，</p>
              <p>
                由您或任何其他人（如果此人能夠使用 Wonder App 使用
                你的登錄憑據）。
              </p>
              <h3>12. 暫停或取消訪問</h3>
              <p>
                我們可能會暫停或取消 你的存取 Wonder
                應用程式由我們自行決定，例如：
              </p>
              <p>（a） 保護我們的業務;</p>
              <p>（b） 保護您或我們免受欺詐或其他損失;</p>
              <p>（c） 如果您未按照這些條款使用萬德應用程式;或</p>
              <p>
                （d） 我們有合理理由相信
                你的使用萬德應用程式違反香港或任何其他國家的法律或法規。
              </p>
              <p>
                如果我們暫停或取消
                你的訪問，我們通常會提前通知您，並說明我們決定的原因。但是，我們不需要給您任何此類通知或理由。
              </p>
              <h3>13. 我們擁有所有智慧財產權</h3>
              <p>
                （a）
                與萬德應用程式上的內容（如商標、文字、圖形、圖像、鏈接和聲音）有關的所有權利（如版權）均歸萬德創投有限公司所有或授權予萬德創投有限公司。除非我們另有說明，否則未經我們事先書面同意，不得以任何方式複製、修改、下載、分發、發佈、重複使用、重新發佈、反向工程、反編譯或以任何方式使用
                Wonder App 的內容。
              </p>
              <p>
                （b） 「Wonder」、「Wonder
                App」及所有相關標誌（「商標」）均為Wonder Ventures
                Limited的未署名或註冊商標，並由Wonder Ventures
                Limited授權使用。未經萬德創投有限公司事先書面同意，您不得使用商標。
              </p>
              <p>
                （c）
                您授予萬德創投有限公司及我們的控制實體全球性的、免版稅的、永久的、不可撤銷的、可再許可的權利和許可，以使用您使用萬德App提交給萬德創投的任何資訊或材料，用於我們認為適當的任何目的，包括但不限於複製、修改、傳輸、分發和發佈，除非受適用法律限制。您保證並將確保您提交給Wonder
                Ventures的任何此類資訊或材料不會侵犯任何第三方的權利。
              </p>
              <p>
                （d）
                除非法律要求，否則萬德創投對您在使用萬德應用程式時提交給我們的任何此類資訊或材料不承擔任何保密義務。
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TermsAndCondition;
