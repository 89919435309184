import { useEffect, useState } from 'react';

interface IGlobalLoadingProps {
  loading: boolean;
}

const GlobalLoading: React.FC<IGlobalLoadingProps> = (props) => {
  const { loading } = props;
  const [lottie, setLottie] = useState<any | null>(null);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const ua = navigator.userAgent.toLowerCase();

    const isChrome = ua.match(/chrome\/(\d+)/);

    const chromeCoreVersion = ua.match(/chrome\/(\d+)/);

    if ((chromeCoreVersion && Number(chromeCoreVersion[1]) > 73) || !isChrome) {
      require('@dotlottie/player-component');
      setLottie(
        <div className="App">
          <dotlottie-player src="/json/loading.json" autoplay loop />
        </div>
      );
    } else {
      setLottie(<img className="w-14" alt="" src={`/images/gif/loading.gif`} />);
    }
  }, []);

  return (
    <>
      <div
        className={`z-50 fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-color-text-1 opacity-50 ${
          loading ? 'block' : 'hidden'
        }`}
      />
      <div
        className={`z-100 fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center ${
          loading ? 'block' : 'hidden'
        }`}
      >
        <div className="w-36 h-36 px-4 flex justify-center items-center rounded-3xl bg-primary-background-color">
          {/* <div id="lottie" /> */}
          {lottie}
        </div>
      </div>
    </>
  );
};

export default GlobalLoading;
