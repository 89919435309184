import type { ReactNode } from 'react';
import type { TabsProps } from './type';
import React, { useState, useEffect } from 'react';
import memoize from 'memoize-one';
import TabContent from './tabs-content';
import TabPane from './tabs-pane';

const getPaneChild = memoize((props: TabsProps) => {
  const { children } = props;

  const paneChildren: ReactNode[] = [];

  React.Children.forEach(children, (child: ReactNode) => {
    paneChildren.push(child);
  });
  return paneChildren as ReactNode[];
});

const Tabs = (props: TabsProps) => {
  const {
    formItemClass,
    activeIndex = 0,
    type = 'button',
    onClickTab,
    contentClass = '',
    tabsNavClass = '',
  } = props;

  const paneChildren = getPaneChild(props);
  const [currentActiveIndex, setActiveIndex] = useState<React.Key>('');

  useEffect(() => {
    setActiveIndex(activeIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  const isActived = (key: React.Key): boolean => {
    return currentActiveIndex === key;
  };

  const getItemTitle = (item: ReactNode) => {
    let title: any = '';
    if (item && typeof item === 'object') {
      const { props = {} } = item as any;
      title = props.title;
    }
    return title;
  };

  const getItemKey = (item: ReactNode, index: React.Key) => {
    let key: React.Key = index;
    if (item && typeof item === 'object') {
      key = (item as any).key;
    }
    return key;
  };

  const clickTabPane = (item: ReactNode, index: React.Key) => {
    onClickTab && onClickTab(getItemKey(item, index));
    setActiveIndex(getItemKey(item, index));
  };

  const domLayout = () => {
    if (type === 'button') {
      return (
        <div className={`sm:block ${formItemClass}`}>
          <div
            className={`flex space-x-4 overflow-x-auto ${tabsNavClass} hidden_scrollbar`}
            aria-label="Tabs"
          >
            {paneChildren.map((item, index) => (
              <div
                key={index}
                className={`
                    h-10 py-2 px-4 rounded-30 text-sm whitespace-nowrap font-medium flex items-center
                    ${
                      isActived(getItemKey(item, index))
                        ? 'bg-primary-color text-color-text-6'
                        : 'bg-color-text-6 text-color-text-2 cursor-pointer hover:text-primary-color border-border-color border'
                    }  
                  `}
                onClick={() => clickTabPane(item, index)}
              >
                {getItemTitle(item)}
              </div>
            ))}
          </div>
          <div aria-label="concis-tabs-content" className={`${contentClass}`}>
            <TabContent
              paneChildren={paneChildren}
              activeIndex={currentActiveIndex}
            />
          </div>
        </div>
      );
    }

    if (type === 'line') {
      return (
        <div className={`sm:block ${formItemClass}`}>
          <div
            className={`flex space-x-4 h-fit overflow-x-auto ${tabsNavClass} hidden_scrollbar`}
            aria-label="line-tabs"
          >
            {paneChildren.map((item, index) => (
              <div
                key={index}
                className={`
                    pb-4 whitespace-nowrap
                    ${
                      isActived(getItemKey(item, index))
                        ? 'text-primary-color border-b border-primary-color'
                        : 'text-color-text-2 cursor-pointer hover:text-primary-color'
                    }
                  `}
                onClick={() => clickTabPane(item, index)}
              >
                {getItemTitle(item)}
              </div>
            ))}
          </div>
          <div className="mb-6 border-b border-border-color" />
          <div aria-label="concis-tabs-content" className={`${contentClass}`}>
            <TabContent
              paneChildren={paneChildren}
              activeIndex={currentActiveIndex}
            />
          </div>
        </div>
      );
    }
  };

  // const domLayout = useMemo(() => {
  //   if (type === 'button') {
  //     return (
  //       <div className={`sm:block ${formItemClass}`}>
  //         <div className="flex space-x-4" aria-label="Tabs">
  //           {paneChildren.map((item, index) => (
  //             <div
  //               key={index}
  //               className={`
  //                   py-2 px-4 rounded-3xl text-sm
  //                   ${
  //                     isActived(getItemKey(item, index))
  //                       ? 'bg-primary-color text-color-text-6'
  //                       : 'bg-color-text-6 text-color-text-3 cursor-pointer hover:text-primary-color border-border-color border'
  //                   }
  //                 `}
  //               onClick={() => setActiveIndex(getItemKey(item, index))}
  //             >
  //               {getItemTitle(item)}
  //             </div>
  //           ))}
  //         </div>
  //         <div aria-label="concis-tabs-content" className="">
  //           <TabContent
  //             paneChildren={paneChildren}
  //             activeIndex={currentActiveIndex}
  //           />
  //         </div>
  //       </div>
  //     );
  //   }

  //   if (type === 'line') {
  //     return (
  //       <div className={`sm:block ${formItemClass}`}>
  //         <div className="flex space-x-4 h-fit" aria-label="line-tabs">
  //           {paneChildren.map((item, index) => (
  //             <div
  //               key={index}
  //               className={`
  //                   pb-4
  //                   ${
  //                     isActived(getItemKey(item, index))
  //                       ? 'text-primary-color border-b border-primary-color'
  //                       : 'text-color-text-3 cursor-pointer hover:text-primary-color'
  //                   }
  //                 `}
  //               onClick={() => setActiveIndex(getItemKey(item, index))}
  //             >
  //               {getItemTitle(item)}
  //             </div>
  //           ))}
  //         </div>
  //         <div className="mb-6 border-b border-border-color" />
  //         <div aria-label="concis-tabs-content" className="">
  //           <TabContent
  //             paneChildren={paneChildren}
  //             activeIndex={currentActiveIndex}
  //           />
  //         </div>
  //       </div>
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentActiveIndex]);

  return <>{domLayout()}</>;
};

Tabs.TabPane = TabPane;
export default Tabs;
