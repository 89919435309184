import React from 'react';
import type { IIconProps } from '@/types/common-types';

const CleanIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 20 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10ZM8.70711 7.29289C8.31658 6.90237 7.68342 6.90237 7.29289 7.29289C6.90237 7.68342 6.90237 8.31658 7.29289 8.70711L8.5859 10.0001L7.29289 11.2931C6.90237 11.6836 6.90237 12.3168 7.29289 12.7073C7.68342 13.0978 8.31658 13.0978 8.70711 12.7073L10.0001 11.4143L11.2931 12.7073C11.6836 13.0978 12.3168 13.0978 12.7073 12.7073C13.0978 12.3168 13.0978 11.6836 12.7073 11.2931L11.4143 10.0001L12.7073 8.70711C13.0978 8.31658 13.0978 7.68342 12.7073 7.29289C12.3168 6.90237 11.6836 6.90237 11.2931 7.29289L10.0001 8.5859L8.70711 7.29289Z"
        />
      </svg>
    </div>
  );
};

export default CleanIcon;
