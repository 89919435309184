import { Alert } from '@/components/common/alert/alert';
import Button from '@/components/common/button';
import Label from '@/components/common/label';
import Textarea from '@/components/common/textarea';
import AntdModal from '@/components/common/antd/modal';
import { payoutStatusUpdate } from '@/data/payouts';
import { IPayout } from '@/types/payouts-type';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IRejectionPayoutProps {
  payout: IPayout;
  businessID: string;
  callback: () => void;
  onCancel: () => void;
}

const RejectionPayout: React.FC<IRejectionPayoutProps> = (props) => {
  const { payout, businessID, callback, onCancel } = props;
  const { t } = useTranslation('payouts');
  const { t: ct } = useTranslation('common');
  const { id } = payout;

  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);

  const handleReject = async () => {
    setLoading(true);
    if (await payoutStatusUpdate(businessID, id, 'Rejected', '', reason)) {
      Alert.success({
        message: t('payout_rejected'),
        position: 'default',
        wrapClass: 'top-px',
      });
      await callback();
    } else {
      Alert.error({
        message: t('payout_reject_failed'),
        position: 'default',
        wrapClass: 'top-px',
      });
    }
    setLoading(false);
  };

  return (
    <AntdModal open={true} footer={null} onCancel={onCancel}>
      <div className="flex flex-col justify-center text-color-text-2 font-medium p-6 pt-10">
        <div className="text-center text-color-text-1 font-bold text-xl">
          {t('rejecton_reason')}
        </div>
        <div className="text-center pt-4 text-color-text-2 font-medium text-sm">
          {t('reject_payout_tip')}
        </div>
        <div className="py-8">
          <Label value={t('input_reason')} />
          <Textarea value={reason} onChange={setReason} allowClear={false} />
        </div>
        <Button
          className="w-full text-base"
          disabled={!reason}
          onClick={handleReject}
          loading={loading}
        >
          {t('confirm')}
        </Button>
        <Button
          styleType={'Border'}
          className="w-full mt-4 text-base"
          onClick={onCancel}
        >
          {ct('cancel')}
        </Button>
      </div>
    </AntdModal>
  );
};

export default RejectionPayout;
