import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import PaymentOverview from './gateway-overview';
import PaymentMethods from './payment-methods';
import SummaryPaymentMethods from './summary-payment-methods';
import TransactionTicket from './transaction-ticket';
import TransactionTypes from './transaction-types';
import { IPaymentGatewayReportsRecord } from '@/types/analytics-type';

interface IGatewayHistoryProps {
  records: IPaymentGatewayReportsRecord[];
  currency: string;
  loading: boolean;
  startDate: string;
  endDate: string;
  businessID: string;
  openExportModal: () => void;
}

const GatewayHistory: React.FC<IGatewayHistoryProps> = (props) => {
  const {
    records = [],
    currency,
    startDate,
    endDate,
    loading,
    businessID,
    openExportModal,
  } = props;

  const baseProps = {
    loading,
    startDate,
    endDate,
    currency,
    businessID,
    openExportModal,
  };

  return (
    <Transition.Root show={true} as={Fragment} appear={true}>
      <div className="grid grid-cols-1 gap-6 mt-6 md:grid-cols-2">
        <PaymentOverview {...baseProps} data={records[0]} />

        <TransactionTypes {...baseProps} data={records[1]} />

        <PaymentMethods {...baseProps} data={records[2]} />

        <SummaryPaymentMethods {...baseProps} data={records[3]} />

        <TransactionTicket {...baseProps} data={records[4]} />
      </div>
    </Transition.Root>
  );
};

export default GatewayHistory;
