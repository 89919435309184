import WarningIcon from '@/components/svg/warning-icon';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import UnsuccessfulIcon from '@/components/svg/failed-icon';
import { IPayee, IPayout } from '@/types/payouts-type';
import { useTranslation } from 'react-i18next';
import { getCookie } from '@/utils';
import api from '@/libs/api';
import { Constants } from '@/constants';
import Config from '@/libs/config';
import { Alert } from '@/components/common/alert/alert';
import AntdModal from '@/components/common/antd/modal';
import { handleMount } from '@/utils/payouts-utils';
import Skeleton from '@/components/skeleton';
import Button from '@/components/common/button';
import { useNavigate, useParams } from 'react-router-dom';

interface IApprovePayoutPayee {
  payout: IPayout;
  payee: IPayee;
  businessID: string;
  activekey: string;
  handleConfirm: (fxQuoteID?: string) => void;
}

let timer: any = '';

const ExchangeRateMoadl: React.FC<IApprovePayoutPayee> = (props) => {
  const { payout, businessID, payee, handleConfirm, activekey } = props;
  const { lang = 'en' } = useParams();
  const { t } = useTranslation('payouts');
  const { t: ct } = useTranslation('common');
  const {
    destination_amount,
    destination_currency,
    calculated_fee,
    fx_sell_currency: currency,
    total_amount,
    fx_exchange_rate,
    fx_quote_id,
  } = payout;

  const [fee, setFee] = useState(calculated_fee);
  const [total, setTotal] = useState(total_amount);
  const [rate, setRate] = useState(fx_exchange_rate);
  const [fxQuoteID, setFxQuoteID] = useState(fx_quote_id);
  const [time, setTime] = useState(30);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    clearInterval(timer);
    if (show) {
      handleFxQuote();
    }
    // eslint-disable-next-line
  }, [show]);

  const timerStart = () => {
    const now = dayjs();
    timer = setInterval(() => handleFxQuoteAgain(now), 1000);
  };

  const handleFxQuoteAgain = (startTime: dayjs.Dayjs) => {
    const intervalTime = Math.floor(dayjs().diff(startTime) / 1000);
    const newTime = 30 - intervalTime;
    setTime(newTime);
    if (newTime < 0) {
      setLoading(true);
      handleFxQuote();
    }
  };

  const handleFxQuote = async () => {
    setLoading(true);
    clearInterval(timer);
    setTime(30);
    const token = getCookie(Constants.TOKEN) || '';
    const resp = await api.post(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/payout_requests/fx_quote`,
      {
        buy_amount: destination_amount,
        buy_currency: destination_currency,
        dealt_side: 'Buy',
        sell_amount: 0,
        payee_id: payee.id,
        sell_currency: currency,
      },
      {
        headers: {
          'x-p-business-id': businessID,
          'x-client-id': Config.clientID,
          'x-platform-id': Config.platformId,
          'X-USER-ACCESS-TOKEN': token,
          'x-i18n-lang': 'en-US',
          'Content-Type': 'application/json',
        },
      }
    );
    const { data } = resp || {};
    const { sell_amount, fee_amount, error_code, exchange_rate, fx_quote_id } =
      data || {};
    if (!error_code) {
      if (fee_amount) {
        setFee(fee_amount);
      }
      setTotal(sell_amount);
      setRate(exchange_rate);
      setFxQuoteID(fx_quote_id || '');
    }
    setLoading(false);
    timerStart();
  };

  const handleCancel = () => {
    clearInterval(timer);
    Alert.success({
      message: t('payout_cancelled'),
      position: 'default',
      wrapClass: 'top-px',
    });
    navigate(`/${lang}/businesses/${businessID}/payouts/${activekey}`);
  };

  const handleBack = () => {
    setShow(true);
  };

  const handleConfirmPayouts = async () => {
    clearInterval(timer);
    handleConfirm(fxQuoteID);
  };

  const handleCancelPayouts = () => {
    setShow(false);
  };

  const getTips = () => {
    const script = t('exchange_rate_tips');
    const text = script.split('{{time}}');
    return (
      <div className="text-center text-sm font-medium leading-snug my-6 whitespace-pre-line">
        {text[0]}
        {
          <label className="font-bold text-primary-color text-base">
            {time}
            {t('s')}
          </label>
        }
        {text.length > 1 ? text[1] : ''}
      </div>
    );
  };

  return (
    <>
      <AntdModal open={show} footer={null} onCancel={handleCancelPayouts}>
        <div className="flex flex-col items-center justify-center text-color-text-2 font-medium p-10">
          <div className="p-[18px] rounded-full bg-primary-color/10">
            <WarningIcon className={`text-primary-color text-3xl`} size={36} />
          </div>
          {getTips()}
          <div className="border border-primary-color rounded-[10px] px-4 w-full divide-y mb-6">
            <div className="flex items-center justify-between py-4">
              <div className="text-color-text-2">
                {t('transfer_amount')}
              </div>
              <div className="text-color-text-1">{`${handleMount(
                destination_amount,
                2
              )} ${destination_currency}`}</div>
            </div>
            {currency !== destination_currency && (
              <div className="flex items-center justify-between py-4">
                <div className="text-color-text-2">{t('rate')}</div>
                {loading && (
                  <div>
                    <Skeleton
                      row={1}
                      width={[115]}
                      skeletonItemClass={`h-5 rounded-2xl mb-0`}
                    />
                  </div>
                )}
                {!loading && (
                  <div className="text-color-text-1">
                    {rate} {currency}-{destination_currency}
                  </div>
                )}
              </div>
            )}
            <div className="flex items-center justify-between py-4">
              <div className="text-color-text-2">
                {t('fees_charged')}
              </div>
              {loading && (
                <div>
                  <Skeleton
                    row={1}
                    width={[115]}
                    skeletonItemClass={`h-5 rounded-2xl mb-0`}
                  />
                </div>
              )}
              {!loading && (
                <div className="text-color-text-1">{`${handleMount(
                  fee,
                  2
                )} ${currency}`}</div>
              )}
            </div>
            <div className="flex items-center justify-between py-4">
              <div className="text-color-text-2">
                {t('billing_amount')}
              </div>
              {loading && (
                <div>
                  <Skeleton
                    row={1}
                    width={[115]}
                    skeletonItemClass={`h-5 rounded-2xl mb-0`}
                  />
                </div>
              )}
              {!loading && (
                <div className="text-color-text-1">{`${handleMount(
                  total,
                  2
                )} ${currency}`}</div>
              )}
            </div>
          </div>
          <Button className="w-full" onClick={handleConfirmPayouts}>
            {t('confirm_payout')}
          </Button>
          <Button
            className="w-full mt-4"
            onClick={handleCancelPayouts}
            styleType="Border"
          >
            {t('cancel_payout')}
          </Button>
        </div>
      </AntdModal>
      <AntdModal open={!show} footer={null} onCancel={handleBack}>
        <div className="p-10 pt-8 flex flex-col items-center justify-center">
          <UnsuccessfulIcon className={`text-error-color text-3xl`} size={80} />
          <div className="mt-4 mb-6 text-center">
            <div className="font-semibold text-lg leading-6 text-color-text-1 mb-2">
              {t('cancel_the_payout')}
            </div>
            <div className="font-medium text-color-text-1 text-sm">
              {t('cancel_payout_info')}
            </div>
            <div className="font-medium text-color-text-1 text-sm">
              {t('action_irreversible')}
            </div>
          </div>
          <Button
            className="w-full mb-4"
            styleType="Danger"
            onClick={handleCancel}
          >
            {t('cancel_payout')}
          </Button>
          <Button className="w-full" onClick={handleBack} styleType="Border">
            {ct('back')}
          </Button>
        </div>
      </AntdModal>
    </>
  );
};

export default ExchangeRateMoadl;
