import { ILanguageKeys } from "@/types/language-type";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import EnIcon from "@/components/svg/en-icon";
import ZhCNIcon from "@/components/svg/zh-cn-icon";
import ZhHKIcon from "@/components/svg/zh-hk-icon";
import PersonalSpaceLayout from "../components/layout";
import i18n, { supportedLngs } from "@/i18n";
import langs from "@/i18n/langs";


const LanguagePanel = () => {
  const { t } = useTranslation('personal_space');
  const navigate = useNavigate();
  const { lang = 'en',} = useParams();

  const langObj: any = {
    [ILanguageKeys.EN]: 'English',
    [ILanguageKeys.ZH_CN]: '简体中文（中国）',
    [ILanguageKeys.ZH_HK]: '繁體中文（中國香港特別行政區）',
  };

  const [filterLang, setFilterLang] = useState<ILanguageKeys[]>([]);

  useEffect(() => {
    const filterLang = Object.values(ILanguageKeys).filter(
      (item) => item !== lang
    );
    setFilterLang(filterLang);
    // eslint-disable-next-line
  }, [lang]);

  const langNode = useCallback(
    (key: string) => {
      let node = <EnIcon />;

      if (key === 'zh-CN') {
        node = <ZhCNIcon />;
      } else if (key === 'zh-HK') {
        node = <ZhHKIcon />;
      }

      return (
        <div className="flex items-center mr-2">
          {node}{' '}
          <span className="text-color-text-1 text-sm font-medium ml-2">
            {langObj[key] || ''}
          </span>
        </div>
      );
    },
    // eslint-disable-next-line
    [lang]
  );

  const handleChange = (lng: string) => {
    if (!supportedLngs.includes(lng)) {
      return;
    }

    let pathname = window.location.pathname;
    const pathnameSplit = pathname.split('/');
    let lang = '';
    if (pathnameSplit.length > 1 && pathnameSplit[1]) {
      lang = pathnameSplit[1];
      lang = lang.replaceAll('_', '-');
    }

    if (lang === lng) {
      return;
    }

    if (lang && langs.includes(lang.toLocaleLowerCase())) {
      pathname = pathname.replace(`/${lang}`, '');
    }

    if (pathname.startsWith('/')) {
      pathname = `/${lng}${pathname}`;
    } else {
      pathname = `/${lng}/${pathname}`;
    }
    i18n.changeLanguage(lng);
    navigate(pathname);
  };

  return (
    <PersonalSpaceLayout activeIndex={2}>
      <h2 className="text-2xl font-bold text-color-text-1 mb-5">
        {t('personal_space:language')}
      </h2>
      <div className="mt-8 mb-4 text-sm font-medium text-color-text-2">
        {t('personal_space:current_language')}
      </div>
      <div className="px-5 py-4 flex items-center justify-between border border-primary-color rounded">
        {langNode(lang)}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <rect width="24" height="24" rx="12" fill="#0094FF" />
          <path
            d="M10.8714 16.6439C10.6371 16.8782 10.2572 16.8782 10.0229 16.6439L5.75044 12.3714C5.51612 12.1371 5.51612 11.7572 5.75044 11.5229L7.02291 10.2504C7.25722 10.0161 7.63712 10.0161 7.87144 10.2504L10.0229 12.4019C10.2572 12.6362 10.6371 12.6362 10.8714 12.4019L16.0229 7.25044C16.2572 7.01612 16.6371 7.01612 16.8714 7.25044L18.1439 8.52291C18.3782 8.75722 18.3782 9.13712 18.1439 9.37144L10.8714 16.6439Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="mt-8 text-sm font-medium text-color-text-2">
        {t('personal_space:optional_languages')}
      </div>
      {filterLang.map((key) => (
        <div
          key={key}
          onClick={() => handleChange(key)}
          className="mt-4 px-5 py-4 flex items-center justify-between border border-border-color rounded cursor-pointer hover:border-primary-color"
        >
          {langNode(key)}
        </div>
      ))}
    </PersonalSpaceLayout>
  );
};

export default LanguagePanel;
