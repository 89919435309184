import { useEffect, useState } from 'react';
import Button from '@/components/common/button';
import Input from '@/components/common/input/input';
import Label from '@/components/common/label';
import ModalEmpty from '@/components/common/modal/modal-empty';
import { sendReportEmail } from '@/data/account-balance';
import dayjs from 'dayjs';
import SuccessfulIcon from '@/components/svg/successful-icon';
import ErrorIcon from '@/components/svg/error-icon';
import { handleExport } from '@/utils/gateway-utils';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DateRange from '@/components/common/antd/date-range';
import type { RangePickerProps } from 'antd/es/date-picker';
import { IObject } from '@/types/common-types';
import { IReducersState } from '@/reducers';

interface ISendEmailComponent {
  businessId: string;
  title: string;
  subtitle: string;
  visible: boolean;
  onCancel?: () => void;
  hiddenDate?: boolean;
  templateType?: string;
  batchId?: string;
}

const SendEmailComponent = (props: ISendEmailComponent) => {
  const {
    visible,
    onCancel,
    title = '',
    subtitle = '',
    businessId,
    hiddenDate = false,
    // templateType,
    batchId,
  } = props;

  const { t } = useTranslation("account_balance");
  const defaultDateRange: [dayjs.Dayjs, dayjs.Dayjs] = [
    dayjs().subtract(7, 'day'),
    dayjs(),
  ];
  const [value, setValue] = useState<string>('');
  const [modalTitle, setTitle] = useState<string>(title);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [sendSuccess, setSendSuccess] = useState<boolean>(false);
  const [sendEmailLoading, setEmailLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState('');
  const [rangeDate, setRangeDate] =
    useState<[dayjs.Dayjs, dayjs.Dayjs]>(defaultDateRange);
  const [errMsg, setErrMsg] = useState<string>('');
  const business = useSelector((state: IReducersState) => state.businessState.business);
  const { default_currency = 'HKD' } = business || {};

  useEffect(() => {
    setValue('');
    setTitle(title);
    setShowResult(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const emailVerify = (data: string) => {
    if (data) {
      const reg =
        /[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
      return reg.test(data);
    }
    return false;
  };

  const sendEmail = async () => {
    if (value) {
      let startTime = '';
      let endTime = '';
      if (!hiddenDate) {
        startTime = dayjs(rangeDate[0]).format('YYYY-MM-DD');
        endTime = dayjs(rangeDate[1]).format('YYYY-MM-DD');
      }

      let obj: any = {
        p_business_id: businessId,
        batchID: batchId,
        type: 'pdf',
      };

      if (startTime && endTime) {
        obj = {
          p_business_id: businessId,
          startTime,
          templateType: 'bas',
          currency: default_currency,
          endTime,
          type: 'pdf',
        };
      }

      setEmailLoading(true);

      const resp: IObject = await handleExport(obj);
      const { url } = resp
      if (url) {
        const data: IObject = await sendReportEmail({
          businessId: businessId,
          email: value,
          url,
          title: `Daily Merchant Notice ${business.business_name}`,
        });
        let { code, message } = data

        if (code === 200) {
          setShowResult(true);
          setSendSuccess(true);
          setTitle('');
        } else {
          setShowResult(true);
          setSendSuccess(false);
          setTitle('');
          setErrMsg(message);
        }
      }

      setEmailLoading(false);
    }
  };

  const handleEmailChange = (value: string) => {
    if (emailVerify(value)) {
      setEmailError('');
    } else {
      setEmailError(t('account_balance:invalid_email_address'));
    }
    setValue(value);
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel();
      setEmailError('');
    }
  };

  return (
    <>
      <ModalEmpty
        visible={visible}
        title={modalTitle}
        onCancel={handleOnCancel}
      >
        {!showResult && (
          <>
            <div className="flex text-sm mt-2 text-color-text-2">
              {subtitle}
            </div>
            {!hiddenDate && (
              <div className={`w-full mt-8`}>
                <Label
                  value={t('account_balance:date_range')}
                  required={true}
                />
                <DateRange
                  format="MMM D, YYYY"
                  className="w-full"
                  disabledDate={disabledDate}
                  allowClear={false}
                  size={'large'}
                  value={rangeDate}
                  onChange={(dateArray) => {
                    setRangeDate(dateArray);
                  }}
                />
              </div>
            )}
            <div className={`w-full my-8`}>
              <Label
                value={t('account_balance:email_address')}
                required={true}
              />
              <Input onChange={handleEmailChange} value={value} />
              {emailError && (
                <div className="mt-2 text-sm text-error-color">
                  {emailError}
                </div>
              )}
            </div>
            <Button
              className={`w-full`}
              styleType="Primary"
              disabled={!emailVerify(value)}
              onClick={() => sendEmail()}
              loading={sendEmailLoading}
            >
              {t('account_balance:send_to', { data: value })}
            </Button>
          </>
        )}
        {showResult && (
          <>
            <div className="justify-center flex">
              {sendSuccess && (
                <div className="p-6 rounded-full bg-success-color/10 w-20 h-20">
                  <SuccessfulIcon
                    className={`text-success-color text-3xl`}
                    size={32}
                  />
                </div>
              )}
              {!sendSuccess && (
                <div className="p-6 rounded-full bg-error-color/10 w-20 h-20">
                  <ErrorIcon
                    className={`text-error-color text-3xl`}
                    size={32}
                  />
                </div>
              )}
            </div>
            <div className=" text-xl font-bold text-color-text-1 mt-4 text-center">
              {sendSuccess && 'Email Sent Successfully'}
              {!sendSuccess && 'Unable to Send Email'}
            </div>
            <div className="text-sm text-color-text-2 mt-4 text-center">
              {`To ${value}`}
            </div>
            {!sendSuccess && (
              <div className="text-xs text-color-text-4 mt-4 text-center">
                {errMsg}
              </div>
            )}
            <Button
              className={`w-full mt-6`}
              styleType="Primary"
              onClick={handleOnCancel}
            >
              {`${sendSuccess ? t('common:done') : t('common:back')}`}
            </Button>
          </>
        )}
      </ModalEmpty>
    </>
  );
};
export default SendEmailComponent;
