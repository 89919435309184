import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { IFileObject, IObject } from '@/types/common-types';
import { IAttachmentFile, INewPayeeFormData, IPayee, IPayout, IPayeeData } from '@/types/payouts-type';
import Config from '@/libs/config';
import FpsDetailsIcon from '@/pages/payouts/payouts-icons/fps-transfer-details-icon';
import PlatformTransferDetailsIcon from '@/pages/payouts/payouts-icons/platformtransfer-details-icon';
import SwiftDetailsIcon from '@/pages/payouts/payouts-icons/swift-details-icon';
import RtgsDetailsIcon from '@/pages/payouts/payouts-icons/rtgs-details-icon';
import AlipayDetailsIcon from '@/pages/payouts/payouts-icons/alipay-details-icon';
import { currencies } from './currencies';

export const getState = (data: any, lang: string) => {
  const { schedule_status = '', transfer_status = '', i18n } = data;
  const scheduleStatus = schedule_status || '';
  const transferStatus = transfer_status || '';
  const schedule = scheduleStatus.toLocaleLowerCase();
  const transfer = transferStatus.toLocaleLowerCase();
  if (schedule === 'pending approval' && transfer === 'initiated') {
    return (
      <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-warning-color bg-warning-color/10 uppercase">
        {getValueMultilingual('Pending Approval', i18n, lang)}
      </span>
    );
  }
  if (
    (schedule === 'approved' && transfer === 'initiated') ||
    (schedule === 'pending verification' && transfer === 'suspended')
  ) {
    return (
      <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-warning-color bg-warning-color/10 bg-opacity-10 uppercase">
        {getValueMultilingual('Pending for Transfer', i18n, lang)}
      </span>
    );
  }
  if (schedule === 'approved' && transfer === 'processing transfer') {
    return (
      <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-primary-color bg-primary-color/10 uppercase">
        {getValueMultilingual('Processing Transfer', i18n, lang)}
      </span>
    );
  }
  if (schedule === 'approved' && transfer === 'settled') {
    return (
      <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#4CD964] bg-[#4CD964]/10 uppercase">
        {getValueMultilingual('Settled', i18n, lang)}
      </span>
    );
  }
  if (schedule === 'rejected' && transfer === 'reversed') {
    return (
      <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#828282] bg-[#828282]/10 uppercase">
        {getValueMultilingual('Rejected', i18n, lang)}
      </span>
    );
  }
  if (schedule === 'cancelled' && transfer === 'reversed') {
    return (
      <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#8E8E93] bg-[#8E8E93]/10 uppercase">
        {getValueMultilingual('Reversed', i18n, lang)}
      </span>
    );
  }
  if (transfer === 'refunded') {
    return (
      <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#F43F5E] bg-[#F43F5E]/10 uppercase">
        {getValueMultilingual('Refunded', i18n, lang)}
      </span>
    );
  }

  return (
    <span className="px-3 py-1 rounded-3xl text-xs font-medium whitespace-nowrap text-[#8E8E93] bg-[#8E8E93]/10 uppercase">
      {getValueMultilingual(transfer_status, i18n, lang)}
    </span>
  );
};

export const getValueMultilingual = (
  value: string,
  i18n: IObject,
  lang: string
) => {
  if (i18n && i18n[value]) {
    if (i18n[value][lang]) {
      return i18n[value][lang];
    }
  }
  return value;
};

export const handleMount = (value: any, points = 2, minus = false) => {
  let returnValue = '0';
  const temp = new BigNumber(value);
  if (!temp.isNaN()) {
    returnValue = temp.toFormat(points, 1);
    if (minus) {
      returnValue = `-${returnValue}`;
    }
  }
  return returnValue;
};

export const initFiles = (allFiles: IAttachmentFile[], type: string) => {
  const files: IFileObject[] = [];
  if (Array.isArray(allFiles)) {
    allFiles.forEach((item) => {
      if (item.out_type === type && item.id) {
        files.push({
          id: item.id,
          directusID: item.payout_request,
          fileUrl: `${Config.urls.gatewayUrl}/api/directus/treasury-services/files/${item.id}`,
          type: item.type,
          title: item.title,
          filenameDownload: item.filename_download,
        });
      }
    });
  }
  return files;
};

export const getLicense = (
  permissions: string[],
  payout: any,
  userID: string
) => {
  const {
    schedule_status = '',
    transfer_status = '',
    estimated_settled_at,
    created_by,
  } = payout || {};
  const scheduleStatus = schedule_status || '';
  const transferStatus = transfer_status || '';
  const schedule = scheduleStatus.toLocaleLowerCase();
  const transfer = transferStatus.toLocaleLowerCase();
  let canApprove = false;
  let canReject = false;
  let canCancel = false;
  let canEdit = false;
  let canExport = false;
  if (
    (schedule === 'approved' && transfer === 'initiated') ||
    (schedule === 'pending verification' && transfer === 'suspended')
  ) {
    if (dayjs(estimated_settled_at).isAfter(dayjs(), 'D')) {
      if (
        permissions.includes('payouts:approve_and_reject') ||
        created_by === userID
      ) {
        canEdit = true;
        canCancel = true;
      }
    }
  }
  if (schedule === 'pending approval' && transfer === 'initiated') {
    if (permissions.includes('payouts:approve_and_reject')) {
      canApprove = true;
      canReject = true;
      canCancel = true;
    }
    if (created_by === userID) {
      canCancel = true;
    }
    if (permissions.includes('payouts:update')) {
      canEdit = true;
    }
  }

  if (schedule === 'approved' && transfer === 'settled') {
    canExport = true;
  }

  return {
    canApprove,
    canReject,
    canCancel,
    canEdit,
    canExport,
  };
};

export const getInitSupportingDocuments = (payout: IPayout) => {
  const { reference, po_number, remark, attachment_files } = payout || {};
  return {
    reference: reference || '',
    poNumber: po_number || '',
    remark: remark || '',
    poFiles: initFiles(attachment_files, 'Purchase Order'),
    invoiceFiles: initFiles(attachment_files, 'Invoice'),
  };
};

export const getCountryByCurrency = (currency: string) => {
  let countryCode = 'hk';
  for (let i = 0; i < currencies.length; i++) {
    const item = currencies[i];
    if (item.currency_code === currency) {
      countryCode = item.alpha_2;
      break;
    }
  }

  return countryCode.toLocaleLowerCase();
};

export const prasePayout = (data: any) => {
  const { payee } = data;
  const payees = parsePayees([payee]);
  const payout: IPayout = {
    ...data,
  };

  if (payees.length > 0) {
    payout.payee = payees[0];
  }

  return payout;
};

export const parsePayees = (data: any) => {
  const payees: IPayee[] = [];
  if (Array.isArray(data)) {
    data.forEach((item) => {
      payees.push({
        id: item.id,
        type: item.type,
        email: item.email,
        phone_country_code: item.phone_country_code,
        phone_number: item.phone_number,
        nickname: item.nickname,
        p_business_id: item.p_business_id,
        company_name: item.company_name,
        payout_category_id: item.payout_category_id,
        categories: item.categories,
        payout_accounts: item.payout_accounts,
        i18n: item.i18n,
        is_default_bank: item.is_default_bank,
      });
    });
  }
  return payees;
};

export const colorRgb = (color: string, opacity: number) => {
  const sColor = color.toLowerCase();
  const sColorChange = [];
  for (let i = 1; i < 7; i += 2) {
    sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)));
  }
  return `rgba(${sColorChange.join(',')}, ${opacity})`;
};

export const getTransferMethod = (transferMethod: string) => {
  let icon: any = '';
  if (transferMethod.indexOf('FPS') !== -1) {
    icon = <FpsDetailsIcon size={24} />;
  } else if (transferMethod === 'Platform Transfer') {
    icon = <PlatformTransferDetailsIcon size={24} />;
  } else if (transferMethod === 'SWIFT') {
    icon = <SwiftDetailsIcon size={24} />;
  } else if (transferMethod === 'RTGS') {
    icon = <RtgsDetailsIcon size={24} />;
  } else if (transferMethod.indexOf('Alipay') !== -1) {
    icon = <AlipayDetailsIcon size={24} />;
  }
  return (
    <div className="flex items-center">
      {icon}
      <div className="ml-2">{transferMethod}</div>
    </div>
  );
};

export const getIdentifierValue = (
  identifier: 'identifier_1_type' | 'identifier_2_type' | 'identifier_3_type',
  payee: any
) => {
  const { payout_accounts } = payee || {};
  const { banks, transfer_methods } = payout_accounts || {};
  const { bank_code, bank_name, swift_code } = banks || {};
  const type = transfer_methods[identifier];

  if (type === 'SWIFT') {
    return swift_code;
  }
  if (type === 'Bank') {
    return `${bank_code ? `${bank_code} ` : ''}${bank_name}`;
  }
  if (type === 'Phone Number') {
    return payout_accounts[identifier.replace('type', 'value')].replace(
      '-',
      ' '
    );
  }

  return payout_accounts[identifier.replace('type', 'value')];
};

export const showTransferMethod = (payee: any) => {
  const { payout_accounts = {} } = payee || {};
  const { transfer_methods = {}, transfer_method = '' } = payout_accounts;
  let transferMethod = transfer_method;
  if (transfer_method.indexOf('FPS') > -1) {
    transferMethod = 'FPS';
  }
  if (transfer_method.indexOf('Alipay') > -1) {
    transferMethod = 'Alipay';
  }
  for (let i = 1; i < 4; i++) {
    const value = suffixParse(
      transfer_methods[`identifier_${i}_type`],
      payout_accounts[`identifier_${i}_value`]
    );
    if (value) {
      transferMethod = `${transferMethod} ${value}`;
      break;
    }
  }

  return transferMethod;
};

const suffixParse = (type = '', value = '') => {
  let result = '';
  if (type === 'Email') {
    const values = value.split('@');
    const useName = values[0];
    const domain = values[1] || '';
    if (useName.length > 8) {
      result = `${useName.substring(0, 4)}**${useName.substring(
        useName.length - 4
      )}@${domain}`;
    } else {
      result = value;
    }
  }
  if (type === 'Platform Business ID') {
    result = `${value.substring(0, 4)}***${value.substring(value.length - 4)}`;
  }
  if (['Phone Number', 'Number', 'Account Number'].includes(type)) {
    result = `**${value.substring(value.length - 4)}`;
  }
  return result;
};

export const initPayout: IPayout = {
  id: '',
  approved_at: '',
  attachment_files: [],
  calculated_fee: '',
  cancelled_at: '',
  currency: '',
  destination_amount: '',
  destination_currency: '',
  display_name: '',
  estimated_settled_at: '',
  fx_buy_currency: '',
  fx_exchange_rate: '',
  fx_quote_id: '',
  fx_sell_currency: '',
  total_amount: '',
  amount: '',
  payee_id: '',
  payee_name: '',
  payout_category_color: '',
  payout_category_icon: '',
  payout_category_id: '',
  payout_category_name: '',
  po_number: '',
  ref_id: '',
  reference: '',
  remark: '',
  reversed_at: '',
  schedule_payout_at: '',
  schedule_status: '',
  settled_at: '',
  transfer_method: '',
  transfer_status: '',
  transfer_type: '',
  updated_by: '',
  vendor_reference_id: '',
  p_created_at: '',
  transfer_purpose: '',
  transfer_purpose_id: '',
  i18n: {},
  payee: undefined,
};

export const getPayoutsI18nValue = (
  i18n: IObject,
  key: string,
  lang: string
) => {
  const value = key;
  if (i18n[value]) {
    if (i18n[value][lang]) {
      return i18n[value][lang];
    }
  }
  return value;
};

export const initNewPayeeData: INewPayeeFormData = {
  company_name: '',
  email: '',
  nickname: '',
  billing_address_1: '',
  billing_address_2: '',
  city: '',
  country: 'HK',
  currency: 'HKD',
  identifier_1_value: '',
  identifier_2_value: '',
  identifier_3_value: '',
  state: '',
  transfer_method: '',
  zip_code: '',
  payout_category_id: undefined,
  phone: '',
  type: '',
  bank: {
    id: '',
    country: '',
    bank_code: '',
    bank_name: '',
    swift_code: '',
  },
};

export const initPayeeData: IPayeeData = {
  type: '',
  company_name: '',
  nickname: '',
  email: '',
  city: '',
  billing_address_1: '',
  billing_address_2: '',
  state: '',
  zip_code: '',
  phone: [],
}

export const initPayee: IPayee = {
  id: '',
  company_name: '',
  email: '',
  nickname: '',
  p_business_id: '',
  phone_country_code: '',
  phone_number: '',
  type: '',
  payout_category_id: '',
  i18n: {},
  categories: {
    id: '',
    category_name: '',
    color: '',
    icon_file: '',
  },
  payout_accounts: {
    banks: {
      id: '',
      bank_code: '',
      bank_name: '',
      country: '',
      swift_code: '',
    },
    country: '',
    currency: '',
    billing_address_1: '',
    billing_address_2: '',
    country_codes: {
      alpha_2: '',
      alpha_3: '',
      calling_code: '',
      country_code: '',
      country_name: '',
      default_currency: '',
    },
    business_legal_name: '',
    business_name: '',
    transfer_method: '',
    transfer_methods: {
      identifier_1_name: '',
      identifier_1_type: '',
      identifier_2_name: '',
      identifier_2_type: '',
      identifier_3_name: '',
      identifier_3_type: '',
    },
    identifier_1_value: '',
    identifier_2_value: '',
    identifier_3_value: '',
    city: '',
    state: '',
    zip_code: ''
  },
}
