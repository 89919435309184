import React from 'react';
import type { IIconProps } from '@/types/common-types';

const EyeIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.9987 11.6693C10.9192 11.6693 11.6654 10.9231 11.6654 10.0026C11.6654 9.08213 10.9192 8.33594 9.9987 8.33594C9.07822 8.33594 8.33203 9.08213 8.33203 10.0026C8.33203 10.9231 9.07822 11.6693 9.9987 11.6693Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3346 9.9974C16.1121 13.8866 13.3346 15.8307 10.0013 15.8307C6.66797 15.8307 3.89047 13.8866 1.66797 9.9974C3.89047 6.10823 6.66797 4.16406 10.0013 4.16406C13.3346 4.16406 16.1121 6.10823 18.3346 9.9974Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default EyeIcon;
