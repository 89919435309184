import React from 'react';
import type { IIconProps } from '@/types/common-types';

const UnselectedIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="26"
        viewBox="0 0 25 26"
        fill="none"
      >
        <circle
          cx="12.5"
          cy="13.1667"
          r="11.75"
          stroke="#0094FF"
          strokeOpacity="0.2"
          strokeWidth="1.5"
        />
      </svg>
    </div>
  );
};

export default UnselectedIcon;
