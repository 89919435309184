
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';
import { useTranslation } from 'react-i18next';
import { handleExport } from '@/utils/gateway-utils';
import { Alert } from '@/components/common/alert/alert';
import ModalEmpty from '@/components/common/modal/modal-empty';
import Label from '@/components/common/label';
import Select from '@/components/common/select';
import DateRange from '@/components/common/antd/date-range';
import Button from '@/components/common/button';

interface IExportModalProps {
  visible: boolean;
  startDate: string;
  endDate: string;
  businessID: string;
  valueKey: string;
  canChange: boolean;
  onCancel: () => void;
}

const ExportModal: React.FC<IExportModalProps> = (props) => {
  const {
    visible,
    startDate,
    endDate,
    businessID,
    valueKey,
    canChange,
    onCancel,
  } = props;

    const { t } = useTranslation('analytics');

  const [selectType, setSelectType] = useState<string>('');
  const [selectStartDate, setSelectStartDate] = useState<string>('');
  const [selectEndDate, setSelectEndDate] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectKey, setSelectKey] = useState<string>('');

  useEffect(() => {
    setSelectStartDate(startDate);
    setSelectEndDate(endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    setSelectKey(valueKey);
    if (valueKey === 'transactions') {
      setSelectType('excel');
    } else {
      setSelectType('pdf');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);


  useEffect(() => {
    if (selectKey === 'transactions') {
      setSelectType('excel');
    } else {
      setSelectType('pdf');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectKey]);


  const options = [
    { name: 'PDF', key: 'pdf' },
    { name: 'Excel', key: 'excel' },
  ];

  const selectOption = [
    { label: 'Transactions Breakdown Report', value: 'transactions' },
    { label: 'Merchant Services Statement', value: 'settlement' },
  ];

  const handleClick = async () => {
    const timestamp = new Date().getTime();
    setLoading(true);
    const { url = '', file_name = '' } = await handleExport({
      p_business_id: businessID,
      type: selectType,
      templateType: selectKey === 'transactions' ? 'tbr' : 'mss',
      startTime: selectStartDate,
      endTime: selectEndDate,
    });

    // const file_name = 'test';
    // const url = 'https://wonder-mss-staging.s3.ap-east-1.amazonaws.com/c3cc84fe-b1c3-11ec-a3d9-42010aaa001d/mss_20230407_20230421_aa001d.pdf'

    if (url) {
      let success = true;
      try {
        await fetch(url + '?timestamp=' + timestamp).then((res) =>
          res.blob().then((blob) => {
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${`${file_name}`}`;
            a.click();
            window.URL.revokeObjectURL(url);
          })
        );
      } catch (error) {
        console.error(error);
        success = false;
      }

      if (success) {
        Alert.success({
          message: t('analytics:exported_successfully'),
          wrapClass: '-top-10',
        });
      } else {
        Alert.error({
          message: t('analytics:exported_failed'),
          wrapClass: '-top-10',
        });
      }
    } else {
      Alert.error({
        message: t('analytics:exported_failed'),
        wrapClass: '-top-10',
      });
    }

    onCancel();
    setLoading(false);
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const handleDateChange = async (dates: any) => {
    if (dates && Array.isArray(dates)) {
      let newStartDate: any = dates[0];
      let newEndDate: any = dates[1];

      if (newStartDate && newEndDate) {
        newStartDate = newStartDate.format('YYYY-MM-DD');
        newEndDate = newEndDate.format('YYYY-MM-DD');
        setSelectStartDate(newStartDate);
        setSelectEndDate(newEndDate);
      }
    }
  };

  return (
    <ModalEmpty
      visible={visible}
      title={t('common:export')}
      onCancel={onCancel}
    >
      <div>
        <Label required value={t('analytics:file_type')} />
        {canChange && (
          <Select
            className="shadow-none text-color-text-1"
            options={selectOption}
            value={selectKey}
            onChange={(value) => setSelectKey(value)}
          />
        )}
        {!canChange && (
          <div className="border border-border-color h-10 rounded text-color-text-2 flex items-center bg-disabled-color">
            <span className="ml-2">
              {selectKey === 'transactions'
                ? 'Transactions Breakdown Report'
                : 'Merchant Services Statement'}
            </span>
          </div>
        )}
        <Label className="mt-6" value={t('analytics:file_format')} required />
        {selectKey === 'transactions' && (
          <div className="border border-border-color h-10 rounded text-sm font-medium text-color-text-1 flex items-center bg-disabled-color">
            <span className="ml-2">Excel</span>
          </div>
        )}
        {selectKey === 'settlement' && (
          <Select
            className="shadow-none"
            options={options}
            dataKey={'key'}
            valueKey={'name'}
            value={selectType}
            onChange={(value) => setSelectType(value)}
          />
        )}
        <Label
          className="mt-6"
          value={t('analytics:created_at_date_range')}
          required
        />
        <div className="flex items-center">
          <DateRange
            format="MMM D, YYYY"
            className="w-full"
            disabledDate={disabledDate}
            allowClear={false}
            placement="topRight"
            size={'large'}
            value={[dayjs(selectStartDate), dayjs(selectEndDate)]}
            onChange={handleDateChange}
          />
        </div>

        <Button
          loading={loading}
          className="w-full mt-6 h-10"
          onClick={handleClick}
        >
          {t('analytics:export_report')}
        </Button>
      </div>
    </ModalEmpty>
  );
};

export default ExportModal;
