import { getCookie } from '@/utils/index';
import Config from '@/libs/config';
import { Constants } from '@/constants';
import api from '@/libs/api';
import { IObject } from '@/types/common-types';

export const getApiCredential = async (businessID: string) => {
  const token = getCookie(Constants.TOKEN) || '';

  let data = [];
  try {
    const resp: IObject = await api.get(
      `${Config.urls.gatewayUrl}/api/galaxy/api-credential`,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'Content-Type': 'application/json',
        },
      }
    );

    if (resp.code === 200 && Array.isArray(resp.data)) {
      data = resp.data;
    }
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const generateApiCredential = async (
  businessID: string,
  password: string,
  domain: string
) => {
  const token = getCookie(Constants.TOKEN) || '';

  let data: IObject = {};
  try {
    const resp: IObject = await api.post(
      `${Config.urls.gatewayUrl}/api/galaxy/api-credential`,
      {
        password,
        domain,
      },
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'Content-Type': 'application/json',
        },
      }
    );

    if (resp.code === 200 && resp.data && resp.data.id) {
      data = resp.data;
    } else {
      data = {
        ...resp,
      };
    }
  } catch (error) {
    console.error(error);
  }
  return data;
};

interface IUpdateApiCredentialBody {
  activate: boolean;
  password: string;
}

export const updateApiCredential = async (
  businessID: string,
  id: string,
  body: IUpdateApiCredentialBody
) => {
  const token = getCookie(Constants.TOKEN) || '';

  let data: IObject = {};
  try {
    data = await api.patch(
      `${Config.urls.gatewayUrl}/api/galaxy/api-credential/${id}`,
      {
        ...body,
      },
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'Content-Type': 'application/json',
        },
      }
    );
  } catch (error) {
    console.error(error);
  }
  return data;
};
