import { useEffect, useState } from 'react';
import Button from '@/components/common/button';
import { getCookie } from '@/utils/index';
import config from '@/libs/config';
import { Constants } from '@/constants';
import api from '@/libs/api';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { handlePrice } from '../utils';
import type { IPayoutsNewStep } from '@/types/global-accounts-type';
import Skeleton from '@/components/skeleton';
import SelectedIcon from '@/pages/payouts/payouts-icons/selected-icon';
import NoRelatedPayeeIcon from '@/pages/payouts/payouts-icons/no-related-payee-icon';
import FpsDetailsIcon from '@/pages/payouts/payouts-icons/fps-transfer-details-icon';
import RtgsDetailsIcon from '@/pages/payouts/payouts-icons/rtgs-details-icon';
import SwiftDetailsIcon from '@/pages/global-accounts/global-accounts-icons/swift-icon';
import ChequeIcon from '@/pages/global-accounts/global-accounts-icons/cheque-icon';
import { currencies } from '@/utils/currencies';


interface IChooseMethodsProps {
  businessID: string;
  setSteps: (steps: IPayoutsNewStep[]) => void;
  setStepCount: (stepCount: number) => void;
  setFree: (free: string) => void;
  setgolbalMethods: (methods: any) => void;
}

interface CheckItem {
  level_1_option?: string;
  fee_mapping_rules?: any;
  transfer_method?: string
}

const AddfundChooseMethod = (props: IChooseMethodsProps) => {
  const { businessID, setStepCount, setSteps, setFree, setgolbalMethods } =
    props;
  const [loadingData, setLoadingData] = useState(true);
  const [active, setActive] = useState('');
  const [checkItem, setCheckItem] = useState<CheckItem>({});
  const [methodList, setmethodList] = useState<any>([]);
  const { t } = useTranslation(["global_accounts",
    "payouts",
    "account_balance",
    "related_company_accounts",
    "payees"]);
  const { lang = 'en' } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryCurrencyCode = searchParams.get('currency_code');
  const handleChangePayee = (item: any) => {
    setCheckItem(item);
    setActive(item.level_1_option);
  };
  useEffect(() => {
    getMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMethod = async () => {
    setLoadingData(true);
    let country_code = '';
    currencies.forEach((item: any) => {
      if (item.currency_code === queryCurrencyCode) {
        country_code = item.country_code;
      }
    });
    const token = getCookie(Constants.TOKEN) || '';
    const resp = await api.get(
      `${config.urls.gatewayUrl}/api/treasury-services/api/transfer_methods?to_currency_code=${queryCurrencyCode}&to_country_code=${country_code}&available_for_merchant_type=payin`,
      {
        headers: {
          'x-p-business-id': businessID,
          'X-USER-ACCESS-TOKEN': token,
          'x-i18n-lang': lang,
          'x-client-id': config.clientID,
          'x-platform-id': config.platformId,
        },
      }
    );
    const { data } = resp || {};
    const filterData = data.filter(
      (item: any) => item.transfer_method !== 'Cheque'
    );
    setmethodList(filterData);
    setLoadingData(false);
  };

  const handleNext = () => {
    setgolbalMethods(checkItem.level_1_option);
    if (checkItem.transfer_method && checkItem.transfer_method.includes('FPS')) {
      if (checkItem.fee_mapping_rules && checkItem.fee_mapping_rules.length > 0) {
        setFree(checkItem.fee_mapping_rules[0].fixed_fee);
      } else {
        setFree('0');
      }
      const steps: IPayoutsNewStep[] = [
        {
          key: '1',
          title: t('global_accounts:choose_method'),
          name: '1',
        },
        {
          key: '2',
          title: t('payouts:fps_edda'),
          name: '2',
        },
      ];
      setSteps(steps);
      setStepCount(1);
    } else {
      const steps: IPayoutsNewStep[] = [
        {
          key: '1',
          title: t('global_accounts:choose_method'),
          name: '1',
        },
        {
          key: '1A',
          title: t('payouts:choose_account'),
          name: '1A',
        },
        {
          key: '2',
          title: t('common:add_fund'),
          name: '2',
        },
      ];
      setSteps(steps);
      setStepCount(1);
    }
  };

  const computationalCost = (amount: string, free: string) => {
    const newAmount = amount.split(',').join('');
    const res = Number(newAmount) - Number(free);
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const formattedNum = formatter.format(res);
    return formattedNum;
  };

  return (
    <div>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        <div className="font-semibold text-xl text-color-text-1">
          {t('global_accounts:choose_method')}
        </div>
        <div className="mt-4 mb-6 border-b border-border-color" />
        {!loadingData &&
          methodList.map((item: any) => {
            return (
              <div
                key={item.transfer_method}
                onClick={() => handleChangePayee(item)}
                className="text-color-text-1 font-medium text-sm"
              >
                <div
                  className={`flex  py-3 cursor-pointer hover:bg-disabled-color mt-6  rounded h-[144px]  flex-col ${active === item.level_1_option
                    ? 'border-[#0094FF] border-2 border-solid'
                    : 'border-border-color border'
                    }`}
                >
                  <div className="flex justify-between  font-medium py-1  border-border-color w-[92%] ml-[4%]">
                    <div className="font-semibold text-xl text-color-text-1 flex">
                      {(item.transfer_method && item.transfer_method.includes('FPS')) && <FpsDetailsIcon />}
                      {(item.transfer_method && item.transfer_method.includes('RTGS')) && <RtgsDetailsIcon />}
                      {(item.transfer_method && item.transfer_method.includes('SWIFT')) && <SwiftDetailsIcon />}
                      {(item.transfer_method && item.transfer_method.includes('Cheque')) && <ChequeIcon />}
                      <span className="ml-2">{item.transfer_method}</span>
                    </div>
                    {active === item.level_1_option && (
                      <div>
                        <SelectedIcon size={24} />
                      </div>
                    )}
                  </div>
                  <div className="flex justify-between  font-medium py-1  border-border-color w-[90%] ml-[5%]">
                    <div className="text-color-text-2">
                      {t('payouts:transfer_fee')}
                    </div>
                    <div className="text-color-text-1">
                      {handlePrice(item.transfer_fee)} {queryCurrencyCode}
                    </div>
                  </div>
                  <div className="flex justify-between  font-medium py-1  border-border-color w-[90%] ml-[5%]">
                    <div className="text-color-text-2">
                      {t('payouts:speed')}
                    </div>
                    <div className="text-color-text-1">
                      {item.transfer_day_min}-{item.transfer_day_max}{' '}
                      {t('payouts:business_day')}
                    </div>
                  </div>
                  <div className="flex justify-between  font-medium py-1  border-border-color w-[90%] ml-[5%]">
                    <div className="text-color-text-2">
                      {t('payouts:transfer_limit')}
                    </div>
                    <div className="text-color-text-1">
                      {!item.transfer_amount_max &&
                        !item.transfer_amount_min && (
                          <span>{t('payouts:no_limit')}</span>
                        )}
                      {!item.transfer_amount_max &&
                        item.transfer_amount_min && (
                          <span>
                            {t('payouts:from')} {item.transfer_amount_min}{' '}
                            {queryCurrencyCode}
                          </span>
                        )}
                      {/* {item.transfer_amount_max &&
                        !item.transfer_amount_min && (
                          <span>
                            {t('payouts:up_to')} {item.transfer_amount_min}{' '}
                            {queryCurrencyCode}
                          </span>
                        )} */}
                      {item.transfer_amount_max && item.transfer_amount_min && (
                        <span>
                          {t('payouts:up_to')}&nbsp;
                          {computationalCost(
                            item.transfer_amount_max,
                            '0'
                          )}{' '}
                          {queryCurrencyCode}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        {!loadingData && !methodList && (
          <div className="flex flex-col items-center justify-center mt-20 mb-52">
            <NoRelatedPayeeIcon size={80} />
            <div className="font-bold text-xl text-color-text-1 mt-6">
              {t('payouts:no_save_payee_yet')}
            </div>
            <div className="text-color-text-2 mt-2">
              {t('payouts:add_new_payee')}
            </div>
          </div>
        )}
        {loadingData && (
          <div>
            <div className="flex items-center py-4">
              <Skeleton
                avatar={true}
                row={3}
                className="items-center"
                width={['100%']}
                avatarClass="h-14 w-14 mr-3.5"
                skeletonItemClass="h-4 first:h-6 last:h-6 mb-1.5 last:mb-0 rounded-[50px] w-[200px]"
              />
            </div>
            <div className="border-b border-border-color"></div>
            <div className="flex items-center py-4">
              <Skeleton
                avatar={true}
                row={3}
                className="items-center"
                width={['100%']}
                avatarClass="h-14 w-14 mr-3.5"
                skeletonItemClass="h-4 first:h-6 last:h-6 mb-1.5 last:mb-0 rounded-[50px] w-[200px]"
              />
            </div>
            <div className="border-b border-border-color"></div>
            <div className="flex items-center py-4">
              <Skeleton
                avatar={true}
                row={3}
                className="items-center"
                width={['100%']}
                avatarClass="h-14 w-14 mr-3.5"
                skeletonItemClass="h-4 first:h-6 last:h-6 mb-1.5 last:mb-0 rounded-[50px] w-[200px]"
              />
            </div>
            <div className="border-b border-border-color"></div>
            <div className="flex items-center py-4">
              <Skeleton
                avatar={true}
                row={3}
                className="items-center"
                width={['100%']}
                avatarClass="h-14 w-14 mr-3.5"
                skeletonItemClass="h-4 first:h-6 last:h-6 mb-1.5 last:mb-0 rounded-[50px] w-[200px]"
              />
            </div>
          </div>
        )}
      </div>

      <div className="flex items-center justify-between">
        <Button
          className="flex-1"
          onClick={handleNext}
          styleType="Primary"
          disabled={loadingData || active === ''}
        >
          {t('common:next')}
        </Button>
      </div>
    </div>
  );
};

export default AddfundChooseMethod;
