import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ApproximatelyEqualIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9145 5.96941C11.143 6.74085 9.94301 7.59801 8.57156 7.59801C7.28583 7.59801 6.51439 7.16943 5.82866 6.82657C5.14294 6.56942 4.45721 6.05513 3.34291 6.05513C2.31432 6.05513 1.37145 6.74085 0.857156 7.25515L0 6.31219C0.77144 5.54075 1.97146 4.68359 3.34291 4.68359C4.62864 4.68359 5.40008 5.11217 6.08581 5.45503C6.77153 5.71218 7.45726 6.14076 8.57156 6.14076C9.60015 6.14076 10.543 5.45503 11.0573 4.94074L11.9145 5.96941Z"
          fill="#475569"
        />
        <path
          d="M8.57156 3.31285C9.94301 3.31285 11.143 2.4557 11.9145 1.68426L11.0573 0.655584C10.543 1.16988 9.60015 1.8556 8.57156 1.8556C7.45726 1.8556 6.77153 1.42702 6.08581 1.16988C5.40008 0.827015 4.62864 0.398438 3.34291 0.398438C1.97146 0.398438 0.77144 1.25559 0 2.02703L0.857156 2.96999C1.37145 2.4557 2.31432 1.76997 3.34291 1.76997C4.45721 1.76997 5.14294 2.28427 5.82866 2.54141C6.51439 2.88427 7.28583 3.31285 8.57156 3.31285Z"
          fill="#475569"
        />
      </svg>
    </div>
  );
};

export default ApproximatelyEqualIcon;
