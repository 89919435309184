import type { UseFormReturn } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useState } from 'react';
import Banks from './banks';
import { INewPayeeFormData, IPayoutMethod } from '@/types/payouts-type';
import { IAreaCodes } from '@/types/common-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Label from '@/components/common/label';
import { getValueMultilingual } from '@/utils/payouts-utils';
import MoreIcon from '@/components/svg/ellipsis-icon';
import PhoneNumber from '@/components/common/phone-number';
import Input from '@/components/common/input';
import { verifyEmail } from '@/utils';

interface IMethodInformationItemProps {
  name: string;
  identifierName: string;
  type: string;
  option: string;
  payoutMethod: IPayoutMethod;
  areaCodes: IAreaCodes[];
  businessID: string;
  payeeData: INewPayeeFormData;
  useFormReturn: UseFormReturn<any, any, undefined>;
  setPayeeData: (payeeData: INewPayeeFormData) => void;
}

const MethodInformationItem: React.FC<IMethodInformationItemProps> = (
  props
) => {
  const {
    name,
    identifierName,
    type,
    option,
    areaCodes,
    businessID,
    payoutMethod,
    payeeData,
    setPayeeData,
    useFormReturn,
  } = props;

  const { bank: initBank } = payeeData;
  const bank = initBank;
  const [show, setShow] = useState(false);
  const { t } = useTranslation('payees');
  const { lang = 'en' } = useParams();
  const { transfer_method } = payoutMethod;
  const isAlipay = transfer_method.indexOf('Alipay') !== -1;

  const { setValue } = useFormReturn;

  const handleBankChange = (bank: any) => {
    setPayeeData({
      ...payeeData,
      bank,
    });
    setValue(name, bank.id);
  };

  return (
    <div>
      <>
        <Label
          className="mb-0 mt-4 md:mt-6 md:mb-1.5"
          value={
            option === 'Platform Transfer'
              ? t('payout_to')
              : getValueMultilingual(identifierName, payoutMethod.i18n, lang)
          }
          required={true}
        />
        <Controller
          name={name}
          control={useFormReturn.control}
          rules={{
            required: t('common:no_data_hint'),
            validate: (value) => {
              let msg = undefined;
              if (type === 'Email') {
                if (!verifyEmail(value)) {
                  if (isAlipay) {
                    msg = t('alipay_user_error');
                  } else {
                    msg = t('invalid_email_address');
                  }
                }
              }
              if (type === 'Phone Number') {
                const values = value.split(' ');
                if (!(values.length > 1 && values[1])) {
                  if (isAlipay) {
                    msg = t('alipay_user_error');
                  } else {
                    msg = t('common:no_data_hint');
                  }
                }
              }
              if (type === 'Number') {
                if (!RegExp(/^[0-9]*$/).test(value)) {
                  msg = t('only_number');
                }
              }
              if (type === 'Account Number') {
                if (value.indexOf(' ') !== -1) {
                  msg = t('no_space');
                }
                if (transfer_method.indexOf('FPS') !== -1 && !RegExp(/^[0-9]*$/).test(value)) {
                  msg = t('only_number');
                }
              }
              return msg;
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            if (type === 'Bank') {
              return (
                <div className="w-full" onClick={() => setShow(true)}>
                  <div
                    className={`group rounded h-10 flex items-center cursor-pointer border hover:border-primary-color shadow-box-drop ${
                      error && error.message
                        ? 'border-error-color'
                        : 'border-border-color'
                    }`}
                  >
                    <input
                      value={
                        bank.bank_code
                          ? `${bank.bank_code} ${bank.bank_name}`
                          : ''
                      }
                      type="text"
                      className="border-none flex-1 outline-none shadow-none cursor-pointer font-medium text-color-text-1 bg-inherit placeholder:text-color-text-5 placeholder:font-normal placeholder:text-sm text-sm py-2 px-3 text-ellipsis"
                      autoComplete="none"
                      readOnly={true}
                      placeholder={t('payees:choose_bank')}
                    />
                    <span className="text-icon-color px-2 group-hover:text-primary-color">
                      <MoreIcon />
                    </span>
                  </div>
                  {error && (
                    <div className="mt-1 text-sm text-error-color">
                      {error.message}
                    </div>
                  )}
                </div>
              );
            }
            if (type === 'Phone Number') {
              return (
                <PhoneNumber
                  value={value}
                  options={areaCodes}
                  name={''}
                  onChange={(data) => {
                    onChange(data);
                  }}
                  error={error}
                  placeholder={`${t('enter')}${getValueMultilingual(
                    identifierName,
                    payoutMethod.i18n,
                    lang
                  )}`}
                />
              );
            }
            return (
              <Input
                onChange={(data) => {
                  onChange(data);
                }}
                value={value}
                error={error}
                placeholder={
                  option === 'Platform Transfer'
                    ? t('enter_business_id')
                    : `${t('enter')}${getValueMultilingual(
                        identifierName,
                        payoutMethod.i18n,
                        lang
                      )}`
                }
              />
            );
          }}
        />
        {name === 'identifier_1_value' && isAlipay && (
          <div className="text-sm text-color-text-2 font-medium mt-2">
            {t('alipay_name_tip')}
          </div>
        )}
        {name === 'identifier_2_value' && isAlipay && (
          <div className="text-sm text-color-text-2 font-medium mt-2">
            {t('alipay_name_tip')}
          </div>
        )}
      </>
      {show && (
        <Banks
          businessID={businessID}
          areaCodes={areaCodes}
          bank={bank}
          setShowModal={setShow}
          onChange={handleBankChange}
        />
      )}
    </div>
  );
};

export default MethodInformationItem;
