import type { ICheckOrderstatusParams, IPayOrderResult } from './interface';
import { checkOrderStatu } from '../../../../data/terminal';

let timeLoop: any;
let timesRun = 0;
let networkErrorNum = 0;
const checkLoop = () => {
  timeLoop = setInterval(() => {
    timesRun += 1;
  }, 1000);
};

const checkOrderStatus = async (
  params: ICheckOrderstatusParams
): Promise<IPayOrderResult> => {
  const {
    businessID,
    orderNumber,
    token,
    callback,
    timeLimit = 900,
    isCheck = false,
    stopOperation,
  } = params;

  if (!timeLoop) {
    checkLoop();
  }

  if (timesRun >= timeLimit || (stopOperation && stopOperation())) {
    clearInterval(timeLoop);
    return;
  }
  if (isCheck && timesRun > 15) {
    clearInterval(timeLoop);
    if (callback) {
      callback({ order: '', state: 'timeOut' });
    } else {
      window.location.href = window.location.href.replace('&check=1', '');
    }
    return;
  }

  const checkResp = await checkOrderStatu(businessID, orderNumber, token);

  const { data: order, code } = checkResp || {};

  // 接口抛错的情况
  if (!order || code !== 200) {
    if (
      checkResp &&
      checkResp.response &&
      checkResp.response.data &&
      checkResp.response.data.code
    ) {
      clearInterval(timeLoop);
      if (callback) {
        callback(checkResp.response.data);
      }
      return;
    } else {
      networkErrorNum += 1;
      if (networkErrorNum > 10) {
        clearInterval(timeLoop);
        if (callback) {
          callback(checkResp);
        }
        return;
      }
    }
  }

  const {
    state,
    correspondence_state: correspondenceState,
    fail_transactions: failedTransactions,
    sale_transactions: saleTransactions,
  } = order || {};

  let saleTransaction: any = '';
  let failedTransaction: any = '';
  let errorTransaction: any = '';
  if (state === 'completed' && correspondenceState === 'paid') {
    if (callback) {
      callback({ order });
    }
    return;
  } else {
    if (saleTransactions && saleTransactions.length > 0) {
      saleTransaction = saleTransactions[saleTransactions.length - 1];
    }
    if (failedTransactions && failedTransactions.length > 0) {
      failedTransaction = failedTransactions[failedTransactions.length - 1];
    }

    if (!saleTransaction && failedTransaction) {
      errorTransaction = failedTransaction;
    }

    if (saleTransaction && failedTransaction) {
      const saleTransactionTime = new Date(
        saleTransaction.created_at
      ).getTime();
      const failedTransactionTime = new Date(
        failedTransaction.created_at
      ).getTime();

      if (failedTransactionTime > saleTransactionTime) {
        errorTransaction = failedTransaction;
      }
    }

    if (
      errorTransaction &&
      errorTransaction.payment_data &&
      errorTransaction.payment_data.resp_code &&
      errorTransaction.payment_data.resp_msg
    ) {
      if (callback) {
        callback({
          code: errorTransaction.payment_data.resp_code,
          message: errorTransaction.payment_data.resp_msg,
        });
        return;
      }
    }

    if (saleTransaction && saleTransaction.success) {
      if (!failedTransaction) {
        if (callback) {
          callback({ order });
        }
        return;
      }

      const saleTransactionTime = new Date(
        saleTransaction.created_at
      ).getTime();
      const failedTransactionTime = new Date(
        failedTransaction.created_at
      ).getTime();
      if (saleTransactionTime > failedTransactionTime) {
        if (callback) {
          callback({ order });
        }
        return;
      }
    }

    setTimeout(async () => {
      await checkOrderStatus(params);
    }, 500);
  }
};

export default checkOrderStatus;
