import type { IObject } from '@/types/common-types';
import type {
  IGetActiveLedgerParams,
  IGetActivitiesParams,
  // IGetActivitesCount,
  IGetDayEndClosedRecords,
  IGetCurrentlyProcessing,
  IGetMerchantTransactions,
  // ISendExportReport,
  ISendReportEmail,
  IGetActiveLedgerSearch,
} from '@/types/account-balance-type';

import { getTokenBuinessID } from '@/pages/account-balance/account-balance-data';
import api from '@/libs/api';
import config from '@/libs/config';
import {
  handleSettledRecordsFilter,
  handleCurrentlyProcessingFilter,
} from '@/utils/account-balance-untils';

/**
 * 获取
 * @param params
 * @returns
 */
export const getPayinDetails = async (params: IGetActiveLedgerParams) => {
  const { pBusinessId, recordId } = params;
  const { token, pBusinessID } = getTokenBuinessID(pBusinessId);

  try {
    const { data } = await api.get(
      `${config.urls.gatewayUrl}/api/treasury-services/api/payins/${recordId}`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessID,
        },
      }
    );

    if (data) {
      return data;
    }
  } catch (error) { }
};

export const getFxConversionDetails = async (
  params: IGetActiveLedgerParams
) => {
  const { pBusinessId, recordId } = params;
  const { token, pBusinessID } = getTokenBuinessID(pBusinessId);

  try {
    const { data } = await api.get(
      `${config.urls.gatewayUrl}/api/treasury-services/api/fx_conversions/${recordId}`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessID,
        },
      }
    );

    if (data) {
      return data;
    }
  } catch (error) { }
};

export const getCardDetails = async (params: IGetActiveLedgerParams) => {
  const { pBusinessId, recordId } = params;
  const { token, pBusinessID } = getTokenBuinessID(pBusinessId);

  try {
    const resp: IObject = await api.get(
      // `${config.urls.baseDomain}/api/cards/transactions/${recordId}`,
      `${config.urls.gatewayUrl}/api/cards/transactions/${recordId}`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessID,
        },
      }
    );
    if (resp && resp.code === 200 && resp.data) {

      return resp.data;
    }
  } catch (error) { }
};

export const getPayOutDetails = async (params: IGetActiveLedgerParams) => {
  const { pBusinessId, recordId } = params;
  const { token, pBusinessID } = getTokenBuinessID(pBusinessId);

  try {
    const { data } = await api.get(
      `${config.urls.gatewayUrl}/api/treasury-services/api/payout_requests/${recordId}`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessID,
        },
      }
    );

    if (data) {
      return data;
    }
  } catch (error) { }
};


/**
 * 获取activities list
 */
export const getActivities = async (
  params: IGetActivitiesParams,
  search?: IGetActiveLedgerSearch
) => {
  const {
    pBusinessId,
    type = 'all',
    limit = 50,
    offset = 0,
    upcoming = '',
    ledger_account_id = '',
  } = params;

  const { token, pBusinessID } = getTokenBuinessID(pBusinessId);
  const param: IObject = {
    ...search,
    type,
    limit,
    offset,
  };

  if (upcoming) {
    param.upcoming = true;
  }

  if (ledger_account_id) {
    param.ledger_account_id = ledger_account_id;
  }

  try {
    const { data } = await api.post(
      `${config.urls.gatewayUrl}/api/treasury-services/api/activities`,
      { ...param },
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessID,
        },
      }
    );
    const { activities = [] } = data || {};

    return activities;
  } catch (error) {
    throw error;
  }
};

/**
 * activities count
 * @param params
 */

/**
 * 获取公司账户金额
 */
export const getBusinessAccount = async (pBusinessId: string, lang: string) => {
  const { token, pBusinessID } = getTokenBuinessID(pBusinessId);
  try {
    const { data } = await api.get(
      `${config.urls.gatewayUrl}/api/retrieve-balances?p_business_id=${pBusinessID}`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessID,
          'x-i18n-lang': lang,
        },
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * 获取merchant account data
 */
export const getMerchantAccount = async (pBusinessId: string) => {
  const { token, pBusinessID } = getTokenBuinessID(pBusinessId);
  try {
    const { data } = await api.get(
      `${config.urls.gatewayUrl}/api/gateway/merchant-account`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessID,
        },
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * 获取payment details
 */
export const getPaymentTransaction = async (
  pBusinessId: string,
  transactionId: string
) => {
  const { token, pBusinessID } = getTokenBuinessID(pBusinessId);

  try {
    const { data } = await api.get(
      `${config.urls.gatewayUrl}/api/gateway/transactions/${transactionId}`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessID,
        },
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * 已结records
 */
export const getDayEndClosedRecords = async (
  params: IGetDayEndClosedRecords
) => {
  const {
    pBusinessId,
    limit = 50,
    offset = 0,
    searchValue = '',
    options = [],
  } = params;

  const { token, pBusinessID } = getTokenBuinessID(pBusinessId);
  const filter = handleSettledRecordsFilter(searchValue, options);
  try {
    const { data = [] } = await api.get(
      `${config.urls.gatewayUrl}/api/gateway/query/merchant_account/settled_records?limit=${limit}&offset=${offset}&${filter}`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessID,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * Currently Processing data
 */
export const getCurrentlyProcessing = async (
  params: IGetCurrentlyProcessing
) => {
  const {
    pBusinessId,
    limit = 10,
    offset = 0,
    searchValue = '',
    options = [],
  } = params;

  const { token, pBusinessID } = getTokenBuinessID(pBusinessId);
  const filter = handleCurrentlyProcessingFilter(searchValue, options);
  try {
    const { data = [] } = await api.get(
      `${config.urls.gatewayUrl}/api/gateway/query/merchant_account/currently_processing?limit=${limit}&offset=${offset}&${filter}`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessID,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * 商户交易结算详细列表
 */
export const getMerchantTransactions = async (
  params: IGetMerchantTransactions
) => {
  const { dayEndId, pBusinessId, lang } = params;

  const { token, pBusinessID } = getTokenBuinessID(pBusinessId);

  let result: any[] = [];
  let storeDayEnd: any = {};

  try {
    const resp = await api.get(
      // `${config.urls.baseDomain}/api/payment-gateway/day-end/${dayEndId}/transactions?limit=${limit}&offset=${offset}`,
      `${config.urls.gatewayUrl}/api/gateway/day-end/${dayEndId}/reports`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessID,
          'x-i18n-lang': lang,
        },
      }
    );

    if (resp) {
      const { data = {} } = resp;
      const {
        chargeback_transactions = {},
        completed_pre_auth_transactions = {},
        hold_and_dispute_transactions = {},
        pre_auth_transactions = {},
        refunded_transactions = {},
        resolved_transactions = {},
        sales_transactions = {},
        voided_transactions = {},
        store_day_ends = {},
        number_of_transactions = 0,
        total_sales_amount = 0,
        total_refund_amount = 0,
        transfer_status = '',
        other_adjustment_fees = {},
      } = data;

      const { items = [] } = other_adjustment_fees;

      if (
        chargeback_transactions.items &&
        Array.isArray(chargeback_transactions.items)
      ) {
        result = result.concat(chargeback_transactions.items);
      }

      if (
        completed_pre_auth_transactions.group &&
        Array.isArray(completed_pre_auth_transactions.group) &&
        completed_pre_auth_transactions.group[0] &&
        completed_pre_auth_transactions.group[0].items &&
        Array.isArray(completed_pre_auth_transactions.group[0].items)
      ) {
        result = result.concat(completed_pre_auth_transactions.group[0].items);
      }

      if (
        hold_and_dispute_transactions.items &&
        Array.isArray(hold_and_dispute_transactions.items)
      ) {
        result = result.concat(hold_and_dispute_transactions.items);
      }

      if (
        pre_auth_transactions.group &&
        Array.isArray(pre_auth_transactions.group) &&
        pre_auth_transactions.group[0] &&
        pre_auth_transactions.group[0].items &&
        Array.isArray(pre_auth_transactions.group[0].items)
      ) {
        result = result.concat(pre_auth_transactions.group[0].items);
      }

      if (
        refunded_transactions.group &&
        Array.isArray(refunded_transactions.group) &&
        refunded_transactions.group[0] &&
        refunded_transactions.group[0].items &&
        Array.isArray(refunded_transactions.group[0].items)
      ) {
        result = result.concat(refunded_transactions.group[0].items);
      }

      if (
        resolved_transactions.items &&
        Array.isArray(resolved_transactions.items)
      ) {
        result = result.concat(resolved_transactions.items);
      }

      if (
        sales_transactions.group &&
        Array.isArray(sales_transactions.group) &&
        sales_transactions.group[0] &&
        sales_transactions.group[0].items &&
        Array.isArray(sales_transactions.group[0].items)
      ) {
        result = result.concat(sales_transactions.group[0].items);
      }

      if (
        voided_transactions.group &&
        Array.isArray(voided_transactions.group) &&
        voided_transactions.group[0] &&
        voided_transactions.group[0].items &&
        Array.isArray(voided_transactions.group[0].items)
      ) {
        result = result.concat(voided_transactions.group[0].items);
      }

      if (
        store_day_ends &&
        Array.isArray(store_day_ends) &&
        store_day_ends.length > 0
      ) {
        storeDayEnd = store_day_ends[0];
        storeDayEnd.transfer_status =
          store_day_ends[0].transfer_status || transfer_status;
        storeDayEnd.total_count = number_of_transactions;
        storeDayEnd.total_sales_amount = total_sales_amount;
        storeDayEnd.total_refund_amount = total_refund_amount;
        storeDayEnd.other_adjustment_fees_items = items;
      }
    }
  } catch (error) {
    console.error(error);
  }

  return {
    result,
    storeDayEnd,
  };
};

/**
 * 商户交易结算详细列表
 */
export const getMerchantSettlement = async (
  pBusinessId: string,
  dayEndId: string
) => {
  const { token, pBusinessID } = getTokenBuinessID(pBusinessId);

  try {
    const { data } = await api.get(
      `${config.urls.gatewayUrl}/api/gateway/day-end/${dayEndId}/reports`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessID,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * 发送邮件
 */

export const sendReportEmail = async (params: ISendReportEmail) => {
  const { businessId, email, url, title } = params;
  const { token, pBusinessID } = getTokenBuinessID(businessId);
  const param = {
    subject: title,
    recipients: [email],
    attachments: [url],
    message_channel: 'test',
    template_type: 'daily_merchant_notice',
    data: {},
  }
  try {
    const data = await api.post(`${config.urls.gatewayUrl}/api/beams/email`,
      { ...param },
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': pBusinessID,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

interface IGetOtheradjustmentFeeParams {
  businessID: string;
  id: string;
  lang: string;
}

export const getOtheradjustmentFee = async (
  params: IGetOtheradjustmentFeeParams
) => {
  const { businessID, id, lang } = params;

  const { token } = getTokenBuinessID(businessID);

  let result: IObject = {};

  try {
    const resp: IObject = await api.get(
      `${config.urls.gatewayUrl}/api/gateway/query/merchant_account/get_other_adjustment_fee?id=${id}`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-p-business-id': businessID,
          'x-i18n-lang': lang,
        },
      }
    );

    if (resp && resp.code === 200 && resp.data) {
      result = resp.data;
    }
  } catch (error) {
    throw error;
  }

  return result;
};
