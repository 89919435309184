import React from 'react';
import type { IIconProps } from '@/types/common-types';

const PlusIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, opacity = 1 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="29"
        viewBox="0 0 28 29"
        fill="none"
      >
        <g opacity={`${opacity}`} clipPath="url(#clip0_20144_108)">
          <circle cx="14" cy="14.8916" r="14" fill="#0094FF" />
          <path
            d="M14 8.8916V20.8916"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 14.8916H20"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_20144_108">
            <rect
              width="28"
              height="28"
              fill="white"
              transform="translate(0 0.891602)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default PlusIcon;
