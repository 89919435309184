import React from 'react';
import type { IIconProps } from '@/types/common-types';

const InputBusinessIDIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="45"
        height="40"
        viewBox="0 0 45 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.5133 1.48873C25.238 2.13223 25.1462 3.74053 26.3297 4.61353C26.6056 4.83178 26.8351 4.85473 28.3633 4.88938L30.0751 4.92403V19.5144V34.0935H28.4209C26.9044 34.0935 26.7437 34.1164 26.399 34.3464C25.8248 34.737 25.5719 35.1622 25.5143 35.7823C25.4567 36.5289 25.8131 37.1611 26.5138 37.5054C27.0191 37.7695 27.065 37.7695 31.9133 37.7695C36.7616 37.7695 36.8075 37.7695 37.3129 37.5054C38.0482 37.1377 38.2894 36.7471 38.2894 35.9313C38.2894 35.173 38.0828 34.7937 37.4276 34.3572C37.0829 34.116 36.9223 34.093 35.4058 34.093H33.7516V19.5027V4.91233H35.4058C36.9223 4.91233 37.0829 4.88938 37.4276 4.64818C38.0824 4.21168 38.2894 3.83233 38.2894 3.07408C38.2894 2.25823 38.0482 1.86763 37.3129 1.48873C36.8075 1.23583 36.7616 1.23583 31.9133 1.23583C27.0646 1.23583 27.0187 1.23583 26.5133 1.48873Z"
          fill="#FFDA44"
        />
        <path
          d="M1.66321 9.56623C1.07731 9.77278 0.686712 10.1521 0.537312 10.6462C0.468462 10.9221 0.433812 14.0122 0.456762 19.7565C0.491412 28.3843 0.491412 28.4532 0.732612 28.7749C0.870312 28.9473 1.14616 29.2002 1.36441 29.3262C1.74331 29.5561 1.98451 29.5561 15.0588 29.5903L28.3509 29.6133V27.775V25.9368H16.2306H4.11031V19.5036V13.0699H16.2306H28.3509V11.2317V9.39343H15.2312C4.40911 9.40513 2.01961 9.42808 1.66321 9.56623Z"
          fill="black"
        />
        <path
          d="M35.4741 11.2317V13.07H38.1741H40.8741V19.5036V25.9373H38.1741H35.4741V27.7755V29.625L39.3572 29.5904C43.1601 29.5557 43.2402 29.5557 43.6538 29.2916C43.895 29.1422 44.1708 28.8321 44.3085 28.5333C44.5502 28.0509 44.5502 27.9132 44.5502 19.5036C44.5502 11.094 44.5502 10.9563 44.309 10.4735C44.1713 10.1747 43.8954 9.8646 43.6542 9.7152C43.2407 9.45105 43.1601 9.45105 39.3576 9.4164L35.4746 9.38175V11.2317H35.4741Z"
          fill="black"
        />
        <path
          d="M10.6936 14.7936C10.3835 14.92 10.28 15.1266 8.25818 19.5955C7.30463 21.7096 6.63818 23.3292 6.63818 23.5362C6.63818 24.0415 7.17818 24.5586 7.70648 24.5586C8.24648 24.5586 8.63708 24.2139 8.94713 23.4673L9.21128 22.8355H11.1989H13.1978L13.5308 23.5708C13.7149 23.9844 13.9673 24.3637 14.0938 24.4326C14.3809 24.5932 14.9785 24.5932 15.2656 24.4438C15.518 24.3061 15.8285 23.7544 15.8285 23.4444C15.8285 23.0421 12.2785 15.2071 11.9684 14.9313C11.6701 14.6671 11.153 14.6095 10.6936 14.7936ZM11.8541 19.4808C12.1412 20.1931 12.3824 20.802 12.3824 20.8249C12.3824 20.8596 11.8654 20.8825 11.2336 20.8825C10.6018 20.8825 10.0847 20.8596 10.0847 20.8366C10.0847 20.779 11.1071 18.3553 11.1877 18.2172C11.2795 18.0678 11.2565 18.0219 11.8541 19.4808Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default InputBusinessIDIcon;
