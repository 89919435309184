import BusinessOverviewIcon from '../personal-space-icons/business-overview-icon';
import MyAccountIcon from '../personal-space-icons/my-account-icon';
import LanguageIcon from '../personal-space-icons/language-icon';
import SignOutIcon from '@/components/svg/sign-out-icon';
import { useCallback } from 'react';
import EnIcon from "@/components/svg/en-icon";
import ZhCNIcon from "@/components/svg/zh-cn-icon";
import ZhHKIcon from '@/components/svg/zh-hk-icon';
import { IPlatformData } from '@/utils/platform-utils';
import { useTranslation } from 'react-i18next';
import i18n from '@/i18n';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Constants } from '@/constants';
import { useUserAction } from '@/utils/common';

interface ISpaceContainerProps {
  children: React.ReactNode;
  activeIndex: number;
  platformData?: IPlatformData;
}

const PersonalSpaceLayout: React.FC<ISpaceContainerProps> = (props) => {
  const { t } = useTranslation('personal_space');
  const navigate = useNavigate();
  const lang = i18n.language;
  const { platformData, children, activeIndex } = props;
  const { platform = {} } = platformData || {};

  useUserAction();

  const tabsData = [
    {
      title: t('personal_space:business_overview'),
      icon: <BusinessOverviewIcon size={20} />,
      key: 'overview',
    },
    {
      title: t('personal_space:account_settings'),
      icon: <MyAccountIcon size={20} />,
      key: 'settings',
    },
    {
      title: t('personal_space:language'),
      icon: <LanguageIcon />,
      key: 'language',
    },
  ];

  const langNode = useCallback(
    (key: string) => {
      let node = <EnIcon />;

      if (key === 'zh-CN') {
        node = <ZhCNIcon />;
      } else if (key === 'zh-HK') {
        node = <ZhHKIcon />;
      }

      return node;
    },
    // eslint-disable-next-line
    [lang]
  );

  const handleSignOut = () => {
    Cookies.remove(Constants.TOKEN);
    navigate(`/${lang}/account/signin`);
  };

  const handleItemClick = (index: number) => {
    if (index !== activeIndex) {
      let routeStr = '';
      switch (index) {
        case 0:
          routeStr = 'personal-space/business-overview';
          break;
        case 1:
          routeStr = 'personal-space/account-settings';
          break;
        case 2:
          routeStr = 'personal-space/language';
          break;

        default:
          break;
      }

      if (routeStr) {
        navigate(`/${lang}/${routeStr}`);
      }
    }
  };

  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col">
      <div className="h-16 flex pl-6 pr-8 py-2 items-center justify-between bg-secondary-background-color">
        <div className="flex items-center">
          <div className="overflow-hidden text-center bg-no-repeat">
            <div className="flex justify-center w-full py-4 h-full">
              <div
                dangerouslySetInnerHTML={{
                  __html: platform.dashboardLogo || '',
                }}
              />
            </div>
          </div>
          <span className="w-px h-[18px] mx-4 bg-color-text-5 opacity-60"></span>
          <div className="text-color-text-6 truncate">
            {t('personal_space:personal_space')}
          </div>
        </div>
      </div>
      <div className="overflow-hidden p-6 flex-1 flex flex-col bg-system-background-color lg:py-8 lg:px-36">
        <div className="font-bold text-4xl text-color-text-1 mb-8">
          {t('personal_space:welcome')}
        </div>
        <div className="overflow-hidden rounded border border-border-color flex-1 flex flex-col bg-primary-background-color lg:flex-row">
          <div className="pt-6 px-4 border-0 border-b border-border-color flex flex-col lg:border-r lg:border-b-0 border-solid">
            <div className="font-light mb-6 text-color-text-4">
              {t('personal_space:business_settings')}
            </div>
            <div
              className={`flex-1 flex items-center flex-row lg:w-48 lg:flex-col overflow-x-auto hidden_scrollbar`}
            >
              <div className="flex-1 flex flex-row lg:flex-col w-full">
                {tabsData.map((item, index) => {
                  return (
                    <div
                      className={`p-2 rounded-sm flex items-center cursor-pointer md:mb-4 truncate ${
                        activeIndex === index
                          ? 'text-primary-color bg-primary-color bg-opacity-10'
                          : 'text-color-text-2'
                      }`}
                      onClick={() => handleItemClick(index)}
                      key={item.key}
                    >
                      <div
                        className={`${
                          activeIndex === index
                            ? 'text-primary-color'
                            : 'text-icon-color'
                        }`}
                      >
                        {item.icon}
                      </div>
                      <div className="ml-2 text-base w-full flex items-centet justify-between">
                        <span>{item.title}</span>
                        {item.key === 'language' && (
                          <span className="ml-2 lg:ml-0">{langNode(lang)}</span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="w-1 h-8 border-0 border-border-color border-l lg:h-1 lg:w-full lg:border-t lg:border-l-0 border-dashed"></div>
              <div className="py-2 text-error-color self-start w-auto lg:w-full">
                <div
                  className="flex items-center p-2 rounded-sm cursor-pointer hover:bg-error-color hover:bg-opacity-10"
                  onClick={handleSignOut}
                >
                  <SignOutIcon />
                  <span className="ml-2 truncate">
                    {t('personal_space:sign_out')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex-1 flex flex-col my-6 px-6 overflow-y-auto hidden_scrollbar`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalSpaceLayout;
