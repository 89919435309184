import React from 'react';
import type { IIconProps } from '@/types/common-types';

const NoFileIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="95"
        height="76"
        viewBox="0 0 95 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="46.4635"
          cy="29.2008"
          r="29.2008"
          fill="#0094FF"
          fillOpacity="0.24"
        />
        <circle
          cx="80.8896"
          cy="62.3974"
          r="12.6025"
          fill="#0094FF"
          fillOpacity="0.24"
        />
        <path
          d="M6.80078 58.4806V69.5795C6.80078 70.3703 7.43621 71.0057 8.22697 71.0057H52.7212C53.512 71.0057 54.1474 70.3703 54.1474 69.5795V24.1109L38.6288 8.60645H8.22697C7.43621 8.60645 6.80078 9.24188 6.80078 10.0326V42.2136"
          stroke="#0094FF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.0551 24.1109H54.1334L38.6289 8.60645V22.6848C38.6289 23.4755 39.2643 24.1109 40.0551 24.1109Z"
          stroke="#0094FF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.3381 58.4809H3.92086C3.14422 58.4809 2.50879 57.8454 2.50879 57.0688V43.6259C2.50879 42.8493 3.14422 42.2139 3.92086 42.2139H40.3381C41.1147 42.2139 41.7501 42.8493 41.7501 43.6259V57.0688C41.7501 57.8454 41.1147 58.4809 40.3381 58.4809Z"
          stroke="#0094FF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.9286 45.8672C17.0943 45.8672 17.2286 46.0015 17.2286 46.1672V47.0095C17.2286 47.1751 17.0943 47.3095 16.9286 47.3095H13.5144V49.5941H16.0607C16.2264 49.5941 16.3607 49.7284 16.3607 49.8941V50.7109C16.3607 50.8766 16.2264 51.0109 16.0607 51.0109H13.5144V54.4761C13.5144 54.6418 13.3801 54.7761 13.2144 54.7761H12.0275C11.8619 54.7761 11.7275 54.6418 11.7275 54.4761V46.1672C11.7275 46.0015 11.8619 45.8672 12.0275 45.8672H16.9286Z"
          fill="#0094FF"
        />
        <path
          d="M19.4019 46.8627C19.087 46.8627 18.8233 46.7649 18.6105 46.5692C18.4063 46.365 18.3042 46.114 18.3042 45.8161C18.3042 45.5183 18.4063 45.2716 18.6105 45.0759C18.8233 44.8716 19.087 44.7695 19.4019 44.7695C19.7167 44.7695 19.9762 44.8716 20.1805 45.0759C20.3932 45.2716 20.4995 45.5183 20.4995 45.8161C20.4995 46.114 20.3932 46.365 20.1805 46.5692C19.9762 46.7649 19.7167 46.8627 19.4019 46.8627ZM19.9826 47.7051C20.1482 47.7051 20.2826 47.8394 20.2826 48.0051V54.4761C20.2826 54.6418 20.1482 54.7761 19.9826 54.7761H18.7957C18.63 54.7761 18.4957 54.6418 18.4957 54.4761V48.0051C18.4957 47.8394 18.63 47.7051 18.7957 47.7051H19.9826Z"
          fill="#0094FF"
        />
        <path
          d="M23.5349 45.3311C23.7006 45.3311 23.8349 45.4654 23.8349 45.6311V54.4761C23.8349 54.6418 23.7006 54.7761 23.5349 54.7761H22.348C22.1823 54.7761 22.048 54.6418 22.048 54.4761V45.6311C22.048 45.4654 22.1823 45.3311 22.348 45.3311H23.5349Z"
          fill="#0094FF"
        />
        <path
          d="M32.1735 51.0875C32.1735 51.2426 32.1672 51.3884 32.1547 51.5247C32.1412 51.6707 32.015 51.7767 31.8683 51.7767H26.9533C26.9958 52.2872 27.1745 52.6871 27.4893 52.9764C27.8042 53.2658 28.1913 53.4104 28.6508 53.4104C29.2478 53.4104 29.6899 53.1798 29.9771 52.7185C30.0375 52.6215 30.1399 52.5553 30.2542 52.5553H31.5916C31.7926 52.5553 31.9368 52.7495 31.8567 52.9338C31.6336 53.447 31.2882 53.8824 30.8206 54.24C30.242 54.674 29.5315 54.891 28.6891 54.891C28.0084 54.891 27.3957 54.7421 26.8512 54.4442C26.3151 54.1379 25.8939 53.7082 25.5876 53.1551C25.2898 52.6021 25.1409 51.9639 25.1409 51.2406C25.1409 50.5088 25.2898 49.8664 25.5876 49.3133C25.8854 48.7602 26.3023 48.3348 26.8384 48.037C27.3745 47.7392 27.9914 47.5903 28.6891 47.5903C29.3613 47.5903 29.9612 47.7349 30.4888 48.0242C31.0248 48.3135 31.4375 48.7262 31.7268 49.2623C32.0246 49.7898 32.1735 50.3982 32.1735 51.0875ZM30.3228 50.5769C30.3143 50.1174 30.1484 49.7515 29.8251 49.4793C29.5017 49.1985 29.106 49.0581 28.6381 49.0581C28.1956 49.0581 27.8212 49.1942 27.5149 49.4665C27.2171 49.7303 27.0341 50.1004 26.966 50.5769H30.3228Z"
          fill="#0094FF"
        />
      </svg>
    </div>
  );
};

export default NoFileIcon;
