import Skeleton from '@/components/skeleton';
import StoreCard from '@/components/store-card';
import type { IBusiness } from '@/types/business-types';
import { plus, times } from '@/utils/galaxy-utils';
import { Transition } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import BusinessModal from '../components/business-modal';
import PersonalSpaceLayout from '../components/layout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { queryBusinessList } from '@/data/businesses';
import { resolvingBusinessStatus } from '@/utils/business-utils';
import NoDataIcon from '../personal-space-icons/no-data-icon';
import { getPlatformConfig } from '@/App';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';

const BusinessOverviewPage = () => {
  const { t } = useTranslation('personal_space');
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [currentBusiness, setCurrentBusiness] = useState<IBusiness>();
  const [activeStores, setActiveStores] = useState<IBusiness[]>([]);
  const [otherStores, setOtherStores] = useState<IBusiness[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);

  const platformData = getPlatformConfig();

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const init = async () => {
    try {
      setLoadingData(true);
      const activeStores: IBusiness[] = [];
      const otherStores: IBusiness[] = [];
      let busniessList = await queryBusinessList();
      setLoadingData(false);
      if (busniessList && busniessList.length > 0) {
        busniessList = busniessList.filter(
          (busniess) =>
            busniess.associate_status &&
            !['idle', 'suspended'].includes(busniess.associate_status)
        );
        busniessList.forEach((busniess) => {
          if (busniess.business_type === 'Natural Person') return;
          const status = resolvingBusinessStatus(busniess);
          if (status === 'active') {
            activeStores.push(busniess);
          } else {
            otherStores.push(busniess);
          }
        });
      }
      activeStores.sort(customSort);
      otherStores.sort(customSort);
      setActiveStores(activeStores);
      setOtherStores(otherStores);
    } catch (error) {
      console.error(error);
    }
  };

  const customSort = (a: IBusiness, b: IBusiness) => {
    const aName = a.business_name;
    const bName = b.business_name;
    if (aName.startsWith("[GSEID]") && !bName.startsWith("[GSEID]")) {
      return -1;
    } else if (!aName.startsWith("[GSEID]") && bName.startsWith("[GSEID]")) {
      return 1;
    } else {
      // 如果a和b都以[GSEID]开头，或者都不以[GSEID]开头，则按照名字的第一个字母的a-z排序
      const nameA = aName.replace("[GSEID] ", "").toUpperCase();
      const nameB = bName.replace("[GSEID] ", "").toUpperCase();
      return nameA.localeCompare(nameB);
    }
  }

  const handleClick = async (business: IBusiness, active = false) => {
    if (active) {
      // dispatch(setBusiness(business));
      dispatch({
        type: ActionType.LOADING_MAIN_DATA,
        payload: {
          navigate,
          business,
        }
      });

    } else {
      setOpen(true);
      setCurrentBusiness(business);
    }
  };

  const handleCancel = async (reload = false) => {
    setOpen(false);

    if (reload) {
      await init();
    }
  };

  return (
    <PersonalSpaceLayout activeIndex={0} platformData={platformData}>
      <h2 className="text-2xl font-bold text-color-text-1 mb-5">
        {t('personal_space:business_overview')}
      </h2>
      {loadingData && (
        <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {new Array(9).fill(1).map((item, index) => {
            return (
              <div key={`${item}_${index}`}>
                <Skeleton row={1} skeletonItemClass="rounded h-56" />
              </div>
            );
          })}
        </div>
      )}
      {!loadingData && activeStores.length > 0 && (
        <>
          <div className="text-color-text-2 mb-4">
            {t('personal_space:active_businesses')}
          </div>
          <Transition.Root show={true} appear={true}>
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
              {activeStores.map((item, index) => (
                <StoreCard
                  active
                  data={item}
                  index={plus(500, times(100, index))}
                  key={item.id}
                  onClick={handleClick}
                />
              ))}
            </div>
          </Transition.Root>
        </>
      )}
      {!loadingData && otherStores.length > 0 && (
        <>
          <div className="text-color-text-4 mb-4 mt-6">
            {t('personal_space:other_businesses')}
          </div>
          <Transition.Root show={true} appear={true}>
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
              {otherStores.map((item, index) => (
                <StoreCard
                  index={plus(500, times(100, index))}
                  data={item}
                  key={item.id}
                  onClick={handleClick}
                />
              ))}
            </div>
          </Transition.Root>
        </>
      )}
      {!loadingData &&
        otherStores.length === 0 &&
        activeStores.length === 0 && (
          <div className="flex justify-center items-center flex-1">
            <div className="flex flex-col items-center flex-1">
              <NoDataIcon className="self-center" />
              <div className="text-color-text-2 mt-10">
                {t('personal_space:no_businesses_tip')}
              </div>
            </div>
          </div>
        )}
      <BusinessModal
        open={open}
        business={currentBusiness}
        onCancel={handleCancel}
      />
    </PersonalSpaceLayout>
  );
};

export default BusinessOverviewPage;

