import React from 'react';
import type { IIconProps } from '@/types/common-types';

const AddressOptionIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="26"
        height="25"
        viewBox="0 0 26 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="13.4883"
          cy="12.5"
          r="11.75"
          stroke="#0094FF"
          strokeOpacity="0.2"
          strokeWidth="1.5"
        />
      </svg>
    </div>
  );
};

export default AddressOptionIcon;
