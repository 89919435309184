import Highcharts from 'highcharts';
import type { IObject } from '@/types/common-types';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import { handleNumber } from '@/utils/field-utils';
interface AccountPieChartsProps {
  projectBalanceRecord: IObject[];
  showCurrenBalance: boolean;
}

const AccountPieCharts: React.FC<AccountPieChartsProps> = (props) => {
  const [options, setOptions] = useState<Highcharts.Options>({
    title: {
      text: undefined,
    },
  });

  const { projectBalanceRecord = [], showCurrenBalance } = props;

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectBalanceRecord, showCurrenBalance]);

  const getOptions = () => {
    if (!projectBalanceRecord) return;
    const pieData: any[] = [];
    projectBalanceRecord?.forEach((record) => {
      const { amount = '', title = '', color = '' } = record;
      if (title) {
        const item: any = {
          name: title,
          y: Number(amount),
          color: color,
        };

        pieData.push(item);
      }
    });

    const options: Highcharts.Options = {
      chart: {
        type: 'pie',
        borderWidth: 0,
        style: {
          fontFamily: 'Roboto',
        },
      },
      title: {
        text: undefined,
      },
      tooltip: {
        formatter: function () {
          const { y, key } = this as any;
          const value = handleNumber(y);
          return (
            `<b>${key}</b>: ${showCurrenBalance ? value : '***'}`
          );
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: '',
          data: pieData,
          type: 'pie',
        },
      ],
      accessibility: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
    };

    setOptions(options);
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
export default AccountPieCharts;
