import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import AddIcon from '@/components/svg/add-icon';
import EllipsisIcon from '@/components/svg/ellipsis-icon';
import PendingForApproval from './pending-for-approval';
import PendingForTransfer from './pending-for-transfer';
import LatestActivities from './latest-activities';
import type { ColumnsType } from 'antd/es/table';
import { Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import ApprovePayout from '../components/approve-payout';
import RejectionPayout from '../components/rejection-payout';
import CancelPayout from '../components/cancel-payout';
import { getLicense, getState, getValueMultilingual, handleMount, prasePayout } from '@/utils/payouts-utils';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IPayout } from '@/types/payouts-type';
import { TableRecord } from '@/components/table/interface';
import Button from '@/components/common/button';
import Tabs from '@/components/common/tabs';
import { IReducersState } from '@/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { Constants } from '@/constants';
import { getCookie } from '@/utils';
import { queryPayoutRequests, queryActivities } from '@/data/payouts';
import Skeleton from '@/components/skeleton';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';

const TabPane = Tabs.TabPane;
const MenuItem = Menu.Item;

const PayoutsListPage = () => {
  const user = useSelector((state: IReducersState) => state.account.loginUser);
  const permissions = useSelector((state: IReducersState) => state.businessState.permissions);
  const viewId = 'payouts';
  const { lang = 'en', type: activekey = '', p_business_id: businessID = '' } = useParams();
  const [searchParams] = useSearchParams();
  const special = searchParams.get('special') || ''
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  
  const [loadingData, setLoadingData] = useState(false);
  const [showApprove, setShowApprove] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [payout, setPayout] = useState<IPayout>();
  const key = ['approval', 'transfer', 'latest'].includes(activekey)
    ? activekey
    : 'approval';

  const { t: ct } = useTranslation();
  const { t } = useTranslation('payouts');
  const dateFormat = lang === 'en' ? 'MMM DD, YYYY' : 'MMM DD日, YYYY';


  useEffect(() => {
    loadingInit();
    // eslint-disable-next-line
  }, [special === 'true']);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          key: '1',
          name: ct('payouts'),
          type: 'label',
        },
        {
          key: '2',
          name: ct('payouts'),
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  const loadingInit = async() => {
    const token = getCookie(Constants.TOKEN) || '';
    const allPromise: any[] = [
      queryPayoutRequests('waiting_approve', businessID, token, 1, 0),
      queryPayoutRequests('waiting_process', businessID, token, 1, 0),
      queryActivities(businessID, token, 1, 0),
    ];
    
    const result = await Promise.all(allPromise);
    const waitingApprovePayout = result[0];
    const waitingProcessPayout = result[1];
    const latestPayout = result[2];
    if (special === 'true') {
      if (waitingApprovePayout.length === 0) {
        if (waitingProcessPayout.length !== 0) {
          navigate(`/${lang}/businesses/${businessID}/payouts/transfer`);
        } else if (latestPayout.length !== 0) {
          navigate(`/${lang}/businesses/${businessID}/payouts/latest`);
        } else {
          navigate(`/${lang}/businesses/${businessID}/payouts/approval`);
        }
      } else {
        navigate(`/${lang}/businesses/${businessID}/payouts/approval`);
      }
    }
  }

  const columns: ColumnsType<TableRecord> = [
    {
      key: 'payee_name',
      title: t('payee_name'),
      dataIndex: 'payee_name',
      width: 160,
    },
    {
      key: 'payout_category_name',
      title: t('transfer_category'),
      dataIndex: 'payout_category_name',
      width: 160,
      render: (value, record) => {
        const { i18n = {} } = record;
        return getValueMultilingual(value, i18n, lang);
      },
    },
    {
      key: 'transfer_method',
      title: t('transfer_method'),
      dataIndex: 'transfer_method',
      width: 180,
      render: (value, record) => {
        const { i18n = {} } = record;
        return getValueMultilingual(value, i18n, lang);
      },
    },
    {
      key: 'schedule_status',
      title: ct('status'),
      dataIndex: 'schedule_status',
      width: 180,
      render: (_value, record) => {
        return getState(record, lang);
      },
    },
    {
      key: 'total_amount',
      title: t('billing_amount'),
      dataIndex: 'total_amount',
      align: 'right',
      width: 170,
      render: (value, record) => {
        return `${handleMount(value, 2, true)} ${record['currency']}`;
      },
    },
    {
      key: 'destination_amount',
      title: t('transfer_amount'),
      dataIndex: 'destination_amount',
      align: 'right',
      width: 170,
      render: (value, record) => {
        return `${handleMount(value, 2, true)} ${
          record['destination_currency']
        }`;
      },
    },
    {
      key: 'p_created_at',
      title: t('created_at'),
      dataIndex: 'p_created_at',
      align: 'right',
      width: 126,
      render: (value, _record) => {
        let time = value;
        if (time && time.indexOf('T') > -1 && time.indexOf('Z') < 0) {
          time = `${time}Z`;
        }
        if (time) {
          return (
            <span className="whitespace-nowrap">
              {dayjs(time).format(dateFormat)}
              <br />
              {dayjs(time).format('HH:mm:ss')}
            </span>
          );
        } else {
          return <span></span>;
        }
      },
    },
    {
      title: ct('actions'),
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 88,
      render: (_value, record) => {
        const { canApprove, canCancel, canEdit, canReject } = getLicense(
          permissions,
          record,
          user.id
        );
        return (
          <Dropdown
            destroyPopupOnHide={true}
            dropdownRender={() => (
              <Menu className="bg-white">
                <MenuItem key="view">
                  <Link
                    to={`/${lang}/businesses/${businessID}/payouts/${activekey}/${record.id}`}
                  >
                    <div className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5">
                      <span className="text-color-text-1 text-sm font-medium">
                        {ct('view')}
                      </span>
                    </div>
                  </Link>
                </MenuItem>
                {canEdit && (
                  <MenuItem key="edit">
                    <Link
                      to={`/${lang}/businesses/${businessID}/payouts/${activekey}/${record.id}?isEdit=true`}
                    >
                      <div className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5">
                        <span className="text-color-text-1 text-sm font-medium">
                          {ct('edit')}
                        </span>
                      </div>
                    </Link>
                  </MenuItem>
                )}
                {permissions.includes('payouts:approve_and_reject') &&
                  canApprove && (
                    <MenuItem key="approve">
                      <div
                        className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                        onClick={() => {
                          setPayout(prasePayout(record));
                          setShowApprove(true);
                        }}
                      >
                        <span className="text-sm font-medium text-primary-color">
                          {t('approve')}
                        </span>
                      </div>
                    </MenuItem>
                  )}
                {permissions.includes('payouts:approve_and_reject') &&
                  canReject && (
                    <MenuItem key="reject">
                      <div
                        className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                        onClick={() => {
                          setPayout(prasePayout(record));
                          setShowReject(true);
                        }}
                      >
                        <span className="text-error-color text-sm font-medium">
                          {t('reject')}
                        </span>
                      </div>
                    </MenuItem>
                  )}
                {permissions.includes('payouts:approve_and_reject') &&
                  canCancel && (
                    <MenuItem key="cancel">
                      <div
                        className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                        onClick={() => {
                          setPayout(prasePayout(record));
                          setShowCancel(true);
                        }}
                      >
                        <span className="text-error-color text-sm font-medium">
                          {t('cancel_payout')}
                        </span>
                      </div>
                    </MenuItem>
                  )}
              </Menu>
            )}
          >
            <div className="justify-center flex">
              <div className="flex items-center cursor-pointer p-1.5 rounded-full w-max hover:bg-primary-color/10 group">
                <EllipsisIcon className="text-icon-color group-hover:text-primary-color flex" />
              </div>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const handleNew = () => {
    navigate(`/${lang}/businesses/${businessID}/payouts/${activekey}/new`);
  };

  const loading = () => {
    setShowApprove(false);
    setShowReject(false);
    setShowCancel(false);
    setLoadingData(!loadingData);
  };

  return (
    <>
      <div className="flex overflow-hidden h-full">
        <div className="bg-system-background-color px-3 pt-5 pb-2.5 md:px-5 md:py-6 lg:px-8 w-full flex flex-col">
          <div className="md:flex mb-4 justify-between md:mb-6">
            <div className="text-xl leading-[30px] md:text-3xl md:leading-10 font-bold text-color-text-1 mb-4 md:mb-0">
              {ct('payouts')}
            </div>
            <div className="flex flex-col-reverse md:flex-row">
              <div className="flex gap-x-4 justify-start">
                {permissions.includes('payouts:create') &&
                  permissions.includes('payouts:approve_and_reject') && (
                    <Button
                      iconInstance={<AddIcon />}
                      type="button"
                      onClick={handleNew}
                    >
                      {t('new_payout')}
                    </Button>
                  )}
                {permissions.includes('payouts:create') &&
                  !permissions.includes('payouts:approve_and_reject') && (
                    <Button
                      iconInstance={<AddIcon />}
                      type="button"
                      onClick={handleNew}
                    >
                      {t('new_payout_request')}
                    </Button>
                  )}
              </div>
            </div>
          </div>
          {special === 'true' && (
            <>
              <div>
                <Skeleton row={1} width={['100%']} skeletonItemClass="mb-6 h-10 rounded-30" />
              </div>
              <Skeleton
                row={10}
                width={[
                  '60%',
                  '70%',
                  '80%',
                  '90%',
                  '100%',
                  '100%',
                  '90%',
                  '80%',
                  '70%',
                  '60%',
                ]}
              />
            </>
          )}
          {special !== 'true' && (
            <Tabs
              activeIndex={key}
              formItemClass="flex-1 sm:flex flex-col overflow-hidden"
              contentClass="flex flex-col flex-1 overflow-hidden"
              onClickTab={(key) => {
                navigate(`/${lang}/businesses/${businessID}/payouts/${key}`);
              }}
            >
              <TabPane key="approval" title={t('pending_for_approval')}>
                <PendingForApproval
                  columns={columns}
                  businessID={businessID}
                  viewId={viewId}
                  loading={loadingData}
                />
              </TabPane>
              <TabPane key="transfer" title={t('pending_for_transfer')}>
                <PendingForTransfer
                  columns={columns}
                  businessID={businessID}
                  viewId={viewId}
                  loading={loadingData}
                />
              </TabPane>
              <TabPane key="latest" title={t('latest_activities')}>
                <LatestActivities
                  permissions={permissions}
                  businessID={businessID}
                  viewId={viewId}
                />
              </TabPane>
            </Tabs>
          )}
        </div>
      </div>
      {showApprove && payout && (
        <ApprovePayout
          payout={payout}
          businessID={businessID}
          callback={loading}
          onReject={() => {
            setShowApprove(false);
            setShowReject(true);
          }}
          onCancel={() => setShowApprove(false)}
        />
      )}
      {showReject && payout && (
        <RejectionPayout
          payout={payout}
          businessID={businessID}
          callback={loading}
          onCancel={() => setShowReject(false)}
        />
      )}
      {showCancel && payout && (
        <CancelPayout
          payout={payout}
          businessID={businessID}
          callback={loading}
          onCancel={() => setShowCancel(false)}
        />
      )}
    </>
  );
};

export default PayoutsListPage;
