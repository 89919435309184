import React from 'react';
import type { IIconProps } from '@/types/common-types';

const CloseCreditCardIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <circle cx="10" cy="10.5" r="7.25" stroke="#0B1147" strokeWidth="1.5" />
        <path
          d="M6 10.5H14"
          stroke="#0B1147"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default CloseCreditCardIcon;
