import { handleNumber } from '@/utils/field-utils';
import React from 'react';
// import CountUp from 'react-countup';

interface IScrollNumberProps {
  value: number | string;
  boxClass?: string;
  prefix?: string;
  size?: number;
  decimals?: number;
  lineThrough?: boolean;
  showAdd?: boolean
}

const ScrollNumber: React.FC<IScrollNumberProps> = (props) => {
  const {
    value,
    size = 28,
    boxClass = 'h-7',
    prefix = '',
    decimals = 2,
    showAdd = false
  } = props;


  return (
    <div className={`${boxClass}`}>
      {/* <CountUp
        end={Number(value)}
        className={`text-[${size}px]`}
        duration={1}
        separator=","
        decimals={decimals}
        prefix={prefix}
        suffix={suffix}
      /> */}
      <div className={`text-[${size}px] flex items-center`}>
        {prefix && !/[-+]/.test(`${value}`) && (
          <span>{prefix === 'undefined' ? '' : prefix}</span>
        )}
        {showAdd && (
          <span>+ {handleNumber(value, decimals)}</span>
        )}
        {!showAdd && (
          <span>{handleNumber(value, decimals)}</span>
        )}
      </div>
    </div>
  );
};
export default React.memo(ScrollNumber);
