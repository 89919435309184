import React from 'react';
import type { IIconProps } from '@/types/common-types';

const EnIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className} cursor-pointer`} onClick={onClick}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3794_136784)">
          <path
            d="M0.00390625 10.0051C0.00390625 12.6576 1.05742 15.2015 2.93269 17.0771C4.80797 18.9527 7.35138 20.0063 10.0034 20.0063C12.6554 20.0063 15.1989 18.9527 17.0741 17.0771C18.9494 15.2015 20.0029 12.6576 20.0029 10.0051C20.0029 7.35264 18.9494 4.80879 17.0741 2.9332C15.1989 1.0576 12.6554 0.00390625 10.0034 0.00390625C7.35138 0.00390625 4.80797 1.0576 2.93269 2.9332C1.05742 4.80879 0.00390625 7.35264 0.00390625 10.0051H0.00390625Z"
            fill="#F0F0F0"
          />
          <path
            d="M9.57185 10.0036H20.0061C20.0061 9.10087 19.8858 8.22639 19.6616 7.39453H9.57185V10.0036ZM9.57185 4.78554H18.5384C17.927 3.78823 17.1461 2.9053 16.2311 2.17651H9.57185V4.78554ZM10.0066 20.0048C12.36 20.0048 14.523 19.1912 16.2311 17.8306H3.78206C5.49018 19.1912 7.65323 20.0048 10.0066 20.0048ZM1.47475 15.2216H18.5384C19.0358 14.4102 19.414 13.5315 19.6616 12.6126H0.351562C0.599163 13.5315 0.977419 14.4102 1.47475 15.2216Z"
            fill="#D80027"
          />
          <path
            d="M4.63587 1.56574H5.54711L4.6995 2.18163L5.02327 3.1782L4.17569 2.5623L3.32812 3.1782L3.60779 2.31727C2.86012 2.94023 2.20683 3.66845 1.66836 4.47914H1.96033L1.42079 4.87118C1.33665 5.01161 1.25598 5.1541 1.17885 5.2985L1.43649 6.0916L0.955813 5.7423C0.835849 5.99634 0.726746 6.25537 0.628797 6.51869L0.912651 7.39254H1.96033L1.11272 8.00844L1.43649 9.00501L0.588917 8.38911L0.0812071 8.75806C0.0297264 9.17176 0.00391003 9.58824 0.00390625 10.0051H10.0034V0.00390625C8.02804 0.00390625 6.18661 0.577023 4.63587 1.56574ZM5.02327 9.00501L4.17569 8.38911L3.32812 9.00501L3.65189 8.00844L2.80428 7.39254H3.85196L4.17569 6.39598L4.49943 7.39254H5.54711L4.6995 8.00844L5.02327 9.00501ZM4.6995 5.09504L5.02327 6.0916L4.17569 5.47571L3.32812 6.0916L3.65189 5.09504L2.80428 4.47914H3.85196L4.17569 3.48257L4.49943 4.47914H5.54711L4.6995 5.09504ZM8.61005 9.00501L7.76247 8.38911L6.91489 9.00501L7.23867 8.00844L6.39105 7.39254H7.43874L7.76247 6.39598L8.0862 7.39254H9.13389L8.28627 8.00844L8.61005 9.00501ZM8.28627 5.09504L8.61005 6.0916L7.76247 5.47571L6.91489 6.0916L7.23867 5.09504L6.39105 4.47914H7.43874L7.76247 3.48257L8.0862 4.47914H9.13389L8.28627 5.09504ZM8.28627 2.18163L8.61005 3.1782L7.76247 2.5623L6.91489 3.1782L7.23867 2.18163L6.39105 1.56574H7.43874L7.76247 0.569171L8.0862 1.56574H9.13389L8.28627 2.18163Z"
            fill="#0052B4"
          />
        </g>
        <defs>
          <clipPath id="clip0_3794_136784">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default EnIcon;
