import React from 'react';
import type { IIconProps } from '@/types/common-types';

const CurrencyIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 16 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.50528 5.87902C2.04499 8.4549 2.58212 11.7851 4.9318 13.7567C7.58048 15.9793 11.5294 15.6338 13.7519 12.9851M14.6162 11.6396C15.807 9.12793 15.2091 6.03525 12.9802 4.16503C10.4281 2.02356 6.66889 2.26623 4.40999 4.65617"
          stroke="white"
          strokeWidth="1.09177"
          strokeLinecap="round"
        />
        <path
          d="M6.98475 6.3584L5.54688 7.79628H12.2809"
          stroke="white"
          strokeWidth="1.09177"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8434 11.8008L12.2812 10.3629H5.54719"
          stroke="white"
          strokeWidth="1.09177"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default CurrencyIcon;
