import AssociatesInvitePage from "@/pages/associates/invite";
import FxCurrent from "@/pages/global-accounts/fx";
import AddFund from "@/pages/global-accounts/add-fund";
import FxResult from "@/pages/global-accounts/fx-result-page";
import PayeesNewPage from "@/pages/payees/new-page";
import PayoutsNewPage from "@/pages/payouts/new-page";
import PayoutResultPage from "@/pages/payouts/result-page";
import AccountSettingsPage from "@/pages/personal-space/account-settings";
import BusinessOverviewPage from "@/pages/personal-space/business-overview";
import LanguagePage from "@/pages/personal-space/language";
import { RouteObject } from "react-router-dom";
import TerminalLineItemsPage from "@/pages/terminal/pages/line-items";
import TerminalOrdersCheckPage from "@/pages/terminal/pages/check";
import TerminalPaymentPage from "@/pages/terminal/pages/payment";
import TerminalOrdersPaymentResultPage from "@/pages/terminal/pages/payment-result";
import AddressPage from "@/pages/terminal/pages/address";
import EditAddressPage from "@/pages/terminal/pages/edit-address";
import { ReactNode, Suspense } from "react";
import GlobalCheckComponent from "@/components/common/global-check-component";


const lazyComponent = (element: ReactNode): ReactNode => {
  return <Suspense fallback={<></>}> {element} </Suspense>
}

const routerWithoutSidebar: RouteObject[] = [
  {
    path: '/:lang?/personal-space',
    element: <GlobalCheckComponent />,
    children: [
      {
        path: 'business-overview',
        element: lazyComponent(<BusinessOverviewPage />),
      },
      {
        path: 'account-settings',
        element: lazyComponent(<AccountSettingsPage />),
      },
      {
        path: 'language',
        element: lazyComponent(<LanguagePage />),
      },
    ]
  },
  {
    path: '/:lang?/businesses/:p_business_id',
    element: <GlobalCheckComponent />,
    children: [
      {
        path: 'associates/invite',
        element: lazyComponent(<AssociatesInvitePage />),
      },
      {
        path: 'payouts/:type/new',
        children: [
          {
            path: '',
            element: lazyComponent(<PayoutsNewPage />),
          },
          {
            path: ':payout_id',
            element: lazyComponent(<PayoutResultPage />),
          },
        ]
      },
      {
        path: 'payees/new',
        element: lazyComponent(<PayeesNewPage />),
      },
      {
        path: 'global-accounts',
        children: [
          {
            path: ':ledger_account_id',
            children: [
              {
                path: 'fx-results',
                element: lazyComponent(<FxResult />),
              },
              {
                path: 'fx',
                element: lazyComponent(<FxCurrent />),

              },
              {
                path: 'addfund',
                element: lazyComponent(<AddFund />),
              }
            ]
          }
        ]
      },
      {
        path: 'terminal',
        children: [
          {
            path: 'line-items',
            element: lazyComponent(<TerminalLineItemsPage />),

          },
          {
            path: 'orders',
            children: [
              {
                path: 'check',
                element: lazyComponent(<TerminalOrdersCheckPage />),
              },
              {
                path: 'payment',
                children: [
                  {
                    path: '',
                    element: lazyComponent(<TerminalPaymentPage />),
                  },
                  {
                    path: 'result',
                    element: lazyComponent(<TerminalOrdersPaymentResultPage />),
                  },
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'address',
        element: lazyComponent(<AddressPage />),
      },
      {
        path: ':address_Id',
        element: lazyComponent(<EditAddressPage />),
      }
    ]
  },
];

export default routerWithoutSidebar;
