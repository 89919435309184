import { handleBuildOrderPayment } from '../utils';
import { payInvoice } from '../../../../../data/terminal';
import type { IFPSMethod } from '../interface';

interface IFPSPayParams {
  businessID: string;
  orderNumber: string;
  payData: IFPSMethod;
  payment: any;
  email: string;
  lang: string;
}

const UseFPSPay = async (params: IFPSPayParams) => {
  const { businessID, orderNumber, payment, payData, email, lang } = params;

  const { amount, currenToken } = payData;

  const newPayment = {
    fps: [
      {
        amount,
        merchant_presented_qr_code: {},
      },
    ],
  };

  const order = handleBuildOrderPayment(newPayment, payment);
  try {
    const resp:any = await payInvoice({
      businessID,
      orderNumber,
      order,
      email,
      lang,
      token: currenToken,
    });
    const { code, data: fulfilOrder } = resp || {};
    if (
      code === 200 &&
      fulfilOrder.sale_transactions &&
      fulfilOrder.sale_transactions.length > 0
    ) {
      return {
        order: fulfilOrder,
      };
    } else {
      return {
        status: 500,
        ...resp,
      };
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export default UseFPSPay;
