import { IMenuType } from "@/types/side-bar-type";

export const handleRoutePath = (item: IMenuType, businessID: string): string => {
  const { path = '', mode = 'list', url = '' } = item;
  let toPath = '/';

  if (url) {
    toPath = url.replace(`{businessID}`, businessID);
  } else {
    if (path && businessID) {
      switch (mode) {
        case 'list':
          toPath = `/${mode}/${businessID}/${path}/records`;
          break;
        case 'form':
        case 'settings':
        case 'businesses':
          toPath = `/${mode}/${businessID}/${path}`;
          break;
        default:
          toPath = '/';
      }
    }
  }

  return toPath;
};