import { Alert } from "@/components/common/alert/alert";
import AntdModal from "@/components/common/antd/modal";
import { removePayee } from "@/data/payouts";
import { useTranslation } from "react-i18next";

interface ICancelPayoutProps {
  payee: any;
  businessID: string;
  callback: () => void;
  onCancel: () => void;
}

const ArchivePayee: React.FC<ICancelPayoutProps> = (props) => {
  const { payee, businessID, callback, onCancel } = props;
  const { t } = useTranslation('payees');

  const handleArchive = async () => {
    const success = await removePayee(payee.id, businessID);
    if (success) {
      Alert.success({
        message: t('archived_successfully'),
        position: 'default',
        wrapClass: 'top-px',
      });
      callback();
      onCancel();
    } else {
      Alert.error({
        message: t('archived_failed'),
        position: 'default',
        wrapClass: 'top-px',
      });
    }
  };

  return (
    <AntdModal
      open={true}
      type="error"
      closeIcon={null}
      closable={false}
      content={
        <div className="mt-4">
          <div className="font-semibold text-lg leading-6">
            {t('archive_record', { name: payee.nickname })}
          </div>
          <div className="mt-2 text-sm font-medium">
            {t('archive_record_info')}
          </div>
        </div>
      }
      okText={t('archive')}
      onOk={handleArchive}
      onCancel={onCancel}
    />
  );
};

export default ArchivePayee;
