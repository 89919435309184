import { useEffect, useState } from 'react';
import { prasePayout } from '@/utils/payouts-utils';
import api from '@/libs/api';
import Config from '@/libs/config';
import { IPayout } from '@/types/payouts-type';
import { findNode, getCookie } from '@/utils';
import { Constants } from '@/constants';

export const searchRecentPayees = async (
  keyword: string,
  businessID: string,
  token: string,
  lang: string,
) => {
  let result: any = [];
  try {
    const resp = await api.get(
      `${
        Config.urls.gatewayUrl
      }/api/treasury-services/api/payees/last_used_payees?limit=4${
        keyword ? `&keyword=${keyword}` : ''
      }`,
      {
        headers: {
          'x-p-business-id': businessID,
          'x-client-id': Config.clientID,
          'x-platform-id': Config.platformId,
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-i18n-lang': lang,
        },
      }
    );
    const { data } = resp;
    if (Array.isArray(data)) {
      result = data;
    }
  } catch (error) {
    
  }
  return result;
};

export const getCategories = async (
  token: string,
  lang: string,
) => {
  try {
    const resp = await api.get(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/global_information/revenue_expense_categories`,
      {
        headers: {
          'x-client-id': Config.clientID,
          'x-platform-id': Config.platformId,
          'x-i18n-lang': lang,
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
        },
      }
    );
    const { data } = resp || {};
  
    return data || [];
  } catch (error) {
    
  }
  return [];
};

export const getRetrieveBalances = async (
  businessID: string,
  token: string,
) => {
  try {
    const resp = await api.get(
      `${Config.urls.gatewayUrl}/api/retrieve-balances?p_business_id=${businessID}`,
      {
        headers: {
          'x-p-business-id': businessID,
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
        },
      }
    );
    const { data } = resp || {};
    const { ledger_accounts } = data || {};
  
    return ledger_accounts || [];
  } catch (error) {
    
  }
  return [];
};

export const getAvailableCurrencies = async (
  businessID: string,
  token: string,
  lang: string
) => {
  try {
    const resp = await api.get(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/global_information/available_currencies`,
      {
        headers: {
          'x-p-business-id': businessID,
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-i18n-lang': lang === 'en' ? 'en-US' : lang,
          'x-client-id': Config.clientID,
        },
      }
    );
    const { data } = resp || {};
    const { available_currencies } = data || {};
    return available_currencies || [];
  } catch (error) {
    
  }
  return [];
};

export const getAvailableCountries = async (token: string, lang: string) => {
  try {
    const resp = await api.get(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/global_information/available_countries`,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
          'x-i18n-lang': lang === 'en' ? 'en-US' : lang,
          'x-client-id': Config.clientID,
        },
      }
    );
    const { data } = resp || {};
    const { available_countries } = data || {};
    return available_countries || [];
  } catch (error) {
    
  }
  return [];
};

export const getPayoutMethod = async (
  businessID: string,
  token: string,
  currency: string,
  country: string,
  lang: string
) => {
  try {
    const resp = await api.get(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/transfer_methods?to_currency_code=${currency}&to_country_code=${country}`,
      {
        headers: {
          'x-p-business-id': businessID,
          'X-USER-ACCESS-TOKEN': token,
          'x-i18n-lang': lang,
          'x-client-id': Config.clientID,
          'x-platform-id': Config.platformId,
        },
      }
    );
    const { data } = resp || {};
    return data || [];
  } catch (error) {
    
  }
  return [];
};

export const payoutStatusUpdate = async (
  businessID: string,
  payoutID: string,
  status: string,
  fxQuoteID?: string,
  remark?: string
) => {
  try {
    const token = getCookie(Constants.TOKEN) || '';
    const resp = await api.post(
      `${Config.urls.gatewayUrl}/api/treasury-services/payout_requests/status`,
      {
        p_business_id: businessID,
        payout_request: payoutID,
        status,
        fx_quote_id: fxQuoteID || null,
        error_remark: remark || null,
      },
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'Content-Type': 'application/json',
        },
      }
    );
    const { data } = resp || {};
    const { code, msg } = data || {};
    if (code === 0 && msg === 'success') {
      return true;
    }
  } catch (error) {
    
  }

  return false;
};

export const getPayout = async (
  payoutID: string,
  businessID: string,
  token: string,
) => {
  let payout: any = {};
  try {
    const resp = await api.get(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/payout_requests/${payoutID}`,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'Content-Type': 'application/json',
        },
      }
    );
  
    const { data } = resp;
    if (data && data.id) {
      payout = prasePayout(data);
    }
  } catch (error) {
    
  }
  return payout;
};

export const exportPDF = async (
  businessID: string,
  payout: IPayout,
  business: any
) => {
  const token = getCookie(Constants.TOKEN) || '';
  const { payee } = payout;
  let result: any = {};
  try {
    if (payee) {
      const { payout_accounts } = payee || {};
      const resp: any = await api.post(
        `${Config.urls.gatewayUrl}/api/helper/generate-pdf`,
        {
          fileData: {
            payout: {
              id: payout.id,
              business_id: businessID,
              reference: payout.reference,
              po_number: payout.po_number,
              remark: payout.remark,
              destination_amount: payout.destination_amount,
              destination_currency: payout.destination_currency,
              estimated_settled_at: payout.estimated_settled_at,
              settled_at: payout.settled_at,
              transfer_status: payout.transfer_status,
              schedule_status: payout.schedule_status,
            },
            payee: {
              payee_name: payout.payee_name,
              category_name: payout.payout_category_name,
              country_name: payout_accounts.country_codes.country_name,
              transfer_method: payout.transfer_method,
              identifier_1_value: payout_accounts.identifier_1_value,
              identifier_2_value: payout_accounts.identifier_2_value,
              identifier_3_value: payout_accounts.identifier_3_value,
              bank_code: payout_accounts.banks.bank_code,
              bank_name: payout_accounts.banks.bank_name,
              swift_code: payout_accounts.banks.swift_code,
              business_name: payout_accounts.business_name,
              business_legal_name: payout_accounts.business_legal_name,
            },
            business: business,
            liquid_Config: {
              locale: 'en',
            },
          },
          templateType: 'payout_transfer_notice',
          fileName: `${payout.id}.pdf`,
          folderName: businessID,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-p-business-id': businessID,
            'X-USER-ACCESS-TOKEN': token,
            'x-client-id': Config.clientID,
          },
        }
      );

      if (resp && resp.code === 200 && resp.data && resp.data.url) {
        result = resp.data;
      }
    }
  } catch (error) {
    console.error(error);
  }

  return result;
};

export const getBusiness = async (businessID: string, token: string) => {
  let business: any = {};
  try {
    const resp: any = await api.get(
      `${Config.urls.gatewayUrl}/api/registry/businesses/${businessID}`,
      {
        headers: {
          'x-user-access-token': token,
        },
      }
    );
    const { code, data } = resp;
    const countries = await api.get(
      `${Config.urls.gatewayUrl}/api/registry/countries`,
      {
        headers: {
          'x-user-access-token': token,
        },
      }
    );
  
    const { data: countriesData } = countries;
    if (code === 200 && data && data.p_business_id) {
      const country = findNode<any>(
        countriesData,
        'alpha_2',
        data.country.alpha_2
      );
      business = {
        ...data,
        country_name: country.country_name,
      };
    }
  } catch (error) {
    
  }

  return business;
};

export const useFrontendRender = () => {
  const [isFronend, setIsFronend] = useState(false);
  useEffect(() => {
    setIsFronend(true);
  }, []);
  return isFronend;
};

export const getBank = async (
  swiftCode: string,
  businessID: string,
  token: string,
  lang: string
) => {
  try {
    const resp = await api.get(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/banks/by_swift_code/${swiftCode}`,
      {
        headers: {
          'x-client-id': Config.clientID,
          'x-platform-id': Config.platformId,
          'X-USER-ACCESS-TOKEN': token,
          'x-p-business-id': businessID,
          'x-i18n-lang': lang === 'en' ? 'en-US' : lang,
          'Content-Type': 'application/json',
        },
      }
    );
    return resp;
  } catch (error) {
    
  }
  return {}
};

export const verifyBusiness = async (
  businessID: string,
  token: string,
  lang: string
) => {
  let resp: any = {}
  try {
    resp = await api.get(
      `${Config.urls.gatewayUrl}/api/registry/businesses/${businessID}`,
      {
        headers: {
          'x-user-access-token': token,
          'x-i18n-lang': lang,
        },
      }
    );
  
  } catch (error) {
    resp = error
  }
  return resp;
};

export const createPayee = async (
  businessID: string,
  token: string,
  payeeData: any,
  lang: string
) => {
  let resp: any = '';
  let result = {
    code: 100,
    message: '',
    data: {
      payee: {
        id: '',
      },
    },
  };
  try {
    resp = await api.post(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/payees`,
      {
        ...payeeData,
      },
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'x-client-id': Config.clientID,
          'x-platform-id': Config.platformId,
          'x-i18n-lang': lang,
        },
      }
    );
  
    result = resp;
    const { data } = resp || {};
    if (data.payee && data.payee.id) {
      resp = await api.get(
        `${Config.urls.gatewayUrl}/api/treasury-services/api/payees/${data.payee.id}`,
        {
          headers: {
            'x-user-access-token': token,
            'x-p-business-id': businessID,
            'x-client-id': Config.clientID,
            'x-platform-id': Config.platformId,
            'x-i18n-lang': lang,
          },
        }
      );
  
      if (resp && resp.data && resp.data.id) {
        result.data.payee = resp.data;
      }
    }
  } catch (error) {
    
  }

  return result;
};

export const getPurposeOfTransfer = async (
  token: string,
  transferMethod: string,
  lang: string
) => {
  try {
    const resp = await api.get(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/global_information/purpose_of_transfer?transfer_method=${transferMethod}`,
      {
        headers: {
          'x-user-access-token': token,
          'x-i18n-lang': lang,
        },
      }
    );
    const { data } = resp;
    if (Array.isArray(data)) {
      return data;
    }
  } catch (error) {
    
  }
  return [];
};

export const queryPayoutRequests = async (
  type: string,
  businessID: string,
  token: string,
  limit: number,
  offset: number
) => {
  let payouts = [];
  try {
    const resp = await api.get(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/payout_requests?type=${type}&limit=${limit}&offset=${offset}`,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
        },
      }
    );
    const { data } = resp || {};
    if (data && Array.isArray(data)) {
      payouts = data;
    }
  } catch (error) {}

  return payouts;
};

export const queryActivities = async (
  businessID: string,
  token: string,
  limit: number,
  offset: number
) => {
  let payouts = [];
  try {
    const resp = await api.post(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/activities`,
      {
        limit,
        offset,
        actions: ['payout'],
        type: 'all',
      },
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'Content-Type': 'application/json',
        },
      }
    );
    const { data } = resp || {};
    const { activities } = data || {};
    if (activities && Array.isArray(activities)) {
      payouts = activities;
    }
  } catch (error) {}

  return payouts;
};

export const removePayee = async (id: string, businessID: string) => {
  try {
    const token = getCookie(Constants.TOKEN) || '';
    const result = await api.remove(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/payees/${id}`,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
        },
      }
    );
    const { data } = result;

    if (data === id) {
      return true;
    }
  } catch (error) {
    
  }
  return false;
};

export const getPayee = async (
  id: string,
  businessID: string,
  token: string
) => {
  try {
    const result = await api.get(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/payees/${id}`,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
        },
      }
    );
    const { data } = result;
  
    if (data && data.id) {
      return data;
    }
  } catch (error) {
    
  }
  return {};
};

export const updataPayee = async (
  id: string,
  businessID: string,
  payee: any
) => {
  let payeeID = '';
  try {
    const token = getCookie(Constants.TOKEN) || '';
    const result = await api.put(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/payees/${id}`,
      {
        ...payee,
      },
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'Content-Type': 'application/json',
        },
      }
    );
    const { data } = result;

    payeeID = data;
  } catch (error) {
    
  }
  return payeeID;
};