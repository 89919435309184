import { IAreaCodes } from '@/types/common-types';
import { useEffect, useState } from 'react';
import AddIcon from '../terminal-icons/address-add-icon';
import EditIcon from '../terminal-icons/address-edit-icon';
import OptionIcon from '../terminal-icons/address-option-icon';
import CheckIcon from '../terminal-icons/address-check-icon';
import { insetHead, triggerWonderRoute } from '../../../utils/terminal-untils';
import { useTranslation } from 'react-i18next';
import { updateAddressRecord } from '../../../data/terminal';
import Skeleton from '@/components/skeleton';
import { useNavigate } from 'react-router-dom';

import type {
  IAddressObject,
  ITerminalOrdersCheckPageProps,
} from '../../../types/terminal-type';
import NetworkError from '../components/network-error';
interface IAddressListProps extends ITerminalOrdersCheckPageProps {
  code: string;
  loading: boolean;
  dataTimeOut: boolean;
  currency: string;
  areaCodes: IAreaCodes[];
  token: string;
  customerID: string;
  addressList: IAddressObject[];
  selectedAddress?: IAddressObject;
  loadAddress: () => void;
}

const AddressListPage: React.FC<IAddressListProps> = (props) => {
  const {
    businessID,
    token,
    inApp,
    loading,
    dataTimeOut,
    selectedAddress,
    addressList,
    tokenExpire = false,
    loadAddress,
  } = props;
  const navigate = useNavigate();
  // const location = useLocation();
  const [selectAdressId, setSelectAdressId] = useState('');
  const [selectedRecord, setSelectedRecord] = useState<
    IAddressObject | undefined
  >(selectedAddress);
  const { t } = useTranslation(["extended_service", "transaction_advice"]);

  useEffect(() => {
    if (tokenExpire) {
      triggerWonderRoute('true', 'is401');
    }
    addressList.forEach((item: any) => {
      if (item.is_default) {
        setSelectAdressId(item.id);
        setSelectedRecord(item);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressList]);

  useEffect(() => {
    window.addEventListener(
      'myCustomEvent',
      (e: any) => {
        if (e.detail.isLoading === 'true') {
          loadAddress();
        }
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goEdit = (val: string, item?: any) => {
    triggerWonderRoute('show', 'loading');
    let msg = '';
    let routeAddress = '';
    if (val === 'edit') {
      msg = 'is_address_edit';
      routeAddress = `/businesses/${businessID}/${msg}?token=${token}&isApp=${inApp}&addressId=${item.id}`;
    } else {
      msg = 'is_address_add';
      routeAddress = `/businesses/${businessID}/${msg}?token=${token}&isApp=${inApp}`;
    }
    if (inApp) {
      triggerWonderRoute('close', 'loading');
      triggerWonderRoute(routeAddress, 'route');
    } else {
      navigate(routeAddress);
    }
  };

  const handleUpdateRecord = async (
    record: IAddressObject | undefined = selectedRecord,
    isUpdateDefault = false
  ) => {
    if (record && record.id) {
      const newRecord = {
        ...record,
      };
      if (isUpdateDefault) {
        newRecord.is_default = true;
      }
      const result = await updateAddressRecord(businessID, token, newRecord);
      if (result.id) {
        triggerWonderRoute('address_confirm_success', 'option_result');
      } else {
        const data = {
          status: 'address_confirm_fail',
          msg: result.error,
        };
        triggerWonderRoute(JSON.stringify(data), 'option_result');
      }
      if (isUpdateDefault) {
        await loadAddress();
      }
    } else {
      const data = {
        status: 'address_confirm_fail',
        msg: 'error',
      };
      triggerWonderRoute(JSON.stringify(data), 'option_result');
    }
  };

  const confirm = () => {
    if (selectedRecord) {
      triggerWonderRoute('show', 'loading');
      handleUpdateRecord(undefined, true);
    }
  };

  const selectAdress = (item: any) => {
    setSelectAdressId(item.id);
    setSelectedRecord(item);
  };

  return (
    <div
      className="min-h-screen bg-[#F5F5F5] overflow-auto"
      style={{ WebkitTapHighlightColor: 'transparent' }}
    >
      {insetHead()}
      <div
        className={`absolute inset-0 h-full flex flex-col font-sfPro overflow-x-hidden overflow-y-auto lg:hidden hidden_scrollbar pb-16`}
      >
        {/* {!dataTimeOut && ( */}
        <div className={`bg-[#F5F5F5] flex-1 hidden_scrollbar`}>
          <div className="p-5 pb-24">
            {loading && (
              <>
                <div className="bg-primary-background-color rounded-xl p-4 mt-5 h-[90px]">
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-[17px] !w-[124px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                    className={'!h-[17px] mt-1'}
                  />
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-[17px] !w-[95%] rounded-2xl mb-0 bg-[#D3CFCF]`}
                    className={'!h-[17px] mt-4'}
                  />
                </div>
                <div className="bg-primary-background-color rounded-xl p-4 mt-5 h-[90px]">
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-[17px] !w-[124px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                    className={'!h-[17px] mt-1'}
                  />
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-[17px] !w-[95%] rounded-2xl mb-0 bg-[#D3CFCF]`}
                    className={'!h-[17px] mt-4'}
                  />
                </div>
                <div className="bg-primary-background-color rounded-xl p-4 mt-5 h-[57px]">
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-[17px] !w-[178px] rounded-2xl mb-0 bg-[#D3CFCF]`}
                    className={'!h-[17px] mt-1'}
                  />
                </div>
              </>
            )}
            {!loading && !dataTimeOut && (
              <>
                {addressList.map((item: any) => {
                  return (
                    <div
                      className="bg-primary-background-color rounded-xl p-4 mt-5"
                      key={item.id}
                    >
                      <div>
                        <div className="flex justify-between items-center text-base font-semibold">
                          <span>{item.name}</span>
                          {selectAdressId !== item.id && (
                            <OptionIcon onClick={() => selectAdress(item)} />
                          )}
                          {selectAdressId === item.id && <CheckIcon />}
                        </div>
                        <div className={` max-w-[160px] truncate mt-1`}>
                          {item.phone}
                        </div>
                        <div className={` max-w-[280px]  mt-1`}>
                          {item.address} {item.street}
                        </div>
                        <div
                          className={` truncate mt-1 flex justify-between items-center`}
                        >
                          <div>{t('extended_service:hong_kong')}</div>{' '}
                          <EditIcon onClick={() => goEdit('edit', item)} />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="bg-primary-background-color rounded-xl p-4 mt-5">
                  <div
                    className="text-[#0094FF] flex items-center"
                    onClick={() => goEdit('add')}
                  >
                    <AddIcon />
                    {t('extended_service:add_new_address')}
                  </div>
                </div>
              </>
            )}
            {dataTimeOut && !loading &&(
              <NetworkError refresh={() => loadAddress()} noPadding={true} />
            )}
          </div>
          {!dataTimeOut && (
            <div className="fixed bottom-0 w-[100%] bg-primary-background-color  pb-[24px] pt-[22px] ">
              <div className="flex items-center px-4 w-full ">
                <div
                  className="!border-none bg-[#0094FF] w-full h-12 rounded-xl font-sfPro text-white hover:text-color-text-6 !text-base !font-semibold flex justify-center items-center"
                  onClick={confirm}
                >
                  <span>{t('common:confirm')}</span>
                </div>
              </div>
            </div>
          )}

        </div>
        {/* )} */}

      </div>
    </div>
  );
};

export default AddressListPage;
