import React from 'react';
import type { IIconProps } from '@/types/common-types';

const LanguageIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <mask
          id="mask0_431_7915"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <rect width="20" height="20" fill="#71829A" />
        </mask>
        <g>
          <path
            d="M10 18L9 15H3.5C3.08333 14.9861 2.72917 14.8368 2.4375 14.5521C2.14583 14.2674 2 13.9167 2 13.5V3.5C2 3.09722 2.14583 2.74653 2.4375 2.44792C2.72917 2.14931 3.08333 2 3.5 2H8L9 5H16.5C16.9028 5 17.2535 5.14931 17.5521 5.44792C17.8507 5.74653 18 6.09722 18 6.5V16.5C18 16.9167 17.8507 17.2708 17.5521 17.5625C17.2535 17.8542 16.9028 18 16.5 18H10ZM6.0625 12C6.85417 12 7.53125 11.7431 8.09375 11.2292C8.65625 10.7153 8.9375 9.99306 8.9375 9.0625C8.9375 8.96528 8.93403 8.86806 8.92708 8.77083C8.92014 8.67361 8.90278 8.56944 8.875 8.45833H6.0625V9.47917H7.8125C7.77083 9.9375 7.58333 10.2951 7.25 10.5521C6.91667 10.809 6.52083 10.9375 6.0625 10.9375C5.53472 10.9375 5.08681 10.75 4.71875 10.375C4.35069 10 4.16667 9.54167 4.16667 9C4.16667 8.47222 4.35069 8.01736 4.71875 7.63542C5.08681 7.25347 5.53472 7.0625 6.0625 7.0625C6.28472 7.0625 6.5 7.10417 6.70833 7.1875C6.91667 7.27083 7.10417 7.38889 7.27083 7.54167L8.0625 6.77083C7.78472 6.50694 7.47222 6.3125 7.125 6.1875C6.77778 6.0625 6.42361 6 6.0625 6C5.22917 6 4.52083 6.29167 3.9375 6.875C3.35417 7.45833 3.0625 8.16667 3.0625 9C3.0625 9.83333 3.35417 10.5417 3.9375 11.125C4.52083 11.7083 5.22917 12 6.0625 12ZM11.3542 12.7083L11.75 12.3125C11.5556 12.1042 11.3819 11.8854 11.2292 11.6562C11.0764 11.4271 10.9306 11.1875 10.7917 10.9375L11.3542 12.7083ZM12.3333 11.6875C12.6111 11.3542 12.8681 11.0035 13.1042 10.6354C13.3403 10.2674 13.5139 9.86111 13.625 9.41667H10.3125L10.6042 10.2917L11.4167 10.2708C11.5417 10.5347 11.6771 10.7847 11.8229 11.0208C11.9688 11.2569 12.1389 11.4792 12.3333 11.6875ZM11 17H16.5C16.6389 17 16.7569 16.9549 16.8542 16.8646C16.9514 16.7743 17 16.6528 17 16.5V6.5C17 6.36111 16.9514 6.24306 16.8542 6.14583C16.7569 6.04861 16.6389 6 16.5 6H9.25L10.0417 8.5625H11.7083V7.70833H12.5417V8.5625H15.5625V9.41667H14.5C14.3611 9.97222 14.1528 10.4896 13.875 10.9688C13.5972 11.4479 13.2778 11.8958 12.9167 12.3125L15.1667 14.5208L14.5625 15.125L12.3542 12.9167L11.625 13.625L12.375 16L11 17Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </div>
  );
};

export default LanguageIcon;
