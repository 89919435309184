/**
 *  Settled Transactions (Previous Settlements)
 */
import { Transition } from '@headlessui/react';
import { TransactionType } from '@/types/account-balance-type';
import ExchangeDetails from './exchange-details';
import CardDetails from './card-details';
import PayInDetails from './pay-in-details';
import PayOutDetails from './payout-details';
import BackIcon from '@/components/svg/back-icon';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ActionType from '@/actions/action-type';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';

const TransactionDetailsPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const upcoming = searchParams.get('upcoming') || '';
  const { t } = useTranslation("account_balance");
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const { type: transactionType = '', p_business_id: businessID = '', lang = 'en', transaction_id: recordID = '', } = useParams();
  
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('common:company_account'),
          key: '1',
          type: 'label',
        },
        {
          name: t('account_balance:account_balance'),
          key: '2',
          type: 'link',
          url: `/${lang}/businesses/${businessID}/account-balance/overview`,
        },
        {
          name: recordID,
          key: '3',
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordID, lang])

  return (
    <Transition.Root show={true} appear={true}>
      <div className="px-4 md:px-6 lg:px-8 py-6">
        <div className="flex mb-6 justify-between">
          <div className="flex">
            <div
              onClick={handleBack}
              className="bg-disabled-color shadow-box-drop rounded-full w-10 h-10 flex items-center justify-center cursor-pointer hover:text-primary-color"
            >
              <BackIcon />
            </div>
            <div className="text-3xl leading-10 font-bold text-color-text-1 ml-2.5 invisible sm:visible">
              {t('account_balance:transaction_details')}
            </div>
          </div>
        </div>
        {transactionType === TransactionType.exChange && (
          <ExchangeDetails
            recordId={recordID}
            businessId={businessID}
            upcoming={upcoming}
          />
        )}
        {transactionType === TransactionType.card && (
          <CardDetails
            recordId={recordID}
            businessId={businessID}
            upcoming={upcoming}
          />
        )}
        {transactionType === TransactionType.payIn && (
          <PayInDetails
            recordId={recordID}
            businessId={businessID}
            upcoming={upcoming}
          />
        )}
        {transactionType === TransactionType.payOut && (
          <PayOutDetails recordId={recordID} businessId={businessID} />
        )}
      </div>
    </Transition.Root>
  );
};

export default TransactionDetailsPage;
