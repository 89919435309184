import React from 'react';
import type { IIconProps } from '@/types/common-types';

const SelectedIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="26"
        viewBox="0 0 25 26"
        fill="none"
      >
        <circle cx="12.5" cy="13" r="12.5" fill="#0094FF" fillOpacity="0.2" />
        <path
          d="M8.33203 13.9259L11.5728 17.1666L16.6654 10.2222"
          stroke="#0094FF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default SelectedIcon;
