import { IObject } from "./common-types";

export interface IBusiness extends IObject {
  id: string;
  business_name: string;
  country_code: string;
  city: string;
  country_name: string;
  status: string;
  state: string;
  address_1: string;
  address_2: string;
  zip_code: string;
  associate_status: string;
  currency_code: string;
  projectedBalance: number;
  timezone: string;
  translations: any;
  business_type: string;
  business_application_status: string;
  business_application_uuid: string;
  default_currency: string;
  business_legal_name: string;
  settlement_entity_type: string;
}

export enum BusinessStatus {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  REJECTED = 'rejected',
  UNDER_REVIEW = 'under review',
  INACTIVE = 'inactive',
  PENDING_ADMISSION = 'pending admission',
  DORMANT = 'dormant',
  REQUIRED_APPLICANT_VERIFICATION = 'required applicant verification',
  ABANDONED = 'abandoned',
  INCOMPLETE_APPLICATION = 'incomplete application',
  LEGACY_POS_ACCOUNT = 'legacy pos account',
  LEGACY_PAYMENT_ACCOUNT = 'legacy payment account',
  MANUAL_UNDERWRITING = 'manual underwriting',
  REQUIRED_ADDITIONAL_CPMPANY_MEMBERS = 'require additional company members',
  PENDING_PRIMARY_NATURAL_PERSON = 'pending primary natural person',
}