import styles from '@/pages/content.module.css';
import Config from '@/libs/config';
import { useParams } from 'react-router-dom';

const PrivacyPolicy = () => {
  const { lang = 'en' } = useParams();
  const prefix = Config.staticPath;

  return (
    <>
      <head>
        <link
          rel="preload"
          href={`${prefix}/font/Outfit-Light.woff`}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="preload"
          href={`${prefix}/font/Outfit-Bold.woff`}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        ></link>
      </head>
      {lang !== 'zh-CN' && lang !== 'zh-HK' && (
        <div className={`${styles.cancleHeader}`}>
          <div className={`${styles.center}`}>
            {/* <div className={`${styles.title} text-center`}>Terms and Conditions</div> */}
            <div className={`${styles.content} text-base break-all`}>
              <h3>I. General Provisions</h3>
              <p>Thank you for choosing to use our ride-hailing platform (hereinafter referred to as "the Platform"). This Payment Agreement (hereinafter referred to as "the Agreement") governs the terms and conditions related to payments made when using the services of the Platform. By making any payment through the Platform, you agree to accept and comply with the terms of this Agreement. This Agreement is formulated in accordance with the laws of the Hong Kong Special Administrative Region.</p>
              <h3>II. Payment Obligations</h3>
              <p>2.1 Service Fees</p>
              <p>When you book a vehicle through the Platform and complete the trip, service fees will be generated based on the cost calculation method corresponding to the trip. These fees will include, but are not limited to, distance fees, time fees, surcharges (if applicable), and any applicable taxes.</p>
              <p>2.2 Fee Calculation</p>
              <p>The fees will be calculated based on the distance and duration of your trip and may be adjusted based on traffic conditions, weather conditions, and other factors. All fee calculations are completed through the Platform's automated system.</p>
              <p>2.3 Final Fee Confirmation</p>
              <p>After the trip ends, you will receive a trip receipt detailing the final costs. Your payment obligation is based on these confirmed final costs.</p>
              <h3>III. Payment Methods</h3>
              <p>3.1 Accepted Payment Methods</p>
              <p>The Platform accepts the following payment methods:</p>
              <ul>
                <li>Credit cards (Visa, MasterCard, UnionPay, JCB, Diners, Discover)</li>
                <li>Electronic wallets (such as Alipay, WeChat Pay, etc.)</li>
                <li>Other payment methods specified by the Platform</li>
              </ul>
              <p>3.2 Pre-Authorization Transactions</p>
              <p>To ensure payment security and user credit, the Platform may pre-authorize an amount at the time of booking the trip. This pre-authorized amount will be adjusted based on the actual costs after the trip ends.</p>
              <p>3.3 Automatic Deductions</p>
              <p>After registering and saving your payment information, the system will automatically deduct the corresponding fees from your account after each trip.</p>
              <h3>IV. Invoices and Receipts</h3>
              <p>After the trip ends, the Platform will send you a trip receipt via email. If you need an official invoice, please apply through the Platform's customer service, confirming your mailing address and invoice details.</p>
              <h3>V. Refund and Cancellation Policy</h3>
              <p >5.1 Cancellation Policy</p>
              <p>You may cancel your booking at any time before the vehicle arrives. Depending on the timing of the cancellation, a cancellation fee may be charged. Specific cancellation fees will be clearly communicated through the Platform before you cancel.</p>
              <p>5.2 Refund Conditions</p>
              <p>If the service cannot be provided due to reasons attributable to the Platform, the corresponding fees will be fully refunded to your original payment method. Refund requests due to reasons attributable to the user will be processed according to the Platform's refund policy.</p>
              <h3>VI. Security and Fraud Prevention</h3>
              <p>To ensure the security of user payments, the Platform adopts industry-standard encryption technologies to protect your payment information. We also regularly review and update our security measures to combat potential fraudulent activities.</p>
              <h3>VII. Dispute Resolution</h3>
              <p>7.1 Dispute Notification</p>
              <p>If you have any questions regarding billing or payments, please contact the Platform's customer service within seven working days after the trip ends. We will review your issues promptly and respond.</p>
              <p>7.2 Dispute Mediation</p>
              <p>For disputes arising from payments, the Platform will provide mediation services to assist in resolution. If an agreement cannot be reached, you have the right to submit the dispute to the relevant courts of the Hong Kong Special Administrative Region.</p>
              <h3>VIII. Amendments and Changes</h3>
              <p>The Platform reserves the right to modify or change the terms of this Payment Agreement at any time. Such modifications or changes will take effect immediately upon posting on the Platform. Continuing to use the Platform's services will be considered acceptance of the amended agreement.</p>
              <h3>IX. Applicable Law and Jurisdiction</h3>
              <p>The interpretation, application, and resolution of disputes under this Agreement shall be governed by the laws of the Hong Kong Special Administrative Region. Any litigation arising from this Agreement shall be subject to the jurisdiction of the courts of the Hong Kong Special Administrative Region.</p>
              <h3>X. Other Terms</h3>
              <p>If any provision of this Agreement is deemed invalid or unenforceable for any reason, that provision will be deleted, and the remaining provisions will continue to be fully effective.</p>
              <h3>XI. Contact Information</h3>
              <p>If you have any questions about this Payment Agreement or need further information, please contact our customer service department through the following means:</p>
              <ul>
                <li>Email: support@wonder.app</li>
                <li>Phone: 4600 4630</li>
                <li>Address: Suite 05-181, 5/F The Quayside, 77 Hoi Bun Road, Kwun Tong, Kowloon, Hong Kong</li>
              </ul>
              <p>This Payment Agreement constitutes the entire agreement between you and the Platform regarding payment services and supersedes any prior discussions or agreements.</p>
              <p>Please read this Agreement carefully to ensure you fully understand its terms. By using the Platform's services, you are indicating that you accept these terms.</p>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-CN' && (
        <div className={`${styles.cancleHeader}`}>
          <div className={`${styles.center}`}>
            {/* <div className={`${styles.title} text-center`}>条款与细则</div> */}
            <div className={`${styles.content} text-base  break-all`}>
              <h3>I. 通用条款</h3>
              <p>感谢您选择使用我们的网约车平台（以下简称“平台”）。本支付协议（以下简称“协议”）规定了使用平台服务时进行支付的条款和条件。通过平台进行支付，即表示您同意接受并遵守本协议条款。本协议是根据香港特别行政区的法律制定的。</p>
              <h3>II. 支付义务</h3>
              <p>2.1 服务费</p>
              <p>通过平台预订车辆并完成行程时，将根据行程对应的费用计算方法产生服务费。这些费用将包括但不限于距离费、时间费、附加费（如适用）以及任何适用的税费。</p>
              <p>2.2 费用计算</p>
              <p>费用将根据您的行程距离和持续时间计算，并可能根据交通状况、天气条件及其他因素进行调整。所有费用计算均通过平台的自动化系统完成。</p>
              <p>2.3 最终费用确认</p>
              <p>行程结束后，您将收到一份详细说明最终费用的行程收据。您的支付义务基于这些确认的最终费用。</p>
              <h3>III. 支付方式</h3>
              <p>3.1 接受的支付方式</p>
              <p>平台接受以下支付方式：</p>
              <ul>
                <li>信用卡（Visa, MasterCard, 银联, JCB, Diners, Discover）</li>
                <li>电子钱包（如支付宝、微信支付等）</li>
                <li>平台指定的其他支付方式</li>
              </ul>
              <p>3.2 预授权交易</p>
              <p>为确保支付安全和用户信用，平台可能在预订行程时进行金额预授权。根据行程结束后的实际成本，预授权金额将进行调整。</p>
              <p>3.3 自动扣费</p>
              <p>注册并保存您的支付信息后，系统将在每次行程结束后自动从您的账户中扣除相应的费用。</p>
              <h3>IV. 发票和收据</h3>
              <p>行程结束后，平台将通过电子邮件发送行程收据给您。如果您需要正式发票，请通过平台的客户服务申请，并确认您的邮寄地址和发票详情。</p>
              <h3>V. 退款和取消政策</h3>
              <p>5.1 取消政策</p>
              <p>您可以在车辆到达前随时取消预订。根据取消的时间，可能会收取取消费。具体的取消费用将通过平台在您取消前明确告知。</p>
              <p>5.2 退款条件</p>
              <p>如果由于平台原因导致无法提供服务，相应的费用将全额退还至您的原支付方式。由于用户原因提出的退款请求将根据平台的退款政策处理。</p>
              <h3>VI. 安全和防欺诈</h3>
              <p>为确保用户支付的安全，平台采用行业标准的加密技术保护您的支付信息。我们还定期审查并更新我们的安全措施以对抗潜在的欺诈活动。</p>
              <h3>VII. 争议解决</h3>
              <p>7.1 争议通知</p>
              <p>如果您对账单或支付有任何疑问，请在行程结束后的七个工作日内联系平台的客户服务。我们将迅速审查您的问题并作出回应。</p>
              <p>7.2 争议调解</p>
              <p>对于因支付产生的争议，平台将提供调解服务以协助解决。如果无法达成协议，您有权将争议提交香港特别行政区的相关法院。</p>
              <h3>VIII. 修改和变更</h3>
              <p>平台保留随时修改或更改本支付协议条款的权利。此类修改或变更自在平台上发布即刻生效。继续使用平台的服务将被视为接受修改后的协议。</p>
              <h3>IX. 适用法律和管辖权</h3>
              <p>本协议下的解释、应用及争议解决均受香港特别行政区法律的管辖。任何因本协议产生的诉讼均应提交香港特别行政区的法院处理。</p>
              <h3>X. 其他条款</h3>
              <p>如果本协议的任何条款因任何原因被认定为无效或不可执行，该条款将被删除，其余条款将继续完全有效。</p>
              <h3>XI. 联系信息</h3>
              <p>如果您对本支付协议有任何疑问或需要更多信息，请通过以下方式联系我们的客户服务部门：</p>
              <ul>
                <li>电子邮件：support@wonder.app</li>
                <li>电话：4600 4630</li>
                <li>地址：香港观塘海滨道77号港滨汇5楼05-181室</li>
              </ul>
              <p>本支付协议构成您与平台关于支付服务的全部协议，并取代任何先前的讨论或协议。</p>
              <p>请仔细阅读本协议，确保您完全理解其条款。通过使用平台的服务，您表明您接受这些条款。</p>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-HK' && (
        <div className={`${styles.cancleHeader}`}>
          <div className={`${styles.center}`}>
            {/* <div className={`${styles.title} text-center`}>條款與細則</div> */}
            <div className={`${styles.content} text-base break-all`}>
            <h3>I. 通用條款</h3>
              <p>感謝您選擇使用我們的叫車平台（以下簡稱「平台」）。 本支付協議（以下簡稱「協議」）規定了使用平台服務時進行支付的條款和條件。 透過平台進行支付，即表示您同意接受並遵守本協議條款。 本協議是根據香港特別行政區的法律制定的。</p>
              <h3>II. 支付義務</h3>
              <p>2.1 服務費</p>
              <p>透過平台預訂車輛並完成行程時，將根據行程對應的費用計算方法產生服務費。 這些費用將包括但不限於距離費、時間費、附加費（如適用）以及任何適用的稅金。</p>
              <p>2.2 費用計算</p>
              <p>費用將根據您的行程距離和持續時間計算，並可能根據交通狀況、天氣條件及其他因素進行調整。 所有費用計算均透過平台的自動化系統完成。</p>
              <p>2.3 最終費用確認</p>
              <p>行程結束後，您將收到一份詳細說明最終費用的行程收據。 您的支付義務是基於這些確認的最終費用。</p>
              <h3>III. 支付方式</h3>
              <p>3.1 接受的付款方式</p>
              <p>平台接受以下付款方式：</p>
              <ul>
                <li>信用卡（Visa, MasterCard, 銀聯, JCB, Diners, Discover）</li>
                <li>電子錢包（如支付寶、微信支付等）</li>
                <li>平台指定的其他付款方式</li>
              </ul>
              <p>3.2 預授權交易</p>
              <p>為確保支付安全和用戶信用，平台可能在預訂行程時進行金額預授權。 根據行程結束後的實際成本，預授權金額將進行調整。</p>
              <p>3.3 自動扣費</p>
              <p>註冊並儲存您的付款資訊後，系統將在每次行程結束後自動從您的帳戶中扣除相應的費用。</p>
              <h3>IV. 發票和收據</h3>
              <p>行程結束後，平台將透過電子郵件發送行程收據給您。 如果您需要正式發票，請透過平台的客戶服務申請，並確認您的郵寄地址和發票詳情。</p>
              <h3>V. 退款與取消政策</h3>
              <p>5.1 取消政策</p>
              <p>您可以在車輛到達前隨時取消預訂。 根據取消的時間，可能會收取取消費。 具體的取消費用將透過平台在您取消前明確告知。</p>
              <p>5.2 退款條件</p>
              <p>如果因平台原因導致無法提供服務，相應的費用將全額退還至您的原支付方式。 由於用戶原因提出的退款請求將根據平台的退款政策處理。</p>
              <h3>VI. 安全與防欺詐</h3>
              <p>為確保用戶支付的安全，平台採用業界標準的加密技術保護您的支付資訊。 我們也定期審查並更新我們的安全措施以對抗潛在的詐欺活動。</p>
              <h3>VII. 爭議解決</h3>
              <p>7.1 爭議通知</p>
              <p>如果您對帳單或付款有任何疑問，請在行程結束後的七個工作天內聯絡平台的客戶服務。 我們將迅速審查您的問題並作出回應。</p>
              <p>7.2 爭議調解</p>
              <p>對於因支付產生的爭議，該平台將提供調解服務以協助解決。 如果無法達成協議，您有權將爭議提交香港特別行政區的相關法院。</p>
              <h3>VIII. 修改與變更</h3>
              <p>平台保留隨時修改或更改本支付協議條款的權利。 此類修改或變更自在平台上發布即刻生效。 繼續使用平台的服務將被視為接受修改後的協議。</p>
              <h3>IX. 適用法律和管轄權</h3>
              <p>本協議下的解釋、應用及爭議解決均受香港特別行政區法律的管轄。 任何因本協議產生的訴訟應提交香港特別行政區的法院處理。</p>
              <h3>X. 其他條款</h3>
              <p>如果本協議的任何條款因任何原因被認定為無效或不可執行，該條款將被刪除，其餘條款將繼續完全有效。</p>
              <h3>XI. 聯絡資訊</h3>
              <p>如果您對本支付協議有任何疑問或需要更多信息，請透過以下方式聯絡我們的客戶服務部門：</p>
              <ul>
                <li>電子郵件：support@wonder.app</li>
                <li>電話：4600 4630</li>
                <li>地址：香港觀塘海濱道77號港濱匯5樓05-181室</li>
              </ul>
              <p>本支付協議構成您與平台關於支付服務的全部協議，並取代任何先前的討論或協議。</p>
              <p>請仔細閱讀本協議，確保您完全理解其條款。 透過使用平台的服務，您表明您接受這些條款。</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivacyPolicy;
