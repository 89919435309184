import ScrollNum from '@/components/common/scroll-number';
import Skeleton from '@/components/skeleton';
import React from 'react';
interface IAccountBalanceOverviewItemProps {
  mount: any;
  subTitle: any;
  show?: boolean;
  balance?: any;
  tooltip?: React.ReactNode;
  loading?: boolean;
}
/**
 * 用于本page的上下展示的结构
 */
const AccountBalanceItem = (props: IAccountBalanceOverviewItemProps) => {
  const {
    mount,
    balance,
    subTitle,
    tooltip,
    loading = false,
    show = true,
  } = props;

  return (
    <>
      <div className="flex items-center">
        <div className="font-bold text-xl text-color-text-1">
          {loading && (
            <div className={`overflow-hidden inline-block w-60`}>
              <Skeleton
                row={1}
                width={['100%']}
                skeletonItemClass={`h-4 rounded-2xl mb-0`}
              />
            </div>
          )}
          {!loading && <>{show ? <ScrollNum value={mount} /> : '****'}</>}
        </div>
        {!loading && balance && (
          <div className="font-medium text-base text-color-text-4 ml-1">
            {balance}
          </div>
        )}
        {!loading && tooltip && tooltip}
      </div>
      <div className="mt-1 text-sm font-medium text-color-text-2">
        {subTitle}
      </div>
    </>
  );
};

export default React.memo(AccountBalanceItem);
