import DateRange from '@/components/common/antd/date-range';
import Button from '@/components/common/button';
import Label from '@/components/common/label';
import ModalEmpty from '@/components/common/modal/modal-empty';
import Select from '@/components/common/select';
import { getDays } from '@/utils/dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { exportBas } from '../../data/global-account';
import { Alert } from '@/components/common/alert/alert';

interface IExportStatementModalProps {
  show: boolean;
  pBusinessId: string;
  currencyCode?: string;
  onCancel: () => void;
}

const ExportStatementModal: React.FC<IExportStatementModalProps> = (props) => {
  const { show, currencyCode, pBusinessId, onCancel } = props;

  const { t } = useTranslation( ["global_accounts", "account_balance"]);

  const [selectType, setSelectType] = useState<'pdf' | 'excel'>('pdf');
  const [startDate, setStartDate] = useState<string>(
    dayjs().format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [loading, setLoading] = useState<boolean>(false);

  const options = [
    { label: 'PDF', value: 'pdf' },
    { label: 'Excel', value: 'excel' },
  ];

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const handleDateChange = async (dates: any) => {
    if (dates && Array.isArray(dates)) {
      let newStartDate: any = dates[0];
      let newEndDate: any = dates[1];

      if (newStartDate && newEndDate) {
        newStartDate = newStartDate.format('YYYY-MM-DD');
        newEndDate = newEndDate.format('YYYY-MM-DD');
        setStartDate(newStartDate);
        setEndDate(newEndDate);
      }
    }
  };

  const handleSave = async () => {
    setLoading(true);

    const { url = '', file_name = '' } = await exportBas({
      startTime: startDate,
      endTime: endDate,
      pBusinessId,
      type: selectType,
      currency: currencyCode,
    });

    if (url) {
      let success = true;
      try {
        await fetch(url).then((res) =>
          res.blob().then((blob) => {
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${`${file_name}`}`;
            a.click();
            window.URL.revokeObjectURL(url);
          })
        );
      } catch (error) {
        console.error(error);
        success = false;
      }

      if (success) {
        Alert.success({
          message: t('global_accounts:exported_successfully'),
          wrapClass: '-top-10',
        });
      } else {
        Alert.error({
          message: t('global_accounts:exported_failed'),
          wrapClass: '-top-10',
        });
      }
    } else {
      Alert.error({
        message: t('global_accounts:exported_failed'),
        wrapClass: '-top-10',
      });
    }
    onCancel();
    setLoading(false);
  };

  return (
    <ModalEmpty
      visible={show}
      title={t('global_accounts:export_statement')}
      onCancel={onCancel}
    >
      <Label required value={t('global_accounts:file_type')} />
      <div className="border border-border-color h-10 rounded font-medium text-color-text-1 pl-2 flex items-center bg-disabled-color">
        {t('global_accounts:business_account_statement')}
      </div>
      <Label
        className="mt-6"
        value={t('global_accounts:file_format')}
        required
      />
      <Select
        className="shadow-none"
        options={options}
        value={selectType}
        onChange={(value: any) => setSelectType(value)}
      />
      <Label
        className="mt-6"
        value={t('global_accounts:created_at_date_range')}
        required
      />
      <DateRange
        format="MMM D, YYYY"
        className="bg-primary-background-color border-border-color shadow-none w-full"
        presets={[
          { label: t('global_accounts:this_week'), value: getDays('Week') },
          {
            label: t('global_accounts:this_month'),
            value: getDays('Month'),
          },
          {
            label: t('global_accounts:past_30_days'),
            value: [dayjs().subtract(30, 'd'), dayjs()],
          },
          { label: t('global_accounts:this_year'), value: getDays('Year') },
        ]}
        disabledDate={disabledDate}
        allowClear={false}
        size={'large'}
        value={[dayjs(startDate), dayjs(endDate)]}
        onChange={handleDateChange}
      />

      <Button
        loading={loading}
        className="w-full mt-6 h-10"
        onClick={handleSave}
      >
        {t('global_accounts:generate_statement')}
      </Button>
    </ModalEmpty>
  );
};

export default ExportStatementModal;
