import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import dayjs from 'dayjs';
import { IAssociateAction } from '@/types/associates-type';
import { useTranslation } from 'react-i18next';
import { Constants } from '@/constants';
import { getPower, getRoleName } from '@/utils/associates-utils';
import Button from '@/components/common/button';
import Label from '@/components/common/label';
import Select from '@/components/common/select/select';
import FormViewContext from '@/components/form-view';
import { AntdSelect, Option } from '@/components/common/antd/select/select';
import ActionModal from '../components/action-modal';
import Input from '@/components/common/input';
import BackIcon from '@/components/svg/back-icon';
import { Alert } from '@/components/common/alert/alert';
import { useNavigate, useParams } from 'react-router-dom';
import { IAreaCodes } from '@/types/common-types';
import { getRoles, queryAssociate, updateAssociate } from '@/data/associates';
import { findNode, getAvatarsName, getCookie, insertComma } from '@/utils';
import CheckIcon from '@/components/svg/check-icon';
import { queryBusiness } from '@/data/businesses';
import { loadAreaCodesData } from '@/data/area-codes';
import { IReducersState } from '@/reducers';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@/components/skeleton';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';


const AssociatesDetailPage = () => {
  const { t: ct } = useTranslation();
  const { t } = useTranslation('associates');
  const dispatch: IDispatch = useDispatch();
  const { lang = 'en', p_business_id: businessID = '', isEdit, associate_id: id = '' } = useParams();
  const permissions = useSelector((state: IReducersState) => state.businessState.permissions);
  const user = useSelector((state: IReducersState) => state.account.loginUser);
  
  const [isView, setIsView] = useState<boolean>(isEdit !== 'true');
  const [showActionModal, setShowActionModal] = useState(false);
  const [action, setAction] = useState<IAssociateAction>(
    IAssociateAction.SUSPEND
  );
  const [associate, setAssociate] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [areaCodes, setAreaCodes] = useState<IAreaCodes[]>([]);
  const [business, setBusiness] = useState<any>({});
  const [roles, setRoles] = useState<{
    id: string;
    name: string;
  }[]>([]);

  const navigate = useNavigate();
  const { edit, remove, editRole, leave, suspend } = getPower(permissions, user.id, associate);
  const {
    calling_code,
    email,
    full_name,
    phone = '',
    role_name,
    status,
    p_created_at,
    p_updated_at,
    job_position,
    role_id,
    display_name,
  } = associate;

  const getRoleId = (associate: any, roles: any[]) => {
    const roleName = getRoleName(associate.role_name, associate, lang);
    let role_id = associate.role_id;
    for (let i = 0; i < roles.length; i++) {
      const role = roles[i];
      if (role.name === roleName) {
        role_id = role.id;
      }
    }

    return role_id;
  };

  const useFormReturn = useForm({
    mode: 'all',
    defaultValues: associate,
  });
  const { handleSubmit, getValues, setValue, reset } = useFormReturn;

  useEffect(() => {
    loadMenuData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: ct('staff_management'),
          key: '1',
          type: 'label',
        },
        {
          name: ct('associate'),
          key: '2',
          type: 'link',
          url: `/${lang}/businesses/${businessID}/associates`,
        },
        {
          name: `${full_name}`,
          key: '3',
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line
  }, [full_name, lang])

  useEffect(() => {
    loadingData();
    // eslint-disable-next-line
  }, [lang]);

  const loadMenuData = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    const allPromise: any[] = [
      queryBusiness(businessID),
      loadAreaCodesData(),
      getRoles(token, businessID, lang),
      queryAssociate({token, businessID, id, lang})
    ];
    const resp = await Promise.all(allPromise);
    setBusiness(resp[0]);
    setAreaCodes(resp[1]);
    setRoles(resp[2]);
    setAssociate(resp[3]);
    reset({
      ...resp[3],
      role_id: getRoleId(resp[3], resp[2]),
    });
    if (!resp[3] || !resp[3].id) {
      navigate(`/${lang}/businesses/${businessID}/associates`);
    }
  }

  const handleActionClick = (key: IAssociateAction) => {
    setAction(key);
    setShowActionModal(true);
  };

  const handleCallback = async (key: IAssociateAction, success: boolean) => {
    if (success) {
      if (
        key === IAssociateAction.SUSPEND ||
        key === IAssociateAction.REACTIVATE
      ) {
        await loadingData();
      }
      if (key === IAssociateAction.REMOVE) {
        navigate(`/${lang}/businesses/${businessID}/associates`);
      }
      if (key === IAssociateAction.LEAVE) {
        navigate(`/${lang}/personal-space/business-overview`);
      }
    }
    setShowActionModal(false);
  };

  const loadingData = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    const data = await queryAssociate({token, businessID, id, lang});
    setAssociate(data);
  };

  const handleSave = async () => {
    setLoading(true);
    const values = getValues();
    const data: any = {};
    const { job_position, role_id } = values;
    data.job_position = job_position;
    if (role_id) {
      data.role_id = role_id;
    }
    const token = getCookie(Constants.TOKEN) || '';
    const success = await updateAssociate(id, businessID, data, token);

    if (success) {
      Alert.success({
        message: t('edit_successfully'),
        position: 'default',
        wrapClass: 'top-px',
      });
      navigate(`/${lang}/businesses/${businessID}/associates`);
    } else {
      Alert.error({
        message: t('editing_failed'),
        position: 'default',
        wrapClass: 'top-px',
      });
    }
    setLoading(false);
  };

  const handleBack = () => {
    if (!isView) {
      setValue('job_position', job_position);
      setValue('role_id', role_id);
      setIsView(true);
    } else {
      navigate(`/${lang}/businesses/${businessID}/associates`);
    }
  };

  return (
    <>
      <div className="p-3 md:p-5 lg:px-7 lg:py-5">
        <Transition.Root show={true} appear={true}>
          <Transition.Child
            className="rounded overflow-hidden h-fit"
            enter="duration-350 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-10 opacity-40"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="flex m-1 mb-4 md:mb-6 justify-between">
              <div className="flex">
                <div className="bg-disabled-color shadow-box-drop rounded-full w-10 h-10 flex items-center justify-center cursor-pointer">
                  <BackIcon
                    className="hover:text-primary-color"
                    onClick={handleBack}
                  />
                </div>
                <div className="hidden md:block text-3xl leading-10 font-bold text-color-text-1 ml-2.5">
                  {full_name}
                </div>
              </div>
              {!associate.id && (
                <div className="w-120">
                  <Skeleton
                    row={1}
                    className="items-center"
                    width={['100%']}
                    skeletonItemClass="h-10 rounded-[50px] text-right"
                  />
                </div>
              )}
              {associate.id && (
                <div className="flex space-x-4">
                  {!isView && (
                    <Button onClick={handleBack} styleType="Border">
                      {ct('cancel')}
                    </Button>
                  )}
                  {!isView && (
                    <Button onClick={handleSubmit(handleSave)} loading={loading}>
                      {ct('save')}
                    </Button>
                  )}
                  {isView && (
                    <>
                      {leave && (
                        <Button
                          styleType={'Secondary Danger'}
                          onClick={() =>
                            handleActionClick(IAssociateAction.LEAVE)
                          }
                        >
                          {t('leave_this')}
                        </Button>
                      )}
                      {remove && (
                        <Button
                          styleType={'Secondary Danger'}
                          onClick={() =>
                            handleActionClick(IAssociateAction.REMOVE)
                          }
                        >
                          {t('remove_this')}
                        </Button>
                      )}
                      {suspend && status !== 'active' && (
                        <Button
                          onClick={() =>
                            handleActionClick(IAssociateAction.REACTIVATE)
                          }
                        >
                          {t('reactivate_this')}
                        </Button>
                      )}
                      {suspend &&
                        !['pending_admission', 'suspended'].includes(status) && (
                          <Button
                            styleType={'Danger'}
                            onClick={() =>
                              handleActionClick(IAssociateAction.SUSPEND)
                            }
                          >
                            {t('suspend_this')}
                          </Button>
                        )}
                      {edit && status !== 'suspended' && (
                        <Button onClick={() => setIsView(false)}>
                          {ct('edit')}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </Transition.Child>
          <Transition.Child
            className="rounded overflow-hidden h-fit"
            enter="duration-450 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-10 opacity-40"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <form>
              <FormViewContext.Provider value={{ viewConfig: undefined }}>
                <div className="m-1 mb-6 flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 h-fit">
                  <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <Controller
                      name={'display_name'}
                      control={useFormReturn.control}
                      render={() => (
                        <div className="flex items-center">
                          <div className="w-16 h-16 rounded-full flex justify-center items-center bg-primary-color/10 text-xl font-bold">
                            {getAvatarsName(display_name)}
                          </div>
                          <div className="ml-4 text-lg font-semibold text-color-text-1">
                            {display_name}
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      name={'status'}
                      control={useFormReturn.control}
                      render={() => (
                        <div className="lg:col-span-2">
                          <Label value={t('status')}></Label>
                          <Select
                            options={[
                              {
                                label: 'ACTIVE',
                                value: 'active',
                                color: 'text-success-color bg-success-color',
                              },
                              {
                                label: 'SUSPENDED',
                                value: 'suspended',
                                color: 'text-error-color bg-error-color',
                              },
                              {
                                label: 'PENDING ADMISSION',
                                value: 'pending_admission',
                                color: 'text-primary-color bg-primary-color',
                              },
                              {
                                label: 'IDLE',
                                value: 'idle',
                                color: 'text-icon-color bg-icon-color',
                              },
                            ]}
                            multiple={false}
                            value={status}
                            disabled={true}
                            enableTag={true}
                          />
                        </div>
                      )}
                    />
                    <Controller
                      name={'full_name'}
                      control={useFormReturn.control}
                      render={() => (
                        <div>
                          <Label value={t('full_name')}></Label>
                          <Input value={full_name} disabled={true} />
                        </div>
                      )}
                    />
                    <Controller
                      name={'phone'}
                      control={useFormReturn.control}
                      render={() => (
                        <div>
                          <Label value={t('phone_number')}></Label>
                          {parsePhone(
                            `${
                              calling_code ? `+${calling_code} ` : ''
                            }${phone}`,
                            areaCodes
                          )}
                        </div>
                      )}
                    />
                    <Controller
                      name={'email'}
                      control={useFormReturn.control}
                      render={() => (
                        <div>
                          <Label
                            disabled={true}
                            value={t('email')}
                          ></Label>
                          <Input disabled={true} value={email || '--'} />
                        </div>
                      )}
                    />
                    <Controller
                      name={'job_position'}
                      control={useFormReturn.control}
                      rules={{
                        required: t('field_is_required'),
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <div>
                          <Label
                            value={t('job_title')}
                            required={true}
                          ></Label>
                          <Input
                            onChange={(data) => {
                              onChange(data);
                            }}
                            placeholder={t('enter_job_title')}
                            value={value}
                            error={error}
                            disabled={isView}
                          />
                        </div>
                      )}
                    />
                    <Controller
                      name={'role_id'}
                      control={useFormReturn.control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <div className="lg:col-span-2 grid lg:grid-cols-2">
                          <div>
                            <Label
                              value={t('role')}
                              required={true}
                            ></Label>
                            {(!editRole || isView) && (
                              <Input
                                disabled={true}
                                value={getRoleName(role_name, associate, lang)}
                              />
                            )}
                            {editRole && !isView && (
                              <AntdSelect
                                value={
                                  value ||
                                  getRoleName(role_name, associate, lang)
                                }
                                placeholder={t('choose_role')}
                                optionLabelProp="label"
                                error={error}
                                onSelect={(_value, option) => {
                                  onChange(option.value);
                                }}
                              >
                                {roles.map((item) => {
                                  return (
                                    <Option
                                      key={item.id}
                                      value={item.id}
                                      label={item.name}
                                      data={item}
                                    >
                                      <div className="flex justify-between items-center">
                                        <div className="text-color-text-1">
                                          {item.name}
                                        </div>
                                        {value === item.id && <CheckIcon />}
                                      </div>
                                    </Option>
                                  );
                                })}
                              </AntdSelect>
                            )}
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      name={'p_created_at'}
                      control={useFormReturn.control}
                      render={() => (
                        <div>
                          <Label value={t('join_since')}></Label>
                          <Input
                            value={
                              p_created_at
                                ? dayjs(p_created_at).format('MMM DD, YYYY')
                                : '--'
                            }
                            disabled={true}
                          />
                        </div>
                      )}
                    />
                    <Controller
                      name={'p_updated_at'}
                      control={useFormReturn.control}
                      render={() => (
                        <div>
                          <Label value={t('updated_at')}></Label>
                          <Input
                            value={
                              p_updated_at
                                ? dayjs(p_updated_at).format(
                                    'MMM DD, YYYY HH:mm:ss'
                                  )
                                : '--'
                            }
                            disabled={true}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
              </FormViewContext.Provider>
            </form>
          </Transition.Child>
        </Transition.Root>
      </div>
      {showActionModal && (
        <ActionModal
          action={action}
          businessID={businessID}
          business={business}
          associate={associate}
          onCancel={() => setShowActionModal(false)}
          callback={handleCallback}
        />
      )}
    </>
  );
};

const parsePhone = (phone: string, areaCodes: IAreaCodes[]) => {
  if (phone && phone.charAt(0) === '+') {
    const tArray: string[] = phone.split(/\s+/);
    const dialItem = findNode<IAreaCodes>(
      areaCodes,
      'phoneCountryCode',
      tArray[0].replace('+', '')
    );
    return (
      <div className="group rounded flex items-center h-10 px-3 py-2.5 cursor-auto pl-0 text-sm text-color-text-1 font-medium">
        {dialItem && (
          <span className="flex items-center relative w-4 mr-1">
            <img
              width="16px"
              height="16px"
              src={`/images/country-flag/${dialItem.isoAlpha2Code.toLocaleLowerCase()}.svg`}
              alt=""
            />
          </span>
        )}
        <span>
          {tArray[0]}{' '}
          {insertComma(
            tArray[tArray.length - 1].replace(/ /g, ''),
            true,
            4,
            ' '
          )}
        </span>
      </div>
    );
  } else {
    return <div>{phone}</div>;
  }
};

export default AssociatesDetailPage;
