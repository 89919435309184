import type { IObject } from '@/types/common-types';
import type { ReactElement } from 'react';
import type { IGetActiveLedgerSearch } from '@/types/account-balance-type';
import { PayinTransferType, TransactionType } from '@/types/account-balance-type';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import BigNumber from 'bignumber.js';
import { getAvatarsName } from '@/utils';
import {
  FilterOperation,
  type IFilterOptions,
} from '@/components/common/filter';
import AlipayIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/alipay-icon';
import AmexIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/amex-icon';
import AmexBlueIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/amex-blue-icon';
import AppPayIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/apppay-icon';
import GooglePayIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/googlepay-icon';
import SamsungPayIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/samsungpay-icon';
import HuaweiPayIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/huaweipay-icon';
import CashIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/cash-icon';
import MastercardIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/mastercard-icon';
import FpsIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/fps-icon';
import JcbIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/jcb-icon';
import QuickpassIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/quickpass-icon';
import TapgoIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/tap-go-icon';
import UnionPayIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/unionpay-icon';
import WechaPayIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/wechat-icon';
import VisaIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/visa-icon';
import OctopusIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/octopus-icon';
import CardTransactionIcon from '@/pages/account-balance/account-balances-icons/card-transaction-icon';
import ExchangeIcon from '@/pages/account-balance/account-balances-icons/exchange-icon';
import MerchantServicesIcon from '@/pages/account-balance/account-balances-icons/pay-in-icons/merchant-services-icon';
import ReleasedIcon from '@/pages/account-balance/account-balances-icons/pay-in-icons/released-reserve-icon';
import SystemAdjustmentIcon from '@/pages/account-balance/account-balances-icons/pay-in-icons/system-adjustment-icon';
import AddFundIcon from '@/pages/account-balance/account-balances-icons/pay-in-icons/add-fund-icon';
import UnionPayWalletIcon from '../pages/account-balance/account-balances-icons/payment-methdod-icons/unionpay-wallet-icon';
import XpayIcon from '../pages/account-balance/account-balances-icons/payment-methdod-icons/xpay-icon';
import DiscoverIcon from '../pages/account-balance/account-balances-icons/payment-methdod-icons/discover-icon';
import DinersIcon from '../pages/account-balance/account-balances-icons/payment-methdod-icons/diners-icon';
import CorporateIncomeIcon from '../pages/account-balance/account-balances-icons/payment-methdod-icons/corporate-income-icon';
import AliPayHkIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/alipay-hk-icon';
import BocPayIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/bocpay-icon';

dayjs.extend(utc);
dayjs.extend(timezone);
// dayjs.tz.setDefault('Asia/Hong_Kong');


export const getTranstionType = (record: IObject) => {

  let type;

  if (record.card_id) {
    type = TransactionType.card;
  } else if (record.payout_request_id) {
    type = TransactionType.payOut;
  } else if (record.payin_request_id) {
    type = TransactionType.payIn;
  } else if (record.fx_conversion_id) {
    type = TransactionType.exChange;
  }

  return type;
};

export const HadleGoTranscationDetails = (
  businessID: string,
  record: any,
  navigate: (url: any, query: any) => void,
  from?: string,
  upcoming?: string,
) => {
  const transtionType = getTranstionType(record);
  let recordId = '';
  let transactionType = '';

  switch (transtionType) {
    case TransactionType.payOut:
      recordId = record.payout_request_id;
      transactionType = TransactionType.payOut;
      break;
    case TransactionType.card:
      recordId = record.card_transaction_id;
      transactionType = TransactionType.card;
      break;
    case TransactionType.exChange:
      recordId = record.fx_conversion_id;
      transactionType = TransactionType.exChange;
      break;
    case TransactionType.payIn:
      recordId = record.payin_request_id;
      transactionType = TransactionType.payIn;
      break;
  }

  const query: IObject = {};

  if (from) {
    query.activekey = from;
  }

  if (upcoming) {
    query.upcoming = upcoming;
  }

  if (transactionType && recordId) {
    // navigate(`/businesses/${businessID}/account-balance/transactions/${transactionType}/${recordId}/${query}`)

    navigate(`/businesses/${businessID}/account-balance/transactions/${transactionType}/${recordId}`, { state: { query } });
    // router.push({
    //   pathname: `/businesses/${businessID}/account-balance/transactions/${transactionType}/${recordId}`,
    //   query,
    // });
  }
};

export const handleUtcDate = (
  date: string,
  format = 'MMM D, YYYY HH:mm:ss'
) => {
  if (!date) return <span>--</span>;
  const dayDate = dayjs.utc(date).tz();

  return (
    <span className="whitespace-nowrap">{`${dayDate.format(format)}`}</span>
  );
};

export const handleMount = (
  value: any,
  hiddenMode = false
): React.Key => {
  let returnValue: React.Key = '--';
  const temp = new BigNumber(value);
  if (!temp.isNaN()) {
    returnValue = temp.toFormat(2, 1);
  }
  if (hiddenMode) {
    returnValue = '****';
  }
  return returnValue;
};

export const getTranstionStatusLabel = (
  record: IObject
): {
  component: ReactElement;
  status: string;
} => {
  const {
    status: transactionStatus = '',
    schedule_status: scheduleStatus = '',
  } = record;

  let status = transactionStatus || '';
  let color = '';

  if (
    (scheduleStatus === 'Approved' && transactionStatus === 'Initiated') ||
    (scheduleStatus === 'Pending Verification' &&
      transactionStatus === 'Suspended')
  ) {
    status = 'Pending for Transfer';
  }

  if (scheduleStatus === 'Rejected') {
    status = 'Rejected';
  }

  if (status === 'Settled' || status === 'Completed') {
    //Settled
    color = 'text-success-color bg-success-color';
  } else if (status === 'Initiated' || status === 'Pending for Transfer') {
    //Initiated
    color = 'text-warning-color bg-warning-color';
  } else if (status === 'Declined' || status === 'Suspended') {
    //Declined
    color = 'text-error-color bg-error-color';
  } else if (status === 'Authorized') {
    //Authorized
    color = 'text-warning-color bg-warning-color';
  } else if (status === 'Terminated' || status === 'Rejected') {
    //Terminated
    color = 'text-icon-color bg-icon-color';
  } else if (status === 'Reversed') {
    //Terminated
    color = 'text-icon-color bg-icon-color';
  } else if (status === 'Processing Transfer') {
    color = 'text-primary-color bg-primary-color';
  } else if (status === 'Refunded') {
    color = 'text-[#FF2E00] bg-[#FF2E00]';
  }
  const component = (
    <span
      className={`px-3 py-1.5 rounded-3xl text-xs ${color} bg-opacity-10 whitespace-nowrap`}
    >
      {status.toUpperCase()}
    </span>
  );

  return {
    component,
    status,
  };
};

/**
 * 结汇status
 * @param transactionStatus
 * @returns
 */
export const getExchangeStatusLabel = (
  transactionStatus: string
): {
  component: ReactElement;
  status: string;
} => {
  let status = transactionStatus.toLowerCase() || '';
  let color = '';

  if (status === 'completed' || status === 'settled') {
    //Settled
    color = 'text-success-color bg-success-color';
    status = 'settled';
  } else if (status === 'initiated') {
    //Initiated
    color = 'text-warning-color bg-warning-color';
  } else if (status === 'terminated') {
    //Terminated
    color = 'text-icon-color bg-icon-color';
  } else if (status === 'reversed') {
    color = 'text-icon-color bg-icon-color';
  }

  const component = (
    <span className={`px-3 py-1.5 rounded-3xl text-xs ${color} bg-opacity-10`}>
      {status.toUpperCase()}
    </span>
  );

  return {
    component,
    status,
  };
};

/**
 * card transfer status
 * @param transactionStatus
 * @returns
 */
export const getCardTranferStatusLabel = (
  transactionStatus: string
): {
  component: ReactElement;
  status: string;
} => {
  let status = transactionStatus.toLowerCase() || '';
  let color = '';

  if (status === 'settled') {
    color = 'text-success-color bg-success-color';
    status = 'settled';
  } else if (status === 'initiated') {
    color = 'text-warning-color bg-warning-color';
  } else if (status === 'reversed') {
    color = 'text-icon-color bg-icon-color';
  } else if (status === 'authorized') {
    color = 'text-warning-color bg-warning-color';
  } else if (status === 'declined') {
    color = 'text-error-color bg-error-color';
  }

  const component = (
    <span className={`px-3 py-1.5 rounded-3xl text-xs ${color} bg-opacity-10`}>
      {status.toUpperCase()}
    </span>
  );

  return {
    component,
    status,
  };
};

/**
 * payin transfer status
 * @param transactionStatus
 * @returns
 */
export const getPayinTranferStatusLabel = (
  transactionStatus: string
): {
  component: ReactElement;
  status: string;
} => {
  let status = transactionStatus.toLowerCase() || '';
  let color = '';

  if (status === 'settled') {
    color = 'text-success-color bg-success-color';
    status = 'settled';
  } else if (status === 'initiated') {
    color = 'text-warning-color bg-warning-color';
  } else if (status === 'reversed') {
    color = 'text-icon-color bg-icon-color';
  } else if (status === 'suspended') {
    color = 'text-error-color bg-error-color';
  }

  const component = (
    <span className={`px-3 py-1.5 rounded-3xl text-xs ${color} bg-opacity-10`}>
      {status.toUpperCase()}
    </span>
  );

  return {
    component,
    status,
  };
};

/**
 * payout transfer status
 * @param record
 * @returns
 */
export const getPayoutTranferStatusLabel = (
  record: IObject
): {
  component: ReactElement;
  status: string;
} => {
  const {
    transfer_status: transferStatus = ' ',
    schedule_status: scheduleStatus = '',
  } = record;

  let color = '';

  let status = transferStatus;

  if (
    (scheduleStatus === 'Approved' && transferStatus === 'Initiated') ||
    (scheduleStatus === 'Pending Verification' && transferStatus === 'Suspended')
  ) {
    status = 'Pending for Transfer';
  }

  if (scheduleStatus === 'Rejected') {
    status = 'Rejected';
  }

  if (status === 'Settled') {
    color = 'text-success-color bg-success-color';
  } else if (status === 'Initiated' || status === 'Pending for Transfer') {
    color = 'text-warning-color bg-warning-color';
  } else if (
    status === 'Reversed' ||
    status === 'Terminated' ||
    status === 'Rejected'
  ) {
    color = 'text-icon-color bg-icon-color';
  } else if (status === 'Suspended') {
    color = 'text-error-color bg-error-color';
  } else if (status === 'Processing Transfer') {
    color = 'text-primary-color bg-primary-color';
  } else if (status === 'Refunded') {
    color = 'text-[#FF2E00] bg-[#FF2E00]';
  }

  const component = (
    <span className={`px-3 py-1.5 rounded-3xl text-xs ${color} bg-opacity-10`}>
      {status.toUpperCase()}
    </span>
  );

  return {
    component,
    status: status.toLowerCase() || '',
  };
};

// export const getBillingAmount = (amount = 0, calculatedFee = 0) => {
//   const tempAmount = new BigNumber(amount);
//   return tempAmount.plus(calculatedFee).toFormat(2, 1);
// };

export const handleSymbolMount = (
  value: any,
  hiddenMode = false,
  symbol?: string
): React.Key => {
  let returnValue: React.Key = '';
  let mountString = '';
  const temp = new BigNumber(value);
  if (!temp.isNaN()) {
    mountString = temp.toFormat(2, 1);
    if (temp.toNumber() >= 0) {
      if (symbol) {
        mountString = `${symbol}${mountString}`;
      } else {
        mountString = `+${mountString}`;
      }
      return mountString;
    } else {
      return `${mountString}`;
    }
  }
  if (hiddenMode) {
    returnValue = '****';
  }
  return returnValue;
};

/**
 * @params payin transfer type
 * @returns 返回对应的icon element
 */
export const getPayInTitleIcon = (
  transferType: string,
  displayName = '',
  size = 32
): null | ReactElement => {
  let icon: ReactElement | null = null;
  let inboundClass = 'h-8 w-8';
  let fontSize = 'text-sm';
  if (size === 48) {
    inboundClass = 'h-12 w-12';
    fontSize = 'text-xl';
  }

  if (transferType === PayinTransferType.merchantServices) {
    icon = <MerchantServicesIcon size={size} />;
  } else if (transferType === PayinTransferType.systemAdjustment) {
    icon = <SystemAdjustmentIcon size={size} />;
  } else if (transferType === PayinTransferType.addFund) {
    icon = <AddFundIcon size={size} />;
  } else if (transferType === PayinTransferType.releasedReserve) {
    icon = <ReleasedIcon size={size} />;
  } else {
    icon = (
      <span
        className={`inline-flex items-center justify-center bg-[#0040EC] rounded-full ${inboundClass}`}
      >
        <span
          className={`font-medium text-primary-background-color ${fontSize} leading-normal`}
        >
          {displayName ? getAvatarsName(displayName) : ''}
        </span>
      </span>
    );
  }

  return icon;
};

/**
 * 根据record数据判断使用那种icon
 * 1. 首先判断是payin/card/exchange/payout
 * 2. payin两种icon
 * 3. payout根据categor获取20多种icon
 */
export const getTransferListIcon = (
  record: IObject,
  size = 32
): null | ReactElement => {
  const {
    transfer_type: transferType = '',
    display_name: displayName = '',
    payout_category_icon_color: iconColor = '',
    payout_category_icon_url: iconUrl = '',
    payout_category_icon_file_url: iconFileUrl = '',
  } = record || {};
  const transtionType = getTranstionType(record);
  let icon: any | null = null;

  switch (transtionType) {
    case TransactionType.card:
      icon = <CardTransactionIcon size={size} />;
      break;
    case TransactionType.exChange:
      icon = <ExchangeIcon size={size} />;
      break;
    case TransactionType.payIn:
      icon = getPayInTitleIcon(transferType, displayName);
      break;
    case TransactionType.payOut:
      const url = iconFileUrl || iconUrl;
      icon = (
        <div
          className={`w-8 h-8 flex justify-center items-center rounded-full`}
          style={{ backgroundColor: iconColor }}
        >
          <div
            style={{
              backgroundImage: `url(${url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '20px',
              height: '20px',
            }}
          />
        </div>
      );
      break;
  }

  return icon;
};

/**
 * 根据支付方式获取对应的图标
 */
export const getPaymentMethodIcon = (
  paymentMethod: string,
  size = 36
): React.ReactElement | null => {
  let iconType: React.ReactElement | null = null;

  switch (paymentMethod.toLowerCase()) {
    case 'unionpay':
    case 'cup':
      iconType = <UnionPayIcon size={size} />;
      break;
    case 'tap&go':
      iconType = <TapgoIcon size={size} />;
      break;
    case 'mastercard':
      iconType = <MastercardIcon size={size} />;
      break;
    case 'wechat':
    case 'wechat pay':
      iconType = <WechaPayIcon size={size} />;
      break;
    case 'xpay':
      iconType = <XpayIcon size={size} />;
      break;
    case 'cash':
      iconType = <CashIcon size={size} />;
      break;
    case 'visa':
      iconType = <VisaIcon size={size} />;
      break;
    case 'alipay':
      iconType = <AlipayIcon size={size} />;
      break;
    case 'fps':
      iconType = <FpsIcon size={size} />;
      break;
    case 'quickpass':
      iconType = <QuickpassIcon size={size} />;
      break;
    case 'amex':
      iconType = <AmexIcon size={size} />;
      break;
    case 'jcb':
      iconType = <JcbIcon size={size} />;
      break;
    case 'octopus_business':
    case 'octopus':
      iconType = <OctopusIcon size={size} />;
      break;
    case 'unionpay_wallet':
    case 'unionpay wallet':
      iconType = <UnionPayWalletIcon size={size} />;
      break;
    case 'discover':
      iconType = <DiscoverIcon size={size} />;
      break;
    case 'diners':
    case 'diners club':
      iconType = <DinersIcon size={size} />;
      break;
    case 'crypto_coin':
      iconType = <CorporateIncomeIcon size={size} />;
      break;
    case 'american express':
      iconType = <AmexBlueIcon size={size} />;
      break;
    case 'apple pay':
      iconType = <AppPayIcon size={size} />;
      break;
    case 'google pay':
      iconType = <GooglePayIcon size={size} />;
      break;
    case 'samsung pay':
      iconType = <SamsungPayIcon size={size} />;
      break;
    case 'huawei pay':
      iconType = <HuaweiPayIcon size={size} />;
      break;
    case 'alipayhk':
      iconType = <AliPayHkIcon size={size} />;
      break;
    case 'boc pay':
      iconType = <BocPayIcon size={size} />;
      break;
  }

  return iconType;
};

/**
 * 后台支付方式字段转换为前端展示的字段
 */
export const geyPaymentDisplayName = (
  paymentMethod: string,
  cardNO?: string
) => {
  let returnString = '';
  let last4digits = '';
  if (cardNO && cardNO.length > 4) {
    last4digits = `**${cardNO.slice(-4)}`;
  }

  switch (paymentMethod.toLowerCase()) {
    case 'unionpay':
    case 'cup':
      returnString = `UnionPay ${last4digits}`;
      break;
    case 'tap&go':
      returnString = 'Tap & Go';
      break;
    case 'mastercard':
      returnString = `Mastercard ${last4digits}`;
      break;
    case 'wechat':
      returnString = 'WeChat Pay';
      break;
    case 'cash':
      returnString = 'Cash';
      break;
    case 'visa':
      returnString = `Visa ${last4digits}`;
      break;
    case 'alipay':
      returnString = 'AliPay';
      break;
    case 'fps':
      returnString = 'FPS';
      break;
    case 'quickpass':
      returnString = 'QuickPass';
      break;
    case 'amex':
      returnString = `Amex ${last4digits}`;
      break;
    case 'jcb':
      returnString = `JCB ${last4digits}`;
      break;
    case 'octopus_business':
    case 'octopus':
      returnString = 'Octopus';
      break;
    case 'unionpay_wallet':
      returnString = 'UnionPay Wallet';
      break;
    case 'xpay':
      returnString = 'Xpay';
      break;
    case 'crypto_coin':
      returnString = 'Corporate Income';
      break;
    default:
      returnString = paymentMethod;
      break;
  }
  return returnString;
};

export const handleType = (
  paymentType: string,
  voidAt: string,
  tipsAmount?: string
): ReactElement => {
  let transfer_status = (paymentType || '').toUpperCase();
  let color = '';
  let show = '';
  const amount = Number(tipsAmount);

  if (voidAt !== null) {
    transfer_status = 'void'.toUpperCase();
  }

  switch (transfer_status) {
    case 'SALE':
      color = 'text-[#4CD964] bg-[#4CD964]';
      show = 'SALES';
      break;
    case 'VOID':
      color = 'text-[#828282] bg-[#828282]';
      show = 'VOID';
      break;
    case 'REFUND':
      color = 'text-primary-color bg-primary-color';
      show = 'REFUND';
      break;
    case 'RISK_HOLD':
      color = 'text-[#FC2E01] bg-[#FC2E01]';
      show = 'RISK HOLD';
      break;
    case 'HOLD':
      color = 'text-[#FC2E01] bg-[#FC2E01]';
      show = 'ON HOLD';
      break;
    case 'DISPUTE':
      color = 'text-[#6780DA] bg-[#6780DA]';
      show = 'DISPUTE';
      break;
    case 'HOLD_REVERSAL':
      color = 'text-primary-color bg-primary-color';
      show = 'RELEASE';
      break;
    case 'DISPUTE_CANCEL':
      color = 'text-[#4CD964] bg-[#4CD964]';
      show = 'CANCEL DISPUTE';
      break;
    case 'CHARGE_BACK':
      color = 'text-[#FBCB09] bg-[#FBCB09]';
      show = 'CHARGE BACK';
      break;
    case 'PRE_AUTH':
      color = 'text-[#F59E0B] bg-[#F59E0B]';
      show = 'PRE-AUTH';
      break;
    case 'INCREMENT_PRE_AUTH':
      color = 'text-[#F59E0B] bg-[#F59E0B]';
      show = 'INCREMENT PRE-AUTH';
      break;
    case 'PRE_AUTH_COMPLETE':
      color = 'text-[#4CD964] bg-[#4CD964]';
      show = 'PRE-AUTH COMPLETE';
      break;
    case 'PRE_DISPUTE':
      color = 'text-[#6780DA] bg-[#6780DA]';
      show = 'PRE-DISPUTE';
      break;
    case 'REPRESENTMENT':
      color = 'text-primary-color bg-primary-color';
      show = 'REPRESENTMENT';
      break;
    default:
      show = transfer_status;
      break;
  }

  return (
    <div className="flex items-center">
      <span className={`px-3 py-1 rounded-3xl text-xs ${color} bg-opacity-10`}>
        {show}
      </span>
      {!isNaN(amount) && amount > 0 && (
        <span
          className={`px-3 py-1 rounded-3xl text-xs ${transfer_status === 'SALE' ? 'text-[#EE8888] bg-[#EE8888]' : color
            } bg-opacity-10 ml-1.5`}
        >
          {'TIPS'}
        </span>
      )}
    </div>
  );
};

export const HadleGoPaymentDetails = (
  businessID: string,
  recordId: string,
  navigate: (url: any, query: any) => void,
  from?: string,
  dayEndId?: string,
) => {
  const query: IObject = {};
  // const navigate = useNavigate();
  if (from) {
    query.from = from;
  }

  if (dayEndId) {
    query.dayEndId = dayEndId;
  }
  // navigate(`/businesses/${businessID}/account-balance/payments/${recordId}/${query}`);
  navigate(`/businesses/${businessID}/account-balance/payments/${recordId}`, { state: { query } })

  // router.push({
  //   pathname: `/businesses/${businessID}/account-balance/payments/${recordId}`,
  //   query,
  // });
};

export const handleSettledRecordsFilter = (
  search: string,
  options: IFilterOptions[]
) => {
  let filter = '';
  if (search) {
    filter = `&day_end_id=${search}`;
  }
  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    if (option.value) {
      const values = option.value.split('&');
      const name = option.name;
      const start = values[0];
      const end = values[1];
      if (
        ['created_at', 'closed_schedule_at', 'settlement_date'].includes(name)
      ) {
        const startFieldName = `${option.name}_start${name === 'created_at' ? '_time' : ''
          }`;
        const endFieldName = `${option.name}_end${name === 'created_at' ? '_time' : ''
          }`;
        filter = `${filter}&${startFieldName}=${dayjs(start)
          .subtract(1, 'D')
          .endOf('D')
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')}`;

        if (option.operation === FilterOperation.EQUALS) {
          filter = `${filter}&${endFieldName}=${dayjs(start)
            .endOf('D')
            .utc()
            .format('YYYY-MM-DD HH:mm:ss')}`;
        }
        if (option.operation === FilterOperation.IS_BETWEEN && end) {
          filter = `${filter}&${endFieldName}=${dayjs(end)
            .endOf('D')
            .utc()
            .format('YYYY-MM-DD HH:mm:ss')}`;
        }
      } else if (name === 'settlement_amount') {
        if (option.operation === FilterOperation.EQUALS) {
          filter = `${filter}&settlement_amount_equal=${start}`;
        } else if (option.operation === FilterOperation.IS_BETWEEN) {
          filter = `${filter}&settlement_amount_range_begin=${start}`;
          if (end) {
            filter = `${filter}&settlement_amount_range_end=${end}`;
          }
        }
      } else if (name === 'transfer_status') {
        const selectValues: string[] = option.value.split(',');
        const values = selectValues.map(item => `transfer_status[]=${item}`)
        filter = values.join('&');
      }
    }
  }

  return filter;
};

export const handleCurrentlyProcessingFilter = (
  search: string,
  options: IFilterOptions[]
) => {
  let filter = '';
  if (search) {
    filter = `&keyword=${search}`;
  }
  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    if (option.value) {
      const name = option.name;
      if (['payment_types', 'payment_methods'].includes(name)) {
        const selectValues: string[] = option.value.split(',');
        const values = selectValues.map(item => `${name}[]=${item}`)
        filter = values.join('&');
      } else if (name === 'amount') {
        const values = option.value.split('&');
        if (option.operation === FilterOperation.EQUALS) {
          filter = `${filter}&amount_range_equal=${values[0]}`;
        } else if (option.operation === FilterOperation.IS_BETWEEN) {
          if (values[0]) {
            filter = `${filter}&amount_range_begin=${values[0]}`;
          } else if (values[1]) {
            filter = `${filter}&amount_range_end=${values[1]}`;
          }
        }
      }
    }
  }

  return filter;
};

export const handleAmoutDisplay = (value: string, symbol?: string) => {
  let returnValue = value;
  if (!isNaN(Number(returnValue))) {
    const temp = new BigNumber(returnValue);
    if (symbol) {
      const value = temp.toFormat(2, 1);
      if (value.indexOf(symbol) === -1) {
        returnValue = `${symbol}${value}`;
      }
    } else {
      if (temp.toNumber() > 0) {
        returnValue = `+${temp.toFormat(2, 1)}`;
      } else {
        returnValue = temp.toFormat(2, 1);
      }
    }
  }

  return returnValue;
};

export const parseGetActivitiesParams = (
  searchValue: string,
  IFilterOptions: IFilterOptions[]
) => {
  const params: IGetActiveLedgerSearch = {};
  if (searchValue) {
    params.id_or_name = searchValue;
  }
  IFilterOptions.forEach((item) => {
    if (item.name === 'settlement_date' && item.value) {
      const values = item.value.split('&');
      params.settlement_date_from = values[0];
      if (item.operation === FilterOperation.EQUALS) {
        params.settlement_date_to = params.settlement_date_from;
      } else if (values.length > 1) {
        params.settlement_date_to = values[1];
      }
    }
    if (item.name === 'created_at_data' && item.value) {
      const values = item.value.split('&');
      params.created_at_data_from = values[0];
      if (item.operation === FilterOperation.EQUALS) {
        params.created_at_data_to = params.created_at_data_from;
      } else if (values.length > 1) {
        params.created_at_data_to = values[1];
      }
    }
    if (item.name === 'amount' && item.value) {
      const values = item.value.split('&');
      params.amount_from = values[0];
      if (item.operation === FilterOperation.EQUALS) {
        params.amount_to = params.amount_from;
      } else if (values.length > 1) {
        params.amount_to = values[1];
      }
    }
    if (
      (item.name === 'activity_status' ||
        item.name === 'activity_type' ||
        item.name === 'currency') &&
      item.value
    ) {
      params[item.name] = item.value.split(',');
    }
  });
  return params;
};
