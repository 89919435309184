import type { FieldError } from 'react-hook-form';
import type { ChangeEvent, InputHTMLAttributes, ReactElement } from 'react';

import { useState } from 'react';
import CleanIcon from '@/components/svg/clean-icon';

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLElement>, 'size' | 'onChange'> {
  /**是否禁用 Input */
  disabled?: boolean;
  /**设置 input 大小，支持 lg md sm */
  // size?: InputSize;

  /**添加前缀 用于配置一些固定组合 */
  prepend?: string | ReactElement;
  /**添加后缀 用于配置一些固定组合 */
  append?: string | ReactElement;
  onChange?: (value: string) => void;
  warpClass?: string;
  allowClear?: boolean;
  error?: FieldError;
  defaultValue?: string;
  value?: string;
}

export const PercentageInput: React.FC<InputProps> = (props) => {
  const {
    className = '',
    warpClass = '',
    disabled = false,
    allowClear = true,
    error,
    onChange,
    value = '',
    ...restProps
  } = props;

  const [inputValue, setInputValue] = useState<string>(value || '');

  const changeIpt = (evt: ChangeEvent<HTMLInputElement>) => {
    //改变文本框
    if (evt.target.value.length > 4) {
      evt.target.value = evt.target.value.slice(0, 4);
    }
    if (evt.target.value && Number(evt.target.value) - 0 < 0) {
      evt.target.value = '0.00';
    }
    setInputValue(evt.target.value);
    if (onChange) {
      onChange(evt.target.value);
    }
  };

  const showClean =
    allowClear && inputValue && inputValue.length > 0 && !disabled;

  return (
    <div className={`${className} group`}>
      {/* <p className={`mb-2 text-gray-900 text-sm font-medium ${lableClass}`}>{props.labelString}</p> */}
      <div
        className={`group rounded flex flex-1 items-center h-10 px-3 py-2 cursor-auto border ${warpClass}
           ${disabled ? '' : 'shadow-box-drop hover:border-primary-color'} ${
          error && error.message ? 'border-error-color' : 'border-border-color'
        }
        ${disabled ? 'bg-disabled-color' : ''}
          `}
      >
        <div className="flex relative w-full">
          <input
            {...restProps}
            value={
              disabled && !inputValue && !value ? '--' : value || inputValue
            }
            className={`
              focus:outline-none font-medium text-color-text-1 bg-inherit
            placeholder:text-color-text-5 placeholder:font-normal placeholder:text-sm text-sm pr-2 ${
              disabled ? 'text-ellipsis' : ''
            }
              w-full
              ${disabled ? 'bg-disabled-color' : ''}
            `}
            autoComplete="none"
            disabled={disabled}
            placeholder="0.00 %"
            onChange={(evt) => {
              changeIpt(evt);
            }}
            type="number"
          />
          {inputValue && (
            <span className="absolute top-0 left-8 text-color-text-1 text-sm font-medium">
              %
            </span>
          )}
        </div>
        <span className={`invisible ${showClean ? 'group-hover:visible' : ''}`}>
          <CleanIcon
            size={20}
            className="cursor-pointer text-color-text-5 hover:text-color-text-3"
            onClick={() => {
              setInputValue('');
              onChange && onChange('');
            }}
          />
        </span>
      </div>
      {error && (
        <div className="mt-1 text-sm text-error-color">{error.message}</div>
      )}
    </div>
  );
};

export default PercentageInput;
