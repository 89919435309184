// import config from '@/shared/config';
import config from '@/libs/config';
// import { Constants } from '@/shared/constants';
// import { getCookie } from 'cookies-next';
import { getCookie } from '@/utils/index';
import { Constants } from '@/constants';
// import fetch from '@/shared/utils/fetch';
import api from '@/libs/api';


export const queryApplication = async (id: string, token?: string) => {
  if (!token) {
    token = (getCookie(Constants.TOKEN) as string) || '';
  }
  try {
    const res = await api.get(
      `${config.urls.gatewayUrl}/api/registry/business-applications/${id}`,
      {
        headers: {
          'x-user-access-token': token,
        },
      }
    );

    return res;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const withdrawBusinessApplication = async (id: string) => {
  try {
    const token = (getCookie(Constants.TOKEN) as string) || '';

    const res = await api.patch(
      `${config.urls.gatewayUrl}/api/registry/business-applications/withdraw/${id}`,
      {},
      {
        headers: {
          'x-user-access-token': token,
        },
      }
    );

    return res;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const archiveBusinessApplication = async (id: string) => {
  try {
    const token = (getCookie(Constants.TOKEN) as string) || '';
    const res = await api.remove(
      `${config.urls.gatewayUrl}/api/registry/business-applications/archive/${id}`,
      {
        headers: {
          'x-user-access-token': token,
        },
      }
    );
    return res;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};


