import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ShopCartIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="22"
        height="23"
        viewBox="0 0 22 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.90426 23C6.35072 23 5.8785 22.8045 5.48758 22.4136C5.09591 22.022 4.90007 21.5494 4.90007 20.9958C4.90007 20.4423 5.09591 19.9697 5.48758 19.578C5.8785 19.1871 6.35072 18.9916 6.90426 18.9916C7.4578 18.9916 7.93002 19.1871 8.32093 19.578C8.71261 19.9697 8.90845 20.4423 8.90845 20.9958C8.90845 21.5494 8.71261 22.022 8.32093 22.4136C7.93002 22.8045 7.4578 23 6.90426 23ZM18.0132 23C17.4597 23 16.9874 22.8045 16.5965 22.4136C16.2048 22.022 16.009 21.5494 16.009 20.9958C16.009 20.4423 16.2048 19.9697 16.5965 19.578C16.9874 19.1871 17.4597 18.9916 18.0132 18.9916C18.5667 18.9916 19.0389 19.1871 19.4299 19.578C19.8215 19.9697 20.0174 20.4423 20.0174 20.9958C20.0174 21.5494 19.8215 22.022 19.4299 22.4136C19.0389 22.8045 18.5667 23 18.0132 23ZM5.61585 4.90504L8.50761 10.9749H16.5244L19.7024 5.21999C19.7406 5.14364 19.7406 5.07225 19.7024 5.00583C19.6643 4.93864 19.607 4.90504 19.5306 4.90504H5.61585ZM6.90426 17.2738C6.14076 17.2738 5.56813 16.9447 5.18638 16.2865C4.80463 15.6276 4.79509 14.9737 5.15775 14.3247L6.78973 11.3757L2.60957 2.61454H1.2639C1.03485 2.61454 0.839012 2.53323 0.676387 2.3706C0.514525 2.20874 0.433594 2.00374 0.433594 1.75561C0.433594 1.50747 0.514525 1.30209 0.676387 1.13946C0.839012 0.977598 1.04439 0.896667 1.29253 0.896667H3.03904C3.22991 0.896667 3.41124 0.948967 3.58303 1.05357C3.75482 1.15893 3.88843 1.30705 3.98387 1.49792L4.78555 3.18717H20.6759C21.2103 3.18717 21.5875 3.38262 21.8074 3.77354C22.0265 4.16521 22.0216 4.58056 21.7925 5.01957L18.0991 11.662C17.9273 11.9865 17.6887 12.2392 17.3833 12.4202C17.0779 12.6019 16.7343 12.6927 16.3526 12.6927H7.99225L6.67521 15.1264C6.61795 15.2218 6.61337 15.3173 6.66147 15.4127C6.7088 15.5082 6.78973 15.5559 6.90426 15.5559H19.1871C19.4161 15.5559 19.612 15.6418 19.7746 15.8136C19.9364 15.9853 20.0174 16.1858 20.0174 16.4148C20.0174 16.663 19.9364 16.8679 19.7746 17.0298C19.612 17.1924 19.4066 17.2738 19.1584 17.2738H6.90426Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default ShopCartIcon;
