/**
 *  Settled Transactions (Previous Settlements)
 */
import type { IObject } from '@/types/common-types';
import type { IAccountBalanceMerchant } from '@/types/account-balance-type';
import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import BigNumber from 'bignumber.js';
import { dateInTabale } from '../../account-balance-data';
import { getDayEndClosedRecords } from '@/data/account-balance';
import type { ColumnsType } from 'antd/es/table';
import type { TableRecord } from '@/components/table/interface';
import Table from '@/components/table';
import { useTranslation } from 'react-i18next';
import type {
  IFilterField,
  IFilterOptions,
} from '@/components/common/filter';
import Filter, {
  FilterOperation,
  IFilterType,
} from '@/components/common/filter';
import { useParams, useNavigate } from 'react-router-dom';


const AccountBalancePreviousSettlements = (props: IAccountBalanceMerchant) => {
  const { businessID, activekey, refreshPage } = props;
  const { t } = useTranslation("account_balance");
  const { lang = 'en' } = useParams();
  const navigate = useNavigate();
  const [colums, setColums] = useState<ColumnsType<TableRecord>>([]);
  const [previousRecords, setPreviousRecords] = useState<IObject[]>([]);
  const [recordLoading, setRecordLoading] = useState<boolean>(true);
  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<IFilterOptions[]>([]);

  useEffect(() => {
    //构建colunms
    handleColums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    activekey === 'merchantAccount' && handleInitdta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  // 构建columns
  const handleColums = () => {
    const columns: ColumnsType<TableRecord> = [
      {
        key: 'id',
        title: t('account_balance:batch_id'),
        fixed: 'left',
        dataIndex: 'id',
        width: 200,
      },
      {
        key: 'transfer_status',
        title: t('account_balance:settlement_status'),
        dataIndex: 'transfer_status',
        width: 180,
        render: (key, record) => {
          const { transfer_status } = record;
          let color = 'text-primary-color bg-primary-color';
          let returnString = '';
          if (transfer_status === 'Initiated' || !transfer_status) {
            returnString = 'pending';
          } else {
            returnString = transfer_status;
          }

          if (returnString === 'Settled') {
            color = 'text-success-color bg-success-color';
          } else if (returnString === 'Reversed') {
            color = 'text-error-color bg-error-color';
          } else if (returnString === 'pending') {
            color = 'text-warning-color bg-warning-color';
          } else if (returnString === 'Reversed') {
            color = 'text-purple-tag-color bg-purple-tag-color';
          }

          return returnString ? (
            <span
              className={`px-3 py-1 rounded-3xl text-xs leading-normal ${color} bg-opacity-10`}
            >
              {returnString.toUpperCase()}
            </span>
          ) : (
            '--'
          );
        },
      },
      {
        key: 'total_count',
        align: 'right',
        title: t('account_balance:number_of_transactions'),
        width: 210,
        dataIndex: 'total_count',
      },
      {
        key: 'amount',
        align: 'right',
        title: t('account_balance:total_settled_amount'),
        width: 180,
        dataIndex: 'amount',
        render: (key, record) => {
          const { treasury_services_payin_amount = '', currency = 'HKD' } =
            record || {};
          let returnValue = treasury_services_payin_amount;

          if (!isNaN(returnValue)) {
            const temp = new BigNumber(returnValue);
            if (temp.toNumber() > 0) {
              returnValue = `+${temp.toFormat(2, 1)}`;
            } else {
              returnValue = temp.toFormat(2, 1);
            }
          }

          return <span>{`${returnValue} ${currency ? currency : 'HKD'}`}</span>;
        },
      },
      {
        key: 'open_at',
        align: 'right',
        title: t('account_balance:opened_on'),
        width: 140,
        dataIndex: 'open_at',
        render: (key, record) => {
          const { open_at = '' } = record || {};
          if (!open_at) return <span>{`--`}</span>;

          return dateInTabale(open_at, true, lang);
        },
      },
      {
        key: 'store_day_end_closed_at',
        align: 'right',
        title: t('account_balance:closed_on'),
        width: 140,
        dataIndex: 'store_day_end_closed_at',
        render: (key, record) => {
          const { closed_schedule_at = '' } = record || {};
          if (!closed_schedule_at) return <span>{`--`}</span>;

          return dateInTabale(closed_schedule_at, true, lang);
        },
      },
      {
        key: 'estimated_settled_date',
        align: 'right',
        title: t('account_balance:settlement_time'),
        width: 150,
        dataIndex: 'estimated_settled_date',
        render: (key, record) => {
          const { estimated_settled_time, settled_at } = record || {};
          if (!estimated_settled_time) {
            return <span>{'--'}</span>;
          }

          return dateInTabale(settled_at || estimated_settled_time, true, lang);
        },
      },
    ];
    setColums(columns);
    return columns;
  };

  const handleInitdta = () => {
    getPreviousRecords();
  };

  const getPreviousRecords = async (
    page = 1,
    limit = pageSize,
    search = searchValue,
    filterOptions = options
  ) => {
    setRecordLoading(true);
    try {
      const data = await getDayEndClosedRecords({
        pBusinessId: businessID,
        limit: limit + 1,
        offset: (page - 1) * limit,
        searchValue: search,
        options: filterOptions,
      });
      if (Array.isArray(data)) {
        setPreviousRecords(data);
      }
    } catch (error) { }
    setPage(page);
    setPageSize(limit);
    setRecordLoading(false);
    setSearchValue(search);
    setOptions(filterOptions);
  };

  const hadleView = (record: any) => {
    navigate(
      `/${lang}/businesses/${businessID}/account-balance/settlements/${record.id}`
    );
  };

  const filterFields: IFilterField[] = [
    {
      name: 'created_at',
      label: t('account_balance:opening_date'),
      type: IFilterType.DATE,
      operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
    },
    {
      name: 'closed_schedule_at',
      label: t('account_balance:closing_date'),
      type: IFilterType.DATE,
      operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
    },
    {
      name: 'settlement_date',
      label: t('account_balance:settlement_date'),
      type: IFilterType.DATE,
      operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
    },
    {
      name: 'settlement_amount',
      label: t('account_balance:settlement_amount'),
      type: IFilterType.NUMBER,
      operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
    },
    {
      name: 'transfer_status',
      label: t('account_balance:settlement_status'),
      type: IFilterType.MULTISELECT,
      operations: [FilterOperation.EQUALS],
      options: [
        {
          name: 'Initiated',
          label: 'Pending',
        },
        {
          name: 'Settled',
          label: 'Settled',
        },
      ],
    },
  ];

  return (
    <Transition.Child
      className="rounded overflow-hidden shadow-card-drop bg-primary-background-color my-4 h-fit p-4 md:p-6 md:my-6"
      enter="duration-900 transform transition ease-in-out"
      enterFrom="-translate-x-1 translate-y-10 opacity-0"
      enterTo="translate-x-0 translate-y-0 opacity-100"
      leave="duration-500 transform transition"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
    >
      <div className="items-center lg:flex lg:justify-between">
        <div>
          <div className="flex flex-col md:flex-row whitespace-nowrap">
            <span className="font-bold text-xl text-color-text-1 flex items-center md:text-xl">
              {t('account_balance:merchant_service_statement')}
            </span>
            {/* <span className="font-normal text-lg text-color-text-1 md:text-xl">
              (Previous Settlements)
            </span> */}
          </div>
        </div>
        <Filter
          fields={filterFields}
          onFilter={(search, options) =>
            getPreviousRecords(1, pageSize, search, options)
          }
          searchValue={searchValue}
          options={options}
          placeholder={t('account_balance:search_batch_id')}
        />
        {/* <div className="flex space-x-4 justify-start mt-4 md:justify-end md:mt-0">
          <Button
            styleType="Secondary"
            onClick={() => exportPdf('merchant_services_statement', {})}
          >
            Export
          </Button>
        </div> */}
      </div>
      <div className="w-full mt-4 md:mt-6">
        <div className="overflow-hidden">
          <Table
            className="!max-h-96"
            columns={colums}
            records={previousRecords}
            loadingData={recordLoading}
            search={searchValue.length > 0 || options.length > 0}
            pagination={{
              className: 'mt-6',
              current: page,
              currentSize: previousRecords.length || 0,
              pageSize,
              pageSizeOptions: [30, 60, 120],
              changePageCallback: getPreviousRecords,
            }}
            onRowCell={(record, dataKey) => {
              return {
                onClick: (evt) => {
                  evt.stopPropagation();
                  if (record.id) {
                    hadleView(record);
                  }
                },
              };
            }}
          />
        </div>
      </div>
    </Transition.Child>
  );
};

export default AccountBalancePreviousSettlements;
