import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ChequeIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_14022_327801)">
          <path
            d="M2.35607 8.24805H23.4787C24.224 8.24805 24.827 8.85099 24.827 9.5963V19.0341C24.827 19.7794 24.224 20.3823 23.4787 20.3823H2.35607C1.61076 20.3823 1.00781 19.7794 1.00781 19.0341V9.5963C1.00781 8.85378 1.61355 8.24805 2.35607 8.24805Z"
            fill="white"
          />
          <path
            d="M22.806 8.24805V17.6858C22.806 18.0599 22.5045 18.3613 22.1305 18.3613H1.00781V19.0369C1.00781 19.785 1.60797 20.3851 2.35607 20.3851H23.4787C24.2268 20.3851 24.827 19.785 24.827 19.0369V9.5963C24.827 8.8482 24.2268 8.24805 23.4787 8.24805H22.806Z"
            fill="#E5F0F1"
          />
          <path
            d="M2.3519 7.57617C1.24371 7.57617 0.328125 8.48896 0.328125 9.59995V19.0377C0.328125 20.1459 1.24092 21.0615 2.3519 21.0615H23.4746C24.5827 21.0615 25.4983 20.1487 25.4983 19.0377V12.7459C25.5039 12.3746 25.2052 12.0676 24.834 12.062C24.4627 12.0564 24.1557 12.3551 24.1501 12.7263V19.0377C24.1501 19.4229 23.8598 19.7132 23.4746 19.7132H2.3519C1.96669 19.7132 1.67638 19.4229 1.67638 19.0377V9.59716C1.67638 9.21194 1.96669 8.92163 2.3519 8.92163H18.0815C18.4556 8.93001 18.7626 8.63133 18.7682 8.26007C18.7738 7.88881 18.4751 7.58175 18.1039 7.57617H2.3519Z"
            fill="#444852"
          />
          <path
            d="M4.16054 11.17C3.78928 11.1644 3.48222 11.4631 3.47664 11.8344C3.47106 12.2056 3.76974 12.5127 4.141 12.5183H14.4972C14.8684 12.5238 15.1755 12.2252 15.181 11.8539C15.1866 11.4826 14.8879 11.1756 14.5167 11.17H4.16054ZM4.16054 13.8665C3.78928 13.8609 3.48222 14.1596 3.47664 14.5309C3.47106 14.9021 3.76974 15.2092 4.141 15.2148H11.8006C12.1719 15.2203 12.479 14.9217 12.4845 14.5504C12.4901 14.1791 12.1914 13.8721 11.8202 13.8665H4.16054Z"
            fill="#D7DFEB"
          />
          <path
            d="M23.7625 5.73091C23.1149 5.35686 22.2942 5.57738 21.9202 6.22499L16.9766 14.7863L17.4399 17.1311L19.701 16.3606L24.6446 7.79935C25.0186 7.15174 24.7981 6.33106 24.1505 5.95701L23.7625 5.73091Z"
            fill="#FFEE91"
          />
          <path
            d="M23.9573 5.8418L18.8993 14.6013L17.0625 15.2265L17.4393 17.1303L19.7004 16.3599L24.644 7.79579C25.018 7.14818 24.7975 6.3275 24.1499 5.95345L23.9573 5.8418Z"
            fill="#F9D151"
          />
          <path
            d="M24.1021 5.14747C23.1419 4.59198 21.8941 4.92695 21.3386 5.88719L18.1927 11.336C18.0029 11.6571 18.1061 12.0702 18.4272 12.26C18.7482 12.4498 19.1613 12.3465 19.3511 12.0255L19.3595 12.0088L22.5054 6.55993C22.698 6.22775 23.0916 6.12167 23.4266 6.31428L23.8146 6.54039C24.1468 6.73299 24.2528 7.12658 24.0602 7.46155L19.245 15.8051L17.9554 16.2433L17.6902 14.9035L18.2373 13.9544C18.4272 13.6334 18.3239 13.2202 18.0029 13.0304C17.6819 12.8406 17.2687 12.9439 17.0789 13.2649L17.0705 13.2817L16.395 14.4485C16.3141 14.5908 16.2861 14.7555 16.3169 14.9146L16.7802 17.2594C16.8528 17.6251 17.2073 17.8624 17.573 17.7898C17.6037 17.7842 17.6316 17.7758 17.6595 17.7675L19.9206 16.997C20.0741 16.944 20.2053 16.8379 20.2862 16.6956L25.2298 8.13149C25.7853 7.17125 25.4504 5.92348 24.4901 5.36799L24.1021 5.14747Z"
            fill="#444852"
          />
        </g>
        <defs>
          <clipPath id="clip0_14022_327801">
            <rect
              width="25"
              height="25"
              fill="white"
              transform="translate(0.5 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ChequeIcon;
