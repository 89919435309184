import { getCookie } from '@/utils/index';
import Config from '@/libs/config';
import { Constants } from '@/constants';
import api from '@/libs/api';
import { IObject } from '@/types/common-types';


export const newGetApiCredential = async (businessID: string, offset: number,limit:number) => {
  const token = getCookie(Constants.TOKEN) || '';
  let data = [];
  try {
    const resp: IObject = await api.get(
      `${Config.urls.mainBindo}/svc/user/api/v1/${businessID}/app?offset=${offset}&limit=${limit}`,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'Content-Type': 'application/json',
        },
      }
    );

    if (resp.code === 200 && Array.isArray(resp.data)) {
      data = resp.data;
    }
  } catch (error) {
    console.error(error);
  }
  return data;
};


export const newGenerateApiCredential = async (
  businessID: string,
  publicKey:string
) => {
  const token = getCookie(Constants.TOKEN) || '';
  let data: IObject = {};
  let param = {
    signature_public_key: publicKey,
  }
  try {
    const resp: IObject = await api.post(
      `${Config.urls.mainBindo}/svc/user/api/v1/${businessID}/app`,
      { ...param },
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'Content-Type': 'application/json',
        },
      }
    );

    if (resp.code === 200 && resp.data && resp.data.id) {
      data = resp.data;
    } else {
      data = {
        ...resp,
      };
    }
  } catch (error) {
    console.error(error);
  }
  return data;
};


export const NewUpdateApiCredential = async (
  businessID: string,
  id: string,
  body: any
) => {
  const token = getCookie(Constants.TOKEN) || '';

  let data: IObject = {};
  try {
    data = await api.put(
      `${Config.urls.mainBindo}/svc/user/api/v1/${businessID}/app/${id}`,
      {
        ...body,
      },
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'Content-Type': 'application/json',
        },
      }
    );
  } catch (error) {
    console.error(error);
  }
  return data;
};
