import { useEffect, useMemo, useState } from 'react';
import html2canvas from 'html2canvas';
import { queryBusiness } from '@/data/businesses';
import { Constants } from '@/constants';
import { getCookie, verifyEmail } from '@/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CloseIcon from './close-icon';
import LoadingIcon from '@/components/svg/loading-icon';
import SuccessfullyIcon from '@/components/svg/successfully-icon';
import FailedIcon from '@/components/svg/failed-icon';
import DownloadIcon from '@/components/svg/download-icon';
import SendIcon from '@/components/svg/send-icon';
import AntdModal from '@/components/common/antd/modal';
import config from '@/libs/config';
import api from '@/libs/api';
import { getQRCodeLink } from '@/data/associates';
import QrCode from '@/components/common/qr-code';
import Button from '@/components/common/button';
import Input from '@/components/common/input';
import Label from '@/components/common/label';

const AssociatesInvitePage = () => {
  const { lang = 'en', p_business_id: businessID = '' } = useParams();
  const [show, setShow] = useState(false);
  const [business, setBusiness] = useState<any>({})
  const [resultModal, setResultModal] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [url, setUrl] = useState('');
  const [error, setError] = useState<
    | {
      type: 'required' | 'validate';
        message: string;
      }
    | undefined
  >(undefined);
  const [emailError, setEmailError] = useState({
    code: 200,
    message: '',
  });
  const { t } = useTranslation('associates');
  const { t: ct } = useTranslation();
  const navigate = useNavigate();
  const { translations, business_name } = business || {};
  const { code, message } = emailError;

  const businessName = useMemo(() => {
    let businessName = business_name;
    if (Array.isArray(translations)) {
      for (let i = 0; i < translations.length; i++) {
        const item = translations[i];
        if (item.languages_code === lang && item.business_name) {
          businessName = item.business_name;
        }
      }
    }

    return businessName;
  }, [business_name, lang, translations]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [businessID]);

  const handleSaveQR = () => {
    const dom = document.getElementById('image');
    if (dom) {
      html2canvas(dom).then((e) => {
        setSaveLoading(true);
        const saveA = document.createElement('a');
        const blob = base64ToBlob(e.toDataURL());
        const objectUrl = window.URL.createObjectURL(blob);
        saveA.setAttribute('href', objectUrl);
        saveA.setAttribute('download', 'Invite QR.png');
        document.body.appendChild(saveA);
        saveA.click();
        window.URL.revokeObjectURL(objectUrl);
        document.body.removeChild(saveA);
        setSaveLoading(false);
      });
    }
  };

  const base64ToBlob = (code: string) => {
    const parts = code.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  };

  const loadData = async () => {
    if (!url) {
      const token = getCookie(Constants.TOKEN) || '';
      const allPromise: any[] = [
        getQRCodeLink(businessID, token, lang),
        queryBusiness(businessID),
      ];
      const resp = await Promise.all(allPromise);
      if (resp[0]) {
        setUrl(resp[0]);
      }
      setBusiness(resp[1]);
    }
  };

  const handleSendEmail = async () => {
    setSendLoading(true);
    const token = getCookie(Constants.TOKEN) || '';
    const result: any = await api.post(
      `${config.urls.gatewayUrl}/api/galaxy/associates/invite/email`,
      {
        email,
      },
      {
        headers: {
          'x-user-access-token': token,
          'x-platform-id': config.platformId,
          'x-p-business-id': businessID,
          'x-i18n-lang': lang,
        },
      }
    );

    const { code = 500, message } = result;
    setEmailError({ code, message });
    setResultModal(true);
    setSendLoading(false);
    setShow(false);
  };

  const handleChange = (value: string) => {
    if (!value) {
      setError({
        type: 'required',
        message: t('field_is_required'),
      });
    } else if (!verifyEmail(value)) {
      setError({
        type: 'validate',
        message: t('invalid_email_address'),
      });
    } else {
      setError(undefined);
    }
    setEmail(value);
  };

  return (
    <>
      <div className="flex flex-col absolute top-0 bottom-0 left-0 right-0 bg-system-background-color text-color-text-1 overflow-auto">
        <div className="px-8 py-6 flex items-center justify-between">
          <div className="text-3xl font-bold">
            {t('organization_qr')}
          </div>
          <CloseIcon
            size={24}
            className="p-2 bg-white rounded-full shadow-box-drop hover:cursor-pointer hover:text-primary-color"
            onClick={() => {
              navigate(`/${lang}/businesses/${businessID}/associates`);
            }}
          />
        </div>
        <div className="justify-center flex flex-col items-center w-[400px] mx-auto">
          <div className="pt-3 font-bold text-[40px] leading-[46px] text-center">
            {businessName}
          </div>
          <div className="pt-4 text-sm font-medium">
            {t('scan_to_join')}
          </div>
          <div className="h-[400px] mt-10">
            <div
              className={`p-5 border-4 ${
                url ? 'border-primary-color' : 'border-border-color'
              } rounded-3xl relative bg-primary-background-color`}
            >
              <QrCode
                value={url}
                className={url ? '' : 'opacity-10'}
                size={352}
              />
              {!url && (
                <div className="text-center items-center flex flex-col justify-center absolute inset-0">
                  <div className="text-center cursor-pointer items-center flex flex-col justify-center">
                    <LoadingIcon />
                    <div className="text-sm mt-2 text-[#0094FF]">
                      {t('loading')}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Button
            className="mt-10 w-full"
            styleType="Secondary"
            loading={saveLoading}
            onClick={handleSaveQR}
          >
            <div className="flex items-center">
              {!saveLoading && <DownloadIcon size={18} className="mr-0.5" />}
              <div>{t('save_organization_qr')}</div>
            </div>
          </Button>
          <Button className="mt-4 mb-20 w-full" onClick={() => setShow(true)}>
            <div className="flex items-center">
              <SendIcon size={18} className="mr-0.5" />
              <div>{t('send_invitation')}</div>
            </div>
          </Button>
        </div>
        {show && (
          <AntdModal
            size="medium"
            open={true}
            footer={null}
            onCancel={() => {
              setShow(false);
              setEmail('');
            }}
          >
            <div className="p-6 pt-8 flex flex-col text-color-text-1">
              <div className="font-bold text-xl mb-6 text-center">
                {t('send_invitation')}
              </div>
              <div>
                <Label
                  value={t('email_address')}
                  required={true}
                ></Label>
                <Input
                  onChange={handleChange}
                  placeholder={'example@wonder.com'}
                  value={email}
                  error={error}
                />
              </div>
              <Button
                className="w-full mt-24"
                disabled={!email || error !== undefined}
                loading={sendLoading}
                onClick={handleSendEmail}
              >
                {t('send')}
              </Button>
            </div>
          </AntdModal>
        )}
        {resultModal && (
          <AntdModal
            size="medium"
            open={true}
            footer={null}
            onCancel={() => setResultModal(false)}
            closeIcon={null}
            closable={false}
          >
            <div className="p-10 pt-8 flex flex-col text-color-text-1 items-center">
              {code === 200 ? <SuccessfullyIcon /> : <FailedIcon />}
              {code === 200 ? (
                <div className="text-color-text-1 mt-4 mb-2 text-xl font-bold text-center">
                  {t('sent_success')}
                </div>
              ) : (
                <div className="text-error-color mt-4 mb-2 text-sm text-center">
                  <div className="text-xl font-bold mb-2">
                    {t('unable_to_send')}
                  </div>
                  <div>{`${t('error_code')}: ${code}`}</div>
                  <div>{message}</div>
                </div>
              )}
              <div className="text-sm font-medium">{`${t(
                'associates:to'
              )} ${email}`}</div>
              {code === 200 ? (
                <Button
                  className="w-full mt-6"
                  onClick={() => {
                    setResultModal(false);
                    setEmail('');
                  }}
                >
                  {t('done')}
                </Button>
              ) : (
                <Button
                  className="w-full mt-6"
                  styleType="Border"
                  onClick={() => {
                    setResultModal(false);
                    setEmail('');
                  }}
                >
                  {ct('back')}
                </Button>
              )}
            </div>
          </AntdModal>
        )}
      </div>
      <div
        id="image"
        style={{
          padding: '30px 22px 20px 44px',
          textAlign: 'center',
          borderRadius: '16px',
          backgroundColor: '#F5F5F5',
          maxWidth: '386px',
        }}
      >
        <div style={{ paddingRight: '22px' }}>
          <div
            style={{ fontSize: '20px', lineHeight: '24px', fontWeight: '600' }}
          >
            {businessName}
          </div>
          <div
            style={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '140%',
              marginTop: '12px',
            }}
          >
            {t('scan_join')}
          </div>
          <div
            style={{
              marginTop: '17px',
              display: 'flex',
              justifyContent: 'center',
              height: '269px',
            }}
          >
            <div
              style={{
                borderRadius: '27px',
                border: '4px solid #0094FF',
                padding: '7px',
                background: '#fff',
              }}
            >
              <QrCode
                value={url}
                size={246}
              />
            </div>
          </div>
          <div
            style={{ fontSize: '10px', lineHeight: '24px', marginTop: '7px' }}
          >
            {t('business_id')}: {businessID}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '15px',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="21"
            viewBox="0 0 100 21"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M66.356 6.99068C66.822 7.30137 67.2881 7.61207 67.5988 7.92277V0H71.0164V20.0399H67.4434V18.7972C67.1327 19.1078 66.6667 19.4185 66.2006 19.7292C65.4239 20.1953 64.4918 20.3506 63.5597 20.3506C62.3169 20.3506 61.2295 20.0399 60.2974 19.4185C59.3653 18.7972 58.5886 18.0204 57.9672 16.933C57.5011 15.8455 57.1904 14.6027 57.1904 13.36C57.1904 11.9618 57.3458 10.8744 57.9672 9.78695C58.5886 8.69951 59.3653 7.92277 60.2974 7.30137C61.3848 6.67998 62.4723 6.36928 63.7151 6.36928C64.8025 6.36928 65.5792 6.67998 66.356 6.99068ZM66.2006 16.4669C66.822 16.1562 67.1327 15.6902 67.4434 15.2241C67.7106 14.8234 67.8629 14.1929 67.9991 13.629C68.0213 13.5371 68.0431 13.447 68.0648 13.36C68.0648 12.5832 67.9095 11.9618 67.5988 11.4958C67.2881 11.0297 66.822 10.5637 66.356 10.253C65.8899 9.9423 65.2685 9.78695 64.6471 9.78695C63.8704 9.78695 63.249 9.9423 62.783 10.253C62.1616 10.5637 61.8509 11.0297 61.5402 11.4958C61.2295 12.1172 61.0741 12.5832 61.0741 13.36C61.0741 13.9813 61.2295 14.6027 61.5402 15.2241C61.8509 15.6902 62.3169 16.1562 62.783 16.4669C63.249 16.7776 63.8704 16.933 64.4918 16.933C65.1132 16.933 65.7346 16.7776 66.2006 16.4669Z"
              fill="black"
            />
            <path
              d="M18.8194 13.5159L23.0138 0.622H26.5868L20.3729 20.0405H17.5766L13.5375 6.99128L9.49848 20.0405H6.70221L0.488281 0.622H4.06129L8.10035 13.5159L12.1394 0.622H14.9357L18.8194 13.5159Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M33.2668 6.36868C34.5096 6.36868 35.7524 6.67937 36.8398 7.30076C37.9272 7.92216 38.8593 8.6989 39.4807 9.78634C40.1021 10.8738 40.4128 12.1166 40.4128 13.3594C40.4128 14.6021 40.1021 15.8449 39.4807 16.9324C38.8593 18.0198 37.9272 18.7965 36.8398 19.4179C35.7524 20.0393 34.6649 20.35 33.2668 20.35C32.024 20.35 30.7812 20.0393 29.6938 19.4179C28.6063 18.7965 27.6742 18.0198 27.0528 16.9324C26.4315 15.8449 26.1208 14.7575 26.1208 13.3594C26.1208 11.9612 26.4315 10.8738 27.0528 9.78634C27.6742 8.6989 28.6063 7.92216 29.6938 7.30076C30.7812 6.67937 31.8686 6.36868 33.2668 6.36868ZM34.9756 16.4663C35.597 16.1556 35.9077 15.6896 36.2184 15.2235C36.5291 14.6021 36.6844 13.9807 36.6844 13.3594C36.6844 12.5826 36.5291 11.9612 36.2184 11.4952C35.9077 11.0291 35.4417 10.5631 34.9756 10.2524C34.3542 9.94169 33.7328 9.78634 33.1114 9.78634C32.49 9.78634 31.8686 9.94169 31.4026 10.2524C30.9366 10.5631 30.4705 10.8738 30.1598 11.4952C29.8491 12.1166 29.6938 12.5826 29.6938 13.3594C29.6938 13.9807 29.8491 14.6021 30.1598 15.2235C30.4705 15.6896 30.9366 16.1556 31.4026 16.4663C31.8686 16.777 32.49 16.9324 33.1114 16.9324C33.8882 16.9324 34.5096 16.777 34.9756 16.4663Z"
              fill="black"
            />
            <path
              d="M52.8407 7.14542C52.0639 6.67937 51.1319 6.36868 50.1998 6.36868C49.1123 6.36868 48.3356 6.52402 47.4035 6.99007C47.334 7.03643 47.2679 7.07933 47.2047 7.12033C46.8447 7.35405 46.5804 7.52566 46.3161 7.92216V6.67937H42.743V20.0393H46.3161V12.4273C46.3161 11.9612 46.4714 11.4952 46.6268 11.0291C46.9375 10.5631 47.2481 10.2524 47.5588 10.097C48.0249 9.94169 48.4909 9.78634 48.957 9.78634C49.7337 9.78634 50.3551 10.097 50.8212 10.5631C51.2872 11.0291 51.5979 11.6505 51.5979 12.4273V20.0393H55.1709V11.6505C55.1709 10.7184 55.0156 9.94169 54.5495 9.16495C54.2388 8.23286 53.6174 7.61146 52.8407 7.14542Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M84.0656 7.30076C83.1336 6.67937 81.8908 6.36868 80.648 6.36868C79.4052 6.36868 78.1624 6.67937 77.075 7.30076C75.9875 7.92216 75.2108 8.6989 74.5894 9.78634C73.968 10.8738 73.6573 11.9612 73.6573 13.3594C73.6573 14.7575 73.968 15.8449 74.5894 16.9324C75.2108 18.0198 76.1429 18.7965 77.2303 19.4179C78.3178 20.0393 79.5605 20.35 80.9587 20.35C82.0461 20.35 82.9782 20.1947 83.9103 19.7286C84.8424 19.4179 85.6191 18.7965 86.2405 18.0198L84.0656 15.8449C83.5996 16.311 83.1336 16.6217 82.6675 16.9324C82.0461 17.0877 81.4247 17.2431 80.8033 17.2431C80.0266 17.2431 79.4052 17.0877 78.7838 16.777C78.1624 16.4663 77.6964 16.0003 77.3857 15.3789C77.308 15.2235 77.2691 15.107 77.2303 14.9905C77.1915 14.874 77.1526 14.7574 77.075 14.6021H86.8619C86.8619 14.4201 86.9152 14.2381 86.9594 14.0874C86.9906 13.9808 87.0173 13.8897 87.0173 13.8254V13.0487C87.0173 11.8059 86.7066 10.7184 86.0852 9.63099C85.7745 8.54355 84.9977 7.76681 84.0656 7.30076ZM77.2303 11.9612C77.2303 11.7792 77.2836 11.6505 77.3278 11.5439C77.359 11.4685 77.3857 11.4042 77.3857 11.3398C77.6964 10.7184 78.1624 10.2524 78.6285 9.94169C79.0945 9.63099 79.7159 9.47564 80.4926 9.47564C81.114 9.47564 81.7354 9.63099 82.2015 9.94169C82.6675 10.2524 82.9782 10.5631 83.2889 11.1845C83.3666 11.3398 83.4054 11.4951 83.4442 11.6504C83.4831 11.8058 83.5219 11.9612 83.5996 12.1166L77.2303 11.9612Z"
              fill="black"
            />
            <path
              d="M96.4935 6.36868C97.1149 6.36868 97.7363 6.52402 98.2024 6.67937C98.8238 6.99007 99.2898 7.30076 99.7558 7.76681L97.4256 10.4077C97.2703 10.2524 96.9596 10.097 96.6489 9.94169C96.3382 9.78634 96.0275 9.78634 95.7168 9.78634C94.94 9.78634 94.3187 10.097 93.8526 10.5631C93.3866 11.0291 93.0759 11.8059 93.0759 12.738V20.0393H89.5029V6.67937H93.0759V7.76681C93.8526 6.83472 94.94 6.36868 96.4935 6.36868Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default AssociatesInvitePage;
