import Button from '@/components/common/button';
import Label from '@/components/common/label';
import { IAreaCodes, IObject } from '@/types/common-types';
import { INewPayeeFormData } from '@/types/payouts-type';
import { findNode } from '@/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface IAccountDetailsProps {
  areaCodes: IAreaCodes[];
  heade: React.ReactNode;
  payeeData: INewPayeeFormData;
  business: IObject;
  setCurrentStep: (currentStep: number) => void;
  setStepCount: (stepCount: number) => void;
}

const AccountDetails: React.FC<IAccountDetailsProps> = (props) => {
  const { areaCodes, heade, payeeData, business, setCurrentStep } = props;

  const { country } = payeeData;
  const { t } = useTranslation('payees');
  const { t: ct } = useTranslation('common');
  const { lang = 'en' } = useParams();

  const handleNext = async () => {
    setCurrentStep(4);
  };

  const businessName = useMemo(() => {
    const { business_name, translations } = business || {};
    let businessName = business_name || '';
    if (Array.isArray(translations)) {
      for (let i = 0; i < translations.length; i++) {
        const item = translations[i];
        if (lang === item.languages_code) {
          businessName = item.business_name;
          break;
        }
      }
    }
    return businessName;
    // eslint-disable-next-line
  }, [business, lang]);

  const countryName = useMemo(() => {
    let countryName = '';
    const areaCode = findNode<IAreaCodes>(areaCodes, 'isoAlpha2Code', country);
    if (areaCode) {
      const { name, translations } = areaCode;
      countryName = name;
      if (Array.isArray(translations)) {
        for (let i = 0; i < translations.length; i++) {
          const item = translations[i];
          if (lang === item.languages_code) {
            countryName = item.country_name;
            break;
          }
        }
      }
    }

    return countryName;
    // eslint-disable-next-line
  }, [business, lang]);

  return (
    <div>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        {heade}
        <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
        <div className="font-medium text-color-text-1">
          <Label
            className="mb-0 mt-4 md:mt-6 md:mb-1.5"
            value={t('country')}
          />
          <div className="h-10 px-3 bg-disabled-color border-border-color border rounded flex items-center">
            {countryName}
          </div>
          <Label
            className="mb-0 mt-4 md:mt-6 md:mb-1.5"
            value={t('wonder_account_id')}
          />
          <div className="h-10 px-3 bg-disabled-color border-border-color border rounded flex items-center">
            {business.p_business_id}
          </div>
          <Label
            className="mb-0 mt-4 md:mt-6 md:mb-1.5"
            value={t('business_legal_name')}
          />
          <div className="h-10 px-3 bg-disabled-color border-border-color border rounded flex items-center">
            {business.business_legal_name}
          </div>
          <Label
            className="mb-0 mt-4 md:mt-6 md:mb-1.5"
            value={t('business_account_name')}
          />
          <div className="h-10 px-3 bg-disabled-color border-border-color border rounded flex items-center">
            {businessName}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <Button className="flex-1" onClick={handleNext} styleType="Primary">
          {ct('next')}
        </Button>
      </div>
    </div>
  );
};

export default AccountDetails;
