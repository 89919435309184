interface TabsContentProps {
  paneChildren: any[];
  activeIndex?: React.Key;
}

export default function TabContent(props: TabsContentProps) {
  const { paneChildren, activeIndex } = props;

  return <>{paneChildren.filter((p) => p.key === activeIndex)}</>;
}
