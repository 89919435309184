export enum ILanguageKeys {
  EN = 'en',
  ZH_CN = 'zh-CN',
  ZH_HK = 'zh-HK',
}

export const languageTransform: any = {
  en_US: ILanguageKeys.EN,
  zh_CN: ILanguageKeys.ZH_CN,
  zh_HK: ILanguageKeys.ZH_HK,
};

export const i18nTransform: any = {
  [ILanguageKeys.EN]: 'en-US',
  [ILanguageKeys.ZH_CN]: 'zh-CN',
  [ILanguageKeys.ZH_HK]: 'zh-HK',
};
