import React from 'react';
import type { IIconProps } from '@/types/common-types';

const NoPayoutIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 176 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 176 176"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="176"
          height="176"
          rx="88"
          fill="url(#paint0_linear_17386_17992)"
        />
        <mask
          id="mask0_17386_17992"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="176"
          height="176"
        >
          <rect width="176" height="176" rx="88" fill="white" />
        </mask>
        <g mask="url(#mask0_17386_17992)">
          <g filter="url(#filter0_d_17386_17992)">
            <rect
              x="140.18"
              y="108.092"
              width="118"
              height="118"
              rx="4"
              transform="rotate(-20 140.18 108.092)"
              fill="url(#paint1_linear_17386_17992)"
            />
            <rect
              x="140.821"
              y="108.391"
              width="117"
              height="117"
              rx="3.5"
              transform="rotate(-20 140.821 108.391)"
              stroke="#CBD5E1"
              strokeOpacity="0.8"
            />
          </g>
          <g filter="url(#filter1_d_17386_17992)">
            <rect
              x="91.6133"
              y="-25.3458"
              width="118"
              height="118"
              rx="4"
              transform="rotate(-20 91.6133 -25.3458)"
              fill="url(#paint2_linear_17386_17992)"
            />
            <rect
              x="92.2541"
              y="-25.0469"
              width="117"
              height="117"
              rx="3.5"
              transform="rotate(-20 92.2541 -25.0469)"
              stroke="#CBD5E1"
              strokeOpacity="0.8"
            />
          </g>
          <g filter="url(#filter2_d_17386_17992)">
            <rect
              x="-65.4219"
              y="-41.6172"
              width="118"
              height="118"
              rx="4"
              transform="rotate(-20 -65.4219 -41.6172)"
              fill="url(#paint3_linear_17386_17992)"
            />
            <rect
              x="-64.781"
              y="-41.3184"
              width="117"
              height="117"
              rx="3.5"
              transform="rotate(-20 -64.781 -41.3184)"
              stroke="#CBD5E1"
              strokeOpacity="0.8"
            />
          </g>
          <g filter="url(#filter3_d_17386_17992)">
            <rect
              x="-16.8555"
              y="91.8183"
              width="118"
              height="148"
              rx="4"
              transform="rotate(-20 -16.8555 91.8183)"
              fill="url(#paint4_linear_17386_17992)"
            />
            <rect
              x="-16.2146"
              y="92.1171"
              width="117"
              height="147"
              rx="3.5"
              transform="rotate(-20 -16.2146 92.1171)"
              stroke="#CBD5E1"
              strokeOpacity="0.8"
            />
          </g>
          <g opacity="0.4" filter="url(#filter4_f_17386_17992)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M28.1913 81.2287L93.6286 95.7614L67.5343 113.088L56.0116 142.215L28.1913 81.2287Z"
              fill="#475569"
              fillOpacity="0.32"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.0425 48.8966L135.127 69.7913L97.6089 94.7032L81.0418 136.581L41.0425 48.8966Z"
            fill="url(#paint5_linear_17386_17992)"
          />
          <mask
            id="mask1_17386_17992"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="41"
            y="48"
            width="95"
            height="89"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M41.0425 48.8966L135.127 69.7913L97.6089 94.7032L81.0418 136.581L41.0425 48.8966Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_17386_17992)">
            <rect
              x="26.9492"
              y="38.0569"
              width="54.4701"
              height="103.277"
              transform="rotate(-51 26.9492 38.0569)"
              fill="url(#paint6_linear_17386_17992)"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M115.343 104.241C125.859 111.922 137.756 117.697 150.226 121.292C150.909 121.494 151.084 120.88 150.411 120.664C144.273 118.752 138.442 115.834 132.913 112.571C127.371 109.303 122.126 105.702 116.806 102.245C114.661 100.831 113.123 102.595 115.343 104.241Z"
            fill="#475569"
            fillOpacity="0.4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M113.054 118.196C132.309 140.066 151.691 161.924 170.984 183.786C172.042 184.985 172.52 184.561 171.474 183.354C152.482 161.414 134.611 137.887 114.907 116.559C110.906 112.229 108.981 113.571 113.054 118.196Z"
            fill="#475569"
            fillOpacity="0.4"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_17386_17992"
            x="137.306"
            y="66.8591"
            width="156.99"
            height="156.991"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0588235 0 0 0 0 0.0901961 0 0 0 0 0.164706 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_17386_17992"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_17386_17992"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_17386_17992"
            x="88.7393"
            y="-66.5784"
            width="156.99"
            height="156.991"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0588235 0 0 0 0 0.0901961 0 0 0 0 0.164706 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_17386_17992"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_17386_17992"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_17386_17992"
            x="-68.2959"
            y="-82.8499"
            width="156.99"
            height="156.991"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0588235 0 0 0 0 0.0901961 0 0 0 0 0.164706 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_17386_17992"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_17386_17992"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_17386_17992"
            x="-19.7295"
            y="50.5857"
            width="167.251"
            height="185.181"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0588235 0 0 0 0 0.0901961 0 0 0 0 0.164706 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_17386_17992"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_17386_17992"
              result="shape"
            />
          </filter>
          <filter
            id="filter4_f_17386_17992"
            x="1.00859"
            y="54.0459"
            width="119.803"
            height="115.351"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="13.5914"
              result="effect1_foregroundBlur_17386_17992"
            />
          </filter>
          <linearGradient
            id="paint0_linear_17386_17992"
            x1="0"
            y1="0"
            x2="0"
            y2="176"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F3F5F9" />
            <stop offset="1" stopColor="#E2E8F0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_17386_17992"
            x1="240.271"
            y1="226.092"
            x2="240.271"
            y2="143.91"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8FAFC" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_17386_17992"
            x1="191.704"
            y1="92.6542"
            x2="191.704"
            y2="10.4726"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8FAFC" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_17386_17992"
            x1="34.669"
            y1="76.3828"
            x2="34.669"
            y2="-5.79892"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8FAFC" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_17386_17992"
            x1="83.2354"
            y1="239.818"
            x2="83.2354"
            y2="136.743"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8FAFC" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_17386_17992"
            x1="14"
            y1="82.2913"
            x2="81.0418"
            y2="136.581"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A5C8FC" />
            <stop offset="1" stopColor="#81A9F8" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_17386_17992"
            x1="33.632"
            y1="63.3984"
            x2="33.632"
            y2="141.334"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0075FF" />
            <stop offset="1" stopColor="#0094FF" stopOpacity="0.01" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default NoPayoutIcon;
