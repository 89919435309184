import BankAccountDetails from './bank-account-details';
import { type INewRelatedFormData } from './type';
import Button from '@/components/common/button';
import { useEffect, useState } from 'react';
import { getCookie } from '@/utils/index';
import { Constants } from '@/constants';
import { Alert } from '@/components/common/alert/alert';
import { useTranslation } from 'react-i18next';
import { getBankList, getTransferMethodDetail, payeeCreate } from './server';
import BankStatements from './bank-statements';
import ModalEmpty from '@/components/common/modal/modal-empty';
import WarningIcon from '@/components/svg/warning-icon';
import BackIcon from '@/components/svg/back-icon';
import { useParams, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import GlobalLoading from '@/components/common/global-loading';
import { useForm } from 'react-hook-form';
import { IReducersState } from '@/reducers';
import { loadAreaCodesData } from '@/data/area-codes';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';

const RelatedFormNew = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation(["settings", "business_account_settings", "account_balance","related_company_accounts"]);
  const { lang = 'en', p_business_id: businessID = '' } = useParams();
  const [banks, setbanks] = useState<any>([])
  const [transferMethod, settransferMethod] = useState<any>([])
  const [areaCodes, setareaCodes] = useState<any>([])

  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const token = getCookie(Constants.TOKEN || '') ?? '';
  const business = useSelector((state: IReducersState) => state.businessState.business);
  // const business = useSelector((state: RootState) => state.common.business);
  const useFormReturn = useForm({
    mode: 'all',
  });

  const initRelated: INewRelatedFormData = {
    nickname: '',
    country: 'HK',
    currency: 'HKD',
    identifier_1_value: '',
    identifier_2_value: business.business_legal_name,
    identifier_3_value: '',
    bank_settlement_files: [],
    bank: {
      id: '',
      country: '',
      bank_code: '',
      bank_name: '',
      swift_code: '',
    },
    payout_accounts: {},
  };
  const [relatedFormDate, setRelatedFormDate] =
    useState<INewRelatedFormData>(initRelated);
  const [cancelModal, setCancelModal] = useState(false);
  const [hasEdit, setHasEdit] = useState(false);

  const [saveDataloading, setSaveDataLoading] = useState<boolean>(false);

  useEffect(() => {
    initLoad();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('settings:settings'),
          key: '1',
          type: 'label',
        },
        {
          name: t('related_company_accounts:related_company'),
          key: '2',
          type: 'link',
          url: `/businesses/${businessID}/settings/related-company-accounts`,
        },
        {
          name: t('related_company_accounts:add_new'),
          key: '3',
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  const initLoad = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    const allPromise: any[] = [
      getTransferMethodDetail(businessID, lang, token),
      loadAreaCodesData(),
      getBankList(businessID, lang, token),
    ];
    const resp: any = await Promise.all(allPromise);
    if (resp.length > 0) {
      settransferMethod(resp[0] ?? {})
      setareaCodes(resp[1] ?? [])
      setbanks(resp[2] ?? [])
    }
  }

  const handleSave = async () => {
    const { trigger } = useFormReturn;

    if (!(await trigger())) {
      return;
    }
    const {
      currency,
      nickname,
      identifier_1_value,
      identifier_2_value,
      identifier_3_value,
      country,
      bank_settlement_files,
    } = relatedFormDate;

    setSaveDataLoading(true);
    setLoading(true);

    const settlementFiles: string[] = [];
    if (
      Array.isArray(bank_settlement_files) &&
      bank_settlement_files.length > 0
    ) {
      bank_settlement_files.forEach((item) => {
        settlementFiles.push(item.id);
      });
    }

    try {
      const result = await payeeCreate(businessID, lang, token, {
        is_default_bank: false,
        nickname: nickname,
        p_business_id: businessID,
        payout_account: {
          country: country || 'HK',
          currency: currency || 'HKD',
          identifier_1_value: identifier_1_value,
          identifier_2_value: identifier_2_value.trim(),
          identifier_3_value: identifier_3_value,
          last_three_bank_settlement_files: settlementFiles,
          transfer_method: 'HK FPS - Account Number',
        },
        related_company_payee: true,
        type: 'Business',
      });
      setLoading(false);
      if (result != null) {
        navigate(
          `/businesses/${businessID}/settings/related-company-accounts?pending=true`
        );
      } else {
        Alert.error({
          message: t('related_company_accounts:creation_failed'),
          position: 'default',
          wrapClass: 'top-px',
        });
      }
    } catch (error) {
      setSaveDataLoading(false);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    if (hasEdit) {
      setCancelModal(true);
    } else {
      navigate(
        `/businesses/${businessID}/settings/related-company-accounts`
      );
    }
  };

  const handleKeepEdit = () => {
    setCancelModal(false);
  };

  const handleDiscardChanges = () => {
    navigate(`/businesses/${businessID}/settings/related-company-accounts`);
  };

  const handleRelatedFormDate = (data: INewRelatedFormData) => {
    setRelatedFormDate({ ...data });
    setHasEdit(true);
  };

  return (
    <>
      <div className="px-4 md:px-6 lg:px-8 py-6">
        <div className="flex mb-6 justify-between">
          <div className="flex">
            <div className="bg-disabled-color shadow-box-drop rounded-full w-10 h-10 flex items-center justify-center cursor-pointer">
              <BackIcon
                className="hover:text-primary-color"
                onClick={handleCancel}
              />
            </div>
            <div className="text-3xl leading-10 font-bold text-color-text-1 ml-2.5">
              {t('related_company_accounts:add_new')}
            </div>
          </div>
          <div className="flex space-x-4">
            <Button onClick={handleCancel} styleType="Border">
              {t('common:cancel')}
            </Button>
            <Button onClick={handleSave} loading={saveDataloading}>
              {t('common:save')}
            </Button>
          </div>
        </div>
        <BankAccountDetails
          // {...props}
          areaCodes={areaCodes}
          transferMethod={transferMethod}
          isView={false}
          loading={false}
          relatedFormData={relatedFormDate}
          setRelatedFormDate={handleRelatedFormDate}
          isAdd={true}
          banks={banks}
          useFormReturn={useFormReturn} 
          related={undefined} 
          businessID={businessID}        />
        <BankStatements
          // {...props}
          areaCodes={areaCodes}
          transferMethod={transferMethod}
          isView={false}
          loading={false}
          relatedFormData={relatedFormDate}
          setRelatedFormDate={handleRelatedFormDate}
          isAdd={true}
          banks={banks}
          useFormReturn={useFormReturn} 
          related={undefined} 
          businessID={businessID}   
        />
      </div>
      <GlobalLoading loading={loading} />
      <ModalEmpty
        visible={cancelModal}
        onCancel={() => setCancelModal(false)}
        icon={<div></div>}
      >
        <div className="text-center">
          <div className="bg-warning-color/10 p-5 rounded-full  w-20 h-20 justify-center flex mb-4 m-auto">
            <WarningIcon className="text-warning-color" size={40} />
          </div>
          <div className="text-xl font-bold">
            {t('related_company_accounts:unsaved_changes')}
          </div>
          <div className="mt-2 text-sm font-medium">
            {t('related_company_accounts:unsaved_changes_tips')}
          </div>
          <Button onClick={handleKeepEdit} className="mt-6 w-full">
            {t('related_company_accounts:keep_editing')}
          </Button>
          <Button
            onClick={handleDiscardChanges}
            styleType="Border"
            className="mt-4 w-full"
          >
            {t('related_company_accounts:discard_changes')}
          </Button>
        </div>
      </ModalEmpty>
    </>
  );
};

export default RelatedFormNew;
