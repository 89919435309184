import Config from "@/libs/config";
import { IPermissionsData } from "@/types/permissions-type";

export const parsePermissions = (data: IPermissionsData) => {
  const { permissions = [], functionWhite } = data;

  if (Config.isProd) {
    const {
      add_fund = false,
      payout = false,
      virtual_account = false,
    } = functionWhite || {};

    const newPermissions = [];

    for (let i = 0; i < permissions.length; i++) {
      const permission = permissions[i];
      if (
        permission.startsWith('payouts:') ||
        permission.startsWith('payees:')
      ) {
        if (payout) {
          newPermissions.push(permission);
        } else if (permission === 'payouts:read') {
          newPermissions.push(permission);
        }
        continue;
      }
      if (permission.startsWith('ledger_accounts:')) {
        if (virtual_account) {
          newPermissions.push(permission);
        }
        continue;
      }

      newPermissions.push(permission);
    }

    if (add_fund) {
      newPermissions.push('add_fund:add_fund');
    }

    return newPermissions;
  }

  return permissions;
};