import type {  ILedgerAccount } from '@/types/account-balance-type';
import type { IObject } from '@/types/common-types';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import RefreshIcon from '@/pages/account-balance/account-balances-icons/refresh-icon';
import {
  getCurrentlyProcessing,
  getMerchantAccount,
} from '@/data/account-balance';
import { useTranslation } from 'react-i18next';
import AccountBalanceMerchant from './account-balance-page/account-balance-merchant/account-balance-merchant';
import { useNavigate, useParams } from 'react-router-dom';
import ActionType from '@/actions/action-type';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';


const AccountBalanceMerchantAccountPage = () => {
  const { t } = useTranslation("account_balance");
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const { p_business_id: businessID = '', lang = 'en' } = useParams();
  const [currentDate, setCurrentdate] = useState<dayjs.Dayjs>();
  const [processRecords, setProcessRecords] = useState<IObject[] | undefined>(
    undefined
  );
  const [refreshPage, setRefreshPage] = useState<boolean>(false);
  const [ledgerAccount] = useState<ILedgerAccount | IObject>(
    {}
  );
  const [initLoading, setInitLoading] = useState<boolean>(false);
  const [merchantAccountData, setMerchantAccountData] = useState<IObject>({});

  useEffect(() => {
    refreshDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  useEffect(() => {
    getInitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  useEffect(() => {
    getProcessRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantAccountData, refreshPage]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('common:company_account'),
          key: '1',
          type: 'label',
        },
        {
          name: t('account_balance:account_balance'),
          key: '2',
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  const { processingRecord } = merchantAccountData || {};
  const { total_count = 0 } = processingRecord || {};

  const getProcessRecords = async () => {
    if (processingRecord && processingRecord.id) {
      const data = await getCurrentlyProcessing({
        pBusinessId: businessID,
        limit: 31,
        offset: 0,
      });
      if (Array.isArray(data)) {
        setProcessRecords(data);
      }
    }
  };

  const getInitData = async () => {
    setInitLoading(true);
    if (businessID) {
      const data = (await getMerchantAccount(businessID)) || {};
      setMerchantAccountData(data);
    }
    setInitLoading(false);
  };

  const refreshDate = () => {
    const currentDate = dayjs();
    setCurrentdate(currentDate);
    // setMenuData(undefined)
  };

  const tabs = [
    t('account_balance:overview'),
    t('account_balance:merchant_account'),
    t('account_balance:current_account'),
  ];

  const handleItemClick = (index: number) => {
    if (index !== 1) {
      let routeStr = '';
      switch (index) {
        case 0:
          routeStr = `/${lang}/businesses/${businessID}/account-balance/overview`;
          break;
        case 2:
          routeStr = `/${lang}/businesses/${businessID}/account-balance/current-account`;
          break;

        default:
          break;
      }

      if (routeStr) {
        navigate(routeStr);
      }
    }
  };

return (
    <div className="p-4 md:p-6 lg:px-8 lg:py-6">
      <div className="flex mb-4 flex-col mb:mb-6">
        <div className="flex items-center space-x-2 justify-between">
          <div className="text-3xl leading-10 font-bold text-color-text-1 ml-2.5">
            {t('account_balance:account_balance')}
          </div>
          <div className="flex items-center space-x-2">
            <div className="text-sm text-primary-color">
              {t('account_balance:last_updated_at', {
                data: currentDate?.format('HH:mm'),
              })}
            </div>
            <RefreshIcon
              size={20}
              className={`text-primary-color shrink-0 ml-1 fill-current hover:text-primary-color/70 cursor-pointer`}
              onClick={() => setRefreshPage((refreshPage) => !refreshPage)}
            />
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <div
          className={`flex space-x-4 h-fit overflow-x-auto hidden_scrollbar`}
          aria-label="line-tabs"
        >
          {tabs.map((item, index) => (
            <div
              key={index}
              className={`
                  py-2 px-4 rounded-3xl text-sm whitespace-nowrap font-medium
                  ${index === 1
                  ? 'bg-primary-color text-color-text-6'
                  : 'bg-color-text-6 text-color-text-2 cursor-pointer hover:text-primary-color border-border-color border'
                }  
                `}
              onClick={() => handleItemClick(index)}
            >
              {item}
            </div>
          ))}
        </div>
        <AccountBalanceMerchant
          currentDate={currentDate}
          merchantAccountData={merchantAccountData}
          processInitData={processRecords}
          processTotal={total_count}
          refreshPage={refreshPage}
          initLoading={initLoading}
          activekey={'merchantAccount'} 
          businessID={businessID}
          ledgerAccount={ledgerAccount}
          lederEnteryData={Object}
          upComingData={Object} />
      </div>
    </div>
  );
};

export default AccountBalanceMerchantAccountPage;
