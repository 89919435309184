import AccountWrapper from '../../components/account-wrapper';
import Label from '@/components/common/label';
import { Alert } from '@/components/common/alert/alert';
import { forwardRef, useState } from 'react';
import type { UseControllerProps } from 'react-hook-form';
import { useController, useForm } from 'react-hook-form';
import Button from '@/components/common/button';
import { b2cResetPwd, encryptPassword } from '@/data/account';
import { useTranslation } from 'react-i18next';
import CleanIcon from '@/components/svg/clean-icon';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BackIcon from '@/components/svg/back-icon';
import EyeIcon from '../../accounts-icons/eye-icon';
import EyeOffIcon from '../../accounts-icons/eye-off-icon';

const ResetPassword = () => {
  const [passwordFlag, setPasswordFlag] = useState(false);
  const [passwordConfirmFlag, setPasswordConfirmFlag] = useState(false);
  const [level, setLevel] = useState(0);
  const [tooltip, setTooltip] = useState('');
  const [classes, setClasses] = useState('');
  const [textClass, setTextClass] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('signin');

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const values = getValues();

  const handleReset = async () => {
    setLoading(true);

    const password = encryptPassword(values.password);
    const data = await b2cResetPwd(
      {
        token: token as string,
        password,
      },
    );

    if (data.code === 200) {
      navigate('/account/password/reset/successful');
      Alert.success({
        message: t('signin:password_reset_successfully'),
        wrapClass: '-top-10',
      });
    } else if (data.message) {
      Alert.error({
        message: data.message,
        wrapClass: '-top-10',
      });
    }
    setLoading(false);
  };

  const handleValueChange = (value: string) => {
    let level = 3;
    let tooltip = '';
    let classes = '';
    let textClass = '';

    if (value.length < 6 || value.length > 20) {
      level--;
    }

    if (
      !RegExp('.*(?=.*[0-9].*).*').test(value) &&
      !RegExp('.*(?=.*[a-zA-Z0-9].*).*').test(value)
    ) {
      level--;
    }

    if (!RegExp('.*(?=.*[A-Z].*)(?=.*[a-z].*).*').test(value)) {
      level--;
    }

    if (level === 1) {
      classes = 'bg-error-color';
      textClass = 'text-error-color';
      tooltip = t('signin:very_weak');
    } else if (level === 2) {
      classes = 'bg-warning-color';
      textClass = 'text-warning-color';
      tooltip = t('signin:so_so');
    } else if (level === 3) {
      classes = 'bg-success-color';
      textClass = 'text-success-color';
      tooltip = `${t('signin:great')}!`;
    }

    setLevel(level);
    setTooltip(tooltip);
    setClasses(classes);
    setTextClass(textClass);
  };

  const handleBack = () => {
    navigate('/account/signin');
  };

  const disabled =
    !values.password ||
    !values.passwordConfirm ||
    Object.keys(errors).length > 0 ||
    level < 3;

  return (
    <AccountWrapper className="flex flex-col">
      <div className="flex flex-col w-120 bg-color-text-6 p-15 md:rounded-[30px] md:mr-[10%] md:shadow-sign-in-card">
        <h1 className="text-3xl text-color-text-1 text-center font-bold mb-6">
          {t('signin:reset_your_password')}! ✨
        </h1>
        <div className="text-sm text-center text-color-text-2 mb-10">
          {t('signin:reset_password_tips')}
        </div>
        <form onSubmit={handleSubmit(handleReset)}>
          <Label value={t('signin:new_password')}>
            <div className="flex items-center">
              <span className={`mr-2 ${textClass}`}>{tooltip}</span>
              {passwordFlag ? (
                <EyeIcon
                  className="cursor-pointer text-color-text-5 hover:text-icon-color"
                  onClick={() => setPasswordFlag(!passwordFlag)}
                />
              ) : (
                <EyeOffIcon
                  className="cursor-pointer text-color-text-5 hover:text-icon-color"
                  onClick={() => setPasswordFlag(!passwordFlag)}
                />
              )}
            </div>
          </Label>
          <InputView
            control={control}
            flag={passwordFlag}
            handleValueChange={handleValueChange}
            errors={errors}
            placeholder={t('signin:enter_new_password')}
            {...register('password', {
              required: t('signin:this_field_is_required'),
            })}
          />
          {values.password && (
            <div className="flex mt-1">
              <span
                className={`flex-1 h-0.5 ${
                  level > 0 ? `${classes}` : 'bg-disabled-color'
                }`}
              />
              <span
                className={`flex-1 h-0.5 ${
                  level > 1 ? `${classes}` : 'bg-disabled-color'
                }`}
              />
              <span
                className={`flex-1 h-0.5 ${
                  level > 2 ? `${classes}` : 'bg-disabled-color'
                }`}
              />
            </div>
          )}
          <Label value={t('signin:confirm_password')} className="mt-6">
            {passwordFlag ? (
              <EyeIcon
                className="cursor-pointer text-color-text-5 hover:text-icon-color"
                onClick={() => setPasswordConfirmFlag(!passwordConfirmFlag)}
              />
            ) : (
              <EyeOffIcon
                className="cursor-pointer text-color-text-5 hover:text-icon-color"
                onClick={() => setPasswordConfirmFlag(!passwordConfirmFlag)}
              />
            )}
          </Label>
          <InputView
            control={control}
            flag={passwordConfirmFlag}
            errors={errors}
            placeholder={t('signin:enter_new_password_again')}
            {...register('passwordConfirm', {
              required: t('signin:this_field_is_required'),
              validate: (value) =>
                value === getValues().password ||
                t('signin:please_repeat_your_new_password'),
            })}
          />
          <Button
            className="w-full h-12 rounded-lg mt-10"
            disabled={disabled}
            loading={loading}
            type="submit"
          >
            {t('signin:reset')}
          </Button>
        </form>
      </div>
      <div
        onClick={handleBack}
        className="flex justify-center items-center text-center text-sm text-color-text-3 hover:text-primary-color cursor-pointer mt-15 md:mr-[10%]"
      >
        <BackIcon />
        <span className="ml-2">{t('signin:back')}</span>
      </div>
    </AccountWrapper>
  );
};

interface IInputViewProps extends UseControllerProps {
  flag: boolean;
  placeholder?: string;
  errors?: any;
  className?: string;
  handleValueChange?: (value: string) => void;
}

const InputView: React.FC<IInputViewProps> = forwardRef((props, _ref) => {
  const { placeholder, errors, name, className, flag, handleValueChange } =
    props;
  const { field } = useController(props);
  const inputProps = {
    ...field,
    value: field.value || '',
    onChange: (e: any) => {
      field.onChange(e.target.value);
      handleValueChange && handleValueChange(e.target.value);
    },
  };
  return (
    <div className="flex-grow w-full">
      <div
        className={`group flex items-center px-3 py-2 text-color-text-1 shadow-box-drop text-base border border-border-color rounded-lg w-full bg-inherit hover:border-primary-color focus:outline-none focus:border-primary-color ${
          errors && errors[name]?.message ? 'border-error-color' : ''
        } ${className}`}
      >
        <input
          className="flex-1 outline-none bg-inherit font-medium placeholder:text-color-text-5 placeholder:font-normal placeholder:text-sm"
          placeholder={placeholder}
          type={`${flag ? 'text' : 'password'}`}
          {...inputProps}
        />
        {field.value && (
          <CleanIcon
            className="text-color-text-5 hover:text-color-text-3 cursor-pointer"
            onClick={() => {
              field.onChange('');
            }}
          />
        )}
      </div>
      {errors && errors[name]?.message && (
        <p className="mt-2 text-sm text-error-color">{errors[name]?.message}</p>
      )}
    </div>
  );
});

InputView.displayName = 'Input';

export default ResetPassword;
