import BackIcon from '@/components/svg/back-icon';
import { Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { IObject } from '@/types/common-types';
import Button from '@/components/common/button';
import ExportStatementModal from './export-statement-modale';
import { activeVirtualAccounts, getBusinessWallets, getPdfUrl, getVirtualAccounts } from '../../data/global-account';
import CopyIcon from '@/components/svg/copy-icon';
import { Alert } from '@/components/common/alert/alert';
import { queryApplication } from '@/utils/business_applications.utils';
import DetailsItem from './components/details-item';
import PendingIcon from './global-accounts-icons/pending-icon';
import AntdModal from '@/components/common/antd/modal';
import WarningIcon from '@/components/svg/warning-side-icon';
import ErrorIcon from '@/components/svg/failed-icon';
import GlobalLoading from '@/components/common/global-loading';
import NoActiveIcon from './global-accounts-icons/no-active-icon';
import { useParams, useNavigate } from 'react-router-dom';
import { Constants } from '@/constants';
import { getCookie } from '@/utils';
import { findNode } from '@/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IReducersState } from '@/reducers';
import CurrencyIcon from './global-accounts-icons/currency-icon';
import AddIcon from './global-accounts-icons/add-icon';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';
import TerminatedIcon from './global-accounts-icons/terminated-icon';
import Skeleton from '@/components/skeleton';


export enum TransactionType {
  payIn = 'payin',
  payOut = 'payout',
  card = 'card',
  exChange = 'exchange',
}

const GlobalAccountsDetails = (

) => {
  const { t } = useTranslation(["global_accounts", "account_balance"]);
  const { lang = 'en', p_business_id: businessID = '', ledger_account_id: recordId = '' } = useParams();
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const business = useSelector((state: IReducersState) => state.businessState.business);
  const [record, setRecord] = useState<any>({});
  const [virtualAccount, setVirtualAccount] = useState<any>({});
  const [firstRecord, setfirstRecord] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [records, setRecords] = useState<IObject[]>(virtualAccount);
  const [isPending, setIsPending] = useState<boolean>(
    firstRecord && firstRecord.status === 'Pending'
  );
  const [showActiveModal, setShowActiveModal] = useState<boolean>(false);
  const [type, setType] = useState<'warn' | 'error'>('warn');
  const [globalLoding, setGlobalLoding] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');

  const { currency_code } = record.currency || {};
  const { business_application_uuid = '', business_legal_name = '' } =
    business || {};

  const queryRecordInterval = useRef<any>(null);
  // const [terminated] = useState<boolean>(
  //   firstRecord && firstRecord.status === 'Terminated'
  // );

  useEffect(() => {
    initLoad();
    if (isPending) {
      if (!queryRecordInterval.current) {
        queryRecordInterval.current = setInterval(() => handleRepeatedly(), 10000);
      }
    } else {
      if (queryRecordInterval) {
        clearInterval(queryRecordInterval.current);
        queryRecordInterval.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('common:company_account'),
          key: '1',
          type: 'label',
        },
        {
          name: t('common:global_accounts'),
          key: '2',
          type: 'link',
          url: `/${lang}/businesses/${businessID}/global-accounts`,
        },
        {
          name: `${currency_code || ''} ${t('global_accounts:account')}`,
          key: '3',
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, currency_code])

  const initLoad = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    let virtualAccount: any = [];
    const allPromise: any[] = [
      getBusinessWallets(businessID, lang, token as string),
    ];
    const resp: any = await Promise.all(allPromise);
    if (resp.length > 0) {
      const data = resp[0];
      const { ledger_accounts = [] } = data;
      const newrecord: any = findNode(ledger_accounts, 'id', recordId);
      setRecord(newrecord)
      if (newrecord) {
        virtualAccount = await getVirtualAccounts({
          pBusinessId: businessID,
          currentToken: token as string,
        });
        setVirtualAccount(virtualAccount)
        setRecords(virtualAccount)
        setfirstRecord(virtualAccount[0])
      }
    }
    setInitLoading(false)
  }

  const handleRepeatedly = async () => {
    const data = (await getVirtualAccounts({ pBusinessId: businessID })) || [];
    const firstRecord = data[0] || {};

    if (firstRecord && firstRecord.status === 'Active') {
      setIsPending(false);
      setRecords(data);
    }
  };

  const handleBack = () => {
    navigate(`/${lang}/businesses/${businessID}/global-accounts`);
  };

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value).then(() => {
      Alert.success({
        message: t('common:copied'),
        position: 'default',
        wrapClass: 'top-px',
      });
    });
  };

  const handleDownloadFile = async (record: IObject) => {
    setLoading(true);

    let businessApplications: IObject = {};
    let success = false;
    if (business_application_uuid.length > 0) {
      const resp = await queryApplication(business_application_uuid);
      // if (resp && resp.code === 200) {
      if (resp) {
        businessApplications = resp.data;
      }
    }

    const data: any = await getPdfUrl({
      business: business,
      lang,
      virtualAccount: {
        ...record,
        address: businessApplications.business_address || '',
      },
    });
    const { url = '' } = data;
    // const url = 'https://account-confirmation.s3.ap-east-1.amazonaws.com/c3cc84fe-b1c3-11ec-a3d9-42010aaa001d/this_test.pdf'
    if (url) {
      success = true;
      try {
        await fetch(url).then((res) =>
          res.blob().then((blob) => {
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${currency_code} Account Confirmation - ${business_legal_name}.pdf`;
            a.click();
            window.URL.revokeObjectURL(url);
          })
        );
      } catch (error) {
        console.error(error);
        success = false;
      }
    }

    if (success) {
      Alert.success({
        message: t('global_accounts:exported_successfully'),
        wrapClass: '-top-10',
      });
    } else {
      Alert.error({
        message: t('global_accounts:exported_failed'),
        wrapClass: '-top-10',
      });
    }

    setLoading(false);
  };

  const handleItemClick = (index: number) => {
    if (index !== 1) {
      navigate(
        `/${lang}/businesses/${businessID}/global-accounts/${recordId}/current-activities`
      );
    }
  };

  const handleCancel = () => {
    setShowActiveModal(false);
    setErrorCode('');
    setErrorMsg('');
    setType('warn');
  };

  const handleActive = async () => {
    setShowActiveModal(false);
    setGlobalLoding(true);
    const resp: any = await activeVirtualAccounts(businessID);
    setGlobalLoding(false);

    if (resp && resp.code === 200) {
      setIsPending(true);
      Alert.success({
        message: t('global_accounts:virtual_account_created_successfully'),
        position: 'default',
        wrapClass: 'top-px',
      });
    } else {
      setType('error');
      setErrorCode(resp.code);
      setErrorMsg(resp.message);
      setShowActiveModal(true);
    }
  };

  const handleAccountNumber = (num: string) => {
    let returnValue = num;

    if (num.indexOf('-') > -1) {
      returnValue = num.split('-')[1];
    }

    return returnValue;
  };

  const tabs = [
    t('global_accounts:current_activities'),
    t('global_accounts:account_details'),
  ];

  const currentRecord: IObject = records[0] || {};
  const isActive = currentRecord && currentRecord.status === 'Active';


  const toFx = () => {
    navigate(`/${lang}/businesses/${businessID}/global-accounts/${recordId}/fx?currency_code=${currency_code}`);
  };

  const toAddFund = () => {
    navigate(`/${lang}/businesses/${businessID}/global-accounts/${recordId}/addfund?currency_code=${currency_code}`);
  };


  return (
    <
      // data={[
      //   {
      //     name: t('common:company_account'),
      //     key: '1',
      //     type: 'label',
      //   },
      //   {
      //     name: t('common:global_accounts'),
      //     key: '2',
      //     type: 'link',
      //     url: `/${lang}/businesses/${businessID}/global-accounts`,
      //   },
      //   {
      //     name: `${currency_code || ''} ${t('global_accounts:account')}`,
      //     key: '3',
      //     type: 'label',
      //   },
      // ]}
      >
      <div className="p-4 flex flex-col md:p-6 lg:px-8 lg:py-6 h-full">
        <div className="flex">
          <div className="flex flex-1 mb-6">
            <div className="bg-disabled-color shadow-box-drop rounded-full w-10 h-10 flex items-center justify-center cursor-pointer">
              <BackIcon
                className="hover:text-primary-color"
                onClick={handleBack}
              />
            </div>
            <div className="text-3xl leading-10 font-bold text-color-text-1 ml-2.5 invisible sm:visible">
              {currency_code || ''} {t('global_accounts:account')}
            </div>
          </div>
          {/* <Button onClick={() => setShow(true)} styleType="Secondary">
            {t('global_accounts:export_statement')}
          </Button> */}
          <Button
            onClick={toFx}
            iconInstance={<CurrencyIcon />}
            className="mr-2.5"
          >
            {t('global_accounts:currency_conversion')}
          </Button>
          <Button
            onClick={toAddFund}
            iconInstance={<AddIcon />}
            className="mr-2.5"
          >
            {t('common:add_fund')}
          </Button>
        </div>

        <div className="flex-1 flex flex-col">
          <div className="flex-1 flex flex-col">
            <div
              className={`flex space-x-4 h-fit overflow-x-auto hidden_scrollbar`}
              aria-label="line-tabs"
            >
              {tabs.map((item, index) => (
                <div
                  key={index}
                  className={`
                    py-2 px-4 rounded-3xl text-sm whitespace-nowrap font-medium
                    ${index === 1
                      ? 'bg-primary-color text-color-text-6'
                      : 'bg-color-text-6 text-color-text-2 cursor-pointer hover:text-primary-color border-border-color border'
                    }  
                  `}
                  onClick={() => handleItemClick(index)}
                >
                  {item}
                </div>
              ))}
            </div>
            {initLoading && (
              <div className="flex-1 flex justify-center items-center mt-6 bg-primary-background-color">
                <Skeleton
                  row={6}
                  width={['100%']}
                  className="mb-2 md:mb-0 md:ml-4"
                  skeletonItemClass="h-12 mt-8 rounded-2xl"
                />
              </div>
            )}
            {!initLoading && (
              <>
                {isActive &&
                  !isPending &&
                  records.map((item) => (
                    <Transition.Root
                      key={item.id}
                      show={true}
                      as={Fragment}
                      appear={true}
                    >
                      <div>
                        <Transition.Child
                          className="flex-1 p-6 mt-6 bg-primary-background-color rounded overflow-hidden shadow-card-drop"
                          enter="duration-350 transform transition ease-in-out"
                          enterFrom="-translate-x-1 translate-y-8 opacity-60"
                          enterTo="translate-x-0 translate-y-0 opacity-100"
                          leave="duration-500 transform transition"
                          leaveFrom="translate-x-0"
                          leaveTo="translate-x-full"
                        >
                          <div className="flex items-center justify-between">
                            <div className="font-bold text-lg text-color-text-1">
                              {t('global_accounts:bank_information')}
                            </div>
                            <Button
                              styleType="Secondary"
                              loading={loading}
                              onClick={() => handleDownloadFile(item)}
                            >
                              {t('global_accounts:export_account_details')}
                            </Button>
                          </div>
                          <div className="my-[26px]">
                            <DetailsItem
                              className="mb-6"
                              label={t('global_accounts:bank_name')}
                              value={item.bank_name}
                            />
                            <DetailsItem
                              label={t('global_accounts:bank_address')}
                              value={item.branch_address}
                            />
                          </div>
                          <div className="font-bold text-lg text-color-text-1">
                            {t('global_accounts:account_overview')}
                          </div>
                          <div className="mt-[26px]">
                            <DetailsItem
                              className="my-6"
                              label={t('global_accounts:beneficiary_name')}
                              value={
                                <div className="flex items-center">
                                  <span>{item.account_name}</span>
                                  <CopyIcon
                                    className="text-primary-color ml-3 cursor-pointer"
                                    onClick={() => handleCopy(item.account_name)}
                                  />
                                </div>
                              }
                            />
                            <DetailsItem
                              className="mb-6"
                              label={t('global_accounts:bank_code')}
                              value={
                                <div className="flex items-center">
                                  <span>{item.bank_code}</span>
                                  <CopyIcon
                                    className="text-primary-color ml-3 cursor-pointer"
                                    onClick={() => handleCopy(item.bank_code)}
                                  />
                                </div>
                              }
                            />
                            <DetailsItem
                              className="mb-6"
                              label={t('global_accounts:branch_code')}
                              value={
                                <div className="flex items-center">
                                  {/* <span>{item.branch_code}</span> */}
                                  <div className="flex items-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M1.75 8C1.75 4.54781 4.54781 1.75 8 1.75C11.4522 1.75 14.25 4.54781 14.25 8C14.25 11.4522 11.4522 14.25 8 14.25C4.54781 14.25 1.75 11.4522 1.75 8Z"
                                        stroke="#F43F5E"
                                        strokeWidth="1.5"
                                      />
                                      <path
                                        d="M9.61875 10.6469H8.45391V5.86035C8.45391 5.58828 8.23379 5.36816 7.96172 5.36816H7.46953H6.64922C6.37715 5.36816 6.15703 5.58828 6.15703 5.86035C6.15703 6.13242 6.37715 6.35254 6.64922 6.35254H7.46953V10.6469H6.30469C6.03262 10.6469 5.8125 10.867 5.8125 11.1391C5.8125 11.4111 6.03262 11.6312 6.30469 11.6312H7.46953H8.45391H9.61875C9.89082 11.6312 10.1109 11.4111 10.1109 11.1391C10.1109 10.867 9.89082 10.6469 9.61875 10.6469Z"
                                        fill="#F43F5E"
                                      />
                                      <path
                                        d="M7.46875 4.01465C7.46875 4.28648 7.68911 4.50684 7.96094 4.50684C8.23277 4.50684 8.45312 4.28648 8.45312 4.01465C8.45312 3.74282 8.23277 3.52246 7.96094 3.52246C7.68911 3.52246 7.46875 3.74282 7.46875 4.01465H7.46875Z"
                                        fill="#F43F5E"
                                      />
                                    </svg>
                                    <span className="text-sm text-error-color ml-1">
                                      {t('global_accounts:branch_code_tips')}
                                    </span>
                                  </div>
                                </div>
                              }
                            />
                            <DetailsItem
                              className="mb-6"
                              label={t('global_accounts:account_number')}
                              value={
                                <div className="flex items-center">
                                  <span>
                                    {handleAccountNumber(
                                      item.display_account_number
                                    )}
                                  </span>
                                  <CopyIcon
                                    className="text-primary-color ml-3 cursor-pointer"
                                    onClick={() =>
                                      handleCopy(handleAccountNumber(
                                        item.display_account_number
                                      ))
                                    }
                                  />
                                </div>
                              }
                            />
                            <DetailsItem
                              className="mb-6"
                              label={t('global_accounts:swift_code')}
                              value={
                                <div className="flex items-center">
                                  <span>{item.swift_code}</span>
                                  <CopyIcon
                                    className="text-primary-color ml-3 cursor-pointer"
                                    onClick={() => handleCopy(item.swift_code)}
                                  />
                                </div>
                              }
                            />
                          </div>
                        </Transition.Child>
                      </div>
                    </Transition.Root>
                  ))}
                {(!records || records.length === 0) && !isPending && (
                  <div className="flex-1 flex justify-center items-center">
                    <div className="flex flex-col justify-center items-center">
                      <NoActiveIcon />
                      <div className="font-bold text-xl text-color-text-1 mb-2 mt-10">
                        {t('global_accounts:no_virtual_account_tips')}
                      </div>
                      <div className="text-color-text-2 mb-6">
                        {t('global_accounts:create_virtual_account')}
                      </div>
                      <div>
                        <Button onClick={() => setShowActiveModal(true)}>
                          {t('global_accounts:activate_virtual_account')}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {isPending && (
                  <div className="flex-1 flex justify-center items-center mt-6 bg-primary-background-color">
                    <div className="flex flex-col justify-center items-center text-center">
                      <PendingIcon />
                      <div className="text-xl text-color-text-1 mt-10">
                        {t('global_accounts:pending_title')}
                      </div>
                      <div className="text-sm text-color-text-2">
                        {t('global_accounts:pending_subtitle')}
                      </div>
                    </div>
                  </div>
                )}
                {firstRecord && firstRecord.status === 'Terminated' && (
                  <div className="flex-1 flex justify-center items-center mt-6 bg-primary-background-color">
                    <div className="flex flex-col justify-center items-center text-center">
                      <TerminatedIcon />
                      <div className="text-xl text-color-text-1 mt-10 font-bold">
                        {t('global_accounts:terminated_title')}
                      </div>
                      <div className="text-sm text-color-text-2 mt-2">
                        {t('global_accounts:contact')}
                        <a href="mailto:support@wonder.app" className="text-[#0094FF]" target="_blank" rel="noopener noreferrer"> support@wonder.app </a>
                        {t('global_accounts:for_inquries')}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

          </div>
        </div>
      </div>
      <GlobalLoading loading={globalLoding} />
      <ExportStatementModal
        show={show}
        onCancel={() => setShow(false)}
        pBusinessId={businessID}
        currencyCode={currency_code}
      />
      <AntdModal
        closable={false}
        open={showActiveModal}
        cancelText={type === 'error' ? t('common:back') : t('common:cancel')}
      >
        <div className="p-10 pt-8 flex text-center flex-col items-center text-color-text-1">
          {type === 'warn' ? <WarningIcon /> : <ErrorIcon />}

          {type === 'warn' && (
            <>
              <div className="mt-4 font-bold text-xl">
                {t('global_accounts:activate_virtual_account')}
              </div>
              <div className="text-sm font-medium mt-2">
                {t('global_accounts:activate_virtual_account_tips')}
              </div>
            </>
          )}
          {type === 'error' && (
            <>
              <div className="mt-4 text-error-color font-semibold text-lg">
                {t('global_accounts:unable_to_create')}
              </div>
              {errorCode && (
                <div className="text-sm text-error-color my-1">
                  {t('global_accounts:error_code')}: {errorCode}
                </div>
              )}
              {errorMsg && (
                <div className="text-sm font-medium text-color-text-1">
                  {errorMsg}
                </div>
              )}
              {!errorMsg && (
                <div className="text-sm font-medium text-color-text-1">
                  {t('global_accounts:error_tips')}
                </div>
              )}

            </>
          )}

          <div className="grid gap-y-4 grid-cols-1 w-full mt-6">
            {type === 'warn' && (
              <>
                <Button onClick={handleActive}>{t('common:confirm')}</Button>
                <Button styleType="Border" onClick={handleCancel}>
                  {t('common:cancel')}
                </Button>
              </>
            )}
            {type === 'error' && (
              <Button onClick={handleCancel}>{t('common:back')}</Button>
            )}
          </div>
        </div>
      </AntdModal>
    </>
  );
};

export default GlobalAccountsDetails;
