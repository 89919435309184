import { useTranslation } from 'react-i18next';
import AccountWrapper from './components/account-wrapper';
import QrCode from '@/components/common/qr-code';
import Config from '@/libs/config';
import VerifyWhoIcon from './accounts-icons/verify-who-icon';
import ThatIsItIcon from './accounts-icons/that-is-it-icon';
import { Link } from 'react-router-dom';
import BackIcon from '@/components/svg/back-icon';

const GuidePage = () => {
  const { t } = useTranslation('signin');

  return (
    <AccountWrapper className="flex flex-col" >
      <div className="flex flex-col items-center justify-center box-border text-center w-full max-w-[552px] bg-color-text-6 py-15 px-6 md:px-15 md:rounded-[30px] md:mr-[10%] md:shadow-sign-in-card">
        <h1 className="text-3xl font-bold mb-6">
          {t('signin:activate_account')}
        </h1>
        <div className="text-sm text-color-text-2">
          {t('signin:activate_existing_account')}
        </div>
        <div className="flex mt-6">
          <div className="p-5 border rounded-3xl border-border-color">
            <QrCode
              size={220}
              value={`${Config.urls.baseDomain}/download-app`}
            />
          </div>
        </div>
        <div className="w-full flex mt-6 text-sm">
          <div className="w-1/2 flex justify-center">
            <span className="flex flex-col">
              <div className="flex justify-center">
                <VerifyWhoIcon />
              </div>
              <div className="text-color-text-2 mt-4">
                1. {t('signin:verify_who_you_are')}
              </div>
            </span>
          </div>
          <div className="flex-1 flex justify-center">
            <span className="flex flex-col">
              <div className="flex justify-center">
                <ThatIsItIcon />
              </div>
              <div className="text-color-text-2 mt-4">
                2. {t('signin:that_is_it')}
              </div>
            </span>
          </div>
        </div>
      </div>
      <Link to="/account/signin">
        <div className="flex justify-center items-center text-center text-sm text-color-text-3 hover:text-primary-color cursor-pointer mt-15 md:mr-[10%]">
          <BackIcon />
          <span className="ml-2">{t('signin:back')}</span>
        </div>
      </Link>
    </AccountWrapper>
  );
};

export default GuidePage;
