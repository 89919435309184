import { useState } from 'react';
import { Transition } from '@headlessui/react';
import CloseIcon from '@/components/svg/close-icon';
import AddfundChooseMethod from './addfund-choose-method';
import OtherMethod from './addfund-choose-method/other-method';
import AddfundTransferDetails from './addfund-transfer-details';
import AddfundChooseAccount from './addfund-choose-account';
import AddTransferConfirmation from './addfund-transfer-confirmation';
import AddfundAddAccount from './addfund-add-account';
import AddfundResults from './add-fund-results';
import type { IPayee } from '@/types/payouts-type';
import type { IPayoutsNewStep } from '@/types/global-accounts-type';
import GlobalLoading from '@/components/common/global-loading';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// interface IAddfundNewProps {
//   activekey: string;
//   businessID: string;
//   setLoading: (loading: boolean) => void;
//   user: any;
//   recordId?: string;
// }
const AddfundNew = () => {
  const { p_business_id: businessID = '', ledger_account_id: recordId = '' } = useParams();
  const { t } = useTranslation(["global_accounts", "account_balance"]);
  // const { lang = 'en' } = useParams();
  const initSteps: IPayoutsNewStep[] = [
    {
      key: '1',
      title: t('global_accounts:choose_method'),
      name: '1',
    },
    {
      key: '2',
      title: t('common:add_fund'),
      name: '2',
    },
  ];
  const [stepCount, setStepCount] = useState<number>(0);
  const [steps, setSteps] = useState<IPayoutsNewStep[]>(initSteps);
  const [amount, setAmount] = useState('');
  const [free, setFree] = useState('');
  const [golbalMethods, setgolbalMethods] = useState('');
  const [detail, setDetail] = useState({});
  const [globalFrom, setGlobalFrom] = useState<any>({});
  const [globalPayees, setglobalPayees] = useState<IPayee[]>([]);
  const [createLoading, setCreateLoading] = useState(false);
  // const { businessID, user, activekey, recordId } = props;
  const navigate = useNavigate();

  // const router = useRouter();

  const handleBack = () => {
    if (recordId === 'noAccountId') {
      navigate(`/businesses/${businessID}/global-accounts`);
    } else {
      navigate(
        `/businesses/${businessID}/global-accounts/${recordId}/current-activities`
      );
    }
  };

  return (
    <div className="h-auto min-h-screen bg-system-background-color text-sm font-medium">
      <Transition.Root show={true} appear={true} className="h-full">
        <Transition.Child
          className="flex items-center justify-between p-4 md:px-6 md:py-8 lg:px-12"
          enter="duration-350 transform transition ease-in-out"
          enterFrom="-translate-x-1 translate-y-10 opacity-40"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="duration-500 transform transition"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="font-bold text-xl leading-[30px] md:text-3xl md:leading-10 text-color-text-1">
            {steps[stepCount].key !== '5' ? (
              <span>{t('common:add_fund')}</span>
            ) : null}
          </div>
          <CloseIcon
            size={20}
            onClick={handleBack}
            className="text-color-text-1 cursor-pointer hover:text-primary-color p-3 bg-white rounded-full"
          />
        </Transition.Child>
        <div className="flex-1 flex flex-col pb-6 md:flex-row  lg:justify-center w-4/5 lg:w-3/5 mx-auto">
          {steps[stepCount].key !== '5' && (
            <Transition.Child
              className="flex flex-[3] items-center flex-col mb-4 md:block"
              enter="duration-450 transform transition ease-in-out"
              enterFrom="-translate-x-1 translate-y-10 opacity-40"
              enterTo="translate-x-0 translate-y-0 opacity-100"
              leave="duration-500 transform transition"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <ul className="flex justify-between w-full md:w-auto px-6 md:block md:px-0">
                {steps.map((step, index) => (
                  <div
                    className={`flex md:block ${index === steps.length - 1 ? '' : 'flex-1'
                      }`}
                    key={step.key}
                  >
                    <li
                      className={`flex items-center text-color-text-4 hover:text-primary-color`}
                    >
                      <span
                        className={`rounded-full w-6 h-6 flex-none flex items-center justify-center ${stepCount >= index
                          ? 'bg-primary-color text-color-text-6'
                          : 'bg-disabled-color text-color-text-2 border border-border-color'
                          }`}
                      >
                        {step.name ? step.name : step.key}
                      </span>
                      <span
                        className={`ml-3 font-medium whitespace-nowrap hidden md:block ${stepCount < index
                          ? 'text-color-text-2'
                          : 'text-color-text-1'
                          }`}
                      >
                        {step.title}
                      </span>
                    </li>
                    {index !== steps.length - 1 && (
                      <div
                        className={`mt-3 flex-1 h-0.5 md:w-0.5 md:h-[46px] md:ml-3 md:mt-0 ${stepCount >= index
                          ? 'bg-primary-color'
                          : 'bg-border-color opacity-60'
                          }`}
                        aria-hidden="true"
                      ></div>
                    )}
                  </div>
                ))}
              </ul>
            </Transition.Child>
          )}

          <Transition.Child
            className={`${steps[stepCount].key !== '5' ? 'flex-[7]' : 'mt-[-20px]'
              }`}
            enter="duration-500 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-10 opacity-40"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div
              className={`w-[614px] ${steps[stepCount].key !== '5'
                ? 'pl-0 md:pl-6 '
                : ' ml-[auto] mr-[auto]'
                }`}
            >
              {steps[stepCount].key === '1' && (
                <AddfundChooseMethod
                  businessID={businessID}
                  setStepCount={setStepCount}
                  setSteps={setSteps}
                  setFree={setFree}
                  setgolbalMethods={setgolbalMethods}
                />
              )}
              {steps[stepCount].key === '1A' && (
                <OtherMethod
                  businessID={businessID}
                  golbalMethods={golbalMethods}
                  setStepCount={setStepCount}
                  setSteps={setSteps}
                />
              )}
              {steps[stepCount].key === '2' && (
                <AddfundTransferDetails
                  setSteps={setSteps}
                  setStepCount={setStepCount}
                  setAmount={setAmount}
                />
              )}
              {steps[stepCount].key === '2A' && (
                <AddfundAddAccount
                  businessID={businessID}
                  setStepCount={setStepCount}
                  setSteps={setSteps}
                  handleBack={handleBack}
                  setGlobalFrom={setGlobalFrom}
                />
              )}
              {steps[stepCount].key === '3' && (
                <AddfundChooseAccount
                  businessID={businessID}
                  setStepCount={setStepCount}
                  setSteps={setSteps}
                  amount={amount}
                  setglobalPayees={setglobalPayees}
                />
              )}
              {steps[stepCount].key === '4' && (
                <AddTransferConfirmation
                  businessID={businessID}
                  globalPayees={globalPayees}
                  globalFrom={globalFrom}
                  amount={amount}
                  free={free}
                  setCreateLoading={setCreateLoading}
                  setStepCount={setStepCount}
                  setSteps={setSteps}
                  handleBack={handleBack}
                  setDetail={setDetail}
                  setGlobalFrom={setGlobalFrom}
                />
              )}
              {steps[stepCount].key === '5' && (
                <AddfundResults
                  businessID={businessID}
                  amount={amount}
                  free={free}
                  detail={detail}
                />
              )}
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
      <GlobalLoading loading={createLoading} />
    </div>
  );
};

export default AddfundNew;
