import api from "@/libs/api";
import Config from "@/libs/config";
import { IObject } from "@/types/common-types";
import { ISignatureType } from "@/types/signature-type";

interface IGetSignatureUrlParam {
  callBackUrl: string;
  token: string;
  businessApplicationId?: string;
  type?: ISignatureType;
}

export const getSignatureUrl = async (param: IGetSignatureUrlParam): Promise<IObject> => {
  const { callBackUrl, token, businessApplicationId = '', type = ISignatureType.MERCHANT_SERCICE_AGREEMENT } = param
  let sign_page_url = '';
  let contract_id = '';
  let business_id = '';

  const body: IObject = {
    redirect_url: callBackUrl,
    template_type: type
  }

  if (businessApplicationId) {
    body.business_application_id = businessApplicationId;
  }

  try {
    const hellosignInfo: IObject =  await api.post(`${Config.urls.gatewayUrl}/api/registry/business-applications/esignature`,
    {
      ...body
    },
    {
      headers: {
        'X-USER-ACCESS-TOKEN': token,
      },
      httpStatusCallback() {
        console.info('callback')
      },
    })

    if (hellosignInfo && hellosignInfo.code === 200) {
      const { seignature_resp = {}, p_business_id = '' } = hellosignInfo.data;
      sign_page_url = seignature_resp.data.contract.signers[0].sign_page_url || '';
      contract_id = seignature_resp.data.contract.signers[0].id;
      business_id = p_business_id;
    }

  } catch (error) {
    console.error(error);
  }

  return {
    sign_page_url,
    contract_id,
    business_id,
  }
  
};

export const referralPartners = async (url: string, token: string) => {
  let business_id = '';
  try {
    const result: IObject =  await api.post(`${Config.urls.gatewayUrl}/api/registry/referral-partners`,
    {
      signature_file_url: url
    },
    {
      headers: {
        'X-USER-ACCESS-TOKEN': token,
      },
      httpStatusCallback() {
        console.info('callback')
      },
    })

    if (result.code === 200 && result.data && result.data.p_business_id) {
      business_id = result.data.p_business_id;
    }

  } catch (error) {
    console.error(error);
  }

  return business_id;
}

export const sendCompletedEmail = async (
  name: string,
  email: string,
  token: string,
  lang = 'en'
) => {
  const body = JSON.stringify({
    message_channel: 'onboarding_completed',
    recipients: [email],
    meta_data: {
      preferred_name: name,
      liquid_config: {
        locale: lang,
      },
    },
  });

  try {
    await api.post(`${Config.urls.gatewayUrl}/api/beams/send-email`, body, {
      headers: {
        'Content-Type': 'application/json',
        'x-user-access-token': token,
      },
    });
  } catch (error) {
    console.error(error);
  }
};