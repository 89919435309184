import { Modal } from 'antd';
import type { IAntdModalProps } from './interface';
import Button from '../../button';
import WarningIcon from '@/components/svg/warning-side-icon';
import { useTranslation } from 'react-i18next';
import SuccessIcon from '@/components/svg/successfully-icon';
import ErrorIcon from '@/components/svg/failed-icon';
import { useState } from 'react';
import './modal.css'

const AntdModal: React.FC<IAntdModalProps> = (props) => {
  const { t } = useTranslation();
  const {
    size = 'medium',
    children,
    className,
    type,
    cancelText = t('cancel'),
    okText = t('confirm'),
    onCancel,
    onOk,
    open,
    content,
    closeIcon,
    closable,
    confirmLoading,
    maskClosable,
    destroyOnClose,
  } = props;

  const baseProps = {
    open,
    closeIcon,
    closable,
    confirmLoading,
    maskClosable,
    destroyOnClose,
    footer: null,
    width: 1920,
    onCancel,
  };

  const [loading, setLoading] = useState(false);

  const handleOk = async () => {
    setLoading(true);
    if (onOk) {
      await onOk();
    }
    setLoading(false);
  };
  if (type) {
    return (
      <Modal
        centered
        {...baseProps}
        className={`${className} max-w-[30%] max-h-[88%]`}
        styles={{
          body: {height: '100%'}
        }}
      >
        <div className="p-10 pt-8 flex text-center flex-col items-center text-color-text-1">
          {type === 'warn' && <WarningIcon />}
          {type === 'success' && <SuccessIcon />}
          {type === 'error' && <ErrorIcon />}
          {content || children}
          {
            <div className="grid gap-y-4 grid-cols-1 w-full mt-6">
              {okText && onOk && (
                <Button
                  styleType={type === 'error' ? 'Danger' : 'Primary'}
                  onClick={handleOk}
                  loading={loading}
                >
                  {okText}
                </Button>
              )}
              {cancelText && onCancel && (
                <Button styleType="Border" onClick={onCancel}>
                  {cancelText}
                </Button>
              )}
            </div>
          }
        </div>
      </Modal>
    );
  }

  let baseClassName = 'max-w-[30%] max-h-[88%]';
  if (size === 'large') {
    baseClassName = 'max-w-[80%] h-[88%]';
  }
  if (size === 'medium') {
    baseClassName = 'max-w-[35%] max-h-[88%]';
  }
  return (
    <Modal
      centered
      {...baseProps}
      onCancel={onCancel}
      className={`${className} ${baseClassName}`}
      styles={{
        body: {height: '100%'}
      }}
    >
      {children}
    </Modal>
  );
};

export default AntdModal;
