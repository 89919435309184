import Button from '@/components/common/button';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import PayoutResultIcon from '@/pages/payouts/payouts-icons/payout-result-icon';
import { useTranslation } from 'react-i18next';
import UnsuccessfulIcon from '@/components/svg/failed-icon';
import { handlePrice } from '../utils';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
dayjs.extend(utc);

interface IAddfundResults {
  businessID: string;
  recordId?: string;
  reslutData?: any;
  amount: string;
  free: string;
  detail: any;
}

const AddfundResults = (props: IAddfundResults) => {
  const { amount, detail } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryCurrencyCode = searchParams.get('currency_code');
  const { p_business_id: businessID = '', ledger_account_id: recordId = '' } = useParams();

  const { t } = useTranslation(["global_accounts",
    "payouts",
    "account_balance",
    "related_company_accounts",
    "payees"]);

  const handleCancel = () => {
    if (recordId === 'noAccountId') {
      navigate(`/businesses/${businessID}/global-accounts`);
    } else {
      navigate(
        `/businesses/${businessID}/global-accounts/${recordId}/current-activities`
      );
    }
  };

  const installStauts = (status: any) => {
    let color = '';
    if (status === 'Settled' || status === 'Completed') {
      //Settled
      color = 'text-success-color bg-success-color';
    } else if (status === 'Initiated' || status === 'Pending for Transfer') {
      //Initiated
      color = 'text-warning-color bg-warning-color';
    } else if (status === 'Declined' || status === 'Suspended') {
      //Declined
      color = 'text-error-color bg-error-color';
    } else if (status === 'Authorized') {
      //Authorized
      color = 'text-warning-color bg-warning-color';
    } else if (status === 'Terminated' || status === 'Rejected') {
      //Terminated
      color = 'text-icon-color bg-icon-color';
    } else if (status === 'Reversed') {
      //Terminated
      color = 'text-icon-color bg-icon-color';
    } else if (status === 'Processing Transfer') {
      color = 'text-primary-color bg-primary-color';
    }
    return color;
  };

  const computationalCost = (amount: string, free: string) => {
    const newAmount = amount.split(',').join('');
    const res = Number(newAmount) - Number(free);
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const formattedNum = formatter.format(res);
    return formattedNum;
  };
  return (
    <div className="bg-system-background-color flex justify-center overflow-auto pb-8 leading-snug ">
      {detail.data && (
        <div className="my-auto">
          <div className="flex flex-col items-center p-6 max-w-xl justify-center m-auto bg-white rounde shadow-card-drop  w-[614px]">
            <PayoutResultIcon className="text-center" />
            <div className="mt-[24px] text-xl">{t('payouts:result_tip')}</div>
            <div className="w-[464px] text-center mt-2 font-normal text-[#71829A]">
              {t('payouts:result_tip_two')}
            </div>

            <div className="border border-border-color rounded-[4px] px-4 w-full py-1 mt-6 text-sm">
              <div className="mt-[8px] text-center">
                <div className="mr-2 text-color-text-1  text-3xl">
                  <span className="font-bold"> +{amount} </span>
                  <span>{queryCurrencyCode}</span>
                </div>
                <div className="mr-2 text-[#71829A] pb-4">
                  {t('global_accounts:from')}{' '}
                  {detail.data.payout_account_bank_account_name}
                </div>
              </div>

              <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">{t('common:status')}</div>

                <div
                  className={`py-1 px-3 ${installStauts(
                    detail.data.transfer_status
                  )} bg-opacity-10 text-xs text-center leading-[18px] rounded-3xl`}
                >
                  {detail.data.transfer_status}
                </div>
              </div>

              <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">
                  {t('payouts:transaction_amount')}
                </div>
                <div className="text-color-text-1">
                  {computationalCost(detail.data.deducted_amount, '0')}{' '}
                  {queryCurrencyCode}
                </div>
              </div>
              <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">
                  {t('payouts:fees_charged')}
                </div>
                <div className="text-color-text-1">
                  -{handlePrice(Number(detail.data.calculated_fee))}{' '}
                  {queryCurrencyCode}
                </div>
              </div>

              <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">
                  {t('account_balance:payin_amount')}
                </div>
                <div className="text-color-text-1">
                  {computationalCost(
                    detail.data.deducted_amount,
                    detail.data.calculated_fee
                  )}{' '}
                  {queryCurrencyCode}
                </div>
              </div>
              <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">
                  {t('account_balance:transaction_date')}
                </div>
                <div className="text-color-text-1">
                  {dayjs(detail.data.p_created_at).format(
                    'MMMM DD, YYYY HH:mm:ss'
                  )}
                </div>
              </div>

              <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">
                  {t('account_balance:transaction_id')}
                </div>
                <div className="text-color-text-1">{detail.data.id}</div>
              </div>
              <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">
                  {t('payouts:sending_bank')}
                </div>
                <div className="text-color-text-1">
                  {detail.data.bank_code} {detail.data.bank_name}
                </div>
              </div>

              <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">
                  {t('payouts:sending_name')}
                </div>
                <div className="text-color-text-1">
                  {detail.data.payout_account_bank_account_name}
                </div>
              </div>
              <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">
                  {t('payouts:sending_number')}
                </div>
                <div className="text-color-text-1">
                  {detail.data.payout_account_bank_account_number}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center w-full mt-6">
            <Button
              className="flex-1"
              onClick={handleCancel}
              styleType="Primary"
            >
              {t('common:done')}
            </Button>
          </div>
        </div>
      )}
      {!detail.data && (
        <div className="my-auto">
          <div className="flex flex-col items-center p-6 max-w-xl justify-center m-auto bg-white rounde shadow-card-drop  w-[614px]">
            <UnsuccessfulIcon className="text-center" />
            <div className="mt-[24px] text-xl font-bold ">
              {t('payouts:payment_failed')}
            </div>
            <div className="mt-[2px] text-sm text-error-color font-normal">
              {t('global_accounts:reason_code')}: {detail.code}
            </div>
            <div className="mt-[2px] text-sm text-error-color font-normal">
              {detail.message}
            </div>
            <div className="mt-[8px] text-[#4C5259] font-normal  flex justify-center text-sm ">
              {t('global_accounts:try_again')}
            </div>

            <div className="border border-border-color rounded-[4px] px-4 w-full py-1 mt-6 text-sm">
              <div className="flex justify-between items-center font-medium py-3  border-border-color">
                <div className="text-color-text-2">
                  {t('payouts:transaction_amount')}
                </div>
                <div className="text-color-text-1">
                  {amount} {queryCurrencyCode}
                </div>
              </div>

              <div className="flex justify-between items-center font-medium py-3 border-t border-border-color">
                <div className="text-color-text-2">
                  {t('payouts:add_fund_method')}
                </div>
                <div className="text-color-text-1">FPS / eDDA</div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center w-full mt-6">
            <Button
              className="flex-1"
              onClick={handleCancel}
              styleType="Primary"
            >
              {t('common:back')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddfundResults;
