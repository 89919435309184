import AntdInput from "@/components/common/antd/input/input";
import Label from "@/components/common/label";
import PhoneNumber from "@/components/common/phone-number";
import Select from "@/components/common/select/select";
import { IAreaCodes } from "@/types/common-types";
import { IPayee, IPayeeData, IPayeeDataKey, IPayeeError } from "@/types/payouts-type";
import { getAvatarsName } from "@/utils";
import { useTranslation } from "react-i18next";

interface IPayeeInformationProps {
  isView: boolean;
  payee: IPayee;
  payeeData: IPayeeData;
  payeeError: IPayeeError;
  handleChange: (value: string, name: IPayeeDataKey) => void;
  areaCodes: IAreaCodes[];
}

const PayeeInformation: React.FC<IPayeeInformationProps> = (props) => {
  const { payee, areaCodes, isView, payeeData, payeeError, handleChange } =
    props;
  const { t } = useTranslation('payees');
  const { t: ct } = useTranslation('common');

  const { nickname, payout_accounts, phone_number } = payee;

  const { transfer_method } = payout_accounts;

  return (
    <div className="p-6 bg-primary-background-color rounded shadow-card-drop mb-6 mx-3">
      <div className="font-bold text-xl text-color-text-1 leading-[26px]">
        {t('payee_information')}
      </div>
      <div className="border-b border-border-color mb-6 mt-4"></div>
      <div className="grid grid-cols-1 gap-6">
        <div className="grid grid-cols-4 gap-6">
          <div className="flex items-center">
            <div className={`w-16 h-16 flex items-center justify-center ${nickname ? 'bg-primary-color/10' : ''} text-xl font-bold rounded-full`}>
              {getAvatarsName(nickname)}
            </div>
            <div className="ml-2 text-lg font-semibold">{nickname}</div>
          </div>
          {!(transfer_method === 'Platform Transfer' && !isView) && (
            <div>
              <Label
                value={t('type')}
                required={true}
                disabled={isView}
              />
              <Select
                disabled={isView}
                options={[
                  {
                    label: t('business'),
                    value: 'Business',
                    color: 'bg-[#FFA217] text-[#FFA217]',
                  },
                  {
                    label: t('people'),
                    value: 'People',
                    color: 'bg-primary-color text-primary-color',
                  },
                ]}
                renderItem={(item) => {
                  const { label, color } = item;
                  return (
                    <span
                      className={`px-3 py-1.5 rounded-3xl text-xs ${color} bg-opacity-10 uppercase`}
                    >
                      {label}
                    </span>
                  );
                }}
                value={payeeData.type}
                onChange={(value) => handleChange(value, 'type')}
                error={payeeError.type}
              />
            </div>
          )}
          {payeeData.type === 'Business' &&
            transfer_method !== 'Platform Transfer' && (
              <div>
                <Label
                  value={t('company')}
                  required={true}
                  disabled={isView}
                />
                <AntdInput
                  onChange={(value) => handleChange(value, 'company_name')}
                  value={payeeData.company_name}
                  error={payeeError.company_name}
                  disabled={isView}
                  placeholder={t('enter_company')}
                />
              </div>
            )}
        </div>
        <div className="grid grid-cols-4 gap-6">
          <div>
            <Label
              value={t('nickname')}
              required={true}
              disabled={isView}
            />
            <AntdInput
              onChange={(value) => handleChange(value, 'nickname')}
              value={payeeData.nickname}
              error={payeeError.nickname}
              disabled={isView}
              placeholder={t('enter_nickname')}
            />
          </div>
          {transfer_method !== 'Platform Transfer' && (
            <>
              <div>
                <Label value={ct('email')} />
                <AntdInput
                  onChange={(value) => handleChange(value, 'email')}
                  value={payeeData.email}
                  error={payeeError.email}
                  disabled={isView}
                  placeholder={t('enter_email')}
                />
              </div>
              <div>
                <Label value={ct('phone_number')} />
                {isView && !phone_number ? (
                  <AntdInput value={'--'} disabled={true} />
                ) : (
                  <PhoneNumber
                    name="phone"
                    position={'bottom'}
                    options={areaCodes}
                    onChange={(value) => handleChange(value, 'phone')}
                    value={payeeData.phone}
                    error={payeeError.phone}
                    disabled={isView}
                  />
                )}
              </div>
            </>
          )}
        </div>

        {(transfer_method === 'RTGS' || transfer_method === 'SWIFT') && (
          <>
            <div className="grid grid-cols-4 gap-6">
              <div className="col-span-2">
                <Label
                  value={t('billing_address_1')}
                  required={true}
                  disabled={isView}
                />
                <AntdInput
                  onChange={(value) =>
                    handleChange(value, 'billing_address_1')
                  }
                  value={payeeData.billing_address_1}
                  error={payeeError.billing_address_1}
                  disabled={isView}
                  placeholder={t('enter_billin_address_1')}
                />
              </div>
              <div className="col-span-2">
                <Label
                  value={t('billing_address_2')}
                  required={true}
                  disabled={isView}
                />
                <AntdInput
                  onChange={(value) =>
                    handleChange(value, 'billing_address_2')
                  }
                  value={payeeData.billing_address_2}
                  error={payeeError.billing_address_2}
                  disabled={isView}
                  placeholder={t('enter_billing_address_2')}
                />
              </div>
            </div>
            <div className="grid grid-cols-4 gap-6">
              <div>
                <Label
                  value={t('city')}
                  required={true}
                  disabled={isView}
                />
                <AntdInput
                  onChange={(value) => handleChange(value, 'city')}
                  value={payeeData.city}
                  error={payeeError.city}
                  disabled={isView}
                  placeholder={t('enter_city')}
                />
              </div>
              <div>
                <Label value={t('state')} />
                <AntdInput
                  onChange={(value) => handleChange(value, 'state')}
                  value={payeeData.state}
                  error={payeeError.state}
                  disabled={isView}
                  placeholder={t('enter_state')}
                />
              </div>
              <div>
                <Label
                  value={t('zip_code')}
                  required={true}
                  disabled={isView}
                />
                <AntdInput
                  onChange={(value) => handleChange(value, 'zip_code')}
                  value={payeeData.zip_code}
                  error={payeeError.zip_code}
                  disabled={isView}
                  placeholder={t('enter_zip_code')}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PayeeInformation;
