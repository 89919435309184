import { IBaseCartProps } from "@/types/analytics-type";
import { handleNumber } from "@/utils/field-utils";
import Highcharts from "highcharts";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TransitionNode from "../../components/transition-node";
import MoreIcon from "../../analytics-icons/more-icon";
import Skeleton from "@/components/skeleton";
import HighchartsReact from "highcharts-react-official";
import NodataContent from "../../components/no-data-content";
import TransactionsDetails from "../transactions-details";
import { IAnalyticsTypes } from "@/data/analytics";
import { getXAndYStyle } from "@/utils/analytics-utils";
import BarChartSkeleton from "../../components/bar-chart-skeleton";

const TransactionTicket: React.FC<IBaseCartProps> = (props) => {
  const {
    data,
    currency,
    startDate,
    endDate,
    businessID,
    loading: loadAllData,
    openExportModal,
  } = props;

  const { t } = useTranslation(['analytics', 'transaction_advice']);

  const { points = [], total_amount = 0, total_count = 0, avg_amount = 0 } = data || {};

  const [showMore, setShowMore] = useState<boolean>(false);
  const [options, setOptions] = useState<Highcharts.Options>({
    title: {
      text: undefined,
    },
  });

  const total = handleNumber(total_amount);
  const title = t('analytics:average_transaction_ticket');
  const disabled = !points || points.length === 0 || total_count === 0;

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getOptions = () => {
    const labels: string[] = [];

    const barData: { name: string; data: number[]; color: string } = {
      name: '',
      data: [],
      color: '#025DF4',
    };

    points?.forEach((point) => {
      const { x_axis, y_axis, name } = point;
      barData.name = t('analytics:average_transaction_ticket_amount');
      if (name) {
        barData.data.push(y_axis);
      }

      if (!labels.includes(x_axis)) {
        labels.push(x_axis);
      }
    });

    const options: Highcharts.Options = {
      chart: {
        type: 'column',
        style: {
          fontFamily: 'Roboto',
        },
      },
      title: {
        text: undefined,
      },
      xAxis: {
        categories: labels,
        labels: {
          style: getXAndYStyle(),
        },
      },
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          style: getXAndYStyle(),
        },
        stackLabels: {
          enabled: false,
          // formatter: function () {
          //   const total = handleNumber(this.total);
          //   return total === '0.00' ? '' : `${total}`;
          // },
        },
      },
      tooltip: {
        formatter: function () {
          const { point, key } = this as any;
          return (
            '<b>' +
            key +
            '</b><br/>' +
            '<span style="color:' +
            point.color +
            '">\u25A0</span>  ' +
            point.series.name +
            ': ' +
            handleNumber(point.y)
          );
        },
      },
      legend: {
        enabled: true,
        useHTML: true,
        className: 'customized-legend',
        labelFormatter: function () {
          return (
            '<div class="series-label flex items-center">' +
            '<div style="color: ' +
            this.color +
            '">' +
            '<svg width="14" height="14" viewBox="0 0 14 14">' +
            '<rect x="0.300781" y="5.59961" width="2.80009" height="8.40028" fill="currentColor"/>' +
            '<rect x="5.10156" width="2.80009" height="14.0005" fill="currentColor"/>' +
            '<rect x="9.90234" y="8.40039" width="2.80009" height="5.60019" fill="currentColor"/>' +
            '</svg>' +
            '</div>' +
            '<div class="ml-2 text-sm font-medium leading-5">' +
            this.name +
            '</div>' +
            '</div>'
          );
        },
      },
      credits: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      series: [
        {
          ...barData,
          type: 'column',
        },
      ],
    };

    setOptions(options);
  };

  const handleMore = async () => {
    if (!disabled) {
      setShowMore(true);
    }
  };

  const handleCancel = () => {
    setShowMore(false);
  };

  const handleOpenExport = () => {
    handleCancel();
    if (openExportModal) {
      openExportModal();
    }
  };

  return (
    <TransitionNode>
      <div className="px-6 py-6 md:px-9 flex flex-col h-full">
        <div className="flex justify-between mb-6">
          <div className="font-bold text-xl text-color-text-1">{title}</div>
          <div
            onClick={handleMore}
            className={`p-2 rounded-full h-8 w-8 ${
              disabled
                ? 'bg-disabled-color text-icon-color'
                : 'cursor-pointer bg-opacity-10 hover:bg-opacity-100 hover:text-color-text-6 text-primary-color bg-primary-color'
            }`}
          >
            <MoreIcon />
          </div>
        </div>
        <div className="flex flex-col mb-4">
          {loadAllData && (
            <div className="h-[45px] flex items-center">
              <Skeleton
                className="h-auto w-48"
                skeletonItemClass="h-6 rounded-2xl"
                row={1}
              />
            </div>
          )}
          {!loadAllData && (
            <div className="text-[30px] text-color-text-1">
              <span className="font-bold mr-2">
                {handleNumber(avg_amount)}
              </span>
              <span>{currency}</span>
            </div>
          )}
          <div className="text-color-text-2 font-medium">
            {t('analytics:average_transaction_amount')}
          </div>
        </div>
        {loadAllData && <BarChartSkeleton />}
        {!loadAllData && !disabled && (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}
        {!loadAllData && disabled && <NodataContent />}
        <TransactionsDetails
          visible={showMore}
          total={`${
            total.indexOf('-') === -1 ? `+${total}` : total
          } ${currency}`}
          startDate={startDate}
          endDate={endDate}
          businessID={businessID}
          currency={currency}
          type={IAnalyticsTypes.TRANSACTION_TICKET}
          openExportModal={handleOpenExport}
          onCancel={handleCancel}
        />
      </div>
    </TransitionNode>
  );
};

export default TransactionTicket;
