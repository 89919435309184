import { Button } from 'antd';
import React from 'react';
import WarningIcon from '@/components/svg/warning-icon';
import { useTranslation } from 'react-i18next';
import AntdModal from '@/components/common/antd/modal';

interface RateUpdateCountdownModalProps {
  interval: number;
  onCancel: () => void;
}

const RateUpdateCountdownModal: React.FC<RateUpdateCountdownModalProps> = (
  props
) => {
  const { t } = useTranslation('payouts');
  const { t: ct } = useTranslation('common');
  const { interval, onCancel } = props;
  return (
    <AntdModal open={true} footer={null} onCancel={onCancel}>
      <div className="p-10 flex flex-col items-center">
        <div className="bg-primary-color/10 p-[18px] rounded-full">
          <WarningIcon size={36} className="text-primary-color" />
        </div>
        <div className="text-color-text-1 font-bold text-xl mt-6 whitespace-pre-line">
          {t('rate_update_countdown')}
        </div>
        <div className="text-color-text-2 text-sm font-medium text-center mt-2">
          {t('rate_update_countdown_tips', { interval })}
        </div>
        <Button
          type="primary"
          className="bg-primary-color text-sm w-full mt-6 h-9 font-medium"
          onClick={onCancel}
        >
          {ct('back')}
        </Button>
      </div>
    </AntdModal>
  );
};

export default React.memo(RateUpdateCountdownModal);
