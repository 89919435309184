import { payInvoice, getApplePaySession } from '../../../../../data/terminal';
import { handleBuildOrderPayment } from '../utils';
import type {
  IApplePayMethod,
  IExtraActionParam,
  IWindowApple,
} from '../interface';
import { IObject } from '@/types/common-types';

interface IApplePayParams {
  businessID: string;
  orderNumber: string;
  payData: IApplePayMethod;
  payment: any;
  email: string;
  lang: string;
  extraAction?: IExtraActionParam;
  callback?: () => Promise<IObject>;
  payLoading?: () => void;
}

const UseApplePay = async (params: IApplePayParams) => {
  const { payData, extraAction, callback, orderNumber } = params;

  const {
    amount,
    countryCode,
    currencyCode,
    label,
    supportedNetworks,
    appMerchantId,
    appMerchantDomain,
    appName = 'Bindo Gateway',
    merchantCapabilities = ['supports3DS'],
  } = payData;

  const { ApplePaySession } = window as IWindowApple;

  if (!ApplePaySession) {
    if (extraAction && extraAction.onCancel) {
      extraAction.onCancel(
        'I won`t support it',
        'Non-apple payment environment'
      );
    }
    return;
  }
  return new Promise((resolve, reject) => {
    const request = {
      total: {
        // 代表付款总额的订单项
        label,
        amount,
      },
      countryCode: countryCode, // 商家的双字母ISO 3166国家代码
      currencyCode: currencyCode, // 付款的三字母ISO 4217货币代码
      merchantCapabilities, // 商家支持的支付功能
      supportedNetworks, // 商家支持的支付网络
    };

    let code = '200';
    let message = '';

    try {
      const session = new ApplePaySession(2, request);
      let newNumber = orderNumber;

      session.onvalidatemerchant = async (event: any) => {
        try {
          const response = await getApplePaySession({
            validationURL:
              'https://apple-pay-gateway.apple.com/paymentservices/startSession',
            appName,
            appMerchantId,
            appMerchantDomain,
          });

          session.completeMerchantValidation(response);
        } catch (err: any) {
          code = 'Unable to process payment';
          message = err.message || 'Please try with another payment or contact support';
          console.error('商家验证失败', err);
          // 中止当前的Apple Pay会话
          session.abort();
        }
      };

      // 用户通过Touch ID，Face ID或密码授权Apple Pay付款时自动调用的回调函数
      session.onpaymentauthorized = async (event: any) => {
        session.completePayment(ApplePaySession.STATUS_SUCCESS);
        if (event.payment.token.paymentData) {
          const paymentDataStr = JSON.stringify(
            event.payment.token.paymentData
          );
          try {
            if (callback) {
              const { orderNumber: number, result } = await callback();
              newNumber = number;
              if (result) {
                code = result.code;
                message = result.message;
              }
            }
            if (!newNumber) {
              if (extraAction && extraAction.onCancel) {
                extraAction.onCancel(code, message);
              }
              session.abort();
            } else {
              const resp: any = await pay(
                window.btoa(paymentDataStr),
                params,
                newNumber
              );
              const { data: resOrder } = resp || {};
              if (resOrder) {
                resolve({
                  order: resOrder,
                });
              } else {
                resolve({
                  status: 500,
                  ...resp,
                });
              }
            }
          } catch (e: any) {
            code = '200007';
            message = 'Acquirer Authentication Failed';
            session.abort();
            reject({
              message: e.message,
            });
            session.abort();
            throw e;
          }
          // 用结果完成支付授权
          session.completePayment(ApplePaySession.STATUS_SUCCESS);
        }
      };

      // 付款界面被取消时自动调用的回调函数
      session.oncancel = () => {
        if (extraAction && extraAction.onCancel) {
          extraAction.onCancel('', '');
        }
      };

      session.begin();
    } catch (error: any) {
      code = 'Other errors';
      message = error.message || 'apple pay error';
      console.error(error, 'apple pay error message');
    }
    if (extraAction && extraAction.onCancel && code !== '200') {
      extraAction.onCancel(code, message);
    }
  });
};

const pay = async (
  paymentData: any,
  params: IApplePayParams,
  number: string
) => {
  try {
    const { businessID, payment, payData, email, lang, payLoading } = params;
    const { amount, appMerchantId, currenToken } = payData;
    const newPayment = {
      apple_pay: [
        {
          amount,
          token_base64: paymentData,
          merchant_identifier: appMerchantId,
        },
      ],
    };
    const order = handleBuildOrderPayment(newPayment, payment);
    if (payLoading) {
      payLoading();
    }
    const resp = await payInvoice({
      businessID,
      orderNumber: number,
      order,
      email,
      lang,
      token: currenToken,
    });

    return resp;
  } catch (error) {
    throw error;
  }
};

export default UseApplePay;
