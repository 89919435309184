import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import type { IFilterOptions, IFilterProps } from '.';
import FilterContent from './filter-content';
import CleanIcon from '@/components/svg/clean-icon';
import FilterIcon from './filter-icons/filter-icon';
import SearchIcon from './filter-icons/search-icon';
import Button from '../button';
import './filter-style.css'

let timer: any = null;

const Filter: React.FC<IFilterProps> = (props) => {
  const { t } = useTranslation();
  const {
    onFilter,
    searchValue,
    options,
    placeholder = t('common:search_items'),
  } = props;

  const filterDom = useRef<any>(null);

  const [search, setSearch] = useState(false);
  const [filter, setFilter] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [value, setValue] = useState(searchValue);
  const [filterOptions, setFilterOptions] = useState(options);

  useEffect(() => {
    const clickHandler = (e: any) => {
      if (
        !filterDom.current ||
        (e.target && e.target.dataset.menu === 'open') ||
        (typeof e.target.className === 'string' &&
          (e.target.className.indexOf('flatpickr') > -1 ||
            e.target.className.indexOf('ant-picker') > -1))
      )
        return;
      if (!filterDom.current.contains(e.target)) {
        setSearch(false);
        setFilter(false);
        setShowMenu(false);
      }
    };

    document.addEventListener('click', clickHandler, true);
    return () => document.removeEventListener('click', clickHandler);
    // eslint-disable-next-line
  }, []);

  const handleSearch = () => {
    if (!search) {
      setSearch(true);
    }
  };

  const handleValueChange = (search = value, options = filterOptions) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      if (handleSearchJudgment(search, options)) {
        onFilter(search, options);
      }
      clearTimeout(timer);
      timer = null;
    }, 700);
    setValue(search);
    setFilterOptions(options);
  };

  const handleSearchJudgment = (
    search: string,
    filterOptions: IFilterOptions[]
  ) => {
    if (search !== searchValue) {
      return true;
    }
    const oldFeature: string[] = [];
    const newFeature: string[] = [];
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if (option.value) {
        oldFeature.push(`${option.name}%${option.operation}%${option.value}`);
      }
    }
    for (let i = 0; i < filterOptions.length; i++) {
      const option = filterOptions[i];
      if (option.value) {
        const featureValue = `${option.name}%${option.operation}%${option.value}`;
        if (!oldFeature.includes(featureValue)) {
          return true;
        }
        newFeature.push(featureValue);
      }
    }

    if (oldFeature.length !== newFeature.length) {
      return true;
    }

    return false;
  };

  const handleFilter = () => {
    setFilter(!filter);
    setSearch(true);
  };

  return (
    <div className={`relative flex`} ref={filterDom}>
      <div
        className={`px-3 bg-primary-background-color flex justify-between items-center border border-border-color ${
          filter ? 'rounded-tl rounded-tr' : 'rounded'
        } transition-width inherit duration-300 h-10 ease-linear ${
          search || filter
            ? 'w-[500px] border-primary-color'
            : 'w-20 border-border-color'
        }`}
      >
        <SearchIcon
          className="text-icon-color cursor-pointer mr-2 hover:text-primary-color"
          onClick={handleSearch}
        />
        {(search || filter) && (
          <input
            className="border-none outline-none text-sm text-color-text-1 w-0 mr-2 flex-grow placeholder:text-color-text-5 font-medium"
            autoFocus={true}
            value={value}
            placeholder={placeholder}
            onChange={(e) => handleValueChange(e.target.value)}
          />
        )}
        <CleanIcon
          className={`text-color-text-5 cursor-pointer hover:text-color-text-3 ${
            search && value.length > 0 ? 'visible' : 'hidden'
          }`}
          onClick={() => handleValueChange('')}
        />
        <FilterContent
          {...props}
          showMenu={showMenu}
          filter={filter}
          search={search}
          options={filterOptions}
          searchValue={value}
          onFilter={handleValueChange}
          setShowMenu={setShowMenu}
        />
        <div onClick={handleFilter} className="relative">
          <FilterIcon
            className={`${
              filter ? 'text-primary-color' : 'text-icon-color'
            } cursor-pointer hover:text-primary-color`}
          />
          <span
            className={`absolute rounded-full bg-primary-color w-5 h-5 px-1 text-sm text-center text-color-text-6 bottom-4 left-5 ${
              filter || filterOptions.length === 0 ? 'hidden' : ''
            }`}
          >
            {filterOptions.length}
          </span>
        </div>
      </div>
      <Button
        className={`ml-4 transition duration-300 ${
          !search && !filter && (filterOptions.length > 0 || value)
            ? 'visible'
            : 'hidden'
        }`}
        onClick={() => handleValueChange('', [])}
      >
        {t('common:clear_filter')}
      </Button>
    </div>
  );
};

export default Filter;
