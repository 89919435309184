import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAssociate, IAssociateAction, IAssociateDetail } from '@/types/associates-type';
import { Constants } from '@/constants';
import { getCookie } from '@/utils';
import { removeAssociate, updateAssociate } from '@/data/associates';
import { useParams } from 'react-router-dom';
import WarningIcon from '@/components/svg/warning-icon';
import Button from '@/components/common/button';
import { Alert } from '@/components/common/alert/alert';
import AntdModal from '@/components/common/antd/modal';
import GlobalLoading from '@/components/common/global-loading';

interface IActionModalProps {
  action: IAssociateAction;
  associate: IAssociate | IAssociateDetail;
  businessID: string;
  business: any;
  onCancel: () => void;
  callback: (action: IAssociateAction, success: boolean) => void;
}

const ActionModal: React.FC<IActionModalProps> = (props) => {
  const { action, associate, businessID, business, onCancel, callback } = props;
  const { id } = associate;
  const { t } = useTranslation('associates');
  const { t: ct } = useTranslation('common');
  const { lang = 'en' } = useParams();
  const [loading, setLoading] = useState(false);
  const [leaveLoading, setLeaveLoading] = useState(false);
  const { title, caption } = useMemo(() => {
    let title = '';
    let caption = '';
    if (action === 'suspend') {
      title = t('suspend_associate', {
        name: associate.display_name,
      });
      caption = t('suspend_associate_info');
    }
    if (action === 'reactivate') {
      title = t('reactivate_associate', {
        name: associate.display_name,
      });
      caption = t('reactivate_associate_info');
    }
    if (action === 'remove') {
      title = t('remove_record', { name: associate.display_name });
      caption = t('remove_record_info');
    }
    if (action === 'leave') {
      title = t('associate_leave');
      caption = t('associate_leave_info');
    }
    return { title, caption };
  }, [action, associate, t]);

  const token = getCookie(Constants.TOKEN) || '';
  const { business_name, translations } = business || {};
  const businessName = useMemo(() => {
    let businessName = business_name;
    if (Array.isArray(translations)) {
      for (let i = 0; i < translations.length; i++) {
        const item = translations[i];
        if (item.languages_code === lang && item.business_name) {
          businessName = item.business_name;
        }
      }
    }

    return businessName;
  }, [business_name, lang, translations]);

  const handleConfirm = async () => {
    let success = false;
    let successMsg = '';
    let failedMsg = '';
    if (action === IAssociateAction.LEAVE) {
      setLeaveLoading(true);
    } else {
      setLoading(true);
    }
    if (action === IAssociateAction.REMOVE) {
      success = await removeAssociate(id, businessID, token);
      successMsg = t('remove_associate_successfully');
      failedMsg = t('remove_associate_failed');
    }
    if (action === IAssociateAction.SUSPEND) {
      success = await updateAssociate(
        id,
        businessID,
        { status: 'suspended' },
        token
      );
      successMsg = t('suspend_associate_successfully');
      failedMsg = t('suspend_associate_failed');
    }
    if (action === IAssociateAction.REACTIVATE) {
      success = await updateAssociate(
        id,
        businessID,
        { status: 'active' },
        token
      );
      successMsg = t('reactivate_associate_successfully');
      failedMsg = t('reactivate_associate_failed');
    }
    if (action === IAssociateAction.LEAVE) {
      success = await removeAssociate(id, businessID, token);
      successMsg = t('leave_successfully', {
        business_name: businessName,
      });
      failedMsg = t('leave_failed');
    }
    if (success) {
      Alert.success({
        message: successMsg,
        position: 'default',
        wrapClass: 'top-px',
      });
    } else {
      Alert.error({
        message: failedMsg,
        position: 'default',
        wrapClass: 'top-px',
      });
    }
    setLoading(false);
    await callback(action, success);
  };

  return (
    <>
      <AntdModal
        open={!leaveLoading}
        footer={null}
        closeIcon={null}
        closable={false}
        onCancel={onCancel}
        size="small"
      >
        <div className="p-10 pt-8 flex text-center flex-col items-center text-color-text-1">
          <div className="flex p-5 bg-warning-color/10 text-warning-color rounded-full">
            <WarningIcon size={40} />
          </div>
          <div className="text-xl font-bold mt-4">{title}</div>
          <div className="text-sm font-medium mt-2">{caption}</div>
          <Button
            className="w-full mt-6"
            onClick={handleConfirm}
            loading={loading}
          >
            {ct('confirm')}
          </Button>
          <Button className="w-full mt-4" styleType="Border" onClick={onCancel}>
            {ct('cancel')}
          </Button>
        </div>
      </AntdModal>
      <GlobalLoading loading={leaveLoading} />
    </>
  );
};

export default ActionModal;
