import BackIcon from '@/components/svg/back-icon';
import { Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { currencies } from '@/utils/currencies';
import { findNode } from '@/utils';
import Table from '@/components/table';
import ScrollNum from '@/components/common/scroll-number';
import { getCurrentAccountColumns } from '../account-balance/account-balance-data';
import { useTranslation } from 'react-i18next';
import type { IObject } from '@/types/common-types';
import BusinessWalletsUpcoming from './upcoming';
import { plus } from '../../utils/global-account-untils';
import type { ColumnsType } from 'antd/es/table';
import type { TableRecord } from '@/components/table/interface';
import Button from '@/components/common/button';
import ExportStatementModal from './export-statement-modale';
import { getActivities, getBusinessWallets } from '../../data/global-account';
import { useParams, useNavigate } from 'react-router-dom';
import Config from '@/libs/config';
import { Constants } from '@/constants';
import { getCookie } from '@/utils';
import AddIcon from './global-accounts-icons/add-icon';


import {
  HadleGoTranscationDetails,
  parseGetActivitiesParams,
} from '../../utils/account-balance-untils';
import type {
  IFilterField,
  IFilterOptions,
} from '@/components/common/filter';
import Filter, {
  FilterOperation,
  IFilterType,
} from '@/components/common/filter';
import CurrencyIcon from './global-accounts-icons/currency-icon';
import ActionType from '@/actions/action-type';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';

export enum TransactionType {
  payIn = 'payin',
  payOut = 'payout',
  card = 'card',
  exChange = 'exchange',
}


const GlobalAccountsActivities = (

) => {
  const prefix = Config.staticPath;
  const [record, setRecord] = useState<any>({});
  const { t } = useTranslation(["global_accounts", "account_balance"]);
  const { lang = 'en', p_business_id: businessID = '', ledger_account_id: recordId = '' } = useParams();
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const [columns, setColumns] = useState<ColumnsType<TableRecord>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [records, setRecords] = useState<IObject[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [show, setShow] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<IFilterOptions[]>([]);

  const { currency_code, currency_name, currency_symbol } =
    record.currency || {};

  useEffect(() => {
    getColums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('common:company_account'),
          key: '1',
          type: 'label',
        },
        {
          name: t('common:global_accounts'),
          key: '2',
          type: 'link',
          url: `/${lang}/businesses/${businessID}/global-accounts`,
        },
        {
          name: `${currency_code || ''} ${t('global_accounts:account')}`,
          key: '3',
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, currency_code])

  useEffect(() => {
    getRecords();
    initLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currencyobj: any = findNode(currencies, 'currency_code', currency_code);

  const getColums = () => {
    const columns = getCurrentAccountColumns(t, false, lang);

    setColumns(columns);
    setLoading(false);
  };

  const initLoad = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    const allPromise: any[] = [
      getBusinessWallets(businessID, lang, token as string),
    ];
    const resp: any = await Promise.all(allPromise);
    if (resp.length > 0) {
      const data = resp[0];
      const { ledger_accounts = [] } = data;
      const newrecord: any = findNode(ledger_accounts, 'id', recordId);
      setRecord(newrecord)
    }
  }

  const handleBack = () => {
    navigate(`/${lang}/businesses/${businessID}/global-accounts`);
  };

  const getRecords = async (
    limit = pageSize,
    offset = 0,
    page = 1,
    search = searchValue,
    filterOptions = options
  ) => {
    setLoading(true);
    if (businessID) {
      const params = parseGetActivitiesParams(search, filterOptions);
      const activities = await getActivities(
        {
          pBusinessId: businessID,
          limit: limit + 1,
          offset,
          type: 'ledger_account',
          ledger_account_id: recordId,
        },
        params
      );
      setPage(page);
      setPageSize(limit);
      setLoading(false);
      setSearchValue(search);
      setOptions(filterOptions);
      if (Array.isArray(activities)) {
        setRecords(activities);
      }
    }
  };

  const handleItemClick = (index: number) => {
    if (index !== 0) {
      navigate(
        `/${lang}/businesses/${businessID}/global-accounts/${recordId}/account-details`
      );
    }
  };

  const tabs = [
    t('global_accounts:current_activities'),
    t('global_accounts:account_details'),
  ];

  const filterFields: IFilterField[] = [
    {
      name: 'settlement_date',
      label: t('account_balance:settled_on'),
      type: IFilterType.DATE,
      operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
    },
    {
      name: 'created_at_data',
      label: t('account_balance:created_on'),
      type: IFilterType.DATE,
      operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
    },
    {
      name: 'amount',
      label: t('common:amount'),
      type: IFilterType.NUMBER,
      operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
    },
    {
      name: 'activity_type',
      label: t('account_balance:activity_type'),
      type: IFilterType.MULTISELECT,
      operations: [FilterOperation.EQUALS],
      options: [
        {
          name: 'Merchant Services',
          label: 'Merchant Services',
        },
        {
          name: 'Add Fund',
          label: 'Add Fund',
        },
        {
          name: 'Inbound Transfer',
          label: 'Inbound Transfer',
        },
        {
          name: 'Outbound Transfer',
          label: 'Outbound Transfer',
        },
        {
          name: 'Fund Reversal',
          label: 'Fund Reversal',
        },
        {
          name: 'Wallet Conversion',
          label: 'Wallet Conversion',
        },
        {
          name: 'Rolling Reserve Released',
          label: 'Rolling Reserve Released',
        },
      ],
    },
    {
      name: 'activity_status',
      label: t('common:status'),
      type: IFilterType.MULTISELECT,
      operations: [FilterOperation.EQUALS],
      options: [
        {
          name: 'Initiated',
          label: 'Initiated',
        },
        {
          name: 'Pending for Transfer',
          label: 'Pending for Transfer',
        },
        {
          name: 'Processing Transfer',
          label: 'Processing Transfer',
        },
        {
          name: 'Settled',
          label: 'Settled',
        },
        {
          name: 'Rejected',
          label: 'Rejected',
        },
        {
          name: 'Reversed',
          label: 'Reversed',
        },
        {
          name: 'Suspended',
          label: 'Suspended',
        },
      ],
    },
  ];
  const toFx = () => {
    navigate(`/${lang}/businesses/${businessID}/global-accounts/${recordId}/fx?currency_code=${currency_code}`);
  };

  const toAddFund = () => {
    navigate(`/${lang}/businesses/${businessID}/global-accounts/${recordId}/addfund?currency_code=${currency_code}`);
  };

  return (
    <
      // data={[
      //   {
      //     name: t('common:company_account'),
      //     key: '1',
      //     type: 'label',
      //   },
      //   {
      //     name: t('common:global_accounts'),
      //     key: '2',
      //     type: 'link',
      //     url: `/${lang}/businesses/${businessID}/global-accounts`,
      //   },
      //   {
      //     name: `${currency_code || ''} ${t('global_accounts:account')}`,
      //     key: '3',
      //     type: 'label',
      //   },
      // ]}
      >
      <div className="p-4 flex flex-col md:p-6 lg:px-8 lg:py-6">
        <div className="flex">
          <div className="flex flex-1 mb-6">
            <div className="bg-disabled-color shadow-box-drop rounded-full w-10 h-10 flex items-center justify-center cursor-pointer">
              <BackIcon
                className="hover:text-primary-color"
                onClick={handleBack}
              />
            </div>
            <div className="text-3xl leading-10 font-bold text-color-text-1 ml-2.5 invisible sm:visible">
              {currency_code || ''} {t('global_accounts:account')}
            </div>
          </div>
          <Button
            onClick={() => setShow(true)}
            styleType="Secondary"
            className="mr-2.5"
          >
            {t('global_accounts:export_statement')}
          </Button>
          <Button
            onClick={toFx}
            iconInstance={<CurrencyIcon />}
            className="mr-2.5"
          >
            {t('global_accounts:currency_conversion')}
          </Button>
          <Button
            onClick={toAddFund}
            iconInstance={<AddIcon />}
            className="mr-2.5"
          >
            {t('common:add_fund')}
          </Button>
        </div>

        <div className="flex-1 flex flex-col">
          <div className="sm:block flex">
            <div
              className={`flex space-x-4 h-fit overflow-x-auto hidden_scrollbar`}
              aria-label="line-tabs"
            >
              {tabs.map((item, index) => (
                <div
                  key={index}
                  className={`
                    py-2 px-4 rounded-3xl text-sm whitespace-nowrap font-medium
                    ${index === 0
                      ? 'bg-primary-color text-color-text-6'
                      : 'bg-color-text-6 text-color-text-2 cursor-pointer hover:text-primary-color border-border-color border'
                    }  
                  `}
                  onClick={() => handleItemClick(index)}
                >
                  {item}
                </div>
              ))}
            </div>
            <Transition.Root show={true} as={Fragment} appear={true}>
              <div>
                <Transition.Child
                  className="flex-1"
                  enter="duration-350 transform transition ease-in-out"
                  enterFrom="-translate-x-1 translate-y-8 opacity-60"
                  enterTo="translate-x-0 translate-y-0 opacity-100"
                  leave="duration-500 transform transition"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="p-6 mt-6 bg-primary-background-color flex rounded overflow-hidden shadow-card-drop">
                    <div className="flex">
                      {currencyobj && (
                        <img
                          // layout="intrinsic"
                          width="45px"
                          height="45px"
                          loading="lazy"
                          src={`${prefix}/images/country-flag/${currencyobj.alpha_2.toLocaleLowerCase()}.svg`}
                          alt=""
                        />
                      )}
                      <div className="flex flex-col justify-between ml-2">
                        <div className="text-color-text-1 text-3xl font-bold">
                          {currency_code}
                        </div>
                        <div className="text-color-text-2 text-sm">
                          {currency_name}
                        </div>
                      </div>
                    </div>
                    <div className="h-auto w-[1px] ml-36 mr-10 bg-border-color" />
                    <div className="flex-1 grid grid-cols-3">
                      <div className="flex flex-col justify-between">
                        <div className="text-color-text-1 text-xl font-bold">
                          <ScrollNum
                            size={20}
                            value={record.available || 0}
                            prefix={`${currency_code === 'HKD' ? 'HK' : ''
                              }${currency_symbol}`}
                          />
                        </div>
                        <div className="text-color-text-2 text-sm">
                          {t('global_accounts:current_balance')}
                        </div>
                      </div>
                      <div className="flex flex-col justify-between">
                        <div className="text-color-text-1 text-xl font-bold">
                          <ScrollNum
                            size={20}
                            value={record.incoming || 0}
                            prefix={`${currency_code === 'HKD' ? 'HK' : ''
                              }${currency_symbol}`}
                          />
                        </div>
                        <div className="text-color-text-2 text-sm">
                          {t('global_accounts:incoming_fund')}
                        </div>
                      </div>
                      <div className="flex flex-col justify-between">
                        <div className="text-color-text-1 text-xl font-bold">
                          <ScrollNum
                            size={20}
                            value={plus(
                              Number(record.incoming),
                              Number(record.available)
                            )}
                            prefix={`${currency_code === 'HKD' ? 'HK' : ''
                              }${currency_symbol}`}
                          />
                        </div>
                        <div className="text-color-text-2 text-sm">
                          {t('global_accounts:projected_balance')}
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition.Child>

                <BusinessWalletsUpcoming
                  businessID={businessID}
                  recordId={recordId}
                  filterFields={filterFields}
                />

                <Transition.Child
                  className="flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color mt-6 p-4 md:p-6 h-fit"
                  enter="duration-500 transform transition ease-in-out"
                  enterFrom="-translate-x-1 translate-y-8 opacity-60"
                  enterTo="translate-x-0 translate-y-0 opacity-100"
                  leave="duration-500 transform transition"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="flex flex-col md:flex-row md:justify-between md:items-center xl:space-y-0 mb-6">
                    <div className="font-bold text-lg text-color-text-1 flex items-center md:text-xl">
                      {t('global_accounts:current_activities')}
                    </div>
                    <Filter
                      fields={filterFields}
                      onFilter={(search, options) =>
                        getRecords(pageSize, 0, 1, search, options)
                      }
                      searchValue={searchValue}
                      options={options}
                      placeholder={t('account_balance:search_id_name')}
                    />
                  </div>
                  <div className="w-full overflow-auto">
                    <Table
                      columns={columns}
                      rowKey={(record) => {
                        const {
                          payin_request_id,
                          payout_request_id,
                          fx_conversion_id,
                          card_transaction_id,
                        } = record || {};
                        return (
                          payin_request_id ||
                          payout_request_id ||
                          fx_conversion_id ||
                          card_transaction_id
                        );
                      }}
                      className={`!max-h-[30rem]`}
                      search={searchValue.length > 0 || options.length > 0}
                      records={records}
                      loadingData={loading}
                      pagination={{
                        className: 'mt-6',
                        current: page,
                        currentSize: records.length,
                        pageSize,
                        pageSizeOptions: [30, 60, 120],
                        changePageCallback: (page, pageSize) => {
                          getRecords(pageSize, (page - 1) * pageSize, page);
                        },
                      }}
                      onRowCell={(record, dataKey) => {
                        return {
                          onClick: (evt) => {
                            evt.stopPropagation();
                            HadleGoTranscationDetails(
                              businessID,
                              record,
                              navigate,
                              'wallets',
                              recordId
                            );
                          },
                        };
                      }}
                    />
                  </div>
                </Transition.Child>
              </div>
            </Transition.Root>
          </div>
        </div>
      </div>
      <ExportStatementModal
        show={show}
        onCancel={() => setShow(false)}
        pBusinessId={businessID}
        currencyCode={currency_code}
      />
    </>
  );
};

export default GlobalAccountsActivities;
