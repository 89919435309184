import type { IFileObject } from '@/types/common-types';
import { useState } from 'react';
// import api from '@/libs/api';
import Config from '@/libs/config';
import { getCookie } from '@/utils/index';
import { Constants } from '@/constants';
import ImageIcon from './icons/image-icon';
import FileIcon from './icons/file-icon';
import axios from 'axios';

import { useTranslation } from 'react-i18next';
import './index.css'

export interface IUploadProps {
  name: string;
  type: 'IMAGE' | 'FILE';
  loading: boolean;
  setLoading: (loading: boolean) => void;
  maxSize: number;
  handlefilesUpdate: (fileObject: IFileObject, type: 'ADD' | 'DELETE') => void;
  className?: string;
  shape?: 'Rectangular' | 'Circular';
  dataBase?: string;
  label?: string;
}

const Upload: React.FC<IUploadProps> = (props) => {
  const { t } = useTranslation();
  const {
    name,
    type,
    loading,
    setLoading,
    maxSize,
    handlefilesUpdate,
    shape,
    className,
    dataBase = 'wonder-registry',
    label = type === 'FILE'
      ? t('common:drop_files_upload')
      : t('common:upload_files'),
  } = props;
  const [failed, setFailed] = useState(false);
  const [limit, setLimit] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const token = getCookie(Constants.TOKEN) || '';

  const handleRequest = () => {
    setLoading(true);
    setFailed(false);
    setLimit(false);
    const upload = document.getElementById(name) as HTMLInputElement;
    if (upload && upload.files && upload.files.length > 0) {
      const file = upload.files[0];
      const max = maxSize * 1024 * 1024;
      if (file.size > max) {
        setFailed(true);
        setLoading(false);
      } else {
        uploadFile(file);
      }
    } else {
      setFailed(true);
      setLimit(true);
      setLoading(false);
    }
  };

  const uploadFile = async (file: any) => {
    try {
      const binary = new FormData();
      binary.append('file', file);
      binary.append('folder', 'business');
      binary.append('fileName', file.name);
      binary.append('title', file.name);


      const res = await axios({
        method: 'post',
        url: `${Config.urls.gatewayUrl}/api/directus/${dataBase}/files`,
        data: binary,
        headers: {
          'x-user-access-token': token,
        },
      })
      // const res = await axios(
      //   `${Config.urls.gatewayUrl}/api/directus/${dataBase}/files`,
      //   {
      //     headers: {
      //       'x-user-access-token': token,
      //     },
      //     body:binary
      //   }
      // ).then((data: any) => data.json());
      const { data } = res || [];
      if (data.code === 200) {
        // 拼接url
        const file: IFileObject = data.data;
        const fileUrl = data.data.url;
        const filenameDownload: string = data.data.filename_download;
        handlefilesUpdate(
          {
            ...file,
            fileUrl,
            filenameDownload,
          },
          'ADD'
        );
      } else {
        setFailed(true);
        setLimit(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setFailed(true);
      setLimit(true);
      console.error(error);
    }
    setInputValue('');
  };

  return (
    <div>
      <div
        className={`${shape === 'Circular' ? 'aspect-square' : className
          } ${shape === 'Circular' ? ' rounded-full' : 'rounded'} ${loading ? 'loading' : ''
          }`}
      >
        <div
          className={`h-full ${shape === 'Circular' ? ' rounded-full' : 'rounded'}`}
        >
          <label
            className={`h-full group cursor-pointer border-primary-color ${type === 'FILE' ? 'bg-primary-color bg-opacity-10' : ''
              } !border-dashed ${loading ? '!border-none' : 'border'
              } flex items-center justify-center flex-col${!loading && failed ? ' border-error-color' : ''
              } ${shape === 'Circular' ? ' rounded-full' : 'rounded'}`}
          >
            {type === 'IMAGE' && (
              <ImageIcon
                className={
                  'text-primary-color group-hover:text-primary-color/70'
                }
                size={40}
              />
            )}
            {type === 'FILE' && (
              <FileIcon
                className={
                  'text-primary-color group-hover:text-primary-color/70'
                }
                size={60}
              />
            )}
            {type === 'IMAGE' && (
              <div className="text-primary-color text-sm mt-5 font-medium text-center">
                {label}
              </div>
            )}
            {type === 'FILE' && (
              <div className="text-color-text-2 text-sm mt-1 font-medium text-center">
                {label}
              </div>
            )}
            <input
              id={name}
              className="hidden"
              onInput={(evt) => {
                handleRequest();
              }}
              type={'file'}
              accept={type === 'IMAGE' ? 'image/*' : undefined}
              value={inputValue}
            />
          </label>
        </div>
      </div>
      {loading && (
        <div className="text-xs mt-1 font-normal text-color-text-2 leading-5">
          {t('common:uploading')}
        </div>
      )}
      {failed && (
        <div className="text-error-color mt-1 text-xs leading-5 font-normal">
          {limit
            ? t('common:upload_failed')
            : t('common:exceeding_size', { maxSize: maxSize })}
        </div>
      )}
    </div>
  );
};

export default Upload;
