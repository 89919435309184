import React from 'react';
import type { IIconProps } from '@/types/common-types';

const CashIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 36 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18" cy="18.875" r="18" fill="#F8FAFC" />
        <g clipPath="url(#clip0_8856_139086)">
          <path
            d="M28.0114 24.0359H8.23865C7.6949 24.0359 7.25 23.591 7.25 23.0472V12.1722C7.25 11.6285 7.6949 11.1836 8.23865 11.1836H28.0114C28.5551 11.1836 29 11.6285 29 12.1722V23.0472C29 23.591 28.5551 24.0359 28.0114 24.0359Z"
            fill="#0E9347"
          />
          <path
            d="M25.5393 20.0859C24.4024 20.0859 23.3643 20.6297 22.7711 21.5194C22.2768 21.2723 21.7331 21.0746 21.0905 21.0746C19.3604 21.0746 17.9268 22.3598 17.6797 24.0405H28.0109C28.5052 24.0405 28.9007 23.6945 28.9996 23.2001C28.8018 21.47 27.3189 20.0859 25.5393 20.0859Z"
            fill="#0D8944"
          />
          <path
            d="M26.0337 23.0507H10.2155C10.2155 21.9632 9.32578 21.0735 8.23828 21.0735V14.153C9.32578 14.153 10.2155 13.2633 10.2155 12.1758H26.0337C26.0337 13.2633 26.9235 14.153 28.011 14.153V21.0735C26.9235 21.0735 26.0337 21.9632 26.0337 23.0507Z"
            fill="#3BB54A"
          />
          <path
            d="M25.5396 20.0859C24.4027 20.0859 23.3646 20.6297 22.7714 21.5194C22.2771 21.2723 21.7334 21.0746 21.0908 21.0746C19.7067 21.0746 18.5203 21.8655 17.9766 23.0518H26.034C26.034 21.9643 26.8743 21.124 27.9618 21.0746C27.3192 20.4814 26.4789 20.0859 25.5396 20.0859Z"
            fill="#0E9347"
          />
          <path
            d="M18.1245 22.0618C20.3085 22.0618 22.079 20.0699 22.079 17.6129C22.079 15.1559 20.3085 13.1641 18.1245 13.1641C15.9404 13.1641 14.1699 15.1559 14.1699 17.6129C14.1699 20.0699 15.9404 22.0618 18.1245 22.0618Z"
            fill="#0E9347"
          />
          <path
            d="M12.1927 18.6023C12.7388 18.6023 13.1814 18.1597 13.1814 17.6136C13.1814 17.0676 12.7388 16.625 12.1927 16.625C11.6467 16.625 11.2041 17.0676 11.2041 17.6136C11.2041 18.1597 11.6467 18.6023 12.1927 18.6023Z"
            fill="#0E9347"
          />
          <path
            d="M24.056 18.6023C24.602 18.6023 25.0447 18.1597 25.0447 17.6136C25.0447 17.0676 24.602 16.625 24.056 16.625C23.51 16.625 23.0674 17.0676 23.0674 17.6136C23.0674 18.1597 23.51 18.6023 24.056 18.6023Z"
            fill="#0E9347"
          />
          <path
            d="M18.619 17.12H17.6304C17.3338 17.12 17.1361 16.9222 17.1361 16.6256C17.1361 16.329 17.3338 16.1313 17.6304 16.1313H19.1133C19.4099 16.1313 19.6076 15.9336 19.6076 15.637C19.6076 15.3404 19.4099 15.1427 19.1133 15.1427H18.619C18.619 14.8461 18.4213 14.6484 18.1247 14.6484C17.8281 14.6484 17.6304 14.8462 17.6304 15.1427C16.7901 15.1427 16.1475 15.7853 16.1475 16.6257C16.1475 17.466 16.7901 18.1086 17.6304 18.1086H18.6191C18.9157 18.1086 19.1134 18.3064 19.1134 18.6029C19.1134 18.8995 18.9156 19.0972 18.6191 19.0972H17.1361C16.8395 19.0972 16.6418 19.295 16.6418 19.5915C16.6418 19.8881 16.8396 20.0859 17.1361 20.0859H17.6304C17.6304 20.3825 17.8282 20.5802 18.1247 20.5802C18.4213 20.5802 18.619 20.3824 18.619 20.0859C19.4594 20.0859 20.102 19.4432 20.102 18.6029C20.102 17.7626 19.4594 17.12 18.619 17.12Z"
            fill="#89C763"
          />
          <path
            d="M20.1028 27.9908C22.0139 27.9908 23.5631 26.4416 23.5631 24.5306C23.5631 22.6195 22.0139 21.0703 20.1028 21.0703C18.1918 21.0703 16.6426 22.6195 16.6426 24.5306C16.6426 26.4416 18.1918 27.9908 20.1028 27.9908Z"
            fill="#FFCA5D"
          />
          <path
            d="M24.5511 27.0064C26.4621 27.0064 28.0113 25.4572 28.0113 23.5462C28.0113 21.6351 26.4621 20.0859 24.5511 20.0859C22.64 20.0859 21.0908 21.6351 21.0908 23.5462C21.0908 25.4572 22.64 27.0064 24.5511 27.0064Z"
            fill="#F6B545"
          />
          <path
            d="M24.5511 20.0859C22.6232 20.0859 21.0908 21.6183 21.0908 23.5462C21.0908 25.474 22.6232 27.0064 24.5511 27.0064C26.4789 27.0064 28.0113 25.474 28.0113 23.5462C28.0113 21.6183 26.4789 20.0859 24.5511 20.0859ZM24.5511 26.0178C23.167 26.0178 22.0795 24.9303 22.0795 23.5462C22.0795 22.1621 23.167 21.0746 24.5511 21.0746C25.9352 21.0746 27.0227 22.1621 27.0227 23.5462C27.0227 24.9303 25.9352 26.0178 24.5511 26.0178Z"
            fill="#FFCB5B"
          />
          <path
            d="M24.5508 26.9538C26.4786 27.0032 28.011 25.4214 28.011 23.543H27.9616C27.7144 23.543 27.5167 23.7407 27.4673 23.9879C27.3684 24.6799 27.0224 25.3225 26.5281 25.7674C26.1326 26.1629 25.5889 26.41 24.9957 26.5089C24.7485 26.5089 24.5508 26.7066 24.5508 26.9538Z"
            fill="#F6B545"
          />
          <path
            d="M22.4261 23.8879C22.4261 22.5038 23.5136 21.4163 24.8977 21.4163C25.4909 21.4163 26.0346 21.6141 26.4795 22.0095C25.9852 21.4163 25.3426 21.0703 24.5517 21.0703C23.1676 21.0703 22.0801 22.1578 22.0801 23.5419C22.0801 24.3328 22.4261 24.9754 22.9698 25.4203C22.6238 25.0249 22.4261 24.4811 22.4261 23.8879Z"
            fill="#F19920"
          />
          <path
            d="M24.5519 25.0284C24.2553 25.0284 24.0576 24.8306 24.0576 24.5341V22.5568C24.0576 22.2602 24.2554 22.0625 24.5519 22.0625C24.8485 22.0625 25.0462 22.2602 25.0462 22.5568V24.5341C25.0462 24.8306 24.8485 25.0284 24.5519 25.0284Z"
            fill="#F19920"
          />
          <path
            d="M26.3804 26.4623C26.0344 25.2265 25.1446 24.2378 24.0077 23.793C23.8099 22.656 23.0685 21.6674 22.1293 21.0742C21.5361 21.7168 21.1406 22.5572 21.1406 23.4964C21.1406 25.4242 22.673 26.9566 24.6009 26.9566C25.2435 27.006 25.8367 26.8083 26.3804 26.4623Z"
            fill="#F19920"
          />
          <path
            d="M22.9689 25.4222C22.6229 24.9773 22.3757 24.4336 22.3757 23.8404C22.3757 23.1483 22.6723 22.5057 23.1666 22.0608C23.0677 21.9125 22.9689 21.8137 22.87 21.7148C22.3757 22.1597 22.0791 22.8023 22.0791 23.5438C22.0791 24.3347 22.4251 24.9773 22.9689 25.4222Z"
            fill="#E78825"
          />
          <path
            d="M24.0566 23.8398V24.5319C24.0566 24.8285 24.2544 25.0262 24.5509 25.0262C24.8475 25.0262 25.0452 24.8284 25.0452 24.5319V24.433C24.7487 24.1858 24.4027 23.9881 24.0566 23.8398Z"
            fill="#E78825"
          />
          <path
            d="M26.38 26.4598C26.2812 26.1138 26.1823 25.8172 25.9846 25.5206C25.5891 25.8172 25.0948 26.0149 24.5511 26.0149C23.167 26.0149 22.0795 24.9274 22.0795 23.5433C22.0795 22.8018 22.3761 22.1592 22.8704 21.7143C22.6232 21.4671 22.3761 21.2694 22.0795 21.1211C21.4863 21.7637 21.0908 22.604 21.0908 23.5432C21.0908 25.4711 22.6232 27.0035 24.5511 27.0035C25.2431 27.0035 25.8363 26.8058 26.38 26.4598Z"
            fill="#F6B545"
          />
          <path
            d="M26.2315 26.0156C25.8855 26.2628 25.4406 26.4111 24.9957 26.5099C24.7485 26.5594 24.5508 26.7571 24.5508 27.0042C25.2428 27.0042 25.836 26.8065 26.3798 26.4605C26.3304 26.3122 26.2809 26.1639 26.2315 26.0156Z"
            fill="#F19920"
          />
          <path
            d="M22.0795 21.1211C21.4863 21.7637 21.0908 22.604 21.0908 23.5432H21.1403C21.3874 23.5432 21.5852 23.3455 21.6346 23.0983C21.7334 22.4063 22.0795 21.8131 22.5243 21.3682C22.3761 21.3188 22.2278 21.2199 22.0795 21.1211Z"
            fill="#FFCB5B"
          />
          <path
            d="M20.1028 27.9908C22.0139 27.9908 23.5631 26.4416 23.5631 24.5306C23.5631 22.6195 22.0139 21.0703 20.1028 21.0703C18.1918 21.0703 16.6426 22.6195 16.6426 24.5306C16.6426 26.4416 18.1918 27.9908 20.1028 27.9908Z"
            fill="#F6B545"
          />
          <path
            d="M19.6074 27.0032C19.6074 27.3492 19.6569 27.6458 19.7557 27.9919C19.8546 27.9919 20.0029 27.9919 20.1017 27.9919C22.0296 27.9919 23.562 26.4595 23.562 24.5316C23.562 24.1856 23.5125 23.889 23.4137 23.543C23.3148 23.543 23.1665 23.543 23.0677 23.543C21.1398 23.543 19.6074 25.0754 19.6074 27.0032Z"
            fill="#F19920"
          />
          <path
            d="M20.1028 21.0703C18.175 21.0703 16.6426 22.6027 16.6426 24.5306C16.6426 26.4584 18.175 27.9908 20.1028 27.9908C22.0307 27.9908 23.5631 26.4584 23.5631 24.5306C23.5631 22.6028 22.0307 21.0703 20.1028 21.0703ZM20.1028 27.0021C18.7187 27.0021 17.6312 25.9146 17.6312 24.5305C17.6312 23.1464 18.7187 22.0589 20.1028 22.0589C21.4869 22.0589 22.5744 23.1464 22.5744 24.5305C22.5744 25.9146 21.4869 27.0021 20.1028 27.0021Z"
            fill="#FFCB5B"
          />
          <path
            d="M20.1025 27.942C22.0304 27.9915 23.5628 26.4097 23.5628 24.5312H23.5133C23.2662 24.5312 23.0684 24.729 23.019 24.9761C22.9202 25.6682 22.5741 26.3108 22.0798 26.7557C21.6844 27.1511 21.1406 27.3983 20.5474 27.4972C20.3003 27.4972 20.1025 27.6949 20.1025 27.942Z"
            fill="#F6B545"
          />
          <path
            d="M17.9769 24.8762C17.9769 23.4921 19.0644 22.4046 20.4485 22.4046C21.0417 22.4046 21.5854 22.6023 22.0303 22.9978C21.536 22.4046 20.8934 22.0586 20.1025 22.0586C18.7184 22.0586 17.6309 23.1461 17.6309 24.5302C17.6309 25.3211 17.9769 25.9637 18.5206 26.4086C18.1747 26.0131 17.9769 25.4694 17.9769 24.8762Z"
            fill="#F19920"
          />
          <path
            d="M21.5857 26.0647C21.5363 26.0647 21.4869 26.0647 21.4374 26.0153C21.3386 25.9164 21.3386 25.7681 21.4374 25.6693C21.734 25.3727 21.8823 24.9278 21.8823 24.5323C21.8823 24.384 21.9812 24.2852 22.1295 24.2852C22.2778 24.2852 22.3766 24.384 22.3766 24.5323C22.3766 25.0761 22.1789 25.6198 21.8329 26.0153C21.734 26.0647 21.6351 26.0647 21.5857 26.0647Z"
            fill="#FFCB5B"
          />
          <path
            d="M20.1017 26.0166C19.8051 26.0166 19.6074 25.8189 19.6074 25.5223V23.5451C19.6074 23.2485 19.8052 23.0508 20.1017 23.0508C20.3983 23.0508 20.596 23.2485 20.596 23.5451V25.5223C20.5961 25.8189 20.3983 26.0166 20.1017 26.0166Z"
            fill="#F19920"
          />
          <path
            d="M22.3756 23.5924C22.4744 23.889 22.5733 24.1856 22.5733 24.5316C22.5733 25.9157 21.4858 27.0032 20.1017 27.0032C19.9534 27.0032 19.7557 27.0032 19.6074 26.9538V27.0032C19.6074 27.3492 19.6569 27.6458 19.7557 27.9919C19.8546 27.9919 20.0029 27.9919 20.1017 27.9919C22.0296 27.9919 23.562 26.4595 23.562 24.5316C23.562 24.1856 23.5125 23.889 23.4137 23.543C23.3148 23.543 23.1665 23.543 23.0677 23.543C22.8204 23.543 22.6227 23.543 22.3756 23.5924Z"
            fill="#F6B545"
          />
          <path
            d="M22.5735 30.9557C24.4846 30.9557 26.0338 29.4064 26.0338 27.4954C26.0338 25.5844 24.4846 24.0352 22.5735 24.0352C20.6625 24.0352 19.1133 25.5844 19.1133 27.4954C19.1133 29.4064 20.6625 30.9557 22.5735 30.9557Z"
            fill="#F6B545"
          />
          <path
            d="M22.5735 24.0352C20.6457 24.0352 19.1133 25.5676 19.1133 27.4954C19.1133 29.4233 20.6457 30.9556 22.5735 30.9556C24.5014 30.9556 26.0338 29.4233 26.0338 27.4954C26.0338 25.5676 24.5014 24.0352 22.5735 24.0352ZM22.5735 29.967C21.1894 29.967 20.1019 28.8795 20.1019 27.4954C20.1019 26.1113 21.1894 25.0238 22.5735 25.0238C23.9576 25.0238 25.0451 26.1113 25.0451 27.4954C25.0451 28.8795 23.9576 29.967 22.5735 29.967Z"
            fill="#FFCB5B"
          />
          <path
            d="M22.5742 30.9069C24.5021 30.9563 26.0345 29.3745 26.0345 27.4961H25.985C25.7379 27.4961 25.5401 27.6938 25.4907 27.941C25.3919 28.633 25.0458 29.2756 24.5515 29.7205C24.1561 30.116 23.6123 30.3631 23.0191 30.462C22.772 30.462 22.5742 30.6597 22.5742 30.9069Z"
            fill="#F6B545"
          />
          <path
            d="M20.4486 27.841C20.4486 26.4569 21.5361 25.3694 22.9202 25.3694C23.5134 25.3694 24.0571 25.5672 24.502 25.9626C24.0077 25.3694 23.365 25.0234 22.5741 25.0234C21.19 25.0234 20.1025 26.1109 20.1025 27.495C20.1025 28.2859 20.4485 28.9285 20.9923 29.3734C20.6463 28.978 20.4486 28.4342 20.4486 27.841Z"
            fill="#F19920"
          />
          <path
            d="M22.5744 28.9815C22.2778 28.9815 22.0801 28.7838 22.0801 28.4872V26.5099C22.0801 26.2133 22.2778 26.0156 22.5744 26.0156C22.871 26.0156 23.0687 26.2134 23.0687 26.5099V28.4872C23.0687 28.7838 22.871 28.9815 22.5744 28.9815Z"
            fill="#F19920"
          />
        </g>
        <defs>
          <clipPath id="clip0_8856_139086">
            <rect
              width="21.75"
              height="21.75"
              fill="white"
              transform="translate(7.25 10.1953)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CashIcon;
