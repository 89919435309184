import React from 'react';
import type { IIconProps } from '@/types/common-types';

const QRIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 20 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M6.75 3.00098H3.75C3.33579 3.00098 3 3.33676 3 3.75098V6.75098C3 7.16519 3.33579 7.50098 3.75 7.50098H6.75C7.16421 7.50098 7.5 7.16519 7.5 6.75098V3.75098C7.5 3.33676 7.16421 3.00098 6.75 3.00098Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.25 12.749V12.7565"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.25 3.00098H11.25C10.8358 3.00098 10.5 3.33676 10.5 3.75098V6.75098C10.5 7.16519 10.8358 7.50098 11.25 7.50098H14.25C14.6642 7.50098 15 7.16519 15 6.75098V3.75098C15 3.33676 14.6642 3.00098 14.25 3.00098Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.25 5.25098V5.25848"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.75 10.499H3.75C3.33579 10.499 3 10.8348 3 11.249V14.249C3 14.6632 3.33579 14.999 3.75 14.999H6.75C7.16421 14.999 7.5 14.6632 7.5 14.249V11.249C7.5 10.8348 7.16421 10.499 6.75 10.499Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.75 5.25098V5.25848"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 10.499H12.75"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 10.499V10.5065"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 10.499V12.749"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 14.999H12.75"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.75 12.749H15"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 12.749V14.999"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default QRIcon;
