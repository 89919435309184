import { useTranslation } from 'react-i18next';
import DataTimeOutIcon from '../terminal-icons/data-timeout-icon';

interface NetworkErrorProps {
    refresh: () => void;
    noPadding?: boolean
}

const NetworkError: React.FC<NetworkErrorProps> = (props) => {
    const { refresh, noPadding } = props;
    const { t } = useTranslation(["extended_service"]);

    return (
        <div>
            <div className={`bg-[#F5F5F5] flex-1 hidden_scrollbar`}>
                <div className={`${noPadding ? '' : 'p-5'}`}>
                    <div
                        className={`h-[91vh] flex flex-col bg-primary-background-color mb-4  justify-center items-center rounded-2xl mt-4`}
                    >
                        <DataTimeOutIcon className="mb-4" />
                        <div className="my-4 text-[#000000] text-xl text-center font-semibold">
                            {t('extended_service:unable_to_display_data')}
                        </div>
                        <div className="text-base text-center w-[90%] font-normal">
                            {t('extended_service:network_tip')}
                        </div>
                        <div className='mt-4 h-[44px] w-[79px] bg-[#0094FF] rounded-xl text-[white] text-center leading-[44px] font-semibold text-base' onClick={refresh}> {t('common:refresh')}</div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default NetworkError;
