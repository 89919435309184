import React, { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { TableRecord } from '@/components/table/interface';
import { getCookie } from '@/utils';
import { Constants } from '@/constants';
import api from '@/libs/api';
import Config from '@/libs/config';
import Table from '@/components/table';
import { useNavigate, useParams } from 'react-router-dom';

interface IPendingForApprovalProps {
  columns: ColumnsType<TableRecord>;
  businessID: string;
  viewId: string;
  loading: boolean;
}

const PendingForApproval: React.FC<IPendingForApprovalProps> = (props) => {
  const { businessID, columns, loading } = props;
  const navigate = useNavigate();
  const { lang = 'en' } = useParams();

  const [records, setRecords] = useState<TableRecord[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [recordLoading, setRecordLoading] = useState<boolean>(true);
  const [loadingAgain, setLoadingAgain] = useState(loading);

  useEffect(() => {
    loadRecords(page, pageSize);
    setLoadingAgain(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessID, loading !== loadingAgain]);

  const loadRecords = async (page = 1, pageSize = 50) => {
    setRecordLoading(true);
    const token = getCookie(Constants.TOKEN) || '';
    try {
      const resp = await api.get(
        `${
          Config.urls.gatewayUrl
        }/api/treasury-services/api/payout_requests?type=waiting_approve&limit=${
          pageSize + 1
        }&offset=${(page - 1) * pageSize}`,
        {
          headers: {
            'x-user-access-token': token,
            'x-p-business-id': businessID,
          },
        }
      );
      const { data } = resp || {};
      if (data && Array.isArray(data)) {
        setRecords(data);
      }
    } catch (error) {
      console.error(error);
    }
    setPage(page);
    setPageSize(pageSize);
    setRecordLoading(false);
  };

  const hadleView = (record: any) => {
    navigate(`/${lang}/businesses/${businessID}/payouts/approval/${record.id}`);
  };

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <Table
        className="my-6"
        columns={columns}
        records={records}
        loadingData={recordLoading}
        pagination={
          records.length > 0
            ? {
                current: page,
                currentSize: records.length,
                pageSize: pageSize,
                changePageCallback: loadRecords,
              }
            : undefined
        }
        onRowCell={(record, dataKey) => {
          return {
            onClick: (evt) => {
              evt.stopPropagation();
              if (dataKey === 'action') return;
              if (record.id) {
                hadleView(record);
              }
            },
          };
        }}
      />
    </div>
  );
};

export default PendingForApproval;
