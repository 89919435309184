import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import BackIcon from '@/components/svg/back-icon';
import UpArrowIcon from '../payouts-icons/up-arrow-icon';
import UpArrowDashedIcon from '../payouts-icons/up-arrow-dashed-icon';
import PayoutDetails from './payout-details';
import SupportingDocuments from './supporting-documents';
import dayjs from 'dayjs';
import WarningIcon from '@/components/svg/warning-icon';
import TipsIcon from '@/components/svg/tips-icon';
import { ICategory, IPayout, ISupportingDocuments } from '@/types/payouts-type';
import { getInitSupportingDocuments, getLicense, getValueMultilingual, handleMount, initPayout } from '@/utils/payouts-utils';
import { useTranslation } from 'react-i18next';
import { Constants } from '@/constants';
import { getCookie } from '@/utils';
import { exportPDF, getBusiness, getCategories, getPayout } from '@/data/payouts';
import api from '@/libs/api';
import Config from '@/libs/config';
import { Alert } from '@/components/common/alert/alert';
import Button from '@/components/common/button';
import PayeeDetails from '../components/payee-details';
import ApprovePayout from '../components/approve-payout';
import RejectionPayout from '../components/rejection-payout';
import CancelPayout from '../components/cancel-payout';
import AntdModal from '@/components/common/antd/modal';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IReducersState } from '@/reducers';
import { useDispatch, useSelector } from 'react-redux';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';

const PayoutsDetailsPage = () => {
  const { lang = 'en', p_business_id: businessID = '', payout_id: recordId = '', type: activekey = '' } = useParams();
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isEdit = searchParams.get('isEdit') || '';
  const permissions = useSelector((state: IReducersState) => state.businessState.permissions);
  const user = useSelector((state: IReducersState) => state.account.loginUser);

  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(isEdit !== 'true');
  const [showTips, setShowTips] = useState<boolean>(false);
  const [showApprove, setShowApprove] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [payout, setPayout] = useState<IPayout>(initPayout);

  useEffect(() => {
    loadingPayout();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadingData();
    // eslint-disable-next-line
  }, [businessID, lang]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: ct('payouts'),
          key: '1',
          type: 'label',
        },
        {
          name: ct('payouts'),
          key: '2',
          type: 'link',
          url: `/${lang}/businesses/${businessID}/payouts/${activekey}`,
        },
        {
          name: t('transfer_details'),
          key: '3',
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, activekey])

  const {
    id,
    payout_category_id,
    payout_category_name,
    payout_category_icon,
    payout_category_color,
    transfer_status,
    total_amount,
    currency,
    destination_amount,
    destination_currency,
    payee,
    i18n,
    refund_payin_id,
  } = payout || {};
  const [selectCategory, setSelectCategory] = useState<ICategory>({
    id: payout_category_id,
    category_name: payout_category_name,
    icon_file: payout_category_icon,
    color: payout_category_color,
  });
  const initData = getInitSupportingDocuments(payout);
  const [data, setData] = useState<ISupportingDocuments>(initData);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const { t } = useTranslation('payouts');
  const { t: ct } = useTranslation('common');
  
  const { canApprove, canCancel, canEdit, canReject, canExport } = getLicense(permissions, payout, user.id);;

  const handleBack = () => {
    if (!isView) {
      if (
        initData.poNumber !== data.poNumber ||
        initData.reference !== data.reference ||
        initData.remark !== data.remark ||
        initData.invoiceFiles.length !== data.invoiceFiles.length ||
        initData.poFiles.length !== data.poFiles.length ||
        selectCategory.id !== payout_category_id
      ) {
        setShowTips(true);
        return;
      }
      for (let i = 0; i < data.invoiceFiles.length; i++) {
        if (data.invoiceFiles[i].id !== initData.invoiceFiles[i].id) {
          setShowTips(true);
          return;
        }
      }
      for (let i = 0; i < data.poFiles.length; i++) {
        if (data.poFiles[i].id !== initData.poFiles[i].id) {
          setShowTips(true);
          return;
        }
      }
      setIsView(true);
      return;
    }
    navigate(-1);
  };

  const loadingPayout = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    const resp = await getPayout(recordId, businessID, token);
    setShowApprove(false);
    setShowReject(false);
    setShowCancel(false);
    if (resp && resp.id) {
      setPayout(resp);
      setData(getInitSupportingDocuments(resp));
      setSelectCategory({
        id: resp.payout_category_id,
        category_name: resp.payout_category_name,
        icon_file: resp.payout_category_icon,
        color: resp.payout_category_color,
      });
    } else {
      navigate(`/${lang}/businesses/${businessID}/payouts/${activekey}`);
    }
  };

  const handleSave = async () => {
    setSaveLoading(true);
    const { attachment_files } = payout;
    const { invoiceFiles, poFiles, poNumber, reference, remark } = data;
    const add_attachment_files: {
      attachment_file: string;
      type: string;
    }[] = [];
    const del_attachment_files: string[] = [];
    if (Array.isArray(attachment_files)) {
      for (let i = 0; i < attachment_files.length; i++) {
        const file = attachment_files[i];
        let isDelete = true;
        if (file.out_type === 'Invoice') {
          for (let n = 0; n < invoiceFiles.length; n++) {
            const item = invoiceFiles[n];
            if (file.id === item.id) {
              isDelete = false;
              break;
            }
          }
        } else if (file.out_type === 'Purchase Order') {
          for (let n = 0; n < poFiles.length; n++) {
            const item = poFiles[n];
            if (file.id === item.id) {
              isDelete = false;
              break;
            }
          }
        }
        if (isDelete) {
          del_attachment_files.push(file.id);
        }
      }
    }

    for (let i = 0; i < invoiceFiles.length; i++) {
      const item = invoiceFiles[i];
      if (!item.directusID) {
        add_attachment_files.push({
          attachment_file: item.id,
          type: 'Invoice',
        });
      }
    }

    for (let i = 0; i < poFiles.length; i++) {
      const item = poFiles[i];
      if (!item.directusID) {
        add_attachment_files.push({
          attachment_file: item.id,
          type: 'Purchase Order',
        });
      }
    }
    const body = {
      add_attachment_files,
      del_attachment_files,
      payout_category_id: selectCategory.id,
      payout_request_id: id,
      po_number: poNumber,
      reference,
      remark,
    };
    const token = getCookie(Constants.TOKEN) || '';
    const resp = await api.post(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/payout_requests/pending_update`,
      body,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'Content-Type': 'application/json',
        },
      }
    );
    if (resp && resp.data && resp.data.payout_request_id === id) {
      Alert.success({
        message: t('payout_edited'),
        position: 'default',
        wrapClass: 'top-px',
      });
      if (isEdit) {
        navigate(`/${lang}/businesses/${businessID}/payouts/${activekey}`);
      } else {
        await loadingPayout();
        setIsView(true);
      }
    } else {
      Alert.error({
        message: t('payout_edit_failed'),
        position: 'default',
        wrapClass: 'top-px',
      });
    }
    setSaveLoading(false);
  };

  const handleExport = async () => {
    setExportLoading(true);
    const token = getCookie(Constants.TOKEN) || '';
    const business = await getBusiness(businessID, token);
    const { url } = await exportPDF(businessID, payout, business);
    let success = true;
    try {
      await fetch(url).then((res) =>
        res.blob().then((blob) => {
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = `Payout_Transfer_Notice_${dayjs().format(
            'YYYY-MM-DD'
          )}_${businessID.slice(-4)}.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
        })
      );
    } catch (error) {
      console.error(error);
      success = false;
    }
    if (success) {
      Alert.success({
        message: t('payout_export_successful'),
        position: 'default',
        wrapClass: 'top-px',
      });
    } else {
      Alert.error({
        message: t('payout_export_failed'),
        position: 'default',
        wrapClass: 'top-px',
      });
    }
    setExportLoading(false);
  };

  const loadingData = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    const resp = await getCategories(token, lang);
    if (Array.isArray(resp)) {
      for (let i = 0; i < resp.length; i++) {
        const item = resp[i];
        if (item.id === selectCategory.id) {
          setSelectCategory(item);
          break;
        }
      }
    }
    setCategories(resp);
  };

  return (
    <div className="bg-system-background-color">
      <Transition.Root
        show={true}
        appear={true}
        className="p-0 md:p-2 lg:px-4 lg:py-2"
      >
        <Transition.Child
          className="rounded overflow-hidden h-fit"
          enter="duration-350 transform transition ease-in-out"
          enterFrom="-translate-x-1 translate-y-10 opacity-40"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="duration-500 transform transition"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="flex mb-4 md:mb-6 justify-between pt-4 mx-4">
            <div className="flex">
              <div className="bg-disabled-color shadow-box-drop rounded-full w-10 h-10 flex items-center justify-center cursor-pointer">
                <BackIcon
                  className="hover:text-primary-color"
                  onClick={handleBack}
                />
              </div>
              <div className="hidden md:block text-3xl leading-10 font-bold text-color-text-1 ml-2.5">
                {t('transfer_details')}
              </div>
            </div>
            <div className="flex space-x-4">
              {!isView && (
                <>
                  <Button
                    styleType="Border"
                    onClick={handleBack}
                    loading={saveLoading}
                  >
                    {ct('cancel')}
                  </Button>
                  <Button onClick={handleSave}>{ct('save')}</Button>
                </>
              )}
              {isView && (
                <>
                  {canExport && (
                    <Button
                      styleType={'Border'}
                      onClick={handleExport}
                      className="px-3"
                      loading={exportLoading}
                    >
                      {t('save_payout_notice')}
                    </Button>
                  )}
                  {canEdit && (
                    <Button
                      styleType={'Secondary'}
                      onClick={() => setIsView(false)}
                      className="px-3"
                    >
                      {ct('edit')}
                    </Button>
                  )}
                  {canCancel && (
                    <Button
                      styleType={'Secondary Danger'}
                      onClick={() => setShowCancel(true)}
                      className="px-3"
                    >
                      {t('cancel_payout')}
                    </Button>
                  )}
                  {canReject && (
                    <Button
                      styleType={'Secondary Danger'}
                      onClick={() => setShowReject(true)}
                      className="px-3"
                    >
                      {t('reject')}
                    </Button>
                  )}
                  {canApprove && (
                    <Button
                      onClick={() => setShowApprove(true)}
                      className="px-3"
                    >
                      {t('approve')}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </Transition.Child>
        <Transition.Child
          className="rounded overflow-hidden h-fit"
          enter="duration-450 transform transition ease-in-out"
          enterFrom="-translate-x-1 translate-y-10 opacity-40"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="duration-500 transform transition"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="p-6 rounded bg-white flex mb-6 shadow-card-drop mx-4 items-center">
            <div
              className="flex items-center mr-6 w-12 h-12 rounded-full p-2.5"
              style={{ backgroundColor: payout_category_color }}
            >
              {payout_category_icon ? (
                <img
                  width={28}
                  height={28}
                  src={`${Config.urls.gatewayUrl}/api/directus/treasury-services/files/${payout_category_icon}.svg`}
                  alt=""
                />
              ) : (
                ''
              )}
            </div>
            <div>
              <div className="flex items-center">
                <UpArrowIcon className="mr-2" />
                <div
                  className={`font-bold text-3xl ${
                    transfer_status === 'Reversed'
                      ? 'text-color-text-2 line-through'
                      : 'text-color-text-1'
                  }`}
                >
                  {handleMount(total_amount, 2, true)}
                </div>
                <div className="ml-1 text-color-text-1 text-3xl">
                  {currency}
                </div>
              </div>
              <div className="text-sm text-color-text-2 mt-2">
                {t('billing_amount')}
              </div>
            </div>
            <div className="border-border-color border-r mx-10 h-[60px]"></div>
            <div>
              <div className="flex flex-1 items-center">
                <UpArrowDashedIcon className="mr-2" />
                <div
                  className={`font-bold text-3xl ${
                    transfer_status === 'Reversed'
                      ? 'text-color-text-2 line-through'
                      : 'text-color-text-1'
                  }`}
                >
                  {handleMount(destination_amount, 2, true)}
                </div>
                <div className="ml-1 text-color-text-1 text-3xl">
                  {destination_currency}
                </div>
              </div>
              <div className="text-sm text-color-text-2 mt-2">
                {t('transfer_amount')}
              </div>
            </div>
            {transfer_status === 'Refunded' && refund_payin_id && (
              <>
                <div className="border-border-color border-r mx-10 h-[60px]"></div>
                <div className="flex items-center">
                  <TipsIcon size={16} className="text-primary-color mr-2" />
                  <div className="text-sm text-color-text-1 max-w-[185px] font-medium cursor-default">
                    {t('payouts_refunded', {
                      currency: destination_currency,
                    })}
                    <br />
                    <label
                      className="font-extrabold text-primary-color cursor-pointer"
                      onClick={() => {
                        navigate(
                          `/${lang}/businesses/${businessID}/account-balance/transactions/payin/${refund_payin_id}`
                        );
                      }}
                    >
                      {t('click_here')}
                    </label>
                    {t('to_view')}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="p-6 rounded bg-white mb-6 shadow-card-drop mx-4">
            <div className="font-bold text-xl text-color-text-1">
              {t('payee')}
            </div>
            <div className="mt-4 border-b border-border-color" />
            {payee && (
              <PayeeDetails
                showAvatar={false}
                payee={payee}
                gridColes="grid-cols-4"
                className="pt-2"
                bgShow={false}
              />
            )}
          </div>

          <PayoutDetails
            payout={payout}
            categories={categories}
            selectCategory={selectCategory}
            setSelectCategory={setSelectCategory}
            isView={isView}
            data={data}
            setData={setData}
          />

          <SupportingDocuments
            isView={isView}
            data={data}
            setData={setData}
          />
          {showApprove && (
            <ApprovePayout
              payout={payout}
              businessID={businessID}
              callback={loadingPayout}
              onReject={() => {
                setShowApprove(false);
                setShowReject(true);
              }}
              onCancel={() => setShowApprove(false)}
            />
          )}
          {showReject && (
            <RejectionPayout
              payout={payout}
              businessID={businessID}
              callback={loadingPayout}
              onCancel={() => setShowReject(false)}
            />
          )}
          {showCancel && (
            <CancelPayout
              payout={payout}
              businessID={businessID}
              callback={loadingPayout}
              onCancel={() => setShowCancel(false)}
            />
          )}
          <AntdModal
            open={showTips}
            size="small"
            footer={null}
            onCancel={() => setShowTips(false)}
          >
            <div className="flex flex-col items-center justify-center text-color-text-1 font-medium p-10 pt-8">
              <div className="p-5 rounded-full bg-warning-color/10">
                <WarningIcon
                  className={`text-warning-color text-3xl`}
                  size={40}
                />
              </div>
              <div className="text-center font-bold text-xl pt-4">
                {t('unsaved_changes')}
              </div>
              <div className="text-center pt-2 text-sm">
                {t('change_tips')}
              </div>
              <Button
                className="w-full text-base mt-6"
                onClick={() => setShowTips(false)}
              >
                {t('keep_editing')}
              </Button>
              <Button
                styleType={'Border'}
                className="w-full mt-4 text-base"
                onClick={() => {
                  setShowTips(false);
                  setData(initData);
                  setSelectCategory({
                    id: payout_category_id,
                    category_name: getValueMultilingual(
                      payout_category_name,
                      i18n,
                      lang
                    ),
                    icon_file: payout_category_icon,
                    color: payout_category_color,
                  });
                  setIsView(true);
                }}
              >
                {t('discard_changes')}
              </Button>
            </div>
          </AntdModal>
        </Transition.Child>
      </Transition.Root>
    </div>
  );
};

export default PayoutsDetailsPage;
