import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import PayinHistory from './payin-history';
import PayinByTransferType from './payin-by-transfer-type';
import PayinByTransferMethods from './payin-by-transfer-methods';
import SummaryByPaymentMethods from './summary-by-payment-methods';
import BreakdownOfPayinByPayer from './breakdown-of-payin-by-payer';
import ProjectedPayinForecast from './projected-payin-forecast';

interface IPayinsReportsProps {
  records: any[];
  currency: string;
  loading: boolean;
  startDate: string;
  endDate: string;
  businessID: string;
}

const PayinsReports: React.FC<IPayinsReportsProps> = (props) => {
  const {
    records = [],
    currency,
    startDate,
    endDate,
    loading,
    businessID,
  } = props;

  const baseProps = {
    loading,
    startDate,
    endDate,
    currency,
    businessID,
  };

  return (
    <Transition.Root show={true} as={Fragment} appear={true}>
      <div className="grid grid-cols-1 gap-6 mt-6 md:grid-cols-2">
        <PayinHistory {...baseProps} data={records[0]} />

        <PayinByTransferType {...baseProps} data={records[1]} />

        <PayinByTransferMethods {...baseProps} data={records[2]} />

        <SummaryByPaymentMethods {...baseProps} data={records[3]} />

        <BreakdownOfPayinByPayer {...baseProps} data={records[4]} />

        <ProjectedPayinForecast {...baseProps} data={records[5]} />
      </div>
    </Transition.Root>
  );
};

export default PayinsReports;
