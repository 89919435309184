import { IBaseCartProps } from '@/types/analytics-type';
import { handleNumber } from '@/utils/field-utils';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TransitionNode from '../../components/transition-node';
import MoreIcon from '../../analytics-icons/more-icon';
import Skeleton from '@/components/skeleton';
import PieChartSkeleton from '../../components/pie-chart-skeleton';
import NodataContent from '../../components/no-data-content';
import PayoutTransactionsDetails from '../../components/payout-transactions-details';
import { IPayoutsReportsTypes } from '@/data/analytics';
import { getColorByTransactionMethods } from '@/utils/analytics-utils';

const PayoutByTransferMethods: React.FC<IBaseCartProps> = (props) => {
  const {
    data,
    currency,
    startDate,
    endDate,
    businessID,
    loading: loadAllData,
  } = props;

    const { t } = useTranslation('analytics');

  const { points = [], total_amount = 0 } = data || {};

  const [showMore, setShowMore] = useState<boolean>(false);
  const [options, setOptions] = useState<Highcharts.Options>({
    title: {
      text: undefined,
    },
  });

  const total = handleNumber(total_amount);
  const title = t('analytics:transactions_summary_by_payment_methods');
  const disabled = !points || points.length === 0;

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getOptions = () => {
    const pieData: any[] = [];

    points?.forEach((point) => {
      const { y_axis, name, type } = point;
      if (name) {
        const value = Math.abs(y_axis);
        const item: any = {
          name: name,
          y: value,
          color: getColorByTransactionMethods(type),
        };

        if (y_axis < 0) {
          item.isNegative = true;
        }
        pieData.push(item);
      }
    });

    const options: Highcharts.Options = {
      chart: {
        type: 'pie',
        borderWidth: 0,
        style: {
          fontFamily: 'Roboto',
        },
      },
      title: {
        text: undefined,
      },
      tooltip: {
        formatter: function () {
          const { y, point, key } = this as any;
          const total = Math.abs(total_amount);
          let percentage = '0';
          if (total !== 0) {
            percentage = ((y / total) * 100).toFixed(2);
          }
          if (point.isNegative) {
            percentage = '-' + percentage + '%';
          } else {
            percentage = percentage + '%';
          }
          return (
            '<b>' + key + '</b><br/>' + percentage + '<br/>' + handleNumber(y)
          );
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          innerSize: '50%',
          dataLabels: {
            enabled: true,
            distance: 20,
            formatter: function () {
              const { y, point } = this as any;
              const total = Math.abs(total_amount);
              let percentage = '0';
              if (total !== 0) {
                percentage = ((y / total) * 100).toFixed(2);
              }
              if (point.isNegative) {
                percentage = '-' + percentage + '%';
              } else {
                percentage = percentage + '%';
              }
              return percentage;
            },
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: 'Sales',
          data: pieData,
          type: 'pie',
        },
      ],
      accessibility: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
    };

    setOptions(options);
  };

  const handleMore = async () => {
    if (!disabled) {
      setShowMore(true);
    }
  };

  const handleCancel = () => {
    setShowMore(false);
  };

  return (
    <TransitionNode className="h-fit mt-6">
      <div className="px-6 py-6 md:px-9 flex flex-col h-full">
        <div className="flex justify-between mb-6">
          <div className="font-bold text-xl text-color-text-1">{title}</div>
          <div
            onClick={handleMore}
            className={`p-2 rounded-full h-8 w-8 ${
              disabled
                ? 'bg-disabled-color text-icon-color'
                : 'cursor-pointer bg-opacity-10 hover:bg-opacity-100 hover:text-color-text-6 text-primary-color bg-primary-color'
            }`}
          >
            <MoreIcon />
          </div>
        </div>
        <div className="flex flex-col mb-4">
          {loadAllData && (
            <div className="h-[45px] flex items-center">
              <Skeleton
                className="h-auto w-48"
                skeletonItemClass="h-6 rounded-2xl"
                row={1}
              />
            </div>
          )}
          {!loadAllData && (
            <div className="text-[30px] text-color-text-1">
              <span className="font-bold mr-2">
                {handleNumber(total_amount)}
              </span>
              <span>{currency}</span>
            </div>
          )}
          <div className="text-color-text-2 font-medium">
            {t('analytics:total_amount')}
          </div>
        </div>
        {loadAllData && <PieChartSkeleton />}
        {!loadAllData && !disabled && (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}
        {!loadAllData && disabled && <NodataContent />}
        <PayoutTransactionsDetails
          visible={showMore}
          total={`${
            total.indexOf('-') === -1 ? `+${total}` : total
          } ${currency}`}
          startDate={startDate}
          endDate={endDate}
          onCancel={handleCancel}
          businessID={businessID}
          currency={currency}
          type={IPayoutsReportsTypes.PAYOUT_TRANSFER_METHOD}
        />
      </div>
    </TransitionNode>
  );
};

export default PayoutByTransferMethods;
