import React from 'react';
import type { IIconProps } from '@/types/common-types';

const RedTipIcon: React.FC<IIconProps> = (props) => {
    const { className = '', onClick } = props;
    return (
        <div
            className={`${className}`}
            onClick={onClick}
        >
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="14" height="14" rx="7" fill="#FC2E01" />
                <rect x="6.125" y="5.25" width="1.75" height="6.125" rx="0.875" fill="white" />
                <rect x="6.125" y="2.625" width="1.75" height="1.75" rx="0.875" fill="white" />
            </svg>

        </div>
    );
};

export default RedTipIcon;
