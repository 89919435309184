import type { IAccountBalanceCurrentAccount } from '@/types/account-balance-type';
import { Transition } from '@headlessui/react';
import AccountBalanceItem from '../../account-balance-item';
import AccountBalanceCurrentUpcomimg from './account-balance-upcoming';
import AccountBalanceCurrentActivities from './account-balance-current-activities';
import { transformField } from '@/utils/field-utils';
import { useTranslation } from 'react-i18next';

const AccountBalanceCurrentAccount = (props: IAccountBalanceCurrentAccount) => {
  const { storeLedgerAccount, initLoading } = props;
  const { t } = useTranslation('account_balance');

  const {
    current_total_with_business_default_currency = {},
    default_currency = {},
  } = storeLedgerAccount || {};
  const {
    available = '',
    incoming = '',
    outgoing = '',
  } = current_total_with_business_default_currency;
  const { currency_code: currency = 'HKD' } = default_currency;

  return (
    <>
      <Transition.Root show={true} appear={true}>
        <div className="flex w-full mt-4 flex-col space-y-4 md:flex-row md:space-x-6 md:space-y-0 md:mt-6">
          <Transition.Child
            className="flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color h-fit p-4 md:p-6"
            enter="duration-350 transform transition ease-in-out"
            enterFrom=" -translate-x-1 translate-y-6 opacity-60"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="flex flex-row space-x-6">
              <div className="flex-1">
                <AccountBalanceItem
                  mount={available}
                  subTitle={t('account_balance:current_balance')}
                  balance={currency}
                  loading={initLoading}
                />
              </div>
            </div>
          </Transition.Child>

          <Transition.Child
            className="flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color h-fit p-4 md:p-6"
            enter="duration-450 transform transition ease-in-out"
            enterFrom=" -translate-x-1 translate-y-6 opacity-40"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="flex space-x-6 flex-row">
              <div className="flex-1">
                <AccountBalanceItem
                  mount={incoming}
                  subTitle={transformField(t('account_balance:incoming_fund'))}
                  balance={currency}
                  loading={initLoading}
                />
              </div>
            </div>
          </Transition.Child>

          <Transition.Child
            className="flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color h-fit p-4 md:p-6"
            enter="duration-550 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-6 opacity-30"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-600 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="flex space-x-6 flex-row">
              <div className="flex-1">
                <AccountBalanceItem
                  mount={outgoing}
                  subTitle={t('account_balance:outgoing_fund')}
                  balance={currency}
                  loading={initLoading}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
        <AccountBalanceCurrentUpcomimg {...props} />
        <AccountBalanceCurrentActivities {...props} />
      </Transition.Root>
    </>
  );
};

export default AccountBalanceCurrentAccount;
