
import { Alert } from '@/components/common/alert/alert';
import { b2cChangeEmail, b2cChangeEmailJudge, b2cChangePhone, b2cChangePhoneJudge, b2cChangePwd, b2cChangePwdVerify, b2cMeChange, b2cOtpSend, b2cOtpVerify, encryptPassword, queryMe } from '@/data/account';
import { IAreaCodes } from '@/types/common-types';
import { findNode, insertComma, verifyEmail } from '@/utils';
import { minus, plus } from '@/utils/galaxy-utils';
import { getNationalityData } from '@/utils/nationalities';
import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import PersonalSpaceLayout from '../components/layout';
import AccountPanelSkeleton from '../components/account-panel-skeleton';
import Button from '@/components/common/button';
import Label from '@/components/common/label';
import Input from '@/components/common/input';
import CopyIcon from '@/components/svg/copy-icon';
import Select from '@/components/common/select';
import BackIcon from '@/components/svg/back-icon';
import ReactCodeInput from 'react-code-input';
import PhoneNumber from '@/components/common/phone-number';
import EyeIcon from '../personal-space-icons/eye-icon';
import EyeOffIcon from '../personal-space-icons/eye-off-icon';
import { loadAreaCodesData } from '@/data/area-codes';
import Avatar from '@/components/common/avatar';
import ModalRight from '@/components/common/modal/modal-right';
import { useDispatch, useSelector } from 'react-redux';
import ActionType from '@/actions/action-type';
import { ILoginUser } from '@/types/account-type';
import { IReducersState } from '@/reducers';
import { IDispatch } from '@/actions';

let verificationTimout: any = null;
let tipsTimeout: any;

const AccountSettingsPage = () => {
  const { t } = useTranslation('personal_space');
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();

  const loginUser = useSelector((state: IReducersState) => state.account.loginUser);

  const [displayName, setDisplayName] = useState('');
  const [country, setCountry] = useState('');
  const [nationality, setNationality] = useState('');
  const [user, setUserData] = useState<ILoginUser>(loginUser);
  const [userPhone, setUserPhone] = useState('');
  const [dialItem, setDialItem] = useState<IAreaCodes | undefined>();
  const [accountChange, setAccountChange] = useState(false);
  const [emailChange, setEmailChange] = useState(false);
  const [phoneChange, setPhoneChange] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [count, setCount] = useState(0);
  const [step, setStep] = useState(0);
  const [btnText, setBtnText] = useState(`${t('personal_space:change')}`);
  const [emailCode, setEmailCode] = useState('');
  const [temporaryToken, setTemporaryToken] = useState(''); // 临时token
  const [passwordChangeStep, setPasswordChangeStep] = useState(0);
  const [passwordFlag, setPasswordFlag] = useState(false);
  const [passwordConfirmFlag, setPasswordConfirmFlag] = useState(false);
  const [currentPasswordFlag, setCurrentPasswordFlag] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [level, setLevel] = useState(0);
  const [tooltip, setTooltip] = useState('');
  const [classes, setClasses] = useState('');
  const [textClass, setTextClass] = useState('');
  const [passwordTemporaryToken, setPasswordTemporaryToken] = useState(''); // 临时token
  const [validCurrentPassword, setValidCurrentPassword] = useState(false);
  const [validNewPassword, setValidNewPassword] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const nationalities = getNationalityData();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [areaCodes, setAreaCodes] = useState<IAreaCodes[]>([]);

  const inputProps: any = {
    className: 'react-input',
    inputStyle: {
      borderRadius: '6px',
      marginRight: '4px',
      height: '76px',
      fontSize: '32px',
      textAlign: 'center',
      flex: 1,
    },
  };

  const Form = useForm({ mode: 'onChange' });

  const { handleSubmit, control, getValues, setError } = Form;
  const values = getValues();

  const btnTexts = [
    t('personal_space:change'),
    t('personal_space:next'),
    t('personal_space:next'),
    t('personal_space:save'),
  ];

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (count === 0) {
      clearTimeout(verificationTimout);
      verificationTimout = null;
    } else {
      verificationTimout = setTimeout(() => {
        verificationTimout = null;
        setCount(minus(count, 1));
      }, 1000);
    }
  }, [count]);

  const handleNext = () => {
    const nextStep = plus(step, 1);
    setStep(nextStep);
    setBtnText(btnTexts[nextStep]);
    setEmailCode('');
  };

  const handleSendCode = async (secondVerify?: true) => {
    if (count) return;
    let params: any = {};
    if (secondVerify) {
      const formValues = getValues();
      params = { ...formValues };
    } else {
      if (emailChange) {
        params.email = user.email;
      } else if (phoneChange) {
        params.phone = user.phone;
      }
    }
    if (step === 2 || step === 3) {
      params.token = temporaryToken;
    }
    // if (token && isString(token)) {
      if (secondVerify) {
        const changePhoneJudge = emailChange
          ? await b2cChangeEmailJudge(params)
          : await b2cChangePhoneJudge(params);
        console.info(changePhoneJudge);
        if (changePhoneJudge.code === 200 && changePhoneJudge.data !== true) {
          params.action = emailChange
            ? 'reset_login_email'
            : 'reset_login_phone';
          const sendData = await b2cOtpSend(params);
          if (sendData.code === 200) {
            setCount(60);
            Alert.success({
              message: t('personal_space:code_sent_successfully'),
            });
            if (step !== 1 && step !== 3) {
              handleNext();
            }
          }
        } else {
          setError(emailChange ? 'email' : 'phone', {
            type: 'manual',
            message: emailChange
              ? t('personal_space:email_already_exist')
              : t('personal_space:phone_number_already_exist'),
          });
        }
      } else {
        params.action = emailChange
          ? 'reset_login_email_verify'
          : 'reset_login_phone_verify';
        const sendData = await b2cOtpSend(params);
        if (sendData.code === 200) {
          setCount(60);
          Alert.success({
            message: t('personal_space:code_sent_successfully'),
          });
          if (step !== 1 && step !== 3) {
            handleNext();
          }
        } else if (sendData.message) {
          Alert.error({
            message: sendData.message,
          });
        }
      }
    // }
  };

  const handlVerifyOld = async () => {
    const params: any = {
      action: emailChange
        ? 'reset_login_email_verify'
        : 'reset_login_phone_verify',
      aut_code: emailCode,
    };
    if (emailChange) {
      params.email = user.email;
    } else if (phoneChange) {
      params.phone = user.phone;
    }
    // if (token && isString(token)) {
      const sendData = await b2cOtpVerify(params);
      if (sendData.code === 200) {
        handleNext();
        setCount(0);
        setTemporaryToken(sendData.data.token);
      } else if (sendData.message) {
        Alert.error({
          message: sendData.message,
        });
      }
    // }
  };
  const handleSubmitChange = async () => {
    const formValues = getValues();
    let params: any = {};
    params = { ...formValues };
    // params.verifyCodeType = 'checknew';
    params.aut_code = emailCode;
    params.token = temporaryToken;
    // if (token && isString(token)) {
      const sendData = emailChange
        ? await b2cChangeEmail(params)
        : await b2cChangePhone(params);
      if (sendData.code === 200) {
        Alert.success({
          message: emailChange
            ? t('personal_space:email_changed_tip')
            : t('personal_space:phone_number_changed_tip'),
        });
        navigate('/account/signin');
      } else if (sendData.message) {
        Alert.error({
          message: sendData.message,
        });
      }
    // }
  };

  const handleCheckNew = () => {
    handleSendCode(true);
  };

  const btnClick = [
    handleSendCode,
    handlVerifyOld,
    handleCheckNew,
    handleSubmitChange,
  ];

  const handleBack = () => {
    const nextStep = minus(step, 1);
    setEmailCode('');
    setCount(0);
    if (step === 0) {
      setEmailChange(false);
      setPhoneChange(false);
    }
    if (nextStep >= 0) {
      setStep(nextStep);
      setBtnText(btnTexts[nextStep]);
    }
  };

  const resetChange = () => {
    setAccountChange(false);
    if (user) {
      setDisplayName(user.name);
      setCountry(user.country);
      setNationality(user.nationality);
    }
    // setAddress(userData.address1);
  };

  const saveAccountChange = async () => {
    const params = {
      display_name: displayName,
      country,
      nationality,
      // address1: address,
    };

    const sendData = await b2cMeChange(params);
    if (sendData.code === 200) {
      const { data } = sendData;
      setAccountChange(false);
      setDisplayName(data.display_name);
      setCountry(data.country);
      setNationality(data.nationality);

      const newUser: ILoginUser = {
        ...user,
        name: data.display_name,
        country: data.country,
        nationality: data.nationality,
      }

      setUserData(newUser);

      dispatch({
        type: ActionType.SET_LOGIN_USER,
        loginUser: newUser,
      });
      Alert.success({
        message: t('common:changes_saved'),
      });
    } else if (sendData.message) {
      Alert.error({
        message: sendData.message,
      });
    }

  };

  const handleValueChange = (value: string) => {
    let level = 3;
    let tooltip = '';
    let classes = '';
    let textClass = '';

    if (value.length < 6 || value.length > 20) {
      level--;
    }

    if (
      !RegExp('.*(?=.*[0-9].*).*').test(value) &&
      !RegExp('.*(?=.*[a-zA-Z0-9].*).*').test(value)
    ) {
      level--;
    }

    if (!RegExp('.*(?=.*[A-Z].*)(?=.*[a-z].*).*').test(value)) {
      level--;
    }

    if (level === 1) {
      classes = 'bg-error-color';
      textClass = 'text-error-color';
      tooltip = t('personal_space:very_weak');
    } else if (level === 2) {
      classes = 'bg-warning-color';
      textClass = 'text-warning-color';
      tooltip = t('personal_space:so_so');
    } else if (level === 3) {
      classes = 'bg-success-color';
      textClass = 'text-success-color';
      tooltip = t('personal_space:great');
    }

    setLevel(level);
    setTooltip(tooltip);
    setClasses(classes);
    setTextClass(textClass);
  };

  const handleChangePassword = async () => {
    const formValues = getValues();
    if (passwordChangeStep === 0) {
      const data = await b2cChangePwdVerify(formValues.currentPassword);
      if (data.code === 200) {
        setPasswordChangeStep(1);
        setPasswordTemporaryToken(data.data.token);
      } else if (data.message) {
        setError('currentPassword', {
          type: 'manual',
          message: data.message,
        });
      }
    } else {
      const password = encryptPassword(values.password);
      const data = await b2cChangePwd(
        {
          new_password: password,
          token: passwordTemporaryToken,
        }
      );
      if (data.code === 200) {
        Alert.success({
          message: t('personal_space:password_reset_tip'),
        });
        navigate('/account/signin');
      } else if (data.message) {
        Alert.error({
          message: data.message,
        });
      }
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(user.id).then(() => {
      setCopySuccess(true);
    });
  };

  useEffect(() => {
    if (copySuccess) {
      clearTimeout(tipsTimeout);
      tipsTimeout = setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }
  }, [copySuccess]);

  const getData = async () => {
    try {
      setLoadingData(true);
      const codes = await loadAreaCodesData();

      const user = await queryMe();
      if (user && user.id) {
        setDisplayName(user.name);
        setCountry(user.country);
        setNationality(user.nationality);
        const tArray: string[] = user.phone.split(/\s+/);
        const dialItem = findNode<IAreaCodes>(
          areaCodes,
          'phoneCountryCode',
          tArray[0].replace('+', '')
        );
        const userPhone = `${tArray[0]} ${insertComma(
          tArray[1].replace(/ /g, ''),
          true,
          4,
          ' '
        )}`;
        setDialItem(dialItem);
        setUserPhone(userPhone);
        setUserData(user);
      }
      setLoadingData(false);
      setAreaCodes(codes)
    } catch (error) {
      setLoadingData(false);
      console.error(error);
    }
  };

  return (
    <PersonalSpaceLayout activeIndex={1}>
      <>{loadingData && <AccountPanelSkeleton />}</>
      {!loadingData && user && (
        <>
          <h2 className="text-2xl font-bold mb-6 text-color-text-1">
            {t('personal_space:account_settings')}
          </h2>
          <Transition
            show={true}
            appear={true}
            className="z-90"
            enter="duration-350 transform transition ease-in-out"
            enterFrom="translate-y-6 opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-y-6"
          >
            <section className="border rounded border-border-color">
              <div className="flex items-center justify-between px-4 py-1.5 border-b border-border-color bg-disabled-color rounded-tl rounded-tr">
                <div className="text-lg text-color-text-1 font-semibold">
                  {t('personal_space:profile_information')}
                </div>
                <div className="flex">
                  {accountChange && (
                    <>
                      <Button
                        styleType="Text"
                        className="text-color-text-3 hover:text-color-text-3/80 h-7"
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                        onClick={() => resetChange()}
                      >
                        {t('common:cancel')}
                      </Button>
                      <Button
                        styleType="Primary"
                        onClick={() => saveAccountChange()}
                        className="ml-4 h-7 px-3"
                      >
                        {t('common:save')}
                      </Button>
                    </>
                  )}
                  {!accountChange && (
                    <Button
                      styleType="Text"
                      onClick={() => setAccountChange(true)}
                      className="h-7 text-primary-color"
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                      {t('personal_space:change_profile')}
                    </Button>
                  )}
                </div>
              </div>
              <div className="px-4 py-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mb-2 md:mb-6">
                  <div className="flex flex-col">
                    {!accountChange && (
                      <Controller
                        name="user_iamge"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Avatar
                            type="AVATAR"
                            name="name"
                            displayName={displayName}
                            proportion={0}
                            rowQuantity={0}
                            multiValue={false}
                            label={''}
                            disabled={true}
                            value={value}
                          />
                        )}
                      />
                    )}
                    {accountChange && (
                      <>
                        <Label
                          disabled={!accountChange}
                          value={t('personal_space:preferred_name')}
                        ></Label>
                        <Input
                          name="name"
                          value={displayName}
                          disabled={!accountChange}
                          onChange={(value) => {
                            setDisplayName(value);
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div className="flex flex-col ml-0 sm:ml-6 mt-2 sm:mt-0">
                    <Label
                      disabled={true}
                      value={t('personal_space:full_name')}
                    ></Label>
                    <Input
                      name="fullName"
                      value={user?.full_name}
                      disabled={true}
                    />
                  </div>
                  <div className="flex flex-col ml-0 md:ml-6 mt-2 md:mt-0">
                    <Label
                      disabled={true}
                      value={t('personal_space:user_id')}
                    ></Label>
                    <div className="w-full flex items-center group h-10">
                      <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-color-text-1 text-sm font-medium mr-1">
                        {user?.id}
                      </span>
                      <div className="flex">
                        <span
                          className="cursor-pointer group"
                          onClick={handleCopy}
                        >
                          <CopyIcon className="text-primary-color" />
                        </span>
                        {copySuccess && (
                          <span className="text-primary-color text-xs cursor-pointer">
                            {t('personal_space:copied')}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                  <div className="flex flex-col">
                    <Label
                      disabled={!accountChange}
                      value={t('personal_space:residing_country')}
                    ></Label>
                    <Select
                      options={areaCodes}
                      dataKey={'isoAlpha2Code'}
                      valueKey={'name'}
                      value={country}
                      disabled={!accountChange}
                      showSearch
                      renderItem={(item: IAreaCodes) => (
                        <div className="flex" key={item.name}>
                          {/* <span className="flex items-center relative w-5 mr-2">
                            <Image
                              layout="intrinsic"
                              width="20px"
                              height="20px"
                              src={`/images/country-flag/${
                                item.isoAlpha2Code
                                  ? item.isoAlpha2Code.toLocaleLowerCase()
                                  : 'hk'
                              }.svg`}
                              alt=""
                            />
                          </span> */}
                          <span className="truncate">{item.name}</span>
                        </div>
                      )}
                      onChange={(data) => {
                        setCountry(data);
                      }}
                    />
                  </div>
                  <div className="flex flex-col ml-0 md:ml-6 mt-2 md:mt-0">
                    <Label
                      disabled={!accountChange}
                      value={t('personal_space:nationality')}
                    ></Label>
                    <Select
                      options={nationalities}
                      dataKey={'isoAlpha2Code'}
                      valueKey={'name'}
                      value={nationality}
                      disabled={!accountChange}
                      showSearch
                      renderItem={(item: IAreaCodes) => (
                        <div className="flex" key={item.name}>
                          {/* <span className="flex items-center relative w-5 mr-2">
                            <Image
                              layout="intrinsic"
                              width="20px"
                              height="20px"
                              src={`/images/country-flag/${
                                item.isoAlpha2Code
                                  ? item.isoAlpha2Code.toLocaleLowerCase()
                                  : 'hk'
                              }.svg`}
                              alt=""
                            />
                          </span> */}
                          <span className="truncate">{item.name}</span>
                        </div>
                      )}
                      onChange={(data) => {
                        setNationality(data);
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>
          </Transition>
          <Transition
            show={true}
            appear={true}
            className="z-80"
            enter="duration-500 transform transition ease-in-out"
            enterFrom="translate-y-8 opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-y-8"
          >
            <section className="border rounded border-border-color mt-6">
              <div className="md:flex items-center justify-between px-4 py-1.5 border-b border-border-color bg-disabled-color rounded-tl rounded-tr">
                <div className="text-lg text-color-text-1 font-semibold">
                  {t('personal_space:email_and_phone')}
                </div>
                {/* <div className="flex items-end">
                  <Button
                    styleType="Text"
                    className="h-7 whitespace-nowrap"
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    onClick={() => setEmailChange(true)}
                  >
                    {t('personal_space:change_email')}
                  </Button>
                  <Button
                    styleType="Text"
                    onClick={() => setPhoneChange(true)}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    className="ml-6 h-7 whitespace-nowrap"
                  >
                    {t('personal_space:change_phone_number')}
                  </Button>
                </div> */}
              </div>
              <div className="px-4 py-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                  <div className="flex flex-col">
                    <Label
                      disabled={true}
                      value={t('personal_space:email')}
                    ></Label>
                    <Input
                      type="email"
                      name="email"
                      disabled={true}
                      value={user?.email}
                    />
                  </div>
                  <div className="flex flex-col ml-0 md:ml-6 mt-2 md:mt-0">
                    <Label
                      disabled={true}
                      value={t('personal_space:phone_number')}
                    ></Label>
                    <div className="flex whitespace-nowrap py-2">
                      {dialItem && (
                        <span className="flex items-center relative w-5 mr-2">
                          <img
                            width="20px"
                            height="20px"
                            src={`/images/country-flag/${dialItem.isoAlpha2Code.toLocaleLowerCase()}.svg`}
                            alt=""
                          />
                        </span>
                      )}
                      <span className="text-color-text-1 text-sm font-medium truncate">
                        {userPhone}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Transition>
          {/* <Transition
            show={true}
            appear={true}
            enter="duration-700 transform transition ease-in-out"
            enterFrom="translate-y-10 opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-y-10"
          >
            <section className="border rounded border-border-color mt-6">
              <div className="flex items-center justify-between px-4 py-1.5 bg-disabled-color rounded">
                <div className="text-lg text-color-text-1 font-semibold">
                  {t('personal_space:password')}
                </div>
                <Button
                  styleType="Text"
                  className="h-7"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  onClick={() => setPasswordChange(true)}
                >
                  {t('personal_space:change_password')}
                </Button>
              </div>
            </section>
          </Transition> */}
        </>
      )}
      <ModalRight
        visible={emailChange || phoneChange}
        title={
          emailChange
            ? t('personal_space:change_email')
            : phoneChange
            ? t('personal_space:change_phone_number')
            : ''
        }
        titleButton={
          <Button
            onClick={() => btnClick[step]()}
            type="submit"
            disabled={
              (step !== 0 && step !== 2 && !emailCode) ||
              (phoneChange && step === 2 && !validPhone) ||
              (emailChange && step === 2 && !validEmail)
            }
          >
            {btnText}
          </Button>
        }
        size="normal"
        onCancel={() => {
          handleBack();
        }}
        iconInstance={step > 0 ? <BackIcon size={20} /> : undefined}
      >
        {step === 0 && (
          <>
            <Label
              disabled={true}
              value={
                emailChange
                  ? t('personal_space:current_email')
                  : t('personal_space:current_phone_number')
              }
              className="mt-8"
            ></Label>
            {emailChange && (
              <Input
                type="email"
                name="email"
                disabled={true}
                value={user?.email}
              />
            )}
            {!emailChange && (
              <div className="flex whitespace-nowrap py-2">
                {dialItem && (
                  <span className="flex items-center relative w-5 mr-2">
                    <img
                      width="20px"
                      height="20px"
                      src={`/images/country-flag/${dialItem.isoAlpha2Code.toLocaleLowerCase()}.svg`}
                      alt=""
                    />
                  </span>
                )}
                <span className="text-color-text-1 text-sm font-medium truncate">
                  {userPhone}
                </span>
              </div>
            )}
          </>
        )}
        {step === 1 && (
          <>
            <div className="text-sm mt-8">
              {t('personal_space:enter_code_tip', {
                data: emailChange ? user?.email : user?.phone,
              })}
            </div>
            <div className="mt-6 mb-4">
              {t('personal_space:enter_code_to_verification')}
            </div>
            <ReactCodeInput
              {...inputProps}
              fields={6}
              name="emailCode"
              type="number"
              inputMode="verbatim"
              onChange={(code: any) => {
                setEmailCode(code);
              }}
            />
            <div
              className={`cursor-pointer mt-8 text-base font-medium ${
                count !== 0 ? 'text-color-text-4' : 'text-primary-color'
              }`}
              onClick={() => handleSendCode()}
            >
              {t('personal_space:send_code_again')}{' '}
              {count !== 0 ? `(${count})` : ''}
            </div>
          </>
        )}
        {step === 2 && (
          <>
            {emailChange && (
              <form onSubmit={handleSubmit(handleSubmitChange)}>
                <Label
                  disabled={true}
                  value={t('personal_space:new_email')}
                  className="mt-8"
                  required={true}
                ></Label>
                <Controller
                  name={'email'}
                  control={control}
                  rules={{
                    required: t('personal_space:field_is_required'),
                    validate: (value) => {
                      if (verifyEmail(value)) {
                        setValidEmail(true);
                        return '';
                      } else {
                        setValidEmail(false);
                        return t('personal_space:invalid_email_address');
                      }
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      onChange={(data) => {
                        onChange(data);
                      }}
                      value={value}
                      error={error}
                      autoComplete="off"
                      placeholder={t('personal_space:email_address')}
                    />
                  )}
                />
              </form>
            )}
            {phoneChange && (
              <form onSubmit={handleSubmit(handleSubmitChange)}>
                <Label
                  disabled={true}
                  value={t('personal_space:new_phone_number')}
                  className="mt-8"
                  required={true}
                ></Label>
                <Controller
                  name={'phone'}
                  control={control}
                  rules={{
                    validate: (value) => {
                      const msg = t('personal_space:field_is_required');
                      setValidPhone(false);
                      if (!value) return msg;
                      if (value && typeof value === 'string') {
                        const newValue = value.substring(
                          value.indexOf(' ') + 1
                        );
                        const code = value.substring(0, value.indexOf(' '));
                        if (
                          (code === '+852' && newValue.length !== 8) ||
                          (code === '+86' && newValue.length !== 11)
                        )
                          return t('personal_space:invalid_phone_number');
                        setValidPhone(true);
                        return newValue && code ? undefined : msg;
                      }
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <PhoneNumber
                      name="signin"
                      options={areaCodes}
                      showSearch
                      placeholder={t('personal_space:phone_number')}
                      onChange={(data) => {
                        onChange(data);
                      }}
                      value={value}
                      error={error}
                    />
                  )}
                />
              </form>
            )}
          </>
        )}
        {step === 3 && (
          <>
            <div className="mt-6 mb-4">
              {t('personal_space:enter_code_tip', {
                data: emailChange ? getValues().email : getValues().phone,
              })}
            </div>
            <ReactCodeInput
              {...inputProps}
              fields={6}
              name="emailCode"
              type="number"
              inputMode="verbatim"
              onChange={(code: any) => {
                setEmailCode(code);
              }}
            />
            <div
              className={`cursor-pointer mt-8 text-base font-medium ${
                count !== 0 ? 'text-color-text-4' : 'text-primary-color'
              }`}
              onClick={() => handleSendCode(true)}
            >
              {t('personal_space:send_code_again')}{' '}
              {count !== 0 ? `(${count})` : ''}
            </div>
          </>
        )}
      </ModalRight>
      <ModalRight
        visible={passwordChange}
        title={t('personal_space:set_new_password')}
        titleButton={
          <Button
            onClick={() => handleChangePassword()}
            disabled={
              (passwordChangeStep === 0 && !validCurrentPassword) ||
              (passwordChangeStep === 1 && !validNewPassword) ||
              (passwordChangeStep === 1 && level !== 3)
            }
          >
            {passwordChangeStep === 0
              ? t('personal_space:next')
              : t('personal_space:save')}
          </Button>
        }
        size="normal"
        onCancel={() => {
          if (passwordChangeStep > 0) {
            setPasswordChangeStep(0);
          } else {
            setPasswordChange(false);
          }
        }}
        iconInstance={
          passwordChangeStep > 0 ? <BackIcon size={20} /> : undefined
        }
      >
        {passwordChangeStep === 0 && (
          <>
            <div className="text-sm mt-8">
              {t('personal_space:enter_current_password_of_account')}{' '}
              {user?.email}
            </div>
            <Label
              disabled={true}
              value={t('personal_space:current_password')}
              className="mt-8"
              required={true}
            >
              {currentPasswordFlag && (
                <EyeIcon
                  className="cursor-pointer text-color-text-5 hover:text-icon-color"
                  onClick={() => setCurrentPasswordFlag(!currentPasswordFlag)}
                />
              )}
              {!currentPasswordFlag && (
                <EyeOffIcon
                  className="cursor-pointer text-color-text-5 hover:text-icon-color"
                  onClick={() => setCurrentPasswordFlag(!currentPasswordFlag)}
                />
              )}
            </Label>
            <form onSubmit={handleSubmit(handleChangePassword)}>
              <Controller
                name="currentPassword"
                control={control}
                rules={{
                  required: t('personal_space:field_is_required'),
                  validate: (value) => {
                    if (value) {
                      setValidCurrentPassword(true);
                      return '';
                    } else {
                      setValidCurrentPassword(false);
                      return t('personal_space:field_is_required');
                    }
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    onChange={(data) => {
                      onChange(data);
                    }}
                    value={value}
                    error={error}
                    autoComplete="off"
                    placeholder={t('personal_space:enter_current_password')}
                    type={currentPasswordFlag ? 'text' : 'password'}
                  />
                )}
              />
            </form>
          </>
        )}
        {passwordChangeStep === 1 && (
          <>
            <div className="text-sm text-color-text-2 mt-8">
              {t('personal_space:reset_password_tip')}
            </div>
            <form>
              <Label
                value={t('personal_space:new_password')}
                className="mt-6"
                required
              >
                <div className="flex items-center">
                  <span className={`mr-2 ${textClass}`}>{tooltip}</span>
                  {passwordFlag && (
                    <EyeIcon
                      className="cursor-pointer text-color-text-5 hover:text-icon-color"
                      onClick={() => setPasswordFlag(!passwordFlag)}
                    />
                  )}
                  {!passwordFlag && (
                    <EyeOffIcon
                      className="cursor-pointer text-color-text-5 hover:text-icon-color"
                      onClick={() => setPasswordFlag(!passwordFlag)}
                    />
                  )}
                </div>
              </Label>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: t('personal_space:field_is_required'),
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    onChange={(data) => {
                      onChange(data);
                      handleValueChange(data);
                    }}
                    value={value}
                    error={error}
                    autoComplete="off"
                    style={{ WebkitBoxShadow: '0 0 0 1000px white inset' }}
                    placeholder={t('personal_space:enter_new_password')}
                    type={passwordFlag ? 'text' : 'password'}
                  />
                )}
              />
              {values.password && (
                <div className="flex mt-1">
                  <span
                    className={`flex-1 h-0.5 ${
                      level > 0 ? `${classes}` : 'bg-disabled-color'
                    }`}
                  />
                  <span
                    className={`flex-1 h-0.5 ${
                      level > 1 ? `${classes}` : 'bg-disabled-color'
                    }`}
                  />
                  <span
                    className={`flex-1 h-0.5 ${
                      level > 2 ? `${classes}` : 'bg-disabled-color'
                    }`}
                  />
                </div>
              )}
              <Label
                value={t('personal_space:confirm_password')}
                className="mt-6"
                required
              >
                {passwordConfirmFlag && (
                  <EyeIcon
                    className="cursor-pointer text-color-text-5 hover:text-icon-color"
                    onClick={() => setPasswordConfirmFlag(!passwordConfirmFlag)}
                  />
                )}
                {!passwordConfirmFlag && (
                  <EyeOffIcon
                    className="cursor-pointer text-color-text-5 hover:text-icon-color"
                    onClick={() => setPasswordConfirmFlag(!passwordConfirmFlag)}
                  />
                )}
              </Label>
              <Controller
                name="passwordConfirm"
                control={control}
                rules={{
                  required: t('personal_space:field_is_required'),
                  validate: (value) => {
                    if (value === getValues().password) {
                      setValidNewPassword(true);
                      return '';
                    } else {
                      setValidNewPassword(false);
                      return t('personal_space:repeat_new_password');
                    }
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    onChange={(data) => {
                      onChange(data);
                    }}
                    value={value}
                    error={error}
                    autoComplete="off"
                    placeholder={t('personal_space:enter_new_password_again')}
                    type={passwordConfirmFlag ? 'text' : 'password'}
                  />
                )}
              />
            </form>
          </>
        )}
      </ModalRight>
    </PersonalSpaceLayout>
  );
};

export default AccountSettingsPage;

