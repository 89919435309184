import React from 'react';
import type { IIconProps } from '@/types/common-types';

const DateIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 16 } = props;
  return (
    <div
      className={`inline-flex items-center justify-center ${className}`}
      onClick={onClick}
    >
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.9987 4.16406H4.9987C4.07822 4.16406 3.33203 4.91025 3.33203 5.83073V15.8307C3.33203 16.7512 4.07822 17.4974 4.9987 17.4974H14.9987C15.9192 17.4974 16.6654 16.7512 16.6654 15.8307V5.83073C16.6654 4.91025 15.9192 4.16406 14.9987 4.16406Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.332 2.5V5.83333"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66797 2.5V5.83333"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33203 9.16406H16.6654"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33464 12.5H6.66797V14.1667H8.33464V12.5Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default DateIcon;
