import React from 'react';
import type { IIconProps } from '@/types/common-types';

const VerificationProcessIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.87 22.7089C27.7164 22.9647 27.4675 23.1491 27.178 23.2214C26.8885 23.2938 26.5822 23.2481 26.3263 23.0946C26.0705 22.941 25.8861 22.6921 25.8138 22.4026C25.7414 22.1131 25.7871 21.8067 25.9406 21.5509C27.1284 19.5725 27.754 17.3076 27.75 15C27.75 7.95825 22.0417 2.25 15 2.25C7.95825 2.25 2.25 7.95825 2.25 15C2.25 22.0417 7.95825 27.75 15 27.75C17.3072 27.7541 19.5719 27.1288 21.5501 25.9414C21.6768 25.8654 21.8172 25.8151 21.9634 25.7933C22.1095 25.7716 22.2585 25.7788 22.4018 25.8146C22.5451 25.8505 22.68 25.9142 22.7987 26.0021C22.9174 26.0901 23.0176 26.2006 23.0936 26.3272C23.1696 26.4539 23.22 26.5943 23.2417 26.7405C23.2634 26.8866 23.2562 27.0356 23.2204 27.1789C23.1845 27.3222 23.1208 27.4571 23.0329 27.5758C22.9449 27.6945 22.8344 27.7947 22.7078 27.8707C20.3798 29.268 17.715 30.0041 15 30C6.71588 30 0 23.2841 0 15C0 6.71587 6.71588 0 15 0C23.2841 0 30 6.71587 30 15C30 17.7525 29.2564 20.3989 27.87 22.7089Z"
          fill="black"
        />
        <path
          d="M10.1693 14.2073L13.1344 17.1601L19.8293 10.4551C20.0402 10.2439 20.3262 10.1252 20.6246 10.125C20.923 10.1248 21.2093 10.2431 21.4204 10.454C21.6316 10.6648 21.7503 10.9509 21.7505 11.2493C21.7507 11.5477 21.6324 11.8339 21.4216 12.0451L13.9328 19.5451C13.7222 19.7561 13.4364 19.8748 13.1383 19.8752C12.8401 19.8757 12.554 19.7577 12.3428 19.5473L8.58156 15.8015C8.47553 15.6975 8.39113 15.5736 8.33322 15.4369C8.27531 15.3002 8.24504 15.1533 8.24416 15.0048C8.24328 14.8564 8.27179 14.7092 8.32807 14.5718C8.38435 14.4344 8.46727 14.3094 8.57205 14.2042C8.67684 14.099 8.80141 14.0156 8.93859 13.9588C9.07577 13.902 9.22284 13.8729 9.37133 13.8731C9.51981 13.8734 9.66677 13.9031 9.80373 13.9605C9.94069 14.0178 10.0649 14.1017 10.1693 14.2073Z"
          fill="#FFDA44"
        />
      </svg>
    </div>
  );
};

export default VerificationProcessIcon;
