import { useEffect, useState } from 'react';
import Button from '@/components/common/button';
import api from '@/libs/api';
import config from '@/libs/config';
import { getCookie } from '@/utils/index';
import { Constants } from '@/constants';
import AddIcon from '@/pages/global-accounts/global-accounts-icons/newstep-icon';
import ChooseAccount from '@/pages/global-accounts/global-accounts-icons/choose-account-icon';
import SelectedIcon from '@/pages/payouts/payouts-icons/selected-icon';
import WarningIcon from '@/components/svg/warning-icon';
import type { IPayee } from '@/types/payouts-type';
import type { IPayoutsNewStep } from '@/types/global-accounts-type';
import Skeleton from '@/components/skeleton';
import NoRelatedPayeeIcon from '@/pages/payouts/payouts-icons/no-related-payee-icon';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
interface IAddfundChooseAccount {
  businessID: string;
  amount: string;
  setSteps: (steps: IPayoutsNewStep[]) => void;
  setStepCount: (stepCount: number) => void;
  setglobalPayees: (globalPayees: any) => void;
}

const AddfundChooseAccount = (props: IAddfundChooseAccount) => {
  const { businessID, amount, setStepCount, setSteps, setglobalPayees } = props;
  const [loadingData, setLoadingData] = useState(true);
  const [payees, setPayees] = useState<IPayee[]>([]);
  const { t } = useTranslation(["global_accounts",
    "payouts",
    "account_balance",
    "related_company_accounts",
    "payees"]);
  const { lang = 'en' } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryCurrencyCode = searchParams.get('currency_code');
  const [active, setActive] = useState('');
  const [checkItem, setCheckItem] = useState({});
  const handleChangePayee = (payee: IPayee) => {
    setCheckItem(payee);
    setActive(payee.id);
  };
  useEffect(() => {
    getPayees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPayees = async () => {
    setLoadingData(true);
    const token = getCookie(Constants.TOKEN) || '';
    const resp = await api.get(
      `${config.urls.gatewayUrl}/api/treasury-services/api/payees?limit=999&offset=0&related_company_payee=true`,
      {
        headers: {
          'x-user-access-token': token,
          'x-client-id': config.clientID,
          'x-platform-id': config.platformId,
          'x-p-business-id': businessID,
          'x-i18n-lang': lang,
        },
      }
    );
    setPayees(resp.data);
    setLoadingData(false);
  };

  const handleNext = () => {
    setglobalPayees(checkItem);
    const steps: IPayoutsNewStep[] = [
      {
        key: '1',
        title: t('global_accounts:choose_method'),
      },
      {
        key: '4',
        title: t('payouts:fps_edda'),
        name: '2',
      },
    ];
    setSteps(steps);
    setStepCount(1);
  };
  const handleBack = () => {
    const steps: IPayoutsNewStep[] = [
      {
        key: '1',
        title: t('global_accounts:choose_method'),
        name: '1',
      },
      {
        key: '2',
        title: t('payouts:fps_edda'),
        name: '2',
      },
    ];
    setSteps(steps);
    setStepCount(1);
  };

  const newStep = () => {
    const steps: IPayoutsNewStep[] = [
      {
        key: '1',
        title: t('global_accounts:choose_method'),
        name: '1',
      },
      {
        key: '2',
        title: t('payouts:fps_edda'),
        name: '2',
      },
      {
        key: '2A',
        title: t('payouts:another_account'),
        name: '2A',
      },
    ];
    setSteps(steps);
    setStepCount(2);
  };

  const encryption = (str: string) => {
    return '*** ****** ' + str.slice(-3);
  };

  return (
    <div>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        <div className="flex items-center">
          <ChooseAccount />
          <div className="ml-4">
            <div className="font-semibold text-3xl text-color-text-1 ">
              {amount} {queryCurrencyCode}
            </div>
            <div className="text-[#8E8E93] text-sm mt-1 font-normal">
              {t('payouts:add_fund_amount')}
            </div>
          </div>
        </div>

        <div className="mt-4 mb-6 border-b border-border-color" />

        <div className=" text-color-text-4  text-lg font-semibold">
          {t('payouts:select_bank_account')}
        </div>
        <div className=" text-[#4C5259] pt-1 text-sm font-normal">
          {t('payouts:select_bank_tip')}
        </div>

        {!loadingData &&
          payees.map((item) => {
            return (
              <div
                key={item.id}
                onClick={() => handleChangePayee(item)}
                className="text-color-text-1 font-medium text-sm"
              >
                <div
                  className={`flex items-center py-3 cursor-pointer hover:bg-disabled-color border h-[136px] rounded mt-4 relative ${active === item.id
                      ? 'border-[#0094FF]'
                      : 'border-border-color'
                    }`}
                >
                  <div className="ml-3">
                    <div className=" text-[#8E8E93] text-sm font-medium">
                      {item.nickname}
                    </div>
                    <div className="pb-1.5 text-[#8E8E93] text-sm font-medium">
                      {item.payout_accounts.banks.bank_code}{' '}
                      {item.payout_accounts.banks.bank_name}
                    </div>
                    <div className="text-xl">
                      {encryption(item.payout_accounts.identifier_3_value)}
                    </div>
                    <div className="flex items-center text-xs leading-normal mt-[10px]">
                      <div className="py-0.5 px-2 bg-[#FFEEAA] rounded-3xl text-[#A28200] mr-1.5">
                        {item.payout_accounts.country_codes.alpha_3}
                      </div>
                      <div className="py-0.5 px-2 bg-[#B3DFFF] rounded-3xl text-primary-color mr-1.5">
                        {item.payout_accounts.currency}
                      </div>
                      {item.payout_accounts.verified_at &&
                        item.payout_accounts.verification_method ? (
                        <div className="flex">
                          <SelectedIcon size={13} />
                          <span className="ml-1 text-[#0094FF]">
                            {t('payouts:verified')}
                          </span>
                        </div>
                      ) : (
                        <div className="flex text-[#FFA217] items-center">
                          <WarningIcon size={13} />
                          <span className="ml-1">
                            {t('payouts:unverified')}
                          </span>
                        </div>
                      )}
                    </div>
                    {active === item.id && (
                      <div className="absolute top-[56px] right-[16px]">
                        <SelectedIcon size={24} />
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="border-b border-border-color"></div> */}
              </div>
            );
          })}

        {!loadingData && payees.length === 0 && (
          <div className="flex flex-col items-center justify-center mt-20 mb-52">
            <NoRelatedPayeeIcon size={80} />
            <div className="font-bold text-xl text-color-text-1 mt-6">
              {t('payouts:no_save_payee_yet')}
            </div>
            <div className="text-color-text-2 mt-2">
              {t('payouts:add_new_payee')}
            </div>
          </div>
        )}
        {!loadingData && (
          <div
            className="flex items-center py-3 cursor-pointer hover:bg-disabled-color border h-[52px] rounded mt-4 text-[#0094FF]"
            onClick={newStep}
          >
            <AddIcon className="mx-3" />
            {t('payouts:another_account')}
          </div>
        )}
        {loadingData && (
          <div>
            <div className="flex items-center py-4">
              <Skeleton
                avatar={true}
                row={3}
                className="items-center"
                width={['100%']}
                avatarClass="h-14 w-14 mr-3.5"
                skeletonItemClass="h-4 first:h-6 last:h-6 mb-1.5 last:mb-0 rounded-[50px] w-[200px]"
              />
            </div>
            <div className="border-b border-border-color"></div>
            <div className="flex items-center py-4">
              <Skeleton
                avatar={true}
                row={3}
                className="items-center"
                width={['100%']}
                avatarClass="h-14 w-14 mr-3.5"
                skeletonItemClass="h-4 first:h-6 last:h-6 mb-1.5 last:mb-0 rounded-[50px] w-[200px]"
              />
            </div>
            <div className="border-b border-border-color"></div>
            <div className="flex items-center py-4">
              <Skeleton
                avatar={true}
                row={3}
                className="items-center"
                width={['100%']}
                avatarClass="h-14 w-14 mr-3.5"
                skeletonItemClass="h-4 first:h-6 last:h-6 mb-1.5 last:mb-0 rounded-[50px] w-[200px]"
              />
            </div>
            <div className="border-b border-border-color"></div>
            <div className="flex items-center py-4">
              <Skeleton
                avatar={true}
                row={3}
                className="items-center"
                width={['100%']}
                avatarClass="h-14 w-14 mr-3.5"
                skeletonItemClass="h-4 first:h-6 last:h-6 mb-1.5 last:mb-0 rounded-[50px] w-[200px]"
              />
            </div>
          </div>
        )}
      </div>

      <div className="flex items-center justify-between">
        <Button
          className="flex-1 mr-6"
          onClick={handleBack}
          styleType="Secondary"
        >
          {t('common:back')}
        </Button>
        <Button
          className="flex-1"
          onClick={handleNext}
          styleType="Primary"
          disabled={Object.keys(checkItem).length === 0 ? true : false}
        >
          {t('common:next')}
        </Button>
      </div>
    </div>
  );
};

export default AddfundChooseAccount;
