import React from 'react';
import type { IIconProps } from '@/types/common-types';

const AddressEditIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.99561 1.83211C10.0949 1.73749 10.2274 1.68559 10.3645 1.68755C10.5017 1.68951 10.6326 1.74517 10.7291 1.84258L13.5846 4.72265C13.635 4.77345 13.6745 4.83403 13.7006 4.90067C13.7267 4.96731 13.7389 5.03859 13.7364 5.11012C13.7339 5.18165 13.7167 5.25191 13.686 5.31656C13.6553 5.38121 13.6117 5.43889 13.5578 5.48603L6.67754 11.5123C6.57917 11.5986 6.45208 11.645 6.32126 11.6423L3.77074 11.5878C3.6337 11.5849 3.50325 11.5284 3.40736 11.4305C3.31147 11.3325 3.25778 11.2009 3.25781 11.0638V8.4735C3.2578 8.40257 3.27219 8.33238 3.30011 8.26718C3.32803 8.20197 3.36889 8.14312 3.42023 8.09417L9.99561 1.83211ZM10.6909 10.2559L14.6178 10.3324C14.6866 10.3338 14.7544 10.3486 14.8175 10.3762C14.8805 10.4038 14.9375 10.4435 14.9853 10.4931C15.033 10.5427 15.0704 10.6012 15.0955 10.6653C15.1206 10.7294 15.1328 10.7978 15.1315 10.8666C15.1301 10.9354 15.1153 11.0032 15.0877 11.0663C15.0601 11.1294 15.0204 11.1864 14.9708 11.2341C14.9212 11.2818 14.8627 11.3192 14.7986 11.3443C14.7345 11.3694 14.6661 11.3816 14.5973 11.3803L10.6704 11.3038C10.6016 11.3024 10.5338 11.2875 10.4707 11.2599C10.4077 11.2323 10.3507 11.1926 10.303 11.1429C10.2554 11.0933 10.2179 11.0348 10.1929 10.9707C10.1678 10.9066 10.1556 10.8382 10.157 10.7694C10.1584 10.7006 10.1733 10.6327 10.2009 10.5697C10.2285 10.5066 10.2682 10.4497 10.3179 10.402C10.3675 10.3543 10.426 10.3169 10.4901 10.2918C10.5542 10.2667 10.6226 10.2546 10.6914 10.2559H10.6909ZM10.3467 2.94494L4.30568 8.69775V10.5509L6.13945 10.5902L12.4482 5.06478L10.3467 2.94494ZM14.5921 12.8756C14.731 12.8746 14.8647 12.9288 14.9637 13.0263C15.0627 13.1238 15.1189 13.2567 15.12 13.3956C15.121 13.5346 15.0668 13.6682 14.9693 13.7672C14.8718 13.8662 14.7389 13.9224 14.6 13.9235L4.30987 14C4.17092 14.001 4.03724 13.9468 3.93824 13.8493C3.83925 13.7518 3.78305 13.6189 3.78201 13.48C3.78097 13.341 3.83517 13.2073 3.93269 13.1083C4.03021 13.0093 4.16306 12.9531 4.30201 12.9521L14.5921 12.8756Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default AddressEditIcon;
