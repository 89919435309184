import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ErrorIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 176 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 177 176"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.0924 80.8594L56.5508 108.555C56.5508 108.555 57.2236 109.326 57.9889 109.515C58.7563 109.702 59.6162 109.307 59.6162 109.307L63.6096 81.496L60.0924 80.8594Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.6106 49.0474C44.7748 63.3119 50.4904 76.5191 60.3506 78.4894C70.2118 80.4576 80.5409 70.4561 83.3777 56.1926C86.2124 41.9291 80.4968 28.7219 70.6366 26.7516C60.7915 24.7812 50.4464 34.7839 47.6095 49.0474H47.6106ZM62.7661 85.562C61.5186 85.562 60.2669 85.4413 59.0173 85.1923C45.4718 82.4857 37.3548 65.672 40.9229 47.7136C40.9229 47.7125 40.924 47.7125 40.924 47.7125C44.4954 29.7562 58.4215 17.3445 71.9692 20.05C85.5158 22.7565 93.6327 39.5703 90.0646 57.5287C86.8213 73.8326 75.0448 85.562 62.7661 85.562Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.8984 72.1877L137.404 67.2812L137.967 149.459L79.8984 154.623V72.1877Z"
          fill="#00BDD7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M95.0488 81.2812C91.3313 81.2812 88.3062 84.1961 88.3062 87.7806C88.3062 91.3639 91.3313 94.2799 95.0488 94.2799C98.7673 94.2799 101.792 91.3639 101.792 87.7806C101.792 84.1961 98.7673 81.2812 95.0488 81.2812ZM95.0495 95.7092C90.5418 95.7092 86.875 92.1526 86.875 87.7804C86.875 83.4081 90.5418 79.8516 95.0495 79.8516C99.5571 79.8516 103.225 83.4081 103.225 87.7804C103.225 92.1526 99.5571 95.7092 95.0495 95.7092Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.481 84.6957C107.113 84.6957 106.799 84.413 106.768 84.04C106.736 83.6466 107.029 83.3016 107.423 83.2683L126.137 81.7142C126.518 81.6765 126.876 81.9732 126.91 82.3666C126.942 82.761 126.649 83.106 126.254 83.1383L107.541 84.6935C107.521 84.6946 107.501 84.6957 107.481 84.6957Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.512 91.7116C107.144 91.7116 106.831 91.4289 106.799 91.056C106.767 90.6626 107.06 90.3176 107.454 90.2843L126.169 88.7301C126.549 88.6903 126.909 88.9891 126.942 89.3825C126.974 89.777 126.682 90.122 126.286 90.1542L107.572 91.7095C107.552 91.7105 107.532 91.7116 107.512 91.7116Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.9964 106.04C87.6325 106.04 87.3214 105.765 87.2848 105.395C87.246 105.003 87.5334 104.652 87.9275 104.614L127.091 100.747C127.478 100.703 127.836 100.994 127.875 101.387C127.914 101.781 127.626 102.13 127.232 102.169L88.0674 106.037C88.0437 106.039 88.0201 106.04 87.9964 106.04Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.5823 114.673C88.2184 114.673 87.9073 114.398 87.8707 114.028C87.8319 113.636 88.1194 113.286 88.5134 113.247L127.677 109.38C128.062 109.333 128.422 109.627 128.461 110.021C128.5 110.413 128.212 110.763 127.818 110.802L88.6534 114.67C88.6297 114.672 88.606 114.673 88.5823 114.673Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M89.1643 123.305C88.8005 123.305 88.4893 123.03 88.4527 122.66C88.414 122.268 88.7014 121.917 89.0954 121.879L128.26 118.012C128.65 117.971 129.004 118.259 129.044 118.652C129.083 119.046 128.795 119.395 128.401 119.434L89.2354 123.302C89.2117 123.304 89.188 123.305 89.1643 123.305Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M89.7503 131.938C89.3864 131.938 89.0753 131.662 89.0387 131.293C88.9999 130.9 89.2873 130.55 89.6814 130.511L128.846 126.645C129.234 126.6 129.591 126.892 129.63 127.286C129.669 127.678 129.381 128.028 128.987 128.067L89.8213 131.934C89.7976 131.936 89.774 131.938 89.7503 131.938Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.3323 140.573C89.9684 140.573 89.6573 140.298 89.6207 139.928C89.5819 139.536 89.8694 139.185 90.2634 139.146L129.427 135.278C129.813 135.232 130.172 135.525 130.211 135.919C130.25 136.312 129.962 136.661 129.568 136.7L90.4034 140.569C90.3797 140.572 90.356 140.573 90.3323 140.573Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default ErrorIcon;
