import Filter, { FilterOperation, IFilterField, IFilterOptions, IFilterType } from "@/components/common/filter";
import { TableRecord } from "@/components/table/interface";
import { IAnalyticsTypes, loadAnalyticsData } from "@/data/analytics";
import { IObject } from "@/types/common-types";
import { getPaymentMethodIcon } from "@/utils/account-balance-untils";
import { handleNumber, transformField } from "@/utils/field-utils";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DetailsModal from "../components/details-modal";
import Button from "@/components/common/button";
import Table from "@/components/table";

interface ITransactionsDetailsProps {
  visible: boolean;
  startDate: string;
  endDate: string;
  total: string;
  businessID: string;
  currency: string;
  type: IAnalyticsTypes;
  openExportModal: () => void;
  onCancel: () => void;
}

const TransactionsDetails: React.FC<ITransactionsDetailsProps> = (props) => {
  const {
    visible,
    onCancel,
    startDate,
    endDate,
    total,
    businessID,
    currency,
    type,
    openExportModal,
  } = props;

  const { t } = useTranslation(['analytics', 'transaction_advice']);

  const [searchValue, setSearchValue] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [filterOptions, setFilterOptions] = useState<IFilterOptions[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [records, setRecords] = useState<IObject[]>([]);
  const [pageSize, setPageSize] = useState<number>(30);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (visible) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const filterFields: IFilterField[] = [
    {
      type: IFilterType.MULTISELECT,
      name: 'payment_type',
      label: t('analytics:payment_type'),
      operations: [FilterOperation.EQUALS],
      options: [
        {
          label: t('transaction_advice:sales'),
          name: 'sale',
        },
        {
          label: t('transaction_advice:void'),
          name: 'void',
        },
        {
          label: t('transaction_advice:refund'),
          name: 'refund',
        },
        {
          label: t('transaction_advice:pre_auth'),
          name: 'pre_auth',
        },
        {
          label: t('transaction_advice:pre_auth_complete'),
          name: 'pre_auth_complete',
        },
      ],
    },
    {
      name: 'amount',
      type: IFilterType.NUMBER,
      label: t('analytics:amount'),
      operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
    },
    {
      type: IFilterType.MULTISELECT,
      name: 'payment_method',
      label: t('common:payment_method'),
      operations: [FilterOperation.EQUALS],
      options: [
        {
          label: t('transaction_advice:mastercard'),
          name: 'mastercard',
        },
        {
          label: t('transaction_advice:visa'),
          name: 'visa',
        },
        {
          label: t('transaction_advice:union_pay'),
          name: 'unionpay',
        },
        {
          label: t('transaction_advice:wechat_pay'),
          name: 'wechat',
        },
        {
          label: t('transaction_advice:alipay'),
          name: 'alipay',
        },
        {
          label: t('transaction_advice:unionpay_wallet'),
          name: 'unionpay_wallet',
        },
        {
          label: t('transaction_advice:fps'),
          name: 'fps',
        },
        {
          label: t('transaction_advice:octopus'),
          name: 'octopus',
        },
        {
          label: t('transaction_advice:discover'),
          name: 'discover',
        },
        {
          label: t('transaction_advice:diners_club'),
          name: 'diners',
        },
        {
          label: t('transaction_advice:american_express'),
          name: 'amex',
        },
        {
          label: t('transaction_advice:jcb'),
          name: 'jcb',
        },
      ],
    },
    {
      type: IFilterType.DATE,
      name: 'transaction_date',
      label: t('analytics:transaction_date'),
      operations: [FilterOperation.EQUALS, FilterOperation.IS_BETWEEN],
    },
  ];

  const handleType = (record: IObject) => {
    const { payment_type: type = '', void_at } = record;
    let className = '';
    let newType = type;

    if (void_at) {
      newType = 'void';
    }

    switch (newType) {
      case 'sale':
        className = 'bg-[#4CD964] text-[#4CD964]';
        newType = 'sales';
        break;
      case 'void':
        className = 'text-icon-color bg-icon-color';
        break;
      case 'refund':
      case 'representment':
        className = 'text-primary-color bg-primary-color';
        break;
      case 'pre_auth':
      case 'Increased Pre-auth':
        newType = 'pre-auth';
        className = 'text-[#F59E0B] bg-[#F59E0B]';
        break;
      case 'pre_auth_completed':
      case 'pre_auth_complete':
        newType = 'pre-auth completed';
        className = 'text-[#4CD964] bg-[#4CD964]';
        break;
      case 'hold':
        className = 'text-[#CE0310] bg-[#CE0310]';
        break;
      case 'tips':
        className = 'text-[#EE8888] bg-[#EE8888]';
        break;
      case 'pre_dispute':
        newType = 'pre-dispute';
        className = 'text-[#6780DA] bg-[#6780DA]';
        break;
      default:
        break;
    }
    return {
      newType,
      className,
    };
  };

  const getPaymentMethod = (record: IObject, getCreditCard = true) => {
    const {
      payment_method,
      consumer_identify,
    } = record;
    let value = payment_method;
    let iconNode = null;

    switch (value) {
      case 'mastercard':
        value = 'MasterCard';
        break;
      case 'wechat':
        value = 'WeChat';
        break;
      case 'amex':
        value = 'AMEX';
        break;
      case 'diners':
        value = 'Diners Club';
        break;
      case 'jcb':
        value = 'JCB';
        break;
      case 'fps':
        value = 'FPS';
        break;
      case 'cup':
        value = 'UnionPay';
        break;
      case 'crypto_coin':
      case 'corporate_income':
        value = 'Corporate Income';
        break;
      default:
        value = transformField(value);
        break;
    }

    if (getCreditCard) {
      if (!['wechat', 'alipay', 'unionpay wallet', 'unionpay_wallet'].includes(payment_method) && consumer_identify.length > 4) {
        const num = consumer_identify.slice(-4);
        value = `${value}***${num}`;
      }
    } else {
      iconNode = getPaymentMethodIcon(payment_method);
    }

    return {
      value,
      iconNode,
    };
  };

  const columns: ColumnsType<TableRecord> = [
    {
      key: 'business_name',
      dataIndex: 'business_name',
      title: t('analytics:business_name').toUpperCase(),
      width: 180,
    },
    {
      key: 'txn_id',
      dataIndex: 'txn_id',
      title: t('analytics:transaction_id').toUpperCase(),
      align: 'left',
      width: 156,
    },
    {
      key: 'order_reference_number',
      dataIndex: 'order_reference_number',
      title: t('analytics:reference_number').toUpperCase(),
      align: 'left',
      width: 170,
    },
    {
      key: 'payment_scheme',
      title: t('common:payment_scheme').toUpperCase(),
      align: 'left',
      width: 120,
      render: (_key, record) => {
        const { value, iconNode } = getPaymentMethod(record, false);

        return (
          <div className="flex items-center">
            {iconNode}
            <span className="whitespace-nowrap ml-1">{value}</span>
          </div>
        );
      },
    },
    {
      key: 'payment_method',
      title: t('common:payment_method').toUpperCase(),
      align: 'left',
      width: 120,
      render: (_key, record) => {
        const { value } = getPaymentMethod(record);

        return (
          <span className="whitespace-nowrap text-color-text-1">{value}</span>
        );
      },
    },
    {
      key: 'payment_type',
      width: 60,
      title: t('analytics:type').toUpperCase(),
      render: (_key, record) => {
        const { has_tips_data = false } = record;
        const { newType, className } = handleType(record);

        const hasTips = has_tips_data && ['sales', 'void'].includes(newType);
        const tipsColor = newType === 'sales' ? '#EE8888' : '#828282'

        return (
          <span className="flex items-center">
            <span className={`${className} px-3 py-1.5 text-xs whitespace-nowrap rounded-3xl bg-opacity-10`}>
              {newType.toUpperCase()}
            </span>
            {hasTips && <span className={`ml-1 px-3 py-1.5 text-xs whitespace-nowrap rounded-3xl text-[${tipsColor}] bg-[${tipsColor}] bg-opacity-10`}>TIPS</span>}
          </span>
        );
      },
    },
    {
      key: 'tran_amount',
      title: t('analytics:transaction_amount').toUpperCase(),
      align: 'right',
      width: 156,
      render: (_key, record) => {
        const { tran_amount = '', tran_currency = '' } = record;
        const { newType } = handleType(record);
        let value = handleNumber(tran_amount);
        if (value.indexOf('-') === -1) {
          value = `+${value}`;
        }

        let className = '';
        let wrapperClass = '';

        if (newType === 'void') {
          className = 'line-through';
          wrapperClass = 'text-color-text-4';
        } else if (newType === 'refund') {
          className = 'text-error-color';
        }

        return (
          <span className={`whitespace-nowrap ${wrapperClass}`}>
            <span className={className}>{value}</span> {tran_currency}
          </span>
        );
      },
    },
    {
      key: 'created_at',
      title: t('analytics:transaction_time').toUpperCase(),
      align: 'right',
      width: 156,
      render: (_key, record) => {
        const { created_at } = record;
        const ymdRes = dayjs(created_at).format('MMM D, YYYY');
        const timeRes = dayjs(created_at).format('HH:mm:ss');
        return (
          <div className="flex flex-col">
            <span className="whitespace-nowrap">{`${ymdRes}`}</span>
            <span className="whitespace-nowrap">{`${timeRes}`}</span>
          </div>
        );
      },
    },
  ];

  const handleExport = () => {
    openExportModal();
    onCancel();
  };

  const getFilter = (searchValue: string, options: IFilterOptions[]) => {
    const filterObj: any = {};

    if (searchValue) {
      filterObj.keyword = searchValue;
    }

    options.forEach((option) => {
      const { operation, name, value, type } = option;
      if (value) {
        if (type === IFilterType.MULTISELECT) {
          filterObj[name] = value.split(',');
        } else {
          let firstValue = '';
          let secondValue = '';
          if (operation === FilterOperation.IS_BETWEEN) {
            const values = value.split('&');
            firstValue = values[0] || '';
            secondValue = values[1] || '';
          } else if (operation === FilterOperation.EQUALS) {
            firstValue = value;
            secondValue = value;
          }

          if (name === 'transaction_date') {
            firstValue && (filterObj.transaction_start_date = firstValue);
            secondValue && (filterObj.transaction_end_date = secondValue);
          } else if (name === 'amount') {
            firstValue && (filterObj.amount_range_begin = Number(firstValue));
            secondValue && (filterObj.amount_range_end = Number(secondValue));
          }
        }
      }
    });

    return filterObj;
  };

  const loadData = async (
    currentPage = page,
    limit = pageSize,
    search = searchValue,
    options: IFilterOptions[] = filterOptions
  ) => {
    setLoading(true);
    setSearchValue(search);
    setFilterOptions(options);

    const filter = getFilter(search, options);

    const resp = await loadAnalyticsData(
      {
        end_date: endDate,
        start_date: startDate,
        p_business_id: businessID,
        currency_code: currency,
        type,
        detail: true,
        offset: (currentPage - 1) * limit,
        limit: limit + 1,
        ...filter,
      }
    );
    const totalResp = await loadAnalyticsData(
      {
        end_date: endDate,
        start_date: startDate,
        p_business_id: businessID,
        currency_code: currency,
        type,
        detail: false,
        offset: (currentPage - 1) * limit,
        limit: limit + 1,
        ...filter,
      }
    );
    console.log('----totalResp', totalResp)
    setTotalAmount(totalResp.total_amount)
    setRecords(resp);
    setPage(currentPage);
    setPageSize(limit);
    // setTotalAmount(resp.total_amount)
    setLoading(false);
  };

  const handleFilter = (
    search?: string | undefined,
    options?: IFilterOptions[]
  ) => {
    loadData(1, 30, search, options);
  };

  const handleCancel = () => {
    setPageSize(30);
    setPage(1);
    onCancel();
    setTimeout(() => {
      setRecords([]);
      setSearchValue('');
      setFilterOptions([]);
    }, 500);
  };

  const hasFilter =
    filterOptions.filter((item) => item.value).length > 0 ||
    searchValue.length > 0;
  const showTotal = hasFilter && records.length > 0;


  return (
    <DetailsModal
      visible={visible}
      title={t('analytics:transaction_overview')}
      onCancel={handleCancel}
    >
      <div className="hidden md:flex justify-between items-center mb-6 mt-2">
        <div className="flex items-cenrer text-color-text-1 truncate">
          <span className="text-xl font-bold">
            {t('analytics:transactions')}
          </span>
          <span className="text-color-text-1 bg-primary-color bg-opacity-10 font-medium px-3 py-1 rounded-lg ml-2 truncate">
            {/* {total} */}
            {handleNumber(totalAmount)} {currency}
          </span>
        </div>
        <div className="flex items-center">
          {!showTotal && (
            <span className="text-color-text-1 text-sm mr-2 font-medium truncate">
              {dayjs(startDate).format('MMM D, YYYY')} to{' '}
              {dayjs(endDate).format('MMM D, YYYY')}
            </span>
          )}
          {showTotal && (
            <span className="text-color-text-1 text-sm mr-2 font-medium truncate">
              {t('analytics:filter_result', { count: records.length })}
            </span>
          )}
          <div className="max-w-[60%] mr-2 md:max-w-max">
            <Filter
              fields={filterFields}
              onFilter={handleFilter}
              searchValue={searchValue}
              options={filterOptions}
              placeholder={t('analytics:filter_placeholder2')}
            />
          </div>
          <Button
            styleType="Border"
            disabled={loading}
            onClick={handleExport}
            className="bg-primary-background-color text-base font-medium px-3 h-10 text-color-text-1"
          >
            {t('common:export')}
          </Button>
        </div>
      </div>
      <div className="flex flex-col mb-4 md:hidden">
        <div className="flex justify-between mt-2">
          <div className="flex flex-col mb-2">
            <span className="text-xl font-bold">
              {t('analytics:transactions')}
            </span>
            <span className="text-color-text-1 w-fit bg-primary-color bg-opacity-10 font-medium px-3 py-1 rounded-lg mt-2">
              {total}
            </span>
          </div>
          <Button
            styleType="Border"
            disabled={loading}
            onClick={handleExport}
            className="bg-primary-background-color text-base font-medium px-3 h-10 text-color-text-1"
          >
            {t('common:export')}
          </Button>
        </div>
        <Filter
          fields={filterFields}
          onFilter={handleFilter}
          searchValue={searchValue}
          options={filterOptions}
          placeholder={t('analytics:filter_placeholder2')}
        />
      </div>
      <Table
        columns={columns}
        records={records}
        loadingData={loading}
        search={hasFilter}
        searchEmptyClass="flex-1 justify-center"
        pagination={{
          className: 'mt-6',
          current: page,
          currentSize: records.length,
          pageSize,
          pageSizeOptions: [30, 60, 120],
          changePageCallback: loadData,
        }}
      />
    </DetailsModal>
  );
};

export default TransactionsDetails;
