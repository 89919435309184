import { getAllRecords } from "@/data/analytics";
import { IReducersState } from "@/reducers";
import { IPaymentGatewayReportsRecord } from "@/types/analytics-type";
import { IObject } from "@/types/common-types";
import { findNode } from "@/utils";
import { currencies } from "@/utils/currencies";
import { querySubStore } from "@/utils/gateway-utils";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import type { RangePickerProps } from 'antd/es/date-picker';
import RefreshIcon from "../analytics-icons/refresh-icon";
import Button from "@/components/common/button";
import Select from "@/components/common/select";
import DateRange from "@/components/common/antd/date-range";
import { getDays } from "@/utils/dayjs";
import ExportModal from "../components/export-modal";
import { getBusinessWallets } from "@/data/global-account";
import GatewayHistory from "./gateway-history";
import i18n from "@/i18n";
import ActionType from "@/actions/action-type";
import { IDispatch } from "@/actions";
import axios, { CancelTokenSource } from "axios";

interface ILoadDataParams {
  currency_code?: string;
  start_date?: string;
  end_date?: string;
}

const PaymentGatewayReportsPage = () => {

  const { t } = useTranslation(['analytics', 'transaction_advice']);
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();
  const lang = i18n.language;
  const [searchParams] = useSearchParams();
  const queryStartDate = searchParams.get('startDate');
  const queryEndDate = searchParams.get('endDate');
  const queryCurrecny = searchParams.get('currency');
  
  const { p_business_id: businessID = '' } = useParams();
  const business = useSelector((state: IReducersState) => state.businessState.business);

  const [data, setData] = useState<IObject>({});
  const [time, setTime] = useState<dayjs.Dayjs>();
  const [refreshPage, setRefreshPage] = useState<boolean>(false);
  const [currency, setCurrency] = useState<string>(
    queryCurrecny || 'HKD'
  );
  const [startDate, setStartDate] = useState<string>(
    queryStartDate || dayjs().subtract(7, 'day').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(
    queryEndDate || dayjs().format('YYYY-MM-DD')
  );
  const [records, setRecords] = useState<IPaymentGatewayReportsRecord[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [subStore, setSubStore] = useState<IObject[]>([]);
  const [canChange, setCanChange] = useState<boolean>(true);
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const { ledger_accounts = [] } = data;

  useEffect(() => {
    getSubStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  useEffect(() => {
    cancelTokenSource.current = axios.CancelToken.source();
    loadData({}, cancelTokenSource.current);

    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel('Operation canceled by the user.');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, refreshPage]);

  useEffect(() => {
    getState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const getState = async () => {
    const data = await getBusinessWallets(businessID, lang);
    const { default_currency_code = 'HKD' } = data || {};

    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          name: t('common:analytics'),
          key: '1',
          type: 'label',
        },
        {
          name: t('analytics:payment_gateway_reports'),
          key: '2',
          type: 'label',
        },
      ]
    });
    
    setCurrency(default_currency_code);
    setData(data);
  }

  const getSubStore = async () => {
    if (business.settlement_entity_type === 'Chain Settlement Entity') {
      const data = await querySubStore(businessID);
      setSubStore(data);
    }
  };

  const loadData = async (params: ILoadDataParams, cancelTokenSource?: CancelTokenSource) => {
    if (loading) return;
    setLoading(true);
    const currentDate = dayjs();
    const {
      currency_code = currency,
      start_date = startDate,
      end_date = endDate,
    } = params;

    setStartDate(start_date);
    setEndDate(end_date);

    const allResult = await getAllRecords({
      p_business_id: businessID,
      start_date,
      end_date,
      currency_code,
      active: 'transactions',
    }, cancelTokenSource);
    setCurrency(currency_code);
    setRecords(allResult);
    setLoading(false);
    setTime(currentDate);
  };

  const handleCurrencyChange = (value: string) => {
    if (value !== currency) {
      loadData({ currency_code: value });
    }
  };

  const options: any[] = [];

  if (Array.isArray(ledger_accounts)) {
    ledger_accounts.forEach((item) => {
      const { currency_code } = item.currency || {};
      const currencyobj: any = findNode(
        currencies,
        'currency_code',
        currency_code
      );

      if (currencyobj) {
        options.push(currencyobj);
      }
    });
  }

  const handleOpenExportModal = (canChange = false) => {
    setTimeout(() => {
      setShowModal(true);
      setCanChange(canChange);
    }, 500);
  };

  const handleDateChange = async (dates: any) => {
    if (dates && Array.isArray(dates)) {
      let newStartDate: any = dates[0];
      let newEndDate: any = dates[1];

      if (newStartDate && newEndDate) {
        newStartDate = newStartDate.format('YYYY-MM-DD');
        newEndDate = newEndDate.format('YYYY-MM-DD');
        await loadData({ start_date: newStartDate, end_date: newEndDate });
      }
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const tabs = [
    t('analytics:transaction_history'),
    t('analytics:settlement_history'),
  ];

  const handleItemClick = (index: number) => {
    if (index !== 0) {
      navigate(
        `/${lang}/businesses/${businessID}/analytics/settlement-reports?startDate=${startDate}&endDate=${endDate}&currency=${currency}`
      );
    }
  };

  return (
    <>
      <div className="p-4 flex flex-col md:p-6 lg:px-8 lg:py-6">
        <div className="md:flex justify-between items-center mb-6">
          <div className="text-3xl leading-10 font-bold text-color-text-1">
            {t('analytics:payment_gateway_reports')}
          </div>
          <div
            className={`flex items-start flex-col md:flex-row md:items-center hidden_scrollbar`}
          >
            <div className="flex items-center my-2 md:mr-2 md:my-0">
              <span className="text-xs text-primary-color whitespace-nowrap font-medium">
                {t('analytics:last_update_at', {
                  data: time ? time.format('HH:mm') : '00:00',
                })}
              </span>
              <RefreshIcon
                size={20}
                className={`text-primary-color shrink-0 ml-1 fill-current hover:text-primary-color/70 cursor-pointer`}
                onClick={() => setRefreshPage((refreshPage) => !refreshPage)}
              />
            </div>
            <Button
              styleType="Border"
              onClick={() => handleOpenExportModal(true)}
              className="bg-primary-background-color text-base font-medium px-4 mb-2 md:mr-2 md:mb-0 h-10 text-color-text-1"
            >
              {t('common:export')}
            </Button>
            <div className="bg-primary-background-color">
              <Select
                className="shadow-none"
                options={options}
                dataKey={'currency_code'}
                valueKey={'currency_code'}
                value={currency}
                onChange={handleCurrencyChange}
                renderItem={(item: any) => (
                  <div className="flex" key={item.country_code}>
                    <span className="flex items-center relative w-5 mr-2">
                      <img
                        width="20px"
                        height="20px"
                        src={`/images/country-flag/${
                          item.alpha_2 ? item.alpha_2.toLocaleLowerCase() : 'hk'
                        }.svg`}
                        alt=""
                      />
                    </span>
                    <span className="truncate text-color-text-1 font-medium">
                      {item.currency_code}
                    </span>
                  </div>
                )}
              />
            </div>
            <DateRange
              format="MMM D, YYYY"
              className="bg-primary-background-color h-10 border-border-color shadow-none mt-2 md:ml-2 md:mt-0"
              textColor="text-color-text-1"
              presets={[
                { label: t('analytics:this_week'), value: getDays('Week') },
                {
                  label: t('analytics:this_month'),
                  value: getDays('Month'),
                },
                {
                  label: t('analytics:past_30_days'),
                  value: [dayjs().subtract(30, 'd'), dayjs()],
                },
                { label: t('analytics:this_year'), value: getDays('Year') },
              ]}
              disabledDate={disabledDate}
              allowClear={false}
              size={'large'}
              value={[dayjs(startDate), dayjs(endDate)]}
              onChange={handleDateChange}
            />
          </div>
        </div>
        <div className="flex-1 flex flex-col">
          <div className="flex-1 flex flex-col">
            <div
              className={`flex space-x-2 h-fit overflow-x-auto hidden_scrollbar`}
              aria-label="line-tabs"
            >
              {tabs.map((item, index) => (
                <div
                  key={index}
                  className={`
                    py-2 px-4 rounded-3xl text-sm whitespace-nowrap font-medium
                    ${
                      index === 0
                        ? 'bg-primary-color text-color-text-6'
                        : 'bg-color-text-6 text-color-text-2 cursor-pointer hover:text-primary-color border-border-color border'
                    }  
                  `}
                  onClick={() => handleItemClick(index)}
                >
                  {item}
                </div>
              ))}
            </div>
            <>
              {subStore.length > 0 && (
                <div className="flex-1">
                  <div className="px-4 py-3 inline-block mt-6 text-[#0094FF] font-bold rounded-2xl bg-[#E7F5FF]">
                    {t('analytics:gseid_account')}
                  </div>
                  <div
                    className={`flex w-full overflow-x-auto mt-3 hidden_scrollbar`}
                  >
                    {subStore.map((item: IObject, index: number) => (
                      <div
                        className={`bg-primary-background-color border border-border-color rounded px-5 py-3 ${
                          index < subStore.length - 1 ? 'mr-3' : ''
                        }`}
                        key={item.id}
                      >
                        <div className="whitespace-nowrap text-color-text-1 font-medium">
                          {item.business_name}
                        </div>
                        <div className="whitespace-nowrap text-color-text-2 font-medium my-1">
                          {item.business_legal_name}
                        </div>
                        <div className="whitespace-nowrap text-color-text-2 font-medium">
                          {item.id}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
            <GatewayHistory
              businessID={businessID}
              loading={loading}
              startDate={startDate}
              endDate={endDate}
              currency={currency}
              records={records}
              openExportModal={handleOpenExportModal}
            />
          </div>
        </div>
      </div>
      <ExportModal
        visible={showModal}
        startDate={startDate}
        endDate={endDate}
        canChange={canChange}
        valueKey={'transactions'}
        businessID={businessID}
        onCancel={() => setShowModal(false)}
      />
    </>
  );
};

export default PaymentGatewayReportsPage;
