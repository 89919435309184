import { useTranslation } from "react-i18next";


const NodataContent: React.FC = () => {
    const { t } = useTranslation('analytics');

  return (
    <div className="flex-1 flex flex-col justify-center items-center py-24">
      <div>
        <svg
          width="160"
          height="130"
          viewBox="0 0 160 130"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.2317 12.0173L118.51 33.9645L80.088 124.187L14.071 102.254L53.2317 12.0173Z"
            fill="#E3E7EE"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.487 29.1673C61.4178 27.4013 56.7216 29.1549 55.0188 33.0786C53.3165 37.0011 55.2428 41.6301 59.312 43.3961C63.3825 45.1625 68.0792 43.4078 69.7815 39.4853C71.4842 35.5616 69.5575 30.9337 65.487 29.1673ZM58.6357 44.9604C53.7014 42.819 51.3771 37.184 53.4541 32.3979C55.5312 27.6118 61.2346 25.4607 66.1688 27.602C71.1031 29.7434 73.4286 35.3789 71.3515 40.165C69.2745 44.9511 63.5699 47.1017 58.6357 44.9604Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M77.4821 38.81C77.0791 38.6351 76.8704 38.1769 77.0134 37.7538C77.1649 37.3078 77.6494 37.0693 78.0965 37.22L99.3201 44.4089C99.7552 44.5488 100.007 45.0438 99.8564 45.4903C99.7043 45.9374 99.2199 46.176 98.7721 46.0236L77.5491 38.8363C77.5262 38.8278 77.5045 38.8198 77.4821 38.81Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M74.187 46.5038C73.784 46.3289 73.5753 45.8706 73.7183 45.4475C73.8699 45.0016 74.3543 44.763 74.8014 44.9137L96.0262 52.1032C96.4611 52.2402 96.7128 52.7381 96.5625 53.1845C96.4104 53.6316 95.926 53.8702 95.4782 53.7178L74.254 46.53C74.2311 46.5215 74.2094 46.5135 74.187 46.5038Z"
            fill="white"
          />
          <path
            opacity="0.6"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.018 52.9182C45.6197 52.7453 45.4098 52.2963 45.5454 51.8742C45.6894 51.4264 46.1705 51.1794 46.6202 51.3242L91.3275 65.6959C91.7716 65.8313 92.0256 66.3204 91.8812 66.7694C91.7367 67.2184 91.2561 67.4643 90.8064 67.3194L46.0973 52.9484C46.0704 52.9395 46.044 52.9294 46.018 52.9182Z"
            fill="white"
          />
          <path
            opacity="0.6"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.5588 62.6473C42.1605 62.4745 41.9506 62.0255 42.0862 61.6034C42.2301 61.1555 42.7112 60.9085 43.1609 61.0534L87.8683 75.425C88.3115 75.5559 88.5664 76.0496 88.4219 76.4986C88.278 76.9464 87.7969 77.1934 87.3472 77.0486L42.6381 62.6776C42.6111 62.6687 42.5847 62.6586 42.5588 62.6473Z"
            fill="white"
          />
          <path
            opacity="0.6"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.0925 72.3746C38.6942 72.2018 38.4843 71.7528 38.6199 71.3307C38.7638 70.8828 39.2449 70.6358 39.6946 70.7807L84.4032 85.1528C84.8492 85.2933 85.1001 85.7768 84.9568 86.2264C84.8124 86.6754 84.3318 86.9212 83.8821 86.7764L39.1718 72.4048C39.1449 72.3959 39.1184 72.3859 39.0925 72.3746Z"
            fill="white"
          />
          <path
            opacity="0.6"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.6356 82.0989C35.2373 81.926 35.0275 81.477 35.163 81.0549C35.307 80.6071 35.7881 80.3601 36.2378 80.5049L80.9458 94.8783C81.3915 95.013 81.6439 95.5028 81.4994 95.9518C81.3555 96.3996 80.8744 96.6466 80.4247 96.5018L35.715 82.1291C35.688 82.1202 35.6616 82.1101 35.6356 82.0989Z"
            fill="white"
          />
          <path
            opacity="0.6"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.1677 91.8299C31.7694 91.6571 31.5595 91.2081 31.6951 90.786C31.8391 90.3381 32.3202 90.0911 32.7699 90.236L77.4778 104.606C77.9216 104.739 78.1758 105.231 78.0314 105.68C77.8869 106.129 77.4063 106.375 76.9566 106.23L32.247 91.8602C32.2201 91.8513 32.1937 91.8412 32.1677 91.8299Z"
            fill="white"
          />
          <rect
            x="65.0098"
            y="88.4004"
            width="6.64531"
            height="36.5492"
            rx="3.32265"
            fill="#2779BE"
          />
          <rect
            x="74.9785"
            y="101.688"
            width="6.64531"
            height="23.2586"
            rx="3.32265"
            fill="#2779BE"
          />
          <rect
            x="84.9414"
            y="75.1074"
            width="6.64531"
            height="49.8398"
            rx="3.32265"
            fill="#2779BE"
          />
          <rect
            x="94.918"
            y="90.0566"
            width="6.64531"
            height="34.8879"
            rx="3.32265"
            fill="#2779BE"
          />
          <g opacity="0.85">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M100.36 29.8186C102.746 26.8747 105.976 24.5567 109.841 23.2772C114.681 21.6746 119.693 21.9737 124.067 23.7673L128.963 11.2736C121.855 8.45832 113.756 8.02848 105.93 10.6195C99.4663 12.7596 94.0827 16.6648 90.1374 21.6225L100.36 29.8186Z"
              fill="#FFC737"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M136.615 39.7374L149.927 37.4503C149.643 35.6485 149.209 33.8458 148.617 32.0575C145.305 22.0559 137.768 14.6405 128.644 11.1379L123.797 23.648C129.279 25.7981 133.8 30.2798 135.795 36.3069C136.172 37.4444 136.444 38.5914 136.615 39.7374Z"
              fill="#FD9800"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M149.866 37.0731C151.53 46.8993 148.711 56.7325 142.641 64.1254L132.206 55.7381C135.875 51.2404 137.57 45.2694 136.547 39.3063L149.866 37.0731Z"
              fill="#FFF500"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M142.735 64.0078C138.812 68.8309 133.512 72.6283 127.173 74.728L123.008 62.1502C126.851 60.8244 130.052 58.4663 132.403 55.4901L142.735 64.0078Z"
              fill="#FFF500"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M84.5101 53.2878C80.759 41.9583 83.3395 30.0557 90.3236 21.4166L100.538 29.6272C96.1644 34.8931 94.5229 42.228 96.833 49.2051C100.395 59.9642 112.005 65.7984 122.764 62.2362C122.93 62.1812 123.095 62.1242 123.259 62.0654L127.425 74.6495C127.344 74.677 127.263 74.7043 127.182 74.7312C109.477 80.5932 90.3721 70.9926 84.5101 53.2878Z"
              fill="#0094FF"
            />
          </g>
          <path
            d="M45.0547 42.0117L44.4448 41.5788C43.8709 41.1459 42.6512 40.2801 41.4674 39.8472C40.2835 39.4143 39.0638 39.4143 37.88 37.6826C36.6962 35.951 35.4765 32.4877 34.2927 31.189C33.1089 29.8903 31.8892 30.7561 30.7054 32.2713C29.5216 33.7865 28.3019 35.951 27.118 36.8168C25.9342 37.6826 24.7145 37.2497 23.5307 36.8168C22.3469 36.3839 21.1272 35.951 19.9434 36.3839C18.7596 36.8168 17.5399 38.1155 16.3561 38.9814C15.1722 39.8472 13.9525 40.2801 12.7687 40.2801C11.5849 40.2801 10.3652 39.8472 9.18139 38.7649C7.99758 37.6826 6.77788 35.951 5.59406 35.951C4.41025 35.951 3.19055 37.6826 2.61658 38.5484L2.00674 39.4143"
            stroke="#B033AB"
            strokeWidth="2.86408"
            strokeLinecap="round"
          />
          <rect
            x="2"
            y="49.6465"
            width="59.8274"
            height="3.26899"
            rx="1.6345"
            fill="white"
          />
          <rect
            x="2"
            y="49.6465"
            width="51.9125"
            height="3.26899"
            rx="1.6345"
            fill="#77B726"
          />
          <rect
            x="2"
            y="57.2852"
            width="49.644"
            height="3.26899"
            rx="1.6345"
            fill="white"
          />
          <rect
            x="2"
            y="57.2852"
            width="25.3501"
            height="3.26899"
            rx="1.6345"
            fill="#0094FF"
          />
          <rect
            x="2"
            y="64.9238"
            width="38.1877"
            height="3.26899"
            rx="1.6345"
            fill="white"
          />
          <rect
            x="2"
            y="64.9238"
            width="13.0001"
            height="3.26899"
            rx="1.6345"
            fill="#FF9811"
          />
        </svg>
      </div>
      <div className="font-medium text-color-text-2 mt-8">
        {t('common:no_data_yet')}
      </div>
    </div>
  );
};

export default NodataContent;
