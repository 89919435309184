import { useParams, useNavigate, Link } from 'react-router-dom';
import { avatarColumn, selectColumn } from '@/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@/components/table';
import type { ColumnsType } from 'antd/es/table';
import { TableRecord } from '@/components/table/interface';
import { getPower } from '@/utils/associates-utils';
import { Dropdown, Menu } from 'antd';
import dayjs from 'dayjs';
import { IAssociateAction, IAssociateDetail } from '@/types/associates-type';
import { getRoles, queryAssociate, queryAssociates } from '@/data/associates';
import { getCookie } from '@/utils';
import ActionModal from '../components/action-modal';
import EllipsisIcon from '@/components/svg/ellipsis-icon';
import QRIcon from '@/components/svg/qr-icon';
import { queryBusiness } from '@/data/businesses';
import Button from '@/components/common/button';
import { Constants } from '@/constants';
import PendingAdmissionModal from '../components/pending-admission-modal';
import { IAreaCodes } from '@/types/common-types';
import { loadAreaCodesData } from '@/data/area-codes';
import { IReducersState } from '@/reducers';
import { useDispatch, useSelector } from 'react-redux';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';

const MenuItem = Menu.Item;
const AssociateListPage = () => {
  const { t } = useTranslation('associates');
  const { t: ct } = useTranslation();
  const user = useSelector((state: IReducersState) => state.account.loginUser);
  const permissions = useSelector((state: IReducersState) => state.businessState.permissions);
  const columns: ColumnsType<TableRecord> = [
    {
      key: 'display_name',
      title: t('preferred_name'),
      dataIndex: 'display_name',
      width: 200,
      render: (key: string, record: TableRecord) => {
        return avatarColumn({
          key,
          record: record,
          boundField: 'display_name',
        });
      },
    },
    {
      key: 'full_name',
      title: t('full_name'),
      dataIndex: 'full_name',
      width: 200,
      render(value) {
        return value || '--';
      },
    },
    {
      key: 'job_position',
      title: t('job_title'),
      dataIndex: 'job_position',
      width: 200,
      render(value) {
        return value || '--';
      },
    },
    {
      key: 'role_name',
      title: t('role'),
      dataIndex: 'role_name',
      width: 200,
      render: (value, record) => {
        let roleName = value;
        const translations = record.translations;
        if (Array.isArray(translations)) {
          for (let i = 0; i < translations.length; i++) {
            const item = translations[i];
            if (item.languages_code === lang) {
              roleName = item.role_name;
              break;
            }
          }
        }
        return roleName || '--';
      },
    },
    {
      key: 'status',
      title: t('status'),
      dataIndex: 'status',
      width: 170,
      render: (_key, record) => {
        return selectColumn({
          key: 'status',
          record,
          options: [
            {
              label: 'ACTIVE',
              value: 'active',
              color: 'text-success-color bg-success-color',
            },
            {
              label: 'SUSPENDED',
              value: 'suspended',
              color: 'text-error-color bg-error-color',
            },
            {
              label: 'PENDING ADMISSION',
              value: 'pending_admission',
              color: 'text-primary-color bg-primary-color',
            },
            {
              label: 'IDLE',
              value: 'idle',
              color: 'text-icon-color bg-icon-color',
            },
          ],
        });
      },
    },
    {
      key: 'p_created_at',
      title: t('join_since'),
      dataIndex: 'p_created_at',
      align: 'right',
      width: 140,
      render: (value) => {
        if (value && value.indexOf('T') > -1 && value.indexOf('Z') < 0) {
          value = `${value}Z`;
        }
        if (value) {
          return (
            <span className="whitespace-nowrap">
              {dayjs(value).format(dateFormat)}
            </span>
          );
        } else {
          return <span></span>;
        }
      },
    },
    {
      title: ct('actions'),
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 90,
      render: (_key, record) => {
        const { edit, suspend, remove } = getPower(
          permissions,
          user.id,
          record
        );
        return (
          <Dropdown
            destroyPopupOnHide={true}
            dropdownRender={() => (
              <Menu>
                <MenuItem key="view">
                  <div
                    className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                    onClick={() => {
                      hadleView(record);
                    }}
                  >
                    <span className="text-color-text-1 font-medium">
                      {ct('view')}
                    </span>
                  </div>
                </MenuItem>
                {edit && (
                  <MenuItem key="edit">
                    <Link
                      to={`/${lang}/businesses/${businessID}/associates/${record.id}?isEdit=true`}
                    >
                      <div className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5">
                        <span className="text-color-text-1 font-medium">
                          {ct('edit')}
                        </span>
                      </div>
                    </Link>
                  </MenuItem>
                )}
                {record.status === 'active' && suspend && (
                  <MenuItem key="suspend">
                    <div
                      className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                      onClick={() => {
                        handleActionClick(IAssociateAction.SUSPEND, record);
                      }}
                    >
                      <span className="text-error-color font-medium">
                        {t('suspend')}
                      </span>
                    </div>
                  </MenuItem>
                )}
                {record.status === 'suspended' && suspend && (
                  <MenuItem key="reactivate">
                    <div
                      className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                      onClick={() => {
                        handleActionClick(IAssociateAction.REACTIVATE, record);
                      }}
                    >
                      <span className="text-color-text-1 font-medium">
                        {t('reactivate')}
                      </span>
                    </div>
                  </MenuItem>
                )}
                {remove && (
                  <MenuItem key="delete">
                    <div
                      className="flex items-center cursor-pointer hover:bg-disabled-color leading-10 h-10 px-5"
                      onClick={() => {
                        handleActionClick(IAssociateAction.REMOVE, record);
                      }}
                    >
                      <span className="text-error-color font-medium">
                        {record.status === 'pending_admission'
                          ? t('reject')
                          : ct('remove')}
                      </span>
                    </div>
                  </MenuItem>
                )}
              </Menu>
            )}
          >
            <div className="justify-center flex">
              <div className="flex items-center cursor-pointer p-1.5 rounded-full w-max hover:bg-primary-color/10 group">
                <EllipsisIcon className="text-icon-color group-hover:text-primary-color flex" />
              </div>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const { lang = 'en', p_business_id: businessID = '' } = useParams();
  const dateFormat = lang === 'en' ? 'MMM DD, YYYY' : 'MMM DD日, YYYY';
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();

  const [records, setRecords] = useState<TableRecord[]>([]);
  const [areaCodes, setAreaCodes] = useState<IAreaCodes[]>([]);
  const [roles, setRoles] = useState([]);
  const [recordLoading, setRecordLoading] = useState<boolean>(true);
  const [showActionModal, setShowActionModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  // const [filterOptions, setFilterOptions] = useState<IFilterOptions[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [action, setAction] = useState<IAssociateAction>(
    IAssociateAction.SUSPEND
  );
  const [associate, setAssociate] = useState<any>({});
  const [business, setBusiness] = useState<any>({});
  const [associateDetail, setAssociateDetail] =
    useState<IAssociateDetail | null>(null);

  useEffect(() => {
    loadMenuData();
    loadRecords();
    // eslint-disable-next-line
  }, [businessID, lang])

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          key: '1',
          name: ct('staff_management'),
          type: 'label',
        },
        {
          key: '2',
          name: ct('associate'),
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  const loadMenuData = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    const allPromise: any[] = [
      queryBusiness(businessID),
      loadAreaCodesData(),
      getRoles(token, businessID, lang),
    ];
    const resp = await Promise.all(allPromise);

    setBusiness(resp[0]);
    setAreaCodes(resp[1]);
    setRoles(resp[2]);
  }

  const loadRecords = async (
    current = page,
    limit = pageSize,
    search = searchValue,
    // options: IFilterOptions[] = filterOptions
  ) => {
    setRecordLoading(true);
    const token = getCookie(Constants.TOKEN) || '';
    setSearchValue(search);
    // setFilterOptions(options);
    let filter = `limit=${limit + 1}&offset=${(current - 1) * limit}`;
    // options.forEach((item) => {
    //   const { name, value, operation } = item;
    //   const fileds = name.split('/');
    //   filter = `${filter}&${fileds[fileds.length - 1]}=${value}`;
    // });
    if (filter.indexOf('&status=') === -1) {
      filter = `${filter}&status=active,pending_admission,suspended`;
    }
    try {
      const resp = await queryAssociates({
        businessID,
        lang,
        token,
        filter,
      })
      const { data } = resp || {};

      if (data && Array.isArray(data)) {
        setRecords(data);
        setAssociate(records[0]);
      }
    } catch (error) {
      console.error(error);
    }
    setPage(current);
    setPageSize(limit);
    setRecordLoading(false);
  };

  const handleActionClick = (key: IAssociateAction, record: any) => {
    setAction(key);
    setAssociate(record);
    setShowActionModal(true);
  };

  const handleCallback = async (_key: IAssociateAction, success: boolean) => {
    if (success) {
      await loadRecords();
    }
    setShowActionModal(false);
  };

  const hadleView = async (record: any) => {
    if (
      record.status === 'pending_admission' &&
      permissions.includes('associates:update')
    ) {
      const token = getCookie(Constants.TOKEN) || '';
      setAssociateDetail(
        await queryAssociate({ token, businessID, id: record.id, lang })
      );
    } else {
      navigate(`/${lang}/businesses/${businessID}/associates/${record.id}`);
    }
  };

  const handleQR = () => {
    navigate(`/${lang}/businesses/${businessID}/associates/invite`);
  };

  return (
    <>
      <div className="flex overflow-hidden h-full">
        <div className="bg-system-background-color pt-5 px-3 pb-2.5 md:px-5 md:py-6 lg:px-8 w-full flex flex-col">
          <div className="md:flex mb-4 justify-between md:mb-6">
            <div className="text-xl leading-[30px] md:text-3xl md:leading-10 font-bold text-color-text-1 mb-4 md:mb-0">
              {ct('associate')}
            </div>
            <div className="flex flex-col-reverse md:flex-row">
              {permissions.includes('associates:create') && (
                <div className="flex gap-x-4 justify-start">
                  <Button
                    iconInstance={<QRIcon size={18} />}
                    type="button"
                    onClick={handleQR}
                  >
                    {t('organization_qr')}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <Table
            className="mb-6"
            columns={columns}
            records={records}
            loadingData={recordLoading}
            pagination={{
              current: page,
              pageSize,
              currentSize: records.length,
              changePageCallback: loadRecords,
            }}
            onRowCell={(record, dataKey) => {
              return {
                onClick: (evt) => {
                  evt.stopPropagation();
                  if (dataKey === 'action') return;
                  if (record.id) {
                    hadleView(record);
                  }
                },
              };
            }}
          />
        </div>
      </div>
      {showActionModal && (
        <ActionModal
          action={action}
          businessID={businessID}
          business={business}
          associate={associate}
          onCancel={() => setShowActionModal(false)}
          callback={handleCallback}
        />
      )}
      {associateDetail && (
        <PendingAdmissionModal
          businessID={businessID}
          associate={associateDetail}
          areaCodes={areaCodes}
          roles={roles}
          callback={loadRecords}
          onCancel={() => setAssociateDetail(null)}
        />
      )}
    </>
  )
}

export default AssociateListPage;
