import { Select } from 'antd';
import type { IAntdSelectProps } from './interface';
import DownIcon from '@/components/svg/down-icon';
import './select.css'

export const AntdSelect: React.FC<IAntdSelectProps> = (props) => {
  const { children, error } = props;
  return (
    <>
      <Select
        className="w-full"
        size="large"
        status={error ? 'error' : undefined}
        {...props}
        suffixIcon={<DownIcon size={20} className="text-icon-color" />}
      >
        {children}
      </Select>
      {error && (
        <div className="mt-1 text-sm text-error-color">{error.message}</div>
      )}
    </>
  );
};

export const Option = Select.Option;
