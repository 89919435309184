import { useMemo, useState } from 'react';
import type { IAccountDetailProps } from './type';
import { Controller } from 'react-hook-form';
import Config from '@/libs/config';
import { useTranslation } from 'react-i18next';
import PayeeItem from '@/pages/payouts/components/payee-item';
import { getCountryByCurrency } from '@/utils/payouts-utils';
import EyeIcon from './related-company-icons/eye-icon';
import EyeOffIcon from '@/pages/account-balance/account-balances-icons/eye-off-icon';
import Skeleton from '@/components/skeleton';
import Select from '@/components/common/select/select';
import Label from '@/components/common/label';
import Input from '@/components/common/input';
import { useParams } from 'react-router-dom';

const BankAccountDetails = (props: IAccountDetailProps) => {
  const {
    isView,
    loading = true,
    transferMethod,
    areaCodes = [],
    relatedFormData,
    setRelatedFormDate,
    isAdd = false,
    banks,
    useFormReturn,
  } = props;

  const {
    nickname,
    currency,
    payout_accounts,
    bank,
    identifier_2_value,
    identifier_3_value,
    country,
  } = relatedFormData;

  const { setValue } = useFormReturn;

  const {
    country_codes,
    verification_method,
    verified_at,
    transfer_methods = {},
  } = payout_accounts || {};

  const { country_name = '' } = country_codes || {};

  const getLang = () => {
    let langCode: string = lang;
    if (lang === 'en') {
      langCode = 'en-US';
    }
    return langCode;
  };

  const getCountryName = () => {
    const list: any[] = [];
    if (areaCodes && Array.isArray(areaCodes)) {
      areaCodes.forEach((item1) => {
        const { translations = [], isoAlpha2Code } = item1;
        if (isoAlpha2Code === country && translations.length > 0) {
          const value = translations.find(
            (item: any) => item['languages_code'] === getLang()
          );
          if (value != null) {
            list.push(value);
          }
        }
      });
    }
    return list.length > 0
      ? list[0]['country_name']
      : country_name || 'Hong Kong';
  };

  const [accountNumberShow, setAccountNumberShow] = useState(false);

  const { t } = useTranslation(["settings", "business_account_settings", "account_balance"]);
  const { lang = 'en' } = useParams();

  const verificationSucess = !(
    verification_method != null && verified_at == null
  );
  let className = 'text-warning-color bg-warning-color';
  let status = t('related_company_accounts:unverified');
  if (!verificationSucess) {
    className = 'text-error-color bg-error-color';
    status = t('related_company_accounts:failed_verification');
  } else if (verification_method != null && verified_at != null) {
    className = 'text-primary-color bg-primary-color ';
    status = t('related_company_accounts:verified');
  }

  const accountNumber = (number: string) => {
    if (accountNumberShow) {
      return number;
    } else {
      if (number.trim().length < 3) {
        return `*********${number}`;
      }
      return `*********${number.substring(number.length - 3)}`;
    }
  };
  const prefix = Config.staticPath;

  const getCurrencyOptions = useMemo(() => {
    const options: any[] = [];
    const { to_currency_codes = [] } = transferMethod || {};

    to_currency_codes.forEach((item: any) => {
      const { currency_code, is_active } = item;
      if (is_active) {
        options.push({
          value: currency_code,
          label: currency_code,
        });
      }
    });
    return options;
  }, [transferMethod]);

  const handleBankChange = (id: any) => {
    let identifier_1_type: string = transfer_methods.identifier_1_type || '';
    let identifier_2_type: string = transfer_methods.identifier_2_type || '';
    const identifier_3_type: string = transfer_methods.identifier_3_type || '';

    if (isAdd) {
      identifier_1_type = transferMethod.identifier_1_type;
      identifier_2_type = transferMethod.identifier_2_type;
      identifier_2_type = transferMethod.identifier_3_type;
    }

    const name: any = 'identifier_1_value';
    if (identifier_1_type === 'Bank') {
      relatedFormData.identifier_1_value = id;
    } else if (identifier_2_type === 'Bank') {
      relatedFormData.identifier_2_value = id;
    } else if (identifier_3_type === 'Bank') {
      relatedFormData.identifier_3_value = id;
    }
    setRelatedFormDate({
      ...relatedFormData,
      bank: {
        id: id,
        country: '',
        bank_code: '',
        bank_name: '',
        swift_code: '',
      },
    });
    setValue(name, id);
  };

  const handleCurrency = (currency: any) => {
    setRelatedFormDate({
      ...relatedFormData,
      currency,
    });
    setValue(currency, currency);
  };

  const handleHoldername = (identifier_2_value: any) => {
    setRelatedFormDate({
      ...relatedFormData,
      identifier_2_value,
    });
    setValue('identifier_2_value', identifier_2_value);
  };

  const handleAccountNumber = (identifier_3_value: any) => {
    setRelatedFormDate({
      ...relatedFormData,
      identifier_3_value,
    });
    setValue('identifier_3_value', identifier_3_value);
  };

  const handleNickName = async (nickname: any) => {
    setRelatedFormDate({
      ...relatedFormData,
      nickname,
    });
    setValue('nickname', nickname);
  };

  const isExist = (type: string) => {
    const { identifiers } = transferMethod;
    const exist: any[] = [];

    if (Array.isArray(identifiers) && identifiers.length > 0) {
      identifiers.forEach((item) => {
        if (item['type'] === type) {
          exist.push(item);
        }
      });
    }
    if (exist.length > 0) {
      return exist[0];
    }
  };

  const existIdentifier = (type: string) => {
    const { id, i18n = {} } = relatedFormData;
    if (id != null) {
      const {
        identifier_1_type,
        identifier_2_type,
        identifier_3_type,
        identifier_1_name,
        identifier_2_name,
        identifier_3_name,
      } = transfer_methods;
      if (type === identifier_1_type) {
        return i18n[identifier_1_name][getLang()] || identifier_1_name;
      } else if (type === identifier_2_type) {
        return i18n[identifier_2_name][getLang()] || identifier_2_name;
      } else if (type === identifier_3_type) {
        return i18n[identifier_3_name][getLang()] || identifier_3_name;
      }
    } else {
      const data = isExist(type);
      if (data != null) {
        const { i18n } = transferMethod;
        const value = i18n[data['label']];
        let typeValue = data['label'];
        if (value != null) {
          const langValue = value[getLang()];
          if (langValue != null) {
            typeValue = langValue;
          }
        }
        return typeValue;
      }
    }
    return null;
  };

  return (
    <>
      <div className="p-6 rounded bg-white mb-6 shadow-dropdown">
        {loading && (
          <Skeleton
            row={1}
            className={`mr-0`}
            width={['20%']}
            skeletonItemClass={`h-6 rounded-2xl`}
          />
        )}
        {!loading && (
          <>
            <div className="font-bold text-xl text-color-text-1 mb-1 h-6">
              {t('related_company_accounts:bank_details')}
            </div>
            {!isAdd &&
              !(verification_method != null && verified_at != null) && (
                <div className="font-medium text-color-text-2 text-sm">
                  {!verificationSucess
                    ? t('related_company_accounts:bank_details_tip')
                    : t(
                      'related_company_accounts:bank_details_unverified_tips'
                    )}
                </div>
              )}
          </>
        )}
        <div className="mt-4 h-[1px] w-[100%] bg-[#E2E8F0] mb-6" />
        <div className="grid">
          <div className="grid gap-x-6 sm:grid-cols-2 md:grid-cols-3 md:mb-6">
            {loading &&
              new Array(3).fill('').map((r, i) => {
                return (
                  <div
                    className="flex flex-col flex-1 h-full md:mb-3 mb-9"
                    key={`${r}_${i}`}
                  >
                    <div className="h-4 mb-4">
                      <Skeleton
                        row={1}
                        skeletonItemClass={`mb-0 h-full rounded-2xl`}
                      />
                    </div>
                    <div className="h-6">
                      <Skeleton
                        row={1}
                        className={`mr-0`}
                        width={['80%']}
                        skeletonItemClass={`h-6 rounded-2xl`}
                      />
                    </div>
                  </div>
                );
              })}
            {!loading && (
              <>
                <PayeeItem
                  title={t('related_company_accounts:country')}
                  className="md:mb-0 mb-4"
                  content={
                    <div className="flex">
                      <span className="flex items-center relative w-4 mr-2">
                        <img
                          // layout="intrinsic"
                          width="16px"
                          height="16px"
                          src={`${prefix}/images/country-flag/${country.toLowerCase()}.svg`}
                          alt=""
                        />
                      </span>
                      <span className="text-color-text-1 text-sm font-medium">
                        {getCountryName()}
                      </span>
                    </div>
                  }
                />
                {!isView && (
                  <div className={isAdd ? '' : 'md:mb-0 sm:mb-0 mb-4'}>
                    <Label
                      value={t('related_company_accounts:currency')}
                      required={true}
                    ></Label>
                    <Controller
                      name={'currency'}
                      defaultValue={currency}
                      control={useFormReturn.control}
                      rules={{
                        validate: (value) => {
                          let msg = undefined;
                          if (value === null || value.length === 0) {
                            msg = t(
                              'related_company_accounts:field_is_required'
                            );
                          }
                          return msg;
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Select
                          error={error}
                          options={getCurrencyOptions}
                          value={currency}
                          showSearch={true}
                          onChange={(value) => {
                            handleCurrency(value);
                            onChange(value);
                          }}
                          renderItem={(item) => {
                            const { value, label } = item;
                            return (
                              <div className="flex">
                                <span className="flex items-center relative w-4 mr-1.5">
                                  <img
                                    // layout="intrinsic"
                                    width="16px"
                                    height="16px"
                                    src={`${prefix}/images/country-flag/${getCountryByCurrency(
                                      value
                                    )}.svg`}
                                    alt=""
                                  />
                                </span>
                                <span className="text-color-text-1 text-sm font-medium">
                                  {label}
                                </span>
                              </div>
                            );
                          }}
                        ></Select>
                      )}
                    />
                  </div>
                )}
                {isView && (
                  <PayeeItem
                    title={t('related_company_accounts:currency')}
                    className="md:mb-0 mb-4"
                    content={
                      <div className="flex">
                        <span className="flex items-center relative w-4 mr-2">
                          <img
                            // layout="intrinsic"
                            width="16px"
                            height="16px"
                            src={`${prefix}/images/country-flag/${getCountryByCurrency(
                              currency ?? ''
                            )}.svg`}
                            alt=""
                          />
                        </span>
                        <span className="text-color-text-1 text-sm font-medium">
                          {currency}
                        </span>
                      </div>
                    }
                  />
                )}
                {!isAdd && (
                  <PayeeItem
                    title={t('related_company_accounts:status')}
                    className={!isView ? '' : 'md:mb-0 mb-4'}
                    content={
                      <span
                        className={`px-3 py-1.5 rounded-3xl text-xs font-medium whitespace-nowrap bg-opacity-10 ${className}`}
                      >
                        {status.toLocaleUpperCase()}
                      </span>
                    }
                  />
                )}
              </>
            )}
          </div>
          {loading && <div className="h-1"></div>}
          {(existIdentifier('Bank') || existIdentifier('String')) && (
            <div className="grid gap-x-6 sm:grid-cols-2 md:grid-cols-3 md:mb-6">
              {loading &&
                new Array(2).fill('').map((r, i) => {
                  return (
                    <div
                      className="flex flex-col flex-1 h-full md:mb-3 mb-9"
                      key={`${r}_${i}`}
                    >
                      <div className="h-4 mb-4">
                        <Skeleton
                          row={1}
                          skeletonItemClass={`mb-0 h-full rounded-2xl`}
                        />
                      </div>
                      <div className="h-6">
                        <Skeleton
                          row={1}
                          className={`mr-0`}
                          width={['80%']}
                          skeletonItemClass={`h-6 rounded-2xl`}
                        />
                      </div>
                    </div>
                  );
                })}
              {!loading && (
                <>
                  {existIdentifier('Bank') != null && (
                    <>
                      {!isView && (
                        <div className="md:mt-0 mt-4">
                          <Label
                            value={existIdentifier('Bank')}
                            required={true}
                          />
                          <Controller
                            name={'bank'}
                            control={useFormReturn.control}
                            defaultValue={bank?.id || ''}
                            rules={{
                              validate: (value) => {
                                let msg = undefined;
                                if (value === null || value.length === 0) {
                                  msg = t(
                                    'related_company_accounts:field_is_required'
                                  );
                                }
                                return msg;
                              },
                            }}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => {
                              return (
                                <Select
                                  key={bank?.id}
                                  error={error}
                                  options={banks}
                                  value={bank?.id || ''}
                                  showSearch={true}
                                  dataKey="id"
                                  valueKey="bank_code"
                                  height={error == null ? '' : 'mb-0'}
                                  itemHeight=""
                                  valueClassName="w-[90%]"
                                  valueSpanClassName="w-[100%] h-10"
                                  onChange={(value) => {
                                    onChange(value);
                                    handleBankChange(value);
                                  }}
                                  placeholder={t('settings:enter_bank')}
                                  renderValueItem={(item: any) => {
                                    const { bank_code } = item;
                                    return (
                                      <div
                                        className={`text-color-text-1 text-sm font-medium text-start py-2 text-ellipsis overflow-hidden whitespace-nowrap`}
                                      >
                                        {bank_code || ''}
                                      </div>
                                    );
                                  }}
                                  renderItem={(item: any) => {
                                    const { bank_code } = item;
                                    return (
                                      <div
                                        className={`text-color-text-1 text-sm font-medium text-start py-2`}
                                      >
                                        {bank_code || ''}
                                      </div>
                                    );
                                  }}
                                ></Select>
                              );
                            }}
                          />
                        </div>
                      )}
                      {isView && (
                        <PayeeItem
                          title={existIdentifier('Bank')}
                          content={
                            (bank?.bank_code || '--') +
                            ' ' +
                            (bank?.bank_name || '--')
                          }
                          contentClass="py-2 font-medium pr-6 text-sm"
                          className="md:mb-0 mb-4"
                        />
                      )}
                    </>
                  )}
                  {existIdentifier('String') != null && (
                    <>
                      {!isView && (
                        <div className="md:mt-0 mt-4">
                          <Label
                            required={true}
                            value={existIdentifier('String')}
                          ></Label>
                          <Controller
                            name={'identifier_2_value'}
                            control={useFormReturn.control}
                            defaultValue={identifier_2_value}
                            rules={{
                              validate: (value) => {
                                let msg = undefined;
                                if (value === null || value.length === 0) {
                                  msg = t(
                                    'related_company_accounts:field_is_required'
                                  );
                                }
                                return msg;
                              },
                            }}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => {
                              return (
                                <Input
                                  error={error}
                                  value={identifier_2_value}
                                  placeholder={`${t(
                                    'related_company_accounts:enter'
                                  )}${existIdentifier('String')}`}
                                  onChange={(value) => {
                                    handleHoldername(value);
                                    onChange(value);
                                  }}
                                />
                              );
                            }}
                          />
                        </div>
                      )}
                      {isView && (
                        <PayeeItem
                          title={existIdentifier('String')}
                          content={identifier_2_value}
                          contentClass="py-2 font-medium pr-6 text-sm"
                          className="md:mb-0 mb-4"
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {loading && <div className="h-1"></div>}
          <div className={`grid gap-x-6 sm:grid-cols-2 md:grid-cols-3`}>
            {loading &&
              new Array(2).fill('').map((r, i) => {
                return (
                  <div
                    className="flex flex-col flex-1 h-full"
                    key={`${r}_${i}`}
                  >
                    <div className="h-4 mb-4">
                      <Skeleton
                        row={1}
                        skeletonItemClass={`mb-0 h-full rounded-2xl`}
                      />
                    </div>
                    <div className="h-6">
                      <Skeleton
                        row={1}
                        className={`mr-0`}
                        width={['80%']}
                        skeletonItemClass={`h-6 rounded-2xl`}
                      />
                    </div>
                  </div>
                );
              })}
            {!loading && (
              <>
                {existIdentifier('Account Number') != null && (
                  <>
                    {!isView && (
                      <div className="md:mt-0 mt-4">
                        <Label
                          required={true}
                          value={existIdentifier('Account Number')}
                        ></Label>
                        <Controller
                          name={'identifier_3_value'}
                          control={useFormReturn.control}
                          defaultValue={identifier_3_value || ''}
                          rules={{
                            validate: (value) => {
                              let msg = undefined;
                              if (value === null || value.length === 0) {
                                msg = t(
                                  'related_company_accounts:field_is_required'
                                );
                              } else if (
                                !RegExp(/^[a-zA-Z0-9]+$/).test(value)
                              ) {
                                msg = t(
                                  'related_company_accounts:please_enter_data'
                                );
                              }
                              return msg;
                            },
                          }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => {
                            return (
                              <Input
                                error={error}
                                name="related_company_accounts:account_number"
                                value={identifier_3_value || ''}
                                placeholder={`${t(
                                  'related_company_accounts:enter'
                                )}${existIdentifier('Account Number')}`}
                                onChange={(value) => {
                                  handleAccountNumber(value);
                                  onChange(value);
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                    )}
                    {isView && (
                      <div className="w-full md:mb-0 sm:mb-0 mb-4">
                        <label
                          className={`text-sm flex items-center mb-2 font-medium text-color-text-2`}
                        >
                          <span className="mr-1">
                            {existIdentifier('Account Number')}
                          </span>
                          {accountNumberShow && (
                            <EyeIcon
                              className="cursor-pointer text-primary-color hover:text-primary-color"
                              onClick={() =>
                                setAccountNumberShow(!accountNumberShow)
                              }
                            />
                          )}
                          {!accountNumberShow && (
                            <EyeOffIcon
                              className="cursor-pointer text-primary-color hover:text-primary-color"
                              onClick={() =>
                                setAccountNumberShow(!accountNumberShow)
                              }
                            />
                          )}
                        </label>
                        <div className="py-1 text-sm font-medium">
                          {accountNumber(identifier_3_value ?? '')}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {!isView && (
                  <div className="md:mt-0 mt-4">
                    <Label
                      required={true}
                      value={t('related_company_accounts:account_nickname')}
                    ></Label>
                    <Controller
                      name={'nickname'}
                      control={useFormReturn.control}
                      defaultValue={nickname}
                      rules={{
                        validate: (value) => {
                          let msg = undefined;
                          if (value.length === 0) {
                            msg = t(
                              'related_company_accounts:field_is_required'
                            );
                          }
                          return msg;
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <Input
                            error={error}
                            value={nickname}
                            placeholder={t(
                              'related_company_accounts:enter_nickname'
                            )}
                            onChange={(value) => {
                              handleNickName(value);
                              onChange(value);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                )}
                {isView && (
                  <PayeeItem
                    title={t('related_company_accounts:account_nickname')}
                    content={nickname}
                    contentClass="py-1 text-sm font-medium"
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BankAccountDetails;
