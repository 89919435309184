import styles from '@/pages/content.module.css';
import Config from '@/libs/config';
import { useParams } from 'react-router-dom';

const PrivacyPolicy = () => {
  const { lang = 'en' } = useParams();
  const prefix = Config.staticPath;

  return (
    <>
      <head>
        <link
          rel="preload"
          href={`${prefix}/font/Outfit-Light.woff`}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="preload"
          href={`${prefix}/font/Outfit-Bold.woff`}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        ></link>
      </head>
      {lang !== 'zh-CN' && lang !== 'zh-HK' && (
        <div className={`${styles.cancleHeader} text-[#A8B1BE]`}>
          <div className={`${styles.center} text-[#A8B1BE]`}>
            {/* <div className={`${styles.title} text-center`}>Cancellation Policy</div> */}
            <div className={`break-all text-[#A8B1BE]`}>
              <p>To ensure fairness, if a passenger chooses to cancel a trip for reasons not attributable to the driver, the passenger will be required to pay a cancellation fee to compensate the driver for the journey cost. For more details on the cancellation fees, please see below. If you have any questions about the fees, please contact our customer support, and we will investigate and respond as soon as possible.</p>
              <p>Situations Requiring a Cancellation Fee</p>
              <p>1. If the passenger cancels the trip more than 7 minutes after the driver has accepted the booking, a cancellation fee will be charged.</p>
              <p>2. The cancellation fee is uniformly charged at HKD 20 for all types of trips.</p>
              <p>Situations Where No Cancellation Fee is Required</p>
              <p>1. If the driver fails to arrive at the pickup location within a reasonable time (such as delays not caused by force majeure, refusal to let the passenger board, etc.), the passenger is not required to pay a cancellation fee. After canceling the booking, the passenger needs to report the issue to customer service. In such cases, the cancellation fee will be refunded to the payment method used by the passenger.</p>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-CN' && (
        <div className={`${styles.cancleHeader} text-[#A8B1BE]`}>
          <div className={`${styles.center} text-[#A8B1BE]`}>
            {/* <div className={`${styles.title} text-center`}>取消政策</div> */}
            <div className={`break-all text-[#A8B1BE]`}>
              <p>为了确保公平，如果乘客因非司机原因选择取消行程，乘客将被要求支付取消费用，以补偿司机的行程成本。有关取消费的更多详细信息，请参见下文。如果您对费用有任何疑问，请联系我们的客户支持，我们将尽快进行调查并回复。</p>
              <p>需要支付取消费的情况</p>
              <p>1. 如果乘客在司机接受预订后超过7分钟取消行程，将收取取消费。</p>
              <p>2. 取消费统一收取，所有类型的行程均为港币20元。</p>
              <p>无需支付取消费的情况</p>
              <p>1. 如果司机未能在合理时间内到达接车地点（例如非不可抗力造成的延误、拒绝让乘客上车等），乘客无需支付取消费。取消预订后，乘客需要向客服报告此问题。在这种情况下，取消费将退还至乘客使用的支付方式。</p>
            </div>
          </div>
        </div>
      )}
      {lang === 'zh-HK' && (
        <div className={`${styles.cancleHeader} text-[#A8B1BE]`}>
          <div className={`${styles.center} text-[#A8B1BE]`}>
            {/* <div className={`${styles.title} text-center`}>取消政策</div> */}
            <div className={`break-all text-[#A8B1BE]`}>
              <p>為了確保公平，如果乘客因非司機原因選擇取消行程，乘客將被要求支付取消費用，以補償司機的行程成本。 有關取消費的更多詳細信息，請參見下文。 如果您對費用有任何疑問，請聯絡我們的客戶支持，我們將盡快進行調查並回覆。</p>
              <h4>需要支付取消費的情況</h4>
              <p>1. 若乘客在司機接受預訂後超過7分鐘取消行程，將收取取消費。</p>
              <p>2. 取消費統一收取，所有類型的行程均為20幣港幣。</p>
              <h4>無需支付取消費的情況</h4>
              <p>1. 若駕駛未能在合理時間內到達接車地點（例如非不可抗力造成的延誤、拒絕讓乘客上車等），乘客無需支付取消費。 取消預訂後，乘客需要向客服報告此問題。 在這種情況下，取消費將退還至乘客使用的付款方式。</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivacyPolicy;
