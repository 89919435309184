import { useEffect, useRef, useState } from 'react';
import type {
  ICreditCard,
} from '../../../types/terminal-type';
import PaymentWebPage from '../web/payment-web';
import PayTypes from '../utils/payment/pay-types';
import type { IPayMethod, IWindowApple } from '../utils/payment/interface';
import { IObject } from '@/types/common-types';
import { useTranslation } from 'react-i18next';
import usePayment from '../utils/payment/usePayment';
import Config from '@/libs/config';
import dayjs from 'dayjs';
import useCheckPay from '../utils/payment/useCheckPay';
import AntdModal from '@/components/common/antd/modal';
import AlipayIcon from '../terminal-icons/alipay-icon';
import {
  addCreditCard,
  bindoBusinessID,
  deleteCreditCard,
  getOrderDetails,
  getRetrieveBalances,
  getWeChatAppID,
  queryCardList,
  queryStore,
} from '../../../data/terminal';
import PaymentMobilePage from '../mobile/payment';
import WeChatPayIcon from '../terminal-icons/wechat-pay-icon';
import FPSIcon from '../terminal-icons/fps-icon';
import UnionPayWalletIcon from '../terminal-icons/unionpay-wallet-icon';
import { judgeBrowserAndTerminal, triggerWonderRoute } from '../../../utils/terminal-untils';
import GlobalLoading from '@/components/common/global-loading';
import { Alert } from '@/components/common/alert/alert';
import api from '@/libs/api';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { deleteCookie, getCookie, setCookie } from '@/utils';
import { Constants } from '@/constants';
import { useSelector } from 'react-redux';
import { IReducersState } from '@/reducers';

let timer: any = '';
let nextTimer: any = '';

const TerminalPaymentPage = () => {
  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const { lang = 'en', p_business_id: businessID = '' } = useParams();
  const business = useSelector((state: IReducersState) => state.businessState.business);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [safetyVerification, setsafetyVerification] = useState<boolean>(false);
  const [safetyVerificationAmount, setsafetyVerificationAmount] = useState<number>(0);
  const [available, setAvailable] = useState<number>(0);
  const [usePaymentMethods, setUsePaymentMethods] = useState<PayTypes[]>([]);
  const [currencyCode, setCurrencyCode] = useState<string>('HKD');
  const [invoice, setInvoice] = useState<any>({});
  const [method, setMethod] = useState<PayTypes | ICreditCard | 'balance'>();
  const [cards, setCards] = useState<ICreditCard[]>([]);
  const [payLoading, setPayLoading] = useState(false);
  const [inPayment, setInPayment] = useState(false);
  const [minutes, setMinutes] = useState(15);
  const [seconds, setSeconds] = useState(0);
  const [acquirer, setAcquirer] = useState('');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showApplePay, setShowApplePay] = useState(false);
  const [deliveryTimeError, setDeliveryTimeError] = useState(false);
  const [nextTime, setNextTime] = useState(5);
  const [payInfo, setPayInfo] = useState({
    name: 'FPS',
    logo: <FPSIcon className="text-[27px]" />,
  });

  const {
    number = '',
    currency,
    unpaid_total,
    customer_email,
  } = invoice;
  const { business_name, translations: i18n } = business;
  const numberRef = useRef(number);
  const checkRef = useRef(false);

  const queryToken = searchParams.get('token');
  const queryisCheck = searchParams.get('check');
  let inApp = false
  const isCheck = queryisCheck === '1'
  const queryInApp = searchParams.get('isApp') || false;
  const initNumber = searchParams.get('number') || '';

  if (typeof queryInApp === 'string' && queryInApp === 'true') {
    inApp = true;
  }
  let access_token = '';
  if (typeof queryToken === 'string' && queryToken.length > 0) {
    access_token = queryToken;
  }
  const cookieToken = getCookie(Constants.TOKEN) || '';
  const token = access_token || cookieToken;

  const { isSafari, isWX, isAlipayCN, isAlipayHK, isPhone } =
    judgeBrowserAndTerminal(navigator.userAgent || '');

  let time = 15 * 60;

  const suffix = `token=${token}&isApp=${inApp}`;

  useEffect(() => {
    initialized();
    // eslint-disable-next-line
  }, []);

  const initialized = async() => {
    await initLoad();
    await handleWeChatOfficialAccountPay();
  }

  const handleWeChatOfficialAccountPay = async() => {
    const code = searchParams.get('code');
    const woa = searchParams.get('woa');
    if (woa === '1' && code) {
      await createInvoice();
      Pay({
        currencyCode: currency,
        type: PayTypes.WECHAT_OFFICIAL_ACCOUNT_PAY,
        code,
        amount: unpaid_total,
        currenToken: token,
        callback(code, message, order) {
          if (message === 'cancel') {
            setPayLoading(false);
            setInPayment(false);
          } else {
            paymentReturnProcessing(order);
          }
        },
      });
    }
  }

  useEffect(() => {
    const { ApplePaySession } = window as IWindowApple;
    setShowApplePay(ApplePaySession ? true : false);
    // eslint-disable-next-line
  }, [])

  const handleDeliveryExpired = (order: any) => {
    const { oms_delivery_note } = order || {};
    const { min_delivery_time } = oms_delivery_note || {};
    if (min_delivery_time && isDeliveryScheduleLater(min_delivery_time, dayjs())) {
      if (inApp) {
        triggerWonderRoute(`/businesses/${businessID}/terminal/line-items?${suffix}`, 'update_delivery_time')
      } else {
        nextTimingStart();
      }
    }
  }

  const initLoad = async () => {
    const localNumber: string = getCookie('__n') || '';
    const allPromise: any[] = [
      queryStore({
        isSafari,
        isWX,
        isAlipayCN,
        isAlipayHK,
        inApp,
      }),
      getRetrieveBalances(businessID, token),
      queryCardList(businessID, token),
      getOrderDetails(initNumber, businessID, token, localNumber),
    ];
    const resp: any = await Promise.all(allPromise);
    if (resp.length > 0) {
      const { usePaymentMethods, safetyVerification, safetyVerificationAmount } =
        resp[0] || {};
      const { available, currency_code } = resp[1];
      setUsePaymentMethods(usePaymentMethods);
      setsafetyVerification(safetyVerification);
      setsafetyVerificationAmount(safetyVerificationAmount);
      setAvailable(available);
      setCurrencyCode(currency_code);
      setInvoice(resp[3] || {});
      setCards(resp[2] || [])
      const { line_items, number = '' } = resp[3] || {};
      if (!Array.isArray(line_items) || line_items.length === 0) {
        handleBack(true);
      } else {
        handleDeliveryExpired(resp[3])
      }
      numberRef.current = number;
      setLoading(false);
      if (isCheck) {
        payLoadingStart();
        CheckInvoice(number);
      }
  
      if (number) {
        triggerWonderRoute(
          JSON.stringify({
            router: `/businesses/${businessID}/terminal/orders/${number}?token=${token}&isApp=true`,
          }),
          'default_value'
        );
      } else {
        triggerWonderRoute(JSON.stringify({ router: '' }), 'default_value');
      }
    }
  }

  const Pay = async (
    payData: IPayMethod,
    callback?: () => Promise<IObject>
  ) => {
    const orderNumber = numberRef.current;
    const result = await usePayment({
      businessID,
      orderNumber,
      email: customer_email,
      isPC: !isPhone,
      lang,
      payData,
      callback,
      extraAction: { onCancel: handlePayCancel },
      payLoading: payLoadingStart,
    });
    const { order } = result || {};
    if (order) {
      paymentReturnProcessing(order);
    } else if (result) {
      payFailedShow(result);
    }
  };

  const payLoadingStart = () => {
    triggerWonderRoute('show', 'loading');
    if (!inApp) {
      setPayLoading(true);
    }
    setInPayment(true);
  };

  const handlePayCancel = (code: string, message: string) => {
    payLoadingHide();
    const orderNumber = numberRef.current;
    if (code && code !== 'Delivery Schedule') {
      handleRouter(
        `/${lang}/businesses/${businessID}/terminal/orders/payment/result?${
          orderNumber ? `number=${orderNumber}&` : ''
        }${suffix}&e=${code}&m=${message}`
      );
    }
  };

  const payLoadingHide = () => {
    triggerWonderRoute('hide', 'loading');
    if (!inApp) {
      setPayLoading(false);
    }
    setInPayment(false)
  };

  const createInvoice = async () => {
    let useOrderNumber = numberRef.current;
    let result: any = '';
    try {
      const { oms_delivery_note } = invoice || {};
      const { min_delivery_time } = oms_delivery_note || {};
      if (isDeliveryScheduleLater(min_delivery_time, dayjs())) {
        if (inApp) {
          triggerWonderRoute(`/businesses/${businessID}/terminal/line-items?${suffix}`, 'update_delivery_time')
        } else {
          nextTimingStart();
        }
        return {
          orderNumber: '',
          result: {
            code: 'Delivery Schedule',
          },
        };
      }
      if (useOrderNumber) {
      } else {
        result = await api.post(
          `${Config.urls.gatewayUrl}/api/oms/b2b/businesses/${bindoBusinessID}/orders`,
          invoice,
          {
            headers: {
              'x-user-access-token': token,
              'x-p-business-id': businessID,
              'Content-Type': 'application/json; charset=utf-8',
              'x-i18n-lang': lang,
            },
          }
        );
        const { data } = result;
        if (data && data.id && data.number) {
          setCookie('__n', data.number);
          useOrderNumber = data.number;
          numberRef.current = useOrderNumber;
        }
      }
    } catch (error) {
      console.error(error);
    }

    return {
      orderNumber: useOrderNumber,
      result,
    };
  };

  const handlePay = async () => {
    try {
      if (!inPayment && method) {
        checkRef.current = false;
        if (method === PayTypes.APPLE_PAY) {
          await Pay(
            {
              countryCode: 'HK',
              currencyCode: 'HKD',
              type: PayTypes.APPLE_PAY,
              amount: unpaid_total,
              supportedNetworks: ['mastercard', 'amex', 'visa'],
              appMerchantId:
                Config.env === 'staging'
                  ? 'merchant.com.bindo.restaurant.test'
                  : 'merchant.com.bindo.restaurant',
              appMerchantDomain: window.location.hostname,
              label: 'Wonder Payments',
              merchantCapabilities: ['supports3DS'],
              appName: 'Wonder Payments',
              currenToken: token,
            },
            createInvoice
          );
        }
        if (method === PayTypes.WECHAT_PAY && isWX) {
          const orderNumber = numberRef.current;
          const { appID, errorCode, errorMessage } = await getWeChatAppID(
            bindoBusinessID
          );
          if (!appID) {
            payFailedShow({
              code: errorCode,
              message: errorMessage,
            });
          } else {
            window.location.href =
              `${Config.urls.baseDomain.replace(
                'hq',
                'pay'
              )}/pay/code?b=${bindoBusinessID}&redirect_uri=${encodeURIComponent(
                `${Config.urls.baseDomain}/${lang}/businesses/${businessID}/terminal/orders/payment?${orderNumber ? `number=${orderNumber}&` : ''}${suffix}`
              )}`;
          }
        } else {
          payLoadingStart();
          const { orderNumber, result } = await createInvoice();
          if (orderNumber) {
            const redirectUrl = `${Config.urls.baseDomain}/${lang}/businesses/${businessID}/terminal/orders/payment?number=${orderNumber}&${suffix}&check=1`;
            const returnUrl = `${Config.urls.payDomain}/redirect?url=${encodeURIComponent(redirectUrl)}`;
            if (
              method === PayTypes.ALIPAYCN ||
              method === PayTypes.ALIPAYHK ||
              method === PayTypes.FPS ||
              method === PayTypes.WECHAT_PAY ||
              method === PayTypes.UNIONPAY_WALLET
            ) {
              await Pay(
                {
                  currencyCode: currency,
                  type: method,
                  returnUrl,
                  amount: unpaid_total,
                  currenToken: token,
                }
              );
            }
            if (typeof method !== 'string' && method.number) {
              await Pay(
                {
                  currencyCode: currency,
                  type: PayTypes.CREDIT_CARD,
                  expYear: method.exp_year,
                  expMonth: method.exp_month,
                  returnUrl,
                  number: method.number,
                  holderName: method.holder_name,
                  amount: unpaid_total,
                  safetyVerification,
                  getawapSafetyVerification: true,
                  appSafetyVerification: true,
                  safetyVerificationAmount,
                  token: method.token,
                  currenToken: token,
                },
                orderNumber
              );
            }
          } else {
            payFailedShow(result);
          }
        }
      }
    } catch (error) {
      payLoadingHide();
    }
  };

  const timingStart = () => {
    const now = dayjs();
    timer = setInterval(() => countDown(now), 1000);
  };

  const countDown = (startTime: dayjs.Dayjs) => {
    const intervalTime = Math.floor(dayjs().diff(startTime) / 1000);
    time = 15 * 60 - intervalTime;
    if (time > 0) {
      setMinutes(Math.floor(time / 60));
      setSeconds(Math.floor(time % 60));
    } else {
      clearInterval(timer);
      setTimeout(() => {
        navigate('/reload', { replace: true });
      }, 1000);
      payFailedShow({
        status: 504,
        code: '504',
        message: t('common:time_out'),
      });
    }
  };

  const nextTimingStart = () => {
    setDeliveryTimeError(true);
    const now = dayjs();
    nextTimer = setInterval(() => nextCountDown(now), 1000);
  };

  const nextCountDown = (startTime: dayjs.Dayjs) => {
    const intervalTime = Math.floor(dayjs().diff(startTime) / 1000);
    const time = 5 - intervalTime;
    if (time >= 0) {
      setNextTime(time);
    } else {
      clearInterval(nextTimer);
      navigate(`/businesses/${businessID}/terminal/line-items`)
    }
  };

  const blobToDataURI = (blob: Blob, callback: (result: any) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function (e) {
      callback(e.target?.result);
    };
  };

  const getBase64 = async (value: string) => {
    const QRCodeStyling = require('qr-code-styling');
    const qrCodeNode = new QRCodeStyling({
      width: 300,
      height: 300,
      dotsOptions: {
        color: '#000000',
        type: 'dots',
      },
      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 2,
      },
      margin: 1,
      cornersSquareOptions: {
        type: 'dot',
      },
      cornersDotOptions: {
        type: 'dot',
      },
      data: value,
    });
    const buffer = await qrCodeNode.getRawData('png');
    blobToDataURI(buffer, (result) => {
      setAcquirer(result);
      setShow(true);
    });
  };

  const getAcquirer = (response: any, paymentMethod: number) => {
    let body = response;
    let type = 'wechat_pay';
    try {
      if (typeof response !== 'object') {
        body = JSON.parse(response);
      }

      if (paymentMethod === 17) {
        type = 'wechat_pay';
        if (body[type].in_web) {
          return body[type].in_web.payinfo;
        }
        if (body[type].merchant_presented_qr_code) {
          return body[type].merchant_presented_qr_code.payinfo;
        }
      }
      if (paymentMethod === 19) {
        type = 'alipay';
        if (body[type].in_web) {
          return body[type].in_web.payinfo;
        }
        if (body[type].merchant_presented_qr_code) {
          return body[type].merchant_presented_qr_code.payinfo;
        }
      }
      if (paymentMethod === 22) {
        type = 'fps';
        if (body[type].merchant_presented_qr_code) {
          return body[type].merchant_presented_qr_code.payinfo;
        }
      }
      if (paymentMethod === 68) {
        type = 'unionpay_wallet';
        if (body[type].in_web) {
          return body[type].in_web.payinfo;
        }
        if (body[type].merchant_presented_qr_code) {
          return body[type].merchant_presented_qr_code.payinfo;
        }
      }
    } catch (error) {
      console.error(error);
    }

    return '';
  };

  const paymentReturnProcessing = (order: any) => {
    let url = '';
    const { number } = order || {};
    if (
      (method === PayTypes.ALIPAYCN ||
        method === PayTypes.ALIPAYHK ||
        method === PayTypes.UNIONPAY_WALLET ||
        method === PayTypes.FPS ||
        method === PayTypes.WECHAT_PAY) &&
      !timer
    ) {
      const { sale_transactions } = order || {};
      if (sale_transactions && Array.isArray(sale_transactions)) {
        sale_transactions.forEach((item) => {
          if (item.is_pending && item.acquirer_response_body) {
            if (
              (method === PayTypes.ALIPAYCN || method === PayTypes.ALIPAYHK) &&
              item.payment_method === 19
            ) {
              url = getAcquirer(
                item.acquirer_response_body,
                item.payment_method
              );
              setPayInfo({
                name: t('extended_service:alipay'),
                logo: <AlipayIcon className="text-[27px]" />,
              });
            }
            if (method === PayTypes.WECHAT_PAY && item.payment_method === 17) {
              url = getAcquirer(
                item.acquirer_response_body,
                item.payment_method
              );
              setPayInfo({
                name: t('extended_service:wechat_pay'),
                logo: <WeChatPayIcon className="text-[27px]" />,
              });
            }
            if (method === PayTypes.FPS && item.payment_method === 22) {
              url = getAcquirer(
                item.acquirer_response_body,
                item.payment_method
              );
              setPayInfo({
                name: 'FPS',
                logo: <FPSIcon className="text-[27px]" />,
              });
            }
            if (
              method === PayTypes.UNIONPAY_WALLET &&
              item.payment_method === 68
            ) {
              url = getAcquirer(
                item.acquirer_response_body,
                item.payment_method
              );
              setPayInfo({
                name: t('extended_service:unionpay_wallet'),
                logo: <UnionPayWalletIcon className="text-[27px]" />,
              });
            }
          }
        });
      }
    }
    if (url) {
      if ((!isPhone && !inApp) || method === PayTypes.FPS) {
        timingStart();
        payLoadingHide();
        getBase64(url);
        CheckInvoice(number, stopOperation);
      } else {
        payLoadingHide();
        if (method === PayTypes.UNIONPAY_WALLET) {
          triggerWonderRoute(
            JSON.stringify({ unionpay_wallet: 'true' }),
            'default_value'
          );
        }
        window.location.href = url;
      }
    } else {
      CheckInvoice(number, stopOperation);
    }
  };

  const stopOperation = () => {
    return checkRef.current === true || time < 1;
  };

  const handleCancel = () => {
    setShow(false);
    clearInterval(timer);
    setMinutes(15);
    setSeconds(0);
    timer = null;
    checkRef.current = true;
  };

  const CheckInvoice = async (
    number: string,
    stopOperation?: () => boolean
  ) => {
    await useCheckPay({
      businessID,
      orderNumber: number,
      token,
      callback: payCallback,
      stopOperation,
      isCheck,
    });
  };

  const payCallback = (data: any) => {
    const orderNumber = numberRef.current;
    const { order, state } = data || {};
    if (order) {
      payLoadingHide();
      deleteCookie('__n');
      handleRouter(
        `/${lang}/businesses/${businessID}/terminal/orders/payment/result?${
          orderNumber ? `number=${orderNumber}&` : ''
        }${suffix}`
      );
    } else if (state === 'timeOut') {
      payLoadingHide();
      handleRouter(
        `/${lang}/businesses/${businessID}/terminal/orders/payment?number=${orderNumber}&${suffix}`,
        'refresh'
      );
    } else {
      payFailedShow(data);
    }
  };

  const payFailedShow = (result: any) => {
    const { code, message } = result || {};
    const orderNumber = numberRef.current;
    setShow(false);
    payLoadingHide();
    if (code !== 'Delivery Schedule') {
      triggerWonderRoute(
        JSON.stringify({
          router: orderNumber ? `/businesses/${businessID}/terminal/orders/${orderNumber}?token=${token}&isApp=true` : '',
        }),
        'default_value'
      );
      handleRouter(
        `/${lang}/businesses/${businessID}/terminal/orders/payment/result?${
          orderNumber ? `number=${orderNumber}&` : ''
        }${suffix}&e=${code}&m=${message}`
      );
    }
  };

  const handleBack = (wrong = false) => {
    const orderNumber = numberRef.current;
    if (wrong) {
      handleRouter(`/${lang}/businesses/${businessID}/terminal/line-items`);
    } else {
      if (inApp) {
        triggerWonderRoute('discontinue_payment', 'confirm');
      } else {
        if (orderNumber) {
          navigate(
            `/${lang}/businesses/${businessID}/terminal/orders/${orderNumber}`
          );
        } else {
          navigate(`/${lang}/businesses/${businessID}/terminal/orders/check`);
        }
      }
    }
  };

  const handleSaveCard = async (card: ICreditCard) => {
    const cardParams = {
      ...card,
      token,
      businessID,
    };
    let success = false;
    payLoadingStart();
    const newCard = await addCreditCard(cardParams);
    if (newCard.number) {
      const newCards = await queryCardList(businessID, token);
      setCards(newCards);
      setMethod(newCard);
      success = true;
    } else if (newCard.message) {
      if (inApp) {
        triggerWonderRoute(
          JSON.stringify({ msg: newCard.message, status: 'create_card_fail' }),
          'option_result'
        );
      } else {
        Alert.error({
          message: newCard.message,
          position: 'default',
          wrapClass: 'top-px',
        });
      }
    }
    payLoadingHide();
    return success;
  };

  const handleRouter = (value = '', action = 'route') => {
    if (inApp) {
      let parts = value.split('/'); // 将字符串按照“/”分割成一个数组  
      let newValue = '/' + parts.slice(2).join('/'); // 获取第二个“/”之后的部分  
      triggerWonderRoute(newValue, action);
    } else {
      navigate(value);
    }
  };

  const getBusinessName = () => {
    if (i18n && i18n[lang] && i18n[lang]['business_name']) {
      return i18n[lang]['business_name'];
    } else {
      return business_name;
    }
  };

  const handleDeleteCard = async (card: ICreditCard) => {
    const { code, message }: any = await deleteCreditCard(
      businessID,
      card.id,
      token
    );
    if (code === 200) {
      if (typeof method !== 'string' && method && card.id === method.id) {
        setMethod(undefined);
      }
      if (inApp) {
        triggerWonderRoute('remove_card_success', 'option_result');
      } else {
        Alert.success({
          message: t('extended_service:removed_successfully'),
          position: 'default',
          wrapClass: 'top-px',
        });
      }
    } else {
      if (inApp) {
        triggerWonderRoute(
          JSON.stringify({ status: 'remove_card_fail', msg: message }),
          'option_result'
        );
      } else {
        Alert.error({
          message: message,
          position: 'default',
          wrapClass: 'top-px',
        });
      }
    }
    const newCards = await queryCardList(businessID, token);
    setCards(newCards);
  };

  const isDeliveryScheduleLater = (deliverySchedule: string, now: dayjs.Dayjs) => {
    const deliveryDateTime = dayjs(deliverySchedule);
    if (now.add(65, 'm').isAfter(deliveryDateTime)) {
      return true;
    }
    return false;
  }

  return (
    <>
      <div className={`${inApp ? 'hidden' : 'hidden md:block'}`}>
        <PaymentWebPage
          cards={cards}
          method={method}
          businessName={getBusinessName()}
          showApplePay={showApplePay}
          setMethod={setMethod}
          orderNumber={numberRef.current}
          handlePay={handlePay}
          handleSaveCard={handleSaveCard}
          handleDeleteCard={handleDeleteCard}
          token={token}
          invoice={invoice}
          creditCards={cards}
          usePaymentMethods={usePaymentMethods}
          safetyVerification={safetyVerification}
          safetyVerificationAmount={safetyVerificationAmount}
          available={available}
          currencyCode={currencyCode}
          business={business}
          isCheck={isCheck}
          isWX={isWX}
          inApp={inApp}
          businessID={businessID}
          isPhone={isPhone}
          loadingData={loading}
        />
      </div>
      <div className={`${inApp ? '' : 'block md:hidden'}`}>
        <PaymentMobilePage
          cards={cards}
          method={method}
          businessName={getBusinessName()}
          showApplePay={showApplePay}
          setMethod={setMethod}
          handlePay={handlePay}
          handleBack={() => handleBack()}
          handleSaveCard={handleSaveCard}
          handleDeleteCard={handleDeleteCard}
          token={token}
          invoice={invoice}
          creditCards={cards}
          usePaymentMethods={usePaymentMethods}
          safetyVerification={safetyVerification}
          safetyVerificationAmount={safetyVerificationAmount}
          available={available}
          currencyCode={currencyCode}
          business={business}
          isCheck={isCheck}
          isWX={isWX}
          inApp={inApp}
          isPhone={isPhone}
          businessID={businessID}
          loadingData={loading}
        />
      </div>
      <GlobalLoading loading={payLoading} />
      {show && (
        <AntdModal
          open={true}
          onCancel={handleCancel}
          footer={null}
          maskClosable={false}
        >
          <div className="px-6 pt-8 pb-10 flex flex-col items-center text-color-text-1">
            <div className="text-xl font-bold mb-7">
              {t('extended_service:pay_with', { name: payInfo.name })}
            </div>
            {payInfo.logo}
            <div className="text-lg leading-6 font-semibold mt-1.5">
              {t('extended_service:scan_qr_code')}
            </div>
            <div className="mt-1 text-primary-color text-sm flex">
              <div>
                {t('extended_service:pay_within', {
                  time: `${minutes < 10 ? '0' : ''}${minutes}:${
                    seconds < 10 ? '0' : ''
                  }${seconds}`,
                })}
              </div>
            </div>
            <div className="mt-1">
              <div className="spinner__box">
                <div className="w-[30px] h-[30px] p-[3px] rounded-full bg-gradient-to-b from-[#0094FF] to-[#e8f4ff] animate-spin duration-[900]">
                  <div className="rounded-[50%] bg-white w-full h-full"></div>
                </div>
              </div>
            </div>
            <div className="w-80 h-80 flex mt-6 rounded-none md:border md:border-[#b4b4b4] md:rounded-30 items-center justify-center">
              <img
                className="w-full"
                width={290}
                height={290}
                src={acquirer}
                id="image"
                alt=""
              />
            </div>
          </div>
        </AntdModal>
      )}
      <AntdModal
        open={deliveryTimeError}
        type={'warn'}
        okText={`${t('common:next')} (${nextTime}s)`}
        onOk={() => {
          clearInterval(nextTimer);
          navigate(`/businesses/${businessID}/terminal/line-items`)
        }}
        closable={false}
        maskClosable={false}
      >
        <div className="pt-4 flex flex-col items-center text-color-text-1">
          <div className="text-xl font-bold">
            {t('extended_service:update_new_time')}
          </div>
          <div className="text-sm font-medium mt-2">
            {t('extended_service:chosen_new_time')}
          </div>
        </div>
      </AntdModal>
    </>
  );
};

export default TerminalPaymentPage;
