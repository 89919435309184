export interface IAssociate {
  id: string;
  p_created_at: string;
  p_business_id: string;
  role_id: string;
  user_id: string;
  status: string;
  remark: string;
  job_position: string;
  default_card: string;
  expired_at: string;
  role_name: string;
  display_name: string;
  full_name: string;
  translations: {
    languages_code: string;
    role_name: string;
  }[];
  leave_organization: boolean;
}

export interface IAssociateDetail {
  id: string;
  calling_code: string;
  display_name: string;
  email: string;
  full_name: string;
  job_position: string;
  leave_organization: boolean;
  phone: string;
  p_created_at: string;
  p_updated_at: string;
  role_name: string;
  role_id: string;
  status: string;
  user_id: string;
  translations: {
    languages_code: string;
    role_name: string;
  }[];
}

export interface IPower {
  edit: boolean;
  remove: boolean;
  leave: boolean;
  editRole: boolean;
  suspend: boolean;
}

export enum IAssociateAction {
  SUSPEND = 'suspend',
  REACTIVATE = 'reactivate',
  REMOVE = 'remove',
  LEAVE = 'leave',
}

export interface IQueryAssociates {
  businessID: string;
  filter: string;
  lang: string;
  token: string;
}

export interface IQueryAssociate {
  businessID: string;
  id: string;
  lang: string;
  token: string;
}
