import type { IPayinetailsProps } from '../type';
import type { IObject } from '@/types/common-types';
import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import Label from '@/components/common/label';
import DetailsItem from '@/pages/account-balance/components/details-item-component';
import {
  getPayinTranferStatusLabel,
  handleUtcDate,
  getPayInTitleIcon,
  handleSymbolMount,
} from '@/utils/account-balance-untils';
import { getPayinDetails } from '@/data/account-balance';
import Skeleton from '@/components/skeleton';
import ScrollNum from '@/components/common/scroll-number';
import CopyIcon from '@/components/svg/copy-icon';
import { PayinTransferType, TransferMethodType } from '@/types/account-balance-type';
import { useTranslation } from 'react-i18next';

let idCopyTimeout: any;
let refIdCopyTimeout: any;

const PayInDetailsPage = (props: IPayinetailsProps) => {
  const { t } = useTranslation("account_balance");

  const [record, setRecord] = useState<IObject>({});
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyRefIdSuccess, setCopyRefIdSuccess] = useState(false);
  const [initLoading, setInitLoaing] = useState(false);
  const { businessId, recordId } = props;
  const {
    transfer_status: status = '',
    p_created_at = '',
    currency = '',
    amount = '',
    transfer_type = '',
    id = '',
    transfer_method = '',
    estimated_settled_at = '',
    ref_id = '',
    settled_at = '',
    remark,
    payer_name,
    transfer = {},
    calculated_fee = '',
    local_amount = '',
    error_code = '',
    error_code_description = '',
    vendor_reference_id,
  } = record || {};

  useEffect(() => {
    getReacord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, recordId]);

  useEffect(() => {
    if (copyRefIdSuccess) {
      clearTimeout(refIdCopyTimeout);
      refIdCopyTimeout = setTimeout(() => {
        setCopyRefIdSuccess(false);
      }, 3000);
    }
  }, [copyRefIdSuccess]);

  useEffect(() => {
    if (copySuccess) {
      clearTimeout(idCopyTimeout);
      idCopyTimeout = setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }
  }, [copySuccess]);

  const { disabled = true } = props;

  const getReacord = async () => {
    setInitLoaing(true);
    const data = await getPayinDetails({
      recordId,
      pBusinessId: businessId,
    });
    if (data) {
      setRecord(data);
      setInitLoaing(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(id).then(() => {
      setCopySuccess(true);
    });
  };

  const handleRefIdCopy = () => {
    navigator.clipboard.writeText(ref_id).then(() => {
      setCopyRefIdSuccess(true);
    });
  };

  const handleAmountStyle = () => {
    let style = 'text-color-text-1';
    if (
      getPayinTranferStatusLabel(status).status === 'declined' ||
      getPayinTranferStatusLabel(status).status === 'reversed' ||
      getPayinTranferStatusLabel(status).status === 'suspended'
    ) {
      style = 'line-through text-color-text-2';
    }
    return style;
  };

  const getSettledTitle = () => {
    let title = t('account_balance:settled_on');
    if (getPayinTranferStatusLabel(status).status === 'initiated') {
      title = t('account_balance:est_settled_on');
    }
    return title;
  };

  const getSettledDate = () => {
    let date = handleUtcDate(settled_at);
    if (getPayinTranferStatusLabel(status).status === 'initiated') {
      date = handleUtcDate(estimated_settled_at);
    }
    return date;
  };

  const isAddFundOrInbound = [
    PayinTransferType.addFund,
    PayinTransferType.inboundTransfer,
  ].includes(transfer_type);

  const isFPS = transfer_method === TransferMethodType.FPSBankAccount;

  const showErrorCode = ['suspended', 'reversed'].includes(
    status.toLowerCase()
  );

  const showTime = ['settled', 'initiated'].includes(status.toLowerCase());

  return (
    <Transition.Root show={true} appear={true}>
      <Transition.Child
        className="rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 h-fit"
        enter="duration-700 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-10 opacity-0"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="items-center lg:flex">
          <div>
            <div className="flex flex-col md:flex-row whitespace-nowrap">
              {getPayInTitleIcon(transfer_type, payer_name, 48)}
            </div>
          </div>
          {initLoading && (
            <Skeleton
              row={2}
              width={['50%', '50%']}
              skeletonItemClass={`h-4 rounded-2xl mb-4`}
            />
          )}
          {!initLoading && (
            <div className="flex">
              <div className="flex flex-col justify-between ml-4">
                <div className="flex space-x-1 items-center text-3xl font-bold">
                  <span className={`${handleAmountStyle()}`}>
                    <ScrollNum
                      value={local_amount}
                      prefix="+"
                      size={36}
                      boxClass={'h-9'}
                    />
                  </span>
                  <span className="text-color-text-1 font-medium">{`${currency}`}</span>
                </div>
                <div className="flex items-center">
                  <span className="text-color-text-2 font-medium text-sm">
                    {t('account_balance:payin_amount')}
                  </span>
                </div>
              </div>
              <div className="mx-10 border-r border-border-color" />
              <div className="flex flex-col justify-between">
                <div className="text-color-text-1 font-medium">
                  {payer_name}
                </div>
                <div className="text-color-text-2 text-sm font-medium">
                  {t('account_balance:payer')}
                </div>
              </div>
            </div>
          )}
        </div>
      </Transition.Child>

      <div className="flex w-full space-x-6 mt-6">
        <Transition.Child
          className="flex-1"
          enter="duration-350 transform transition ease-in-out"
          enterFrom="-translate-x-1 translate-y-8 opacity-60"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="duration-500 transform transition"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div
            className={`w-full rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 h-fit`}
          >
            <div className="font-bold text-xl text-color-text-1 flex items-center">
              {t('account_balance:payin')}
            </div>
            <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
                <div>
                  <Label
                    disabled={disabled}
                    value={`${
                      isAddFundOrInbound
                        ? t('account_balance:transaction_amount')
                        : t('account_balance:payin_amount')
                    }`}
                  ></Label>
                  {initLoading && (
                    <div className={`overflow-hidden inline-block w-60`}>
                      <Skeleton
                        row={1}
                        width={['100%']}
                        skeletonItemClass={`h-4 rounded-2xl mb-0`}
                      />
                    </div>
                  )}
                  {!initLoading && (
                    <div className="flex space-x-1 h-6 items-center text-color-text-1 font-medium pt-4">
                      <ScrollNum
                        size={14}
                        boxClass="h-6 leading-6"
                        prefix="+"
                        value={`${isAddFundOrInbound ? amount : local_amount}`}
                      />
                      <span className="text-sm">{`${currency}`}</span>
                    </div>
                  )}
                </div>
                <DetailsItem
                  title={t('account_balance:status')}
                  content={getPayinTranferStatusLabel(status).component}
                  loading={initLoading}
                />
                <div className="w-full col-auto md:col-span-2">
                  <Label disabled={disabled} value={'ID'}></Label>
                  {initLoading && (
                    <Skeleton
                      row={2}
                      width={['50%', '50%']}
                      skeletonItemClass={`h-4 rounded-2xl mb-4`}
                    />
                  )}
                  {!initLoading && (
                    <div className="py-2 text-color-text-1 font-medium pr-6 flex items-center space-x-2">
                      <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-color-text-1 text-sm font-medium">
                        {id}
                      </span>
                      <span
                        className="cursor-pointer group"
                        onClick={handleCopy}
                      >
                        <CopyIcon className="text-primary-color" />
                      </span>
                      <span
                        className={`text-primary-color text-xs cursor-pointer ${
                          copySuccess ? 'visible' : 'invisible'
                        }`}
                      >
                        {t('account_balance:copied')}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
                {isAddFundOrInbound && (
                  <DetailsItem
                    title={t('account_balance:fees_charged')}
                    content={`${handleSymbolMount(
                      calculated_fee,
                      false,
                      '-'
                    )} ${currency}`}
                    loading={initLoading}
                  />
                )}
                <DetailsItem
                  title={t('account_balance:type')}
                  content={transfer_type}
                  loading={initLoading}
                />
                <DetailsItem
                  title={t('account_balance:method')}
                  content={transfer_method}
                  loading={initLoading}
                />
                <div className="w-full">
                  <Label
                    disabled={disabled}
                    value={t('account_balance:reference_id')}
                  ></Label>
                  {initLoading && (
                    <Skeleton
                      row={2}
                      width={['50%', '50%']}
                      skeletonItemClass={`h-4 rounded-2xl mb-4`}
                    />
                  )}
                  {!initLoading && (
                    <>
                      {ref_id ? (
                        <div className="py-2 text-color-text-1 font-medium pr-6 flex items-center space-x-2">
                          <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-color-text-1 text-sm font-medium">
                            {ref_id}
                          </span>
                          <span
                            className="cursor-pointer group"
                            onClick={handleRefIdCopy}
                          >
                            <CopyIcon className="text-primary-color" />
                          </span>
                          <span
                            className={`text-primary-color text-xs cursor-pointer ${
                              copyRefIdSuccess ? 'visible' : 'invisible'
                            }`}
                          >
                            {t('account_balance:copied')}
                          </span>
                        </div>
                      ) : (
                        '--'
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
                {isAddFundOrInbound && (
                  <div>
                    <Label
                      disabled={disabled}
                      value={t('account_balance:payin_amount')}
                    ></Label>
                    {initLoading && (
                      <div className={`overflow-hidden inline-block w-60`}>
                        <Skeleton
                          row={1}
                          width={['100%']}
                          skeletonItemClass={`h-4 rounded-2xl mb-0`}
                        />
                      </div>
                    )}
                    {!initLoading && (
                      <div className="flex space-x-1 items-center text-color-text-1 font-medium">
                        <ScrollNum
                          size={16}
                          prefix="+"
                          boxClass="h-6 leading-6"
                          value={local_amount}
                        />
                        <span>{`${currency}`}</span>
                      </div>
                    )}
                  </div>
                )}
                <DetailsItem
                  title={t('account_balance:created_on')}
                  content={handleUtcDate(p_created_at)}
                  loading={initLoading}
                />
                {showErrorCode && (
                  <DetailsItem
                    title={t('account_balance:reason_code')}
                    content={
                      <>
                        {error_code && error_code_description ? (
                          <>
                            <div className="text-ellipsis overflow-hidden text-[#FC2E01]">
                              {error_code}
                            </div>
                            <div className="text-ellipsis overflow-hidden">
                              {error_code_description}
                            </div>
                          </>
                        ) : (
                          '--'
                        )}
                      </>
                    }
                    loading={initLoading}
                  />
                )}
                {showTime && (
                  <DetailsItem
                    title={getSettledTitle()}
                    content={getSettledDate()}
                    loading={initLoading}
                  />
                )}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
                {isFPS && (
                  <DetailsItem
                    title={t('account_balance:fps_reference_id')}
                    content={vendor_reference_id}
                    loading={initLoading}
                  />
                )}
                <div className="col-span-3">
                  <DetailsItem
                    title={t('account_balance:remark')}
                    content={remark}
                    loading={initLoading}
                  />
                </div>
              </div>
            </>
          </div>
        </Transition.Child>
      </div>
      <div className="flex w-full space-x-6 mt-6">
        <Transition.Child
          className="flex-1"
          enter="duration-350 transform transition ease-in-out"
          enterFrom="-translate-x-1 translate-y-8 opacity-60"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="duration-500 transform transition"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div
            className={`w-full rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 h-fit`}
          >
            <div className="font-semibold text-lg text-color-text-1 flex items-center md:text-xl">
              {t('account_balance:payer')}
            </div>
            <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6">
              <DetailsItem
                title={t('account_balance:payer_name')}
                content={payer_name}
                loading={initLoading}
              />
              <DetailsItem
                title={t('account_balance:bank_name')}
                content={transfer.bank_name || ''}
                loading={initLoading}
              />
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};

export default PayInDetailsPage;
