import React from 'react';
import type { IIconProps } from '@/types/common-types';

const AddressCheckIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="26"
        height="25"
        viewBox="0 0 26 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="13.4883"
          cy="12.5"
          r="12.5"
          fill="#0094FF"
          fillOpacity="0.2"
        />
        <path
          d="M9.32031 13.4259L12.5611 16.6666L17.6536 9.72217"
          stroke="#0094FF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default AddressCheckIcon;
