
import BigNumber from 'bignumber.js';
import trim from 'lodash/trim';

export const transformField = (value: string, symbol = '_') => {
  let newValue = '';
  if (!value) return newValue;
  const valueArray = value.toLowerCase().split(symbol);
  valueArray.forEach((item) => {
    if (trim(item)) {
      newValue = `${newValue ? `${newValue} ` : ''}${item.replace(
        item[0],
        item[0].toUpperCase()
      )}`;
    }
  });
  return newValue;
};

export const handleNumber = (value: string | number, decimals = 2) => {
  let returnValue: React.Key = '--';
  const temp = new BigNumber(value);
  if (!temp.isNaN()) {
    returnValue = temp.toFormat(decimals, 1);
  }
  return returnValue;
};

export const camelToUnderscore = (camelCase: string) => {
  return camelCase.replace(/([A-Z])/g, '_$1').toLowerCase();
};
