import { payInvoice } from '../../../../../../data/terminal';
import { handleBuildOrderPayment } from '../../utils';
import type {
  IWechatOfficialAccountPayMethod,
  IWindowWX,
} from '../../interface';
import { getWeChatAppID } from '../../../../../../data/terminal';

interface IWechatOfficialAccountPayParams {
  businessID: string;
  orderNumber: string;
  payData: IWechatOfficialAccountPayMethod;
  payment: any;
  email: string;
  lang: string;
}

const UseWechatOfficialAccountPay = async (
  params: IWechatOfficialAccountPayParams
) => {
  const { businessID, orderNumber, payment, payData, email, lang } = params;

  const { amount, code, callback, currenToken } = payData;

  const newPayment = {
    wechatpay: [
      {
        amount,
        official_account: {
          code,
        },
      },
    ],
  };

  const order = handleBuildOrderPayment(newPayment, payment);
  try {
    const resp:any = await payInvoice({
      businessID,
      orderNumber,
      order,
      email,
      lang,
      token: currenToken,
    });
    const { appID } = await getWeChatAppID(businessID);
    const { code, data: fulfilOrder } = resp || {};
    const { sale_transactions } = fulfilOrder || {};

    let timeStamp = '';
    let nonceStr = '';
    let payPackage = '';
    let signType = '';
    let paySign = '';

    if (code === 200 && Array.isArray(sale_transactions)) {
      sale_transactions.forEach((item) => {
        if (item.payment_method === 17 && item.is_pending) {
          let response = item.acquirer_response_body;
          if (typeof response !== 'object') {
            response = JSON.parse(item.acquirer_response_body);
          }
          if (response.wechat_pay && response.wechat_pay.official_account) {
            const {
              time_stamp,
              nonce_str,
              package: resultPackge,
              sign_type,
              pay_sign,
            } = response.wechat_pay.official_account || {};
            timeStamp = time_stamp;
            nonceStr = nonce_str;
            payPackage = resultPackge;
            signType = sign_type;
            paySign = pay_sign;
          }
        }
      });
    }

    if (appID && timeStamp && nonceStr && payPackage && signType && paySign) {
      const { WeixinJSBridge } = window as IWindowWX;
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: appID,
          timeStamp,
          nonceStr,
          package: payPackage,
          signType,
          paySign,
        },
        function (res: any) {
          const { err_msg: errMsg } = res;
          if (errMsg === 'get_brand_wcpay_request:ok') {
            callback('200', '', fulfilOrder);
            return;
          }
          if (errMsg === 'get_brand_wcpay_request:cancel') {
            callback('200', 'cancel', fulfilOrder);
            return;
          }
          callback('200', errMsg, fulfilOrder);
        }
      );
    } else {
      return {
        status: 500,
        ...resp,
      };
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export default UseWechatOfficialAccountPay;
