import React from 'react';
import type { IIconProps } from '@/types/common-types';

const PaymentSuccessIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
      >
        <path
          d="M58.636 29.9996C58.636 45.8151 45.8151 58.636 29.9996 58.636C14.1842 58.636 1.36328 45.8151 1.36328 29.9996C1.36328 24.4998 2.91376 19.362 5.60146 14.9996C10.6425 6.81762 19.6841 1.36328 29.9996 1.36328C45.8151 1.36328 58.636 14.1842 58.636 29.9996Z"
          fill="#0094FF"
        />
        <path
          d="M29.9998 59.726C29.4543 59.726 28.9089 59.3169 28.9089 58.6351C28.9089 57.9533 29.318 57.5442 29.9998 57.5442C45.2725 57.5442 57.5453 45.1351 57.5453 29.9988C57.5453 14.8624 45.2725 2.4533 29.9998 2.4533C20.318 2.4533 11.5907 7.36239 6.40889 15.5442C6.13616 16.0897 5.45435 16.226 5.04525 15.8169C4.56738 15.5442 4.5187 14.8652 4.77253 14.4533C10.2271 5.58967 19.6362 0.271484 29.9998 0.271484C46.3634 0.271484 59.7271 13.6351 59.7271 29.9988C59.7271 46.3624 46.3634 59.726 29.9998 59.726Z"
          fill="black"
        />
        <path
          d="M24.818 41.8624L13.4998 30.5442C13.0907 30.1351 13.0907 29.4533 13.4998 29.0442C13.9089 28.6351 14.5907 28.6351 14.9998 29.0442L24.9543 38.9988L43.3634 20.5897C43.7725 20.1806 44.4543 20.1806 44.8634 20.5897C45.2725 20.9988 45.2725 21.6806 44.8634 22.0897L26.2479 40.452L24.818 41.8624Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default PaymentSuccessIcon;
