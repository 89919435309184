import React from 'react';
import type { IIconProps } from '@/types/common-types';

const FPSBankIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 24 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.53031 6.90903C2.53031 6.72162 2.64401 6.55295 2.81772 6.48264L11.2125 3.08473C11.5446 2.95031 11.916 2.95031 12.2481 3.08473L20.6429 6.48264C20.8166 6.55295 20.9303 6.72162 20.9303 6.90903V8.81891C20.9303 9.07296 20.7244 9.27891 20.4703 9.27891H2.99031C2.73626 9.27891 2.53031 9.07296 2.53031 8.81891V6.90903Z"
          stroke="black"
          strokeWidth="0.92"
        />
        <rect
          x="2.06937"
          y="19.4014"
          width="19.32"
          height="2.76"
          rx="0.46"
          stroke="black"
          strokeWidth="0.92"
        />
        <rect x="3.44922" y="9.28125" width="0.92" height="9.66" fill="black" />
        <rect x="9.89062" y="9.28125" width="0.92" height="9.66" fill="black" />
        <rect x="16.7891" y="9.28125" width="0.92" height="9.66" fill="black" />
        <rect x="5.75" y="9.28125" width="0.92" height="9.66" fill="black" />
        <rect x="12.6504" y="9.28125" width="0.92" height="9.66" fill="black" />
        <rect x="19.0898" y="9.28125" width="0.92" height="9.66" fill="black" />
        <rect
          x="10.8105"
          y="4.67969"
          width="1.84"
          height="3.22"
          rx="0.92"
          fill="#0094FF"
        />
      </svg>
    </div>
  );
};

export default FPSBankIcon;
