import { IAreaCodes } from "@/types/common-types";


const data = [
  {
    iso_alpha2_code: 'ZW',
    iso_alpha3_code: 'ZWE',
    name: { 'en-US': 'Zimbabwean', 'zh-CN': '津巴布韦', 'zh-HK': '津巴布韋' },
  },
  {
    iso_alpha2_code: 'ZM',
    iso_alpha3_code: 'ZMB',
    name: { 'en-US': 'Zambian', 'zh-CN': '赞比亚', 'zh-HK': '贊比亞' },
  },
  {
    iso_alpha2_code: 'YE',
    iso_alpha3_code: 'YEM',
    name: { 'en-US': 'Yemeni', 'zh-CN': '也门', 'zh-HK': '也門' },
  },
  {
    iso_alpha2_code: 'EH',
    iso_alpha3_code: 'ESH',
    name: {
      'en-US': 'Western Sahara citizen',
      'zh-CN': '西撒哈拉',
      'zh-HK': '西撒哈拉',
    },
  },
  {
    iso_alpha2_code: 'WF',
    iso_alpha3_code: 'WLF',
    name: {
      'en-US': 'French (Wallis and Futuna)',
      'zh-CN': '瓦利斯和富图纳群岛',
      'zh-HK': '瓦利斯和富圖納群島',
    },
  },
  {
    iso_alpha2_code: 'VN',
    iso_alpha3_code: 'VNM',
    name: { 'en-US': 'Vietnamese', 'zh-CN': '越南', 'zh-HK': '越南' },
  },
  {
    iso_alpha2_code: 'VE',
    iso_alpha3_code: 'VEN',
    name: { 'en-US': 'Venezuelan', 'zh-CN': '委内瑞拉', 'zh-HK': '委內瑞拉' },
  },
  {
    iso_alpha2_code: 'VA',
    iso_alpha3_code: 'VAT',
    name: { 'en-US': 'Vatican citizen', 'zh-CN': '梵帝冈', 'zh-HK': '梵帝岡' },
  },
  {
    iso_alpha2_code: 'VU',
    iso_alpha3_code: 'VUT',
    name: {
      'en-US': 'Vanuatu citizen',
      'zh-CN': '瓦努阿图',
      'zh-HK': '瓦努阿圖',
    },
  },
  {
    iso_alpha2_code: 'UZ',
    iso_alpha3_code: 'UZB',
    name: {
      'en-US': 'Uzbek',
      'zh-CN': '乌兹别克斯坦',
      'zh-HK': '烏茲別克斯坦',
    },
  },
  {
    iso_alpha2_code: 'UY',
    iso_alpha3_code: 'URY',
    name: { 'en-US': 'Uruguayan', 'zh-CN': '乌拉圭', 'zh-HK': '烏拉圭' },
  },
  {
    iso_alpha2_code: 'US',
    iso_alpha3_code: 'USA',
    name: { 'en-US': 'American', 'zh-CN': '美国', 'zh-HK': '美國' },
  },
  {
    iso_alpha2_code: 'GB',
    iso_alpha3_code: 'GBR',
    name: { 'en-US': 'British', 'zh-CN': '英国', 'zh-HK': '英國' },
  },
  {
    iso_alpha2_code: 'AE',
    iso_alpha3_code: 'ARE',
    name: {
      'en-US': 'Emirati',
      'zh-CN': '阿拉伯联合酋长国',
      'zh-HK': '阿拉伯聯合酋長國',
    },
  },
  {
    iso_alpha2_code: 'UA',
    iso_alpha3_code: 'UKR',
    name: { 'en-US': 'Ukrainian', 'zh-CN': '乌克兰', 'zh-HK': '烏克蘭' },
  },
  {
    iso_alpha2_code: 'UG',
    iso_alpha3_code: 'UGA',
    name: { 'en-US': 'Ugandan', 'zh-CN': '乌干达', 'zh-HK': '烏干達' },
  },
  {
    iso_alpha2_code: 'VI',
    iso_alpha3_code: 'VIR',
    name: {
      'en-US': 'American (U.S. Virgin Islands)',
      'zh-CN': '美属维尔京群岛',
      'zh-HK': '美屬維爾京群島',
    },
  },
  {
    iso_alpha2_code: 'TV',
    iso_alpha3_code: 'TUV',
    name: { 'en-US': 'Tuvaluan', 'zh-CN': '图瓦卢', 'zh-HK': '圖瓦盧' },
  },
  {
    iso_alpha2_code: 'TC',
    iso_alpha3_code: 'TCA',
    name: {
      'en-US': 'Turks and Caicos Islander',
      'zh-CN': '特克斯和凯科斯群岛',
      'zh-HK': '特克斯和凱科斯群島',
    },
  },
  {
    iso_alpha2_code: 'TM',
    iso_alpha3_code: 'TKM',
    name: { 'en-US': 'Turkmen', 'zh-CN': '土库曼斯坦', 'zh-HK': '土庫曼斯坦' },
  },
  {
    iso_alpha2_code: 'TR',
    iso_alpha3_code: 'TUR',
    name: { 'en-US': 'Turkish', 'zh-CN': '土耳其', 'zh-HK': '土耳其' },
  },
  {
    iso_alpha2_code: 'TN',
    iso_alpha3_code: 'TUN',
    name: { 'en-US': 'Tunisian', 'zh-CN': '突尼斯', 'zh-HK': '突尼斯' },
  },
  {
    iso_alpha2_code: 'TT',
    iso_alpha3_code: 'TTO',
    name: {
      'en-US': 'Trinidadian',
      'zh-CN': '特立尼达和多巴哥',
      'zh-HK': '特立尼達和多巴哥',
    },
  },
  {
    iso_alpha2_code: 'TO',
    iso_alpha3_code: 'TON',
    name: { 'en-US': 'Tongan', 'zh-CN': '汤加', 'zh-HK': '湯加' },
  },
  {
    iso_alpha2_code: 'TK',
    iso_alpha3_code: 'TKL',
    name: { 'en-US': 'Tokelau citizen', 'zh-CN': '托克劳', 'zh-HK': '托克勞' },
  },
  {
    iso_alpha2_code: 'TG',
    iso_alpha3_code: 'TGO',
    name: { 'en-US': 'Togolese', 'zh-CN': '多哥', 'zh-HK': '多哥' },
  },
  {
    iso_alpha2_code: 'TH',
    iso_alpha3_code: 'THA',
    name: { 'en-US': 'Thai', 'zh-CN': '泰国', 'zh-HK': '泰國' },
  },
  {
    iso_alpha2_code: 'TZ',
    iso_alpha3_code: 'TZA',
    name: { 'en-US': 'Tanzanian', 'zh-CN': '坦桑尼亚', 'zh-HK': '坦桑尼亞' },
  },
  {
    iso_alpha2_code: 'TJ',
    iso_alpha3_code: 'TJK',
    name: { 'en-US': 'Tajik', 'zh-CN': '塔吉克斯坦', 'zh-HK': '塔吉克斯坦' },
  },
  {
    iso_alpha2_code: 'TW',
    iso_alpha3_code: 'TWN',
    name: { 'en-US': 'Taiwanese', 'zh-CN': '台湾', 'zh-HK': '台灣' },
  },
  {
    iso_alpha2_code: 'SY',
    iso_alpha3_code: 'SYR',
    name: { 'en-US': 'Syrian', 'zh-CN': '叙利亚', 'zh-HK': '敘利亞' },
  },
  {
    iso_alpha2_code: 'CH',
    iso_alpha3_code: 'CHE',
    name: { 'en-US': 'Swiss', 'zh-CN': '瑞士', 'zh-HK': '瑞士' },
  },
  {
    iso_alpha2_code: 'SE',
    iso_alpha3_code: 'SWE',
    name: { 'en-US': 'Swedish', 'zh-CN': '瑞典', 'zh-HK': '瑞典' },
  },
  {
    iso_alpha2_code: 'SZ',
    iso_alpha3_code: 'SWZ',
    name: { 'en-US': 'Swazi', 'zh-CN': '斯威士兰', 'zh-HK': '斯威士蘭' },
  },
  {
    iso_alpha2_code: 'SJ',
    iso_alpha3_code: 'SJM',
    name: {
      'en-US': 'Norwegian (Svalbard and Jan Mayen)',
      'zh-CN': '斯瓦尔巴和扬马延',
      'zh-HK': '斯瓦爾巴和揚馬延',
    },
  },
  {
    iso_alpha2_code: 'SR',
    iso_alpha3_code: 'SUR',
    name: { 'en-US': 'Surinamese', 'zh-CN': '苏里南', 'zh-HK': '蘇里南' },
  },
  {
    iso_alpha2_code: 'SD',
    iso_alpha3_code: 'SDN',
    name: { 'en-US': 'Sudanese', 'zh-CN': '苏丹', 'zh-HK': '蘇丹' },
  },
  {
    iso_alpha2_code: 'LK',
    iso_alpha3_code: 'LKA',
    name: { 'en-US': 'Sri Lankan', 'zh-CN': '斯里兰卡', 'zh-HK': '斯里蘭卡' },
  },
  {
    iso_alpha2_code: 'ES',
    iso_alpha3_code: 'ESP',
    name: { 'en-US': 'Spanish', 'zh-CN': '西班牙', 'zh-HK': '西班牙' },
  },
  {
    iso_alpha2_code: 'SS',
    iso_alpha3_code: 'SSD',
    name: { 'en-US': 'South Sudanese', 'zh-CN': '南苏丹', 'zh-HK': '南蘇丹' },
  },
  {
    iso_alpha2_code: 'KR',
    iso_alpha3_code: 'KOR',
    name: { 'en-US': 'South Korean', 'zh-CN': '韩国', 'zh-HK': '韓國' },
  },
  {
    iso_alpha2_code: 'ZA',
    iso_alpha3_code: 'ZAF',
    name: { 'en-US': 'South African', 'zh-CN': '南非', 'zh-HK': '南非' },
  },
  {
    iso_alpha2_code: 'SO',
    iso_alpha3_code: 'SOM',
    name: { 'en-US': 'Somali', 'zh-CN': '索马里', 'zh-HK': '索馬里' },
  },
  {
    iso_alpha2_code: 'SB',
    iso_alpha3_code: 'SLB',
    name: {
      'en-US': 'Solomon Islander',
      'zh-CN': '所罗门群岛',
      'zh-HK': '所羅門群島',
    },
  },
  {
    iso_alpha2_code: 'SI',
    iso_alpha3_code: 'SVN',
    name: {
      'en-US': 'Slovenian',
      'zh-CN': '斯洛文尼亚',
      'zh-HK': '斯洛文尼亞',
    },
  },
  {
    iso_alpha2_code: 'SK',
    iso_alpha3_code: 'SVK',
    name: { 'en-US': 'Slovak', 'zh-CN': '斯洛伐克', 'zh-HK': '斯洛伐克' },
  },
  {
    iso_alpha2_code: 'SX',
    iso_alpha3_code: 'SXM',
    name: {
      'en-US': 'Dutch (Sint Maarten)',
      'zh-CN': '圣马丁',
      'zh-HK': '聖馬丁',
    },
  },
  {
    iso_alpha2_code: 'SG',
    iso_alpha3_code: 'SGP',
    name: { 'en-US': 'Singaporean', 'zh-CN': '新加坡', 'zh-HK': '新加坡' },
  },
  {
    iso_alpha2_code: 'SL',
    iso_alpha3_code: 'SLE',
    name: {
      'en-US': 'Sierra Leonean',
      'zh-CN': '塞拉利昂',
      'zh-HK': '塞拉利昂',
    },
  },
  {
    iso_alpha2_code: 'SC',
    iso_alpha3_code: 'SYC',
    name: {
      'en-US': 'Seychelles citizen',
      'zh-CN': '塞舌尔',
      'zh-HK': '塞舌爾',
    },
  },
  {
    iso_alpha2_code: 'RS',
    iso_alpha3_code: 'SRB',
    name: { 'en-US': 'Serbian', 'zh-CN': '塞尔维亚', 'zh-HK': '塞爾維亞' },
  },
  {
    iso_alpha2_code: 'SN',
    iso_alpha3_code: 'SEN',
    name: { 'en-US': 'Senegalese', 'zh-CN': '塞内加尔', 'zh-HK': '塞內加爾' },
  },
  {
    iso_alpha2_code: 'SA',
    iso_alpha3_code: 'SAU',
    name: {
      'en-US': 'Saudi Arabian',
      'zh-CN': '沙特阿拉伯',
      'zh-HK': '沙特阿拉伯',
    },
  },
  {
    iso_alpha2_code: 'ST',
    iso_alpha3_code: 'STP',
    name: {
      'en-US': 'Sao Tomean',
      'zh-CN': '圣多美和普林西比',
      'zh-HK': '聖多美和普林西比',
    },
  },
  {
    iso_alpha2_code: 'SM',
    iso_alpha3_code: 'SMR',
    name: { 'en-US': 'Sammarinese', 'zh-CN': '圣马力诺', 'zh-HK': '聖馬力諾' },
  },
  {
    iso_alpha2_code: 'WS',
    iso_alpha3_code: 'WSM',
    name: { 'en-US': 'Samoan', 'zh-CN': '萨摩亚', 'zh-HK': '薩摩亞' },
  },
  {
    iso_alpha2_code: 'VC',
    iso_alpha3_code: 'VCT',
    name: {
      'en-US': 'Vincentian',
      'zh-CN': '圣文森与格瑞那丁',
      'zh-HK': '聖文森與格瑞那丁',
    },
  },
  {
    iso_alpha2_code: 'PM',
    iso_alpha3_code: 'SPM',
    name: {
      'en-US': 'French (Saint Pierre and Miquelon)',
      'zh-CN': '圣皮埃尔和密克隆群岛',
      'zh-HK': '聖皮埃爾和密克隆群島',
    },
  },
  {
    iso_alpha2_code: 'MF',
    iso_alpha3_code: 'MAF',
    name: {
      'en-US': 'French (Saint Martin)',
      'zh-CN': '圣马丁',
      'zh-HK': '聖馬丁',
    },
  },
  {
    iso_alpha2_code: 'LC',
    iso_alpha3_code: 'LCA',
    name: { 'en-US': 'Saint Lucian', 'zh-CN': '圣卢西亚', 'zh-HK': '聖盧西亞' },
  },
  {
    iso_alpha2_code: 'KN',
    iso_alpha3_code: 'KNA',
    name: {
      'en-US': 'Kittitian and Nevisian',
      'zh-CN': '圣基茨和尼维斯',
      'zh-HK': '聖基茨和尼維斯',
    },
  },
  {
    iso_alpha2_code: 'SH',
    iso_alpha3_code: 'SHN',
    name: {
      'en-US': 'British (Saint Helena)',
      'zh-CN': '圣赫勒拿岛',
      'zh-HK': '聖赫勒拿島',
    },
  },
  {
    iso_alpha2_code: 'BL',
    iso_alpha3_code: 'BLM',
    name: {
      'en-US': 'French (Saint Barthelemy)',
      'zh-CN': '圣巴泰勒米',
      'zh-HK': '聖巴泰勒米',
    },
  },
  {
    iso_alpha2_code: 'RW',
    iso_alpha3_code: 'RWA',
    name: { 'en-US': 'Rwandan', 'zh-CN': '卢旺达', 'zh-HK': '盧旺達' },
  },
  {
    iso_alpha2_code: 'RU',
    iso_alpha3_code: 'RUS',
    name: { 'en-US': 'Russian', 'zh-CN': '俄罗斯', 'zh-HK': '俄羅斯' },
  },
  {
    iso_alpha2_code: 'RO',
    iso_alpha3_code: 'ROU',
    name: { 'en-US': 'Romanian', 'zh-CN': '罗马尼亚', 'zh-HK': '羅馬尼亞' },
  },
  {
    iso_alpha2_code: 'RE',
    iso_alpha3_code: 'REU',
    name: { 'en-US': 'French (Reunion)', 'zh-CN': '留尼汪', 'zh-HK': '留尼汪' },
  },
  {
    iso_alpha2_code: 'CG',
    iso_alpha3_code: 'COG',
    name: {
      'en-US': 'Congolese (Congo Republic)',
      'zh-CN': '刚果共和国',
      'zh-HK': '剛果共和國',
    },
  },
  {
    iso_alpha2_code: 'QA',
    iso_alpha3_code: 'QAT',
    name: { 'en-US': 'Qatari', 'zh-CN': '卡塔尔', 'zh-HK': '卡塔爾' },
  },
  {
    iso_alpha2_code: 'PR',
    iso_alpha3_code: 'PRI',
    name: { 'en-US': 'Puerto Rican', 'zh-CN': '波多黎各', 'zh-HK': '波多黎各' },
  },
  {
    iso_alpha2_code: 'PT',
    iso_alpha3_code: 'PRT',
    name: { 'en-US': 'Portuguese', 'zh-CN': '葡萄牙', 'zh-HK': '葡萄牙' },
  },
  {
    iso_alpha2_code: 'PL',
    iso_alpha3_code: 'POL',
    name: { 'en-US': 'Polish', 'zh-CN': '波兰', 'zh-HK': '波蘭' },
  },
  {
    iso_alpha2_code: 'PN',
    iso_alpha3_code: 'PCN',
    name: {
      'en-US': 'British (Pitcairn)',
      'zh-CN': '皮特凯恩群岛',
      'zh-HK': '皮特凱恩群島',
    },
  },
  {
    iso_alpha2_code: 'PH',
    iso_alpha3_code: 'PHL',
    name: { 'en-US': 'Filipino', 'zh-CN': '菲律宾', 'zh-HK': '菲律賓' },
  },
  {
    iso_alpha2_code: 'PE',
    iso_alpha3_code: 'PER',
    name: { 'en-US': 'Peruvian', 'zh-CN': '秘鲁', 'zh-HK': '秘魯' },
  },
  {
    iso_alpha2_code: 'PY',
    iso_alpha3_code: 'PRY',
    name: { 'en-US': 'Paraguayan', 'zh-CN': '巴拉圭', 'zh-HK': '巴拉圭' },
  },
  {
    iso_alpha2_code: 'PG',
    iso_alpha3_code: 'PNG',
    name: {
      'en-US': 'Papua New Guinean',
      'zh-CN': '巴布亚新几内亚',
      'zh-HK': '巴布亞新幾內亞',
    },
  },
  {
    iso_alpha2_code: 'PA',
    iso_alpha3_code: 'PAN',
    name: { 'en-US': 'Panamanian', 'zh-CN': '巴拿马', 'zh-HK': '巴拿馬' },
  },
  {
    iso_alpha2_code: 'PS',
    iso_alpha3_code: 'PSE',
    name: { 'en-US': 'Palestinian', 'zh-CN': '巴勒斯坦', 'zh-HK': '巴勒斯坦' },
  },
  {
    iso_alpha2_code: 'PW',
    iso_alpha3_code: 'PLW',
    name: { 'en-US': 'Palauan', 'zh-CN': '帛琉', 'zh-HK': '帛琉' },
  },
  {
    iso_alpha2_code: 'PK',
    iso_alpha3_code: 'PAK',
    name: { 'en-US': 'Pakistani', 'zh-CN': '巴基斯坦', 'zh-HK': '巴基斯坦' },
  },
  {
    iso_alpha2_code: 'OM',
    iso_alpha3_code: 'OMN',
    name: { 'en-US': 'Omani', 'zh-CN': '阿曼', 'zh-HK': '阿曼' },
  },
  {
    iso_alpha2_code: 'NO',
    iso_alpha3_code: 'NOR',
    name: { 'en-US': 'Norwegian', 'zh-CN': '挪威', 'zh-HK': '挪威' },
  },
  {
    iso_alpha2_code: 'MP',
    iso_alpha3_code: 'MNP',
    name: {
      'en-US': 'American (Northern Mariana Islands)',
      'zh-CN': '北马里亚纳群岛',
      'zh-HK': '北馬里亞納群島',
    },
  },
  {
    iso_alpha2_code: 'KP',
    iso_alpha3_code: 'PRK',
    name: { 'en-US': 'North Korean', 'zh-CN': '朝鲜', 'zh-HK': '朝鮮' },
  },
  {
    iso_alpha2_code: 'NU',
    iso_alpha3_code: 'NIU',
    name: { 'en-US': 'Niuean', 'zh-CN': '纽埃', 'zh-HK': '纽埃' },
  },
  {
    iso_alpha2_code: 'NG',
    iso_alpha3_code: 'NGA',
    name: { 'en-US': 'Nigerian', 'zh-CN': '尼日利亚', 'zh-HK': '尼日利亚' },
  },
  {
    iso_alpha2_code: 'NE',
    iso_alpha3_code: 'NER',
    name: { 'en-US': 'Nigerien', 'zh-CN': '尼日尔', 'zh-HK': '尼日爾' },
  },
  {
    iso_alpha2_code: 'NI',
    iso_alpha3_code: 'NIC',
    name: { 'en-US': 'Nicaraguan', 'zh-CN': '尼加拉瓜 ', 'zh-HK': '尼加拉瓜 ' },
  },
  {
    iso_alpha2_code: 'NZ',
    iso_alpha3_code: 'NZL',
    name: { 'en-US': 'New Zealander', 'zh-CN': '新西兰', 'zh-HK': '新西蘭' },
  },
  {
    iso_alpha2_code: 'NC',
    iso_alpha3_code: 'NCL',
    name: {
      'en-US': 'French (New Caledonia)',
      'zh-CN': '新喀里多尼亚',
      'zh-HK': '新喀裡多尼亞',
    },
  },
  {
    iso_alpha2_code: 'AN',
    iso_alpha3_code: 'ANT',
    name: {
      'en-US': 'Dutch (Netherlands Antilles)',
      'zh-CN': '荷属安的列斯',
      'zh-HK': '荷屬安的列斯',
    },
  },
  {
    iso_alpha2_code: 'NL',
    iso_alpha3_code: 'NLD',
    name: { 'en-US': 'Dutch', 'zh-CN': '荷兰', 'zh-HK': '荷蘭' },
  },
  {
    iso_alpha2_code: 'NP',
    iso_alpha3_code: 'NPL',
    name: { 'en-US': 'Nepalese', 'zh-CN': '尼泊尔', 'zh-HK': '尼泊爾' },
  },
  {
    iso_alpha2_code: 'NR',
    iso_alpha3_code: 'NRU',
    name: { 'en-US': 'Nauruan', 'zh-CN': '瑙鲁', 'zh-HK': '瑙魯' },
  },
  {
    iso_alpha2_code: 'NA',
    iso_alpha3_code: 'NAM',
    name: { 'en-US': 'Namibian', 'zh-CN': '纳米比亚', 'zh-HK': '納米比亞' },
  },
  {
    iso_alpha2_code: 'MM',
    iso_alpha3_code: 'MMR',
    name: { 'en-US': 'Burmese', 'zh-CN': '缅甸', 'zh-HK': '緬甸' },
  },
  {
    iso_alpha2_code: 'MZ',
    iso_alpha3_code: 'MOZ',
    name: { 'en-US': 'Mozambican', 'zh-CN': '莫桑比克', 'zh-HK': '莫桑比克' },
  },
  {
    iso_alpha2_code: 'MA',
    iso_alpha3_code: 'MAR',
    name: { 'en-US': 'Moroccan', 'zh-CN': '摩洛哥', 'zh-HK': '摩洛哥' },
  },
  {
    iso_alpha2_code: 'MS',
    iso_alpha3_code: 'MSR',
    name: {
      'en-US': 'Montserratian',
      'zh-CN': '蒙特塞拉特',
      'zh-HK': '蒙特塞拉特',
    },
  },
  {
    iso_alpha2_code: 'ME',
    iso_alpha3_code: 'MNE',
    name: { 'en-US': 'Montenegrin', 'zh-CN': '黑山', 'zh-HK': '黑山' },
  },
  {
    iso_alpha2_code: 'MN',
    iso_alpha3_code: 'MNG',
    name: { 'en-US': 'Mongolian', 'zh-CN': '蒙古', 'zh-HK': '蒙古' },
  },
  {
    iso_alpha2_code: 'MC',
    iso_alpha3_code: 'MCO',
    name: { 'en-US': 'Monegasque', 'zh-CN': '摩纳哥', 'zh-HK': '摩納哥' },
  },
  {
    iso_alpha2_code: 'MD',
    iso_alpha3_code: 'MDA',
    name: { 'en-US': 'Moldovan', 'zh-CN': '摩尔多瓦', 'zh-HK': '摩爾多瓦' },
  },
  {
    iso_alpha2_code: 'FM',
    iso_alpha3_code: 'FSM',
    name: {
      'en-US': 'Micronesian',
      'zh-CN': '密克罗尼西亚联邦',
      'zh-HK': '密克羅尼西亞聯邦',
    },
  },
  {
    iso_alpha2_code: 'MX',
    iso_alpha3_code: 'MEX',
    name: { 'en-US': 'Mexican', 'zh-CN': '墨西哥', 'zh-HK': '墨西哥' },
  },
  {
    iso_alpha2_code: 'YT',
    iso_alpha3_code: 'MYT',
    name: { 'en-US': 'French (Mayotte)', 'zh-CN': '马约特', 'zh-HK': '馬約特' },
  },
  {
    iso_alpha2_code: 'MU',
    iso_alpha3_code: 'MUS',
    name: { 'en-US': 'Mauritian', 'zh-CN': '毛里求斯', 'zh-HK': '毛里求斯' },
  },
  {
    iso_alpha2_code: 'MR',
    iso_alpha3_code: 'MRT',
    name: {
      'en-US': 'Mauritanian',
      'zh-CN': '毛里塔尼亚',
      'zh-HK': '毛里塔尼亞',
    },
  },
  {
    iso_alpha2_code: 'MH',
    iso_alpha3_code: 'MHL',
    name: {
      'en-US': 'Marshallese',
      'zh-CN': '马绍尔群岛',
      'zh-HK': '馬紹爾群島',
    },
  },
  {
    iso_alpha2_code: 'MT',
    iso_alpha3_code: 'MLT',
    name: { 'en-US': 'Maltese', 'zh-CN': '马耳他', 'zh-HK': '馬耳他' },
  },
  {
    iso_alpha2_code: 'ML',
    iso_alpha3_code: 'MLI',
    name: { 'en-US': 'Malian', 'zh-CN': '马里', 'zh-HK': '馬里' },
  },
  {
    iso_alpha2_code: 'MV',
    iso_alpha3_code: 'MDV',
    name: { 'en-US': 'Maldivian', 'zh-CN': '马尔代夫', 'zh-HK': '馬爾代夫' },
  },
  {
    iso_alpha2_code: 'MY',
    iso_alpha3_code: 'MYS',
    name: { 'en-US': 'Malaysian', 'zh-CN': '马来西亚', 'zh-HK': '馬來西亞' },
  },
  {
    iso_alpha2_code: 'MW',
    iso_alpha3_code: 'MWI',
    name: { 'en-US': 'Malawian', 'zh-CN': '马拉维', 'zh-HK': '馬拉維' },
  },
  {
    iso_alpha2_code: 'MG',
    iso_alpha3_code: 'MDG',
    name: {
      'en-US': 'Malagasy',
      'zh-CN': '马达加斯加岛',
      'zh-HK': '马达加斯加岛',
    },
  },
  {
    iso_alpha2_code: 'MK',
    iso_alpha3_code: 'MKD',
    name: { 'en-US': 'Macedonian', 'zh-CN': '马其顿', 'zh-HK': '馬其頓' },
  },
  {
    iso_alpha2_code: 'MO',
    iso_alpha3_code: 'MAC',
    name: {
      'en-US': 'Chinese (Macau)',
      'zh-CN': '中国（澳门）',
      'zh-HK': '中國（澳門）',
    },
  },
  {
    iso_alpha2_code: 'LU',
    iso_alpha3_code: 'LUX',
    name: { 'en-US': 'Luxembourger', 'zh-CN': '卢森堡', 'zh-HK': '盧森堡' },
  },
  {
    iso_alpha2_code: 'LT',
    iso_alpha3_code: 'LTU',
    name: { 'en-US': 'Lithuanian', 'zh-CN': '立陶宛', 'zh-HK': '立陶宛' },
  },
  {
    iso_alpha2_code: 'LI',
    iso_alpha3_code: 'LIE',
    name: {
      'en-US': 'Liechtenstein citizen',
      'zh-CN': '列支敦士登',
      'zh-HK': '列支敦士登',
    },
  },
  {
    iso_alpha2_code: 'LY',
    iso_alpha3_code: 'LBY',
    name: { 'en-US': 'Libyan', 'zh-CN': '利比亚', 'zh-HK': '利比亞' },
  },
  {
    iso_alpha2_code: 'LR',
    iso_alpha3_code: 'LBR',
    name: { 'en-US': 'Liberian', 'zh-CN': '利比里亚', 'zh-HK': '利比里亞' },
  },
  {
    iso_alpha2_code: 'LS',
    iso_alpha3_code: 'LSO',
    name: { 'en-US': 'Lesotho citizen', 'zh-CN': '莱索托', 'zh-HK': '萊索托' },
  },
  {
    iso_alpha2_code: 'LB',
    iso_alpha3_code: 'LBN',
    name: { 'en-US': 'Lebanese', 'zh-CN': '黎巴嫩', 'zh-HK': '黎巴嫩' },
  },
  {
    iso_alpha2_code: 'LV',
    iso_alpha3_code: 'LVA',
    name: { 'en-US': 'Latvian', 'zh-CN': '拉脱维亚', 'zh-HK': '拉脫維亞' },
  },
  {
    iso_alpha2_code: 'LA',
    iso_alpha3_code: 'LAO',
    name: { 'en-US': 'Lao', 'zh-CN': '老挝', 'zh-HK': '老撾' },
  },
  {
    iso_alpha2_code: 'KG',
    iso_alpha3_code: 'KGZ',
    name: {
      'en-US': 'Kyrgyz',
      'zh-CN': '吉尔吉斯斯坦',
      'zh-HK': '吉爾吉斯斯坦',
    },
  },
  {
    iso_alpha2_code: 'KW',
    iso_alpha3_code: 'KWT',
    name: { 'en-US': 'Kuwaiti', 'zh-CN': '科威特', 'zh-HK': '科威特' },
  },
  {
    iso_alpha2_code: 'XK',
    iso_alpha3_code: 'XKX',
    name: { 'en-US': 'Kosovan', 'zh-CN': '科索沃', 'zh-HK': '科索沃' },
  },
  {
    iso_alpha2_code: 'KI',
    iso_alpha3_code: 'KIR',
    name: {
      'en-US': 'Kiribati citizen',
      'zh-CN': '基里巴斯',
      'zh-HK': '基里巴斯',
    },
  },
  {
    iso_alpha2_code: 'KE',
    iso_alpha3_code: 'KEN',
    name: { 'en-US': 'Kenyan', 'zh-CN': '肯尼亚', 'zh-HK': '肯尼亞' },
  },
  {
    iso_alpha2_code: 'KZ',
    iso_alpha3_code: 'KAZ',
    name: { 'en-US': 'Kazakh', 'zh-CN': '哈萨克斯坦', 'zh-HK': '哈薩克斯坦' },
  },
  {
    iso_alpha2_code: 'JO',
    iso_alpha3_code: 'JOR',
    name: { 'en-US': 'Jordanian', 'zh-CN': '约旦', 'zh-HK': '約旦' },
  },
  {
    iso_alpha2_code: 'JE',
    iso_alpha3_code: 'JEY',
    name: { 'en-US': 'British (Jersey)', 'zh-CN': '泽西岛', 'zh-HK': '澤西島' },
  },
  {
    iso_alpha2_code: 'JP',
    iso_alpha3_code: 'JPN',
    name: { 'en-US': 'Japanese', 'zh-CN': '日本', 'zh-HK': '日本' },
  },
  {
    iso_alpha2_code: 'JM',
    iso_alpha3_code: 'JAM',
    name: { 'en-US': 'Jamaican', 'zh-CN': '牙买加', 'zh-HK': '牙買加' },
  },
  {
    iso_alpha2_code: 'CI',
    iso_alpha3_code: 'CIV',
    name: { 'en-US': 'Ivorian', 'zh-CN': '科特迪瓦', 'zh-HK': '科特迪瓦' },
  },
  {
    iso_alpha2_code: 'IT',
    iso_alpha3_code: 'ITA',
    name: { 'en-US': 'Italian', 'zh-CN': '意大利', 'zh-HK': '意大利' },
  },
  {
    iso_alpha2_code: 'IL',
    iso_alpha3_code: 'ISR',
    name: { 'en-US': 'Israeli', 'zh-CN': '以色列', 'zh-HK': '以色列' },
  },
  {
    iso_alpha2_code: 'IM',
    iso_alpha3_code: 'IMN',
    name: {
      'en-US': 'British (Isle of Man)',
      'zh-CN': '马恩岛',
      'zh-HK': '馬恩島',
    },
  },
  {
    iso_alpha2_code: 'IE',
    iso_alpha3_code: 'IRL',
    name: { 'en-US': 'Irish', 'zh-CN': '爱尔兰', 'zh-HK': '愛爾蘭' },
  },
  {
    iso_alpha2_code: 'IQ',
    iso_alpha3_code: 'IRQ',
    name: { 'en-US': 'Iraqi', 'zh-CN': '伊拉克', 'zh-HK': '伊拉克' },
  },
  {
    iso_alpha2_code: 'IR',
    iso_alpha3_code: 'IRN',
    name: { 'en-US': 'Iranian', 'zh-CN': '伊朗', 'zh-HK': '伊朗' },
  },
  {
    iso_alpha2_code: 'ID',
    iso_alpha3_code: 'IDN',
    name: {
      'en-US': 'Indonesian',
      'zh-CN': '印度尼西亚',
      'zh-HK': '印度尼西亞',
    },
  },
  {
    iso_alpha2_code: 'IN',
    iso_alpha3_code: 'IND',
    name: { 'en-US': 'Indian', 'zh-CN': '印度', 'zh-HK': '印度' },
  },
  {
    iso_alpha2_code: 'IS',
    iso_alpha3_code: 'ISL',
    name: { 'en-US': 'Icelandic', 'zh-CN': '冰岛', 'zh-HK': '冰島' },
  },
  {
    iso_alpha2_code: 'HU',
    iso_alpha3_code: 'HUN',
    name: { 'en-US': 'Hungarian', 'zh-CN': '匈牙利', 'zh-HK': '匈牙利' },
  },
  {
    iso_alpha2_code: 'HK',
    iso_alpha3_code: 'HKG',
    name: {
      'en-US': 'Chinese (Hong Kong)',
      'zh-CN': '中国（香港）',
      'zh-HK': '中國（香港）',
    },
  },
  {
    iso_alpha2_code: 'HN',
    iso_alpha3_code: 'HND',
    name: { 'en-US': 'Honduran', 'zh-CN': '洪都拉斯', 'zh-HK': '洪都拉斯' },
  },
  {
    iso_alpha2_code: 'HT',
    iso_alpha3_code: 'HTI',
    name: { 'en-US': 'Haitian', 'zh-CN': '海地', 'zh-HK': '海地' },
  },
  {
    iso_alpha2_code: 'GY',
    iso_alpha3_code: 'GUY',
    name: { 'en-US': 'Guyanese', 'zh-CN': '圭亚那', 'zh-HK': '圭亞那' },
  },
  {
    iso_alpha2_code: 'GW',
    iso_alpha3_code: 'GNB',
    name: {
      'en-US': 'Guinea-Bissau citizen',
      'zh-CN': '几内亚比索',
      'zh-HK': '幾內亞比索',
    },
  },
  {
    iso_alpha2_code: 'GN',
    iso_alpha3_code: 'GIN',
    name: { 'en-US': 'Guinean', 'zh-CN': '几内亚', 'zh-HK': '幾內亞' },
  },
  {
    iso_alpha2_code: 'GG',
    iso_alpha3_code: 'GGY',
    name: {
      'en-US': 'British (Guernsey)',
      'zh-CN': '根西岛',
      'zh-HK': '根西島',
    },
  },
  {
    iso_alpha2_code: 'GT',
    iso_alpha3_code: 'GTM',
    name: { 'en-US': 'Guatemalan', 'zh-CN': '危地马拉', 'zh-HK': '危地馬拉' },
  },
  {
    iso_alpha2_code: 'GU',
    iso_alpha3_code: 'GUM',
    name: { 'en-US': 'Guamanian', 'zh-CN': '关岛', 'zh-HK': '關島' },
  },
  {
    iso_alpha2_code: 'GD',
    iso_alpha3_code: 'GRD',
    name: { 'en-US': 'Grenadian', 'zh-CN': '格林纳达', 'zh-HK': '格林納達' },
  },
  {
    iso_alpha2_code: 'GL',
    iso_alpha3_code: 'GRL',
    name: {
      'en-US': 'Danish (Greenland)',
      'zh-CN': '格陵兰',
      'zh-HK': '格陵蘭',
    },
  },
  {
    iso_alpha2_code: 'GR',
    iso_alpha3_code: 'GRC',
    name: { 'en-US': 'Greek', 'zh-CN': '希腊', 'zh-HK': '希臘' },
  },
  {
    iso_alpha2_code: 'GI',
    iso_alpha3_code: 'GIB',
    name: { 'en-US': 'Gibraltarian', 'zh-CN': '直布罗陀', 'zh-HK': '直布羅陀' },
  },
  {
    iso_alpha2_code: 'GH',
    iso_alpha3_code: 'GHA',
    name: { 'en-US': 'Ghanaian', 'zh-CN': '加纳', 'zh-HK': '加納' },
  },
  {
    iso_alpha2_code: 'DE',
    iso_alpha3_code: 'DEU',
    name: { 'en-US': 'German', 'zh-CN': '德国', 'zh-HK': '德國' },
  },
  {
    iso_alpha2_code: 'GE',
    iso_alpha3_code: 'GEO',
    name: { 'en-US': 'Georgian', 'zh-CN': '格鲁吉亚', 'zh-HK': '格魯吉亞' },
  },
  {
    iso_alpha2_code: 'GM',
    iso_alpha3_code: 'GMB',
    name: { 'en-US': 'Gambian', 'zh-CN': '冈比亚', 'zh-HK': '岡比亞' },
  },
  {
    iso_alpha2_code: 'GA',
    iso_alpha3_code: 'GAB',
    name: { 'en-US': 'Gabonese', 'zh-CN': '加蓬', 'zh-HK': '加蓬' },
  },
  {
    iso_alpha2_code: 'PF',
    iso_alpha3_code: 'PYF',
    name: {
      'en-US': 'French (French Polynesia)',
      'zh-CN': '法属波利尼西亚',
      'zh-HK': '法屬波利尼西亞',
    },
  },
  {
    iso_alpha2_code: 'FR',
    iso_alpha3_code: 'FRA',
    name: { 'en-US': 'French', 'zh-CN': '法国', 'zh-HK': '法國' },
  },
  {
    iso_alpha2_code: 'FI',
    iso_alpha3_code: 'FIN',
    name: { 'en-US': 'Finnish', 'zh-CN': '芬兰', 'zh-HK': '芬蘭' },
  },
  {
    iso_alpha2_code: 'FJ',
    iso_alpha3_code: 'FJI',
    name: { 'en-US': 'Fijian', 'zh-CN': '斐济', 'zh-HK': '斐濟' },
  },
  {
    iso_alpha2_code: 'FO',
    iso_alpha3_code: 'FRO',
    name: {
      'en-US': 'Danish (Faroe Islands)',
      'zh-CN': '法罗群岛',
      'zh-HK': '法羅群島',
    },
  },
  {
    iso_alpha2_code: 'FK',
    iso_alpha3_code: 'FLK',
    name: {
      'en-US': 'British (Falkland Islands)',
      'zh-CN': '福克兰群岛',
      'zh-HK': '福克蘭群島',
    },
  },
  {
    iso_alpha2_code: 'ET',
    iso_alpha3_code: 'ETH',
    name: {
      'en-US': 'Ethiopian',
      'zh-CN': '埃塞俄比亚',
      'zh-HK': '埃塞俄比亞',
    },
  },
  {
    iso_alpha2_code: 'EE',
    iso_alpha3_code: 'EST',
    name: { 'en-US': 'Estonian', 'zh-CN': '爱沙尼亚', 'zh-HK': '愛沙尼亞' },
  },
  {
    iso_alpha2_code: 'ER',
    iso_alpha3_code: 'ERI',
    name: { 'en-US': 'Eritrean', 'zh-CN': '厄立特里亚', 'zh-HK': '厄立特里亞' },
  },
  {
    iso_alpha2_code: 'GQ',
    iso_alpha3_code: 'GNQ',
    name: {
      'en-US': 'Equatorial Guinean',
      'zh-CN': '赤道几内亚',
      'zh-HK': '赤道幾內亞',
    },
  },
  {
    iso_alpha2_code: 'SV',
    iso_alpha3_code: 'SLV',
    name: { 'en-US': 'Salvadorean', 'zh-CN': '萨尔瓦多', 'zh-HK': '薩爾瓦多' },
  },
  {
    iso_alpha2_code: 'EG',
    iso_alpha3_code: 'EGY',
    name: { 'en-US': 'Egyptian', 'zh-CN': '埃及', 'zh-HK': '埃及' },
  },
  {
    iso_alpha2_code: 'EC',
    iso_alpha3_code: 'ECU',
    name: { 'en-US': 'Ecuadorean', 'zh-CN': '厄瓜多尔', 'zh-HK': '厄瓜多爾' },
  },
  {
    iso_alpha2_code: 'TL',
    iso_alpha3_code: 'TLS',
    name: { 'en-US': 'East Timorese', 'zh-CN': '东帝汶', 'zh-HK': '東帝汶' },
  },
  {
    iso_alpha2_code: 'DO',
    iso_alpha3_code: 'DOM',
    name: {
      'en-US': 'Dominican Republic citizen',
      'zh-CN': '多米尼加共和国 ',
      'zh-HK': '多米尼加共和國',
    },
  },
  {
    iso_alpha2_code: 'DM',
    iso_alpha3_code: 'DMA',
    name: { 'en-US': 'Dominican', 'zh-CN': '多米尼克', 'zh-HK': '多米尼克' },
  },
  {
    iso_alpha2_code: 'DJ',
    iso_alpha3_code: 'DJI',
    name: { 'en-US': 'Djiboutian', 'zh-CN': '吉布提', 'zh-HK': '吉布提' },
  },
  {
    iso_alpha2_code: 'DK',
    iso_alpha3_code: 'DNK',
    name: { 'en-US': 'Danish', 'zh-CN': '丹麦', 'zh-HK': '丹麥' },
  },
  {
    iso_alpha2_code: 'CD',
    iso_alpha3_code: 'COD',
    name: {
      'en-US': 'Congolese (DRC)',
      'zh-CN': '刚果民主共和国',
      'zh-HK': '剛果民主共和國',
    },
  },
  {
    iso_alpha2_code: 'CZ',
    iso_alpha3_code: 'CZE',
    name: { 'en-US': 'Czech', 'zh-CN': '捷克共和国', 'zh-HK': '捷克共和國' },
  },
  {
    iso_alpha2_code: 'CY',
    iso_alpha3_code: 'CYP',
    name: { 'en-US': 'Cypriot', 'zh-CN': '塞浦路斯', 'zh-HK': '塞浦路斯' },
  },
  {
    iso_alpha2_code: 'CW',
    iso_alpha3_code: 'CUW',
    name: { 'en-US': 'Dutch (Curaçao)', 'zh-CN': '库拉索', 'zh-HK': '庫拉索' },
  },
  {
    iso_alpha2_code: 'CU',
    iso_alpha3_code: 'CUB',
    name: { 'en-US': 'Cuban', 'zh-CN': '古巴', 'zh-HK': '古巴' },
  },
  {
    iso_alpha2_code: 'HR',
    iso_alpha3_code: 'HRV',
    name: { 'en-US': 'Croatian', 'zh-CN': '克罗地亚', 'zh-HK': '克羅地亞' },
  },
  {
    iso_alpha2_code: 'CR',
    iso_alpha3_code: 'CRI',
    name: {
      'en-US': 'Costa Rican',
      'zh-CN': '哥斯达黎加',
      'zh-HK': '哥斯達黎加',
    },
  },
  {
    iso_alpha2_code: 'CK',
    iso_alpha3_code: 'COK',
    name: {
      'en-US': 'Cook Islander',
      'zh-CN': '库克群岛',
      'zh-HK': '庫克群島',
    },
  },
  {
    iso_alpha2_code: 'KM',
    iso_alpha3_code: 'COM',
    name: { 'en-US': 'Comoran', 'zh-CN': '科摩罗', 'zh-HK': '科摩羅' },
  },
  {
    iso_alpha2_code: 'CO',
    iso_alpha3_code: 'COL',
    name: { 'en-US': 'Colombian', 'zh-CN': '哥伦比亚', 'zh-HK': '哥倫比亞' },
  },
  {
    iso_alpha2_code: 'CC',
    iso_alpha3_code: 'CCK',
    name: {
      'en-US': 'Australian (Cocos Islands)',
      'zh-CN': '科科斯群岛',
      'zh-HK': '科科斯群島',
    },
  },
  {
    iso_alpha2_code: 'CX',
    iso_alpha3_code: 'CXR',
    name: {
      'en-US': 'Australian (Christmas Island)',
      'zh-CN': '圣诞岛',
      'zh-HK': '聖誕島',
    },
  },
  {
    iso_alpha2_code: 'CN',
    iso_alpha3_code: 'CHN',
    name: { 'en-US': 'Chinese', 'zh-CN': '中国', 'zh-HK': '中國' },
  },
  {
    iso_alpha2_code: 'CL',
    iso_alpha3_code: 'CHL',
    name: { 'en-US': 'Chilean', 'zh-CN': '智利', 'zh-HK': '智利' },
  },
  {
    iso_alpha2_code: 'TD',
    iso_alpha3_code: 'TCD',
    name: { 'en-US': 'Chadian', 'zh-CN': '查德', 'zh-HK': '查德' },
  },
  {
    iso_alpha2_code: 'CF',
    iso_alpha3_code: 'CAF',
    name: {
      'en-US': 'Central African',
      'zh-CN': '中非共和国',
      'zh-HK': '中非共和國',
    },
  },
  {
    iso_alpha2_code: 'KY',
    iso_alpha3_code: 'CYM',
    name: {
      'en-US': 'Cayman Islander',
      'zh-CN': '开曼群岛',
      'zh-HK': '開曼群島',
    },
  },
  {
    iso_alpha2_code: 'CV',
    iso_alpha3_code: 'CPV',
    name: { 'en-US': 'Cape Verdean', 'zh-CN': '佛得角', 'zh-HK': '佛得角' },
  },
  {
    iso_alpha2_code: 'CA',
    iso_alpha3_code: 'CAN',
    name: { 'en-US': 'Canadian', 'zh-CN': '加拿大', 'zh-HK': '加拿大' },
  },
  {
    iso_alpha2_code: 'CM',
    iso_alpha3_code: 'CMR',
    name: { 'en-US': 'Cameroonian', 'zh-CN': '喀麦隆', 'zh-HK': '喀麥隆' },
  },
  {
    iso_alpha2_code: 'KH',
    iso_alpha3_code: 'KHM',
    name: { 'en-US': 'Cambodian', 'zh-CN': '柬埔寨', 'zh-HK': '柬埔寨' },
  },
  {
    iso_alpha2_code: 'BI',
    iso_alpha3_code: 'BDI',
    name: { 'en-US': 'Burundian', 'zh-CN': '布隆迪', 'zh-HK': '布隆迪' },
  },
  {
    iso_alpha2_code: 'BF',
    iso_alpha3_code: 'BFA',
    name: { 'en-US': 'Burkinan', 'zh-CN': '布基纳法索', 'zh-HK': '布基納法索' },
  },
  {
    iso_alpha2_code: 'BG',
    iso_alpha3_code: 'BGR',
    name: { 'en-US': 'Bulgarian', 'zh-CN': '保加利亚', 'zh-HK': '保加利亞' },
  },
  {
    iso_alpha2_code: 'BN',
    iso_alpha3_code: 'BRN',
    name: { 'en-US': 'Bruneian', 'zh-CN': '文莱', 'zh-HK': '文萊' },
  },
  {
    iso_alpha2_code: 'VG',
    iso_alpha3_code: 'VGB',
    name: {
      'en-US': 'British (British Virgin Islands)',
      'zh-CN': '英属维尔京群岛',
      'zh-HK': '英屬維爾京群島',
    },
  },
  {
    iso_alpha2_code: 'IO',
    iso_alpha3_code: 'IOT',
    name: {
      'en-US': 'British (British Indian Ocean Territory)',
      'zh-CN': '英属印度洋领地',
      'zh-HK': '英屬印度洋領地',
    },
  },
  {
    iso_alpha2_code: 'BR',
    iso_alpha3_code: 'BRA',
    name: { 'en-US': 'Brazilian', 'zh-CN': '巴西', 'zh-HK': '巴西' },
  },
  {
    iso_alpha2_code: 'BW',
    iso_alpha3_code: 'BWA',
    name: { 'en-US': 'Botswanan', 'zh-CN': '博茨瓦纳', 'zh-HK': '博茨瓦納' },
  },
  {
    iso_alpha2_code: 'BA',
    iso_alpha3_code: 'BIH',
    name: {
      'en-US': 'Bosnia and Herzegovina citizen',
      'zh-CN': '波斯尼亚 黑塞哥维那',
      'zh-HK': '波斯尼亞 黑塞哥維那',
    },
  },
  {
    iso_alpha2_code: 'BO',
    iso_alpha3_code: 'BOL',
    name: { 'en-US': 'Bolivian', 'zh-CN': '玻利维亚', 'zh-HK': '玻利維亞' },
  },
  {
    iso_alpha2_code: 'BT',
    iso_alpha3_code: 'BTN',
    name: { 'en-US': 'Bhutanese', 'zh-CN': '不丹', 'zh-HK': '不丹' },
  },
  {
    iso_alpha2_code: 'BM',
    iso_alpha3_code: 'BMU',
    name: { 'en-US': 'Bermudian', 'zh-CN': '百慕大', 'zh-HK': '百慕大' },
  },
  {
    iso_alpha2_code: 'BJ',
    iso_alpha3_code: 'BEN',
    name: { 'en-US': 'Beninese', 'zh-CN': '贝宁', 'zh-HK': '貝寧' },
  },
  {
    iso_alpha2_code: 'BZ',
    iso_alpha3_code: 'BLZ',
    name: { 'en-US': 'Belizean', 'zh-CN': '伯利兹', 'zh-HK': '伯利兹' },
  },
  {
    iso_alpha2_code: 'BE',
    iso_alpha3_code: 'BEL',
    name: { 'en-US': 'Belgian', 'zh-CN': '比利时', 'zh-HK': '比利時' },
  },
  {
    iso_alpha2_code: 'BY',
    iso_alpha3_code: 'BLR',
    name: { 'en-US': 'Belarusian', 'zh-CN': '白俄罗斯', 'zh-HK': '白俄羅斯' },
  },
  {
    iso_alpha2_code: 'BB',
    iso_alpha3_code: 'BRB',
    name: { 'en-US': 'Barbadian', 'zh-CN': '巴巴多斯', 'zh-HK': '巴巴多斯' },
  },
  {
    iso_alpha2_code: 'BD',
    iso_alpha3_code: 'BGD',
    name: { 'en-US': 'Bangladeshi', 'zh-CN': '孟加拉国', 'zh-HK': '孟加拉國' },
  },
  {
    iso_alpha2_code: 'BH',
    iso_alpha3_code: 'BHR',
    name: { 'en-US': 'Bahraini', 'zh-CN': '巴林', 'zh-HK': '巴林' },
  },
  {
    iso_alpha2_code: 'BS',
    iso_alpha3_code: 'BHS',
    name: { 'en-US': 'Bahamian', 'zh-CN': '巴哈马', 'zh-HK': '巴哈馬' },
  },
  {
    iso_alpha2_code: 'AZ',
    iso_alpha3_code: 'AZE',
    name: { 'en-US': 'Azerbaijani', 'zh-CN': '阿塞拜疆', 'zh-HK': '阿塞拜疆' },
  },
  {
    iso_alpha2_code: 'AT',
    iso_alpha3_code: 'AUT',
    name: { 'en-US': 'Austrian', 'zh-CN': '奧地利', 'zh-HK': '奧地利' },
  },
  {
    iso_alpha2_code: 'AU',
    iso_alpha3_code: 'AUS',
    name: { 'en-US': 'Australian', 'zh-CN': '澳大利亚', 'zh-HK': '澳大利亞' },
  },
  {
    iso_alpha2_code: 'AW',
    iso_alpha3_code: 'ABW',
    name: {
      'en-US': 'Dutch (Aruba)',
      'zh-CN': '阿鲁巴岛',
      'zh-HK': '阿魯巴島',
    },
  },
  {
    iso_alpha2_code: 'AM',
    iso_alpha3_code: 'ARM',
    name: { 'en-US': 'Armenian', 'zh-CN': '亚美尼亚', 'zh-HK': '亞美尼亞' },
  },
  {
    iso_alpha2_code: 'AR',
    iso_alpha3_code: 'ARG',
    name: { 'en-US': 'Argentine', 'zh-CN': '阿根廷', 'zh-HK': '阿根廷' },
  },
  {
    iso_alpha2_code: 'AG',
    iso_alpha3_code: 'ATG',
    name: {
      'en-US': 'Antigua and Barbuda citizen',
      'zh-CN': '安提瓜和巴布达',
      'zh-HK': '安提瓜和巴布達',
    },
  },
  {
    iso_alpha2_code: 'AQ',
    iso_alpha3_code: 'ATA',
    name: {
      'en-US': 'Antarctica citizen',
      'zh-CN': '南极洲',
      'zh-HK': '南極洲',
    },
  },
  {
    iso_alpha2_code: 'AI',
    iso_alpha3_code: 'AIA',
    name: { 'en-US': 'Anguillan', 'zh-CN': '安圭拉', 'zh-HK': '安圭拉' },
  },
  {
    iso_alpha2_code: 'AO',
    iso_alpha3_code: 'AGO',
    name: { 'en-US': 'Angolan', 'zh-CN': '安哥拉', 'zh-HK': '安哥拉' },
  },
  {
    iso_alpha2_code: 'AD',
    iso_alpha3_code: 'AND',
    name: { 'en-US': 'Andorran', 'zh-CN': '安道尔', 'zh-HK': '安道爾' },
  },
  {
    iso_alpha2_code: 'AS',
    iso_alpha3_code: 'ASM',
    name: {
      'en-US': 'American (American Samoa)',
      'zh-CN': '美属萨摩亚',
      'zh-HK': '美屬薩摩亞',
    },
  },
  {
    iso_alpha2_code: 'DZ',
    iso_alpha3_code: 'DZA',
    name: { 'en-US': 'Algerian', 'zh-CN': '阿尔及利亚', 'zh-HK': '阿爾及利亞' },
  },
  {
    iso_alpha2_code: 'AL',
    iso_alpha3_code: 'ALB',
    name: { 'en-US': 'Albanian', 'zh-CN': '阿尔巴尼亚', 'zh-HK': '阿爾巴尼亞' },
  },
  {
    iso_alpha2_code: 'AF',
    iso_alpha3_code: 'AFG',
    name: { 'en-US': 'Afghan', 'zh-CN': '阿富汗', 'zh-HK': '阿富汗' },
  },
];

export const getNationalityData = () => {
  const nationalities: IAreaCodes[] = [];
  data.forEach((item: any) => {
    nationalities.push({
      isoAlpha2Code: item.iso_alpha2_code,
      isoAlpha3Code: item.iso_alpha3_code,
      phoneCountryCode: '',
      name: item.name['en-US'],
      __i18n: {
        name: item.name,
      },
    });
  });
  return nationalities;
};
