import type { IPayee } from '@/types/payouts-type'
import type { IFileObject, IObject, IUser, IAreaCodes } from '@/types/common-types';
import type { UseFormReturn } from 'react-hook-form';

export interface IListViewProps {
  viewId: string;
  user: IUser;
  businessID: string;
  areaCodes: IAreaCodes[];
  permissions: string[];
  availableFeatures: {
    canUpdate: boolean;
    canCreate: boolean;
    canDelete: boolean;
  };
}

export interface IRelatedDetailsProps {
  user: IUser;
  businessID: string;
  areaCodes: IAreaCodes[];
  permissions: string[];
  related: IPayee | undefined;
  transferMethod: any;
  useFormReturn: UseFormReturn;
  disabled?: boolean;
  recordId: string;
  loading?: boolean;
  banks?: any;
  availableFeatures: {
    canUpdate: boolean;
    canDelete: boolean;
  };
}

export interface IBank {
  id: string;
  bank_code: string;
  bank_name: string;
  country: string;
  swift_code: string;
}

export interface INewRelatedFormData {
  nickname: string;
  country: string;
  currency: string;
  identifier_1_value: string;
  identifier_2_value: string;
  identifier_3_value: string;
  bank: IBank;
  bank_settlement_files: IFileObject[];
  payout_accounts: any;
  id?: string;
  i18n?: IObject;
}

export const initRelatedForm: INewRelatedFormData = {
  nickname: '',
  country: 'HK',
  currency: 'HKD',
  identifier_1_value: '',
  identifier_2_value: '',
  identifier_3_value: '',
  bank_settlement_files: [],
  bank: {
    id: '',
    country: '',
    bank_code: '',
    bank_name: '',
    swift_code: '',
  },
  payout_accounts: {},
  i18n: {},
};

export interface IAccountDetailProps {
  related: any | undefined;
  isView: boolean;
  loading: boolean;
  isAdd?: boolean;
  transferMethod: any;
  businessID: string;
  areaCodes: IAreaCodes[];
  relatedFormData: INewRelatedFormData;
  setRelatedFormDate: (relatedFormDate: INewRelatedFormData) => void;
  banks?: any;
  useFormReturn: UseFormReturn;
}
