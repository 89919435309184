import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ThatIsItIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className} inline-block`} onClick={onClick}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37.856 19.7874C37.856 29.6435 29.8661 37.6334 20.0101 37.6334C10.154 37.6334 2.16406 29.6435 2.16406 19.7874C2.16406 16.3599 3.13031 13.1581 4.80527 10.4395C7.9468 5.34051 13.5815 1.94141 20.0101 1.94141C29.8661 1.94141 37.856 9.93133 37.856 19.7874Z"
          fill="#0094FF"
        />
        <path
          d="M20.0095 37.6334C29.8655 37.6334 37.8555 29.6435 37.8555 19.7874C37.8555 9.93133 29.8655 1.94141 20.0095 1.94141C13.5809 1.94141 7.94622 5.34051 4.80469 10.4395"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M12 21L16.8946 25.7444C17.0515 25.8965 17.3014 25.8945 17.4558 25.74L29.358 13.8379"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default ThatIsItIcon;
