
import { useEffect, useState } from 'react';
import PayoutInformation from './payout-information';
import PayeeInformation from './payee-information';
import { IPayee, IPayeeData, IPayeeDataKey, IPayeeError } from '@/types/payouts-type';
import { useTranslation } from 'react-i18next';
import { getCookie, verifyEmail } from '@/utils';
import { Constants } from '@/constants';
import { getPayee, updataPayee } from '@/data/payouts';
import { Alert } from '@/components/common/alert/alert';
import BackIcon from '@/components/svg/back-icon';
import Button from '@/components/common/button';
import PayoutsTable from './payouts-table';
import ArchivePayee from '../components/archive-modal';
import AntdModal from '@/components/common/antd/modal';
import WarningIcon from '@/components/svg/warning-icon';
import { useNavigate, useParams } from 'react-router-dom';
import { IAreaCodes } from '@/types/common-types';
import { loadAreaCodesData } from '@/data/area-codes';
import { initPayee, initPayeeData } from '@/utils/payouts-utils';
import { IReducersState } from '@/reducers';
import { useDispatch, useSelector } from 'react-redux';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';

const payeeErrorInit: IPayeeError = {
  type: undefined,
  company_name: undefined,
  nickname: undefined,
  email: undefined,
  city: undefined,
  billing_address_1: undefined,
  billing_address_2: undefined,
  zip_code: undefined,
  phone: undefined,
};

const PayeesDetailsPage = () => {
  const getData = (payee: IPayee) => {
    const {
      company_name,
      nickname,
      email,
      phone_country_code,
      phone_number,
      payout_accounts,
      type,
    } = payee || {};

    const { city, billing_address_1, billing_address_2, state, zip_code } =
      payout_accounts || {};

    return {
      type: type || '',
      company_name: company_name || '',
      nickname: nickname || '',
      email: email || '',
      city: city || '',
      billing_address_1: billing_address_1 || '',
      billing_address_2: billing_address_2 || '',
      state: state || '',
      zip_code: zip_code || '',
      phone: [phone_country_code || '+852', phone_number || ''],
    };
  };
  const permissions = useSelector((state: IReducersState) => state.businessState.permissions);
  const { lang = 'en', p_business_id: businessID = '', payee_id: recordId = '', type: activekey = '', isEdit } = useParams();
  const [isView, setIsView] = useState<boolean>(isEdit !== 'true');
  const [showTips, setShowTips] = useState<boolean>(false);
  const [payeeData, setPayeeData] = useState<IPayeeData>(initPayeeData);
  const [payeeError, setPayeeError] = useState<IPayeeError>(payeeErrorInit);
  const [payee, setPayee] = useState<IPayee>(initPayee);
  const [loading, setLoading] = useState(false);
  const [showArchive, setShowArchive] = useState<boolean>(false);
  const [areaCodes, setAreaCodes] = useState<IAreaCodes[]>([]);
  const { t } = useTranslation('payees');
  const { t: ct } = useTranslation();
  const navigate = useNavigate();
  const dispatch: IDispatch = useDispatch();

  const { nickname, payout_accounts } = payee;

  const { transfer_method } = payout_accounts;

  useEffect(() => {
    const newPayeeError = { ...payeeErrorInit };
    Object.keys(payeeError).forEach((value) => {
      const key = value as IPayeeDataKey;
      if (payeeError[key]) {
        newPayeeError[key] = {
          type: 'required',
          message: ct('no_data_hint'),
        };
      }
    });
    if (newPayeeError.email) {
      newPayeeError.email = {
        type: 'validate',
        message: t('invalid_email_address'),
      };
    }

    setPayeeError(newPayeeError);
    // eslint-disable-next-line
  }, [lang]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_BREADCRUMB,
      breadcrumbData: [
        {
          key: '1',
          name: ct('payouts'),
          type: 'label',
        },
        {
          key: '2',
          name: ct('payees'),
          type: 'link',
          url: `/${lang}/businesses/${businessID}/payees`,
        },
        {
          key: '3',
          name: nickname,
          type: 'label',
        },
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, nickname])

  useEffect(() => {
    setIsView(!isEdit);
    // eslint-disable-next-line
  }, [isEdit]);

  useEffect(() => {
    loadingInitData();
    // eslint-disable-next-line
  }, []);

  const loadingInitData = async() => {
    const token = getCookie(Constants.TOKEN) || '';
    const allPromise: any[] = [
      getPayee(recordId, businessID, token),
      loadAreaCodesData()
    ];
    const resp = await Promise.all(allPromise);
    setPayee(resp[0]);
    setPayeeData(getData(resp[0]));
    setAreaCodes(resp[1]);
  }

  const handleChange = (value: string, name: IPayeeDataKey) => {
    let error: any = undefined;
    if (
      [
        'type',
        'company_name',
        'nickname',
        'city',
        'billing_address_1',
        'billing_address_2',
      ].includes(name)
    ) {
      if (!value) {
        error = { type: 'required', message: ct('no_data_hint') };
      }
    }
    if (name === 'email' && !verifyEmail(value) && value) {
      error = { type: 'validate', message: t('invalid_email_address') };
    }
    setPayeeData({
      ...payeeData,
      [name]: value,
    });
    setPayeeError({
      ...payeeError,
      [name]: error,
    });
  };

  const handleBack = () => {
    if (!isView) {
      let change = false;
      const originalData = getData(payee);
      const keys = Object.keys(originalData);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i] as IPayeeDataKey;
        if (key === 'company_name' && payeeData.type === 'People') {
          continue;
        }
        if (key === 'phone') {
          const phone1 = originalData.phone.join('');
          const phone2 = payeeData.phone.join('');
          if (phone1 === phone2 || `+${phone1}` === phone2) {
            continue;
          } else {
            change = true;
            break;
          }
        }
        if (originalData[key] !== payeeData[key]) {
          change = true;
          break;
        }
      }
      if (change) {
        setShowTips(true);
        return;
      }
      setIsView(true);
      setPayeeData(getData(payee));
      setPayeeError(payeeErrorInit);
    } else {
      navigate(`/${lang}/businesses/${businessID}/payees`);
    }
  };

  const loadingPayee = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    const resp = await getPayee(recordId, businessID, token);
    setPayeeData(getData(resp));
    setPayee(resp);
  };

  const handleSave = async () => {
    setLoading(true);
    let haveError = false;
    let requiredFields = ['type', 'nickname'];
    if (
      payeeData.type === 'Business' &&
      transfer_method !== 'Platform Transfer'
    ) {
      requiredFields.push('company_name');
    }
    if (transfer_method === 'RTGS' || transfer_method === 'SWIFT') {
      requiredFields = requiredFields.concat([
        'city',
        'billing_address_1',
        'billing_address_2',
        'zip_code',
      ]);
    }
    const newPayeeError = { ...payeeErrorInit };
    Object.keys(payeeError).forEach((value) => {
      const key = value as IPayeeDataKey;
      let error: any = undefined;
      if (requiredFields.includes(key) && !payeeData[key]) {
        error = { type: 'required', message: ct('no_data_hint') };
        haveError = true;
      }

      if (key === 'email' && payeeData[key] && !verifyEmail(payeeData[key])) {
        error = {
          type: 'validate',
          message: t('invalid_email_address'),
        };
        haveError = true;
      }
      newPayeeError[key] = error;
    });
    if (haveError) {
      setPayeeError(newPayeeError);
      setLoading(false);
      return;
    }
    const data = {
      billing_address_1: payeeData.billing_address_1,
      billing_address_2: payeeData.billing_address_2,
      city: payeeData.city,
      company_name: payeeData.company_name,
      email: payeeData.email,
      nickname: payeeData.nickname,
      phone_country_code: payeeData.phone[0],
      phone_number: payeeData.phone[1],
      state: payeeData.state,
      type: payeeData.type,
      zip_code: payeeData.zip_code,
    };

    const payeeID = await updataPayee(recordId, businessID, data);

    if (payeeID === recordId) {
      Alert.success({
        message: t('payee_edited'),
        position: 'default',
        wrapClass: 'top-px',
      });
      if (isEdit) {
        navigate(`/${lang}/businesses/${businessID}/payees`);
      } else {
        await loadingPayee();
        setIsView(true);
      }
    } else {
      Alert.error({
        message: t('payee_edit_failed'),
        position: 'default',
        wrapClass: 'top-px',
      });
    }
    setLoading(false);
  };

  return (
    <>
      <div
        className={`flex overflow-hidden ${
          activekey === 'payouts' ? 'h-full' : ''
        }`}
      >
        <div className="bg-system-background-color pt-5 pb-2.5 md:px-2 md:py-6 lg:px-5 w-full flex flex-col">
          <div className="md:flex mb-4 justify-between md:mb-6 mx-3">
            <div className="flex">
              <div className="bg-disabled-color shadow-box-drop rounded-full w-10 h-10 flex items-center justify-center cursor-pointer mr-2.5">
                <BackIcon
                  className="hover:text-primary-color"
                  onClick={handleBack}
                />
              </div>
              <div className="text-xl leading-[30px] md:text-3xl md:leading-10 font-bold text-color-text-1 mb-4 md:mb-0">
                {`${
                  !isView && activekey === 'information'
                    ? t('editing')
                    : ''
                }${nickname}`}
              </div>
            </div>
            {activekey !== 'payouts' && (
              <div className="flex flex-col-reverse md:flex-row">
                <div className="flex gap-x-4 justify-start">
                  {permissions.includes('payees:delete') && isView && (
                    <Button
                      styleType="Secondary Danger"
                      onClick={() => setShowArchive(true)}
                    >
                      {t('archive')}
                    </Button>
                  )}
                  {permissions.includes('payees:update') && isView && (
                    <Button onClick={() => setIsView(false)}>
                      {ct('edit')}
                    </Button>
                  )}
                  {!isView && (
                    <>
                      <Button styleType="Border" onClick={handleBack}>
                        {ct('cancel')}
                      </Button>
                      <Button onClick={handleSave} loading={loading}>
                        {ct('save')}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="flex-1 flex flex-col overflow-hidden">
            <div className="mx-3 mb-6 flex gap-x-4 text-sm font-medium">
              <div
                className={`h-10 py-2 px-4 rounded-30 whitespace-nowrap flex items-center ${
                  activekey === 'information'
                    ? 'bg-primary-color text-color-text-6'
                    : 'bg-color-text-6 text-color-text-2 cursor-pointer hover:text-primary-color border-border-color border'
                }`}
                onClick={() => {
                  navigate(
                    `/${lang}/businesses/${businessID}/payees/${recordId}/information`
                  );
                }}
              >
                {t('information')}
              </div>
              {isView && permissions.includes('payouts:read') && (
                <div
                  className={`h-10 py-2 px-4 rounded-30 whitespace-nowrap flex items-center ${
                    activekey === 'payouts'
                      ? 'bg-primary-color text-color-text-6'
                      : 'bg-color-text-6 text-color-text-2 cursor-pointer hover:text-primary-color border-border-color border'
                  }`}
                  onClick={() => {
                    navigate(
                      `/${lang}/businesses/${businessID}/payees/${recordId}/payouts`
                    );
                  }}
                >
                  {ct('payouts')}
                </div>
              )}
            </div>
            {activekey === 'information' && (
              <>
                <PayoutInformation payee={payee} />
                <PayeeInformation
                  payee={payee}
                  areaCodes={areaCodes}
                  payeeData={payeeData}
                  payeeError={payeeError}
                  handleChange={handleChange}
                  isView={isView}
                />
              </>
            )}
            {permissions.includes('payouts:read') &&
              activekey === 'payouts' && (
                <PayoutsTable businessID={businessID} id={recordId} />
              )}
          </div>
        </div>
      </div>
      {showArchive && (
        <ArchivePayee
          payee={payee}
          businessID={businessID}
          onCancel={() => setShowArchive(false)}
          callback={handleBack}
        />
      )}
      <AntdModal
        open={showTips}
        size="small"
        footer={null}
        onCancel={() => setShowTips(false)}
      >
        <div className="flex flex-col items-center justify-center text-color-text-1 font-medium p-10 pt-8">
          <div className="p-5 rounded-full bg-warning-color/10">
            <WarningIcon className={`text-warning-color text-3xl`} size={40} />
          </div>
          <div className="text-center font-bold text-xl pt-4">
            {t('unsaved_changes')}
          </div>
          <div className="text-center pt-2 text-sm">
            {t('change_tips')}
          </div>
          <Button
            className="w-full text-base mt-6"
            onClick={() => setShowTips(false)}
          >
            {t('keep_editing')}
          </Button>
          <Button
            styleType={'Border'}
            className="w-full mt-4 text-base"
            onClick={() => {
              setShowTips(false);
              setPayeeData(getData(payee));
              setPayeeError(payeeErrorInit);
              setIsView(true);
            }}
          >
            {t('discard_changes')}
          </Button>
        </div>
      </AntdModal>
    </>
  );
};

export default PayeesDetailsPage;
