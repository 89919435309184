import { useParams } from 'react-router-dom';
import styles from '@/pages/content.module.css';

const IssuingTerms = () => {
  const { lang = 'en' } = useParams();

  return (
    <>
      {lang !== 'zh' && lang !== 'tw' && (
        <div className={`${styles.header}`}>
          <div className={`${styles.payment}`}>
            <h3 className={`${styles.heading}`}>Issuing Terms</h3>
            <h3>1. Introduction</h3>
            <p>
              1.1 This document (the <strong>‘Issuing Terms</strong>’) is a
              supplement to the Payout and FX Terms (the ‘Customer Agreement’)
              and it must be read together with the Customer Agreement. These
              Issuing Terms together with the{' '}
              <strong>Customer Agreement</strong> governs the use of Cards by
              you (‘<strong>Customer</strong>’ or ‘<strong>You</strong>’) and
              your Additional Cardholders. These Issuing Terms are incorporated
              into and forms part of the Customer Agreement as if set out in
              full in the Customer Agreement. Unless otherwise defined
              throughout and clause 9 of these Issuing Terms, all capitalised
              terms have the meanings given in the Customer Agreement.
            </p>
            <p>
              1.2 You should also download a copy of these Issuing Terms and
              keep it for future reference or ask us for a copy at any time. You
              can always see the most current version of these Issuing Terms (as
              well as the Customer Agreement), on our website www.bindolabs.com.
            </p>
            <p>
              1.3 The Card is issued by Bindo Labs Limited (‘Bindo’, ‘
              <strong>Us</strong>’ or ‘<strong>We</strong>’), which has its
              registered office at . Bindo’s contact details are set out in the
              Customer Agreement.
            </p>
            <p>
              1.4 You or any Authorised User may request that a Card be issued
              to you or any Additional Cardholder in accordance with clause 3.
              If we agree to your request, these terms and conditions shall
              apply to the use of any Cards we issue to you or any Additional
              Cardholder. By applying for, or using, a Card, you confirm that
              you accept and agree to these Issuing Terms as they may be amended
              from time to time by Bindo in accordance with the Customer
              Agreement. If you do not agree to the terms of these Issuing
              Terms, please do not apply for, or use, your Card.
            </p>
            <h3>2. USING THE CARD</h3>
            <p>
              2.1 <strong>Applying for a Card</strong>. When you or an
              Authorised User requests a Card, it will be issued in the form of
              a virtual card (‘<strong>Virtual Card</strong>’).
            </p>
            <p>
              2.2 A Virtual Card shall consist of a 16-digit account number,
              expiry date and ‘CVV/CVV2’ code (the ‘
              <strong>Card Details</strong>’) which will be made available to
              you through the Bindo Platform, if we agree to your request to be
              issued with a Card. We may in the future provide the functionality
              for you to be issued with a physical card (‘
              <strong>Physical Card’</strong>). Physical Cards will be sent to
              you in the mail, if we approve your request for a Physical Card.
            </p>
            <p>
              2.3 Your Card is linked to your Wallet and can be used to pay for
              goods and services using the balance of your Wallet. We will not
              setup a separate account in connection with your Card. The Card is
              not a credit card and can only be used for purchases up to the
              value of your Wallet balance at any given time. Before making a
              Payment you must therefore ensure that your Wallet has sufficient
              balance as we will not provide you with any credit in connection
              with your use of the Card.
            </p>
            <p>
              2.4 You must only use your Card for lawful purposes. All Cards
              remain our property and we may request that you return all Cards
              to us at any time.
            </p>
            <p>
              2.5 <strong>Virtual Card</strong>. A Virtual Card may be used to
              make purchases over the phone or the internet or in any ‘card not
              present transaction’ by entering your Card Details where
              MasterCard is accepted as a means of payment. A Virtual Card
              cannot be used to make a payment in person or in any transaction
              which requires the use of a Physical Card. Virtual Cards may be
              issued for one time
            </p>
            <p>
              use (meaning they can only be used for a single transaction) or
              can be issued so that they can be used repeatedly until the expiry
              date.
            </p>
            <p>
              2.6 <strong>Physical Card</strong>. Physical Cards may be used in
              the same way as a Virtual Card and may also be used for in store
              and card present transactions where MasterCard is accepted
              electronically. We may not permit the Card to be used for manual
              or offline transactions. If the Card functionality will be so
              limited we will notify you of this at the time we approve you for
              a Physical Card.
            </p>
            <p>
              2.7 If your Physical Card has the payWave logo present, you may be
              able to make transactions by tapping your Card against the
              contactless reader at a participating merchant.
            </p>
            <p>
              2.8 <strong>Activating your Card</strong>. When you receive a
              Physical Card, you must sign the back of the Card and activate the
              Card. We will send you instructions on how to activate the
              Physical Card when we send this to you.
            </p>
            <p>
              2.9 <strong>Pre-authorisation</strong>. You may pre-authorise the
              estimated amount of (or a certain percentage of) the final amount
              of a Card transaction with a merchant. The amount of the
              pre-authorisation may be reflected in the balance of your Wallet
              and may be made unavailable for future purchases. Any portion of
              such pre-authorised amount which is not applied towards the
              payment of goods or services will then be made available to you.
            </p>
            <p>
              2.10 <strong>Currency conversion</strong>. Your Card may be used
              to make purchases in certain Supported Currencies and MasterCard
              Supported Currencies.
            </p>
            <p>
              2.11 If your transaction is in a Supported Currency, the amount of
              your transaction shall be deducted from the balance of your Wallet
              in that Supported Currency. If you do not have sufficient balance
              in the relevant Supported Currency and you have automatic FX
              Exchange Rate conversions enabled for a Card, Bindo will: (i)
              automatically convert the transaction currency to your Primary
              Currency at the prevailing FX Exchange Rate; and (ii) deduct the
              transaction amount from the Wallet balance of your Primary
              Currency. If your transaction is in a MasterCard Supported
              Currency, the amount of your transaction will be converted from
              the balance of your Wallet maintained in your Primary Currency
              using the prevailing foreign exchange rates determined by
              MasterCard for that transaction. You acknowledge and agree that we
              have no control over the rates selected by MasterCard and
              MasterCard may charge you a fee as part of this conversion
              process.
            </p>
            <p>
              2.12 You should ensure that your Wallet has suﬃcient balance in
              the relevant currency for a transaction. If the balance of your
              Wallet is not suﬃcient in the relevant currency, the transaction
              may be declined (even if your Wallet has balances maintained in
              other currencies). You acknowledge that Bindo and/or MasterCard
              may perform multiple currency conversions in order to settle a
              particular transaction.
            </p>
            <p>
              2.13 <strong>Refunds</strong>. If you receive a refund on a card
              payment in a Supported Currency that you are permitted to hold in
              your Wallet, we will credit your Wallet in that currency. If you
              receive a refund in anything other than a Supported Currency that
              you are permitted to hold in your Wallet, that amount will be
              converted into your Base Currency before being credited to your
              account at an exchange rate determined by MasterCard (which may be
              different to the exchange rate for the original transaction).
              Generally, transactions made in one currency must be refunded in
              that same currency. Refunds of transactions made through your Card
              shall be credited to the balance of your Wallet and may not be
              made through other means (for example, through cash).
            </p>
            <p>
              2.14 <strong>Merchant surcharges</strong>. In some instances you
              may also be charged a surcharge by merchants in connection with
              the use of your Card. The surcharge may be applied once you have
              confirmed the amount of the transaction. This surcharge may appear
              as a separate transaction or as part of the entire purchase
              amount. Once you have confirmed the transaction you will not be
              able to dispute the surcharge amount.
            </p>
            <p>
              2.15 <strong>Authorisation and Suspension</strong>. Transactions
              on your Card may require our authorisation. We may in our
              reasonable discretion deny authorisation and/or suspend the use of
              your Card because the balance of your Wallet is insufficient, the
              transaction exceeds any transaction limit in connection with your
              Card from time to time, we suspect that there is fraud or a
              security issue or we suspect that your Card is being used for an
              illegal purpose or you have materially breached the Customer
              Agreement, these Issuing Terms or any Additional Terms. We will
              give you notice of any suspension and the reasons for such
              suspension as soon as we can. Where possible we will do this
              before the suspension is put in place, or immediately after,
              unless it would compromise our reasonable security measures or
              otherwise be unlawful. We will lift the suspension as soon as
              practicable after the reasons for the suspension have ceased to
              exist. We will allow you to use or if necessary replace the Card
              as soon as practicable after the reasons for stopping its use
              cease to exist. Before, we authorise a transaction we may require
              you to confirm your identity by following certain steps specified
              by us. You acknowledge and agree that we are not liable to you or
              any other person for any loss suffered as a result of an
              authorisation not being granted.
            </p>
            <p>
              2.16<strong> Expiry date.</strong> Unless you tell us not to, we
              may automatically issue a new Card prior to the expiry date of an
              existing Card, but we are not obliged to do so. You must not use a
              Card after its expiry date.
            </p>
            <p>
              2.17 <strong>Goods and Services</strong>. We are not responsible
              for the quality, safety, legality, or any other aspect of any
              goods or services purchased with the Card. We are not liable for
              any loss arising from any merchant refusing to accept your Card.
              Any complaints about any goods or services purchased with a Card
              must be resolved directly with a merchant.
            </p>
            <p>
              2.18 <strong>Transaction and Card limits</strong>. We may from
              time to time agree transaction limits on your use of the Card
              which may be on a per day or per transaction basis. Other third
              party organisations may impose additional restrictions on the
              amount of your transactions. We may also place limits on the
              number of Cards you can request from us each month or that you may
              have active at any one time.
            </p>
            <h3>3. CARD MANAGEMENT; ADDITIONAL CARDHOLDERS</h3>
            <p>
              3.1 <strong>Who may request Cards</strong>. You and each of your
              Authorised Users which are authorised by you to request Cards may
              request a Card be issued to you or an Additional Cardholder. If
              you are not a Consumer, cards may (a) be issued to Additional
              Cardholders that are your employees for use in connection with
              your business and (b) only be issued for business purposes and may
              not be issued to any person that is not your employee.
            </p>
            <p>
              3.2 You must or you must ensure that we will be provided with the
              following details of an Additional Cardholder including: name,
              address, phone and email address and any other contact or
              identification information of the Additional Cardholder that we
              may reasonably require. You must inform us promptly if any details
              previously provided about an Additional Cardholder changes or is
              incorrect. You will ensure that your Additional Cardholders comply
              with the Customer Agreement and these Issuing Terms and in respect
              of your obligations and liabilities under this agreement and for
              such purposes any references to:
            </p>
            <p>
              (a) ‘you’ shall (where the context requires) be read as including
              your Additional Cardholders; and
            </p>
            <p>
              (b) any reference to ‘your Card’ or ‘Card’ shall (where context
              requires) include Cards issued to Additional Cardholders.
            </p>
            <p>
              3.3 We may in our discretion refuse to issue a Card to an
              Additional Cardholder (for example, where we have not been
              provided with information we have requested about the Additional
              Cardholder) or limit the number of Additional Cardholders in
              connection with your Wallet.
            </p>
            <p>
              3.4 <strong>Your liability for Additional Cardholders</strong>.
              You acknowledge and agree that you are liable for all transactions
              made by an Additional Cardholder using a Card. You are also bound
              by all requests made by an Authorised User for us to issue a Card.
            </p>
            <p>
              3.5 <strong>Cancelling a Card</strong>. You or any Authorised User
              which are authorised by you can view details of the Cards that
              have been issued in connection with your Wallet and you or any
              Authorised User which are authorised by you may request that any
              of those Cards be cancelled or temporarily suspended at any time.
            </p>
            <p>
              3.6 You will however remain liable for all transactions made
              through your Card prior to its cancellation and for all
              transactions posted to your Wallet in respect of the period up
              until the Card is cancelled.
            </p>
            <p>
              3.7 <strong>Card restrictions</strong>. You may request us to
              place a special restriction on:
            </p>
            <p>
              (a) the maximum amount for a transaction that may be conducted
              using a Card;
            </p>
            <p>
              (b) the place at which, or merchant with which, a Card may be
              used. Such a request must be made to us in writing or, if we
              permit, through the Bindo Platform. We will inform you if we
              approve the request.
            </p>
            <p>
              3.8 It is your obligation to notify each Authorised User of any
              restrictions that apply to a Card and the rules regarding the use
              of Cards.
            </p>
            <h3>3. SAFETY AND SECURITY</h3>
            <p>4.1 You must:</p>
            <p>
              (a) only allow Authorised Users authorised to use a Card issued to
              you to use that Card and not any other person;);
            </p>
            <p>
              (b) only allow an Additional Cardholder to use a Card that has
              been issued to them to use that Card and not any other person;
            </p>
            <p>(c) not give your PIN to anyone else;</p>
            <p>
              (d) not use identifiable numbers which could be easily guessed by
              someone else for your PIN;
            </p>
            <p>
              (e) regularly monitor your account to make sure no unauthorised
              transactions have occurred;
            </p>
            <p>
              (f) not record the PIN on anything carried with your Card or on
              anything liable to loss or theft simultaneously with your Card,
              unless you make a reasonable attempt to protect the security of
              the PIN; and
            </p>
            <p>
              (g) if your Card is lost or stolen, if you suspect that someone
              else knows your PIN or your Card Details, or if you think your
              Card, Card Details or PIN may be misused, stop using the Card and
              contact us immediately.
            </p>
            <p>
              4.2 You must also comply with any additional security requirements
              we notify to you from time to time.
            </p>
            <p>
              4.3 We may disclose any information we believe to be reasonable to
              law enforcement agencies if we suspect there has been any
              unauthorised use, misuse or fraud in connection with your Card.
            </p>
            <h3>5. HOW TO REPORT A LOST OR STOLEN CARD OR UNAUTHORISED USE</h3>
            <p>
              5.1 If you believe your card has been lost or stolen or used
              without your authorisation you must notify us immediately by:
            </p>
            <p>(a) emailing us at: support@bindo.com</p>
            <p>(b) telephone: +852-2777 1177</p>
            <p>
              5.2 We may request additional information from you in connection
              with any misuse of your card including date of transaction(s),
              amount(s), details of the merchant and any other details that may
              assist us in investigating your claim. You agree that you will
              cooperate with us in investigating any claims in connection with
              the misuse of your card.
            </p>
            <h3>6. LIABILITY</h3>
            <p>
              6.1 Subject to the terms of the Customer Agreement, you are liable
              for all transactions made by you, any Additional Cardholders or
              Authorised Users using a Card until such time as such Card expires
              or is cancelled.
            </p>
            <p>
              6.2 Without limiting any clause of the Customer Agreement, you
              will be liable for all unauthorised transactions where you have
              contributed to the loss by:
            </p>
            <p>
              (a) breaching or compromising the security of your Card, PIN, Card
              Details or your Wallet including by breaching clause 4 above; or
            </p>
            <p>
              (b) delaying the reporting of the loss, theft or misuse of your
              Card, PIN or Card Details, in which case you will be liable for
              all losses until such time as you notify us of the loss, theft or
              misuse of your Card. If you are not a Corporate Customer, this
              clause 6.2 applies to you only if you have acted fraudulently, or
              have intentionally or through gross negligence, failed to keep
              your PIN and Card Details safe and secure.
            </p>
            <p>
              6.3 We are not responsible for any decision by a merchant to
              accept or reject the use of a Card or for any charges incurred by
              any user of a Card who does not, in fact, have sufficient
              authorisation from you to use the Card.
            </p>
            <h3>7. CHARGEBACKS</h3>
            <p>
              7.1 We may be entitled to seek to reverse (chargeback)
              transactions made through your Card where you have a dispute with
              the merchant that supplied the goods or services. For example, you
              may be entitled to reverse a transaction where the merchant has
              not provided you with the goods or services you paid for and you
              have tried to get a refund from the merchant and were
              unsuccessful.
            </p>
            <p>
              7.2 To avoid losing any rights you may have to dispute such a
              transaction, you should:
            </p>
            <p>
              (a) tell us as soon as possible after the date of the transaction;
              and
            </p>
            <p>
              (b) provide us with any information we ask for to support your
              request.
            </p>
            <p>
              7.3 If we are satisfied after investigation that you are entitled
              to reverse a transaction, the amount initially debited for the
              transaction will be credited to the Wallet in the original
              currency of
            </p>
            <p>
              the transaction if that currency is a Supported Currency that you
              are permitted to hold in your Wallet. If the same currency is not
              a Supported Currency that you are permitted to hold in your Wallet
              at the time of the reversal, the amount will be credited in the
              Base Currency using the then prevailing exchange rates determined
              by Bindo. If the currency of the original transaction is a
              MasterCard Supported Currency, that amount will be converted by
              MasterCard into your Base Currency.
            </p>
            <p>
              7.4 You should notify us immediately of a disputed transaction.
              The operating rules of the Payment Networks impose time limits for
              raising a dispute. Generally, under these rules Bindo must lodge a
              fully detailed claim on your behalf within 120 days. If you do not
              notify us and provide us with sufficient information, we may be
              unable to investigate or lodge a claim in time in which case you
              will be liable for the transaction. It is your responsibility to
              review carefully your transaction history. We are not responsible
              for any loss to you if you do not ask us to reverse a transaction
              in time.
            </p>
            <p>
              7.5 To notify us of a disputed transaction, please contact us and
              request a transaction dispute form and send the completed form to
              Bindo at Bindo Card Transaction Disputes by either:
            </p>
            <p>Mail: Suite 05-181,</p>
            <p>5/F The Quayside,</p>
            <p>77 Hoi Bun Road, Kwun Tong </p>
            <p>Email: support@Bindo.com</p>
            <p>
              7.6 Please assist us by providing as much information as you can.
              Once we have received your completed form and associated
              information, we can only investigate the transaction on your
              behalf when the transaction has settled. Settlement of a
              transaction is evident when a post date is recorded in the Wallet.
              Our aim is to acknowledge receipt of your dispute form within a
              reasonable time upon receipt. However, the investigation of your
              disputed transaction may take longer.
            </p>
            <h3>8. AMENDING THIS AGREEMENT. </h3>
            <p>
              For the avoidance of doubt, these Issuing Terms may be amended as
              set out in the Customer Agreement.
            </p>
            <h3>9. DEFINITIONS</h3>
            <p>In these Issuing Terms, the following definitions apply:</p>
            <p>
              <strong>Additional Cardholder</strong> means a person to whom we
              have issued a Card to access the balance of your Wallet at your
              request.
            </p>
            <p>
              <strong>Card</strong> means a Physical Card or Virtual Card as the
              context requires.
            </p>
            <p>
              <strong>Card Details</strong> has the meaning given in clause 2.2.
            </p>
            <p>
              <strong>Payment Network</strong> means MasterCard and any other
              similar scheme.
            </p>
            <p>
              <strong>PIN</strong> is the personal identification number we
              issued to you to use with your Physical Card when making in store
              or card present transactions.
            </p>
            <p>
              <strong>Physical Card</strong> has the meaning given in clause
              2.2.
            </p>
            <p>
              <strong>Virtual Card</strong> has the meaning given in clause 2.1.
            </p>
            <p>
              <strong>MasterCard</strong> means MasterCard Inc. and its
              affiliates.
            </p>
            <p>
              <strong>MasterCard Supported Currency</strong> means a currency
              supported by MasterCard in which payments may be made using the
              Card that is not a Supported Currency that you are permitted to
              hold in your Wallet.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default IssuingTerms;
