import { useMemo, useState } from 'react';
import CountryCurrencySelect from './country-currency-select';
import PayoutMethodSelect from './payout-method-select';
import PayeeInformation from './payee-information';
import NewPayeeDetails from './new-payee-details';
import { IAvailableCountry, IAvailableCurrency, ICategory, INewPayeeFormData, IPayee, IPayout, IPayoutMethod, IPayoutsStep, IPurpose } from '@/types/payouts-type';
import { IAreaCodes } from '@/types/common-types';
import { initNewPayeeData } from '@/utils/payouts-utils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getCookie } from '@/utils';
import { Constants } from '@/constants';
import { getPayoutMethod, getPurposeOfTransfer } from '@/data/payouts';
import MethodInformation from './method-information';

interface IPayoutsChoosePayee {
  businessID: string;
  intiPayees: IPayee[];
  payout: IPayout;
  categories: ICategory[];
  availableCurrencies: IAvailableCurrency[];
  availableCountries: IAvailableCountry[];
  areaCodes: IAreaCodes[];
  setPayout: (payout: IPayout) => void;
  setSteps: (steps: IPayoutsStep[]) => void;
  setStepCount: (stepCount: number) => void;
  setPurposes: (purposes: IPurpose[]) => void;
}

const NewPayeeStep: React.FC<IPayoutsChoosePayee> = (props) => {
  const {
    businessID,
    payout,
    availableCurrencies,
    availableCountries,
    areaCodes,
    setPayout,
    setStepCount,
    setSteps,
    setPurposes,
    categories,
  } = props;

  const [payeeData, setPayeeData] = useState<INewPayeeFormData>(initNewPayeeData);
  const [payee, setPayee] = useState<IPayee | undefined>(undefined);
  const [currentStep, setCurrentStep] = useState(0);
  const [payoutMethod, setPayoutMethod] = useState<IPayoutMethod | undefined>(
    undefined
  );
  const [payoutMethods, setPayoutMethods] = useState<IPayoutMethod[]>([]);
  const { t } = useTranslation('payouts');
  const { t: ct } = useTranslation('common');
  const { lang = 'en' } = useParams();

  const steps: IPayoutsStep[] = [
    {
      key: '1',
      title: t('choose_payee'),
    },
    {
      key: '2',
      title: t('transfer_details'),
    },
    {
      key: '3',
      title: t('payout_amount'),
    },
    {
      key: '4',
      title: t('transfer_confirmation'),
    },
  ];

  const handleCancel = () => {
    setSteps(steps);
    setStepCount(0);
  };

  const handleNext = async () => {
    if (payee) {
      const token = getCookie(Constants.TOKEN) || '';
      const data = await getPurposeOfTransfer(
        token,
        payee.payout_accounts.transfer_method,
        lang
      );
      if (!payout.transfer_purpose_id && data.length > 0) {
        setPayout({
          ...payout,
          transfer_purpose: data[0].purpose,
          transfer_purpose_id: data[0].id,
          payee,
        });
      }
      setSteps(steps);
      setPurposes(data);
      setStepCount(1);
    }
  };

  const handleCurrecyNext = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    const payoutMethods = await getPayoutMethod(
      businessID,
      token,
      payeeData.currency,
      payeeData.country,
      lang
    );
    setPayoutMethods(payoutMethods);
    setCurrentStep(1);
  };


  const spaceHead = useMemo(() => {
    const setps = [
      t('select_payout_destination'),
      t('select_payout_method'),
      t('enter_account_details'),
      t('enter_payee_information'),
      '',
    ];
    return (
      <>
        <div className="flex justify-between font-semibold text-color-text-1">
          <div className="text-xl leading-[26px]">{t('new_payee')}</div>
          <div
            className="text-color-text-2 font-medium text-sm cursor-pointer hover:text-color-text-2/80"
            onClick={handleCancel}
          >
            {ct('cancel')}
          </div>
        </div>
        <div className="text-color-text-2 text-sm mt-1">
          {setps[currentStep]}
        </div>
      </>
    );
    // eslint-disable-next-line
  }, [currentStep, payoutMethod, lang]);

  return (
    <>
      {currentStep === 0 && (
        <CountryCurrencySelect
          availableCurrencies={availableCurrencies}
          availableCountries={availableCountries}
          areaCodes={areaCodes}
          heade={spaceHead}
          payeeData={payeeData}
          setPayeeData={setPayeeData}
          handleCurrecyNext={handleCurrecyNext}
        />
      )}
      {currentStep === 1 && (
        <PayoutMethodSelect
          heade={spaceHead}
          payoutMethods={payoutMethods}
          payoutMethod={payoutMethod}
          currency={payeeData.currency}
          setPayoutMethod={setPayoutMethod}
          setCurrentStep={setCurrentStep}
        />
      )}
      {currentStep === 2 && payoutMethod && (
        <MethodInformation
          businessID={businessID}
          areaCodes={areaCodes}
          heade={spaceHead}
          payoutMethod={payoutMethod}
          payeeData={payeeData}
          setPayeeData={setPayeeData}
          setCurrentStep={setCurrentStep}
        />
      )}
      {currentStep === 3 && payoutMethod && (
        <PayeeInformation
          businessID={businessID}
          areaCodes={areaCodes}
          heade={spaceHead}
          categories={categories}
          payoutMethod={payoutMethod}
          payeeData={payeeData}
          setCurrentStep={setCurrentStep}
          setPayee={setPayee}
        />
      )}
      {currentStep === 4 && payee && (
        <NewPayeeDetails
          heade={spaceHead}
          payee={payee}
          handleCancel={handleCancel}
          handleNext={handleNext}
        />
      )}
    </>
  );
};

export default NewPayeeStep;
