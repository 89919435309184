import type { ICartParams, ITerminalLineItemsPage } from '../../../types/terminal-type';
import { useTranslation } from 'react-i18next';
import Label from '@/components/common/label';
import { Cascader } from 'antd';
import type { ICascaderOptions } from '../../../utils/terminal-untils';
import { handlePrice, isDeliveryScheduleLater } from '../../../utils/terminal-untils';
import type { IObject } from '@/types/common-types';
import Button from '@/components/common/button';
import CartIcon from '../terminal-icons/cart-icon';
import StepWrapper from '../components/step-wrapper';
import { useNavigate, useParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import CartItem from '../components/card-item';
import PanelTitle from '../components/panel-title';
import ScheduleIcon from '@/components/svg/time-icon';
import Skeleton from '@/components/skeleton';
import { useUserAction } from '@/utils/common';
import { useState, useEffect } from 'react';

interface ILineItemsWebPage extends ITerminalLineItemsPage {
  cart: ICartParams;
  currency: string;
  loadingData: boolean;
  options: ICascaderOptions[];
  deliverySchedule: string[];
  defoutValue: any;
  setDeliverySchedule: (value: string[]) => void;
  handleMinus: (record: IObject) => void;
  handlePlus: (record: IObject) => void;
}

const LineItemsWebPage: React.FC<ILineItemsWebPage> = (props) => {
  const {
    businessID,
    cart,
    currency,
    loadingData,
    deliverySchedule,
    options,
    inApp,
    defoutValue,
    setDeliverySchedule,
    handleMinus,
    handlePlus,
  } = props;
  
  const { t } = useTranslation(["extended_service", "transaction_advice"]);
  const navigate = useNavigate();
  const { lang = 'en' } = useParams();
  const [pickTime, setpickTime] = useState(false);
  const handleChange = (value: any[]) => {
    setDeliverySchedule(value);
  };

  useUserAction(inApp ? true : false)

  let count = 0;

  cart.listings.forEach((item) => {
    count += item.cartQuantity || 0;
  });

  useEffect(() => {
    let defoutTime = [
      defoutValue.value,
      defoutValue.children && defoutValue.children?.length > 0
        ? defoutValue.children[0].value
        : '',
    ]
    let checkTime = isDeliveryScheduleLater(deliverySchedule, defoutTime)
    setpickTime(!checkTime)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverySchedule]);

  const handlePay = () => {
    let defoutTime = [
      defoutValue.value,
      defoutValue.children && defoutValue.children?.length > 0
        ? defoutValue.children[0].value
        : '',
    ]
    let checkTime = isDeliveryScheduleLater(deliverySchedule, defoutTime)
    setpickTime(!checkTime)
    if (!checkTime) {
      return
    }

    navigate(`/${lang}/businesses/${businessID}/terminal/orders/check`);
  };

  return (
    <StepWrapper active={0} businessID={businessID}>
      <Transition.Child
        className="min-h-full flex flex-col pb-6"
        enter="duration-500 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-10 opacity-40"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        {pickTime && (
          <div className='bg-primary-background-color h-[59px] leading-[59px] font-semibold text-lg rounded text-[#1F2F3C] relative'>
            <div
              className="absolute left-[24px] top-[20px] w-[18px] h-[18px] rounded-full bg-[#FC2E01] text-white text-center leading-[18px] text-sm"
            >
              i
            </div>
            <div className='indent-12'>
              {t('extended_service:time_error')}
            </div>
          </div>
        )}

        <div className={`flex-1 bg-primary-background-color p-6 rounded  ${pickTime ? 'mt-[24px]' : ''}`}>
          {/* <div className={`flex-1 bg-primary-background-color p-6 rounded `}> */}
          <PanelTitle
            loadingData={loadingData}
            title={t('extended_service:choose_item')}
          />
          {loadingData && (
            <>
              <Skeleton row={1} skeletonItemClass={`h-4 w-1/2 rounded-2xl`} />
              <Skeleton
                row={1}
                width={['100%']}
                className="mt-3"
                skeletonItemClass={`h-6 rounded-2xl`}
              />
              {new Array(3).fill('').map((r, i) => (
                <div key={`${r}_${i}`} className="flex mt-8">
                  <Skeleton
                    row={1}
                    className="mr-4 !h-20 !w-20"
                    skeletonItemClass={`h-20 !w-20 rounded-2xl`}
                  />
                  <div className="ml-4 flex-1 flex flex-col justify-between">
                    <Skeleton
                      row={1}
                      width={['100%']}
                      skeletonItemClass={`h-6 rounded-2xl`}
                    />
                    <Skeleton
                      row={1}
                      width={['50%']}
                      skeletonItemClass={`h-6 rounded-2xl`}
                    />
                  </div>
                </div>
              ))}
            </>
          )}
          {!loadingData && (
            <>
              <Label value={t('extended_service:delivery_schedule')} required />
              <Cascader
                value={deliverySchedule}
                allowClear={false}
                suffixIcon={<ScheduleIcon size={20} className="text-primary-color" />}
                changeOnSelect
                onChange={handleChange}
                className="w-full mb-4"
                options={options}
                getPopupContainer={(triggerNode) => triggerNode}
                popupClassName="w-full h-[20rem]"
              />
              <CartItem
                cart={cart}
                currency={currency}
                handleMinus={handleMinus}
                handlePlus={handlePlus}
              />
            </>
          )}
        </div>
        <Button
          onClick={handlePay}
          disabled={count === 0}
          className="mt-4 w-full h-10"
        >
          <div className="flex items-cennter text-sm">
            <span className="relative mr-4">
              <CartIcon />
              <span
                className={`${count === 0
                  ? 'bg-border-color text-disabled-color'
                  : 'bg-primary-background-color text-primary-color'
                  } w-4 h-4 rounded-full absolute right-[-10px] top-[-6px] text-xs leading-4`}
              >
                {count}
              </span>
            </span>
            <span>
              {t('extended_service:pay')}{' '}
              {count !== 0 && `${handlePrice(cart.total)} ${currency}`}
            </span>
          </div>
        </Button>
      </Transition.Child>
    </StepWrapper>
  );
};

export default LineItemsWebPage;
