import React, { useEffect, useState } from 'react';
import type { ISidebarLinkGroupProps } from '../../types/side-bar-type';

const SidebarLinkGroup: React.FC<ISidebarLinkGroupProps> = (props) => {
  const { activecondition, children, inputValue } = props;

  const [open, setOpen] = useState(activecondition);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(activecondition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activecondition]);

  useEffect(() => {
    if (inputValue) {
      setOpen(true);
    } else {
      setOpen(activecondition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <li
      className={`flex flex-col py-0 px-4 rounded-sm ${
        activecondition && 'bg-select-secondary-background-color/70'
      }`}
    >
      {children(handleClick, open)}
    </li>
  );
};

export default SidebarLinkGroup;
