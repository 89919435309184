/**
 * modal right
 * @returns react fc
 */
import { Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import CloseIcon from '@/components/svg/close-icon';

import type { IModalRightProps } from './type';

const ModalRight: React.FC<IModalRightProps> = (props) => {
  const {
    visible = false,
    onCancel,
    children,
    title = '',
    titleButton = '',
    size = 'normal',
    className = '',
    iconInstance,
  } = props;

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  let widthClass = 'w-full md:w-3/4 lg:w-1/3';

  if (size === 'large') {
    widthClass = 'w-full lg:w-2/3';
  }

  return (
    <Transition.Root show={visible} as={Fragment}>
      <div className="relative z-99">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-80 transition-opacity bg-select-secondary-background-color/60" />
        </Transition.Child>

        <div
          id="sidebar"
          className={`flex fixed z-99 right-0 top-0 h-screen overflow-hidden no-scrollbar shrink-0 lg ${widthClass} ${className}`}
        >
          <Transition.Child
            className="flex h-screen w-full"
            enter="duration-500 transform transition ease-in-out"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div
              className="text-color-text-4 mr-4 mt-6 hover:text-color-text-5"
              onClick={handleCancel}
            >
              {!iconInstance && (
                <CloseIcon
                  size={20}
                  className="p-2.5 bg-primary-background-color rounded-full hover:cursor-pointer hover:text-primary-color"
                />
              )}
              {iconInstance && (
                <div className="p-2.5 bg-primary-background-color rounded-full hover:text-primary-color">
                  {iconInstance}
                </div>
              )}
            </div>
            <div className="bg-primary-background-color p-6 w-full flex flex-col overflow-auto">
              <div className="flex justify-between items-center">
                <div className="text-xl text-color-text-1 font-bold leading-normal">
                  {title}
                </div>
                {titleButton}
              </div>
              {children}
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  );
};

export default ModalRight;
