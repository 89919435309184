import type { IObject } from '@/types/common-types';
import { useTranslation } from 'react-i18next';
import FieldItem from './field-item';
import { useEffect, useState } from 'react';
import { getPaymentMethodTranslate } from '@/utils/transaction-utils';
import CopyIcon from '@/components/svg/copy-icon';
import { ConvertTimeZone, handlePrice } from '../../../utils/terminal-untils';
import { transformField } from '@/utils/field-utils';

interface IPaymentDetailsPanelParam {
  record: IObject;
  dateFormat: string;
  type: React.ReactNode;
}

let tipsTimeout: any;

const PaymentDetailsPanel: React.FC<IPaymentDetailsPanelParam> = (props) => {
  const { record = {}, type, dateFormat } = props;
  const {
    amount,
    payment_method: paymentMethod,
    created_at = '',
    payment_data = {},
    currency = 'HKD',
    payment,
  } = record;
  const {
    new_gateway_txn_id = '',
    rrn = '',
    brn = '',
    last_4_digits = '',
    credit_card_type,
    payment_method,
  } = payment_data;
  const { t } = useTranslation(["extended_service","transaction_advice"]);

  const [copySuccess, setCopySuccess] = useState<boolean>(false);

  useEffect(() => {
    getPaymentMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (copySuccess) {
      clearTimeout(tipsTimeout);
      tipsTimeout = setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copySuccess]);

  const getPaymentMethod = () => {
    let returnValue = '';
    if (paymentMethod === 4 || paymentMethod === 18) {
      if (credit_card_type === 'cup') {
        return `UnionPay**${last_4_digits}`
      }
      returnValue = transformField(`${credit_card_type}**${last_4_digits}`);
    } else if (payment_method === 'other_payment') {
      returnValue = payment;
    } else {
      returnValue = getPaymentMethodTranslate(payment_method, t);
    }

    return returnValue;
  };

  const handleCopy = (copyValue: string) => {
    navigator.clipboard.writeText(copyValue).then(() => {
      setCopySuccess(true);
    });
  };

  return (
    <>
      <div className="grid grid-cols-3 mt-6">
        <FieldItem
          label={t('extended_service:payment_amount')}
          value={`${handlePrice(amount)} ${currency}`}
        />
        <FieldItem label={t('extended_service:type')} value={type} />
      </div>
      <div className="grid grid-cols-3 mt-6">
        <FieldItem
          label={t('extended_service:paid_via')}
          value={getPaymentMethod()}
        />
        <FieldItem
          label={t('extended_service:transaction_time')}
          // value={dayjs(created_at).format(`${dateFormat} HH:mm`)}
          value={ConvertTimeZone(created_at, `${dateFormat} HH:mm:ss`)}
        />
        <FieldItem
          label={t('extended_service:transaction_id')}
          value={
            <div className="flex items-center">
              <span>{new_gateway_txn_id}</span>
              <CopyIcon
                onClick={() => handleCopy(new_gateway_txn_id)}
                className="text-primary-color cursor-pointer ml-1"
              />
              <span
                className={`text-primary-color text-xs ml-1 ${copySuccess ? 'visible' : 'invisible'
                  }`}
              >
                {t('extended_service:copied')}
              </span>
            </div>
          }
        />
      </div>
      <div className="grid grid-cols-3 mt-6">
        <FieldItem label={t('extended_service:brn')} value={brn} />
        <FieldItem label={t('extended_service:rrn')} value={rrn} />
      </div>
    </>
  );
};

export default PaymentDetailsPanel;
