import config from '@/libs/config';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

const OpenWonder = () => {
  const [searchParams] = useSearchParams();
  const url = searchParams.get('url') || null;
  const prefix = config.staticPath;

  useEffect(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);

  return (
    <div className="h-screen p-6 gap-y-4">
      <div className="text-center py-12">Starting Wonder</div>
      <div className="text-center pb-3">Wonder is not started ?</div>
      <div className="text-center pb-3">You may not have downloaded Wonder</div>
      <div className="text-center font-normal text-[15px] leading-5 text-color-[#000000] mb-6">
        Download <label className="font-bold">Wonder</label> Today
      </div>
      <div className="flex justify-around gap-x-3">
        <Link className="flex-1" to="https://apps.apple.com/cn/app/wonder-business-wallet/id1626393041">
          <img
            className="h-12 md:h-32 w-full cursor-pointer"
            alt=""
            src={`${prefix}/images/open-wonder/apple-store.png`}
          />
        </Link>
        <Link className="flex-1" to="https://play.google.com/store/apps/details?id=app.wonder.hq">
          <img
            className="h-12 md:h-32 w-full cursor-pointer"
            alt=""
            src={`${prefix}/images/open-wonder/google-paly.png`}
          />
        </Link>
      </div>
    </div>
  );
};

export default OpenWonder;
