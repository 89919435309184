import type { PayeeDynamicsItemProps } from '../../type';
import Label from '@/components/common/label';
import { PayoutTransferIdentifyType } from '@/types/account-balance-type';
import { useSelector } from 'react-redux';
import { IReducersState } from '@/reducers';

const DynamicsItem = (props: PayeeDynamicsItemProps) => {
  const {
    label = '',
    value = '',
    type = '',
  } = props;

  const business = useSelector((state: IReducersState) => state.businessState.business);

  const getValue = (): string => {
    if (type === PayoutTransferIdentifyType.PlatformBusinessId) {
      return business.business_name || '';
    }

    return value;
  };

  return (
    <div className="w-full">
      <Label disabled={true} value={label}></Label>
      <div className="py-2 text-color-text-1 font-medium pr-6 text-sm">
        {getValue()}
      </div>
    </div>
  );
};

export default DynamicsItem;
