import { payInvoice } from '../../../../../data/terminal';
import type { IUnionPayWalletMethod } from '../interface';
import { handleBuildOrderPayment } from '../utils';

interface IUnionPayWalletParams {
  businessID: string;
  orderNumber: string;
  payData: IUnionPayWalletMethod;
  payment: any;
  email: string;
  isPC: boolean;
  lang: string;
}

const UseUnionPayWallet = async (params: IUnionPayWalletParams) => {
  const { businessID, orderNumber, payData, payment, email, isPC, lang } =
    params;

  const { returnUrl, amount, currenToken } = payData;

  let newPayment = {};
  if (isPC) {
    newPayment = {
      unionPay_wallet: [
        {
          amount,
          merchant_presented_qr_code: {},
        },
      ],
    };
  } else {
    newPayment = {
      unionPay_wallet: [
        {
          amount,
          in_web: {
            return_url: returnUrl,
          },
        },
      ],
    };
  }

  const order = handleBuildOrderPayment(newPayment, payment);
  try {
    const resp :any= await payInvoice({
      businessID,
      orderNumber,
      order,
      email,
      lang,
      token: currenToken,
    });
    const { code, data: fulfilOrder } = resp || {};

    if (
      code === 200 &&
      fulfilOrder.sale_transactions &&
      fulfilOrder.sale_transactions.length > 0
    ) {
      return {
        order: fulfilOrder,
      };
    } else {
      return {
        status: 500,
        ...resp,
      };
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export default UseUnionPayWallet;
