import { produce } from 'immer';
import { handleActions } from 'redux-actions';
import { ISetLoginUser } from '@/actions/account-action';
import ActionType from '@/actions/action-type';
import { ILoginUser } from '@/types/account-type';

export interface IAccountState {
  loginUser: ILoginUser;
}

export const initUser = (): ILoginUser => ({
  id: '',
  full_name: '',
  name: '',
  phone: '',
  email: '',
  country: '',
  nationality: '',
  avatarUrl: '',
  access_token: '',
  inventory_manager: false,
  suspended: false,
  preferred_lang: 'en',
  kyc_status: '',
  in_white_list: false,
  id_number: '',
});

const initState: IAccountState = {
  loginUser: initUser(),
};

const actions: any = {};

actions[ActionType.SET_LOGIN_USER] = (state: IAccountState, action: ISetLoginUser) => produce(state, draft => {
  if (action.loginUser) {
    draft.loginUser = action.loginUser;
  }
});

export default handleActions(actions, initState);
