import React from 'react';
import type { IIconProps } from '@/types/common-types';

const RollingReserverIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="20" fill="#31E2D8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8964 22H9.14355V24H13.1436V30H28.1436V24H32.1436V22H28.3902C26.6684 24.4208 23.8402 26 20.6433 26C17.4463 26 14.6182 24.4208 12.8964 22Z"
          fill="white"
        />
        <circle
          cx="20.8332"
          cy="16.6897"
          r="7.03282"
          stroke="white"
          strokeWidth="1.3137"
        />
        <path
          d="M20.0734 16.9301L21.4657 16.9301C21.851 16.9301 22.1645 17.2239 22.1645 17.5849C22.1645 17.9472 21.8515 18.2398 21.4658 18.2398C21.4627 18.2398 21.4596 18.2398 21.4565 18.2398L20.1227 18.2398C19.7947 18.2369 19.4776 18.0689 19.2952 17.8018L19.2882 17.7915C19.1245 17.5508 18.7835 17.48 18.5267 17.6335C18.2698 17.7869 18.1943 18.1064 18.358 18.3471L18.3656 18.3582C18.7501 18.9214 19.3774 19.2735 20.218 19.2735L20.218 19.8933C20.218 20.1788 20.465 20.4102 20.7695 20.4102C21.0741 20.4102 21.3211 20.1787 21.3211 19.8933L21.3211 19.2735C21.8481 19.2735 22.3045 19.1839 22.7351 18.7833C23.0785 18.4639 23.2676 18.0383 23.2676 17.585C23.2676 16.6539 22.4592 15.8965 21.4657 15.8965L20.0734 15.8965C19.6881 15.8965 19.3746 15.6027 19.3746 15.2417C19.3746 14.8934 19.6691 14.5868 20.0826 14.5868L21.5379 14.5868C21.8245 14.5894 22.0994 14.7151 22.2923 14.9321C22.4873 15.1515 22.8351 15.1811 23.0691 14.9984C23.3031 14.8157 23.3347 14.4898 23.1398 14.2705C22.6236 13.6899 22.023 13.5532 21.3211 13.5532L21.3211 12.9333C21.3211 12.6479 21.0742 12.4165 20.7696 12.4165C20.465 12.4165 20.2181 12.6479 20.2181 12.9333L20.2181 13.5532C18.9335 13.5532 18.2716 14.4012 18.2716 15.2417C18.2715 16.1727 19.0799 16.9301 20.0734 16.9301V16.9301Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default RollingReserverIcon;
