import { useEffect, useState } from 'react';
import CleanIcon from '@/components/svg/clean-icon';
import SearchIcon from '../../payouts-icons/search-icon';
import AddIcon from '@/components/svg/add-icon';
import Payees from './payees';
import NoRelatedPayeeIcon from '../../payouts-icons/no-related-payee-icon';
import { IPayee, IPayout, IPayoutsStep, IPurpose } from '@/types/payouts-type';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getAvatarsName, getCookie } from '@/utils';
import api from '@/libs/api';
import Config from '@/libs/config';
import { Constants } from '@/constants';
import { getPurposeOfTransfer } from '@/data/payouts';
import Skeleton from '@/components/skeleton';
import PayeeDetails from '../../components/payee-details';
import Button from '@/components/common/button';
import { colorRgb, getPayoutsI18nValue, initPayout, parsePayees, showTransferMethod } from '@/utils/payouts-utils';

let timer: any = null;
interface IPayoutsChoosePayee {
  businessID: string;
  intiPayees: IPayee[];
  payout: IPayout;
  loading: boolean;
  setPayout: (payout: IPayout) => void;
  setSteps: (steps: IPayoutsStep[]) => void;
  setStepCount: (stepCount: number) => void;
  setPurposes: (purposes: IPurpose[]) => void;
}

const PayoutsChoosePayee: React.FC<IPayoutsChoosePayee> = (props) => {
  const {
    businessID,
    intiPayees,
    payout,
    loading,
    setPayout,
    setStepCount,
    setSteps,
    setPurposes,
  } = props;
  const { payee } = payout;
  const [loadingData, setLoadingData] = useState(loading);
  const [payees, setPayees] = useState<IPayee[]>([]);
  const [show, setShow] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation('payouts');
  const { t: ct } = useTranslation('common');
  const { lang = 'en' } = useParams();

  const searchPayees = async (keyword: string) => {
    const token = getCookie(Constants.TOKEN) || '';
    let result: any = [];
    const resp = await api.get(
      `${
        Config.urls.gatewayUrl
      }/api/treasury-services/api/payees/last_used_payees?limit=4${
        keyword ? `&keyword=${keyword}` : ''
      }`,
      {
        headers: {
          'x-p-business-id': businessID,
          'x-client-id': Config.clientID,
          'x-platform-id': Config.platformId,
          'X-USER-ACCESS-TOKEN': token,
        },
      }
    );

    const { data } = resp;
    if (Array.isArray(data)) {
      result = data;
    }
    setPayees(parsePayees(result));
    setLoadingData(false);
  };

  const handleChange = (value: string) => {
    setSearchValue(value);
    setLoadingData(true);
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      searchPayees(value);
      clearTimeout(timer);
      timer = null;
    }, 500);
  };

  const handleChangePayee = (payee?: IPayee) => {
    setPayout({
      ...initPayout,
      payee,
    });
  };
  useEffect(() => {
    setPayees(intiPayees);
  }, [intiPayees]);

  useEffect(() => {
    setLoadingData(loading);
  }, [loading]);

  const handleToNewPayee = () => {
    const steps: IPayoutsStep[] = [
      {
        key: '1',
        title: t('choose_payee'),
      },
      {
        key: '1A',
        title: t('new_payee'),
      },
      {
        key: '2',
        title: t('transfer_details'),
      },
      {
        key: '3',
        title: t('payout_amount'),
      },
      {
        key: '4',
        title: t('transfer_confirmation'),
      },
    ];
    setSteps(steps);
    setStepCount(1);
  };

  const handleNext = async () => {
    if (payee) {
      const token = getCookie(Constants.TOKEN) || '';
      const data = await getPurposeOfTransfer(
        token,
        payee.payout_accounts.transfer_method,
        lang
      );
      if (!payout.transfer_purpose_id && data.length > 0) {
        setPayout({
          ...payout,
          transfer_purpose: data[0].purpose,
          transfer_purpose_id: data[0].id,
        });
      }
      setPurposes(data);
      setStepCount(1);
    }
  };

  return (
    <div>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        <div className="font-semibold text-xl text-color-text-1">
          {t('choose_payee')}
        </div>
        <div className="mt-4 mb-6 border-solid border-0 border-b border-border-color" />
        {!payee && (
          <>
            <div className="flex-grow group">
              <div className="group rounded flex items-center h-10 px-2.5 py-2 cursor-auto border hover:border-primary-color border-border-color">
                <span>
                  <SearchIcon size={14} />
                </span>
                <input
                  placeholder={t('search_payee')}
                  value={searchValue}
                  className="focus:outline-none flex-grow font-medium text-color-text-1 bg-inherit placeholder:text-color-text-2 placeholder:font-normal placeholder:text-sm text-base px-2 text-ellipsis border-none"
                  autoComplete="none"
                  onChange={(evt) => {
                    handleChange(evt.target.value);
                  }}
                />
                <span className="invisible group-hover:visible">
                  <CleanIcon
                    size={20}
                    className="text-color-text-5 hover:text-color-text-3 cursor-pointer"
                    onClick={() => {
                      handleChange('');
                    }}
                  />
                </span>
              </div>
            </div>
            {!(!loadingData && payees.length === 0) && (
              <div className="text-sm font-medium text-color-text-4 pt-6">
                {t('recent_payees')}
              </div>
            )}
            {!loadingData &&
              payees.map((item) => {
                return (
                  <div
                    key={item.id}
                    onClick={() => handleChangePayee(item)}
                    className="text-color-text-1 font-medium text-sm"
                  >
                    <div className="flex items-center py-3 cursor-pointer hover:bg-disabled-color">
                      <div className="truncate">
                        <span className="inline-flex items-center justify-center h-16 w-16 bg-primary-color/10 rounded-full">
                          <span className="font-bold text-color-text-1 text-xl">
                            {getAvatarsName(item.nickname)}
                          </span>
                        </span>
                      </div>
                      <div className="ml-3">
                        <div>{item.nickname}</div>
                        <div className="pt-1 pb-1.5 text-[#8E8E93] text-sm">
                          {t('transfer_through', {
                            data: showTransferMethod(item),
                          })}
                        </div>
                        <div className="flex items-center text-xs leading-normal">
                          <div className="py-0.5 px-2 bg-[#FFEEAA] rounded-3xl text-[#A28200] mr-1.5">
                            {item.payout_accounts.country_codes.alpha_3}
                          </div>
                          <div className="py-0.5 px-2 bg-[#B3DFFF] rounded-3xl text-primary-color mr-1.5">
                            {item.payout_accounts.currency}
                          </div>
                          {item.categories.category_name && (
                            <div
                              className={`py-0.5 px-2 bg-opacity-30 rounded-3xl`}
                              style={{
                                color: `${item.categories.color}`,
                                backgroundColor: colorRgb(
                                  item.categories.color,
                                  0.3
                                ),
                              }}
                            >
                              {getPayoutsI18nValue(
                                item.i18n,
                                item.categories.category_name,
                                lang
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-border-color"></div>
                  </div>
                );
              })}
            {!loadingData && payees.length === 0 && (
              <div className="flex flex-col items-center justify-center mt-20 mb-52">
                <NoRelatedPayeeIcon size={80} />
                <div className="font-bold text-xl text-color-text-1 mt-6">
                  {searchValue
                    ? t('no_related_payee')
                    : t('no_save_payee_yet')}
                </div>
                <div className="text-color-text-2 mt-2">
                  {t('add_new_payee')}
                </div>
              </div>
            )}
            {loadingData && (
              <div>
                <div className="flex items-center py-4">
                  <Skeleton
                    avatar={true}
                    row={3}
                    className="items-center"
                    width={['100%']}
                    avatarClass="h-14 w-14 mr-3.5"
                    skeletonItemClass="h-4 first:h-6 last:h-6 mb-1.5 last:mb-0 rounded-[50px] w-[200px]"
                  />
                </div>
                <div className="border-b border-border-color"></div>
                <div className="flex items-center py-4">
                  <Skeleton
                    avatar={true}
                    row={3}
                    className="items-center"
                    width={['100%']}
                    avatarClass="h-14 w-14 mr-3.5"
                    skeletonItemClass="h-4 first:h-6 last:h-6 mb-1.5 last:mb-0 rounded-[50px] w-[200px]"
                  />
                </div>
                <div className="border-b border-border-color"></div>
                <div className="flex items-center py-4">
                  <Skeleton
                    avatar={true}
                    row={3}
                    className="items-center"
                    width={['100%']}
                    avatarClass="h-14 w-14 mr-3.5"
                    skeletonItemClass="h-4 first:h-6 last:h-6 mb-1.5 last:mb-0 rounded-[50px] w-[200px]"
                  />
                </div>
                <div className="border-b border-border-color"></div>
                <div className="flex items-center py-4">
                  <Skeleton
                    avatar={true}
                    row={3}
                    className="items-center"
                    width={['100%']}
                    avatarClass="h-14 w-14 mr-3.5"
                    skeletonItemClass="h-4 first:h-6 last:h-6 mb-1.5 last:mb-0 rounded-[50px] w-[200px]"
                  />
                </div>
              </div>
            )}
          </>
        )}
        {payee && (
          <>
            <div className="text-sm font-medium text-color-text-4 mb-3">
              {t('payee_confirmation')}
            </div>
            <PayeeDetails
              payee={payee}
              nickname={false}
              className="pt-3 rounded bg-disabled-color p-4"
            />
          </>
        )}
      </div>
      {payee ? (
        <div className="flex items-center justify-between">
          <Button
            className="flex-1 mr-6"
            onClick={() => handleChangePayee(undefined)}
            styleType="Secondary"
          >
            {ct('back')}
          </Button>
          <Button className="flex-1" onClick={handleNext} styleType="Primary">
            {ct('next')}
          </Button>
        </div>
      ) : (
        <div className="flex items-center justify-between">
          <Button
            className="flex-1 mr-6"
            iconInstance={<AddIcon />}
            styleType="Secondary"
            onClick={handleToNewPayee}
          >
            {t('new_payee')}
          </Button>
          <Button
            className="flex-1"
            onClick={() => setShow(true)}
            styleType="Primary"
          >
            {t('view_all')}
          </Button>
        </div>
      )}
      {show && (
        <Payees
          businessID={businessID}
          showModal={show}
          setShowModal={setShow}
          onChange={handleChangePayee}
        />
      )}
    </div>
  );
};

export default PayoutsChoosePayee;
