import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ProcessingFeeIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick} = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="20" fill="#C0C8D6" />
        <path
          d="M7.74588 25.4065C7.81336 25.2095 8.00839 25.0855 8.21543 25.1081L10.8428 25.3946C11.2612 25.4403 11.3886 25.9873 11.0333 26.2131L7.54974 28.427C7.19448 28.6527 6.75325 28.3051 6.88963 27.9069L7.74588 25.4065Z"
          fill="white"
        />
        <path
          d="M18.8058 20.9938L21.3065 20.9938C21.9986 20.9938 22.5617 21.5568 22.5617 22.2489C22.5617 22.9434 21.9995 23.5041 21.3068 23.5041C21.3011 23.5041 21.2956 23.504 21.2899 23.504L18.8944 23.504C18.3052 23.4985 17.7356 23.1766 17.4081 22.6647L17.3955 22.6449C17.1014 22.1835 16.489 22.0478 16.0277 22.342C15.5664 22.636 15.4307 23.2484 15.7248 23.7098L15.7383 23.731C16.429 24.8106 17.5558 25.4853 19.0656 25.4853L19.0656 26.6735C19.0656 27.2206 19.5091 27.6641 20.0562 27.6641C20.6032 27.6641 21.0468 27.2205 21.0468 26.6735L21.0468 25.4853C21.9934 25.4853 22.8131 25.3136 23.5866 24.5459C24.2033 23.9336 24.5429 23.1179 24.5429 22.249C24.5429 20.4644 23.091 19.0126 21.3065 19.0126L18.8058 19.0126C18.1138 19.0126 17.5507 18.4496 17.5507 17.7575C17.5507 17.09 18.0796 16.5024 18.8224 16.5024L21.4362 16.5024C21.951 16.5073 22.4448 16.7483 22.7913 17.1642C23.1415 17.5846 23.7661 17.6414 24.1864 17.2913C24.6067 16.941 24.6636 16.3164 24.3134 15.8961C23.3864 14.7832 22.3075 14.5212 21.0468 14.5212L21.0468 13.333C21.0468 12.7859 20.6033 12.3425 20.0562 12.3425C19.5092 12.3425 19.0657 12.786 19.0657 13.333L19.0657 14.5212C16.7583 14.5212 15.5696 16.1466 15.5696 17.7575C15.5694 19.542 17.0213 20.9938 18.8058 20.9938V20.9938Z"
          fill="white"
        />
        <circle
          cx="20.1614"
          cy="19.9983"
          r="13.1429"
          stroke="white"
          strokeWidth="1.5414"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="4.45 4.45"
        />
      </svg>
    </div>
  );
};

export default ProcessingFeeIcon;
