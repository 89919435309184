import FpsDetailsIcon from '@/pages/payouts/payouts-icons/fps-transfer-details-icon';
import PlatformTransferDetailsIcon from '@/pages/payouts/payouts-icons/platformtransfer-details-icon';
import RtgsDetailsIcon from '@/pages/payouts/payouts-icons/rtgs-details-icon';
import SwiftDetailsIcon from '@/pages/payouts/payouts-icons/swift-details-icon';
import type {
  INewPayeeFormData,
  // IPayee,
  IPayout,
} from '@/types/payouts-type';
import type { IObject } from '@/types/common-types';
import AlipayDetailsIcon from '@/pages/payouts/payouts-icons/alipay-details-icon';
import BigNumber from 'bignumber.js';
import { insertComma } from '@/utils';

export const parsePayees = (data: any) => {
  const payees: any = [];
  if (Array.isArray(data)) {
    data.forEach((item) => {
      payees.push({
        id: item.id,
        type: item.type,
        nickname: item.nickname,
        p_business_id: item.p_business_id,
        company_name: item.company_name,
        payout_category_id: item.payout_category_id,
        categories: item.categories,
        payout_accounts: item.payout_accounts,
        i18n: item.i18n,
        is_default_bank: item.is_default_bank,
      });
    });
  }
  return payees;
};

export const colorRgb = (color: string, opacity: number) => {
  const sColor = color.toLowerCase();
  const sColorChange = [];
  for (let i = 1; i < 7; i += 2) {
    sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)));
  }
  return `rgba(${sColorChange.join(',')}, ${opacity})`;
};

export const getTransferMethod = (transferMethod: string) => {
  let icon: any = '';
  if (transferMethod.indexOf('FPS') !== -1) {
    icon = <FpsDetailsIcon size={24} />;
  } else if (transferMethod === 'Platform Transfer') {
    icon = <PlatformTransferDetailsIcon size={24} />;
  } else if (transferMethod === 'SWIFT') {
    icon = <SwiftDetailsIcon size={24} />;
  } else if (transferMethod === 'RTGS') {
    icon = <RtgsDetailsIcon size={24} />;
  } else if (transferMethod.indexOf('Alipay') !== -1) {
    icon = <AlipayDetailsIcon size={24} />;
  }
  return (
    <div className="flex items-center">
      {icon}
      <div className="ml-2">{transferMethod}</div>
    </div>
  );
};

export const getIdentifierValue = (
  identifier: 'identifier_1_type' | 'identifier_2_type' | 'identifier_3_type',
  payee: any
) => {
  const { payout_accounts } = payee || {};
  const { banks, transfer_methods } = payout_accounts || {};
  const { bank_code, bank_name, swift_code } = banks || {};
  const type = transfer_methods[identifier];

  if (type === 'SWIFT') {
    return swift_code;
  }
  if (type === 'Bank') {
    return `${bank_code ? `${bank_code} ` : ''}${bank_name}`;
  }
  if (type === 'Phone Number') {
    return payout_accounts[identifier.replace('type', 'value')].replace(
      '-',
      ' '
    );
  }

  return payout_accounts[identifier.replace('type', 'value')];
};

export const showTransferMethod = (payee: any) => {
  let transferMethod = payee.payout_accounts.transfer_method;
  const type1 = payee.payout_accounts.transfer_methods.identifier_1_type;
  const type2 = payee.payout_accounts.transfer_methods.identifier_2_type;
  const type3 = payee.payout_accounts.transfer_methods.identifier_3_type;
  const value1 = payee.payout_accounts.identifier_1_value;
  const value2 = payee.payout_accounts.identifier_2_value;
  const value3 = payee.payout_accounts.identifier_3_value;
  if (payee.payout_accounts.transfer_method.indexOf('FPS') > -1) {
    transferMethod = 'FPS';
  }
  if (type1 === 'Account Number' && value1) {
    transferMethod = `${transferMethod} **${value1.substring(
      value1.length - 4,
      value1.length
    )}`;
  }
  if (type2 === 'Account Number' && value2) {
    transferMethod = `${transferMethod} **${value2.substring(
      value2.length - 4,
      value2.length
    )}`;
  }
  if (type3 === 'Account Number' && value3) {
    transferMethod = `${transferMethod} **${value3.substring(
      value3.length - 4,
      value3.length
    )}`;
  }

  return transferMethod;
};

export const initPayout: IPayout = {
  id: '',
  approved_at: '',
  attachment_files: [],
  calculated_fee: '',
  cancelled_at: '',
  currency: '',
  destination_amount: '',
  destination_currency: '',
  display_name: '',
  estimated_settled_at: '',
  fx_buy_currency: '',
  fx_exchange_rate: '',
  fx_quote_id: '',
  fx_sell_currency: '',
  total_amount: '',
  amount: '',
  payee_id: '',
  payee_name: '',
  payout_category_color: '',
  payout_category_icon: '',
  payout_category_id: '',
  payout_category_name: '',
  po_number: '',
  ref_id: '',
  reference: '',
  remark: '',
  reversed_at: '',
  schedule_payout_at: '',
  schedule_status: '',
  settled_at: '',
  transfer_method: '',
  transfer_status: '',
  transfer_type: '',
  updated_by: '',
  vendor_reference_id: '',
  p_created_at: '',
  transfer_purpose: '',
  transfer_purpose_id: '',
  i18n: {},
  payee: undefined,
};

export const getPayoutsI18nValue = (
  i18n: IObject,
  key: string,
  lang: string
) => {
  const value = key;
  if (i18n[value]) {
    if (i18n[value][lang]) {
      return i18n[value][lang];
    }
  }
  return value;
};

export const initPayeeData: INewPayeeFormData = {
  company_name: '',
  email: '',
  nickname: '',
  billing_address_1: '',
  billing_address_2: '',
  city: '',
  country: 'HK',
  currency: 'HKD',
  identifier_1_value: '',
  identifier_2_value: '',
  identifier_3_value: '',
  state: '',
  transfer_method: '',
  zip_code: '',
  payout_category_id: undefined,
  phone: '',
  type: '',
  bank: {
    id: '',
    country: '',
    bank_code: '',
    bank_name: '',
    swift_code: '',
  },
};

export const handlePrice = (value: number) => {
  const total = new BigNumber(value).absoluteValue().toFixed(2).split('.');
  let amount = '';
  if (value) {
    if (total.length > 1) {
      amount = `${insertComma(total[0])}.${insertComma(
        total[1],
        false,
        3,
        ','
      )}`;
    } else {
      amount = `${insertComma(total[0])}`;
    }
  } else {
    amount = '0.00';
  }

  return amount;
};
