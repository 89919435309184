import Config from '@/libs/config';
import { Constants } from '@/constants';
import api from '@/libs/api';
import { IObject } from '@/types/common-types';
import axios from 'axios';
import { getCookie } from '@/utils/index';
import { currencySymbols } from '@/utils';
import type { IAddressObject, IPaymentSettings } from '../types/terminal-type';
import type PayTypes from '../pages/terminal/utils/payment/pay-types';
import {
  getBusinessPaymentMethods,
  getDeliveryDate,
  triggerWonderRoute,
} from '../utils/terminal-untils';
import UAParser from 'ua-parser-js';

interface IQueryOrderListParams {
  businessID: string;
  offset: number;
  limit: number;
  filter: string;
  customerID: string;
  token?: string;
}

export const bindoBusinessID =
  Config.env === 'staging'
    ? '1f3e0ca6-1f47-4d54-a540-c4e9d909cdf7'
    : '8624b366-b292-11ec-a3d9-42010aaa001d';
// stg -> grd6sf 59219
// alpha -> te3 59250

const httpStatusCallback = (httpStatus: number) => {
  if (httpStatus === 401) {
    triggerWonderRoute('true', 'is401');
  }
};

export const queryOrderList = async (params: IQueryOrderListParams) => {
  const {
    offset = 0,
    limit = 50,
    filter = '',
    token,
    customerID,
    businessID,
  } = params;
  let result: any = {};

  let accessToken = token;

  if (!accessToken) {
    accessToken = (getCookie(Constants.TOKEN) as string) || '';
  }
  try {
    const res: IObject = await api.get(
      `${Config.urls.gatewayUrl}/api/oms/b2c/businesses/${bindoBusinessID}/customer/${customerID}/order_list?offset=${offset}&limit=${limit}&filter[state][_neq]=voided&${filter}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-user-access-token': accessToken,
          'x-p-business-id': businessID,
        },
        httpStatusCallback
      }
    );

    if (res && res.code === 200 && res.data && Array.isArray(res.data.orders)) {
      result = {
        data: res.data.orders,
        count: res.data.count,
      };
    }
    return result;
  } catch (error: any) {
    console.error('error', error);
    return { msg: error.message };
  }
};

interface IqueryOrderCartParams {
  businessID: string;
  customerID: string;
  method?: 'GET' | 'POST';
  token?: string;
  cartData?: IObject;
}

export const OrderCartAPI = async (params: IqueryOrderCartParams) => {
  const { token, businessID, customerID, method = 'GET', cartData } = params;

  let result: any = {};
  let accessToken = token;

  if (!accessToken) {
    accessToken = (getCookie(Constants.TOKEN) as string) || '';
  }

  try {
    const headers = {
      'Content-Type': 'application/json',
      'x-user-access-token': accessToken,
      'x-p-business-id': businessID,
    };

    const url = `${Config.urls.gatewayUrl}/api/oms/b2c/businesses/${bindoBusinessID}/customer/${customerID}/cart`;

    let res: any = null;

    if (method === 'GET') {
      // res = await api.get(url, { headers, httpStatusCallback });
      res = await api.get(url, { headers });

    } else if (method === 'POST' && cartData) {
      res = await api.post(
        url,
        { ...cartData },
        { headers, httpStatusCallback }
      );
    }

    if (res && res.code === 200 && typeof res.data !== 'string') {
      result = res.data;
    }

    return result;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const queryListing = async () => {
  try {
    const res: any = await api.get(
      `${Config.urls.mainBindo}/api/b2c/app/payment_link/businesses/${bindoBusinessID}/favorite_tabs?page=1&per_page=999&with_details=true`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-I18n-Lang': null
        },
      }
    );
    return parseListing(res);
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const resetListing = async (customerID: string, businessID: string, accessToken: string) => {
  try {
    await api.remove(
      `${Config.urls.gatewayUrl}/api/oms/b2c/businesses/${bindoBusinessID}/customer/${customerID}/cart/clean`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-I18n-Lang': null,
          'x-user-access-token': accessToken,
          'x-p-business-id': businessID,
        },
      }
    );
  } catch (error) {
    console.error(error);
  }
};

const parseListing = (data: any[]) => {
  const listings: any[] = [];
  data.forEach((item) => {
    if (item.favorite_tab) {
      const { favorite_sections = [] } = item.favorite_tab;
      if (favorite_sections && favorite_sections.length > 0) {
        favorite_sections.forEach((favoriteItem: any) => {
          if (favoriteItem.favorites && Array.isArray(favoriteItem.favorites)) {
            favoriteItem.favorites.forEach((favorite: any) => {
              const newFavorite = parseFavorite(
                favorite,
                favoriteItem.favorite_tab_id || ''
              );
              if (newFavorite) {
                listings.push(newFavorite);
              }
            });
          }
        });
      }
    }
  });

  return listings;
};

const parseFavorite = (data: any, favoriteTabID: string) => {
  const listingData = data.listing;
  let listing = undefined;

  if (data && data.listing && data.listing.id) {
    listing = {
      id: listingData.id,
      name: listingData.name || '',
      favoriteID: data.id,
      favoriteSectionID: data.favorite_section_id || '',
      favoriteTabID,
      imageUrl: listingData.image_url,
      modifierIDs: [],
      description: listingData.description || '',
      inventoryTags: [],
      overwritePriceEnable: data.overwrite_price,
      price: data.overwrite_price ? data.price : listingData.price,
      status: listingData.status,
      soldOut: listingData.sold_out,
      position: data.position || 999999,
      storeID: data.store_id,
      productID: listingData.product_id || '',
      categoryID: listingData.category_id || '',
      departmentID: listingData.department_id || '',
      kitchenDepartmentID: listingData.kitchen_department_id || '',
      overwriteLineItemNameEnabled:
        listingData.overwrite_line_item_name_enabled || false,
      procurementMethod: [],
      purchasableID: data.source_id || '',
      purchasableType: data.source_type,
      modifiers: [],
      i18n: listingData.translation_info,
    };
  }

  return listing;
};

//以customer_code 等于 businessID 作为 查询条件获取customer，如果没见就创建一个 customer

export const getCustomerID = async (businessID: string, token: string) => {
  try {
    let customerID = '';
    const resp: IObject = await api.get(
      `${Config.urls.gatewayUrl}/api/crm/customers/bycode?customer_code=${businessID}&linked_source_type=Store&p_business_id=${bindoBusinessID}`,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
        },
        httpStatusCallback,
      }
    );
    if (
      resp &&
      resp.code === 200 &&
      Array.isArray(resp.data) &&
      resp.data.length > 0
    ) {
      customerID = resp.data[resp.data.length - 1].id;
    }
    return customerID;
  } catch (error: any) {
    console.error('error', error);
    return error.code;
  }
};

export const getOrderDetails = async (
  number: string,
  businessID: string,
  token: string,
  localNumber?: string
) => {
  let record = null;
  try {
    if (number) {
      const resp: IObject = await api.get(
        `${Config.urls.gatewayUrl}/api/oms/b2c/businesses/${bindoBusinessID}/orders/${number}`,
        {
          headers: {
            'x-user-access-token': token,
            'x-p-business-id': businessID,
          },
          httpStatusCallback,
        }
      );
      if (resp && resp.code === 200 && resp.data && resp.data.id) {
        record = resp.data;
      }
    } else {
      const customerID = await getCustomerID(businessID, token);
      const allPromise: any[] = [];
      let listings: any[] = [];
      allPromise.push(queryListing());
      allPromise.push(
        OrderCartAPI({
          businessID,
          customerID,
          token,
        })
      );
      allPromise.push(getAddressList(businessID, token, customerID));
      if (localNumber) {
        allPromise.push(
          api.get(
            `${Config.urls.gatewayUrl}/api/oms/b2c/businesses/${bindoBusinessID}/orders/${localNumber}`,
            {
              headers: {
                'x-user-access-token': token,
                'x-p-business-id': businessID,
              },
              httpStatusCallback,
            }
          )
        );
      }

      const allReasult = await Promise.all(allPromise);
      listings = allReasult[0];
      const cart = allReasult[1];
      const addressList: IAddressObject[] = allReasult[2];
      const lineItems: any[] = [];

      const {
        listings: cartListings = [],
        discount_code,
        max_delivery_time,
        min_delivery_time,
        note,
        origin_total,
        total,
      } = cart;
      if (Array.isArray(cartListings) && cartListings.length > 0) {
        listings.forEach((listing) => {
          cartListings.forEach((item: any) => {
            if (
              listing.id === item.listing_id &&
              listing.favoriteID === item.favorite_id &&
              item.quantity
            ) {
              const newItem = {
                price: listing.price,
                quantity: item.quantity,
                purchasable_id: item.listing_id,
                id: item.id,
                purchasable_type: 'Listing',
                total: listing.price * item.quantity,
              };
              lineItems.push(newItem);
            }
          });
        });
      }

      if (lineItems.length > 0 && min_delivery_time && max_delivery_time) {
        const addressInfo = addressList.filter((item) => item.is_default)[0];
        const {
          name,
          phone,
          country,
          province,
          city,
          state,
          district,
          street,
          address,
          lat,
          lng,
        } = addressInfo || {};
        const valuation = await valuationOrder(businessID, token, {
          discount_code,
          listings: cart.listings,
          delivery_district: addressInfo.district,
        });
        let subtotal = total || '0';
        let initial_total = origin_total || '0';
        if (valuation) {
          subtotal = valuation.subtotal;
          initial_total = valuation.initial_total;
        }
        record = {
          due_date: getDeliveryDate(7),
          note,
          from: 5,
          discount_code,
          sale_type: 'invoice_sale',
          currency: 'HKD',
          customer_reference: customerID,
          delivery_district: addressInfo.district,
          ignore_token: true,
          initial_total: initial_total,
          subtotal: subtotal,
          oms_delivery_note: {
            name,
            phone,
            country,
            province,
            city,
            state,
            district,
            street,
            address1: address,
            note,
            lat,
            lng,
            min_delivery_time,
            max_delivery_time,
            auto_booking: true,
          },
          line_items: lineItems,
          listings: cart.listings,
          ...valuation,
          unpaid_total: initial_total,
        };
      } else {
        const result = allReasult[3];
        if (result && result.code === 200 && result.data && result.data.id) {
          record = result.data;
        }
      }
    }
  } catch (error: any) {
    console.error(error);
    return error.code;
  }

  return record;
};

export const checkPromotionCode = async (
  code: string,
  businessID: string,
  token: string
) => {
  let success = false;
  try {
    const resp: IObject = await api.get(
      `${Config.urls.gatewayUrl}/api/oms/b2c/businesses/${bindoBusinessID}/check_discount_code/${code}`,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
        },
        httpStatusCallback,
      }
    );
    if (resp && resp.code === 200) {
      success = true;
    }
  } catch (error) {
    console.error(error);
  }

  return success;
};

export const downloadInvoicePDF = async (
  order: IObject,
  business: IObject,
  lang: string
) => {
  let data: IObject = {};
  try {
    const { currency: orderCurrency } = order;
    const templateData = {
      order,
      business,
      liquid_config: {
        locale: lang,
        money_currency: orderCurrency,
        money_currency_symbol: currencySymbols[orderCurrency] || 'HK$',
        money_decimal_points: 2,
      },
    };

    const resp = await axios({
      method: 'post',
      url: `${Config.urls.gatewayUrl}/api/helper/generate-pdf`,
      data: {
        fileData: { ...templateData },
        fileName: `${order.number}.pdf`,
        templateType: 'wonder_order_details',
        folderName: business.id,
      },
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    if (resp.data.code === 200 && resp.data.data.url) {
      data = resp.data.data;
    }
  } catch (error) {
    console.error(error);
  }

  return data;
};

export const valuationOrder = async (
  businessID: string,
  token: string,
  params: IObject
) => {
  let data: undefined | IObject = undefined;
  try {
    const resp: IObject = await api.post(
      // `${Config.urls.mainB2B}/businesses/${bindoBusinessID}/valuation`,
      `${Config.urls.mainBindo}/services/oms/b2b/businesses/${bindoBusinessID}/valuation`,
      { ...params },
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
        },
        httpStatusCallback,
      }
    );
    if (resp && resp.code === 200 && resp.data) {
      data = resp.data;
    }
  } catch (error) {
    console.error(error);
  }

  return data;
};

export const discountCoupon = async (
  token: string,
  businessID: string,
  lang: string,
  invoice: IObject
) => {
  let number: any = '';
  let errorCode = '';
  let errorMessage = '';
  try {
    const result: any = await api.post(
      `${Config.urls.gatewayUrl}/api/oms/b2b/businesses/${bindoBusinessID}/orders`,
      {
        ...invoice,
        payment: {
          other: [
            {
              amount: 0,
              name: 'Discount Coupon'
            },
          ],
        }
      },
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'Content-Type': 'application/json; charset=utf-8',
          'x-i18n-lang': lang,
        },
      }
    );
    const { data, code, message } = result;
    if (data && data.number && data.correspondence_state === 'paid') {
      number = data.number;
    } else {
      errorCode = code;
      errorMessage = message;
    }
  } catch (error: any) {
    if (error && error.response && error.response.data) {
      errorCode = error.response.data.code;
      errorMessage = error.response.data.message;
    }
  }
  return {
    number,
    errorCode,
    errorMessage
  };
}

interface IhandleVoidOrderParams {
  number: string;
  token: string;
  businessID: string;
  userID: string;
}

export const handleVoidOrder = async (params: IhandleVoidOrderParams) => {
  const { number, token, businessID, userID } = params;
  let data = undefined;
  try {
    const res: IObject = await api.post(
      `${Config.urls.gatewayUrl}/api/oms/b2b/businesses/${bindoBusinessID}/orders/${number}/void`,
      {
        voided_by_reference: userID,
      },
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'Content-Type': 'application/json; charset=utf-8',
        },
      }
    );

    if (res.code === 200 && res.data && res.data.id) {
      data = res.data;
    }
  } catch (error) {
    console.error(error);
  }

  return data;
};

export const getAddressList = async (
  businessID: string,
  token: string,
  customerID: string
) => {
  let data: IAddressObject[] = [];
  try {
    const resp: IObject = await api.get(
      `${Config.urls.gatewayUrl}/api/crm/customers/address?customer=${customerID}`,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
        },
        httpStatusCallback,
      }
    );
    if (resp && resp.code === 200 && Array.isArray(resp.data)) {
      data = resp.data;
    }
  } catch (error) {
    console.error(error);
  }

  return data;
};

export const updateAddressRecord = async (
  businessID: string,
  token: string,
  record: IObject
) => {
  let data: IObject = {};
  try {
    const resp = await api.put(
      `${Config.urls.gatewayUrl}/api/crm/customers/address/${record.id}`,
      {
        ...record,
      },
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
        },
      }
    );
    // if (resp && resp.code === 200 && Array.isArray(resp.data)) {
    data = resp.data;
    // }
  } catch (error) {
    console.error(error);
  }

  return data;
};

export const deleteAddressRecord = async (
  businessID: string,
  token: string,
  record_id: string
) => {
  let success = false;
  try {
    const resp: IObject = await api.remove(
      `${Config.urls.gatewayUrl}/api/crm/customers/address/${record_id}`,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
        },
        httpStatusCallback,
      }
    );
    if (resp && resp.code === 200 && resp.data && resp.data.id) {
      success = true;
    }
  } catch (error) {
    console.error(error);
  }

  return success;
};

export const createAddressRecord = async (
  businessID: string,
  token: string,
  record: IObject
) => {
  let data: any = {};
  try {
    const resp = await api.post(
      `${Config.urls.gatewayUrl}/api/crm/customers/address`,
      {
        ...record,
      },
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
        },
        httpStatusCallback,
      }
    );
    // if (resp && resp.code === 200 && resp.data && resp.data.id) {
    data = resp.data;
    // }
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const getDistrictOption = async (
  businessID: string,
  token: string,
  lang: string
) => {
  let data = [];
  try {
    const resp: IObject = await api.get(
      `${Config.urls.gatewayUrl}/api/oms/b2b/information/get_delivery_fees`,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'x-i18n-lang': lang,
        },
        httpStatusCallback,
      }
    );
    if (resp && resp.code === 200 && resp.data) {
      data = resp.data;
    }
  } catch (error) {
    console.error(error);
  }

  return data;
};

export const getAddressRecord = async (
  businessID: string,
  token: string,
  recordId: string
) => {
  let data = {};
  try {
    const resp: IObject = await api.get(
      `${Config.urls.gatewayUrl}/api/crm/customers/address/${recordId}`,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
        },
        httpStatusCallback,
      }
    );
    if (resp && resp.code === 200 && resp.data && resp.data.id) {
      data = resp.data;
    }
  } catch (error) {
    console.error(error);
  }

  return data;
};

interface IPayInvoiceParams {
  businessID: string;
  orderNumber: string;
  order: any;
  lang: string;
  token: string;
  email?: string;
}
export const payInvoice = async (params: IPayInvoiceParams) => {
  const { businessID, orderNumber, order, lang, email, token } = params;
  const url = `${Config.urls.gatewayUrl
    }/api/oms/b2c/businesses/${bindoBusinessID}/orders/${orderNumber}/pay${email ? `?auto_send_email_receipt=true&email_receipt_to=${email}` : ''
    }`;
  try {
    const parser = new UAParser();
    const { model } = parser.getDevice();
    const resp: IObject = await api.post(url,
      {
        ...order,
      }, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'x-platform-id': `${Config.platformId}`,
        'X-USER-ACCESS-TOKEN': token,
        'x-i18n-lang': lang,
        'B-APP-ID': 'mb5',
        'B-APP-SECRET': 'fa8a7c8554c1d376af6b0840f5473742',
        'X-USER-DEVICE-MODEL': model === 'K' ? '--' : model,
        'X-USER-DEVICE-ID': '--',
        'x-p-business-id': businessID,
      },
    });
    return resp;
  } catch (error: any) {
    let data: any = error;
    if (error && error.response && error.response.data) {
      data = error.response.data;
    }
    return data;
  }
};

export const checkOrderStatu = async (
  businessID: string,
  orderNumber: string,
  token: string
) => {
  const url = `${Config.urls.gatewayUrl}/api/oms/b2c/businesses/${bindoBusinessID}/orders/${orderNumber}/check_pay`;
  const date = new Date();
  try {
    const resp: any = await axios.get(`${url}?time=${date.getTime()}`, {
      headers: {
        'x-p-business-id': businessID,
        'X-USER-ACCESS-TOKEN': token,
        'Content-Type': 'application/json',
      },
    });
    const { data } = resp;
    return data;
  } catch (error: any) {
    return error;
  }
};

export const getApplePaySession = async (data: any) => {
  const tempurl = `${Config.urls.mainBindo}/services/oms/b2b/businesses/${bindoBusinessID}/apple_pay_session`;

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', tempurl);
    xhr.onload = function load() {
      if (this.status >= 200 && this.status < 300) {
        resolve(JSON.parse(xhr.response));
      } else {
        reject({
          status: this.status,
          message: xhr.statusText,
        });
      }
    };
    xhr.onerror = function error() {
      reject({
        status: this.status,
        message: xhr.statusText,
      });
    };
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('B-APP-ID', 'mb5');
    xhr.setRequestHeader('B-APP-SECRET', 'fa8a7c8554c1d376af6b0840f5473742');
    xhr.send(JSON.stringify(data));
  });
};

export const getWeChatAppID = async (businessID: string) => {
  const result = {
    appID: '',
    errorCode: '',
    errorMessage: '',
  }

  try {
    const resp: IObject = await api.get(
      `${Config.urls.mainBindo}/api/b2c/app/payment_link/businesses/${businessID}/wechaappid`
    );
    const { official_account } = resp || {};
    const { app_id, error_code, error_desc } = official_account || {};
    result.appID = app_id;
    result.errorCode = error_code;
    result.errorMessage = error_desc;
  } catch (error) {

  }

  return result;
};

export const queryStore = async (settings: IPaymentSettings) => {
  let safetyVerification = false;
  let safetyVerificationAmount = 0;
  let usePaymentMethods: PayTypes[] = [];

  try {
    const result: IObject = await api.get(
      `${Config.urls.mainBindo}/api/b2c/app/payment_link/store/${bindoBusinessID}?search_templates=wonder`,
    );
    const { business: resultBusiness, payment_method } = result;

    const { business_merchant_services_setting } = resultBusiness;
    const { threeds_enabled, threeds_threshold_amount } =
      business_merchant_services_setting || {};

    safetyVerification = threeds_enabled ? true : false;
    safetyVerificationAmount = threeds_threshold_amount
      ? threeds_threshold_amount
      : 0;
    usePaymentMethods = getBusinessPaymentMethods(payment_method, settings);
  } catch (error) { }

  return {
    usePaymentMethods,
    safetyVerification,
    safetyVerificationAmount,
  };
};

export const getRetrieveBalances = async (
  businessID: string,
  token: string
) => {
  const result = { available: 0, currency_code: 'HKD' }
  try {
    const resp = await api.get(
      `${Config.urls.gatewayUrl}/api/retrieve-balances?p_business_id=${businessID}`,
      {
        headers: {
          'x-p-business-id': businessID,
          'X-USER-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
        },
        httpStatusCallback,
      }
    );

    const { data } = resp || {};
    const { current_total_with_business_default_currency, default_currency } =
      data || {};
    const { available = 0 } = current_total_with_business_default_currency || {};
    const { currency_code = 'HKD' } = default_currency || {};
    result.available = available;
    result.currency_code = currency_code;
  } catch (error) {

  }

  return result;
};

interface IAddCreditCardParams {
  exp_month: string;
  exp_year: string;
  holder_name: string;
  number: string;
  businessID: string;
  token: string;
}
export const addCreditCard = async (params: IAddCreditCardParams) => {
  const { exp_month, exp_year, number, holder_name, businessID, token } =
    params;
  const resp: IObject = await api.post(
    `${Config.urls.gatewayUrl}/api/oms/b2c/credit_cards/bind`,
    {
      exp_month,
      exp_year,
      number,
      holder_name,
      default: false,
    },
    {
      headers: {
        'x-p-business-id': businessID,
        'X-USER-ACCESS-TOKEN': token,
        'Content-Type': 'application/json',
      },
      httpStatusCallback,
    }
  );

  const { code, data } = resp || {};
  if (code === 200 && data) {
    return data;
  } else {
    return resp;
  }
};

export const queryCardList = async (businessID: string, token: string) => {
  let list: any[] = [];
  try {
    const result = await api.get(
      `${Config.urls.gatewayUrl}/api/oms/b2c/credit_cards/list`,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
        },
      },
    );
    const { data } = result || {};
    if (data && Array.isArray(data)) {
      list = data;
    }
  } catch (error) {

  }
  return list.reverse();
};

export const deleteCreditCard = async (
  businessID: string,
  id: string,
  token: string
) => {
  let result = {};
  try {
    result = await api.remove(
      `${Config.urls.gatewayUrl}/api/oms/b2c/credit_cards/unbind/${id}`,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
        },
        httpStatusCallback,
      }
    );

  } catch (error) {

  }

  return result;
};

export const querySellerBusiness = async () => {
  const result: IObject = await api.get(
    `${Config.urls.mainBindo}/api/b2c/app/payment_link/store/${bindoBusinessID}?search_templates=wonder`,
  );
  const { business } = result || {};
  return business || {};
};

interface IGetAddresOptionsByGoogleParams {
  businessID: string;
  token: string;
  query: string;
  action: 'autocomplete' | 'details';
}

export const queryGoogleService = async (
  params: IGetAddresOptionsByGoogleParams
) => {
  const { token, businessID, query, action } = params;
  let data: IObject = {};
  try {
    const resp: IObject = await api.get(
      `${Config.urls.gatewayUrl}/api/google-map/place/${action}/json?${query}&components=country:HK`,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
        },
        httpStatusCallback,
      }
    );

    if (resp && resp.code === 200 && resp.data) {
      data = resp.data;
    }
  } catch (error) {
    console.error(error);
  }

  return data;
};
