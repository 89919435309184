import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ChangeIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="20" fill="#0094FF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.257 15.3923C27.8601 15.7766 27.2166 15.7766 26.8196 15.3923L24.7214 13.3607L24.7214 28.3667C24.7214 28.9102 24.2664 29.3507 23.705 29.3507C23.1437 29.3507 22.6887 28.9102 22.6887 28.3667L22.6887 10.985C22.6887 10.587 22.9363 10.2282 23.3161 10.0759C23.6959 9.9236 24.133 10.0078 24.4237 10.2892L28.257 14.0006C28.6539 14.3849 28.6539 15.008 28.257 15.3923Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2977 23.9583C12.6946 23.574 13.3381 23.574 13.735 23.9583L15.8333 25.9898L15.8333 10.9839C15.8333 10.4404 16.2883 9.99984 16.8497 9.99984C17.411 9.99984 17.866 10.4404 17.866 10.9839L17.866 28.3655C17.866 28.7635 17.6184 29.1224 17.2386 29.2747C16.8588 29.427 16.4217 29.3428 16.131 29.0614L12.2977 25.35C11.9008 24.9657 11.9008 24.3426 12.2977 23.9583Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default ChangeIcon;
