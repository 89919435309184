import React from 'react';
import type { IIconProps } from '@/types/common-types';

const CopyIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 16 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0878 6.04008C14.0811 5.98125 14.0682 5.92329 14.0493 5.86717V5.80954C14.0185 5.74369 13.9775 5.68316 13.9277 5.63022L10.0852 1.78782C10.0323 1.73801 9.97178 1.69694 9.90594 1.66615C9.88682 1.66343 9.86742 1.66343 9.8483 1.66615C9.78325 1.62884 9.7114 1.60489 9.63697 1.5957H7.04335C6.53382 1.5957 6.04515 1.79811 5.68485 2.15841C5.32456 2.5187 5.12215 3.00737 5.12215 3.5169V4.1573H4.48175C3.97221 4.1573 3.48355 4.35972 3.12325 4.72001C2.76296 5.08031 2.56055 5.56897 2.56055 6.0785V12.4825C2.56055 12.992 2.76296 13.4807 3.12325 13.841C3.48355 14.2013 3.97221 14.4037 4.48175 14.4037H9.60495C10.1145 14.4037 10.6031 14.2013 10.9634 13.841C11.3237 13.4807 11.5262 12.992 11.5262 12.4825V11.8421H12.1666C12.6761 11.8421 13.1647 11.6397 13.525 11.2794C13.8853 10.9191 14.0878 10.4304 14.0878 9.92091V6.0785V6.04008ZM10.2454 3.77947L11.904 5.4381H10.8858C10.7159 5.4381 10.553 5.37063 10.4329 5.25054C10.3128 5.13044 10.2454 4.96755 10.2454 4.7977V3.77947V3.77947ZM10.2454 12.4825C10.2454 12.6524 10.1779 12.8152 10.0578 12.9353C9.93768 13.0554 9.77479 13.1229 9.60495 13.1229H4.48175C4.3119 13.1229 4.14901 13.0554 4.02892 12.9353C3.90882 12.8152 3.84135 12.6524 3.84135 12.4825V6.0785C3.84135 5.90866 3.90882 5.74577 4.02892 5.62567C4.14901 5.50557 4.3119 5.4381 4.48175 5.4381H5.12215V9.92091C5.12215 10.4304 5.32456 10.9191 5.68485 11.2794C6.04515 11.6397 6.53382 11.8421 7.04335 11.8421H10.2454V12.4825V12.4825ZM12.807 9.92091C12.807 10.0908 12.7395 10.2536 12.6194 10.3737C12.4993 10.4938 12.3364 10.5613 12.1666 10.5613H7.04335C6.8735 10.5613 6.71062 10.4938 6.59052 10.3737C6.47042 10.2536 6.40295 10.0908 6.40295 9.92091V3.5169C6.40295 3.34706 6.47042 3.18417 6.59052 3.06407C6.71062 2.94397 6.8735 2.8765 7.04335 2.8765H8.96455V4.7977C8.96455 5.30724 9.16696 5.7959 9.52726 6.1562C9.88755 6.51649 10.3762 6.7189 10.8858 6.7189H12.807V9.92091V9.92091Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default CopyIcon;
