import type { IObject } from '@/types/common-types';
import type { ICartParams } from '../../../types/terminal-type';
import MinusIcon from '../terminal-icons/minus-icon';
import PlusIcon from '../terminal-icons/plus-icon';
import { handlePrice } from '../../../utils/terminal-untils';
import DefaultItemIcon from '../terminal-icons/default-item-icon';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '../terminal-icons/error-icon';
import AntdTips from '@/components/common/antd/tips';

interface ICartItemParam {
  cart: ICartParams;
  currency: string;
  handleMinus: (record: IObject) => void;
  handlePlus: (record: IObject) => void;
}

const CartItem: React.FC<ICartItemParam> = (props) => {
  const { cart, currency, handleMinus, handlePlus } = props;
  const { t } = useTranslation(["extended_service","transaction_advice"]);
  return (
    <>
      {cart.listings.map((item: any) => {
        const { cartQuantity = 0 } = item;
        return (
          <div key={item.favoriteID} className="relative">
            <div className="flex justify-between pt-4 pb-8 ">
              <div className="flex">
                <div className="p-2 bg-disabled-color rounded-2xl">
                  {item.imageUrl && (
                    <div
                      className="w-16 h-16 flex justify-center items-center bg-center bg-no-repeat bg-contain"
                      style={{ backgroundImage: `url(${item.imageUrl})` }}
                    />
                  )}
                  {!item.imageUrl && (
                    <div
                      style={{
                        backgroundColor: 'rgba(226, 228, 231, 0.15)',
                      }}
                      className="w-16 h-16 flex justify-center items-center rounded"
                    >
                      <DefaultItemIcon />
                    </div>
                  )}
                </div>
                <div className="ml-2 font-medium flex flex-col justify-between">
                  <div className="text-color-text-1 w-[120%] flex items-center text-sm max-w-16 line-clamp-2 overflow-ellipsis lg:max-w-28">
                    <span>{item.name}</span>
                    {`${item.name}`.includes('POS') && (
                      <AntdTips
                        overlayInnerStyle={{
                          width: '320px',
                        }}
                        className="ml-1"
                        content={t('extended_service:cart_item_tips')}
                      />
                    )}
                  </div>
                  <div className="text-color-text-1 text-base">
                    {handlePrice(item.price)} {currency}
                  </div>
                </div>
              </div>
              <div className="flex items-end">
                {cartQuantity > 0 && (
                  <>
                    <MinusIcon
                      className="cursor-pointer hover:!opacity-50"
                      onClick={() => handleMinus(item)}
                    />
                    <span className="w-8 mx-1 text-center leading-7">{cartQuantity}</span>
                  </>
                )}
                <PlusIcon
                  className="cursor-pointer hover:!opacity-50"
                  opacity={item.cartQuantity >= 5 ? 0.3 : 1}
                  onClick={() => handlePlus(item)}
                />
              </div>
            </div>
            {item.cartQuantity >= 5 && (
              <div className="flex items-center justify-end text-error-color absolute bottom-0 right-0">
                <ErrorIcon />
                <span className="text-sm ml-2">
                  {t('extended_service:maximum')}
                </span>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default CartItem;
