import type { IAccountBalanceCurrentActivities } from '@/types/account-balance-type';
import type { IObject } from '@/types/common-types';
import { useEffect, useState } from 'react';
import Table from '@/components/table';
import { getCurrentAccountColumns } from '../../account-balance-data';
import { Transition } from '@headlessui/react';
import {
  HadleGoTranscationDetails,
  parseGetActivitiesParams,
} from '../../../../utils/account-balance-untils';
import { getActivities } from '@/data/account-balance';
import Button from '@/components/common/button';
import SendExportModal from '@/pages/account-balance/components/export-modal';
import SendEmailModal from '@/pages/account-balance/components/send-email-modal';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import type { TableRecord } from '@/components/table/interface';
import type { IFilterOptions } from '@/components/common/filter';
import Filter from '@/components/common/filter';
import { useParams, useNavigate } from 'react-router-dom';


const AccountBalanceCurrentActivities = (
  props: IAccountBalanceCurrentActivities
) => {
  const [colums, setColums] = useState<ColumnsType<TableRecord>>([]);
  const { businessID, refreshPage, filterFields } = props;
  const navigate = useNavigate();
  const { t } = useTranslation("account_balance");
  const { lang = 'en' } = useParams();
  const [activitiesEntity, setActivities] = useState<IObject[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [showSendEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [showSendExportModal, setShowExportModal] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<IFilterOptions[]>([]);

  useEffect(() => {
    //构建colunms
    handleColums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    //init data
    getRecords(pageSize, 0, 1, searchValue, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  // 构建columns
  const handleColums = () => {
    const columns = getCurrentAccountColumns(t, false, lang);
    setColums(columns);
    return columns;
  };

  const getRecords = async (
    limit = pageSize,
    offset = 0,
    page = 1,
    search = searchValue,
    filterOptions = options
  ) => {
    setTableLoading(true);
    if (businessID) {
      const params = parseGetActivitiesParams(search, filterOptions);
      const activities = await getActivities(
        {
          pBusinessId: businessID,
          limit: limit + 1,
          offset,
        },
        params
      );
      setPage(page);
      setPageSize(limit);
      setTableLoading(false);
      setSearchValue(search);
      setOptions(filterOptions);
      if (Array.isArray(activities)) {
        setActivities(activities);
      }
    }
  };

  return (
    <>
      <Transition.Child
        className="rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 my-6 h-fit"
        enter="duration-900 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-10 opacity-0"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="items-center flex justify-between">
          <div>
            <div className="flex flex-col md:flex-row whitespace-nowrap">
              <span className="font-bold text-xl text-color-text-1 flex items-center md:text-xl">
                {t('account_balance:current_activities')}
              </span>
            </div>
          </div>
          <div className="flex space-x-4">
            <Filter
              fields={filterFields}
              onFilter={(search, options) =>
                getRecords(pageSize, 0, 1, search, options)
              }
              searchValue={searchValue}
              options={options}
              placeholder={t('account_balance:search_id_name')}
            />
            <Button
              styleType="Secondary"
              onClick={() => setShowExportModal(true)}
            >
              {t('common:export')}
            </Button>
            {/* <Button
              styleType="Secondary"
              onClick={() => setShowEmailModal(true)}
            >
              {t('account_balance:send_via_email')}
            </Button> */}
          </div>
        </div>
        <div className="">
          <div className="w-full mt-6 overflow-auto">
            <Table
              columns={colums}
              rowKey={(record) => {
                const {
                  payin_request_id,
                  payout_request_id,
                  fx_conversion_id,
                  card_transaction_id,
                } = record || {};
                return (
                  payin_request_id ||
                  payout_request_id ||
                  fx_conversion_id ||
                  card_transaction_id
                );
              }}
              records={activitiesEntity}
              loadingData={tableLoading}
              className="!max-h-108"
              search={searchValue.length > 0 || options.length > 0}
              pagination={{
                className: 'mt-6',
                current: page,
                currentSize: activitiesEntity.length,
                pageSize,
                pageSizeOptions: [30, 60, 120],
                changePageCallback: (page, pageSize) => {
                  getRecords(pageSize, (page - 1) * pageSize, page);
                },
              }}
              onRowCell={(record, dataKey) => {
                return {
                  onClick: (evt) => {
                    evt.stopPropagation();
                    HadleGoTranscationDetails(
                      businessID,
                      record,
                      navigate,
                      'currentAccount'
                    );
                  },
                };
              }}
            />
          </div>
        </div>
      </Transition.Child>
      <SendExportModal
        businessId={businessID}
        visible={showSendExportModal}
        templateType={'business_account_snapshot'}
        onCancel={() => setShowExportModal(false)}
      />
      <SendEmailModal
        businessId={businessID}
        title={t('account_balance:send_via_email')}
        subtitle={t('account_balance:tell_us_email')}
        visible={showSendEmailModal}
        templateType={'business_account_snapshot'}
        onCancel={() => setShowEmailModal(false)}
      />
    </>
  );
};

export default AccountBalanceCurrentActivities;
