import AntdModal from '@/components/common/antd/modal';
import WarnContent from './warn-content';
import InfoContent from './info-content';
import { BusinessStatus, IBusiness } from '@/types/business-types';
import { resolvingBusinessStatus } from '@/utils/business-utils';

interface IBusinessModalProps {
  open: boolean;
  onCancel: (reload?: boolean) => void;
  business?: IBusiness;
}

const BusinessModal = (props: IBusinessModalProps) => {
  const { open, business, onCancel } = props;

  let status: any = '';

  if (business) {
    status = resolvingBusinessStatus(business);
  }

  const isWarn = [
    BusinessStatus.INCOMPLETE_APPLICATION,
    BusinessStatus.REJECTED,
    BusinessStatus.DORMANT,
    BusinessStatus.ABANDONED,
    BusinessStatus.SUSPENDED,
  ].includes(status);

  let className = 'max-w-[30%] max-h-[88%]';

  if (isWarn) {
    className = 'max-w-[30%] max-h-[88%]';
  }

  return (
    <AntdModal
      open={open}
      closable={!isWarn}
      className={className}
      onCancel={() => onCancel()}
    >
      {isWarn && (
        <WarnContent
          status={status}
          businessID={business?.business_application_uuid || ''}
          onCancel={onCancel}
        />
      )}
      {!isWarn && <InfoContent status={status} business={business} />}
    </AntdModal>
  );
};

export default BusinessModal;
