import type { IDefaultDetailsContent } from '../type';
import React, { useEffect, useState } from 'react';
import Label from '@/components/common/label';
import DetailsItem from '@/pages/account-balance/components/details-item-component';
import { Transition } from '@headlessui/react';
import {
  handleMount,
  handleUtcDate,
  handleSymbolMount,
} from '@/utils/account-balance-untils';
import Skeleton from '@/components/skeleton';
import CopyIcon from '@/components/svg/copy-icon';
import UpIcon from '@/components/svg/up-icon';
import FileInput from '@/components/common/file-input/file-input';
import type { IFileObject } from '@/types/common-types';
import Config from '@/libs/config';
import { useTranslation } from 'react-i18next';

let tipsTimeout: any;
let orgTxnIdTipsTimeout: any;

const UnusualDetailsContent = (props: IDefaultDetailsContent) => {
  const {
    showType = null,
    settledAmount = '',
    record,
    initLoading,
    viewDetails,
  } = props;

  const { t } = useTranslation("account_balance");

  const [copySuccess, setCopySuccess] = useState(false);
  const [copyOrgTxnIdSuccess, setCopyOrgTxnIdSuccess] = useState(false);

  const {
    id = '',
    amount,
    currency = 'HKD',
    fee_amount: feeAmount = '',
    order_num: orderNum = '',
    oms_transaction_id: omsTransactionId = '',
    order = {},
    acquirer_rrn: acquirerRrn = '',
    created_at: createdAt = '',
    org_txn_id: orgTxnId = '',
    remark,
    payment_type,
    fee_currency: feeCurrency = 'HKD',
  } = record || {};

  const {
    reference_number: referenceNumber = '',
    additional_documents: documents,
  } = order || {};

  const handleCopy = (copyValue: string, isOrgTxnId = false) => {
    navigator.clipboard.writeText(copyValue).then(() => {
      if (isOrgTxnId) {
        setCopyOrgTxnIdSuccess(true);
      } else {
        setCopySuccess(true);
      }
    });
  };

  useEffect(() => {
    if (copySuccess) {
      clearTimeout(tipsTimeout);
      tipsTimeout = setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }
  }, [copySuccess]);

  useEffect(() => {
    if (copyOrgTxnIdSuccess) {
      clearTimeout(orgTxnIdTipsTimeout);
      orgTxnIdTipsTimeout = setTimeout(() => {
        setCopyOrgTxnIdSuccess(false);
      }, 3000);
    }
  }, [copyOrgTxnIdSuccess]);

  const getFiles = () => {
    const files: IFileObject[] = [];
    const prefix = Config.staticPath;

    if (Array.isArray(documents)) {
      documents.forEach((item) => {
        if (item && typeof item === 'string') {
          const splits = item.split('/');
          const suffix = splits[splits.length - 1].split('.');
          const id = suffix[0];
          const fileType = suffix[suffix.length - 1];
          let fileName = splits[splits.length - 1];
          if (suffix.length > 2) {
            fileName = `${suffix[1]}.${fileType}`;
          }
          files.push({
            id,
            fileUrl: `${prefix}/api/treasury-services/files/${id}.${fileType}`,
            filenameDownload: fileName,
          });
        }
      });
    }

    return files;
  };

  return (
    <div className="flex w-full space-x-6 mt-6">
      <Transition.Child
        className="flex-1"
        enter="duration-350 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-8 opacity-60"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        {(payment_type === 'risk_hold' ||
          payment_type === 'hold' ||
          payment_type === 'dispute') && (
            <div className="w-full rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 gap-y-6">
                <div>
                  <div className="font-bold text-xl leading-6 mb-2.5">
                    {t('account_balance:payment_tip1')}
                  </div>
                  <div>{t('account_balance:payment_tip2')}</div>
                  <div>
                    <ol className="list-decimal list-inside">
                      <li className="font-bold marker:font-normal">
                        {t('account_balance:sales_slips')}
                      </li>
                      <li className="font-bold marker:font-normal">
                        {t('account_balance:payment_tip3')}
                      </li>
                    </ol>
                  </div>
                </div>
                <div>
                  <FileInput
                    name="additional_documents"
                    rowQuantity={4}
                    proportion={1}
                    disabled={true}
                    defaultValue={getFiles()}
                    dataBase="treasury-services"
                  />
                </div>
              </div>
            </div>
          )}
        <div className="w-full rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit">
          <div className="font-bold text-xl text-color-text-1 flex items-center md:text-xl">
            <div>{t('account_balance:payment_details')}</div>
            <div className="w-44">
              {initLoading && (
                <div className={`overflow-hidden inline-block w-60`}>
                  <Skeleton
                    row={1}
                    width={['100%']}
                    skeletonItemClass={`h-4 rounded-2xl mb-0`}
                  />
                </div>
              )}
              {!initLoading && (
                <div className="text-color-text-2 font-medium pl-2.5 flex items-center">
                  {showType}
                </div>
              )}
            </div>
          </div>
          <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
            <DetailsItem
              title={t('account_balance:transaction_amount')}
              content={
                <div
                  className={`${
                    payment_type === 'pre_dispute' ? 'text-color-text-2' : ''
                  }`}
                >
                  {`${handleSymbolMount(
                    amount,
                    false,
                    payment_type === 'pre_dispute' ? '' : undefined
                  )} ${currency}`}
                </div>
              }
              loading={initLoading}
            />
            <DetailsItem
              title={t('account_balance:transaction_time')}
              content={handleUtcDate(createdAt)}
              loading={initLoading}
            />
            <DetailsItem
              title={t('account_balance:reference_number')}
              content={referenceNumber}
              loading={initLoading}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
            <DetailsItem
              title={t('account_balance:fees_charged')}
              content={`-${handleMount(feeAmount)} ${feeCurrency}`}
              loading={initLoading}
            />
            <div className="w-full">
              <Label
                disabled={true}
                value={t('account_balance:transaction_id')}
              ></Label>
              {initLoading && (
                <Skeleton
                  row={2}
                  width={['50%', '50%']}
                  skeletonItemClass={`h-4 rounded-2xl mb-4`}
                />
              )}
              {!initLoading && (
                <div className="py-2 text-color-text-1 font-medium pr-6 flex items-center space-x-2">
                  <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-color-text-1 text-sm font-medium">
                    {id}
                  </span>
                  <span
                    className="cursor-pointer group"
                    onClick={() => handleCopy(id)}
                  >
                    <CopyIcon className="text-primary-color hover:text-opacity-70" />
                  </span>
                  <span
                    className={`text-primary-color text-xs cursor-pointer ${copySuccess ? 'visible' : 'invisible'
                      }`}
                  >
                    {t('account_balance:copied')}
                  </span>
                </div>
              )}
            </div>
            <DetailsItem
              title={t('account_balance:invoice_number')}
              content={orderNum}
              loading={initLoading}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
            <DetailsItem
              title={t('account_balance:settled_amount')}
              content={settledAmount}
              loading={initLoading}
            />
            <div className="w-full">
              <Label
                disabled={true}
                value={t('account_balance:orginal_transaction_id')}
              ></Label>
              {initLoading && (
                <Skeleton
                  row={2}
                  width={['50%', '50%']}
                  skeletonItemClass={`h-4 rounded-2xl mb-4`}
                />
              )}
              {!initLoading && (
                <div className="py-2 text-color-text-1 font-medium pr-6 flex items-center space-x-2">
                  <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-color-text-1 text-sm font-medium">
                    {orgTxnId}
                  </span>
                  <span
                    className="cursor-pointer group"
                    onClick={() => handleCopy(orgTxnId, true)}
                  >
                    <CopyIcon className="text-primary-color hover:text-opacity-70" />
                  </span>
                  <span
                    className={`text-primary-color text-xs cursor-pointer ${copyOrgTxnIdSuccess ? 'visible' : 'invisible'
                      }`}
                  >
                    {t('account_balance:copied')}
                  </span>
                </div>
              )}
              <div
                className="flex items-center cursor-pointer"
                onClick={() => viewDetails(orgTxnId)}
              >
                <div className="flex items-center cursor-pointer group">
                  <div className="text-base text-primary-color group-hover:text-opacity-70">
                    {t('account_balance:view_details')}
                  </div>
                  <UpIcon className="shrink-0 ml-1 fill-current rotate-90 text-primary-color group-hover:text-opacity-70" />
                </div>
              </div>
            </div>
            <DetailsItem
              title="BRN"
              content={omsTransactionId}
              loading={initLoading}
            />
            <DetailsItem
              title="RRN"
              content={acquirerRrn}
              loading={initLoading}
            />
          </div>
          <DetailsItem
            title={t('account_balance:remark')}
            content={remark}
            loading={initLoading}
          />
        </div>
      </Transition.Child>
    </div>
  );
};

export default React.memo(UnusualDetailsContent);
