import React from 'react';
import type { IIconProps } from '@/types/common-types';

const MinusIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="29"
        viewBox="0 0 28 29"
        fill="none"
      >
        <g clipPath="url(#clip0_20144_102)">
          <circle
            cx="14"
            cy="14.8916"
            r="13.25"
            stroke="#0094FF"
            strokeWidth="1.5"
          />
          <path
            d="M9 14.8916H19"
            stroke="#0094FF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_20144_102">
            <rect
              width="28"
              height="28"
              fill="white"
              transform="translate(0 0.891602)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default MinusIcon;
