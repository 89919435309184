import React from 'react';
import type { IIconProps } from '@/types/common-types';

const FPSIDIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 24 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.7425 10.1777H20.4862L21.0826 9.12109H18.1686C17.521 9.12109 16.9757 9.27447 16.5156 9.54713C16.0554 9.81979 15.6635 10.2629 15.3056 10.8764C14.9818 11.4387 14.8796 11.8648 15.0159 12.1204C15.1522 12.376 15.5101 12.5123 16.1236 12.5123H17.4358C17.7255 12.5123 17.8959 12.5635 17.9641 12.6657C18.0152 12.768 17.9811 12.9384 17.8448 13.1769C17.7085 13.3985 17.5551 13.5689 17.3676 13.6711C17.1802 13.7734 16.9416 13.8245 16.6178 13.8245H13.823L13.2266 14.847H16.2258C16.8564 14.847 17.4017 14.7107 17.8618 14.438C18.3219 14.1653 18.7139 13.7393 19.0547 13.1429C19.3785 12.5635 19.4978 12.1545 19.3785 11.8988C19.2592 11.6432 18.9013 11.5239 18.2879 11.5239H16.8223C16.5837 11.5239 16.4303 11.4728 16.3792 11.3706C16.3111 11.2683 16.3451 11.1149 16.4644 10.9104C16.6008 10.6548 16.7882 10.4674 16.9927 10.3481C17.1972 10.2288 17.4528 10.1777 17.7425 10.1777ZM5.06349 10.6389C4.77378 10.9797 4.43295 11.508 4.00692 12.2407L2.49023 14.8822H3.61497L4.96124 12.5304H8.14798L8.71034 11.525H5.5236C5.81331 11.0478 6.13709 10.69 6.47792 10.4684C6.81875 10.2469 7.24478 10.1447 7.72194 10.1447H9.52833L10.1077 9.15625H8.4036C7.9094 9.15625 7.44928 9.20737 7.05733 9.30962C6.66538 9.41187 6.29047 9.58228 5.96668 9.80382C5.64289 10.0254 5.33615 10.298 5.06349 10.6389Z"
          fill="black"
        />
        <path
          d="M10.9765 9.15234H13.3964C13.8054 9.15234 14.1121 9.16938 14.3336 9.22051C14.5552 9.27163 14.7086 9.3398 14.7938 9.44205C14.9301 9.61246 14.9983 9.81696 14.9642 10.0896C14.9301 10.3623 14.8108 10.669 14.6063 11.0099C14.4018 11.3507 14.1803 11.6404 13.9417 11.896C13.7031 12.1346 13.4305 12.3391 13.1237 12.5095C12.8681 12.6458 12.6295 12.731 12.3568 12.7992C12.1012 12.8503 11.7434 12.8844 11.2832 12.8844H9.93696L8.79519 14.8783H7.6875L10.9765 9.15234ZM12.7999 10.1748H11.5048L10.5334 11.8619H11.8967C12.2376 11.8619 12.5273 11.7938 12.7658 11.6574C13.0044 11.5211 13.2089 11.2996 13.3793 11.0269C13.5497 10.7202 13.6009 10.4986 13.5157 10.3793C13.4134 10.243 13.1919 10.1748 12.7999 10.1748Z"
          fill="#0094FF"
        />
        <path
          d="M1.67922 9.12032C1.67922 7.97537 1.68024 7.16496 1.76281 6.55082C1.84352 5.95049 1.99415 5.61009 2.24157 5.36267C2.489 5.11524 2.8294 4.96461 3.42973 4.8839C4.04386 4.80133 4.85428 4.80031 5.99922 4.80031H17.5192C18.6642 4.80031 19.4746 4.80133 20.0887 4.8839C20.689 4.96461 21.0294 5.11524 21.2769 5.36267C21.5243 5.61009 21.6749 5.95049 21.7556 6.55082C21.8382 7.16495 21.8392 7.97537 21.8392 9.12031V14.8803C21.8392 16.0253 21.8382 16.8357 21.7556 17.4498C21.6749 18.0501 21.5243 18.3905 21.2769 18.638C21.0294 18.8854 20.689 19.036 20.0887 19.1167C19.4746 19.1993 18.6642 19.2003 17.5192 19.2003H5.99922C4.85428 19.2003 4.04386 19.1993 3.42973 19.1167C2.8294 19.036 2.489 18.8854 2.24157 18.638C1.99415 18.3905 1.84352 18.0501 1.76281 17.4498C1.68024 16.8357 1.67922 16.0253 1.67922 14.8803V9.12032Z"
          stroke="black"
          strokeWidth="0.96"
        />
      </svg>
    </div>
  );
};

export default FPSIDIcon;
