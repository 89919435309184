import { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@/components/svg/close-icon';
import SearchIcon from '../../payouts-icons/search-icon';
import CleanIcon from '@/components/svg/clean-icon';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TableRecord } from '@/components/table/interface';
import Config from '@/libs/config';
import { getAvatarsName, getCookie } from '@/utils';
import { getCountryByCurrency, getPayoutsI18nValue, parsePayees, showTransferMethod } from '@/utils/payouts-utils';
import { Constants } from '@/constants';
import api from '@/libs/api';
import AntdModal from '@/components/common/antd/modal';
import Select from '@/components/common/select/select';
import Table from '@/components/table';
import Button from '@/components/common/button';

interface IPayeesProps {
  businessID: string;
  showModal: boolean;
  setShowModal: (visible: boolean) => void;
  onChange: (payee: any) => void;
}

const Payees: React.FC<IPayeesProps> = (props) => {
  const { businessID, setShowModal, onChange } = props;
  const { t } = useTranslation('payouts');
  const { lang = 'en' } = useParams();
  const [records, setRecords] = useState<TableRecord[]>([]);
  const [type, setType] = useState('All');
  const [transferMethod, setTransferMethod] = useState('All');
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [selectRecord, setSelectRecord] = useState<TableRecord | null>(null);

  const prefix = Config.staticPath || '';

  useEffect(() => {
    loadingPayees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessID]);

  const columns: ColumnsType<TableRecord> = useMemo(() => {
    return [
      {
        key: 'id',
        title: t('payee'),
        dataIndex: 'id',
        width: 200,
        render: (_key, record) => {
          return (
            <div className="flex items-center">
              <div>
                <span className="inline-flex items-center justify-center h-10 w-10 bg-primary-color/10 rounded-full">
                  <span className="font-semibold text-color-text-1 text-xs leading-normal">
                    {getAvatarsName(record.nickname)}
                  </span>
                </span>
              </div>
              <div className="font-medium text-sm text-color-text-1 ml-1.5">
                {record.nickname}
              </div>
            </div>
          );
        },
      },
      {
        key: 'nickname',
        title: t('transfer_method'),
        dataIndex: 'nickname',
        width: 300,
        render: (_key, record) => {
          return showTransferMethod(record);
        },
      },
      {
        key: 'company_name',
        title: t('destination_country'),
        dataIndex: 'company_name',
        width: 180,
        render: (_key, record) => {
          const { payout_accounts } = record;
          const { country_codes } = payout_accounts;
          const { alpha_2, country_name } = country_codes;
          return (
            <div className="flex">
              <div className="flex items-center w-4 mr-1">
                <img
                  width="16px"
                  height="16px"
                  src={`${prefix}/images/country-flag/${
                    alpha_2 ? alpha_2.toLocaleLowerCase() : 'hk'
                  }.svg`}
                  alt=""
                />
              </div>
              <div className="text-color-text-1 flex-1">{country_name}</div>
            </div>
          );
        },
      },
      {
        key: 'p_business_id',
        title: t('destination_currency'),
        dataIndex: 'p_business_id',
        width: 180,
        render: (_key, record) => {
          const { payout_accounts } = record;
          const { currency } = payout_accounts;
          return (
            <div className="flex">
              <div className="flex items-center relative w-4 mr-1">
                <img
                  width="16px"
                  height="16px"
                  src={`${prefix}/images/country-flag/${getCountryByCurrency(
                    currency
                  )}.svg`}
                  alt=""
                />
              </div>
              <div className="text-color-text-1 flex-1">{currency}</div>
            </div>
          );
        },
      },
      {
        key: 'type',
        title: t('type'),
        dataIndex: 'type',
        width: 120,
        render: (_key, record) => {
          const { type } = record;
          let color = '';
          let value = '';
          if (type === 'Business') {
            color = 'text-warning-color bg-warning-color';
            value = t('business');
          } else if (type === 'People') {
            color = 'text-primary-color bg-primary-color';
            value = t('people');
          }
          return (
            <div className="flex">
              <div
                className={`py-1 text-xs px-3 rounded-3xl ${color} bg-opacity-10 font-medium`}
              >
                {value.toLocaleUpperCase()}
              </div>
            </div>
          );
        },
      },
      {
        key: 'payout_category_id',
        title: t('categories'),
        dataIndex: 'payout_category_id',
        width: 200,
        render: (_key, record) => {
          return getPayoutsI18nValue(
            record.i18n,
            record.categories.category_name,
            lang
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingPayees = async (
    page = 1,
    pageSize = 30,
    type = 'All',
    transferMethod = 'All',
    keyword?: string
  ) => {
    setLoading(true);
    const token = getCookie(Constants.TOKEN) || '';
    let records: any[] = [];
    const offset = (page - 1) * pageSize;
    let url = `${
      Config.urls.gatewayUrl
    }/api/treasury-services/api/payees?limit=${
      pageSize + 1
    }&offset=${offset}&type=${type}`;
    if (transferMethod !== 'All') {
      url = `${url}&transfer_method=${transferMethod}`;
    }
    if (keyword) {
      url = `${url}&keyword=${keyword}`;
    }
    try {
      const result = await api.get(url, {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
        },
      });
      const { data } = result || {};
      if (data && Array.isArray(data)) {
        records = parsePayees(data);
      }
    } catch (error) {
      console.error(error);
    }
    setPage(page);
    setPageSize(pageSize);
    setRecords(records);
    setLoading(false);
    setSelectRecord(null);
  };

  const handleConfirm = () => {
    if (selectRecord && selectRecord.id) {
      onChange(selectRecord);
      setShowModal(false);
    }
  };

  const handleChange = (value: string) => {
    setSearchValue(value);
    loadingPayees(1, pageSize, type, transferMethod, value);
  };

  const handleSelect = (record: TableRecord) => {
    setSelectRecord(record);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <AntdModal
      open={true}
      footer={null}
      size="large"
      closeIcon={false}
      closable={false}
    >
      <div className="h-full flex flex-col">
        <div className="flex justify-between items-center p-6">
          <div className="text-lg font-semibold text-color-text-1">
            {t('payees')}
          </div>
          <div
            className="text-icon-color cursor-pointer"
            onClick={handleCancel}
          >
            <CloseIcon
              className="bg-primary-background-color rounded-full hover:text-primary-color"
              size={20}
            />
          </div>
        </div>
        <div className="border-border-color border-b"></div>
        <div className="px-6 flex-1 flex flex-col overflow-hidden">
          <div className="grid pt-6 pb-4 grid-cols-4 gap-x-4 items-end">
            <div>
              <div className="text-color-text-2 text-sm mb-1">
                {t('payee_type')}
              </div>
              <Select
                options={[
                  {
                    label: 'All',
                    value: 'All',
                  },
                  {
                    label: t('people'),
                    value: 'People',
                  },
                  {
                    label: t('business'),
                    value: 'Business',
                  },
                ]}
                value={type}
                onChange={(value) => {
                  setType(value);
                  loadingPayees(
                    1,
                    pageSize,
                    value,
                    transferMethod,
                    searchValue
                  );
                }}
              ></Select>
            </div>
            <div>
              <div className="text-color-text-2 text-sm mb-1">
                {t('transfer_method')}
              </div>
              <Select
                options={[
                  {
                    label: 'All',
                    value: 'All',
                  },
                  {
                    label: 'HK FPS - Account Number',
                    value: 'HK FPS - Account Number',
                  },
                  {
                    label: 'HK FPS - Email',
                    value: 'HK FPS - Email',
                  },
                  {
                    label: 'HK FPS - FPS ID',
                    value: 'HK FPS - FPS ID',
                  },
                  {
                    label: 'HK FPS - Mobile',
                    value: 'HK FPS - Mobile',
                  },
                  {
                    label: 'Platform Transfer',
                    value: 'Platform Transfer',
                  },
                  {
                    label: 'RTGS',
                    value: 'RTGS',
                  },
                  {
                    label: 'SWIFT',
                    value: 'SWIFT',
                  },
                ]}
                value={transferMethod}
                onChange={(value) => {
                  setTransferMethod(value);
                  loadingPayees(1, pageSize, type, value, searchValue);
                }}
              ></Select>
            </div>
            <div className="flex-grow group col-span-2">
              <div className="group rounded flex items-center h-10 px-3 py-2 cursor-auto border hover:border-primary-color border-border-color">
                <span>
                  <SearchIcon size={14} />
                </span>
                <input
                  placeholder={t('search')}
                  value={searchValue}
                  className="focus:outline-none flex-grow font-medium text-color-text-2 bg-inherit placeholder:text-color-text-5 placeholder:font-normal placeholder:text-sm text-base px-2 text-ellipsis"
                  autoComplete="none"
                  onChange={(evt) => {
                    handleChange(evt.target.value);
                  }}
                />
                <span className="invisible group-hover:visible">
                  <CleanIcon
                    size={20}
                    className="text-color-text-5 hover:text-color-text-3"
                    onClick={() => {
                      handleChange('');
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            records={records}
            loadingData={loading}
            pagination={{
              className: 'my-4',
              current: page,
              currentSize: records.length,
              pageSize,
              pageSizeOptions: [30, 60, 120],
              changePageCallback: (page, pageSize) =>
                loadingPayees(
                  page,
                  pageSize,
                  type,
                  transferMethod,
                  searchValue
                ),
            }}
            rowSelection={{
              type: 'checkbox',
              hideSelectAll: true,
              onSelect: handleSelect,
              selectedRowKeys: selectRecord ? [selectRecord.id] : undefined,
            }}
            onRowCell={(record) => {
              return {
                onClick: () => {
                  handleSelect(record);
                },
              };
            }}
          ></Table>
        </div>
        <div className="border-border-color border-0 border-b border-solid"></div>
        <div className="p-6 pt-4 flex justify-end">
          <Button
            disabled={!selectRecord}
            className={`w-36 ${
              selectRecord ? '' : 'bg-primary-color/50 text-white'
            }`}
            onClick={handleConfirm}
          >
            {t('select')}
          </Button>
        </div>
      </div>
    </AntdModal>
  );
};

export default Payees;
