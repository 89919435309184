import { useState } from 'react';
import SearchIcon from '@/components/common/filter/filter-icons/search-icon';
import { useTranslation } from 'react-i18next';
import Config from '@/libs/config';
import SelectedIcon from './store-settings-icons/selected-icon';

interface ICountryListProps {
  onChange?: (value: any) => void;
  data: any[];
  select?: string;
}

const CountryList: React.FC<ICountryListProps> = (props) => {
  const { onChange, data, select = '' } = props;
  const { t } = useTranslation(["settings", "business_account_settings", "account_balance"]);
  const [searchValue, setSearchValue] = useState('');
  const [countryData, setCountryData] = useState<any[]>(data);
  const [selectKeys, setSelectKeys] = useState(select);
  const prefix = Config.staticPath;
  const handleSelect = (item: any) => {
    setSelectKeys(item.alpha_2);
    if (onChange) {
      onChange(item);
    }
  };

  const handleValueChange = (value: string) => {
    let options: any[] = [];
    data.forEach((item: any) => {
      if (
        item.country_name
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase())
      ) {
        options.push(item);
      }
    });
    if (!value) {
      options = data;
    }
    setCountryData(options);
    setSearchValue(value);
  };

  return (
    <>
      <div className="max-h-[80vh] flex flex-col min-h-[80vh]">
        <div className="font-bold text-color-text-1 text-xl text-center">
          {t('settings:country')}
        </div>
        <div
          className={`py-2 px-2 mt-6 bg-primary-background-color flex items-center border border-border-color justify-center rounded transition-width inherit duration-300 h-10 ease-linear
        }`}
        >
          <SearchIcon className="text-border-color cursor-pointer mr-1 hover:text-primary-color" />
          <input
            className=" h-5 border-none outline-none text-sm text-color-text-1 w-0 flex-grow placeholder:text-[#c0c7cf]"
            autoFocus={true}
            value={searchValue}
            placeholder={t('common:search')}
            onChange={(e) => handleValueChange(e.target.value)}
          />
        </div>
        <div className={`mt-2 overflow-y-scroll hidden_scrollbar`}>
          {countryData.map((item: any, index) => {
            return (
              <div
                className="justify-between  flex cursor-pointer pl-2 h-[56px]"
                onClick={() => handleSelect(item)}
                key={item.alpha_2}
              >
                <div className="flex">
                  <div className='flex items-center'>
                    <img
                      // layout="intrinsic"
                      width="32px"
                      height="32px"
                      src={`${prefix}/images/country-flag/${item.alpha_2 ? item.alpha_2.toLocaleLowerCase() : 'hk'
                        }.svg`}
                      alt=""
                    />
                  </div>
                  <div className="text-sm text-color-text-1 ml-2 flex items-center font-medium">
                    {item.country_name}
                  </div>
                </div>
                {selectKeys === item.alpha_2 && (
                  <SelectedIcon size={24} className="text-primary-color" />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CountryList;
