import React from 'react';
import type { IIconProps } from '@/types/common-types';

const EllipsisIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 20 } = props;
  return (
    <div className={`${className} flex`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="4.5" cy="10" r="1.5" fill="currentColor" />
        <circle cx="10" cy="10" r="1.5" fill="currentColor" />
        <circle cx="15.5" cy="10" r="1.5" fill="currentColor" />
      </svg>
    </div>
  );
};

export default EllipsisIcon;
