import api from "@/libs/api"
import Config from "@/libs/config";
import { IQueryAssociate, IQueryAssociates } from "@/types/associates-type"

export const queryAssociates = async (params: IQueryAssociates) => {
  const { filter, businessID, lang, token } = params;
  let resp: any = {};
  try {
    resp = await api.get(`${Config.urls.gatewayUrl}/api/galaxy/associates?${filter}`, {
      headers: {
        'x-user-access-token': token,
        'x-p-business-id': businessID,
        'x-i18n-lang': lang,
      }
    })
  } catch (error) {

  }
  return resp;
}

export const queryAssociate = async (params: IQueryAssociate) => {
  let associate: any = {}
  try {
    const { id, businessID, token, lang } = params;
    const result = await api.get(
      `${Config.urls.gatewayUrl}/api/galaxy/associates/detail?id=${id}`,
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'x-i18n-lang': lang,
        },
      }
    );
    const { data } = result || {};
    if (data) {
      associate = data
    }
  } catch (error) {
    
  }
  
  return associate;
};

export const removeAssociate = async (
  id: string,
  businessID: string,
  token: string
) => {
  try {
    const result: any = await api.remove(`${Config.urls.gatewayUrl}/api/galaxy/associates/${id}`, {
      headers: {
        'x-user-access-token': token,
        'x-p-business-id': businessID,
      },
    });
    const { code } = result || {};
    if (code === 200 || code === 400002003) {
      return true;
    }
  } catch (error) {
    
  }
  return false;
};

export const updateAssociate = async (
  id: string,
  businessID: string,
  information: any,
  token: string
) => {
  try {
    const resp = await api.patch(`${Config.urls.gatewayUrl}/api/galaxy/associates/${id}`,
    { ...information },
    {
      headers: {
        'x-user-access-token': token,
        'x-p-business-id': businessID,
        'Content-Type': 'application/json; charset=utf-8',
      },
    });
    const { data } = resp || {};
    if (data && data.id) {
      return true;
    }
  } catch (error) {
    
  }
  return false;
};

export const getQRCodeLink = async (
  businessID: string,
  token: string,
  lang: string,
) => {
  let sUrl = ''
  try {
    const result = await api.post(
      `${Config.urls.gatewayUrl}/api/short-chain/qrcode-links`,
      {
        type: 'Permanent',
        link_type: 'Invite Associates',
        data: {
          p_business_id: businessID,
        },
      },
      {
        headers: {
          'x-user-access-token': token,
          'x-platform-id': Config.platformId,
          'x-p-business-id': businessID,
          'x-i18n-lang': lang,
          'x-app-slug': Config.xAppSlug,
          'x-app-key': Config.xAppKey,
          'authorization': Config.appAuthorization,
        },
      }
    );
    const { data } = result;
    if (data && data.shortChain && data.shortChain.sUrl) {
      sUrl = data.shortChain.sUrl;
    }
  } catch (error) {

  }
  return sUrl;
};

export const approveAssociate = async (
  id: string,
  businessID: string,
  information: any,
  token: string
) => {
  try {
    const resp: any = await api.post(
      `${Config.urls.gatewayUrl}/api/galaxy/associates/approval/${id}`, {
        ...information,
      },
      {
        headers: {
          'x-user-access-token': token,
          'x-p-business-id': businessID,
          'Content-Type': 'application/json; charset=utf-8',
        },
      }
    );
    const { data } = resp || {};
    if (data && data.id) {
      return true;
    }
  } catch (error) {
    
  }
  return false;
};

export const getRoles = async (
  token: string,
  businessID: string,
  lang = 'en-US'
) => {
  let roles = [];
  try {
    const resp: any = await api.get(`${Config.urls.gatewayUrl}/api/galaxy/roles`, {
      headers: {
        'x-user-access-token': token,
        'x-p-business-id': businessID,
        'x-i18n-lang': lang === 'en' ? 'en-US' : lang,
      },
    });
  
    const { data } = resp || {};
    if (Array.isArray(data)) {
      roles = data;
    }
  } catch (error) {
    
  }
  return roles;
};
