import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import TipsIcon from '@/components/svg/tips-icon';
interface ITipsWidgetProps {
  children: React.ReactNode;
  className?: string;
  tipWapperclass?: string;
  size?: 'lg' | 'md' | 'sm' | 'default';
  position?: 'right' | 'left' | 'bottom';
  contentClass?: string;
  inconNode?: React.ReactElement;
}

const TipsWidget: React.FC<ITipsWidgetProps> = (props) => {
  const {
    children,
    className = '',
    size = 'default',
    position,
    tipWapperclass = '',
    contentClass = '',
    inconNode = null,
  } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const positionOuterClasses = () => {
    switch (position) {
      case 'right':
        return 'left-full top-1/2 transform -translate-y-1/2';
      case 'left':
        return 'right-full top-1/2 transform -translate-y-1/2';
      case 'bottom':
        return 'top-full left-1/2 transform -translate-x-1/2';
      default:
        return 'bottom-full left-1/2 transform -translate-x-1/2';
    }
  };

  const sizeClasses = () => {
    switch (size) {
      case 'lg':
        return 'w-72 p-3';
      case 'md':
        return 'w-56 p-3';
      case 'sm':
        return 'w-44 p-2';
      default:
        return 'p-2';
    }
  };

  const positionInnerClasses = () => {
    switch (position) {
      case 'right':
        return 'ml-2';
      case 'left':
        return 'mr-2';
      case 'bottom':
        return 'mt-2';
      default:
        return 'mb-2';
    }
  };

  return (
    <div
      className={`relative ${className}`}
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
      onFocus={() => setTooltipOpen(true)}
      onBlur={() => setTooltipOpen(false)}
    >
      <button
        type="button"
        className="block border-none bg-white"
        aria-haspopup="true"
        aria-expanded={tooltipOpen}
        onClick={(e) => e.preventDefault()}
      >
        {inconNode || <TipsIcon className="text-primary-color" />}
      </button>
      <div
        className={`z-10 absolute ${positionOuterClasses()} ${tipWapperclass} `}
      >
        <Transition
          show={tooltipOpen}
          className={`rounded text-color-text-5 bg-color-text-1 overflow-visible ${sizeClasses()} ${positionInnerClasses()} ${contentClass}`}
          enter="transition ease-out duration-200 transform"
          enterFrom="opacity-0 -translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {children}
        </Transition>
      </div>
    </div>
  );
};

export default TipsWidget;
