import { Modal } from 'antd';
import Button from "@/components/common/button";
import { Constants } from "@/constants";
import { referralPartners } from "@/data/onboarding";
import { getCookie } from "@/utils";
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom";

const SignedSucceededPage = () => {
  const [searchParams] = useSearchParams();
  const access_token = searchParams.get('access_token');
  const lang = searchParams.get('lang') || 'en';

  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  
  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);


  let text = 'Loading now. Please wait...';
  let title = 'Unable to Process';
  let btnText = 'Try Again';
  let btnText2 = 'Back';
  let content = (
    <div className="text-[#030303] text-xs">
      Please contact <span className="text-primary-color">sales@wonder.app</span> for assistance.
    </div>
  )

  if (lang === 'zh-HK') {
    text = '正在加載. 請稍候...';
    title = '無法處理';
    btnText = '再試一次';
    btnText2 = '返回';
    content = (
      <div className="text-[#030303] text-xs">
        請聯系 <span className="text-primary-color">sales@wonder.app</span>尋求幫助。
      </div>
    )
  } else if (lang === 'zh-CN') {
    text = '正在加载. 请稍候...';
    title = '无法处理';
    btnText = '再试一次';
    btnText2 = '返回';
    content = (
      <div className="text-[#030303] text-xs">
        请联系 <span className="text-primary-color">sales@wonder.app</span>寻求帮助。
      </div>
    )
  }

  const loadData = async(isReload = false) => {
    if (isReload) {
      setLoading(true);
      setShowModal(false);
    }

    const url = getCookie(Constants.SIGN_URL);
    if (url && access_token) {
      const businessId = await referralPartners(url, access_token);
      if (businessId) {
        (window as any).handleWonder?.postMessage(
          JSON.stringify({
            context: 'Navigator',
            action: 'success',
            data: {
             business_id: businessId,
           }
          })
        );
      } else {
        setLoading(false);
        setShowModal(true)
      }
    }

  }

  const handleRetry = async () => {
    await loadData(true)
  }

  const handleBack = () => {
    (window as any).handleWonder?.postMessage(
      JSON.stringify({
        context: 'Navigator',
        action: 'back',
      })
    );
  }

  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 bg-primary-background-color">
      {
        loading && 
        <div className="w-full h-full flex flex-col justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="87" height="64" viewBox="0 0 87 64" fill="none">
            <path d="M0.5 0L21.1617 64H30.4052L43.4607 21.335L56.5948 64H65.7476L86.5 0H74.3567L61.0601 42.509L48.0764 0H38.9236L25.9011 42.6359L12.6433 0H0.5Z" fill="black"/>
          </svg>
          <div className="mt-6 font-semibold">{text}</div>
        </div>
      }
      <Modal
        open={showModal}
        closable={false}
        centered
        footer={null}
        width="auto"
        className="rounded-xl overflow-hidden"
      >
        <div className="p-6 flex flex-col justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="54" height="53" viewBox="0 0 54 53" fill="none">
            <g clipPath="url(#clip0_66429_8259)">
              <path d="M26.8334 4C22.5152 4 18.2939 5.28051 14.7034 7.6796C11.1129 10.0787 8.3145 13.4886 6.66198 17.4781C5.00946 21.4677 4.57709 25.8576 5.41953 30.0929C6.26198 34.3282 8.34141 38.2185 11.3949 41.272C14.4483 44.3254 18.3387 46.4049 22.5739 47.2473C26.8092 48.0898 31.1992 47.6574 35.1887 46.0049C39.1783 44.3524 42.5882 41.5539 44.9873 37.9634C47.3864 34.3729 48.6669 30.1517 48.6669 25.8334C48.6669 20.0428 46.3666 14.4894 42.272 10.3949C38.1774 6.3003 32.624 4 26.8334 4ZM26.8334 44.0279C23.2349 44.0279 19.7172 42.9609 16.7251 40.9616C13.733 38.9624 11.401 36.1208 10.0239 32.7962C8.64679 29.4716 8.28648 25.8132 8.98852 22.2838C9.69056 18.7545 11.4234 15.5125 13.968 12.968C16.5125 10.4234 19.7545 8.69055 23.2839 7.98851C26.8133 7.28647 30.4716 7.64678 33.7962 9.02388C37.1208 10.401 39.9624 12.733 41.9616 15.7251C43.9609 18.7172 45.028 22.2349 45.028 25.8334C45.028 28.2228 44.5573 30.5887 43.643 32.7962C42.7286 35.0036 41.3884 37.0094 39.6989 38.6989C38.0094 40.3884 36.0036 41.7286 33.7962 42.643C31.5887 43.5573 29.2228 44.0279 26.8334 44.0279Z" fill="#FC2E01"/>
              <path d="M26.8333 29.5985C26.206 29.5985 25.6044 29.3493 25.1608 28.9057C24.7172 28.4621 24.468 27.8605 24.468 27.2332V16.3165C24.468 15.6891 24.7172 15.0875 25.1608 14.6439C25.6044 14.2004 26.206 13.9512 26.8333 13.9512C27.4606 13.9512 28.0622 14.2004 28.5058 14.6439C28.9494 15.0875 29.1986 15.6891 29.1986 16.3165V27.2332C29.1986 27.8605 28.9494 28.4621 28.5058 28.9057C28.0622 29.3493 27.4606 29.5985 26.8333 29.5985Z" fill="#FC2E01"/>
              <path d="M26.7426 37.6957C28.2499 37.6957 29.4718 36.4738 29.4718 34.9665C29.4718 33.4592 28.2499 32.2373 26.7426 32.2373C25.2353 32.2373 24.0134 33.4592 24.0134 34.9665C24.0134 36.4738 25.2353 37.6957 26.7426 37.6957Z" fill="#FC2E01"/>
            </g>
            <defs>
              <clipPath id="clip0_66429_8259">
                <rect width="53" height="53" fill="white" transform="translate(0.5)"/>
              </clipPath>
            </defs>
          </svg>
          <div className="font-medium my-2">{title}</div>
          {content}
          <Button onClick={handleRetry} className="w-full mt-4 mb-2">
            {btnText}
          </Button>
          <Button onClick={handleBack} styleType="Secondary" className="w-full">
            {btnText2}
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default SignedSucceededPage