import React from 'react';
import type { IIconProps } from '@/types/common-types';

const PersonalSpaceIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.9974 9.16667C11.8383 9.16667 13.3307 7.67428 13.3307 5.83333C13.3307 3.99238 11.8383 2.5 9.9974 2.5C8.15645 2.5 6.66406 3.99238 6.66406 5.83333C6.66406 7.67428 8.15645 9.16667 9.9974 9.16667Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.41927 17.4977C7.26022 17.4977 8.7526 16.0053 8.7526 14.1644C8.7526 12.3234 7.26022 10.8311 5.41927 10.8311C3.57832 10.8311 2.08594 12.3234 2.08594 14.1644C2.08594 16.0053 3.57832 17.4977 5.41927 17.4977Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5833 17.4977C16.4243 17.4977 17.9167 16.0053 17.9167 14.1644C17.9167 12.3234 16.4243 10.8311 14.5833 10.8311C12.7424 10.8311 11.25 12.3234 11.25 14.1644C11.25 16.0053 12.7424 17.4977 14.5833 17.4977Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default PersonalSpaceIcon;
