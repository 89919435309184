import { AutoComplete } from 'antd';
import { useEffect, useState } from 'react';
import { queryGoogleService } from '../../../data/terminal';
import type { IObject } from '@/types/common-types';
import type { FieldError } from 'react-hook-form';
interface IAutocompleteInputParam {
  businessID: string;
  token: string;
  value: string;
  placeholder: string;
  error?: FieldError;
  onChange?: (value: IObject) => void;
}

interface IOptionParam {
  value: string;
  label: string;
  details: string;
}

const AutocompleteInput: React.FC<IAutocompleteInputParam> = (props) => {
  // const Google_Key = 'AIzaSyBlsJAzarDsq1ZNLHmu3fLkYhQ69bITT1o';
  const { businessID, token, placeholder, onChange, value, error } = props;

  const [options, setOptions] = useState<IOptionParam[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [isPass, setIspass] = useState<boolean>(true);
  const [key, setKey] = useState<number>(Math.random());

  useEffect(() => {
    setInputValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = async (value: string) => {
    setInputValue(value);

    if (onChange) {
      onChange({
        value,
      });
    }

    const { predictions = [] } = await queryGoogleService({
      businessID,
      token,
      query: `region=HK&input=${value}`,
      action: 'autocomplete',
    });

    const options: IOptionParam[] = [];

    predictions.forEach((item: IObject) => {
      const { structured_formatting = {}, description = '', place_id } = item;
      const { main_text = '' } = structured_formatting;
      const newItem = {
        value: place_id,
        label: description,
        details: main_text,
      };
      options.push(newItem);
    });

    setOptions(options);
    setIspass(false);
  };

  const handleHightlight = (value: string) => {
    let returnValue = value;
    if (value && inputValue) {
      const index = value
        .toLocaleLowerCase()
        .indexOf(inputValue.toLocaleLowerCase());
      const hightStr = value.slice(index, index + inputValue.length);
      returnValue = value.replace(
        hightStr,
        `<span style="color: #10B981">${hightStr}</span>`
      );
    }
    return returnValue;
  };

  const renderOption = (option: IOptionParam) => {
    return (
      <div key={option.value} className="flex items-center">
        <div className="flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M11.9872 6.67858C12.835 6.67858 13.6481 7.01537 14.2476 7.61485C14.8471 8.21433 15.1839 9.02741 15.1839 9.8752C15.1839 10.723 14.8471 11.5361 14.2476 12.1356C13.6481 12.735 12.835 13.0718 11.9872 13.0718C11.1394 13.0718 10.3264 12.735 9.72688 12.1356C9.1274 11.5361 8.79061 10.723 8.79061 9.8752C8.79061 9.02741 9.1274 8.21433 9.72688 7.61485C10.3264 7.01537 11.1394 6.67858 11.9872 6.67858ZM18.9999 9.87661C18.9998 8.78487 18.7449 7.70822 18.2553 6.73239C17.7658 5.75656 17.0552 4.90851 16.1801 4.25576C15.305 3.60301 14.2895 3.1636 13.2146 2.97251C12.1398 2.78142 11.0351 2.84394 9.98864 3.15509C8.94218 3.46623 7.98282 4.01741 7.18695 4.76472C6.39108 5.51204 5.78068 6.43486 5.40437 7.45969C5.02806 8.48452 4.89622 9.58306 5.01936 10.6678C5.1425 11.7526 5.51721 12.7936 6.11365 13.7081L11.3594 20.7024C11.4177 20.8193 11.5073 20.9176 11.6184 20.9863C11.7294 21.055 11.8574 21.0914 11.9879 21.0914C12.1185 21.0914 12.2465 21.055 12.3575 20.9863C12.4685 20.9176 12.5582 20.8193 12.6164 20.7024L17.872 13.7081C18.5799 12.6041 18.9999 11.2923 18.9999 9.87661Z"
              fill="#1F2F3C"
            />
          </svg>
        </div>
        <div className="text-sm ml-3">
          <div
            className="font-medium text-color-text-1"
            title={option.label}
            dangerouslySetInnerHTML={{
              __html: handleHightlight(option.label),
            }}
          ></div>
          <div className="text-color-text-2"
            title={option.details}
            dangerouslySetInnerHTML={{
              __html: handleHightlight(option.details),
            }}></div>
        </div>
      </div>
    );
  };

  const handleSelect = async (value: any, option: any) => {
    const records = options.filter((item) => item.value === option.key);
    if (records.length > 0) {
      const id = records[0].value;
      const { result = {} } = await queryGoogleService({
        businessID,
        token,
        query: `place_id=${id}`,
        action: 'details',
      });

      const { location = {} } = result.geometry || {};
      const { lat, lng } = location;
      if (onChange) {
        onChange({
          value,
          lat,
          lng,
        });
      }
      setIspass(true);
    }
    setKey(Math.random());
  };

  const handleBlur = () => {
    if (!isPass) {
      setInputValue('');
      setOptions([]);
      if (onChange) {
        onChange({
          value: '',
        });
      }
      setKey(Math.random());
    }
  };

  return (
    <>
      <AutoComplete
        onChange={handleChange}
        onSelect={handleSelect}
        key={key}
        onBlur={handleBlur}
        value={inputValue}
        placeholder={placeholder}
        options={options.map((option) => ({
          value: option.label,
          label: renderOption(option),
          key: option.value
        }))}
      ></AutoComplete>
      {error && (
        <div className="mt-1 text-sm text-error-color">{error.message}</div>
      )}
    </>
  );
};

export default AutocompleteInput;
