import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Constants } from '@/constants';
import { getCookie } from '@/utils';
import { Alert } from '@/components/common/alert/alert';
import AntdModal from '@/components/common/antd/modal';
import WarningIcon from '@/components/svg/warning-icon';
import Button from '@/components/common/button';
import { IPayout } from '@/types/payouts-type';
import Config from '@/libs/config';
import api from '@/libs/api';
import { payoutStatusUpdate } from '@/data/payouts';
import { useParams } from 'react-router-dom';
import { handleMount } from '@/utils/payouts-utils';
import Skeleton from '@/components/skeleton';

interface IApprovePayoutProps {
  payout: IPayout;
  businessID: string;
  callback: () => void;
  onReject: () => void;
  onCancel: () => void;
}

let timer: any = '';

const ApprovePayout: React.FC<IApprovePayoutProps> = (props) => {
  const { payout, businessID, callback, onReject, onCancel } = props;
  const { t } = useTranslation('payouts');
  const { lang = 'en' } = useParams();
  const {
    destination_amount,
    destination_currency,
    calculated_fee,
    currency,
    total_amount,
    payee,
    fx_exchange_rate,
    fx_quote_id,
    id,
  } = payout;

  const [fee, setFee] = useState(calculated_fee);
  const [total, setTotal] = useState(total_amount);
  const [rate, setRate] = useState(fx_exchange_rate);
  const [fxQuoteID, setFxQuoteID] = useState(fx_quote_id);
  const [time, setTime] = useState(30);
  const [loading, setLoading] = useState(true);
  const [approveLoading, setApproveLoading] = useState(false);

  useEffect(() => {
    clearInterval(timer);
    handleFxQuote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timerStart = () => {
    const now = dayjs();
    timer = setInterval(() => handleFxQuoteAgain(now), 1000);
  };

  const handleFxQuoteAgain = (startTime: dayjs.Dayjs) => {
    const intervalTime = Math.floor(dayjs().diff(startTime) / 1000);
    const newTime = 30 - intervalTime;
    setTime(newTime);
    if (newTime < 0) {
      setLoading(true);
      handleFxQuote();
    }
  };

  const handleFxQuote = async () => {
    setLoading(true);
    clearInterval(timer);
    setTime(30);
    const token = getCookie(Constants.TOKEN) || '';
    const resp = await api.post(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/payout_requests/fx_quote`,
      {
        buy_amount: destination_amount,
        buy_currency: destination_currency,
        dealt_side: 'Buy',
        sell_amount: 0,
        payee_id: payee?.id,
        sell_currency: currency,
      },
      {
        headers: {
          'x-p-business-id': businessID,
          'x-client-id': Config.clientID,
          'x-platform-id': Config.platformId,
          'X-USER-ACCESS-TOKEN': token,
          'x-i18n-lang': lang,
          'Content-Type': 'application/json',
        },
      }
    );
    const { data } = resp || {};
    const { sell_amount, fee_amount, error_code, exchange_rate, fx_quote_id } =
      data || {};
    if (!error_code) {
      if (fee_amount) {
        setFee(fee_amount);
      }
      setTotal(sell_amount);
      setRate(exchange_rate);
      setFxQuoteID(fx_quote_id || '');
    }
    setLoading(false);
    if (currency !== destination_currency) {
      timerStart();
    }
  };

  const handleApprove = async () => {
    setApproveLoading(true);
    if (await payoutStatusUpdate(businessID, id, 'Approved', fxQuoteID)) {
      Alert.success({
        message: t('payout_approved'),
        position: 'default',
        wrapClass: 'top-px',
      });
      clearInterval(timer);
      await callback();
    } else {
      Alert.error({
        message: t('payout_approve_failed'),
        position: 'default',
        wrapClass: 'top-px',
      });
    }
    setApproveLoading(false);
  };

  const getTips = () => {
    const script = t('exchange_rate_tips');
    const text = script.split('{{time}}');
    return (
      <div className="text-center text-base leading-snug my-6 px-10 whitespace-pre-line text-color-text-1">
        {text[0]}
        {
          <label className="font-bold text-primary-color">
            {time}
            {t('s')}
          </label>
        }
        {text.length > 1 ? text[1] : ''}
      </div>
    );
  };

  const handleCancel = () => {
    clearInterval(timer);
    onCancel();
  };

  return (
    <AntdModal open={true} onCancel={handleCancel} footer={null}>
      <div className="flex flex-col items-center justify-center text-color-text-2 font-medium p-6 pt-10">
        <div className="p-5 rounded-full bg-warning-color/10">
          <WarningIcon className={`text-warning-color text-3xl`} size={40} />
        </div>
        {currency !== destination_currency && getTips()}
        {currency === destination_currency && (
          <div className="text-center mt-4 mb-6 px-9 whitespace-pre-line text-color-text-1">
            {t('check_updated')}
          </div>
        )}
        <div className="border border-primary-color rounded-[10px] px-4 w-full divide-y mb-6">
          <div className="flex items-center justify-between py-4">
            <div>{t('transfer_amount')}</div>
            <div className="text-color-text-1">{`${handleMount(
              destination_amount,
              2,
              true
            )} ${destination_currency}`}</div>
          </div>
          {currency !== destination_currency && (
            <div className="flex items-center justify-between py-4">
              <div>{t('rate')}</div>
              {loading && (
                <div>
                  <Skeleton
                    row={1}
                    width={[115]}
                    skeletonItemClass={`h-5 rounded-2xl mb-0`}
                  />
                </div>
              )}
              {!loading && (
                <div className="text-color-text-1">
                  {rate} {currency}-{destination_currency}
                </div>
              )}
            </div>
          )}
          <div className="flex items-center justify-between py-4">
            <div>{t('fees_charged')}</div>
            {loading && (
              <div>
                <Skeleton
                  row={1}
                  width={[115]}
                  skeletonItemClass={`h-5 rounded-2xl mb-0`}
                />
              </div>
            )}
            {!loading && (
              <div className="text-color-text-1">{`${handleMount(
                fee,
                2,
                true
              )} ${currency}`}</div>
            )}
          </div>
          <div className="flex items-center justify-between py-4">
            <div>{t('billing_amount')}</div>
            {loading && (
              <div>
                <Skeleton
                  row={1}
                  width={[115]}
                  skeletonItemClass={`h-5 rounded-2xl mb-0`}
                />
              </div>
            )}
            {!loading && (
              <div className="text-color-text-1">{`${handleMount(
                total,
                2,
                true
              )} ${currency}`}</div>
            )}
          </div>
        </div>
        <Button
          className="w-full"
          onClick={handleApprove}
          loading={approveLoading}
        >
          {t('approve_payout')}
        </Button>
        <Button
          styleType={'Secondary Danger'}
          className="w-full mt-4"
          onClick={() => {
            clearInterval(timer);
            onReject();
          }}
        >
          {t('reject_payout')}
        </Button>
      </div>
    </AntdModal>
  );
};

export default ApprovePayout;
