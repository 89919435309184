import type { IIconProps } from '@/types/common-types';
import React from 'react';

const NoResultsIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 16 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 176 176"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31 99C31 97.8954 31.8954 97 33 97H46.3V120.5C46.3 123.261 44.0614 125.5 41.3 125.5H36.5C33.4624 125.5 31 123.038 31 120V99Z"
          fill="#00B2B2"
          stroke="black"
          strokeWidth="2"
        />
        <path
          d="M30 56.8497C31.5234 56.4145 34.9619 56.0662 36.5288 58.1555L41.7519 66.2078"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M130.109 55.2208C128.586 54.7855 125.147 54.4373 123.58 56.5266L118.357 64.5789"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M42.1873 43C42.1873 38.0294 46.2167 34 51.1873 34H109.357C114.328 34 118.357 38.0294 118.357 43V125.404H42.1873V43Z"
          fill="white"
          stroke="black"
          strokeWidth="2"
        />
        <path
          d="M68.7379 56.6328C67.7223 58.664 64.2112 62.117 58.2917 59.6796"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle cx="67.8674" cy="62.0742" r="1.5234" fill="black" />
        <path
          d="M92.6769 56.6328C93.6925 58.664 97.2036 62.117 103.123 59.6796"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle
          cx="1.5234"
          cy="1.5234"
          r="1.5234"
          transform="matrix(-1 0 0 1 95.0708 60.5508)"
          fill="black"
        />
        <rect
          x="127.401"
          y="122.359"
          width="7.83462"
          height="4.78782"
          rx="2"
          transform="rotate(45 127.401 122.359)"
          fill="white"
          stroke="black"
          strokeWidth="2"
        />
        <path
          d="M132.854 127.254C133.635 126.473 134.901 126.473 135.683 127.254L150.272 141.844C152.142 143.714 152.142 146.745 150.273 148.615C148.403 150.485 145.371 150.485 143.501 148.615L128.912 134.025C128.13 133.244 128.13 131.978 128.912 131.197L132.854 127.254Z"
          fill="#00B2B2"
          stroke="black"
          strokeWidth="2"
        />
        <line
          x1="137.587"
          y1="129.16"
          x2="130.816"
          y2="135.931"
          stroke="black"
          strokeWidth="2"
        />
        <circle
          cx="105.734"
          cy="103.643"
          r="29.1622"
          fill="white"
          stroke="black"
          strokeWidth="2"
        />
        <path
          d="M134.897 103.643C134.897 119.748 121.84 132.805 105.734 132.805C89.6286 132.805 76.5723 119.748 76.5723 103.643C76.5723 87.5368 89.6286 74.4805 105.734 74.4805C121.84 74.4805 134.897 87.5368 134.897 103.643ZM82.4016 103.643C82.4016 116.529 92.8481 126.976 105.734 126.976C118.621 126.976 129.067 116.529 129.067 103.643C129.067 90.7563 118.621 80.3098 105.734 80.3098C92.8481 80.3098 82.4016 90.7563 82.4016 103.643Z"
          fill="#F5F8FF"
          stroke="black"
          strokeWidth="2"
        />
        <path
          d="M113.635 92.2294C112.878 90.9655 111.928 90.0183 110.6 89.3847C109.337 88.7543 107.882 88.4375 106.175 88.4375C103.457 88.4375 101.243 89.1946 99.6619 90.7754H99.5985C97.9576 92.2896 97.0706 94.3139 97.0706 96.7151C97.0706 96.9052 97.1339 97.0319 97.2606 97.1554C97.3873 97.2822 97.5141 97.3455 97.701 97.3455H102.253C102.443 97.3455 102.57 97.2822 102.694 97.1554C102.82 97.0287 102.884 96.902 102.884 96.7151C102.884 95.8946 103.2 95.2611 103.767 94.7542C104.274 94.2474 105.031 93.9971 105.918 93.9971C106.802 93.9971 107.499 94.1872 108.069 94.6275C108.51 95.0678 108.7 95.448 108.763 96.0182C108.763 96.3983 108.636 96.7753 108.51 97.0921C108.193 97.4722 107.499 98.2294 106.295 99.2431H106.359C105.031 100.38 104.081 101.644 103.451 102.975C102.82 104.365 102.567 105.82 102.567 107.464V108.221C102.567 108.411 102.567 108.538 102.757 108.664C102.884 108.791 103.01 108.854 103.2 108.854H107.879C108.006 108.854 108.196 108.791 108.323 108.664C108.45 108.538 108.513 108.411 108.513 108.284C108.513 107.21 108.64 106.45 108.83 105.883V105.82C108.956 105.252 109.146 104.872 109.337 104.619L109.4 104.556C109.653 104.175 110.22 103.545 111.171 102.658C112.245 101.711 112.942 100.951 113.258 100.507C113.762 99.8133 114.142 99.053 114.396 98.3561C114.649 97.5989 114.776 96.8387 114.776 96.1449C114.773 94.7574 114.392 93.43 113.635 92.2294ZM108.199 112.801C107.505 112.108 106.618 111.791 105.608 111.791C104.661 111.791 103.774 112.108 103.08 112.801H103.017C102.323 113.559 101.943 114.382 101.943 115.393C101.943 116.34 102.323 117.227 103.08 117.984C103.774 118.614 104.661 118.995 105.608 118.995C106.618 118.995 107.505 118.614 108.263 117.984V117.921C108.956 117.227 109.273 116.34 109.273 115.393C109.273 114.382 108.956 113.559 108.263 112.801H108.199Z"
          fill="#FFBE00"
        />
      </svg>
    </div>
  );
};

export default NoResultsIcon;
