import React from 'react';
import type { IIconProps } from '@/types/common-types';

const DownArrowDashedIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="9"
        height="82"
        viewBox="0 0 9 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.85108 81.4604C4.06654 81.6759 4.41587 81.6759 4.63134 81.4604L8.14249 77.9493C8.35795 77.7338 8.35795 77.3845 8.14249 77.169C7.92702 76.9536 7.57769 76.9536 7.36223 77.169L4.24121 80.2901L1.12018 77.169C0.904723 76.9536 0.555391 76.9536 0.339929 77.169C0.124467 77.3845 0.124467 77.7338 0.339929 77.9493L3.85108 81.4604ZM3.68949 0.0703125L3.68949 1.19531L4.79294 1.19531L4.79294 0.0703125L3.68949 0.0703125ZM3.68949 3.44531L3.68949 5.69531L4.79293 5.69531L4.79293 3.44531L3.68949 3.44531ZM3.68949 7.94531L3.68949 10.1953L4.79293 10.1953L4.79293 7.94531L3.68949 7.94531ZM3.68949 12.4453L3.68949 14.6953L4.79293 14.6953L4.79293 12.4453L3.68949 12.4453ZM3.68949 16.9453L3.68949 19.1953L4.79293 19.1953L4.79293 16.9453L3.68949 16.9453ZM3.68949 21.4453L3.68949 23.6953L4.79293 23.6953L4.79293 21.4453L3.68949 21.4453ZM3.68949 25.9453L3.68949 28.1953L4.79293 28.1953L4.79293 25.9453L3.68949 25.9453ZM3.68949 30.4453L3.68949 32.6953L4.79293 32.6953L4.79293 30.4453L3.68949 30.4453ZM3.68949 34.9453L3.68949 37.1953L4.79293 37.1953L4.79293 34.9453L3.68949 34.9453ZM3.68949 39.4453L3.68948 41.6953L4.79293 41.6953L4.79293 39.4453L3.68949 39.4453ZM3.68948 43.9453L3.68948 46.1953L4.79293 46.1953L4.79293 43.9453L3.68948 43.9453ZM3.68948 48.4453L3.68948 50.6953L4.79293 50.6953L4.79293 48.4453L3.68948 48.4453ZM3.68948 52.9453L3.68948 55.1953L4.79293 55.1953L4.79293 52.9453L3.68948 52.9453ZM3.68948 57.4453L3.68948 59.6953L4.79293 59.6953L4.79293 57.4453L3.68948 57.4453ZM3.68948 61.9453L3.68948 64.1953L4.79293 64.1953L4.79293 61.9453L3.68948 61.9453ZM3.68948 66.4453L3.68948 68.6953L4.79293 68.6953L4.79293 66.4453L3.68948 66.4453ZM3.68948 70.9453L3.68948 73.1953L4.79293 73.1953L4.79293 70.9453L3.68948 70.9453ZM3.68948 75.4453L3.68948 77.6953L4.79293 77.6953L4.79293 75.4453L3.68948 75.4453ZM3.68948 79.9453L3.68948 81.0703L4.79293 81.0703L4.79293 79.9453L3.68948 79.9453Z"
          fill="#0094FF"
        />
      </svg>
    </div>
  );
};

export default DownArrowDashedIcon;
