import { useState } from 'react';
import type { IStoreOverviewFormViewProps } from './type';
import Input from '@/components/common/input/input';
import Label from '@/components/common/label';
import { Controller } from 'react-hook-form';
import PhoneNumber from '@/components/common/phone-number';
import ImageInput from '@/components/common/file-input/image-input';
import CountryList from './country-list';
import { Transition } from '@headlessui/react';
import { findNode } from '@/utils';
import { getCookie } from '@/utils/index';
import Config from '@/libs/config';
import { Constants } from '@/constants';
import api from '@/libs/api';
import { useTranslation } from 'react-i18next';
import Skeleton from '@/components/skeleton';
import CopyIcon from '@/components/svg/copy-icon';
import { useEffect } from 'react';
import MultiLangInput from '@/components/common/multi-lang-input';
import MultiLangTextarea from '@/components/common/multi-lang-textarea';
import DefaultImageIcon from './store-settings-icons/default-image-icon';
import ModalContent from '@/components/common/modal/modal-content';

let tipsTimeout: any;
const CustomerProfile = (props: IStoreOverviewFormViewProps) => {
  const { areaCodes, disabled, record, useFormReturn, loading = false } = props;
  const { t } = useTranslation(["settings", "business_account_settings", "account_balance"]);

  const country = findNode<any>(
    areaCodes,
    'isoAlpha2Code',
    record?.country?.alpha_2
  );

  const [countryData, setCountryData] = useState<any[]>([]);
  const [showCountryModal, setCountryModal] = useState(false);
  const { businessPictures, country: businessCountry } = record || {};
  const [pictures, setPictures] = useState(businessPictures || []);

  const { reset, getValues } = useFormReturn;
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    if (!loading) {
      record.country.alpha_2 = businessCountry?.alpha_2;
      setPictures(businessPictures);
    }
    getCountryData();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!loading]);

  const getCountryData = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    try {
      const result = await api.get(
        `${Config.urls.gatewayUrl}/api/registry/countries`,
        {
          headers: {
            'x-user-access-token': token,
          },
        }
      );
      if (Array.isArray(result.data)) {
        setCountryData(result.data);
      }
    } catch (e) { }
  };

  const handleSetCountry = (value: any) => {
    const values = getValues();
    record.country.alpha_2 = value.alpha_2;
    record.country.country_name = value.country_name;
    reset({
      ...values,
      country: {
        alpha_2: value.alpha_2,
        country_name: value.country_name,
      },
    });
    setCountryModal(false);
  };

  useEffect(() => {
    if (copySuccess) {
      clearTimeout(tipsTimeout);
      tipsTimeout = setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }
  }, [copySuccess]);

  const handleCopy = () => {
    navigator.clipboard.writeText(record?.p_business_id).then(() => {
      setCopySuccess(true);
    });
  };

  return (
    <>
      <Transition.Root show={true} appear={true}>
        <div className="w-full mt-6">
          <Transition.Child
            className="flex-1"
            enter="duration-350 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-8 opacity-60"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div
              className={`w-full flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit`}
            >
              <div className="font-bold text-xl text-color-text-1">
                {t('business_account_settings:business_pictures')}
              </div>
              <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
              <div className="grid grid-cols-1 gap-x-6 gap-y-2">
                <div className="">
                  <div className="w-full">
                    {!loading && (!disabled || pictures.length > 0) && (
                      <Controller
                        name={'businessPictures'}
                        control={useFormReturn.control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <ImageInput
                            className="w-1/4"
                            name={'businessPictures'}
                            proportion={2}
                            rowQuantity={6}
                            multiValue={true}
                            maxCount={9}
                            {...props}
                            onChange={(data) => {
                              onChange(data);
                              setPictures(data);
                            }}
                            value={value}
                            error={error}
                            disabled={disabled}
                          />
                        )}
                      />
                    )}
                    {!loading && pictures.length <= 0 && disabled && (
                      <div
                        className={`block gap-4 sm:grid`}
                        style={{
                          gridTemplateColumns: `repeat(6, minmax(0, 1fr))`,
                        }}
                      >
                        <div className="aspect-w-4 aspect-h-3 flex flex-1">
                          <div className="flex-1 justify-center items-center flex">
                            <div>
                              <DefaultImageIcon
                                size={40}
                                className="text-primary-color justify-center items-center flex"
                              />
                              <div className=" text-color-text-2 text-sm text-center mt-3 whitespace-pre">
                                {t(
                                  'business_account_settings:no_image_submit_yet'
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {loading && (
                      <Skeleton
                        row={1}
                        width={['100%']}
                        skeletonItemClass={`h-20 rounded-xl mb-0`}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>

          <Transition.Child
            className="flex-1"
            enter="duration-700 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-10 opacity-30"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-500 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div
              className={`w-full flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit`}
            >
              <div className="font-bold text-xl text-color-text-1">
                {t('business_account_settings:business_information')}
              </div>
              <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
              <div className="grid grid-cols-1 gap-x-6 gap-y-2 md:gap-y-6">
                <div className="">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-6 mb-0 md:mb-4">
                    <div className="w-full mb-6 md:mb-0">
                      <Label
                        disabled={true}
                        value={t('business_account_settings:business_id')}
                        className="mb-0"
                      ></Label>
                      {loading && (
                        <Skeleton
                          row={1}
                          className={`h-10`}
                          width={['100%']}
                          skeletonItemClass={`h-10 rounded-xl mb-0`}
                        />
                      )}
                      {!loading && (
                        <div className="py-2 text-color-text-2 font-medium pr-6 flex items-center space-x-2 h-10">
                          <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-color-text-1 font-medium text-sm ">
                            {record?.p_business_id}
                          </span>
                          <div className="flex w-24">
                            <span
                              className="cursor-pointer group"
                              onClick={handleCopy}
                            >
                              <CopyIcon className="text-primary-color" />
                            </span>
                            <span
                              className={`text-primary-color text-xs cursor-pointer ${copySuccess ? 'visible' : 'invisible'
                                }`}
                            >
                              {t('account_balance:copied')}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-full mb-6 md:mb-0">
                      <Label
                        disabled={true}
                        value={t('business_account_settings:business_name')}
                      ></Label>
                      {loading && (
                        <Skeleton
                          row={1}
                          className={`h-10`}
                          width={['100%']}
                          skeletonItemClass={`h-10 rounded-xl mb-0`}
                        />
                      )}
                      {!loading && (
                        <Input
                          name="id"
                          value={record?.business_legal_name}
                          disabled={true}
                        />
                      )}
                    </div>
                    <div className="w-full mb-6 md:mb-0">
                      <Label
                        disabled={disabled}
                        value={t(
                          'business_account_settings:business_account_name'
                        )}
                        required={true}
                      ></Label>
                      {loading && (
                        <Skeleton
                          row={1}
                          className={`h-10`}
                          width={['100%']}
                          skeletonItemClass={`h-10 rounded-xl mb-0`}
                        />
                      )}
                      {!loading && (
                        <Controller
                          name="business_name"
                          control={useFormReturn.control}
                          rules={{
                            validate: (value) => {
                              let msg = '';
                              if (value && value['en-US'] === '') {
                                msg = t('settings:required_tips');
                              }
                              return msg;
                            },
                          }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <MultiLangInput
                              onChangeMultiLang={(data) => {
                                onChange(data);
                              }}
                              value={value}
                              error={error}
                              disabled={disabled}
                              showMultiLanguage={true}
                              title={t(
                                'business_account_settings:business_account_name'
                              )}
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full mb-6 md:mb-0">
                    <Label
                      disabled={disabled}
                      value={t('settings:highlight')}
                    ></Label>
                    {loading && (
                      <Skeleton
                        row={1}
                        className={`h-10`}
                        width={['100%']}
                        skeletonItemClass={`h-10 rounded-xl mb-0`}
                      />
                    )}
                    {!loading && (
                      <Controller
                        name="business_highlight"
                        control={useFormReturn.control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <MultiLangTextarea
                            onChangeMultiLang={(data) => {
                              onChange(data);
                            }}
                            rows={2}
                            value={value}
                            error={error}
                            disabled={disabled}
                            showMultiLanguage={true}
                            title={t('settings:highlight')}
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>

          <Transition.Child
            className="w-full flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit"
            enter="duration-900 transform transition ease-in-out"
            enterFrom="-translate-x-1 translate-y-8 opacity-0"
            enterTo="translate-x-0 translate-y-0 opacity-100"
            leave="duration-700 transform transition"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="font-bold text-xl text-color-text-1">
              {t('business_account_settings:contact_information')}
            </div>
            <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-6 gap-y-2 md:gap-y-6 mb-2 md:mb-4">
              <div className="w-full">
                <Label
                  disabled={disabled}
                  value={t('settings:phone_number')}
                ></Label>
                {loading && (
                  <Skeleton
                    row={1}
                    className={`h-10`}
                    width={['100%']}
                    skeletonItemClass={`h-10 rounded-xl mb-0`}
                  />
                )}
                {!loading && (record?.business_phone || !disabled) && (
                  <Controller
                    name={'phone'}
                    control={useFormReturn.control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <PhoneNumber
                        position="bottom"
                        name="signin"
                        className={
                          disabled ? 'min-w-default pt-0.5' : 'min-w-6.5'
                        }
                        options={areaCodes}
                        showSearch
                        placeholder="Phone Number"
                        onChange={(data) => {
                          onChange(data);
                        }}
                        value={value}
                        error={error}
                        disabled={disabled}
                        isSetting={true}
                      />
                    )}
                  />
                )}
                {!loading && !record?.business_phone && disabled && (
                  <span className="py-2 text-color-text-1 text-sm font-medium pr-2 block">
                    --
                  </span>
                )}
              </div>
              <div className="w-full">
                <Label disabled={disabled} value={t('settings:email')}></Label>
                {loading && (
                  <Skeleton
                    row={1}
                    className={`h-10`}
                    width={['100%']}
                    skeletonItemClass={`h-10 rounded-xl mb-0`}
                  />
                )}
                {!loading && (
                  <Controller
                    name={'business_email'}
                    control={useFormReturn.control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        onChange={(data) => {
                          onChange(data);
                        }}
                        value={value}
                        error={error}
                        autoComplete="off"
                        placeholder={t('settings:email_address')}
                        disabled={disabled}
                      />
                    )}
                  />
                )}
              </div>
            </div>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-6 gap-y-2 md:gap-y-6 mb-2 md:mb-4">
              <div className="">
                <div className="w-full">
                  <Label
                    disabled={disabled}
                    value={t('settings:address_1')}
                  ></Label>
                  {loading && (
                    <Skeleton
                      row={1}
                      className={`h-10`}
                      width={['100%']}
                      skeletonItemClass={`h-10 rounded-xl mb-0`}
                    />
                  )}
                  {!loading && (
                    <Controller
                      name="address_1"
                      control={useFormReturn.control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <MultiLangTextarea
                          onChangeMultiLang={(data) => {
                            onChange(data);
                          }}
                          rows={2}
                          value={value}
                          error={error}
                          disabled={disabled}
                          showMultiLanguage={true}
                          title={t('settings:address_1')}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="">
                <div className="w-full">
                  <Label
                    disabled={disabled}
                    value={t('settings:address_2')}
                  ></Label>
                  {loading && (
                    <Skeleton
                      row={1}
                      className={`h-10`}
                      width={['100%']}
                      skeletonItemClass={`h-10 rounded-xl mb-0`}
                    />
                  )}
                  {!loading && (
                    <Controller
                      name="address_2"
                      control={useFormReturn.control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <MultiLangTextarea
                          onChangeMultiLang={(data) => {
                            onChange(data);
                          }}
                          rows={2}
                          value={value}
                          error={error}
                          disabled={disabled}
                          showMultiLanguage={true}
                          title={t('settings:address_2')}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-6 gap-y-2 md:gap-y-6">
              <div className="w-full mt-2 md:mt-0">
                <Label disabled={disabled} value={t('settings:city')}></Label>
                {loading && (
                  <Skeleton
                    row={1}
                    className={`h-10`}
                    width={['100%']}
                    skeletonItemClass={`h-10 rounded-xl mb-0`}
                  />
                )}
                {!loading && (
                  <Controller
                    name="city"
                    control={useFormReturn.control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <MultiLangInput
                        onChangeMultiLang={(data) => {
                          onChange(data);
                        }}
                        value={value}
                        error={error}
                        disabled={disabled}
                        showMultiLanguage={true}
                        title={t('settings:city')}
                      />
                    )}
                  />
                )}
              </div>

              <div className="w-full">
                <Label
                  disabled={true}
                  value={t('settings:country_region')}
                  required={true}
                ></Label>
                {loading && (
                  <Skeleton
                    row={1}
                    className={`h-10`}
                    width={['100%']}
                    skeletonItemClass={`h-10 rounded-xl mb-0`}
                  />
                )}
                {!loading && (
                  <div
                    className={`flex h-10 rounded relative items-center`}
                    // onClick={() =>
                    //   !disabled ? setCountryModal(true) : undefined
                    // }
                  >
                    <Controller
                      name="country_name"
                      control={useFormReturn.control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <span
                            className={`flex items-center w-5 mr-1 absolute`}
                          >
                            {record?.country && (
                              <img
                                // layout="intrinsic"
                                width="20px"
                                height="20px"
                                src={`/images/country-flag/${record?.country.alpha_2.toLocaleLowerCase()}.svg`}
                                alt=""
                              />
                            )}
                          </span>
                          <input
                            style={{
                              fontFamily: 'Roboto',
                            }}
                            className={`focus:outline-none flex-grow font-medium text-color-text-1 bg-inherit
                            placeholder:text-color-text-5 placeholder:font-normal placeholder:text-sm text-sm pr-2 text-ellipsis flex-1 py-2 pl-6`}
                            type="text"
                            value={country ? country.name : ''}
                            disabled
                          />
                        </>
                      )}
                    />
                    {/* <span
                      className={`text-icon-color px-2 h-0 top-1 absolute right-1 ${disabled ? 'hidden' : 'block'
                        }`}
                    >
                      ...
                    </span> */}
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
      <ModalContent
        visible={showCountryModal}
        onCancel={() => setCountryModal(false)}
        title={''}
        visibleIcon={false}
        className="px-6 sm:px-6"
      >
        <CountryList
          onChange={(value) => handleSetCountry(value)}
          data={countryData}
          select={record?.country?.alpha_2}
        />
      </ModalContent>
    </>
  );
};

export default CustomerProfile;
