import type { ISplash } from '@/utils/platform-utils';
import React from 'react';
import ImageView from './image-view';

const views: any = {
  IMAGE: ImageView,
};

export interface ICreateViewParams {
  data: ISplash;
  logo: string;
  splashData: ISplash[];
  selectedIndex: number;
  handleIndexChange: (index: number) => void;
  handleImageChange: (index: number, xCoordinate: number) => void;
}

export const createView = (params: ICreateViewParams) => {
  const { data } = params;

  const { id, type } = data;

  const viewProps = {
    key: id,
    ...params,
  };

  let viewNode: React.ReactNode | null = null;
  if (views[type]) {
    const ViewItem = views[type];
    viewNode = <ViewItem {...viewProps} />;
  }

  return viewNode;
};
