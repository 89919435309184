import api from "@/libs/api"
import Config from "@/libs/config"
import { IPermissionsData } from "@/types/permissions-type"

export const queryPermissions = async (businessID: string): Promise<IPermissionsData | undefined> => {

  return await api.get(`${Config.urls.gatewayUrl}/api/galaxy/accounts/permissions`, {
    headers: {
      'x-p-business-id': businessID,
    }
  }).then(({ data }) => {
    return data;
  }).catch(() => {
    return undefined;
  });
    
}