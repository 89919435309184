import Skeleton from '@/components/skeleton';

const AccountPanelSkeleton = () => {
  return (
    <>
      <div className="h-10">
        <Skeleton
          row={1}
          skeletonItemClass="h-10 rounded-[50px]"
          className="h-10"
        />
      </div>
      <div className="rounded border-border-color mt-5 border">
        <div className="rounded-tl rounded-tr h-10 border-border-color bg-disabled-color border-b pl-4 pr-4 pt-2 pb-2">
          <div className="h-6 rounded-[50px]">
            <Skeleton row={1} skeletonItemClass="h-6 rounded-2xl" />
          </div>
        </div>
        <div className="px-4 py-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mb-2 md:mb-6 ">
            <div className="flex items-center justify-center">
              <div
                className={`skeleton_avatar  bg-gradient-to-r from-system-background-color to-system-background-color/0  w-16 h-16 rounded-full`}
              />
              <div className="h-6 rounded-[50px] flex-1">
                <Skeleton row={1} skeletonItemClass="h-6 rounded-2xl" />
              </div>
            </div>
            <div className="flex flex-col ml-0 sm:ml-6 mt-2 sm:mt-0">
              <div className="h-4 rounded-[50px]">
                <Skeleton row={1} skeletonItemClass="h-4 rounded-2xl" />
              </div>
              <div className="h-6 rounded-[50px] mt-3">
                <Skeleton row={1} skeletonItemClass="h-6 rounded-2xl" />
              </div>
            </div>
            <div className="flex flex-col ml-0 sm:ml-6 mt-2 sm:mt-0">
              <div className="h-4 rounded-[50px]">
                <Skeleton row={1} skeletonItemClass="h-4 rounded-2xl" />
              </div>
              <div className="h-6 rounded-[50px] mt-3">
                <Skeleton row={1} skeletonItemClass="h-6 rounded-2xl" />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
            <div className="flex flex-col">
              <div className="h-4 rounded-[50px]">
                <Skeleton row={1} skeletonItemClass="h-4 rounded-2xl" />
              </div>
              <div className="h-6 rounded-[50px] mt-3">
                <Skeleton row={1} skeletonItemClass="h-6 rounded-2xl" />
              </div>
            </div>
            <div className="flex flex-col ml-0 sm:ml-6 mt-2 sm:mt-0">
              <div className="h-4 rounded-[50px]">
                <Skeleton row={1} skeletonItemClass="h-4 rounded-2xl" />
              </div>
              <div className="h-6 rounded-[50px] mt-3">
                <Skeleton row={1} skeletonItemClass="h-6 rounded-2xl" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rounded border-border-color mt-6 border">
        <div className="rounded-tl rounded-tr h-10 border-border-color bg-disabled-color border-b pl-4 pr-4 pt-2 pb-2">
          <div className="h-6 rounded-[50px]">
            <Skeleton row={1} skeletonItemClass="h-6 rounded-2xl" />
          </div>
        </div>
        <div className="px-4 py-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
            <div className="flex flex-col">
              <div className="h-4 rounded-[50px]">
                <Skeleton row={1} skeletonItemClass="h-4 rounded-2xl" />
              </div>
              <div className="h-6 rounded-[50px] mt-3">
                <Skeleton row={1} skeletonItemClass="h-6 rounded-2xl" />
              </div>
            </div>
            <div className="flex flex-col ml-0 sm:ml-6 mt-2 sm:mt-0">
              <div className="h-4 rounded-[50px]">
                <Skeleton row={1} skeletonItemClass="h-4 rounded-2xl" />
              </div>
              <div className="h-6 rounded-[50px] mt-3">
                <Skeleton row={1} skeletonItemClass="h-6 rounded-2xl" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rounded border-border-color mt-6 border">
        <div className="rounded-tl rounded-tr h-10 bg-disabled-color pl-4 pr-4 pt-2 pb-2">
          <div className="h-6 rounded-[50px]">
            <Skeleton row={1} skeletonItemClass="h-6 rounded-2xl" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountPanelSkeleton;
