import LanguageSelector from '@/i18n/language-selector';
import Splash from './splash';
import { getPlatformConfig } from '@/App';

interface IAccountWrapperProps {
  children?: React.ReactNode;
  className?: string;
}

const AccountWrapper: React.FC<IAccountWrapperProps> = (props) => {
  const { children, className } = props;

  const platformData = getPlatformConfig();
  
  const { platform, splashData = [] } = platformData || {};
  const { dashboardLogo = '', splashEnabled = true } = platform || {};


  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 flex bg-disabled-color">
      <div className="fixed right-8 top-6">
        <LanguageSelector />
      </div>
      {splashEnabled && splashData.length > 0 && (
        <div className="w-1/2 hidden lg:block">
          <Splash
            data={splashData}
            width="50%"
            className="pl-12"
            logo={dashboardLogo}
          />
        </div>
      )}
      <div className="flex flex-1 flex-col w-1/2 bg-color-text-6 md:bg-inherit">
        <div className={`${className} flex-1 flex justify-center items-center`}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default AccountWrapper;
