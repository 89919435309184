import Button from '@/components/common/button';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@/components/svg/warning-icon';
import { Link } from 'react-router-dom';
import SignOutIcon from '@/components/svg/sign-out-icon';
import { getPlatformConfig } from '@/App';

const SuspensionZone = () => {

  const { t } = useTranslation('signin');

  const platformData = getPlatformConfig();

  const { platform = {} } = platformData || {};
  
  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col">
      <div className="h-16 flex pl-6 pr-8 py-2 items-center justify-between bg-secondary-background-color">
        <div className="flex items-center">
          <div className="overflow-hidden text-center bg-no-repeat">
            <div className="flex justify-center w-full py-4 h-full">
              <div
                dangerouslySetInnerHTML={{
                  __html: platform.dashboardLogo || '',
                }}
              />
            </div>
          </div>
          <span className="w-px h-6 mx-4 bg-color-text-5"></span>
          <div className="text-color-text-6">{t('signin:suspension_zone')}</div>
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center">
        <div className="flex flex-col items-center text-center text-color-text-2">
          <div className="bg-error-color/10 rounded-full p-6">
            <WarningIcon className="text-error-color" />
          </div>
          <div className="text-xl font-bold text-color-text-1 mt-10 mb-2">
            {t('signin:suspended_user')}
          </div>
          <div>{t('signin:account_suspended')} </div>
          <div>{t('signin:contact_us')}</div>
          <Link to="/account/signin">
            <Button className="mt-8" iconInstance={<SignOutIcon />}>
              {t('signin:sign_out')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SuspensionZone;
