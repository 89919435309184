import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ExitIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle
          cx="12"
          cy="12"
          r="8.5"
          fill="#0094FF"
          fillOpacity="0.1"
          stroke="#0094FF"
        />
        <circle cx="12" cy="12" r="4" fill="#0094FF" />
      </svg>
    </div>
  );
};

export default ExitIcon;
