import type { ICardDetailsProps } from '../type';
import type { IObject } from '@/types/common-types';
import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import Label from '@/components/common/label';
import DetailsItem from '@/pages/account-balance/components/details-item-component';
import {
  getCardTranferStatusLabel,
  handleSymbolMount,
  handleUtcDate,
} from '@/utils/account-balance-untils';
import { getCardDetails } from '@/data/account-balance';
import Skeleton from '@/components/skeleton';
import ScrollNum from '@/components/common/scroll-number';
import CopyIcon from '@/components/svg/copy-icon';
import CardTransactionIcon from '@/pages/account-balance/account-balances-icons/card-transaction-icon';
import { useTranslation } from 'react-i18next';

let tipsTimeout: any;

const CardDetailsPage = (props: ICardDetailsProps) => {
  const { t } = useTranslation("account_balance");
  const [record, setRecord] = useState<IObject>({});
  const [copySuccess, setCopySuccess] = useState(false);
  const [cardLoading, setCardInitLoaing] = useState(false);
  const [initLoading, setInitLoaing] = useState(false);
  const { businessId, recordId, disabled = true } = props;
  const {
    status = '',
    id,
    error_message = '',
    transaction_currency = '',
    transaction_amount = '',
    merchant_name = '',
    card = {},
    p_created_at = '',
    issuing_category_group = {},
    declined_type,
    settled_at,
  } = record || {};

  useEffect(() => {
    if (copySuccess) {
      clearTimeout(tipsTimeout);
      tipsTimeout = setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }
  }, [copySuccess]);

  const { card_holders: cardHolders = [], last_4_digits = '' } = card;
  const { name = '' } = issuing_category_group;
  const { full_name: cardFullName = '' } = cardHolders[0] || {};

  useEffect(() => {
    getReacord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, recordId]);

  const { status: analysisStatus, component } =
    getCardTranferStatusLabel(status);
  const showErrorCode = ['declined', 'reversed', 'suspended'].includes(
    analysisStatus
  );

  const getReacord = async () => {
    setInitLoaing(true);
    setCardInitLoaing(true);
    const data = await getCardDetails({
      recordId,
      pBusinessId: businessId,
    });
    if (data) {
      setRecord(data);
      setInitLoaing(false);
    }
    setCardInitLoaing(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(id).then(() => {
      setCopySuccess(true);
    });
  };

  const handleAmountStyle = () => {
    let style = 'text-color-text-1 space-x-1';
    if (showErrorCode) {
      style = 'line-through text-color-text-2';
    }
    return style;
  };

  return (
    <Transition.Root show={true} appear={true}>
      <Transition.Child
        className="rounded overflow-hidden shadow-card-drop bg-primary-background-color p-4 md:p-6 h-fit"
        enter="duration-700 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-10 opacity-0"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="items-center lg:flex">
          <div>
            <div className="flex flex-col md:flex-row whitespace-nowrap">
              <CardTransactionIcon size={48} />
            </div>
          </div>
          {initLoading && (
            <Skeleton
              row={2}
              width={['50%', '50%']}
              skeletonItemClass={`h-4 rounded-2xl mb-4`}
            />
          )}
          {!initLoading && (
            <div className="flex">
              <div className="flex flex-col justify-between ml-4">
                <div className="flex items-center space-x-1 text-3xl font-bold">
                  <span className={`${handleAmountStyle()}`}>
                    <ScrollNum
                      value={transaction_amount}
                      size={36}
                      boxClass={'h-9'}
                    />
                  </span>
                  <span className="text-color-text-1 font-medium">{`${transaction_currency}`}</span>
                </div>
                <div className="flex space-x-1 items-center">
                  <span className="text-color-text-4 text-sm">
                    {t('account_balance:transaction_amount')}
                  </span>
                </div>
              </div>
              <div className="mx-10 border-r border-border-color" />
              <div className="flex flex-col justify-between">
                <div className="text-color-text-1 font-medium">
                  {merchant_name || '--'}
                </div>
                <div className="text-color-text-4 text-sm">
                  {t('account_balance:merchant_name')}
                </div>
              </div>
            </div>
          )}
        </div>
      </Transition.Child>

      <div className="flex w-full space-x-6 mt-6">
        <Transition.Child
          className="flex-1"
          enter="duration-350 transform transition ease-in-out"
          enterFrom="-translate-x-1 translate-y-8 opacity-60"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="duration-500 transform transition"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div
            className={`w-full rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit`}
          >
            <div className="font-bold text-xl text-color-text-1 flex items-center">
              {t('account_balance:card_transaction')}
            </div>
            <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
                <DetailsItem
                  title={t('account_balance:transaction_amount')}
                  content={`${handleSymbolMount(
                    transaction_amount,
                    false,
                    '-'
                  )} ${transaction_currency}`}
                  loading={cardLoading}
                />
                <DetailsItem
                  title={t('account_balance:status')}
                  content={component}
                  loading={initLoading}
                />
                <div className="w-full col-auto">
                  <Label disabled={disabled} value={'ID'}></Label>
                  {initLoading && (
                    <Skeleton
                      row={2}
                      width={['50%', '50%']}
                      skeletonItemClass={`h-4 rounded-2xl mb-4`}
                    />
                  )}
                  {!initLoading && (
                    <div className="py-2 text-color-text-1 font-medium pr-6 flex items-center space-x-2">
                      <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-color-text-1 text-sm font-medium">
                        {id}
                      </span>
                      <span
                        className="cursor-pointer group"
                        onClick={handleCopy}
                      >
                        <CopyIcon className="text-primary-color" />
                      </span>
                      <span
                        className={`text-primary-color text-xs cursor-pointer ${
                          copySuccess ? 'visible' : 'invisible'
                        }`}
                      >
                        {t('account_balance:copied')}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
                <DetailsItem
                  title={t('account_balance:type')}
                  content={t('account_balance:card_transaction')}
                  loading={cardLoading}
                />
                <DetailsItem
                  title={t('account_balance:cardholder_name')}
                  content={cardFullName}
                  loading={cardLoading}
                />
                <DetailsItem
                  title={t('account_balance:card_ending_title')}
                  content={`**${last_4_digits}`}
                  loading={initLoading}
                />
                <DetailsItem
                  title={t('account_balance:merchant_name')}
                  content={merchant_name}
                  loading={initLoading}
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
                <DetailsItem
                  title={t('account_balance:category')}
                  content={name}
                  loading={initLoading}
                />
                <DetailsItem
                  title={t('account_balance:transaction_date')}
                  content={handleUtcDate(p_created_at)}
                  loading={initLoading}
                />
                {!showErrorCode &&
                  settled_at &&
                  analysisStatus === 'settled' && (
                    <DetailsItem
                      title={t('account_balance:settled_on')}
                      content={handleUtcDate(settled_at, 'MMM D, YYYY')}
                      loading={initLoading}
                    />
                  )}
                {showErrorCode && (
                  <DetailsItem
                    title={t('account_balance:error_reason')}
                    content={
                      <>
                        {declined_type && error_message ? (
                          <>
                            <span className="text-ellipsis overflow-hidden text-[#FC2E01]">
                              {declined_type}{' '}
                            </span>
                            <span className="text-ellipsis overflow-hidden">
                              {error_message}
                            </span>
                          </>
                        ) : (
                          '--'
                        )}
                      </>
                    }
                    loading={initLoading}
                  />
                )}
              </div>

              {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
                <DetailsItem
                  title="Sell Amount"
                  content={
                    <div className="flex space-x-1.5 items-center">
                      <span>{handleMount(fx_sell_amount)}</span>
                      <span>{fx_sell_currency}</span>
                      <span>{getCurrencyIcon(fx_sell_currency)}</span>
                    </div>
                  }
                />
                <DetailsItem
                  title="Buy Amount"
                  // content={`${handleMount(fx_buy_amount)} ${fx_buy_currency} ${getCurrencyIcon(fx_buy_currency)}`}
                  content={
                    <div className="flex space-x-1.5 items-center">
                      <span>{handleMount(fx_buy_amount)}</span>
                      <span>{fx_buy_currency}</span>
                      <span>{getCurrencyIcon(fx_buy_currency)}</span>
                    </div>
                  }
                />
                <DetailsItem
                  title="Exchange Rate"
                  content={`${fx_exchange_rate} ${fx_sell_currency} to ${fx_buy_currency}`}
                />
                <DetailsItem
                  title="Exchange Date"
                  content={handleUtcDate(ledger_valid_from)}
                />
              </div> */}
            </>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};

export default CardDetailsPage;
