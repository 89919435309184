import React from 'react';
import type { IIconProps } from '@/types/common-types';

const PlatformTransferDetailsIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 32 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.5556 14H6L10.4444 10M10.4444 18H26L21.5556 22"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default PlatformTransferDetailsIcon;
