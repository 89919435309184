import React from 'react';
import type { IIconProps } from '@/types/common-types';

const EmailIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div
      className={`inline-flex items-center justify-center ${className}`}
      onClick={onClick}
    >
      <svg
        width="120"
        height="120"
        viewBox="0 0 120 114"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="email">
          <path
            id="Shape"
            d="M120 43.3275L60 86.5487L0 43.3275L60 0.106445L120 43.3275Z"
            fill="#1890FF"
          />
          <path
            id="Shape_2"
            d="M16.3928 7.69727H103.607V113.999H16.3928V7.69727Z"
            fill="#CDE7FF"
          />
          <path
            id="check-circle - anticon"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.5176 14.4629C65.9004 15.2598 66.9932 16.3496 67.7959 17.7324C68.5986 19.1152 69 20.6211 69 22.25C69 23.8789 68.5986 25.3848 67.7959 26.7676C66.9932 28.1504 65.9004 29.2432 64.5176 30.0459C63.1348 30.8486 61.6289 31.25 60 31.25C58.3711 31.25 56.8652 30.8486 55.4824 30.0459C54.0996 29.2432 53.0068 28.1504 52.2041 26.7676C51.4014 25.3848 51 23.8789 51 22.25C51 20.6211 51.4014 19.1152 52.2041 17.7324C53.0068 16.3496 54.0996 15.2568 55.4824 14.4541C56.8652 13.6514 58.3711 13.25 60 13.25C61.6289 13.25 63.1348 13.6543 64.5176 14.4629Z"
            fill="#7ED321"
          />
          <path
            id="Path"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.5703 19.5078C64.6992 19.3906 64.7637 19.2441 64.7637 19.0684C64.7637 18.8926 64.7021 18.7432 64.5791 18.6201C64.4561 18.4971 64.3066 18.4355 64.1309 18.4355C63.9551 18.4355 63.8027 18.5 63.6738 18.6289L57.9258 24.3945L56.4141 21.7754C56.332 21.623 56.2061 21.5234 56.0361 21.4766C55.8662 21.4297 55.7051 21.4502 55.5527 21.5381C55.4004 21.626 55.3008 21.752 55.2539 21.916C55.207 22.0801 55.2305 22.2383 55.3242 22.3906L57.2402 25.748C57.3574 25.959 57.5391 26.0645 57.7852 26.0645C57.9023 26.0645 58.0078 26.0352 58.1016 25.9766C58.1602 25.9414 58.207 25.9062 58.2422 25.8711L58.2598 25.8535L64.5703 19.5078Z"
            fill="white"
          />
          <path
            id="Shape_3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M94.1117 36.9521H25.9019V41.897H94.1117V36.9521ZM94.1117 45.3317H25.9019V50.2767H94.1117V45.3317ZM25.9019 53.7114H94.1117V58.6563H25.9019V53.7114ZM94.1117 62.091H25.9019V67.0359H94.1117V62.091Z"
            fill="white"
          />
          <path
            id="Shape_4"
            d="M0 113.999H120V43.3271L60 86.5483L0 43.3271V113.999Z"
            fill="#1890FF"
          />
          <path
            id="Shape_5"
            d="M0 113.999H120L70.942 78.6631H49.058L0 113.999Z"
            fill="#5BB0FF"
          />
        </g>
      </svg>
    </div>
  );
};

export default EmailIcon;
