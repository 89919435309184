import AntdTips from '@/components/common/antd/tips';
import Skeleton from '@/components/skeleton';
import type { IObject } from '@/types/common-types';

interface IFieldItemParam {
  label: React.ReactNode;
  value: React.ReactNode;
  loadingData?: boolean;
  className?: string;
  tips?: string;
  tipStype?: IObject;
}

const FieldItem: React.FC<IFieldItemParam> = (props) => {
  const {
    label,
    value,
    tips,
    className = '',
    tipStype = {},
    loadingData = false,
  } = props;
  return (
    <div className={`font-medium text-sm ${className}`}>
      <div className="flex items-center">
        {loadingData && (
          <Skeleton
            row={1}
            width={['50%']}
            skeletonItemClass={`h-4 rounded-2xl`}
          />
        )}
        {!loadingData && (
          <>
            <span className="text-color-text-2 mr-1">{label}</span>
            {tips && <AntdTips overlayInnerStyle={tipStype} content={tips} />}
          </>
        )}
      </div>
      {loadingData && (
        <Skeleton
          row={1}
          width={['100%']}
          className="mt-3"
          skeletonItemClass={`h-6 rounded-2xl`}
        />
      )}
      {!loadingData && (
        <div className="text-color-text-1 py-[10px] overflow-hidden">
          {value || '--'}
        </div>
      )}
    </div>
  );
};

export default FieldItem;
