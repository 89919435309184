import { useEffect, useRef, useState } from 'react';

interface IQrCodeProps {
  value: string;
  size?: number;
  className?: string;
  image?: string;
}

const QrCode: React.FC<IQrCodeProps> = (props) => {
  const { value = ' ', className = '', size = 240, image } = props;

  const [qrCode, setQrCode] = useState<any>(null);

  const ref = useRef<any>(null);
  const triggerRef = useRef(false);

  useEffect(() => {
    if (!triggerRef.current) {
      const QRCodeStyling = require('qr-code-styling');
      const qrCodeNode = new QRCodeStyling({
        width: size,
        height: size,
        image: image,
        dotsOptions: {
          color: '#000000',
          type: 'dots',
        },
        imageOptions: {
          crossOrigin: 'use-credentials',
          imageSize: 0.4,
          margin: 2,
        },
        cornersSquareOptions: {
          type: 'dot',
        },
        cornersDotOptions: {
          type: 'dot',
        },
        type: 'svg',
        data: value || ' ',
      });

      triggerRef.current = true;
      qrCodeNode.append(ref.current);
      setQrCode(qrCodeNode);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (qrCode) {
      qrCode.update({
        data: value,
      });
    }
    // eslint-disable-next-line
  }, [value]);

  return <div className={className} ref={ref} />;
};

export default QrCode;
