import { useEffect, useState } from 'react';
import type { IBankData, IStoreOverviewFormViewProps } from './type';
import Label from '@/components/common/label';
import Select from '@/components/common/select';
import { Controller } from 'react-hook-form';
import AntdSwitch from '@/components/common/antd/antd-switch';
import { Transition } from '@headlessui/react';
import BankAcountList from './bank-account-list';
import { getCookie } from '@/utils/index';
import Config from '@/libs/config';
import { Constants } from '@/constants';
import api from '@/libs/api';
import dayjs from 'dayjs';
import TimePicker from '@/components/common/time-picker/time-picker';
import { useTranslation } from 'react-i18next';
import ModalContent from '@/components/common/modal/modal-content';
import PercentageInput from '@/components/common/percentage-input/percentage-input';
import Button from '@/components/common/button';
import WarningIcon from '@/components/svg/warning-side-icon';
import { useNavigate } from 'react-router-dom';


const StoreSettingAdvancedSettings = (props: IStoreOverviewFormViewProps) => {
  const { disabled, record, useFormReturn, businessID = '' } = props;
  const { t } = useTranslation(["settings", "business_account_settings", "account_balance"]);

  const [showBankAcountModal, setBankAcountModal] = useState(false);
  const [autoDisbursement, setAutoDisbursement] = useState(
    record.auto_disbursement_status === 'Active' ? true : false
  );
  // const [verification, set3dsVerification] = useState(record.threeds_enabled);
  const [surchargeFee, setSurchargeFee] = useState(record.surcharge_fee);
  // const [enabledOctopus, setEnabledOctopus] = useState(false);
  const [invoiceTips, setInvoiceTips] = useState(
    record.invoice_link_tips_enabled
  );
  const [bankList, setBankList] = useState<IBankData[]>([]);
  const [bankCode, setBankCode] = useState<string>(record.bankCode);
  const [bankName, setBankName] = useState<string>(record.bankName);
  const [bankID, setBankID] = useState<string>(record.payee_id || '');
  const [showModal, setShowModal] = useState<boolean>(false);
  const prefix = Config.staticPath;

  const { reset, getValues } = useFormReturn;
  const values = getValues();
  const navigate = useNavigate();
  const bankNumber = (number: string) => {
    if (number.trim().length < 3) {
      return `*** ****** ${number}`;
    }
    return `*** ****** ${number.substring(number.length - 3)}`;
  };

  useEffect(() => {
    getBankAccountList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInvoiceTips(values.invoice_link_tips_enabled);
    setSurchargeFee(values.surcharge_fee);
    setAutoDisbursement(
      values.auto_disbursement_status === 'Active' ? true : false
    );
    // setEnabledOctopus(values.octopus_enabled);
    setBankID(values.payee_id || bankID);
    setBankCode(values.bankCode || bankCode);
    setBankName(values.bankName || bankName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const getBankAccountList = async () => {
    const token = getCookie(Constants.TOKEN) || '';
    const bankAccountList: IBankData[] = [];
    try {
      const result: any = await api.get(
        `${Config.urls.gatewayUrl}/api/treasury-services/api/payees?limit=1000&offset=0&related_company_payee=true&bank_filter=false&verified_at=true&business_legal_name_filter=false&currency=HKD`,
        {
          headers: {
            'x-user-access-token': token,
            'x-p-business-id': businessID || '',
          },
        }
      )
      if (result.code === 200 && Array.isArray(result.data)) {
        const dataList: any[] = result.data || [];
        dataList.forEach((item) => {
          const payoutAccounts = item.payout_accounts;
          if (payoutAccounts.id) {
            bankAccountList.push({
              id: item.id,
              bankName: payoutAccounts.banks?.bank_name || '',
              bankCode: payoutAccounts.banks?.bank_code || '',
              bankId: payoutAccounts.identifier_1_value || '',
              accountHolderName: payoutAccounts.identifier_2_value || '',
              accountNumber: payoutAccounts.identifier_3_value || '',
            });
          }
        });
        setBankList(bankAccountList);
      }
    } catch (e) { }
  };

  const handleSetBankAccount = (value: IBankData) => {
    const values = getValues();
    setBankID(value.id);
    setBankCode(value.accountNumber);
    setBankName(value.bankName);
    reset({
      ...values,
      payee_id: value.id,
      bankCode: value.accountNumber,
      bankName: value.bankName,
    });
    setBankAcountModal(false);
  };

  const getCurrency = () => {
    let alpha2Code = 'hk';
    if (record && record.default_currency?.currency_code) {
      const currencyCode = record.default_currency?.currency_code;
      if (currencyCode.length > 2) {
        alpha2Code = currencyCode!.substring(0, 2).toLowerCase();
      } else {
        alpha2Code = currencyCode.toLowerCase();
      }
    }
    return alpha2Code;
  };

  const handleNewBank = () => {
    navigate(
      `/businesses/${businessID}/settings/related-company-accounts/new`
    );
  };

  return (
    <>
      <div className="w-full mt-6">
        <Transition
          show={true}
          appear={true}
          className="z-80 w-full flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit"
          enter="duration-700 transform transition ease-in-out"
          enterFrom="-translate-x-1 translate-y-10 opacity-30"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="duration-500 transform transition"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="font-semibold text-lg text-color-text-1 mb-4">
            {t('settings:current_account_settings')}
          </div>
          <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
          <div className="gap-x-6 grid grid-cols-1 sm:grid-cols-2">
            <div>
              <div className="w-full tems-center flex justify-between">
                <div className="w-full mb-0 text-sm font-medium text-color-text-1">
                  {t('settings:auto_disbursement')}
                </div>
                <Controller
                  name={'auto_disbursement_status'}
                  defaultValue={record.auto_disbursement_status}
                  control={useFormReturn.control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <AntdSwitch
                      className="mb-0 w-auto text-color-text-5"
                      checked={autoDisbursement}
                      value={autoDisbursement ? 'On' : 'Off'}
                      disabled={disabled}
                      onChange={
                        record.isChainOperatingEntity
                          ? undefined
                          : (value) => {
                            setAutoDisbursement(!autoDisbursement);
                            onChange(
                              autoDisbursement ? 'Active' : 'Inactive'
                            );
                            const values = getValues();
                            reset({
                              ...values,
                              auto_disbursement_status: !autoDisbursement
                                ? 'Active'
                                : 'Inactive',
                            });
                          }
                      }
                      onClick={
                        record.isChainOperatingEntity
                          ? () => {
                            setShowModal(true);
                          }
                          : undefined
                      }
                    />
                  )}
                />
              </div>
              {autoDisbursement && (
                <>
                  <div className="mt-6">
                    <Label
                      className="mb-1.5 text-sm font-medium"
                      disabled={disabled}
                      value={t('settings:auto_disbursement_cycle')}
                      required={true}
                    ></Label>
                    <div className="bg-disabled-color border-border-color border rounded pl-3">
                      <Controller
                        name={'auto_disbursement_cycle'}
                        defaultValue={
                          record.auto_disbursement_cycle || 'Every Day'
                        }
                        control={useFormReturn.control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={[
                              {
                                label: t(
                                  'settings:auto_disbursement_every_day'
                                ),
                                value: 'Every Day',
                              },
                            ]}
                            multiple={false}
                            onChange={onChange}
                            value={value}
                            disabled={true}
                            className="text-color-text-1"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="mt-6">
                    <Label
                      className="mb-1.5 text-sm font-medium text-color-text-2"
                      disabled={disabled}
                      value={t('settings:receiving_bank_account')}
                      required={true}
                    ></Label>
                    <Controller
                      name="payee_id"
                      control={useFormReturn.control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <div
                          className={`${disabled || record.isChainOperatingEntity
                            ? 'border border-border-color bg-disabled-color'
                            : 'border border-border-color hover:border-primary-color cursor-pointer'
                            } flex rounded relative items-center justify-center  ${!bankID ? 'border-error-color' : ''
                            }`}
                          onClick={() =>
                            !disabled && !record.isChainOperatingEntity
                              ? setBankAcountModal(true)
                              : undefined
                          }
                        >
                          <div
                            className={`rounded pl-3 text-sm text-color-text-1 font-medium items-center relative w-full ${bankName ? 'py-2' : 'h-10'
                              }`}
                          >
                            {bankCode && (
                              <>
                                <div className="text-sm text-color-text-1 font-medium">
                                  {bankName}
                                </div>
                                <div className="text-sm text-color-text-1 font-medium">
                                  {bankCode ? bankNumber(bankCode) : ''}
                                </div>
                              </>
                            )}
                            {!disabled && !bankCode && (
                              <div className="text-sm text-color-text-5 font-medium flex h-10 items-center">
                                {t('settings:please_choose_bank')}
                              </div>
                            )}
                          </div>
                          <span
                            className={`text-icon-color px-2 right-2 pb-1.5 absolute ${disabled ? 'hidden' : 'block'
                              }`}
                          >
                            ...
                          </span>
                        </div>
                      )}
                    />
                    {!bankID && (
                      <div className="mt-1 text-sm text-error-color">
                        {t('settings:required_tips')}
                      </div>
                    )}
                    <p className="text-sm text-color-text-2 mt-2">
                      {t('settings:auto_disbursement_tip')}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-2 md:gap-y-6 mb-2 md:mb-6">
            <div className="w-full">
              <div className="text-sm font-medium text-color-text-1 mb-1.5">
                {t('settings:default_currency')}
              </div>
              <div className="flex border-border-color border bg-disabled-color rounded h-10 pl-3 items-center">
                <img
                  // layout="intrinsic"
                  width="16px"
                  height="16px"
                  src={`${prefix}/images/country-flag/${getCurrency()}.svg`}
                  alt=""
                />
                <div className="text-sm text-color-text-1 ml-1.5 flex items-center font-medium">
                  {record.default_currency
                    ? record.default_currency.currency_code
                    : ''}
                </div>
              </div>
              <p className="text-sm text-color-text-2 mt-2">
                {t('settings:default_currency_tip')}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-2">
            <div className="w-full">
              <div className="text-sm font-medium text-color-text-1 mb-1.5">
                {t('settings:timezone')}
              </div>
              <div className="border-border-color border bg-disabled-color rounded h-10 pl-3 text-sm text-color-text-1 flex items-center font-medium">
                {record.timezone}
              </div>
              <p className="text-sm text-color-text-2 mt-2">
                {t('settings:timezone_tip')}
              </p>
            </div>
          </div>
        </Transition>
        <Transition
          show={true}
          appear={true}
          className="z-70 w-full flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit"
          enter="duration-350 transform transition ease-in-out"
          enterFrom="-translate-x-1 translate-y-8 opacity-60"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="duration-500 transform transition"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="font-semibold text-lg text-color-text-1 mb-4">
            {t('settings:merchant_account_settings')}
          </div>
          <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
          <div className="gap-x-6 grid grid-cols-1 sm:grid-cols-2">
            <div className="w-full">
              <div className="text-sm font-medium text-color-text-1 mb-1.5">
                {t('settings:dayend_time_everyday')}
              </div>
              <Controller
                name={'day_end_at'}
                defaultValue={record.day_end_at ? record.day_end_at : ''}
                control={useFormReturn.control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TimePicker
                    defaultValue={
                      record.day_end_at
                        ? `${dayjs(new Date()).format('YYYY-MM-DD')} ${record.day_end_at
                        }`
                        : dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
                    }
                    disabled={disabled}
                    onChange={onChange}
                    value={value}
                    isSpecial={true}
                  />
                )}
              />
            </div>
          </div>
        </Transition>
        <div
          className={`w-full flex-1 rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit`}
        >
          <div className="font-semibold text-lg text-color-text-1 mb-4">
            {t('settings:online_payment')}
          </div>
          <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
          <div className="gap-x-6 grid grid-cols-1 sm:grid-cols-2">
            <div>
              <div className="w-full tems-center flex justify-between">
                <div className="w-full mb-0 text-sm font-medium text-color-text-1">
                  {t('settings:enable_tips')}
                </div>
                <Controller
                  name={'invoice_link_tips_enabled'}
                  defaultValue={record.invoice_link_tips_enabled}
                  control={useFormReturn.control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <AntdSwitch
                      className="mb-0 w-auto text-color-text-5"
                      value={invoiceTips ? 'On' : 'Off'}
                      checked={invoiceTips}
                      disabled={disabled}
                      onChange={(value) => {
                        setInvoiceTips(!invoiceTips);
                        onChange(invoiceTips);
                        const values = getValues();
                        reset({
                          ...values,
                          invoice_link_tips_enabled: !invoiceTips,
                        });
                      }}
                    />
                  )}
                />
              </div>
              <p className="text-sm text-color-text-2 mt-2">
                {t('settings:add_tips_feature')}
              </p>
            </div>
          </div>
          {/* <div className="gap-x-6 grid grid-cols-1 sm:grid-cols-2">
            <div>
              <div className="w-full tems-center flex justify-between mt-6">
                <div className="w-full mb-0 text-sm font-medium text-color-text-1">
                  {t('settings:enable_3ds_verification')}
                </div>
                <Controller
                  name={'threeds_enabled'}
                  defaultValue={record.threeds_enabled}
                  control={useFormReturn.control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <AntdSwitch
                      className="w-auto text-color-text-5"
                      value={verification ? 'On' : 'Off'}
                      checked={verification}
                      disabled={disabled}
                      onChange={(value) => {
                        set3dsVerification(!verification);
                        onChange(verification);
                        const values = getValues();
                        reset({
                          ...values,
                          threeds_enabled: !verification,
                        });
                      }}
                    />
                  )}
                />
              </div>
              {verification && (
                <div className="mt-6">
                  <Label
                    className="mb-1.5 text-sm font-medium text-color-text-2"
                    disabled={disabled}
                    value={t('settings:minimum_amount')}
                    required={true}
                  ></Label>
                  {disabled && (
                    <div className="border-border-color border bg-disabled-color rounded h-10 pl-3 text-sm text-color-text-1 flex items-center">
                      {record.threeds_threshold_amount}
                    </div>
                  )}
                  {!disabled && (
                    <Controller
                      name="threeds_threshold_amount"
                      defaultValue={record.threeds_threshold_amount}
                      control={useFormReturn.control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Input
                          onChange={(data) => {
                            if (data && Number(data) - 0 < 0) {
                              data = '0';
                            }
                            onChange(data);
                          }}
                          value={value}
                          error={error}
                          disabled={disabled}
                          type="number"
                        />
                      )}
                    />
                  )}
                </div>
              )}
            </div>
          </div> */}

          <div className="gap-x-6 grid grid-cols-1 sm:grid-cols-2">
            <div>
              <div className="w-full tems-center flex justify-between mt-6">
                <div className="w-full mb-0 text-sm font-medium text-color-text-1">
                  {t('settings:surcharge_fee')}
                </div>
                <Controller
                  name={'surcharge_fee'}
                  defaultValue={record.surcharge_fee}
                  control={useFormReturn.control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <AntdSwitch
                      className="mb-0 w-auto text-color-text-5"
                      value={surchargeFee ? 'On' : 'Off'}
                      checked={surchargeFee}
                      disabled={disabled}
                      onChange={(value) => {
                        setSurchargeFee(!surchargeFee);
                        onChange(surchargeFee);
                        const values = getValues();
                        reset({
                          ...values,
                          surcharge_fee: !surchargeFee,
                        });
                      }}
                    />
                  )}
                />
              </div>
              {surchargeFee && (
                <div className="mt-6">
                  <Label
                    className="mb-1.5 text-sm font-medium text-color-text-2"
                    disabled={disabled}
                    value={t('settings:applied_surcharge_fee')}
                    required={true}
                  ></Label>
                  <Controller
                    name="percentage_surcharge_fee"
                    defaultValue={record.percentage_surcharge_fee}
                    control={useFormReturn.control}
                    rules={{
                      required: t('settings:required_tips'),
                      validate: (value) => {
                        if (value - 5 > 0) {
                          return t('settings:surcharge_fee_not_more');
                        }
                        if (value === 0) {
                          return t('settings:set_surcharge_fee');
                        }
                        return '';
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <PercentageInput
                        onChange={(data) => {
                          onChange(data);
                        }}
                        value={value}
                        error={error}
                        disabled={disabled || record.fixed_surcharge_fee_enabled}
                      />
                    )}
                  />
                  <p className="text-sm text-color-text-2 mt-2">
                    {t('settings:surcharge_fee_tip')}
                  </p>
                </div>
              )}
            </div>
          </div>

          {/* <div className="gap-x-6 grid grid-cols-1 sm:grid-cols-2">
            <div className="w-full tems-center flex justify-between mt-6">
              <div className="w-full mb-0 text-sm font-medium text-color-text-1">
                {t('settings:enable_online_octopus')}
              </div>
              <Controller
                name={'octopus_enabled'}
                // defaultValue={record.invoice_link_tips_enabled}
                control={useFormReturn.control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <AntdSwitch
                    className="mb-0 w-auto text-color-text-5"
                    value={enabledOctopus ? 'On' : 'Off'}
                    checked={enabledOctopus}
                    disabled={disabled}
                    onChange={(value) => {
                      setEnabledOctopus(!enabledOctopus);
                      onChange(enabledOctopus);
                      const values = getValues();
                      reset({
                        ...values,
                        octopus_enabled: !enabledOctopus,
                      });
                    }}
                  />
                )}
              />
            </div>
          </div> */}
        </div>
      </div>
      <ModalContent
        visible={showBankAcountModal}
        onCancel={() => setBankAcountModal(false)}
        title={''}
        visibleIcon={false}
        className="px-6 sm:px-6"
      >
        <BankAcountList
          onChange={(value) => handleSetBankAccount(value)}
          data={bankList}
          select={bankID}
          handleClick={handleNewBank}
        />
      </ModalContent>
      <ModalContent
        visible={showModal}
        onCancel={() => setShowModal(false)}
        visibleIcon={false}
        className="px-6 sm:px-6"
      >
        <div className="text-center">
          <div className="justify-center flex">
            <WarningIcon />
          </div>
          <div className="mt-6 font-bold text-xl text-color-text-1">
            {t('settings:auto_disbursement_fixed')}
          </div>
          <p className="text-color-text-1 text-sm font-medium mt-2">
            {t('settings:this_business')}
            <span className="text-color-text-1 text-sm font-bold">
              {t('settings:chain_operating')}
            </span>
            {t('settings:therefore_auto')}
          </p>
          <Button className="w-full mt-6" onClick={() => setShowModal(false)}>
            {t('common:back')}
          </Button>
        </div>
      </ModalContent>
    </>
  );
};

export default StoreSettingAdvancedSettings;
