import { IPower } from "@/types/associates-type";

export const getPower = (
  permissions: string[],
  userID: string,
  associate: any
) => {
  const power: IPower = {
    edit: false,
    remove: false,
    leave: false,
    editRole: false,
    suspend: false,
  };
  if (permissions.includes('associates:update')) {
    power.suspend = true;
    if (userID === associate.user_id) {
      power.suspend = false;
    }
    if (associate.role_name === 'Director') {
      power.suspend = false;
    }
    if (associate.status === 'pending_admission') {
      power.suspend = false;
    }
    if (associate.status === 'active') {
      power.edit = true;
    }
  }

  if (permissions.includes('associates:delete')) {
    power.remove = true;
    if (userID === associate.user_id) {
      power.remove = false;
    }
    if (
      associate.role_name === 'Director' ||
      associate.status === 'pending_admission'
    ) {
      power.remove = false;
    }
  }

  if (associate.role_name !== 'Director') {
    if (userID === associate.user_id) {
      power.leave = true;
    }

    if (!(associate.role_name === 'Agent' && userID === associate.user_id)) {
      power.editRole = true;
    }
  }

  return power;
};

export const getRoleName = (value: string, record: any, lang: string) => {
  let roleName = value;
  const translations = record.translations;
  if (Array.isArray(translations)) {
    for (let i = 0; i < translations.length; i++) {
      const item = translations[i];
      if (item.languages_code === lang) {
        roleName = item.role_name;
        break;
      }
    }
  }
  return roleName || '--';
};
