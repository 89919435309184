import { Constants } from "@/constants";
import { IReducersState } from "@/reducers";
import { getCookie } from "@/utils";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"

const GlobalCheckComponent = () => {
  const navigate = useNavigate();
  const { lang = 'en' } = useParams();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const paramsToken = searchParams.get('token');
  const isApp = searchParams.get('isApp');
  const account = useSelector((state: IReducersState) => state.account);
  const businessState = useSelector((state: IReducersState) => state.businessState);
  const { loginUser } = account;
  const { business } = businessState;

  useEffect(() => {
    if (isApp) return;

    let redirectUrl = '';

    if (loginUser.id) {
      const { status, kyc_status, suspended } = loginUser;
      if (
        ['secured', 'active'].includes(status) &&
        kyc_status === 'approved' &&
        !suspended
      ) {
        redirectUrl = '';
      } else if (suspended) {
        redirectUrl = `/${lang}/account/suspension-zone`;
      } else {
        redirectUrl = `/${lang}/account/guide`;
      }
    }

    if (!redirectUrl) {
      const token = getCookie(Constants.TOKEN) || '';
      const realToken = token || paramsToken;
      if (!realToken) {
        redirectUrl = `/${lang}/logged-out-alert`;
      }
    }

    if (!redirectUrl && business.id && pathname.indexOf('/personal-space') < 0) {
      if (['idle', 'suspended'].includes(business.associate_status)) {
        redirectUrl = `${lang}/personal-space/business-overview`;
      }
    }

    if (redirectUrl) {
      navigate(redirectUrl);
    }
  // eslint-disable-next-line
  }, []);

  return (
    <Outlet />
  )
}

export default GlobalCheckComponent;