import React from 'react';
import type { IIconProps } from '@/types/common-types';

const FPSEmailIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick, size = 24 } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.91945 9.12032C1.91945 7.97537 1.92047 7.16496 2.00304 6.55082C2.08375 5.95049 2.23439 5.61009 2.48181 5.36267C2.72923 5.11524 3.06963 4.96461 3.66996 4.8839C4.28409 4.80133 5.09451 4.80031 6.23945 4.80031H17.7595C18.9044 4.80031 19.7148 4.80133 20.3289 4.8839C20.9293 4.96461 21.2697 5.11524 21.5171 5.36267C21.7645 5.61009 21.9152 5.95049 21.9959 6.55082C22.0784 7.16495 22.0795 7.97537 22.0795 9.12031V14.8803C22.0795 16.0253 22.0784 16.8357 21.9959 17.4498C21.9152 18.0501 21.7645 18.3905 21.5171 18.638C21.2697 18.8854 20.9293 19.036 20.3289 19.1167C19.7148 19.1993 18.9044 19.2003 17.7595 19.2003H6.23945C5.09451 19.2003 4.28409 19.1993 3.66996 19.1167C3.06963 19.036 2.72923 18.8854 2.48181 18.638C2.23439 18.3905 2.08375 18.0501 2.00304 17.4498C1.92047 16.8357 1.91945 16.0253 1.91945 14.8803V9.12032Z"
          stroke="black"
          strokeWidth="0.96"
        />
        <path
          d="M9.16474 10.2391L3.9969 15.4069C3.78749 15.6163 3.78749 15.9557 3.9969 16.1651C4.1016 16.2697 4.23869 16.322 4.37592 16.322C4.51315 16.322 4.65038 16.2697 4.75495 16.1651L9.92279 10.9972C10.1322 10.7879 10.1322 10.4484 9.92279 10.2391C9.71352 10.0297 9.37415 10.0297 9.16474 10.2391Z"
          fill="#0094FF"
        />
        <path
          d="M14.8353 10.2391L20.0031 15.4069C20.2125 15.6163 20.2125 15.9557 20.0031 16.1651C19.8984 16.2697 19.7613 16.322 19.6241 16.322C19.4868 16.322 19.3496 16.2697 19.2451 16.1651L14.0772 10.9972C13.8678 10.7879 13.8678 10.4484 14.0772 10.2391C14.2865 10.0297 14.6259 10.0297 14.8353 10.2391Z"
          fill="#0094FF"
        />
        <path
          d="M2.63867 5.51953L11.9987 11.9995L21.3587 5.51953"
          stroke="black"
          strokeWidth="0.96"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default FPSEmailIcon;
