import React from 'react';
import type { IIconProps } from '@/types/common-types';

const VerificationHomeIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.1523 11.3872L17.1185 0.864446C16.3445 0.239846 15.2213 0.239846 14.4473 0.864446L1.41354 11.3872C0.702541 11.9614 0.439741 12.8884 0.743941 13.7488C1.04814 14.611 1.83474 15.1672 2.74914 15.1672H5.22054V27.3424H2.82294C2.32614 27.3424 1.92294 27.7456 1.92294 28.2424C1.92294 28.7392 2.32614 29.1424 2.82294 29.1424H28.7429C29.2397 29.1424 29.6429 28.7392 29.6429 28.2424C29.6429 27.7456 29.2397 27.3424 28.7429 27.3424H26.8925V15.1672H28.8167C29.7293 15.1672 30.5177 14.611 30.8219 13.7488C31.1261 12.8866 30.8615 11.9614 30.1523 11.3872ZM7.02054 15.1672H11.5025V27.3424H7.02054V15.1672ZM18.8087 15.1672V27.3424H13.3043V15.1672H18.8087ZM25.0925 27.3442H20.6105V15.169H25.0925V27.3442ZM29.1227 13.1494C29.0939 13.2304 29.0165 13.3672 28.8149 13.3672H2.74914C2.54934 13.3672 2.47014 13.2304 2.44134 13.1494C2.41254 13.0684 2.38734 12.9136 2.54394 12.7876L15.5777 2.26485C15.6965 2.16945 15.8693 2.16945 15.9881 2.26485L29.0219 12.7876C29.1767 12.9136 29.1515 13.0684 29.1227 13.1494Z"
          fill="#231815"
        />
        <path
          d="M19.7837 11.1716H11.7809C11.2841 11.1716 10.8809 10.7684 10.8809 10.2716C10.8809 9.77478 11.2841 9.37158 11.7809 9.37158H19.7855C20.2823 9.37158 20.6855 9.77478 20.6855 10.2716C20.6855 10.7684 20.2805 11.1716 19.7837 11.1716Z"
          fill="#FFDA44"
        />
      </svg>
    </div>
  );
};

export default VerificationHomeIcon;
