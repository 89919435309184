import { useEffect, useState } from 'react';
import UpArrowIcon from '../../payouts-icons/up-arrow-icon';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import UnsuccessfulIcon from '@/components/svg/failed-icon';
import TipsIcon from '@/components/svg/tips-icon';
import RateUpdateCountdownModal from '../rate-update-modal';
import ExchangeRateMoadl from '../exchange-rate-modal';
import { IPayee, IPayout } from '@/types/payouts-type';
import { IUser } from '@/types/common-types';
import { getPayoutsI18nValue, handleMount, initFiles } from '@/utils/payouts-utils';
import { getCookie } from '@/utils';
import { Constants } from '@/constants';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import api from '@/libs/api';
import Config from '@/libs/config';
import UpArrowDashedIcon from '../../payouts-icons/up-arrow-dashed-icon';
import PayeeDetails from '../../components/payee-details';
import PayeeItem from '../../components/payee-item';
import FileInput from '@/components/common/file-input/file-input';
import Button from '@/components/common/button';
import AntdModal from '@/components/common/antd/modal';

dayjs.extend(utc);

interface IPayoutsTransferConfirmation {
  businessID: string;
  activekey: string;
  payee: IPayee;
  payout: IPayout;
  user: IUser;
  setStepCount: (stepCount: number) => void;
  setCreateLoading: (loading: boolean) => void;
}

let cycleTimer: any = '';

const PayoutsTransferConfirmation: React.FC<IPayoutsTransferConfirmation> = (
  props
) => {
  const {
    payee,
    setStepCount,
    payout,
    user,
    businessID,
    setCreateLoading,
    activekey,
  } = props;
  const { destination_currency, fx_sell_currency } = payout;
  const [time, setTime] = useState(60);
  const [showRateModal, setRateShowModal] = useState(false);
  const [showExchangeRateModal, setExchangeRateShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const { t } = useTranslation('payouts');
  const { t: ct } = useTranslation('common');
  const { lang = 'en' } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    clearInterval(cycleTimer);
    if (payout.fx_sell_currency !== payout.destination_currency) {
      timerStart();
    }
    // eslint-disable-next-line
  }, [show]);

  const timerStart = () => {
    const now = dayjs();
    cycleTimer = setInterval(() => timeChange(now), 1000);
  };

  const timeChange = (startTime: dayjs.Dayjs) => {
    const intervalTime = Math.floor(dayjs().diff(startTime) / 1000);
    const newTime = 60 - intervalTime;
    setTime(newTime);
    if (newTime <= 0) {
      clearInterval(cycleTimer);
      setExchangeRateShowModal(true);
      setRateShowModal(false);
    }
  };

  const invoiceFiles = initFiles(payout.attachment_files, 'Invoice');
  const poFiles = initFiles(payout.attachment_files, 'Purchase Order');

  const handleConfirm = async (fxQuoteID?: string) => {
    setCreateLoading(true);
    setExchangeRateShowModal(false);
    const token = getCookie(Constants.TOKEN) || '';
    const files: {
      type: string;
      attachment_file: string;
    }[] = [];
    payout.attachment_files.forEach((item) => {
      files.push({
        type: item.out_type,
        attachment_file: item.id,
      });
    });
    const data: any = {
      p_business_id: businessID,
      payee: payee.id,
      transfer_type: 'Outbound Transfer',
      currency: payout.fx_sell_currency,
      schedule_at: payout.schedule_payout_at
        ? dayjs(payout.schedule_payout_at).utc().format()
        : null,
      payout_category: payout.payout_category_id,
      reference: payout.reference,
      purchase_order_number: payout.po_number,
      remark: payout.remark,
      attachment_files: files,
      amount: payout.total_amount,
      transfer_purpose: payout.transfer_purpose_id,
    };
    if (fxQuoteID) {
      data.fx_quote_id = fxQuoteID;
    } else if (
      payout.fx_buy_currency !== payout.fx_sell_currency &&
      payout.fx_quote_id
    ) {
      data.fx_quote_id = payout.fx_quote_id;
    }
    const resp: any = await api.post(
      `${Config.urls.gatewayUrl}/api/treasury-services/api/payout_requests`,
      data,
      {
        headers: {
          'X-USER-ACCESS-TOKEN': token,
          'x-p-business-id': businessID,
          'Content-Type': 'application/json',
          'x-i18n-lang': lang,
        },
      }
    );
    const { data: payoutData, code, message } = resp;
    if (
      payoutData &&
      payoutData.payout_request &&
      payoutData.payout_request.id
    ) {
      navigate(
        `/${lang}/businesses/${businessID}/payouts/${activekey}/new/${payoutData.payout_request.id}`
      );
    } else {
      setErrorCode(code || '500');
      setShow(true);
      setCreateLoading(false);
      setErrorMsg(message || t('check_network'));
    }
  };

  return (
    <>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        <div className="flex justify-between relative">
          <div className="font-semibold text-xl text-color-text-1 leading-[26px]">
            {t('transfer_confirmation')}
          </div>
          {destination_currency !== fx_sell_currency && (
            <div
              className="bg-primary-color/10 rounded h-10 flex justify-center items-center pl-2.5 pr-3 text-primary-color absolute right-0 -top-[7px]"
              onClick={() => setRateShowModal(true)}
            >
              <div className="font-medium mr-1">
                {time}
                {t('s')}
              </div>
              <TipsIcon size={16} />
            </div>
          )}
        </div>
        <div className="mb-6 border-solid border-0 border-b border-border-color mt-4" />
        <div className="p-4 rounded-[10px] bg-disabled-color flex">
          <div>
            <div className="flex items-center text-3xl">
              <UpArrowIcon className="mr-2" />
              <div className="font-bold text-color-text-1">
                {handleMount(payout.total_amount, 2, true)}
              </div>
              <div className="ml-1 text-color-text-1">
                {payout.fx_sell_currency}
              </div>
            </div>
            <div className="text-sm text-color-text-2 mt-2">
              {t('billing_amount')}
            </div>
          </div>
          <div className="border-border-color border-solid border-0 border-r mx-6"></div>
          <div>
            <div className="flex items-center text-3xl">
              <UpArrowDashedIcon className="mr-2" />
              <div className="font-bold text-color-text-1">
                {handleMount(payout.destination_amount, 2, true)}
              </div>
              <div className="ml-1 text-color-text-1">
                {payout.destination_currency}
              </div>
            </div>
            <div className="text-sm text-color-text-2 mt-2">
              {t('transfer_amount')}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        <div className="font-semibold text-xl text-color-text-1 mb-3">
          {t('payee')}
        </div>
        <PayeeDetails payee={payee} showAvatar={false} bgShow={false} />
      </div>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        <div className="font-semibold text-xl text-color-text-1 mb-6">
          {t('payout_details')}
        </div>
        <div className="grid grid-cols-2 gap-y-6 gap-x-4">
          <PayeeItem
            title={t('transfer_amount')}
            content={`${handleMount(payout.destination_amount)} ${
              payout.destination_currency
            }`}
          />
          {payout.fx_sell_currency !== payout.destination_currency && (
            <PayeeItem
              title={t('rate')}
              content={`${payout.fx_exchange_rate} ${payout.fx_sell_currency}-${payout.destination_currency}`}
            />
          )}
          {payout.fx_sell_currency === payout.destination_currency && (
            <PayeeItem
              title={t('transfer_date')}
              content={payout.schedule_payout_at || t('immediate')}
            />
          )}
          <PayeeItem
            title={t('fees_charged')}
            content={`${handleMount(payout.calculated_fee)} ${
              payout.fx_sell_currency
            }`}
          />
          <PayeeItem
            title={t('billing_amount')}
            content={`${handleMount(payout.total_amount, 2)} ${
              payout.fx_sell_currency
            }`}
          />
          <PayeeItem
            title={t('purpose_of_transfer')}
            content={payout.transfer_purpose}
          />
          <PayeeItem title={t('transfer_by')} content={user.name} />
          <PayeeItem
            title={t('category')}
            content={getPayoutsI18nValue(
              payee.i18n,
              payee.categories.category_name,
              lang
            )}
          />
          {payout.fx_sell_currency !== payout.destination_currency && (
            <PayeeItem
              title={t('transfer_date')}
              content={payout.schedule_payout_at || t('immediate')}
            />
          )}
        </div>
      </div>
      <div className="flex-1 rounded shadow-card-drop bg-primary-background-color p-6 mb-6">
        <div className="font-semibold text-xl text-color-text-1 mb-6">
          {t('supporting_documents')}
        </div>
        <div className="grid grid-cols-2 gap-y-6 gap-x-4">
          <PayeeItem
            title={t('internal_reference_number')}
            content={payout.reference}
          />
          <PayeeItem
            title={t('purchase_order_number')}
            content={payout.po_number}
          />
          <PayeeItem
            title={t('invoice_receipt_attachment')}
            content={
              invoiceFiles.length > 0 ? (
                <FileInput
                  disabled={true}
                  name={'invoice'}
                  defaultValue={invoiceFiles}
                  rowQuantity={1}
                  proportion={1}
                  maxCount={0}
                />
              ) : null
            }
          />
          <PayeeItem
            title={t('purchase_order_attachment')}
            content={
              poFiles.length > 0 ? (
                <FileInput
                  disabled={true}
                  name={'po'}
                  defaultValue={poFiles}
                  rowQuantity={1}
                  proportion={1}
                  maxCount={0}
                />
              ) : null
            }
          />
          <div className="col-span-2">
            <PayeeItem
              title={t('remark_reference')}
              content={payout.remark}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <Button
          className="flex-1 mr-6"
          onClick={() => setStepCount(2)}
          styleType="Secondary"
        >
          {ct('back')}
        </Button>
        <Button
          className="flex-1"
          onClick={() => handleConfirm()}
          styleType="Primary"
        >
          {t('confirm')}
        </Button>
      </div>
      <AntdModal
        open={show}
        closeIcon={null}
        closable={false}
        onCancel={() => setShow(false)}
      >
        <div className="text-center text-color-text-1 p-8 text-sm font-medium">
          <div className="justify-center flex text-error-color">
            <UnsuccessfulIcon />
          </div>
          <div className="mt-4 font-semibold text-lg leading-6">
            {t('unsuccessful_transfer')}
          </div>
          <div className="mt-2">
            {t('error_code')}: {errorCode}
            <br />
            {t('server_request_failed')} {errorMsg}
          </div>
          <div>{t('payout_again')}</div>
          <Button
            className="w-full mt-6"
            styleType="Border"
            onClick={() => setShow(false)}
          >
            {ct('back')}
          </Button>
        </div>
      </AntdModal>
      {showRateModal && (
        <RateUpdateCountdownModal
          onCancel={() => setRateShowModal(false)}
          interval={60}
        />
      )}
      {showExchangeRateModal && (
        <ExchangeRateMoadl
          activekey={activekey}
          payout={payout}
          payee={payee}
          businessID={businessID}
          handleConfirm={handleConfirm}
        />
      )}
    </>
  );
};

export default PayoutsTransferConfirmation;
