import React from 'react';
import type { IIconProps } from '@/types/common-types';

const VerifyWhoIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className} inline-block`} onClick={onClick}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5526_157812)">
          <path
            d="M5.54229 26.4579L5.5423 26.4579C6.57894 29.2714 8.14478 31.7074 10.1947 33.7084C12.5312 35.9876 15.6119 37.8179 19.3733 39.1301C19.4691 39.1629 19.5656 39.1895 19.6613 39.2088C19.8023 39.2363 19.9421 39.25 20.0787 39.25H20.1077C20.2361 39.2479 20.3663 39.2342 20.4945 39.209C20.5927 39.1892 20.6909 39.1623 20.7843 39.1298C24.5417 37.8155 27.6189 35.9842 29.9533 33.7048C32.0024 31.7037 33.5688 29.266 34.6071 26.4521C36.5091 21.2937 36.4101 15.59 36.3205 10.8701C36.3205 10.8687 36.3205 10.8673 36.3205 10.866L36.3199 10.8498C36.3016 10.4573 36.2899 10.0448 36.2835 9.58856M5.54229 26.4579L3.8743 9.58863C3.90132 7.77784 5.34469 6.27473 7.15814 6.17362L7.15818 6.17362C11.9175 5.90802 15.6493 4.34543 18.8286 1.25L18.8465 1.23355C18.8466 1.23345 18.8467 1.23334 18.8468 1.23324C19.2076 0.902478 19.6571 0.74355 20.102 0.750201L20.1035 0.75022C20.532 0.755791 20.9606 0.913884 21.3082 1.23076L21.3254 1.24694C24.506 4.34437 28.2386 5.90793 32.9996 6.17362L32.9996 6.17362C34.8131 6.27473 36.2564 7.7778 36.2835 9.58856M5.54229 26.4579C3.64676 21.3139 3.74686 15.6202 3.83669 10.9083M5.54229 26.4579L3.83669 10.9083M36.2835 9.58856L37.0334 9.57793M36.2835 9.58856C36.2835 9.58871 36.2835 9.58887 36.2835 9.58903L37.0334 9.57793M37.0334 9.57793C37.0398 10.0302 37.0514 10.4364 37.0694 10.8212L37.0334 9.57793ZM3.83669 10.9083C3.8367 10.9081 3.8367 10.9078 3.83671 10.9075L3.838 10.8465C3.85619 10.4528 3.86785 10.0409 3.8743 9.58903L3.83669 10.9083Z"
            fill="#00DD80"
            stroke="black"
            strokeWidth="1.5"
          />
          <path
            d="M35.3107 26.7114C34.2356 29.6253 32.6096 32.1588 30.4774 34.2411C28.0503 36.611 24.8722 38.4943 21.0313 39.8377C20.9052 39.8816 20.7737 39.9176 20.6415 39.9442C20.467 39.9787 20.2894 39.9973 20.1133 39.9997V0C20.7236 0.0079346 21.3315 0.23468 21.8183 0.680543L21.8442 0.704957C24.8847 3.66791 28.4424 5.16785 33.0415 5.4245C35.2473 5.54749 37.0008 7.37214 37.0335 9.57764C37.0399 10.0299 37.0515 10.4361 37.0695 10.8209L37.0704 10.8514C37.1595 15.5502 37.27 21.398 35.3107 26.7114Z"
            fill="#00AA63"
          />
          <path
            d="M30.0474 20.0001C30.0474 25.4851 25.594 29.9498 20.1137 29.9693H20.0786C14.582 29.9693 10.1094 25.497 10.1094 20.0001C10.1094 14.5036 14.582 10.0312 20.0786 10.0312H20.1137C25.594 10.0508 30.0474 14.5155 30.0474 20.0001Z"
            fill="white"
          />
          <path
            d="M30.0471 20.0001C30.0471 25.4851 25.5936 29.9498 20.1133 29.9693V10.0312C25.5936 10.0508 30.0471 14.5155 30.0471 20.0001Z"
            fill="#E1EBF0"
          />
          <path
            d="M24.6047 18.5641L20.115 23.0545L19.1448 24.0246C18.9156 24.2538 18.615 24.3682 18.3147 24.3682C18.0141 24.3682 17.7138 24.2538 17.4843 24.0246L15.3985 21.9378C14.9401 21.4795 14.9401 20.737 15.3985 20.2783C15.8562 19.8199 16.5996 19.8199 17.058 20.2783L18.3147 21.535L22.9452 16.9046C23.4035 16.4459 24.1469 16.4459 24.6047 16.9046C25.0631 17.3629 25.0631 18.1063 24.6047 18.5641Z"
            fill="#B4D2D7"
          />
          <path
            d="M24.603 18.5641L20.1133 23.0545V19.7345L22.9435 16.9046C23.4019 16.4459 24.1453 16.4459 24.603 16.9046C25.0614 17.3629 25.0614 18.1063 24.603 18.5641Z"
            fill="#6FA5AA"
          />
        </g>
        <defs>
          <clipPath id="clip0_5526_157812">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default VerifyWhoIcon;
