import type { PayeeContentBaseProps } from '../../type';
import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import Label from '@/components/common/label';
import DetailsItem from '@/pages/account-balance/components/details-item-component';
import CopyIcon from '@/components/svg/copy-icon';
import PlatformtDetailsIcon from '@/pages/account-balance/account-balances-icons/payment-methdod-icons/platformtransfer-details-icon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IReducersState } from '@/reducers';

let tipsTimeout: any;

const PlatformTransferPayee = (props: PayeeContentBaseProps) => {
  const { t } = useTranslation("account_balance");
  const [copySuccess, setCopySuccess] = useState(false);
  const { record, initLoading, disabled = true } = props;
  const { payee = {}, transfer_method: transferMethod = '' } = record || {};
  const { nickname = '', payout_accounts = {} } = payee || {};
  const { identifier_1_value: identifier1Value = '' } = payout_accounts || {};
  const business = useSelector((state: IReducersState) => state.businessState.business);
  const businessName = business.business_name

  useEffect(() => {
    if (copySuccess) {
      clearTimeout(tipsTimeout);
      tipsTimeout = setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }
  }, [copySuccess]);


  const handleCopy = () => {
    navigator.clipboard.writeText(identifier1Value).then(() => {
      setCopySuccess(true);
    });
  };

  return (
    <div className="flex w-full space-x-6">
      <Transition.Child
        className="flex-1"
        enter="duration-350 transform transition ease-in-out"
        enterFrom="-translate-x-1 translate-y-8 opacity-60"
        enterTo="translate-x-0 translate-y-0 opacity-100"
        leave="duration-500 transform transition"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div
          className={`w-full rounded overflow-hidden shadow-card-drop bg-primary-background-color p-6 mb-6 h-fit`}
        >
          <div className="font-semibold text-lg text-color-text-1 flex items-center md:text-xl">
            {t('account_balance:payee')}
          </div>
          <div className="mt-4 mb-5 border-solid border-0 border-b border-border-color" />
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
              <DetailsItem
                title={t('account_balance:payee_name')}
                content={nickname}
                loading={initLoading}
              />
              <DetailsItem
                title={t('account_balance:transfer_method')}
                content={
                  <div className="text-color-text-1 font-medium flex items-center space-x-2 space-y-0">
                    <span className="group">
                      <PlatformtDetailsIcon className="text-primary-color" />
                    </span>
                    <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-color-text-1 font-medium text-sm">
                      {transferMethod}
                    </span>
                  </div>
                }
                loading={initLoading}
                contentClass={'text-color-text-1 font-medium pr-6 text-sm'}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 md:gap-y-6 mb-2 md:mb-6">
              <DetailsItem
                title={t('account_balance:business_name')}
                content={businessName}
              // loading={loadingBusiness}
              />
              <div className="w-full col-auto md:col-span-2">
                <Label
                  disabled={disabled}
                  value={t('account_balance:business_id')}
                ></Label>
                <div className="py-2 text-color-text-1 font-medium pr-6 flex items-center text-sm space-x-2">
                  <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-color-text-1 text-sm font-medium">
                    {identifier1Value}
                  </span>
                  <span className="cursor-pointer group" onClick={handleCopy}>
                    <CopyIcon className="text-primary-color" />
                  </span>
                  <span
                    className={`text-primary-color text-xs cursor-pointer ${copySuccess ? 'visible' : 'invisible'
                      }`}
                  >
                    {t('account_balance:copied')}
                  </span>
                </div>
              </div>
            </div>
          </>
        </div>
      </Transition.Child>
    </div>
  );
};
export default PlatformTransferPayee;
